import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Content from "components/Content";
import { ExternalDashboard } from "./ExternalDashboard";
import { Row, Col } from "react-bootstrap";

import { Creators as NotificacoesActions } from "../../store/ducks/notificacoes";
import { Creators as DocumentoActions } from "../../store/ducks/documentos";

export const useStyles = makeStyles((theme) => ({
  paper: { minWidth: "700px", maxHeight: "800px", overflowY: "hidden" },
}));

function Agenda() {
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const [userActions, setUserActions] = useState([]);

  const {
    access: { servicos },
  } = useSelector((state) => state.permissoes);

  useEffect(() => {
    const founded = servicos?.find((item) => item.route === pathname);
    if (founded && founded !== undefined) setUserActions(founded?.actions);
  }, [pathname, servicos]);

  useEffect(() => {
    dispatch(DocumentoActions.clearAll());
    dispatch(NotificacoesActions.listRequest({ page: 1 }));
  }, [dispatch]);

  return (
    <Content>
      <Row>
        <Col>
          <ExternalDashboard />
        </Col>
      </Row>
    </Content>
  );
}

export default Agenda;
