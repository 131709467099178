import api from 'services/api';
import { getToken } from 'services/auth';
import { call, select, put, all, takeLatest } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';

import { Creators as EncerramentosActions, Types } from 'store/ducks/encerramentos';

export const getOrganization = state => state.usuarios.auth.organization;

export function* listEncerramentos({ payload }) {
  const organization = yield select(getOrganization);

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
      params: payload,
    };

    const { data } = yield call(api.get, 'encerramentos', headerParams);

    yield put(EncerramentosActions.listSuccess(data.docs, data.total));
  } catch (error) {
    toastr.error('Ops', 'Não foi possível listar o(s) encerramento(s).');
  }
}

export default all([
  takeLatest(Types.LIST_REQUEST, listEncerramentos)
])