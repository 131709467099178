import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Button, Divider, IconButton } from '@material-ui/core';
import IndexerFormFieldFactory from 'components/FormFieldIndexer/IndexerFormFieldFactory';
import { ArrowDownIcon, ArrowUpIcon } from 'components/Icons';
import ToolTipComponent from 'components/Tooltip';
import { TERMO_ATE, TERMO_DE } from '..';

import './styles.css';

const INITIAL_DATA = [];

//#region componentes auxiliares
function ShowFilters({ tipoImagemSelect, defaultOpen, children }) {

    const [open, setOpen] = useState(defaultOpen);

    return (
        tipoImagemSelect?.indexadores?.length > 0 && <>
            <div className="controlpanel-item">
                <ToolTipComponent
                    title={open ? "Recolher" : "Expandir"}
                    placement="top"
                >
                    <div className='controlpanel-item-filter'>
                        <span>
                            <Col md={12}>
                                Filtros por indexadores do tipo de imagem:{' '}
                                {tipoImagemSelect.nome}
                                <IconButton
                                    size="small"
                                    color="primary"
                                    aria-label={open ? "Recolher" : "Expandir"}
                                    component="span"
                                    onClick={() => setOpen(!open)}
                                >
                                    {open ? <ArrowUpIcon /> : <ArrowDownIcon />}
                                </IconButton>
                            </Col>
                            <Divider />
                        </span>
                    </div>
                </ToolTipComponent>
            </div>

            <div className="painel-filters-viewr">
                {open && children}
            </div>
        </>
    );
}
//#endregion

export default function Filters({ handleFilter, tipoImagemSelect, clearType }) {

    const [defaultFilterVisualize, setDefaultFilterVisualize] = useState(false);
    const [indexadoresTipoDocumento, setIndexadoresTipoDocumento] = useState(INITIAL_DATA);

    function handleChangeIndexador(v, indexador) {
        setIndexadoresTipoDocumento((prev) => {
            const { id_es, tipo } = indexador;

            const index = prev.length > 0 ? prev.findIndex(e => e.id_es === id_es) : -1;
            const valor = v?.target?.value;

            if (index !== -1) {
                prev[index] = {
                    ...prev[index],
                    valor,
                };
                return prev;
            }

            return [
                ...prev,
                {
                    valor,
                    id_es,
                    tipo,
                },
            ];
        });
    };

    function searchForm() {
        handleFilter(indexadoresTipoDocumento);
    }

    function clearForm() {
        setDefaultFilterVisualize(true);
        setIndexadoresTipoDocumento(INITIAL_DATA);
        clearType();
    }

    function getIndexadores(cb) {
        return tipoImagemSelect?.indexadores
            ? tipoImagemSelect.indexadores
                .filter((indexador) => indexador?.exibirPesquisaDetalhada)
                .map(cb)
            : null
    }

    return (
        <>
            {
                tipoImagemSelect &&

                <ShowFilters tipoImagemSelect={tipoImagemSelect} defaultOpen={defaultFilterVisualize}>
                    <>
                        <Row>

                            {
                                getIndexadores((indexador) => (
                                    indexador.tipo === 'data'
                                        ?
                                        <>
                                            <Col
                                                key={`${indexador.id_es}${TERMO_DE}`}
                                                md={6}
                                                className="esteira-indexador"
                                            >
                                                <IndexerFormFieldFactory
                                                    fieldToAdd={null}
                                                    genericParams={{
                                                        tipo: indexador.tipo,
                                                        indexador: { ...indexador, nome: `${indexador.nome} de` },
                                                        checkReadOnly: false,
                                                        hasIndexadorError: () => { },
                                                        hasIndexadorTextError: () => { },
                                                        handleChangeIndexador: (v) => handleChangeIndexador(v, { ...indexador, id_es: `${indexador.id_es}${TERMO_DE}` }),
                                                    }}
                                                    identificadorUnicoParams={{}}
                                                />
                                            </Col>

                                            <Col
                                                key={`${indexador.id_es}${TERMO_ATE}`}
                                                md={6}
                                                className="esteira-indexador"
                                            >
                                                <IndexerFormFieldFactory
                                                    fieldToAdd={null}
                                                    genericParams={{
                                                        tipo: indexador.tipo,
                                                        indexador: { ...indexador, nome: `${indexador.nome} até` },
                                                        checkReadOnly: false,
                                                        hasIndexadorError: () => { },
                                                        hasIndexadorTextError: () => { },
                                                        handleChangeIndexador: (v) => handleChangeIndexador(v, { ...indexador, id_es: `${indexador.id_es}${TERMO_ATE}` }),
                                                    }}
                                                    identificadorUnicoParams={{}}
                                                />
                                            </Col>
                                        </>
                                        :
                                        <Col
                                            key={indexador.id_es}
                                            md={6}
                                            className="esteira-indexador"
                                        >
                                            <IndexerFormFieldFactory
                                                fieldToAdd={
                                                    indexador.tipo === 'combo'
                                                        ? { valor: '', texto: 'Limpar Filtro' }
                                                        : null
                                                }
                                                genericParams={{
                                                    indexador,
                                                    tipo: indexador.tipo,
                                                    checkReadOnly: false,
                                                    hasIndexadorError: () => { },
                                                    hasIndexadorTextError: () => { },
                                                    handleChangeIndexador: (v) => handleChangeIndexador(v, indexador),
                                                }}
                                                identificadorUnicoParams={{}}
                                            />
                                        </Col>
                                ))
                            }

                            <Col className="search-form-buttons">
                                <Button
                                    onClick={(e) => searchForm()}
                                    variant="contained"
                                    color="primary"
                                >
                                    Pesquisar
                                </Button>
                                <Button
                                    onClick={(e) => clearForm()}
                                    variant="contained"
                                    color="secondary"
                                    type="reset"
                                >
                                    Limpar
                                </Button>
                            </Col>

                        </Row>

                    </>

                </ShowFilters>
            }
        </>
    );
}