import styled, { css } from 'styled-components/macro';


import { default as MaterialDialog } from "@material-ui/core/Dialog";
import { default as MaterialDialogTitle } from "@material-ui/core/DialogTitle";
import { default as MaterialDialogContent } from "@material-ui/core/DialogContent";
import { default as MaterialDialogActions } from "@material-ui/core/DialogActions";
import { default as MaterialButton } from "@material-ui/core/Button";

export const Container = styled(MaterialDialog)`
    .MuiDialog-paperWidthSm {
        max-width: 60vw;
        width: 60vw;

        @media screen and (max-width:  1000px) {
            max-width: 80vw;
            width: 80vw;
        }

        ${({ contained }) => contained && css`
            width: 600px;
        `};
    }
`;

export const Title = styled(MaterialDialogTitle)`
    text-align: center;
`;

export const Content = styled(MaterialDialogContent)`

`;

export const Actions = styled(MaterialDialogActions)`
    display: flex;
    justify-content: center;
    align-items: center;

    ${({ centerbuttons }) => centerbuttons && css`
        .MuiDialogActions-root {
            justify-content: center;
        }    
    `};


`;

export const Button = styled(MaterialButton)`

`;