import api from "services/api";
import { getToken } from "services/auth";
import { call, select, put, all, takeLatest } from "redux-saga/effects";
import { toastr } from "react-redux-toastr";

import {
  Creators as ProvidenciasActions,
  Types,
} from "store/ducks/providencias";

export const getOrganization = (state) => state.usuarios.auth.organization;

export function* listProvidencias({ payload }) {
  const organization = yield select(getOrganization);

  try {
    if ((payload.SLA && payload.SLA === "") || !payload.SLA) delete payload.SLA;
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
      params: payload,
    };

    const { data } = yield call(api.get, "providencias", headerParams);

    yield put(ProvidenciasActions.listSuccess(data.docs, data.total));
  } catch (error) {
    toastr.error("Ops", "Não foi possível listar os subsidios.");
  }
}

export function* listProvidenciasRecebidas({ payload }) {
  const organization = yield select(getOrganization);

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
      params: payload,
    };

    const { data } = yield call(
      api.get,
      "providencias/recebidas",
      headerParams
    );

    yield put(ProvidenciasActions.listReceivedSuccess(data.docs, data.total));
  } catch (error) {
    toastr.error("Ops", "Não foi possível listar as tarefas recebidas.");
  }
}

export function* listProvidenciasEnviadas({ payload }) {
  const organization = yield select(getOrganization);

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
      params: payload,
    };

    const { data } = yield call(api.get, "providencias/enviadas", headerParams);

    yield put(ProvidenciasActions.listSentSuccess(data.docs, data.total));
  } catch (error) {
    toastr.error("Ops", "Não foi possível listar as tarefas enviadas.");
  }
}

export function* tarefasNaoLidas() {
  const organization = yield select(getOrganization);

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const url = "providencias/nao-lidas";

    const { data } = yield call(api.get, url, headerParams);
    yield put(ProvidenciasActions.unreadSuccess(data.total));
  } catch (error) {
    toastr.error("Ops", "Não foi possível listar as tarefas.");
  }
}

export function* markAsRead({ payload }) {
  const organization = yield select(getOrganization);

  const { _id } = payload;

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const url = `providencias/${_id}/marcar-como-lida`;

    yield call(api.put, url, null, headerParams);
    yield put(ProvidenciasActions.markAsReadSuccess(_id));
  } catch (error) {
    toastr.error("Ops", "Não foi possível marcar como lida a tarefa.");
  }
}

export default all([
  takeLatest(Types.LIST_REQUEST, listProvidencias),
  takeLatest(Types.LIST_RECEIVED_REQUEST, listProvidenciasRecebidas),
  takeLatest(Types.LIST_SENT_REQUEST, listProvidenciasEnviadas),
  takeLatest(Types.UNREAD_REQUEST, tarefasNaoLidas),
  takeLatest(Types.MARK_AS_READ_REQUEST, markAsRead),
]);
