import React, { useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import useForm from 'react-hook-form';

import history from 'routes/history';

import { Creators as GruposEmpresariaisActions } from 'store/ducks/gruposempresariais';
import Content from 'components/Content';

const INITIAL_FORM = {
  nome: ''
}

function FormGrupoEmpresarial({ location }) {

  const { grupoEmpresarial = INITIAL_FORM } = location.state || {};
  const dispatch = useDispatch();

  const { setValue, register, handleSubmit } = useForm();

  useEffect(() => {
    register({
      name: 'nome',
      value: grupoEmpresarial.nome
    })
    if (grupoEmpresarial._id) {
      register({
        name: '_id',
        value: grupoEmpresarial._id
      })
    }
  }, [register, grupoEmpresarial])

  function handleChange({ target }) {
    const { name, value } = target;
    setValue(name, value);
  }

  function handleBackButton() {
    history.goBack();
  }

  function onSubmit(values) {
    dispatch(GruposEmpresariaisActions.saveRequest(values))
  }

  return (
    <Content>
      <Row>
        <Col md="12" className="painel">
          <div className="painel-content">
            <div className="painel-title text-left">Editar Grupo Empresarial</div>
            <div className="painel-body">
              <form onSubmit={handleSubmit(onSubmit)}>
                <Row className="row">
                  <Col>
                    <TextField
                      label="Nome"
                      variant="outlined"
                      onChange={handleChange}
                      name="nome"
                      defaultValue={grupoEmpresarial.nome}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                    >
                      Enviar
                    </Button>
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={handleBackButton}
                    >
                      Voltar
                    </Button>
                  </Col>
                </Row>
              </form>
            </div>
          </div>
        </Col>
      </Row>
    </Content>
  );
}

export default FormGrupoEmpresarial;