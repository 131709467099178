import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toastr } from "react-redux-toastr";

import { Creators as FormularioActions } from "store/ducks/formulario";

import FormUser from "./FormUser";
import FormIndexadores from "./FormIndexadores";

const DEFAULT_INFORMACOES = {};
const DEFAULT_AUTENTICADO = false;
const DEFAULT_RESPOSTA = null;

export default function FormularioPublico(props) {

    const dispatch = useDispatch();

    const [sending, setSending] = useState(false);
    const [idResposta, setIdResposta] = useState(DEFAULT_RESPOSTA);
    const [informacoes, setInformacoes] = useState(DEFAULT_INFORMACOES);
    const [autenticado, setAutenticado] = useState(DEFAULT_AUTENTICADO);
    const [formularioCarregado, setFormularioCarregado] = useState();

    const { formulario: formReq, informacoes: retornoResposta } = useSelector((state) => state.formularios);

    //#region funções auxiliares
    function getGetFormId(text) {
        const info = text.split('/').pop();
        const [id, xkeyorg] = info.split('-');
        return { id, xkeyorg };
    }

    function onSubmitUser(values) {
        setInformacoes(values);
        setAutenticado(true);
    }

    function onFinished() {
        setIdResposta(DEFAULT_RESPOSTA);
        setInformacoes(DEFAULT_INFORMACOES);
        setAutenticado(DEFAULT_AUTENTICADO);
    }

    function onSubmitForm(values) {
        try {
            setSending(true);

            const { indexadores, files } = values;
            const { idTipoDocumento, idDepartamento } = formularioCarregado;

            dispatch(FormularioActions.responseRequest({ xkeyorg: informacoes.xkeyorg, idResposta, idTipoDocumento, idDepartamento, campos: indexadores, attachments: files }));

            onFinished();

        } catch (err) {
            toastr.error("Ops", err.message);
        }
        finally {
            setSending(false);
        }
    }
    //#endregion

    //#region efeitos
    useEffect(() => {
        const payload = getGetFormId(props.location.pathname);
        dispatch(FormularioActions.getNoAuthRequest(payload));
        setInformacoes(payload);
    }, [dispatch]);

    useEffect(() => {
        setFormularioCarregado(formReq);
    }, [formReq]);

    useEffect(() => {
        setIdResposta(retornoResposta.idResposta);
    }, [retornoResposta]);
    //#endregion

    return (
        <>
            {
                autenticado
                    ?
                    <FormIndexadores
                        onSave={onSubmitForm}
                        lockScreen={sending}
                        initialData={formularioCarregado}
                    />
                    : <FormUser onChange={onSubmitUser} informacoes={informacoes} />
            }
        </>
    )

}