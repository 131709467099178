import api from "services/api";
import { getToken } from "services/auth";
import { call, select, put, all, takeLatest } from "redux-saga/effects";
import { toastr } from "react-redux-toastr";
import { push } from "connected-react-router";

import { Creators as AssessoriaActions, Types } from "../ducks/assessoria";

export const getOrganization = (state) => state.usuarios.auth.organization;
export const getUserId = (state) => state.usuarios.auth.id;

export function* listAssessoria({ payload }) {
  try {
    const organization = yield select(getOrganization);

    const { page, limit, sort } = payload;

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
      params: { page, limit, sort },
    };

    const response = yield call(api.post, `assessorias`, {}, headerParams);

    const data = {
      total: response.data.total,
      list: response.data.docs,
    };

    yield put(AssessoriaActions.listSuccess(data));
  } catch (error) {
    toastr.error("Ops", "Não foi possível listar os assessorias.");
  }
}

export function* getAssessoria(action) {
  try {
    const headerParams = {
      headers: { Authorization: getToken() },
    };

    const { data } = yield call(
      api.get,
      `assessoria/${action.payload}`,
      headerParams
    );

    yield put(AssessoriaActions.getSuccess(data));
  } catch (error) {
    toastr.error("Ops", "Não foi possível buscar os dados do Assessoria.");
  }
}

export function* editAssessoria(action) {
  const userId = yield select(getUserId);

  try {
    const organization = yield select(getOrganization);

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const body = { ...action.payload, id_user_acao: userId };

    const { data } = yield call(
      api.put,
      `assessoria/${action.payload._id}`,
      body,
      headerParams
    );

    yield put(push(`/configuracao/assessoria`));

    yield put(AssessoriaActions.editSuccess(data));

    toastr.success("Assessoria editada com sucesso.");
  } catch (error) {
    toastr.error("Ops", "Não foi possível editar o Assessoria.");
  }
}

export function* createAssessoria(action) {
  const organization = yield select(getOrganization);

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const { data } = yield call(
      api.post,
      `assessoria`,
      action.payload,
      headerParams
    );

    yield put(push(`/configuracao/assessoria`));

    yield put(AssessoriaActions.createSuccess(data));

    toastr.success("", "Assessoria criada com sucesso.");
  } catch (error) {
    toastr.error("Ops", "Não foi possível criar o Assessoria.");
  }
}

export function* deleteAssessoria(action) {
  try {
    const organization = yield select(getOrganization);
    const userId = yield select(getUserId);

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization, userId },
    };

    yield call(api.delete, `assessoria/${action.payload}`, headerParams);

    toastr.success("", "Assessoria removida com sucesso.");

    yield put(
      AssessoriaActions.listRequest({
        page: 1,
        limit: 10,
        sort: "nome",
      })
    );
  } catch (error) {
    //   toastr.error("Ops", "Não foi possível remover os assessorias.");
  }
}

export default all([
  takeLatest(Types.LIST_REQUEST, listAssessoria),
  takeLatest(Types.GET_REQUEST, getAssessoria),
  takeLatest(Types.EDIT_REQUEST, editAssessoria),
  takeLatest(Types.NEW_REQUEST, createAssessoria),
  takeLatest(Types.DELETE_REQUEST, deleteAssessoria),
]);
