import React from "react";
import ComentarioPadrao from "pages/Analise/components/Comentarios/ComentarioPadrao";
import { Col, Row } from "react-bootstrap";

import "./styles.css";

function FormGenerico({
  documento,
  opcao = "E-mail",
  deveAplicarClasses = true,
  botao = null,
  aoEnviar = () => {},
}) {
  function aplicarClasses(className) {
    return deveAplicarClasses ? className : "";
  }

  return (
    <>
      <div className={aplicarClasses("panels")}>
        <Row>
          <Col md="12" className={aplicarClasses("painel")}>
            <div className={aplicarClasses("painel-email-content")}>
              <ComentarioPadrao
                aoEnviar={aoEnviar}
                documento={documento}
                opcaoUnica={opcao}
                closeButton={botao}
              />
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default FormGenerico;
