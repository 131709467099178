import { endOfDay, startOfDay } from "date-fns";
import Utils from "utils/utils";
import * as Yup from "yup";

import Locale from "./Locale";

Yup.setLocale(Locale);



function isBeforeThan(value, parent, valueToCompare) {
  if (!value) return true;

  if (!parent[valueToCompare]) return true;

  const startDate = startOfDay(Utils.localeDateStringToDate(value));
  const endDate = endOfDay(Utils.localeDateStringToDate(parent[valueToCompare]));

  return startDate < endDate;
}

function isAfterThan(value, parent, valueToCompare) {
  if (!value) return true;

  if (!parent[valueToCompare]) return true;

  const startDate = startOfDay(Utils.localeDateStringToDate(parent[valueToCompare]));
  const endDate = endOfDay(Utils.localeDateStringToDate(value));
  return endDate > startDate;
}

function requiredFieldWhen(field) {
  return Yup.string()
    .optional()
    .test({
      name: "valor-obrigatorio",
      message: "Campo obrigatorio ao ter um período final",
      test: function (value) {
        return !this.parent[field] || !!value;
      },
    });
}

function createStartFieldValidation({ endFieldName }) {
  return requiredFieldWhen(endFieldName).test({
    name: "menor",
    message: "Período inicial deve ser menor do que o período final",
    test: function (value) {
      return isBeforeThan(value, this.parent, endFieldName);
    },
  });
}

function createdEndFieldValidation({ startFieldName }) {
  return requiredFieldWhen(startFieldName).test({
    name: "maior",
    message: "Período final deve ser maior do que o período inicial",
    test: function (value) {
      return isAfterThan(value, this.parent, startFieldName);
    },
  });
}

const PesquisaAvancadaSchema = Yup.object().shape({
  createdAtStart: createStartFieldValidation({ endFieldName: "createdAtEnd" }),
  createdAtEnd: createdEndFieldValidation({ startFieldName: "createdAtStart" }),
  updatedAtStart: createStartFieldValidation({ endFieldName: "updatedAtEnd" }),
  updatedAtEnd: createdEndFieldValidation({ startFieldName: "updatedAtStart" }),
  externalDateAtStart: createStartFieldValidation({
    endFieldName: "externalDateAtEnd",
  }),
  externalDateAtEnd: createdEndFieldValidation({
    startFieldName: "externalDateAtStart",
  }),
});

export { PesquisaAvancadaSchema };
