import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { useLocation, Link } from 'react-router-dom';

import logoBranco from 'assets/img/logo.png';
import logoBrancoNV from 'assets/img/logo-nv.webp';

import { MENU_ITEM_DATA, MENU_ICONS } from 'constants/vertical-menu-items';

import './styles.css';
import { FaAngleRight, FeatherIcon } from 'components/Icons';
import { Creators as MenuActions } from 'store/ducks/menu';

function Menu() {
  const dispatch = useDispatch();
  const { name, email } = useSelector(({ usuarios }) => usuarios.auth);
  const { servicos } = useSelector(({ permissoes }) => permissoes.access);
  const containsNSSmartSuite = process.env.REACT_APP_FRONT_URL.includes('nssmartsuite');

  const [collapsed, setCollapsed] = useState({});
  const [wrapper, setWrapper] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState(false);
  const [appsShow, setAppsShow] = useState(false);
  const [hovered, setHover] = useState(null);
  const [activated, setActive] = useState(null);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event, title, index) => {
    setAnchorEl(event.currentTarget);
    setActive(index === activated ? null : index);
    setSelectedMenu(title);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setActive(null);
    setSelectedMenu(null);
  };

  const { pathname } = useLocation();

  const maxWidth = useMediaQuery('(max-width:1400px)');

  const menu = useMemo(
    () =>
      MENU_ITEM_DATA.map(item => ({
        ...item,
        children: servicos
          ? servicos.filter(subMenu => subMenu.menuSuperior === item.title).sort((a, b) => (a.order > b.order ? 1 : -1))
          : [],
      })).filter(item => item.children.length > 0),
    [servicos]
  );

  useEffect(() => {
    const { title = null } = menu.find(item => item.children.some(subMenu => subMenu.route === pathname)) || {};

    if (title) {
      setCollapsed(c => ({ ...c, [title]: true }));
    }
  }, [pathname, menu]);

  useEffect(() => {
    dispatch(MenuActions.toggleMenu(activated !== null));
  }, [activated]);

  function getMenuShortName(title) {
    const menuShort = MENU_ITEM_DATA.find(menu => menu.title === title);

    return menuShort.shortName;
  }

  const handleClickCollapse = title => {
    setCollapsed({
      ...collapsed,
      [title]: !collapsed[title],
    });
  };

  const { logo } = useSelector(state => state.configuracoes.configuracoes);

  return (
    <div className={wrapper ? `menu-click` : `menu`}>
      <div className={`menu-fixed ${activated ? 'active' : ''}`}>
        <div
          className={`menu-container ${wrapper ? 'menu-wrapped-click' : 'menu-wrapped'} ${activated ? 'active' : ''}`}
        >
          <div className="menu-toolbar" style={{ borderBottom: '1px solid rgba(240, 240, 255, 0.1)' }}>
            <Link to="/agenda">
              {containsNSSmartSuite ? (
                <img src={logo || logoBranco} alt="Logo NewSpace" />
              ) : (
                <img src={logo || logoBrancoNV} alt="Logo NV Security" />
              )}
            </Link>
          </div>
          <div className="menu-header">
            <strong>{name}</strong>
            <span>{email}</span>
          </div>
          <div className={`menu-content`}>
            {menu.map((item, idx) => {
              const index = idx + 1;
              const isActivated = activated === index;
              return (
                <div key={`${item.title}_${index}`} className={`menu-lateral ${isActivated ? 'active' : ''}`}>
                  <IconButton
                    onClick={e => handleClick(e, item.title, index)}
                    className={`menu-lateral-item ${isActivated ? 'active' : ''}`}
                    onMouseEnter={() => setHover(index)}
                    onMouseLeave={() => setHover(null)}
                  >
                    {/* <span className="shape1"/> */}
                    {/* <span className="shape2"/> */}

                    {item.icon && MENU_ICONS[item.icon] && (
                      <>
                        <FeatherIcon
                          className={`menu-lateral-item-icone ${isActivated ? 'active' : ''}`}
                          size="2x"
                          icon={MENU_ICONS[item.icon]}
                        />
                      </>
                    )}
                    <strong>{getMenuShortName(item.title)}</strong>
                  </IconButton>

                  <div className={`menu-item-wrapper`} style={{ maxHeight: isActivated ? '5000px' : '0' }}>
                    {isActivated &&
                      item.children.map((subMenu, index) => (
                        <Link to={subMenu.route} key={`${subMenu._id}_${index}`} onClick={handleClose}>
                          <div className="menu-item">
                            <FaAngleRight fill={'transparent'} />
                            <span>{subMenu.title}</span>
                          </div>
                        </Link>
                      ))}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Menu;
