export const Types = {
  LIST_REQUEST: "templatesignaturepoints/LIST_REQUEST",
  LIST_SUCCESS: "templatesignaturepoints/LIST_SUCCESS",
  EDIT_REQUEST: "templatesignaturepoints/EDIT_REQUEST",
  EDIT_SUCCESS: "templatesignaturepoints/EDIT_SUCCESS",
};

const INITIAL_STATE = {
  loading: false,
  data: {},
};

export default function templatesignaturepoints(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.LIST_REQUEST:
      return { ...state, loading: true };

    case Types.LIST_SUCCESS:
      return { ...state, data: action.payload, loading: false };

    case Types.EDIT_REQUEST:
      return { ...state, loading: true };

    case Types.EDIT_SUCCESS:
      return { ...state, loading: false };

    default:
      return state;
  }
}

export const Creators = {
  listRequest: (data) => ({
    type: Types.LIST_REQUEST,
    payload: data,
  }),

  listSuccess: (data) => ({
    type: Types.LIST_SUCCESS,
    payload: data,
  }),

  editRequest: (data) => ({
    type: Types.EDIT_REQUEST,
    payload: data,
  }),

  editSuccess: () => ({
    type: Types.EDIT_SUCCESS,
    payload: {},
  }),
};
