import React from 'react';

import {
    Container,
    AccordionDetails,
} from './styles/AccordionMenu';

import { AccordionSummary } from '@material-ui/core';
import { ArrowUpIcon } from 'components/Icons';
import ChipsArray from '../ChipsArray';

function AccordionMenu() {

    return (
        <Container>
            <AccordionSummary
                expandIcon={<ArrowUpIcon style={{ color: 'white' }} />}
                aria-controls="panel1a-content"
                id="panel1a-header">
                Parágrafos
            </AccordionSummary>        
            <AccordionDetails>
                <ChipsArray/>
            </AccordionDetails>
        </Container>
    );
}

export default AccordionMenu;