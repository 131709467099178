import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
  INCLUSAO: {
    backgroundColor: 'rgb(76, 175, 80)',
    color: 'white',
    fontWeight: '400',
    display: 'inline',
    padding: '2px 4px',
    borderRadius: '4px'
  },
  ALTERACAO: {
    backgroundColor: 'rgb(255, 152, 0)',
    color: 'black',
    fontWeight: '400',
    display: 'inline',
    padding: '2px 4px',
    borderRadius: '4px'
  },
  EXCLUSAO: {
    backgroundColor: 'rgb(211, 47, 47)',
    color: 'white',
    fontWeight: '400',
    display: 'inline',
    padding: '2px 4px',
    borderRadius: '4px'
  }
}));