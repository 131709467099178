import React, { useEffect, useMemo, useCallback } from "react";
import { Dialog, DialogContent } from "@material-ui/core";
import Recaptcha from "react-recaptcha";
import { useSelector, useDispatch } from "react-redux";

import { Creators as MergepdfActions } from "store/ducks/mergepdf";

function RecaptchaDownload({
  selectedDoc,
  showViewer,
  selectedFiles,
  open,
  onClose,
  ...props
}) {
  const dispatch = useDispatch();

  const {
    access: { captcha = [] },
  } = useSelector((state) => state.permissoes);

  const showCaptcha = useMemo(() => captcha.includes("DOWNLOAD_DOCUMENTOS"), [
    captcha,
  ]);

  const verifyCallback = useCallback(() => {
    const output = `documentos.pdf`;

    const filesToMerge = showViewer
      ? selectedDoc.imagens.map(({ filepath }) => filepath)
      : selectedFiles;

    dispatch(
      MergepdfActions.add({
        filesToMerge,
        output,
      })
    );
    onClose();
  }, [selectedDoc, showViewer, selectedFiles, dispatch, onClose]);

  useEffect(() => {
    if (open) {
      if (!showCaptcha) {
        verifyCallback();
      }
    }
  }, [open, showCaptcha, onClose, verifyCallback]);

  return (
    <Dialog open={open && showCaptcha} onClose={onClose} {...props}>
      <DialogContent>
        <Recaptcha
          sitekey={process.env.REACT_APP_RECAPTCHAKEY}
          render="explicit"
          hl="pt-br"
          verifyCallback={verifyCallback}
        />
      </DialogContent>
    </Dialog>
  );
}

export default RecaptchaDownload;
