import React from 'react';

import {
    Container,
} from './styles/UserAvatar';

import TooltipComponent from '../Tooltip';

import Utils from 'utils/utils';

function UserAvatar({ user, size = 48 }) {
    return (
        <TooltipComponent 
            placement="top" 
            title={user.nome}>
            <Container size={size}>
                {Utils.getNameInitials(user.nome)}
            </Container>
        </TooltipComponent>
    );
}

export default UserAvatar;