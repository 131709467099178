import React, { useMemo } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { InputAdornment, Icon } from '@material-ui/core';

import TableUnidade from './TableUnidades';

import { Creators as UnidadesActions } from 'store/ducks/unidades';
import Content from 'components/Content';

function Unidades() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { access: { servicos } } = useSelector(state => state.permissoes);
  const { search, page, limit, sort } = useSelector(state => state.unidades);

  const userActions = useMemo(() => {
    const find = servicos.find(item => item.route === pathname);
    if (find !== undefined) return find;
    return [];
  }, [pathname, servicos]);

  const canCreate = useMemo(() =>
    true || (userActions.actions && userActions.actions.some(userAct => userAct.action === 'criar'))
    , [userActions]);

  function handleChange({ target }) {
    const { value } = target;
    dispatch(UnidadesActions.listRequest({ page, limit, sort, search: value }))
  }

  return (
    <Content>
      <Row>
        <Col md="12" className="painel">
          <div className="painel-content">
            <div className="painel-title text-left">
              <Col>
                <strong>Unidades</strong>
              </Col>
              <Col>
                <TextField
                  label="Pesquisar"
                  variant="outlined"
                  size="small"
                  InputProps={{
                    endAdornment: <InputAdornment position="end"><Icon className="text-20" color="action">search</Icon></InputAdornment>
                  }}
                  onChange={handleChange}
                  value={search}
                />
              </Col>

              {canCreate &&
                <Button
                  component={Link}
                  to={"/configuracao/unidades/create"}
                  variant="contained"
                  color="primary"
                >Nova Unidade</Button>
              }
            </div>
            <div className="painel-body">
              <TableUnidade userActions={userActions} />
            </div>
          </div>
        </Col>
      </Row>
    </Content>
  );
}

export default Unidades;