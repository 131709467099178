export const Types = {
  LIST_REQUEST: "empresas/LIST_REQUEST",
  LIST_SUCCESS: "empresas/LIST_SUCCESS",
  NEW_REQUEST: "empresas/NEW_REQUEST",
  NEW_SUCCESS: "empresas/NEW_SUCCESS",
  EDIT_REQUEST: "empresas/EDIT_REQUEST",
  EDIT_SUCCESS: "empresas/EDIT_SUCCESS",
  REMOVE_REQUEST: "empresas/REMOVE_REQUEST",
  REMOVE_SUCCESS: "empresas/REMOVE_SUCCESS",
  LIST_BYCNPJ_REQUEST: "empresas/LIST_BYCNPJ_REQUEST",
  LIST_BYCNPJ_SUCCESS: "empresas/LIST_BYCNPJ_SUCCESS",
  GET_REQUEST_REGRAS: "empresas/GET_REQUEST_REGRAS",
  GET_REQUEST_REGRAS_SUCCESS: "empresas/GET_REGRAS_SUCCESS",
};

const INITIAL_STATE = {
  list: [],
  byCNPJ: [],
  loading: false,
  regras: [],
  page: 1,
  limit: 10,
  total: 0,
  sort: "fantasia",
  search: "",
};

export default function empresas(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.LIST_REQUEST:
      return {
        ...state,
        loading: true,
        page: action.payload.page,
        limit: action.payload.limit,
        sort: action.payload.sort,
      };

    case Types.LIST_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };

    case Types.NEW_REQUEST:
      return { ...state, loading: true };

    case Types.NEW_SUCCESS:
      return { ...state, loading: false };

    case Types.EDIT_REQUEST:
      return { ...state, loading: true };

    case Types.EDIT_SUCCESS:
      return { ...state, loading: false };

    case Types.REMOVE_REQUEST:
      return { ...state, loading: true };

    case Types.REMOVE_SUCCESS:
      return { ...state, loading: false };

    case Types.LIST_BYCNPJ_REQUEST:
      return { ...state, loading: true };

    case Types.LIST_BYCNPJ_SUCCESS:
      return { ...state, loading: false, byCNPJ: action.payload };

    case Types.GET_REQUEST_REGRAS:
      return { ...state, loading: true };

    case Types.GET_REQUEST_REGRAS_SUCCESS:
      return { ...state, loading: false, regras: action.payload };

    default:
      return state;
  }
}

export const Creators = {
  listRequest: data => ({
    type: Types.LIST_REQUEST,
    payload: { ...data },
  }),

  listSuccess: empresas => ({
    type: Types.LIST_SUCCESS,
    payload: empresas,
  }),

  createRequest: empresa => ({
    type: Types.NEW_REQUEST,
    payload: empresa,
  }),

  createSuccess: () => ({
    type: Types.NEW_SUCCESS,
  }),

  editRequest: empresa => ({
    type: Types.EDIT_REQUEST,
    payload: empresa,
  }),

  editSuccess: () => ({
    type: Types.EDIT_SUCCESS,
  }),

  removeRequest: id => ({
    type: Types.REMOVE_REQUEST,
    payload: id,
  }),

  removeSuccess: () => ({
    type: Types.REMOVE_SUCCESS,
  }),

  listByCNPJRequest: cnpj => ({
    type: Types.LIST_BYCNPJ_REQUEST,
    payload: cnpj,
  }),

  listByCNPJSuccess: empresa => ({
    type: Types.LIST_BYCNPJ_SUCCESS,
    payload: empresa,
  }),

  getRequestRegras: empresa => ({
    type: Types.GET_REQUEST_REGRAS,
    payload: empresa,
  }),

  getRegrasSuccess: regras => ({
    type: Types.GET_REQUEST_REGRAS_SUCCESS,
    payload: regras,
  }),
};
