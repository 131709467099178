import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';

import {
    Container,
    ControlPanel,
    ImageName,
    ViewerContainer,
    ButtonRow,
    Actions,
} from './styles/ModalTaskImages';

import DefaultModal from '../DefaultModal';
import Viewer from "components/SearchViewer/Viewer";
import Loader from "components/Loader";
import CustomButtonIcon from '../CustomButtonIcon';
import RenderInWindow from '../RenderInWindow';

import ProvidenciaImagensService from 'services/ProvidenciaImagensService';
import { Creators as ProvidenciaActions } from 'store/ducks/providencia';

import { getPresignedUrl } from "services/presignedUrl";
import { ChevronLeftIcon, ChevronRightIcon, DeleteForeverIcon, DeleteIcon } from 'components/Icons';

function ModalTaskImages({ open, handleClose }) {
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [images, setImages] = useState([]);
    const [previewURL, setPreviewURL] = useState('');
    const [selectedImage, setSelectedImage] = useState(null);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [openDocInNewWindow, setOpenDocInNewWindow] = useState(false);
    const [docOpenedInNewWindow, setDocOpenedInNewWindow] = useState(false);

    const { organization } = useSelector(state => state.usuarios.auth);
    const { selectedTask } = useSelector(state => state.providencias);

    useEffect(() => {
        async function getImages() {
            const images = await ProvidenciaImagensService.fetchAll(selectedTask._id, organization);
            setImages(images);
            if (images) {
                setSelectedImage(images[0]);
            }
        }
        setLoading(true);
        getImages();
        setLoading(false);

        return () => handleClose();
    }, []);

    useEffect(() => {
        async function signedUrl(selectedImage) {
          setLoading(true);
          const preview = await getPresignedUrl(selectedImage.filepath);
          setPreviewURL(preview);
          setLoading(false);
        }
        if (selectedImage) {
            signedUrl(selectedImage);
        } 
      }, [selectedImage]);

    function handleConfirmDelete() {
        setConfirmDelete(true);
        setTimeout(() => {
            setConfirmDelete(false);
        }, 3000);
    }

    function handleNext() {
        const currentImageIndex = getCurrentImageIndex();
        const nextIndex = currentImageIndex + 1;
        if (images[nextIndex]) {
            setSelectedImage(images[nextIndex]);
        }
    }

    function handlePrevious() {
        const currentImageIndex = getCurrentImageIndex()
        const previousIndex = currentImageIndex - 1;
        if (images[previousIndex]) {
            setSelectedImage(images[previousIndex]);
        }      
    }

    function getCurrentImageIndex() {
        if (images && selectedImage) {
            return images.findIndex(image => image._id === selectedImage._id);
        }
        return false;
    }

    function handleDeleteImage() {
        dispatch(ProvidenciaActions.deleteImageRequest(
            selectedTask._id,
            selectedImage._id,
            images.length,
        ));
    }

    function handleOpenDocumentInNewWindow() {
        if (openDocInNewWindow) {
          setOpenDocInNewWindow(false);
        }
        setOpenDocInNewWindow(true);
        setDocOpenedInNewWindow(previewURL);
      }
    
      function handleCloseDocumentInNewWindow() {
        setOpenDocInNewWindow(false);
      }
        
    return (
        <>
            <DefaultModal
                title="Imagens"
                handleClose={handleClose} 
                confirmButtonText="Fechar"   
                nocancelbutton={true}    
                centerbuttons={true}
                confirmButtonFunction={handleClose}
                open={open}>
                <Container>
                <ControlPanel>
                    <ButtonRow>
                        <Button
                            color="primary"
                            variant="contained"
                            disabled={!images[getCurrentImageIndex() - 1]}
                            startIcon={<ChevronLeftIcon />}
                            onClick={handlePrevious}>
                            Anterior
                        </Button>
                        <Button
                            color="primary"
                            variant="contained"
                            disabled={!images}
                            onClick={handleOpenDocumentInNewWindow}>
                            Abrir em uma nova janela
                        </Button>
                        <Button
                            color="primary"
                            variant="contained"
                            disabled={!images[getCurrentImageIndex() + 1]}
                            endIcon={<ChevronRightIcon />}
                            onClick={handleNext}>
                            Próximo
                        </Button>
                    </ButtonRow>
                    <ButtonRow>
                        <ImageName>Nome do arquivo:&nbsp;<span>{selectedImage && selectedImage.nome}</span></ImageName>
                        <Actions>
                        {!confirmDelete ? (
                            <CustomButtonIcon
                                onClick={handleConfirmDelete}
                                Icon={<DeleteIcon />}
                                tooltip="Excluir imagem" />
                        ) : (
                            <CustomButtonIcon
                                Icon={<DeleteForeverIcon />}
                                onClick={handleDeleteImage}
                                iconbackground="#faaab6"
                                iconcolor="#e03f57"
                                progresscolor="#e03f57"
                                progress
                                tooltip="Clique novamente para confirmar" />
                        )}
                            
                        </Actions>
                    </ButtonRow>
                </ControlPanel>
                <ViewerContainer>
                    {loading ? (
                        <Loader fullscreen={false} size={300}/>
                    ) : ( 
                        previewURL && <Viewer imagem={previewURL} />)}
                </ViewerContainer>
                </Container>
            </DefaultModal>
            {openDocInNewWindow && 
                <RenderInWindow
                    title="Visualizar documento"
                    handleClose={handleCloseDocumentInNewWindow}>
                    <Viewer imagem={docOpenedInNewWindow} />
                </RenderInWindow>}
        </>
    );
}

export default ModalTaskImages;