import React from "react";
import { Modal, Button } from "react-bootstrap";

export default function ModalTimeout({ showModal, handleStay }) {
  return (
    <Modal show={showModal} onHide={handleStay} centered>
      <Modal.Header>
        <Modal.Title>Logout por inatividade</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Se quiser permanecer logado, clique no botão abaixo!
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleStay}>
          Permanecer
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
