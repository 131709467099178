import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Autocomplete } from '@material-ui/lab';
import Skeleton from '@material-ui/lab/Skeleton';
import DefaultInput from 'components/DefaultInput';

import { TextWriter } from '../StepTypesStyle';

import RichTextEditor from '../../Utils/Editor';

import { Creators as TiposImagemActions } from 'store/ducks/tipoimagem';
import {
  ImagesContainer,
  AutoCompleteSection,
  TableImages,
} from '../StepTypesStyle';

export default function ImageStep({ tipoDocumento, onChange, settings }) {
  const dispatch = useDispatch();

  const [imageTypes, setImagesTypes] = useState(settings.imageTypes || []);

  const onEditorStateChange = (content) => {
    onChange(settings, { ...settings, imageTypes, content });
  };

  const { list: listTpImages, loading: loadingTpImages } = useSelector(
    (state) => state.tipoImagem
  );

  useEffect(() => {
    if (imageTypes) {
      onChange(settings, { ...settings, imageTypes });
    }
  }, [imageTypes]);

  useEffect(() => {
    if (tipoDocumento) {
      dispatch(
        TiposImagemActions.listByTipoDocumentoRequest(tipoDocumento._id)
      );
    }
  }, [dispatch, tipoDocumento]);

  return (
    <ImagesContainer>
      <TextWriter>
        <b>Escreva o texto desejado:</b>
        <RichTextEditor
          onEditorStateChange={onEditorStateChange}
          value={settings.content}
        />
      </TextWriter>
      <AutoCompleteSection>
        {loadingTpImages ? (
          <Skeleton variant="rect" width="100%" height={35} />
        ) : (
          <Autocomplete
            className="mb-2"
            options={listTpImages}
            multiple={true}
            getOptionLabel={(option) => option.nome}
            onChange={(e, value) => {
              const updatedValue = value.map(item => ({
                ...item,
                required: true
              }));
              setImagesTypes(updatedValue);
            }}
            getOptionSelected={(option, value) => option._id === value._id}
            defaultValue={listTpImages.filter((item) => {
              return imageTypes.some((i) => i._id === item._id);
            })}
            renderInput={(params) => (
              <DefaultInput
                {...params}
                name="tipoImagem"
                label="Tipo de imagem"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        )}
      </AutoCompleteSection>
      <TableImages>
        <table>
          <thead>
            <tr>
              <th>Nome</th>
              <th>Obrigatório?</th>
            </tr>
          </thead>
          <tbody>
            {imageTypes.length > 0 ? (
              imageTypes.map((item) => (
                <tr key={item._id}>
                  <td>{item.nome}</td>
                  <td>{!!item.required ? "Sim" : "Não"}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={2}>Nenhum tipo de imagem selecionado</td>
              </tr>
            )}
          </tbody>
        </table>
      </TableImages>
    </ImagesContainer>
  );
}
