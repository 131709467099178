import React, { useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Creators as GrupoEmailActions } from "store/ducks/grupoemail";
import { useLocation } from "react-router-dom";

import GridCadastro from "components/GridCadastro";
import TableGrupoEmail from "./TableGrupoEmail";

import _ from "lodash";
import { Button } from "@material-ui/core";
import RemoveMail from "./RemoveMail";
import AddMail from "./AddMail";
export default function GrupoEmails() {
  const { pathname } = useLocation();

  const dispatch = useDispatch();
  const [telaExclusao, setTelaExclusao] = useState(false);
  const [telaAdd, setTelaAdd] = useState(false);
  const dispatchDebounce = useRef(_.debounce(dispatch, 500)).current;

  //#region menu
  const {
    access: { servicos },
  } = useSelector((state) => state.permissoes);
  const userActions = useMemo(() => {
    const find = servicos.find((item) => item.route === pathname);
    if (find !== undefined) return find;
    return [];
  }, [servicos]);

  const hasActionExcluirEmail = useMemo(
    () =>
      userActions.actions &&
      userActions.actions.some((userAct) => userAct.action === "excluiremail"),
    [userActions]
  );
  //#endregion

  function handleClick() {
    setTelaExclusao(true);
  }

  function handleClose() {
    setTelaExclusao(false);
  }

  function handleClickAdd() {
    setTelaAdd(true);
  }

  function handleCloseAdd() {
    setTelaAdd(false);
  }

  return (
    <>
      <GridCadastro
        title="Grupo de e-mail"
        route={"/grupoemail/novo"}
        searchRequest={(value) => {
          dispatchDebounce(
            GrupoEmailActions.listRequest({ page: 1, limit: 10, search: value })
          );
        }}
        titleButtonNovo="Novo Grupo"
        component={
          hasActionExcluirEmail && (
            <>
              <Button className="mr-3" variant="contained" color="primary" onClick={handleClick}>
                Excluir e-mail
              </Button>
              <Button variant="contained" color="primary" onClick={handleClickAdd}>
                Adicionar e-mail
              </Button>
            </>
          )
        }
      >
        <TableGrupoEmail />
      </GridCadastro>
      {telaExclusao && (
        <RemoveMail open={telaExclusao} handleClose={handleClose} />
      )}
      {telaAdd && <AddMail open={telaAdd} handleClose={handleCloseAdd} />}
    </>
  );
}
