import React, { useState } from "react";
import { parseJSON, format } from "date-fns";
import { useSelector } from "react-redux";

import pt from "date-fns/locale/pt-BR";

import {
  Container,
  Wrapper,
  Body,
  Header,
  AuthorName,
  Time,
  Subject,
  Message,
  AuthorAvatar,
  ActionBar,
} from "./styles/SingleMessage";

import ToolTipComponent from "components/Tooltip";
import ActionButton from "../ActionButton";
import Utils from "utils/utils";

function SingleMessage({
  message,
  documentStatus,
  handleShowImagens,
  handleShowUpload,
  handleShowView,
  handleUpload,
}) {
  const [hovered, setHover] = useState(false);
  const userId = useSelector((state) => state.usuarios.auth.id);

  const { autor, conteudo, assunto, createdAt } = message;

  const ownUserMessage = autor._id === userId;
  const currentTime = format(parseJSON(createdAt), "dd/MM/yyyy 'às' HH:mm", {
    locale: pt,
  });

  function getAuthorInitials(name) {
    const [firstName, middleName] = name.split(" ");

    if (firstName && middleName) {
      return `${firstName[0]}${middleName[0]}`;
    } else {
      return name[0];
    }
  }

  function handleMouseEnter() {
    setHover(true);
  }

  function handleMouseLeave() {
    setHover(false);
  }

  return (
    <Container
      ownUserMessage={ownUserMessage}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Wrapper>
        <Body ownUserMessage={ownUserMessage}>
          <Header>
            <AuthorName>{ownUserMessage ? "Você" : autor.nome}</AuthorName>
            <Subject>{assunto}</Subject>
            <Time>{currentTime}</Time>
          </Header>
          {Utils.isHTML(conteudo) ? (
            <div>
              <iframe
                src={`data:text/html;charset=utf-8,${conteudo}`}
                width="100%"
                frameborder="0"
                // maxHeight="800"
                display="block"
                overflow="hidden"
                style={{
                    width: "75ch",
                    maxHeight: 700
                }}
              />
            </div>
          ) : (
            <Message>{conteudo}</Message>
          )}
        </Body>
        {!ownUserMessage && (
          <ToolTipComponent title={autor.nome} placement="top">
            <AuthorAvatar>{getAuthorInitials(autor.nome)}</AuthorAvatar>
          </ToolTipComponent>
        )}
      </Wrapper>

      <ActionBar visible={hovered}>
        <ToolTipComponent title="Visualizar" placement="top">
          <div style={{ marginRight: "8px" }}>
            <ActionButton
              icon="visibility"
              onClick={() => handleShowView(message)}
            />
          </div>
        </ToolTipComponent>
        <ToolTipComponent title="Upload" placement="top">
          <div style={{ marginRight: "8px" }}>
            <ActionButton
              icon="backup"
              disabled={!documentStatus}
              onClick={() => handleShowUpload(message)}
            />
          </div>
        </ToolTipComponent>
        <ToolTipComponent
          title={
            !message.imagens || message.imagens.length === 0
              ? "Não há documentos"
              : "Visualizar documentos"
          }
          placement="top"
        >
          <div style={{ marginRight: "8px" }}>
            <ActionButton
              icon="image"
              onClick={() => handleShowImagens(message.imagens)}
              disabled={!message.imagens || message.imagens.length === 0}
            />
          </div>
        </ToolTipComponent>
      </ActionBar>
    </Container>
  );
}

export default SingleMessage;
