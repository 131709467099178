import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import Button from "@material-ui/core/Button";

import List from "./ListUF";

import "./styles.css";

import { Row, Col } from "react-bootstrap";
import Content from "components/Content";

export default function UF() {
  const { pathname } = useLocation();

  const {
    access: { servicos },
  } = useSelector((state) => state.permissoes);

  const [userActions, setUserActions] = useState([]);

  useEffect(() => {
    let find = servicos.find((item) => item.route === pathname);
    if (find !== undefined) {
      setUserActions(find);
    }
  }, [pathname, servicos]);

  return (
    <Content>
      <Row>
        <Col md="12" className="painel">
          <div className="painel-content">
            <div className="painel-title text-left">
              <div></div>
              {userActions.actions &&
                userActions.actions.map(
                  (userAct, index) =>
                    userAct.action === "criar" && (
                      <Button
                        key={index}
                        component={Link}
                        to={"/configuracao/uf/novo"}
                        variant="contained"
                        color="primary"
                      >
                        Nova UF
                      </Button>
                    )
                )}
            </div>
            <div className="painel-body">
              <List />
            </div>
          </div>
        </Col>
      </Row>
    </Content>
  );
}
