import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Creators as DocSignatureActions } from "store/ducks/docsignature";

import { LoaderContainer, ActionButton } from "./styles/DocSignatureList";
import { MoreIcon } from "components/Icons";
import DocSignatureActionsMenu from "./ActionMenu/index";
import ModalSigners from "./ModalSigners";
import ModalView from "./ModalView";

import Loader from "../Loader";
import "./styles.css";

function DocSignatureList({ documento }) {
  const dispatch = useDispatch();

  const [actionMenuAnchor, setActionMenuAnchor] = useState(null);
  const [openSigners, setOpenSigners] = useState(false);
  const [openDocView, setOpenDocView] = useState(false);
  const [active, setActive] = useState(null);

  const enableViewImagem = !!active?.docbase64;

  function handleOpenActionMenu(e, item) {
    setActionMenuAnchor(e.currentTarget);
    setActive(item);
  }

  function handleCloseActionMenu() {
    setActionMenuAnchor(null);
    setActive(null);
  }

  const { loading, list } = useSelector((state) => state.docsignature);

  useEffect(() => {
    dispatch(DocSignatureActions.listRequest(documento?._id));
  }, [dispatch, documento]);

  return (
    <>
      {!!loading ? (
        <LoaderContainer>
          <Loader fullscreen={false} size={300} />
        </LoaderContainer>
      ) : (
        <>
          {list && list.length > 0 ? (
            list.map((item, index) => (
              <div className="task-list-item" key={index}>
                <div className="task-list-item__header">
                  <div className="task-list-item__header__item">
                    <div className="task-list-item__header_title">
                      Assinatura Eletrônica
                    </div>
                    <div className="task-list-item__header_subtitle">
                      <div className="task-list-item__header_subtitle_item">
                        <span className="task-list-item__header__label">
                          Criação:
                        </span>
                        {item.createdAt}
                      </div>
                      <div className="task-list-item__header_subtitle_item">
                        <span className="task-list-item__header__label">
                          Atualização:
                        </span>
                        {item.updatedAt}
                      </div>
                    </div>
                  </div>
                  <div className="task-list-item__content">
                    <div className="task-list-item__content__participants_status">
                      <div className="task-list-item__content__participants_status_item task-list-item__content__status ">
                        <span className="task-list-item__header__label">
                          {item.status !== "completed" ? (
                            <div className="badge badge-red">{item.status}</div>
                          ) : (
                            <div className="badge badge-green">
                              {item.status}
                            </div>
                          )}
                        </span>
                        <ActionButton
                          aria-label="Abrir menu de ações"
                          className="ml-3"
                          onClick={(event) => handleOpenActionMenu(event, item)}
                        >
                          <MoreIcon />
                        </ActionButton>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div>Nenhuma assinatura eletrônica encontrada</div>
          )}
          <DocSignatureActionsMenu
            anchor={actionMenuAnchor}
            handleClose={handleCloseActionMenu}
            setOpenSigners={setOpenSigners}
            setOpenDocView={setOpenDocView}
            enableViewImagem={enableViewImagem}
          />

          <ModalSigners
            signers={active?.signers}
            open={openSigners}
            setOpenSigners={setOpenSigners}
          />

          <ModalView
            b64={active?.docbase64}
            open={openDocView}
            setOpenDocView={setOpenDocView}
          />
        </>
      )}
    </>
  );
}

export default DocSignatureList;
