import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { trackWindowScroll } from 'react-lazy-load-image-component';
import { Col } from 'react-bootstrap';

import Switch from '@material-ui/core/Switch';
import Tooltip from '../../../components/Tooltip';
import ModalDocSign from '../../../pages/ModalDocSign';
import ActionMenu from './ActionMenu';
import ImageDetailsModal from 'components/ImageDetailsModal';
import ImageChangeTypeModal from 'components/ImageChangeType';
import ImageNewVersionModal from 'components/ImageNewVersion';
import ModalDeleteImage from 'components/ModalDeleteImage';
import ImageVersionsModal from 'components/ImageVersions';
import { MoreIcon } from 'components/Icons';
import { THUMBNAIL_STYLES } from 'constants/thumbnail-icon-styles';
import VisualizeIndexer from './VisualizeIndexer';
import Utils from 'utils/utils';
import { getPresignedUrlBase64 } from 'services/presignedUrl';

function ThumbnailGallery({
  images: imagesLoaded,
  scrollPosition,
  textDocs,
  colSize,
  checkUncheckImage,
  selectedFiles,
  setFileObjectIndex,
  handleShowViewer,
  checkUncheckImageSendEmail,
  selectedDoc,
}) {
  const [image, setImage] = useState(null);
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [openDocSign, setOpenDocSign] = useState(false);
  const [openRemoveImage, setOpenRemoveImage] = useState(false);
  const [openImageDetail, setOpenImageDetail] = useState(false);
  const [openVersionModal, setOpenVersionModal] = useState(false);
  const [openChangeTypeModal, setOpenChangeTypeModal] = useState(false);
  const [openUploadNewVersionModal, setOpenUploadNewVersionModal] = useState(false);
  const [images, setImages] = useState(null);

  const { limitViewFileSize = 0 } = useSelector(state => state.configuracoes.configuracoes);

  useEffect(() => {
    const loaded = imagesLoaded.map(async image => {
      try {
        const { thumbnail } = image;
        const ret = await getPresignedUrlBase64(thumbnail)
          .then(async thumbSignedUrl => ({
            ...image,
            thumbSignedUrl,
          }))
          .catch(err => ({
            ...image,
            thumbSignedUrl: null,
            err,
          }));
        return ret;
      } catch (e) {
        console.log(e);
        return null;
      }
    });

    Promise.allSettled(loaded).then(data => {
      const loaded = [];
      data.forEach(({ value }) => {
        loaded.push(value);
      });
      setImages(loaded);
    });
  }, [imagesLoaded]);

  function handleOpenActionsMenu(event) {
    setMenuAnchor(event.currentTarget);
  }

  function handleCloseActionsMenu() {
    setMenuAnchor(null);
  }

  function handleOpenDocSign() {
    setOpenDocSign(!openDocSign);
  }

  function handleOpenRemoveImage() {
    setOpenRemoveImage(!openRemoveImage);
  }

  function handleOpenImageDetail() {
    setOpenImageDetail(!openImageDetail);
  }

  function handleOpenChangeType() {
    setOpenChangeTypeModal(!openChangeTypeModal);
  }

  function handleOpenNewVersionModal() {
    setOpenUploadNewVersionModal(!openUploadNewVersionModal);
  }

  function handleOpenVersionsModal() {
    setOpenVersionModal(!openVersionModal);
  }

  const importantIndexTitle = 'No Index';

  function thumbnailIconFactory(documentExtension, handleShowViewer, image) {
    const extension = Object.keys(THUMBNAIL_STYLES).includes(documentExtension) ? documentExtension : 'generic';

    const Icon = THUMBNAIL_STYLES[extension].icon;
    const color = THUMBNAIL_STYLES[extension].color;

    const style = {
      fontSize: 125,
      color,
      opacity: 0.4,
    };

    return <Icon style={style} onClick={() => handleShowViewer(image)} />;
  }

  function formatImageTypeName(tipoImagem) {
    if (!tipoImagem) {
      return '';
    }

    const name = tipoImagem.shortname ? tipoImagem.shortname : tipoImagem.nome;
    const parsedName = name.replace(/_/g, ' ');

    return parsedName.length > 18 ? parsedName.substring(0, 12) + '...' : parsedName;
  }

  function handleChange(image) {
    checkUncheckImage(image.filepath);
    checkUncheckImageSendEmail({
      filepath: image.filepath,
      tipoImagem: formatImageTypeName(image.tipoImagem),
      indexadorConteudo: importantIndexTitle,
    });
  }

  return (
    <>
      {menuAnchor && (
        <ActionMenu
          anchor={menuAnchor}
          selectedFiles={selectedFiles}
          handleClose={handleCloseActionsMenu}
          handleOpenDocSign={handleOpenDocSign}
          handleOpenChangeType={handleOpenChangeType}
          handleOpenImageDetail={handleOpenImageDetail}
          handleOpenRemoveImage={handleOpenRemoveImage}
          handleOpenVersionsModal={handleOpenVersionsModal}
          handleOpenNewVersionModal={handleOpenNewVersionModal}
        />
      )}
      {image && openDocSign && (
        <ModalDocSign open={true} image={image} selectedDoc={selectedDoc} handleClose={handleOpenDocSign} />
      )}
      {image && openImageDetail && (
        <ImageDetailsModal
          selectedDoc={selectedDoc}
          open={openImageDetail}
          handleOpenImageDetail={handleOpenImageDetail}
          image={image}
        />
      )}

      {image && openRemoveImage && (
        <ModalDeleteImage
          selectedDoc={selectedDoc}
          open={openRemoveImage}
          handleOpenImageDetail={handleOpenRemoveImage}
          image={image}
        />
      )}

      {image && openUploadNewVersionModal && (
        <ImageNewVersionModal
          image={image}
          selectedDoc={selectedDoc}
          open={openUploadNewVersionModal}
          handleOpenNewVersionModal={handleOpenNewVersionModal}
        />
      )}

      {image && openVersionModal && (
        <ImageVersionsModal
          image={image}
          selectedDoc={selectedDoc}
          open={openVersionModal}
          handleOpenVersionsModal={handleOpenVersionsModal}
        />
      )}

      {image && openChangeTypeModal && (
        <ImageChangeTypeModal
          selectedDoc={selectedDoc}
          open={openChangeTypeModal}
          handleOpenChangeType={handleOpenChangeType}
          image={image}
        />
      )}

      {images &&
        images
          .filter(image => image.size >= limitViewFileSize * 1024)
          .map((image, index) => {
            const { filepath, tipoImagem, thumbnail, thumbSignedUrl, err } = image;
            const realIndex = images.findIndex(img => img.filepath === filepath);
            const textFound = textDocs[realIndex] && textDocs[realIndex].found;
            !err || console.log('erro de visualização de thumbnail', err);
            return (
              <Tooltip title={textFound ? textDocs[realIndex].text : ''} placement="top" arrow key={realIndex}>
                <Col md={colSize ? colSize : 2} className="thumbViewer" key={realIndex}>
                  <div
                    onClick={() => setFileObjectIndex(realIndex)}
                    className={`thumbViewerContainer ${textFound ? 'textFound' : ''}`}
                  >
                    <div className="topThumbViewer">
                      <div className="bottomThumbViewer">
                        <VisualizeIndexer image={image} />
                      </div>
                      <Switch
                        checked={selectedFiles.includes(filepath)}
                        onChange={() => handleChange(image)}
                        name={`doc-check-${realIndex}`}
                        inputProps={{
                          'aria-label': 'primary checkbox',
                        }}
                        size="small"
                        color="primary"
                      />
                      <div>
                        <MoreIcon
                          onClick={e => {
                            handleOpenActionsMenu(e);
                            setImage(image);
                          }}
                        />
                      </div>
                    </div>
                    <div className="bodyThumbViewer">
                      <div className="image">
                        {thumbnail && (
                          <img src={thumbSignedUrl} alt="miniatura" onClick={() => handleShowViewer(image)} />
                        )}

                        {!thumbnail && thumbnailIconFactory(Utils.getURLExtension(filepath), handleShowViewer, image)}
                      </div>
                      <div className="description" onClick={() => handleShowViewer(image)}>
                        {formatImageTypeName(tipoImagem)}
                      </div>
                    </div>
                  </div>
                </Col>
              </Tooltip>
            );
          })}
    </>
  );
}

export default trackWindowScroll(ThumbnailGallery);
