import React, { memo } from 'react';
import TableSortLabel from '@material-ui/core/TableSortLabel';

function CustomTableSortLabel({ currentSort, sortField, children, onClick, ...props }) {

  function handleSortChange() {
    const sort = currentSort === sortField ? `-${sortField}` : sortField;
    onClick(sort);
  }

  return (
      <TableSortLabel
        {...props}
        active={currentSort === sortField || currentSort === `-${sortField}`}
        direction={currentSort === `-${sortField}` ? 'desc' : 'asc'}
        onClick={handleSortChange}
      >
        {children}
      </TableSortLabel>
  );
}

export default memo(CustomTableSortLabel);