import React, { useEffect, useState, useRef, useCallback } from "react";
import { useDispatch } from "react-redux";
import { Grid } from "@material-ui/core";
import { DialogContent } from "@material-ui/core";
import { Modal, DialogTitle } from "./styles";

import DropZone from "components/Dropzone";
import { Row, Col } from "react-bootstrap";
import { Form } from "@unform/web";

import DialogActions from "@material-ui/core/DialogActions";
import ButtonMui from "@material-ui/core/Button";
import { Creators as DocumentosActions } from "store/ducks/documentos";
import { Creators as TiposImagemActions } from "store/ducks/tipoimagem";
import { toastr } from "react-redux-toastr";

function ImageNewVersionModal({
  open,
  image,
  selectedDoc,
  handleOpenNewVersionModal,
}) {
  const dispatch = useDispatch();
  const formRef = useRef();

  const INITIAL_FORM = {
    files: [],
    image: "",
  };


  const [form, setForm] = useState(INITIAL_FORM);

  const onDrop = useCallback(
    (files) => {
      setForm({ ...form, files: files ?? [] });
    },
    [form]
  );

  useEffect(() => {
    if (selectedDoc.idTipoDocumento && selectedDoc.idTipoDocumento._id) {
      dispatch(
        TiposImagemActions.listByTipoDocumentoRequest(
          selectedDoc.idTipoDocumento._id
        )
      );
    }
  }, [selectedDoc, dispatch]);

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const onSubmit = async () => {
    try {
      const { files } = form;

      if (!Array.isArray(files) || files.length !== 1) {
        return toastr.warning(
          "Ops",
          "É necessário selecionar um único arquivo."
        );
      }

      const file = files[0];
      const imageNewVersion = {};
      imageNewVersion.imagem = await toBase64(file);
      imageNewVersion.tipoFile = file.type;
      imageNewVersion.fileName = file.name;
      imageNewVersion.tipoImagem = image.tipoImagem;

      const payload = {
        idDoc: selectedDoc._id,
        currentImage: image._id,
        imagem: [imageNewVersion],
      };

      dispatch(
        DocumentosActions.createUploadVersionRequest({ upload: payload })
      );
    } catch (err) {
      console.log(err.stack);
      return toastr.error("Ops", "Ocorreu um erro.");
    }
  };

  const onDeleteFile = () => {
    setForm({ ...form, files: null });
  };

  return (
    <Modal open={open} className="small-modal">
      <Form onSubmit={onSubmit} ref={formRef}>
        <div className="modal-header">
          <DialogTitle className="modal-title">
            Upload de nova versão da imagem
          </DialogTitle>
        </div>
        <DialogContent>
          <Row className="mt-1">
            <Col md={12} className="m-1">
              <DropZone
                onDrop={onDrop}
                onDeleteFile={onDeleteFile}
                files={form.files}
                message=""
                warning=""
                style={{
                  marginLeft: "2px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignmentItems: "center",
                  width: "100%",
                }}
              />
            </Col>
          </Row>
        </DialogContent>

        <DialogActions>
          <div className="buttons p-3">
            <Grid container spacing={1}>
              <Grid item>
                <ButtonMui
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  Salvar
                </ButtonMui>
              </Grid>
              <Grid item>
                <ButtonMui
                  onClick={() => handleOpenNewVersionModal(false)}
                  variant="contained"
                  color="secondary"
                  type="reset"
                >
                  Cancelar
                </ButtonMui>
              </Grid>
            </Grid>
          </div>
        </DialogActions>
      </Form>
    </Modal>
  );
}

export default ImageNewVersionModal;
