import * as Yup from 'yup';
import Locale from './Locale'
import _ from 'lodash';

Yup.setLocale(Locale);

const requiredFields = [
    "tipoDocumento",
    "empresa",
    "departamento"
];

const stringRequiredFields = [
];

export default Yup.lazy(obj => 
    Yup.object(
        _.mapValues(obj, (value, key) => {
            if (requiredFields.includes(key)) {
                return Yup.mixed().required();
            }
            if (stringRequiredFields.includes(key)) {
                return Yup.string().required();
            }
            return Yup.mixed();
        }),
    )
);
