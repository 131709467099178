import api from "../../services/api";
import { getToken } from "../../services/auth";
import { put, call } from "redux-saga/effects";
import { toastr } from "react-redux-toastr";
import { select } from "redux-saga/effects";

import { push } from "connected-react-router";

import { Creators as EtapasActions } from "../ducks/etapas";

export const getOrganization = (state) => state.usuarios.auth.organization;

export const getUserId = (state) => state.usuarios.auth.id;

export function* listEtapas(action) {
  try {
    const organization = yield select(getOrganization);

    const { fila, empresas } = action.payload;

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const { data } = yield call(
      api.post,
      `etapas`,
      { fila, empresas },
      headerParams
    );

    yield put(EtapasActions.listSuccess(data));
  } catch (error) {
    toastr.error("Ops", "Não foi possível listar as etapas");
  }
}

export function* createEtapas(action) {
  const organization = yield select(getOrganization);

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const { data } = yield call(
      api.post,
      `etapa`,
      action.payload,
      headerParams
    );

    yield put(EtapasActions.createSuccess(data));

    // delete action.payload.nome;
    // delete action.payload.userAcao;

    // yield listEtapas(action);

    yield put(push(`/operacao/esteiraconfig`));

    toastr.success("", "Etapa criada com sucesso.");
  } catch (error) {
    toastr.error("Ops", "Não foi possível criar a etapa.");
  }
}

export function* editEtapa(action) {
  const organization = yield select(getOrganization);

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const { id, fila } = action.payload;

    yield call(api.put, `etapa/${id}`, action.payload, headerParams);

    yield put(EtapasActions.listRequest({ fila: fila }));

    toastr.success("", "Etapa editada com sucesso.");
  } catch (error) {
    toastr.error("Ops", "Não foi possível editar a etapa.");
  }
}

export function* countDocsEtapas(action) {
  const organization = yield select(getOrganization);

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const { data } = yield call(
      api.post,
      `etapas/docs/count`,
      action.payload,
      headerParams
    );

    yield put(EtapasActions.getDocCountSuccess(data));
  } catch (error) {}
}
