import { call, put, select } from "redux-saga/effects";

import { toastr } from "react-redux-toastr";
import { push } from "connected-react-router";

import api from "../../services/api";
import { getToken } from "../../services/auth";

import { Creators as CategoriasActions } from "../ducks/categorias";

export const getOrganization = (state) => state.usuarios.auth.organization;
export const getUserId = (state) => state.usuarios.auth.id;

export function* listCategorias({ payload }) {
    try {
        const organization = yield select(getOrganization);

        const { page, limit, sort, all, search } = payload;

        const headerParams = {
            headers: { Authorization: getToken(), xkeyorg: organization },
            params: { page, limit, sort, all, search },
        };

        const response = yield call(api.get, `categorias`, headerParams);

        const data = {
            total: response.data.total,
            list: response.data.docs,
        };

        yield put(CategoriasActions.listSuccess(data));
    } catch (error) {
        toastr.error("Ops", "Não foi possível listar categorias.");
    }
}

export function* getCategoria(action) {
    try {
        const headerParams = {
            headers: { Authorization: getToken() },
        };

        const { data } = yield call(
            api.get,
            `categorias/${action.payload}`,
            headerParams
        );

        yield put(CategoriasActions.getSuccess(data));
    } catch (error) {
        toastr.error("Ops", "Não foi possível buscar os dados da categoria.");
    }
}

export function* editCategoria({ payload }) {
    const userId = yield select(getUserId);

    try {
        const organization = yield select(getOrganization);

        const headerParams = {
            headers: { Authorization: getToken(), xkeyorg: organization },
        };

        const body = { ...payload, id_user_acao: userId };

        const { data } = yield call(
            api.put,
            `categorias/${payload._id}`,
            body,
            headerParams
        );

        yield put(push(`/administracao/categorias`));

        yield put(CategoriasActions.editSuccess(data));

        if (data.message) toastr.warning(data.message);
        else toastr.success("Categoria editada com sucesso.");

    } catch (error) {
        toastr.error("Ops", "Não foi possível editar categoria.");
    }
}

export function* createCategoria({ payload }) {
    const organization = yield select(getOrganization);

    try {
        const headerParams = {
            headers: { Authorization: getToken(), xkeyorg: organization },
        };

        const { data } = yield call(
            api.post,
            `categorias`,
            payload,
            headerParams
        );

        yield put(push(`/administracao/categorias`));
        yield put(CategoriasActions.createSuccess(data));

        toastr.success("", "Categoria criada com sucesso.");
    } catch (error) {
        toastr.error("Ops", "Não foi possível criar categoria.");
    }
}

