import React from "react";
import { parseISO, format } from "date-fns";

import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import Paper from "@material-ui/core/Paper";

import { DateRangeIcon } from "components/Icons";
import { Typography, makeStyles } from "@material-ui/core";

import "./styles.css";

const styleBox = { overflowY: "auto", margin: "1rem" };

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "6px 16px",
    display: "flex",
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
  content: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
  },
  actions: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const JSONGrid = ({ jsonData }) => {
  const renderValue = (parentKey, value) => {
    if (Array.isArray(value)) {
      return value.map((item, index) => (
        <React.Fragment key={`${parentKey}[${index}]`}>
          {renderValue(`${parentKey}[${index}]`, item)}
        </React.Fragment>
      ));
    } else if (typeof value === "object" && value !== null) {
      return renderTable(value, parentKey);
    } else {
      return (
        <tr key={parentKey}>
          <td>{parentKey}</td>
          <td>{value}</td>
        </tr>
      );
    }
  };

  const renderTable = (data, parentKey = "") => {
    return Object.keys(data).map((key) => {
      const value = data[key];
      const composedKey = parentKey !== "" ? `${parentKey}.${key}` : key;

      return (
        <React.Fragment key={composedKey}>
          {renderValue(composedKey, value)}
        </React.Fragment>
      );
    });
  };

  return (
    <div style={styleBox}>
      <table style={{ backgroundColor: "#red" }}>
        <thead>
          <tr>
            <th>Chave</th>
            <th>Valor</th>
          </tr>
        </thead>
        <tbody>{renderTable(JSON.parse(jsonData))}</tbody>
      </table>
    </div>
  );
};

export default function TimelineAuditoriaFormalizacao({ data }) {
  const classes = useStyles();

  return (
    <TimelineItem className="componente-historico-container">
      <TimelineOppositeContent>
        <Typography variant="body2" color="textSecondary"></Typography>
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineDot />
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent>
        <Paper
          elevation={3}
          className={classes.paper}
          style={{
            marginRight: "20px",
          }}
        >
          <div
            className={classes.content}
            style={{ maxHeight: "310px", overflow: "auto", direction: "rtl" }}
          >
            <Typography variant="h6" component="h1">
              <strong>
                {data?.operacao} de {data?.tipo}
              </strong>
            </Typography>
            <Typography className={classes.dateInfo}>
              <DateRangeIcon
                className="mr-1"
                style={{ fontSize: "14px", color: "#8f8fb1" }}
              />
              <span>
                {data?.createdAt}
              </span>
            </Typography>
            <Typography className={classes.dateInfo}>
              <span>Autor: {data?.autor}</span>
            </Typography>
             {data?.json && <JSONGrid jsonData={data?.json} />}
          </div>
        </Paper>
      </TimelineContent>
    </TimelineItem>
  );
}
