export const Types = {
  LIST_RECLAMACOES_PROCON_REQUEST:
    "reclamacoes/LIST_RECLAMACOES_PROCON_REQUEST",
  LIST_RECLAMACOES_PROCON_SUCCESS:
    "reclamacoes/LIST_RECLAMACOES_PROCON_SUCCESS",
  TOTAL_RECLAMACOES_POR_SINDEC_REQUEST:
    "reclamacoes/TOTAL_RECLAMACOES_POR_SINDEC_REQUEST",
  TOTAL_RECLAMACOES_POR_SINDEC_SUCCESS:
    "reclamacoes/TOTAL_RECLAMACOES_POR_SINDEC_SUCCESS",
  RECLAMACOES_EVOLUCAO_REQUEST: "reclamacoes/RECLAMACOES_EVOLUCAO_REQUEST",
  RECLAMACOES_EVOLUCAO_SUCCESS: "reclamacoes/RECLAMACOES_EVOLUCAO_SUCCESS",
  TOTAL_CIPSNAORESP_REQUEST: "reclamacoes/TOTAL_CIPSNAORESP_REQUEST",
  TOTAL_CIPSNAORESP_SUCCESS: "reclamacoes/TOTAL_CIPSNAORESP_SUCCESS",
  TOTAL_CIPSNAORESP_POR_SINDEC_REQUEST:
    "reclamacoes/TOTAL_CIPSNAORESP_POR_SINDEC_REQUEST",
  TOTAL_CIPSNAORESP_POR_SINDEC_SUCCESS:
    "reclamacoes/TOTAL_CIPSNAORESP_POR_SINDEC_SUCCESS",
  TOTAL_CIPSNAORESP_PRAZO_REQUEST:
    "reclamacoes/TOTAL_CIPSNAORESP_PRAZO_REQUEST",
  TOTAL_CIPSNAORESP_PRAZO_SUCCESS:
    "reclamacoes/TOTAL_CIPSNAORESP_PRAZO_SUCCESS",
  CIPSNAORESP_EVOLUCAO_REQUEST: "reclamacoes/CIPSNAORESP_EVOLUCAO_REQUEST",
  CIPSNAORESP_EVOLUCAO_SUCCESS: "reclamacoes/CIPSNAORESP_EVOLUCAO_SUCCESS",
  TOTAL_CIPSRESP_PRAZO_REQUEST: "reclamacoes/TOTAL_CIPSRESP_PRAZO_REQUEST",
  TOTAL_CIPSRESP_PRAZO_SUCCESS: "reclamacoes/TOTAL_CIPSRESP_PRAZO_SUCCESS",
  ANALITICO_SUCCESS: "reclamacoes/ANALITICO_SUCCESS",
  ANALITICO_CLOSE: "reclamacoes/ANALITICO_CLOSE",
};

const INITIAL_STATE = {
  analitico: {
    list: [],
    show: false,
    title: "",
  },
  reclamacoesProcon: {
    graphic: [],
    list: [],
    loading: false,
  },
  reclamacoesEvolucao: {
    graphic: [],
    list: [],
    loading: false,
  },
  totalRecPorSindec: {
    graphic: [],
    list: [],
    loading: false,
  },
  cipsNaoResp: {
    graphic: [],
    list: [],
    loading: false,
  },
  cipsNaoRespEvolucao: {
    graphic: [],
    list: [],
    loading: false,
  },
  totalCipsNaoRespPorSindec: {
    graphic: [],
    list: [],
    loading: false,
  },
  cipsNaoRespPrazo: {
    graphic: [],
    list: [],
    loading: false,
  },
  cipsRespPrazo: {
    graphic: [],
    list: [],
    loading: false,
  },
};

export default function reclamacoes(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.LIST_RECLAMACOES_PROCON_REQUEST:
      return {
        ...state,
        reclamacoesProcon: {
          list: [],
          graphic: [],
          loading: true,
        },
      };

    case Types.LIST_RECLAMACOES_PROCON_SUCCESS:
      return {
        ...state,
        reclamacoesProcon: {
          list: action.payload.data,
          loading: false,
          graphic: action.payload.graphic,
        },
      };
    case Types.TOTAL_RECLAMACOES_POR_SINDEC_REQUEST:
      return {
        ...state,
        totalRecPorSindec: {
          list: [],
          graphic: [],
          loading: true,
        },
      };

    case Types.TOTAL_RECLAMACOES_POR_SINDEC_SUCCESS:
      return {
        ...state,
        totalRecPorSindec: {
          list: action.payload.data,
          loading: false,
          graphic: action.payload.graphic,
        },
      };
    case Types.RECLAMACOES_EVOLUCAO_REQUEST:
      return {
        ...state,
        reclamacoesEvolucao: {
          ...state.reclamacoesEvolucao,
          graphic: [],
          loading: true,
        },
      };

    case Types.RECLAMACOES_EVOLUCAO_SUCCESS:
      return {
        ...state,
        reclamacoesEvolucao: {
          ...state.reclamacoesEvolucao,
          loading: false,
          graphic: action.payload.graphic,
        },
      };

    case Types.TOTAL_CIPSNAORESP_REQUEST:
      return {
        ...state,
        cipsNaoResp: {
          ...state.cipsNaoResp,
          graphic: [],
          loading: true,
        },
      };

    case Types.TOTAL_CIPSNAORESP_SUCCESS:
      return {
        ...state,
        cipsNaoResp: {
          list: action.payload.data,
          loading: false,
          graphic: action.payload.graphic,
        },
      };
    case Types.TOTAL_CIPSNAORESP_POR_SINDEC_REQUEST:
      return {
        ...state,
        totalCipsNaoRespPorSindec: {
          ...state.totalCipsNaoRespPorSindec,
          graphic: [],
          loading: true,
        },
      };

    case Types.TOTAL_CIPSNAORESP_POR_SINDEC_SUCCESS:
      return {
        ...state,
        totalCipsNaoRespPorSindec: {
          list: action.payload.data,
          loading: false,
          graphic: action.payload.graphic,
        },
      };

    case Types.TOTAL_CIPSNAORESP_PRAZO_REQUEST:
      return {
        ...state,
        cipsNaoRespPrazo: {
          ...state.cipsNaoRespPrazo,
          graphic: [],
          loading: true,
        },
      };

    case Types.TOTAL_CIPSNAORESP_PRAZO_SUCCESS:
      return {
        ...state,
        cipsNaoRespPrazo: {
          list: action.payload.data,
          loading: false,
          graphic: action.payload.graphic,
        },
      };
    case Types.CIPSNAORESP_EVOLUCAO_REQUEST:
      return {
        ...state,
        cipsNaoRespEvolucao: {
          ...state.cipsNaoRespEvolucao,
          graphic: [],
          loading: true,
        },
      };

    case Types.CIPSNAORESP_EVOLUCAO_SUCCESS:
      return {
        ...state,
        cipsNaoRespEvolucao: {
          ...state.cipsNaoRespEvolucao,
          loading: false,
          graphic: action.payload.graphic,
        },
      };

    case Types.TOTAL_CIPSRESP_PRAZO_REQUEST:
      return {
        ...state,
        cipsRespPrazo: {
          graphic: [],
          loading: true,
        },
      };

    case Types.TOTAL_CIPSRESP_PRAZO_SUCCESS:
      return {
        ...state,
        cipsRespPrazo: {
          loading: false,
          graphic: action.payload.graphic,
          list: action.payload.data,
        },
      };

    case Types.ANALITICO_SUCCESS:
      return {
        ...state,
        analitico: {
          list: action.payload.list,
          show: true,
          title: action.payload.title,
        },
      };

    case Types.ANALITICO_CLOSE:
      return {
        ...state,
        analitico: {
          list: [],
          show: false,
          title: "",
        },
      };

    default:
      return state;
  }
}

export const Creators = {
  analiticoSuccess: (list, title) => ({
    type: Types.ANALITICO_SUCCESS,
    payload: { list, title },
  }),
  analiticoClose: () => ({
    type: Types.ANALITICO_CLOSE,
    payload: {},
  }),
  listReclamacoesProconRequest: (data) => ({
    type: Types.LIST_RECLAMACOES_PROCON_REQUEST,
    payload: { filter: { data } },
  }),

  listReclamacoesProconSuccess: (data) => ({
    type: Types.LIST_RECLAMACOES_PROCON_SUCCESS,
    payload: data,
  }),

  reclamacoesEvolucaoRequest: () => ({
    type: Types.RECLAMACOES_EVOLUCAO_REQUEST,
    payload: {},
  }),

  reclamacoesEvolucaoSuccess: (data) => ({
    type: Types.RECLAMACOES_EVOLUCAO_SUCCESS,
    payload: data,
  }),

  totalRecPorSindecRequest: (data) => ({
    type: Types.TOTAL_RECLAMACOES_POR_SINDEC_REQUEST,
    payload: { filter: { data } },
  }),

  totalRecPorSindecSuccess: (data) => ({
    type: Types.TOTAL_RECLAMACOES_POR_SINDEC_SUCCESS,
    payload: data,
  }),

  totalCipsNaoRespPorSindecRequest: (data) => ({
    type: Types.TOTAL_CIPSNAORESP_POR_SINDEC_REQUEST,
    payload: { filter: { data } },
  }),

  totalCipsNaoRespPorSindecSuccess: (data) => ({
    type: Types.TOTAL_CIPSNAORESP_POR_SINDEC_SUCCESS,
    payload: data,
  }),

  totalCipsNaoRespRequest: (data) => ({
    type: Types.TOTAL_CIPSNAORESP_REQUEST,
    payload: { filter: { data } },
  }),

  totalCipsNaoRespSuccess: (data) => ({
    type: Types.TOTAL_CIPSNAORESP_SUCCESS,
    payload: data,
  }),

  cipsNaoRespPrazoRequest: (data) => ({
    type: Types.TOTAL_CIPSNAORESP_PRAZO_REQUEST,
    payload: { filter: { data } },
  }),

  cipsNaoRespPrazoSuccess: (data) => ({
    type: Types.TOTAL_CIPSNAORESP_PRAZO_SUCCESS,
    payload: data,
  }),

  cipsNaoRespEvolucaoRequest: () => ({
    type: Types.CIPSNAORESP_EVOLUCAO_REQUEST,
    payload: {},
  }),

  cipsNaoRespEvolucaoSuccess: (data) => ({
    type: Types.CIPSNAORESP_EVOLUCAO_SUCCESS,
    payload: data,
  }),

  cipsRespPrazoRequest: (data) => ({
    type: Types.TOTAL_CIPSRESP_PRAZO_REQUEST,
    payload: { filter: { data } },
  }),

  cipsRespPrazoSuccess: (data) => ({
    type: Types.TOTAL_CIPSRESP_PRAZO_SUCCESS,
    payload: data,
  }),
};
