import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Grid } from "@material-ui/core";
import { DialogContent } from "@material-ui/core";
import { Modal, DialogTitle } from "./styles/ModalImageDetails";
import { toastr } from "react-redux-toastr";

import ButtonMui from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import Indexadores from "./Indexadores";

import { Creators as DocumentosActions } from "store/ducks/documentos";

function ImageDetailsModal({
  open,
  image,
  handleOpenImageDetail,
  selectedDoc,
}) {
  const dispatch = useDispatch();

  const [indexadores, setIndexadores] = useState(image?.indexadores || []);

  const isDisabled = !indexadores || indexadores?.length === 0;

  function handleChangeIndexador({ target }) {
    const { name, value } = target;
    const newIndexadores = indexadores?.map((indexador) => ({
      ...indexador,
      valor: name === indexador.nome ? value : indexador.valor,
    }));

    setIndexadores(newIndexadores);
  }

  function onSubmit() {
    const body = {
      indexadores,
      image: image._id,
      documentId: selectedDoc._id,
      reloadDoc: true,
    };

    dispatch(DocumentosActions.updateDocIndexImgRequest(body));
  }

  function handleDoubleClick(e) {
    if (!e.target.value) return;
    navigator.clipboard.writeText(e.target.value);
    toastr.info("", "Input copiado em segundo plano");
  }

  return (
    <Modal open={open} className="small-modal">
      <div className="modal-header">
        <DialogTitle className="modal-title">
          Informações do arquivo
        </DialogTitle>
      </div>
      <DialogContent>
        <Indexadores
          handleChangeIndexador={handleChangeIndexador}
          handleDoubleClick={handleDoubleClick}
          indexadores={indexadores}
        />
      </DialogContent>

      <DialogActions>
        <div className="buttons p-3">
          <Grid container spacing={1}>
            <Grid item>
              <ButtonMui
                onClick={() => onSubmit()}
                disabled={isDisabled}
                variant="contained"
                color="primary"
                type="submit"
              >
                Salvar
              </ButtonMui>
            </Grid>
            <Grid item>
              <ButtonMui
                variant="contained"
                color="secondary"
                type="reset"
                onClick={() => handleOpenImageDetail()}
              >
                Cancelar
              </ButtonMui>
            </Grid>
          </Grid>
        </div>
      </DialogActions>
    </Modal>
  );
}

export default ImageDetailsModal;
