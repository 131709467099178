export const Types = {
  NEW_REQUEST: 'assistant/NEW_REQUEST',
  NEW_SUCCESS: 'assistant/NEW_SUCCESS',
  LIST_REQUEST: 'assistant/LIST_REQUEST',
  LIST_SUCCESS: 'assistant/LIST_SUCCESS',
};

const INITIAL_STATE = {
  list: [],
  loading: false,
};

export default function analises(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.NEW_REQUEST:
      return { ...state };

    case Types.NEW_SUCCESS:
      return { ...state };

    case Types.LIST_REQUEST:
      return { ...state, loading: true, list: [] };

    case Types.LIST_SUCCESS:
      return { ...state, loading: false, list: action.payload };

    default:
      return state;
  }
}

export const Creators = {
  createRequest: (message) => ({
    type: Types.NEW_REQUEST,
    payload: message,
  }),

  createSuccess: () => ({
    type: Types.NEW_SUCCESS,
  }),

  listRequest: (payload) => ({
    type: Types.LIST_REQUEST,
    payload,
  }),

  listSuccess: (data) => ({
    type: Types.LIST_SUCCESS,
    payload: data,
  }),
};
