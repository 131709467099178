import api from "services/api";
import { getToken } from "services/auth";
import { call, select, put } from "redux-saga/effects";
import { toastr } from "react-redux-toastr";
import { push } from "connected-react-router";

import { Creators as DashboardExternoActions } from "../ducks/configuracaoDashboardExterno";

export const getOrganization = (state) => state.usuarios.auth.organization;
export const getUserId = (state) => state.usuarios.auth.id;

export function* listConfiguracaoDashboardExterno({ payload }) {
  try {
    const organization = yield select(getOrganization);

    const { page, limit } = payload;

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
      params: { page, limit },
    };

    const response = yield call(api.get, `configuracao/external-dashboard`, headerParams);

    yield put(DashboardExternoActions.listSuccess(response.data || []));
  } catch (error) {
    toastr.error("Ops", "Não foi possível listar as configurações de dashboard externo.");
  }
}

export function* editConfiguracaoDashboardExterno({ payload }) {
  try {
    const organization = yield select(getOrganization);

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const response = yield call(api.post, `configuracao/external-dashboard`, payload, headerParams);

    yield put(DashboardExternoActions.newSuccess(response.data || []));

    yield put(push(`/configuracao/external-dashboard`));
  } catch (error) {
    toastr.error("Ops", "Não foi possível criar ou editar a configuração de dashboard externo.");
  }
}
