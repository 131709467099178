import React, { useState } from 'react';
import { CPF, CNPJ } from "components/Masks";
import TextField from "@material-ui/core/TextField";
import { WrapSensibleFlag } from 'components/SensibleFlag';
import { handlePasteTexts, validContent } from 'utils/utils';

function FieldCNPJOrCPF({ indexador, checkReadOnly, hasIndexadorError, hasIndexadorTextError, handleChangeIndexador }) {
    const [value, setValue] = useState(indexador.valor || "")

    function onChange(event) {
        handleChangeIndexador(event)
        setValue(event?.currentTarget?.value)
    }

    function onPaste() {
        handlePasteTexts((text) => {
            validContent(value, text, indexador.tipo, indexador.nome, (valor) => {
                onChange({ currentTarget: { value: valor } });
            })
        }, value);
    }

    return (
        <WrapSensibleFlag indexador={indexador}>
            <TextField
                disabled={checkReadOnly ? indexador.somenteLeitura : false}
                onPaste={onPaste}
                onChange={onChange}
                label={indexador.nome}
                name={indexador.nome}
                value={value}
                variant="outlined"
                size="small"
                InputProps={{
                    inputComponent: indexador.tipo !== "cpf" ? CNPJ : CPF
                }}
                error={hasIndexadorError(indexador)}
                helperText={hasIndexadorTextError(indexador)}
            />
        </WrapSensibleFlag>
    );
}

export default FieldCNPJOrCPF;