import React from "react";

import { Container } from "./styles/Loader";

import loaderImg from "assets/img/loading.svg";

function Loader({ fullscreen = true, size = 392 }) {
  return (
    <Container fullscreen={fullscreen} size={size}>
      <img src={loaderImg} alt="Carregando..." />
    </Container>
  );
}

export default Loader;
