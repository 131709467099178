import React, { useState, useEffect, useMemo } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TableFooter,
  TablePagination,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import CustomTableSortLabel from "components/CustomTableSortLabel";
import LoadingTable from "components/LoadingTable";
import StyledTableRow from "components/StyledTableRow";
import ButtonSquare from "components/ButtonSquare";

import ToolTipComponent from "components/Tooltip";

import { Creators as ModalidadesActions } from "store/ducks/modalidades";

function TableModalidades({ userActions }) {
  const dispatch = useDispatch();

  const {
    loading,
    list: modalidades,
    page = 1,
    limit,
    total,
    sort = "nome",
  } = useSelector((state) => state.modalidades);

  const [request, setRequest] = useState({ sort, page, limit });

  const hasActions = useMemo(
    () =>
      userActions.actions &&
      userActions.actions.some((userAct) => userAct.action === "editar"),
    [userActions]
  );

  useEffect(() => {
    dispatch(ModalidadesActions.listRequest(request));
  }, [dispatch, request]);

  function handleChangeSort(sort) {
    setRequest({ ...request, sort });
  }

  function handleChangeRowsPerPage({ target }) {
    const { value } = target;
    setRequest({ ...request, limit: value });
  }

  function handleChangePage(e, newPage) {
    setRequest({ ...request, page: newPage + 1 });
  }

  function handleDelete(item) {
    dispatch(ModalidadesActions.deleteRequest(item._id));
  }

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>
              <CustomTableSortLabel
                onClick={handleChangeSort}
                currentSort={request.sort}
                sortField="nome"
              >
                Nome
              </CustomTableSortLabel>
            </TableCell>
            <TableCell>
              <CustomTableSortLabel
                onClick={handleChangeSort}
                currentSort={request.sort}
                sortField="tipoDocumento.nome"
              >
                Tipo de Documento
              </CustomTableSortLabel>
            </TableCell>
            {!!hasActions && <TableCell align="center">Ações</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {!!loading && (
            <LoadingTable rows={request.limit} columns={hasActions ? 3 : 2} />
          )}
          {!loading &&
            modalidades.map((modalidade) => (
              <StyledTableRow key={modalidade._id}>
                <TableCell>{modalidade.nome}</TableCell>
                <TableCell>{modalidade.tipoDocumento.nome}</TableCell>
                {!!hasActions && (
                  <TableCell align="center">
                    <div className="flex-direction">
                      <ToolTipComponent title="Imagens" placement="top">
                        <Link
                          to={{
                            pathname: "modalidades/imagens",
                            state: { modalidade },
                          }}
                        >
                          <ButtonSquare icon="image" />
                        </Link>
                      </ToolTipComponent>
                      <ToolTipComponent title="Editar" placement="top">
                        <Link
                          to={{
                            pathname: "modalidades/edit",
                            state: { modalidade },
                          }}
                        >
                          <ButtonSquare icon="edit" />
                        </Link>
                      </ToolTipComponent>
                      <ToolTipComponent title="Remover" placement="top">
                        <div>
                          <ButtonSquare
                            onClick={() => handleDelete(modalidade)}
                            icon="delete"
                          />
                        </div>
                      </ToolTipComponent>
                    </div>
                  </TableCell>
                )}
              </StyledTableRow>
            ))}
        </TableBody>
        {!loading && (
          <TableFooter>
            <TableRow>
              <TablePagination
                count={total}
                page={request.page - 1}
                rowsPerPage={request.limit}
                rowsPerPageOptions={[10, 25, 50, 100]}
                labelRowsPerPage="Linhas por página"
                labelDisplayedRows={({ from, to, count }) =>
                  `${from}-${to} de ${count !== -1 ? count : `mais que ${to}`}`
                }
                onRowsPerPageChange={handleChangeRowsPerPage}
                onPageChange={handleChangePage}
              />
            </TableRow>
          </TableFooter>
        )}
      </Table>
    </TableContainer>
  );
}

export default TableModalidades;
