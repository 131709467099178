export const Types = {
  LIST_REQUEST: 'separadores/LIST_REQUEST',
  LIST_SUCCESS: 'separadores/LIST_SUCCESS'
}

const INITIAL_STATE = {
  loading: false,
  list: []
};

export default function separadores(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.LIST_REQUEST:
      return { ...state, loading: true, list: [] }

    case Types.LIST_SUCCESS:
      return { ...state, loading: false, list: action.payload }

    default:
      return state
  }
}

export const Creators = {
  listRequest: tipoDocumento => ({
    type: Types.LIST_REQUEST,
    payload: { tipoDocumento }
  }),
  listSuccess: payload => ({
    type: Types.LIST_SUCCESS,
    payload
  })

}