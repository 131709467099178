import React from "react";
import Divider from "@material-ui/core/Divider";

function Separador({ titulo }) {
  return (
    <>
      <small style={{ marginLeft: "0px", textAlign: "start", display: "block" }}>
        {titulo}
      </small>
      <Divider style={{ marginBottom: "1rem" }} />
    </>
  );
}

export default Separador;
