import api from "services/api";
import { getToken } from "services/auth";
import { call, select, put, all, takeLatest } from "redux-saga/effects";
import { toastr } from "react-redux-toastr";

import { Creators as AndamentosActions, Types } from "store/ducks/andamentos";

export const getOrganization = (state) => state.usuarios.auth.organization;

export function* listAndamentos(action) {
  const organization = yield select(getOrganization);

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const { data } = yield call(
      api.get,
      `andamentos/${action.payload.numeroCNJ.replace(/\W/g, "")}`,
      headerParams
    );


    yield put(AndamentosActions.listSuccess(data));
  } catch (err) {
    console.error(err);
    if(err){

      yield put(AndamentosActions.listError());
      // toastr.error("Ops", "Não foi possível listar os andamentos");
    }
  }
}

export function* editAndamentos(action) {
  const organization = yield select(getOrganization);

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };
    const {valueSelected, cnj, idFromcurrentItem} = action.payload
    const body = {
      idFromcurrentItem,
      valueSelected
    }
    const { data } = yield call(
      api.put,
      `andamentos/${cnj.replace(/\W/g, "")}`,
      body,
      headerParams
    );
    yield put(AndamentosActions.listSuccess(data));
  } catch (err) {
    console.error(err);
    yield put(AndamentosActions.listError());
    toastr.error("Ops", "Não foi possível listar os andamentos");
  }
}

export default all([
  takeLatest(Types.LIST_REQUEST, listAndamentos),
  takeLatest(Types.EDIT_REQUEST, editAndamentos),

]);
