import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { Button, Row, Col } from 'react-bootstrap';
import { Button as ButtonMui } from '@material-ui/core';

import './styles.css';

import { Creators as TiposImagemActions } from 'store/ducks/tipoimagem';

import Content from 'components/Content';
import BackButton from 'components/BackButton';
import { Indexadores } from 'components/Indexadores';
import ModalStepsCustomField from 'components/ModalSteps/ModalStepsCustomField';

export default function IndexadoresTipoImagem(props) {
  const dispatch = useDispatch();

  const { TipoImagem } = props.location.state;

  const [indexadores, setIndexadores] = useState([]);
  const [indexador, setIndexador] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [tabela, setTabela] = useState();

  const treeViewRef = useRef(null);

  

  useEffect(
    () => setIndexadores(props.location.state.TipoImagem?.indexadores || []),
    [props]
  );

  const separadores = useMemo(() => {
    const separadores = indexadores.map((idx) => idx.separador);
    return separadores.filter(
      (separador, i) => separadores.indexOf(separador) === i && separador
    );
  }, [indexadores]);

  
  const handleReorder = (result) => {
    console.log(result)
    const { source, destination } = result;

    // Verifica se a movimentação é válida
    if (!destination) {
        window.alert('Item não pode ser solto fora de uma área válida');
        return;
    }

    if (source.index === destination.index) {
        window.alert('Não pode mover item para o mesmo lugar');
        return;
    }


    if (source.droppableId !== destination.droppableId) {
      window.alert('Não pode mover item para outro separador');
      return;
  }

  // Divida os indexadores por separador
  const separadoresMap = {};
  indexadores.forEach((indexador) => {
    const separador = indexador.separador || "Outros indexadores";
    if (!separadoresMap[separador]) {
        separadoresMap[separador] = [];
    }
    separadoresMap[separador].push(indexador);
});

  const reorderedItems = Array.from(separadoresMap[source.droppableId ?? 'Outros indexadores']);

  // Realiza a reordenação dos itens dentro do separador
  const [removed] = reorderedItems.splice(source.index, 1); // Remove o item da posição original
  reorderedItems.splice(destination.index, 0, removed); // Insere na nova posição

  // Substitui os itens do separador reordenado
  separadoresMap[source.droppableId] = reorderedItems;

  // "Flatten" (achatar) a matriz de volta para um array
  const novosIndexadores = Object.values(separadoresMap).flat();

  // Atualiza o estado com o novo array reordenado
  setIndexadores(novosIndexadores);
};


  //#region funções auxiliares
  const handleClose = () => {
    setOpenModal(false);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    dispatch(
      TiposImagemActions.editIndexadoresRequest(TipoImagem._id, indexadores)
    );

    toastr.success('Indexadores atualizados com sucesso!');
  };

  const novoIndexador = async () => {
    const maxValue =
      Math.max(...indexadores.map((indexador) => indexador.index), -1) + 1;

      const indexadoresFiltrados = indexadores.filter(indexador => indexador.separador === '');

      const maxValueOrder =
        indexadoresFiltrados.length > 0
          ? Math.max(...indexadoresFiltrados.map(indexador => indexador.order), -1) + 1 : 0

    const novoIndexador = {
      index: maxValue >= 0 ? maxValue : 0,
      nome: `Novo campo ${maxValue + 1}`,
      nomeOcr: '',
      id_es: '',
      tipo: 'caracter',
      mascara: '',
      limiteCaracteres: '',
      limpar: false,
      obrigatorio: false,
      invisivel: false,
      campoOcr: '',
      somenteLeitura: false,
      separador: '',
      order: maxValueOrder,
      opcoes: [],
      sensivel: false
    };

    const indexadoresAtualizado = [...indexadores, novoIndexador];

    await setIndexadores(indexadoresAtualizado);

    setIndexador(novoIndexador);
    setOpenModal(true);
  };

  const getIndexador = async (indexador, index) => {
    indexadores.index = index;
    setIndexador(indexador);
  };

  const removeIndexador = async (indexador) => {
    const { index: parentIndex, nome } = indexador;
    const toUpdate = indexadores.filter(function (indexador) {
      return indexador.index != parentIndex || indexador.nome != nome;
    });
    setIndexadores(toUpdate);
  };

  const editIndexador = async (indexador) => {
    setIndexador(indexador);
    setOpenModal(true);
  };

  const changeIndexador = (e) => {
    let idxs = [...indexadores];

    const index = idxs.indexOf(indexador);

    const reference = idxs[index];

    if (e.target.type === 'checkbox') {
      reference[e.target.name] = e.target.checked;
    } else {
      if (e.target.name === 'tipo' && e.target.value === 'cpf') {
        reference['mascara'] = '111.111.111-11';
      }
      if (e.target.name === 'tipo' && e.target.value === 'cnpj') {
        reference['mascara'] = '11.111.111/1111-11';
      }

      reference[e.target.name] = e.target.value;
      let clearString = reference.nome
        .toLowerCase()
        .replace(/[^A-Z0-9]+/gi, '_');
      reference.id_es = clearString;
    }

    setIndexadores(idxs);
  };

  const handleChangeSeparador = (e, newValue) => {
    if (!(indexador.index > -1)) return;
    const idxs = [...indexadores];

    const pos = idxs.indexOf(indexador);

    delete idxs[pos]['separador'];

    if (newValue) {
      if (typeof newValue === 'string') {
        idxs[pos]['separador'] = newValue;
      } else {
        idxs[pos]['separador'] = newValue.inputValue;
      }
    }

    if (
      idxs[pos].tipo === 'tabela' &&
      idxs[pos].campos &&
      idxs[pos].campos.length > 0
    ) {
      idxs[pos].campos = idxs[pos].campos.map((campo) => ({
        ...campo,
        separador: newValue?.inputValue || '',
      }));
    }

    setIndexadores(idxs);
  };

  const canSave = () => {
    if (
      indexadores
        .filter((indexador) => indexador.tipo === 'tabela')
        .some((indexador) => !indexador.separador || indexador.separador === '')
    )
      return false;

    return indexador ? true : false;
  };

  const handleChangeOpcoes = (e, newValue) => {
    const idxs = [...indexadores];

    const index = idxs.indexOf(indexador);

    const reference = idxs[index];

    delete reference['opcoes'];

    if (newValue) {
      reference['opcoes'] = newValue.map((opcao) =>
        typeof opcao === 'string' ? opcao : opcao.inputValue
      );
    }

    setIndexadores(idxs);
  };

  const handleChangeObrigatorioEtapa = (e, newValue) => {
    const idxs = [...indexadores];

    delete idxs[idxs.indexOf(indexador)]['obrigatorioEtapa'];
    if (newValue) {
      idxs[idxs.indexOf(indexador)]['obrigatorioEtapa'] = newValue.map(
        (opcao) => (typeof opcao === 'string' ? opcao : opcao.inputValue)
      );
    }

    setIndexadores(idxs);
  };

  const handleChangeMostrarFluxo = (e, newValue) => {
    const idxs = [...indexadores];

    delete idxs[idxs.indexOf(indexador)]['mostrarFluxo'];
    if (newValue) {
      idxs[idxs.indexOf(indexador)]['mostrarFluxo'] = newValue.map((opcao) =>
        typeof opcao === 'string' ? opcao : opcao.fila || opcao.inputValue
      );
    }

    setIndexadores(idxs);
  };
  //#endregion

  return (
    <Content>
      <Row>
        <Col className="painel">
          <div className="painel-content">
            <div className="painel-title text-left">
              Indexadores ({TipoImagem.nome})
              <Button onClick={novoIndexador}>Novo indexador</Button>
            </div>
            <div className="painel-body">
              <Row>
                <Col md="12">
                  <Indexadores
                    separadores={separadores}
                    indexadores={indexadores}
                    treeViewRef={treeViewRef}
                    hasDocument={false}
                    onClick={getIndexador}
                    onClickRemove={removeIndexador}
                    onClickEdit={editIndexador}
                    onAddFieldClick={novoIndexador}
                    onReorder={handleReorder}
                  />
                </Col>
                <Col className="mt-2 mb-2">
                  <ButtonMui
                    variant="contained"
                    color="primary"
                    type="submit"
                    className="mr-2"
                    onClick={(e) => onSubmit(e)}
                    disabled={!canSave()}
                  >
                    Salvar
                  </ButtonMui>
                  <BackButton>Voltar</BackButton>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>

      <ModalStepsCustomField
        open={openModal}
        setOpenModal={setOpenModal}
        handleClose={handleClose}
        changeIndexador={changeIndexador}
        handleChangeOpcoes={handleChangeOpcoes}
        handleChangeSeparador={handleChangeSeparador}
        handleChangeMostrarFluxo={handleChangeMostrarFluxo}
        handleChangeObrigatorioEtapa={handleChangeObrigatorioEtapa}
        separadores={separadores}
        indexadores={indexadores}
        indexador={indexador}
        tabela={tabela}
        setTabela={setTabela}
        isImageTypeIndexor={true}
        tipoImagem={TipoImagem}
      />
    </Content>
  );
}
