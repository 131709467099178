import React from 'react';
import { Badge } from 'react-bootstrap';

export function ExibeFilaEtapa({ fila, etapa }) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignContent: 'center',
        padding: '12px 0',
      }}
    >
      {fila?.length && (
        <div>
          <h6>
            FILA:{' '}
            <Badge variant="primary" style={{ fontSize: '14px' }}>
              {fila}
            </Badge>
          </h6>
        </div>
      )}
      {etapa?.length && (
        <div>
          <h6>
            ETAPA:{' '}
            <Badge variant="warning" style={{ fontSize: '14px' }}>
              {etapa}
            </Badge>
          </h6>
        </div>
      )}
    </div>
  );
}
