import React from 'react';

import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";

const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
        color: theme.palette.common.black,
    },
    tooltip: {
        backgroundColor: theme.palette.common.black,
        fontSize: 12,
    },
}));

function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();

    return <Tooltip arrow classes={classes} {...props} />;
}

export default function ToolTipComponent({ title, placement, children}) {
    return (
        <BootstrapTooltip
            title={title}
            placement={placement}
        >
            {children}
        </BootstrapTooltip>
    );
}