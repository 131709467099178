import React, { useEffect, useRef } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { useDispatch, useSelector } from "react-redux";

import { Creators as MensagemAction } from "store/ducks/mensagem";
import { Creators as UsuariosAction } from "store/ducks/usuarios";
import { Creators as DocumentosActions } from "store/ducks/documentos";
import { Creators as MensagensActions } from "store/ducks/mensagens";

import ComentarioPadrao from "pages/Analise/components/Comentarios/ComentarioPadrao";
import { Button } from "react-bootstrap";

import _ from "@lodash";

function FormMensagem({ documento }) {
  const dispatch = useDispatch();
  const dispatchDebounce = useRef(_.debounce(dispatch, 500)).current;

  const { open, loading } = useSelector((state) => state.mensagem);

  const {
    list: usuarios,
    loading: loadingUsuarios,
    limit = 1000,
    page = 1,
    sort = "nome",
  } = useSelector((state) => state.usuarios);

  const {
    search = "",
    page: pgmsg,
    sort: srtmsg,
    endpoint,
    list: messages,
    authorList: authorFilter,
  } = useSelector((state) => state.mensagens);

  function handleClose() {
    if (!loading) dispatch(MensagemAction.cancel());
    dispatch(DocumentosActions.createSuccess());
    dispatch(MensagensActions.listInitialize());
    dispatchDebounce(
      MensagensActions.listRequest({
        documento: documento._id,
        page: pgmsg,
        limit: Number.MAX_SAFE_INTEGER,
        sort: srtmsg,
        search,
        endpoint,
      })
      )
  }

  useEffect(() => {
    dispatch(UsuariosAction.listRequest({ limit, page, sort }));
  }, [loading]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogContent>
        <ComentarioPadrao
          documento={documento}
          opcaoUnica="Comentário"
          handleClose={handleClose}
          closeButton={
            <Button
              variant="info"
              color="secondary"
              onClick={handleClose}
              disabled={!!loading}
            >
              Cancelar
            </Button>
          } />
      </DialogContent>
    </Dialog>
  );
}

export default FormMensagem;
