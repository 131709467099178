import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'connected-react-router';
import storage from 'redux-persist/lib/storage';
import { composeWithDevTools } from 'redux-devtools-extension/logOnly';

import history from '../routes/history';
import rootReducer from './ducks';
import rootSaga from './sagas';

import { AUTH_KEYS } from '../services/auth';

localStorage.removeItem(AUTH_KEYS.STATE);

const sagaMiddleware = createSagaMiddleware();

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [
    'auth',
    'usuarios',
    'permissoes',
    'empresas',
    'analise',
    'empresa',
    'translate',
    'checkTipos',
    'template',
    'configuracoes',
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer(history));

const store = createStore(
  persistedReducer,
  /* preloadedState, */ composeWithDevTools(applyMiddleware(sagaMiddleware, routerMiddleware(history)))
);

const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

export { store, persistor };
