export const Types = {
  LIST_REQUEST: "andamentos/LIST_REQUEST",
  EDIT_REQUEST: "andamentos/EDIT_REQUEST",
  LIST_SUCCESS: "andamentos/LIST_SUCCESS",
  LIST_ERROR: "andamentos/LIST_ERROR",
  SHOW_MODAL: 'andamentos/SHOW_MODAL'
};

const INITIAL_STATE = {
  list: [],
  loading: false,
  showModalToEditNature: false
};

export default function andamentos(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.LIST_REQUEST:
      return { ...state, loading: true };
 
      case Types.EDIT_REQUEST:
      return { ...state, loading: true };

    case Types.LIST_SUCCESS:
      return { ...state, loading: false, list: action.payload.list };

    case Types.LIST_ERROR:
      return { ...state, loading: false };

    case Types.SHOW_MODAL:
      return { ...state, showModalToEditNature: action.payload };

    default:
      return state;
  }
}

export const Creators = {
  listRequest: (numeroCNJ) => ({
    type: Types.LIST_REQUEST,
    payload: { numeroCNJ },
  }),
  editRequest: (payload) => ({
    type: Types.EDIT_REQUEST,
    payload
  }),
  listSuccess: (list) => ({
    type: Types.LIST_SUCCESS,
    payload: { list },
  }),
  listError: () => ({
    type: Types.LIST_ERROR,
  }),

  showModal: payload => ({
    type: Types.SHOW_MODAL,
    payload
  }),
};
