import React, { useRef } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Form } from '@unform/web';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { ValidationError } from 'yup';
import { useDispatch } from "react-redux";
import InputForm from 'components/InputForm';
import BackButton from 'components/BackButton';

import UnidadeSchema from 'validators/UnidadeSchema';

import { Creators as UnidadesActions } from 'store/ducks/unidades';
import Content from 'components/Content';

function FormUnidade({ location }) {
  const dispatch = useDispatch();
  const formRef = useRef();

  const { unidade } = location.state || {};

  async function handleSubmit(data) {
    try {
      await UnidadeSchema.validate(data, {
        abortEarly: false,
      });

      const body = {
        ...data,
        _id: unidade ? unidade._id : null
      }

      dispatch(UnidadesActions.saveRequest(body));

    } catch (err) {
      const validationErrors = {};
      if (err instanceof ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });

        formRef.current.setErrors(validationErrors);
      }
    }
  }

  return (
    <Content>
      <Row>
        <Col md="12" className="painel">
          <div className="painel-content">
            <div className="painel-title text-left">Unidade</div>
            <div className="painel-body">
              <Form
                ref={formRef}
                initialData={unidade}
                onSubmit={handleSubmit}
              >
                <Row className="row">
                  <Col>
                    <InputForm name="tipo" label="Tipo de unidade" />
                  </Col>
                  <Col>
                    <InputForm name="descricao" label="Descrição" />
                  </Col>
                </Row>
                <Row className="row">
                  <Col>
                    <InputForm name="centroDeCusto" label="Centro de Custo" />
                  </Col>
                  <Col>
                    <InputForm name="email" label="Email" type="email" />
                  </Col>
                </Row>
                <Grid container spacing={1}>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                    >
                      Enviar
                    </Button>
                  </Grid>
                  <Grid item>
                    <BackButton variant="contained" color="secondary">
                      Voltar
                    </BackButton>
                  </Grid>
                </Grid>
              </Form>
            </div>
          </div>
        </Col>
      </Row>
    </Content>
  );
}

export default FormUnidade;