import React from "react";

import {
  Container,
  Title,
  Content,
  Actions,
  Button,
} from "./styles/DefaultModal";

import Step from "@material-ui/core/Step";
import Stepper from "@material-ui/core/Stepper";
import StepLabel from "@material-ui/core/StepLabel";

import ButtonCloseModal from "../ButtonCloseModal";

function StepsModal({
  open,
  handleClose,
  title,
  subtitle,
  noActions,
  nextButtonText = "Avançar",
  confirmButtonFunction,
  children,
  steps = [],
  activeStep = 0,
  disabled = false,
}) {
  const titleStyle = {
    color: "black",
    fontWeight: "600",
    fontSize: "1em",
  };

  const subtitleStyle = {
    color: "#77777c",
    fontSize: "smaller",
  };

  return (
    <Container open={open}>
      {handleClose && <ButtonCloseModal handleClose={handleClose} />}
      <Title>
        <div style={titleStyle}>{title}</div>
        <div style={subtitleStyle}>{subtitle}</div>
      </Title>
      <Content>{children}</Content>
      <Stepper alternativeLabel activeStep={activeStep}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {!noActions && (
        <Actions>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleClose}>
            Fechar
          </Button>
          <Button
            disabled={disabled}
            variant="contained"
            color="primary"
            onClick={confirmButtonFunction}
          >
            {nextButtonText}
          </Button>
        </Actions>
      )}
    </Container>
  );
}

export default StepsModal;
