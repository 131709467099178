import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';

import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Skeleton from '@material-ui/lab/Skeleton';

import { useDropzone } from 'react-dropzone';

import Content from 'components/Content';
import FileDropUploader from 'components/FileDropUploader';
import { Filter, Buttons } from './styles';

import { Creators as TiposDocumentoActions } from 'store/ducks/tiposdocumento';
import { Creators as TiposImagemActions } from '../../store/ducks/tipoimagem';
import { Creators as BulkImportActions } from '../../store/ducks/bulkimport';
import DropZone from 'components/Dropzone';

export default function BulkImport() {
  const dispatch = useDispatch();

  const [imagens, setImagens] = useState([]);
  const [tipoDocumento, setTipoDocumento] = useState();
  const [tipoImagem, setTipoImagem] = useState();
  const [loading, setLoading] = useState(false);

  const { listByEmpresas: tipoDocList, loading: loadingTipoDoc } = useSelector(
    (state) => state.tipoDocumentos
  );

  const { list: tiposimagem, loading: loadingTipoImgs } = useSelector(
    (state) => state.tipoImagem
  );

  const { loading: loadingBulkImport } = useSelector(
    (state) => state.bulkimport
  );

  const {
    access: { empresas },
  } = useSelector((state) => state.permissoes);

  useEffect(() => {
    dispatch(TiposDocumentoActions.listByEmpresaRequest(empresas));
  }, [empresas]);

  useEffect(() => {
    if (tipoDocumento && tipoDocumento._id) {
      dispatch(
        TiposImagemActions.listByTipoDocumentoRequest(tipoDocumento._id)
      );
    }
  }, [dispatch, tipoDocumento]);

  function handleTipoImagem(tipoImg) {
    setTipoImagem(tipoImg);
  }

  function handleTipoDocumento(tipoDoc) {
    setTipoDocumento(tipoDoc);
  }

  const asyncForEach = async (array, callback) => {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  async function handleFiles(files) {
    await asyncForEach(files, async (file) => {
      const base64 = await toBase64(file);

      const imagem = {
        imagem: base64,
        tipoImagem: tipoImagem._id,
        tipoFile: file.type,
        fileName: file.name,
      };

      setImagens((prev) => [...prev, imagem]);
    });
  }

  async function handleUpload() {
    dispatch(BulkImportActions.createRequest(imagens));
  }

  function handleClear() {}

  return (
    <Content>
      <Row>
        <Col md="12" className="painel">
          <div className="painel-content">
            <div className="painel-title text-left">
              <Col>
                <strong>Importação de lote de documentos</strong>
              </Col>
            </div>
            <div className="painel-body" style={{ padding: '1.6rem' }}>
              <Filter>
                <Row>
                  <Col md={6}>
                    {loadingTipoDoc ? (
                      <Skeleton width="100%" height={70} />
                    ) : (
                      <Autocomplete
                        options={tipoDocList || []}
                        onChange={(event, newValue) =>
                          handleTipoDocumento(newValue)
                        }
                        getOptionLabel={(option) => option.nome || ''}
                        getOptionSelected={(option, value) =>
                          option?._id === value?._id
                        }
                        name="tipoDoc"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Tipo de Documento"
                            size="small"
                            variant="outlined"
                            className="mb-4"
                          />
                        )}
                      />
                    )}
                  </Col>
                  <Col md={6}>
                    {loadingTipoImgs ? (
                      <Skeleton width="100%" height={50} />
                    ) : (
                      <Autocomplete
                        id="combo-box-filas"
                        getOptionLabel={(option) => option.nome}
                        options={tiposimagem.sort(
                          (a, b) => -b.nome.localeCompare(a.nome)
                        )}
                        noOptionsText="Não há tipos de imagem disponíveis"
                        getOptionSelected={(option, value) =>
                          option._id === value._id
                        }
                        onChange={(e, value) => handleTipoImagem(value)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Selecione o tipo de imagem"
                            variant="outlined"
                          />
                        )}
                        size="small"
                      />
                    )}
                  </Col>
                </Row>
              </Filter>

              <DropZone onDrop={handleFiles} />

              <Buttons>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleUpload}
                  disabled={loadingBulkImport}
                >
                  {!!loadingBulkImport ? (
                    <CircularProgress size={20} />
                  ) : (
                    'Enviar'
                  )}
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleClear}
                >
                  Limpar
                </Button>
              </Buttons>
            </div>
          </div>
        </Col>
      </Row>
    </Content>
  );
}
