import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useField } from '@unform/core';
import _ from 'lodash';

import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';

import {
  OptionBox,
  OptionHeader,
  OptionBody,
  OptionDetails,
  OptionDetailsLabel,
} from './styles/CNJSearcher';

import { Creators as DocumentosActions } from 'store/ducks/documentos';

function CNJSearcher({ name, setCNJ, CNJ, disabled }) {
  const dispatch = useDispatch();
  const dispatchDebounce = useRef(_.debounce(dispatch, 300)).current;
  const inputRef = useRef(null);
  const options = useSelector(
    (state) => state.documentos.autocomplete.resultSearch
  );

  const [open, setOpen] = useState(false);
  const loading = open && options && options.length === 0;

  const { fieldName, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
    });
  }, [registerField, fieldName]);

  function handleInputChange(event) {
    if (!event) {
      return;
    }
    const { value } = event.target;
    dispatchDebounce(DocumentosActions.autoCompleteRequest(value, null));
  }

  function handleGetOptionLabel(option) {
    if (disabled) {
      return CNJ ? CNJ.numeroCNJ : '';
    }
    return option._source ? option._source.numeroCNJ : '';
  }

  function handleRenderOption(option) {
    const { empresa, instancia, tipodocumento, tribunal, comarca, numeroCNJ } =
      option._source;

    return (
      <OptionBox>
        <OptionHeader>{numeroCNJ}</OptionHeader>
        <OptionBody>
          <OptionDetailsLabel>Empresa:</OptionDetailsLabel>
          <span>{empresa}</span>
        </OptionBody>
        <OptionDetails>
          <div>
            <OptionDetailsLabel>Comarca:</OptionDetailsLabel>
            <span>{comarca}</span>
          </div>
          <div>
            <OptionDetailsLabel>Tipo:</OptionDetailsLabel>
            <span>{tipodocumento}</span>
          </div>
          <div>
            <span>{instancia}</span>
            <span>{tribunal}</span>
          </div>
        </OptionDetails>
      </OptionBox>
    );
  }

  return (
    <>
      <Autocomplete
        disabled={disabled}
        options={options ? options : []}
        loading={loading}
        value={disabled ? '' : CNJ}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        renderOption={handleRenderOption}
        getOptionLabel={handleGetOptionLabel}
        onInputChange={handleInputChange}
        onChange={(event, newValue) => {
          setCNJ(newValue);
        }}
        getOptionSelected={(option, value) =>
          option.numeroCNJ === value.numeroCNJ
        }
        renderInput={(params) => (
          <TextField
            {...params}
            name={name}
            ref={inputRef}
            label="Número do cliente"
            error={!disabled && !!error}
            helperText={!disabled && error}
            size="small"
            variant="outlined"
            required
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </>
  );
}

export default CNJSearcher;
