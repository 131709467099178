import { v4 as uuidv4 } from "uuid";

export const Types = {
  SAVE_PARAGRAPH: "template/SAVE_PARAGRAPH",
  DELETE_PARAGRAPH: "template/DELETE_PARAGRAPH",
  ADD_VARIABLE: "template/ADD_VARIABLE",
  MOVE_PARAGRAPH_TO_TEMPLATE: "template/MOVE_PARAGRAPH_TO_TEMPLATE",
  EDIT_PARAGRAPH_TEMPLATE: "template/UPDATE_PARAGRAPH_TEMPLATE",
  UPDATE_PARAGRAPH_TEMPLATE_LIST: "template/UPDATE_PARAGRAPH_TEMPLATE_LIST",
  DELETE_PARAGRAPH_TEMPLATE: "template/DELETE_PARAGRAPH_TEMPLATE",
  ADD_HEADER: "template/ADD_HEADER",
  ADD_FOOTER: "template/ADD_FOOTER",
  DELETE_HEADER: "template/DELETE_HEADER",
  DELETE_FOOTER: "template/DELETE_FOOTER",
  CLEAR_SELECTED_TEMPLATE: "template/CLEAR_SELECTED_TEMPLATE",
  CURRENT_EDITOR: "template/CURRENT_EDITOR",
  SAVE_TEMPLATE: "template/SAVE_TEMPLATE",
  REQUEST_SUCCESS: "template/REQUEST_SUCCESS",
  SHOW_MODAL: "template/SHOW_MODAL",
  GET_TEMPLATE: "template/GET_TEMPLATE",
  GET_TEMPLATE_SUCCESS: "template/GET_TEMPLATE_SUCCESS",
  SELECT_TEMPLATE_TO_EDIT: "template/SELECT_TEMPLATE_TO_EDIT",
  EDIT_TEMPLATE_REQUEST: "template/EDIT_TEMPLATE_REQUEST",
  DELETE_REQUEST: "template/DELETE_REQUEST",
  DELETE_SUCCESS: "template/DELETE_SUCCESS",
  DELETE_FAILURE: "template/DELETE_FAILURE",
  OPEN_DELETE: "template/OPEN_DELETE",
  CLOSE_DELETE: "template/CLOSE_DELETE",
};

const INITIAL_STATE = {
  loading: false,
  loadingGetTemplate: false,
  paragraph: [],
  header: "",
  footer: "",
  paragraphTemplate: [],
  variable: [],
  currentEditor: "",
  showModalFooter: false,
  showModalHeader: false,
  showModalParagraph: false,
  showModalSignaturePoints: false,
  template: [],
  templateSelected: {},
  openDelete: false,
};

export default function template(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.SAVE_PARAGRAPH:
      return {
        ...state,
        paragraph: [...state.paragraph, action.payload],
      };

    case Types.DELETE_PARAGRAPH:
      return {
        ...state,
        paragraph: [
          ...state.paragraph.filter((item) => item.id !== action.payload),
        ],
        paragraphTemplate: [
          ...state.paragraphTemplate.filter(
            (item) => item.id !== action.payload
          ),
        ],
      };

    case Types.MOVE_PARAGRAPH_TO_TEMPLATE:
      return {
        ...state,
        paragraphTemplate: action.payload,
      };

    case Types.EDIT_PARAGRAPH_TEMPLATE:
      return {
        ...state,
        paragraphTemplate: state.paragraphTemplate.map((item) =>
          item.id_editor == action.payload.id_editor
            ? { ...item, paragraph: action.payload.currentContent }
            : item
        ),
        paragraph: state.paragraph.map((item) =>
          item.id == action.payload.id
            ? { ...item, paragraph: action.payload.currentContent }
            : item
        ),
        variable: "",
      };

    case Types.UPDATE_PARAGRAPH_TEMPLATE_LIST:
      return {
        ...state,
        paragraphTemplate: action.payload,
      };

    case Types.DELETE_PARAGRAPH_TEMPLATE:
      return {
        ...state,
        paragraphTemplate: [
          ...state.paragraphTemplate.filter(
            (item) => item.id_editor !== action.payload
          ),
        ],
      };

    case Types.ADD_HEADER:
      return {
        ...state,
        header: action.payload,
        variable: "",
      };

    case Types.DELETE_HEADER:
      return {
        ...state,
        header: "",
        fileHeader: "",
      };

      case Types.CLEAR_SELECTED_TEMPLATE:
        return {
          ...state,
          templateSelected: {}
        };

    case Types.ADD_FOOTER:
      return {
        ...state,
        footer: action.payload,
        variable: "",
      };

    case Types.DELETE_FOOTER:
      return {
        ...state,
        footer: "",
        fileFooter: "",
      };

    case Types.ADD_VARIABLE:
      return {
        ...state,
        variable: action.payload.key,
      };

    case Types.CURRENT_EDITOR:
      return {
        ...state,
        currentEditor: action.payload,
      };

    case Types.SAVE_TEMPLATE:
      return {
        ...state,
        loading: true,
      };

    case Types.REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case Types.SHOW_MODAL:
      return {
        ...state,
        showModalFooter:
          action.payload.modal === "FOOTER" ? action.payload.show : false,
        showModalHeader:
          action.payload.modal === "HEADER" ? action.payload.show : false,
        showModalParagraph:
          action.payload.modal === "PARAGRAPH" ? action.payload.show : false,
        showModalSignaturePoints:
          action.payload.modal === "SIGNATURE" ? action.payload.show : false,
      };
    case Types.GET_TEMPLATE:
      return {
        ...state,
        loadingGetTemplate: true,
      };
    case Types.GET_TEMPLATE_SUCCESS:
      return {
        ...state,
        loadingGetTemplate: false,
        template: action.payload,
      };

    case Types.SELECT_TEMPLATE_TO_EDIT:
      const template = state.template.filter(
        (item) => item._id === action.payload
      );
      let hasParagraphToEdit = [];
      let headerToEdit = "";
      let footerToEdit = "";
      const cleanPageEditor =
        (template || []).length === 0
          ? []
          : template[0].paragraphTemplate && [...template[0].paragraphTemplate];

      if (!!template.length) {
        hasParagraphToEdit = template[0].paragraphTemplate && [
          ...template[0].paragraphTemplate,
        ];
        headerToEdit = template[0].header && template[0].header;
        footerToEdit = template[0].footer && template[0].footer;
      }

      return {
        ...state,
        templateSelected: template[0] || {},
        paragraph: hasParagraphToEdit,
        paragraphTemplate: cleanPageEditor,
        header: { currentContent: headerToEdit, id_editor: uuidv4() },
        footer: { currentContent: footerToEdit, id_editor: uuidv4() },
      };

    case Types.EDIT_TEMPLATE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case Types.DELETE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case Types.DELETE_FAILURE:
      return {
        ...state,
        loading: false,
        openDelete: false,
        templateToDelete: null,
      };

    case Types.DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        openDelete: false,
        templateToDelete: null,
      };

    case Types.OPEN_DELETE:
      return {
        ...state,
        openDelete: true,
        templateToDelete: action.payload,
      };

    case Types.CLOSE_DELETE:
      return {
        ...state,
        openDelete: false,
        templateToDelete: null,
      };
    default:
      return state;
  }
}

export const Creators = {
  deleteRequest: (data) => ({
    type: Types.DELETE_REQUEST,
    payload: data,
  }),

  deleteSuccess: () => ({
    type: Types.DELETE_SUCCESS,
    payload: {},
  }),

  deleteFailure: () => ({
    type: Types.DELETE_FAILURE,
    payload: {},
  }),
  saveParagraph: (payload) => ({
    type: Types.SAVE_PARAGRAPH,
    payload,
  }),
  deleteParagraph: (payload) => ({
    type: Types.DELETE_PARAGRAPH,
    payload,
  }),
  moveParagraphToTemplate: (payload) => ({
    type: Types.MOVE_PARAGRAPH_TO_TEMPLATE,
    payload,
  }),
  editParagraphTemplate: (payload) => ({
    type: Types.EDIT_PARAGRAPH_TEMPLATE,
    payload,
  }),
  updateParagraphTemplateList: (payload) => ({
    type: Types.UPDATE_PARAGRAPH_TEMPLATE_LIST,
    payload,
  }),
  deleteParagraphTemplate: (payload) => ({
    type: Types.DELETE_PARAGRAPH_TEMPLATE,
    payload: payload,
  }),
  addHeader: (payload) => ({
    type: Types.ADD_HEADER,
    payload,
  }),
  addFooter: (payload) => ({
    type: Types.ADD_FOOTER,
    payload,
  }),
  deleteHeader: () => ({
    type: Types.DELETE_HEADER,
  }),
  clearSelectedTemplate: () => ({
    type: Types.CLEAR_SELECTED_TEMPLATE,
  }),
  deleteFooter: () => ({
    type: Types.DELETE_FOOTER,
  }),
  addVariable: (payload) => ({
    type: Types.ADD_VARIABLE,
    payload,
  }),
  currenteEditor: (payload) => ({
    type: Types.CURRENT_EDITOR,
    payload,
  }),
  saveTemplate: (payload) => ({
    type: Types.SAVE_TEMPLATE,
    payload,
  }),
  requestSuccess: (payload) => ({
    type: Types.REQUEST_SUCCESS,
    payload,
  }),
  showModal: (payload) => ({
    type: Types.SHOW_MODAL,
    payload,
  }),
  getTemplate: (payload) => ({
    type: Types.GET_TEMPLATE,
    payload,
  }),
  getTemplateSuccess: (payload) => ({
    type: Types.GET_TEMPLATE_SUCCESS,
    payload,
  }),
  selectTemplateToEdit: (payload) => ({
    type: Types.SELECT_TEMPLATE_TO_EDIT,
    payload,
  }),
  editTemplateRequest: (payload) => ({
    type: Types.EDIT_TEMPLATE_REQUEST,
    payload,
  }),
  openDelete: (template) => ({
    type: Types.OPEN_DELETE,
    payload: { template },
  }),
  closeDelete: () => ({
    type: Types.CLOSE_DELETE,
    payload: {},
  }),
};
