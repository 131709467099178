import { call, put, select } from "redux-saga/effects";

import { toastr } from "react-redux-toastr";
import api from "../../services/api";
import { getToken } from "../../services/auth";

import { Creators as VersionsImageActions } from "../ducks/versionsimage";

export const getOrganization = (state) => state.usuarios.auth.organization;
export const getUserId = (state) => state.usuarios.auth.id;

export function* getVersionsImage(action) {
  try {
    const organization = yield select(getOrganization);

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const { documento, imagem } = action.payload;

    const { data } = yield call(
      api.get,
      `images-versions/documento/${documento}/imagem/${imagem}`,
      headerParams
    );

    yield put(VersionsImageActions.listSuccess(data));
  } catch (error) {
    toastr.error("Ops", "Não foi possível listar as versões de imagem");
  }
}
