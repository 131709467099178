import React, { useState } from "react";
import { Button, Typography } from "@material-ui/core";
import { CheckIcon } from "components/Icons";

export function VerticalLinearStepper({
    steps,
    atualActiveStep = 0,
    message,
    title,
    onSend = null,
}) {
    const [activeStep, setActiveStep] = useState(atualActiveStep);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    return (
        <>
            <h2>{title}</h2>

            <div style={{ width: '100%', marginTop: 28 }} class="wizard clearfix vertical">

                <div className="steps clearfix">
                    <ul role="tablist">
                        {steps.map(({ label }, index) => {
                            const className = `${activeStep === index ? 'current' : activeStep > index ? 'passed' : 'disabled'}`;
                            return (
                                <li role="tab" className={className}>
                                    <a>
                                        <span className="number">{className === 'passed' ? <CheckIcon /> : index + 1}</span>{' '}
                                        <span className="title">{label}</span>
                                    </a>
                                </li>
                            )
                        })}
                    </ul>
                </div>

                <div className="content clearfix">

                    {steps.map(({ component }, index) => (
                        <section id="wizard3-p-0" role="tabpanel" aria-labelledby="wizard3-h-0" className="body current" hidden={activeStep !== index}>
                            {component}
                        </section>
                    ))}

                    {
                        activeStep === steps.length && (
                            <section id="wizard3-p-0" role="tabpanel" aria-labelledby="wizard3-h-0" className="body current">
                                <Typography style={{ marginBottom: '16px' }}>
                                    {message}
                                </Typography>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}>
                                    <Button variant="contained" color="primary" onClick={onSend ? onSend : handleBack}>
                                        {onSend ? 'Enviar' : 'Voltar'}
                                    </Button>
                                </div>
                            </section>
                        )
                    }

                    <div className="actions clearfix" hidden={activeStep === steps.length}>
                        <div className="nav-buttons">
                            <Button
                                className="nav-button"
                                color="primary"
                                variant="contained"
                                onClick={handleNext}
                                sx={{ mt: 1, mr: 1 }}
                            >
                                {activeStep === steps.length - 1 ? "Finalizar" : "Avançar"}
                            </Button>
                            <Button
                                className="nav-button"
                                color="secondary"
                                variant="contained"
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                sx={{ mt: 1, mr: 1 }}
                            >
                                Voltar
                            </Button>
                        </div>
                    </div>

                </div>

            </div >

        </>

    );
}