import React from 'react';
import Utils from 'utils/utils';
import { DateRangeIcon, FeatherIcon, ICONS_CUSTOM_FIELDS } from 'components/Icons';

export default function VisualizeIndexer({ image }) {
    const util = new Utils();
    const { createdAt, indexadores = [] } = image;
    const exibeNaGaleria = indexadores.find(e => e?.exibirThumbGallery === true);
    return (
        <>
            {!exibeNaGaleria
                ? (
                    <>
                        <span>
                            <DateRangeIcon />
                        </span>
                        <div>
                            {createdAt ? util.formatISODate(createdAt) : "--/--/----"}
                        </div >
                    </>
                )
                : <div style={
                    {
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }
                }>
                    <span>
                        <FeatherIcon icon={ICONS_CUSTOM_FIELDS[exibeNaGaleria.tipo]} />
                    </span>
                    <div>
                        {exibeNaGaleria.valor}
                    </div >
                </div>
            }
        </>
    );
}