import React, { useState } from "react";

import FormEnviarEmail from "./FormEnviarEmail";
import FormNovoComentario from "./FormNovoComentario";
import FormColumnsComentario from "./FormColumnsComentario";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import { Col, Row } from "react-bootstrap";
import RespostaOrgaos from "components/RespostaOrgaos";
import FormEnviarWhatsapp from "./FormEnviarWhatsapp";

const TERMO_COMENTARIOS = "Comentário";
const TERMO_EMAIL = "E-mail";
const TERMO_WHATSAPP = "WhatsApp";
const TERMO_RESPOSTA_PARA_ORGAOS = "Gerar PDF";

export default function ComentarioPadrao({
  documento,
  opcaoUnica = null,
  closeButton = null,
  handleClose = null,
  aoEnviar = () => { },
}) {
  const [opcaoComentario, setOpcaoComentario] = useState(
    opcaoUnica || TERMO_COMENTARIOS
  );
  const [isCommentBlank, setIsCommentBlank] = useState(true);

  function handleChangeTipo(e) {
    setOpcaoComentario(e.target.value);
  }

  return (
    <div>
      <FormControl variant="outlined" size="small" fullWidth>
        <InputLabel id={"select-label-opcao"}>Opção</InputLabel>
        <Select
          labelId="select-label-opcao"
          id="select-opcao"
          value={opcaoComentario}
          label="Opção"
          name="opcao"
          onChange={handleChangeTipo}
          readOnly={opcaoUnica}
        >
          {[TERMO_COMENTARIOS, TERMO_EMAIL, TERMO_WHATSAPP].map((denominacao) => (
            <MenuItem key={denominacao} value={denominacao}>
              {denominacao}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {opcaoComentario === TERMO_COMENTARIOS ? (
        <Row>
          <Col md="12" className="mt-2">
            <FormNovoComentario
              analise={documento}
              setIsCommentBlank={setIsCommentBlank}
              fechaAoSalvar={handleClose}
            >
              {closeButton}
            </FormNovoComentario>
          </Col>
        </Row>
      ) : opcaoComentario === TERMO_EMAIL ? (
        <FormEnviarEmail documento={documento} aoEnviar={aoEnviar}>{closeButton}</FormEnviarEmail>
      ) : opcaoComentario === TERMO_RESPOSTA_PARA_ORGAOS ? (
        <RespostaOrgaos documento={documento} >{closeButton}</RespostaOrgaos>
      ) : opcaoComentario === TERMO_WHATSAPP ? (
        <FormEnviarWhatsapp documento={documento} >{closeButton}</FormEnviarWhatsapp>
      ) : (
        <Row>
          <Col md="12" className="mt-2">
            <FormColumnsComentario analise={documento}>
              {closeButton}
            </FormColumnsComentario>
          </Col>
        </Row>
      )}
    </div>
  );
}
