import styled, { css, keyframes } from "styled-components/macro";

import { default as Menu } from "@material-ui/core/Menu";
import { default as MenuItemMaterial } from "@material-ui/core/MenuItem";
import { default as ListItemIconMaterial } from "@material-ui/core/ListItemIcon";
import { default as ListItemTextMaterial } from "@material-ui/core/ListItemText";

const confirmationTimer = keyframes`
    to {
        width: 0%;
    }
`;

export const Container = styled(Menu)`
  .MuiMenu-paper {
    border: 2px solid #86ccff;
  }
`;

export const MenuItem = styled(MenuItemMaterial)`
  position: relative;
  ${({ confirmation }) =>
    confirmation &&
    css`
      background-color: #e06969 !important;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        background-color: #00000033;
        width: 100%;
        height: 100%;
        animation: ${confirmationTimer} 2s forwards;
      }
    `};

  ${({ loading }) =>
    loading &&
    css`
      pointer-events: none;
    `};
`;

export const ListItemIcon = styled(ListItemIconMaterial)`
  background-color: #fff;
  color: #7f7f7f;
  border-radius: 50px;
  margin: 0;
  min-width: 20px;
  padding: 4px;

  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;

  ${MenuItem}:hover & {
    background-color: #7f7f7f;
    color: #fff;
  }

  ${({ confirmation }) =>
    confirmation &&
    css`
      background-color: #fff !important;
      color: #e06969 !important;
    `};
`;

export const ListItemText = styled(ListItemTextMaterial)`
  color: #555;
  margin-left: 8px;
  padding-bottom: 4px;
  border-bottom: 2px solid transparent;
  transition: border 0.2s ease-in-out;

  ${MenuItem}:hover & {
    border-bottom: 2px solid #7f7f7f;
  }

  ${({ confirmation }) =>
    confirmation &&
    css`
      border-bottom: 2px solid #fff !important;
      color: #fff !important;
    `};

  ${({ loading }) =>
    loading &&
    css`
      color: #bbb;
      pointer-events: none;
    `};
`;
