import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Row, Col } from "react-bootstrap";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { Dialog, DialogTitle, DialogContent } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import { Creators as DocumentosActions } from "../../store/ducks/documentos";
import "./style.css";

function ModalDeleteImage({ open, image, handleOpenImageDetail, selectedDoc }) {
  const dispatch = useDispatch();

  const onSubmit = () => {
    if (image && selectedDoc)
      dispatch(DocumentosActions.removeImagemRequest({
        image,
        selectedDoc
      }));
  };

  return (
    <Dialog open={open} onClose={handleOpenImageDetail} maxWidth="sm" fullWidth>
      <DialogTitle className="header-modal">
        Excluir imagem do documento
      </DialogTitle>
      <DialogContent>
        <Row>
          <Col md={12}>
            <Alert className="mb-1" severity="warning">
              Recurso apenas disponível para administradores da plataforma.
            </Alert>
            <Alert severity="warning">
              Essa ação é irreversível.
            </Alert>
          </Col>
        </Row>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={() => onSubmit()}>
          Excluir
        </Button>
        <Button variant="contained" color="secondary" onClick={() => handleOpenImageDetail()}>
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ModalDeleteImage;
