import _ from 'lodash';

export const Types = {
  LIST_INITIALIZE: "mensagens/LIST_INITIALIZE",
  LIST_REQUEST: "mensagens/LIST_REQUEST",
  LIST_SUCCESS: "mensagens/LIST_SUCCESS",
  UNREAD_REQUEST: "mensagens/UNREAD_REQUEST",
  UNREAD_SUCCESS: "mensagens/UNREAD_SUCCESS",
  MARK_AS_READ_REQUEST: "mensages/MARK_AS_READ_REQUEST",
  MARK_AS_READ_SUCCESS: "mensagens/MARK_AS_READ_SUCCESS",
};

const INITIAL_STATE = {
  list: [],
  authorList: [],
  loading: false,
  page: 1,
  limit: 10,
  total: 0,
  search: "",
  endpoint: 0,
  sort: "-createdAt",
  naoLidas: 0,
};

export default function mensagens(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.LIST_INITIALIZE:
      return {
        ...state,
        list: [],
      };

    case Types.LIST_REQUEST:
      return {
        ...state,
        ...action.payload,
        list: [...state.list],
        authorList: action.payload.authorList,
        loading: true,
      };

    case Types.LIST_SUCCESS:
      return {
        ...state,
        total: action.payload.total,
        list: _.uniqBy([ ...state.list, ...action.payload.messages], '_id'),
        authorList: action.payload.authorList,
        loading: false
      }
         
    case Types.UNREAD_SUCCESS:
      return {
        ...state,
        naoLidas: action.payload.naoLidas
      }

    case Types.MARK_AS_READ_SUCCESS:
      return {
        ...state,
        naoLidas: state.naoLidas -1,
        list: state.list.map(mensagem => ({
          ...mensagem,
          lida: mensagem._id === action.payload._id ? 
            true : mensagem.lida
        }))
      }

    default:
      return state;
  }
}

export const Creators = {
  listInitialize: () => ({
    type: Types.LIST_INITIALIZE,
  }),
  listRequest: (params) => ({
    type: Types.LIST_REQUEST,
    payload: params,
  }),
  listSuccess: ({ messages, authorList }, total) => ({
    type: Types.LIST_SUCCESS,
    payload: { messages, authorList, total }
  }),
  unreadRequest: () => ({
    type: Types.UNREAD_REQUEST
  }),
  unreadSuccess: naoLidas => ({
    type: Types.UNREAD_SUCCESS,
    payload: { naoLidas }
  }),
  markAsReadRequest: _id => ({
    type: Types.MARK_AS_READ_REQUEST,
    payload: { _id }
  }),
  markAsReadSuccess: _id => ({
    type: Types.MARK_AS_READ_SUCCESS,
    payload: { _id }
  })
};
