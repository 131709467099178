import React, { useState, useEffect } from 'react';

import { Row } from 'react-bootstrap';
import DefaultPanel from '../../components/DefaultPanel';

import Filas from './components/Filas';
import Etapas from './components/Etapas/withoutmodal';
import SearchResult from './components/SearchResult';
import SearchForm from './components/SearchForm';

import './styles.css';
import Utils from 'utils/utils';
import Content from 'components/Content';

function TitleComp({ fila, etapas }) {
  return (
    <div className="titleComp">
      <div className="processo">
        <strong>Fila:</strong>
        {fila}
      </div>
      <div className="fila">
        <strong>Etapas:</strong>
        {etapas.join(', ')}
      </div>
    </div>
  );
}

export default function Esteira(props) {
  const [selectedFilaNome, setSelectedFilaNome] = useState('');
  const [selectedFilaTitle, setSelectedFilaTitle] = useState('');
  const [selectedEtapasNome, setSelectedEtapasNome] = useState([]);
  const [openEtapas, setOpenEtapas] = useState(false);
  const [collapseBPMViewer, setCollapseBPMViewer] = useState(false);
  const [collapseSearchForm, setCollapseSearchForm] = useState(false);
  const [collapseSearchResult, setCollapseSearchResult] = useState(false);

  useEffect(() => {
    if (!Utils.isNullOrUndefined(props.location?.state?.numeroCNJ)) return;

    setCollapseSearchForm(true);
    setCollapseSearchResult(false);
  }, [props.location?.state?.numeroCNJ]);

  useEffect(() => {
    setSelectedEtapasNome([]);
  }, [selectedFilaNome]);

  return (
    <Content>
      <Row>
        <DefaultPanel
          isCollapsible={true}
          collapse={collapseBPMViewer}
          setCollapse={setCollapseBPMViewer}
          size={12}
          titleText={
            !selectedFilaTitle ? 'Selecione uma fila' : `${selectedFilaTitle}`
          }
          titleComp={
            selectedEtapasNome.length > 0 ? (
              <TitleComp fila={selectedFilaNome} etapas={selectedEtapasNome} />
            ) : null
          }
        >
          <Filas
            setSelectedFilaNome={setSelectedFilaNome}
            setSelectedFilaTitle={setSelectedFilaTitle}
            setOpenEtapas={setOpenEtapas}
            fluxo={props.location?.state?.fluxo}
          />
          <Etapas
            setCollapseBPMViewer={setCollapseBPMViewer}
            selectedFilaNome={selectedFilaNome}
            setSelectedEtapasNome={setSelectedEtapasNome}
            selectedEtapasNome={selectedEtapasNome}
            openEtapas={openEtapas}
            setOpenEtapas={setOpenEtapas}
          />
        </DefaultPanel>
      </Row>

      <SearchForm
        collapse={collapseSearchForm}
        setCollapse={setCollapseSearchForm}
        numeroCNJ={props.location?.state?.numeroCNJ}
        selectedFilaNome={selectedFilaNome}
      />

      <SearchResult
        fila={selectedFilaNome}
        collapse={collapseSearchResult}
        setCollapse={setCollapseSearchResult}
      />
    </Content>
  );
}
