import api from 'services/api';
import { getToken } from 'services/auth';
import { call, select, put, all, takeLatest } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';

import { Creators as DashboardActions, Types } from 'store/ducks/dashboard';

export const getOrganization = state => state.usuarios.auth.organization;

// TODO MUDAR PARA LIST ao invez de create
export function* createDashboard() {
  const organization = yield select(getOrganization);

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const { data } = yield call(api.get, 'dashboard', headerParams);

    // TODO MUDAR PARA LIST
    yield put(DashboardActions.createSuccess(data));
  } catch (error) {
    toastr.error('Ops', 'Não foi possível listar as Dashboard.');
  }
}

export function* slaFilterList(action) {
  const organization = yield select(getOrganization);

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
      params: action.payload
    };

    const { data } = yield call(api.get, 'dashboard/sla', headerParams);

    if (data?.error) {
      toastr.error('Ops', "Não foi possivel carregar as informações");
      return   
    }
    yield put(DashboardActions.slaFilterSuccess(data));
  } catch (error) {
    toastr.error('Ops', 'Não foi possível listar as Dashboard.');
  } finally {
    yield put(DashboardActions.toggleLoading(false));
  }
}

export function* documentsOnMaxScallingWithoutAnswerRequest(action) {
  const organization = yield select(getOrganization);

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
      params: action.payload
    };

    const { data } = yield call(api.get, 'dashboard/max-scalling/without/answer/sla', headerParams);

    if (data?.error) {
      toastr.error('Ops', "Não foi possivel carregar as informações");
      return   
    }
    yield put(DashboardActions.documentsMaxScalllingWithoutAnswerSuccess(data));
  } catch (error) {
    toastr.error('Ops', 'Não foi possível listar as Dashboard.');
  } finally {
    yield put(DashboardActions.toggleLdocumentsMaxScalllingWithoutAnswerToggleLooading(false));
  }
}


