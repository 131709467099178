import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Col } from 'react-bootstrap';

import Historico from './Historico';
import Providencias from './Providencias';
import DadosProcessuais from './DadosProcessuais';
import SearchViewer from '../../../components/SearchViewer';
import Mensagens from '../../../components/Mensagens';
import DocSignatureList from '../../../components/DocSignatureList';
import { Mails } from '../../../components/Mails';
import Encerramentos from 'components/Encerramentos';
import Assistente from './Assistente';
import RespostaOrgaos from 'components/RespostaOrgaos';

import HistoricoWF from '../../../components/HistoricoWF';

import * as Icons from 'react-feather';

import './styles.css';
import { FeatherIcon } from 'components/Icons';
import Checklist from 'pages/CheckTipoPreenchimento/checklist';

const TAB_DADOSPROCESSUAIS = 0;
const TAB_CHECKLIST = 1;
const TAB_SEARCHVIEWER = 2;
const TAB_PROVIDENCIAS = 3;
const TAB_COMENTARIOS = 4;
const TAB_EMAILS = 5;
const TAB_ENCERRAMENTOS = 6;
const TAB_HISTORICO = 7;
const TAB_HISTORICO_WF = 8;
const TAB_ASSISTENTE = 9;
const TAB_GERAR_PDF = 10;
const TAB_ASSINATURA_ELETRONICA = 11;

export default function SearchTabs({ selectedDoc }) {
  let { loading } = useSelector(state => state.documentos);

  const [value, setValue] = useState(0);

  const {
    access: { servicos },
  } = useSelector(state => state.permissoes);

  const userActions = useMemo(() => {
    const find = servicos.find(item => item.route.indexOf("pesquisa") > -1);

    if (find !== undefined) return find;
    return [];
  }, [servicos]);

  function hasPermission(action) {

    if (userActions.actions) {
      return userActions.actions.some(userAct => userAct.action === action);
    }
    return false;
  }

  const handleTabChange = newValue => {
    setValue(newValue);
  };

  return (
    <>
      {(selectedDoc.imagens || loading) && (
        <>
          <Col md="3" className="painel">
            <div className="menu-search">
              <div className="menu-search-header">
                <h4>INFORMAÇÕES</h4>
              </div>
              <div className="menu-search-content">
                <ul className="menu-search-list">
                  <li>
                    <button
                      className={`nav-link ${value === TAB_DADOSPROCESSUAIS && 'active'}`}
                      onClick={e => handleTabChange(TAB_DADOSPROCESSUAIS)}
                    >
                      <FeatherIcon fontSize="small" icon={Icons.File} />
                      Dados gerais
                    </button>
                  </li>
                  <li>
                    <button
                      className={`nav-link ${value === TAB_CHECKLIST && 'active'}`}
                      onClick={e => handleTabChange(TAB_CHECKLIST)}
                    >
                      <FeatherIcon fontSize="small" icon={Icons.Check} />
                      Checklist
                    </button>
                  </li>
                  {hasPermission('assistente') && (
                    <li>
                      <button
                        className={`nav-link ${value === TAB_ASSISTENTE && 'active'}`}
                        onClick={e => handleTabChange(TAB_ASSISTENTE)}
                      >
                        <FeatherIcon fontSize="small" icon={Icons.HelpCircle} />
                        Assistente
                      </button>
                    </li>
                  )}

                  <li>
                    <button
                      className={`nav-link ${value === TAB_SEARCHVIEWER && 'active'}`}
                      onClick={e => handleTabChange(TAB_SEARCHVIEWER)}
                    >
                      <FeatherIcon fontSize="small" icon={Icons.HardDrive} />
                      Arquivos
                    </button>
                  </li>
                  {hasPermission('tarefas') && (
                    <li>
                      <button
                        className={`nav-link ${value === TAB_PROVIDENCIAS && 'active'}`}
                        onClick={e => handleTabChange(TAB_PROVIDENCIAS)}
                      >
                        <FeatherIcon fontSize="small" icon={Icons.List} />
                        Tarefas
                      </button>
                    </li>
                  )}
                  {hasPermission('comentarios') && (
                    <li>
                      <button
                        className={`nav-link ${value === TAB_COMENTARIOS && 'active'}`}
                        onClick={e => handleTabChange(TAB_COMENTARIOS)}
                      >
                        <FeatherIcon fontSize="small" icon={Icons.MessageSquare} />
                        Comentários
                      </button>
                    </li>
                  )}
                  {hasPermission('emails') && (
                    <li>
                      <button
                        className={`nav-link ${value === TAB_EMAILS && 'active'}`}
                        onClick={e => handleTabChange(TAB_EMAILS)}
                      >
                        <FeatherIcon fontSize="small" icon={Icons.Mail} />
                        Emails
                      </button>
                    </li>
                  )}

                  {hasPermission('gerarpdf') && (
                    <li>
                      <button
                        className={`nav-link ${value === TAB_GERAR_PDF && 'active'}`}
                        onClick={e => handleTabChange(TAB_GERAR_PDF)}
                      >
                        <FeatherIcon fontSize="small" icon={Icons.Paperclip} />
                        Gerar PDF
                      </button>
                    </li>
                  )}
                  {hasPermission('assinaturaeletronica') && (
                    <li>
                      <button
                        className={`nav-link ${value === TAB_ASSINATURA_ELETRONICA && 'active'}`}
                        onClick={e => handleTabChange(TAB_ASSINATURA_ELETRONICA)}
                      >
                        <FeatherIcon fontSize="small" icon={Icons.Feather} />
                        Assinatura Eletrônica
                      </button>
                    </li>
                  )}
                  {hasPermission('historico') && (
                    <li>
                      <button
                        className={`nav-link ${value === TAB_HISTORICO && 'active'}`}
                        onClick={e => handleTabChange(TAB_HISTORICO)}
                      >
                        <FeatherIcon fontSize="small" icon={Icons.BookOpen} />
                        Histórico
                      </button>
                    </li>
                  )}
                  {hasPermission('historicoworkflow') && (
                    <li>
                      <button
                        className={`nav-link ${value === TAB_HISTORICO_WF && 'active'}`}
                        onClick={e => handleTabChange(TAB_HISTORICO_WF)}
                      >
                        <FeatherIcon fontSize="small" icon={Icons.Archive} />
                        Histórico Workflow
                      </button>
                    </li>
                  )}
                  {hasPermission('encerramentos') && (
                    <li>
                      <button
                        className={`nav-link ${value === TAB_ENCERRAMENTOS && 'active'}`}
                        onClick={e => handleTabChange(TAB_ENCERRAMENTOS)}
                      >
                        <FeatherIcon fontSize="small" icon={Icons.XCircle} />
                        Encerramento
                      </button>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </Col>
          <Col md="9" className="painel">
            <div
              className={
                value === TAB_PROVIDENCIAS || value === TAB_ASSINATURA_ELETRONICA
                  ? 'painel-content-full painel-nopaddingtb'
                  : 'painel-content painel-nopaddingtb'
              }
            >
              {value === TAB_DADOSPROCESSUAIS && <DadosProcessuais selectedDoc={selectedDoc} />}
              {value === TAB_CHECKLIST && <Checklist selectedDoc={selectedDoc} />}
              {value === TAB_SEARCHVIEWER && <SearchViewer selectedDoc={selectedDoc} />}
              {value === TAB_PROVIDENCIAS && <Providencias selectedDoc={selectedDoc} />}
              {value === TAB_COMENTARIOS && <Mensagens documento={selectedDoc} />}
              {value === TAB_EMAILS && <Mails documento={selectedDoc} key={selectedDoc._id} />}
              {value === TAB_ENCERRAMENTOS && <Encerramentos documento={selectedDoc} />}
              {value === TAB_HISTORICO && <Historico selectedDoc={selectedDoc} />}
              {value === TAB_HISTORICO_WF && <HistoricoWF documento={selectedDoc} />}
              {value === TAB_ASSISTENTE && <Assistente selectedDoc={selectedDoc} />}
              {value === TAB_GERAR_PDF && (
                <Col sm={12} className="m-2 pt-2">
                  <RespostaOrgaos documento={selectedDoc} />
                </Col>
              )}

              {value === TAB_ASSINATURA_ELETRONICA && <DocSignatureList documento={selectedDoc} />}
            </div>
          </Col>
        </>
      )}
    </>
  );
}
