import api from "services/api";
import { getToken } from "services/auth";
import { call, select, put } from "redux-saga/effects";
import { toastr } from "react-redux-toastr";

import { Creators as PeticionamentoActions } from '../ducks/peticionamento';

export const getOrganization = (state) => state.usuarios.auth.organization;

export function* saveParagraph({ payload }) {
    const organization = yield select(getOrganization);

    try {
        const headerParams = {
            headers: { Authorization: getToken(), xkeyorg: organization },
        };

        const { data } = yield call(api.post, `paragrafos/`, payload, headerParams);

        yield put(PeticionamentoActions.saveNewParagraphSuccess(data));
        toastr.success("Parágrafo salvo com sucesso");
    } catch (err) {
        console.error(err);
        toastr.error("Ops", "Não foi possível salvar o parágrafo");
    }
}

export function* getParagraphs() {
    const organization = yield select(getOrganization);

    yield put(PeticionamentoActions.setParagraphsLoading());

    try {
        const headerParams = {
            headers: { Authorization: getToken(), xkeyorg: organization },
        };

        const { data } = yield call(api.get, `paragrafos/`, headerParams);

        yield put(PeticionamentoActions.getParagraphsSuccess(data));

    } catch (err) {
        console.error(err);
        toastr.error("Ops", "Não foi possível acessar a lista de parágrafos");
    }
}

export function* updateParagraph({ payload }) {
    const organization = yield select(getOrganization);

    yield put(PeticionamentoActions.setParagraphsLoading());

    try {
        const headerParams = {
            headers: { Authorization: getToken(), xkeyorg: organization },
        };

        const { data } = yield call(api.put, `paragrafos/${payload._id}`, payload, headerParams);

        yield put(PeticionamentoActions.updateSingleParagraphSuccess(data));

    } catch (err) {
        console.error(err);
        toastr.error("Ops", "Não foi possível atualizar o parágrafo");
    }
}

export function* deleteParagraph({ payload }) {
    const organization = yield select(getOrganization);

    yield put(PeticionamentoActions.setParagraphsLoading());

    try {
        const headerParams = {
            headers: { Authorization: getToken(), xkeyorg: organization },
        };

        yield call(api.delete, `paragrafos/${payload}`, headerParams);

        yield put(PeticionamentoActions.deleteSingleParagraphSuccess(payload));
        toastr.success('Concluído', 'Parágrafo removido com sucesso.');
    } catch (err) {
        console.error(err);
        toastr.error("Ops", "Não foi possível remover o parágrafo");
    }
}

