import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { toastr } from "react-redux-toastr";
import { format, parseISO } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';
import {
    Container,
    Tabs,
    Tab, 
    Description,
    DescriptionText,
    NoDescription,
    Remark,
    MiniUserAvatar,
    RemarkData,
    LoaderContainer,
    InputContainer,
    ButtonSubmit,
} from './styles/TabModalTaskDetails';

import DefaultInput from 'components/DefaultInput';
import TooltipComponent from 'components/Tooltip';
import Loader from 'components/Loader';

import noContentIcon from 'assets/svg/no_content.svg';
import Utils from 'utils/utils';
import api from 'services/api';
import { getToken } from "../../services/auth";
import { AssignmentIcon, ChatIcon, SendIcon } from 'components/Icons';

const TAB_ITEMS = {
    COMMENT: 0,
    REMARK: 1,
}

function TabModalTaskDetails({ comentario, taskId }) {
    const formRef = useRef(null);
    const { organization } = useSelector(state => state.usuarios.auth);
    const userId = useSelector(state => state.usuarios.auth.id);

    const [selectedTab, setSelectedTab] = useState(TAB_ITEMS.COMMENT);
    const [remarks, setRemarks] = useState([])
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function getRemarks() {
            setLoading(true);
            try {
                const headerParams = {
                    headers: {
                        Authorization: getToken(), 
                        xkeyorg: organization
                    },
                };
    
                const { data } = await api.get(
                    `providencias/${taskId}/remarks`,
                    headerParams
                );
                setRemarks(data);      
            } catch (err) {
                console.error(err);
                toastr.error("Ops", "Não foi possível buscar comentários.");
            } finally {
                setLoading(false);
            }
        }

        getRemarks();
    }, []);

    function handleChange(event, newValue) {
        setSelectedTab(newValue);
    }

    async function handleSubmit(newRemark, { reset }) {

        try {
            const headerParams = {
                headers: {
                    Authorization: getToken(), 
                    xkeyorg: organization
                },
            };

            const { data } = await api.post(
                `providencias/${taskId}/remarks`,
                newRemark,
                headerParams
            );

            setRemarks(data);

            toastr.success(
                "Sucesso",
                "Comentário criado com sucesso."
              );
            
            reset();
        } catch (error) {
            console.error(error)
            toastr.error("Ops", "Não foi possível criar o comentário.");
        }
    }

    function dateFormatter(date) {
        return format(parseISO(date), "d/M/yyyy - HH:mm", { locale: pt });
    }

    return (
        <Container>
            <Tabs
                value={selectedTab}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                aria-label="icon label tabs example" >

                <Tab icon={<AssignmentIcon />} label="Descrição" />
                <Tab icon={<ChatIcon />} label="Comentários" />
            </Tabs>
                {selectedTab === TAB_ITEMS.COMMENT && (
                    <Description>
                        {comentario ? (
                            <DescriptionText>{comentario}</DescriptionText>
                        ) : (
                            <NoDescription>
                                <img src={noContentIcon} alt="Essa tarefa/evento não possui descrição" />
                                Essa tarefa/evento não possui descrição
                            </NoDescription> )}
                        
                    </Description> )}

                {selectedTab === TAB_ITEMS.REMARK && (
                    <>
                        <Description>
                            {!loading && remarks && remarks.map(remark => 
                                <Remark key={remark._id} ownmessage={remark.user._id === userId}>
                                    <TooltipComponent placement="top" title={remark.user.nome}>
                                        <MiniUserAvatar>
                                            {Utils.getNameInitials(remark.user.nome)}
                                        </MiniUserAvatar>
                                    </TooltipComponent>

                                    <RemarkData ownmessage={remark.user._id === userId}>
                                        <div>{remark.data}</div><span>{dateFormatter(remark.createdAt)}</span>
                                    </RemarkData>
                                </Remark>)}
                                {loading && 
                                    <LoaderContainer>
                                        <Loader fullscreen={false} size={100} />
                                    </LoaderContainer>}
                        </Description>
                        <InputContainer ref={formRef} onSubmit={handleSubmit}>
                            <DefaultInput name="remark" placeholder="Novo comentário"/>
                            <ButtonSubmit type="submit">
                                <SendIcon style={{ color: "#FFF" }} />
                            </ButtonSubmit>
                        </InputContainer>
                    </>)}
        </Container>
    );
}

export default TabModalTaskDetails;