class UploadImageToDraft {

    constructor(props) {

    }

    uploadImage(file) {

        return new Promise(
            (resolve, reject) => {
                if (file) {
                    const type = file ? file?.split('.').at(-1) : '';
                    let reader = new FileReader();
                    reader.onload = (e) => {
                        resolve({
                            data: {
                                link: e.target.result,
                                size: reader.result.length,
                                type
                            }
                        })
                    };
                    reader.readAsDataURL(file);
                }
            }
        );
    }
}
export default UploadImageToDraft;