import api from 'services/api';
import { getToken } from 'services/auth';
import { call, select, put, all, takeLatest } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';

import { Creators as DenominacoesActions, Types } from 'store/ducks/denominacoes';

export const getOrganization = (state) => state.usuarios.auth.organization;

export function* listDenominacoes() {
  const organization = yield select(getOrganization); 

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization }
    };

    const { data } = yield call(api.get, 'denominacoes', headerParams);

    yield put(DenominacoesActions.listSuccess(data))

  } catch(err) {
    yield put(DenominacoesActions.listError());
    toastr.error('Ops', 'Não foi possível listar as denominações');
  }
}

export default all([
  takeLatest(Types.LIST_REQUEST, listDenominacoes)
])