import React from "react";
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
} from "@material-ui/core";

import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";

import { CNPJ, CPF, Date } from "components/Masks";
import DynamicInputMask from "components/DynamicInputMask";
import OptionAutocomplete from "components/FormFieldIndexer/OptionAutocomplete";

import "./styles.css";
import { SearchIcon } from "components/Icons";
import { SensibleFlag, WrapSensibleFlag } from "components/SensibleFlag";

export function IndexadorComponent({
  indexador,
  handleChangeIndexador,
  handleChangeIndexadorUnico,
  handleDoubleClick,
  open,
  onOpen,
  onClose,
  value,
  options,
  loading,
  onDoubleClick,
  error,
  helperText,
}) {
  if (indexador.tipo === "tabela")
    return (
      <WrapSensibleFlag indexador={indexador}>
        {indexador.valor && indexador.valor.length > 0 ? (
          <table className="indexadorTable">
            <thead>
              <tr>
                {Object.keys(indexador.valor[0]).map((value) => (
                  <th key={value}>{value}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {Object.keys(indexador.valor).map((row, index) => (
                <tr key={index}>
                  {Object.keys(indexador.valor[row]).map((key) => (
                    <td key={key}>{indexador.valor[row][key]}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className="indexadorTableNoContent">
            Sem resultados a serem exibidos
          </div>
        )}
      </WrapSensibleFlag>
    );

  if (indexador.indentificadorUnico) {
    const filter = createFilterOptions();
    return (
      <Autocomplete
        id={`combo-box-${indexador.nome}`}
        open={open}
        onOpen={onOpen}
        onClose={onClose}
        clearOnBlur={false}
        getOptionLabel={(option) => option[indexador.nome]}
        getOptionSelected={(option, value) => option === value}
        renderOption={(option) => <OptionAutocomplete option={option} />}
        onChange={handleChangeIndexador}
        options={options}
        loading={loading}
        renderInput={(params) => (
          <WrapSensibleFlag indexador={indexador}>
            <TextField
              {...params}
              label={indexador.nome}
              onChange={handleChangeIndexadorUnico}
              name={indexador.nome}
              value={indexador.valor || ""}
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                inputComponent:
                  indexador.tipo === "cpf"
                    ? CPF
                    : indexador.tipo === "cnpj"
                      ? CNPJ
                      : params.InputProps.inputComponent,
                endAdornment: (
                  <React.Fragment>
                    {!!loading ? (
                      <CircularProgress color="primary" size={20} />
                    ) : (
                      <SearchIcon />
                    )}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
              error={error}
              helperText={helperText}
              onDoubleClick={onDoubleClick}
            />
          </WrapSensibleFlag>

        )}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          if (params.inputValue !== "") {
            filtered.push({
              [indexador.nome]: params.inputValue,
            });
          }
          return filtered;
        }}
        value={value}
        size="small"
      />
    );
  }

  if (indexador.tipo === "combo")
    return (
      <FormControl variant="outlined" size="small" fullWidth>
        <SensibleFlag indexador={indexador} >
          <InputLabel id={`select-label-${indexador.nome}`}>
            {indexador.nome}
          </InputLabel>
        </SensibleFlag>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={indexador.valor || ""}
          label={indexador.nome}
          disabled={indexador.somenteLeitura}
          name={indexador.nome}
          onChange={handleChangeIndexador}
        >
          {indexador.opcoes.map((opcao) => (
            <MenuItem key={opcao} value={opcao}>
              {opcao}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );

  if (indexador.tipo === "cpf" || indexador.tipo === "cnpj")
    return (
      <WrapSensibleFlag indexador={indexador}>
        <TextField
          onChange={handleChangeIndexador}
          label={indexador.nome}
          name={indexador.nome}
          value={indexador.valor || ""}
          disabled={indexador.somenteLeitura}
          variant="outlined"
          size="small"
          InputProps={{
            inputComponent: indexador.tipo !== "cpf" ? CNPJ : CPF,
          }}
          error={error}
          helperText={helperText}
          onDoubleClick={handleDoubleClick}
        />
      </WrapSensibleFlag>

    );

  if (indexador.tipo === "data")
    return (
      <WrapSensibleFlag indexador={indexador}>
        <TextField
          onChange={handleChangeIndexador}
          label={indexador.nome}
          name={indexador.nome}
          value={indexador.valor || ""}
          disabled={indexador.somenteLeitura}
          variant="outlined"
          size="small"
          InputProps={{
            inputComponent: Date,
          }}
          onDoubleClick={handleDoubleClick}
          error={error}
          helperText={helperText}
        />
      </WrapSensibleFlag>
    );

  if (indexador.tipo !== "tabela")
    return indexador.mascara === "" ? (
      <WrapSensibleFlag indexador={indexador}>
        <TextField
          onChange={handleChangeIndexador}
          label={indexador.nome}
          name={indexador.nome}
          value={indexador.valor || ""}
          variant="outlined"
          disabled={indexador.somenteLeitura}
          size="small"
          type={indexador.tipo === "numero" ? "number" : "text"}
          onDoubleClick={handleDoubleClick}
          error={error}
          helperText={helperText}
        />
      </WrapSensibleFlag>
    ) : (
        <WrapSensibleFlag indexador={indexador}>
          <DynamicInputMask
            mask={indexador.mascara}
            onChange={handleChangeIndexador}
            value={indexador.valor || ""}
            name={indexador.nome}
            disabled={indexador.somenteLeitura}
            label={indexador.nome}
            error={error}
            helperText={helperText}
          />
        </WrapSensibleFlag>

    );
}
