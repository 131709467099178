import Button from '@material-ui/core/Button';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Moment from 'react-moment';
import { useDispatch, useSelector } from 'react-redux';

import { Creators as DocumentosActions } from 'store/ducks/documentos';
import { Creators as SeparadoresActions } from 'store/ducks/separadores';

import FormIndexadores from './FormIndexadores';

import './styles.css';

import { SensibleFlag } from 'components/SensibleFlag';

export default function Indexadores({ selectedDoc, loading }) {
  const dispatch = useDispatch();
  const [separadores, setSeparadores] = useState([]);

  const { list, loading: loadingSeparadores } = useSelector(
    (state) => state.separadores
  );

  // useEffect(() => {
  //   dispatch(DenominacoesActions.listRequest());
  //   dispatch(ProvisoesActions.listRequest());
  // }, [dispatch]);

  useEffect(() => {
    if (selectedDoc.idTipoDocumento)
      dispatch(SeparadoresActions.listRequest(selectedDoc.idTipoDocumento._id));
  }, [selectedDoc.idTipoDocumento, dispatch]);

  const handleOpenEdit = () => {
    dispatch(DocumentosActions.openEdit());
  };

  const renderSeparadores = () => {
    if (selectedDoc.indexadores) {
      const seps = selectedDoc.indexadores.reduce(
        (acc, indexador) =>
          indexador.separador && !acc.includes(indexador.separador)
            ? [indexador.separador, ...acc]
            : acc,
        ['Outros indexadores']
      );
      const index = seps.indexOf('Preenchimento Analista');
      if (index > -1) seps.splice(index, 1);
      setSeparadores(seps.sort((a, b) => a.localeCompare(b)));
    }
  };

  useEffect(() => {
    renderSeparadores();
  }, [selectedDoc.indexadores]);

  return (
    <>
      {(loading || loadingSeparadores || !selectedDoc || !selectedDoc.createdAt) && (
        <div className="tabLoading">Carregando...</div>
      )}

      {!loading && !loadingSeparadores && selectedDoc && selectedDoc.createdAt ? (
        <Col md={12} className="indexadores">
          {/* {selectedDoc.alerta && selectedDoc.alerta !== '' && (
            <Alert className="mb-2" severity="warning">
              {selectedDoc.alerta}
            </Alert>
          )} */}
          <Row>
            <Col>
              {/* <AlertVulnerability indexadores={selectedDoc.indexadores} /> */}
            </Col>
          </Row>
          {/* <AlertPartWithMoreManifestation _id={selectedDoc._id} /> */}
          <div className="indexadores-actions">
            <Button
              variant="contained"
              color="primary"
              onClick={handleOpenEdit}
            >
              {!selectedDoc.status ? 'Visualizar' : 'Editar'}
            </Button>
            {/* <ExibeFilaEtapa etapa={selectedDoc.etapa} /> */}
          </div>

          {selectedDoc?.createdAt && (
            <div className="indexadores-viewer">
              <div className="indexadores-viewer-content">
                {selectedDoc && selectedDoc?.selfie && (
                  <>
                    <div className="indexadores-section">
                      <div className="indexadores-section-header">
                        <label>Selfie</label>
                      </div>
                      <div className="indexadores-section-content">
                        <Row>
                          {/* md={{ span: 4, offset: 8 }}>  */}
                          <Col>
                            <div className="indexador">
                              <span>
                                <img
                                  src={selectedDoc.selfie}
                                  alt="Selfie"
                                  style={{ width: '100%', width: '100px', height: '100px' }}
                                />
                              </span>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                    <div className="border-top"></div>
                  </>
                )}
                <div className="indexadores-section">
                  <div className="indexadores-section-header">
                    <label>Informações Gerais</label>
                  </div>
                  <div className="indexadores-section-content">
                    <Row>
                      <Col md={4}>
                        <div className="indexador">
                          <strong>Grupo Empresarial:</strong>
                          <span>
                            {selectedDoc.grupoEmpresarial
                              ? selectedDoc.grupoEmpresarial.nome
                              : '-'}
                          </span>
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="indexador">
                          <strong>Empresa:</strong>
                          <span>
                            {selectedDoc.idEmpresa
                              ? selectedDoc.idEmpresa.razaoSocial
                              : '-'}
                          </span>
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="indexador">
                          <strong>Departamento:</strong>
                          <span>
                            {selectedDoc.idDepartamento
                              ? selectedDoc.idDepartamento.nome
                              : '-'}
                          </span>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={4}>
                        <div className="indexador">
                          <strong>Criador:</strong>
                          <span>
                            {selectedDoc.userAcao
                              ? selectedDoc.userAcao.nome
                              : 'automático'}
                          </span>
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="indexador">
                          <strong>Data de criação:</strong>
                          <span>
                            <Moment
                              format="DD/MM/YYYY - HH:mm"
                              date={selectedDoc.createdAt}
                            />
                          </span>
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="indexador">
                          <strong>Data externa:</strong>
                          <span>
                            <Moment
                              format="DD/MM/YYYY - HH:mm"
                              date={selectedDoc?.dataGeracao}
                            />
                          </span>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
                <div className="border-top"></div>
                {selectedDoc.etapa && (
                  <div className="indexadores-section">
                    <div className="indexadores-section-header">
                      <label>Workflow</label>
                    </div>
                    <div className="indexadores-section-content">
                      <Row>
                        <Col md={4}>
                          <div className="indexador">
                            <strong>Etapa</strong>
                            <span>
                              {selectedDoc.etapa ? selectedDoc.etapa : '-'}
                            </span>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                )}
                <div className="border-top"></div>
                {separadores &&
                  separadores
                    .filter((separador) =>
                      selectedDoc.indexadores?.some(
                        (indexador) =>
                          indexador.separador === separador ||
                          (!indexador.separador &&
                            separador === 'Outros indexadores')
                      )
                    )
                    .map((separador) => (
                      <>
                        <div className="indexadores-section" key={separador}>
                          <div className="indexadores-section-header">
                            <label>{separador}</label>
                          </div>
                          <div className="indexadores-section-content">
                            <Row>
                              {selectedDoc.indexadores
                                .filter(
                                  (indexador) => indexador.separador === separador || (!indexador.separador && separador === 'Outros indexadores')
                                )
                                .filter((indexador) => !indexador.invisivel)
                                .sort((a, b) => a.order - b.order)
                                .map((indexador, index) => (
                                  <Col
                                    md={indexador.tipo === 'tabela' ? 12 : 6}
                                    key={index}
                                  >
                                    <div className="indexador">
                                      {indexador.tipo === 'tabela' ? (
                                        <>
                                          {indexador.valor &&
                                            indexador.valor.length > 0 ? (
                                            <table className="indexadorTable">
                                              <thead>
                                                <tr>
                                                  {Object.keys(
                                                    indexador.valor[0]
                                                  ).map((value) => (
                                                    <th key={value}>{value}</th>
                                                  ))}
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {Object.keys(indexador.valor).map(
                                                  (row, index) => (
                                                    <tr key={index}>
                                                      {Object.keys(
                                                        indexador.valor[row]
                                                      ).map((key) => (
                                                        <td key={key}>
                                                          {
                                                            indexador.valor[row][
                                                            key
                                                            ]
                                                          }
                                                        </td>
                                                      ))}
                                                    </tr>
                                                  )
                                                )}
                                              </tbody>
                                            </table>
                                          ) : (
                                            <div className="indexadorTableNoContent">
                                              Sem resultados a serem exibidos
                                            </div>
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          <SensibleFlag indexador={indexador}>
                                            <strong>{indexador.nome}:</strong>
                                          </SensibleFlag>
                                          <span>
                                            {indexador.valor ||
                                              'Sem informação preenchida'}
                                          </span>
                                        </>
                                      )}
                                    </div>
                                  </Col>
                                ))}
                            </Row>
                          </div>
                        </div>
                        <div className="border-top"></div>
                      </>
                    ))}
              </div>
            </div>
          )}
          <FormIndexadores documento={selectedDoc} />
        </Col>
      ) : (
        !loading &&
        !loadingSeparadores && (
          <></>
        )
      )}
    </>
  );
}
