import styled from 'styled-components/macro';

export const Container = styled.li`
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    cursor: pointer;

    transition: background-color .3s ease;

    & div {
        margin-left: 8px;
    }

    &:hover {
        background-color: #cccccc44;
    }
`;

export const ThumbnailFloater = styled.div`
    position: absolute;
    top: ${({ pos, size }) => `${pos.y - (size.height + 120)}px`};
    left: ${({ pos, size }) => `${pos.x - (size.width + 40)}px`};
    z-index: 99999;
    padding: 16px;
    background-color: #FFF;
    border-radius: 10px;
    box-shadow: 4px 4px 8px 4px #00000027;
`;



export const InputContainer = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    top: ${({ pos }) => `${pos.y - 30}px`};
    left: ${({ pos }) => `${pos.x + 200}px`};
    z-index: 99999;
    padding: 16px;
    background-color: #FFF;
    box-shadow: 4px 4px 8px 4px #00000027;
    border-radius: 10px;

    input {
        z-index: 999999;
    }
`;