export const Types = {
  NEW_REQUEST: "comentarios/NEW_REQUEST",
  SET_LIST_COMMENTS: "comentarios/SET_VALUE",
  SET_LIST_EVIDENCIAS: "evidencias/SET_VALUE",
};

const INITIAL_STATE = {
  list: [],
  loading: false,
};

export default function comentarios(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.NEW_REQUEST:
      return { ...state, loading: true };

    case Types.SET_LIST_COMMENTS:
      return {
        ...state,
        loading: false,
        list:
          action.payload.list.length > state.list.length
            ? action.payload.list
            : state.list,
      };

    default:
      return state;
  }
}

export const Creators = {
  createRequest: (_id, data) => ({
    type: Types.NEW_REQUEST,
    payload: { _id, data },
  }),

  setListComments: (list) => ({
    type: Types.SET_LIST_COMMENTS,
    payload: { list },
  }),

  setListEvidencias: (list) => ({
    type: Types.SET_LIST_EVIDENCIAS,
    payload: { list },
  }),
};
