/* Types */

export const Types = {
  CLEAR_ALL: "usuario/CLEAR_ALL",
  LOGIN_REQUEST: "usuario/LOGIN_REQUEST",
  LOGIN_KC_REQUEST: "usuario/LOGIN_KC_REQUEST",
  LOGIN_SUCCESS: "usuario/LOGIN_SUCCESS",
  LOGIN_ERROR: "usuario/LOGIN_ERROR",
  LOGOUT_REQUEST: "usuario/LOGOUT_REQUEST",
  LOGOUT_SUCCESS: "usuario/LOGOUT_SUCCESS",
  LIST_REQUEST: "usuario/LIST_REQUEST",
  LIST_SUCCESS: "usuario/LIST_SUCCESS",
  EDIT_REQUEST: "usuario/EDIT_REQUEST",
  EDIT_SUCCESS: "usuario/EDIT_SUCCESS",
  LIST_REQUEST_USERS_EMPRESAS_ORGANIZACAO:
    "usuario/LIST_REQUEST_USERS_EMPRESAS_ORGANIZACAO",
  LIST_SUCCESS_USERS_EMPRESAS_ORGANIZACAO:
    "usuario/LIST_SUCCESS_USERS_EMPRESAS_ORGANIZACAO",
  EDIT_EMPRESA_REQUEST: "usuario/EDIT_EMPRESA_REQUEST",
  EDIT_EMPRESA_SUCCESS: "usuario/EDIT_EMPRESA_SUCCESS",
  LIST_REQUEST_BY_DEPTO: "usuario/LIST_REQUEST_BY_DEPTO",
  LIST_SUCCESS_BY_DEPTO: "usuario/LIST_SUCCESS_BY_DEPTO",
  CLEAN_REQUEST: "usuario/CLEAN_REQUEST",
  CHANGE_PASSWORD_REQUEST: "usuario/CHANGE_PASSWORD_REQUEST",
  CHANGE_PASSWORD_SUCCESS: "usuario/CHANGE_PASSWORD_SUCCESS",
  FORGOT_PASSWORD_REQUEST: "usuario/FORGOT_PASSWORD_REQUEST",
  FORGOT_PASSWORD_SUCCESS: "usuario/FORGOT_PASSWORD_SUCCESS",

  VALIDATE_TOKEN_REQUEST: "usuario/VALIDATE_TOKEN_REQUEST",
  VALIDATE_TOKEN_SUCCESS: "usuario/VALIDATE_TOKEN_SUCCESS",

  CHANGE_PASSWORD_BY_LOGIN_REQUEST: "usuario/CHANGE_PASSWORD_BY_LOGIN_REQUEST",
  CHANGE_PASSWORD_BY_LOGIN_SUCCESS: "usuario/CHANGE_PASSWORD_BY_LOGIN_SUCCESS",

  CHANGE_PASSWORD_BY_NEW_PASS_REQUEST:
    "usuario/CHANGE_PASSWORD_BY_NEW_PASS_REQUEST",
  CHANGE_PASSWORD_BY_NEW_PASS_SUCCESS:
    "usuario/CHANGE_PASSWORD_BY_NEW_PASS_SUCCESS",

  GO_TO_APP_REQUEST: "usuario/GO_TO_APP_REQUEST",

  LOGIN_WITH_TOKEN_REQUEST: "usuario/LOGIN_WITH_TOKEN_REQUEST",
  LOGIN_WITH_TOKEN_SUCCESS: "usuario/LOGIN_WITH_TOKEN_SUCCESS",
  LOGIN_WITH_TOKEN_ERROR: "usuario/LOGIN_WITH_TOKEN_ERROR",
  SET_ORGANIZATION: "usuario/SET_ORGANIZATION",
  LIST_BY_ACCESS_GROUP_REQUEST: "usuario/LIST_BY_ACCESS_GROUP_REQUEST",
  LIST_BY_ACCESS_GROUP_SUCCESS: "usuario/LIST_BY_ACCESS_GROUP_SUCCESS",
  SEARCH_BY_NAME_REQUEST: "usuario/SEARCH_BY_NAME_REQUEST",
  SEARCH_BY_NAME_SUCCESS: "usuario/SEARCH_BY_NAME_SUCCESS",
  TOGGLE_ANALYSTS_LOADING: "usuario/TOGGLE_ANALYSTS_LOADING",

  TWO_FACTOR_REQUEST: "usuario/TWO_FACTOR_REQUEST",
  TWO_FACTOR_SUCCESS: "usuario/TWO_FACTOR_SUCCESS",
};

/* Reducer */

const INITIAL_STATE = {
  loading: false,
  auth: {},
  list: [],
  byEmpOrg: [],
  byAccessGroup: [],
  passwordChanged: false,
  error: "",
  attempt: 0,
  page: 1,
  limit: 10,
  total: 0,
  sort: "nome",
  validatedToken: { loading: false, isValidated: false },
  validateCode: false,
  analysts: {
    loading: false,
    page: 1,
    limit: 10,
    total: 0,
    sort: "nome",
    results: [],
  },
};

export default function usuarios(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.CLEAR_ALL:
      return { ...state, loading: false, auth: {}, error: "" };
    case Types.LOGIN_REQUEST:
      return { ...state, loading: true, error: "" };

    case Types.LOGIN_KC_REQUEST:
      return { ...state, loading: true, error: "" };

    case Types.LOGIN_SUCCESS:
      return { ...state, loading: false, auth: action.payload, attempt: 0 };

    case Types.LOGIN_ERROR:
      return {
        ...state,
        loading: false,
        auth: {},
        error: action.payload,
        attempt: state.attempt + 1,
      };

    case Types.LOGIN_WITH_TOKEN_REQUEST:
      return { ...state, loading: true, error: "" };

    case Types.LOGIN_WITH_TOKEN_SUCCESS:
      return { ...state, loading: false, auth: action.payload, attempt: 0 };

    case Types.LOGIN_WITH_TOKEN_ERROR:
      return {
        ...state,
        loading: false,
        auth: {},
        error: action.payload,
      };

    case Types.LOGOUT_REQUEST:
      return { ...state, loading: false, auth: state.auth };

    case Types.CLEAN_REQUEST:
      return { ...state, loading: false, auth: {}, error: "" };

    case Types.LIST_REQUEST:
      return { ...state, loading: true };

    case Types.LIST_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };

    case Types.LIST_REQUEST_BY_DEPTO:
      return { ...state, loading: true };

    case Types.LIST_SUCCESS_BY_DEPTO:
      return { ...state, loading: false, byEmpOrg: action.payload };

    case Types.EDIT_REQUEST:
      return { ...state, loading: true };

    case Types.EDIT_SUCCESS:
      return { ...state, loading: false };

    case Types.EDIT_EMPRESA_REQUEST:
      return { ...state, loading: true };

    case Types.EDIT_EMPRESA_SUCCESS:
      return { ...state, loading: false };

    case Types.LIST_REQUEST_USERS_EMPRESAS_ORGANIZACAO:
      return { ...state, loading: true };

    case Types.LIST_SUCCESS_USERS_EMPRESAS_ORGANIZACAO:
      return { ...state, loading: false, byEmpOrg: action.payload };

    case Types.LIST_BY_ACCESS_GROUP_REQUEST:
      return { ...state, loading: true };

    case Types.LIST_BY_ACCESS_GROUP_SUCCESS:
      return { ...state, loading: false, list: action.payload };

    case Types.CHANGE_PASSWORD_REQUEST:
      return { ...state, loading: true };

    case Types.CHANGE_PASSWORD_SUCCESS:
      return { ...state, loading: false, passwordChanged: true };

    case Types.FORGOT_PASSWORD_REQUEST:
      return { ...state };

    case Types.FORGOT_PASSWORD_SUCCESS:
      return { ...state };

    case Types.VALIDATE_TOKEN_REQUEST:
      return {
        ...state,
        validatedToken: { loading: true, isValidated: false },
      };

    case Types.VALIDATE_TOKEN_SUCCESS:
      return {
        ...state,
        validatedToken: { loading: false, isValidated: action.payload.status },
      };

    case Types.CHANGE_PASSWORD_BY_LOGIN_REQUEST:
      return { ...state, loading: true };

    case Types.CHANGE_PASSWORD_BY_LOGIN_SUCCESS:
      return { ...state, loading: false, passwordChanged: true };

    case Types.CHANGE_PASSWORD_BY_NEW_PASS_REQUEST:
      return { ...state, loading: true };

    case Types.CHANGE_PASSWORD_BY_NEW_PASS_SUCCESS:
      return { ...state, loading: false, passwordChanged: true };

    case Types.GO_TO_APP_REQUEST:
      return { ...state };

    case Types.SEARCH_BY_NAME_REQUEST:
      return { ...state, analysts: { ...state.analysts, loading: true } };

    case Types.SEARCH_BY_NAME_SUCCESS:
      return {
        ...state,
        analysts: { loading: false, results: action.payload },
      };

    case Types.TOGGLE_ANALYSTS_LOADING: {
      return {
        ...state,
        analysts: { ...state.analysts, loading: action.payload ?? !state.analysts.loading },
      };
    }

    case Types.SET_ORGANIZATION:
      return {
        ...state,
        loading: true,
        auth: { ...state.auth, organization: action.payload },
      };

    case Types.TWO_FACTOR_REQUEST:
      return {
        ...state,
        loading: true,
        validateCode: false,
      };

    case Types.TWO_FACTOR_SUCCESS:
      return {
        ...state,
        loading: false,
        validateCode: true,
      }

    default:
      return state;
  }
}

export const Creators = {
  clearAll: () => ({
    type: Types.CLEAR_ALL,
    payload: {

    },
  }),


  loginRequest: (user) => ({
    type: Types.LOGIN_REQUEST,
    payload: user,
  }),

  loginKcRequest: (user) => ({
    type: Types.LOGIN_KC_REQUEST,
    payload: user,
  }),

  loginSuccess: (payload) => ({
    type: Types.LOGIN_SUCCESS,
    payload: payload,
  }),

  loginError: (errorMessage) => ({
    type: Types.LOGIN_ERROR,
    payload: errorMessage,
  }),

  loginWithTokenRequest: (token) => ({
    type: Types.LOGIN_WITH_TOKEN_REQUEST,
    payload: token,
  }),

  loginWithTokenSuccess: (payload) => ({
    type: Types.LOGIN_WITH_TOKEN_SUCCESS,
    payload,
  }),

  loginWithTokenError: (payload) => ({
    type: Types.LOGIN_WITH_TOKEN_ERROR,
    payload,
  }),

  goToAppRequest: (app) => ({
    type: Types.GO_TO_APP_REQUEST,
    payload: app,
  }),

  logoutRequest: () => ({
    type: Types.LOGOUT_REQUEST,
    payload: {},
  }),

  listRequest: (data) => ({
    type: Types.LIST_REQUEST,
    payload: data,
  }),

  listSuccess: (data) => ({
    type: Types.LIST_SUCCESS,
    payload: data,
  }),

  editRequest: (user) => ({
    type: Types.EDIT_REQUEST,
    payload: user,
  }),

  editSuccess: () => ({
    type: Types.EDIT_SUCCESS,
  }),

  listRequestUsersEmpOrg: (empresa) => ({
    type: Types.LIST_REQUEST_USERS_EMPRESAS_ORGANIZACAO,
    payload: empresa,
  }),

  listSuccessRequestUsersEmpOrg: (usuarios) => ({
    type: Types.LIST_SUCCESS_USERS_EMPRESAS_ORGANIZACAO,
    payload: usuarios,
  }),

  listRequestUsersAccessGroup: (accessgroup) => ({
    type: Types.LIST_BY_ACCESS_GROUP_REQUEST,
    payload: accessgroup,
  }),

  listSuccessRequestUsersAccessGroup: (usuarios) => ({
    type: Types.LIST_BY_ACCESS_GROUP_SUCCESS,
    payload: usuarios,
  }),

  editEmpresaRequest: (empresa) => ({
    type: Types.EDIT_EMPRESA_REQUEST,
    payload: empresa,
  }),

  editEmpresaSuccess: (auth) => ({
    type: Types.EDIT_EMPRESA_SUCCESS,
    payload: auth,
  }),

  changePasswordRequest: (userData) => ({
    type: Types.CHANGE_PASSWORD_REQUEST,
    payload: userData,
  }),

  changePasswordSuccess: () => ({
    type: Types.CHANGE_PASSWORD_SUCCESS,
  }),

  forgotPasswordRequest: (info) => ({
    type: Types.FORGOT_PASSWORD_REQUEST,
    payload: info,
  }),

  forgotPasswordSuccess: () => ({
    type: Types.FORGOT_PASSWORD_SUCCESS,
  }),

  listRequestByDepto: (usersdepto) => ({
    type: Types.LIST_REQUEST_BY_DEPTO,
    payload: usersdepto,
  }),

  listSuccessByDepto: (usersdepto) => ({
    type: Types.LIST_SUCCESS_BY_DEPTO,
    payload: usersdepto,
  }),

  cleanError: () => ({
    type: Types.CLEAN_REQUEST,
    payload: {},
  }),

  validateTokenRequest: (info) => ({
    type: Types.VALIDATE_TOKEN_REQUEST,
    payload: info,
  }),

  validadeTokenSuccess: (data) => ({
    type: Types.VALIDATE_TOKEN_SUCCESS,
    payload: data,
  }),

  changePasswordByLoginRequest: (userData) => ({
    type: Types.CHANGE_PASSWORD_BY_LOGIN_REQUEST,
    payload: userData,
  }),

  changePasswordByLoginSuccess: () => ({
    type: Types.CHANGE_PASSWORD_BY_LOGIN_SUCCESS,
  }),

  changePasswordByNewPassRequest: (userData) => ({
    type: Types.CHANGE_PASSWORD_BY_NEW_PASS_REQUEST,
    payload: userData,
  }),

  changePasswordByNewPassSuccess: () => ({
    type: Types.CHANGE_PASSWORD_BY_NEW_PASS_SUCCESS,
  }),

  setOrganization: (payload) => ({
    type: Types.SET_ORGANIZATION,
    payload,
  }),

  searchByNameRequest: (payload) => ({
    type: Types.SEARCH_BY_NAME_REQUEST,
    payload,
  }),

  searchByNameSuccess: (payload) => ({
    type: Types.SEARCH_BY_NAME_SUCCESS,
    payload,
  }),

  toggleAnylystsLoading: (payload) => ({
    type: Types.TOGGLE_ANALYSTS_LOADING,
    payload
  }),

  twoFactorRequest: (payload) => ({
    type: Types.TWO_FACTOR_REQUEST,
    payload
  }),

  twoFactorSuccess: (payload) => ({
    type: Types.TWO_FACTOR_SUCCESS,
    payload
  })
};
