import { all, call, put, select, takeLatest } from "redux-saga/effects";

import { toastr } from "react-redux-toastr";

import api from "../../services/api";
import { getToken } from "../../services/auth";
import { Creators as WhatsappActions, Types } from "../ducks/whatsapp";

export const getOrganization = (state) => state.usuarios.auth.organization;
export const getUser = (state) => state.usuarios;

export function* createWhatsappMessage(action) {
  const organization = yield select(getOrganization);

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    let body = {
      ...action.payload,
    };

    console.log("BODY DE ENVIO:::::", body)

    const { data } = yield call(api.post, `whatsapp`, body, headerParams);

    yield put(WhatsappActions.createSuccess(data));

    toastr.success("", "Mensagem enviada com sucesso.");
  } catch (error) {
    toastr.error("Ops", "Não foi possível enviar a mensagem.");
  }
}


export default all([
  takeLatest(Types.NEW_REQUEST, createWhatsappMessage)
]);
