import styled from 'styled-components';

export const Container = styled.div`
  h4 {
    font-size: 16px;
    border-bottom: 1px solid #ccc;
    padding: 10px 0;
    margin-bottom: 10px;
  }
`;

export const Field = styled.div`
  margin-top: 15px;
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 15px;

  button {
    width: 45%;
  }
`;
