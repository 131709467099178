import { call, put, select } from "redux-saga/effects";

import { toastr } from "react-redux-toastr";
import { push } from "connected-react-router";

import api from "../../services/api";
import { getToken } from "../../services/auth";

import { Creators as TipoDepartamentosActions } from "../ducks/tipodepartamento";

export const getOrganization = (state) => state.usuarios.auth.organization;
export const getUserId = (state) => state.usuarios.auth.id;

export function* listTipoDepartamentos({ payload }) {
    try {
        const organization = yield select(getOrganization);

        const { page, limit, sort, all, search } = payload;

        const headerParams = {
            headers: { Authorization: getToken(), xkeyorg: organization },
            params: { page, limit, sort, all, search },
        };

        const response = yield call(api.get, `tipodepartamento`, headerParams);

        const data = {
            total: response.data.total,
            list: response.data.docs,
        };

        yield put(TipoDepartamentosActions.listSuccess(data));
    } catch (error) {
        toastr.error("Ops", "Não foi possível listar os tipos de departamentos.");
    }
}

export function* getTipoDepartamento(action) {
    try {
        const headerParams = {
            headers: { Authorization: getToken() },
        };

        const { data } = yield call(api.get, `tipodepartamento/${action.payload}`, headerParams);

        yield put(TipoDepartamentosActions.getSuccess(data));
    } catch (error) {
        toastr.error("Ops", "Não foi possível buscar os dados do departamento.");
    }
}

export function* editTipoDepartamento(action) {
    const userId = yield select(getUserId);

    try {
        const organization = yield select(getOrganization);

        const headerParams = {
            headers: { Authorization: getToken(), xkeyorg: organization },
        };

        const body = { ...action.payload, id_user_acao: userId };

        const { data } = yield call(api.put, `tipodepartamento/${action.payload._id}`, body, headerParams);

        yield put(push(`/administracao/tipodepartamento`));

        yield put(TipoDepartamentosActions.editSuccess(data));

        toastr.success("TipoDepartamento editado com sucesso.");
    } catch (error) {
        toastr.error("Ops", "Não foi possível editar o tipo de departamento.");
    }
}

export function* createTipoDepartamento(action) {
    const organization = yield select(getOrganization);

    try {
        const headerParams = {
            headers: { Authorization: getToken(), xkeyorg: organization },
        };

        const { data } = yield call(api.post, `tipodepartamento`, action.payload, headerParams);

        yield put(push(`/administracao/tipodepartamento`));
        yield put(TipoDepartamentosActions.createSuccess(data));

        toastr.success("", "Tipo de departamento criado com sucesso.");
    } catch (error) {
        toastr.error("Ops", "Não foi possível criar o tipo de departamento.");
    }
}

export function* removeTipoDepartamento(action) {
    try {
        const organization = yield select(getOrganization);
        const userId = yield select(getUserId);

        const headerParams = {
            headers: { Authorization: getToken(), xkeyorg: organization, userId },
        };

        const { data } = yield call(api.delete, `tipodepartamento/${action.payload}`, headerParams);

        if (data.error) toastr.warning("Ops", data.message);
        else toastr.success("", "Tipo de departamento removido com sucesso.");

        yield put(
            TipoDepartamentosActions.listRequest({
                page: 1,
                limit: 10,
                sort: "nome",
            })
        );
    } catch (error) {
        toastr.error("Ops", "Não foi possível remover o tipo de departamento.");
    }
}
