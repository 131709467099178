import React, { useState, useEffect, useCallback } from "react";
import ModalFormulario from "pages/Formulario/ModalFormulario";
import '../../styles.css';
import fileToBase64 from "services/fileToBase64";
import { getUploadAndPresignedUrl } from "services/uploadAndPresignedUrl";

const ETAPA_PREENCHIMENTO = 0;

export default function FormIndexadores({ initialData = {}, lockScreen, onSave }) {

    const { nome, mensagem } = initialData;

    const [validate, setValidate] = useState(false);
    const [tipoDocumento, setTipoDocumento] = useState(null);
    const [indexadores, setIndexadores] = useState([]);
    const [etapas, setEtapas] = useState([]);
    const [files, setFiles] = useState([]);
    const [tiposDeImg, setTiposDeImg] = useState([]);
    const [activeStep, setActiveStep] = useState(ETAPA_PREENCHIMENTO);

    //#region funções auxiliares
    function rule(indexador, valida = false) {
        return valida && indexador.obrigatorio && !indexador.valor
    }

    function hasIndexadorError(indexador) {
        return rule(indexador, validate)
    }

    function hasIndexadorTextError(indexador) {
        if (rule(indexador, validate)) return "Campo obrigatório";
        return "";
    }

    function handleChangeIndexador({ target }) {
        const { name, value } = target;
        const newIndexadores = indexadores.map((indexador) => {
            return ({
                ...indexador,
                valor: indexador.nomeFormulario === name ? value : indexador.valor,
            })
        });
        setIndexadores(newIndexadores);
    }

    async function handleSave() {

        setValidate(true);

        const found = indexadores.some(indexador => indexador.obrigatorio && !indexador.valor);

        if (found) {
            setActiveStep(ETAPA_PREENCHIMENTO);
            return;
        }

        if (files?.length > 0) {
            const arquivosPromisses = files.map((file) => fileToBase64(file));
            const arquivosPrometidos = await Promise.all(arquivosPromisses);

            const arquivosAssinados = arquivosPrometidos.map(async (file) => {
                const filepath = await getUploadAndPresignedUrl(file);
                return { path: filepath };
            });

            const arquivos = await Promise.all(arquivosAssinados);

            onSave({ indexadores, files: arquivos });
        } else {
            onSave({ indexadores, files: [] });
        }
    }

    const onDrop = useCallback(
        (arquivos) => {
            let attachments = [];
            for (const file of arquivos) {
                const { path, type } = file;
                const fileExists = files?.some(
                    (x) => x.path === path && x.type === type
                );
                if (!fileExists) attachments.push(file);
            }
            setFiles([...files, ...attachments]);
        },
        [files, setFiles]
    );

    const onDeleteFile = (file) => {
        setFiles(
            files?.filter((item) => item !== file),
        );
    };
    //#endregion

    //#region efeitos
    useEffect(() => {
        const { campos, tipoDocumento: tipoDoc, steps, tiposDeImagem } = initialData;
        setTipoDocumento(tipoDoc);
        setIndexadores(campos);
        setEtapas(steps);
        setTiposDeImg(tiposDeImagem);
    }, [initialData]);
    //#endregion

    return (
        <ModalFormulario
            open={true}
            title={nome}
            steps={etapas}
            campos={indexadores}
            tiposDeImagem={tiposDeImg}
            mensagem={mensagem}
            disabled={lockScreen}
            handleSave={handleSave}
            activeStep={activeStep}
            files={files}
            onDrop={onDrop}
            onDeleteFile={onDeleteFile}
            indexadores={tipoDocumento ? tipoDocumento.indexadores : []}
            hasIndexadorError={hasIndexadorError}
            hasIndexadorTextError={hasIndexadorTextError}
            handleChangeIndexador={handleChangeIndexador}
        />
    );
}
