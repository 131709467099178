import React from "react";
import { INITIAL_FORM } from "../Util";
import HandleFormulario from "../Util/handleFormulario";

export default function Formulario() {
    return (
        <HandleFormulario
            idFormulario={null}
            initialData={INITIAL_FORM}
        />
    )
}
