import React from "react";
import { differenceInSeconds, parseISO } from "date-fns";

const DiffDates = ({ date1, date2 }) => {
  const startDate = parseISO(date1);
  const endDate = parseISO(date2);

  const formatDurationHHMMSS = (totalSeconds) => {
    const totalMinutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    const totalHours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    const pad = (num) => String(num).padStart(2, "0");
    return `${pad(totalHours)}:${pad(minutes)}:${pad(seconds)}`;
  };

  const calculateDiff = () => {
    const totalSeconds = differenceInSeconds(endDate, startDate);
    return formatDurationHHMMSS(totalSeconds);
  };

  return <div>{calculateDiff()}</div>;
};

export default DiffDates;
