import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ContainerMessage } from './style';
import { InfoIcon } from 'components/Icons';
import { BeatLoader } from 'react-spinners';

import { Creators as DashboardExternoActions } from 'store/ducks/dashboardexterno';
import { Row, Col, Form } from 'react-bootstrap';

import { Creators as ConfigActions } from 'store/ducks/configuracaoDashboardExterno';

import '../styles.css';

export function ExternalDashboard() {
  const iframeRef = useRef(null);

  const dispatch = useDispatch();

  const [dashboard, setDashboard] = useState(null);

  const { loading, data } = useSelector(state => state.dashboardexterno);

  const { docs: configs, loading: loadingConfig } = useSelector(state => state.configuracaoDashboardExterno);

  useEffect(() => {
    dispatch(
      ConfigActions.listRequest({
        page: 1,
        limit: 100,
        search: '',
      })
    );
    dispatch(DashboardExternoActions.newRequest({ dashboard }));
  }, [dispatch, dashboard]);

  const styleLoader = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '300px',
  };

  const showIframe = !loading && data && data.iframeUrl;

  useEffect(() => {
    const handleIframeLoad = () => {
      const iframe = iframeRef.current;
      if (iframe) {
        const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;

        const style = iframeDocument.createElement('style');
        style.innerHTML = `
          .Card {
            border: 1px solid #000;
          }
        `;
        iframeDocument.head.appendChild(style);
      }
    };

    const iframe = iframeRef.current;
    if (iframe) {
      iframe.addEventListener('load', handleIframeLoad);
    }

    // Cleanup event listener
    return () => {
      if (iframe) {
        iframe.removeEventListener('load', handleIframeLoad);
      }
    };
  }, [dashboard]);

  return (
    <>
      <div style={{ textAlign: 'right' }}>
        <Row>
          <Col lg="4" sm="12" className="offset-lg-8">
            {!!loadingConfig ? (
              <BeatLoader sizeUnit={'px'} size={10} color={'#444444'} loading={!!loadingConfig} />
            ) : (
              <Form.Control as="select" onChange={e => setDashboard(e.target.value)}>
                <option value="">Selecione o dashboard desejado</option>
                {configs.map(config => (
                  <option key={config.id} value={config._id}>
                    {config.shortname}
                  </option>
                ))}
              </Form.Control>
            )}
          </Col>
        </Row>
      </div>
      <div className="card mt-2" style={styleLoader}>
        {showIframe ? (
          <iframe
            src={data.iframeUrl}
            style={{ border: 'none' }}
            title="Visualizador de documentos"
            width="100%"
            height="800px"
            ref={iframeRef}
          />
        ) : (
          <ContainerMessage>
            <div>
              <InfoIcon />
            </div>
            <h4>Sem dashboards para exibir</h4>
          </ContainerMessage>
        )}
      </div>
    </>
  );
}
