import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './styles.css';

import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Menu from '@material-ui/core/Menu';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import { Link } from 'react-router-dom';

import { toastr } from 'react-redux-toastr';

import bcrypt from 'bcryptjs';

import { Modal } from 'react-bootstrap';

import { Form } from '@unform/web';

import InputForm from 'components/InputForm';

import ButtonMui from '@material-ui/core/Button';

import SimpleSearch from './SimpleSearch';
import MobileSearch from './MobileSearch';
import Notificacoes from './Notificacoes';

import History from 'routes/history';

import { Creators as MensagensActions } from "store/ducks/mensagens";
import { Creators as UsuariosActions } from "../../store/ducks/usuarios";
import { Creators as ProvidenciasActions } from "store/ducks/providencias";
import { MaximizeIcon, MessageIcon, MinimizeIcon } from "components/Icons";
import { getToken } from "../../services/auth";

const useStyles = makeStyles((theme) => ({
  blueBg: {
    color: '#fff',
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '32px',
    height: '32px',
  },
  whiteBg: {
    color: '#5f6368',
    backgroundColor: '#fff',
  },
  customWidth: {
    '& div': {
      // this is just an example, you can use vw, etc.
      width: '350px',
    },
  },
}));

export default function Navbar({ whithoutNotificationIcon = false }) {
  const formRef = useRef(null);

  const [validaSenhas, setValidaSenhas] = useState(true);
  const [senha1, setSenha1] = useState();
  const [senha2, setSenha2] = useState();
  const [isFullSize, setIsFullSize] = useState((document.fullScreenElement && document.fullScreenElement === null) ||
    (!document.mozFullScreen && !document.webkitIsFullScreen));

  const userId = useSelector(state => state.usuarios.auth.id);
  const organization = useSelector(state => state.usuarios.auth.organization);

  const idUser = useSelector((state) => state.usuarios.auth.id);
  const isOpen = useSelector((state) => state.menu.isOpen);

  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const [searchText, setSearchText] = useState('');
  const usuario = useSelector((state) => state.usuarios.auth);
  const { naoLidas } = useSelector((state) => state.mensagens);

  const { naoLidas: tarefasNaoLidas } = useSelector(
    (state) => state.providencias
  );

  const [showModal, setShowModal] = useState(false);

  const dispatch = useDispatch();

  const redirectFlowBuilder = () => {
    return `${process.env.REACT_APP_FLOWBUILDER}/checkin/${organization}/${getToken()}/${userId}`;
  };

  const handleLogout = () => {
    dispatch(UsuariosActions.logoutRequest());
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleChangePassowrd = async (values) => {
    const { senhaatual, novasenha1, novasenha2 } = values;

    if (novasenha1 !== novasenha2) {
      toastr.error(
        'Falha ao trocar a senha',
        'As senhas digitadas precisam ser iguais.'
      );

      return;
    } else if (novasenha1 === '' || novasenha2 === '') {
      toastr.error(
        'Ops',
        'Os campos de Nova Senha e Confirmação de nova senha precisam estar preenchidos.'
      );

      return;
    } else {
      const hashingPass = async (password) => {
        const salt = await bcrypt.genSalt(10);
        const passHash = await bcrypt.hash(password, salt);
        const editedPass = passHash;

        return editedPass;
      };

      let passHashed = await hashingPass(novasenha1);

      dispatch(
        UsuariosActions.changePasswordByNewPassRequest({
          senhaAtualDigitada: senhaatual,
          usuario: idUser,
          changePassword: false,
          senha: passHashed,
        })
      );

      setShowModal(false);
    }
  };

  const handleChangeSenha1 = (e) => {
    if (e.target.value) {
      setSenha1(e.target.value);
    }
  };

  const handleChangeSenha2 = (e) => {
    if (e.target.value) {
      setSenha2(e.target.value);
    }
  };

  const handleClickMessages = () => {
    History.push('/mensagens');
  };

  function Fullscreen() {
    if (isFullSize) {
      if (document.documentElement.requestFullScreen) {
        document.documentElement.requestFullScreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullScreen) {
        document.documentElement.webkitRequestFullScreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
    setIsFullSize(!isFullSize);
  }

  useEffect(() => {
    if (senha1 === senha2) {
      setValidaSenhas(true);
    } else {
      setValidaSenhas(false);
    }
  }, [senha1, senha2]);

  useEffect(() => {
    dispatch(MensagensActions.unreadRequest());
    dispatch(ProvidenciasActions.unreadRequest());
  }, [dispatch]);

  return (
    <div className="topbar-menu">
      <div className="topbar-fixed">
        <div className={`topbar ${isOpen ? 'small' : ''}`}>
          <div className="defaulttop">
            <div className="search">
              <SimpleSearch
                setSearchText={setSearchText}
                searchText={searchText}
              />
            </div>
            <div className="actions">
              <div className="action-menu">
                <Badge
                  badgeContent={0}
                  showZero={false}
                  variant='dot'
                  color="secondary"
                  style={{
                    marginTop: '10px',
                    cursor: 'pointer',
                  }}
                  onClick={Fullscreen}
                >
                  {!isFullSize ? <MinimizeIcon size={18} /> : <MaximizeIcon size={18} />}
                </Badge>
              </div>
              {!whithoutNotificationIcon && (
                <div className="action-menu">
                  <Badge
                    badgeContent={naoLidas + tarefasNaoLidas}
                    color="secondary"
                    style={{
                      marginTop: '10px',
                      cursor: 'pointer',
                    }}
                    onClick={handleClickMessages}
                  >
                    <MessageIcon size={18} />
                  </Badge>
                </div>
              )}

              <div className="action-menu">
                <Notificacoes />
              </div>

              <div className="action-menu">
                <Avatar
                  className={classes.blueBg}
                  onClick={(e) => handleMenuClick(e, 'profile')}
                  sx={{ width: 24, height: 24 }}
                >
                  {usuario.name ? usuario.name.substring(0, 1) : 'U'}
                </Avatar>
                <Menu
                  id="profile-menu"
                  keepMounted
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                  getContentAnchorEl={null}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                  <MenuItem>
                    <a href={redirectFlowBuilder()}>
                      Flow Builder
                    </a>
                  </MenuItem>
                  <MenuItem>
                    <Link
                      to={{
                        pathname: '/meus-arquivos',
                      }}
                      className="linkicon"
                    >
                      Meus arquivos
                    </Link>
                  </MenuItem>
                  <MenuItem onClick={() => setShowModal(true)}>
                    Trocar senha
                  </MenuItem>
                  <MenuItem onClick={handleLogout}>Sair</MenuItem>
                </Menu>
              </div>
            </div>
          </div>

          <MobileSearch setSearchText={setSearchText} searchText={searchText} />
        </div>
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Form
          initialData={{ fantasia: '' }}
          ref={formRef}
          onSubmit={handleChangePassowrd}
        >
          <Modal.Header>
            <Modal.Title>Alteração de Senha</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="text-modal">
              <strong>Insira a sua senha atual:</strong>
            </div>

            <InputForm label="Senha Atual*" name="senhaatual" type="password" />

            <InputForm
              label="Nova Senha*"
              name="novasenha1"
              type="password"
              onChange={(e) => handleChangeSenha1(e)}
              className={validaSenhas === false ? 'color-validation' : ''}
            />

            <InputForm
              label="Confirme a nova senha*"
              name="novasenha2"
              type="password"
              onChange={(e) => handleChangeSenha2(e)}
              className={validaSenhas === false ? 'color-validation' : ''}
            />
          </Modal.Body>
          <Modal.Footer>
            <ButtonMui variant="contained" color="primary" type="submit">
              Enviar
            </ButtonMui>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
}
