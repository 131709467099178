import React, { useEffect, useState } from 'react';
import {
  List,
  ListItem,
  Avatar,
  Typography,
  TablePagination,
  Checkbox,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import Skeleton from '@material-ui/lab/Skeleton';
import _ from '@lodash';
import { parseISO, format } from 'date-fns';
import pt from 'date-fns/locale/pt';

import Tarefa from './Tarefa';
import Aprovado from './Tarefa/Aprovado';
import Reprovado from './Tarefa/Reprovado';

import './styles.css';

import { Creators as ProvidenciasActions } from 'store/ducks/providencias';
import Utils from 'utils/utils';
import { DraftsIcon, MailUnreadedIcon } from 'components/Icons';

function Tarefas({ endpoint }) {
  const dispatch = useDispatch();

  const { list, loading, total, page, limit, sort } = useSelector(
    (state) => state.providencias
  );

  const [tarefa, setTarefa] = useState(null);
  const [selecionadas, setSelecionadas] = useState([]);

  useEffect(() => {
    const params = {
      page: 1,
      limit: 10,
      sort: '-createdAt',
    };
    if (endpoint === 1)
      dispatch(ProvidenciasActions.listReceivedRequest(params));

    if (endpoint === 2) dispatch(ProvidenciasActions.listSentRequest(params));
  }, [dispatch, endpoint]);

  useEffect(() => {
    if (tarefa && !tarefa.lida && endpoint === 1)
      dispatch(ProvidenciasActions.markAsReadRequest(tarefa._id));
  }, [dispatch, tarefa, endpoint]);

  function handleChangeRowsPerPage({ target }) {
    const { value } = target;
    const params = {
      page: 1,
      limit: value,
      sort: '-createdAt',
    };
    if (endpoint === 1)
      dispatch(ProvidenciasActions.listReceivedRequest(params));

    if (endpoint === 2) dispatch(ProvidenciasActions.listSentRequest(params));
  }

  function handleChangePage(e, newPage) {
    const params = {
      page: newPage + 1,
      limit,
      sort: '-createdAt',
    };
    if (endpoint === 1)
      dispatch(ProvidenciasActions.listReceivedRequest(params));

    if (endpoint === 2) dispatch(ProvidenciasActions.listSentRequest(params));
  }

  function handleCheck({ target }) {
    if (target.checked) setSelecionadas([...selecionadas, target.id]);

    if (!target.checked)
      setSelecionadas(selecionadas.filter((_id) => _id !== target.id));
  }

  function handleCheckUncheckAll({ target }) {
    if (target.checked) setSelecionadas(list.map(({ _id }) => _id));

    if (!target.checked) setSelecionadas([]);
  }

  function markAsRead() {
    selecionadas.forEach((_id) => {
      if (list.some((tarefa) => tarefa._id === _id && !tarefa.lida))
        dispatch(ProvidenciasActions.markAsReadRequest(_id));
    });
    setSelecionadas([]);
  }

  function handleApproveDisapprove() {
    const params = {
      page,
      limit,
      sort,
    };
    if (endpoint === 1)
      dispatch(ProvidenciasActions.listReceivedRequest(params));

    if (endpoint === 2) dispatch(ProvidenciasActions.listSentRequest(params));

    setTarefa(null);
  }

  return (
    <div className="painel-content tarefas-container">
      {!tarefa && !!loading && (
        <>
          <div className="tarefas-actions">
            <Checkbox
              tabIndex={-1}
              disableRipple
              color="primary"
              disabled={true}
            />
            <Tooltip title="Marcar como lida">
              <IconButton disabled={true}>
                <DraftsIcon />
              </IconButton>
            </Tooltip>
          </div>
          <List className="tarefas-list">
            {[0, 1, 2, 3, 4, 5, 6].map((el) => (
              <ListItem dense button className="tarefas-item" key={el}>
                <Skeleton width={15} />
                <div>
                  <div className="tarefas-item-header">
                    <div className="tarefas-item-avatar">
                      <Skeleton variant="circle" width={40} height={40} />
                      <Skeleton
                        variant="text"
                        width={160}
                        style={{ marginLeft: '8px' }}
                      />
                    </div>
                    <Skeleton variant="text" width={80} />
                  </div>
                  <div className="tarefas-item-body">
                    <Skeleton variant="text" />
                    <Skeleton variant="rect" width="100%" height={50} />
                  </div>
                  <div className="tarefas-item-footer">
                    <Skeleton variant="text" width={240} />
                  </div>
                </div>
              </ListItem>
            ))}
          </List>
        </>
      )}
      {!tarefa && !loading && (
        <>
          <div className="tarefas-actions">
            <Checkbox
              tabIndex={-1}
              disableRipple
              color="primary"
              onChange={handleCheckUncheckAll}
            />
            <Tooltip title="Marcar como lida">
              <IconButton
                onClick={markAsRead}
                disabled={selecionadas.length === 0 || endpoint === 2}
              >
                <DraftsIcon />
              </IconButton>
            </Tooltip>
          </div>
          {list.length === 0 && (
            <div className="tarefas-aviso">
              <Typography variant="h3">Não há tarefas =(</Typography>
            </div>
          )}
          {list.length > 0 && (
            <List className="tarefas-list">
              {list.map((tarefa) => (
                <ListItem
                  dense
                  button
                  className={
                    tarefa.lida
                      ? 'tarefas-item'
                      : 'tarefas-item tarefas-nao-lida'
                  }
                  onClick={() => setTarefa(tarefa)}
                  key={tarefa._id}
                >
                  <Checkbox
                    id={tarefa._id}
                    tabIndex={-1}
                    disableRipple
                    onClick={(e) => e.stopPropagation()}
                    color="primary"
                    checked={selecionadas.some((_id) => _id === tarefa._id)}
                    onChange={handleCheck}
                  />
                  <div>
                    <div className="tarefas-item-header">
                      <div className="tarefas-item-avatar">
                        <Avatar>
                          {endpoint === 1
                            ? tarefa.userAcao
                              ? Utils.getNameInitials(tarefa.userAcao.nome)
                              : '? ?'
                            : Utils.getNameInitials(
                                tarefa.participantes[0].nome
                              )}
                        </Avatar>
                        <Typography variant="subtitle1">
                          {endpoint === 1
                            ? tarefa.userAcao
                              ? tarefa.userAcao.nome
                              : '???????'
                            : tarefa.participantes[0].nome}
                        </Typography>
                      </div>
                      <div className="tarefas-item-header-details">
                        {tarefa.aprovado === true && <Aprovado />}
                        {tarefa.aprovado === false && <Reprovado />}
                        {!tarefa.lida ? <MailUnreadedIcon /> : <DraftsIcon />}
                        <Typography variant="subtitle1">
                          {format(parseISO(tarefa.createdAt), 'dd MMM', {
                            locale: pt,
                          })}
                        </Typography>
                      </div>
                    </div>
                    <div className="tarefas-item-body">
                      <Typography
                        className={
                          !tarefa.lida ? 'tarefas-titulo-nao-lido' : ''
                        }
                      >
                        {tarefa.tipoTarefa.nome}
                      </Typography>
                      <Typography color="textSecondary">
                        {_.truncate(tarefa.descricao, { length: 280 })}
                      </Typography>
                    </div>
                    <div className="tarefas-item-footer">
                      <Typography
                        className="tarefas-item-numero-processo"
                        color="textSecondary"
                      >
                        Nr. do cliente:{' '}
                        {tarefa.documento ? tarefa.documento.numeroCNJ : ''}
                      </Typography>
                    </div>
                  </div>
                </ListItem>
              ))}
            </List>
          )}
          <TablePagination
            style={{
              float: 'right',
            }}
            count={total}
            page={page - 1}
            rowsPerPage={limit}
            rowsPerPageOptions={[10, 25, 50, 100]}
            labelRowsPerPage="Linhas por página"
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} de ${count !== -1 ? count : `mais que ${to}`}`
            }
            onRowsPerPageChange={handleChangeRowsPerPage}
            onPageChange={handleChangePage}
          />
        </>
      )}
      {!!tarefa && (
        <Tarefa
          onBack={() => setTarefa(null)}
          tarefa={tarefa}
          avatar={
            endpoint === 1
              ? tarefa.userAcao
                ? tarefa.userAcao.nome
                : '??????? ???????'
              : tarefa.participantes[0].nome
          }
          disableButtons={endpoint !== 1}
          onApproveDisapprove={handleApproveDisapprove}
        />
      )}
    </div>
  );
}

export default Tarefas;
