import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import BackButton from "components/BackButton";
import InputForm from "components/InputForm";
import { Dropdown } from "components/Dropdown";
import SwitchForm from "components/SwitchForm";

import { Grid, Button, CircularProgress } from "@material-ui/core";

import { Row, Col } from "react-bootstrap";
import { Form } from "@unform/web";
import { ValidationError } from "yup";

import CategoriaSchema from "validators/CategoriaSchema";
import { Creators as CategoriasActions } from "store/ducks/categorias";

import "./styles.css";
import Content from "components/Content";

const INITIAL_STATE = {
    nome: '',
    tipo: 'INTERNA',
    status: true,
};

function Line({ children }) {
    return (
        <Row className="row">
            <Col md={6}>
                {children}
            </Col>
        </Row>
    )
}

export default function FormCategoria() {

    const dispatch = useDispatch();
    const formRef = useRef(INITIAL_STATE);

    const [valores, setValores] = useState(INITIAL_STATE);
    const { loading } = useSelector((state) => state.categorias);

    const onSubmit = async (values) => {

        const categoria = {
            ...valores,
            ...values,
        };

        try {
            await CategoriaSchema.validate(categoria, {
                abortEarly: false,
            });
            dispatch(CategoriasActions.createRequest(categoria));
        } catch (err) {
            const validationErrors = {};
            if (err instanceof ValidationError) {
                err.inner.forEach((error) => {
                    validationErrors[error.path] = error.message;
                });
                formRef.current.setErrors(validationErrors);
            }
        }
    };

    return (
        <Content>
            <Row>
                <Col md="12" className="painel">
                    <div className="painel-content">
                        <div className="painel-title text-left">Novo Categoria</div>
                        <div className="painel-body">
                            <Form
                                ref={formRef}
                                onSubmit={onSubmit}
                                initialData={{ ...valores }}
                            >

                                <Line>
                                    <InputForm name="nome" label="Nome" />
                                </Line>

                                <Line>
                                    <div className="line-components">
                                        <label>Tipo</label>
                                        <Dropdown
                                            options={[
                                                { label: "Interna", value: 'INTERNA' },
                                                { label: "Externa", value: 'EXTERNA' },
                                            ]}
                                            value={valores.tipo}
                                            onChange={e => {
                                                setValores({ ...valores, tipo: e.target.value });
                                            }}
                                            style={{
                                                fontSize: "14px",
                                                padding: "0.8rem",
                                            }}
                                        />
                                    </div>
                                </Line>

                                <Line>
                                    <SwitchForm color="primary" name="status" label={"Ativa"} />
                                </Line>

                                <Row>
                                    <Grid container spacing={1}>
                                        <Grid item>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                type="submit"
                                                disabled={loading}
                                            >
                                                Enviar
                                                {!!loading && <CircularProgress size={20} />}
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <BackButton variant="contained" color="secondary">
                                                Voltar
                                            </BackButton>
                                        </Grid>
                                    </Grid>
                                </Row>
                            </Form>
                        </div>
                    </div>
                </Col>
            </Row>
        </Content>
    );
}
