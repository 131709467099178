export const ACTIONS = {
    OPEN_MODAL_SAVE: '@peticionamento/OPEN_MODAL_SAVE',
    OPEN_MODAL_PROTOCOL: '@peticionamento/OPEN_MODAL_PROTOCOL',
    OPEN_MODAL_AR: '@peticionamento/OPEN_MODAL_AR',
    OPEN_MODAL_PREVIEW_DOCUMENTS: '@peticionamento/OPEN_MODAL_OPEN_MODAL_PREVIEW_DOCUMENTS',
    OPEN_MODAL_CREATE_PARAGRAPH: '@peticionamento/OPEN_MODAL_CREATE_PARAGRAPH',
    CLOSE_MODAL: '@peticionamento/CLOSE_MODAL',
    DOCUMENT_SELECT_TYPE: '@peticionamento/DOCUMENT_SELECT_TYPE',
    DOCUMENT_SELECT_MODEL: '@peticionamento/DOCUMENT_SELECT_MODEL',
    DOCUMENT_OPEN: '@peticionamento/DOCUMENT_OPEN',
    SET_TEMPLATES_LOADING: '@peticionamento/SET_TEMPLATES_LOADING',
    GET_TEMPLATES_REQUEST: '@peticionamento/GET_TEMPLATES_REQUEST',
    GET_TEMPLATES_SUCCESS: '@peticionamento/GET_TEMPLATES_SUCCESS',
    SET_PARAGRAPHS_LOADING: '@peticionamento/SET_PARAGRAPHS_LOADING',
    GET_PARAGRAPHS_REQUEST: '@peticionamento/GET_PARAGRAPHS_REQUEST',
    GET_PARAGRAPHS_SUCCESS: '@peticionamento/GET_PARAGRAPHS_SUCCESS',
    SAVE_PARAGRAPH_REQUEST: '@peticionamento/SAVE_PARAGRAPH_REQUEST',
    SAVE_PARAGRAPH_SUCCESS: '@peticionamento/SAVE_PARAGRAPH_SUCCESS',
    UPDATE_PARAGRAPH_LIST: '@peticionamento/UPDATE_PARAGRAPH_LIST',
    UPDATE_SINGLE_PARAGRAPH_REQUEST: '@peticionamento/UPDATE_SINGLE_PARAGRAPH_REQUEST',
    UPDATE_SINGLE_PARAGRAPH_SUCCESS: '@peticionamento/UPDATE_SINGLE_PARAGRAPH_SUCCESS',
    DELETE_SINGLE_PARAGRAPH_REQUEST: '@peticionamento/DELETE_SINGLE_PARAGRAPH_REQUEST',
    DELETE_SINGLE_PARAGRAPH_SUCCESS: '@peticionamento/DELETE_SINGLE_PARAGRAPH_SUCCESS',
    UPDATE_MODEL_BODY: '@peticionamento/UPDATE_MODEL_BODY',
    REPLACE_VARIABLES: '@peticionamento/REPLACE_VARIABLES',
    EXPORT_AS_HTML: '@peticionamento/EXPORT_AS_HTML',
    SET_LOADING: '@peticionamento/SET_LOADING',
    SET_DOCUMENT_SAVE_LOADING: '@peticionamento/SET_DOCUMENT_SAVE_LOADING',
    OPEN_AR_IN_NEW_WINDOW: '@MODAL_AR/LOCAL_ACTION/OPEN_AR_IN_NEW_WINDOW',
}

const initialState = {
    saveDialogOpen: false,
    protocolDialogOpen: false,
    modalAROpen: false,
    modalAddTemplateChunksData: null,
    modalCreateParagraphOpen: false,
    modalPreviewDocuments: false,
    selectedTemplateType: null,
    selectedDocumentType: '',
    documentModels: [],
    selectedDocumentModel: null,
    paragraphs: [],
    loading: false,
    paragraphsLoading: false,
    templatesLoading: false,
    documentSaveLoading: false,
}

function replaceVariables(selectedModel, variables) {

    let newSelectedModel = {...selectedModel};

    Object.keys(variables).forEach(variableName => {
        if (!variables[variableName]) {
            return;
        }
        const re = new RegExp(`{{ ${variableName} }}`, 'gi');

        newSelectedModel.header = newSelectedModel.header.map(item => item.replace(re, variables[variableName]));
        newSelectedModel.footer = newSelectedModel.footer.map(item => item.replace(re, variables[variableName]));
        newSelectedModel.body = newSelectedModel.body.map(item => ({
            ...item,
            data: item.data.map(chunk => chunk.replace(re, variables[variableName])),
        }));
    });

    return newSelectedModel;
}

export default function reducer(state = initialState, action) {

    switch (action.type) {
        case ACTIONS.OPEN_MODAL_SAVE:
            return {
                ...state,
                saveDialogOpen: true,
            }

        case ACTIONS.OPEN_MODAL_PROTOCOL:
            return {
                ...state,
                protocolDialogOpen: true,
            }

        case ACTIONS.OPEN_MODAL_AR:
            return {
                ...state,
                modalAROpen: true,
            } 
            
        case ACTIONS.OPEN_MODAL_CREATE_PARAGRAPH:
            return {
                ...state,
                modalCreateParagraphOpen: true,
            }

        case ACTIONS.OPEN_MODAL_PREVIEW_DOCUMENTS:
            return {
                ...state,
                modalPreviewDocuments: true,
            }

        case ACTIONS.GET_TEMPLATES_REQUEST:
            return {
                ...state,
            }

        case ACTIONS.GET_TEMPLATES_SUCCESS:
            return {
                ...state,
                documentModels: action.payload,
                templatesLoading: false,
            }

        case ACTIONS.SAVE_PARAGRAPH_SUCCESS:
            return {
                ...state,
                paragraphs: [action.payload, ...state.paragraphs],
                modalCreateParagraphOpen: false,
            }
            
        case ACTIONS.CLOSE_MODAL:
            return {
                ...state,
                saveDialogOpen: false,
                protocolDialogOpen: false,
                modalAROpen: false,
                modalPreviewDocuments: false,
                modalCreateParagraphOpen: false,
            }

        case ACTIONS.DOCUMENT_SELECT_TYPE:
            return {
                ...state,
                selectedDocumentType: action.payload,
            }

        case ACTIONS.DOCUMENT_SELECT_MODEL:
            return {
                ...state,
                selectedDocumentModel: action.payload,
            }  
            
        case ACTIONS.UPDATE_MODEL_BODY:
            return {
                ...state,
                selectedDocumentModel: {
                    ...state.selectedDocumentModel,
                    body: action.payload,
                },
            }

        case ACTIONS.UPDATE_PARAGRAPH_LIST:
            return {
                ...state,
                paragraphs: action.payload,
            }

        case ACTIONS.UPDATE_SINGLE_PARAGRAPH_SUCCESS:
            return {
                ...state,
                paragraphs: [action.payload, ...state.paragraphs],
                paragraphsLoading: false,
            }

        case ACTIONS.GET_PARAGRAPHS_SUCCESS:
            return {
                ...state,
                paragraphs: action.payload,
                paragraphsLoading: false,
            }

        case ACTIONS.REPLACE_VARIABLES:
            return {
                ...state,
                selectedDocumentModel: replaceVariables(state.selectedDocumentModel, action.payload),
            }
        
        case ACTIONS.EXPORT_AS_HTML:
            return state;

        case ACTIONS.SET_LOADING:
            return {
                ...state,
                loading: action.payload,
            }

        case ACTIONS.SET_PARAGRAPHS_LOADING:
            return {
                ...state,
                paragraphsLoading: true,
            }

        case ACTIONS.SET_TEMPLATES_LOADING:
            return {
                ...state,
                templatesLoading: action.payload,
            }

        case ACTIONS.SET_DOCUMENT_SAVE_LOADING:
            return {
                ...state,
                documentSaveLoading: action.payload,
                saveDialogOpen: false,
            }
        
        case ACTIONS.DELETE_SINGLE_PARAGRAPH_SUCCESS:
            return {
                ...state,
                paragraphs: state.paragraphs.filter(paragraph => paragraph._id !== action.payload),
                paragraphsLoading: false,
            }

        default:
            return state;
    }
}

export const Creators = {

    closeModal: () => ({
        type: ACTIONS.CLOSE_MODAL,
    }),

    openModalSave: () => ({
        type: ACTIONS.OPEN_MODAL_SAVE,
    }),

    openModalProtocol: () => ({
        type: ACTIONS.OPEN_MODAL_PROTOCOL,
    }),

    openModalAR: () => ({
        type: ACTIONS.OPEN_MODAL_AR,
    }),

    openModalPreviewDocuments: () => ({
        type: ACTIONS.OPEN_MODAL_PREVIEW_DOCUMENTS,
    }),

    openModalCreateParagraph: () => ({
        type: ACTIONS.OPEN_MODAL_CREATE_PARAGRAPH,
    }),

    replaceVariables: payload => ({
        type: ACTIONS.REPLACE_VARIABLES, 
    }),

    updateParagraphList: newList => ({
        type: ACTIONS.UPDATE_PARAGRAPH_LIST,
        payload: newList,
    }),

    updateModelBody: updatedModel => ({
        type: ACTIONS.UPDATE_MODEL_BODY,
        payload: updatedModel,
    }),

    setModelLoading: status => ({
        type: ACTIONS.SET_LOADING,
        payload: status,
    }),

    selectTemplateLoading: status => ({
        type: ACTIONS.SET_TEMPLATES_LOADING,
        payload: status,
    }),

    selectTemplateModel: model => ({
        type: ACTIONS.DOCUMENT_SELECT_MODEL,
        payload: model,
    }),

    selectTemplateType: type => ({
        type: ACTIONS.DOCUMENT_SELECT_TYPE,
        payload: type,
    }),

    getTemplatesRequest: () => ({
        type: ACTIONS.GET_TEMPLATES_REQUEST,
    }),

    getTemplatesSuccess: data => ({
        type: ACTIONS.GET_TEMPLATES_SUCCESS,
        payload: data,
    }),

    getParagraphsRequest: () => ({
        type: ACTIONS.GET_PARAGRAPHS_REQUEST,
    }),

    getParagraphsSuccess: data => ({
        type: ACTIONS.GET_PARAGRAPHS_SUCCESS,
        payload: data,
    }),

    replaceVariables: formData => ({
        type: ACTIONS.REPLACE_VARIABLES,
        payload: formData,
    }),

    saveNewParagraph: newParagraph => ({
        type: ACTIONS.SAVE_PARAGRAPH_REQUEST,
        payload: newParagraph,
    }),

    saveNewParagraphSuccess: data => ({
        type: ACTIONS.SAVE_PARAGRAPH_SUCCESS,
        payload: data,
    }),

    setParagraphsLoading: () => ({
        type: ACTIONS.SET_PARAGRAPHS_LOADING,
    }),

    updateSingleParagraphRequest: updatedParagraph => ({
        type: ACTIONS.UPDATE_SINGLE_PARAGRAPH_REQUEST,
        payload: updatedParagraph,
    }),

    updateSingleParagraphSuccess: updatedParagraph => ({
        type: ACTIONS.UPDATE_SINGLE_PARAGRAPH_SUCCESS,
        payload: updatedParagraph,
    }),

    deleteSingleParagraphRequest: paragraphId => ({
        type: ACTIONS.DELETE_SINGLE_PARAGRAPH_REQUEST,
        payload: paragraphId,
    }),

    deleteSingleParagraphSuccess: paragraphId => ({
        type: ACTIONS.DELETE_SINGLE_PARAGRAPH_SUCCESS,
        payload: paragraphId,
    }),

}

