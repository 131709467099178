export const Types = {
  NEW_REQUEST: 'analise/NEW_REQUEST',
  NEW_SUCCESS: 'analise/NEW_SUCCESS',
  NEW_BULK_REQUEST: 'analise/NEW_BULK_REQUEST',
  NEW_BULK_SUCCESS: 'analise/NEW_BULK_SUCCESS',
  WORKED: 'analise/WORKED',
  GENERATE_PDF_REQUEST: 'analise/GENERATE_PDF_REQUEST',
  GENERATE_PDF_SUCCESS: 'analise/GENERATE_PDF_SUCCESS',
  GENERATE_AR: 'analise/GENERATE_AR',
  SEND_EMAIL_REQUEST: 'analise/SEND_EMAIL_REQUEST',
  SEND_EMAIL_SUCCESS: 'analise/SEND_EMAIL_SUCCESS',
  GET_REQUEST: 'analise/GET_REQUEST',
  GET_SUCCESS: 'analise/GET_SUCCESS',
  UNASSIGN_REQUEST: 'analise/UNASSIGN_REQUEST',
  UNASSIGN_SUCCESS: 'analise/UNASSIGN_SUCCESS',
  BATCH_UNASSIGN_USER_REQUEST: 'analise/BATCH_UNASSIGN_USER_REQUEST',
  BATCH_UNASSIGN_USER_SUCCESS: 'analise/BATCH_UNASSIGN_USER_SUCCESS',
  ASSIGN_USER_REQUEST: 'analise/ASSIGN_USER_REQUEST',
  ASSIGN_USER_SUCCESS: 'analise/ASSIGN_USER_SUCCESS',
  SET_CURRENT_FILA_REQUEST: 'analise/SET_CURRENT_FILA_REQUEST',
  SET_CURRENT_FILA_SUCCESS: 'analise/SET_CURRENT_FILA_SUCCESS',
};

const INITIAL_STATE = {
  list: [],
  loading: false,
  worked: false,
  emailSent: false,
  pathPDF: '',
  generateAR: [],
  documento: {},
  fila: '',
};

export default function analises(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.NEW_REQUEST:
      return { ...state, loading: true };

    case Types.NEW_SUCCESS:
      return { ...state, loading: false, worked: true };

    case Types.NEW_BULK_REQUEST:
      return { ...state, loading: true };

    case Types.NEW_BULK_SUCCESS:
      return { ...state, loading: false };

    case Types.SET_CURRENT_FILA_REQUEST:
      return { ...state, loading: true };

    case Types.SET_CURRENT_FILA_SUCCESS:
      return { ...state, loading: false, fila: action.payload };

    case Types.WORKED:
      return { ...state, worked: true };

    case Types.GENERATE_PDF_REQUEST:
      return { ...state, loading: true };

    case Types.GENERATE_PDF_SUCCESS:
      return { ...state, loading: false, pathPDF: action.payload };

    case Types.SEND_EMAIL_REQUEST:
      return { ...state, loading: true };

    case Types.SEND_EMAIL_SUCCESS:
      return { ...state, loading: false, emailSent: true };

    case Types.GENERATE_AR:
      return { ...state, generateAR: action.payload };

    case Types.GET_REQUEST:
      return { ...state, loading: true, documento: {} };

    case Types.GET_SUCCESS:
      return { ...state, loading: false, documento: action.payload };

    case Types.UNASSIGN_REQUEST:
      return { ...state, loading: true };

    case Types.UNASSIGN_SUCCESS:
      return { ...state, loading: false };

    case Types.BATCH_UNASSIGN_USER_REQUEST:
      return { ...state, loading: true };

    case Types.BATCH_UNASSIGN_USER_SUCCESS:
      return { ...state, loading: false };

    case Types.ASSIGN_USER_REQUEST:
      return { ...state, loading: true };

    case Types.ASSIGN_USER_SUCCESS:
      return { ...state, loading: false };

    default:
      return state;
  }
}

export const Creators = {
  createRequest: (analise, indexadores) => ({
    type: Types.NEW_REQUEST,
    payload: { analise, indexadores },
  }),

  createSuccess: () => ({
    type: Types.NEW_SUCCESS,
  }),

  createBulkRequest: (payload) => ({
    type: Types.NEW_BULK_REQUEST,
    payload: payload
  }),

  createBulkSuccess: () => ({
    type: Types.NEW_BULK_SUCCESS
  }),

  setCurrentFilaRequest: () => ({
    type: Types.SET_CURRENT_FILA_REQUEST,
  }),

  setCurrentFilaSuccess: (fila) => ({
    type: Types.SET_CURRENT_FILA_SUCCESS,
    payload: fila,
  }),

  workedRequest: () => ({
    type: Types.WORKED,
  }),

  generatePDFRequest: (content) => ({
    type: Types.GENERATE_PDF_REQUEST,
    payload: content,
  }),

  generatePDFSuccess: (path) => ({
    type: Types.GENERATE_PDF_SUCCESS,
    payload: path,
  }),

  sendEmailRequest: (info) => ({
    type: Types.SEND_EMAIL_REQUEST,
    payload: info,
  }),

  sendEmailSuccess: (sent) => ({
    type: Types.SEND_EMAIL_REQUEST,
    payload: sent,
  }),

  generateARRequest: (content) => ({
    type: Types.GENERATE_AR,
    payload: content,
  }),

  getRequest: (_id, taskId, processInstanceId, type, etapa, fila, fluxo) => ({
    type: Types.GET_REQUEST,
    payload: { _id, taskId, processInstanceId, type, etapa, fila, fluxo },
  }),

  getSuccess: (payload) => ({
    type: Types.GET_SUCCESS,
    payload,
  }),

  unassignRequest: (
    taskId,
    processInstanceId,
    type,
    idDocumento,
    etapa,
    fila,
    fluxo
  ) => ({
    type: Types.UNASSIGN_REQUEST,
    payload: {
      taskId,
      processInstanceId,
      type,
      idDocumento,
      etapa,
      fila,
      fluxo,
    },
  }),

  unassignSuccess: (payload) => ({
    type: Types.UNASSIGN_SUCCESS,
    payload,
  }),

  batchUnassignFromUserRequest: (userId) => ({
    type: Types.BATCH_UNASSIGN_USER_REQUEST,
    payload: { userId },
  }),

  batchUnassignFromUserSuccess: (payload) => ({
    type: Types.BATCH_UNASSIGN_USER_SUCCESS,
    payload,
  }),

  assignTaskToUserRequest: (payload) => ({
    type: Types.ASSIGN_USER_REQUEST,
    payload,
  }),

  assignTaskToUserSuccess: (payload) => ({
    type: Types.ASSIGN_USER_SUCCESS,
    payload,
  }),
};
