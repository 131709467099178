import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toastr } from "react-redux-toastr";
import { Form } from "@unform/web";
import { ValidationError } from "yup";
import { Col, Row } from "react-bootstrap";
import Grid from "@material-ui/core/Grid";
import ButtonMui from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Collapse from "@material-ui/core/Collapse";
import InputAdornment from "@material-ui/core/InputAdornment";
import CircularProgress from "@material-ui/core/CircularProgress";

import BackButton from "components/BackButton";
import InputForm from "components/InputForm";
import DefaultInput from 'components/DefaultInput';
import AutocompleteForm from "components/AutocompleteForm";
import SelectForm from "components/SelectForm";
import CheckboxForm from "components/CheckboxForm";

import EmpresaSchema from "validators/EmpresaSchema";

import { Creators as EmpresasActions } from "store/ducks/empresas";
import { Creators as GruposEmpresariaisActions } from "store/ducks/gruposempresariais";

import { valida_cnpj, valida_cpf } from "services/validadorCpfCNPJ";

import RGMask from "components/Masks/RG";

import Currency from "components/Masks/Currency";

import "./styles.css";
import Content from "components/Content";

export default function TipoPessoa() {
  const formRef = useRef(null);

  const [showPessoaFisica, setShowPessoaFisica] = useState(false);
  const [showPessoaJuridica, setShowPessoaJuridica] = useState(false);
  const [empresa, setEmpresa] = useState([]);
  const [valorDigitado, setValorDigitado] = useState("");
  const [colorValidation, setColorValidation] = useState(true);

  const { loading, byCNPJ } = useSelector(state => state.empresas);
  const { list: gruposEmpresariais } = useSelector(
    state => state.gruposempresariais
  );
  const usuarioID = useSelector(state => state.usuarios.auth.id);

  const cpfMaskara = value => {
    return value
      .replace(/\D/g, "")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d{1,2})/, "$1-$2")
      .replace(/(-\d{2})\d+?$/, "$1");
  };

  const cnpjMaskara = value => {
    return value
      .replace(/\D/g, "")
      .replace(/(\d{2})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1/$2")
      .replace(/(\d{4})(\d)/, "$1-$2")
      .replace(/(-\d{2})\d+?$/, "$1");
  };

  const dispatch = useDispatch();

  const handleKey = event => {
    if (event.keyCode === 9) {
      let cnpjFormatado = event.target.value.replace(/[^0-9]+/g, "");
      setEmpresa([]);
      dispatch(EmpresasActions.listByCNPJRequest(cnpjFormatado));
    }
  };

  const onSubmit = async values => {
    if (!colorValidation) {
      toastr.error("Ops", "O CPF / CPNJ informado está inválido");

      return;
    }

    try {
      let body;

      if (values.tipoPessoa === "Jurídica") {
        body = {
          ...values,
          usuarioAlteracao: usuarioID,
          cnpj: valorDigitado,
          horarioComercial: {
            abertura: values.horarioAbertura,
            fechamento: values.horarioFechamento,
          },
          faturamentoMinimo: values.faturamentoMinimo
            .replace(",", "")
            .replace(/\./g, ""),
        };
      } else {
        body = {
          ...values,
          usuarioAlteracao: usuarioID,
          cpf: valorDigitado,
          faturamentoMinimo: values.faturamentoMinimo
            .replace(",", "")
            .replace(/\./g, ""),
        };
      }

      await EmpresaSchema.validate(body, {
        abortEarly: false,
      });

      dispatch(EmpresasActions.createRequest(body));
    } catch (err) {
      const validationErrors = {};
      if (err instanceof ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });

        formRef.current.setErrors(validationErrors);
      }
    }
  };

  useEffect(() => {
    dispatch(
      GruposEmpresariaisActions.listRequest({
        page: 1,
        limit: Number.MAX_SAFE_INTEGER,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (byCNPJ.length > 0) {
      setEmpresa(byCNPJ);
      setShowPessoaJuridica(true);

      const [emp] = byCNPJ;
      const { cnpj, razaoSocial, nomeFantasia: fantasia } = emp;
      formRef.current.setData({
        cnpj,
        razaoSocial,
        fantasia,
        tipoPessoa: "Jurídica",
      });
    }
  }, [byCNPJ]);

  useEffect(() => {
    if (empresa.length === 0) {
      setEmpresa([]);
    }
  }, [empresa.length]);

  const handleChange = e => {
    if (e.target.value) {
      let digitos = e.target.value;

      digitos = digitos.replace(/\D/g, "");

      let totalDigitos = digitos.length;

      if (totalDigitos <= 11) {
        setValorDigitado(cpfMaskara(e.target.value));

        const retornoValida = valida_cpf(digitos);

        setColorValidation(retornoValida);
        if (retornoValida) {
          setShowPessoaFisica(true);
          setShowPessoaJuridica(false);
          formRef.current.setFieldValue("tipoPessoa", "Física");
        }
      } else {
        setValorDigitado(cnpjMaskara(e.target.value));

        const retornoValida = valida_cnpj(digitos);

        setColorValidation(retornoValida);
        if (retornoValida) {
          setShowPessoaFisica(false);
          setShowPessoaJuridica(true);
          formRef.current.setFieldValue("tipoPessoa", "Jurídica");
        }
      }
    } else {
      setValorDigitado("");
    }
  };
  return (
    <Content>
      <Row>
        <Col md="12" className="painel">
          <div className="painel-content">
            <div className="painel-title text-left">Novo Cliente</div>
            <div className="painel-body">
              <Form
                initialData={{ tipoPessoa: "Jurídica" }}
                ref={formRef}
                onSubmit={onSubmit}
              >
                <Row className="row">
                  <Col md={3}>
                    <TextField
                      type="text"
                      label="CPF / CNPJ"
                      onChange={handleChange}
                      onKeyDown={handleKey}
                      value={valorDigitado}
                      variant="outlined"
                      size="small"
                      error={!colorValidation}
                      helperText={!colorValidation ? "CPF Inválido" : ""}
                    />
                  </Col>
                  <Col md={1}>
                    <InputForm className="mt-0" name="codigo" label="Código" />
                  </Col>
                </Row>

                <Row className="row">
                  <Col>
                    <AutocompleteForm
                      label="Grupo Empresarial*"
                      name="grupoEmpresarial"
                      options={gruposEmpresariais}
                      //transformOnSelect={(option) => option._id ? option._id : null}
                      getOptionLabel={option => option.nome}
                    />
                  </Col>
                  <Col>
                    <InputForm label="Razão Social*" name="razaoSocial" />
                  </Col>
                  <Col>
                    <InputForm
                      label="Tipo Pessoa*"
                      name="tipoPessoa"
                      disabled
                    />
                  </Col>
                  <Col>
                    <InputForm label="Fantasia*" name="fantasia" />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <AutocompleteForm
                      label="Sinônimos"
                      name="sinonimos"
                      autoSelect
                      multiple
                      freeSolo
                      clearOnBlur
                      options={[]}
                    />
                  </Col>
                </Row>
                <Collapse in={showPessoaFisica}>
                  <Row>
                    <Col>
                      <InputForm
                        name="rg"
                        label="RG"
                        InputProps={{
                          inputComponent: RGMask,
                        }}
                      />
                    </Col>
                  </Row>
                </Collapse>
                <Collapse in={showPessoaJuridica}>
                  <Row>
                    <Col>
                      <InputForm
                        name="inscricaoEstadual"
                        label="Inscrição Estadual"
                      />
                    </Col>
                    <Col>
                      <InputForm
                        name="inscricaoMunicipal"
                        label="Inscrição Municipal"
                      />
                    </Col>
                  </Row>
                </Collapse>
                <Row>
                  <Col>
                    <InputForm
                      label="Data de Assinatura"
                      name="dataAssinatura"
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Col>
                  <Col>
                    <InputForm
                      label="Data de Reajuste"
                      name="dataReajuste"
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Col>
                  <Col>
                    <InputForm
                      label="Data de Encerramento"
                      name="dataEncerramento"
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Col>
                  <Col>
                    <InputForm
                      label="Data de Inicio do Fechamento"
                      name="dataInicioFechamento"
                      type="number"
                      InputProps={{ inputProps: { min: 1, max: 30 } }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <InputForm
                      label="Data de Fim do Fechamento"
                      type="number"
                      name="dataTerminoFechamento"
                      InputProps={{ inputProps: { min: 1, max: 30 } }}
                    />
                  </Col>
                  <Col>
                    <InputForm
                      type="number"
                      name="dataEmissaoNota"
                      label="Data de Emissão da Nota"
                      InputProps={{ inputProps: { min: 1, max: 30 } }}
                    />
                  </Col>
                  <Col>
                    <InputForm
                      type="number"
                      name="dataVencimentoNota"
                      label="Data de Vencimento da Nota"
                      InputProps={{ inputProps: { min: 1, max: 30 } }}
                    />
                  </Col>
                  <Col>
                    <InputForm
                      type="email"
                      name="emailFatura"
                      label="Email Fatura*"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <InputForm
                      type="text"
                      name="faturamentoMinimo"
                      label="Faturamento Mínimo"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            R$
                          </InputAdornment>
                        ),
                        inputComponent: Currency,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Col>
                  <Col>
                    <InputForm
                      type="number"
                      name="limiteSenhasRepetidas"
                      label="Limite de Senhas Repetidas"
                      InputProps={{ inputProps: { min: 0 } }}
                    />
                  </Col>
                  <Col>
                    <InputForm
                      type="number"
                      name="limiteSenhasIncorretas"
                      label="Limite de Senhas Incorretas"
                      InputProps={{ inputProps: { min: 0 } }}
                    />
                  </Col>
                  <Col>
                    <InputForm
                      type="number"
                      name="qtMinimaCaracteresSenha"
                      label="Quantidade Minima de Caracteres"
                      InputProps={{ inputProps: { min: 0 } }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <InputForm
                      name="timeOut"
                      label="Timeout(Segundos)"
                      type="number"
                      InputProps={{ inputProps: { min: 1 } }}
                    />
                  </Col>
                  <Col>
                    <SelectForm name="status" label="Status*">
                      <MenuItem value={true}>Ativo</MenuItem>
                      <MenuItem value={false}>Inativo</MenuItem>
                    </SelectForm>
                  </Col>
                  <Col>
                    <SelectForm
                      name="tipoPagamento"
                      label="Tipo de Pagamento*"
                    >
                      <MenuItem value="Depósito Bancário">
                        Depósito Bancário
                      </MenuItem>
                      <MenuItem value="Boleto">Boleto</MenuItem>
                    </SelectForm>
                  </Col>
                  <Col>
                    <Row>
                      <Col>
                        <CheckboxForm
                          label="Bloqueio Inatividade"
                          name="bloqueioInatividade"
                        />
                      </Col>
                      <Col>
                        <CheckboxForm
                          label="Acesso Simultaneo"
                          name="acessoSimultaneo"
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="Empresas--form__horario-funcionamento">
                      <DefaultInput
                        type="number"
                        name="horarioAbertura"
                        label="Horário abertura"
                        defaultValue={7}
                        shrink
                        InputProps={{
                          endAdornment: <InputAdornment position="start">H</InputAdornment>,
                          inputProps: { min: 0, max: 23 }
                        }} />
                      <DefaultInput
                        type="number"
                        name="horarioFechamento"
                        label="Horário fechamento"
                        defaultValue={22}
                        shrink
                        InputProps={{
                          endAdornment: <InputAdornment position="start">H</InputAdornment>,
                          inputProps: { min: 0, max: 23 }
                        }} />
                    </div>
                  </Col>
                  <Col></Col>
                  <Col></Col>
                  <Col></Col>
                </Row>
                <Grid container spacing={1}>
                  <Grid item>
                    <ButtonMui
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={!!loading}
                    >
                      {!!loading && (
                        <>
                          <CircularProgress size={20} color="primary" />
                          &nbsp;
                        </>
                      )}
                      Enviar
                    </ButtonMui>
                  </Grid>
                  <Grid item>
                    <ButtonMui
                      variant="contained"
                      color="secondary"
                      type="reset"
                    >
                      Limpar
                    </ButtonMui>
                  </Grid>
                  <Grid item>
                    <BackButton variant="contained" color="secondary">
                      Voltar
                    </BackButton>
                  </Grid>
                </Grid>
              </Form>
            </div>
          </div>
        </Col>
      </Row>
    </Content>
  );
}
