import { call, put, select } from "redux-saga/effects";
import { toastr } from "react-redux-toastr";

import api from "../../services/api";
import { getToken } from "../../services/auth";

import { Creators as PermissoesActions } from "../ducks/permissoes";

export const getUserId = (state) => state.usuarios.auth.id;
export const getOrganization = (state) => state.usuarios.auth.organization;

export function* getPermissoes(xkeyorg, idUsuario, username) {
  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg },
    };

    const url = username ? `/permissoes/usuario/${username}/username` : `/permissoes/usuario/${idUsuario}`;

    const { data } = yield call(
      api.get,
      url,
      headerParams
    );

    yield put(PermissoesActions.getPermissoesSuccess(data));
  } catch (error) {
    console.error(error);
  }
}

export function* updatePermissoes(action) {
  try {
    const organization = yield select(getOrganization);

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    yield call(
      api.put,
      `/permissoes/usuario/${action.payload._id}`,
      action.payload.structure,
      headerParams
    );

    // yield getPermissoes();
    yield getPermissoesPosEdit(action.payload._id);

    toastr.success("Permissões editadas com sucesso.");
  } catch (error) {
    toastr.error("Ops", "Não foi possível editar as permissões.");
  }
}

export function* getPermissoesAnotherUser(action) {
  const organization = yield select(getOrganization);

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const { data } = yield call(
      api.get,
      `/permissoes/usuario/${action.payload}`,
      headerParams
    );

    yield put(PermissoesActions.getPermissoesAnotherUserSuccess(data));
  } catch (error) { }
}

export function* getPermissoesPosEdit(user) {
  const idUser = yield select(getUserId);
  const organization = yield select(getOrganization);

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const { data } = yield call(
      api.get,
      `/permissoes/usuario/${user}`,
      headerParams
    );
    yield put(PermissoesActions.getPermissoesAnotherUserSuccess(data));
  } catch (error) { }
}
