import React, { useState, useRef } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import Icon from '@material-ui/core/Icon';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import _ from '@lodash';

import ConfirmButton from 'components/ConfirmButton';

import TableEncerramentos from './TableEncerramentos';
import FormEncerramentosModal from './FormEncerramentosModal';
import ViewEncerramento from './ViewEncerramento';

import { Creators as EncerramentoActions } from 'store/ducks/encerramento';
import { Creators as EncerramentosActions } from 'store/ducks/encerramentos';
import { Creators as DocumentosActions } from 'store/ducks/documentos';

import { LabelSwapper } from '../../components/LabelSwapper';

function Encerramentos({ documento }) {
  const dispatch = useDispatch();
  const dispatchDebounce = useRef(_.debounce(dispatch, 500)).current;

  const {
    search = '',
    page,
    limit,
    sort,
  } = useSelector((state) => state.encerramentos);
  const { loadingReopen } = useSelector((state) => state.documentos);

  const [searchField, setSearchField] = useState(search);

  function handleChange({ target }) {
    const { value } = target;
    setSearchField(value);
    dispatchDebounce(
      EncerramentosActions.listRequest({
        documento: documento._id,
        page,
        limit,
        sort,
        search: value,
      })
    );
  }

  function handleClick() {
    dispatch(EncerramentoActions.new());
  }

  function handleReopenProcess() {
    dispatch(DocumentosActions.reopenRequest(documento._id));
  }

  return (
    <>
      <Row>
        <Col md="12">
          <div className="controlpanel-header">
            <div className="controlpanel-item comments-header-spacing">
              <TextField
                label="Pesquisar"
                variant="outlined"
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Icon className="text-20" color="action">
                        search
                      </Icon>
                    </InputAdornment>
                  ),
                }}
                onChange={handleChange}
                value={searchField}
              />
            </div>
            <div className="controlpanel-item comments-header-spacing">
              {documento.status && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleClick}
                >
                  Novo Encerramento
                </Button>
              )}
              {!documento.status && (
                <ConfirmButton
                  variant="contained"
                  color="secondary"
                  title={
                    <LabelSwapper
                      text={'Deseja realmente reabrir o prontuário?'}
                      parent={'Encerramentos'}
                      name={'msgReopenConfirmation'}
                    />
                  }
                  onConfirm={handleReopenProcess}
                  startIcon={
                    loadingReopen ? <CircularProgress size={20} /> : null
                  }
                  disabled={loadingReopen}
                >
                  <LabelSwapper
                    text={'Reabrir prontuário!'}
                    parent={'Encerramentos'}
                    name={'buttonReopen'}
                  />
                </ConfirmButton>
              )}
            </div>
          </div>
          <div className="border-top"></div>
          <div className="painel-content">
            <div className="painel-body">
              <TableEncerramentos documento={documento} />
            </div>
          </div>
        </Col>
      </Row>

      <FormEncerramentosModal documento={documento} />
      <ViewEncerramento />
    </>
  );
}

export default Encerramentos;
