import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import _ from 'lodash';

import GridCadastro from "components/GridCadastro";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import TableLayout from "./TableLayout";

export default function Layouts() {

  const { pathname } = useLocation();

  const {
    access: { servicos },
  } = useSelector((state) => state.permissoes);

  const [userActions, setUserActions] = useState([]);

  useEffect(() => {
    let find = servicos.find((item) => item.route === pathname);
    if (find !== undefined) {
      setUserActions(find);
    }
  }, [pathname, servicos]);

  return (
    <GridCadastro
      title='Layouts'
      route={"/layout/novo"}
      titleButtonNovo="Novo Layout"
    >
      <TableLayout userActions={userActions} />
    </GridCadastro>
  )
}