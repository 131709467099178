export const Types = {
  LIST_REQUEST: 'acoesauditoria/LIST_REQUEST',
  LIST_SUCCESS: 'acoesauditoria/LIST_SUCCESS',
}

const INITIAL_STATE = {
  list: [],
  loading: false
}

export default function acoesauditoria(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.LIST_REQUEST:
      return { ...state, list: [], loading: true }

    case Types.LIST_SUCCESS:
      return { ...state, list: action.payload, loading: false }

    default:
      return state;
  }
}

export const Creators = {
  listRequest: () => ({
    type: Types.LIST_REQUEST
  }),
  listSuccess: payload => ({
    type: Types.LIST_SUCCESS,
    payload
  })
}