import React, { useState, useEffect } from "react";

import StepOne from "./StepsComponents/StepOne";
import StepTwo from "./StepsComponents/StepTwo";
import StepThree from "./StepsComponents/StepThree";
import StepFour from "./StepsComponents/StepFour";
import StepsModal from "./../index";

function ModalStepsCustomField({
  open,
  tabela,
  setOpenModal,
  setTabela,
  indexador,
  indexadores,
  separadores,
  handleClose,
  changeIndexador,
  handleChangeOpcoes,
  handleChangeSeparador,
  handleChangeMostrarFluxo,
  handleDependenciaIndexador,
  handleAddCondicaoDependenciaIndexador,
  handleChangeOptionDependenciaIndexador,
  handleChangeValuesForOptionDependenciaIndexador,
  handleChangeObrigatorioEtapa,
  isImageTypeIndexor = false,
  tipoImagem = null,
}) {
  const title = "Configuração de campo customizado";
  const subtitle =
    "Adapte o sistema às suas necessidades com campos personalizados";

  const stepsLabels = isImageTypeIndexor
    ? ["Selecione o tipo do campo", "Propriedades"]
    : ["Selecione o tipo do campo", "Propriedades", "Dependência de indexador", "Outros"];

  const isChildren = parseInt(tabela) >= 0 && indexador?.campos?.length > 0;
  const hasShowStepFour = isImageTypeIndexor ? false : !isChildren;
  const maxStep = hasShowStepFour ? 4 : 3;

  const [activeStep, setActiveStep] = useState(0);
  const disableThumbGalery = indexadores && indexadores.find(e => e?.exibirThumbGallery === true);

  useEffect(() => {
    setActiveStep(0);
  }, [open]);

  useEffect(() => {
    if (activeStep === maxStep) {
      setOpenModal(false);
    }
  }, [activeStep]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  return (
    <>
      <StepsModal
        title={title}
        subtitle={subtitle}
        activeStep={activeStep}
        handleClose={handleClose}
        confirmButtonFunction={handleNext}
        steps={stepsLabels}
        open={open}
      >
        {activeStep === 0 && (
          <StepOne
            indexador={isChildren ? indexador.campos[tabela] : indexador}
            changeIndexador={changeIndexador}
            tabela={tabela}
            next={handleNext}
            isImageTypeIndexor={isImageTypeIndexor}
          />
        )}
        {activeStep === 1 && (
          <StepTwo
            indexador={isChildren ? indexador.campos[tabela] : indexador}
            changeIndexador={changeIndexador}
            separadores={separadores}
            handleChangeSeparador={handleChangeSeparador}
            handleChangeOpcoes={handleChangeOpcoes}
            setTabela={setTabela}
            tabela={tabela}
            next={handleNext}
            isImageTypeIndexor={isImageTypeIndexor}
            tipoImagem={tipoImagem}
            disableThumbGalery={disableThumbGalery}
          />
        )}
        {activeStep === 2 && (
          <StepThree
            indexador={isChildren ? indexador.campos[tabela] : indexador}
            indexadores={indexadores}
            handleDependenciaIndexador={handleDependenciaIndexador}
            handleAddCondicaoDependenciaIndexador={handleAddCondicaoDependenciaIndexador}
            handleChangeOptionDependenciaIndexador={handleChangeOptionDependenciaIndexador}
            handleChangeValuesForOptionDependenciaIndexador={handleChangeValuesForOptionDependenciaIndexador}
          />
        )}
        {activeStep === 3 && hasShowStepFour && (
          <StepFour
            handleChangeMostrarFluxo={handleChangeMostrarFluxo}
            handleChangeObrigatorioEtapa={handleChangeObrigatorioEtapa}
            indexador={isChildren ? indexador.campos[tabela] : indexador}
            next={handleNext}
          />
        )}
      </StepsModal>
    </>
  );
}

export default ModalStepsCustomField;
