import React, { useState } from 'react';
import { 
  Icon, IconButton, Typography,
  Avatar, Divider
} from '@material-ui/core';
import mime from 'mime-types';
import { useDispatch } from 'react-redux';

import DialogPreviewImage from 'components/DialogPreviewImage';

import { Creators as DocumentosActions } from 'store/ducks/documentos';

import './styles.css'

function Mensagem({ onBack, mensagem, avatar }) {
  const dispatch = useDispatch();

  const [imageUrl, setImageUrl] = useState('');
  const [open, setOpen] = useState(false);

  function getAuthorInitials(name) {
    const [firstName, middleName,] = name.split(' ');
    
    if (firstName && middleName) {
        return `${firstName[0]}${middleName[0]}`;
    } else {
        return name[0];
    }    
  }

  function getEXT(filepath) {
    const contentType = mime.lookup(filepath);
    const extension = mime.extension(contentType);
    return extension.toLowerCase();
  }

  function handleShowAnexo(imageUrl) {
    setImageUrl(imageUrl);
    setOpen(true);
  }

  function handleClickProcesso() {
    dispatch(DocumentosActions.sendToSearchPageRequest(
      [mensagem.documento._id], 
      {
        limit: 10,
        page: 1,
        sort: "-createdAt",
        searchText: '',
      }, 
      false,
      true
    ));
  }

  return (
    <div className="mensagem-container">
      <div className="mensagem-header">
        <IconButton onClick={onBack}>
          <Icon>arrow_back</Icon>
        </IconButton>
        <Typography variant="subtitle1">
          {mensagem.assunto}
        </Typography>
      </div>
      <Divider />
      <div className="mensagem-body">
        <div className="mensagem-body-header">
          <div className="mensagem-avatar">
            <Avatar>{getAuthorInitials(avatar)}</Avatar>
            <Typography variant="subtitle1">{avatar}</Typography>
          </div>
          <Typography 
            className="mensagens-numero-processo" 
            variant="subtitle1"
            onClick={handleClickProcesso}
          >
            {mensagem.documento.numeroCNJ}
          </Typography>
        </div>
        <Typography variant="body2" dangerouslySetInnerHTML={{
          __html: mensagem.conteudo
        }}/>
        <Divider />
        <Typography variant="subtitle1">
          {`Anexos (${mensagem.imagens ? mensagem.imagens.length : 0})`}
        </Typography>
        <div className="mensagem-anexos">
          {mensagem.imagens && mensagem.imagens.map(imagem => 
            <div 
              key={imagem._id}
              className="mensagem-anexo" 
              onClick={e => handleShowAnexo(imagem.filepath)}
            >
              <div className={`mensagem-anexo-${getEXT(imagem.filepath)}`}>
                {getEXT(imagem.filepath).toUpperCase()}
              </div>
              <Typography variant="subtitle1">{imagem.nome}</Typography>
            </div>
          )}
        </div>
      </div>
      <DialogPreviewImage
        open={open}
        onClose={() => setOpen(false)}
        imageUrl={imageUrl}
      />
    </div>
  );
}

export default Mensagem;