import api from 'services/api';
import { getToken } from 'services/auth';
import { call, select, put, all, takeLatest } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';
import { push } from "connected-react-router";

import { Creators as UnidadesActions, Types } from 'store/ducks/unidades';

export const getOrganization = state => state.usuarios.auth.organization;
export const getUnidades = state => state.unidades;

export function* saveUnidade({ payload }) {
  const organization = yield select(getOrganization);
  const { _id, ...body } = payload;

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    if (_id) {
      yield call(api.put, `unidades/${_id}`, body, headerParams);
    } else {
      yield call(api.post, "unidades", body, headerParams);
    }

    yield put(push("/configuracao/unidades"));
  } catch (error) {
    toastr.error("Ops", "Não foi possível editar a modalidade.");
  }
}

export function* listUnidades({ payload }) {
  const organization = yield select(getOrganization);

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
      params: payload,
    };

    const { data } = yield call(api.get, "unidades", headerParams);

    yield put(UnidadesActions.listSuccess(data.docs, data.total));
  } catch (error) {
    toastr.error("Ops", "Não foi possível listar as modalidades.");
  }
}

export function * deleteUnidade({ payload }) {
  const organization = yield select(getOrganization);

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    yield call(api.delete, `unidades/${payload._id}`, headerParams);

    const { page, limit, sort, search } = yield select(getUnidades);

    yield put(UnidadesActions.listRequest({ page, limit, sort, search }));
  } catch (error) {
    toastr.error("Ops", "Não foi possível remover a modalidade.");
  }
}

export default all([
  takeLatest(Types.LIST_REQUEST, listUnidades),
  takeLatest(Types.SAVE_REQUEST, saveUnidade),
  takeLatest(Types.DELETE_REQUEST, deleteUnidade)
])