import React, { useEffect, useRef, useState } from 'react';

import InputForm from 'components/InputForm';
import { Line } from 'pages/Formulario/Util';
import { Form } from '@unform/web';

import { Button, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { toastr } from 'react-redux-toastr';

import { Creators as FormularioActions } from "store/ducks/formulario";

import '../../styles.css';
import { useDispatch, useSelector } from 'react-redux';

const ETAPA_USUARIO = 0;
const ETAPA_CODIGO = 1;
const style = { minWidth: '458px' };

export default function FormUser({ onChange, informacoes }) {
    const dispatch = useDispatch();

    const userRef = useRef(null);
    const [sending, setSending] = useState(false);
    const [payload, setPayload] = useState(null);
    const [step, setStep] = useState(ETAPA_USUARIO);

    const [tenant, setTenant] = useState('');
    const [idFormulario, setIdFormulario] = useState('');

    const { informacoes: retornoValidacao } = useSelector((state) => state.formularios);

    const initialData = {
        nome: '',
        email: '',
        codigo: '',
    }

    //#region funções auxiliares
    async function onSubmitUser(values) {
        try {
            //#region validações
            const { nome, email, codigo } = values;

            if (step === ETAPA_USUARIO) {
                if (!nome.trim().length) {
                    toastr.error("Nome  deve ser informado!");
                    return;
                } else if (!email.trim().length) {
                    toastr.error("E-mail deve ser informado!");
                    return;
                }
            } else if (step === ETAPA_CODIGO) {
                if (!codigo.trim().length) {
                    toastr.error("Código não informado!");
                    return;
                }
            }
            //#endregion

            setSending(true);

            const payload = { ...values, xkeyorg: tenant, idFormulario, etapa: step }

            dispatch(FormularioActions.validateRequest(payload));

            setPayload(payload);

        } catch (err) {
            console.log(err);
            toastr.error(err.message);
        }
        finally {
            setSending(false);
        }
    }
    //#endregion

    //#region effects
    useEffect(() => {
        const { xkeyorg, id } = informacoes;
        setTenant(xkeyorg);
        setIdFormulario(id);
    }, [informacoes]);

    useEffect(() => {
        const { success } = retornoValidacao
        if (success) {
            if (step === ETAPA_USUARIO) setStep(ETAPA_CODIGO);
            if (step === ETAPA_CODIGO) onChange(payload);
        }
    }, [retornoValidacao]);
    //#endregion

    return (
        <div className="form-user">

            <Form
                ref={userRef}
                onSubmit={onSubmitUser}
                initialData={initialData}
            >

                <DialogTitle>
                    <h3>Acesso ao formulário</h3>
                </DialogTitle>

                <DialogContent dividers>

                    <Line>
                        <InputForm
                            autoFocus
                            label="Nome"
                            name="nome"
                            style={style}
                            disabled={step === ETAPA_CODIGO || sending}
                        />
                    </Line>

                    <Line>
                        <InputForm
                            name="email"
                            style={style}
                            label="E-mail"
                            disabled={step === ETAPA_CODIGO || sending}
                        />
                    </Line>

                    <Line>
                        <span>Você receberá um código em seu e-mail</span>
                        <InputForm
                            name="codigo"
                            style={style}
                            label="Código"
                            disabled={step === ETAPA_USUARIO}
                        />
                    </Line>

                </DialogContent>

                <DialogActions>

                    <Button
                        type="submit"
                        disabled={sending}
                        variant="contained"
                        color={step === ETAPA_USUARIO ? "primary" : "secondary"}
                    >
                        {step === ETAPA_USUARIO ? "Enviar" : "Validar Código"}
                    </Button>

                </DialogActions>

            </Form>

        </div>

    );
}