import React, { useEffect } from "react";
import { Typography } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { Col, Row } from "react-bootstrap";

import PageEditor from "../PageEditor";
import ModelForm from "../ModelForm";
import "./styles.css";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { Creators as TemplateActions } from "../../../store/ducks/template";
import Content from "components/Content";

function Editor(props) {
  const dispatch = useDispatch();
  const { paragraphTemplate, paragraph, template, templateSelected } =
    useSelector((state) => state.template);

  const { template: templateToEdit } = props.location.state;

  useEffect(() => {
    dispatch(TemplateActions.selectTemplateToEdit(templateToEdit._id));
  }, [templateToEdit, dispatch]);

  useEffect(() => {
    if (templateSelected && templateSelected?.header === "")
      dispatch(TemplateActions.deleteHeader());

    if (templateSelected && templateSelected?.footer === "")
      dispatch(TemplateActions.deleteFooter());
  }, [templateSelected, dispatch]);

  function handleOnDragEnd(result) {
    if (!result.destination) return;

    if (result.source.droppableId === "pageEditor") {
      const items = paragraphTemplate;
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);
      dispatch(TemplateActions.updateParagraphTemplateList(items));
      return;
    }

    const item = paragraphTemplate;

    const newParagraph = paragraph.find(
      (paragraph) => paragraph.id.toString() === result.draggableId
    );

    item.splice(result.destination.index, 0, {
      ...newParagraph,
      id_editor: uuidv4(),
    });
    dispatch(TemplateActions.moveParagraphToTemplate(item));
  }

  return (
    <Content>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Row>
          <Col lg="4" md="12"  className="painel">
            <div className="painel-content">
              <div className="painel-title text-left">
                <Typography className="title" variant="h6">
                  Templates
                </Typography>
              </div>

              <Droppable
                droppableId={"card"}
                isDragDisabled={true}
                isDropDisabled={true}
              >
                {(provided, snapshot) => {
                  return (
                    <>
                      <div
                        className="painel-body model-body"
                        isDraggingOver={snapshot.isDraggingOver}
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        <ModelForm />
                      </div>
                      {provided.placeholder}
                    </>
                  );
                }}
              </Droppable>
            </div>
          </Col>
          <Col>
            <div>
              <Droppable droppableId={"pageEditor"}>
                {(provided, snapshot) => {
                  return (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      <PageEditor />
                    </div>
                  );
                }}
              </Droppable>
            </div>
          </Col>
        </Row>
      </DragDropContext>
    </Content>
  );
}

export default Editor;
