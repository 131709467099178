import api from 'services/api';
import { getToken } from 'services/auth';
import { call, select, put, all, takeLatest } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';
import { push } from 'connected-react-router';

import { Creators as BulkImportActions, Types } from '../ducks/bulkimport';

export const getOrganization = (state) => state.usuarios.auth.organization;
export const getUserId = (state) => state.usuarios.auth.id;

export function* handleBulkImport({ payload }) {
  try {
    const organization = yield select(getOrganization);

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    for (const file of payload) {
      yield call(api.post, `bulkimport`, file, headerParams);
    }

    //const response = yield call(api.post, `bulkimport`, payload, headerParams);

    yield put(BulkImportActions.createSuccess());
  } catch (error) {
    toastr.error('Ops', 'Não foi possível importar o documento.');
  }
}

export default all([takeLatest(Types.NEW_REQUEST, handleBulkImport)]);
