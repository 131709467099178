import api from 'services/api';
import { getToken } from 'services/auth';
import { call, select, put, all, takeLatest } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';
import { push } from 'connected-react-router';

import { Creators as AcessosRPAActions, Types } from 'store/ducks/acessosrpa';

export const getOrganization = state => state.usuarios.auth.organization;
export const getParamsEncerramentos = (state) => ({
  page: state.acessosrpa.page,
  sort: state.acessosrpa.sort,
  limit: state.acessosrpa.limit,
  search: state.acessosrpa.search,
});

export function* deleteAcessos({ payload }) {
  try {
    const organization = yield select(getOrganization);
    const { _id } = payload;

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    yield call(api.delete, `acessos-rpa/${_id}`, headerParams);

    toastr.success("", "Tribunal removido com sucesso.");

    const params = yield select(getParamsEncerramentos);
    yield put(AcessosRPAActions.listRequest(params));
  } catch (error) {
    toastr.error("Ops", "Não foi possível remover o acesso.");
    yield put(AcessosRPAActions.deleteError());
  }
}

export function* saveRequest({ payload }) {
  const organization = yield select(getOrganization);
  const { _id, ...body } = payload.body;

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    if (_id) {
      yield call(api.put, `acessos-rpa/${_id}`, body, headerParams);
    } else {
      yield call(api.post, 'acessos-rpa', body, headerParams);
    }

    yield put(push('/configuracao/acessos-rpa'))
  } catch(err) {
    toastr.error('Ops', 'Não foi possível salvar o acesso.');
    yield put(AcessosRPAActions.saveError());
  }
}

export function* listAcessosRPA({ payload }) {
  const organization = yield select(getOrganization);

  const { params } = payload;

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
      params,
    };

    const { data } = yield call(api.get, 'acessos-rpa', headerParams);

    yield put(AcessosRPAActions.listSuccess(data.docs, data.total));
  } catch (error) {
    toastr.error('Ops', 'Não foi possível listar o(s) acesso(s).');
    yield put(AcessosRPAActions.listError())
  }
}

export default all([
  takeLatest(Types.LIST_REQUEST, listAcessosRPA),
  takeLatest(Types.SAVE_REQUEST, saveRequest),
  takeLatest(Types.DELETE_REQUEST, deleteAcessos)
])