import React, { useState } from 'react';

import Indexadores from './Indexadores';

function DadosProcessuais({ selectedDoc }) {
  return (
    <>
      <Indexadores selectedDoc={selectedDoc} />
    </>
  );
}

export default DadosProcessuais;
