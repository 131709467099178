import React, { useCallback, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import { useDropzone } from 'react-dropzone';

import Thubmnail from 'components/Thumbnail';

import './styles.css';
import { CloudUploadIcon } from 'components/Icons';

function SelectFilesToUpload({ 
  loading, onUpload, onClose, maxFiles=0, 
  defaultName='', names=[], ...props
}) {

  const [filesToUpload, setFilesToUpload] = useState([]);

  const onDrop = useCallback(async acceptedFiles => {
    if (!loading) {
      const validFiles = acceptedFiles.filter(file => file.type === 'application/pdf' || file.type.indexOf('image') !== -1);
      setFilesToUpload(f => ([
        ...f,
        ...validFiles.map(file => ({ file, nome: defaultName }))
      ]))
    }
  }, [loading, defaultName]);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, maxFiles });

  function handleChange({ target }, index) {
    const { value } = target;

    const newArray = filesToUpload.map((fileToUpload, index2) => ({
      ...fileToUpload,
      nome: index2 === index ? value : fileToUpload.nome
    }));
    setFilesToUpload(newArray);
  }

  function canUpload() {
    return filesToUpload.length > 0 && filesToUpload.every(({ nome }) => !!nome);
  }

  return (
    <Dialog
      maxWidth="xs"
      {...props}
    >
      <DialogTitle>Upload de arquivos</DialogTitle>
      <DialogContent className="select-files-to-upload-content">
        <div className="drag-imagens" {...getRootProps()}>
          <input {...getInputProps()} accept='application/pdf,image/*' />
          {
            isDragActive ?
              <Typography>Arraste o(s) arquivo(s) aqui...</Typography> :
              <Typography>Arraste e solte o(s) arquivo(s) aqui, ou clique aqui e selecione o(s) arquivo(s)</Typography>
          }
        </div>
        {filesToUpload.map((fileToUpload, index) => 
          <div className="item-upload" key={index}>
            <Thubmnail file={fileToUpload.file} />
            <div className="item-details">
              {names.length === 0 &&
                <TextField
                  label="Nome do documento"
                  type="text"
                  size="small"
                  variant="outlined"
                  name="nome"
                  value={fileToUpload.nome}
                  disabled={!!loading}
                  onChange={e => handleChange(e, index)}
                />
              }
              {names.length > 0 && 
                <FormControl 
                  variant="outlined"
                  fullWidth
                >
                  <InputLabel id={index.toString()} >Nome do documento</InputLabel>
                  <Select
                    labelId={index.toString()}
                    label="Nome do documento"
                    margin="dense"
                    value={fileToUpload.nome}
                    onChange={e => handleChange(e, index)}
                  >
                    {names.map(name => 
                      <MenuItem key={name} value={name}>{name}</MenuItem>
                    )}
                  </Select>
                </FormControl>
              }
            </div>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          disabled={!!loading || !canUpload()}
          startIcon={!loading ? <CloudUploadIcon /> : <CircularProgress size={20} />}
          onClick={() => onUpload(filesToUpload)}
        >
          Upload
        </Button>
        <Button
          color="secondary"
          variant="contained"
          disabled={!!loading}
          onClick={onClose}
        >
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default SelectFilesToUpload;