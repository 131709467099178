import api from "../../services/api";

import { put, call, select } from "redux-saga/effects";

import { getToken } from "../../services/auth";

import { Creators as WelcomeActions } from "../ducks/welcome";

export const getOrganization = (state) => state.usuarios.auth.organization;

export function* latestNewsList({ payload }) {
  try {
    yield put(WelcomeActions.latestNewsSuccess([]));
  } catch (error) {
    yield put(WelcomeActions.latestNewsSuccess([]));
  }
}

export function* biToken(action) {
  const organization = yield select(getOrganization);

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const { data } = yield call(api.get, `/bitoken`, headerParams);

    yield put(WelcomeActions.biTokenSuccess(data.token));
  } catch (error) {
    console.error(error);
  }
}
