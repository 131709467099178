export const Types = {
  NEW: 'bloqueio/NEW',
  EDIT: 'bloqueio/EDIT',
  CANCEL: 'bloqueio/CANCEL',
  SAVE_REQUEST: 'bloqueio/SAVE_REQUEST',
  SAVE_SUCCESS: 'bloqueio/SAVE_SUCCESS',
  SAVE_ERROR: 'bloqueio/SAVE_ERROR',
  OPEN_UPLOAD: 'bloqueio/OPEN_UPLOAD',
  CLOSE_UPLOAD: 'bloqueio/CLOSE_UPLOAD',
  UPLOAD_REQUEST: 'bloqueio/UPLOAD_REQUEST',
  UPLOAD_SUCCESS: 'bloqueio/UPLOAD_SUCCESS',
  UPLOAD_ERROR: 'bloqueio/UPLOAD_ERROR',
  OPEN_VIEW: 'bloqueio/OPEN_VIEW',
  CLOSE_VIEW: 'bloqueio/CLOSE_VIEW',
  OPEN_VIEW_IMAGENS: 'bloqueio/OPEN_VIEW_IMAGENS',
  CLOSE_VIEW_IMAGENS: 'bloqueio/CLOSE_VIEW_IMAGENS',
  OPEN_DELETE: 'bloqueio/OPEN_DELETE',
  CLOSE_DELETE: 'bloqueio/CLOSE_DELETE',
  DELETE_REQUEST: 'bloqueio/DELETE_REQUEST',
  DELETE_SUCCESS: 'bloqueio/DELETE_SUCCESS',
  DELETE_ERROR: 'bloqueio/DELETE_ERROR',
  DELETE_IMAGE_REQUEST: 'bloqueio/DELETE_IMAGE_REQUEST',
  DELETE_IMAGE_SUCCESS: 'bloqueio/DELETE_IMAGE_SUCCESS',
  DELETE_IMAGE_ERROR: 'bloqueio/DELETE_IMAGE_ERROR'
}

const INITIAL_STATE = {
  data: {},
  loading: false,
  open: false,
  openUpload: false,
  openView: false,
  openViewImages: false,
  openDelete: false,
}

export default function bloqueio(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.NEW:
      return {
        ...state,
        data: {},
        loading: false,
        open: true
      }

    case Types.EDIT:
      return { 
        ...state,
        data: action.payload.bloqueio,
        loading: false,
        open: true
      }

    case Types.SAVE_REQUEST:
      return { 
        ...state,
        loading: true,
        data: action.payload.bloqueio,
      }
    
    case Types.SAVE_SUCCESS:
      return { 
        ...state,
        loading: false,
        open: false
      }

    case Types.SAVE_ERROR:
      return {
        ...state,
        loading: false
      }

    case Types.CANCEL:
      return {
        ...state,
        loading: false,
        open: false
      }

    case Types.OPEN_UPLOAD:
      return {
        ...state,
        openUpload: true,
        data: action.payload.bloqueio
      }

    case Types.CLOSE_UPLOAD:
      return {
        ...state,
        openUpload: false,
        data: {}
      }

    case Types.UPLOAD_REQUEST:
      return {
        ...state,
        loading: true
      }

    case Types.UPLOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        openUpload: false
      }

    case Types.UPLOAD_ERROR:
      return {
        ...state,
        loading: false
      }

    case Types.OPEN_VIEW:
      return {
        ...state,
        openView: true,
        data: action.payload.bloqueio,
      }

    case Types.CLOSE_VIEW:
      return {
        ...state,
        openView: false,
        data: {},
      }

    case Types.OPEN_DELETE:
      return {
        ...state,
        openDelete: true,
        data: action.payload.bloqueio,
      }

    case Types.CLOSE_DELETE:
      return {
        ...state,
        openDelete: false,
        data: {}
      }

    case Types.DELETE_REQUEST:
      return { ...state, loading: true }

    case Types.DELETE_SUCCESS:
      return { ...state, loading: false, openDelete: false }

    case Types.DELETE_ERROR:
      return { ...state, loading: false }

    case Types.OPEN_VIEW_IMAGENS:
      return { ...state, openViewImages: true, data: action.payload.bloqueio }

    case Types.CLOSE_VIEW_IMAGENS:
      return { ...state, openViewImages: false, data: {} }

    case Types.DELETE_IMAGE_REQUEST:
      return { ...state, loading: true }

    case Types.DELETE_IMAGE_SUCCESS:
      return { ...state, loading: false, data: action.payload.bloqueio }

    case Types.DELETE_IMAGE_ERROR:
      return { ...state, loading: false }

    default:
      return state;
  }
}

export const Creators = {
  new: () => ({
    type: Types.NEW,
  }),
  edit: bloqueio => ({
    type: Types.EDIT,
    payload: { bloqueio }
  }),
  cancel: () => ({
    type: Types.CANCEL
  }),
  saveRequest: bloqueio => ({
    type: Types.SAVE_REQUEST,
    payload: { bloqueio }
  }),
  saveSuccess: () => ({
    type: Types.SAVE_SUCCESS,
  }),
  saveError: () => ({
    type: Types.SAVE_ERROR,
  }),
  openUpload: bloqueio => ({
    type: Types.OPEN_UPLOAD,
    payload: { bloqueio }
  }),
  closeUpload: () => ({
    type: Types.CLOSE_UPLOAD
  }),
  uploadRequest: body => ({
    type: Types.UPLOAD_REQUEST,
    payload: { body }
  }),
  uploadSuccess: () => ({
    type: Types.UPLOAD_SUCCESS
  }),
  uploadError: () => ({
    type: Types.UPLOAD_ERROR
  }),
  openView: bloqueio => ({
    type: Types.OPEN_VIEW,
    payload: { bloqueio }
  }),
  closeView: () => ({
    type: Types.CLOSE_VIEW
  }),
  openDeleteDialog: bloqueio => ({
    type: Types.OPEN_DELETE,
    payload: { bloqueio }
  }),
  closeDeleteDialog: () => ({
    type: Types.CLOSE_DELETE
  }),
  deleteRequest: () => ({
    type: Types.DELETE_REQUEST,
  }),
  deleteSuccess: () => ({
    type: Types.DELETE_SUCCESS
  }),
  deleteError: () => ({
    type: Types.DELETE_ERROR
  }),
  openViewImages: bloqueio => ({
    type: Types.OPEN_VIEW_IMAGENS,
    payload: { bloqueio }
  }),
  closeViewImages: () => ({
    type: Types.CLOSE_VIEW_IMAGENS
  }),
  deleteImageRequest: idImage => ({
    type: Types.DELETE_IMAGE_REQUEST,
    payload: { idImage }
  }),
  deleteImageSuccess: bloqueio => ({
    type: Types.DELETE_IMAGE_SUCCESS,
    payload: { bloqueio }
  }),
  deleteImageError: () => ({
    type: Types.DELETE_IMAGE_ERROR
  })
}