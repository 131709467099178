import React, { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import FormularioCadastro from "components/FormularioCadastro";
import CheckboxForm from "components/CheckboxForm";
import ToolTipComponent from "components/Tooltip";
import InputForm from "components/InputForm";
import AutocompleteForm from "components/AutocompleteForm";
import Alert from "@material-ui/lab/Alert";

import { Creators as ParamAssinaturaEletronicaActions } from "store/ducks/paramassinaturaeletronica";

export default function FormParamAssinaturaEletronic() {
  const dispatch = useDispatch();

  const formRef = useRef();

  const { data, loading } = useSelector(
    (state) => state.paramassinaturaeletronica
  );

  const [enableRSAField, setEnableRSAField] = useState(false);
  const [selectedType, setSelectedType] = useState(data?.TYPE ?? "docusign");

  const showFields = !loading;

  function handleSubmit(newValues) {
    if (!enableRSAField && newValues["RSA_PRIVATE_KEY"]) {
      delete newValues["RSA_PRIVATE_KEY"];
    }

    if (!enableRSAField) {
      delete newValues["RSA_PRIVATE_KEY"];
    }

    dispatch(ParamAssinaturaEletronicaActions.editRequest(newValues));

    setEnableRSAField(false);
  }

  useEffect(() => {
    dispatch(ParamAssinaturaEletronicaActions.listRequest());
  }, [dispatch]);

  return (
    <>
      <FormularioCadastro
        title={"Parâmetros de assinatura eletrônica"}
        canSave={true}
        formref={formRef}
        initialData={data}
        onSubmit={handleSubmit}
      >
        {showFields && (
          <>
            <AutocompleteForm
              name="TYPE"
              label="Tipo de assinaturas"
              options={["docusign", "autentique"]}
              onChange={(e, newValue) => setSelectedType(newValue)}
            />

            <InputForm
              required
              label="BASE_PATH"
              name="BASE_PATH"
              type="text"
            />

            <InputForm
              required
              label="INTEGRATION_KEY"
              name="INTEGRATION_KEY"
              type="text"
            />

            {selectedType === "docusign" && (
              <>
                <InputForm
                  required
                  label="USER_ID"
                  name="USER_ID"
                  type="text"
                />

                <Alert className="mt-2" severity="warning">
                  Por motivos de segurança, a chave RSA atual não será exibida
                </Alert>
                <ToolTipComponent title="Editar" placement="top">
                  <CheckboxForm
                    label="Criar/Alterar chave RSA"
                    name="gerarRSA"
                    onChange={(e) => setEnableRSAField(e.target.checked)}
                  />
                </ToolTipComponent>
                {enableRSAField && (
                  <InputForm
                    label="RSA_PRIVATE_KEY"
                    name="RSA_PRIVATE_KEY"
                    type="text"
                    multiline
                    rows={20}
                  />
                )}
              </>
            )}
          </>
        )}
      </FormularioCadastro>
    </>
  );
}
