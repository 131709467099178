import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { DialogContent } from "@material-ui/core";
import { Row, Col } from "react-bootstrap";

import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Skeleton from "@material-ui/lab/Skeleton";
import Dialog from "@material-ui/core/Dialog";

import useForm from "react-hook-form";

import { Creators as GrupoAcessoActions } from "../../../../../store/ducks/grupoacesso";

import { Button, GroupButton, DialogTitle } from "./style";

const INITIAL_FORM = {
  accessGroup: null,
};

function ModalFilterGrupoAcesso({ open, handleClose, handlePrint }) {
  const dispatch = useDispatch();

  const { list: listGrupoAcessos, loading } = useSelector(
    (state) => state.grupoacesso
  );

  const { register } = useForm();
  const [form, setForm] = useState(INITIAL_FORM);

  const handleChangeForm = (name, value) => {
    setForm({ ...form, [name]: value });
  };

  useEffect(() => {
    dispatch(
      GrupoAcessoActions.listRequest({ page: 1, limit: 100, sort: "name" })
    );
  }, [dispatch]);

  const disableSendButtonWhen = !form.accessGroup;

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        id="alert-dialog-title"
        style={{ minWidth: "600px" }}
      ></DialogTitle>
      <DialogContent>
        <Row className="mt-3">
          <Col md={12}>
            {loading ? (
              <Skeleton width="100%" height={50} />
            ) : (
              <Autocomplete
                required
                size="small"
                onChange={(event, newValue) => {
                  handleChangeForm("accessGroup", newValue);
                }}
                options={listGrupoAcessos}
                getOptionLabel={(option) => option.name}
                noOptionsText="Selecione um grupo de acesso"
                getOptionSelected={(option, value) => option._id === value._id}
                clearOnBlur={false}
                name="accessGroup"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputRef={register}
                    name="accessGroup"
                    label="Grupo de acesso"
                    variant="outlined"
                  />
                )}
              />
            )}
          </Col>
        </Row>
      </DialogContent>
      <GroupButton className="mt-2">
        <Button variant="contained" color="secondary" onClick={handleClose}>
          Cancelar
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={disableSendButtonWhen}
          onClick={() => {
            handlePrint({
              accessGroup: form.accessGroup,
            });
          }}
        >
          Imprimir
        </Button>
      </GroupButton>
    </Dialog>
  );
}

export default ModalFilterGrupoAcesso;
