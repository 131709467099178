import React, { useRef, useEffect } from 'react';
import { useField } from '@unform/core';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormHelperText from '@material-ui/core/FormHelperText';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    marginTop: '8px'
  }
})

function CheckboxForm({ name, label, ...rest }) {
  const inputRef = useRef(null);

  const { fieldName, defaultValue, registerField, error } = useField(name);

  const classes = useStyles();

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'checked'
    });
  }, [fieldName, registerField])
  
  return (
    <FormControl className={classes.root} component="fieldset">
      <FormControlLabel 
        label={label}
        control={
          <Checkbox
            inputRef={inputRef}
            name={name}
            defaultChecked={defaultValue}
            {...rest}
          />
        }
      />
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
}

export default CheckboxForm;