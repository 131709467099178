import React from 'react';

import history from '../../routes/history';

import Button from "@material-ui/core/Button";

function BackButton({ variant = 'contained', type = 'button', children, ...props }) {

    const back = () => {
        history.goBack();
    }

    return <Button  onClick={back} variant={variant} type={type} {...props}>{children}</Button>;

}

export default BackButton;