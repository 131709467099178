import api from 'services/api';
import { getToken } from 'services/auth';
import { call, select, put, all, takeLatest } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';

import { Creators as ProvisoesActions, Types } from 'store/ducks/provisoes';

export const getOrganization = (state) => state.usuarios.auth.organization;

export function* listProvisoes() {
  const organization = yield select(getOrganization);

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization }
    };

    const { data } = yield call(api.get, 'provisoes', headerParams);

    yield put(ProvisoesActions.listSuccess(data))

  } catch(err) {
    yield put(ProvisoesActions.listError());
    toastr.error('Ops', 'Não foi possível listar as provisões');
  }
}

export default all([
  takeLatest(Types.LIST_REQUEST, listProvisoes)
])