import * as Yup from "yup";
import Locale from "./Locale";
import moment from "moment";

Yup.setLocale(Locale);

function greaterThanSevenDays(value, parent, valueToCompare) {
  if (!value) return true;

  if (!parent[valueToCompare]) return true;

  const endDate = moment(value, 'YYYY-MM-DD');
  const startDate = moment(parent[valueToCompare], 'YYYY-MM-DD');

  const differenceInDays = endDate.diff(startDate, 'days')

  return differenceInDays < 7;
}

function requiredFieldWhen(field) {
  return Yup.string()
    .optional()
    .test({
      name: "valor-obrigatorio",
      message: "Campo obrigatorio ao ter um período final",
      test: function (value) {
        return !this.parent[field] || !!value;
      },
    });
}

function createdEndFieldValidation({ dataInicial }) {
  return requiredFieldWhen(dataInicial).test({
    name: "maior",
    message: "Período deve ser menor que 8 dias",
    test: function (value) {
      return greaterThanSevenDays(value, this.parent, dataInicial);
    },
  });
}

const FilterSevenDaysSchema = Yup.object().shape({
  dataInicial: Yup.string().required().typeError('Data inicial é obrigatória'),
  dataFinal: createdEndFieldValidation({ dataInicial: "dataInicial" }),
  departamento: Yup.object().required().typeError("Departamento é obrigatório"),
});

export { FilterSevenDaysSchema };
