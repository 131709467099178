import React, { useCallback, useState, useEffect, forwardRef, useMemo } from 'react';
import { useDropzone } from 'react-dropzone'
import { useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Tooltip from '@material-ui/core/Tooltip';

import Thubmnail from 'components/Thumbnail';

import './styles.css';

function ClassificacaoImagens({ tipoDocumento, onChange }, ref) {

  const { list } = useSelector(state => state.tipoImagem);
  const { loading } = useSelector(state => state.documentos);

  const imagens = useMemo(() => {
    if (tipoDocumento && list.length > 0) {
      return list.filter(item => item.tipoDocumento._id === tipoDocumento._id);
    }
    return [];
  }, [list, tipoDocumento])

  const [filesForIndexing, setFilesForIndexing] = useState([]);
  const [checkAll, setCheckAll] = useState(false);

  if (ref) {
    ref.current.clearOnSave = () => {
      setFilesForIndexing(filesForIndexing.filter(fileForIndexing => !fileForIndexing.checked));
    }
  }
  
  const onDrop = useCallback(async acceptedFiles => {
    const validFiles = acceptedFiles.filter(file => file.type === 'application/pdf' || file.type.indexOf('image') !== -1);
    setFilesForIndexing(f => ([
      ...f,
      ...validFiles.map(file => ({ file, tipoImagem: null, checked: checkAll }))
    ]))
  }, [checkAll]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  useEffect(() => {
    onChange(filesForIndexing)
  }, [filesForIndexing, onChange])

  function handleChangeTipoImagem(value, index) {
    setFilesForIndexing(filesForIndexing.map((fileForIndexing, index2) => ({
      ...fileForIndexing,
      tipoImagem: index === index2 ? value : fileForIndexing.tipoImagem
    })))
  }

  function handleChecked({ target }, index) {
    const { checked } = target;
    setFilesForIndexing(filesForIndexing.map((fileForIndexing, index2) => ({
      ...fileForIndexing,
      checked: index === index2 ? checked : fileForIndexing.checked
    })))
  }

  function handleCheckedAll({ target }) {
    const { checked } = target;

    setCheckAll(checked);

    setFilesForIndexing(filesForIndexing.map(fileForIndexing => ({
      ...fileForIndexing,
      checked
    })));
  }

  return (
    <div className="classificacao-imagens">
      <div className="drag-imagens" {...getRootProps()}>
        <input {...getInputProps()} accept='application/pdf,image/*' />
        {
          isDragActive ?
            <Typography>Arraste o(s) arquivo(s) aqui...</Typography> :
            <Typography>Arraste e solte o(s) arquivo(s) aqui, ou clique aqui e selecione o(s) arquivo(s)</Typography>
        }
      </div>
      {filesForIndexing.length > 0 && 
      <div className="item-classificacao">
        <Tooltip title="Marcar/Desmarcar todos">
          <Checkbox color="primary" onChange={handleCheckedAll} disabled={loading} />
        </Tooltip>
        <Typography className="mark-all">Marcar/Desmarcar</Typography>
      </div>}
      {filesForIndexing.map((fileForIndexing, index) => 
        <div className="item-classificacao" key={index}>
          <Checkbox
            checked={fileForIndexing.checked}
            inputProps={{ 'aria-label': 'primary checkbox' }}
            onChange={e => handleChecked(e, index)}
            color="primary"
            disabled={loading}
          />
          <Thubmnail file={fileForIndexing.file} />
          <div className="item-detalhes">
            <Typography noWrap>{fileForIndexing.file.name}</Typography>
            <Autocomplete 
              options={imagens}
              getOptionSelected={(option, value) => option._id === value._id }
              getOptionLabel={option => option.nome}
              onChange={(event, newValue) => handleChangeTipoImagem(newValue, index)}
              size="small"
              disabled={loading}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Tipo de imagem"
                  variant="outlined"
                  error={fileForIndexing.checked && !fileForIndexing.tipoImagem}
                  helperText={fileForIndexing.checked && !fileForIndexing.tipoImagem && "Informe o tipo da imagem"}
                />
              )}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default forwardRef(ClassificacaoImagens);