import styled from 'styled-components/macro';

export const OptionBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    
    &:not(:last-of-type) {
        padding-bottom: 8px;
        border-bottom: 1px solid #CCC;
        margin-bottom: 8px;
    }
    padding-bottom: 8px;
        border-bottom: 1px solid #CCC;
        margin-bottom: 8px;
`;

export const OptionHeader = styled.div`
    font-size: 20px;
    color: blue;
    margin-bottom: 4px;
`;

export const OptionBody = styled.div`
    padding: 0 8px;
    display: flex;
    justify-content: space-between;
`;

export const OptionDetails = styled.div`
    padding: 0 8px;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    color: #444;

    & > div {
        display: flex;
        justify-content: space-between;
    }

`;

export const OptionDetailsLabel = styled.span`
    font-size: 12px;
    color: #999;
`;