import React, { useRef, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Form } from '@unform/web';
import { useDispatch, useSelector } from "react-redux";
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress'
import { ValidationError } from 'yup';
import Skeleton from '@material-ui/lab/Skeleton';

import InputForm from 'components/InputForm';
import SelectForm from 'components/SelectForm';
import BackButton from 'components/BackButton';
import AutocompleteForm from 'components/AutocompleteForm';

import { Creators as EmpresasActions } from 'store/ducks/empresas';
import { Creators as AcessosRPAActions } from 'store/ducks/acessosrpa';
import { Creators as ComarcasActions } from "store/ducks/comarca";
import Content from 'components/Content';

function FormAcessoRPA({ location }) {
  const dispatch = useDispatch();
  const formRef = useRef();

  const { acessoRPA } = location.state || {};

  const { list: empresas, loading: loadingEmpresas } = useSelector(state => state.empresas);
  const { list: listComarcas, loading: loadingComarcas } = useSelector(state => state.comarca);
  const { loading } = useSelector(state => state.acessosrpa);

  useEffect(() => {
    dispatch(EmpresasActions.listRequest());
  }, [dispatch]);

  useEffect(() => {
    if (acessoRPA)
      dispatch(ComarcasActions.listRequest({
        page: 1,
        limit: Number.MAX_SAFE_INTEGER,
        uf: acessoRPA.uf,
      }))
  }, [dispatch, acessoRPA]);

  function handleChangeUF({ target }) {
    dispatch(ComarcasActions.listRequest({
      page: 1,
      limit: Number.MAX_SAFE_INTEGER,
      uf: target.value,
    }))
  }

  async function handleSubmit(data) {
    try {

      const body = {
        ...data,
        _id: acessoRPA ? acessoRPA._id : null
      }

      dispatch(AcessosRPAActions.saveRequest(body))
    } catch(err) {
      const validationErrors = {};
      if (err instanceof ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });

        formRef.current.setErrors(validationErrors);
      }
    }
  }

  return (
    <Content>
          <Row>
            <Col md="12" className="painel">
              <div className="painel-content">
                <div className="painel-title text-left">Acesso RPA</div>
                <div className="painel-body">
                  <Form
                    ref={formRef}
                    initialData={acessoRPA}
                    onSubmit={handleSubmit}
                  >
                    <Row className="row">
                      <Col>
                        <SelectForm name="sistema" label="Sistema">
                          <MenuItem value="SINDEC">SINDEC</MenuItem>
                          <MenuItem value="PJE">PJE</MenuItem>
                          <MenuItem value="E-SAJ">E-SAJ</MenuItem>
                          <MenuItem value="E-PROC">E-PROC</MenuItem>
                          <MenuItem value="OUTROS">Outros</MenuItem>
                        </SelectForm>
                      </Col>
                      <Col>
                        <InputForm name="contato" label="Contato" />
                      </Col>
                      <Col>
                        <InputForm name="link" label="Link" />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <SelectForm 
                          name="uf" 
                          label="UF" 
                          onChange={handleChangeUF}
                        >
                          <MenuItem value="AC">AC</MenuItem>
                          <MenuItem value="AL">AL</MenuItem>
                          <MenuItem value="AP">AP</MenuItem>
                          <MenuItem value="AM">AM</MenuItem>
                          <MenuItem value="BA">BA</MenuItem>
                          <MenuItem value="CE">CE</MenuItem>
                          <MenuItem value="DF">DF</MenuItem>
                          <MenuItem value="ES">ES</MenuItem>
                          <MenuItem value="GO">GO</MenuItem>
                          <MenuItem value="MA">MA</MenuItem>
                          <MenuItem value="MT">MT</MenuItem>
                          <MenuItem value="MS">MS</MenuItem>
                          <MenuItem value="MG">MG</MenuItem>
                          <MenuItem value="PA">PA</MenuItem>
                          <MenuItem value="PB">PB</MenuItem>
                          <MenuItem value="PR">PR</MenuItem>
                          <MenuItem value="PE">PE</MenuItem>
                          <MenuItem value="PI">PI</MenuItem>
                          <MenuItem value="RJ">RJ</MenuItem>
                          <MenuItem value="RN">RN</MenuItem>
                          <MenuItem value="RS">RS</MenuItem>
                          <MenuItem value="RO">RO</MenuItem>
                          <MenuItem value="RR">RR</MenuItem>
                          <MenuItem value="SC">SC</MenuItem>
                          <MenuItem value="SP">SP</MenuItem>
                          <MenuItem value="SE">SE</MenuItem>
                          <MenuItem value="TO">TO</MenuItem>
                        </SelectForm>
                      </Col>
                      <Col>
                        {!!loadingComarcas && <Skeleton width="100%" height={50} />}
                        {!loadingComarcas &&
                        <AutocompleteForm
                          options={listComarcas}
                          label="Comarca"
                          name="comarca"
                          getOptionLabel={option => option.nome}
                          transformOnSelect={option => option ? option._id : option}
                          disabled={formRef.current && formRef.current.getFieldValue('uf') === ''}
                        />}
                      </Col>
                      <Col>
                        <InputForm name="login" label="Login" />
                      </Col>
                      <Col>
                        <InputForm name="senha" label={acessoRPA && acessoRPA._id ? 'Nova senha' : 'Senha'} type="password" />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        {!loadingEmpresas && 
                        <AutocompleteForm
                          multiple
                          options={empresas.sort((a, b) =>
                            b.grupoEmpresarial &&
                            a.grupoEmpresarial &&
                            b.grupoEmpresarial.nome &&
                            a.grupoEmpresarial.nome
                              ? -b.grupoEmpresarial.nome.localeCompare(
                                  a.grupoEmpresarial.nome
                                )
                              : b.grupoEmpresarial && !a.grupoEmpresarial
                              ? -1
                              : 1
                          )}
                          groupBy={option =>
                            option.grupoEmpresarial
                              ? option.grupoEmpresarial.nome
                              : ""
                          }
                          label="Empresas"
                          name="empresas"
                          getOptionLabel={option => option.razaoSocial}
                          transformOnSelect={(options) =>
                            options ? options.map((option) => option._id) : []
                          }
                        />}
                        {!!loadingEmpresas && <Skeleton width="100%" height={50} />}
                      </Col>
                    </Row>
                    <Grid container spacing={1}>
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          disabled={loading || !!loadingEmpresas}
                          startIcon={!loading ? null : <CircularProgress size={20} />}
                        >
                          Enviar
                        </Button>
                      </Grid>
                      <Grid item>
                        <BackButton variant="contained" color="secondary" disabled={loading}>
                          Voltar
                        </BackButton>
                      </Grid>
                    </Grid>
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
          </Content>
  );
}

export default FormAcessoRPA;