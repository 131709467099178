import api from "services/api";
import { getToken } from "services/auth";
import { call, select, put, all, takeLatest } from "redux-saga/effects";
import { toastr } from "react-redux-toastr";

import {
  Creators as ParamAssinaturaEletronicaActions,
  Types,
} from "../ducks/paramassinaturaeletronica";

export const getOrganization = (state) => state.usuarios.auth.organization;
export const getUserId = (state) => state.usuarios.auth.id;

export function* listAssinaturaEletronica({ payload }) {
  try {
    const organization = yield select(getOrganization);

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const response = yield call(
      api.get,
      `param/assinaturaeletronica`,
      headerParams
    );

    yield put(
      ParamAssinaturaEletronicaActions.listSuccess(response.data || [])
    );
  } catch (error) {
    toastr.error(
      "Ops",
      "Não foi possível listar os parâmetros de assinatura eletronica."
    );
  }
}

export function* editAssinaturaEletronica({ payload }) {
  try {
    const organization = yield select(getOrganization);

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const response = yield call(
      api.post,
      `param/assinaturaeletronica`,
      payload,
      headerParams
    );

    yield put(
      ParamAssinaturaEletronicaActions.editSuccess(response.data || [])
    );

    yield put(ParamAssinaturaEletronicaActions.listRequest());
  } catch (error) {
    toastr.error(
      "Ops",
      "Não foi possível criar ou editar os parâmetros de assinatura eletronica."
    );
  }
}

export default all([
  takeLatest(Types.LIST_REQUEST, listAssinaturaEletronica),
  takeLatest(Types.EDIT_REQUEST, editAssinaturaEletronica),
]);
