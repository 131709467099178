import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { Form } from "@unform/web";

import Skeleton from "@material-ui/lab/Skeleton";
import Grid from "@material-ui/core/Grid";
import ButtonMui from "@material-ui/core/Button";
import { FormControlLabel, Switch } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

import BackButton from "components/BackButton";
import InputForm from "components/InputForm";
import DepartamentoSchema from "validators/DepartamentoSchema";
import { ValidationError } from "yup";

import AutocompleteForm from "components/AutocompleteForm";

import { Creators as CategoriasActions } from "store/ducks/categorias";
import { Creators as GrupoAcessoActions } from "store/ducks/grupoacesso";
import { Creators as DepartamentosActions } from "store/ducks/departamentos";
import { Creators as TipoDepartamentoActions } from "store/ducks/tipodepartamento";

import { TransferList } from "components/TransferList";
import Content from "components/Content";

export default function EditTiposDocumento(params) {
  const dispatch = useDispatch();
  const formRef = useRef(null);

  const { deptos } = params.location.state;

  const { _id, ...initialData } = deptos;
  const [listaTipos, setListaTipos] = useState([]);
  const [listaCategorias, setListaCategorias] = useState([]);
  const [status, setStatus] = useState(deptos ? deptos.status : false);
  const [operacional, setOperacional] = useState(deptos ? deptos.operacional : false);
  const [accessGroupListSelected, setAccessGroupListSelected] = useState(deptos ? deptos.gruposDeAcesso : []);

  const { loading, gruposDeAcesso } = useSelector((state) => state.departamentos);

  const {
    access: { empresas },
  } = useSelector((state) => state.permissoes);
  const { list: accessGroupList } = useSelector((state) => state.grupoacesso);

  const { list: empresasList, loading: loadingEmpresas } = useSelector(
    (state) => state.empresas
  );

  const { list: categoriasList, loading: loadingCategorias } = useSelector(
    (state) => state.categorias
  );

  const { list: tiposList, loading: loadingTipos } = useSelector(
    (state) => state.tipodepartamento
  );

  const handleCheckStatus = () => {
    setStatus(!status);
  };

  const handleCheckOperacional = () => {
    setOperacional(!operacional);
  };

  const onSubmitEdit = async (values) => {
    const body = { ...values, _id, status, operacional, gruposDeAcesso: accessGroupListSelected };

    try {
      await DepartamentoSchema.validate(body, {
        abortEarly: false,
      });

      dispatch(DepartamentosActions.editRequest(body));
    } catch (err) {
      const validationErrors = {};
      if (err instanceof ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });

        formRef.current.setErrors(validationErrors);
      }
    }
  };

  useEffect(() => {
    dispatch(
      GrupoAcessoActions.listRequest({ page: 1, limit: 10000, sort: "name" })
    );
    dispatch(CategoriasActions.listRequest());
    dispatch(TipoDepartamentoActions.listRequest());
  }, [dispatch, empresas]);

  useEffect(() => { setListaTipos(tiposList) }, [tiposList]);
  useEffect(() => { setAccessGroupListSelected(gruposDeAcesso) }, [gruposDeAcesso]);
  useEffect(() => { setListaCategorias(categoriasList.filter(e => e.status)) }, [categoriasList]);

  return (
    <Content>
      <Row>
        <Col md="12" className="painel">
          <div className="painel-content">
            <div className="painel-title text-left">
              Editar Departamento
            </div>
            <div className="painel-body">
              <Form
                ref={formRef}
                initialData={initialData}
                onSubmit={onSubmitEdit}
              >
                <Row className="row">
                  <Col md={5}>
                    <InputForm name="nome" label="Nome do departamento" />
                  </Col>
                  <Col md={1}>
                    <InputForm name="codigo" label="Código" />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    {loadingTipos ? (
                      <Skeleton width="100%" height={50} />
                    ) : (
                      <AutocompleteForm
                        label="Tipo"
                        name="tipoDepartamento"
                        options={listaTipos}
                        transformOnSelect={(option) =>
                          option ? option._id : null
                        }
                        getOptionLabel={(option) => option.nome}
                      />
                    )}
                  </Col>
                </Row>
                <Row className="row">
                  <Col md={3}>
                    <InputForm
                      type="number"
                      name="maximoEncaminhamento"
                      label="Máximo Encaminhamento"
                      InputProps={{ inputProps: { min: 1, max: 30 } }}
                    />
                  </Col>
                  <Col md={3}>
                    <InputForm
                      type="number"
                      name="prazo"
                      label="Prazo"
                      InputProps={{ inputProps: { min: 1, max: 30 } }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    {loadingEmpresas ? (
                      <Skeleton width="100%" height={50} />
                    ) : (
                      <AutocompleteForm
                        label="Empresa"
                        name="empresa"
                        options={empresasList}
                        transformOnSelect={(option) =>
                          option ? option._id : null
                        }
                        getOptionLabel={(option) => option.fantasia}
                      />
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <InputForm name="email" label="Email do responsável" />
                  </Col>
                </Row>
                <Row>
                  <Col md={3}>
                    <InputForm
                      name="horarioInicialDaOperacao"
                      label="Horário Inicial Da Operação"
                      type="time"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Col>
                  <Col md={3}>
                    <InputForm
                      name="horarioFinalDaOperacao"
                      label="Horário Final da Operação"
                      type="time"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    {loadingCategorias ? (
                      <Skeleton width="100%" height={50} />
                    ) : (
                      <AutocompleteForm
                        label="Categoria"
                        name="categoria"
                        options={listaCategorias}
                        transformOnSelect={(option) =>
                          option ? option._id : null
                        }
                        getOptionLabel={(option) => option.nome}
                      />
                    )}
                  </Col>
                </Row>

                <Row>
                  <Col md={3}>
                    <FormControlLabel
                      control={
                        <Switch
                          name="status"
                          onChange={handleCheckStatus}
                          color="primary"
                          defaultChecked={deptos ? deptos.status : false}
                        />
                      }
                      label="Ativo"
                    />
                  </Col>
                  <Col md={3}>
                    <FormControlLabel
                      control={
                        <Switch
                          name="operacional"
                          onChange={handleCheckOperacional}
                          color="primary"
                          defaultChecked={deptos ? deptos.operacional : false}
                        />
                      }
                      label="Operacional"
                    />
                  </Col>
                </Row>

                <Row>
                  <Col sm={8}>
                    <TransferList
                      title='Grupos de Acesso'
                      list={accessGroupList}
                      titleList="Disponíveis:"
                      value={accessGroupListSelected}
                      placeholder="Filtrar por grupo de acesso"
                      titleValue="Relacionados ao departamento:"
                      onChange={(value) => setAccessGroupListSelected(value)}
                    />
                  </Col>
                </Row>

                <Grid container spacing={1}>
                  <Grid item>
                    <ButtonMui
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={loading}
                    >
                      Enviar
                      {!!loading && <CircularProgress size={20} />}
                    </ButtonMui>
                  </Grid>
                  <Grid item>
                    <BackButton variant="contained" color="secondary">
                      Voltar
                    </BackButton>
                  </Grid>
                </Grid>
              </Form>
            </div>
          </div>
        </Col>
      </Row>
    </Content>
  );
}
