import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TablePagination,
  TableFooter,
} from '@material-ui/core';
import CustomTableSortLabel from 'components/CustomTableSortLabel';
import StyledTableRow from 'components/StyledTableRow';
import LoadingTable from 'components/LoadingTable';
import { Link, useLocation } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';

import ToolTipComponent from 'components/Tooltip';

import ButtonSquare from 'components/ButtonSquare';

import { Creators as OnboardingActions } from 'store/ducks/onboarding';

import './styles.css';

export default function ListOnboarding() {
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const {
    list: onboardings,
    loading,
    page = 1,
    limit = 10,
    total,
    sort = 'nome',
  } = useSelector((state) => state.onboarding);
  const {
    access: { servicos, empresas },
  } = useSelector((state) => state.permissoes);

  const [request, setRequest] = useState({ page, limit, sort });
  const { organization } = useSelector((state) => state.usuarios.auth);
  const [userActions, setUserActions] = useState([]);

  useEffect(() => {
    dispatch(OnboardingActions.listRequest({ empresas, ...request }));
  }, [dispatch, empresas, request]);

  useEffect(() => {
    let find = servicos.find((item) => item.route === pathname);
    if (find !== undefined) {
      setUserActions(find);
    }
  }, [pathname, servicos]);

  function handleChangeRowsPerPage({ target }) {
    const { value: limit } = target;
    setRequest({ ...request, limit, page: 1 });
  }

  function handleChangePage(e, newPage) {
    setRequest({ ...request, page: newPage + 1 });
  }

  function handleChangeSort(sort) {
    setRequest({ ...request, sort });
  }

  function handleDelete(item) {
    dispatch(OnboardingActions.removeRequest(item._id));
  }

  const copyLinkToClipboard = (link) => {
    const baseUrl = window.location.origin;

    const fullLink = `${baseUrl}/onboarding/${organization}/${link}`;

    navigator.clipboard
      .writeText(fullLink)
      .then(() => {
        toastr.success('Link copiado!', 'Compartilhe com quem deve acessa-lo.');
      })
      .catch((error) => {
        console.error('Erro ao copiar o link:', error);
      });
  };

  return (
    <TableContainer className="table-tipo-documento-container">
      {(onboardings && onboardings.length > 0) || !!loading ? (
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                <CustomTableSortLabel
                  currentSort={request.sort}
                  sortField="nome"
                  onClick={handleChangeSort}
                >
                  Nome
                </CustomTableSortLabel>
              </TableCell>
              <TableCell>
                <CustomTableSortLabel
                  currentSort={request.sort}
                  sortField="nome"
                  onClick={handleChangeSort}
                >
                  Descrição
                </CustomTableSortLabel>
              </TableCell>
              {/* <TableCell>
                <CustomTableSortLabel
                  currentSort={request.sort}
                  sortField="departamento.empresa.fantasia"
                  onClick={handleChangeSort}
                >
                  Departamento(s)
                </CustomTableSortLabel>
              </TableCell> */}
              {/* <TableCell align="center">Indexadores</TableCell> */}
              {userActions.actions &&
                userActions.actions.map(
                  (userAct, index) =>
                    userAct.action === 'editar' && (
                      <TableCell align="center" key={index}>
                        Ações
                      </TableCell>
                    )
                )}
            </TableRow>
          </TableHead>
          <TableBody>
            {!!loading && <LoadingTable columns={5} />}
            {!loading &&
              onboardings &&
              onboardings.map((item) => (
                <StyledTableRow key={item._id}>
                  <TableCell>{item.nome}</TableCell>
                  <TableCell>{item.descricao}</TableCell>
                  {/* <TableCell>{item.departamentos}</TableCell> */}
                  <TableCell align="center">
                    <div className="flex-direction">
                      {/* <ToolTipComponent title="Indexadores" placement="top">
                        <Link
                          to={{
                            pathname:
                              '/configuracao/onboardingsumento/indexadores',
                            state: {
                              tipodoc: item,
                            },
                          }}
                        >
                          <ButtonSquare icon="playlist_add" />
                        </Link>
                      </ToolTipComponent> */}

                      {/* {userActions.actions &&
                        userActions.actions.map(
                          (userAct, index) =>
                            userAct.action === 'editar' && (
                              
                            )
                        )} */}

                      <ToolTipComponent
                        title="Link para compartilhar"
                        placement="top"
                      >
                        <div className="linkicon">
                          <ButtonSquare
                            icon="share"
                            onClick={(e) => copyLinkToClipboard(`${item._id}`)}
                          />
                        </div>
                      </ToolTipComponent>

                      <ToolTipComponent title="Editar" placement="top">
                        <Link
                          to={{
                            pathname: '/configuracao/onboarding/editar',
                            state: {
                              onboarding: item,
                            },
                          }}
                          className="linkicon"
                          id="onboardings"
                        >
                          <ButtonSquare icon="edit" />
                        </Link>
                      </ToolTipComponent>

                      <ToolTipComponent title="Remover" placement="top">
                        <div>
                          <ButtonSquare
                            onClick={() => handleDelete(item)}
                            icon="delete"
                          />
                        </div>
                      </ToolTipComponent>
                    </div>
                  </TableCell>
                </StyledTableRow>
              ))}
          </TableBody>
          {!loading && (
            <TableFooter>
              <TableRow>
                <TablePagination
                  count={total}
                  page={request.page - 1}
                  rowsPerPage={request.limit}
                  rowsPerPageOptions={[10, 25, 50, 100]}
                  labelRowsPerPage="Linhas por página"
                  labelDisplayedRows={({ from, to, count }) =>
                    `${from}-${to} de ${
                      count !== -1 ? count : `mais que ${to}`
                    }`
                  }
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  onPageChange={handleChangePage}
                />
              </TableRow>
            </TableFooter>
          )}
        </Table>
      ) : (
        <div className="table-tipo-documento-content">
          <span className="list__none">Não há formulários cadastrados.</span>
        </div>
      )}
    </TableContainer>
  );
}
