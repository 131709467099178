import React, { useEffect, useState } from 'react';

import { registerLocale } from 'react-datepicker';

import br from 'date-fns/locale/pt-BR';

import 'react-datepicker/dist/react-datepicker.css';

import Grid from '@material-ui/core/Grid';
import ButtonMui from '@material-ui/core/Button';
import BackButton from '../../components/BackButton';

import { Form, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import useForm from 'react-hook-form';

import './style.css';

import { Creators as ConfiguracaoActions } from '../../store/ducks/configuracoes';

import { useDispatch, useSelector } from 'react-redux';
import Content from 'components/Content';

export default function Configuracoes() {
  registerLocale('pt-br', br);
  const dispatch = useDispatch();

  const usuario = useSelector(state => state.usuarios.auth);

  const configuracoes = useSelector(state => state.configuracoes.configuracoes);

  const [validadeSenha, setValidadeSenha] = useState(new Date());

  useEffect(() => {
    dispatch(ConfiguracaoActions.getRequest());
  }, [dispatch]);

  const { register, handleSubmit } = useForm();

  const toBase64 = file =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });

  const onSubmit = async values => {
    console.log('configurações: ', values);

    if (values.logo) {
      const logoBase64 = await toBase64(values.logo[0]);
      values.logo = logoBase64;
    }

    dispatch(ConfiguracaoActions.editRequest(values));
  };

  return (
    <Content>
      <Row>
        <Col md="12" className="painel">
          <div className="painel-content">
            <div className="painel-title text-left">Configurações do sistema</div>
            <div className="painel-body">
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Row className="row">
                  <Col>
                    <Form.Label>Logo</Form.Label>
                    <Form.Control type="file" name="logo" ref={register} />
                  </Col>
                </Row>
                <Row className="row">
                  <Col>
                    <Form.Label>Quantidade de tentativas para bloqueio</Form.Label>
                    <Form.Control
                      type="number"
                      name="blockAfterAttempts"
                      ref={register}
                      defaultValue={configuracoes.blockAfterAttempts}
                    />
                  </Col>
                </Row>
                <Row className="row">
                  <Col>
                    <Form.Label>Minutos de espera até a próxima tentativa</Form.Label>
                    <Form.Control
                      type="number"
                      name="minutesToEnableLogin"
                      ref={register}
                      defaultValue={configuracoes.minutesToEnableLogin}
                    />
                  </Col>
                </Row>
                <Row className="row">
                  <Col>
                    <Form.Label>Quantidade de tentativas para inativar por determinado tempo o login</Form.Label>
                    <Form.Control
                      type="number"
                      name="attemptsToWaitLogin"
                      ref={register}
                      defaultValue={configuracoes.attemptsToWaitLogin}
                    />
                  </Col>
                </Row>
                <Row className="row">
                  <Col>
                    <Form.Label>Tempo Máximo de Sessão</Form.Label>
                    <Form.Control
                      type="number"
                      name="timeMaxSessionUser"
                      ref={register}
                      defaultValue={configuracoes.timeMaxSessionUser}
                    />
                  </Col>
                </Row>
                <Row className="row">
                  <Col>
                    <Form.Label>Força da senha</Form.Label>

                    <OverlayTrigger
                      overlay={<Tooltip id="tooltip-disabled">Precisa conter ao menos uma letra.</Tooltip>}
                    >
                      <span className="d-inline-block">
                        <Form.Check
                          inline
                          label="Muito Fraca"
                          type="radio"
                          ref={register}
                          defaultValue="^(?=.*[a-z])"
                          name="strengthPassword"
                          defaultChecked={configuracoes.strengthPassword === '^(?=.*[a-z])'}
                        />
                      </span>
                    </OverlayTrigger>

                    <OverlayTrigger
                      overlay={
                        <Tooltip id="tooltip-disabled">
                          Precisa conter ao menos uma letra e uma letra maiúscula.
                        </Tooltip>
                      }
                    >
                      <span className="d-inline-block">
                        <Form.Check
                          inline
                          label="Fraca"
                          type="radio"
                          ref={register}
                          defaultValue="^(?=.*[a-z])(?=.*[A-Z])"
                          name="strengthPassword"
                          defaultChecked={configuracoes.strengthPassword === '^(?=.*[a-z])(?=.*[A-Z])'}
                        />
                      </span>
                    </OverlayTrigger>

                    <OverlayTrigger
                      overlay={
                        <Tooltip id="tooltip-disabled">
                          Precisa conter ao menos uma letra, uma letra Maiúscula e um número
                        </Tooltip>
                      }
                    >
                      <span className="d-inline-block">
                        <Form.Check
                          inline
                          label="Média"
                          type="radio"
                          ref={register}
                          defaultValue="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])"
                          name="strengthPassword"
                          defaultChecked={configuracoes.strengthPassword === '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])'}
                        />
                      </span>
                    </OverlayTrigger>

                    <OverlayTrigger
                      overlay={
                        <Tooltip id="tooltip-disabled">
                          Precisa conter ao menos uma letra, uma letra Maiúscula, um número e um caracter especial
                        </Tooltip>
                      }
                    >
                      <span className="d-inline-block">
                        <Form.Check
                          inline
                          label="Forte"
                          type="radio"
                          ref={register}
                          defaultValue="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])"
                          name="strengthPassword"
                          alt=""
                          defaultChecked={
                            configuracoes.strengthPassword === `^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])`
                          }
                        />
                      </span>
                    </OverlayTrigger>

                    <OverlayTrigger
                      overlay={
                        <Tooltip id="tooltip-disabled">
                          Precisa conter ao menos uma letra, uma letra Maiúscula, um número, um caracter especial e
                          mínimo de 8 caracteres
                        </Tooltip>
                      }
                    >
                      <span className="d-inline-block">
                        <Form.Check
                          inline
                          label="Muito forte"
                          type="radio"
                          ref={register}
                          defaultValue="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})"
                          name="strengthPassword"
                          defaultChecked={
                            configuracoes.strengthPassword ===
                            `^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})`
                          }
                        />
                      </span>
                    </OverlayTrigger>
                  </Col>
                </Row>
                {/* <Row>
                      <Col>
                        <Button type="submit">Salvar</Button>
                      </Col>
                    </Row> */}

                <Grid container spacing={1}>
                  <Grid item>
                    <ButtonMui variant="contained" color="primary" type="submit">
                      Enviar
                    </ButtonMui>
                  </Grid>
                  <Grid item>
                    <ButtonMui variant="contained" color="secondary" type="reset">
                      Limpar
                    </ButtonMui>
                  </Grid>
                  <Grid item>
                    <BackButton variant="contained" color="secondary">
                      Voltar
                    </BackButton>
                  </Grid>
                </Grid>
              </Form>
            </div>
          </div>
        </Col>
      </Row>
    </Content>
  );
}
