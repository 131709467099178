import React, { useState, useEffect, useRef } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useSelector, useDispatch } from "react-redux";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";

import { Creators as ComentariosActions } from "store/ducks/comentarios";
import { Creators as DepartamentosActions } from "store/ducks/departamentos";
import { Creators as UsuariosActions } from "store/ducks/usuarios";

import useStyles from "./styles";
import { TextEditorWithTemplate } from "components/TextEditorWithTemplate";

const INITIAL_FORM = {
  texto: "",
  tipoImagem: "",
  file: "",
  size: 0,
  type: "",
  tipo: "Comentário",
  documento: "",
  protocolo: "",
  processInstanceId: "",
  enviarEmail: false,
  participantes: [],
  departamento: { _id: "", nome: "" },
};

function FormNovoComentario({
  analise,
  setIsCommentBlank,
  fechaAoSalvar = null,
  children,
}) {
  const { list: tipoImagens } = useSelector((state) => state.tipoImagem);
  const username = useSelector((state) => state.usuarios.auth.name);
  const { loading, list: listComentarios } = useSelector(
    (state) => state.comentarios
  );
  const { list: listGrupoAcessos } = useSelector((state) => state.grupoacesso);
  const { list: listDepartamentos } = useSelector(
    (state) => state.departamentos
  );
  const {
    access: { empresas },
  } = useSelector((state) => state.permissoes);
  const ref = useRef()

  const { loading: loadingMensagem } = useSelector((state) => state.mensagens);

  const [form, setForm] = useState(INITIAL_FORM);
  const [fileName, setFileName] = useState("");
  const [tipoImagem, setTipoImagem] = useState(null);
  const { byEmpOrg: listUsuarios } = useSelector((state) => state.usuarios);

  const classes = useStyles();

  const dispatch = useDispatch();

  useEffect(() => {
    setForm(INITIAL_FORM);
    setFileName("");
    setTipoImagem(null);
    dispatch(
      DepartamentosActions.listRequest({
        page: 1,
        sort: "nome",
        limit: Number.MAX_SAFE_INTEGER,
        all: true,
      })
    );

    // UsuariosActions.listRequest({
    //   empresas,
    //   page: 1,
    //   limit: Number.MAX_SAFE_INTEGER,
    // });
  }, [loading]);

  useEffect(() => {
    if (!loadingMensagem)
      dispatch(ComentariosActions.setListComments(listComentarios || []));
  }, [loadingMensagem]);

  function handleChange(params) {
    if (Array.isArray(params)) {
      const arrayUser = params.map(function (user) {
        return user;
      });
      setForm({
        ...form,
        participantes: arrayUser,
        documento: analise?._id,
        processInstanceId: analise?.processInstanceId,
        protocolo: analise?.numeroCNJ,
      });
    } else {
      const { target } = params;
      const { checked, value, name } = target;
      if (target?.type === "checkbox") {
        setForm({
          ...form,
          [name]: checked,
          documento: analise?._id,
          processInstanceId: analise?.processInstanceId,
          protocolo: analise?.numeroCNJ,
        });
      } else {
        name === "texto" && setIsCommentBlank(!value);

        setForm({
          ...form,
          [name]: value,
          documento: analise?._id,
          processInstanceId: analise?.processInstanceId,
          protocolo: analise?.numeroCNJ,
        });
      }
    }
  }

  function handleUpload({ target }) {
    const [file] = target.files;
    setFileName(file.name);

    const reader = new FileReader();
    reader.readAsBinaryString(file);

    reader.onload = async () => {
      const type = file.type.split("/").at(-1);
      const fileBase64 = `data:${file.type};base64,${btoa(reader.result)}`;
      const size = reader.result.length;
      setForm({
        ...form,
        file: fileBase64,
        size,
        type,
      });
    };
  }

  function handleChangeTipoImagem(e, newValue) {
    if (newValue) {
      setForm({
        ...form,
        tipoImagem: newValue._id,
      });
    }
    setTipoImagem(newValue);
  }

  function loadUsersFromDepartamento(selectedGroup) {
    if (selectedGroup) {

      dispatch(UsuariosActions.listRequestByDepto(selectedGroup._id));

      setForm({ ...form, departamento: selectedGroup });
    } else {
      dispatch(
        UsuariosActions.listRequest({
          empresas,
          page: 1,
          limit: Number.MAX_SAFE_INTEGER,
        })
      );

      setForm({
        ...form,
        participantes: [],
        departamento: { _id: "", nome: "" },
      });
    }
  }

  useEffect(() => {
    if (listUsuarios && form.departamento._id !== "") {
      setForm({
        ...form,
        participantes: listUsuarios,
      });
    }
  }, [listUsuarios]);

  function canSubmit() {
    return form.texto !== "" || form.file !== "";
  }

  function handleSubmit() {
    dispatch(
      ComentariosActions.createRequest(analise.processInstanceId, {
        ...form,
        data: Date.now(),
        autor: username,
        idEmpresa: analise.idEmpresa,
        idDocumento: analise._id,
      })
    );
    clearForm();
    if (fechaAoSalvar) fechaAoSalvar();
  }

  const clearForm = () => {
    ref.current?.clearForm()
    setForm({
      ...form,
      enviarEmail: false,
    });
  };
  return (
    <div>
      <TextEditorWithTemplate
        documento={analise}
        onSelectTemplate={
          (texto) => {
            handleChange({
              target: {
                checked: false,
                value: texto,
                name: "texto",
              },
            });
          }
        }
        ref={ref}
        onTextChange={
          (texto) =>
            handleChange({
              target: {
                checked: false,
                value: texto,
                name: "texto",
              },
            })
        }
      />
      <FormControlLabel
        control={
          <Checkbox
            tabIndex={-1}
            disableRipple
            checked={form.enviarEmail}
            name="enviarEmail"
            onChange={handleChange}
            size="small"
            color="primary"
            disabled={false}
          />
        }
        label="Enviar comentário via e-mail e notificação"
      />
      <Autocomplete
        options={listDepartamentos}
        disabled={!form.enviarEmail}
        onChange={(event, newValue) => loadUsersFromDepartamento(newValue)}
        getOptionLabel={(option) => option.nome}
        getOptionSelected={(option, value) => option._id === value._id}
        value={form.departamento}
        name="departamento"
        renderInput={(params) => (
          <TextField
            {...params}
            label="Departamento"
            size="small"
            variant="outlined"
            className="mb-4"
          />
        )}
      />
      <Autocomplete
        multiple
        options={listUsuarios}
        disabled={!form.enviarEmail}
        onChange={(event, newValue) => handleChange(newValue)}
        getOptionLabel={(option) => option.nome}
        getOptionSelected={(option, value) => option._id === value._id}
        value={form.participantes}
        name="participantes"
        renderInput={(params) => (
          <TextField
            {...params}
            label="Destinatários"
            size="small"
            variant="outlined"
            className="mb-4"
          />
        )}
      />

      <div className={classes.actions}>
        <Button
          color="primary"
          variant="contained"
          className={classes.newCommentButton}
          disabled={!canSubmit() || !!loading}
          onClick={handleSubmit}
        >
          Salvar
        </Button>
        <Grid container spacing={1}>
          <Grid item xs={7} className={classes.anexo}>
            <div>
              <input
                accept="application/pdf"
                style={{ display: "none" }}
                id="raised-button-file"
                multiple
                type="file"
                disabled={!!loading}
                onChange={handleUpload}
              />
              <label htmlFor="raised-button-file">
                <Button
                  variant="contained"
                  color="secondary"
                  component="span"
                  disabled={!canSubmit() || !!loading}
                >
                  Anexo
                </Button>
                <p className={classes.fileName}>{fileName}</p>
              </label>
            </div>
          </Grid>

          <Grid item xs={5}>
            {fileName !== "" && (
              <Autocomplete
                id="combo-box-tipo-imagens"
                options={tipoImagens}
                getOptionLabel={(option) => option.nome}
                getOptionSelected={(option, value) => option._id === value._id}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Tipo de Imagem"
                    variant="outlined"
                  />
                )}
                onChange={handleChangeTipoImagem}
                size="small"
                disabled={!!loading}
                value={tipoImagem}
              />
            )}
          </Grid>
        </Grid>
      </div>

      {children && (
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            marginRight: "8px",
          }}
        >
          {children}
        </div>
      )}
    </div>
  );
}

export default FormNovoComentario;
