import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Row, Col } from 'react-bootstrap';

import Checkbox from '@material-ui/core/Checkbox';

import TablePagination from '@material-ui/core/TablePagination';
import { LabelSwapper } from '../../../components/LabelSwapper';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import history from '../../../routes/history';

import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TableFooter,
} from '@material-ui/core';

import { Creators as CargaItensActions } from 'store/ducks/cargaitens';

import StyledTableRow from '../../../components/StyledTableRow';

import LoadingTable from 'components/LoadingTable';

import { useDispatch, useSelector } from 'react-redux';
import Content from 'components/Content';

const COLORS = {
  ERROR: '#dd3d3d',
  SUCESS: '#21971e',
  INFO: '#e4a94f',
};

const useStyles = makeStyles((theme) => ({
  input: {
    backgroundColor: '#eee',
    border: 'none',
    padding: '10px 30px 10px 20px',
    minWidth: '450px',
    margin: '0.3rem',
  },
  box: {
    display: 'flex',
    alignContent: 'flex-start',
    verticalAlign: 'middle',
    alignItems: 'center',
    fontSize: '14px',
    backgroundColor: '#ffffff',
  },
  pointer: {
    cursor: 'pointer',
  },
}));

export default function FormCarga(params) {
  const classes = useStyles();

  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState('');
  const [selecionadas, setSelecionadas] = useState([]);

  const placeholderCustom = LabelSwapper({
    text: 'Digite o número do cliente',
    parent: 'FormCarga',
    name: 'placeholder',
  });

  var { loading, cargaitens } = useSelector((state) => state.cargaitens);
  const { carga } = params.location.state;

  const { totalDocs: total, page, limit, sort, search } = cargaitens;

  useEffect(() => {
    dispatch(
      CargaItensActions.listRequest({
        page: 1,
        limit: 10,
        sort: '_id',
        id: carga._id,
      })
    );
  }, [dispatch]);

  function handleChangeRowsPerPage({ target }) {
    setSelecionadas([]);
    const { value } = target;
    dispatch(
      CargaItensActions.listRequest({
        page,
        limit: value,
        sort,
        search,
        id: carga._id,
      })
    );
  }

  function handleChangePage(e, newPage) {
    setSelecionadas([]);
    dispatch(
      CargaItensActions.listRequest({
        page: newPage + 1,
        limit,
        sort,
        search,
        id: carga._id,
      })
    );
  }

  function handleSearch(sucess = null, numeroCNJ = null, type = null) {
    setSelecionadas([]);
    dispatch(
      CargaItensActions.listRequest({
        page: 1,
        limit: 10,
        sort: '_id',
        id: carga._id,
        sucess: sucess,
        numeroCNJ: numeroCNJ === '' ? null : numeroCNJ,
        type: type === '' ? null : type,
      })
    );
  }

  function handleSubmit() {
    dispatch(
      CargaItensActions.markAsRemovedRequest({
        ids: selecionadas,
      })
    );
  }

  function handleCheckAll({ target }) {
    const { checked } = target;

    if (checked) {
      const allSelect = cargaitens?.docs?.map((item) => {
        if (item.tipo === 'ALTERAR' || item.exclusao) return null;
        return item._id;
      });

      setSelecionadas(allSelect);
    } else setSelecionadas([]);
  }

  function handleCheck({ target }, id) {
    const { checked } = target;

    if (checked) setSelecionadas([...selecionadas, id]);
    else setSelecionadas([...selecionadas.filter((sel) => sel !== id)]);
  }

  function handleBackButton() {
    history.goBack();
  }

  return (
    <Content>
      <Row>
        <Col md="12" className="painel">
          <div>
            <Row
              style={{
                display: 'flex',
                alignContent: 'center',
                textAlign: 'center',
              }}
            >
              <Col className={classes.pointer} md="3" sm="12">
                <Card className="painel" onClick={() => handleSearch()}>
                  <CardContent>
                    <Typography variant="h4" component="h2">
                      {carga.count}
                    </Typography>
                    <h6 style={{ fontWeight: 'bold' }}>Total</h6>
                  </CardContent>
                </Card>
              </Col>
              <Col className={classes.pointer} md="3" sm="12">
                <Card
                  className="painel"
                  onClick={() => handleSearch(null, null, 'INCLUIR')}
                  style={{ color: COLORS.SUCESS }}
                >
                  <CardContent>
                    <Typography variant="h4" component="h2">
                      {carga.inserts}
                    </Typography>
                    <h6 style={{ fontWeight: 'bold' }}>Criados</h6>
                  </CardContent>
                </Card>
              </Col>
              <Col className={classes.pointer} md="3" sm="12">
                <Card
                  className="painel"
                  onClick={() => handleSearch(null, null, 'ALTERAR')}
                  style={{ color: COLORS.INFO }}
                >
                  <CardContent>
                    <Typography variant="h4" component="h2">
                      {carga.updats}
                    </Typography>
                    <h6 style={{ fontWeight: 'bold' }}>Alterados</h6>
                  </CardContent>
                </Card>
              </Col>
              <Col className={classes.pointer} md="3" sm="12">
                <Card
                  className="painel"
                  onClick={() => handleSearch(false)}
                  style={{ color: COLORS.ERROR }}
                >
                  <CardContent>
                    <Typography variant="h4" component="h2">
                      {carga.count - carga.sucess}
                    </Typography>
                    <h6 style={{ fontWeight: 'bold' }}>Erros</h6>
                  </CardContent>
                </Card>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <Divider />
      <Row className="mt-2 d-none d-md-block">
        <Col md="12" className="painel">
          <div className="painel-content">
            <div className="painel-title text-left">Filtros</div>
            <div className="painel-body">
              <Col md="12">
                <input
                  type="text"
                  className={classes.input}
                  value={searchText}
                  size="small"
                  onChange={(e) => setSearchText(e.target.value)}
                  placeholder={placeholderCustom}
                />
                <Button
                  size="small"
                  color="primary"
                  variant="contained"
                  onClick={() => handleSearch(null, searchText)}
                  className={'ml-2'}
                >
                  Pesquisar
                </Button>
              </Col>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col md="12" className="painel">
          <div className="painel-content">
            <div className="painel-title text-left">Detalhamento</div>
            <div className="painel-body">
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Checkbox
                          defaultChecked={false}
                          onChange={(e) => handleCheckAll(e)}
                        />
                      </TableCell>
                      <TableCell>Documento</TableCell>
                      <TableCell>Sucesso</TableCell>
                      <TableCell>Erro</TableCell>
                      <TableCell>Tipo</TableCell>
                      <TableCell>Excluído</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!!loading && <LoadingTable rows={10} columns={7} />}
                    {!loading &&
                      cargaitens?.docs?.map((item) => (
                        <StyledTableRow key={item._id}>
                          <TableCell>
                            <Checkbox
                              disabled={
                                item.tipo === 'ALTERAR' || item.exclusao
                              }
                              onChange={(e) => handleCheck(e, item._id)}
                              checked={selecionadas.some(
                                (_id) => _id === item._id
                              )}
                            />
                          </TableCell>
                          <TableCell>
                            {item.documento?.numeroCNJ || '-'}
                          </TableCell>
                          <TableCell>{item.sucesso ? 'Sim' : 'Não'}</TableCell>
                          <TableCell>{item.erro || '-'}</TableCell>
                          <TableCell>
                            {item.tipo === 'ALTERAR' ? 'Alteração' : 'Inclusão'}
                          </TableCell>
                          <TableCell>{item.exclusao ? 'Sim' : 'Não'}</TableCell>
                        </StyledTableRow>
                      ))}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <td
                        colSpan={7}
                        style={{ textAlign: 'center', padding: '0.6rem' }}
                      >
                        <Button
                          disabled={true}
                          size="small"
                          className="mr-2"
                          variant="contained"
                          color="secondary"
                          onClick={handleSubmit}
                        >
                          Excluir
                        </Button>
                        <Button
                          size="small"
                          color="primary"
                          variant="contained"
                          onClick={handleBackButton}
                        >
                          Voltar
                        </Button>
                      </td>
                    </TableRow>
                  </TableFooter>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        count={total || 0}
                        page={page - 1}
                        rowsPerPage={limit || 10}
                        rowsPerPageOptions={[10, 25, 50, 100]}
                        labelRowsPerPage="Linhas por página"
                        labelDisplayedRows={({ from, to, count }) =>
                          `${from}-${to} de ${
                            count !== -1 ? count : `mais que ${to}`
                          }`
                        }
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        onPageChange={handleChangePage}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </div>
          </div>
        </Col>
      </Row>
    </Content>
  );
}
