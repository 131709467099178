export const Types = {
  LIST_REQUEST: "departamentos/LIST_REQUEST",
  LIST_SUCCESS: "departamentos/LIST_SUCCESS",
  LIST_BYEMPRESA_REQUEST: "departamentos/LIST_BYEMPRESA_REQUEST",
  LIST_BYEMPRESA_SUCCESS: "departamentos/LIST_BYEMPRESA_SUCCESS",
  NEW_REQUEST: "departamentos/NEW_REQUEST",
  NEW_SUCCESS: "departamentos/NEW_SUCCESS",
  REMOVE_REQUEST: "departamentos/REMOVE_REQUEST",
  REMOVE_SUCCESS: "departamentos/REMOVE_SUCCESS",
  GET_REQUEST: "departamentos/GET_REQUEST",
  GET_SUCCESS: "departamentos/GET_SUCCESS",
  EDIT_REQUEST: "departamentos/EDIT_REQUEST",
  EDIT_SUCCESS: "departamentos/EDIT_SUCCESS",
};

/* Reducer */

const INITIAL_STATE = {
  loading: false,
  list: [],
  listByEmpresa: [],
  departamento: [],
  gruposDeAcesso: [],
  sort: "nome",
  page: 1,
  limit: 10,
  total: 0,
};

export default function items(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.LIST_REQUEST:
      return { ...state, loading: true };

    case Types.LIST_SUCCESS:
      return { ...state, ...action.payload, loading: false };

    case Types.LIST_BYEMPRESA_REQUEST:
      return { ...state, loading: true };

    case Types.LIST_BYEMPRESA_SUCCESS:
      return { ...state, loading: false, listByEmpresa: action.payload };

    case Types.NEW_REQUEST:
      return { ...state, loading: true };

    case Types.NEW_SUCCESS:
      return { ...state, loading: false };

    case Types.REMOVE_REQUEST:
      return { ...state, loading: true };

    case Types.REMOVE_SUCCESS:
      return { ...state, loading: false };

    case Types.GET_REQUEST:
      return { ...state, loading: true };

    case Types.GET_SUCCESS:
      return { ...state, loading: false, departamento: action.payload };

    case Types.EDIT_REQUEST:
      return { ...state, loading: true };

    case Types.EDIT_SUCCESS:
      return { ...state, loading: false };

    default:
      return state;
  }
}

export const Creators = {
  listRequest: (data) => ({
    type: Types.LIST_REQUEST,
    payload: { ...data },
  }),

  listSuccess: (data) => ({
    type: Types.LIST_SUCCESS,
    payload: data,
  }),

  listByEmpresaRequest: (empresa) => ({
    type: Types.LIST_BYEMPRESA_REQUEST,
    payload: empresa,
  }),

  listByEmpresaSuccess: (departamentos) => ({
    type: Types.LIST_BYEMPRESA_SUCCESS,
    payload: departamentos,
  }),

  removeRequest: (id) => ({
    type: Types.REMOVE_REQUEST,
    payload: id,
  }),

  removeSuccess: () => ({
    type: Types.REMOVE_SUCCESS,
    payload: {},
  }),

  getRequest: (id) => ({
    type: Types.GET_REQUEST,
    payload: id,
  }),

  getSuccess: (departamento) => ({
    type: Types.GET_SUCCESS,
    payload: departamento,
  }),

  editRequest: (departamento) => ({
    type: Types.EDIT_REQUEST,
    payload: departamento,
  }),

  editSuccess: () => ({
    type: Types.EDIT_SUCCESS,
    payload: {},
  }),

  createRequest: (departamento) => ({
    type: Types.NEW_REQUEST,
    payload: departamento,
  }),

  createSuccess: () => ({
    type: Types.NEW_SUCCESS,
    payload: {},
  }),
};
