import { all, call, put, select, takeLatest } from "redux-saga/effects";

import { toastr } from "react-redux-toastr";

import api from "../../services/api";
import { getToken } from "../../services/auth";
import { Creators as MailActions, Types } from "../ducks/mail";
import { Creators } from "store/ducks/dashboard";

export const getOrganization = (state) => state.usuarios.auth.organization;
export const getUser = (state) => state.usuarios;

export function* createMail(action) {
  const organization = yield select(getOrganization);
  const user = yield select(getUser);

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    let body = {
      ...action.payload,
      usuario: user?.auth?.id,
    };

    const { data } = yield call(api.post, `mail`, body, headerParams);

    yield put(MailActions.createSuccess(data));
    yield put(MailActions.listRequest(body.documento));

    toastr.success("", "E-mail agendado com sucesso.");
  } catch (error) {
    toastr.error("Ops", "Não foi possível agendar envio de e-mail.");
  }
}

export function* listMail({ payload: documento }) {

  try {

    const organization = yield select(getOrganization);

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
      params: { documento }
    };

    const { data } = yield call(api.get, `mail`, headerParams);
    const { emailGroups: emails, agregacoes } = data;

    yield put(MailActions.listSuccess({ emails, agregacoes }));

  } catch (error) {
    console.error(error);
    toastr.error("Ops", "Não foi possível listar e-mails.");
  }
}

export function* listUnreadedMail(action) {

  try {

    const organization = yield select(getOrganization);

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
      params: action.payload
    };

    const { data } = yield call(api.get, `mail/unreaded`, headerParams);

    yield put(MailActions.listUnreadedSuccess({ emailsNaoLidos: data }));

  } catch (error) {
    console.error(error);
    toastr.error("Ops", "Não foi possível listar e-mails.");
  }
}

export function* readMail({ payload: idEmail }) {

  try {

    const organization = yield select(getOrganization);

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const { data } = yield call(api.patch, `mail/read/${idEmail}`, {}, headerParams);

    yield put(MailActions.readMailSuccess({ email: data }));

  } catch (error) {
    console.error(error);
    toastr.error("Ops", "Não foi possível ler o e-mail.");
  }
}

export function* forceScalling({ payload: idDocumento }) {

  try {

    const organization = yield select(getOrganization);

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const { data } = yield call(api.patch, `mail/force-scalling/${idDocumento}`, {}, headerParams);

    if (data.error) {
      toastr.error("", data.message);
      return
    }

    yield put(MailActions.forceScallingSuccess({}));
    toastr.success("", "Escalonamento do e-mail agendado com sucesso.");
  } catch (error) {
    console.error(error);
    toastr.error("Ops", "Não foi possivel forçar o escalonamento dos e-mails.");
  }
}

export function* exportEmail({ payload }) {
  try {

    const organization = yield select(getOrganization);
    const { option, id, idEmail, documento } = payload;

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
      params: { option, id, idEmail, documento },
    };

    const { data } = yield call(api.get, `mail/export`, headerParams);

    if (data.error) {
      toastr.error("", data.message);
      return
    }

    yield put(MailActions.exportEmailSuccess({ data }));

    toastr.success("", "Exportação de e-mail agendada com sucesso.");
  } catch (error) {
    console.error(error);
    toastr.error("Ops", "Não foi possivel fazer a exportação de e-mails.");
  }
}

export function* listMaxScallingMailsWithoutAnswerFromDocument({ payload }) {
  try {

    const organization = yield select(getOrganization);
    const { documento } = payload;

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
      params: { documento },
    };

    const { data } = yield call(api.get, `mail/max-scaling`, headerParams);

    if (data.error) {
      toastr.error("", data.message);
      return
    }

    yield put(MailActions.listMaxScallingMailsWithoutAnswerFromDocumentSuccess(data))

  } catch (error) {
    console.error(error);
    toastr.error("Ops", "Não foi possivel listar os e-mails.");
  } finally {

    yield put(MailActions.setLoadingMailMaxScalling(false))
  }
}

export function* removeMailFromMaxScallingList({ payload }) {
  try {
    const organization = yield select(getOrganization);
    const { idEmail, idDocumento } = payload;

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
      params: { idEmail },
    };

    const { data } = yield call(api.get, `mail/mark-as-remove-from-listing`, headerParams);

    if (data.error) {
      toastr.error("", data.message);
      return
    }

    yield put(MailActions.listMaxScallingMailsWithoutAnswerFromDocumentRequest({
      documento: idDocumento
    }))
    yield put(Creators.documentsMaxScalllingWithoutAnswerRequest({
      documento: idDocumento
    }))
  } catch (error) {

  } finally {
    yield put(MailActions.setLoadingMailMaxScalling(false))
  }
}

export function* resendingEmailWithError({ payload: id }) {

  try {

    const organization = yield select(getOrganization);

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const { data } = yield call(api.patch, `mail/resend/${id}`, {}, headerParams);

    if (data.error) {
      toastr.error("", data.message);
      return
    }

    yield put(MailActions.resendingEmailSuccess());
    toastr.success("", "Verifique se o status do e-mail mudará daqui a alguns segundos.");
  } catch (error) {
    console.error(error);
    toastr.error("Ops", "Não foi possivel efetuar a retentativa de envio de e-mail.");
  }
}

export default all([
  takeLatest(Types.LIST_REQUEST, listMail),
  takeLatest(Types.UNREADED_LIST_REQUEST, listUnreadedMail),
  takeLatest(Types.READ_MAIL_REQUEST, readMail),
  takeLatest(Types.FORCE_SCALLING_REQUEST, forceScalling),
  takeLatest(Types.EXPORT_MAIL_REQUEST, exportEmail),
  takeLatest(Types.RESEND_REQUEST, resendingEmailWithError),
]);
