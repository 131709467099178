import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { Form } from "@unform/web";

import Grid from "@material-ui/core/Grid";
import ButtonMui from "@material-ui/core/Button";
import { FormControlLabel, Switch } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

import BackButton from "components/BackButton";
import InputForm from "components/InputForm";
import AcaoSchema from "validators/AcaoSchema";
import { ValidationError } from "yup";

import { Creators as AcaoActions } from "../../../store/ducks/acao";
import Content from "components/Content";

export default function EditAcao(params) {
  const dispatch = useDispatch();
  const formRef = useRef(null);

  const { acao } = params.location.state;

  const { _id, ...initialData } = acao;
  const [status, setStatus] = useState(acao ? acao.status : false);

  const { loading } = useSelector((state) => state.acao);

  const handleCheckStatus = () => {
    setStatus(!status);
  };

  const onSubmitEdit = async (values) => {
    const body = { ...values, _id, status };

    try {
      await AcaoSchema.validate(body, {
        abortEarly: false,
      });

      dispatch(AcaoActions.editRequest(body));
    } catch (err) {
      const validationErrors = {};
      if (err instanceof ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });

        formRef.current.setErrors(validationErrors);
      }
    }
  };

  return (
    <Content>
      <Row>
        <Col md="12" className="painel">
          <div className="painel-content">
            <div className="painel-title text-left">Editar Ação</div>
            <div className="painel-body">
              <Form
                ref={formRef}
                initialData={initialData}
                onSubmit={onSubmitEdit}
              >
                <Row className="row">
                  <Col md={6}>
                    <InputForm name="nome" label="Nome da ação" />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormControlLabel
                      control={
                        <Switch
                          name="status"
                          onChange={handleCheckStatus}
                          color="primary"
                          defaultChecked={acao ? acao.status : false}
                        />
                      }
                      label="Ativo"
                    />
                  </Col>
                </Row>
                <Grid container spacing={1}>
                  <Grid item>
                    <ButtonMui
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={loading}
                    >
                      Enviar
                      {!!loading && <CircularProgress size={20} />}
                    </ButtonMui>
                  </Grid>
                  <Grid item>
                    <BackButton variant="contained" color="secondary">
                      Voltar
                    </BackButton>
                  </Grid>
                </Grid>
              </Form>
            </div>
          </div>
        </Col>
      </Row>
    </Content>
  );
}
