import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
  header: {
    display: 'flex',
    flexDirection: 'row'
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    marginRight: '16px'
  },
  bold: {
    fontWeight: 'bold'
  }
}));