import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import { IconButton } from "@material-ui/core";
import { ClockIcon } from "components/Icons";

/**
 * @param {Object} props
 * @param {boolean}props.onTime
 * @param {string} props.measureOfTime
 * @param {any} props.duration
 * @param {string} [props.onTimeColor]
 * @returns {Element}
 * @constructor
 */
export default function Chronometer({ onTime, measureOfTime, duration, onTimeColor }) {
  return (
    <Tooltip title="Tempo em aberto" placement="top-start">
      <IconButton
        size="small"
        style={{
          padding: "0px",
          fontSize: "1rem",
          background: "transparent",
        }}
      >
        <ClockIcon
          style={{
            marginRight: 10,
          }}
          htmlColor={onTime ? (onTimeColor || "green") : "red"}
        />
        <span
          style={{
            color: onTime ? (onTimeColor || "green") : "red",
          }}
        >
          {duration}
          {measureOfTime || "h"}
        </span>
      </IconButton>
    </Tooltip>
  );
}
