import React from "react";
import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import AutocompleteForm from "components/AutocompleteForm";

export const MailFileOptions = ({
  canPrint,
  canSheel,
  onChange,
  isForm = false,
}) => {
  const createOptions = () => {
    const options = [];

    if (canPrint) {
      options.push({ id: 1, name: "PDF", value: "PDF" });
    }

    if (canSheel) {
      options.push({ id: 2, name: "XLSX/CSV", value: "XLSX/CSV" });
    }

    return options;
  };

  return (
    <>
      {isForm ? (
        <AutocompleteForm
          size="small"
          label="Selecione o tipo do arquivo"
          name="attachmentType"
          options={createOptions()}
          getOptionLabel={(option) => option.name}
          transformOnSelect={(option) => (option ? option.value : null)}
        />
      ) : (
        <Autocomplete
          required
          size="small"
          onChange={(event, newValue) => {
            onChange(newValue);
          }}
          options={createOptions()}
          getOptionLabel={(option) => option.name}
          noOptionsText="Selecione o tipo do arquivo"
          getOptionSelected={(option, value) => option.name === value.name}
          clearOnBlur={false}
          name="arquivo"
          renderInput={(params) => (
            <TextField
              {...params}
              name="modelType"
              label="Tipo do arquivo"
              variant="outlined"
            />
          )}
        />
      )}
    </>
  );
};
