import React, { useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Creators as WelcomeActions } from "store/ducks/welcome";

export default function Charts({ view }) {
  const dispatch = useDispatch();

  const { empresas } = useSelector(({ permissoes }) => permissoes.access);

  const siteName = window.location.origin.toString();

  const { tableau } = window;
  const ref = useRef(null);

  const { token, loadingToken } = useSelector((state) => state.welcome);
  const { organization } = useSelector((state) => state.usuarios.auth);

  let viz;

  const { innerWidth: width, innerHeight: height } = window;

  async function initViz() {
    const url = `https://analytics.newspace.io/trusted/${token}/t/newSpace/views/${
      organization === "newspace" && siteName.indexOf("newspaceh") > -1
        ? "newspacehomolog"
        : `${organization}${view}`
    }/Dashboard`;

    let options = {
      hideTabs: true,
      hideToolbar: true,
      height: height + 900,
      width: width - 160,
      ID_EMPRESA: empresas,
    };

    viz = new tableau.Viz(ref.current, url, options);
  }

  useEffect(() => {
    initViz();
  }, [token]);

  useEffect(() => {
    dispatch(WelcomeActions.biTokenRequest());
  }, [dispatch]);

  return <div>{!loadingToken && <div ref={ref}></div>}</div>;
}
