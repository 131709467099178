import React, { useEffect, useRef, useState } from 'react';
import './styles.css';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as TipoImagensActions } from 'store/ducks/tipoimagem';
import { Row, Col } from 'react-bootstrap';
import { Form } from '@unform/web';
import { Creators as TipoDocsActions } from 'store/ducks/tiposdocumento';
import { ValidationError } from 'yup';

import Grid from '@material-ui/core/Grid';
import ButtonMui from '@material-ui/core/Button';
import BackButton from 'components/BackButton';
import InputForm from 'components/InputForm';
import AutocompleteForm from 'components/AutocompleteForm';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import CheckboxForm from 'components/CheckboxForm';

import TipoImagemSchema from 'validators/TipoImagemSchema';
import Content from 'components/Content';

export default function FormTipoImagem({ data }) {
  const dispatch = useDispatch();

  const [status, setStatus] = useState(true);
  const [defaultTpImg, setDefaultTpImage] = useState(false);
  const [notUsedToClassify, setNotUsedToClassify] = useState(false);

  const tipoDocs = useSelector(state => state.tipoDocumentos.list);
  const usuarioId = useSelector(state => state.usuarios.auth.id);
  const {
    access: { empresas },
  } = useSelector(state => state.permissoes);

  const formRef = useRef(null);

  const handleCheckStatus = () => {
    setStatus(!status);
  };

  const handleCheckDefault = () => {
    setDefaultTpImage(!defaultTpImg);
  };

  useEffect(() => {
    dispatch(TipoDocsActions.listRequest({ empresas }));
  }, [dispatch, empresas]);

  const onSubmit = async values => {
    const tipoimagens = {
      ...values,
      userAcao: usuarioId,
      status,
      default: defaultTpImg,
      notUsedToClassify,
    };

    try {
      await TipoImagemSchema.validate(tipoimagens, {
        abortEarly: false,
      });

      dispatch(TipoImagensActions.createRequest(tipoimagens));
    } catch (err) {
      const validationErrors = {};
      if (err instanceof ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });

        formRef.current.setErrors(validationErrors);
      }
    }
  };

  return (
    <Content>
      <Row>
        <Col md="12" className="painel">
          <div className="painel-content">
            <div className="painel-title text-left">Novo tipo de imagem</div>
            <div className="painel-body">
              <Form ref={formRef} onSubmit={onSubmit}>
                <Row>
                  <Col md="12">
                    <AutocompleteForm
                      options={tipoDocs}
                      name="tipoDocumento"
                      label="Tipo do documento*"
                      getOptionLabel={option => option.nome}
                      transformOnSelect={option => option._id}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <InputForm label="Descrição*" name="nome" />
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <InputForm label="Descrição utilizada para classificar com a IA" name="descricao_ia" />
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <InputForm label="Descrição reduzida (até 18 caracteres)*" name="shortname" />
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <CheckboxForm name="processarOCR" label={'Processa OCR?'} />
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <CheckboxForm name="selfie" label={'Selfie?'} />
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <CheckboxForm
                      name="notUsedToClassify"
                      label={'Não utilizado para classificar'}
                      onChange={() => setNotUsedToClassify(!notUsedToClassify)}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormControlLabel
                      control={<Switch name="default" onChange={handleCheckDefault} color="primary" />}
                      label="Padrão"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormControlLabel
                      control={<Switch name="status" onChange={handleCheckStatus} color="primary" />}
                      label="Ativo"
                    />
                  </Col>
                </Row>

                <Grid container spacing={1}>
                  <Grid item>
                    <ButtonMui variant="contained" color="primary" type="submit">
                      Enviar
                    </ButtonMui>
                  </Grid>
                  <Grid item>
                    <ButtonMui variant="contained" color="secondary" type="reset">
                      Limpar
                    </ButtonMui>
                  </Grid>
                  <Grid item>
                    <BackButton variant="contained" color="secondary">
                      Voltar
                    </BackButton>
                  </Grid>
                </Grid>
              </Form>
            </div>
          </div>
        </Col>
      </Row>
    </Content>
  );
}
