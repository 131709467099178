import React, { useState, useRef, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toastr } from "react-redux-toastr";

import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "ckeditor5-build-full";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

import { Creators as DocumentosActions } from "store/ducks/documentos";
import { getPresignedUrl } from "services/presignedUrl";

import "./styles.css";
import { AttachFileIcon } from "components/Icons";

export default function ModalSendEmail({
  selectedDoc,
  showModal,
  handleStay,
  fillImageTypeName,
  selectedFilesSendMail,
}) {
  const sendClicked = useRef(false);

  const { loading } = useSelector((state) => state.documentos);

  const dispatch = useDispatch();

  const [editorValue, setEditorValue] = useState("");
  const [destinatario, setDestinatario] = useState("");
  const [assunto, setAssunto] = useState("");
  const [valueErrorDestinatario, setValueErrorDestinatario] = useState(false);
  const [valueErrorAssunto, setValueErrorAssunto] = useState(false);

  function validaEmail(destinatario) {
    const regex =
      /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;

    return !regex.test(destinatario);
  }

  const handleDestinatario = (e) => {
    if (!e.target.value) {
      return setDestinatario("");
    }

    setDestinatario(e.target.value);
    setValueErrorDestinatario(validaEmail(e.target.value));
  };

  const handleAssunto = (e) => {
    if (!e.target.value) {
      return setAssunto("");
    }

    setValueErrorAssunto(false);
    setAssunto(e.target.value);
  };

  const handleSubmit = async () => {
    if (!destinatario) {
      return setValueErrorDestinatario(true);
    }

    if (!assunto) {
      return setValueErrorAssunto(true);
    }

    if (!editorValue) {
      return toastr.error(
        "Ops",
        "Não é possível enviar um e-mail sem mensagem de conteúdo."
      );
    }

    const sendEmailValues = {
      conteudo: editorValue,
      assunto: assunto,
      destinatario: destinatario,
      idDocumento: selectedDoc._id,
      imagens: await transform(selectedFilesSendMail),
    };

    sendClicked.current = true;

    dispatch(DocumentosActions.sendEmailByAppRequest(sendEmailValues));

    setEditorValue("");
  };

  useEffect(() => {
    if (!loading && sendClicked.current === true) {
      handleStay();
      sendClicked.current = false;
    }
  }, [loading, handleStay]);

  const imagensSize = selectedFilesSendMail.length;

  const textImagensSize =
    selectedFilesSendMail.length === 1
      ? ` Existe ${imagensSize} imagem em anexo.`
      : selectedFilesSendMail.length > 1
        ? ` Existem ${imagensSize} imagens em anexo.`
        : `Não existem imagens em anexo.`;

  const transform = async (selectedFiles) => {
    const promises = selectedFiles.map(async (file) => {
      console.log("file: ", file.filepath);
      const signedUrl = await getPresignedUrl(file.filepath);
      console.log("signedUrl: ", signedUrl);

      return {
        path: signedUrl,
      };
    });

    const result = await Promise.all(promises);
    console.log("result ", result);

    return result;
  };

  // const Modelo1 = `
  //       <p>À</p>
  //       <p>Processo: NÚMERO CNJ</p>
  //       <p>Número do Ofício: NÚMERO</p>
  //       <p>Autor: NOME/RAZÃO SOCIAL</p>
  //       <p>Réu: NOME/RAZÃO SOCIAL</p>
  //       <p>Código de Cadastro: CÓDIGO X-GRACCO</p>
  //       <br />
  //       <p>Em atendimento aos termos do ofício supra, vem respeitosamente à presença de Vossa Excelência, informar os dados dos veículos adquiridos pelo executado NOME/RAZÃO SOCIAL – CPF/CNPJ, quais sejam:</p>
  //       <p>MODELO, PLACA, RENAVAM, CHASSI, ANO</p>
  //       <p>Dessa forma, apresentamos em anexo as notas fiscais em cumprimento à determinação judicial.</p>
  //       <p>Aproveitamos a oportunidade para renovar nossos protestos de estima e consideração.</p>
  //       <p>Cordialmente.</p>
  //       <br />
  //       <p>Assinatura.</p>
  //       <p>Nome da Empresa</p>
  //       <p>Responsável</p>
  //       <p>Cargo</p>`;

  return (
    <Modal show={showModal} onHide={handleStay} centered size="xl">
      <Modal.Header>
        <Modal.Title>Envio de email</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="text-input">
          <TextField
            label="E-mail do destinatário"
            variant="outlined"
            onChange={(e) => handleDestinatario(e)}
            error={valueErrorDestinatario}
            helperText={
              valueErrorDestinatario === true
                ? "Email Destinatário inválido."
                : ""
            }
          />
        </div>

        <div className="text-input">
          <TextField
            label="Assunto (Título) do Email"
            variant="outlined"
            error={valueErrorAssunto}
            helperText={
              valueErrorAssunto === true
                ? "Assunto não pode estar em branco."
                : ""
            }
            onChange={(e) => handleAssunto(e)}
          />
        </div>

        {selectedFilesSendMail.length && selectedFilesSendMail.length > 0 ? (
          <div className="icon-attached">
            <AttachFileIcon />
            {textImagensSize}
          </div>
        ) : (
          <div className="icon-attached">{textImagensSize}</div>
        )}

        <div className="component-editor">
          <CKEditor
            editor={ClassicEditor}
            data={editorValue}
            onChange={(event, editor) => {
              const data = editor.getData();
              setEditorValue(data);
            }}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="white-loading">
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={loading}
          >
            Enviar
            {!!loading && <CircularProgress size={20} />}
          </Button>
        </div>

        <div>
          <Button variant="contained" color="secondary" onClick={handleStay}>
            Voltar
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
