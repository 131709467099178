import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import CameraStep from "./StepTypes/camera";
import TextStep from "./StepTypes/text";
import CircularProgress from "@material-ui/core/CircularProgress";

import { Creators as OnboardingActions } from "store/ducks/onboarding";
import { Creators as UsuariosActions } from "store/ducks/usuarios";

import {
  Container,
  Header,
  FormContainer,
  StepList,
  StepItem,
  StepItemMobile,
  StepListContainer,
  PreviewStepContainer,
  PreviewStepContent,
  PreviewButtons,
  LoadingOnboardingData,
} from "./OnboardingStyle";

import ValidationStep from "./StepTypes/validation";
import ConciligConditional from "./StepTypes/concilig";
import FieldsStep from "./StepTypes/fields";
import ImageStep from "./StepTypes/image";
import Verificacao from "./Verificacao";

import { toastr } from "react-redux-toastr";

export default function OnboardingPublicForm(props) {
  const dispatch = useDispatch();
  const { organization, id } = useParams();

  useEffect(() => {
    dispatch(UsuariosActions.setOrganization(organization));
    dispatch(OnboardingActions.getRequest(id));
  }, [id, dispatch]);

  const {
    form: onboardingForm,
    loading: loadingOnboarding,
    twofactor: { formCode, codigo_verificado },
  } = useSelector((state) => state.onboarding);

  const [form, setForm] = useState(null);
  const [isFinished, setIsFinished] = useState(false);
  const [formFieldNames, setFormFieldNames] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [images, setImages] = useState([]);
  const currentStepSetting = form?.steps ? form.steps[currentStep] : {};
  const imageTypesRequired =
    currentStepSetting?.imageTypes?.filter((x) => !!x.required) ?? [];

  useEffect(() => {
    if (onboardingForm) {
      setForm(onboardingForm.config);
      setFormFieldNames(
        onboardingForm.config?.steps
          .filter((step) => step.type === "fields")
          .map((step) => step.fields)
          .flat(1)
          .map((field) => field.id_es)
      );
    }
  }, [onboardingForm]);

  const handleCaptureImage = (image) => {
    setImages([...images, image]);

  };

  const handleCurrentStep = (stepIndex) => {
    setLoading(true);
    setCurrentStep(stepIndex);
    setLoading(false);
  };

  const handleNextStep = () => {
    const hasBlock =
      currentStepSetting?.type === "image" &&
      !imageTypesRequired?.every(
        (type) =>
          type.required && images.some((image) => image.tpImg === type._id)
      );

    if (hasBlock) {
      return toastr.warning(
        "Ops",
        "Imagens obrigatórias não foram preenchidas."
      );
    }

    if (currentStep < form.steps.length - 1) {
      setLoading(true);
      setCurrentStep(currentStep + 1);
      setLoading(false);
    } else {
      dispatch(
        OnboardingActions.finishOnboardingRequest({ onboardingId: id, form, imagens: images })
      );
      setIsFinished(true);
    }
  };

  const handlePreviousStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleUpdateFields = async (fields) => {
    const stepFields = form.steps.map((step) => {
      if (step.type === "fields") {
        for (let field of step.fields) {
          for (let f of fields) {
            if (
              f.nome === field.id_es &&
              !field.value &&
              field.value !== "Não identificado" &&
              !field.valor
            ) {
              field.valor = f.value;
            }
          }
        }
      }

      return step;
    });

    setForm({ ...form, steps: stepFields });
  };

  const onFieldsChange = async (field) => {
    const stepFields = form.steps.map((step) => {
      if (step.type === "fields") {
        for (let f of step.fields) {
          if (f.nome === field.target.name) {
            f.valor = field.target.value;
          }
        }
      }

      return step;
    });

    setForm({ ...form, steps: stepFields });
  };

  return (
    <>
      {loadingOnboarding ? (
        <LoadingOnboardingData>
          <CircularProgress size={60} />
          <h3>Aguarde enquanto carregamos os dados...</h3>
        </LoadingOnboardingData>
      ) : (
        <>

          {!codigo_verificado ? (
            <Verificacao form={form} />
          ) : (
            <>
              {form && (
                <Container $background={form.layout.background}>
                  {form.layout.logo && (
                    <Header>
                      <img src={form.layout.logo} alt="logo" />
                    </Header>
                  )}

                  <FormContainer>
                    {!!isFinished && (
                      <h2>Processo finalizado com sucesso!</h2>
                    )}
                    {!isFinished && (
                      <>
                        <StepListContainer>
                          <StepList>
                            {form.steps.map((step, index) => (
                              <StepItem
                                key={step.name}
                                $selected={currentStep === index}
                                $layout={form.layout.step}
                              // onClick={(e) => handleCurrentStep(index)} desativado nao faz sentido pq pula etapas
                              >
                                <span>{index + 1}</span>
                                <strong>{step.name}</strong>
                              </StepItem>
                            ))}
                          </StepList>
                          <StepItemMobile
                            $selected={true}
                            $layout={form.layout.step}
                          >
                            <span>{currentStep + 1}</span>
                            <strong>{form.steps[currentStep].name}</strong>
                          </StepItemMobile>
                        </StepListContainer>

                        <PreviewStepContainer>
                          <PreviewStepContent>
                            {!loading && (
                              <>
                                {form.steps[currentStep].type === "text" && (
                                  <TextStep
                                    key={currentStep}
                                    content={form.steps[currentStep].content}
                                  />
                                )}
                                {form.steps[currentStep].type === "image" && (
                                  <ImageStep
                                    key={currentStep}
                                    handleCaptureImage={handleCaptureImage}
                                    settings={form.steps[currentStep]}
                                    handleUpdateFields={handleUpdateFields}
                                    layout={form.layout.buttons}
                                    imagesOnboarding={images}
                                    setImagesOnboarding={setImages}
                                    formFieldNames={formFieldNames}
                                  />
                                )}
                                {form.steps[currentStep].type === "camera" && (
                                  <CameraStep
                                    key={currentStep}
                                    handleCaptureImage={handleCaptureImage}
                                    settings={form.steps[currentStep]}
                                    layout={form.layout.buttons}
                                  />
                                )}
                                {form.steps[currentStep].type ===
                                  "validation" && (
                                    <ValidationStep
                                      key={currentStep}
                                      settings={form.steps[currentStep]}
                                      nextStep={handleNextStep}
                                      fields={form.steps
                                        .filter((step) => step.type === "fields")
                                        .map((step) => step.fields)
                                        .flat(1)}
                                    />
                                  )}
                                {form.steps[currentStep].type === "fields" && (
                                  <FieldsStep
                                    key={currentStep}
                                    settings={form.steps[currentStep]}
                                    onFieldsChange={onFieldsChange}
                                  />
                                )}
                              </>
                            )}
                          </PreviewStepContent>
                          {!isFinished && (
                            <PreviewButtons $layout={form.layout.buttons}>
                              <button
                                onClick={handlePreviousStep}
                                disabled={currentStep === 0}
                              >
                                Anterior
                              </button>
                              <button onClick={handleNextStep}>
                                {currentStep + 1 === form.steps.length
                                  ? "Finalizar"
                                  : "Próximo"}
                              </button>
                            </PreviewButtons>
                          )}
                        </PreviewStepContainer>
                      </>
                    )}
                  </FormContainer>
                </Container>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}
