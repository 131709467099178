import { put, takeLatest } from "redux-saga/effects";
import { Creators as MenuActions } from "../ducks/menu";

export function* toggleMenuSaga() {
    yield put({ type: MenuActions.toggleMenu });
}

export function* watchToggleMenu() {
    yield takeLatest(MenuActions.toggleMenu, toggleMenuSaga);
}
