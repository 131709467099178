export const Types = {
  SEND_REQUEST: "docsignature/SEND_REQUEST",
  SEND_SUCCESS: "docsignature/SEND_SUCCESS",
  SEND_ERROR: "docsignature/SEND_ERROR",
  LIST_REQUEST: "docsignature/LIST_REQUEST",
  LIST_SUCCESS: "docsignature/LIST_SUCCESS",
  BLOB_REQUEST: "docsignature/BLOB_REQUEST",
  BLOB_SUCCESS: "docsignature/BLOB_SUCCESS",
  B64_REQUEST: "docsignature/B64_REQUEST",
  B64_SUCCESS: "docsignature/B64_SUCCESS",
};

const INITIAL_STATE = {
  list: [],
  loading: false,
  page: 1,
  limit: 10,
  total: 0,
  search: "",
  sort: "_id",
  blob: null,
  b64: null,
};

export default function docsignature(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.SEND_REQUEST:
      return {
        ...state,
        ...action.payload,
        list: [],
        loading: true,
      };

    case Types.SEND_SUCCESS:
      return {
        ...state,
        list: [],
        loading: false,
      };

    case Types.SEND_ERROR:
      return {
        ...state,
        loading: false,
      };

    case Types.LIST_REQUEST:
      return { ...state, loading: true };

    case Types.LIST_SUCCESS:
      return { ...state, loading: false, list: action.payload.list };

    case Types.BLOB_REQUEST:
      return { ...state, loading: true };

    case Types.BLOB_SUCCESS:
      return { ...state, loading: false, blob: action.payload };

    case Types.B64_REQUEST:
      return { ...state, loading: true };

    case Types.B64_SUCCESS:
      return { ...state, loading: false, b64: action.payload };

    default:
      return state;
  }
}

export const Creators = {
  sendRequest: (params) => ({
    type: Types.SEND_REQUEST,
    payload: params,
  }),
  sendSuccess: (payload) => ({
    type: Types.SEND_SUCCESS,
    payload: payload,
  }),
  sendError: (payload) => ({
    type: Types.SEND_ERROR,
    payload: payload,
  }),
  listRequest: (payload) => ({
    type: Types.LIST_REQUEST,
    payload: payload,
  }),

  listSuccess: (payload) => ({
    type: Types.LIST_SUCCESS,
    payload: payload,
  }),

  blobRequest: (payload) => ({
    type: Types.BLOB_REQUEST,
    payload: payload,
  }),

  blobSuccess: (payload) => ({
    type: Types.BLOB_SUCCESS,
    payload: payload,
  }),

  b64Request: (payload) => ({
    type: Types.B64_REQUEST,
    payload: payload,
  }),

  b64Success: (payload) => ({
    type: Types.B64_SUCCESS,
    payload: payload,
  }),
};
