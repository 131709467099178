import React from 'react';
import { Row } from 'react-bootstrap';
import { Badge, Tooltip } from "@material-ui/core";
import { useSelector } from 'react-redux';

export function TooltipEmail({ children }) {

    const { agregacoes } = useSelector((state) => state.email);

    if (!agregacoes || (!agregacoes.possuiRespostaNaoLida && !agregacoes.possuiEscalonamentoPendente)) {
        return (<>{children}</>)
    }

    const { possuiRespostaNaoLida, possuiEscalonamentoPendente } = agregacoes;

    return (
        <Tooltip
            title={
                <div style={{ width: "200px", padding: '0 24px' }}>
                    {possuiRespostaNaoLida && <Row>Possui resposta não lida</Row>}
                    {possuiEscalonamentoPendente && <Row>Possui Escalonamento pendente</Row>}
                </div>
            }
            placement="top-start"
        >
            <Badge
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                variant="dot"
                color="primary"
                overlap="rectangle"
            >
                {children}
            </Badge>
        </Tooltip>
    )
}