import styled, { keyframes, css } from "styled-components/macro";
import { default as IconButtonMaterial } from "@material-ui/core/IconButton";

const rotate = keyframes`
    to {
        stroke-dashoffset: 230;
    }
`;

export const Container = styled(IconButtonMaterial)`
  position: relative;
  z-index: 2;
  width: 36px;
  height: 36px;
  background-color: #fff;
  color: #444444;
  transition: background-color 0.2s ease, color 0.2s ease;

  &:hover {
    background-color: #444444;
    color: #fff;
  }

  &:not(:last-of-type) {
    margin-right: 8px;
  }

  ${({ iconbackground }) =>
    iconbackground &&
    css`
      background-color: ${iconbackground} !important;

      &:hover {
        color: ${iconbackground} !important;
      }
    `};

  ${({ iconcolor }) =>
    iconcolor &&
    css`
      color: ${iconcolor} !important;

      &:hover {
        background-color: ${iconcolor} !important;
      }
    `};
`;

export const SVGContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;

  svg {
    display: block;
    margin: auto;
  }

  circle {
    fill: transparent;
    stroke: ${({ progresscolor }) => (progresscolor ? progresscolor : "black")};
    stroke-width: 2;
    stroke-dasharray: 200;
    stroke-dashoffset: 0;
    animation: ${rotate} 5s -2s linear forwards;
  }
`;
