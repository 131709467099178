import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Skeleton from '@material-ui/lab/Skeleton';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import { Creators as FilaActions } from '../../../../store/ducks/filas';
import { Creators as EtapasActions } from '../../../../store/ducks/etapas';
import { Creators as DocumentoActions } from 'store/ducks/documentos';

import './styles.css';
import { FluxoIcon } from 'components/Icons';

export default function Filas({
  setSelectedFilaNome,
  setSelectedFilaTitle,
  setOpenEtapas,
  fluxo,
}) {
  const dispatch = useDispatch();

  const [selectedFila, setSelectedFila] = useState(false);
  const [SLA, setSLA] = useState([]);

  const {
    access: { etapas, filas, empresas },
  } = useSelector((state) => state.permissoes);

  const { fila } = useSelector((state) => state.analise);

  const { list: filasList, loading: filasLoading } = useSelector(
    (state) => state.filas
  );

  const { limit = 10, sort = 'documentoAnalise.0.indexadores.0.valor' } =
    useSelector((state) => state.documentos);

  // useEffect(() => {
  //   if (fila) {
  //     handleLoadEtapas({ fila });
  //   }
  // }, [fila]);

  useEffect(() => {
    // console.log('Fluxo repassado:', fluxo);

    if (fluxo) {
      handleLoadEtapas({ fila: fluxo });
    }
  }, [fluxo]);

  const handleLoadEtapas = (value) => {
    if (value) {
      dispatch(EtapasActions.listRequest(value.fila, empresas));

      dispatch(
        DocumentoActions.listRequestByEtapas({
          fila: value.fila,
          etapas: null,
          empresas,
          endEvent: false,
          sla: SLA,
          limit,
          page: 1,
          sort,
          fields:
            'documentoAnalise.indexadores,usuarioAcao,etapas,ultimaEtapa,dtUltimaEtapa,sla',
        })
      );

      setSelectedFilaNome(value.fila);
      setSelectedFilaTitle(value.nome);

      setSelectedFila(true);
    } else {
      setSelectedFilaNome('');
      setSelectedFila(false);
    }
  };

  useEffect(() => {
    dispatch(FilaActions.listRequest({ filas, empresa: empresas }));
  }, [dispatch, empresas, filas]);

  return (
    <div className="filas-container">
      {filasLoading ? (
        <>
          <Skeleton width={'100%'} height={50} />
        </>
      ) : (
        <>
          <Autocomplete
            id="combo-box-filas"
            getOptionLabel={(option) => option.nome}
            //options={filasList.sort((a, b) => -b.name.localeCompare(a.name))}
            options={(filasList || [])
              ?.filter(
                (arr, index, self) =>
                  index === self.findIndex((t) => t.fila === arr.fila)
              )
              .sort((a, b) => (a.name > b.name ? 1 : -1))}
            noOptionsText="Não há filas disponíveis"
            getOptionSelected={(option, value) => option.fila === value.fila}
            onChange={(e, value) => handleLoadEtapas(value)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Selecione a fila desejada"
                variant="outlined"
              />
            )}
            size="small"
          />
        </>
      )}
    </div>
  );
}
