import React, { memo, useMemo } from 'react';
import TableCell from '@material-ui/core/TableCell';
import Skeleton from '@material-ui/lab/Skeleton';

import StyledTableRow from 'components/StyledTableRow';

function LoadingTable({ rows = 10, columns = 1 }) {

  const arrayRows = useMemo(() => {
    const arrayRow = [];
    for (let i = 0; i < rows; i++) arrayRow.push(i);
    return arrayRow;
  }, [rows]);

  const arrayColumns = useMemo(() => {
    const arrayColumns = [];
    for (let i = 0; i < columns; i++) arrayColumns.push(i);
    return arrayColumns;
  }, [columns])

  return (
    arrayRows.map(item =>
      <StyledTableRow key={item}>
        {arrayColumns.map(itemColumn =>
          <TableCell key={itemColumn}><Skeleton /></TableCell>
        )}
      </StyledTableRow>
    )
  );
}

export default memo(LoadingTable);