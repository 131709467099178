import React, { useEffect, useRef, useState } from "react";

import {
    IconButton,
    Menu,
    MenuItem,
    Modal,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { Creators as EmailsActions } from "store/ducks/mail";
import { MoreIcon } from "components/Icons";

const EMAIL_OPTIONS = [
    'Exportar e-mail atual',
    'Exportar todos os e-mails vinculados ao e-mail atual',
    'Exportar todos os e-mails vinculados ao prontuário'
];

export function EmailExport({ email, styleIcons = null }) {
    const dispatch = useDispatch();
    const { exportar: { data: arquivoGerado } } = useSelector((state) => state.email);

    const [option, setOption] = useState(-1);
    const [anchor, setAnchor] = useState(null);
    const [openModal, setOpenModal] = useState(false);

    const file = useRef(null);
    const open = Boolean(anchor);

    function handleClose(opt) {
        setOption(opt);
        setAnchor(null);
    }
    
    function onCloseModal() {
        dispatch(EmailsActions.exportEmailSuccess({}));
        setOpenModal(false);
    }

    function exportPdfEmail({ option, email: { _id: id, idEmail, documento } }) {
        if (option < 0 || option > EMAIL_OPTIONS.length) return;
        dispatch(EmailsActions.exportEmailRequest({ option, id, idEmail, documento }));
    }

    useEffect(() => exportPdfEmail({ option, email }), [option])
    useEffect(() => {
        const hasFile = Object.keys(arquivoGerado).length
        file.current = hasFile ? arquivoGerado.data : null;
        setOpenModal(hasFile);
    }, [arquivoGerado]);

    return (
        <>
            <IconButton
                edge="end"
                aria-label="delete"
                onClick={(e) => {
                    setAnchor(e.currentTarget);
                }}
            >
                <MoreIcon fontSize="medium" style={styleIcons} />
            </IconButton>
            <Menu
                id="mail-menu"
                anchorEl={anchor}
                open={open}
                onClose={() => handleClose(-1)}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {EMAIL_OPTIONS.map((option, index) =>

                    <MenuItem onClick={() => handleClose(index)}>
                        {option}
                    </MenuItem>

                )}
            </Menu>

            <Modal open={openModal} onClose={onCloseModal}>
                <iframe
                    src={`data:application/pdf;base64,${file.current}`}
                    title="exportacao"
                    width="80%"
                    height="1024px"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        top: `25%`,
                        margin: 'auto'
                    }}
                />
            </Modal>

        </>
    )
}
