export const Types = {
  LIST_REQUEST: "comarca/LIST_REQUEST",
  LIST_SUCCESS: "comarca/LIST_SUCCESS",
  NEW_REQUEST: "comarca/NEW_REQUEST",
  NEW_SUCCESS: "comarca/NEW_SUCCESS",
  DELETE_REQUEST: "comarca/DELETE_REQUEST",
  DELETE_SUCCESS: "comarca/DELETE_SUCCESS",
  GET_REQUEST: "comarca/GET_REQUEST",
  GET_SUCCESS: "comarca/GET_SUCCESS",
  EDIT_REQUEST: "comarca/EDIT_REQUEST",
  EDIT_SUCCESS: "comarca/EDIT_SUCCESS",
};

/* Reducer */

const INITIAL_STATE = {
  loading: false,
  list: [],
  comarca: [],
  sort: "nome",
  page: 1,
  limit: 10,
  total: 0,
  uf: "",
};

export default function comarca(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.LIST_REQUEST:
      return { ...state, loading: true };

    case Types.LIST_SUCCESS:
      return { ...state, ...action.payload, loading: false };

    case Types.NEW_REQUEST:
      return { ...state, loading: true };

    case Types.NEW_SUCCESS:
      return { ...state, loading: false };

    case Types.DELETE_REQUEST:
      return { ...state, loading: true };

    case Types.DELETE_SUCCESS:
      return { ...state, loading: false };

    case Types.GET_REQUEST:
      return { ...state, loading: true };

    case Types.GET_SUCCESS:
      return { ...state, loading: false };

    case Types.EDIT_REQUEST:
      return { ...state, loading: true };

    case Types.EDIT_SUCCESS:
      return { ...state, loading: false };

    default:
      return state;
  }
}

export const Creators = {
  listRequest: data => ({
    type: Types.LIST_REQUEST,
    payload: data,
  }),

  listSuccess: data => ({
    type: Types.LIST_SUCCESS,
    payload: data,
  }),

  deleteRequest: id => ({
    type: Types.DELETE_REQUEST,
    payload: id,
  }),

  deleteSuccess: () => ({
    type: Types.DELETE_SUCCESS,
    payload: {},
  }),

  getRequest: id => ({
    type: Types.GET_REQUEST,
    payload: id,
  }),

  getSuccess: comarca => ({
    type: Types.GET_SUCCESS,
    payload: comarca,
  }),

  editRequest: comarca => ({
    type: Types.EDIT_REQUEST,
    payload: comarca,
  }),

  editSuccess: () => ({
    type: Types.EDIT_SUCCESS,
    payload: {},
  }),

  createRequest: comarca => ({
    type: Types.NEW_REQUEST,
    payload: comarca,
  }),

  createSuccess: () => ({
    type: Types.NEW_SUCCESS,
    payload: {},
  }),
};
