import styled from 'styled-components/macro';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  h4 {
    margin-bottom: 15px;
  }
`;

export const ValidationListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding: 20px;

  h4 {
    font-size: 14px;
  }
`;

export const ValidationList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    font-size: 14px;
    margin-bottom: 5px;
    list-style: none;
  }
`;
