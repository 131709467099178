import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import { useSelector, useDispatch } from "react-redux";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import { Creators as ComentariosActions } from "store/ducks/comentarios";

import useStyles from "./styles";

const INITIAL_FORM = {
  alegaFraude: "",
  numeroContrato: "",
  motivo: "",
  subMotivo: "",
  tipo: "Evidência",
  descricao: "",
};

function FormColumnsComentario({ analise,children }) {
  const username = useSelector((state) => state.usuarios.auth.name);
  const { loading } = useSelector((state) => state.comentarios);

  const [form, setForm] = useState(INITIAL_FORM);

  const classes = useStyles();

  const dispatch = useDispatch();

  useEffect(() => {
    setForm(INITIAL_FORM);
  }, [loading]);

  function handleChange({ target }) {
    const { value, name } = target;
    setForm({
      ...form,
      [name]: value,
    });
  }

  function canSubmit() {
    return form.texto !== "" || form.file !== "";
  }

  function handleSubmit() {
    dispatch(
      ComentariosActions.createRequest(analise.processInstanceId, {
        ...form,
        data: Date.now(),
        autor: username,
        idEmpresa: analise.idEmpresa,
        idDocumento: analise._id
      })
    );
  }

  return (
    <div>
      <TextField
        name="numeroContrato"
        label={!!loading ? "Aguarde..." : "Nº Contrato"}
        value={form.texto}
        onChange={handleChange}
        size="small"
        variant="outlined"
        style={{ marginBottom: "8px" }}
        disabled={!!loading}
      />
      <TextField
        name="motivo"
        label={!!loading ? "Aguarde..." : "Motivo"}
        value={form.texto}
        onChange={handleChange}
        size="small"
        variant="outlined"
        style={{ marginBottom: "8px" }}
        disabled={!!loading}
      />
      <TextField
        name="subMotivo"
        label={!!loading ? "Aguarde..." : "Submotivo"}
        value={form.texto}
        onChange={handleChange}
        className="mb-3"
        size="small"
        variant="outlined"
        style={{ marginBottom: "8px" }}
        disabled={!!loading}
      />
      <FormControl variant="outlined" size="small" fullWidth>
        <InputLabel id={"select-label-opcao"}>Alega fraude</InputLabel>
        <Select
          labelId="select-label-opcao"
          id="alegaFraude"
          value={form.alegaFraude}
          label="Opção"
          onChange={handleChange}
          className="mb-3"
          size="small"
          name="alegaFraude"
        >
          {["Não", "Sim"].map((opcao) => (
            <MenuItem key={opcao} value={opcao}>{opcao}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        name="descricao"
        label={!!loading ? "Aguarde..." : "Descrição"}
        value={form.descricao}
        className="mb-3"
        onChange={handleChange}
        multiline
        minRows={3}
        variant="outlined"
        disabled={!!loading}
      />
      <div className={classes.actions}>
        <Button
          color="primary"
          variant="contained"
          className={classes.newCommentButton}
          disabled={!canSubmit() || !!loading}
          onClick={handleSubmit}
        >
          Nova Evidência
        </Button>
        {children}
      </div>
    </div>
  );
}

export default FormColumnsComentario;
