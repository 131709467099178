import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

import TableMenu from "./TableMenu";

import { useSelector } from "react-redux";

import Button from "@material-ui/core/Button";
import Content from "components/Content";


export default function Menus() {

  const { pathname } = useLocation();

  const { access: { servicos } } = useSelector(state => state.permissoes);

  const [userActions, setUserActions] = useState([]);

  useEffect(() => {
    let find = servicos.find(item => item.route === pathname);
    if (find !== undefined) { setUserActions(find) }
  }, [pathname, servicos]);

  return (
    <Content>
      <Row>
        <Col md="12" className="painel">
          <div class="painel-content">
            <div class="painel-title text-left">
              <div></div>
              {userActions.actions &&
                userActions.actions.map((userAct, index) =>
                  userAct.action === "criar" &&
                  <Button
                    key={index}
                    component={Link}
                    to={"/configuracao/menu/novo"}
                    variant="contained"
                    color="primary"
                  >
                    Novo Menu
                  </Button>
                )}
            </div>
            <div class="painel-body">
              <TableMenu />
            </div>
          </div>
        </Col>
      </Row>
    </Content>
  );
}
