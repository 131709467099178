import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import Skeleton from "@material-ui/lab/Skeleton";

import AutocompleteForm from "components/AutocompleteForm";
import InputForm from "components/InputForm";

import { Form as FormBootstrap } from "react-bootstrap";

function DynamicFieldValue({
  register,
  condicao,
  indexador,
  indexadores,
  tipoDocumento,
  referencia,
  ref,
  index,
}) {
  const { list: listEmpresas, loading: loadingEmpresas } = useSelector(
    (state) => state.empresas
  );

  const opcoes = useMemo(() => {
    if (indexador) {
      if (indexadores.some((idx) => idx.id_es === indexador)) {
        const idx = indexadores.find((idx) => idx.id_es === indexador);
        if (idx.tipo === "combo") return idx.opcoes;
      }
    }
    return [];
  }, [indexador, indexadores]);

  const tipo = useMemo(() => {
    if (indexador) {
      if (indexadores.some((idx) => idx.index === indexador)) {
        const idx = indexadores.find((idx) => idx.index === indexador);
        return idx.tipo;
      }
    }
    return "caracter";
  }, [indexador, indexadores]);

  const type = useMemo(() => {
    if (tipo === "numero") return "number";
    if (tipo === "data") return "date";
    return "text";
  }, [tipo]);

  return (
    <>
      {condicao !== "DENTRO_DE" && condicao !== "FORA_DE" && (
        <>
          <FormBootstrap.Label>Valor:</FormBootstrap.Label>
          <InputForm type={type} name={`regras[${index}].valor`} />
        </>
      )}

      {(condicao === "DENTRO_DE" || condicao === "FORA_DE") && (
        <>
          {referencia === "INDEXADOR" && (
            <>
              <FormBootstrap.Label>Valores:</FormBootstrap.Label>
              <AutocompleteForm
                freeSolo={true}
                multiple={true}
                name={`regras[${index}].valores`}
                options={opcoes}
                transformOnSelect={(option) => option}
                getOptionLabel={(option) => option}
              />
            </>
          )}

          {referencia === "EMPRESA" && (
            <>
              <FormBootstrap.Label>Empresa:</FormBootstrap.Label>
              {!!loadingEmpresas && <Skeleton height="100%" />}

              {!loadingEmpresas && (
                <AutocompleteForm
                  name={`regras[${index}].empresas`}
                  options={listEmpresas || []}
                  transformOnSelect={(option) => ({
                    _id: option._id,
                    fantasia: option.fantasia,
                  })}
                  getOptionLabel={(option) =>
                    typeof option === "string" ? option : option.fantasia
                  }
                  getOptionSelected={(option, value) => (value?._id || value) === option._id}
                />
              )}
            </>
          )}
        </>
      )}
    </>
  );
}

export default DynamicFieldValue;
