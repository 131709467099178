import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

import useStyles from './styles';

function ModalWaitCheckTransfer(props) {
  const classes = useStyles();

  return (
    <Dialog {...props}>
      <DialogContent className={classes.root}>
        <CircularProgress color="primary" />
        <Typography variant="h6" className={classes.text}>Verificando imagens selecionadas para translado</Typography>
      </DialogContent>
    </Dialog>
  );
}

export default ModalWaitCheckTransfer;