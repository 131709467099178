import { call, put, select } from "redux-saga/effects";

import api from "../../services/api";
import { getToken } from "../../services/auth";

import { Creators as ProcessosActions } from "../ducks/processos";

export const getOrganization = (state) => state.usuarios.auth.organization;
export const getUserId = (state) => state.usuarios.auth.id;

export function* statusAcoes(action) {
  try {
    const organization = yield select(getOrganization);

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    let dataRel = "";

    if (action.payload.filter.data) {
      dataRel = `?dataRel=${action.payload.filter.data}`;
    }

    const { data } = yield call(
      api.get,
      `/processos/statusacoes${dataRel}`,
      headerParams
    );

    yield put(ProcessosActions.statusAcoesSuccess(data));
  } catch (error) {}
}

export function* acoesUF(action) {
  try {
    const organization = yield select(getOrganization);

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    let dataRel = "";

    if (action.payload.filter.data) {
      dataRel = `?dataRel=${action.payload.filter.data}`;
    }

    const { data } = yield call(
      api.get,
      `/processos/acoesporuf${dataRel}`,
      headerParams
    );

    yield put(ProcessosActions.acoesUFSuccess(data));
  } catch (error) {}
}

export function* monitoria(action) {
  try {
    const organization = yield select(getOrganization);

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    let dataRel = "";

    if (action.payload.filter.data) {
      dataRel = `?dataRel=${action.payload.filter.data}`;
    }

    const { data } = yield call(
      api.get,
      `/processos/monitoria${dataRel}`,
      headerParams
    );

    yield put(ProcessosActions.monitoriaSuccess(data));
  } catch (error) {}
}

export function* naturezaOperacao(action) {
  try {
    const organization = yield select(getOrganization);

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    let dataRel = "";

    if (action.payload.filter.data) {
      dataRel = `?dataRel=${action.payload.filter.data}`;
    }

    const { data } = yield call(
      api.get,
      `/processos/naturezadaacao${dataRel}`,
      headerParams
    );

    yield put(ProcessosActions.naturezaOperacaoSuccess(data));
  } catch (error) {}
}

export function* capturaAcaoJudicial(action) {
  try {
    const organization = yield select(getOrganization);

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    let dataRel = "";

    if (action.payload.filter.data) {
      dataRel = `?dataRel=${action.payload.filter.data}`;
    }

    const { data } = yield call(
      api.get,
      `/processos/capturajudicial${dataRel}`,
      headerParams
    );

    yield put(ProcessosActions.capturaAcaoJudicialSuccess(data));
  } catch (error) {}
}
export function* capturaAcaoJudicialPrazo(action) {
  try {
    const organization = yield select(getOrganization);

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    let dataRel = "";

    if (action.payload.filter.data) {
      dataRel = `?dataRel=${action.payload.filter.data}`;
    }

    const { data } = yield call(
      api.get,
      `/processos/capturajudicialprazo${dataRel}`,
      headerParams
    );

    yield put(ProcessosActions.capturaAcaoJudicialPrazoSuccess(data));
  } catch (error) {}
}

export function* analiticoProcessos(action) {
  try {
    const organization = yield select(getOrganization);

    const { type, param, params } = action.payload;

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
      params: {
        ...params,
        page: !params.page || params.page === 0 ? 1 : params.page,
      },
    };

    const { data } = yield call(
      api.get,
      `/processos/analitico?type=${type}&param=${param}`,
      headerParams
    );

    yield put(ProcessosActions.analiticoSuccess(data));
  } catch (error) {}
}
