import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { Form } from '@unform/web';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { useLocation } from 'react-router-dom';

import AutocompleteForm from 'components/AutocompleteForm';

import { Creators as ImagensModalidadeActions } from 'store/ducks/imagensmodalidade';
import { Creators as AuditoriaActions } from 'store/ducks/auditoria';

function FormModal({ data, onSave, onClose,...props }) {
  const location = useLocation();
  const dispatch = useDispatch();

  const { list: tipoImagens } = useSelector(state => state.tipoImagem)
  const [empresa, setEmpresa] = useState('');

  useEffect(() => {
    if (data && data.empresa) setEmpresa(data.empresa.fantasia);
  }, [data]);

  function handleSubmit(values) {

    if (!_.isEqual(values.imagens, data.imagens)) {
      const dadosAlterados = {
        Imagens: {
          valorAnterior: data.imagens.map(imagem => imagem.nome).join(', '),
          valorNovo: values.imagens.map(imagem => imagem.nome).join(', '),
        }
      };

      const auditoriaBody = {
        detalhes: {
          acao: 'Alteração das Imagens',
          route: location.pathname,
          modalidadeEmpresa: data._id,
          categoria: 'modalidadeEmpresa',
          dadosAlterados,
        },
      };
      if (!_.isEqual(dadosAlterados, {}))
        dispatch(AuditoriaActions.createRequest(auditoriaBody));
    }

    const imagens = values.imagens.map(item => item._id);

    dispatch(
      ImagensModalidadeActions.saveRequest({
        modalidade: data.modalidade._id,
        empresa: data.empresa._id,
        ...values,
        imagens
      })
    )
    onSave();
  }

  return (
    <Dialog {...props}>
      <DialogTitle>
        <strong>Empresa:&nbsp;</strong>
        <span>{empresa}</span>
      </DialogTitle>
      <DialogContent>
        <Form 
          initialData={data}
          onSubmit={handleSubmit}
        >
          <AutocompleteForm
            multiple
            name="imagens"
            label="Imagens"
            options={tipoImagens}
            getOptionLabel={option => option.nome}
          />
          <DialogActions>
            <Button 
              color="primary"
              variant="contained"
              type="submit"
            >
              Salvar
            </Button>
            <Button 
              color="secondary"
              variant="contained"
              onClick={onClose}
            >
              Cancelar
            </Button>
          </DialogActions>
        </Form>
      </DialogContent>
    </Dialog>
  );
}

export default FormModal;