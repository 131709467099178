import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

import { Row } from "react-bootstrap";

import { useDispatch, useSelector } from "react-redux";

import { parseISO, format } from "date-fns";

import { Creators as MensagemAction } from "store/ducks/mensagem";

import Field from "./field";
import "./styles.css";

function ViewMensagem({ documento }) {
  const dispatch = useDispatch();

  const { openView, loading, data } = useSelector((state) => state.mensagem);

  const { SLA, destinatario, assunto, conteudo, autor, createdAt,participantes } = data;
  const displayDestinatario = handleValue();

  function handleClose() {
    if (!loading) dispatch(MensagemAction.closeView());
  }

  function handleValue(){
    if (participantes?.length > 0) return participantes.reduce((acc,participante) => acc += `${participante.nome}\n`,[''])
    return destinatario && destinatario.nome
  }

  return (
    <Dialog open={openView} onClose={handleClose}>
      <DialogTitle>Detalhes da mensagem</DialogTitle>
      <DialogContent>
        <Row>
          <Field
            label="Data de envio"
            value={
              createdAt
                ? format(parseISO(createdAt.split(".")[0]), "dd/MM/yyyy")
                : ""
            }
          />
          <Field label="Autor" value={autor && autor.nome} />
          <Field
            label="Destinatário"
            value={displayDestinatario}
            multiLineValue={true}
          />
          <Field label="Assunto" value={assunto} />
          <Field label="Conteúdo" value={conteudo} />
        </Row>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={handleClose}>
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ViewMensagem;
