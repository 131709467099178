import styled from 'styled-components/macro';

export const Container = styled.div`
    position: absolute;
    display: flex;
    top: 16px;
    right: 16px;
    background-color: transparent;
    outline: none;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color .3s ease;

    &:hover {
        background-color: #00000022;
    }
`;