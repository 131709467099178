import styled from 'styled-components/macro';

export const Container = styled.div`
    display: flex;
    width: 100%;
    min-height: 100%;
    background-color: transparent;
    position: relative;
    padding-left: 1rem;
    padding-right: 1rem;
    color: #444444;
`;

export const ChartRow = styled.ul`
    grid-area: chart;
    display: flex;
    justify-content: space-evenly;
    height: 100%;
`;