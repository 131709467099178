import { call, select, put } from "redux-saga/effects";
import { toastr } from "react-redux-toastr";

import api from "services/api";
import { getToken } from "services/auth";

import { Creators as GraphsActions } from "store/ducks/graphs";

export const getPermissoesEmpresas = (state) =>
  state.permissoes.access.empresas;
export const getOrganization = (state) => state.usuarios.auth.organization;

export function* getTotalByCompany() {
  const empresas = yield select(getPermissoesEmpresas);
  const organization = yield select(getOrganization);

  const headersParams = {
    headers: { Authorization: getToken(), xkeyorg: organization },
    params: { limit: 5 },
  };

  try {

    const { data } = yield call(
      api.post,
      '/documentos/indicadores/qtdporempresa',
      empresas,
      headersParams
    )

    const topFive = data.map(item => {
      const [firtName, secondName=''] = item.nome.split(" ");
      return {
        _id: item._id,
        name: `${firtName} ${secondName}`,
        value: item.value,
      }
    });
    yield put(GraphsActions.totalByCompanySuccess(topFive));
    
  } catch (e) {
    toastr.error(
      "Ops",
      "Não foi possível carregar o gráfico de total de documentos por empresa."
    );
  }
}
