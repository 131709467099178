import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { Form } from "@unform/web";

import { Creators as TiposDocumentoActions } from "store/ducks/tiposdocumento";
import { Creators as TiposImagemActions } from "store/ducks/tipoimagem";
import { Creators as TemplateActions } from "store/ducks/template";

import AutocompleteForm from "components/AutocompleteForm";
import ButtonMui from "@material-ui/core/Button";
import Skeleton from "@material-ui/lab/Skeleton";
import BackButton from "components/BackButton";
import InputForm from "components/InputForm";
import Grid from "@material-ui/core/Grid";
import Content from "components/Content";

const INITIAL_FORM = {
  name: "",
  modelType: "",
  description: "",
  idTipoDocumento: null,
};

export default function FormTemplate(props) {
  const dispatch = useDispatch();
  const formRef = useRef(null);

  const { template } = props.location.state || {};

  const [tipoDoc, setTipoDoc] = useState( { _id: template?.idTipoDocumento });

  const {
    access: { empresas },
  } = useSelector((state) => state.permissoes);

  const { listByEmpresas: tipoDocList, loading: loadingTipoDoc } = useSelector(
    (state) => state.tipoDocumentos
  );

  const { list: tipoImagens, loading: loadingTipoImagem } = useSelector(
    (state) => state.tipoImagem
  );

  useEffect(() => {
    if (tipoDoc && tipoDoc._id)
      dispatch(TiposImagemActions.listByTipoDocumentoRequest(tipoDoc._id));
  }, [tipoDoc, dispatch]);

  useEffect(() => {
    dispatch(TiposDocumentoActions.listByEmpresaRequest(empresas));
  }, [dispatch, empresas]);

  const onSubmitLayout = (values) => {
    values["modelName"] = values["name"];

    const fakeState = {
      paragraphTemplate: template?.paragraphTemplate || [],
      header: template?.header || "",
      footer: template?.footer || "",
      tipoImagem: template?.tipoImagem || null,
      idTipoDocumento: template?.idTipoDocumento || null,
    };

    if (template)
      return dispatch(
        TemplateActions.editTemplateRequest({
          id: template._id,
          state: fakeState,
          data: values,
        })
      );

    dispatch(TemplateActions.saveTemplate({ state: fakeState, data: values }));
    dispatch(TemplateActions.getTemplate({}));
  };

  return (
    <Content>
      <Row>
        <Col md="12" className="painel">
          <div className="painel-content">
            <div className="painel-title text-left">Novo/Editar Template</div>
            <div className="painel-body">
              <Form
                initialData={template || INITIAL_FORM}
                onSubmit={onSubmitLayout}
                className={"mt-2"}
                ref={formRef}
              >
                <InputForm
                  label="Nome"
                  name="name"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />

                <InputForm
                  label="Descrição"
                  name="description"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />

                <AutocompleteForm
                  label="Modelo"
                  name="modelType"
                  options={[
                    "E-mail",
                    "Documento PDF",
                    "Assinatura eletrônica PDF",
                  ]}
                  transformOnSelect={(option) => option}
                  getOptionLabel={(option) => option}
                />

                {!loadingTipoDoc ? (
                  <AutocompleteForm
                    required
                    size="small"
                    name="idTipoDocumento"
                    label="Tipo documento"
                    noOptionsText="Selecione um tipo documento"
                    options={tipoDocList}
                    value={tipoDocList?.find(x => x._id === template?.idTipoDocumento)}
                    onChange={(event, newValue) => setTipoDoc(newValue)}
                    getOptionLabel={(option) => option.nome}
                    transformOnSelect={(option) => (option ? option : null)}
                    getOptionSelected={(option, value) =>
                      option._id === value._id
                    }
                  />
                ) : (
                  <Skeleton width="100%" height={50} />
                )}

                {!loadingTipoImagem ? (
                  <AutocompleteForm
                    multiple={true}
                    size="small"
                    name="tipoImagem"
                    label="Tipos de imagens"
                    defaultValue={tipoImagens?.filter((tipoImagem) => template?.tipoImagem?.some((tp) => tp._id === tipoImagem._id))}
                    noOptionsText="Selecione os tipos imagem"
                    options={tipoImagens || []}
                    getOptionLabel={(option) => option?.nome}
                    transformOnSelect={(option) => (option ? option : null)}
                    getOptionSelected={(option, value) =>
                      option?._id === value?._id
                    }
                  />
                ) : (
                  <Skeleton width="100%" height={50} />
                )} 

                <Grid container spacing={1} className="mt-2 mb-2">
                  <Grid item>
                    <ButtonMui
                      variant="contained"
                      color="primary"
                      type="submit"
                    >
                      Salvar
                    </ButtonMui>
                  </Grid>
                  <Grid item>
                    <BackButton variant="contained" color="secondary">
                      Voltar
                    </BackButton>
                  </Grid>
                </Grid>
              </Form>
            </div>
          </div>
        </Col>
      </Row>
    </Content>
  );
}
