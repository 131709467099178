export const Types = {
  NEW_REQUEST: "dashboardexterno/NEW_REQUEST",
  NEW_SUCCESS: "dashboardexterno/NEW_SUCCESS",
};

const INITIAL_STATE = {
  loading: false,
  data: {},
};

export default function dashboardexterno(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.NEW_REQUEST:
      return { ...state, loading: true };

    case Types.NEW_SUCCESS:
      return { ...state, loading: false, data: action.payload };

    default:
      return state;
  }
}

export const Creators = {
  newRequest: (payload) => ({
    type: Types.NEW_REQUEST,
    payload: payload,
  }),

  newSuccess: (payload) => ({
    type: Types.NEW_SUCCESS,
    payload: payload,
  }),
};
