import React from "react";

import { Col } from "react-bootstrap";

import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { MoreIcon } from "components/Icons";
import { ArrowUpIcon, ArrowDownIcon } from "components/Icons";

import "./styles.css";

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
    fontSize: 12,
  },
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
}

export default function DefaulPanel({
  size,
  titleComp,
  titleText,
  isCollapsible,
  children,
  collapse,
  setCollapse,
  actionMenu = null,
  handleOpenActionMenu = null
}) {
  return (
    <Col md={size} className="painel">
      <div className="painel-content">
        {titleComp && <>{titleComp}</>}
        {titleText && (
          <div className="painel-title text-left">
            {titleText}
            {isCollapsible && (
              <div>
                {!!actionMenu && (
                  <BootstrapTooltip
                    title={"Opções"}
                    placement="top"
                    className="mr-2"
                    onClick={(e) => handleOpenActionMenu(e)}
                  >
                    <IconButton
                      size="small"
                      color="primary"
                      aria-label={"Opções"}
                      component="span"
                    >
                      <MoreIcon />
                    </IconButton>
                  </BootstrapTooltip>
                )}

                <BootstrapTooltip
                  title={collapse ? "Expandir" : "Recolher"}
                  placement="top"
                >
                  <IconButton
                    size="small"
                    color="primary"
                    aria-label={collapse ? "Expandir" : "Recolher"}
                    component="span"
                    onClick={(e) => setCollapse(!collapse)}
                  >
                    {collapse ? <ArrowDownIcon /> : <ArrowUpIcon />}
                  </IconButton>
                </BootstrapTooltip>
              </div>
            )}
          </div>
        )}

        <div className={`painel-body ${collapse && "hide-panel"}`}>
          {children}
        </div>
      </div>
    </Col>
  );
}
