import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TablePagination,
  TableFooter,
} from "@material-ui/core";
import CustomTableSortLabel from "components/CustomTableSortLabel";
import StyledTableRow from "components/StyledTableRow";
import LoadingTable from "components/LoadingTable";
import { Link, useLocation } from "react-router-dom";

import ToolTipComponent from "components/Tooltip";

import ButtonSquare from "components/ButtonSquare";

import { Creators as TiposDocumentoActions } from "store/ducks/tiposdocumento";

import "./styles.css";

export default function ListTipoDocumento() {
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const {
    list: tiposdoc,
    loading,
    page = 1,
    limit = 10,
    total,
    sort = "nome",
  } = useSelector((state) => state.tipoDocumentos);
  const {
    access: { servicos, empresas },
  } = useSelector((state) => state.permissoes);

  const [request, setRequest] = useState({ page, limit, sort });
  const [userActions, setUserActions] = useState([]);

  useEffect(() => {
    dispatch(TiposDocumentoActions.listRequest({ empresas, ...request }));
  }, [dispatch, empresas, request]);

  useEffect(() => {
    let find = servicos.find((item) => item.route === pathname);
    if (find !== undefined) {
      setUserActions(find);
    }
  }, [pathname, servicos]);

  function handleChangeRowsPerPage({ target }) {
    const { value: limit } = target;
    setRequest({ ...request, limit, page: 1 });
  }

  function handleChangePage(e, newPage) {
    setRequest({ ...request, page: newPage + 1 });
  }

  function handleChangeSort(sort) {
    setRequest({ ...request, sort });
  }

  function handleDelete(item) {
    dispatch(TiposDocumentoActions.removeRequest(item._id));
  }

  return (
    <TableContainer className="table-tipo-documento-container">
      {(tiposdoc && tiposdoc.length > 0) || !!loading ? (
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                <CustomTableSortLabel
                  currentSort={request.sort}
                  sortField="nome"
                  onClick={handleChangeSort}
                >
                  Nome
                </CustomTableSortLabel>
              </TableCell>
              <TableCell>
                <CustomTableSortLabel
                  currentSort={request.sort}
                  sortField="departamento.empresa.fantasia"
                  onClick={handleChangeSort}
                >
                  Empresas
                </CustomTableSortLabel>
              </TableCell>
              {/* <TableCell align="center">Indexadores</TableCell> */}
              {userActions.actions &&
                userActions.actions.map(
                  (userAct, index) =>
                    userAct.action === "editar" && (
                      <TableCell align="center" key={index}>
                        Ações
                      </TableCell>
                    )
                )}
            </TableRow>
          </TableHead>
          <TableBody>
            {!!loading && <LoadingTable columns={5} />}
            {!loading &&
              tiposdoc &&
              tiposdoc.map((item) => (
                <StyledTableRow key={item._id}>
                  <TableCell>{item.nome}</TableCell>
                  <TableCell>
                    {item.empresas
                      .map((empresa) => empresa.fantasia)
                      .join(", ")}
                  </TableCell>
                  <TableCell align="center">
                    <div className="flex-direction">
                      <ToolTipComponent title="Indexadores" placement="top">
                        <Link
                          to={{
                            pathname:
                              "/configuracao/tiposdocumento/indexadores",
                            state: {
                              tipodoc: item,
                            },
                          }}
                        >
                          <ButtonSquare icon="playlist_add" />
                        </Link>
                      </ToolTipComponent>

                      <ToolTipComponent title="Redirecionamentos" placement="top">
                        <Link
                          to={{
                            pathname:
                              "/configuracao/tiposdocumento/redirecionamentos",
                            state: {
                              tipodoc: item,
                            },
                          }}
                        >
                          <ButtonSquare icon="move_down" />
                        </Link>
                      </ToolTipComponent>

                      <ToolTipComponent title="Definição SLA" placement="top">
                        <Link
                          to={{
                            pathname: "/configuracao/definicaosla",
                            state: {
                              tipodoc: item,
                            },
                          }}
                        >
                          <ButtonSquare icon="settings" />
                        </Link>
                      </ToolTipComponent>

                      {userActions.actions &&
                        userActions.actions.map(
                          (userAct, index) =>
                            userAct.action === "editar" && (
                              <ToolTipComponent
                                key={index}
                                title="Editar"
                                placement="top"
                              >
                                <Link
                                  to={{
                                    pathname:
                                      "/configuracao/tiposdocumento/edit",
                                    state: {
                                      tiposDocs: item,
                                    },
                                  }}
                                  className="linkicon"
                                  id="tiposdocs"
                                >
                                  <ButtonSquare icon="edit" />
                                </Link>
                              </ToolTipComponent>
                            )
                        )}

                      {/* <ToolTipComponent title="Remover" placement="top">
                        <div>
                          <ButtonSquare
                            onClick={() => handleDelete(item)}
                            icon="delete"
                          />
                        </div>
                      </ToolTipComponent> */}
                    </div>
                  </TableCell>
                </StyledTableRow>
              ))}
          </TableBody>
          {!loading && (
            <TableFooter>
              <TableRow>
                <TablePagination
                  count={total}
                  page={request.page - 1}
                  rowsPerPage={request.limit}
                  rowsPerPageOptions={[10, 25, 50, 100]}
                  labelRowsPerPage="Linhas por página"
                  labelDisplayedRows={({ from, to, count }) =>
                    `${from}-${to} de ${
                      count !== -1 ? count : `mais que ${to}`
                    }`
                  }
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  onPageChange={handleChangePage}
                />
              </TableRow>
            </TableFooter>
          )}
        </Table>
      ) : (
        <div className="table-tipo-documento-content">
          <span className="list__none">
            Não há tipos de documentos registrados.
          </span>
        </div>
      )}
    </TableContainer>
  );
}
