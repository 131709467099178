import styled from 'styled-components/macro';

export const Filter = styled.div`
  margin-bottom: 15px;
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 15px;

  button {
    margin-right: 15px;
  }
`;
