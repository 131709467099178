import React, { useEffect, useState } from 'react';
import { TextWriter } from '../StepTypesStyle';

import RichTextEditor from '../../Utils/Editor';

export default function TextStep({ settings, onChange }) {
  const onEditorStateChange = (content) => {
    onChange(settings, { content });
  };

  return (
    <TextWriter>
      <b>Escreva o texto desejado:</b>
      <RichTextEditor
        onEditorStateChange={onEditorStateChange}
        value={settings.content}
      />
    </TextWriter>
  );
}
