import api from "services/api";
import { getToken } from "services/auth";
import { call, select, put, all, takeLatest } from "redux-saga/effects";
import { toastr } from "react-redux-toastr";
import { push } from "connected-react-router";

import { Creators as GrupoEmailActions, Types } from "../ducks/grupoemail";

export const getOrganization = (state) => state.usuarios.auth.organization;
export const getUserId = (state) => state.usuarios.auth.id;

export function* listGrupoEmail({ payload }) {
  try {
    const { page = 1, limit = 10, search } = payload;

    const organization = yield select(getOrganization);

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
      params: { page, limit, search },
    };

    const response = yield call(api.get, `grupoemail`, headerParams);

    const data = {
      total: response.data.total,
      list: response.data.docs,
      total: response.data.total,
      limit: response.data.limit,
      page: response.data.page,
      pages: response.data.pages,
    };

    yield put(GrupoEmailActions.listSuccess(data));
  } catch (error) {
    toastr.error("Ops", "Não foi possível listar os grupos de e-mail.");
  }
}

export function* getGrupoEmail(action) {
  try {
    const headerParams = {
      headers: { Authorization: getToken() },
    };

    const { data } = yield call(
      api.get,
      `grupoemail/${action.payload}`,
      headerParams
    );

    yield put(GrupoEmailActions.getSuccess(data));
  } catch (error) {
    toastr.error("Ops", "Não foi possível buscar os dados do grupo de e-mail.");
  }
}

export function* editGrupoEmail(action) {
  const userId = yield select(getUserId);

  try {
    const organization = yield select(getOrganization);

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const body = { ...action.payload, id_user_acao: userId };

    const { data } = yield call(
      api.put,
      `grupoemail/${action.payload._id}`,
      body,
      headerParams
    );

    yield put(GrupoEmailActions.editSuccess(data));

    toastr.success("Grupo de e-mail editado com sucesso.");
  } catch (error) {
    toastr.error("Ops", "Não foi possível editar o grupo de e-mail.");
  }
}

export function* deleteGrupoEmail(action) {
  const { grupoemail } = action.payload;

  try {
    const organization = yield select(getOrganization);

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const response = yield call(
      api.delete,
      `grupoemail/${grupoemail._id}`,
      headerParams
    );

    if (response?.data?.error)
      throw new Error(
        response?.data?.message || "Não foi possível deletar o grupo de e-mail."
      );

    yield put(GrupoEmailActions.deleteSuccess());
    yield put(GrupoEmailActions.listRequest({}));

    toastr.success("Grupo de e-mail removido com sucesso.");

    yield put(push(`/grupoemail`));
  } catch (error) {
    toastr.error(error.message);

    yield put(GrupoEmailActions.deleteFailure());
  }
}

export function* createGrupoEmail(action) {
  const organization = yield select(getOrganization);

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const { data } = yield call(
      api.post,
      `grupoemail`,
      action.payload,
      headerParams
    );

    yield put(GrupoEmailActions.createSuccess(data));

    yield put(push(`/grupoemail`));

    toastr.success("", "Grupo de e-mail criado com sucesso.");
  } catch (error) {
    toastr.error("Ops", "Não foi possível criar o grupo de e-mail.");
  }
}

export function* deleteEmailDosGruposDeEmail({ payload: emails }) {
  const termo = `e-mail${emails.length ? "" : "s"}`;

  try {
    const organization = yield select(getOrganization);

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const { data } = yield call(
      api.post,
      `grupoemail/excluiremails`,
      emails,
      headerParams
    );

    if (data?.error)
      throw new Error(
        data?.message ||
          `Não foi possível deletar ${termo} dos grupos de e-mail.`
      );

    yield put(GrupoEmailActions.deleteMailSucess());

    const { data: response } = yield call(api.get, `grupoemail`, headerParams);

    const dataResponse = {
      total: response.total,
      list: response.docs,
      total: response.total,
      limit: response.limit,
      page: response.page,
      pages: response.pages,
    };

    yield put(GrupoEmailActions.listSuccess(dataResponse));

    toastr.success(
      data.message || "Grupos de e-mails atualizados com sucesso."
    );
  } catch (error) {
    toastr.error(error.message);

    yield put(GrupoEmailActions.deleteFailure());
  }
}

export function* addEmailDosGruposDeEmail({ payload: emails }) {
  const termo = `e-mail${emails.length ? "" : "s"}`;

  try {
    const organization = yield select(getOrganization);

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const { data } = yield call(
      api.post,
      `grupoemail/addemails`,
      emails,
      headerParams
    );

    if (data?.error)
      throw new Error(
        data?.message ||
          `Não foi possível adicionar ${termo} dos grupos de e-mail.`
      );

    yield put(GrupoEmailActions.addMailSucess());

    const { data: response } = yield call(api.get, `grupoemail`, headerParams);

    const dataResponse = {
      total: response.total,
      list: response.docs,
      total: response.total,
      limit: response.limit,
      page: response.page,
      pages: response.pages,
    };

    yield put(GrupoEmailActions.listSuccess(dataResponse));

    toastr.success(
      data.message || "Grupos de e-mails atualizados com sucesso."
    );
  } catch (error) {
    toastr.error(error.message);
  }
}
export default all([
  takeLatest(Types.LIST_REQUEST, listGrupoEmail),
  takeLatest(Types.GET_REQUEST, getGrupoEmail),
  takeLatest(Types.EDIT_REQUEST, editGrupoEmail),
  takeLatest(Types.NEW_REQUEST, createGrupoEmail),
]);
