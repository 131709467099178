import { select, call, put, all } from "redux-saga/effects";

import api from "../../services/api";
import { toastr } from "react-redux-toastr";
import { getToken } from "../../services/auth";

import { Creators as ChecklistdocumentoActions } from "../ducks/checklistdocumento";

export const getOrganization = (state) => state.usuarios.auth.organization;
export const getChecklistDocument = (state) => state.checklistdocumento.data;
export const getUsuario = (state) => state.usuarios.auth;

export function* getChecklistdocumento({ payload }) {
  try {
    const { documentId, filterStatusChecklist, filterStatus, filterTipo, search } = payload;

    const organization = yield select(getOrganization);

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    if (!documentId)
      return;

    const route = `checktipos/document/${documentId}`;
    const queryParams = [];

    if (filterStatusChecklist) queryParams.push(`statuschecklist=${filterStatusChecklist}`);
    if (filterStatus) queryParams.push(`status=${filterStatus}`);
    if (filterTipo) queryParams.push(`tipo=${filterTipo}`);
    if (search) queryParams.push(`search=${search}`);

    const queryString =
      queryParams.length > 0 ? `?${queryParams.join("&")}` : "";

    const { data } = yield call(api.get, route + queryString, headerParams);

    yield put(ChecklistdocumentoActions.getChecklistdocumentoSuccess(data));
  } catch (e) {
    toastr.error("Ops", "Não foi possível recuperar o checklist do documento.");
  }
}

export function* updateChecklistDocumento({ payload }) {
  try {
    const organization = yield select(getOrganization);
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const { documentId, itensModify } = payload;

    const { data } = yield call(
      api.post,
      `/checktipos/document/${documentId}`,
      itensModify,
      headerParams
    );

    yield put(ChecklistdocumentoActions.updateChecklistdocumentoSuccess(data));
    // yield put(ChecklistdocumentoActions.getChecklistdocumento(documentId));
  } catch (e) {
    toastr.error("Ops", "Não foi possível atualizar os itens de checklist.");
  }
}

export function* uploadInformation({ payload }) {
  try {
    const organization = yield select(getOrganization);
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const { documentId } = payload;

    const { data } = yield call(
      api.post,
      `/checklistdocumento/${documentId}`,
      payload,
      headerParams
    );

    yield put(ChecklistdocumentoActions.uploadInformationSuccess(data));
  } catch (e) {
    toastr.error("Ops", "Não foi possível atualizar o item de checklist.");
  }
}

export function* uploadImagemRecorrencia({ payload }) {
  try {
    const organization = yield select(getOrganization);
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const { data } = yield call(
      api.post,
      `/checktipo/upload/recorrencia`,
      payload,
      headerParams
    );

    toastr.success("Item de recorrência atualizado com sucesso");

    yield put(ChecklistdocumentoActions.uploadImageRecorrenciaSuccess(data));
  } catch (e) {
    toastr.error("Ops", "Não foi possível atualizar o item de recorrencia.");
  }
}

export function* syncChecklistdocumento({ payload }) {	
  try {
  const organization = yield select(getOrganization);
  const headerParams = {
    headers: { Authorization: getToken(), xkeyorg: organization },
  };

  const { id } = payload;

  const { data } = yield call(
    api.put,
    `/checklistdocumento/sync/${id}`,
    null,
    headerParams
  );

  toastr.success("Checklists sincronizados com sucesso");

  yield getChecklistdocumento({ payload: {
    documentId: id
  } });

  yield put(ChecklistdocumentoActions.syncChecklistdocumentoSuccess(data));
} 
catch (e) {
    toastr.error("Ops", "Não foi possível sincronizar os checklists.");
  }
}