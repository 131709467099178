import * as Yup from "yup";
import Locale from "./Locale";

Yup.setLocale(Locale);

const tipoTarefaThatNeedsHours = [
  '6054b761b6372f4d44067b5c', // id of Evento
  '5ffc917cdb70740186783ad1', // id of Audiência
];

const validateHour = (tipoTarefa, diaTodo) => {
  return diaTodo ? diaTodo : tipoTarefa && !tipoTarefaThatNeedsHours.includes(tipoTarefa);
}

export default Yup.object().shape({
  dataInicio: Yup.date().required('Campo obrigatório').nullable().typeError('Data inválida'),
  vencimento: Yup.date().required('Campo obrigatório').nullable().typeError('Data inválida'),
  comentario: Yup.string(),
  lembrete: Yup.string().nullable(),
  tipoTarefa: Yup.string().nullable().required(),
  documento: Yup.string().nullable().when('tipoTarefa', {
    is: value => value && value === '6054b761b6372f4d44067b5c', // id of Evento
    then: Yup.string().nullable().notRequired(),
    otherwise: Yup.string().required(),
  }),
  hour_start: Yup.string().nullable().when(['tipoTarefa', 'diaTodo'], {
    is: validateHour,
    then: Yup.string().nullable().notRequired(),
    otherwise: Yup.string().required(),
  }),
  hour_end: Yup.string().nullable().when(['tipoTarefa', 'diaTodo'], {
    is: validateHour,
    then: Yup.string().nullable().notRequired(),
    otherwise: Yup.string().required(),
  }),
  diaTodo: Yup.boolean().notRequired(),
  participantes: Yup.array().of(Yup.string()).required(),
  participantesExternos: Yup.array().of(Yup.string()),
  prioridade: Yup.string().required(),
  titulo: Yup.string().required(),
});
