import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';

import { Dialog, DialogContent } from '@material-ui/core';
import FormGenerico from 'pages/PesquisaAvancada/Tabs/FormGenerico';
import { Creators } from 'store/ducks/mail';
import EmailsList from 'components/EmailsList';

import './styles.css';

function Mails({ documento }) {
  const dispatch = useDispatch();
  const { emails: emailsObject } = useSelector((state) => state.email);
  const emails = Object.values(emailsObject ?? {}).flat();

  const [showEmailModal, setShowEmailModal] = useState(false);
  const [filters, setFilters] = useState({
    conteudo: '',
    emailEnviado: '',
    emailLido: '',
    remetente: '',
  });
  function handleFilterOptionsChange(field, value) {
    setFilters((prev) => ({
      ...prev,
      [field]: value,
    }));
  }

  function handleFilter(data, customFilters) {
    const filterFunctions = [];
    if (customFilters.emailEnviado !== '') {
      const filterByEmailEnviadoStatus = (email) =>
        email.emailEnviado === customFilters.emailEnviado;
      filterFunctions.push(filterByEmailEnviadoStatus);
    }

    if (customFilters.emailLido !== '') {
      const filterByEmailLidoStatus = (email) =>
        email.emailLido === customFilters.emailLido;
      filterFunctions.push(filterByEmailLidoStatus);
    }

    if (customFilters.remetente !== '') {
      const filterByRemetente = (email) =>
        email.remetente === customFilters.remetente;
      filterFunctions.push(filterByRemetente);
    }

    if (customFilters.conteudo !== '') {
      const filterByConteudo = (email) =>
        email.conteudo
          .toLowerCase()
          .includes(customFilters.conteudo.toLowerCase());
      filterFunctions.push(filterByConteudo);
    }

    const filterFunction = (item) =>
      filterFunctions.every((filter) => filter(item));

    return Array.from(data).filter(filterFunction);
  }

  function applyFilters(emails) {
    return handleFilter(emails, filters);
  }

  useEffect(() => {
    dispatch(Creators.listRequest(documento._id));
  }, [documento._id]);

  const remetenteEmails = [...new Set(emails?.map((email) => email.remetente))];

  return (
    <>
      <Row>
        <Col md="12">
          <div className="controlpanel-header">
            <div className="controlpanel-item comments-header-spacing">
              <TextField
                label="Pesquisar"
                variant="outlined"
                size="small"
                onChange={(e) =>
                  handleFilterOptionsChange('conteudo', e.target.value)
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Icon className="text-20" color="action">
                        search
                      </Icon>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="controlpanel-item comments-header-spacing">
              <TextField
                size="small"
                label="Remetente"
                id="select"
                variant="outlined"
                onChange={(e) =>
                  handleFilterOptionsChange('remetente', e.target.value)
                }
                select
              >
                <MenuItem value={''}>Todos</MenuItem>
                {remetenteEmails.map((remetente) => (
                  <MenuItem key={remetente} value={remetente}>
                    {remetente}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div className="controlpanel-item comments-header-spacing">
              <TextField
                size="small"
                label="Status envio"
                id="select"
                variant="outlined"
                onChange={(e) =>
                  handleFilterOptionsChange('emailEnviado', e.target.value)
                }
                select
              >
                <MenuItem value={''}>Todos</MenuItem>
                <MenuItem value={true}>Enviado Com Sucesso</MenuItem>
                <MenuItem value={false}>Aguardando</MenuItem>
              </TextField>
            </div>
            <div className="controlpanel-item comments-header-spacing">
              <TextField
                size="small"
                label="Status leitura"
                id="select"
                variant="outlined"
                onChange={(e) =>
                  handleFilterOptionsChange('emailLido', e.target.value)
                }
                select
              >
                <MenuItem value={''}>Todos</MenuItem>
                <MenuItem value={true}>Lidos</MenuItem>
                <MenuItem value={false}>Não lidos</MenuItem>
              </TextField>
            </div>
            <div className="controlpanel-item comments-header-spacing">
              <Button
                variant="contained"
                color="primary"
                onClick={() => setShowEmailModal((prev) => !prev)}
                disabled={!documento.status}
              >
                Novo e-mail
              </Button>
            </div>
          </div>
          <div className="border-top"></div>
          <div className="painel-content">
            <div
              className="painel-body"
              style={{ overflow: 'auto', maxHeight: '600px' }}
            >
              <EmailsList
                documento={documento._id}
                handleFilters={applyFilters}
              />
            </div>
          </div>
        </Col>
      </Row>

      <Dialog
        open={showEmailModal}
        onClose={() => {
          setShowEmailModal((prev) => !prev);
        }}
      >
        <DialogContent>
          <FormGenerico
            documento={documento}
            aoEnviar={() => {
              setShowEmailModal((prev) => !prev);
            }}
            opcao="E-mail"
            deveAplicarClasses={false}
            botao={
              <Button
                color="primary"
                variant="contained"
                onClick={() => setShowEmailModal((prev) => !prev)}
              >
                Cancelar
              </Button>
            }
          />
        </DialogContent>
      </Dialog>
    </>
  );
}

export { Mails };
