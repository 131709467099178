export const Types = {
  LIST_REQUEST: 'unidades/LIST_REQUEST',
  LIST_SUCCESS: 'unidades/LIST_SUCCESS',
  SAVE_REQUEST: 'unidades/SAVE_REQUEST',
  DELETE_REQUEST: 'unidades/DELETE_REQUEST'
}

const INITIAL_STATE = {
  list: [],
  loading: false,
  page: 1,
  limit: 10,
  total: 0,
  search: null,
  sort: 'descricao'
}

export default function unidades(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.LIST_REQUEST:
      return {
        ...state,
        ...action.payload,
        list: [],
        loading: true
      }

    case Types.LIST_SUCCESS:
      return { 
        ...state,
        total: action.payload.total,
        list: action.payload.list,
        loading: false
      }

    case Types.DELETE_REQUEST:
      return { ...state, loading: true };
    
    default:
      return state;
  }
}

export const Creators = {
  listRequest: params => ({
    type: Types.LIST_REQUEST,
    payload: params
  }),
  listSuccess: (list, total) => ({
    type: Types.LIST_SUCCESS,
    payload: { list, total }
  }),
  saveRequest: payload => ({
    type: Types.SAVE_REQUEST,
    payload
  }),
  deleteRequest: (_id) => ({
    type: Types.DELETE_REQUEST,
    payload: { _id },
  }),
}