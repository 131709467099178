import styled from 'styled-components/macro';

export const Container = styled.ul`
    margin: 0 auto;
    max-width: 1200px; 
    height: 100%;
    list-style: none;
`;

export const LoadingContainer = styled.div`
    display: ${({ loading }) => loading ? 'flex' : 'none' };
    width: 100%;
    min-height: 150px;
    align-items: center;
    justify-content: center;
`;