import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Row, Col } from "react-bootstrap";

import Button from "@material-ui/core/Button";
import Skeleton from "@material-ui/lab/Skeleton";

import Modal from "../../../../components/Modal";
import BPMViewer from "../../../../components/BPMViewer";

import { Creators as ChecklistActions } from "store/ducks/checktipo";
import { Creators as DocumentoActions } from "store/ducks/documentos";

export default function Etapas({
  onFilasChange,
  setSelectedEtapasNome,
  selectedEtapasNome,
  openEtapas,
  setOpenEtapas,
  isToResult = false,
  multiple = false,
  functionToResult,
}) {
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);
  const [taskDefintion, setTaskDefinition] = useState([]);
  const [endEvent, setEndEvent] = useState(false);
  const [SLA, setSLA] = useState([]);

  const {
    xml,
    loading: etapasLoading,
    count,
    idFila: fila,
  } = useSelector((state) => state.etapas);

  const {
    access: { empresas },
  } = useSelector((state) => state.permissoes);

  const { limit = 10, sort = "documentoAnalise.0.indexadores.0.valor" } =
    useSelector((state) => state.documentos);

  const handleSearch = () => {
    if (isToResult) functionToResult({}, taskDefintion || null);
    else
      dispatch(
        DocumentoActions.listRequestByEtapas({
          etapas: taskDefintion || fila,
          empresas,
          endEvent,
          sla: SLA,
          limit,
          page: 1,
          sort,
          fields:
            "documentoAnalise.indexadores,usuarioAcao,etapas,ultimaEtapa,dtUltimaEtapa,sla",
        })
      );

    setShow(false);
  };

  useEffect(() => {
    //etapasLoading
    if (openEtapas) {
      setShow(true);
    }
  }, [etapasLoading, openEtapas]);

  return (
    <Modal
      isOpen={show}
      title="Selecione as etapas desejadas:"
      onClose={setShow}
      className="modal-padding"
      size={800}
    >
      <Row>
        {etapasLoading ? (
          <>
            <Col md={12}>
              <Skeleton width="100%" height={300} />
            </Col>
          </>
        ) : (
          <BPMViewer
            xml={xml}
            count={count}
            setTaskDefinition={setTaskDefinition}
            setEndEvent={setEndEvent}
            fila={fila}
            multiple={multiple}
          />
        )}
      </Row>
      <Row>
        <Col md={12}>
          <div className="button-container">
            <Button
              variant="contained"
              color="primary"
              disabled={!taskDefintion}
              onClick={(e) => handleSearch()}
            >
              Buscar
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={(e) => {
                setShow(false);
                setSelectedEtapasNome([]);
                setOpenEtapas(false);
              }}
            >
              Cancelar
            </Button>
          </div>
        </Col>
      </Row>
    </Modal>
  );
}
