import styled from 'styled-components/macro';

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const TextWriter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
  width: 100%;

  .rdw-editor-wrapper {
    width: 100%;
  }

  b {
    margin-bottom: 5px;
  }

  textarea {
    width: 100%;
    min-height: 300px;
  }
`;

export const ImagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  b {
    margin-bottom: 5px;
  }

  textarea {
    width: 100%;
    min-height: 300px;
  }
`;

export const AutoCompleteSection = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;

export const TableImages = styled.div`
  width: 100%;

  table {
    width: 100%;
    border: 1px solid #eaeaea;
    border-collapse: collapse;

    th {
      border-bottom: 1px solid #eaeaea;
      padding: 10px 20px;
      text-transform: uppercase;
    }

    td {
      border-bottom: 1px solid #eaeaea;
      padding: 10px 20px;
    }
  }
`;

export const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  b {
    margin-bottom: 5px;
  }

  textarea {
    width: 100%;
    min-height: 300px;
  }
`;

export const ValidationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const ValidationItem = styled.div`
  flex: 1;
`;
