import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

import HistoricoComponente from "../../../../pages/Historico";

import { Creators as AuditoriaActions } from '../../../../store/ducks/auditoria';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
}));

export default function Historico({ documento }) {

  const classes = useStyles();
  const dispatch = useDispatch();
  const { loading } = useSelector(state => state.auditoria);

  useEffect(() => {
    if(documento) {
      const params = {
        idDocumento: documento._id,
      }
      dispatch(AuditoriaActions.listIdentificadorRequest(params));
    }
  }, [dispatch, documento])

  return (
    <div className={classes.root}>
      {loading ? <CircularProgress /> : <HistoricoComponente />}
    </div>
  );
}
