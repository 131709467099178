import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Creators as EmailsActions } from "store/ducks/mail";

import {
  Button,
  Container,
  Tooltip,
  Menu,
  IconButton,
  Dialog,
  DialogContent,
} from "@material-ui/core";
import { Badge, Col, Row } from "react-bootstrap";

import {
  MailboxContainer,
  MailboxSent,
  MailboxResponse,
  Modal,
} from "./styles/EmailList";
import Utils from "utils/utils";

import { Creators as DocumentosActions } from "../../store/ducks/documentos";
import { Creators as EmailActions } from "../../store/ducks/mail";

import FormEnviarEmail from "pages/Analise/components/Comentarios/FormEnviarEmail";
import { EmailExport } from "components/EmailExport";
import { ArrowDownIcon, ArrowUpIcon, MailReadedIcon, MailUndeliveredIcon, MailUnreadedIcon, SendIcon, PreviewIcon, CloseIcon, AttachFileIcon } from "components/Icons";

const iconStyle = { margin: "14px", padding: "2px" };
const emailTipoEnvio = "ENVIO";
const util = new Utils();

function displayDataHora(dataEnvio, horaEnvio) {
  return `${util.formatISODate(
    dataEnvio?.toString().substring(0, 10)
  )} às ${horaEnvio}`;
}

export function DisplayConteudo({
  conteudo,
  height = 160,
  width = 1024,
  zoom,
  pointerEvents = "none",
  frameBorder = 0,
}) {
  return (
    <iframe
      title="display"
      width={width}
      height={height}
      src={`data:text/html;charset=utf-8,${conteudo}`}
      frameBorder={frameBorder}
      display="flex"
      whiteSpace="break-spaces"
      style={{
        zIndex: -1,
        pointerEvents,
        padding: "0px",
        margin: "0px",
        whiteSpace: "normal",
        zoom,
        width: "100%",
      }}
    />
  );
}

function Display({ info }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
        }}
      >
        <div style={{ width: "100%" }}>{info}</div>
      </div>
    </div>
  );
}

function AgrupamentoEmails({ emails = [], onClick, emailKey }) {
  return emails?.map((email, index) => (
    <MailboxContainer onClick={() => onClick({ ...email, emailKey })}>
      {email.tipo === emailTipoEnvio ? (
        <EmailEnvio email={email} index={index} />
      ) : (
        <MailContent email={email} />
      )}
    </MailboxContainer>
  ));
}

function DetailsView({ onClose, onClick, open, anchorEl, details }) {
  const { remetente, destinatarios, cc } = details;
  const style = { display: "flex", flexDirection: "column", alignItems: "end" };
  const lista = (str) =>
    util.handlePastedEmails(str).map((item) => <Row key={item}>{item}</Row>);
  return (
    <Menu
      id="details"
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      onClick={onClick}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
    >
      <Container style={{ padding: "32px", width: "600px", height: "100%" }}>
        <Row>
          <Col>
            <b>Enviado por:</b>
          </Col>
          <Col style={style}>{lista(remetente)}</Col>
        </Row>
        <Row>
          <Col>
            <b>Enviado para:</b>
          </Col>
          <Col style={style}>{lista(destinatarios)}</Col>
        </Row>
        {cc && (
          <Row>
            <Col>
              <b>Com cópia para:</b>
            </Col>
            <Col style={style}>{lista(cc)}</Col>
          </Row>
        )}
      </Container>
    </Menu>
  );
}

function HeaderEmail({
  style,
  visibleIcons = false,
  email,
  onViewManifestacao,
  onSendMail,
}) {
  const { destinatarios, cc, assunto, remetente, tipo, dataEnvio, horaEnvio, emailEnviado } =
    email;
  const dataHora = displayDataHora(dataEnvio, horaEnvio);
  const styleIcons = { color: "#7EACDF" };
  const styleActions = { marginLeft: "12px", padding: "2px" };
  const styleDisplay = {
    display: "flex",
    flexDirection: "column",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "100%",
    fontSize: "12px",
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => setAnchorEl(open ? null : event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const formatDisplay = (str) =>
    util.handlePastedEmails(str).toString().replace(/,/g, ", ");

  return (
    <div style={style}>
      <Row>
        <Display
          info={
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "baseline",
                  justifyContent: "space-between",
                  padding: "8px 0px",
                  width: "100%",
                  color: emailEnviado ? "#4f4f4f" : "red"
                }}
              >
                <b>
                  <h5>{assunto}</h5>
                </b>
                {visibleIcons && (
                  <div>
                    <Tooltip title="responder e-mail">
                      <IconButton
                        size="medium"
                        onClick={onSendMail}
                        style={styleActions}
                      >
                        <SendIcon fontSize="medium" style={styleIcons} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="visualizar prontuário">
                      <IconButton
                        size="medium"
                        onClick={onViewManifestacao}
                        style={styleActions}
                      >
                        <PreviewIcon fontSize="medium" style={styleIcons} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="mais opções">
                      <EmailExport email={email} styleIcons={styleIcons} />
                    </Tooltip>
                  </div>
                )}
              </div>
            </div>
          }
        />
        <Display info={<div style={styleDisplay}>{dataHora}</div>} />
        <Display
          info={
            <div style={styleDisplay}>
              {`Enviado por ${formatDisplay(remetente)}`}
            </div>
          }
        />
        {tipo === emailTipoEnvio ? (
          <Display
            info={
              <div style={{ ...styleDisplay, flexDirection: "row" }}>
                <div style={{ maxWidth: "98%", overflow: "hidden", wordWrap: "break-word" }}>
                  {`Enviado para ${formatDisplay(destinatarios)}`}
                </div>
                {visibleIcons && (
                  <Tooltip title="ver detalhes">
                    <IconButton
                      size="small"
                      aria-controls={open ? "details" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                    >
                      <ArrowDownIcon onClick={handleClick} />
                    </IconButton>
                  </Tooltip>
                )}
              </div>
            }
          />
        ) : null}
      </Row>

      <Row>
        <div
          style={{
            marginBottom: "16px",
            color: "transparent",
          }}
        >
          .
        </div>
      </Row>

      <DetailsView
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        onClick={handleClick}
        details={{ remetente, destinatarios, cc }}
      />
    </div>
  );
}

function CardEmailEnviado({
  email,
  visibleIcons,
  height,
  width = "100%",
  zoom,
  scroll,
  onViewManifestacao,
  onSendMail,
  pointerEvents = "none"
}) {
  const { conteudo, cc } = email;
  return (
    <>
      <HeaderEmail
        visibleIcons={visibleIcons}
        email={email}
        style={{ maxWidth: "1160px" }}
        onViewManifestacao={onViewManifestacao}
        onSendMail={onSendMail}
      />
      <DisplayConteudo
        conteudo={conteudo}
        height={height || cc ? 450 : 470}
        width={width}
        zoom={zoom}
        scroll={scroll}
        pointerEvents={pointerEvents}
      />
    </>
  );
}

function CardEmailRecebido({
  email,
  visibleIcons,
  height,
  width,
  onViewManifestacao,
  onSendMail,
  zoom,
  scroll,
  pointerEvents = "none"
}) {
  const { conteudo } = email;
  return (
    <>
      <HeaderEmail
        visibleIcons={visibleIcons}
        email={email}
        style={{ maxWidth: "1100px" }}
        onViewManifestacao={onViewManifestacao}
        onSendMail={onSendMail}
      />
      <DisplayConteudo
        conteudo={conteudo}
        height={height}
        width={width}
        zoom={zoom}
        scroll={scroll}
        pointerEvents={pointerEvents}
      />
    </>
  );
}

function FooterMail({
  onClose,
  onClick,
  anexos = [],
  deveMostrarOpcaoEscalonamentoManual = false,
  email,
  showButtons = true,
}) {
  const dispatch = useDispatch();

  const [hasAlreadyForcedScalling, setHasAlreadyForcedScalling] =
    useState(false);

  const onForceScallingButtonClick = () => {
    dispatch(EmailsActions.forceScallingRequest(email.documento));
    setHasAlreadyForcedScalling((prev) => !prev);
    dispatch(EmailsActions.listRequest(email.documento));
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        padding: "0px",
        margin: "0px",
        justifyContent: "space-between",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {anexos.length > 0 && (
          <>
            <b>Anexos</b>
            <Col>
              {anexos.map((anexo, idx) => (
                <div key={anexo} style={{ padding: "4px", cursor: "pointer" }}>
                  <Badge variant="none" onClick={() => onClick(anexo)}>
                    <AttachFileIcon style={{ marginRight: "8px" }} />
                    {`Anexo ${idx + 1}`}
                  </Badge>
                </div>
              ))}
            </Col>
          </>
        )}
      </div>

      {showButtons && (
        <div>
          {deveMostrarOpcaoEscalonamentoManual && (
            <Button
              variant="contained"
              color="secondary"
              disabled={hasAlreadyForcedScalling}
              onClick={onForceScallingButtonClick}
              style={{ marginRight: "12px" }}
            >
              Forçar Escalonamento
            </Button>
          )}

          <IconButton variant="contained" color="secondary" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
      )}
    </div>
  );
}

function MailContent({ email }) {
  const { emailLido } = email;
  return (
    <MailboxResponse>
      <div>
        {
          emailLido
            ? (<MailReadedIcon style={iconStyle} />)
            : (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <MailUnreadedIcon style={iconStyle} />
                <Badge variant="warning">Não lido</Badge>
              </div>
            )
        }
      </div>
      <Col>
        <CardEmailRecebido email={email} height={175} />
      </Col>
    </MailboxResponse>
  );
}

function EmailEnvio({ email }) {
  const { emailEnviado } = email;
  return (
    <MailboxSent>
      <div
        style={{
          flexDirection: "column",
          display: "flex",
          alignItems: "center",
          alignContent: "center",
        }}
      >
        {
          emailEnviado ?
            <SendIcon style={iconStyle} />
            :
            <MailUndeliveredIcon style={{ ...iconStyle, color: "red" }} />
        }
        {email?.nivelEscalonamento > 0 && email?.departamento ? (
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
              alignContent: "center",
            }}
          >
            <ArrowUpIcon
              fontSize="large"
              color="secondary"
              style={{ paddingLeft: "4px", margin: "-7px" }}
            />
            <Badge color="secondary" overlap="rectangular">
              {email?.nivelEscalonamento || 0}
            </Badge>
          </div>
        ) : (
          <></>
        )}
      </div>
      <Col>
        <CardEmailEnviado email={email} />
      </Col>
    </MailboxSent>
  );
}

export function ViewMail({
  email,
  onClose,
  onClick,
  agrupamento,
  zoom = null,
  width = 988,
  height = null,
  scroll = null,
  showButtons = true,
  showIcons = true,
  pointerEvents = "none"
}) {
  const { anexos, tipo, emailKey, documento: doc, emailEnviado } = email;
  const { documento } = useSelector((state) => state.documentos);

  const possuiEmailDeRespota = agrupamento[emailKey]?.some(
    (email) => email.tipo === "RESPOSTA"
  );

  const agrupamentoKeys = Object.keys(agrupamento);

  const eOUltimoEmail =
    agrupamentoKeys[agrupamentoKeys.length - 1] === email.emailKey;

  const deveMostrarOpcaoEscalonamentoManual =
    tipo === emailTipoEnvio &&
    eOUltimoEmail &&
    email.departamento &&
    !possuiEmailDeRespota;

  const [selectedId, setSelectedId] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    let docId = doc || documento?._id;
    setSelectedId(docId.toString());
  }, [documento, doc]);

  useEffect(() => {
    if (selectedId.length === 0) return;
    if (!doc || doc === documento?._id) return; // foi chamado da tela de subsídios e o documento já foi carregado
    dispatch(DocumentosActions.getDocumentoRequest(selectedId));
  }, [dispatch, selectedId]);

  const visualizarManifestacao = () => {
    dispatch(
      DocumentosActions.sendToSearchPageRequest(
        [selectedId],
        {
          limit: 10,
          page: 1,
          sort: "-createdAt",
          searchText: "",
        },
        false,
        true
      )
    );
  };

  const [showEmailModal, setShowEmailModal] = useState(false);
  const [sendAgain, setSendAgain] = useState(true);
  const onShowEmailModal = () => setShowEmailModal((prev) => !prev);
  const enviarEmail = () => setShowEmailModal(true);
  const md = emailEnviado || !sendAgain ? null : 9;

  const resenderMail = () => {
    const { _id } = email;
    dispatch(EmailActions.resendingEmailRequest(_id));
    setSendAgain(false);
  }

  return (
    <Container
      height={tipo === emailTipoEnvio ? "600px" : null}
      style={{
        width: "100%",
        overflowWrap: "break-word",
        overflowX: "hidden",
        height: "100%",
      }}
    >
      {tipo === emailTipoEnvio ? (
        <CardEmailEnviado
          visibleIcons={showIcons}
          email={email}
          width={width}
          height={height}
          zoom={zoom}
          scroll={scroll}
          onViewManifestacao={visualizarManifestacao}
          onSendMail={enviarEmail}
          pointerEvents={pointerEvents}
        />
      ) : (
        <CardEmailRecebido
          visibleIcons={showIcons}
          email={email}
          height={height || 600}
          width={width}
          zoom={zoom}
          scroll={scroll}
          onViewManifestacao={visualizarManifestacao}
          onSendMail={enviarEmail}
          pointerEvents={pointerEvents}
        />
      )}
      <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}>
        {tipo === emailTipoEnvio
          && !emailEnviado
          && sendAgain
          &&
          <Col>
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              endIcon={<SendIcon />}
              onClick={resenderMail}
            >
              Reenviar
            </Button>
          </Col>
        }
        <Col md={md}>
          <FooterMail
            onClose={onClose}
            onClick={onClick}
            anexos={anexos}
            email={email}
            showButtons={showButtons}
            deveMostrarOpcaoEscalonamentoManual={deveMostrarOpcaoEscalonamentoManual}
          />
        </Col>
      </div>

      <Dialog open={showEmailModal} onClose={onShowEmailModal}>
        <DialogContent>
          <FormEnviarEmail
            documento={documento}
            email={email}
            aoEnviar={onShowEmailModal}
          >
            {
              <Button
                color="primary"
                variant="contained"
                onClick={onShowEmailModal}
              >
                Cancelar
              </Button>
            }
          </FormEnviarEmail>
        </DialogContent>
      </Dialog>
    </Container>
  );
}

export function ViewAnexo({ url, onClose }) {
  const height = "600px";
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        padding: "8px",
        height,
      }}
    >
      <iframe
        src={url}
        title="anexo"
        onClick={onClose}
        height={height}
        width={"800em"}
      />
      <FooterMail onClose={onClose} />
    </div>
  );
}

function EmailsList({ documento, handleFilters = () => { } }) {
  const dispatch = useDispatch();
  const [agrupamento, setAgrupamento] = useState({});
  const [open, setOpen] = useState(false);
  const [view, setView] = useState(null);
  const [email, setEmail] = useState({});

  const { emails } = useSelector((state) => state.email);

  useEffect(() => {
    setAgrupamento(emails);
  }, [emails]);
  useEffect(() => {
    if (open) dispatch(EmailsActions.listRequest(documento));
  }, [email, open]);

  function onClick(email) {
    const idEmail = email?._id;
    setEmail(email);
    setOpen(true);
    if (!email.emailLido) dispatch(EmailsActions.readMailRequest(idEmail));
  }

  function onClose() {
    closeView();
    setOpen(false);
  }

  function closeView() {
    setView(null);
  }

  async function onClickAnexo(anexo) {
    if (!anexo) return;
    const link = await util.signedUrl(anexo);
    setView(link);
  }

  return (
    <div
      style={{
        width: "100%",
        minWidth: "1024px",
        maxWidth: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {agrupamento &&
        Object.keys(agrupamento).map((key) => (
          <AgrupamentoEmails
            emails={handleFilters(agrupamento[key])}
            onClick={onClick}
            emailKey={key}
          />
        ))}
      <Modal open={open} onClose={onClose} scroll="paper">
        {view ? (
          <ViewAnexo url={view} onClose={closeView} />
        ) : (
          <ViewMail
            agrupamento={agrupamento}
            email={email}
            onClose={onClose}
            onClick={onClickAnexo}
          />
        )}
      </Modal>
    </div>
  );
}

export default EmailsList;
