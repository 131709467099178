import * as Yup from "yup";
import Locale from "./Locale";

Yup.setLocale(Locale);

const getHourDate = (hours) => {
  const horario = new Date();
  const [horas, minutos] = hours.split(":");
  horario.setHours(Number(horas), Number(minutos));

  return horario;
};

export default Yup.object().shape({
  nome: Yup.string().required(),
  tipoDepartamento: Yup.string()
    .required("Campo Obrigatório")
    .typeError("Campo obrigatório"),
  prazo: Yup.string().required(),
  maximoEncaminhamento: Yup.string().required(),
  empresa: Yup.string()
    .required("Campo Obrigatório")
    .typeError("Campo obrigatório"),
  horarioInicialDaOperacao: Yup.string()
    .test({
      name: "menor",
      message: "Horário inicial da operação deve ser menor do que o horário final",
      test: function (value) {
        const { horarioFinalDaOperacao: horarioFinalString } = this.parent;

        const horarioFinal = getHourDate(horarioFinalString);
        const horarioInicial = getHourDate(value);
        return horarioInicial < horarioFinal;
      },
    })
    .required(),
  horarioFinalDaOperacao: Yup.string()
    .test({
      name: "maior",
      message: "Horário final da operação deve ser maior do que o horário incial",
      test: function (value) {
        const { horarioInicialDaOperacao: horarioInicialString } = this.parent;

        const horarioInicial = getHourDate(horarioInicialString);
        const horarioFinal = getHourDate(value);
        return horarioInicial < horarioFinal;
      },
    })
    .required(),
});
