import React, { useMemo, useEffect, useState } from 'react';
import {
  Table, TableHead, TableBody, TableRow, TableCell,
  TableContainer, TableFooter, TablePagination,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from "react-router-dom";

import StyledTableRow from 'components/StyledTableRow';
import LoadingTable from 'components/LoadingTable';
import CustomTableSortLabel from "components/CustomTableSortLabel";
import ButtonSquare from "components/ButtonSquare";
import ToolTipComponent from "components/Tooltip";

import { Creators as UnidadesActions } from 'store/ducks/unidades';

function TableUnidades({ userActions }) {
  const dispatch = useDispatch();

  const {
    loading, list: unidades, page, limit, total, sort, search
  } = useSelector(state => state.unidades);

  const [params, setParams] = useState({ page, limit, sort, search })

  const hasActions = useMemo(
    () =>
      true || (
      userActions.actions &&
      userActions.actions.some((userAct) => userAct.action === 'editar' || userAct.action === 'criar')),
    [userActions]
  );

  useEffect(() => {
    dispatch(UnidadesActions.listRequest(params))
  }, [dispatch, params]);

  function handleChangeRowsPerPage({ target }) {
    const { value } = target;
    setParams({ ...params, limit: value });
  }

  function handleChangePage(e, newPage) {
    setParams({ ...params, page: newPage + 1 });
  }

  function handleChangeSort(sort) {
    setParams({ ...params, sort });
  }

  function handleRemove(_id) {
    dispatch(UnidadesActions.deleteRequest(_id))
  }
  
  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>
              <CustomTableSortLabel
                onClick={handleChangeSort}
                currentSort={params.sort}
                sortField="descricao"
              >
                Descrição
              </CustomTableSortLabel>
            </TableCell>
            <TableCell>
              <CustomTableSortLabel
                onClick={handleChangeSort}
                currentSort={params.sort}
                sortField="tipo"
              >
                Tipo
              </CustomTableSortLabel>
            </TableCell>
            <TableCell>
              <CustomTableSortLabel
                onClick={handleChangeSort}
                currentSort={params.sort}
                sortField="centroDeCusto"
              >
                Centro de Custo
              </CustomTableSortLabel>
            </TableCell>
            <TableCell>
              <CustomTableSortLabel
                onClick={handleChangeSort}
                currentSort={params.email}
                sortField="email"
              >
                Email
              </CustomTableSortLabel>
            </TableCell>
            {!!hasActions &&
            <TableCell align="center">
              Ações
            </TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {!!loading && <LoadingTable rows={limit} columns={!!hasActions ? 6 : 5} />}
          {!loading && 
          unidades.map(unidade => 
            <StyledTableRow key={unidade._id}>
              <TableCell>{unidade.descricao}</TableCell>
              <TableCell>{unidade.tipo}</TableCell>
              <TableCell>{unidade.centroDeCusto}</TableCell>
              <TableCell>{unidade.email}</TableCell>
              {!!hasActions &&
              <TableCell align="center">
                <div className="flex-direction">
                  <ToolTipComponent title="Editar" placement="top">
                    <Link
                      to={{
                        pathname: "unidades/edit",
                        state: { unidade },
                      }}
                    >
                      <ButtonSquare icon="edit" />
                    </Link>
                  </ToolTipComponent>
                  <ToolTipComponent title="Remover" placement="top">
                    <div>
                      <ButtonSquare icon="delete" onClick={() => handleRemove(unidade._id)} />
                    </div>
                  </ToolTipComponent>
                </div>
              </TableCell>}
            </StyledTableRow>
          )}
        </TableBody>
        {!loading &&
        <TableFooter>
          <TableRow>
            <TablePagination
              count={total}
              page={page - 1}
              rowsPerPage={limit}
              rowsPerPageOptions={[10, 25, 50, 100]}
              labelRowsPerPage="Linhas por página"
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} de ${count !== -1 ? count : `mais que ${to}`}`
              }
              onRowsPerPageChange={handleChangeRowsPerPage}
              onPageChange={handleChangePage}
            />
          </TableRow>
        </TableFooter>}
      </Table>
    </TableContainer>
  );
}

export default TableUnidades;