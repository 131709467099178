import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CircularProgress from '@material-ui/core/CircularProgress';

import {
    Container,
    LoaderContainer,
} from './styles/ModalTaskDetailsActions';

import CustomButtonIcon from '../CustomButtonIcon';
import ModalTaskImages from "components/ModalTaskImages";
import ModalUploadFiles from 'components/SelectFilesToUpload';

import { Creators as ProvidenciasActions } from 'store/ducks/providencias';
import { Creators as ProvidenciaActions } from 'store/ducks/providencia';
import { BackupIcon, CheckIcon, DeleteForeverIcon, DeleteIcon, ImageIcon } from 'components/Icons';

function ModalTaskDetailsActions({ handleClose }) {
    const dispatch = useDispatch();

    const {
        openModalImages,
        openModalEditTask,
        selectedTask: task,
    } = useSelector(state => state.providencias);
    const {
        loadingTaskApproval, 
        openUpload: openModalUpload,
        loading: loadingFromProvidencia,
    } = useSelector(state => state.providencia);
    const { id: loggedUserId } = useSelector(state => state.usuarios.auth);

    const { participantes, haveImages } = task;

    const [confirmDelete, setConfirmDelete] = useState(false);

    function handleApproveTask() {
        dispatch(ProvidenciaActions.taskApprovalRequest(task));
    }

    function handleEditTask() {
        dispatch(ProvidenciaActions.edit(task));
    }

    function handleOpenUploadDocuments() {
        dispatch(ProvidenciaActions.openUpload(task));
    }

    function handleCloseModalUpload() {
        dispatch(ProvidenciaActions.closeUpload());
    }

    function handleOpenModalImages() {
        dispatch(ProvidenciasActions.handleModalImages(true));
    }

    function handleCloseModalImages() {
        dispatch(ProvidenciasActions.handleModalImages(false));
    }

    function handleConfirmDelete() {
        setConfirmDelete(true);
        setTimeout(() => {
            setConfirmDelete(false);
        }, 3000);
    }

    function handleDeleteTask() {
        dispatch(ProvidenciaActions.deleteRequest(task._id));
        handleClose();
    }

    function handleUpload(filesToUpload) {
        const formData = new FormData();

        filesToUpload.forEach((fileToUpload) => {
            formData.append("imagem", fileToUpload.file);
            formData.append("nome", fileToUpload.nome);
        });

        dispatch(ProvidenciaActions.uploadRequest(formData));
    }

    // turned this into a function because this code will get complex later
    function userIsResponsibleForTaskApproval(participantes, loggedUserId) {
        if (participantes && participantes.length > 0) {
            const isUserAuthorized = participantes.some(participante => participante._id === loggedUserId);
            return isUserAuthorized;
        }
        return false;
    }    

    return (
        <>
            <Container>

                {loadingTaskApproval
                ? <LoaderContainer><CircularProgress size={16} /></LoaderContainer>
                : !task.aprovado
                ? <CustomButtonIcon
                    disabled={!userIsResponsibleForTaskApproval(participantes, loggedUserId)}
                    onClick={handleApproveTask}
                    Icon={<CheckIcon />}
                    tooltip="Encerrar Tarefa" />
                : null}

                <CustomButtonIcon
                    onClick={handleOpenUploadDocuments}
                    Icon={<BackupIcon />}
                    tooltip="Upload de documentos" />
                <CustomButtonIcon
                    onClick={handleOpenModalImages}
                    disabled={!haveImages}
                    Icon={<ImageIcon />}
                    tooltip="Visualizar documentos" />

                {!confirmDelete ? (
                    <CustomButtonIcon
                        onClick={handleConfirmDelete}
                        Icon={<DeleteIcon />}
                        tooltip="Excluir tarefa" />
                ) : (
                    <CustomButtonIcon
                        Icon={<DeleteForeverIcon />}
                        onClick={handleDeleteTask}
                        iconbackground="#faaab6"
                        iconcolor="#e03f57"
                        progresscolor="#e03f57"
                        progress
                        tooltip="Clique novamente para confirmar" />
                )}
            </Container>
            {openModalImages &&
                <ModalTaskImages
                    open={openModalImages}
                    handleClose={handleCloseModalImages} />}
            {openModalUpload && 
                <ModalUploadFiles
                    open={openModalUpload}
                    onClose={handleCloseModalUpload}
                    onUpload={handleUpload}
                    loading={loadingFromProvidencia}
                    defaultName="Documento"
                    names={["Documento"]} />}
        </>
    );
}

export default ModalTaskDetailsActions;