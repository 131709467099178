import React from 'react';
import history from 'routes/history';

class ErrorBoundary extends React.Component {

  static getDerivedStateFromError(error, errorInfo) {
    history.push('/ops');
  }

  render() {
    return this.props.children;
  }
    
}

export default ErrorBoundary;
