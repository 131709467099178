import React from 'react';
import MaskedInput from 'react-text-mask';

function CPFMask({ inputRef, ...other }) {
  return (
    <MaskedInput
      {...other}
      ref={ref => inputRef(ref ? ref.inputElement : null)}
      mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
    />
  );
}

export default CPFMask;