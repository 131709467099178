import React from "react";

import { toastr } from "react-redux-toastr";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  TableContainer,
  TablePagination,
} from "@material-ui/core";

import StyledTableRow from "components/StyledTableRow";
import ButtonSquare from "components/ButtonSquare";
import LoadingTable from "components/LoadingTable";
import Utils from "utils/utils";

function TableResults({
  loading,
  docs,
  total,
  page,
  limit,
  handleChangeRowsPerPage,
  handleChangePage,
}) {
  const util = new Utils();

  async function loadAttachment(anexo) {
    if (!anexo) return;
    const link = await util.signedUrl(anexo);
    navigator.clipboard.writeText(link);
    toastr.info("", "Link de acesso copiado em segundo plano");
  }

  async function downloadAttachments(anexo) {
    if (!anexo) return;
    const link = await util.signedUrl(anexo);
    
    const a = document.createElement('a');
    a.href = link;
    a.download = anexo;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  const formatNumerosCNJ = (item) => {

    if (!item.numerosCNJ || item.numerosCNJ.length === 0) {
      return item.arquivo;
    }
  
    const displayedCNJs = item.numerosCNJ.slice(0, 10).join(', ');
    if (item.numerosCNJ.length > 10) {
      return `${displayedCNJs}...`;
    }
  
    return displayedCNJs;
  };

  return (
    <>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Arquivo</TableCell>
              <TableCell>Tipo</TableCell>
              <TableCell>Data/Hora</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!!loading && <LoadingTable rows={10} columns={4} />}
            {!loading &&
              docs?.map((item) => (
                <StyledTableRow key={item._id}>
                  <TableCell>{item.numerosCNJ ? formatNumerosCNJ(item) : item.arquivo}</TableCell>
                  <TableCell>{item.tipo}</TableCell>
                  <TableCell>{item.createdAt}</TableCell>
                  <TableCell align="center">
                    <div className="flex-direction">
                      <ButtonSquare
                        onClick={() => downloadAttachments(item.arquivo)}
                        icon="backup"
                      />
                    </div>
                  </TableCell>
                </StyledTableRow>
              ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                count={total || 0}
                page={page - 1 || 0}
                rowsPerPage={limit || 10}
                rowsPerPageOptions={[10, 25, 50, 100]}
                labelRowsPerPage="Linhas por página"
                labelDisplayedRows={({ from, to, count }) =>
                  `${from}-${to} de ${count !== -1 ? count : `mais que ${to}`}`
                }
                onRowsPerPageChange={handleChangeRowsPerPage}
                onPageChange={handleChangePage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
}

export default TableResults;
