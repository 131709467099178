import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Button } from "@material-ui/core";
import { Draggable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";

import { Chip } from "./styles/ChipsArray";
import Tooltip from "../../../components/Tooltip";
import ModalDeleteConfirmation from "../ModalDeleteConfirmation";

import { Creators as TemplateActions } from "../../../store/ducks/template";
import { AddIcon } from "components/Icons";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    flexDirection: "column",
    listStyle: "none",
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
    width: "100%",
    fontSize: "14px",
    "& .MuiChip-label": {
      marginRight: "auto",
    },
  },
  addButton: {
    color: "#fff !important",
    backgroundColor: "#7f7f7f",
    display: "flex",
    marginLeft: "auto",
    marginTop: "5px",
    "&:hover": {
      backgroundColor: "#7f7f7f !important",
    },
  },
}));

function ChipsArray() {
  const { paragraph, templateSelected } = useSelector(
    (state) => state.template
  );
  const classes = useStyles();
  const dispatch = useDispatch();
  const [modalConfirmDeletionOpen, setModalConfirmDeletionOpen] =
    useState(false);
  const [selectedItemDelete, setSelectedItemDelete] = useState(null);

  function handleCreateParagraph() {
    dispatch(TemplateActions.showModal({ modal: "PARAGRAPH", show: true }));
  }

  function tooltipText(text) {
    return `${text.replace(/<[^>]*>/g, "").substring(0, 120)}...`;
  }

  function handleCloseConfirmationModal() {
    setModalConfirmDeletionOpen(false);
  }

  function handleDelete(item) {
    setModalConfirmDeletionOpen(true);
    setSelectedItemDelete(item);
  }

  return (
    <>
      <Paper component="ul" className={classes.root}>
        {paragraph &&
          paragraph.map((item, index) => (
            <Draggable key={item.id} draggableId={item.id} index={index}>
              {(provided, snapshot) => (
                <>
                  <li
                    ref={provided.innerRef}
                    key={item.id}
                    {...provided.dragHandleProps}
                    {...provided.draggableProps}
                    isDragging={snapshot.isDragging}
                  >
                    <Tooltip
                      placement="top"
                      title={tooltipText(item.paragraph)}
                    >
                      <Chip
                        label={item.title}
                        onDelete={() => handleDelete(item)}
                        className={classes.chip}
                      />
                    </Tooltip>
                    {provided.placeholder}
                  </li>
                  {snapshot.isDragging && (
                    <Chip
                      label={item.title}
                      onDelete={() => handleDelete(item)}
                      className={classes.chip}
                    />
                  )}
                </>
              )}
            </Draggable>
          ))}
        {/* <li>
          <Button
            style={{ borderRadius: "50px" }}
            className={classes.addButton}
            onClick={handleCreateParagraph}
          >
            <AddIcon style={{ marginRight: "5px" }} />
            Adicionar
          </Button>
        </li> */}
      </Paper>
      <ModalDeleteConfirmation
        item={selectedItemDelete}
        open={modalConfirmDeletionOpen}
        handleClose={handleCloseConfirmationModal}
      />
    </>
  );
}

export default ChipsArray;
