export const Types = {
  LIST_REQUEST: "acessosrpa/LIST_REQUEST",
  LIST_SUCCESS: "acessosrpa/LIST_SUCCESS",
  LIST_ERROR: "acessosrpa/LIST_ERROR",
  SAVE_REQUEST: "acessosrpa/SAVE_REQUEST",
  SAVE_ERROR: "acessosrpa/SAVE_ERROR",
  DELETE_REQUEST: "acessosrpa/DELETE_REQUEST",
  DELETE_ERROR: "acessosrpa/DELETE_ERROR"
};

const INITIAL_STATE = {
  list: [],
  search: '',
  loading: false,
  sort: 'login',
  page: 1,
  limit: 10,
  total: 0,
}

export default function acessosrpa(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.LIST_REQUEST:
      return { 
        ...state,
        loading: true,
        page: action.payload.params.page,
        sort: action.payload.params.sort,
        limit: action.payload.params.limit,
        search: action.payload.params.search || '',
        list: []
      }

    case Types.LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload.list,
        total: action.payload.total
      }

    case Types.LIST_ERROR:
      return { ...state, loading: false }

    case Types.SAVE_REQUEST:
      return { ...state, loading: true }

    case Types.SAVE_ERROR:
      return { ...state, loading: false }

    case Types.DELETE_REQUEST:
      return { ...state, loading: true }

    case Types.DELETE_ERROR:
      return { ...state, loading: false }

    default:
      return state;
  }
}

export const Creators = {
  listRequest: params => ({
    type: Types.LIST_REQUEST,
    payload: { params }
  }),
  listSuccess: (list, total) => ({
    type: Types.LIST_SUCCESS,
    payload: { list, total }
  }),
  listError: () => ({
    type: Types.LIST_ERROR
  }),
  saveRequest: body => ({
    type: Types.SAVE_REQUEST,
    payload: { body }
  }),
  saveError: () => ({
    type: Types.SAVE_ERROR
  }),
  deleteRequest: _id => ({
    type: Types.DELETE_REQUEST,
    payload: { _id }
  }),
  deleteError: () => ({
    type: Types.DELETE_ERROR
  })
}