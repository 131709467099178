import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import List from "./ListAcao";

import "./styles.css";

import GridCadastro from "components/GridCadastro";

export default function Acao() {
  const { pathname } = useLocation();

  const {
    access: { servicos },
  } = useSelector((state) => state.permissoes);

  const [userActions, setUserActions] = useState([]);

  useEffect(() => {
    let find = servicos.find((item) => item.route === pathname);
    if (find !== undefined) {
      setUserActions(find);
    }
  }, [pathname, servicos]);

  return (
    <GridCadastro
      title='Ações'
      route={"/configuracao/acao/novo"}
      titleButtonNovo="Nova Ação"
    >
      <List />
    </GridCadastro>
  );
}
