import api from "services/api";
import { getToken } from "services/auth";
import { call, select, put, all, takeLatest } from "redux-saga/effects";
import { toastr } from "react-redux-toastr";
import { push } from "connected-react-router";

import { Creators as DefinicaoSlaActions, Types } from "../ducks/definicaosla";

export const getOrganization = (state) => state.usuarios.auth.organization;
export const getUserId = (state) => state.usuarios.auth.id;

export function* listSLA({ payload }) {
  try {
    const organization = yield select(getOrganization);

    const { page, limit } = payload;

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
      params: { page, limit },
    };

    const response = yield call(api.get, `definicoessla`, headerParams);

    const data = { sla: response.data || [] };

    yield put(DefinicaoSlaActions.listSuccess(data));
  } catch (error) {
    toastr.error("Ops", "Não foi possível listar as definições de SLA.");
  }
}

export function* getSLA(action) {
  try {
    const headerParams = {
      headers: { Authorization: getToken() },
    };

    const { data } = yield call(
      api.get,
      `definicoessla/${action.payload}`,
      headerParams
    );

    yield put(DefinicaoSlaActions.getSuccess(data));
  } catch (error) {
    toastr.error("Ops", "Não foi possível buscar as definições de SLA.");
  }
}

export function* editSLA(action) {
  const userId = yield select(getUserId);

  try {
    const organization = yield select(getOrganization);

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const body = { ...action.payload, id_user_acao: userId };

    const { data } = yield call(
      api.put,
      `definicoessla/${action.payload._id}`,
      body,
      headerParams
    );

    yield put(DefinicaoSlaActions.editSuccess(data));

    toastr.success("Definição de SLA editado com sucesso.");
  } catch (error) {
    toastr.error("Ops", "Não foi possível editar a definição de SLA.");
  }
}

export function* createSLA(action) {
  const organization = yield select(getOrganization);

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const { data } = yield call(
      api.post,
      `definicoessla`,
      action.payload,
      headerParams
    );

    yield put(DefinicaoSlaActions.createSuccess(data));

    yield put(push(`/configuracao/tiposdocumento`));

    toastr.success("", "Definição de SLA criado com sucesso.");
  } catch (error) {
    toastr.error("Ops", "Não foi possível criar a definição de SLA.");
  }
}

export default all([
  takeLatest(Types.LIST_REQUEST, listSLA),
  takeLatest(Types.GET_REQUEST, getSLA),
  takeLatest(Types.EDIT_REQUEST, editSLA),
  takeLatest(Types.NEW_REQUEST, createSLA),
]);
