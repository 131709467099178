import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Creators as OnboardingActions } from 'store/ducks/onboarding';

import {
  PreviewButtons,
  TwoFactorContainer,
  TwoFactorForm,
} from './style';

function TwoFactorInput({ form }) {
  const dispatch = useDispatch();

  const {
    twofactor: { formCode, codigo_verificado },
  } = useSelector((state) => state.onboarding);

  const [values, setValues] = useState("");

  const handleChange = (index, event) => {

    const { value } = event.target;
    setValues(value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    dispatch(
      OnboardingActions.validateCodeRequest({
        codigo_verificacao: values,
        formCode,
      })
    );
  };

  return (
    <TwoFactorForm onSubmit={handleSubmit}>
      <h4>Insira o código de verificação:</h4>
      <TwoFactorContainer>
        <input
          type="text"
          onChange={(event) => handleChange(1, event)}
          // ref={register}
          name="code"
          maxLength={6}
          className="form-control bg-transparent h-60px w-60px fs-2qx text-center mx-1 my-2 mb-3"
          inputMode="numeric"
          autoComplete="off"
          // disabled={loading}
        />
        {/* {values.map((value, index) => (
          <InputTwoFactor
            key={index}
            type="text"
            maxLength={1}
            value={value}
            onChange={(event) => handleChange(index, event)}
            ref={inputRefs[index]}
          />
        ))} */}
      </TwoFactorContainer>

      <PreviewButtons $layout={form.layout.buttons}>
        <button type="submit">Enviar</button>
      </PreviewButtons>
    </TwoFactorForm>
  );
}

export default TwoFactorInput;
