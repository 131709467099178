export const Types = {
    LIST_REQUEST: "agrupamento/LIST_REQUEST",
    LIST_SUCCESS: "agrupamento/LIST_SUCCESS",

    REMOVE_REQUEST: "agrupamento/REMOVE_REQUEST",
    REMOVE_SUCCESS: "agrupamento/REMOVE_SUCCESS",

    NEW_REQUEST: "agrupamento/NEW_REQUEST",
    NEW_SUCCESS: "agrupamento/NEW_SUCCESS",

    EDIT_REQUEST: "agrupamento/EDIT_REQUEST",
    EDIT_SUCCESS: "agrupamento/EDIT_SUCCESS",

    LIST_STEPS_REQUEST: "agrupamento/LIST_STEPS_REQUEST",
    LIST_STEPS_SUCCESS: "agrupamento/LIST_STEPS_SUCCESS",
};

const INITIAL_STATE = {
    agrupamentos: [],
    loading: false,
    etapasDetalhes: [],
    loadingDetalhes: false,
};

export default function filas(state = INITIAL_STATE, action) {
    switch (action.type) {

        case Types.LIST_REQUEST:
            return { ...state, loading: true, agrupamentos: [] };
        case Types.LIST_SUCCESS:
            return { ...state, loading: false, agrupamentos: action.payload };

        case Types.REMOVE_REQUEST:
            return { ...state, loading: true };

        case Types.REMOVE_SUCCESS:
            return { ...state, loading: false };

        case Types.NEW_REQUEST:
            return { ...state, loading: true };

        case Types.NEW_SUCCESS:
            return { ...state, loading: false };

        case Types.EDIT_REQUEST:
            return { ...state, loading: true };

        case Types.EDIT_SUCCESS:
            return { ...state, loading: false };

        case Types.LIST_STEPS_REQUEST:
            return { ...state, loadingDetalhes: true, etapasDetalhes: [] };

        case Types.LIST_STEPS_SUCCESS: 
            return { ...state, loadingDetalhes: false, etapasDetalhes: action.payload };

        default:
            return state;
    }
}

export const Creators = {
    listRequest: () => ({
        type: Types.LIST_REQUEST,
        payload: [],
    }),
    listSuccess: (data) => ({
        type: Types.LIST_SUCCESS,
        payload: data,
    }),

    removeRequest: (id) => ({
        type: Types.REMOVE_REQUEST,
        payload: id,
    }),

    removeSuccess: () => ({
        type: Types.REMOVE_SUCCESS,
        payload: [],
    }),

    createRequest: (data) => ({
        type: Types.NEW_REQUEST,
        payload: data,
    }),

    createSuccess: () => ({
        type: Types.NEW_SUCCESS,
        payload: {},
    }),

    editRequest: (data) => ({
        type: Types.EDIT_REQUEST,
        payload: data,
    }),

    editSuccess: () => ({
        type: Types.EDIT_SUCCESS,
        payload: {},
    }),

    listStepsRequest: () => ({
        type: Types.LIST_STEPS_REQUEST,
        payload: [],
    }),

    listStepsSuccess: (data) => ({
        type: Types.LIST_STEPS_SUCCESS,
        payload: data,
    }),
};
