import React, { useRef, useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { Creators as GrupoEmailAction } from "store/ducks/grupoemail";
import Utils from 'utils/utils';
import { Autocomplete } from '@material-ui/lab';
import { Form } from '@unform/web';
import { Scope } from '@unform/core';

export default function AddMail({ open, handleClose }) {

    const dispatch = useDispatch();

    const formRef = useRef();

    const [emails, setEmails] = useState([]);
    const [error, setError] = useState(false);

    async function handleSubmit() {

        setError(false);

        try {
            if (emails.length === 0) throw new Error();

            dispatch(GrupoEmailAction.addMailRequest(emails));

            handleClose();

        } catch (err) {
            setError(true);
        }
    }

    const onChangeAutocomplete = (name, value, e) => {
        const util = new Utils();
        const email = value?.map((e) => typeof e === "object" ? e.inputValue.toLocaleLowerCase() : e.toLocaleLowerCase());
        const emailsValidados = util.handlePastedEmails(email);
        setEmails(emailsValidados);
    };

    return (
        <Dialog
            fullWidth
            open={open}
            onClose={handleClose}
        >
            <DialogTitle>
                Adicionar e-mail em todos os grupos de e-mail
            </DialogTitle>

            <DialogContent>

                <Form
                    ref={formRef}
                    onSubmit={handleSubmit}
                    initialData={{
                        emails: []
                    }}
                >
                    <Scope path={'emails'}>

                        <Autocomplete
                            freeSolo
                            multiple
                            name="emails"
                            clearOnBlur
                            options={[]}
                            size="medium"
                            selectOnFocus
                            value={emails}
                            id="options-combo"
                            style={{ marginBottom: "8px", height: '120px' }}
                            onChange={(e, value) =>
                                onChangeAutocomplete("emails", value, e)
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Adicione destinatários"
                                    variant="outlined"
                                    name="opcoes"
                                />
                            )}
                            getOptionLabel={(option) =>
                                typeof option === "string"
                                    ? option
                                    : option.inputValue
                            }
                            filterOptions={(options, params) => [
                                {
                                    inputValue: params.inputValue,
                                    title: `Novo e-mail "${params.inputValue}"`,
                                },
                            ]}
                            renderOption={(option) =>
                                typeof option === "string" ? option : option.title
                            }
                        />
                        <div hidden={!error} style={{ color: '#f44336',     fontSize: '10.5px' }}>
                            Campo obrigatório
                        </div>
                    </Scope>

                    <DialogActions>
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                        >
                            Confirmar
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={handleClose}
                        >
                            Cancelar
                        </Button>
                    </DialogActions>
                </Form>

            </DialogContent >
        </Dialog >
    )
}
