export const Types = {
  LIST_REQUEST: "menus/LIST_REQUEST",
  LIST_SUCCESS: "menus/LIST_SUCCESS",
  REMOVE_REQUEST: "menus/REMOVE_REQUEST",
  REMOVE_SUCCESS: "menus/REMOVE_SUCCESS",
  NEW_REQUEST: "menus/NEW_REQUEST",
  NEW_SUCCESS: "menus/NEW_SUCCESS",
  EDIT_REQUEST: "menus/EDIT_REQUEST",
  EDIT_SUCCESS: "menus/EDIT_SUCCESS",
  GET_REQUEST: "menus/GET_REQUEST",
  GET_SUCCESS: "menus/GET_SUCCESS"
};

const INITIAL_STATE = {
  loading: false,
  list: [],
  error: "",
  attempt: 0,
  page: 1,
  limit: 10,
  total: 0,
  sort: 'nome'
};

export default function items(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.LIST_REQUEST:
      return { ...state, loading: true };

    case Types.LIST_SUCCESS:
      return { ...state, loading: false, ...action.payload };

    case Types.EDIT_REQUEST:
      return { ...state, loading: true };

    case Types.EDIT_SUCCESS:
      return { ...state, loading: false };

    case Types.NEW_REQUEST:
      return { ...state, loading: true };

    case Types.NEW_SUCCESS:
      return { ...state, loading: false };

    case Types.REMOVE_REQUEST:
      return { ...state, loading: true };

    case Types.REMOVE_SUCCESS:
      return { ...state, loading: false };

    case Types.GET_REQUEST:
      return { ...state, loading: true };

    case Types.GET_SUCCESS:
      return { ...state, loading: false };

    default:
      return state;
  }
}

export const Creators = {
  listRequest: payload => ({
    type: Types.LIST_REQUEST,
    payload
  }),

  listSuccess: menus => ({
    type: Types.LIST_SUCCESS,
    payload: menus
  }),

  getRequest: id => ({
    type: Types.GET_REQUEST,
    payload: id
  }),

  getSuccess: menu => ({
    type: Types.GET_SUCCESS,
    payload: menu
  }),

  removeRequest: id => ({
    type: Types.REMOVE_REQUEST,
    payload: id
  }),

  removeSuccess: () => ({
    type: Types.REMOVE_SUCCESS,
    payload: []
  }),

  createRequest: menu => ({
    type: Types.NEW_REQUEST,
    payload: menu
  }),

  createSuccess: () => ({
    type: Types.NEW_SUCCESS,
    payload: {}
  }),

  editRequest: menu => ({
    type: Types.EDIT_REQUEST,
    payload: menu
  }),

  editSuccess: () => ({
    type: Types.EDIT_SUCCESS,
    payload: {}
  })
};
