import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  Button,
  InputAdornment,
  Icon,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
} from '@material-ui/core';
import _ from '@lodash';

import TaskList from '../TaskList';
import ModalNewTask from '../ModalNewTask';

import { Creators as ProvidenciasActions } from 'store/ducks/providencias';

function Providencias() {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const dispatchDebounce = useRef(_.debounce(dispatch, 500)).current;
  const [openModalNewTask, setOpenModalNewTask] = useState(false);

  const { documento } = useSelector((state) => state.documentos);

  const {
    search = '',
    page,
    limit,
    sort,
    SLA,
    total,
    loading,
    list: tasks,
  } = useSelector((state) => state.providencias);

  const {
    access: { servicos },
  } = useSelector((state) => state.permissoes);

  const { loading: loadingFromProvidencia } = useSelector(
    (state) => state.providencia
  );

  const [searchField, setSearchField] = useState(search);

  const userActions = useMemo(() => {
    const find = servicos.find((item) => item.route === pathname);

    if (find !== undefined) return find;
    return [];
  }, [servicos]);

  const hasActionNovaTarefa = useMemo(
    () =>
      userActions.actions &&
      userActions.actions.some((userAct) => userAct.action === 'novatarefa'),
    [userActions]
  );

  function handleChange({ target }) {
    const { value } = target;
    setSearchField(value);
    dispatchDebounce(
      ProvidenciasActions.listRequest({
        documento: documento._id,
        page,
        limit,
        sort,
        search: value,
        SLA,
      })
    );
  }

  function createListRequest({
    customDocument,
    customPage,
    customLimit,
    customSort,
    customSearch,
    customSLA,
  } = {}) {
    dispatch(
      ProvidenciasActions.listRequest({
        documento: customDocument || documento._id,
        page: customPage || page,
        limit: customLimit || limit,
        sort: customSort || sort,
        search: customSearch || search,
        SLA: customSLA || SLA,
      })
    );
  }

  function handleChangeFilter({ target }) {
    const { value } = target;
    createListRequest({ SLA: value });
  }

  useEffect(() => {
    if (documento._id) {
      createListRequest({
        documento: documento._id,
        page: 1,
        limit: 10,
        sort: '-createdAt',
        search: '',
        SLA: '',
      });
    }
  }, [documento._id]);

  function handleChangeRowsPerPage({ target }) {
    const { value } = target;
    createListRequest({ limit: value });
  }

  function handleChangePage(e, newPage) {
    createListRequest({ page: newPage + 1 });
  }

  function handleChangeSort(sort) {
    createListRequest({ sort });
  }

  function handleClick() {
    setOpenModalNewTask(true);
  }

  function handleCloseModalNewTask() {
    setOpenModalNewTask(false);
  }

  return (
    <>
      <div className="controlpanel-header bg-white">
        {/* <div className="controlpanel-item">
          <TextField
            label="Pesquisar"
            variant="outlined"
            size="small"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Icon className="text-20" color="action">
                    search
                  </Icon>
                </InputAdornment>
              ),
            }}
            onChange={handleChange}
            value={searchField}
          />
        </div> */}
        {/* <div className="controlpanel-item">
          <FormControl component="fieldset">
            <RadioGroup
              row
              aria-label="filter"
              name="filter"
              value={SLA}
              onChange={handleChangeFilter}
            >
              <FormControlLabel
                value={''}
                control={<Radio color="primary" />}
                label="Todos"
              />
              <FormControlLabel
                value={'true'}
                control={<Radio color="primary" />}
                label="Dentro do SLA"
              />
              <FormControlLabel
                value={'false'}
                control={<Radio color="primary" />}
                label="Fora do SLA"
              />
            </RadioGroup>
          </FormControl>
        </div> */}
        <div className="controlpanel-item">
          <Button
            variant="contained"
            color="primary"
            onClick={handleClick}
            disabled={!documento.status && !hasActionNovaTarefa}
          >
            Nova Tarefa
          </Button>
        </div>
      </div>

      <div>
        <TaskList
          tasks={tasks}
          loading={loading || loadingFromProvidencia}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleChangeSort={handleChangeSort}
          limit={limit}
          page={page}
          sort={sort}
          total={total}
        />
      </div>

      {openModalNewTask && (
        <ModalNewTask
          selectedDocument={documento}
          open={openModalNewTask}
          handleClose={handleCloseModalNewTask}
        />
      )}
    </>
  );
}

export default Providencias;
