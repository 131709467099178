import React, { useMemo, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import Button from '@material-ui/core/Button';

import { Creators as TipoDoscActions } from 'store/ducks/tiposdocumento';
import { Creators as TipoImagensActions } from 'store/ducks/tipoimagem';

import TableModalidades from './TableModalidades';
import Content from 'components/Content';

function Modalidades() {
  const dispatch = useDispatch();

  const { pathname } = useLocation();
  const { access: { servicos, empresas } } = useSelector(state => state.permissoes);

  const userActions = useMemo(() => {
    const find = servicos.find(item => item.route === pathname);
    if (find !== undefined) return find;
    return [];
  }, [pathname, servicos]);

  const canCreate = useMemo(() =>
    userActions.actions && userActions.actions.some(userAct => userAct.action === 'criar')
    , [userActions])

  useEffect(() => {
    dispatch(TipoDoscActions.listRequest({ empresas }));
    dispatch(TipoImagensActions.listRequest({ limit: Number.MAX_SAFE_INTEGER }))
  }, [dispatch, empresas]);

  return (
    <Content>
      <Row>
        <Col md="12" className="painel">
          <div className="painel-content">
            <div className="painel-title text-left">
              <div></div>
              {canCreate &&
                <Button
                  component={Link}
                  to={"/configuracao/modalidades/create"}
                  variant="contained"
                  color="primary"
                >Nova Modalidade</Button>
              }
            </div>
            <div className="painel-body">
              <TableModalidades userActions={userActions} />
            </div>
          </div>
        </Col>
      </Row>
    </Content>
  );
}

export default Modalidades;