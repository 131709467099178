import React, { useRef } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import MenuItem from "@material-ui/core/MenuItem";

import { Form } from "@unform/web";
import { ValidationError } from "yup";
import { useDispatch, useSelector } from "react-redux";
import { parseISO, format } from "date-fns";
import SelectForm from "components/SelectForm";
import InputForm from "components/InputForm";

import EncerramentoSchema from "validators/EncerramentoSchema";

import { Creators as EncerramentoActions } from "store/ducks/encerramento";

function FormEncerramentosModal({ documento }) {
  const dispatch = useDispatch();
  const formRef = useRef();

  const { open, loading, data } = useSelector((state) => state.encerramento);

  function handleClose() {
    if (!loading) dispatch(EncerramentoActions.cancel());
  }

  async function handleSubmit(values) {
    try {
      const validateValues = {
        ...values,
        dataDoTransito:
          values.dataDoTransito === "" ? null : values.dataDoTransito,
      };
      await EncerramentoSchema.validate(validateValues, {
        abortEarly: false,
      });

      const body = {
        documento: documento._id,
        ...data,
        ...validateValues,
      };

      dispatch(EncerramentoActions.saveRequest(body));
    } catch (err) {
      const validationErrors = {};
      if (err instanceof ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });

        formRef.current.setErrors(validationErrors);
      }
    }
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Cadastro de encerramento</DialogTitle>
      <DialogContent>
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          initialData={{
            ...data,
            dataDoEncerramento: data.dataDoEncerramento
              ? format(parseISO(data.dataDoEncerramento), "yyyy-MM-dd")
              : format(new Date(), "yyyy-MM-dd"),
          }}
        >
          <InputForm
            autoFocus
            label="Data do encerramento"
            name="dataDoEncerramento"
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            disabled
          />

          <SelectForm name="motivoDoEncerramento" label="Motivo">
            <MenuItem value="IMPROCEDENTE">Improcedente</MenuItem>
            <MenuItem value="PROCEDENTE">Procedente</MenuItem>
            <MenuItem value="PARCIAL_PROCEDENTE">Parcial procedente</MenuItem>
          </SelectForm>

          <InputForm
            label="Comentários"
            name="comentario"
            type="text"
            multiline
            rows={4}
          />
          
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={!!loading}
            >
              {!!loading && (
                <>
                  <CircularProgress color="primary" size={20} />
                  &nbsp;
                </>
              )}
              Salvar
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleClose}
              disabled={!!loading}
            >
              Cancelar
            </Button>
          </DialogActions>
        </Form>
      </DialogContent>
    </Dialog>
  );
}

export default FormEncerramentosModal;
