export const Types = {
  CLEAR_COUNT: 'tempstorage/CLEAR',
  NEW_PART_REQUEST: 'tempstorage/NEW_PART_REQUEST',
  NEW_PART_SUCCESS: 'tempstorage/NEW_PART_SUCCESS',
  NEW_STORAGE_REQUEST: 'tempstorage/NEW_STORAGE_REQUEST',
  NEW_STORAGE_SUCCESS: 'tempstorage/NEW_STORAGE_SUCCESS',
}

const INITIAL_STATE = {
  loading: false,
  loadingStorage: false,
  countRequest: 0,
  countSuccess: 0,
  storageId: null,
}

export default function tempstorage(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.CLEAR_COUNT:
      return {
        ...state,
        ...action.payload,
        loading: false,
        loadingStorage: false,
        storageId: null,  
        countRequest: 0,
        countSuccess: 0,
      }
    
    case Types.NEW_PART_REQUEST:
      return {
        ...state,
        ...action.payload,
        loading: true,
        countRequest: state.countRequest + 1 
      }

    case Types.NEW_PART_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
        countSuccess: state.countSuccess + 1 
        
      }

    case Types.NEW_STORAGE_REQUEST:
      return {
        ...state,
        loadingStorage: true
      }

    case Types.NEW_STORAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        loadingStorage: false,
        storageId: null,
      }

    default:
      return state;
  }
}

export const Creators = {
  clearRequest: () => ({
    type: Types.CLEAR_COUNT
  }),
  newPartRequest: payload => ({
    type: Types.NEW_PART_REQUEST,
    payload: payload
  }),
  newPartSuccess: (payload) => ({
    type: Types.NEW_PART_SUCCESS,
    payload: payload
  }),
  newStorageRequest: storageId => ({
    type: Types.NEW_STORAGE_REQUEST,
    payload: storageId
  }),
  newStorageSuccess: () => ({
    type: Types.NEW_STORAGE_SUCCESS,
  }),
}