import React, { useState, useEffect } from "react";
import SearchResults from "../PesquisaAvancada/SearchResults";

import Tabs from "../PesquisaAvancada/Tabs";

import { useDispatch, useSelector } from "react-redux";
import { Creators as AuditoriaActions } from "../../store/ducks/auditoria";

import "./styles.css";

import { Row } from "react-bootstrap";
import Content from "components/Content";

export default function Pesquisa() {
  const dispatch = useDispatch();

  let selectedDoc = useSelector((state) => state.documentos.documento);
  const { result, esResult } = useSelector(
    (state) => state.documentos.advSearch
  );

  const [collapse, setCollapse] = useState(false);

  useEffect(() => {
    setCollapse(false);
  }, [result]);

  useEffect(() => {
    if (selectedDoc._id) {
      const idDocumento = selectedDoc._id;
      dispatch(AuditoriaActions.listDocumentoRequest({ idDocumento }));
    }
  }, [dispatch, selectedDoc]);

  return (
    <Content>
      <SearchResults
        setCollapse={setCollapse}
        collapse={collapse}
        simpleSearch={true}
      />

      <Row>
        <Tabs selectedDoc={selectedDoc} />
      </Row>
    </Content>
  );
}
