import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Autocomplete } from '@material-ui/lab';
import DefaultInput from 'components/DefaultInput';

import { Creators as TiposImagemActions } from 'store/ducks/tipoimagem';
import {
  ImagesContainer,
  AutoCompleteSection,
  TextWriter,
} from '../StepTypesStyle';
import RichTextEditor from '../../Utils/Editor';

export default function CameraStep({ tipoDocumento, onChange, settings }) {
  const dispatch = useDispatch();

  const cameraTypes = [
    { id: 'selfie', nome: 'Selfie' },
    { id: 'documento', nome: 'Documento' },
  ];

  const [content, setContent] = useState(settings.content || null);
  const [imageType, setSelectedImageType] = useState(
    settings.imageType || null
  );
  const [cameraType, setSelectedCameraType] = useState(
    settings.cameraType || 0
  );

  const onEditorStateChange = (value) => {
    setContent(value);
  };

  useEffect(() => {
    if (imageType || cameraType || content) {
      onChange(settings, { ...settings, content, imageType, cameraType });
    }
  }, [imageType, cameraType, content]);

  const {
    access: { empresas },
  } = useSelector((state) => state.permissoes);

  const { list: listTpImages, loading: loadingTpImages } = useSelector(
    (state) => state.tipoImagem
  );

  useEffect(() => {
    if (tipoDocumento) {
      dispatch(
        TiposImagemActions.listByTipoDocumentoRequest(tipoDocumento._id)
      );
    }
  }, [dispatch, tipoDocumento]);

  return (
    <ImagesContainer>
      <TextWriter>
        <b>Escreva o texto desejado:</b>
        <RichTextEditor
          onEditorStateChange={onEditorStateChange}
          value={settings.content}
        />
      </TextWriter>
      <AutoCompleteSection>
        {loadingTpImages ? (
          <div>carregando...</div>
        ) : (
          <Autocomplete
            className="mb-2"
            options={cameraTypes}
            getOptionLabel={(option) => option.nome}
            onChange={(e, value) => setSelectedCameraType(value.id)}
            getOptionSelected={(option, value) => option.id === value.id}
            defaultValue={cameraTypes[settings.cameraType === 'selfie' ? 0 : 1]}
            renderInput={(params) => (
              <DefaultInput
                {...params}
                name="cameraType"
                label="Selecione o tipo de captura"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        )}
      </AutoCompleteSection>
      <AutoCompleteSection>
        {loadingTpImages ? (
          <div>carregando...</div>
        ) : (
          <Autocomplete
            className="mb-2"
            options={listTpImages}
            getOptionLabel={(option) => option.nome}
            onChange={(e, value) => setSelectedImageType(value._id)}
            getOptionSelected={(option, value) => option._id === value._id}
            defaultValue={
              listTpImages[
                listTpImages.findIndex(
                  (item) => item._id === settings.imageType
                )
              ]
            }
            renderInput={(params) => (
              <DefaultInput
                {...params}
                name="tipoImagem"
                label="Selecione o tipo de imagem que deverá ser vinculada a Selfie registrada"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        )}
      </AutoCompleteSection>
    </ImagesContainer>
  );
}
