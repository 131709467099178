import React, { useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Row, Col } from "react-bootstrap";

import { CircularProgress } from "@material-ui/core";

import BackButton from "../../../components/BackButton";
import Timeline from "@material-ui/lab/Timeline";

import TimelineProgressComponent from "components/TimelineAuditoriaFormalizacao";

import "./styles.css";

import { Creators as ParamFormalizacaoAuditoriaActions } from "../../../store/ducks/paramformalizacaoauditoria";

import Content from "components/Content";

export default function AuditoriaItemConfig(params) {
  const dispatch = useDispatch();

  const { checkTipos } = params.location.state;
  const { _id } = checkTipos;

  const { loading, data } = useSelector(
    (state) => state.paramformalizacaoauditoria
  );

  useEffect(() => {
    dispatch(ParamFormalizacaoAuditoriaActions.getRequest(_id));
  }, [dispatch]);
  
  return (
    <Content>
      <Row>
        <Col md="12" className="painel">
          <div className="painel-content">
            <div className="painel-title text-left">Auditoria checklist</div>
            <div className="painel-body">
              {!!loading && (
                <>
                  <CircularProgress color="primary" size={20} />
                  &nbsp;
                </>
              )}
              <Timeline align={"alternate"}>
                {!loading && data?.map((value) => {
                  return (
                    <TimelineProgressComponent data={value} key={value._id} />
                  );
                })}
              </Timeline>
              <BackButton variant="contained" color="secondary">
                Voltar
              </BackButton>
            </div>
          </div>
        </Col>
      </Row>
    </Content>
  );
}
