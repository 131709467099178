import React from "react";

import { Col } from "react-bootstrap";

export default function Field({ label, value, multiLineValue }) {
  return (
    <Col md={12} className="viewField">
      <strong>{label}:</strong>
      {
        multiLineValue
          ? <span style={{
            display: "block",
            wordWrap: "break-word",
            whiteSpace: "pre-wrap"
          }}>
            {value}
          </span>
          : <span>{value}</span>
      }
    </Col>
  );
}
