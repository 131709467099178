export const Types = {
  LIST_REQUEST: 'gruposempresariais/LIST_REQUEST',
  LIST_SUCCESS: 'gruposempresariais/LIST_SUCCESS',
  SAVE_REQUEST: 'gruposempresariais/SAVE_REQUEST',
}

const INITIAL_STATE = {
  list: [],
  loading: false,
  page: 1,
  limit: 10,
  total: 0,
  sort: 'nome'
}

export default function gruposempresariais(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.LIST_REQUEST:
      return { ...state, ...action.payload, loading: true }

    case Types.LIST_SUCCESS:
      return { ...state, ...action.payload, loading: false }

    case Types.SAVE_REQUEST:
      return { ...state, loading: true }

    default: 
      return state
  }
}

export const Creators = {
  listRequest: payload => ({
    type: Types.LIST_REQUEST,
    payload
  }),
  listSuccess: payload => ({
    type: Types.LIST_SUCCESS,
    payload
  }),
  saveRequest: payload => ({
    type: Types.SAVE_REQUEST,
    payload
  }),
}