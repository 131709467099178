import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Creators as MenusActions } from "../../../store/ducks/menus";
import { Row, Col } from "react-bootstrap";

import { Form } from "@unform/web";
import ButtonMui from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";

import InputForm from "components/InputForm";
import SelectForm from "components/SelectForm";
import MenuItem from "@material-ui/core/MenuItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import ToolTipComponent from "components/Tooltip";

import ButtonSquare from "components/ButtonSquare";
import BackButton from "../../../components/BackButton";
import MenuSchema from "validators/MenuSchema";

import { ValidationError } from "yup";

import "./styles.css";
import Content from "components/Content";

export default function CreateMenu(params) {
  const dispatch = useDispatch();
  const formRef = useRef(null);
  const actionsRef = useRef(null);

  const [actions, setActions] = useState([]);
  const [status, setStatus] = useState(false);

  const handleCheckStatus = () => {
    setStatus(!status);
  };

  useEffect(() => { }, [dispatch]);

  const onSubmit = async (values) => {
    let body = {
      ...values,
      actions,
      status,
    };

    try {
      await MenuSchema.validate(body, {
        abortEarly: false,
      });

      dispatch(MenusActions.createRequest(body));
    } catch (err) {
      const validationErrors = {};
      if (err instanceof ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });

        formRef.current.setErrors(validationErrors);
      }
    }
  };

  const newAction = () => {
    setActions([...actions, { action: "" }]);
  };

  const removeAction = async (e, index) => {
    let updatedActions = [...actions];

    await updatedActions.splice(index, 1);

    setActions(updatedActions);
  };

  const editAction = async (e, index) => {
    let updatedActions = actions;

    updatedActions[index].action = e.target.value;

    setActions(updatedActions);
  };

  const icones = [
    "home",
    "usuarios",
    "steps",
    "monitoramento",
    "departamento",
    "relatorio",
    "configdoc",
    "configimg",
    "indeximg",
    "empresa",
    "checklist",
    "configesteira",
    "distributed",
    "dollar",
  ];

  return (
    <Content>
      <Row>
        <Col md="6" className="painel">
          <div className="painel-content">
            <div className="painel-title text-left">Editar Menu:</div>
            <div className="painel-body">
              <Form ref={formRef} onSubmit={onSubmit}>
                <Row>
                  <Col md="12">
                    <InputForm label="Nome*" name="title" />
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <InputForm label="Identificador*" name="identifier" />
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <InputForm label="Rota*" name="route" />
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <SelectForm name="icon" label="Icone*">
                      {icones.map((icone) => (
                        <MenuItem value={icone}>{icone}</MenuItem>
                      ))}

                      <MenuItem value={false}>Inativo</MenuItem>
                    </SelectForm>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <InputForm label="Ordem*" name="order" type="number" />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormControlLabel
                      control={
                        <Switch
                          name="status"
                          onChange={handleCheckStatus}
                          color="primary"
                        />
                      }
                      label="Ativo"
                    />
                  </Col>
                </Row>

                <div className="buttons">
                  <Grid container spacing={1}>
                    <Grid item>
                      <ButtonMui
                        variant="contained"
                        color="primary"
                        type="submit"
                      >
                        Salvar
                      </ButtonMui>
                    </Grid>
                    <Grid item>
                      <BackButton variant="contained" color="secondary">
                        Voltar
                      </BackButton>
                    </Grid>
                  </Grid>
                </div>
              </Form>
            </div>
          </div>
        </Col>
        <Col md="6" className="painel">
          <div className="painel-content">
            <div className="painel-title text-left">
              Ações:
              <ButtonMui
                variant="contained"
                color="primary"
                onClick={newAction}
              >
                Nova ação
              </ButtonMui>
            </div>
            <div className="painel-body">
              <div className="actions">
                <Form ref={actionsRef}>
                  {actions &&
                    actions.map((action, actionIndex) => (
                      <Row className="action-item" key={actionIndex}>
                        <Col md={10}>
                          {" "}
                          <InputForm
                            label="Nome da ação*"
                            name={`acao${actionIndex}`}
                            onChange={(e) => editAction(e, actionIndex)}
                            defaultValue={action.action}
                          />
                        </Col>
                        <Col md={2}>
                          <ToolTipComponent title="Remover" placement="top">
                            <ButtonSquare
                              onClick={(e) => removeAction(e, actionIndex)}
                              icon="delete"
                            />
                          </ToolTipComponent>
                        </Col>
                      </Row>
                    ))}
                </Form>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Content>
  );
}
