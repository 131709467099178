import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import TextField from '@material-ui/core/TextField';

import Autocomplete from '@material-ui/lab/Autocomplete';
import { ArrowDownCircle, ArrowUpCircle } from 'components/Icons';

import CustomButtonIcon from 'components/CustomButtonIcon';
import ActionMenu from '../ActionMenu';
import { AppsIcon, MoreIcon, PreviewIcon } from 'components/Icons';

// TODO - Refactor this component. It receives so many props
function ControlPanel({
  showViewer,
  tpimgOptions,
  setShowViewer,
  selectedFiles,
  sizeArrayImagens,
  setShowModalMerge,
  setShowModalSendEmail,
  setShowModalUploadImagens,
  handleDownload,
  handleSelectAll,
  handleSelectedOption,
  tipoImagemSelect,
  reverseImageArray,
  imageOrderReversed,
  handleGenerateThumbnails,
}) {
  const { documento: selectedDoc } = useSelector((state) => state.documentos);

  const [menuAnchor, setMenuAnchor] = useState(null);

  function handleOpenActionsMenu(event) {
    setMenuAnchor(event.currentTarget);
  }

  function handleCloseActionsMenu() {
    setMenuAnchor(null);
  }

  function handleClickShowViewer() {
    setShowViewer(!showViewer);
  }

  function handleOpenModalSendEmail() {
    setShowModalSendEmail(true);
    setMenuAnchor(null);
  }

  function handleOpenModalUpload() {
    setShowModalUploadImagens(true);
    setMenuAnchor(null);
  }

  function handleOpenModalMerge() {
    setShowModalMerge(true);
    setMenuAnchor(null);
  }

  return (
    <>
      <div className="controlpanel-header">
        <div className="controlpanel-item">
          <Autocomplete
            id="viewerTipoImagens"
            options={tpimgOptions}
            getOptionLabel={(option) => option.nome}
            getOptionSelected={(option) => option._id}
            defaultChecked={tipoImagemSelect}
            onChange={handleSelectedOption}
            style={{ width: '100%' }}
            clearText="Limpar tipo de imagem selecionado"
            value={tpimgOptions.find((tp) => tp._id === tipoImagemSelect)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Tipo de imagem"
                variant="outlined"
              />
            )}
            size="small"
            disabled={!selectedDoc.imagens}
          />
          <button className="btn" onClick={reverseImageArray}>
            Data de criação
            {imageOrderReversed ? (
              <ArrowDownCircle className="ml-2" />
            ) : (
              <ArrowUpCircle className="ml-2" />
            )}
          </button>
        </div>

        {/* <div className="controlpanel-item">
          <RadioGroup
            aria-label="Ordenar por data"
            name="order"
            value={imageOrderReversed}
            onChange={reverseImageArray}
            row
          >
            <FormControlLabel
              value={false}
              control={<Radio color="primary" />}
              label="crescente"
            />
            <FormControlLabel
              value={true}
              control={<Radio color="primary" />}
              label="decrescente"
            />
          </RadioGroup>
        </div> */}

        <div className="controlpanel-item controlpanel-actions">
          <CustomButtonIcon
            iconcolor="#a9a9a9"
            iconbackground="#FFF"
            Icon={
              showViewer ? (
                <AppsIcon size="small" />
              ) : (
                <PreviewIcon size="small" />
              )
            }
            tooltip={
              showViewer ? 'Listar documentos' : 'Visualizador de documentos'
            }
            aria-label="Mostrar documento"
            onClick={handleClickShowViewer}
            arrow
          />

          <CustomButtonIcon
            Icon={<MoreIcon size="small" />}
            iconcolor="#a9a9a9"
            iconbackground="#FFF"
            tooltip="Exibir menu"
            aria-label="Exibir menu"
            onClick={handleOpenActionsMenu}
            arrow
          />
        </div>
        {menuAnchor && (
          <ActionMenu
            anchor={menuAnchor}
            selectedFiles={selectedFiles}
            sizeArrayImagens={sizeArrayImagens}
            handleOpenSendEmail={handleOpenModalSendEmail}
            handleOpenUpload={handleOpenModalUpload}
            handleOpenModalMerge={handleOpenModalMerge}            
            handleDownload={handleDownload}
            handleSelectAll={handleSelectAll}
            handleClose={handleCloseActionsMenu}
            handleGenerateThumbnails={handleGenerateThumbnails}
          />
        )}
      </div>
      <div className="border-top"></div>
    </>
  );
}

export default ControlPanel;
