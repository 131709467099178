import React from 'react';

import Providencias2 from 'components/Providencias';

function Providencias({ selectedDoc }) {
  return (
    <>
      <Providencias2 documento={selectedDoc} />
    </>
  );
}

export default Providencias;
