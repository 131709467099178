import api from '../../services/api';
import { getToken } from '../../services/auth';
import { put, call, takeLatest, all } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';
import { Creators as OnboardingActions, Types } from '../ducks/onboarding';
import { push } from 'connected-react-router';
import { select } from 'redux-saga/effects';

export const getOrganization = (state) => state.usuarios.auth.organization;
export const getUserId = (state) => state.usuarios.auth.id;

export function* listOnboarding({ payload }) {
  try {
    const organization = yield select(getOrganization);

    const { page, limit, sort } = payload;

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
      params: { page, limit, sort },
    };

    const body = {};

    if (payload.query) {
      body.query = payload.query;
    }

    const { data } = yield call(api.get, `onboarding`, headerParams);

    yield put(OnboardingActions.listSuccess(data));
  } catch (error) {
    toastr.error('Ops', 'Não foi possível listar os formulários.');
  }
}

export function* removeOnboarding(action) {
  try {
    const organization = yield select(getOrganization);

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const {
      data: { status },
    } = yield call(api.delete, `onboarding/${action.payload}`, headerParams);

    if (status) {
      toastr.success('Ação realizada!', 'Remoção realizada com sucesso.');
    }

    yield put(OnboardingActions.listRequest({ page: 1, limit: 10 }));
  } catch (error) {
    toastr.error('Ops', 'Não foi possível remover o Onboarding.');
  }
}

export function* createOnboarding(action) {
  try {
    const userId = yield select(getUserId);

    const organization = yield select(getOrganization);

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const body = { ...action.payload, userAcao: userId };

    const { data } = yield call(api.post, `onboarding`, body, headerParams);

    yield put(push(`/configuracao/onboarding`));

    yield put(OnboardingActions.createSuccess(data));

    toastr.success('', 'Onboarding criado com sucesso.');
  } catch (error) {
    toastr.error('Ops', 'Não foi possível criar o Onboarding.');
  }
}

export function* generateCodeOnboarding(action) {
  try {
    const organization = yield select(getOrganization);

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const body = { ...action.payload };

    const { data } = yield call(
      api.post,
      `onboarding/initializeform`,
      body,
      headerParams
    );

    yield put(OnboardingActions.generateCodeSuccess(data));

    toastr.success(
      'Código solicitado',
      'Em alguns instantes o código será enviado para o email informado.'
    );
  } catch (error) {
    toastr.error('Ops', 'Não foi possível criar o Onboarding.');
  }
}

export function* finishOnboarding(action) {
  try {
    const organization = yield select(getOrganization);

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const body = { ...action.payload };

    const { data } = yield call(
      api.post,
      `onboarding/finishform`,
      body,
      headerParams
    );

    yield put(OnboardingActions.finishOnboardingSuccess(data));

    toastr.success(
      'Onboarding',
      'Processo realizado com sucesso.'
    );
  } catch (error) {
    toastr.error('Ops', 'Não foi possível criar o Onboarding.');
  }
}

export function* validateCodeOnboarding(action) {
  try {
    const organization = yield select(getOrganization);

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const body = { ...action.payload };

    const { data } = yield call(
      api.post,
      `onboarding/validatecodeform`,
      body,
      headerParams
    );

    yield put(OnboardingActions.validateCodeSuccess(data));
  } catch (error) {
    toastr.error('Ops', 'Não foi possível verificar o Código.');
  }
}

export function* validateCodePrivateRequest(action) {
  try {
    const organization = yield select(getOrganization);

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const body = { ...action.payload };

    const { data } = yield call(
      api.post,
      `check-private-code`,
      body,
      headerParams
    );

    if (data.error) {
      toastr.error('Ops', data.message);
      yield put(OnboardingActions.validateCodeError());
      return;
    }

    yield put(OnboardingActions.validateCodeSuccess(data));
  } catch (error) {
    toastr.error('Ops', 'Não foi possível verificar o Código.');
  }
}

export function* validationsData(action) {
  try {
    const organization = yield select(getOrganization);

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const body = { ...action.payload };

    const { data } = yield call(
      api.post,
      `validation/dadosbasicos`,
      body,
      headerParams
    );

    console.log('retorno da api', data);

    yield put(OnboardingActions.validationsSuccess(data));
  } catch (error) {
    toastr.error('Ops', 'Não foi realizar as validações.');
  }
}

export function* editOnboarding(action) {
  const userId = yield select(getUserId);

  try {
    const organization = yield select(getOrganization);

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const body = { ...action.payload, user_acao: userId };

    const { data } = yield call(
      api.put,
      `/onboarding/${action.payload._id}`,
      body,
      headerParams
    );

    yield put(push(`/configuracao/onboarding`));

    yield put(OnboardingActions.editSuccess(data));

    toastr.success('', 'Onboarding editada com sucesso.');
  } catch (error) {
    toastr.error('Ops', 'Não foi possível editar o Onboarding.');
  }
}

export function* getOnboarding(action) {
  try {
    const organization = yield select(getOrganization);

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const { data } = yield call(
      api.get,
      `/onboarding/${action.payload}`,
      headerParams
    );

    yield put(OnboardingActions.getSuccess(data));
  } catch (error) {
    toastr.error('Ops', 'Não foi possível buscar os dados do Onboarding.');
  }
}

export default all([
  takeLatest(Types.LIST_REQUEST, listOnboarding),
  takeLatest(Types.EDIT_REQUEST, editOnboarding),
  takeLatest(Types.REMOVE_REQUEST, removeOnboarding),
  takeLatest(Types.GET_REQUEST, getOnboarding),
  takeLatest(Types.NEW_REQUEST, createOnboarding),
  takeLatest(Types.GENERATECODE_REQUEST, generateCodeOnboarding),
  takeLatest(Types.VALIDATECODE_REQUEST, validateCodeOnboarding),
  takeLatest(Types.VALIDATECODE_PRIVATE_REQUEST, validateCodePrivateRequest),
  takeLatest(Types.VALIDATIONS_REQUEST, validationsData),
  takeLatest(Types.FINISHONBOARDING_REQUEST, finishOnboarding)
]);
