export const Types = {
  NEW_REQUEST: 'bulkimport/NEW_REQUEST',
  NEW_SUCCESS: 'bulkimport/NEW_SUCCESS',
};

/* Reducer */

const INITIAL_STATE = {
  loading: false,
};

export default function bulkimport(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.NEW_REQUEST:
      return { ...state, loading: true };

    case Types.NEW_SUCCESS:
      return { ...state, loading: false };

    default:
      return state;
  }
}

/*Actions */

export const Creators = {
  createRequest: (documento) => ({
    type: Types.NEW_REQUEST,
    payload: documento,
  }),

  createSuccess: () => ({
    type: Types.NEW_SUCCESS,
  }),
};
