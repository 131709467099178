export const Types = {
    NEW_REQUEST: "resposta/NEW_REQUEST",
    NEW_SUCCESS: "resposta/NEW_SUCCESS",
    LIST_ORGAOS_REQUEST: "resposta/LIST_ORGAOS_REQUEST",
    LIST_ORGAOS_SUCCESS: "resposta/LIST_ORGAOS_SUCCESS",
    LIST_OPCOES_REQUEST: "resposta/LIST_OPCOES_REQUEST",
    LIST_OPCOES_SUCCESS: "resposta/LIST_OPCOES_SUCCESS",
};

/* Reducer */

const INITIAL_STATE = {
    loading: false,
    data: null,
    orgaos: [],
    tipoManifestacoesList: [],
};

export default function items(state = INITIAL_STATE, action) {
    switch (action.type) {
        case Types.NEW_REQUEST:
            return { ...state, loading: true };

        case Types.NEW_SUCCESS:
            return { ...state, loading: false, data: action?.payload };

        case Types.LIST_ORGAOS_REQUEST:
            return { ...state, loading: true };

        case Types.LIST_ORGAOS_SUCCESS:
            return { ...state, loading: false, orgaos: action?.payload };

        case Types.LIST_OPCOES_REQUEST:
            return { ...state, loading: true };

        case Types.LIST_OPCOES_SUCCESS:
            return { ...state, loading: false, tipoManifestacoesList: action?.payload };

        default:
            return state;
    }
}

export const Creators = {
    createRequest: (data) => ({
        type: Types.NEW_REQUEST,
        payload: data,
    }),

    createSuccess: (data) => ({
        type: Types.NEW_SUCCESS,
        payload: data,
    }),

    listOrgaosRequest: (data) => ({
        type: Types.LIST_ORGAOS_REQUEST,
        payload: data,
    }),

    listOrgaosSuccess: (data) => ({
        type: Types.LIST_ORGAOS_SUCCESS,
        payload: data,
    }),

    listOpcoesRequest: (data) => ({
        type: Types.LIST_OPCOES_REQUEST,
        payload: data,
    }),

    listOpcoesSuccess: (data) => ({
        type: Types.LIST_OPCOES_SUCCESS,
        payload: data,
    }),
};
