import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

function ConfirmDialog({ open, onClose, onConfirm }) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Há um comentário em andamento!
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Você começou a preencher um comentário e ainda não o salvou. Caso
          continue sem salva-lo, o mesmo não ficará armazenado no histórico da
          análise realizada. <br />
          <br />
          Deseja avançar mesmo assim?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onClose} color="secondary">
          Voltar
        </Button>
        <Button
          variant="contained"
          onClick={onConfirm}
          color="primary"
          autoFocus
        >
          Continuar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmDialog;
