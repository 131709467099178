import React, { useEffect, useState } from 'react';
import { getTenant, setTenant } from 'services/auth';
import { Button, Modal } from 'react-bootstrap';
import Login from '.';
import LoginOld from './index-old';
import { usaKeycloak } from 'utils/utils';

const subdomains = [
    'documentor',
    'localhost',
    'nssmartsuite'
];

function getSubdomain() {
    const host = window.location.host;
    const subdominio = host.split('.')[0];
    return subdominio;
}

const Realm = () => {

    const [realm, setRealm] = useState(getTenant());
    const [subdomain, setSubdomain] = useState(null);
    const [authenticated, setAuthenticated] = useState(subdomain?.length > 3);

    const handleRealmChange = (e) => {
        setRealm(e.target.value);
    };

    const handleLogin = () => {
        setSubdomain(realm);
    };

    useEffect(() => {
        const sbdomain = getSubdomain();
        let itsOk = true;
        subdomains.forEach(e => {
            if (sbdomain.includes(e)) itsOk = false;
        });
        if (itsOk) setSubdomain(sbdomain);
    }, []);

    useEffect(() => {
        if (subdomain) {
            setTenant(subdomain);
            setAuthenticated(true);
        }
    }, [subdomain]);

    if (!usaKeycloak()) return (<LoginOld />);

    if (!authenticated) {
        return (
            <Modal show={true} centered>
                <Modal.Body>
                    <input
                        type="text"
                        value={realm}
                        onChange={handleRealmChange}
                        placeholder="Empresa"
                        style={{ width: '100%' }}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={handleLogin} disabled={!realm || realm.length < 3}>
                        Seguir
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }

    return (
        <Login />
    );
};

export default Realm;
