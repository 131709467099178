import React from "react";
import { Row, Col } from "react-bootstrap";
import Paper from "@material-ui/core/Paper";
import StyledTableRow from "components/StyledTableRow";

import Table from "@material-ui/core/Table";
import Button from "@material-ui/core/Button";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";

function ModalSigners({ open, handleClose, signers = [], setOpenSigners }) {
  const goBack = () => {
    setOpenSigners(false);
  };

  return (
    <Row>
      <Col>
        <Dialog open={open} maxWidth="md" fullWidth>
          <DialogTitle className="header-modal">Destinatários</DialogTitle>
          <DialogContent>
            <Row>
              <Col>
                <TableContainer component={Paper}>
                  <Table aria-label="collapsible table" size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">
                          <b>Nome</b>
                        </TableCell>
                        <TableCell align="left">
                          <b>E-mail</b>
                        </TableCell>
                        <TableCell align="center">
                          <b>Ordem de assinatura</b>
                        </TableCell>
                        <TableCell align="center">
                          <b>Status</b>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {signers?.map((signer, index) => (
                        <StyledTableRow key={index}>
                          <TableCell align="left">{signer.name}</TableCell>
                          <TableCell align="left">{signer.email}</TableCell>
                          <TableCell align="center">
                            {signer.routingOrder}
                          </TableCell>
                          <TableCell align="center">{signer.status}</TableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Col>
            </Row>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              onClick={() => goBack()}
            >
              Voltar
            </Button>
          </DialogActions>
        </Dialog>
      </Col>
    </Row>
  );
}

export default ModalSigners;
