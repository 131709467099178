import React, { useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';

import {
    Container,
    ThumbnailFloater,
    InputContainer,
} from './styles/SingleFile';

import Thumbnail from 'components/Thumbnail';

import useOutsideAlerter from '../../../hooks/useOutsideAlerter';
import { CheckCircleIcon, UploadIcon } from 'components/Icons';

function SingleFile({ file, name }) {
    const containerRef = useRef(null);
    const thumbnailFloaterRef = useRef(null);
    const inputContainerRef = useRef(null);

    const [hovered, setHovered] = useState(false);
    const [showInputFloater, setShowInputFloater] = useState(false);
    const [customFilename, setCustomFilename] = useState(() => getFileName(name));
    const [thumbnailFloaterPos, setThumbnailFloaterPos] = useState({ x: 0, y: 0});
    const [thumbnailFloaterSize, setThumbnailFloaterSize] = useState({ width: 0, height: 0});

    useOutsideAlerter(inputContainerRef, handleClickOutside);

    function handleMouseEnter() {
        setHovered(true);
    }

    function handleMouseLeave() {
        setHovered(false);
    }

    function handleClickOutside() {
        setShowInputFloater(false);
    }

    function handleClick() {
        setShowInputFloater(true);
    }

    function handleChange(e) {
        setCustomFilename(e.target.value);
    }

    function handleSubmitChangeName() {
        setShowInputFloater(false);
    }

    useEffect(() => {
        if (containerRef.current) {
            const boundingRect = containerRef.current.getBoundingClientRect();
            setThumbnailFloaterPos({ x: boundingRect.x, y: boundingRect.y });
        }
    }, [containerRef.current]);

    useEffect(() => {
        if (thumbnailFloaterRef.current) {
            const boundingRect = thumbnailFloaterRef.current.getBoundingClientRect();
            setThumbnailFloaterSize({
                width: boundingRect.width,
                height: boundingRect.height
            });
        }
    }, [thumbnailFloaterRef.current]);

    function createViewer() {
        return ReactDOM.createPortal(
        <ThumbnailFloater
            ref={thumbnailFloaterRef}
            pos={thumbnailFloaterPos}
            size={thumbnailFloaterSize}>
            <Thumbnail file={file} />
        </ThumbnailFloater>, document.body);
    }

    function getFileName(name) {
        const [filename,] = name.split('.');
        return filename;
    }

    function showInput() {

        return ReactDOM.createPortal(
            <InputContainer
                ref={inputContainerRef}
                pos={thumbnailFloaterPos}>
                <TextField
                    onFocus={() => {}}
                    onChange={(event) => console.log(event.target.value)}
                    label="Renomear"
                    variant="outlined"
                    name="filename"
                    value={customFilename}
                    shrink
                    size="small" />
                <IconButton
                    onClick={handleSubmitChangeName}
                    color="primary"
                    aria-label="Renomear arquivo"
                    component="span">
                    <CheckCircleIcon />
                </IconButton>
            </InputContainer>
        , document.body);
    }
    
    return (
        <Container
            ref={containerRef}
            onClick={handleClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}>
            {hovered && file && createViewer()}
            <UploadIcon /> <div>{name}</div>
        </Container>
    );
}

const FilenameEditor = React.forwardRef(({ handleSubmitChangeName, customFilename, handleChange, pos }, ref) => {
    // Text edition not working on Portal, need to investigate documentation
    const [text, setText] = useState('');
    return ReactDOM.createPortal(
        <InputContainer
            ref={ref}
            pos={pos}>
            <TextField
                onFocus={() => {}}
                onChange={(e) => setText(e.target.value)}
                label="Renomear"
                variant="outlined"
                name="filename"
                value={text}
                shrink
                size="small" />
            <IconButton
                onClick={handleSubmitChangeName}
                color="primary"
                aria-label="Renomear arquivo"
                component="span">
                <CheckCircleIcon />
            </IconButton>
        </InputContainer>
    , document.body);
});

export default SingleFile;