import React, { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Webcam from 'react-webcam';
import * as Icons from 'react-feather';
import { FeatherIcon } from 'components/Icons';
import CircularProgress from '@material-ui/core/CircularProgress';
import api from 'services/api';
import DOMPurify from 'dompurify';

import {
  Container,
  CameraContainer,
  ImagePreviewContainer,
  MaskCamera,
  CapturePhotoContainer,
  PictureTypeSelectionContainer,
  SelectionType,
  ImagePreview,
  ImageTextContainer,
  ImageListNames,
  ImageListNamesItem,
  ImageLegendsStatus,
  ImageLegendsStatusItem,
  ImageLegendsContainer,
  TakePhotoButton,
  SwitchCameraButton,
} from './styled';
import { LoadingOnboardingData } from 'pages/Onboarding/OnboardingStyle';
import Thumb from './Thumb';

const ImageStep = ({
  handleCaptureImage,
  settings,
  handleUpdateFields,
  layout,
  imagesOnboarding,
  setImagesOnboarding,
  formFieldNames,
  defaultImages
}) => {
  const webcamRef = useRef(null);
  const inputFileRef = useRef(null);

  const { organization } = useSelector(state => state.usuarios.auth);
  const [numeroDocAtual, setNumeroDocAtual] = useState(1);
  const [totalDocsSelected, setTotalDocsSelected] = useState(null);
  const [imagens, setImagens] = useState([]);
  const [loadingImageProcess, setLoadingImageProcess] = useState(false);
  const [pictureType, setPictureType] = useState(null);
  const [facingMode, setFacingMode] = useState("user"); // "user" para a frontal, "environment" para a traseira

  const toggleCamera = () => {
    setFacingMode((prev) => (prev === "user" ? "environment" : "user"));
  };

  const setImagesSetting = new Set();
  const setImagesInMemory = new Set();
  const setImagesSettingName = new Set();

  for (const image of imagens) {
    setImagesInMemory.add(image.tpImg);
  }

  if (settings && settings.imageTypes) {
    for (const setting of settings.imageTypes) {
      setImagesSetting.add(setting._id);
      const hasImage = imagens?.find(img => img.tpImg === setting._id) || defaultImages?.find(img => img.tipoImagem === setting._id);

      setImagesSettingName.add({ text: `${setting.nome}`, hasImage: hasImage ? true : false, required: setting.required, imageType: setting._id });
    }
  }

  const setIntersection = new Set([...setImagesSetting].filter(x => !setImagesInMemory.has(x)));
  const description = Array.from(setImagesSettingName);
  const hasImageSettingWithoutImageInMemory = setIntersection.size > 0;

  useEffect(() => {
    setImagens(imagesOnboarding.filter(img => img.stepName === settings.name));
  }, []);

  const handleInputFileClick = () => {
    inputFileRef.current.click();
  };

  const convertToBase64 = async file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        resolve(reader.result);
      };

      reader.onerror = error => {
        reject(error);
      };
    });
  };

  const generateObjectId = (m = Math, d = Date, h = 16, s = s => m.floor(s).toString(h)) =>
    s(d.now() / 1000) + ' '.repeat(h).replace(/./g, () => s(m.random() * h));

  const handleFileChange = async (e) => {
    setLoadingImageProcess(true);

    let selectedFiles = e.target.files;

    //filtrar para somente seguir com arquivos de png, jpeg e pdf

    selectedFiles = Array.from(selectedFiles).filter((file) => {
      const fileType = file.type;
      return fileType === 'image/jpeg' || fileType === 'image/png' || fileType === 'application/pdf';
    });

    if (selectedFiles) {
      const currentImagens = [...imagens];
      let numAtual = 1;

      setTotalDocsSelected(selectedFiles.length);

      const limit = 10;
      const batches = [];

      for (let i = 0; i < selectedFiles.length; i += limit) {
        const batch = [...selectedFiles].slice(i, i + limit);
        batches.push(batch);
      }

      for (const batch of batches) {
        await Promise.all(
          batch.map(async (file) => {
            setNumeroDocAtual(numAtual);

            const base64 = await convertToBase64(file);

            const headerParams = {
              headers: { xkeyorg: organization },
            };

            const uniqueTypeImage = settings.imageTypes.length === 1;

            if (uniqueTypeImage) {
              currentImagens.push({
                _id: generateObjectId(),
                tpImg: settings.imageTypes[0]._id,
                file: base64,
                stepName: settings.name,
              });
              numAtual++;
              return;
            }

            const sendToAi = {
              tipoDocumento: settings.imageTypes[0].tipoDocumento,
              classification_items: settings.imageTypes.map((tp) => tp.nome),
              custom_fields: formFieldNames,
              image: base64.split(',')[1],
            };

            try {
              const { data: AIResp } = await api.post(`documento/fulldatafromai`, sendToAi, headerParams);

              const fieldsForUpdate = Object.keys(AIResp).map((key) => {
                if (AIResp[key] && AIResp[key].toLowerCase() === "null") {
                  return { nome: key, value: "" };
                }

                return { nome: key, value: AIResp[key] };
              });

              handleUpdateFields(fieldsForUpdate);

              const aiTpImg = settings.imageTypes.find(
                (tp) =>
                  tp.nome.toUpperCase() === AIResp.classification?.toUpperCase() ||
                  tp.nome.toUpperCase() === AIResp.classificacao?.toUpperCase()
              );

              currentImagens.push({
                _id: generateObjectId(),
                tpImg: aiTpImg ? aiTpImg._id : '',
                file: base64,
                stepName: settings.name,
              });
            } catch (error) {
              currentImagens.push({
                _id: generateObjectId(),
                tpImg: '',
                file: base64,
                stepName: settings.name,
              });

              console.log("Não foi possivel processar a tipificação");
            }

            numAtual++;
          })
        );
      }

      setImagens(currentImagens);

      const imagesToUpdate = currentImagens.filter(
        (img) => !imagesOnboarding.some((imgOnboarding) => imgOnboarding._id === img._id)
      );

      setImagesOnboarding([...imagesOnboarding, ...imagesToUpdate]);
    }

    setLoadingImageProcess(false);
  };

  const handleImageTypeChange = (value, id) => {
    const currentImagens = [...imagens];
    const index = currentImagens.findIndex(imagem => imagem._id === id);

    if (index !== -1) {
      currentImagens[index].tpImg = value;
      setImagens(currentImagens);
    } else {
      console.error('Imagem não encontrada com o _id fornecido.');
    }
  };

  const capture = React.useCallback(async () => {
    setLoadingImageProcess(true);

    const currentImagens = [...imagens];

    console.log("capture images", currentImagens)

    const imageSrc = webcamRef.current.getScreenshot();

    const headerParams = {
      headers: { xkeyorg: organization },
    };

    const uniqueTypeImage = settings.imageTypes.length === 1;
    if (uniqueTypeImage) {
      // currentImagens.push({
      //   _id: generateObjectId(),
      //   tpImg: settings.imageTypes[0]._id,
      //   file: imageSrc,
      //   stepName: settings.name,
      // });

      setImagens([...imagens, {
        _id: generateObjectId(),
        tpImg: settings.imageTypes[0]._id,
        file: imageSrc,
        stepName: settings.name,
      }]);

      setImagesOnboarding([...imagesOnboarding, {
        _id: generateObjectId(),
        tpImg: settings.imageTypes[0]._id,
        file: imageSrc,
        stepName: settings.name,
      }]);

      setPictureType(null);
      setLoadingImageProcess(false);
      return;
    }

    const sendToAi = {
      tipoDocumento: settings.imageTypes[0].tipoDocumento,
      classification_items: settings.imageTypes.map(tp => tp.nome),
      custom_fields: formFieldNames,
      image: imageSrc.split(',')[1],
    };

    const { data: AIResp } = await api.post(`documento/fulldatafromai`, sendToAi, headerParams);

    const fieldsForUpdate = Object.keys(AIResp).map(key => {
      return { nome: key, value: AIResp[key] };
    });

    handleUpdateFields(fieldsForUpdate);

    const aiTpImg = settings.imageTypes.find(tp => tp.nome.toUpperCase() === AIResp.classification?.toUpperCase());

    currentImagens.push({
      _id: generateObjectId(),
      tpImg: aiTpImg ? aiTpImg._id : '',
      file: imageSrc,
      stepName: settings.name,
    });

    setImagens(currentImagens);
    setImagesOnboarding(currentImagens);
    setPictureType(null);
    setLoadingImageProcess(false);
  }, [webcamRef, imagens, settings?.imageTypes]);

  const typesImagesStep = settings?.imageTypes ?? [];
  const imagensAccordingStep =
    imagens?.filter(img => !img.tpImg || typesImagesStep.some(tp => tp._id === img.tpImg)) ?? null;
  const activeWebcam = pictureType === 'camera';

  return (
    <Container>
      {loadingImageProcess ? (
        <LoadingOnboardingData>
          <CircularProgress size={60} />
          <h3>Processando documento {numeroDocAtual} de {totalDocsSelected}...</h3>
        </LoadingOnboardingData>
      ) : activeWebcam ? (
        <CameraContainer>
          <Webcam
            audio={false}
            height={'100%'}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            width={'100%'}
            videoConstraints={{ facingMode }}
          />
          <MaskCamera $maskType={settings.maskType} />
          <TakePhotoButton onClick={capture}>
            <FeatherIcon fontSize="medium" icon={Icons.Camera} />
          </TakePhotoButton>
          <SwitchCameraButton onClick={toggleCamera}>
            <FeatherIcon fontSize="medium" icon={Icons.RefreshCw} />
          </SwitchCameraButton>
        </CameraContainer>
      ) : (
        <>
          <ImageTextContainer dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(settings.content) }} />

          {!!hasImageSettingWithoutImageInMemory && (
            <PictureTypeSelectionContainer>
              <ImageLegendsContainer>
                <p>Os documentos solicitados nessa etapa são:</p>
                <ImageLegendsStatus>
                  <ImageLegendsStatusItem color="#f1416c"><span></span> Obrigatório não selecionado</ImageLegendsStatusItem>
                  <ImageLegendsStatusItem color="#50cd89"><span></span> Selecionado</ImageLegendsStatusItem>
                  <ImageLegendsStatusItem color="#DBDFE9"><span></span> Não obrigatório</ImageLegendsStatusItem>
                </ImageLegendsStatus>
              </ImageLegendsContainer>

              <ImageListNames className="mt-2">
                {description?.map((item, index) => (
                  <ImageListNamesItem hasImage={item.hasImage} required={item.required} key={index}>{item.text}</ImageListNamesItem>
                ))}
              </ImageListNames>
              <SelectionType $layout={layout}>
                <button onClick={() => setPictureType('camera')}>
                  <FeatherIcon fontSize="medium" icon={Icons.Camera} />
                  Tirar Foto
                </button>

                <button onClick={handleInputFileClick}>
                  <FeatherIcon fontSize="medium" icon={Icons.File} />
                  Selecionar arquivo(s)
                </button>
              </SelectionType>
              <input type="file" multiple ref={inputFileRef} style={{ display: 'none' }} onChange={handleFileChange} />
            </PictureTypeSelectionContainer>
          )}

          {defaultImages && (
            <ImagePreviewContainer>
              {defaultImages.filter(img => description.find(desc => desc.imageType === img.tipoImagem)).map((img, index) => (
                <ImagePreview key={index}>
                  <select defaultValue={img.tipoImagem} disabled={true}>
                    {settings.imageTypes.map(option => (
                      <option key={option._id} value={option._id}>
                        {option.nome}
                      </option>
                    ))}
                  </select>
                  <Thumb base64PDF={img.thumbnail} />
                  {/* <img src={img.file} alt="imagem capturada" /> */}
                </ImagePreview>
              ))}
            </ImagePreviewContainer>
          )}

          {imagens && (
            <ImagePreviewContainer>
              {imagens.map((img, index) => (
                <ImagePreview key={index}>
                  <select onChange={e => handleImageTypeChange(e.target.value, img._id)} defaultValue={img.tpImg}>
                    <option value="">Selecione o tipo de documento</option>
                    {settings.imageTypes.map(option => (
                      <option key={option._id} value={option._id}>
                        {option.nome}
                      </option>
                    ))}
                  </select>
                  <Thumb base64PDF={img.file} />
                  {/* <img src={img.file} alt="imagem capturada" /> */}
                </ImagePreview>
              ))}
            </ImagePreviewContainer>
          )}
        </>
      )}
    </Container>
  );
};

export default ImageStep;
