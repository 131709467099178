import api from "services/api";
import { getToken } from "services/auth";
import { call, select, put } from "redux-saga/effects";
import { toastr } from "react-redux-toastr";

import { Creators as ProductivityActions } from "store/ducks/productivity";

export const getOrganization = (state) => state.usuarios.auth.organization;

export function* createProductivity(action) {
  const organization = yield select(getOrganization);

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const body = { ...action.payload };

    const { data } = yield call(
      api.post,
      `dashboard/custom/productivity`,
      body,
      headerParams
    );

    yield put(ProductivityActions.createSuccess(data));
  } catch (error) {
    toastr.error("Ops", "Não foi possível listar a produtividade.");
  }
}

export function* exportProductivity({ payload }) {
  try {
    const organization = yield select(getOrganization);
    
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    toastr.success("", `Exportação agendada com sucesso.`);

    const { data } = yield call(
      api.get,
      `dashboard/custom/productivity/export`,
      headerParams
    );

    if (data.error) {
      toastr.error("", data.message);
      yield put(ProductivityActions.exportSuccess());
      return;
    }

    yield put(ProductivityActions.exportSuccess(data));
  } catch (error) {
    console.error(error);
    toastr.error("Ops", "Não foi possivel exportar os dados.");
  }
}
