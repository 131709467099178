export const Types = {

    NEW_RELACIONAMENTO_REQUEST: "relacionamento/CREATE_RELACIONAMENTO_REQUEST",
    NEW_RELACIONAMENTO_SUCCESS: "relacionamento/CREATE_RELACIONAMENTO_SUCCESS",

    LIST_RELACIONAMENTO_REQUEST: "relacionamento/LIST_RELACIONAMENTO_REQUEST",
    LIST_RELACIONAMENTO_SUCCESS: "relacionamento/LIST_RELACIONAMENTO_SUCCESS",

    EDIT_RELACIONAMENTO_REQUEST: "relacionamento/EDIT_RELACIONAMENTO_REQUEST",
    EDIT_RELACIONAMENTO_SUCCESS: "relacionamento/EDIT_RELACIONAMENTO_SUCCESS",

    DELETE_RELACIONAMENTO_REQUEST: "relacionamento/DELETE_RELACIONAMENTO_REQUEST",
    DELETE_RELACIONAMENTO_SUCCESS: "relacionamento/DELETE_RELACIONAMENTO_SUCCESS",

    EDIT_RELACIONAMENTO_PERMISSAO_REQUEST: "relacionamento/EDIT_RELACIONAMENTO_PERMISSAO_REQUEST",
    EDIT_RELACIONAMENTO_PERMISSAO_SUCCESS: "relacionamento/EDIT_RELACIONAMENTO_PERMISSAO_SUCCESS",

};

const INITIAL_STATE = {
    loading: false,
    list: [],
    sort: 'nome',
    page: 1,
    limit: 10,
    total: 0
};


export default function items(state = INITIAL_STATE, action) {

    switch (action.type) {

        case Types.NEW_RELACIONAMENTO_REQUEST:
            return { ...state, loading: true };

        case Types.NEW_RELACIONAMENTO_SUCCESS:
            return { ...state, loading: false };


        case Types.LIST_RELACIONAMENTO_REQUEST:
            return { ...state, loading: true };

        case Types.LIST_RELACIONAMENTO_SUCCESS:
            return { ...state, ...action.payload, loading: false };

        case Types.EDIT_RELACIONAMENTO_REQUEST:
            return { ...state };

        case Types.EDIT_RELACIONAMENTO_SUCCESS:
            return { ...state, loading: false };

        case Types.DELETE_RELACIONAMENTO_REQUEST:
            return { ...state, loading: true };

        case Types.DELETE_RELACIONAMENTO_SUCCESS:
            return { ...state, loading: false };

        case Types.EDIT_RELACIONAMENTO_PERMISSAO_REQUEST:
            return { ...state, loading: true };

        case Types.EDIT_RELACIONAMENTO_PERMISSAO_SUCCESS:
            return { ...state, loading: false };


        default:
            return state;
    }
};

export const Creators = {


    listRelacionamentoRequest: data => ({
        type: Types.LIST_RELACIONAMENTO_REQUEST,
        payload: data
    }),

    listRelacionamentoSuccess: data => ({
        type: Types.LIST_RELACIONAMENTO_SUCCESS,
        payload: data
    }),


    createRelacionamentoRequest: relacionamento => ({
        type: Types.NEW_RELACIONAMENTO_REQUEST,
        payload: relacionamento
    }),

    createRelacionamentoSuccess: () => ({
        type: Types.NEW_RELACIONAMENTO_SUCCESS,
        payload: {}
    }),


    editRelacionamentoRequest: relacionamento => ({
        type: Types.EDIT_RELACIONAMENTO_REQUEST,
        payload: relacionamento
    }),

    editRelacionamentoSuccess: () => ({
        type: Types.EDIT_RELACIONAMENTO_SUCCESS,
        payload: {}
    }),

    removeRelacionamentoRequest: id => ({
        type: Types.DELETE_RELACIONAMENTO_REQUEST,
        payload: id

    }),

    removeRelacionamentoSuccess: () => ({
        type: Types.DELETE_RELACIONAMENTO_SUCCESS,
        payload: {}
    }),

    editPermissaoRelacionanentoRequest: relacionamento => ({
        type: Types.EDIT_RELACIONAMENTO_PERMISSAO_REQUEST,
        payload: relacionamento
    }),

    editPermissaoRelacionanentoSuccess: auth => ({
        type: Types.EDIT_RELACIONAMENTO_PERMISSAO_SUCCESS,
        payload: auth
    }),


};