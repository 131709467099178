import React from "react";

import Dialog from "@material-ui/core/Dialog";
import no_filter from "../../../../../assets/svg/no_filter.svg";

import { Row, Col } from "react-bootstrap";

import { DialogContent } from "@material-ui/core";
import { Button, GroupButton, DialogTitle } from "./style";

function ModalSemFiltro({ open, handleClose, handlePrint, form }) {
  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        id="alert-dialog-title"
        style={{ minWidth: "600px" }}
      ></DialogTitle>
      <DialogContent
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img src={no_filter} alt="Filtro não disponível" />
        <span style={{ margin: "1rem" }}>
          Esse relatório não possui filtros disponíveis
        </span>
      </DialogContent>
      <GroupButton className="mt-2">
        <Button variant="contained" color="secondary" onClick={handleClose}>
          Cancelar
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() =>
            handlePrint({
            })
          }
        >
          Imprimir
        </Button>
      </GroupButton>
    </Dialog>
  );
}

export default ModalSemFiltro;
