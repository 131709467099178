export const Types = {
  LIST_REQUEST: "modalidades/LIST_REQUEST",
  LIST_SUCCESS: "modalidades/LIST_SUCCESS",
  SAVE_REQUEST: "modalidades/SAVE_REQUEST",
  DELETE_REQUEST: "modalidades/DELETE_REQUEST",
  DELETE_SUCCESS: "modalidades/DELETE_SUCCESS",
};

const INITIAL_STATE = {
  list: [],
  loading: false,
  page: 1,
  limit: 10,
  total: 0,
  sort: "nome",
};

export default function modalidades(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.LIST_REQUEST:
      return { ...state, ...action.payload, loading: true };

    case Types.LIST_SUCCESS:
      return { ...state, ...action.payload, loading: false };

    case Types.DELETE_REQUEST:
      return { ...state, loading: true };

    case Types.DELETE_SUCCESS:
      return { ...state, loading: false };

    default:
      return state;
  }
}

export const Creators = {
  listRequest: (payload) => ({
    type: Types.LIST_REQUEST,
    payload,
  }),
  listSuccess: (payload) => ({
    type: Types.LIST_SUCCESS,
    payload,
  }),
  saveRequest: (payload) => ({
    type: Types.SAVE_REQUEST,
    payload,
  }),
  deleteRequest: (payload) => ({
    type: Types.DELETE_REQUEST,
    payload,
  }),
  deleteSuccess: () => ({
    type: Types.DELETE_SUCCESS,
    payload: {},
  }),
};
