export const Types = {
    LIST_REQUEST: "formularios/LIST_REQUEST",
    LIST_SUCCESS: "formularios/LIST_SUCCESS",
    NEW_REQUEST: "formularios/NEW_REQUEST",
    NEW_SUCCESS: "formularios/NEW_SUCCESS",
    GET_REQUEST: "formularios/GET_REQUEST",
    GET_SUCCESS: "formularios/GET_SUCCESS",
    EDIT_REQUEST: "formularios/EDIT_REQUEST",
    EDIT_SUCCESS: "formularios/EDIT_SUCCESS",
    SEND_REQUEST: "formularios/SEND_REQUEST",
    SEND_SUCCESS: "formularios/SEND_SUCCESS",
    DELETE_REQUEST: "formularios/DELETE_REQUEST",
    DELETE_SUCCESS: "formularios/DELETE_SUCCESS",
    VALIDATE_REQUEST: "formularios/VALIDATE_REQUEST",
    VALIDATE_SUCCESS: "formularios/VALIDATE_SUCCESS",
    RESPONSE_REQUEST: "formularios/RESPONSE_REQUEST",
    RESPONSE_SUCCESS: "formularios/RESPONSE_SUCCESS",
    GET_NOAUTH_REQUEST: "formularios/GET_NOAUTH_REQUEST",
    GET_NOAUTH_SUCCESS: "formularios/GET_NOAUTH_SUCCESS",
};

const INITIAL_STATE = {
    loading: false,
    list: [],
    sort: "nome",
    page: 1,
    limit: 10,
    total: 0,
    formulario: {},
    informacoes: {},
};

export default function formularios(state = INITIAL_STATE, action) {
    switch (action.type) {
        case Types.LIST_REQUEST:
            return { ...state, loading: true };

        case Types.LIST_SUCCESS:
            return { ...state, ...action.payload, loading: false };

        case Types.NEW_REQUEST:
            return { ...state, loading: true };

        case Types.NEW_SUCCESS:
            return { ...state, loading: false };

        case Types.GET_REQUEST:
            return { ...state, loading: true };

        case Types.GET_SUCCESS:
            return { ...state, formulario: action.payload, loading: false };

        case Types.EDIT_REQUEST:
            return { ...state, loading: true };

        case Types.EDIT_SUCCESS:
            return { ...state, loading: false };

        case Types.DELETE_REQUEST:
            return { ...state, loading: true };

        case Types.DELETE_SUCCESS:
            return { ...state, loading: false };

        case Types.SEND_REQUEST:
            return { ...state, loading: true };

        case Types.SEND_SUCCESS:
            return { ...state, loading: false };

        case Types.VALIDATE_REQUEST:
            return { ...state, loading: true };

        case Types.VALIDATE_SUCCESS:
            return { ...state, informacoes: action.payload, loading: false };

        case Types.RESPONSE_REQUEST:
            return { ...state, loading: true };

        case Types.RESPONSE_SUCCESS:
            return { ...state, loading: false };

        case Types.GET_NOAUTH_REQUEST:
            return { ...state, loading: true };

        case Types.GET_NOAUTH_SUCCESS:
            return { ...state, formulario: action.payload, loading: false };

        default:
            return state;
    }
}

export const Creators = {
    deleteRequest: (data) => ({
        type: Types.DELETE_REQUEST,
        payload: data,
    }),

    deleteSuccess: () => ({
        type: Types.DELETE_SUCCESS,
        payload: {},
    }),

    listRequest: (data) => ({
        type: Types.LIST_REQUEST,
        payload: data,
    }),

    listSuccess: (data) => ({
        type: Types.LIST_SUCCESS,
        payload: data,
    }),

    getRequest: (id) => ({
        type: Types.GET_REQUEST,
        payload: id,
    }),

    getSuccess: (data) => ({
        type: Types.GET_SUCCESS,
        payload: data,
    }),

    editRequest: (data) => ({
        type: Types.EDIT_REQUEST,
        payload: data,
    }),

    editSuccess: () => ({
        type: Types.EDIT_SUCCESS,
        payload: {},
    }),

    createRequest: (data) => ({
        type: Types.NEW_REQUEST,
        payload: data,
    }),

    createSuccess: () => ({
        type: Types.NEW_SUCCESS,
        payload: {},
    }),

    sendRequest: (data) => ({
        type: Types.SEND_REQUEST,
        payload: data,
    }),

    sendSuccess: () => ({
        type: Types.SEND_SUCCESS,
        payload: {},
    }),

    validateRequest: (data) => ({
        type: Types.VALIDATE_REQUEST,
        payload: data,
    }),

    validateSuccess: (data) => ({
        type: Types.VALIDATE_SUCCESS,
        payload: data,
    }),

    responseRequest: (data) => ({
        type: Types.RESPONSE_REQUEST,
        payload: data,
    }),

    responseSuccess: () => ({
        type: Types.RESPONSE_SUCCESS,
        payload: {},
    }),

    getNoAuthRequest: (id) => ({
        type: Types.GET_NOAUTH_REQUEST,
        payload: id,
    }),

    getNoAuthSuccess: (data) => ({
        type: Types.GET_NOAUTH_SUCCESS,
        payload: data,
    }),

};
