import React, { useEffect } from "react";

import { Creators as AuditoriaActions } from "store/ducks/auditoria";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "react-bootstrap";

import Alert from "@material-ui/lab/Alert";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import StyledTableRow from "components/StyledTableRow";
import TableContainer from "@material-ui/core/TableContainer";
import { LabelSwapper } from "../LabelSwapper";
import CircularProgress from "@material-ui/core/CircularProgress";
import Utils from "utils/utils";

function HistoricoWF({ documento }) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (documento._id) {
      const idDocumento = documento._id;
      dispatch(AuditoriaActions.listDocumentoWFRequest(idDocumento));
    }
  }, [dispatch, documento]);

  const { loadingHistoricoWF: loading, historicoWF } = useSelector(
    (state) => state.auditoria
  );

  const { loadingDocumento } = useSelector((state) => state.documentos);

  return (
    <div className="m-2">
      {loading || loadingDocumento ? (
        <div className="historico-container">
          <CircularProgress />
        </div>
      ) : (
        <div className="panels">
          <Alert className="mb-4" severity="info">
            <LabelSwapper
              text={"Histórico é carregado ao encerrar o prontuário"}
              parent={"HistoricoWF"}
              name={"alert"}
            />
          </Alert>
          <Row>
            <Col md="12" className="painel">
              <div className="painel-content">
                <div className="painel-title text-left">Histórico de fluxo</div>
                <div className="painel-body">
                  <TableContainer>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Tarefa</TableCell>
                          <TableCell>Etapa</TableCell>
                          <TableCell>Usuário</TableCell>
                          <TableCell>Início</TableCell>
                          <TableCell>Fim</TableCell>
                          <TableCell>Duração total</TableCell>
                          <TableCell>Duração produtiva</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <>
                          {historicoWF.length === 0 ? (
                            <StyledTableRow>
                              <TableCell colSpan={7}>Sem informações</TableCell>
                            </StyledTableRow>
                          ) : (
                            historicoWF.map((item) => (
                              <StyledTableRow>
                                <TableCell>{item.process}</TableCell>
                                <TableCell>{item.task}</TableCell>
                                <TableCell>{item.usuario}</TableCell>
                                <TableCell>{item.startTime}</TableCell>
                                <TableCell>{item.endTime}</TableCell>
                                <TableCell>{item.duration}</TableCell>
                                <TableCell>
                                  {Utils.decimalToTimeString(
                                    Number(item.productiveHours)
                                  )}
                                </TableCell>
                              </StyledTableRow>
                            ))
                          )}
                        </>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
}

export default HistoricoWF;
