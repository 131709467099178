import React from 'react';

function Identificacao({ identificacao }) {
  return (
    identificacao.map((item, index) => 
      <React.Fragment key={index}>
        {item.chave}:&nbsp;{item.valor}<br />
      </React.Fragment>
    )
  );
}

export default Identificacao;