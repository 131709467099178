export const Types = {
  LIST_REQUEST: 'providencias/LIST_REQUEST',
  LIST_SUCCESS: 'providencias/LIST_SUCCESS',
  LIST_RECEIVED_REQUEST: 'providencias/LIST_RECEIVED_REQUEST',
  LIST_RECEIVED_SUCCESS: 'providencias/LIST_RECEIVED_SUCCESS',
  LIST_SENT_REQUEST: 'providencias/LIST_SENT_REQUEST',
  LIST_SENT_SUCCESS: 'providencias/LIST_SENT_SUCCESS',
  UNREAD_REQUEST: 'providencias/UNREAD_REQUEST',
  UNREAD_SUCCESS: 'providencias/UNREAD_SUCCESS',
  MARK_AS_READ_REQUEST: 'providencias/MARK_AS_READ_REQUEST',
  MARK_AS_READ_SUCCESS: 'providencias/MARK_AS_READ_SUCCESS',
  OPEN_MODAL_TASK_DETAILS: 'providencias/OPEN_MODAL_TASK_DETAILS',
  CLOSE_MODAL_TASK_DETAILS: 'providencias/CLOSE_MODAL_TASK_DETAILS',
  HANDLE_MODAL_IMAGES: 'providencias/HANDLE_MODAL_IMAGES',
  HANDLE_MODAL_UPLOAD: 'providencias/HANDLE_MODAL_UPLOAD',
  HANDLE_MODAL_EDIT_TASK: 'providencias/HANDLE_MODAL_EDIT_TASK',
  TASK_APPROVAL_SUCCESS: 'providencias/TASK_APPROVAL_SUCCESS',
  SELECT_TASK: 'providencias/SELECT_TASK',
  CLEAR_STATE_ON_UNMOUNT: 'providencias/CLEAR_STATE_ON_UNMOUNT',
} 

const INITIAL_STATE = {
  list: [],
  loading: false,
  page: 1,
  limit: 10,
  total: 0,
  search: '',
  SLA: '',
  sort: '-createdAt',
  naoLidas: 0,
  openModalTaskDetails: false,
  openModalImages: false,
  openModalUpload: false,
  openModalEditTask: false,
  selectedTask: null,
}

export default function providencias(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.LIST_REQUEST:
      return {
        ...state,
        ...action.payload,
        list: [],
        loading: true
      }

    case Types.LIST_SUCCESS:
      return { 
        ...state,
        total: action.payload.total,
        list: action.payload.list,
        loading: false
      }

    case Types.LIST_RECEIVED_REQUEST:
      return {
        ...state,
        ...action.payload,
        list: [],
        loading: true
      }

    case Types.LIST_RECEIVED_SUCCESS:
      return {
        ...state,
        total: action.payload.total,
        list: action.payload.list,
        loading: false
      }

    case Types.LIST_SENT_REQUEST:
      return {
        ...state,
        ...action.payload,
        list: [],
        loading: true
      }

    case Types.LIST_SENT_SUCCESS:
      return {
        ...state,
        total: action.payload.total,
        list: action.payload.list,
        loading: false
      }

    case Types.UNREAD_SUCCESS:
      return {
        ...state,
        naoLidas: action.payload.naoLidas
      }

    case Types.MARK_AS_READ_SUCCESS:
      return {
        ...state,
        naoLidas: state.naoLidas -1,
        list: state.list.map(mensagem => ({
          ...mensagem,
          lida: mensagem._id === action.payload._id ? 
            true : mensagem.lida
        }))
      }
    
    case Types.UNREAD_SUCCESS:
      return {
        ...state,
        loading: true,
      }

    case Types.OPEN_MODAL_TASK_DETAILS:
      return {
        ...state,
        openModalTaskDetails: true,
        selectedTask: action.payload,
      }

    case Types.CLOSE_MODAL_TASK_DETAILS:
      return {
        ...state,
        openModalTaskDetails: false,
      }

    case Types.SELECT_TASK:
      return {
        ...state,
        selectedTask: action.payload,
      }
    
    case Types.TASK_APPROVAL_SUCCESS:
      const newListWithApprovalChangedTask = state.list.map(
        task => task._id !== action.payload._id
                ? task
                : ({...task, aprovado: !task.aprovado })
      );

      return {
        ...state,
        list: newListWithApprovalChangedTask,
        selectedTask: action.payload,
      }

    case Types.HANDLE_MODAL_EDIT_TASK:
      return {
        ...state,
        openModalEditTask: action.payload,
      }  

    case Types.HANDLE_MODAL_IMAGES:
      return {
        ...state,
        openModalImages: action.payload,
      } 

    case Types.HANDLE_MODAL_UPLOAD:
      return {
        ...state,
        openModalUpload: action.payload,
      }      

    case Types.CLEAR_STATE_ON_UNMOUNT:
      return INITIAL_STATE;
    
    default:
      return state;
  }
}

export const Creators = {
  listRequest: params => ({
    type: Types.LIST_REQUEST,
    payload: params
  }),
  listSuccess: (list, total) => ({
    type: Types.LIST_SUCCESS,
    payload: { list, total }
  }),
  listReceivedRequest: params => ({
    type: Types.LIST_RECEIVED_REQUEST,
    payload: params
  }),
  listReceivedSuccess: (list, total) => ({
    type: Types.LIST_RECEIVED_SUCCESS,
    payload: { list, total }
  }),
  listSentRequest: params => ({
    type: Types.LIST_SENT_REQUEST,
    payload: params
  }),
  listSentSuccess: (list, total) => ({
    type: Types.LIST_SENT_SUCCESS,
    payload: { list, total }
  }),
  unreadRequest: () => ({
    type: Types.UNREAD_REQUEST
  }),
  unreadSuccess: naoLidas => ({
    type: Types.UNREAD_SUCCESS,
    payload: { naoLidas }
  }),
  markAsReadRequest: _id => ({
    type: Types.MARK_AS_READ_REQUEST,
    payload: { _id }
  }),
  markAsReadSuccess: _id => ({
    type: Types.MARK_AS_READ_SUCCESS,
    payload: { _id }
  }),
  openModalTaskDetails: task => ({
    type: Types.OPEN_MODAL_TASK_DETAILS,
    payload: task,
  }),
  closeModalTaskDetails: () => ({
    type: Types.CLOSE_MODAL_TASK_DETAILS,
  }),
  selectTask: task => ({
    type: Types.SELECT_TASK,
    payload: task,
  }),
  taskApprovalSuccess: taskId => ({
    type: Types.TASK_APPROVAL_SUCCESS,
    payload: taskId,
  }),
  handleModalImages: open => ({
    type: Types.HANDLE_MODAL_IMAGES,
    payload: open,
  }),
  handleModalUpload: open => ({
    type: Types.HANDLE_MODAL_UPLOAD,
    payload: open,
  }),
  handleModalEditTask: open => ({
    type: Types.HANDLE_MODAL_EDIT_TASK,
    payload: open,
  }),
  clearStateOnUnmount: () => ({
    type: Types.CLEAR_STATE_ON_UNMOUNT,
  }),
}