import { call, put, select } from "redux-saga/effects";

import { toastr } from "react-redux-toastr";
import { push } from "connected-react-router";

import api from "../../services/api";
import { getToken } from "../../services/auth";

import { Creators as DepartamentosActions } from "../ducks/departamentos";

export const getOrganization = (state) => state.usuarios.auth.organization;
export const getUserId = (state) => state.usuarios.auth.id;
export const departamentosAccess = (state) =>
  state.permissoes.access.departamentos;

export function* listDepartamentos({ payload }) {
  try {
    const organization = yield select(getOrganization);

    const { page, limit, sort, all, search, onlyOperational } = payload;

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
      params: { page, limit, sort, all, search, onlyOperational },
    };

    const response = yield call(api.post, `departamentos`, null, headerParams);

    const data = {
      total: response.data.total,
      list: response.data.docs,
    };

    yield put(DepartamentosActions.listSuccess(data));
  } catch (error) {
    toastr.error("Ops", "Não foi possível listar os departamentos.");
  }
}

export function* listDepartamentosByEmpresa(action) {
  const organization = yield select(getOrganization);

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const { data } = yield call(
      api.post,
      `departamento/empresa`,
      action.payload,
      headerParams
    );

    yield put(DepartamentosActions.listByEmpresaSuccess(data));
  } catch (error) {
    toastr.error("Ops", "Não foi possível listar os departamentos da empresa");
  }
}

export function* getDepartamento(action) {
  try {
    const headerParams = {
      headers: { Authorization: getToken() },
    };

    const { data } = yield call(
      api.get,
      `departamento/${action.payload}`,
      headerParams
    );

    yield put(DepartamentosActions.getSuccess(data));
  } catch (error) {
    toastr.error("Ops", "Não foi possível buscar os dados do departamento.");
  }
}

export function* editDepartamento(action) {
  const userId = yield select(getUserId);

  try {
    const organization = yield select(getOrganization);

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const body = { ...action.payload, id_user_acao: userId };

    const { data } = yield call(
      api.put,
      `departamento/${action.payload._id}`,
      body,
      headerParams
    );

    yield put(push(`/administracao/departamentos`));

    yield put(DepartamentosActions.editSuccess(data));

    toastr.success("Departamento editado com sucesso.");
  } catch (error) {
    toastr.error("Ops", "Não foi possível editar o departamento.");
  }
}

export function* createDepartamento(action) {
  const organization = yield select(getOrganization);

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const { data } = yield call(
      api.post,
      `departamento`,
      action.payload,
      headerParams
    );

    yield put(push(`/administracao/departamentos`));
    yield put(DepartamentosActions.createSuccess(data));

    toastr.success("", "Departamento criado com sucesso.");
  } catch (error) {
    toastr.error("Ops", "Não foi possível criar o departamento.");
  }
}

export function* removeDepartamento(action) {
  try {
    const organization = yield select(getOrganization);
    const userId = yield select(getUserId);

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization, userId },
    };

    yield call(api.delete, `departamento/${action.payload}`, headerParams);

    toastr.success("", "Departamento removido com sucesso.");

    const departamentos = yield select(departamentosAccess);

    yield put(
      DepartamentosActions.listRequest({
        page: 1,
        limit: 10,
        sort: "nome",
        departamentos,
      })
    );
  } catch (error) {
    //   toastr.error("Ops", "Não foi possível remover os departamentos.");
  }
}
