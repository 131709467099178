import React from 'react';
import './style.css';

export default function AppLoader() {
  return (
    <div className="holder">
  <div className="preloader"><div></div><div></div></div>
</div>
  );
}
