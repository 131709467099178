import React, { useState, useRef, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import { Row, Col } from "react-bootstrap";
import { Form } from "@unform/web";

import CircularProgress from "@material-ui/core/CircularProgress";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DefaultInput from "components/DefaultInput";
import ButtonMui from "@material-ui/core/Button";
import Skeleton from "@material-ui/lab/Skeleton";
import BackButton from "components/BackButton";
import Grid from "@material-ui/core/Grid";

import TextField from "@material-ui/core/TextField";

import { Creators as DepartamentoEscalonamentoActions } from "store/ducks/departamentoescalonamento";
import { Creators as GrupoEmailActions } from "store/ducks/grupoemail";

import { toastr } from "react-redux-toastr";
import Content from "components/Content";

export default function Escalonamento(props) {
  const { depto } = props.location.state;

  const dispatch = useDispatch();
  const formRef = useRef();

  const horas_default = 2;

  const initial_form = {
    idDepartamento: depto._id,
    grupoEmails: [
      {
        nivel: 1,
        horas: horas_default,
        _id: null,
      },
      {
        nivel: 2,
        horas: horas_default,
        _id: null,
      },
      {
        nivel: 3,
        horas: horas_default,
        _id: null,
      },
      {
        nivel: 4,
        horas: horas_default,
        _id: null,
      },
      {
        nivel: 5,
        horas: horas_default,
        _id: null,
      },
    ],
  };

  const [form, setForm] = useState(depto.escalonamento || initial_form);

  const { loading, list: grupoemail } = useSelector(
    (state) => state.grupoemail
  );

  useEffect(() => {
    dispatch(
      GrupoEmailActions.listRequest({
        page: 1,
        limit: 9999,
      })
    );
  }, []);

  function handleGrupoChange(gr, { target }) {
    const { value, name } = target;

    gr[name] = value;

    setForm((prev) => ({
      ...form,
      grupoEmails: [
        ...(prev.grupoEmails.filter(function (x) {
          return x.nivel != gr.nivel;
        }) || []),
        ...[gr],
      ],
    }));
  }

  const onSubmitEscalonamento = () => {
    try {
      dispatch(DepartamentoEscalonamentoActions.createRequest(form));
    } catch (err) {
      return toastr.error("Ops", "Ocorreu um erro ao salvar o escalonamento");
    }
  };

  return (
    <Content>
      <Row>
        <Col md="12" className="painel">
          <div className="painel-content">
            <div className="painel-title text-left">
              Configuração de Escalonamento
            </div>
            <div className="painel-body">
              <Form
                ref={formRef}
                onSubmit={onSubmitEscalonamento}
                className={"mt-2"}
              >
                {!!loading && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress color="primary" size={20} />
                  </div>
                )}
                {!loading &&
                  form.grupoEmails
                    ?.sort((x, y) => (x.nivel > y.nivel ? 1 : -1))
                    .map((gr) => (
                      <Row key={gr.nivel}>
                        <Col sm={12}>
                          <Row>
                            <Col sm={6} md={1}>
                              <TextField
                                style={{ marginTop: "0.6rem" }}
                                required
                                disabled={true}
                                label="Nível"
                                name="nivel"
                                variant="outlined"
                                value={gr.nivel}
                                size="small"
                                type="number"
                                InputProps={{
                                  inputProps: { min: 1, max: 5 },
                                }}
                              />
                            </Col>
                            <Col sm={6} md={1}>
                              <TextField
                                style={{ marginTop: "0.6rem" }}
                                required
                                label="Horas"
                                name="horas"
                                variant="outlined"
                                value={gr.horas}
                                onChange={(event) =>
                                  handleGrupoChange(gr, event)
                                }
                                size="small"
                                type="number"
                                InputProps={{
                                  inputProps: { min: 1, max: 24 },
                                }}
                              />
                            </Col>
                            <Col sm={12} md={10}>
                              {!loading ? (
                                <Autocomplete
                                  options={grupoemail || []}
                                  getOptionLabel={(option) =>
                                    typeof option === "string"
                                      ? grupoemail?.find(
                                        (x) => x._id === option
                                      )?.nome || ""
                                      : option.nome
                                  }
                                  getOptionSelected={(option, value) =>
                                    option._id === value
                                  }
                                  value={gr._id || null}
                                  onChange={(event, newValue) => {
                                    handleGrupoChange(gr, {
                                      target: {
                                        value: newValue?._id,
                                        name: "_id",
                                      },
                                    });
                                  }}
                                  renderInput={(params) => (
                                    <DefaultInput
                                      {...params}
                                      name="grupoId"
                                      label="Grupo de e-mail"
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                    />
                                  )}
                                />
                              ) : (
                                <Skeleton width="100%" height={50} />
                              )}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    ))}
                <Row>
                  <Grid
                    style={{ marginLeft: "1rem" }}
                    container
                    spacing={1}
                    className="mt-2 mb-2"
                  >
                    <Grid item>
                      <ButtonMui
                        variant="contained"
                        color="primary"
                        type="submit"
                      >
                        Salvar
                      </ButtonMui>
                    </Grid>
                    <Grid item>
                      <BackButton variant="contained" color="secondary">
                        Voltar
                      </BackButton>
                    </Grid>
                  </Grid>
                </Row>
              </Form>
            </div>
          </div>
        </Col>
      </Row>
    </Content>
  );
}
