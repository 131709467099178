import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import MergeProcess from './MergeProcess';

import { Creators as MergepdfActions } from '../../store/ducks/mergepdf';

import './styles.css';

function MergePDF() {

  const dispatch = useDispatch();
  const mergepdf = useSelector(state => state.mergepdf);

  const [queue, setQueue] = useState([]);

  useEffect(() => {
    if (mergepdf.filesToMerge) {
      setQueue(q => ([
        ...q, mergepdf
      ]))
      dispatch(MergepdfActions.clear());
    }
  }, [mergepdf, dispatch]);

  return (
    <div className="mergepdf-container">
      {queue.map((item, index) => 
        <MergeProcess 
          key={index} 
          data={item} 
        />
      )}
    </div>
  );
}

export default MergePDF;