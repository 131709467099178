import React, { useEffect, useRef, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap';

import { Creators as TiposDocumentoActions } from 'store/ducks/tiposdocumento';
import { Creators as DepartamentosActions } from 'store/ducks/departamentos';
import { Creators as TiposTarefasActions } from 'store/ducks/tipostarefas';
import { Creators as InstanciaActions } from 'store/ducks/instancia';
import { Creators as EmpresasActions } from 'store/ducks/empresas';
import { Creators as UsuariosAction } from 'store/ducks/usuarios';

import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import DefaultInput from 'components/DefaultInput';
import Skeleton from '@material-ui/lab/Skeleton';
import ButtonMui from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Alert from '@material-ui/lab/Alert';
import Fab from '@material-ui/core/Fab';
import Box from '@material-ui/core/Box';
import { DeleteForeverIcon } from 'components/Icons';

const INITIAL_FORM = {
  rules: [],
};

const DEFAULT_OPTIONS = ['Igual', 'Diferente', 'Contém', ''];

const DefaultValueField = ({ condition, onChange, conditionHashValues }) => (
  <>
    <TextField
      autoComplete="off"
      label="Valor"
      name="valor"
      value={condition.valor}
      disabled={!conditionHashValues[condition.hash]}
      variant="outlined"
      size="small"
      onChange={onChange}
    />
  </>
);

function FormRules({
  rules,
  atributes,
  handleRule,
  removeRule,
  handleNewRule,
  handleCondition,
  handleNewCondition,
  handleRemoveCondition,
  fieldsOptions = {},
  idEsRenderField = {},
  isSLA,
}) {
  const dispatch = useDispatch();
  const formRef = useRef(null);
  const [form, setForm] = useState(INITIAL_FORM);

  const [conditionHashValues, setConditionHashValues] = useState(
    Object.fromEntries(
      rules.flatMap(({ conditions }) =>
        conditions.map(({ hash, atributo, condicao, valor }) => [
          hash,
          { atributo, condicao, valor },
        ])
      )
    )
  );

  const {
    access: { empresas },
  } = useSelector((state) => state.permissoes);

  const { list: listUsuarios, loading: loadingUsuarios } = useSelector(
    (state) => state.usuarios
  );

  const { list: empresasList, loading: loadingEmpresas } = useSelector(
    (state) => state.empresas
  );

  const { list: listTiposTarefas, loading: loadingTipoTarefas } = useSelector(
    (state) => state.tipostarefas
  );

  const { list: departamentosList, loading: loadingDepartamentos } =
    useSelector((state) => state.departamentos);

  const { list: listInstancia, loading: loadingInstancia } = useSelector(
    (state) => state.instancia
  );

  useEffect(() => {
    dispatch(UsuariosAction.listRequest({ limit: 999, page: 1, sort: 'nome' }));
    dispatch(EmpresasActions.listRequest());
    dispatch(TiposDocumentoActions.listByEmpresaRequest(empresas));
    dispatch(DepartamentosActions.listRequest());
    dispatch(
      TiposTarefasActions.listRequest({
        limit: 100,
        page: 1,
        sort: 'nome',
      })
    );
    dispatch(
      InstanciaActions.listRequest({ page: 1, limit: Number.MAX_SAFE_INTEGER })
    );
  }, [dispatch, empresas]);

  const getFieldOptions = (fieldName) => {
    return fieldsOptions[fieldName] || DEFAULT_OPTIONS;
  };

  useEffect(() => {
    if (listTiposTarefas.length === 1) {
      const [tipoTarefa] = listTiposTarefas;
      setForm((f) => ({ ...f, tipoTarefa }));
    }
  }, []);

  return (
    <Col md={12}>
      <ButtonMui
        variant="contained"
        color="primary"
        size="small"
        onClick={() => handleNewRule()}
        style={{ marginBottom: '1.2rem' }}
      >
        Adicionar regra
      </ButtonMui>
      <Alert
        severity="warning"
        style={rules?.length === 0 ? {} : { display: 'none' }}
      >
        Necessário configurar ao menos uma regra
      </Alert>
      {rules
        ?.sort((x, y) => (x.hash > y.hash ? 1 : -1))
        ?.map((rule) => (
          <Box
            key={rule.hash}
            border={1}
            borderColor="#e5e5e5"
            borderRadius={11}
            style={{ marginBottom: '1.2rem' }}
          >
            <Col md={12} sm={12}>
              <Row>
                <Col md={3} sm={12}>
                  <TextField
                    required
                    style={{ marginTop: '1.2rem' }}
                    autoComplete="off"
                    label="Nome da regra"
                    name="nome"
                    variant="outlined"
                    size="small"
                    value={rule.nome}
                    onChange={(event) =>
                      handleRule(rule, 'nome', event.target.value)
                    }
                  />
                </Col>
                <Col md={3} sm={12}>
                  <Fab
                    color="primary"
                    aria-label="add"
                    size="small"
                    style={{ marginTop: '1.2rem' }}
                    onClick={() => removeRule(rule.hash)}
                  >
                    <DeleteForeverIcon />
                  </Fab>
                </Col>
              </Row>
              <small
                style={{
                  marginLeft: '0px',
                  textAlign: 'start',
                  display: 'block',
                }}
              >
                Condições da regra
              </small>
              <Divider />
              <Row>
                <Col md={3} sm={12}>
                  <ButtonMui
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => handleNewCondition(rule.hash)}
                    style={{ marginTop: '1.2rem' }}
                  >
                    Adicionar condição
                  </ButtonMui>
                </Col>
              </Row>
              {rule?.conditions
                ?.sort((x, y) => (x.hash > y.hash ? 1 : -1))
                .map((condition) => (
                  <Row key={condition.hash}>
                    <Col md={3} sm={12}>
                      <Autocomplete
                        autoHighlight={true}
                        options={atributes}
                        disableClearable
                        getOptionLabel={(option) =>
                          typeof option === 'object' ? option.nome : option
                        }
                        getOptionSelected={(option, value) =>
                          option?.id_es === value
                        }
                        value={atributes.find(
                          (atribute) => atribute.id_es === condition.atributo
                        )}
                        onChange={(event, newValue) => {
                          handleCondition(
                            rule,
                            condition,
                            'atributo',
                            newValue?.id_es || null
                          );

                          setForm((prev) => prev);

                          setConditionHashValues((prev) => ({
                            ...prev,
                            [condition.hash]: {
                              ...prev[condition.hash],
                              atributo: condition.atributo,
                              id_es: newValue?.id_es || null,
                            },
                          }));
                        }}
                        renderInput={(params) => (
                          <DefaultInput
                            {...params}
                            name="atributo"
                            label="Atributo"
                            size="small"
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        )}
                      />
                    </Col>
                    <Col md={3} sm={12}>
                      <Autocomplete
                        key={getFieldOptions(condition.atributo).join('')}
                        autoHighlight={true}
                        options={getFieldOptions(condition.atributo)}
                        getOptionLabel={(option) => option}
                        getOptionSelected={(option, value) => option === value}
                        value={getFieldOptions(condition.atributo).find(
                          (option) => option === condition.condicao
                        )}
                        onChange={(event, newValue) => {
                          handleCondition(
                            rule,
                            condition,
                            'condicao',
                            newValue
                          );
                        }}
                        disabled={
                          !conditionHashValues[condition.hash]?.atributo
                        }
                        renderInput={(params) => (
                          <DefaultInput
                            {...params}
                            name="condicao"
                            label="Condição"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            required
                          />
                        )}
                      />
                    </Col>
                    <Col md={3} sm={12} style={{ marginTop: '0.6rem' }}>
                      {(
                        idEsRenderField[condition.atributo] || DefaultValueField
                      )({
                        condition,
                        conditionHashValues,
                        onChange: (event) => {
                          handleCondition(
                            rule,
                            condition,
                            'valor',
                            event.target.value
                          );
                        },
                      })}
                    </Col>
                    <Col md={3} sm={12}>
                      <Fab
                        color="primary"
                        aria-label="add"
                        size="small"
                        onClick={() =>
                          handleRemoveCondition(rule.hash, condition.hash)
                        }
                        style={{ marginTop: '0.6rem' }}
                      >
                        <DeleteForeverIcon />
                      </Fab>
                    </Col>
                  </Row>
                ))}
              <small
                style={{
                  marginLeft: '0px',
                  textAlign: 'start',
                  display: 'block',
                }}
              >
                Destino
              </small>
              <Divider />
              <Row style={{ marginTop: '0.6rem' }}>
                {isSLA ? (
                  <>
                    <Col md={3} sm={12}>
                      <TextField
                        label="Minutos"
                        variant="outlined"
                        type="number"
                        size="small"
                        value={rule.destinations?.valor || 0}
                        InputProps={{ inputProps: { min: 1, max: 99 } }}
                        onChange={(e) =>
                          handleRule(rule, 'valor', e.target.value || null)
                        }
                      />
                    </Col>
                    <Col md={3} sm={12}>
                      {!loadingInstancia && (
                        <Autocomplete
                          id="combo-box-instancia"
                          options={listInstancia}
                          onChange={(event, newValue) => {
                            handleRule(
                              rule,
                              'instancia',
                              newValue?._id || null
                            );
                          }}
                          value={
                            listInstancia.find(
                              (x) => x._id === rule.destinations?.instancia
                            ) || null
                          }
                          getOptionSelected={(option, value) =>
                            option._id === value._id
                          }
                          getOptionLabel={(option) => option.nome}
                          size="small"
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              required
                              label="Instancia"
                              variant="outlined"
                            />
                          )}
                        />
                      )}
                      {!!loadingInstancia && (
                        <Skeleton width="100%" height={50} />
                      )}
                    </Col>
                  </>
                ) : (
                  <>
                    <Col md={3} sm={12}>
                      {loadingEmpresas ? (
                        <Skeleton width="100%" height={50} />
                      ) : (
                        <Autocomplete
                          options={empresasList}
                          value={
                            empresasList.find(
                              (x) => x._id === rule.destinations?.empresa
                            ) || null
                          }
                          getOptionLabel={(option) => option?.fantasia}
                          getOptionSelected={(option, value) =>
                            option?.id === value?.id
                          }
                          onChange={(event, newValue) => {
                            handleRule(rule, 'empresa', newValue?._id || null);
                          }}
                          renderInput={(params) => (
                            <DefaultInput
                              {...params}
                              name="empresa"
                              label="Empresa"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              required
                            />
                          )}
                        />
                      )}
                    </Col>
                    <Col md={3} sm={12}>
                      {loadingDepartamentos ? (
                        <Skeleton width="100%" height={50} />
                      ) : (
                        <Autocomplete
                          options={departamentosList}
                          value={
                            departamentosList.find(
                              (x) => x._id === rule.destinations?.departamento
                            ) || null
                          }
                          getOptionLabel={(option) => option?.nome}
                          getOptionSelected={(option, value) =>
                            option._id === value._id
                          }
                          onChange={(event, newValue) => {
                            handleRule(
                              rule,
                              'departamento',
                              newValue?._id || null
                            );
                          }}
                          renderInput={(params) => (
                            <DefaultInput
                              {...params}
                              name="departamento"
                              label="Departamento"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              required
                            />
                          )}
                        />
                      )}
                    </Col>
                    <Col md={3} sm={12}>
                      {!loadingTipoTarefas ? (
                        <Autocomplete
                          options={listTiposTarefas}
                          value={
                            listTiposTarefas.find(
                              (x) => x._id === rule.destinations?.tarefa
                            ) || null
                          }
                          getOptionLabel={(option) => option?.nome}
                          getOptionSelected={(option, value) =>
                            option._id === value._id
                          }
                          onChange={(event, newValue) => {
                            handleRule(rule, 'tarefa', newValue?._id || null);
                          }}
                          renderInput={(params) => (
                            <DefaultInput
                              {...params}
                              name="tarefa"
                              label="Tarefa"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              required
                            />
                          )}
                        />
                      ) : (
                        <Skeleton width="100%" height={50} />
                      )}
                    </Col>
                    <Col sm={12} md={9}>
                      <Alert severity="warning" className="mt-2">
                        Será distribuído igualmente entre os usuários
                        selecionados (Opcional)
                      </Alert>
                      <Autocomplete
                        className="mt-2"
                        multiple={true}
                        options={listUsuarios}
                        value={rule.destinations?.usuarios || []}
                        getOptionLabel={(option) => option?.nome}
                        getOptionSelected={(option, value) =>
                          option._id === value._id
                        }
                        onChange={(event, newValue) => {
                          const selectedUsuarios = newValue.map((option) => ({
                            _id: option._id,
                            nome: option.nome,
                          }));
                          handleRule(
                            rule,
                            'usuarios',
                            selectedUsuarios || null
                          );
                        }}
                        renderInput={(params) => (
                          <DefaultInput
                            {...params}
                            name="Usuários"
                            label="Usuários"
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        )}
                      />
                    </Col>
                  </>
                )}
              </Row>
            </Col>
          </Box>
        ))}
    </Col>
  );
}

export default FormRules;
