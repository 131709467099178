export const Types = {
  LIST_REQUEST: 'imagensmodalidades/LIST_REQUEST',
  LIST_SUCCESS: 'imagensmodalidades/LIST_SUCCESS',
  SAVE_REQUEST: 'imagensmodalidades/SAVE_REQUEST',
  SAVE_SUCCESS: 'imagensmodalidades/SAVE_SUCCESS'
}

const INITIAL_STATE = {
  list: [],
  loading: false,
  limit: 10,
  page: 1,
  total: 0,
  sort: 'empresa.fantasia'
}

export default function imagensModalidade(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.LIST_REQUEST:
      return { ...state, loading: true }

    case Types.LIST_SUCCESS:
      return { ...state, loading: false, ...action.payload }

    case Types.SAVE_REQUEST:
      return { ...state, loading: true }

    case Types.SAVE_SUCCESS:
      return {
        ...state,
        loading: false,
        list: state.list.map(item => 
          item.empresa._id === action.payload.empresa ?
            { ...item, imagens: action.payload && action.payload.imagens ? action.payload.imagens : item.imagens || [] }
          :
            item
        )
      }

    default:
      return state
  }
}

export const Creators = {
  listRequest: payload => ({
    type: Types.LIST_REQUEST,
    payload
  }),
  listSuccess: payload => ({
    type: Types.LIST_SUCCESS,
    payload
  }),
  saveRequest: payload => ({
    type: Types.SAVE_REQUEST,
    payload
  }),
  saveSuccess: payload => ({
    type: Types.SAVE_SUCCESS,
    payload
  })
}
