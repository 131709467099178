import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@material-ui/core";
import { DialogContent } from "@material-ui/core";
import { Modal, DialogTitle } from "./styles";
import { toastr } from "react-redux-toastr";
import AutocompleteForm from "components/AutocompleteForm";
import { Alert } from "@material-ui/lab";
import { Col, Row } from "react-bootstrap";
import { BeatLoader } from "react-spinners";
import { Form } from "@unform/web";

import DialogActions from "@material-ui/core/DialogActions";
import ButtonMui from "@material-ui/core/Button";
import { Creators as DocumentosActions } from "store/ducks/documentos";
import { Creators as TiposImagemActions } from "store/ducks/tipoimagem";

function ImageChangeType({ open, image, handleOpenChangeType, selectedDoc }) {
  const dispatch = useDispatch();
  const formRef = useRef();

  const { list, loading } = useSelector((state) => state.tipoImagem);

  useEffect(() => {
    if (selectedDoc.idTipoDocumento && selectedDoc.idTipoDocumento._id) {
      dispatch(
        TiposImagemActions.listByTipoDocumentoRequest(
          selectedDoc.idTipoDocumento._id
        )
      );
    }
  }, [selectedDoc, dispatch]);

  const onSubmit = (values) => {
    values["reloadDoc"] = true;
    values["image"] = image;
    values["documentId"] = selectedDoc?._id;
    values["tipoImagem"] = values.tipoImagem?._id

    if (values.image && values.documentId && values.tipoImagem)
      dispatch(DocumentosActions.updateTypeImageArchieveRequest(values));
  };

  return (
    <Modal open={open} className="small-modal">
      <Form onSubmit={onSubmit} ref={formRef}>
        <div className="modal-header">
          <DialogTitle className="modal-title">
            Alterar tipo de imagem
          </DialogTitle>
        </div>
        <DialogContent>
          <Alert className="mb-4" severity="warning">
            Ao modificar o tipo de imagem vinculado os indexadores serão
            redefinidos, dessa forma os valores atuais serão apagados
          </Alert>

          <Col md="12">
            {!!loading ? (
              <BeatLoader
                sizeUnit={"px"}
                size={10}
                color={"#444444"}
                loading={loading}
              />
            ) : (
              <AutocompleteForm
                name="tipoImagem"
                options={list || []}
                getOptionLabel={(option) => option.nome}
                getOptionSelected={(option, value) => value._id === option._id}
              />
            )}
          </Col>
        </DialogContent>

        <DialogActions>
          <div className="buttons p-3">
            <Grid container spacing={1}>
              <Grid item>
                <ButtonMui variant="contained" color="primary" type="submit">
                  Salvar
                </ButtonMui>
              </Grid>
              <Grid item>
                <ButtonMui
                  variant="contained"
                  color="secondary"
                  type="reset"
                  onClick={handleOpenChangeType}
                >
                  Cancelar
                </ButtonMui>
              </Grid>
            </Grid>
          </div>
        </DialogActions>
      </Form>
    </Modal>
  );
}

export default ImageChangeType;
