import React from "react";
import TableRow from '@material-ui/core/TableRow';
import withStyles from '@material-ui/core/styles/withStyles'
import { color } from "App";

const styles = (theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: color,
    },
  }
});

const CustomTableRow = withStyles(styles)(({ classes, ...props }) => {
  const { backgroundColor } = props;
  return (
    <TableRow className={classes.root} style={{ backgroundColor }} {...props}>
      {props.children}
    </TableRow>
  );
});

export default CustomTableRow;