import React, { useEffect, useMemo, useState } from "react";
import IndexerFormFieldFactory from "components/FormFieldIndexer/IndexerFormFieldFactory";

import '../styles.css';
import { VerticalLinearStepper } from "components/VerticalStepper";
import DropZone from "components/Dropzone";
import { useIsFirstRender } from "hooks/useIsFirstRender";

export default function ModalFormulario({
    open,
    title,
    steps = [],
    campos = [],
    tiposDeImagem = [],
    onClose,
    mensagem,
    disabled = false,
    activeStep,
    indexadores = [],
    files = [],
    onDrop = () => { },
    onDeleteFile = () => { },
    handleSave = () => { },
    hasIndexadorError = () => { },
    hasIndexadorTextError = () => { },
    handleChangeIndexador = () => { },
}) {

    const isFirstRender = useIsFirstRender();
    const [componentes, setComponentes] = useState([]);

    const arquivos = useMemo(() => files, [files]);

    useEffect(() => {

        if (!isFirstRender) return;

        const camposPorEtapa = campos
            ?.filter(campo => campo.visivel && campo?.visivelNaEtapa?.length > 0)
            ?.sort((a, b) => steps.indexOf(a.visivelNaEtapa) - steps.indexOf(b.visivelNaEtapa))
            ?.reduce((acc, { nomeFormulario, nome, id_es, valor, visivelNaEtapa }, idx) => {
                const found = acc.find(e => e.etapa === visivelNaEtapa);
                if (found) {
                    found.campos.push({ nomeFormulario, nome, id_es, valor });
                } else {
                    acc.push({ etapa: visivelNaEtapa, campos: [{ nomeFormulario, nome, id_es, valor }] });
                }
                return acc;
            }, []);

        let componentes = [];

        if (camposPorEtapa.length) {
            componentes = camposPorEtapa.map(
                ({ etapa, campos }) =>
                ({
                    label: etapa,
                    component: <div>
                        {
                            campos.map(({ nomeFormulario, nome, id_es, valor, visivelNaEtapa }, idx) => {
                                const buscaIndicadorAtual = indexador => indexador.id_es === id_es;
                                const indexador = indexadores?.find(buscaIndicadorAtual) || null;
                                const tipo = indexador?.tipo || '';
                                return (
                                    <div className="painel-body-preview-item" >
                                        <IndexerFormFieldFactory
                                            autoFocus={idx === 0}
                                            id={id_es || `adicional-${idx}`}
                                            name={id_es || `adicional-${idx}`}
                                            label={nomeFormulario}
                                            genericParams={{
                                                tipo,
                                                hasIndexadorError,
                                                hasIndexadorTextError,
                                                handleChangeIndexador,
                                                checkReadOnly: false,
                                                identificadorUnico: false,
                                                indexador: { ...indexador, id_es, nome: nomeFormulario, valor },
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </div>
                                )
                            })
                        }
                    </div>,
                }))
        }

        if (tiposDeImagem.length) {
            componentes.unshift({
                label: 'Imagens',
                component:
                    <div className="painel-body-preview-item" >
                        <DropZone
                            onDrop={onDrop}
                            files={arquivos}
                            onDeleteFile={onDeleteFile}
                            message={
                                <p>
                                    Arraste e solte alguns arquivos aqui para importação
                                    do {
                                        tiposDeImagem.map((tipo, idx) => (
                                            <>
                                                {idx > 0 ? ', ' : ''} <strong>{tipo.nome}</strong>
                                            </>
                                        ))
                                    }
                                </p>
                            }
                            style={{
                                marginTop: "12px",
                                marginBottom: '100px',
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                alignmentItems: "center",
                                maxWidth: '800px',
                                width: "1200px",
                                height: '100%',
                            }}
                        />
                    </div>
            })

        }

        setComponentes(componentes);

    }, [campos, arquivos]);

    return (
        <div className="painel-content-formulario" hidden={!open} >
            <VerticalLinearStepper
                title={title}
                activeStep={activeStep}
                handleClose={onClose}
                disabled={disabled}
                open={open}
                message={mensagem}
                steps={componentes}
                onSend={handleSave}
            />
        </div >

    );
}