import { combineReducers } from 'redux';
import { reducer as toastrReducer } from 'react-redux-toastr';

import { connectRouter } from 'connected-react-router';

import permissoes from './permissoes';
import translate from './translate';
import redirectlogin from './redirectlogin';
import usuarios from './usuarios';
import empresas from './empresas';
import departamentos from './departamentos';
import tipodepartamento from './tipodepartamento';
import categorias from './categorias';
import documentos from './documentos';

import definicaosla from './definicaosla';
import dashboard from './dashboard';
import dashboardcustom from './dashboardcustom';
import dashboardscalingpending from './dashboardscalingpending';
import importacao from './importacao';
import exportacao from './exportacao';
import exportacaounic from './exportacaounic';
import report from './report';
import tipoDocumentos from './tiposdocumento';
import tipoImagem from './tipoimagem';

import filas from './filas';
import filasdetalhes from './filasDetalhes';
import agrupamento from './agrupamento';
import pesosprodutividade from './pesosprodutividade';

import organizacoes from './organizacoes';
import etapas from './etapas';

import checkTipos from './checktipo';

import analise from './analise';

import dividaAtiva from './dividaativa';
import menu from './menu';
import menus from './menus';

import configuracoes from './configuracoes';

import auditoria from './auditoria';

import reclamacoes from './reclamacoes';

import processos from './processos';

import checklistdocumento from './checklistdocumento';

import mergepdf from './mergepdf';

import welcome from './welcome';
import configuracaoDashboardExterno from './configuracaoDashboardExterno';
import dashboardexterno from './dashboardexterno';
import gruposempresariais from './gruposempresariais';
import graphs from './graphs';
import modalidades from './modalidades';
import imagensmodalidade from './imagensmodalidade';
import separadores from './separadores';
import relacionamentos from './relacionamentos';
import docsignature from './docsignature';
import comentarios from './comentarios';
import empresa from './empresa';
import tribunal from './tribunal';
import uf from './uf';
import ocorrencias from './ocorrencias';
import comarca from './comarca';
import esfera from './esfera';
import instancia from './instancia';
import acao from './acao';
import assessoria from './assessoria';
import asynchronousresults from './asynchronousresults';
import unidades from './unidades';
import naturezas from './naturezas';
import tipostarefas from './tipostarefas';
import tiposdespesas from './tiposdespesas';
import partes from './partes';
import parte from './parte';
import bloqueios from './bloqueios';
import bloqueio from './bloqueio';
import encerramentos from './encerramentos';
import encerramento from './encerramento';
import despesas from './despesas';
import despesa from './despesa';
import providencias from './providencias';
import providencia from './providencia';
import mensagens from './mensagens';
import mensagem from './mensagem';
import acoesauditoria from './acoesauditoria';
import processo from './processo';
import denominacoes from './denominacoes';
import provisoes from './provisoes';
import bancos from './bancos';
import notificacoes from './notificacoes';
import andamentos from './andamentos';
import peticionamento from './peticionamento';
import template from './template';
import templatesignaturepoints from './templatesignaturepoints';
import agenda from './agenda';
import kanban from './kanban';
import acessosrpa from './acessosrpa';
import grupoacesso from './grupoacesso';
import grupoemail from './grupoemail';
import layout from './layout';
import departamentoescalonamento from './departamentoescalonamento';
import email from './mail';
import carga from './carga';
import cargaitens from './cargaitens';
import exportDocuments from './exportDocuments';
import formularios from './formulario';
import rpa from './rpa';
import respostaOrgaos from './respostaOrgaos';
import productivity from './productivity';
import assistant from './assistant';
import tempstorage from './tempstorage';
import paramassinaturaeletronica from './paramassinaturaeletronica';
import paramformalizacaoauditoria from './paramformalizacaoauditoria';
import versionsimage from './versionsimage';
import bulkimport from './bulkimport';
import onboarding from './onboarding';
import classificacao from './classificacao';
import whatsapp from './whatsapp';
import login from './login';

const rootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    toastr: toastrReducer,
    redirectlogin,
    permissoes,
    translate,
    usuarios,
    empresas,
    tempstorage,
    tipoDocumentos,
    docsignature,
    layout,
    dashboard,
    dashboardcustom,
    dashboardscalingpending,
    paramassinaturaeletronica,
    configuracaoDashboardExterno,
    dashboardexterno,
    importacao,
    exportacao,
    exportacaounic,
    report,
    departamentos,
    tipodepartamento,
    categorias,
    documentos,
    tipoImagem,
    filas,
    filasdetalhes,
    agrupamento,
    pesosprodutividade,
    organizacoes,
    checkTipos,
    etapas,
    analise,
    dividaAtiva,
    configuracoes,
    menu,
    menus,
    auditoria,
    reclamacoes,
    processos,
    checklistdocumento,
    mergepdf,
    welcome,
    gruposempresariais,
    graphs,
    modalidades,
    imagensmodalidade,
    relacionamentos,
    separadores,
    comentarios,
    empresa,
    tribunal,
    uf,
    ocorrencias,
    comarca,
    esfera,
    instancia,
    acao,
    assessoria,
    asynchronousresults,
    unidades,
    naturezas,
    tipostarefas,
    tiposdespesas,
    partes,
    parte,
    bloqueios,
    bloqueio,
    encerramentos,
    encerramento,
    despesas,
    despesa,
    providencias,
    providencia,
    mensagens,
    mensagem,
    acoesauditoria,
    processo,
    denominacoes,
    provisoes,
    bancos,
    notificacoes,
    andamentos,
    peticionamento,
    template,
    templatesignaturepoints,
    agenda,
    kanban,
    acessosrpa,
    grupoacesso,
    grupoemail,
    definicaosla,
    departamentoescalonamento,
    email,
    carga,
    cargaitens,
    exportDocuments,
    formularios,
    rpa,
    respostaOrgaos,
    versionsimage,
    productivity,
    assistant,
    paramformalizacaoauditoria,
    bulkimport,
    onboarding,
    classificacao,
    whatsapp,
    login,
  });

export default rootReducer;
