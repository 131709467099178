import React from "react";

import { Col, Row } from "react-bootstrap";

export default function Field({ label, value }) {
  return (
    <Col md={12} className="viewField">
      <strong>{label}:</strong>
      <span>{value}</span>
    </Col>
  );
}
