import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from '@unform/web';
import { Creators as TipoImagensActions } from 'store/ducks/tipoimagem';
import { Creators as TipoDocumentosActions } from 'store/ducks/tiposdocumento';
import { ValidationError } from 'yup';
import CheckboxForm from 'components/CheckboxForm';

import { Row, Col } from 'react-bootstrap';

import './styles.css';

import Grid from '@material-ui/core/Grid';
import ButtonMui from '@material-ui/core/Button';
import BackButton from 'components/BackButton';
import InputForm from 'components/InputForm';
import AutocompleteForm from 'components/AutocompleteForm';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import TipoImagemSchema from 'validators/TipoImagemSchema';
import Content from 'components/Content';

export default function EditTipoImagem(params) {
  const dispatch = useDispatch();

  const formRef = useRef(null);

  const {
    access: { empresas },
  } = useSelector(state => state.permissoes);
  const { list } = useSelector(state => state.tipoDocumentos);

  const { TipoImagem } = params.location.state;
  const { _id, ...initialData } = TipoImagem;

  const [status, setStatus] = useState(TipoImagem ? TipoImagem.status : false);
  const [defaultTpImg, setDefaultTpImage] = useState(TipoImagem ? TipoImagem.default : false);

  const [notUsedToClassify, setNotUsedToClassify] = useState(TipoImagem ? TipoImagem.notUsedToClassify : false);
  const handleCheckStatus = () => {
    setStatus(!status);
  };

  const handleCheckDefault = () => {
    setDefaultTpImage(!defaultTpImg);
  };

  useEffect(() => {
    dispatch(TipoDocumentosActions.listRequest({ empresas }));
  }, [dispatch, empresas]);

  const onSubmitEdit = async values => {
    const body = {
      _id,
      status,
      default: defaultTpImg,
      notUsedToClassify,
      ...values,
    };
    try {
      await TipoImagemSchema.validate(body, {
        abortEarly: false,
      });

      dispatch(TipoImagensActions.editRequest(body));
    } catch (err) {
      const validationErrors = {};
      if (err instanceof ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });

        formRef.current.setErrors(validationErrors);
      }
    }
  };

  return (
    <Content>
      <Row>
        <Col md="12" className="painel">
          <div className="painel-content">
            <div className="painel-title text-left">Editar tipo de imagem:</div>
            <div className="painel-body">
              <Form ref={formRef} initialData={initialData} onSubmit={onSubmitEdit}>
                <Row>
                  <Col>
                    <AutocompleteForm
                      options={list}
                      name="tipoDocumento"
                      label="Tipo do documento*"
                      getOptionLabel={option => option.nome}
                      transformOnSelect={option => option._id}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <InputForm label="Descrição*" name="nome" />
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <InputForm label="Descrição utilizada para classificar com a IA" name="descricao_ia" />
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <InputForm label="Descrição reduzida (até 18 caracteres)*" name="shortname" />
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <CheckboxForm name="processarOCR" label={'Processa OCR?'} />
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <CheckboxForm name="selfie" label={'Selfie?'} />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormControlLabel
                      control={
                        <Switch
                          name="notUsedToClassify"
                          onChange={() => setNotUsedToClassify(!notUsedToClassify)}
                          color="primary"
                          defaultChecked={TipoImagem ? TipoImagem.notUsedToClassify : false}
                        />
                      }
                      label={'Não utilizado para classificar'}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormControlLabel
                      control={
                        <Switch
                          name="default"
                          onChange={handleCheckDefault}
                          color="primary"
                          defaultChecked={TipoImagem ? TipoImagem.default : false}
                        />
                      }
                      label="Padrão"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormControlLabel
                      control={
                        <Switch
                          name="status"
                          onChange={handleCheckStatus}
                          color="primary"
                          defaultChecked={TipoImagem ? TipoImagem.status : false}
                        />
                      }
                      label="Ativo"
                    />
                  </Col>
                </Row>
                <Grid container spacing={1}>
                  <Grid item>
                    <ButtonMui variant="contained" color="primary" type="submit">
                      Enviar
                    </ButtonMui>
                  </Grid>

                  <Grid item>
                    <BackButton variant="contained" color="secondary">
                      Voltar
                    </BackButton>
                  </Grid>
                </Grid>
              </Form>
            </div>
          </div>
        </Col>
      </Row>
    </Content>
  );
}
