import React from 'react';
import { useSelector } from 'react-redux';

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";

import {
    Container,
} from './styles/ActionMenu';
import { ArrowDownIcon, BackupIcon, EmailIcon, GetAppIcon, LibraryAddCheckIcon, MergeIcon } from 'components/Icons';

function ActionMenu({
    anchor,
    sizeArrayImagens,
    selectedFiles,
    handleSelectAll,
    handleDownload,
    handleOpenUpload,
    handleOpenSendEmail,
    handleOpenModalMerge,
    handleClose,
    handleGenerateThumbnails
}) {

    const { documento: selectedDoc } = useSelector(state => state.documentos);

    return (
        <Container
            id="menulist"
            open={!!anchor}
            anchorEl={anchor}
            onClose={handleClose}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "center",
            }}>
                <List component="nav" aria-label="menu de ações dos documentos">
                    <ListItem button onClick={handleSelectAll}>
                        <ListItemIcon>
                            <LibraryAddCheckIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary={
                            selectedDoc.imagens &&
                            selectedFiles.length === selectedDoc.imagens.length
                                ? "Desmarcar todos"
                                : "Selecionar todos"
                            }/>
                    </ListItem>
                    <Divider />

                    <ListItem
                        button
                        disabled={sizeArrayImagens === 0 ? "true" : ""}
                        onClick={handleDownload}>
                        <ListItemIcon>
                            <GetAppIcon />
                        </ListItemIcon>
                        <ListItemText primary="Baixar documentos" />
                    </ListItem>

                    <ListItem
                        button
                        disabled={sizeArrayImagens === 0 ? "true" : ""}
                        onClick={handleOpenSendEmail} >
                        <ListItemIcon>
                            <EmailIcon />
                        </ListItemIcon>
                        <ListItemText primary="Enviar email"/>
                    </ListItem>

                    <ListItem
                        button
                        onClick={handleOpenUpload} >
                        <ListItemIcon>
                            <BackupIcon />
                        </ListItemIcon>
                        <ListItemText primary="Upload de Imagens"/>
                    </ListItem>
                    <ListItem
                        button
                        disabled={sizeArrayImagens < 2 ? "true" : ""}
                        onClick={handleOpenModalMerge} >
                        <ListItemIcon>
                            <MergeIcon />
                        </ListItemIcon>
                        <ListItemText primary="Unificar Imagens"/>
                    </ListItem>
                    <ListItem
                        button
                        onClick={handleGenerateThumbnails} >
                        <ListItemIcon>
                            <ArrowDownIcon />
                        </ListItemIcon>
                        <ListItemText primary="Gerar miniaturas"/>
                    </ListItem>
                </List>

        </Container>
    );
}

export default ActionMenu;