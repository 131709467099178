import React, { useState, useEffect, useMemo, useRef } from 'react';
import './styles.css';
import { useDispatch } from 'react-redux';
import { Creators as TipoDocumentoActions } from 'store/ducks/tiposdocumento';
import ModalStepsCustomField from '../../../components/ModalSteps/ModalStepsCustomField/index';
import BackButton from 'components/BackButton';
import { Row, Col } from 'react-bootstrap';

import { Button as ButtonMui } from '@material-ui/core';

import { toastr } from 'react-redux-toastr';
import { Alert } from '@material-ui/lab';
import Content from 'components/Content';
import { Indexadores } from 'components/Indexadores';
import { reorderIndexadores } from 'utils/utils';

export default function IndexadoresTipoDocumento(props) {
  const dispatch = useDispatch();

  const { tipodoc } = props.location.state;

  const { hasDocument, definicoes } = tipodoc;

  let [indexadores, setIndexadores] = useState([]);
  let [indexador, setIndexador] = useState();
  let [openModal, setOpenModal] = useState(false);
  const [orderChange, setOrderChange] = useState(false);
  const [tabela, setTabela] = useState();
  const treeViewRef = useRef(null);

  const separadores = useMemo(() => {
    const separadores = indexadores.map((idx) => idx.separador);
    return separadores.filter(
      (separador, i) => separadores.indexOf(separador) === i && separador
    );
  }, [indexadores]);

  useEffect(
    () => {
       const indexadoresOrdenados = reorderIndexadores(props.location.state.tipodoc.indexadores)
      
      setIndexadores(indexadoresOrdenados)},
    [props]
  );
  


  const handleReorder = (result) => {
    const { source, destination } = result;

    // Verifica se a movimentação é válida
    if (!destination) {
        toastr.error('O indexador não pode ser solto fora de uma área válida');
        return;
    }

    if (source.index === destination.index) {
        toastr.error('Não se pode mover o indexador para o mesmo lugar');
        return;
    }


    if (source.droppableId !== destination.droppableId) {
      toastr.error('Não se pode mover um indexador para outro separador');
      return;
  }

  const separadoresMap = {};
  indexadores.forEach((indexador) => {
    const separador = indexador.separador || "Outros indexadores";
    if (!separadoresMap[separador]) {
        separadoresMap[separador] = [];
    }
    separadoresMap[separador].push(indexador);
});

  const reorderedItems = Array.from(separadoresMap[source.droppableId ?? 'Outros indexadores']);

  
  const [removed] = reorderedItems.splice(source.index, 1);
  reorderedItems.splice(destination.index, 0, removed);


  separadoresMap[source.droppableId] = reorderedItems;
  
  Object.keys(separadoresMap).forEach((separador) => {
    separadoresMap[separador].forEach((indexador, order) => {
      indexador.order = order;
    });
  });

  const novosIndexadores = Object.values(separadoresMap).flat();

  setOrderChange(true);
  setIndexadores(novosIndexadores);
};



  const handleClose = () => {
    setOpenModal(false);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    dispatch(
      TipoDocumentoActions.editIndexadoresRequest(tipodoc._id, indexadores)
    );

    toastr.success('Indexadores atualizados com sucesso!');
  };

  const novoIndexador = async () => {
    setTabela(null);

    const maxValue =
      Math.max(...indexadores.map((indexador) => indexador.index), -1) + 1;

      const indexadoresFiltrados = indexadores.filter(indexador => indexador.separador === '');

      const maxValueOrder =
        indexadoresFiltrados.length > 0
          ? Math.max(...indexadoresFiltrados.map(indexador => indexador.order), -1) + 1 : 0

    const novoIndexador = {
      index: maxValue >= 0 ? maxValue : 0,
      nome: `Novo campo ${maxValue + 1}`,
      id_es: '',
      tipo: 'caracter',
      mascara: '',
      limiteCaracteres: '',
      icapt: '',
      limpar: false,
      obrigatorio: false,
      invisivel: false,
      somenteLeitura: false,
      esteira: false,
      indexar: false,
      buscaExata: false,
      conectorOnboarding: false,
      ordemEsteira: '',
      obrigatorioEtapa: [],
      separador: '',
      order: maxValueOrder,
      opcoes: [],
      sensivel: false,
      dependenciaIndexador: null
    };

    const indexadoresAtualizado = [...indexadores, novoIndexador];

    await setIndexadores(indexadoresAtualizado);
    atualizarIndexadores(indexadoresAtualizado);
    setIndexador(novoIndexador);
    setOpenModal(true);
  };

  const getIndexador = async (indexador, tabela) => {
    setTabela(tabela);
    setIndexador(indexador);
  };

  const editIndexador = async (indexador, tabela) => {
    setTabela(tabela);
    setIndexador(indexador);
    setOpenModal(true);
  };

  const removeIndexador = async (indexador, tabela) => {
    const { index: parentIndex, nome } = indexador;
    let toUpdate = null;
    if (tabela) {
      toUpdate = [...indexadores];
      const indexOfParent = toUpdate.indexOf(indexador);
      const indexOfChildren = toUpdate[indexOfParent].campos.indexOf(tabela);
      toUpdate[indexOfParent].campos.splice(indexOfChildren, 1);
    } else {
      toUpdate = indexadores.filter(function (indexador) {
        return indexador.index != parentIndex || indexador.nome != nome;
      });
    }
    setIndexadores(toUpdate);
    atualizarIndexadores(toUpdate);
  };

  const atualizarIndexadores = (indexs) => {
    dispatch(TipoDocumentoActions.editIndexadoresRequest(tipodoc._id, indexs));
  };

  const changeIndexador = (e) => {
    let idxs = [...indexadores];

    const index = idxs.indexOf(indexador);

    const reference =
      parseInt(tabela) >= 0 ? idxs[index].campos[tabela] : idxs[index];

    if (e.target.type === 'checkbox') {
      reference[e.target.name] = e.target.checked;
    } else {
      if (e.target.name === 'tipo' && e.target.value === 'cpf') {
        reference['mascara'] = '111.111.111-11';
      }
      if (e.target.name === 'tipo' && e.target.value === 'cnpj') {
        reference['mascara'] = '11.111.111/1111-11';
      }

      reference[e.target.name] = e.target.value;
      let clearString = reference.nome
        .toLowerCase()
        .replace(/[^A-Z0-9]+/gi, '_');
      reference.id_es = clearString;
    }

    setIndexadores(idxs);
  };

  const handleChangeSeparador = (e, newValue) => {
    if (!(indexador.index > -1)) return;
    const idxs = [...indexadores];

    const pos = idxs.indexOf(indexador);

    delete idxs[pos]['separador'];

    if (newValue) {
      if (typeof newValue === 'string') {
        idxs[pos]['separador'] = newValue;
      } else {
        idxs[pos]['separador'] = newValue.inputValue;
      }
    }

    if (
      idxs[pos].tipo === 'tabela' &&
      idxs[pos].campos &&
      idxs[pos].campos.length > 0
    ) {
      idxs[pos].campos = idxs[pos].campos.map((campo) => ({
        ...campo,
        separador: newValue?.inputValue || '',
      }));
    }

    setIndexadores(idxs);
  };

  const handleChangeOpcoes = (e, newValue) => {
    const idxs = [...indexadores];

    const index = idxs.indexOf(indexador);

    const reference =
      parseInt(tabela) >= 0 ? idxs[index].campos[tabela] : idxs[index];

    delete reference['opcoes'];

    if (newValue) {
      reference['opcoes'] = newValue.map((opcao) =>
        typeof opcao === 'string' ? opcao : opcao.inputValue
      );
    }

    setIndexadores(idxs);
  };

  const handleChangeObrigatorioEtapa = (e, newValue) => {
    const idxs = [...indexadores];

    delete idxs[idxs.indexOf(indexador)]['obrigatorioEtapa'];
    if (newValue) {
      idxs[idxs.indexOf(indexador)]['obrigatorioEtapa'] = newValue.map(
        (opcao) => (typeof opcao === 'string' ? opcao : opcao.inputValue)
      );
    }

    setIndexadores(idxs);
  };

  const handleChangeMostrarFluxo = (e, newValue) => {
    const idxs = [...indexadores];

    delete idxs[idxs.indexOf(indexador)]['mostrarFluxo'];
    if (newValue) {
      idxs[idxs.indexOf(indexador)]['mostrarFluxo'] = newValue.map((opcao) =>
        typeof opcao === 'string' ? opcao : opcao.fila || opcao.inputValue
      );
    }

    setIndexadores(idxs);
  };

  const addField = (e, indexador) => {
    e.preventDefault();

    if (!indexador.campos) indexador.campos = [];

    const maxValue =
      Math.max(...indexador?.campos?.map((campo) => campo.index), -1) + 1;

    indexador.campos.push({
      index: maxValue,
      nome: `campo ${maxValue + 1}`,
      id_es: '',
      tipo: 'caracter',
      mascara: '',
      limiteCaracteres: '',
      icapt: '',
      limpar: false,
      obrigatorio: false,
      indexar: false,
      conectorOnboarding: false,
      buscaExata: false,
      esteira: false,
      ordemEsteira: '',
      obrigatorioEtapa: [],
      separador: indexador.separador,
      order: indexador.order,
      opcoes: [],
      sensivel: false,
      dependenciaIndexador: null
    });

    const index = indexadores.findIndex(
      (search) => search.index === indexador.index
    );

    indexadores[index].campos = indexador.campos;
    setIndexadores([...indexadores]);
  };

  const handleDependenciaIndexador = (e, newValue) => {
    const idxs = [...indexadores];

    const index = idxs.indexOf(indexador);

    const reference =
      parseInt(tabela) >= 0 ? idxs[index].campos[tabela] : idxs[index];

    delete reference['dependenciaIndexador'];

    if (newValue) {
      reference['dependenciaIndexador'] = {
        index: newValue.index,
        nome: newValue.nome,
        condicoes: []
      };
    }

    setIndexadores(idxs);
  }

  const handleAddCondicaoDependenciaIndexador = (e) => {

    const idxs = [...indexadores];

    const index = idxs.indexOf(indexador);

    const reference =
      parseInt(tabela) >= 0 ? idxs[index].campos[tabela] : idxs[index];

    reference['dependenciaIndexador'].condicoes.push({
      valor: "",
      opcoes: []
    });

    setIndexadores(idxs);
  }

  const handleChangeOptionDependenciaIndexador = (e, newValue, idx) => {
    const idxs = [...indexadores];

    const index = idxs.indexOf(indexador);

    const reference =
      parseInt(tabela) >= 0 ? idxs[index].campos[tabela] : idxs[index];

    reference['dependenciaIndexador'].condicoes[idx].valor = newValue;

    setIndexadores(idxs);
  }

  const handleChangeValuesForOptionDependenciaIndexador = (e, newValue, idx) => {
    const idxs = [...indexadores];

    const index = idxs.indexOf(indexador);

    const reference =
      parseInt(tabela) >= 0 ? idxs[index].campos[tabela] : idxs[index];

    reference['dependenciaIndexador'].condicoes[idx].opcoes = newValue;

    setIndexadores(idxs);
  }

  useEffect(() => {
    console.log("indexadores", indexadores)
  }, [indexadores]);

  const canSave = () => {

    if(orderChange){
      return true
    }
    
    if (
      indexadores
        .filter((indexador) => indexador.tipo === 'tabela')
        .some((indexador) => !indexador.separador || indexador.separador === '')
    )
      return false;

    return indexador ? true : false;
  };

  return (
    <Content>
      <Row>
        <Col md="12">
          {!definicoes.length && (
            <Alert className="mb-3" severity="warning">
              Não existem definições de SLA para esse tipo documento
            </Alert>
          )}
        </Col>
        <Col md="12">
          {hasDocument && (
            <Alert className="mb-3" severity="info">
              O tipo de documento está vinculado a documentos existentes.
              Portanto, a exclusão de campo será desabilitada.
            </Alert>
          )}
        </Col>
      </Row>
      <Row>
        <Col md="12" className="painel">
          <div className="painel-content">
            <div className="painel-title text-left">
              Campos ({tipodoc.nome})
              <ButtonMui
                variant="contained"
                color="primary"
                onClick={novoIndexador}
              >
                Novo campo
              </ButtonMui>
            </div>
            <div className="painel-body">
              <Row>
                <Col md="12">
                  <Indexadores
                    separadores={separadores}
                    indexadores={indexadores}
                    treeViewRef={treeViewRef}
                    hasDocument={hasDocument}
                    onClick={getIndexador}
                    onClickRemove={removeIndexador}
                    onClickEdit={editIndexador}
                    onAddFieldClick={addField}
                    onReorder={handleReorder}
                  />
                </Col>
              </Row>
              <Row>
                <Col className="mt-2 mb-2">
                  <ButtonMui
                    variant="contained"
                    color="primary"
                    type="submit"
                    className="mr-2"
                    onClick={(e) => onSubmit(e)}
                    disabled={!canSave()}
                  >
                    Salvar
                  </ButtonMui>
                  <BackButton
                    color="secondary"
                    className="btn-back-type-document"
                  >
                    Voltar
                  </BackButton>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
      <ModalStepsCustomField
        open={openModal}
        setOpenModal={setOpenModal}
        setTabela={setTabela}
        handleClose={handleClose}
        changeIndexador={changeIndexador}
        handleChangeOpcoes={handleChangeOpcoes}
        handleChangeSeparador={handleChangeSeparador}
        handleChangeMostrarFluxo={handleChangeMostrarFluxo}
        handleChangeObrigatorioEtapa={handleChangeObrigatorioEtapa}
        handleDependenciaIndexador={handleDependenciaIndexador}
        handleAddCondicaoDependenciaIndexador={handleAddCondicaoDependenciaIndexador}
        handleChangeOptionDependenciaIndexador={handleChangeOptionDependenciaIndexador}
        handleChangeValuesForOptionDependenciaIndexador={handleChangeValuesForOptionDependenciaIndexador}
        separadores={separadores}
        indexadores={indexadores}
        indexador={indexador}
        tabela={tabela}
      />
    </Content>
  );
}
