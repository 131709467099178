import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import ButtonMui from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { Grid, MenuItem } from "@material-ui/core";
import { Form } from "@unform/web";

import { Row, Col } from "react-bootstrap";
import { toastr } from "react-redux-toastr";

import BackButton from "components/BackButton";

import { Creators as GrupoEmailActions } from "store/ducks/grupoemail";
import { Autocomplete } from "@material-ui/lab";
import Utils from "utils/utils";
import AutocompleteForm from "components/AutocompleteForm";
import SelectForm from "components/SelectForm";
import { Creators } from "store/ducks/departamentos";
import Content from "components/Content";

const INITIAL_FORM = {
  nome: "",
  emails: [],
  tipoDeArea: null,
  idDepartamento: null,
  nivelEscalonamento: null,
};

export default function EditGrupoDeEmail(params) {
  const dispatch = useDispatch();
  const formRef = useRef(null);

  const { grupoemail } = params.location.state ?? {};
  const [form, setForm] = useState({
    ...INITIAL_FORM,
    ...(grupoemail ?? {}),
    __v: 1,
  });

  const { list: departamentosList, loading: loadingDepartamentos } =
    useSelector((state) => state.departamentos);

  const conditionalSaveBlock = () => {
    const hasSelectedDepartamento = !!form.idDepartamento;
    if (hasSelectedDepartamento) {
      return Object.values(form).every((value) => {
        if (Array.isArray(value)) return value.length > 0;

        return !!value;
      });
    }

    return form.emails?.length > 0 && !!form.nome;
  };

  const canSave = conditionalSaveBlock();
  const onSubmit = () => {
    try {
      if (!form.emails.length) return;

      const payload = {
        ...form,
        nivelEscalonamento: form.nivelEscalonamento || null,
        tipoDeArea: form.tipoDeArea || null,
        idDepartamento: form.idDepartamento || null,
      }
      dispatch(GrupoEmailActions.editRequest(payload));
    } catch (err) {
      return toastr.error("Ops", err.message);
    }
  };

  const onChange = ({ target }) => {
    const { value, name } = target;
    setForm({ ...form, [name]: value });
  };

  const onSelectChange = (value, field) => {
    setForm((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  useEffect(() => {
    dispatch(Creators.listRequest());
  }, []);

  const onChangeAutocomplete = (name, value, e) => {
    const util = new Utils();
    const email = value?.map((e) => typeof e === "object" ? e.inputValue.toLocaleLowerCase() : e.toLocaleLowerCase());
    const emails = util.handlePastedEmails(email);
    setForm({ ...form, [name]: emails });
  };

  return (
    <Content>
      <Row>
        <Col md="12" className="painel">
          <div className="painel-content">
            <div className="painel-title text-left">
              Editar Grupo de e-mail
            </div>
            <div className="painel-body">
              <Form
                ref={formRef}
                onSubmit={onSubmit}
                className={"mt-2"}
                defaultValue={grupoemail}
              >
                <Row>
                  <Col md={6}>
                    <TextField
                      onChange={onChange}
                      autoComplete="off"
                      label="Nome"
                      name="nome"
                      variant="outlined"
                      size="small"
                      value={form.nome}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <SelectForm
                      label="Nivel Escalonamento"
                      onChange={(e) => {
                        onSelectChange(
                          e.target.value,
                          "nivelEscalonamento"
                        );
                      }}
                      name="nivelEscalonamento"
                      value={form.nivelEscalonamento}
                    >
                      {Array.from({ length: 5 }, (_, index) => (
                        <MenuItem
                          value={`Escalonamento ${index + 1}`}
                          key={`Escalonamento ${index + 1}`}
                        >
                          Escalonamento {index + 1}
                        </MenuItem>
                      ))}
                    </SelectForm>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <SelectForm
                      label="Tipo de Area"
                      name="tipoDeArea"
                      onChange={(e) => {
                        onSelectChange(e.target.value, "tipoDeArea");
                      }}
                      value={form.tipoDeArea}
                    >
                      <MenuItem value="Interna">Interna</MenuItem>
                      <MenuItem value="Externa">Externa</MenuItem>
                    </SelectForm>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <AutocompleteForm
                      value={
                        departamentosList.find(
                          (departamento) =>
                            departamento._id === form.idDepartamento
                        ) ?? {}
                      }
                      label="Departamento"
                      name="idDepartamento"
                      options={departamentosList}
                      disabled={loadingDepartamentos}
                      getOptionLabel={(option) => option.nome}
                      onChange={(_, value) => {
                        onSelectChange(value?._id, "idDepartamento");
                      }}
                      forceShrink
                    />
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <Autocomplete
                      freeSolo
                      multiple
                      selectOnFocus
                      clearOnBlur
                      id="options-combo"
                      name="email"
                      value={form.emails}
                      style={{ marginBottom: "8px" }}
                      onChange={(e, value) =>
                        onChangeAutocomplete("emails", value, e)
                      }
                      options={[]}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Adicione destinatários"
                          variant="outlined"
                          name="opcoes"
                        />
                      )}
                      getOptionLabel={(option) =>
                        typeof option === "string"
                          ? option
                          : option.inputValue
                      }
                      filterOptions={(options, params) => [
                        {
                          inputValue: params.inputValue,
                          title: `Novo e-mail "${params.inputValue}"`,
                        },
                      ]}
                      renderOption={(option) =>
                        typeof option === "string" ? option : option.title
                      }
                      size="medium"
                    />
                  </Col>
                </Row>

                <Grid container spacing={1} className="mt-2 mb-2">
                  <Grid item>
                    <ButtonMui
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={!canSave}
                    >
                      Salvar
                    </ButtonMui>
                  </Grid>
                  <Grid item>
                    <BackButton variant="contained" color="secondary">
                      Voltar
                    </BackButton>
                  </Grid>
                </Grid>
              </Form>
            </div>
          </div>
        </Col>
      </Row>
    </Content>
  );
}
