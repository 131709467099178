import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row } from "react-bootstrap";

import SearchForm from "./SearchForm";
import SearchResults from "./SearchResults";
import Tabs from "./Tabs";

import "./styles.css";

import { Creators as DocumentosActions } from "../../store/ducks/documentos";
import Content from "components/Content";

export default function PesquisaAvancada(params) {
  const dispatch = useDispatch();
  const { cpfCnpj, date } = params?.location?.state ?? {};

  useEffect(() => {
    if (!cpfCnpj) return

    setCollapse(false)
  }, [cpfCnpj, date])

  const [collapse, setCollapse] = useState(false);

  let { documento, advSearch } = useSelector((state) => state.documentos);

  useEffect(() => {
    dispatch(DocumentosActions.advancedSearchResultSClear());
  }, [dispatch]);

  return (
    <Content>
      <SearchForm
        cpfCnpj={cpfCnpj}
        date={date}
        setCollapse={setCollapse}
        collapse={collapse}
      />
      <SearchResults
        selectedDoc={documento}
        setCollapse={setCollapse}
        collapse={collapse}
      />
      <Row>
        <Tabs selectedDoc={documento} loading={advSearch.loading} />
      </Row>
    </Content>
  );
}
