import React from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Divider,
} from "@material-ui/core";

import "./styles.css";

const Participantes = ({ list }) => {
  return <ul>{JSON.stringify(list)}</ul>;
};

function DadosAuditados({ dadosAlterados, titulo, onClose, ...props }) {
  const tituloUpper = titulo || "LOGS DE ALTERAÇÃO";

  return (
    <Dialog onClose={onClose} maxWidth="md" fullWidth {...props}>
      <DialogTitle>{tituloUpper.toUpperCase()}</DialogTitle>
      <DialogContent style={{ wordBreak: "break-all" }}>
        <div className="dados-auditados-content">
          <span className="header">Campo</span>
          <span className="header">Valor</span>
          <Divider />
          <Divider />
          {dadosAlterados
            .filter(({ visivel }) => visivel === true)
            .map(({ chave, valor, visivel }, index) => (
              <React.Fragment key={index}>
                <>
                  <span className="dados-auditados-cap">{chave}</span>
                  {typeof valor != "object" ? (
                    <span>{valor || "-"}</span>
                  ) : (
                    <div>
                      {chave.toUpperCase() === "PARTICIPANTES" ||
                      chave.toUpperCase() === "CAMPOS ALTERADOS" ||
                      chave.toUpperCase() === "DESTINATÁRIO" ? (
                        <Participantes list={valor} />
                      ) : (
                        Object.keys(valor).map((key) => (
                          <>
                            <span>{key}</span>
                          </>
                        ))
                      )}
                    </div>
                  )}
                </>
                <Divider />
                <Divider />
              </React.Fragment>
            ))}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="contained" color="primary">
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DadosAuditados;
