import React, { useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Form } from "@unform/web";
import { useSelector, useDispatch } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { ValidationError } from "yup";
import _ from "lodash";

import InputForm from "components/InputForm";
import AutocompleteForm from "components/AutocompleteForm";
import BackButton from "components/BackButton";
import { FormControlLabel, Switch } from "@material-ui/core";

import ModalidadeSchema from "validators/ModalidadeSchema";

import { Creators as ModalidadesActions } from "store/ducks/modalidades";
import { Creators as AuditoriaActions } from "store/ducks/auditoria";
import Content from "components/Content";

function FormModalidade({ location }) {
  const dispatch = useDispatch();
  const formRef = useRef();

  const { modalidade } = location.state || {};

  const [status, setStatus] = useState(modalidade ? modalidade.status : false);

  const tipoDocs = useSelector((state) => state.tipoDocumentos.list);

  const handleCheckStatus = () => {
    setStatus(!status);
  };
  async function handleSubmit(data) {
    try {
      await ModalidadeSchema.validate(data, {
        abortEarly: false,
      });

      if (
        modalidade &&
        modalidade._id &&
        !_.isEqual(modalidade, { ...modalidade, ...data })
      ) {
        const dadosAlterados = {};
        if (modalidade.nome !== data.nome) {
          dadosAlterados.Nome = {
            valorAnterior: modalidade.nome,
            valorNovo: data.nome,
          };
        }
        if (modalidade.tipoDocumento._id !== data.tipoDocumento._id) {
          dadosAlterados["Tipo de Documento"] = {
            valorAnterior: modalidade.tipoDocumento.nome,
            valorNovo: data.tipoDocumento.nome,
          };
        }

        if (!_.isEqual(dadosAlterados, {})) {
          const auditoriaBody = {
            detalhes: {
              acao: "Alteração da Modalidade",
              route: location.pathname,
              modalidade: modalidade._id,
              categoria: "modalidade",
              dadosAlterados,
            },
          };
          if (!_.isEqual(dadosAlterados, {}))
            dispatch(AuditoriaActions.createRequest(auditoriaBody));
        }
      }

      const body = {
        ...data,
        tipoDocumento: data.tipoDocumento._id,
      };

      dispatch(
        ModalidadesActions.saveRequest({
          _id: modalidade ? modalidade._id : null,
          status,
          ...body,
        })
      );
    } catch (err) {
      const validationErrors = {};
      if (err instanceof ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });

        formRef.current.setErrors(validationErrors);
      }
    }
  }

  return (
    <Content>
      <Row>
        <Col md="12" className="painel">
          <div className="painel-content">
            <div className="painel-title text-left">Modalidade</div>
            <div className="painel-body">
              <Form
                ref={formRef}
                initialData={modalidade}
                onSubmit={handleSubmit}
              >
                <Row className="row">
                  <Col>
                    <InputForm name="nome" label="Nome" />
                  </Col>
                  <Col>
                    <AutocompleteForm
                      options={tipoDocs}
                      name="tipoDocumento"
                      label="Tipo de Documento"
                      getOptionLabel={(option) => option.nome}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormControlLabel
                      control={
                        <Switch
                          name="status"
                          onChange={handleCheckStatus}
                          color="primary"
                          defaultChecked={
                            modalidade ? modalidade.status : false
                          }
                        />
                      }
                      label="Ativo"
                    />
                  </Col>
                </Row>
                <Grid container spacing={1}>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                    >
                      Enviar
                    </Button>
                  </Grid>
                  <Grid item>
                    <BackButton variant="contained" color="secondary">
                      Voltar
                    </BackButton>
                  </Grid>
                </Grid>
              </Form>
            </div>
          </div>
        </Col>
      </Row>
    </Content>
  );
}

export default FormModalidade;
