export const Types = {
  LIST_REQUEST: 'etapas/LIST_REQUEST',
  LIST_SUCCESS: 'etapas/LIST_SUCCESS',
  REMOVE_REQUEST: 'etapas/REMOVE_REQUEST',
  REMOVE_SUCCESS: 'etapas/REMOVE_SUCCESS',
  NEW_REQUEST: 'etapas/NEW_REQUEST',
  NEW_SUCCESS: 'etapas/NEW_SUCCESS',
  EDIT_REQUEST: 'etapas/EDIT_REQUEST',
  EDIT_SUCCESS: 'etapas/EDIT_SUCCESS',
  GET_REQUEST: 'etapas/GET_REQUEST',
  GET_SUCCESS: 'etapas/GET_SUCCESS',
  CURRENTETAPA_REQUEST: 'etapas/CURRENTETAPA_REQUEST',
  CURRENTETAPA_SUCCESS: 'etapas/CURRENTETAPA_SUCCESS',
};

const INITIAL_STATE = {
  list: [],
  loading: false,
  idFila: '',
  page: 1,
  limit: 10,
  total: 0,
  sort: 'descricao',
  xml: null,
};

export default function etapas(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.LIST_REQUEST:
      return {
        ...state,
        loading: true,
        xml: null,
        idFila: action.payload.fila,
      };

    case Types.LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        xml: action.payload.xml,
        count: action.payload.count,
      };

    case Types.REMOVE_REQUEST:
      return { ...state, loading: true };

    case Types.REMOVE_SUCCESS:
      return { ...state, loading: false };

    case Types.NEW_REQUEST:
      return { ...state, loading: true };

    case Types.NEW_SUCCESS:
      return { ...state, loading: false };

    case Types.EDIT_REQUEST:
      return { ...state, loading: true };

    case Types.EDIT_SUCCESS:
      return { ...state };

    case Types.GET_REQUEST:
      return { ...state, loading: true };

    case Types.GET_SUCCESS:
      return { ...state, loading: false, etapsest: action.payload };

    default:
      return state;
  }
}

export const Creators = {
  listRequest: (fila, empresas) => ({
    type: Types.LIST_REQUEST,
    payload: { fila, empresas },
  }),

  listSuccess: (etapaesteira) => ({
    type: Types.LIST_SUCCESS,
    payload: etapaesteira,
  }),
  removeRequest: (id) => ({
    type: Types.REMOVE_REQUEST,
    payload: id,
  }),

  removeSuccess: () => ({
    type: Types.REMOVE_SUCCESS,
    payload: [],
  }),
  createRequest: (etapaesteira) => ({
    type: Types.NEW_REQUEST,
    payload: etapaesteira,
  }),

  createSuccess: () => ({
    type: Types.NEW_SUCCESS,
    payload: {},
  }),
  editRequest: (etapaesteira) => ({
    type: Types.EDIT_REQUEST,
    payload: etapaesteira,
  }),

  editSuccess: () => ({
    type: Types.EDIT_SUCCESS,
    payload: {},
  }),
  getRequest: (id) => ({
    type: Types.GET_REQUEST,
    payload: id,
  }),

  getSuccess: (etapaesteira) => ({
    type: Types.GET_SUCCESS,
    payload: etapaesteira,
  }),
};
