export const Types = {
    LIST_REQUEST: "tipodepartamento/LIST_REQUEST",
    LIST_SUCCESS: "tipodepartamento/LIST_SUCCESS",
    NEW_REQUEST: "tipodepartamento/NEW_REQUEST",
    NEW_SUCCESS: "tipodepartamento/NEW_SUCCESS",
    GET_REQUEST: "tipodepartamento/GET_REQUEST",
    GET_SUCCESS: "tipodepartamento/GET_SUCCESS",
    EDIT_REQUEST: "tipodepartamento/EDIT_REQUEST",
    EDIT_SUCCESS: "tipodepartamento/EDIT_SUCCESS",
    REMOVE_REQUEST: "tipodepartamento/REMOVE_REQUEST",
    REMOVE_SUCCESS: "tipodepartamento/REMOVE_SUCCESS",
};

/* Reducer */

const INITIAL_STATE = {
    loading: false,
    list: [],
    data: {},
    sort: "nome",
    page: 1,
    limit: 10,
    total: 0,
};

export default function items(state = INITIAL_STATE, action) {
    switch (action.type) {
        case Types.LIST_REQUEST:
            return { ...state, loading: true };

        case Types.LIST_SUCCESS:
            return { ...state, ...action.payload, loading: false };

        case Types.NEW_REQUEST:
            return { ...state, loading: true };

        case Types.NEW_SUCCESS:
            return { ...state, loading: false };

        case Types.GET_REQUEST:
            return { ...state, loading: true };

        case Types.GET_SUCCESS:
            return { ...state, loading: false, data: action.payload };

        case Types.EDIT_REQUEST:
            return { ...state, loading: true };

        case Types.EDIT_SUCCESS:
            return { ...state, loading: false };

        case Types.REMOVE_REQUEST:
            return { ...state, loading: true };

        case Types.REMOVE_SUCCESS:
            return { ...state, loading: false };

        default:
            return state;
    }
}

export const Creators = {
    listRequest: (data) => ({
        type: Types.LIST_REQUEST,
        payload: { ...data },
    }),

    listSuccess: (data) => ({
        type: Types.LIST_SUCCESS,
        payload: data,
    }),

    getRequest: (id) => ({
        type: Types.GET_REQUEST,
        payload: id,
    }),

    getSuccess: (data) => ({
        type: Types.GET_SUCCESS,
        payload: data,
    }),

    editRequest: (data) => ({
        type: Types.EDIT_REQUEST,
        payload: data,
    }),

    editSuccess: () => ({
        type: Types.EDIT_SUCCESS,
        payload: {},
    }),

    createRequest: (data) => ({
        type: Types.NEW_REQUEST,
        payload: data,
    }),

    createSuccess: () => ({
        type: Types.NEW_SUCCESS,
        payload: {},
    }),

    removeRequest: (id) => ({
        type: Types.REMOVE_REQUEST,
        payload: id,
    }),

    removeSuccess: () => ({
        type: Types.REMOVE_SUCCESS,
        payload: [],
    }),
};
