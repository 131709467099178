import * as Yup from "yup";
import Locale from "./Locale";

Yup.setLocale(Locale);

export default Yup.object().shape({
  title: Yup.string().required(),
  identifier: Yup.string().required(),
  route: Yup.string().required(),
  icon: Yup.string().required(),
  order: Yup.string().required(),
});
