import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { Form } from "@unform/web";

import Grid from "@material-ui/core/Grid";
import ButtonMui from "@material-ui/core/Button";
import { FormControlLabel, Switch } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

import BackButton from "components/BackButton";
import InputForm from "components/InputForm";
import UFSchema from "validators/UFSchema";
import { ValidationError } from "yup";

import { Creators as AssessoriaActions } from "../../../store/ducks/assessoria";
import Content from "components/Content";

export default function EditAssessoria(params) {
  const dispatch = useDispatch();
  const formRef = useRef(null);

  const { assessoria } = params.location.state;

  const { _id, ...initialData } = assessoria;
  const [status, setStatus] = useState(assessoria ? assessoria.status : false);

  const { loading } = useSelector((state) => state.assessoria);

  const handleCheckStatus = () => {
    setStatus(!status);
  };

  const onSubmitEdit = async (values) => {
    const body = { ...values, _id, status };

    try {
      await UFSchema.validate(body, {
        abortEarly: false,
      });

      dispatch(AssessoriaActions.editRequest(body));
    } catch (err) {
      const validationErrors = {};
      if (err instanceof ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });

        formRef.current.setErrors(validationErrors);
      }
    }
  };

  return (
    <Content>
      <Row>
        <Col md="12" className="painel">
          <div className="painel-content">
            <div className="painel-title text-left">Editar Assessoria</div>
            <div className="painel-body">
              <Form
                ref={formRef}
                initialData={initialData}
                onSubmit={onSubmitEdit}
              >
                <Row className="row">
                  <Col md={6}>
                    <InputForm name="nome" label="Nome da assessoria" />
                  </Col>
                </Row>
                <Row className="row">
                  <Col md={6}>
                    <InputForm name="cnpj" label="CNPJ" />
                  </Col>
                </Row>
                <Row className="row">
                  <Col md={6}>
                    <InputForm name="email" label="Email" />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormControlLabel
                      control={
                        <Switch
                          name="status"
                          onChange={handleCheckStatus}
                          color="primary"
                          defaultChecked={
                            assessoria ? assessoria.status : false
                          }
                        />
                      }
                      label="Ativo"
                    />
                  </Col>
                </Row>
                <Grid container spacing={1}>
                  <Grid item>
                    <ButtonMui
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={loading}
                    >
                      Enviar
                      {!!loading && <CircularProgress size={20} />}
                    </ButtonMui>
                  </Grid>
                  <Grid item>
                    <BackButton variant="contained" color="secondary">
                      Voltar
                    </BackButton>
                  </Grid>
                </Grid>
              </Form>
            </div>
          </div>
        </Col>
      </Row>
    </Content>
  );
}
