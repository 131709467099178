import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  TableContainer,
  TableFooter,
  TablePagination,
} from "@material-ui/core";

import Button from "@material-ui/core/Button";

import { Col } from "react-bootstrap";

import { Link, useLocation } from "react-router-dom";
import CustomTableSortLabel from "components/CustomTableSortLabel";
import LoadingTable from "components/LoadingTable";
import StyledTableRow from "components/StyledTableRow";
import ButtonSquare from "components/ButtonSquare";
import ToolTipComponent from "components/Tooltip";
import Modal from "components/Modal";

import { Creators as UFActions } from "store/ducks/uf";

import "./styles.css";

export default function ListUF() {
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const {
    list: ufs,
    loading,
    total,
    page = 1,
    limit = 10,
    sort = "nome",
  } = useSelector((state) => state.uf);
  const {
    access: { servicos },
  } = useSelector((state) => state.permissoes);

  const [request, setRequest] = useState({ page, limit, sort });
  const [userActions, setUserActions] = useState([]);
  const [unidadeFederativa, setUnidadeFederativa] = useState({});
  const [show, setShow] = useState(false);

  useEffect(() => {
    let find = servicos.find((item) => item.route === pathname);
    if (find !== undefined) {
      setUserActions(find);
    }
  }, [pathname, servicos]);

  useEffect(() => {
    dispatch(
      UFActions.listRequest({
        ...request,
      })
    );
  }, [dispatch, request]);

  useEffect(() => {
    setShow(false);
  }, [loading]);

  function handleChangeRowsPerPage({ target }) {
    const { value: limit } = target;
    setRequest({ ...request, limit, page: 1 });
  }

  function handleChangePage(event, newPage) {
    setRequest({ ...request, page: newPage + 1 });
  }

  function handleSortChange(sort) {
    setRequest({ ...request, sort });
  }

  function openDeleteConfirm(item) {
    setUnidadeFederativa(item);
    setShow(true);
  }

  function handleDelete() {
    dispatch(UFActions.deleteRequest(unidadeFederativa._id));
  }

  return (
    <>
      <TableContainer className="list-tribunal-container">
        {(ufs && ufs.length > 0) || !!loading ? (
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>
                  <CustomTableSortLabel
                    currentSort={request.sort}
                    sortField="nome"
                    onClick={handleSortChange}
                  >
                    Nome
                  </CustomTableSortLabel>
                </TableCell>
                <TableCell align="center">
                  <CustomTableSortLabel
                    currentSort={request.sort}
                    sortField="status"
                    onClick={handleSortChange}
                  >
                    Status
                  </CustomTableSortLabel>
                </TableCell>
                {userActions.actions &&
                  userActions.actions.map(
                    (userAct, index) =>
                      userAct.action === "editar" && (
                        <TableCell key={index} align="center">
                          Ações
                        </TableCell>
                      )
                  )}
              </TableRow>
            </TableHead>
            <TableBody>
              {!!loading && <LoadingTable columns={5} />}
              {!loading &&
                ufs.map((uf) => (
                  <StyledTableRow key={uf._id}>
                    <TableCell>{uf.nome}</TableCell>

                    <TableCell align="center">
                      {uf.status === true ? (
                        <span className="status-active">Ativo</span>
                      ) : (
                        <span className="status-inactive">Inativo</span>
                      )}
                    </TableCell>
                    {userActions.actions &&
                      userActions.actions.map(
                        (userAct, index) =>
                          userAct.action === "editar" && (
                            <TableCell align="center" key={index}>
                              <div className="flex-direction">
                                <ToolTipComponent
                                  title="Editar"
                                  placement="top"
                                >
                                  <Link
                                    to={{
                                      pathname: "/configuracao/uf/edit",
                                      state: { uf },
                                    }}
                                    className="linkicon"
                                    id="tribunal"
                                  >
                                    {/* <FontAwesomeIcon icon={faEdit}></FontAwesomeIcon> */}
                                    <ButtonSquare icon="edit" />
                                  </Link>
                                </ToolTipComponent>
                                <ToolTipComponent
                                  title="Remover"
                                  placement="top"
                                >
                                  <div>
                                    <ButtonSquare
                                      onClick={() => openDeleteConfirm(uf)}
                                      icon="delete"
                                    />
                                  </div>
                                </ToolTipComponent>
                              </div>
                            </TableCell>
                          )
                      )}
                  </StyledTableRow>
                ))}
            </TableBody>
            {!loading && (
              <TableFooter>
                <TableRow>
                  <TablePagination
                    count={total}
                    page={request.page - 1}
                    rowsPerPage={request.limit}
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    labelRowsPerPage="Linhas por página"
                    labelDisplayedRows={({ from, to, count }) =>
                      `${from}-${to} de ${
                        count !== -1 ? count : `mais que ${to}`
                      }`
                    }
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    onPageChange={handleChangePage}
                  />
                </TableRow>
              </TableFooter>
            )}
          </Table>
        ) : (
          <div className="list-tribunal-content">
            <span className="list__none">Não há ufs registradas.</span>
          </div>
        )}
      </TableContainer>
      <Modal
        isOpen={show}
        title="Remover UF:"
        onClose={setShow}
        className="modal-padding"
      >
        <p>Deseja realmente remover a UF {unidadeFederativa.nome}?</p>

        <Col md={12}>
          <div className="button-container">
            <Button
              variant="contained"
              color="primary"
              onClick={(e) => handleDelete()}
            >
              Sim
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={(e) => {
                setShow(false);
              }}
            >
              Não
            </Button>
          </div>
        </Col>
      </Modal>
    </>
  );
}
