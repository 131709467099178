import api from "services/api";
import { getToken } from "services/auth";
import { call, select, put, all, takeLatest } from "redux-saga/effects";
import { toastr } from "react-redux-toastr";

import { Creators as MensagemActions, Types } from "store/ducks/mensagem";
import { Creators as MensagensActions } from "store/ducks/mensagens";

export const getOrganization = (state) => state.usuarios.auth.organization;
export const getParamsMensagens = (state) => ({
  endpoint: state.mensagens.endpoint,
  page: state.mensagens.page,
  search: state.mensagens.search,
  documento: state.mensagens.documento,
  sort: state.mensagens.sort,
  limit: state.mensagens.limit,
});
export const getMensagem = (state) => state.mensagem.data;

export function* saveMensagem({ payload }) {
  const organization = yield select(getOrganization);
  const { _id, ...body } = payload.mensagem;

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    if (_id) {
      yield call(api.put, `mensagens/${_id}`, body, headerParams);
    } else {
      yield call(api.post, "mensagens", body, headerParams);
    }

    toastr.success("Sucesso!", "Dados salvos com sucesso!");
    yield put(MensagemActions.saveSuccess());
    const params = yield select(getParamsMensagens);
    yield put(MensagensActions.listInitialize());
    yield put(MensagensActions.listRequest(params));
  } catch (error) {
    yield put(MensagemActions.saveError());
    toastr.error("Ops", "Não foi possível salvar mensagem.");
  }
}

export function* upload({ payload }) {
  const organization = yield select(getOrganization);
  const mensagem = yield select(getMensagem);
  const { body } = payload;

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    yield call(
      api.post,
      `mensagens/${mensagem._id}/imagens`,
      body,
      headerParams
    );

    toastr.success("Sucesso!", "Upload realizado com sucesso!");
    yield put(MensagemActions.uploadSuccess());
    const params = yield select(getParamsMensagens);
    yield put(MensagensActions.listRequest(params));
  } catch (err) {
    yield put(MensagemActions.uploadError());
    toastr.error("Ops", "Não foi possível realizar o upload");
  }
}

export function* remove({ payload }) {
  const organization = yield select(getOrganization);
  const id = payload;

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    yield call(api.delete, `mensagens/${id}`, headerParams);

    toastr.success("Sucesso!", "Remoção realizada com sucesso!");
    yield put(MensagemActions.deleteSuccess());
    const params = yield select(getParamsMensagens);
    yield put(MensagensActions.listRequest(params));
  } catch (err) {
    yield put(MensagemActions.uploadError());
    toastr.error("Ops", "Não foi possível realizar a remoção");
  }
}

export default all([
  takeLatest(Types.SAVE_REQUEST, saveMensagem),
  takeLatest(Types.UPLOAD_REQUEST, upload),
  takeLatest(Types.DELETE_REQUEST, remove),
]);
