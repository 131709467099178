import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal, Form, Col, Row } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";

import { Creators as TemplateActions } from "../../../store/ducks/template";
import UploadImageToDraft from "../../../utils/uploadImageToDraft";

import { EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .modal-content": {
      minWidth: "800px",
      minHeight: "450px",
    },

    "& .editor-class": {
      overflow: "hidden",
    },
  },
}));

const uploadCallback = async (file) => {
  const uploadFunction = new UploadImageToDraft();
  const image = await uploadFunction.uploadImage(file);
  return image;
};

export const ParagraphModal = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { showModalParagraph } = useSelector((state) => state.template);

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [title, setTitle] = useState("");

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };

  const handleSubmit = () => {
    const currentContent = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );

    const data = { title, paragraph: currentContent, id: uuidv4() };
    dispatch(TemplateActions.saveParagraph(data));
    dispatch(TemplateActions.showModal({ modal: "PARAGRAPH", show: false }));
  };

  const handleCloseModal = () => {
    dispatch(TemplateActions.showModal({ modal: "PARAGRAPH", show: false }));
  };

  return (
    <Modal className={classes.root} show={showModalParagraph} centered>
      <Form>
        <Modal.Header>
          <Modal.Title>Novo Parágrafo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Label>Título do parágrafo</Form.Label>
          <Form.Control
            onChange={(e) => setTitle(e.target.value)}
            name="Title"
            type="text"
          />
          <Row className="space-row">
            <Col md={12}>
              <Editor
                editorState={editorState}
                wrapperClassName="wrapper"
                editorClassName="editor-class"
                toolbarClassName="toolbar-class"
                onEditorStateChange={onEditorStateChange}
                localization={{
                  locale: "pt",
                }}
                toolbar={{
                  options: [
                    "inline",
                    "blockType",
                    "list",
                    "textAlign",
                    "fontSize",
                    "history",
                    "image",
                  ],
                  inline: {
                    options: ["bold", "italic"],
                  },
                  textAlign: { inDropdown: true },
                  image: {
                    uploadEnabled: true,
                    uploadCallback: uploadCallback,
                    previewImage: true,
                    inputAccept:
                      "image/gif,image/jpeg,image/jpg,image/png,image/svg",
                    alt: { present: false, mandatory: false },
                    defaultSize: {
                      height: "auto",
                      width: "auto",
                    },
                  },
                }}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{ marginRight: "5px" }}
            onClick={handleSubmit}
            variant="contained"
            color="primary"
          >
            Adicionar
          </Button>
          <Button
            onClick={handleCloseModal}
            variant="contained"
            color="secondary"
          >
            Cancelar
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export const ModalHeaderFooter = ({ headerTitle, footerTitle }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { showModalHeader, showModalFooter } = useSelector(
    (state) => state.template
  );

  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };

  const handleSubmit = () => {
    const currentContent = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );

    headerTitle &&
      dispatch(
        TemplateActions.addHeader({ currentContent, id_editor: uuidv4() })
      );
    footerTitle &&
      dispatch(
        TemplateActions.addFooter({ currentContent, id_editor: uuidv4() })
      );

    headerTitle &&
      dispatch(TemplateActions.showModal({ modal: "HEADER", show: false }));
    footerTitle &&
      dispatch(TemplateActions.showModal({ modal: "FOOTER", show: false }));
  };

  const handleCloseModal = () => {
    headerTitle &&
      dispatch(TemplateActions.showModal({ modal: "HEADER", show: false }));
    footerTitle &&
      dispatch(TemplateActions.showModal({ modal: "FOOTER", show: false }));
  };

  return (
    <Modal
      className={classes.root}
      show={showModalHeader || showModalFooter}
      centered
    >
      <Form>
        <Modal.Header>
          <Modal.Title>{headerTitle ? headerTitle : footerTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="space-row">
            <Col md={12}>
              <Editor
                editorState={editorState}
                wrapperClassName="wrapper"
                editorClassName="editor-class"
                toolbarClassName="toolbar-class"
                onEditorStateChange={onEditorStateChange}
                localization={{
                  locale: "pt",
                }}
                toolbar={{
                  options: [
                    "inline",
                    "fontSize",
                    "blockType",
                    "list",
                    "textAlign",
                    "history",
                    "image",
                  ],
                  inline: {
                    options: ["bold", "italic"],
                  },
                  textAlign: { inDropdown: true },
                  image: {
                    uploadEnabled: true,
                    uploadCallback: uploadCallback,
                    previewImage: true,
                    inputAccept:
                      "image/gif,image/jpeg,image/jpg,image/png,image/svg",
                    alt: { present: false, mandatory: false },
                    defaultSize: {
                      height: "auto",
                      width: "auto",
                    },
                  },
                }}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{ marginRight: "5px" }}
            onClick={handleSubmit}
            variant="contained"
            color="primary"
          >
            Adicionar
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleCloseModal}
          >
            Cancelar
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
