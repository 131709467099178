import React, { useState, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Creators as ImportacaoActions } from "store/ducks/importacao";
import { Creators as LayoutActions } from "store/ducks/layout";

import { toastr } from "react-redux-toastr";
import { Row, Col } from "react-bootstrap";
import { Form } from "@unform/web";

import CircularProgress from "@material-ui/core/CircularProgress";
import AutocompleteForm from "components/AutocompleteForm";
import SeparadorLayout from "../Layout/Separador/index";
import Skeleton from "@material-ui/lab/Skeleton";
import BackButton from "components/BackButton";
import Button from "@material-ui/core/Button";
import InputForm from "components/InputForm";
import DropZone from "components/Dropzone";
import Grid from "@material-ui/core/Grid";
import Content from "components/Content";
import { getPresignedUrl } from "services/presignedUrl";

const INITIAL_FORM = {
  layout: "",
  groupDistribution: null,
  files: [],
};

export default function Importacao() {
  const dispatch = useDispatch();
  const formRef = useRef(null);
  const [loading] = useState(false);
  const [form, setForm] = useState(INITIAL_FORM);
  const [selectedLayout, setSelectedLayout] = useState(null);

  const {
    access: { empresas, relacionamentos },
  } = useSelector((state) => state.permissoes);

  const { layout: listLayout, loading: loadingLayout } = useSelector(
    (state) => state.layout
  );
  
  useEffect(() => {
    dispatch(
      LayoutActions.listRequest({
        page: 1,
        limit: 100,
      })
    );
  }, [dispatch, empresas]);

  const checkFile = (file) => {
    const typeSuport = ["xlsx", "xls", "csv", "txt"];
    const typeFile = file.path.split(".").pop();
    return typeSuport.includes(typeFile);
  };

  const onDrop = useCallback(
    (files) => {
      const filter = files.filter(checkFile);
      setForm({ ...form, files: filter || [] });
    },
    [form.files]
  );

  const handleDeleteFile = (file) => {
    setForm({ ...form, files: form?.files.filter((item) => item !== file) });
  };

  const getFileExtensionFromUrl = (url) => {
    const parts = url.split(".");
    return parts.length > 1 ? parts.pop() : "";
  };

  const handleDownload = async () => {
  if (!selectedLayout?.filepath) {
    return toastr.error("Ops", "O layout selecionado não possui arquivo");
  }

  try {
    const response = await getPresignedUrl(selectedLayout.filepath);
    
    const link = document.createElement("a");
    link.href = response;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    toastr.error("Erro", "Não foi possível baixar o arquivo");
    console.error("Erro no download:", error);
  }
};

  const onSubmitFiles = async (values) => {
    try {
      values["files"] = form?.files || [];
      if (form?.files.length == 0) {
        return toastr.info("Ops", "Selecione ao menos um arquivo.");
      }

      if (values?.separador == null) {
        return toastr.error("Separador é obrigátorio");
      }

      if (values?.layout == null) {
        return toastr.error("Layout é obrigátorio");
      }

      dispatch(ImportacaoActions.createRequest(values));
      setForm({ ...form, files: [] });
    } catch (err) {
      return toastr.error("Ops", "Ocorreu um erro.");
    }
  };

  return (
    <Content>
      <Row>
        <Col md="12" className="painel">
          <div className="painel-content">
            <div className="painel-title text-left">
              Importação de documentos
            </div>
            <div className="painel-body">
              <Form
                ref={formRef}
                onSubmit={onSubmitFiles}
                className={"mt-2"}
              >
                <SeparadorLayout titulo="Arquivo & layout" />
                <Row>
                  <Col md={3} sm="12">
                    {!loadingLayout ? (
                      <AutocompleteForm
                        label="Layout"
                        name="layout"
                        options={listLayout?.docs || []}
                        transformOnSelect={(option) =>
                          option ? option._id : null
                        }
                        getOptionLabel={(option) => option.nome}
                        onChange={(event, value) => setSelectedLayout(value)}
                      />
                    ) : (
                      <Skeleton width="100%" height={50} />
                    )}
                  </Col>
                  <Col md={3} sm="12">
                    <InputForm
                      label="Quebra de coluna"
                      name="separador"
                      type="text"
                      multiline
                      minRows={1}
                    />
                  </Col>
                  <Col md={3} sm="12" style={{ marginTop: "6px" }}>
                    <Button
                          variant="contained"
                          color="primary"
                          disabled={loading || selectedLayout == null}
                          onClick={handleDownload}
                        >
                          Baixar Layout
                          {!!loading && <CircularProgress size={20} />}
                    </Button>
                  </Col>
                </Row>
                <DropZone
                  onDrop={onDrop}
                  files={form.files}
                  onDeleteFile={handleDeleteFile}
                  message={
                    <p>
                      Arraste e solte alguns arquivos aqui para importação
                      do <strong>layout selecionado</strong>
                    </p>
                  }
                  warning={
                    <div>
                      <em>Apenas *.csv, *.xlsx e *.txt são aceitos</em><br /><br />
                      <em>Encoding aceitos: UTF-8, ANSI, WINDOWS-1252, ISO 8859-1 e IBM855</em>
                    </div>
                  }
                />
                <Row>
                  <Col md="6" sm="12">
                    <Grid container spacing={1}>
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          disabled={loading}
                        >
                          Enviar
                          {!!loading && <CircularProgress size={20} />}
                        </Button>
                      </Grid>
                      <Grid item>
                        <BackButton variant="contained" color="secondary">
                          Voltar
                        </BackButton>
                      </Grid>
                    </Grid>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        </Col>
      </Row>
    </Content>
  );
}
