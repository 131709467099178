export const Types = {
  NEW_REQUEST: "dashboardcustom/NEW_REQUEST",
  NEW_SUCCESS: "dashboardcustom/NEW_SUCCESS",
  NEW_REQUEST_YEAR: "dashboardcustomyear/NEW_REQUEST",
  NEW_SUCCESS_YEAR: "dashboardcustomyear/NEW_SUCCESS",
  CLEAR: "dashboardcustomyear/CLEAN",
  PREDEFINITIONS_REQUEST: "dashboardcustomyear/PREDEFINITIONS_REQUEST",
  PREDEFINITIONS_SUCCESS: "dashboardcustomyear/PREDEFINITIONS_SUCCESS",
  EXPORT_REQUEST: "dashboardcustomyear/EXPORT_REQUEST",
  EXPORT_SUCCESS: "dashboardcustomyear/EXPORT_SUCCESS",
};

/* Reducer */

const INITIAL_STATE = {
  loading: false,
  data: null,
  x: null,
  y: null,
  predefinitions: [],
  loadingExport: false,
  exportacao: null,
};

export default function items(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.CLEAR:
      return { ...state, loading: false, data: null, x: null, y: null };
    case Types.NEW_REQUEST:
      return { ...state, loading: true };

    case Types.NEW_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action?.payload?.data,
        y: action?.payload?.y,
        x: action?.payload?.x,
      };

    case Types.NEW_REQUEST_YEAR:
      return { ...state, loading: true };

    case Types.NEW_SUCCESS_YEAR:
      return {
        ...state,
        loading: false,
        data: action?.payload?.data,
        y: action?.payload?.y,
        x: action?.payload?.x,
        months: action?.payload.months
      };

    case Types.PREDEFINITIONS_REQUEST:
      return { ...state, loading: true };

    case Types.PREDEFINITIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        predefinitions: action?.payload,
      };

    case Types.EXPORT_REQUEST:
      return { ...state, loadingExport: true };

    case Types.EXPORT_SUCCESS:
      return { ...state, loadingExport: false, exportacao: action?.payload }

    default:
      return state;
  }
}

export const Creators = {
  createRequest: (dashboardcustom) => ({
    type: Types.NEW_REQUEST,
    payload: dashboardcustom,
  }),

  createSuccess: (dashboardcustom) => ({
    type: Types.NEW_SUCCESS,
    payload: dashboardcustom,
  }),

  createYearRequest: (dashboardyearcustom) => ({
    type: Types.NEW_REQUEST_YEAR,
    payload: dashboardyearcustom,
  }),

  createYearSuccess: (dashboardyearcustom) => ({
    type: Types.NEW_SUCCESS_YEAR,
    payload: dashboardyearcustom,
  }),

  clearRequest: () => ({
    type: Types.CLEAR,
    payload: null,
  }),

  predefinitionsRequest: (dashboardcustom) => ({
    type: Types.PREDEFINITIONS_REQUEST,
    payload: [],
  }),

  predefinitionsSuccess: (dashboardcustom) => ({
    type: Types.PREDEFINITIONS_SUCCESS,
    payload: dashboardcustom,
  }),

  exportRequest: (data) => ({
    type: Types.EXPORT_REQUEST,
    payload: data,
  }),
  exportSuccess: (data) => ({
    type: Types.EXPORT_SUCCESS,
    payload: data,
  }),
};
