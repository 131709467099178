import React, { useMemo, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
} from "@material-ui/core";
import { Creators as TemplateActions } from "store/ducks/template";
import { useSelector, useDispatch } from "react-redux";
import StyledTableRow from "components/StyledTableRow";
import { CircularProgress } from "@material-ui/core";
import ButtonSquare from "components/ButtonSquare";
import LoadingTable from "components/LoadingTable";
import ToolTipComponent from "components/Tooltip";
import ModalDeleteConfirmationTemplate from "../ModalDeleteConfirmationTemplate";

function TableTemplate({ userActions }) {
  const dispatch = useDispatch();

  const { loading, template } = useSelector((state) => state.template);

  const hasActions = useMemo(
    () =>
      true ||
      (userActions.actions &&
        userActions.actions.some(
          (userAct) => userAct.action === "editar" || userAct.action === "criar"
        )),
    [userActions]
  );

  const handleDelete = (template) => {
    if (template) dispatch(TemplateActions.openDelete(template));
  };

  useEffect(() => {
    dispatch(TemplateActions.clearSelectedTemplate());
    dispatch(TemplateActions.getTemplate({}));
  }, [dispatch]);

  return !!loading ? (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <CircularProgress color="primary" size={20} />
    </div>
  ) : (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Nome</TableCell>
            <TableCell>Descrição</TableCell>
            <TableCell>Tipo</TableCell>
            {userActions.actions &&
              userActions.actions.map(
                (userAct, index) =>
                  userAct.action === "editar" && (
                    <TableCell key={index} align="center">
                      Ações
                    </TableCell>
                  )
              )}
          </TableRow>
        </TableHead>
        <TableBody>
          {!!loading && <LoadingTable columns={hasActions ? 4 : 2} />}
          {!loading &&
            template?.map((item) => (
              <StyledTableRow key={item._id}>
                <TableCell>{item.name}</TableCell>
                <TableCell>{item.description}</TableCell>
                <TableCell>{item.modelType}</TableCell>
                {userActions.actions &&
                  userActions.actions.map(
                    (userAct, index) =>
                      userAct.action === "editar" && (
                        <TableCell align="center" key={index}>
                          <div className="flex-direction">
                            <ToolTipComponent title="Editar" placement="top">
                              <Link
                                id="edit"
                                className="linkicon"
                                to={{
                                  pathname: "/template/edit",
                                  state: { template: item },
                                }}
                              >
                                <ButtonSquare icon="edit" />
                              </Link>
                            </ToolTipComponent>
                            <ToolTipComponent title="Editor" placement="top">
                              <Link
                                id="editor"
                                className="linkicon"
                                to={{
                                  pathname: "/template/editor",
                                  state: { template: item },
                                }}
                              >
                                <ButtonSquare icon="assignment" />
                              </Link>
                            </ToolTipComponent>
                            <ToolTipComponent title="Remover" placement="top">
                              <ButtonSquare
                                icon="delete"
                                style={{ cursor: "pointer" }}
                                onClick={() => handleDelete(item)}
                              />
                            </ToolTipComponent>
                          </div>
                        </TableCell>
                      )
                  )}
              </StyledTableRow>
            ))}
        </TableBody>
      </Table>
      <ModalDeleteConfirmationTemplate open={true} />
    </TableContainer>
  );
}

export default TableTemplate;
