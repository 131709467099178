import React, { useState, useEffect } from 'react';

import { Col, Row } from 'react-bootstrap';
import _ from '@lodash';
import { Alert } from '@material-ui/lab';
import { toastr } from 'react-redux-toastr';

import TextField from '@material-ui/core/TextField';

import {
  KeyboardArrowRightRoundedIcon,
  KeyboardArrowLeftRoundedIcon,
  FileCopyIcon,
} from 'components/Icons';
import { IconButton, InputAdornment } from '@material-ui/core';

export default function Destaque({ form, expandLeftSize, leftSize }) {
  const [indexadores, setIndexadores] = useState([]);

  const handleCopy = (value) => {
    navigator.clipboard.writeText(value);
    toastr.info('', 'Input copiado em segundo plano');
  };

  function handleDoubleClick(e) {
    if (!e.target.value) return;
    navigator.clipboard.writeText(e.target.value);
    toastr.info('', 'Input copiado em segundo plano');
  }

  useEffect(() => {
    if (form.indexadores) setIndexadores(form.indexadores);
  }, [form.indexadores]);

  return (
    <div className="panel">
      <div className="formalizacao index-title">
        <span>Indexadores em destaque</span>
        <button type="button" onClick={expandLeftSize}>
          {leftSize === 6 ? (
            <KeyboardArrowRightRoundedIcon />
          ) : (
            <KeyboardArrowLeftRoundedIcon />
          )}
        </button>
      </div>
      <div className="index-inputs">
        {indexadores &&
          indexadores.filter((indexador) => indexador.destacarFormalizacao)
            .length === 0 && (
            <Alert className="mb-2" severity="warning">
              Nenhum campo em destaque
            </Alert>
          )}

        {indexadores
          .filter((indexador) => indexador.destacarFormalizacao)
          .map((indexador) => (
            <Row key={indexador.nome}>
              <Col md={12}>
                <TextField
                  label={indexador.nome}
                  value={indexador.valor}
                  disabled={true}
                  variant="outlined"
                  shrink
                  size="small"
                  onDoubleClick={handleDoubleClick}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={(e) => handleCopy(indexador.valor)}
                          edge="end"
                          aria-label="Copiar texto"
                        >
                          <FileCopyIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Col>
            </Row>
          ))}
      </div>
    </div>
  );
}
