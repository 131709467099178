import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { FaSearch, FaTimes } from "components/Icons";

export function SearchInput({ id, placeholder, onChange,width, margin}) {

    const useStyles = makeStyles((theme) => ({
        inputDiv: {
            background: "rgba(196, 196, 196, 0.3)",
            border: "none",
            padding: "10px 10px ",
            boxSizing: "border-box",
            width: "90%",
            "&::placeholder": {
                fontSize: 12,
            },
        },
        div: {
            boxSizing: "border-box",
            margin,
            width,
            display: "flex",
            alignItems: "center",
            flex: 1,
            boxSizing: "border-box",
        },
        searchIcon: {
            background: "rgba(196, 196, 196, 0.3)",
            height: 41,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "10%",
            cursor: "pointer",
        },
    }));

    const classes = useStyles();
    const [texto, setTexto] = useState('');

    useEffect(() => { onChange(texto) }, [texto]);

    return (
        <div className={classes.div}>
            <input
                id={id}
                type="text"
                value={texto}
                onChange={(e) => {
                    setTexto(e.target.value);
                }}
                className={classes.inputDiv}
                placeholder={placeholder}
            />
            <div className={classes.searchIcon}
                disabled={texto.length === 0}
                onClick={() => { setTexto('') }}
            >
                {texto.length ? <FaTimes color="#999" /> : <FaSearch color="#999" />}
            </div>
        </div>
    )
}