import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap';

import { Creators as DashboardActions } from 'store/ducks/dashboard';
import { Creators as ReportActions } from 'store/ducks/report';

import { List } from '@material-ui/core';
import { Container } from './styles';

import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import TabPanel from 'components/TabPanel';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Report from './Report/index';
import Filter from './Filter';
import { AssessmentIcon, DashboardIcon, LockOpenIcon } from 'components/Icons';
import Content from 'components/Content';

export default function Dashboard() {
  const username = useSelector(state => state.usuarios.auth.name);
  const INITIAL_FORM = {
    dataInicial: '',
    dataFinal: '',
    tipo: '',
    isExcel: false,
    filter: '',
    showSLAFilter: false,
  };

  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [form, setForm] = useState(INITIAL_FORM);
  const [open, setOpen] = useState(false);

  const { data: reportBase64, loading: loadingReport } = useSelector(state => state.report);

  const { data: dashboard, loading: loadingDashboard } = useSelector(state => state.dashboard);

  const documentosEmFluxo = dashboard?.instances || 0;
  const documentosForaSLA = dashboard?.instancesByTaskAndProcess?.find(inst => inst && inst.expired)?.count || 0;
  const documentosDentroSLA = dashboard?.instancesByTaskAndProcess?.find(inst => inst && !inst.expired)?.count || 0;
  const mediaMinutosTarefas = dashboard?.avgDurationTaskByMonth[0]?.averageInMinutes || 0;
  const percentualSLA = documentosEmFluxo === 0 ? 100 : (documentosDentroSLA * 100) / documentosEmFluxo;
  const percentualTM = 800;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const closeModal = () => {
    setOpen(false);
  };

  const printReport = ({ tipo, isExcel, filter, showSLAFilter = false, sendAsMail, canPrint, canSheel }) => {
    setForm({
      ...form,
      tipo,
      isExcel,
      filter,
      id: Math.random(),
      showSLAFilter,
      sendAsMail,
      canPrint,
      canSheel,
    });

    dispatch(ReportActions.createSuccess({}));

    setOpen(true);
  };

  //fazer download do arquivo recebido como url
  useEffect(() => {
    if (reportBase64) {
      if (form.isExcel) {
        const link = document.createElement('a');
        link.href = reportBase64;
        link.download = `report.xlsx`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        window.open(reportBase64, "_blank");
      }
    }
  }, [reportBase64]);

  useEffect(() => {
    dispatch(DashboardActions.createRequest());
  }, []);

  return (
    <Content>
      <Row>
        <Col md="12">
          <div className="painel-content">
            <div className="painel-body">
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="administrativo"
                indicatorColor="primary"
                variant="fullWidth"
                icon={<DashboardIcon />}
              >
                <Tab label="Relatórios" variant="fullWidth" textColor="primary" icon={<AssessmentIcon />} />
                <Tab label="Auditoria" variant="fullWidth" textColor="primary" disabled icon={<LockOpenIcon />} />
              </Tabs>
              <TabPanel value={value} index={0}>
                <Col md={12} sm={12} className="mt-4">
                  <Filter
                    handleClose={closeModal}
                    type={form.filter}
                    form={form}
                    open={open}
                    showSLAFilter={form.showSLAFilter}
                  />
                  <Row>
                    <Col sm={12} md={6}>
                      <TextField
                        name="searchText"
                        label="Digite para pesquisar"
                        value={searchText}
                        size="small"
                        onChange={e => setSearchText(e.target.value)}
                        variant="outlined"
                      />
                    </Col>
                  </Row>
                  <List>
                    <Row className="mt-2">
                      <Report printReport={printReport} searchText={searchText} canSubmit={!loadingReport} />
                    </Row>
                  </List>

                  {(loadingReport || loadingDashboard) && (
                    <div
                      style={{
                        padding: '1rem',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <CircularProgress color="secondary" />
                    </div>
                  )}

                  {!loadingDashboard && !loadingReport && reportBase64 && (
                    <Col sm={12}>
                      {form.isExcel ? (
                        <iframe
                          src={`data:application/vnd.ms-excel;base64,${reportBase64}`}
                          title="Relatório"
                          width="100%"
                          height="800px"
                          hidden={true}
                        />
                      ) : (
                        <iframe
                          src={`data:application/pdf;base64,${reportBase64}`}
                          title="Relatório"
                          width="100%"
                          height="800px"
                            hidden={true}
                        />
                      )}
                    </Col>
                  )}
                </Col>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Container></Container>
              </TabPanel>
            </div>
          </div>
        </Col>
      </Row>
    </Content>
  );
}
