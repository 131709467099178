import React, { useMemo, useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, Link } from "react-router-dom";
import {
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TableContainer,
    TableFooter,
    TablePagination,
} from "@material-ui/core";

import { Creators as FormularioActions } from "store/ducks/formulario";
import { Creators as GrupoEmailActions } from "store/ducks/grupoemail";

import StyledTableRow from "components/StyledTableRow";
import LoadingTable from "components/LoadingTable";
import ButtonSquare from "components/ButtonSquare";
import ToolTipComponent from "components/Tooltip";
import ModalShare from "../ModalShare";

function TableFormularios() {

    const { pathname } = useLocation();

    const [userActions, setUserActions] = useState([]);
    const [open, setOpen] = useState(false);
    const [share, setShare] = useState(null);

    const dispatch = useDispatch();
    const {
        loading,
        list: formularios,
        total,
        page,
        limit,
        sort,
        search,
    } = useSelector((state) => state.formularios);

    const {
        access: { servicos },
    } = useSelector((state) => state.permissoes);

    const hasActions = useMemo(() =>
        true ||
        (userActions.actions &&
            userActions.actions.some(
                (userAct) => userAct.action === "editar" || userAct.action === "criar"
            )),
        [userActions]
    );
    //#region funções auxiliares
    function handleDelete(formulario) {
        dispatch(FormularioActions.deleteRequest(formulario));
    }

    function handleChangeRowsPerPage({ target }) {
        const { value } = target;
        dispatch(
            FormularioActions.listRequest({
                page,
                limit: value,
                sort,
                search,
            })
        );
    }

    function handleChangePage(e, newPage) {
        dispatch(
            FormularioActions.listRequest({
                page: newPage + 1,
                limit,
                sort,
                search,
            })
        );
    }

    function handleShare(registro) {
        const { _id } = registro;
        const id = _id.toString();
        setShare(id);
        setOpen(true);
    }
    //#endregion

    //#region efeitos
    useEffect(() => {
        let find = servicos.find((item) => item.route === pathname);
        if (find !== undefined) {
            setUserActions(find);
        }
    }, [pathname, servicos]);

    useEffect(() => {
        dispatch(FormularioActions.listRequest({}));
        dispatch(
            GrupoEmailActions.listRequest({
                page: 1,
                limit: Number.MAX_SAFE_INTEGER,
            })
        );
    }, [dispatch]);
    //#endregion

    return (
        <>
            <TableContainer>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Nome</TableCell>
                            <TableCell>Tipo de Documento</TableCell>
                            <TableCell>Departamento</TableCell>
                            {userActions.actions &&
                                userActions.actions.map(
                                    (userAct, index) =>
                                        userAct.action === "editar" && (
                                            <TableCell key={index} align="center">
                                                Ações
                                            </TableCell>
                                        )
                                )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!!loading && <LoadingTable columns={hasActions ? 4 : 2} />}
                        {
                            !loading &&

                            formularios?.map((formulario) => {
                                const { _id, nome, idTipoDocumento, idDepartamento } = formulario;
                                return (
                                    <StyledTableRow key={_id}>
                                        <TableCell>{nome}</TableCell>
                                        <TableCell>
                                            {idTipoDocumento ? idTipoDocumento.nome : "-"}
                                        </TableCell>
                                        <TableCell>
                                            {idDepartamento ? idDepartamento.nome : "-"}
                                        </TableCell>
                                        {userActions.actions &&
                                            userActions.actions.map(
                                                (userAct, index) =>
                                                    userAct.action === "editar" && (
                                                        <TableCell align="center" key={index}>
                                                            <div className="flex-direction">

                                                                <ToolTipComponent title="Compartilhar formulário" placement="top">
                                                                    <ButtonSquare
                                                                        onClick={() => handleShare(formulario)}
                                                                        icon="share"
                                                                    />
                                                                </ToolTipComponent>

                                                                <ToolTipComponent title="Editar" placement="top">
                                                                    <Link
                                                                        to={{
                                                                            pathname: "/formulario/edit",
                                                                            state: { formulario: formulario },
                                                                        }}
                                                                        className="linkicon"
                                                                        id="formulario"
                                                                    >
                                                                        <ButtonSquare icon="edit"
                                                                            style={{ marginLeft: "0.5rem" }}
                                                                        />
                                                                    </Link>
                                                                </ToolTipComponent>

                                                                <ToolTipComponent title="Remover" placement="top">
                                                                    <ButtonSquare
                                                                        onClick={() => handleDelete(formulario)}
                                                                        icon="delete"
                                                                    />
                                                                </ToolTipComponent>
                                                            </div>
                                                        </TableCell>
                                                    )
                                            )}
                                    </StyledTableRow>
                                );
                            })

                        }
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                count={total || 0}
                                page={page - 1}
                                rowsPerPage={limit || 10}
                                rowsPerPageOptions={[10, 25, 50, 100]}
                                labelRowsPerPage="Linhas por página"
                                labelDisplayedRows={({ from, to, count }) =>
                                    `${from}-${to} de ${count !== -1 ? count : `mais que ${to}`}`
                                }
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                onPageChange={handleChangePage}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer >

            <ModalShare
                open={open}
                onCloseModal={() => setOpen(false)}
                idFormulario={share}
            />
        </>
    );
}

export default TableFormularios;
