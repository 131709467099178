import api from 'services/api';
import { getToken } from 'services/auth';
import { put, call, select, all, takeLatest } from "redux-saga/effects";
import { toastr } from "react-redux-toastr";

import { Creators as ImagensModalidadeActions, Types } from 'store/ducks/imagensmodalidade';

export const getOrganization = state => state.usuarios.auth.organization;
export const getTipoImagens = state => state.tipoImagem;

export function* saveImagens({ payload }) {
  const organization = yield select(getOrganization);
  const { modalidade, empresa, ...body } = payload;

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization }
    }

    const { data } = yield call(
      api.put,
      `modalidades/${modalidade}/empresas/${empresa}`,
      body,
      headerParams
    );

    const { list: tipoImagens } = yield select(getTipoImagens);
    const imagens = tipoImagens.filter(tipoImagem => data.imagens.includes(tipoImagem._id));

    yield put(ImagensModalidadeActions.saveSuccess({ ...data, imagens }));

    toastr.success('Dados salvos com sucesso!')
  } catch(error) {
    toastr.error('Ops', 'Não foi possível salvas as imagens.');
  }
}

export function* listImagens({ payload }) {
  const organization = yield select(getOrganization);
  const { modalidade, page, limit, sort } = payload;

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
      params: { page, limit, sort }
    }

    const response = yield call(
      api.get,
      `modalidades/${modalidade}/empresas`,
      headerParams
    );

    const data = {
      list: response.data.docs,
      total: response.data.total
    };

    yield put(ImagensModalidadeActions.listSuccess(data));
  } catch (error) {
    toastr.error('Ops', 'Não foi possível listar as imagens.');
  }
}

export default all([
  takeLatest(Types.LIST_REQUEST, listImagens),
  takeLatest(Types.SAVE_REQUEST, saveImagens)
])