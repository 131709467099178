import React from 'react';

import {
    Container,
    SVGContainer,
} from './styles/CustomButtonIcon';

import Tooltip from '../Tooltip';

function CustonButtonIcon({ tooltip, onClick, Icon, progress, iconcolor, iconbackground, progresscolor, disabled, arrow }) {
    const size = 36;

    return (
        <Tooltip title={tooltip} placement="top" arrow={arrow}>
            <Container
                disabled={disabled}
                onClick={onClick}
                iconcolor={iconcolor}
                iconbackground={iconbackground}>
                {progress && 
                    <SVGContainer
                        progresscolor={progresscolor}>
                        <svg height={size} width={size} >
                            <circle cx={size / 2} cy={size / 2} r="14" />
                        </svg>
                    </SVGContainer> }
                    {Icon}
            </Container>
        </Tooltip>
    );
}

export default CustonButtonIcon;