import styled from 'styled-components';

export const Container = styled.div`
    margin: auto;
`;

export const Page = styled.div`
    background-color: #fff;
    margin-top: -11px;
    margin-bottom: 16px;
    min-height: calc(29cm * 1.2);

    width: calc(21cm * 1.2);
    padding: 25px 65px;
    border-radius: 4px;
    box-shadow: 4px 4px 8px 2px #00000027;

    display:flex; 
    flex-direction:column; 

    .header {
        width: 100%;
        top: 0px;
    }
    .content {
        width: 100%;     
    }
    .footer {
        /* position: absolute;
        right: 62px;
        left: 77px;
        bottom: 25px; */
        margin-top: auto; 
    }

    @media screen and (max-width: 1400px) {
        min-height: calc(29cm * .8);
        width: calc(21cm * .8);
        padding: calc(25px * .6) calc(65px * .6);
    }
`;

