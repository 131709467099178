import publicIp from 'public-ip';
import privateIp from 'ip';

export const getPublicIP = async () => {

  const response = await publicIp.v4();

  return response;
} 

export const getPrivateIp = () => privateIp.address();
