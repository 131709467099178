import React, { useState, useEffect, useCallback, useRef } from "react";

import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { useSelector, useDispatch } from "react-redux";

import useStyles from "../FormEnviarWhatsapp/styles";
import Utils from "utils/utils";
import clsx from "clsx";

import { Creators as WhatsappActions } from "store/ducks/whatsapp";


import { TextEditorWithTemplate } from "components/TextEditorWithTemplate";


const util = new Utils();

function FormEnviarWhatsapp({ documento, email, children, aoEnviar = () => { } }) {
  const [emailCarregado] = useState(email);

  useEffect(() => {
    console.log("documento::::::::", documento);
  }, [documento])

  const [whatsappNumber] = useState(documento.whatsappNumber);

  const ref = useRef();

  const INITIAL_FORM = {
    documento: documento?._id || "",
    whatsappNumber,
    texto: "",
  };

  const { loading } = useSelector((state) => state.comentarios);
  const [form, setForm] = useState(INITIAL_FORM);
  const [sending, setSending] = useState(false);


  const { loading: loadingWhatsapp } = useSelector(
    (state) => state.whatsapp
  );

  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    setForm(INITIAL_FORM);
  }, [loading]);

  console.log("form::::::", form)

  function canSubmit() {
    return (
      !loadingWhatsapp &&
      !sending &&
      form.whatsappNumber !== "" &&
      form.texto !== ""
    );
  }

  function clearForm() {
    setForm(INITIAL_FORM);
    ref.current?.clearForm();
  }

  async function handleSubmit() {
    setSending(true);

    let formattedTexto = form.texto.replace(/<\/?[^>]*>*/g, '')
    formattedTexto = formattedTexto.replace(/&nbsp;/g, ' ')

    const payload = { ...form, texto: formattedTexto };

    dispatch(WhatsappActions.createRequest(payload));

    //if (aoEnviar) aoEnviar();
    clearForm();

    setSending(false);
  }



  return (
    <div style={{ marginTop: "1rem" }}>

      <TextField
        size="small"
        variant="outlined"
        name="whatsappNumber"
        id="whatsappNumber"
        label="Número do Whatsapp"
        defaultValue={whatsappNumber}
        className={clsx(classes.margin, classes.textField)}

        InputProps={{
          readOnly: true,
          // endAdornment: (
          //   <InputAdornment position="start">
          //     - {documento?.numeroCNJ}
          //   </InputAdornment>
          // ),
        }}
      />


      <TextEditorWithTemplate
        size={"sm"}
        documento={documento}
        // onSelectTemplate={(texto) => handleSelectTemplate(texto)}
        ref={ref}
        hideTemplates={true}
        onTextChange={(texto) =>
          setForm({
            ...form,
            texto,
          })
        }
      />

      <div className={classes.actions}>
        <Button
          color="primary"
          variant="contained"
          className={classes.newCommentButton}
          disabled={!canSubmit()}
          onClick={handleSubmit}
          children={sending ? "Enviando..." : "Enviar"}
        />
        {children}
      </div>
    </div>
  );
}

export default FormEnviarWhatsapp;
