import { call, put, select } from 'redux-saga/effects';

import { toastr } from 'react-redux-toastr';

import api from '../../services/api';
import { getToken } from '../../services/auth';

import { Creators as ConfiguracaoActions } from '../ducks/configuracoes';

export const getOrganization = state => state.usuarios.auth.organization;
export const getUserId = state => state.usuarios.auth.id;

export function* getConfiguracoes(xkeyorg) {
  try {
    const organization = yield select(getOrganization);

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const { data } = yield call(api.get, `configuracoes`, headerParams);

    yield put(ConfiguracaoActions.getSuccess(data));
  } catch (error) {
    toastr.error('Ops', 'Não foi possível buscar as configurações do sistema.');
  }
}

export function* editConfiguracoes(action) {
  const userId = yield select(getUserId);

  try {
    const organization = yield select(getOrganization);

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const body = { ...action.payload, id_user_acao: userId };

    const { data } = yield call(api.put, `configuracoes`, body, headerParams);

    yield getConfiguracoes();

    // yield put(push(`/configuracoes`));

    yield put(ConfiguracaoActions.editSuccess(data));

    toastr.success('Configurações atualizadas com sucesso.');
  } catch (error) {
    toastr.error('Ops', 'Não foi possível atualizar as configurações.');
  }
}
