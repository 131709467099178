import React, { useRef } from "react";
import { useDispatch } from "react-redux";
import { Creators as GruposEmpresariaisActions } from "store/ducks/gruposempresariais";

import _ from 'lodash';

import GridCadastro from "components/GridCadastro";
import TableGruposEmpresariais from "./TableGruposEmpresariais";

export default function GruposEmpresariais() {

  const dispatch = useDispatch();
  const dispatchDebounce = useRef(_.debounce(dispatch, 500)).current;

  return (
    <GridCadastro
      title='Grupos Empresariais'
      route={"/administracao/gruposempresariais/create"}
      searchRequest={(value) => {
        dispatchDebounce(
          GruposEmpresariaisActions.listRequest({ page: 1, limit: 10, search: value })
        );
      }}
      titleButtonNovo="Novo Grupo"
    >
      <TableGruposEmpresariais />
    </GridCadastro>
  )
}