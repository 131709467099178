import React, { useEffect } from 'react';

import DOMPurify from 'dompurify';

function TextStep({ content }) {
  const cleanHtml = DOMPurify.sanitize(content);

  return <div dangerouslySetInnerHTML={{ __html: cleanHtml }} />;
}

export default TextStep;
