import api from "services/api";
import { getToken } from "services/auth";
import { call, select, put, all, takeLatest } from "redux-saga/effects";
import { toastr } from "react-redux-toastr";
import { parseISO } from "date-fns";

import { Creators as DespesaActions, Types } from "store/ducks/despesa";
import { Creators as DespesasActions } from "store/ducks/despesas";

export const getOrganization = (state) => state.usuarios.auth.organization;
export const getParamDespesas = (state) => ({
  page: state.despesas.page,
  search: state.despesas.search,
  documento: state.despesas.documento,
  sort: state.despesas.sort,
  limit: state.despesas.limit,
});

export const getDespesa = (state) => state.despesa.data;

export function* saveDespesa({ payload }) {
  const organization = yield select(getOrganization);
  const { _id, ...body } = payload.despesa;

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const data = {
      ...body,
      data: parseISO(body.data),
      dataDoVencimento: parseISO(body.dataDoVencimento),
    };

    if (_id) {
      yield call(api.put, `despesas/${_id}`, data, headerParams);
    } else {
      yield call(api.post, "despesas", data, headerParams);
    }

    toastr.success("Sucesso!", "Dados salvos com sucesso!");
    yield put(DespesaActions.saveSuccess());
    const params = yield select(getParamDespesas);
    yield put(DespesasActions.listRequest(params));
  } catch (error) {
    yield put(DespesaActions.saveError());
    toastr.error("Ops", "Não foi possível salvar a despesa.");
  }
}

export function* remove({ payload }) {
  const organization = yield select(getOrganization);
  const id = payload;

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    yield call(api.delete, `despesas/${id}`, headerParams);

    toastr.success("Sucesso!", "Remoção realizada com sucesso!");
    yield put(DespesaActions.deleteSuccess());
    const params = yield select(getParamDespesas);
    yield put(DespesasActions.listRequest(params));
  } catch (err) {
    yield put(DespesaActions.uploadError());
    toastr.error("Ops", "Não foi possível realizar a remoção");
  }
}

export function* upload({ payload }) {
  const organization = yield select(getOrganization);
  const despesa = yield select(getDespesa);
  const { body } = payload;

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    yield call(api.post, `despesas/${despesa._id}/imagens`, body, headerParams);

    toastr.success("Sucesso!", "Upload realizado com sucesso!");
    yield put(DespesaActions.uploadSuccess());
    const params = yield select(getParamDespesas);
    yield put(DespesasActions.listRequest(params));
  } catch (err) {
    yield put(DespesaActions.uploadError());
    toastr.error("Ops", "Não foi possível realizar o upload");
  }
}

export function* deleteDespesaImage({ payload }) {
  const organization = yield select(getOrganization);
  const despesa = yield select(getDespesa);

  const { idImage } = payload;

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    yield call(api.delete, `despesas/${despesa._id}/imagens/${idImage}`, headerParams);
    
    const despesaSerialized = {
      ...despesa,
      imagens: despesa.imagens.filter(imagem => imagem._id !== idImage)
    }

    yield put(DespesaActions.deleteImageSuccess(despesaSerialized));
  } catch(err) {
    yield put(DespesaActions.deleteImageError());
    toastr.error('Ops', 'Não foi possível remover a imagem vinculado a despesa');
  }
}

export default all([
  takeLatest(Types.SAVE_REQUEST, saveDespesa),
  takeLatest(Types.UPLOAD_REQUEST, upload),
  takeLatest(Types.DELETE_REQUEST, remove),
  takeLatest(Types.DELETE_IMAGE_REQUEST, deleteDespesaImage)
]);
