import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import FormularioCadastro from "components/FormularioCadastro";

import Skeleton from "@material-ui/lab/Skeleton";

import InputForm from "components/InputForm";
import AutocompleteForm from "components/AutocompleteForm";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

import { Creators as EmpresasActions } from "store/ducks/empresas";
import { Creators as DepartamentosActions } from "store/ducks/departamentos";
import { Creators as ConfigActions } from "store/ducks/configuracaoDashboardExterno";

export default function FormConfigExternalDashboard(params) {
  const dispatch = useDispatch();

  const { list: empresasList, loading: loadingEmpresas } = useSelector(
    (state) => state.empresas
  );

  const { list: departamentosList, loading: loadingDepartamentos } =
    useSelector((state) => state.departamentos);

  const { config = null } = params.location.state || {};

  const newRegister = config === null;

  const [status, setStatus] = useState(config?.status || false);

  const formRef = useRef();

  function handleCheckStatus() {
    setStatus(!status);
  }

  function handleSubmit(newValues) {
    if (!newRegister) newValues["_id"] = config._id;

    dispatch(ConfigActions.newRequest(newValues));
  }

  useEffect(() => {
    dispatch(EmpresasActions.listRequest());
    dispatch(DepartamentosActions.listRequest({ onlyOperational: true }));
  }, [dispatch]);

  return (
    <>
      <FormularioCadastro
        title={"Gerenciamento de dashboard externo"}
        formref={formRef}
        onSubmit={handleSubmit}
        canSave={true}
        initialData={{
          ...config,
        }}
      >
        {loadingEmpresas ? (
          <Skeleton width="100%" height={50} />
        ) : (
          <AutocompleteForm
            label="Empresa"
            name="empresa"
            options={empresasList}
            transformOnSelect={(option) => option}
            getOptionLabel={(option) => option.razaoSocial}
          />
        )}

        {loadingDepartamentos ? (
          <Skeleton width="100%" height={50} />
        ) : (
          <AutocompleteForm
            label="Departamento"
            name="departamento"
            options={departamentosList}
            transformOnSelect={(option) => option}
            getOptionLabel={(option) => option.nome}
          />
        )}

        <InputForm required label="Apelido" name="shortname" type="text" />

        <AutocompleteForm
          required
          label="Tipo"
          name="type"
          options={["Metabase", "Power BI", "Link externo"]}
          transformOnSelect={(option) => option}
          getOptionLabel={(option) => option}
        />

        <InputForm required label="URL" name="url" type="text" />

        <InputForm required label="Secret key" name="secret_key" type="text" />

        <InputForm label="Código Metabase" name="codigo" type="number" />

        <FormControlLabel
          control={
            <Switch
              name="status"
              onChange={handleCheckStatus}
              color="primary"
              defaultChecked={status || false}
            />
          }
          label="Ativo"
        />
      </FormularioCadastro>
    </>
  );
}
