import styled from "styled-components/macro";
import {
  Dialog,
  DialogTitle as MaterialDialogTitle,
  DialogContentText as MaterialDialogContentText,
  Button as MaterialButton,
} from "@material-ui/core";

export const Modal = styled(Dialog)`
  .MuiDialog-paperWidthSm {
    min-width: 1024px;
    height: 800px;
  }
`;

export const ContainerMessage = styled.div`
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-weight: 500;

  svg {
    font-size: 75px;
    margin-bottom: 30px;
  }
`;

export const Button = styled(MaterialButton)`
  width: 30%;

  &:not(:last-of-type) {
    margin-right: 8px;
  }
`;

export const ButtonClose = styled.div`
  position: absolute;
  display: flex;
  top: 16px;
  right: 16px;
  background-color: transparent;
  outline: none;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #00000022;
  }
`;

export const DialogTitle = styled(MaterialDialogTitle)`
  width: 100%;
  text-align: center;
`;

export const DialogContentText = styled(MaterialDialogContentText)`
  padding: 32px;
`;

export const Chunk = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 16px 0;
  padding: 32px 16px;
  border-radius: 10px;
  border: 1px solid #444444;
  box-shadow: 2px 0 4px #00000027;

  & h1 {
    margin-bottom: 32px;
  }
`;

export const GroupButton = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 32px;
`;
