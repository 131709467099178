
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";

import { IconButton, Tooltip, Typography, makeStyles } from "@material-ui/core";
import { ArrowUpIcon, ArrowDownIcon } from "components/Icons";

const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
        color: theme.palette.common.black,
    },
    tooltip: {
        backgroundColor: theme.palette.common.black,
        fontSize: 12,
    },
    separador: {
        padding: 8,
        background: theme.palette.primary.main,
        borderRadius: 8,
        marginBottom: 15,
        color: "white",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    content: {
        transition: "max-height 1s ease-in-out",
        overflow: "hidden",
    },
}));

function BootstrapTooltip(props) {
    return <Tooltip arrow {...props} />;
}

export const Acordeon = ({ titleOpen, titleClose, index, hidden, children, defaultOpen = false}) => {

    const [open, setOpen] = useState(defaultOpen);
    const classes = useStylesBootstrap();
    const handleOpen = () => setOpen(!open);

    return (
        <Row key={index} hidden={hidden}>
            <Col>
                <Typography
                    variant="body1"
                    className={classes.separador}
                    gutterBottom
                    key={index}
                    style={{ paddingLeft: 14 }}
                >
                    {open ? titleClose : titleOpen}
                    <>
                        <BootstrapTooltip
                            placement="top"
                            title={open
                                ? "Recolher"
                                : "Expandir"
                            }
                        >
                            <IconButton
                                size="small"
                                color="inherit"
                                component="span"
                                onClick={handleOpen}
                            >
                                {open
                                    ? <ArrowUpIcon />
                                    : <ArrowDownIcon />
                                }
                            </IconButton>
                        </BootstrapTooltip>
                    </>
                </Typography>

                <div className={classes.content} style={{ maxHeight: open ? "5000px" : "0" }}>
                    <Row key={'conteudo'} hidden={!open}>
                        <Col md={12}>{children}</Col>
                    </Row>
                </div>
            </Col>
        </Row >
    )
}
