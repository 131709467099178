import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

import SingleFile from './SingleFile';

import {
    Container,
    DropZone,
    FileList,
} from './styles/FileDropUploader';

function FileDropUploader({
    imagens, setImagens, 
    loading, onUpload, onClose, maxFiles=0, 
    defaultName='', names=[], ...props
  }) {

    const onDrop = useCallback(async acceptedFiles => {
        if (!loading) {
        const validFiles = acceptedFiles.filter(file => file.type === 'application/pdf' || file.type.indexOf('image') !== -1);
        setImagens(f => ([
            ...f,
            ...validFiles.map(file => ({ file, nome: defaultName }))
        ]))
        }
    }, [loading, defaultName]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, maxFiles });

    return (
        <Container>
          <FileList>
            {imagens.map((file) =>     
                <SingleFile
                    key={`${file.file.name} + ${file.file.lastModified}`}
                    file={file.file}
                    name={file.file.name} /> )}
          </FileList>
                  
          <DropZone {...getRootProps()}>
              <input {...getInputProps()} accept='application/pdf,image/*' />
                  {isDragActive ?
                      <span>Arraste o(s) arquivo(s) aqui...</span> :
                      <span>Arraste e solte o(s) arquivo(s) aqui, ou clique aqui e selecione o(s) arquivo(s)</span>}
          </DropZone>
        </Container>
    );
}

export default FileDropUploader;
