import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from '@unform/web';
import { Row, Col } from 'react-bootstrap';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import Typography from '@material-ui/core/Typography';

import InputForm from 'components/InputForm';
import AutocompleteForm from 'components/AutocompleteForm';
import IndexerFormFieldFactory from 'components/FormFieldIndexer/IndexerFormFieldFactory';

import { valida_cnpj, valida_cpf } from 'services/validadorCpfCNPJ';
import { LabelSwapper } from '../../../../../../components/LabelSwapper';
import { parseISO, format } from 'date-fns';

import { Creators as EmpresasActions } from 'store/ducks/empresas';
import { Creators as DepartamentosActions } from 'store/ducks/departamentos';
import { Creators as TiposDocumentoActions } from 'store/ducks/tiposdocumento';
import { Creators as DocumentosActions } from 'store/ducks/documentos';
import { Creators as TipoImagensActions } from 'store/ducks/tipoimagem';
import { Creators as ComarcasActions } from 'store/ducks/comarca';
import { Creators as TribunalActions } from 'store/ducks/tribunal';
import { Creators as EsferaActions } from 'store/ducks/esfera';
import { Creators as InstanciaActions } from 'store/ducks/instancia';
import { Creators as AcaoActions } from 'store/ducks/acao';
import { reorderIndexadores } from 'utils/utils';

function FormIndexadores({ documento }) {
  const dispatch = useDispatch();
  const [currentMetadata, setCurrentMetadata] = useState(null);
  const [indexadores, setIndexadores] = useState(reorderIndexadores(documento?.indexadores) || []);
  const [open, setOpen] = useState({});

  const checkReadOnly = true;



  let { openIndexadoresEdit, loadingSave } = useSelector(
    (state) => state.documentos
  );

  function handleClose() {
    dispatch(DocumentosActions.closeEdit());
  }

  const {
    access: { empresas, relacionamentos },
  } = useSelector((state) => state.permissoes);

  const { list: comarcaList, loading: loadingComarcas } = useSelector(
    (state) => state.comarca
  );

  const { list: listInstancia, loading: loadingInstancia } = useSelector(
    (state) => state.instancia
  );

  const [uf, setUF] = useState(null);

  const separadores = useMemo(
    () =>
      documento.indexadores
        ? documento.indexadores.reduce(
          (acc, indexador) =>
            indexador.separador && !acc.includes(indexador.separador)
              ? [indexador.separador, ...acc]
              : acc,
          ['Outros indexadores']
        )
        : null,
    [documento.indexadores]
  );

  useEffect(() => {
    if (!uf) {
      return;
    }
    dispatch(
      ComarcasActions.listRequest({
        page: 1,
        limit: Number.MAX_SAFE_INTEGER,
        uf,
      })
    );
  }, [dispatch, uf]);

  useEffect(() => {
    setUF(documento.UF);
  }, [documento.UF]);

  useEffect(() => {
    // dispatch(EmpresasActions.listRequest());
    // dispatch(TiposDocumentoActions.listByEmpresaRequest(empresas));
    // dispatch(DepartamentosActions.listRequest());
    // dispatch(
    //   TipoImagensActions.listRequest({
    //     limit: Number.MAX_SAFE_INTEGER,
    //     page: 1,
    //     sort: 'nome',
    //   })
    // );
    dispatch(
      InstanciaActions.listRequest({ page: 1, limit: Number.MAX_SAFE_INTEGER })
    );
  }, [dispatch, empresas, relacionamentos]);

  function checkIfFieldHaveValidValue(fieldType, indexer) {
    if (!indexer) {
      return false;
    }
    if (indexer.tipo === fieldType && indexer.valor && indexer.valor !== '') {
      return true;
    }
    return false;
  }

  function checkIfValueIsInvalid(fieldType, indexer, validator) {
    return (
      checkIfFieldHaveValidValue(fieldType, indexer) &&
      !validator(indexer.valor)
    );
  }

  function checkIfItsAInvalidCPF(indexer) {
    return checkIfValueIsInvalid('cpf', indexer, valida_cpf);
  }

  function checkIfItsAInvalidCNPJ(indexer) {
    return checkIfValueIsInvalid('cnpj', indexer, valida_cnpj);
  }

  function hasIndexadorError(indexador) {
    if (indexador.obrigatorio && !indexador.valor) return true;

    if (checkIfItsAInvalidCPF(indexador) || checkIfItsAInvalidCNPJ(indexador)) {
      return true;
    }
    return false;
  }

  function hasIndexadorTextError(indexador) {
    if (indexador.obrigatorio && !indexador.valor) return 'Campo obrigatório';

    if (checkIfItsAInvalidCPF(indexador)) {
      return 'CPF inválido!';
    }

    if (checkIfItsAInvalidCNPJ(indexador)) {
      return 'CNPJ inválido!';
    }

    return '';
  }

  function handleChangeIndexador({ target }, nome) {
    const { name, value } = target;

    const separadoresMap = {};
    indexadores.forEach((indexador) => {
      const separador = indexador.separador || "Outros indexadores";

      if (!separadoresMap[separador]) {
        separadoresMap[separador] = [];
      }

      separadoresMap[separador].push(indexador);
    });

    Object.keys(separadoresMap).forEach((separador) => {
      separadoresMap[separador].sort((a, b) => a.order - b.order);
    });

    const indexadoresOrdenados = Object.values(separadoresMap).flat();


    const newIndexadores = indexadoresOrdenados.map((indexador) => ({
      ...indexador,
      valor: name === indexador.nome ? value : indexador.valor,
    }));

    setIndexadores(newIndexadores);
  }

  const handleSubmit = (form) => {
    const properties = {
      comarca: form.comarca,
      tribunal: form.tribunal,
      instancia: form.instancia,
      esfera: form.esfera,
      acao: form.acao,
      assunto: form.assunto,
      causaRaiz: form.causaRaiz,
      resumo: form.resumo,
      analiseInterna: form.analiseInterna,
      conclusao: form.conclusao,
      provisao: form.provisao,
      pedidos: form.pedidos,
      centroDeCusto: form.centroDeCusto,
      pasta: form.pasta,
      denominacao: form.denominacao,
      UF: form.UF,
      valorDaCausa: form.valorDaCausa
        ? form.valorDaCausa.toString().replace(',', '').replace(/\./g, '')
        : null,
      posicaoParte: form.posicaoParte,
      indexadores,
    };

    const indexadoresBody = {
      id: documento._id,
      properties,
      reloadDoc: true,
    };

    dispatch(DocumentosActions.updateDocIndexRequest(indexadoresBody));
  };

  return (
    <Dialog
      open={openIndexadoresEdit}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>
        {!!documento.status ? 'Editar dados' : 'Visualizar dados'}
      </DialogTitle>
      <DialogContent>
        <Form onSubmit={handleSubmit} initialData={documento}>
          <Row>
            <Col md={4}>
              <InputForm
                disabled
                name="numeroCNJ"
                label={
                  <LabelSwapper
                    text={'Nº do cliente'}
                    parent={'FormIndexadores'}
                    name={'numeroCNJ'}
                  />
                }
              />
            </Col>
            <Col md={4}>
              {!loadingInstancia && (
                <AutocompleteForm
                  options={listInstancia}
                  name="instancia"
                  label="Instancia"
                  value={documento.instancia}
                  getOptionLabel={(option) => option.nome}
                  transformOnSelect={(option) => (option ? option._id : option)}
                />
              )}
              {!!loadingInstancia && <Skeleton width="100%" height={50} />}
            </Col>
            <Col md={4}>
              <InputForm
                name="dataVencimento"
                label="Data do vencimento"
                disabled={true}
                value={
                  documento.dataVencimento
                    ? format(parseISO(documento.dataVencimento), 'yyyy-MM-dd')
                    : ''
                }
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <InputForm
                disabled
                name="resumo"
                label="Resumo"
                multiline
                minRows={8}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <InputForm
                name="analiseInterna"
                label="Analise"
                multiline
                minRows={8}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <InputForm
                name="conclusao"
                label="Conclusão"
                multiline
                minRows={8}
              />
            </Col>
          </Row>
          {separadores &&
            separadores
              .filter((separador) =>
                documento.indexadores.some(
                  (indexador) =>
                    indexador.separador === separador ||
                    (!indexador.separador && separador === 'Outros indexadores')
                )
              )
              .map((separador) => (
                <Row key={separador}>
                  {indexadores
                    .filter(
                      (indexador) =>
                        (indexador.separador === separador &&
                          indexador.tipo !== 'tabela' &&
                          (indexador.invisivel === false ||
                            indexador.invisivel === undefined)) ||
                        (!indexador.separador &&
                          separador === 'Outros indexadores')
                    )
                    .map((indexador, index) =>
                      index == 0 ? (
                        <>
                          <Col md={12}>
                            <Typography
                              className="separador"
                              variant="body1"
                              gutterBottom
                            >
                              <strong>{separador}</strong>
                            </Typography>
                          </Col>
                          <Col md={12}>
                            <Row key={indexador.nome}>
                              <Col md={12}>
                                <IndexerFormFieldFactory
                                  genericParams={{
                                    indexador,
                                    checkReadOnly,
                                    hasIndexadorError,
                                    hasIndexadorTextError,
                                    handleChangeIndexador,
                                  }}
                                />
                              </Col>
                            </Row>
                          </Col>
                        </>
                      ) : (
                        <Col md={12}>
                          <Row key={indexador.nome}>
                            <Col md={12}>
                              <IndexerFormFieldFactory
                                genericParams={{
                                  indexador,
                                  checkReadOnly,
                                  hasIndexadorError,
                                  hasIndexadorTextError,
                                  handleChangeIndexador,
                                }}
                              />
                            </Col>
                          </Row>
                        </Col>
                      )
                    )}
                </Row>
              ))}
          <DialogActions>
            {!!documento.status ? (
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={!!loadingSave}
              >
                {!!loadingSave && (
                  <>
                    <CircularProgress color="primary" size={20} />
                    &nbsp;
                  </>
                )}
                Salvar
              </Button>
            ) : (
              <></>
            )}
            <Button
              variant="contained"
              color="secondary"
              onClick={handleClose}
              disabled={!!loadingSave}
            >
              {!documento.status ? 'Voltar' : 'Cancelar'}
            </Button>
          </DialogActions>
        </Form>
      </DialogContent>
    </Dialog>
  );
}

export default FormIndexadores;
