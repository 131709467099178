export const Types = {
  LIST_REQUEST: "fila/LIST_REQUEST",
  LIST_SUCCESS: "fila/LIST_SUCCESS",
  LIST_FLUXO_REQUEST: "fluxo/LIST_REQUEST",
  LIST_FLUXO_SUCCESS: "fluxo/LIST_SUCCESS",
  REMOVE_REQUEST: "fila/REMOVE_REQUEST",
  REMOVE_SUCCESS: "fila/REMOVE_SUCCESS",
  NEW_REQUEST: "fila/NEW_REQUEST",
  NEW_SUCCESS: "fila/NEW_SUCCESS",
  EDIT_REQUEST: "fila/EDIT_REQUEST",
  EDIT_SUCCESS: "fila/EDIT_SUCCESS",
  GET_REQUEST: "fila/GET_REQUEST",
  GET_SUCCESS: "fila/GET_SUCCESS",
  SET_FILA_ACTIVE: "fila/SET_FILA_ACTIVE",
  SET_FILA_COLOR: "fila/SET_FILA_COLOR",
  SLA_QTD_REQUEST: "fila/SLA_QTD_REQUEST",
  SLA_QTD_SUCCESS: "fila/SLA_QTD_SUCCESS",
};

const INITIAL_STATE = {
  list: [],
  loading: false,
  color: "",
  active: "",
  page: 1,
  limit: 10,
  total: 0,
  sort: "descricao",
  slaqtd: {
    loading: false,
    data: [],
  },
};

export default function filas(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.LIST_REQUEST:
      return { ...state, loading: true };
    case Types.LIST_SUCCESS:
      return { ...state, loading: false, ...action.payload };
    case Types.LIST_FLUXO_REQUEST:
      return { ...state, loading: true };
    case Types.LIST_FLUXO_SUCCESS:
      return { ...state, loading: false, ...action.payload };
    case Types.REMOVE_REQUEST:
      return { ...state, loading: true };

    case Types.REMOVE_SUCCESS:
      return { ...state, loading: false };

    case Types.NEW_REQUEST:
      return { ...state, loading: true };

    case Types.NEW_SUCCESS:
      return { ...state, loading: false };

    case Types.EDIT_REQUEST:
      return { ...state };

    case Types.EDIT_SUCCESS:
      return { ...state, loading: false };

    case Types.GET_REQUEST:
      return { ...state, loading: true };

    case Types.GET_SUCCESS:
      return { ...state, loading: false, servsgerais: action.payload };

    case Types.SET_FILA_ACTIVE:
      return { ...state, active: action.payload };

    case Types.SET_FILA_COLOR:
      return { ...state, color: action.payload };

    case Types.SLA_QTD_REQUEST:
      return { ...state, slaqtd: { loading: true, data: [] } };

    case Types.SLA_QTD_SUCCESS:
      return { ...state, slaqtd: { loading: false, data: action.payload } };

    default:
      return state;
  }
}

export const Creators = {
  listRequest: (empresas) => ({
    type: Types.LIST_REQUEST,
    payload: empresas,
  }),
  listSuccess: (configesteira) => ({
    type: Types.LIST_SUCCESS,
    payload: configesteira,
  }),
  listFluxoSuccess: (configesteira) => ({
    type: Types.LIST_FLUXO_SUCCESS,
    payload: configesteira,
  }),
  listFluxoRequest: (empresas) => ({
    type: Types.LIST_FLUXO_REQUEST,
    payload: empresas,
  }),
  removeRequest: (id) => ({
    type: Types.REMOVE_REQUEST,
    payload: id,
  }),

  removeSuccess: () => ({
    type: Types.REMOVE_SUCCESS,
    payload: [],
  }),
  createRequest: (configesteira) => ({
    type: Types.NEW_REQUEST,
    payload: configesteira,
  }),

  createSuccess: () => ({
    type: Types.NEW_SUCCESS,
    payload: {},
  }),
  editRequest: (configesteira) => ({
    type: Types.EDIT_REQUEST,
    payload: configesteira,
  }),

  editSuccess: () => ({
    type: Types.EDIT_SUCCESS,
    payload: {},
  }),
  getRequest: (id) => ({
    type: Types.GET_REQUEST,
    payload: id,
  }),

  getSuccess: (configesteira) => ({
    type: Types.GET_SUCCESS,
    payload: configesteira,
  }),

  setFilaActive: (fila) => ({
    type: Types.SET_FILA_ACTIVE,
    payload: fila,
  }),

  setFilaColor: (color) => ({
    type: Types.SET_FILA_COLOR,
    payload: color,
  }),

  slaQtdRequest: (etapas) => ({
    type: Types.SLA_QTD_REQUEST,
    payload: etapas,
  }),
  slaQtdSuccess: (data) => ({
    type: Types.SLA_QTD_SUCCESS,
    payload: data,
  }),
};
