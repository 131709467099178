import api from "services/api";
import { getToken } from "services/auth";
import { call, select, put, all, takeLatest } from "redux-saga/effects";
import { toastr } from "react-redux-toastr";
import { push } from "connected-react-router";

import { Creators as RpaActions, Types } from "../ducks/rpa";

export const getOrganization = (state) => state.usuarios.auth.organization;
export const getUserId = (state) => state.usuarios.auth.id;

export function* listRPA({ payload }) {
  try {
    const organization = yield select(getOrganization);

    const { page, limit } = payload;

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
      params: { page, limit },
    };

    const response = yield call(api.get, `rpa`, headerParams);

    yield put(RpaActions.listSuccess(response.data || []));
  } catch (error) {
    toastr.error("Ops", "Não foi possível listar os RPAs.");
  }
}

export function* editRPA({ payload }) {
  try {
    const organization = yield select(getOrganization);

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const response = yield call(api.post, `rpa`, payload, headerParams);

    yield put(RpaActions.editSuccess(response.data || []));

    yield put(push(`/rpa`));
  } catch (error) {
    toastr.error("Ops", "Não foi possível criar ou editar o RPA.");
  }
}

export default all([takeLatest(Types.LIST_REQUEST, listRPA)]);
