import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Col, Row } from 'react-bootstrap';

import FormularioCadastro from "components/FormularioCadastro";

import Skeleton from "@material-ui/lab/Skeleton";

import InputForm from "components/InputForm";
import AutocompleteForm from "components/AutocompleteForm";

import { Creators as OcorrenciasActions } from "store/ducks/ocorrencias";
import OcorrenciasSchema from "validators/OcorrenciasSchema";
import { ValidationError } from "yup";

import {
  TYPES_LIST,
  SISTEM_LIST,
  CLASSIFICATION_LIST,
  PROCESS_LINE_LIST,
  STATUS_LIST,
  loadingTipo,
  loadingSistema,
  loadingClassificao,
  loadingEsteira,
  normalizeSchema,
} from '../index';

export default function FormOcorrencia(params) {
  const dispatch = useDispatch();

  const formRef = useRef(null);

  const [data] = new Date().toISOString().split('T')
  // const [_, hora] = new Date().toLocaleString('pt-BR').split(', ')

  const INITIAL_DATA = {
    id: '',
    dataStarts: data,
    timeStarts: null,
    dataEnds: null,
    timeEnds: null,
    tipo: TYPES_LIST[0],
    sistema: SISTEM_LIST[0],
    classificacao: CLASSIFICATION_LIST[0],
    esteira: PROCESS_LINE_LIST[0],
    observacao: '',
    chamado: '',
    status: STATUS_LIST[0],
  };

  const [timeStarts, setTimeStarts] = useState(INITIAL_DATA.dataInicio);
  const [timeEnds, setTimeEnds] = useState(INITIAL_DATA.dataFinal);

  async function handleSubmit(newValues) {

    const { dataStarts, timeStarts, dataEnds, timeEnds } = newValues;

    delete newValues.dataStarts;
    delete newValues.timeStarts;
    delete newValues.dataEnds;
    delete newValues.timeEnds;

    const dataInicio = new Date(`${dataStarts}T${timeStarts || '00:00'}:00`)
    let dataFinal = ""
    if (dataEnds) dataFinal = new Date(`${dataEnds}T${timeEnds || '00:00'}:00`)

    const normalizedData = normalizeSchema(newValues)
    const body = { ...normalizedData, tipo: newValues.tipo.value, dataInicio, dataFinal};

    try {
      await OcorrenciasSchema.validate(body, {
        abortEarly: false,
      });

      dispatch(OcorrenciasActions.createOcorrenciasRequest(body));

    } catch (err) {
      const validationErrors = {};
      if (err instanceof ValidationError) {
        err.inner.forEach((error) => {
          const errorPath = error.path.replace('dataInicio', 'dataStarts').replace('dataFinal', 'dataEnds');
          validationErrors[errorPath] = error.message;
        });

        formRef.current.setErrors(validationErrors);
      }
    }
  }

  return (
      <FormularioCadastro
        title={"Cadastro de ocorrência"}
        formRef={formRef}
        onSubmit={handleSubmit}
        canSave={true}
        initialData={INITIAL_DATA}
      >

        {!loadingTipo ? (
          <Skeleton width="100%" height={50} />
        ) : (
          <AutocompleteForm
            label="Tipo"
            name="tipo"
            options={TYPES_LIST}
            defaultValue={INITIAL_DATA.tipo}
            transformOnSelect={(option) => option}
            getOptionLabel={(option) => option.name}
          />
        )}

        {!loadingSistema ? (
          <Skeleton width="100%" height={50} />
        ) : (
          <AutocompleteForm
            label="Sistema"
            name="sistema"
            options={SISTEM_LIST}
            defaultValue={INITIAL_DATA.sistema}
            transformOnSelect={(option) => option}
            getOptionLabel={(option) => option.name}
          />
        )}

        {!loadingClassificao ? (
          <Skeleton width="100%" height={50} />
        ) : (
          <AutocompleteForm
            label="Classificação"
            name="classificacao"
            options={CLASSIFICATION_LIST}
            defaultValue={INITIAL_DATA.classificacao}
            transformOnSelect={(option) => option}
            getOptionLabel={(option) => option.name}
          />
        )}

        {!loadingEsteira ? (
          <Skeleton width="100%" height={50} />
        ) : (
          <AutocompleteForm
            label="Esteira de processamento"
            name="esteira"
            options={PROCESS_LINE_LIST}
            defaultValue={INITIAL_DATA.esteira}
            transformOnSelect={(option) => option}
            getOptionLabel={(option) => option.name}
          />
        )}

        <Row>
          <Col>
            <InputForm
              label="Data Inicial"
              name="dataStarts"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Col>
          <Col>
            <InputForm
              label="Hora"
              name="timeStarts"
              type="time"
              value={timeStarts}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={e => setTimeStarts(e.target.value)}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <InputForm
              label="Data Final"
              name="dataEnds"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Col>
          <Col>
            <InputForm
              label="Hora"
              name="timeEnds"
              type="time"
              value={timeEnds}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={e => setTimeEnds(e.target.value)}
            />
          </Col>
        </Row>

        <AutocompleteForm
          label="Status Ocorrência"
          name="status"
          options={STATUS_LIST}
          defaultValue={INITIAL_DATA.status}
          transformOnSelect={(option) => option}
          getOptionLabel={(option) => option.name}
        />

        <InputForm required label="Observação" name="observacao" type="text" />

        <InputForm
          label="Número do chamado"
          name="chamado"
          type="text"
          InputLabelProps={{
            shrink: true,
          }}
        />

      </FormularioCadastro>
  );
}
