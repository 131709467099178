import React from "react";

import {
    Button,
    Icon,
} from './styles/ActionButton';

function ActionButton({ icon, ...props }) {
  return (
    <Button
        {...props}
        variant="contained"
        color="primary"
        size="small">
        
        <Icon>{icon}</Icon>
    </Button>
  );
}

export default ActionButton;