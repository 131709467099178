import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Table,
    TableRow,
    TableCell,
    TableHead,
    TableBody,
    TableContainer,
    TableFooter,
    TablePagination,
} from "@material-ui/core";

import Button from "@material-ui/core/Button";

import { Col } from "react-bootstrap";

import { Link, useLocation } from "react-router-dom";
import CustomTableSortLabel from "components/CustomTableSortLabel";
import LoadingTable from "components/LoadingTable";
import StyledTableRow from "components/StyledTableRow";
import ButtonSquare from "components/ButtonSquare";
import ToolTipComponent from "components/Tooltip";
import Modal from "components/Modal";

import { Creators as TipoDepartamentoActions } from "store/ducks/tipodepartamento";

import "./styles.css";

const headers = [
    { sortField: "nome", label: "Nome" },
];

export default function ListTipoDepartamento() {

    const { pathname } = useLocation();
    const dispatch = useDispatch();

    const {
        list: tipodepartamento,
        loading,
        total,
        page = 1,
        limit = 10,
        sort = "nome",
    } = useSelector((state) => state.tipodepartamento);
    const {
        access: { servicos },
    } = useSelector((state) => state.permissoes);

    const [request, setRequest] = useState({ page, limit, sort });
    const [userActions, setUserActions] = useState([]);
    const [tipoDepartamento, setTipoDepartamento] = useState({});
    const [show, setShow] = useState(false);

    useEffect(() => {
        let find = servicos.find((item) => item.route === pathname);
        if (find !== undefined) {
            setUserActions(find);
        }
    }, [pathname, servicos]);

    useEffect(() => {
        dispatch(
            TipoDepartamentoActions.listRequest({
                ...request,
            })
        );
    }, [dispatch, request]);

    useEffect(() => {
        setShow(false);
    }, [loading]);

    function handleChangeRowsPerPage({ target }) {
        const { value: limit } = target;
        setRequest({ ...request, limit, page: 1 });
    }

    function handleChangePage(event, newPage) {
        setRequest({ ...request, page: newPage + 1 });
    }

    function handleSortChange(sort) {
        setRequest({ ...request, sort });
    }

    function handleDelete() {
        dispatch(TipoDepartamentoActions.removeRequest(tipoDepartamento._id));
    }

    function openDeleteConfirm(item) {
        setTipoDepartamento(item);
        setShow(true);
    }

    return (
        <>
            <TableContainer className="list-tipodepartamento-container">
                {(tipodepartamento && tipodepartamento.length > 0) || !!loading ? (
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                {headers.map(({ sortField, label }) => {
                                    return (
                                        <TableCell>
                                            <CustomTableSortLabel
                                                currentSort={request.sort}
                                                sortField={sortField}
                                                onClick={handleSortChange}
                                            >
                                                {label}
                                            </CustomTableSortLabel>
                                        </TableCell>
                                    )
                                })}
                                {userActions.actions &&
                                    userActions.actions.map(
                                        (userAct, index) =>
                                            userAct.action === "editar" && (
                                                <TableCell key={index} align="center">
                                                    Ações
                                                </TableCell>
                                            )
                                    )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!!loading && <LoadingTable columns={headers.length} />}
                            {!loading &&
                                tipodepartamento.map((tipoDepartamento) => (
                                    <StyledTableRow key={tipoDepartamento._id}>

                                        <TableCell>{tipoDepartamento.nome}</TableCell>

                                        {userActions.actions &&
                                            userActions.actions.map(
                                                (userAct, index) =>
                                                    userAct.action === "editar" && (
                                                        <TableCell align="center" key={index}>
                                                            <div className="flex-direction">

                                                                <ToolTipComponent
                                                                    title="Editar"
                                                                    placement="top"
                                                                >
                                                                    <Link
                                                                        to={{
                                                                            pathname:
                                                                                "/administracao/tipodepartamento/edit",
                                                                            state: { tipoDepartamento: tipoDepartamento },
                                                                        }}
                                                                        className="linkicon"
                                                                        id="tipodepartamento"
                                                                    >
                                                                        <ButtonSquare icon="edit" />
                                                                    </Link>
                                                                </ToolTipComponent>

                                                                <ToolTipComponent
                                                                    title="Remover"
                                                                    placement="top"
                                                                >
                                                                    <div>
                                                                        <ButtonSquare
                                                                            onClick={() => openDeleteConfirm(tipoDepartamento)}
                                                                            icon="delete"
                                                                        />
                                                                    </div>
                                                                </ToolTipComponent>

                                                            </div>
                                                        </TableCell>
                                                    )
                                            )}
                                    </StyledTableRow>
                                ))}
                        </TableBody>
                        {!loading && (
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        count={total}
                                        page={request.page - 1}
                                        rowsPerPage={request.limit}
                                        rowsPerPageOptions={[10, 25, 50, 100]}
                                        labelRowsPerPage="Linhas por página"
                                        labelDisplayedRows={({ from, to, count }) =>
                                            `${from}-${to} de ${count !== -1 ? count : `mais que ${to}`
                                            }`
                                        }
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        onPageChange={handleChangePage}
                                    />
                                </TableRow>
                            </TableFooter>
                        )}
                    </Table>
                ) : (
                    <div className="list-tipodepartamento-content">
                        <span className="list__none">
                            Não há tipos de departamento registrados.
                        </span>
                    </div>
                )}
            </TableContainer>
            <Modal
                isOpen={show}
                title="Remover tipo de departamento:"
                onClose={setShow}
                className="modal-padding"
            >
                <p>Deseja realmente remover o tipo de departamento {tipoDepartamento.nome}?</p>

                <Col md={12}>
                    <div className="button-container">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={(e) => handleDelete()}
                        >
                            Sim
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={(e) => {
                                setShow(false);
                            }}
                        >
                            Não
                        </Button>
                    </div>
                </Col>
            </Modal>
        </>
    );
}
