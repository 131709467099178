import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Icon from '@material-ui/core/Icon';
import _ from 'lodash';

import './styles.css';

import { Row, Col } from 'react-bootstrap';
import Content from 'components/Content';

export default function GridCadastro({
  route,
  searchRequest,
  titleButtonNovo = 'novo',
  title,
  children,
  component,
}) {
  const { pathname } = useLocation();

  const [searchField, setSearchField] = useState('');

  const {
    access: { servicos },
  } = useSelector((state) => state.permissoes);

  const [userActions, setUserActions] = useState([]);

  function handleChange({ target }) {
    const { value } = target;
    setSearchField(value);
    searchRequest(value);
  }

  useEffect(() => {
    let find = servicos.find((item) => item.route === pathname);
    if (find !== undefined) {
      setUserActions(find);
    }
  }, [pathname, servicos]);

  return (
    <Content>
      <Row>
        <Col md="12" className="painel">
          <div className="painel-content">
            <div className="painel-title text-left">
              <div className="painel-title-controls">
                <strong>{title}</strong>
                {searchRequest && (
                  <div>
                    <TextField
                      label="Pesquisar"
                      variant="outlined"
                      size="small"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Icon className="text-20" color="action">
                              search
                            </Icon>
                          </InputAdornment>
                        ),
                      }}
                      onChange={handleChange}
                      value={searchField}
                    />
                  </div>
                )}
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'space-around',
                }}
              >
                <div style={{ marginRight: '12px' }}>{component}</div>
                {/* {userActions.actions &&
                                    userActions.actions.map(
                                        (userAct, index) =>
                                            userAct.action === "criar" && ( */}
                <Button
                  // key={index}
                  component={Link}
                  to={route}
                  variant="contained"
                  color="primary"
                >
                  {titleButtonNovo}
                </Button>
                {/* )
                                    )} */}
              </div>
            </div>
            <div className="painel-body">{children}</div>
          </div>
        </Col>
      </Row>
    </Content>
  );
}
