import React, { useRef } from "react";
import { useDispatch } from "react-redux";
import { Creators as CategoriasActions } from "store/ducks/categorias";

import _ from 'lodash';

import GridCadastro from "components/GridCadastro";
import List from "./ListCategorias/index";

export default function Categoria() {

    const dispatch = useDispatch();
    const dispatchDebounce = useRef(_.debounce(dispatch, 500)).current;

    return (
        <GridCadastro
            title='Categorias'
            route={"/administracao/categorias/novo"}
            searchRequest={(value) => {
                dispatchDebounce(
                    CategoriasActions.listRequest({ page: 1, limit: 10, search: value })
                );
            }}
            titleButtonNovo="Nova Categoria"
        >
            <List/>
        </GridCadastro>
    )
}