export default {
  mixed: {
    default: 'Campo inválido',
    required: 'Campo obrigatório'
  },
  string: {
    required: 'Campo obrigatório'
  },
  boolean: {
    required: 'Campo obrigatório'
  },
  number: {
    positive: 'Deve ser um valor maior do que 0'
  },
  date: {
    default: 'Campo inválido',
    required: 'Campo obrigatório',
  }
}