/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import useForm from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import generator from 'generate-password';
import Utils from 'utils/utils';

import { Modal, Button, Form } from 'react-bootstrap';
import { Creators as UsuariosActions } from '../../store/ducks/usuarios';

import logoBranco from 'assets/img/logo.png';
import logoBrancoNV from 'assets/img/logo-nv.webp';

import './styles.css';
import { FaEye, FaEyeSlash } from 'components/Icons';

export default function Login() {
  const dispatch = useDispatch();

  const { register, handleSubmit, errors } = useForm();

  const [subdomain, setSubdomain] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const containsNSSmartSuite = process.env.REACT_APP_FRONT_URL.includes('nssmartsuite');

  const loading = useSelector(state => state.usuarios.loading);

  useEffect(() => {
    //checar se há algo no localstorage, se sim, apagar e fazer reload
    // if (localStorage.getItem('persist:root')) {
    //   localStorage.clear();
    //   window.location.reload();
    // }

    dispatch(UsuariosActions.clearAll({}));

    setSubdomain(Utils.getSubdomain());
  }, []);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // #region Forgot Password
  const body = {};

  const onSubmit = values => {
    localStorage.clear();
    body.username = values.email;
    body.senha = values.senha;
    body.useCaptcha = false;
    body.xkeyorg = !!subdomain ? subdomain : values.xkeyorg;
    dispatch(UsuariosActions.loginRequest(body));
  };

  const handleForgotPass = values => {
    const { usuario, ambiente } = values;

    let pass = generator.generate({
      length: 10,
      numbers: true,
    });

    let body = {
      usuario,
      ambiente,
      texto: pass,
      assunto: `${process.env.REACT_APP_TITLE} - Recuperação de Senha`,
      remetente: 'contact@newspace.com.br',
    };
    dispatch(UsuariosActions.forgotPasswordRequest(body));

    setTimeout(() => setShowModal(false), 5000);
  };
  // #endregion

  return (
    <div id="login">
      <div className="content">
        <div className="introduction">
          <div className="introduction-logo">
            {/* {containsNSSmartSuite ? (
              <img src={logoBranco} alt="Logo NewSpace" />
            ) : (
              <img src={logoBrancoNV} alt="Logo NV Security" />
            )} */}
          </div>
          <div className="introduction-text"></div>
        </div>
        <div className="login-content">
          {/* {containsNSSmartSuite ? (
            <img src={logoBranco} alt="Logo NewSpace" />
          ) : (
            <img src={logoBrancoNV} alt="Logo NV Security" />
          )} */}
          <div className="login-title">
            <h3>Olá!</h3>
            <h4>Seja bem vindo.</h4>
          </div>
          <div className="loginForm">
            <form onSubmit={handleSubmit(onSubmit)}>
              {!subdomain && (
                <input
                  type="text"
                  name="xkeyorg"
                  ref={register}
                  spellCheck="false"
                  placeholder="Empresa"
                  autoComplete="off"
                  defaultValue={process.env.REACT_APP_AMBIENTE}
                  required
                />
              )}
              <input
                type="text"
                name="email"
                ref={register}
                spellCheck="false"
                placeholder="Usuário"
                autoComplete="off"
                autoFocus
              />

              <div className="login-password">
                <input type={showPassword ? "text" : "password"} name="senha" ref={register} spellCheck="false" placeholder="Senha" />

                <button type="button" onClick={togglePasswordVisibility}>
                  {showPassword ? <FaEyeSlash
                    color="#ffffff"
                    size="1x" /> : <FaEye
                    color="#ffffff"
                    size="1x" />}
                </button>
              </div>

              <button disabled={loading}>{loading ? 'Carregando...' : 'Entrar'}</button>
            </form>
          </div>
          <div className="login-forgotpass">
            <span className="forgot__pass" onClick={() => setShowModal(true)}>
              Esqueceu sua senha?
            </span>
          </div>
        </div>
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Form onSubmit={handleSubmit(handleForgotPass)}>
          <Modal.Header>
            <Modal.Title>Esqueceu sua senha?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Empresa
            <Form.Control
              type="text"
              autoComplete="off"
              className="mb-2"
              ref={register({
                validate: value => value !== '' || 'Campo obrigatório!',
              })}
              name="ambiente"
            />
            Usuário
            <Form.Control
              autoComplete="off"
              type="text"
              className="mb-2"
              ref={register({
                validate: value => value !== '' || 'Campo obrigatório!',
              })}
              name="usuario"
            />
            <small>Um link para troca de sua senha será enviado ao e-mail cadastrado.</small>
            <small className="color">{errors.usuario && errors.usuario.message}</small>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" type="submit">
              Enviar
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
}
