import { call, put, select, all, takeLatest } from 'redux-saga/effects';
import { getToken } from 'services/auth';
import { toastr } from "react-redux-toastr";

import api from 'services/api';

import { Creators as ComentariosActions, Types } from 'store/ducks/comentarios';

export const getOrganization = (state) => state.usuarios.auth.organization;

export function* newComment({ payload }) {
  const organization = yield select(getOrganization);

  const { _id, data: body } = payload;

  if (body?.tipo != "Comentário")
    return true;
  
  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    if (body.file) {
      const responseUpload = yield call(
        api.post,
        's3/upload',
        { file: body.file },
        headerParams
      )
      body.filePath = responseUpload.data.filePath;
      delete body.file;
    }

    const { data } = yield call(
      api.post,
      `analise/${_id}/comentarios`,
      body,
      headerParams
    )

    yield put(ComentariosActions.setListComments(data))

  } catch(error) {
    toastr.error("Ops", "Não foi possível adicionar o comentário.");
  }
}

export function* newEvidencia({ payload }) {
  const organization = yield select(getOrganization);

  const { _id, data: body } = payload;

  if (body?.tipo == "Comentário")
    return true;

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const { data } = yield call(
      api.post,
      `analise/${_id}/evidencias`,
      body,
      headerParams
    )
    
    yield put(ComentariosActions.setListComments(data))

  } catch(error) {
    toastr.error("Ops", "Não foi possível adicionar a evidência.");
  }
}

export default all([
  takeLatest(Types.NEW_REQUEST, newComment),
  takeLatest(Types.NEW_REQUEST, newEvidencia),

])