export const Types = {
  LIST_REQUEST: 'onboarding/LIST_REQUEST',
  LIST_SUCCESS: 'onboarding/LIST_SUCCESS',
  GET_REQUEST: 'onboarding/GET_REQUEST',
  GET_SUCCESS: 'onboarding/GET_SUCCESS',
  REMOVE_REQUEST: 'onboarding/REMOVE_REQUEST',
  REMOVE_SUCCESS: 'onboarding/REMOVE_SUCCESS',
  NEW_REQUEST: 'onboarding/NEW_REQUEST',
  NEW_SUCCESS: 'onboarding/NEW_SUCCESS',
  EDIT_REQUEST: 'onboarding/EDIT_REQUEST',
  EDIT_SUCCESS: 'onboarding/EDIT_SUCCESS',
  GENERATECODE_REQUEST: 'onboarding/GENERATECODE_REQUEST',
  GENERATECODE_SUCCESS: 'onboarding/GENERATECODE_SUCCESS',
  FINISHONBOARDING_REQUEST: 'onboarding/FINISHONBOARDING_REQUEST',
  FINISHONBOARDING_SUCCESS: 'onboarding/FINISHONBOARDING_SUCCESS',
  VALIDATECODE_REQUEST: 'onboarding/VALIDATECODE_REQUEST',
  VALIDATECODE_SUCCESS: 'onboarding/VALIDATECODE_SUCCESS',
  VALIDATECODE_ERROR: 'onboarding/VALIDATECODE_ERROR',
  VALIDATECODE_PRIVATE_REQUEST: 'onboarding/VALIDATECODE_PRIVATE_REQUEST',
  VALIDATIONS_REQUEST: 'onboarding/VALIDATIONS_REQUEST',
  VALIDATIONS_SUCCESS: 'onboarding/VALIDATIONS_SUCCESS',
};

const INITIAL_STATE = {
  loading: false,
  list: [],
  page: 1,
  limit: 10,
  total: 0,
  sort: 'nome',
  form: {},
  validations: {
    loading: false,
    data: {},
  },
  defaultIndexadores: [],
  defaultImages: [],
  twofactor: {
    formCode: '',
    codigo_verificado: false,
  },
};

export default function items(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.LIST_REQUEST:
      return {
        ...state,
        loading: true,
        page: action.payload.page,
        limit: action.payload.limit,
        sort: action.payload.sort,
      };

    case Types.LIST_SUCCESS:
      return {
        ...state,
        list: action.payload.docs,
        pages: action.payload.pages,
        total: action.payload.total,
        loading: false,
      };

    case Types.GET_REQUEST:
      return { ...state, loading: true };

    case Types.GET_SUCCESS:
      return { ...state, loading: false, form: action.payload };

    case Types.NEW_REQUEST:
      return { ...state, loading: true };

    case Types.NEW_SUCCESS:
      return { ...state, loading: false };

    case Types.EDIT_REQUEST:
      return { ...state, loading: true };

    case Types.EDIT_SUCCESS:
      return { ...state, loading: false };

    case Types.FINISHONBOARDING_REQUEST:
      return { ...state, loading: true };

    case Types.FINISHONBOARDING_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case Types.GENERATECODE_REQUEST:
      return { ...state, loading: true };

    case Types.GENERATECODE_SUCCESS:
      return {
        ...state,
        loading: false,
        twofactor: {
          formCode: action.payload.formCode,
          codigo_verificado: false,
        },
      };

    case Types.VALIDATECODE_REQUEST:
      return { ...state, loading: true };

    case Types.VALIDATECODE_PRIVATE_REQUEST:
      return { ...state, loading: true };

    case Types.VALIDATECODE_ERROR:
      return { ...state, loading: false };

    case Types.VALIDATECODE_SUCCESS:
      return {
        ...state,
        loading: false,
        defaultIndexadores: action.payload.document?.indexadores || [],
        defaultImages: action.payload.document?.imagens || [],
        twofactor: {
          formCode: state.twofactor.formCode,
          codigo_verificado: action.payload.codigo_verificado,
        },
      };

    case Types.VALIDATIONS_REQUEST:
      return {
        ...state,
        validations: {
          loading: true,
          data: {},
        },
      };

    case Types.VALIDATIONS_SUCCESS:
      return {
        ...state,
        validations: {
          loading: false,
          data: action.payload.data,
        },
      };

    case Types.REMOVE_REQUEST:
      return { ...state, loading: true };

    case Types.REMOVE_SUCCESS:
      return { ...state, loading: false };

    default:
      return state;
  }
}

export const Creators = {
  listRequest: (data) => ({
    type: Types.LIST_REQUEST,
    payload: data,
  }),

  listSuccess: (data) => ({
    type: Types.LIST_SUCCESS,
    payload: data,
  }),

  getRequest: (id) => ({
    type: Types.GET_REQUEST,
    payload: id,
  }),

  getSuccess: (form) => ({
    type: Types.GET_SUCCESS,
    payload: form,
  }),

  removeRequest: (id) => ({
    type: Types.REMOVE_REQUEST,
    payload: id,
  }),

  removeSuccess: () => ({
    type: Types.REMOVE_SUCCESS,
    payload: [],
  }),

  createRequest: (onboarding) => ({
    type: Types.NEW_REQUEST,
    payload: onboarding,
  }),

  createSuccess: () => ({
    type: Types.NEW_SUCCESS,
    payload: {},
  }),

  editRequest: (onboarding) => ({
    type: Types.EDIT_REQUEST,
    payload: onboarding,
  }),

  editSuccess: () => ({
    type: Types.EDIT_SUCCESS,
    payload: {},
  }),

  generateCodeRequest: (onboarding) => ({
    type: Types.GENERATECODE_REQUEST,
    payload: onboarding,
  }),

  generateCodeSuccess: (payload) => ({
    type: Types.GENERATECODE_SUCCESS,
    payload,
  }),

  finishOnboardingRequest: (onboarding) => ({
    type: Types.FINISHONBOARDING_REQUEST,
    payload: onboarding,
  }),

  finishOnboardingSuccess: (payload) => ({
    type: Types.FINISHONBOARDING_SUCCESS,
    payload,
  }),

  validateCodeRequest: (onboarding) => ({
    type: Types.VALIDATECODE_REQUEST,
    payload: onboarding,
  }),

  validateCodePrivateRequest: (onboarding) => ({
    type: Types.VALIDATECODE_PRIVATE_REQUEST,
    payload: onboarding,
  }),

  validateCodeSuccess: (payload) => ({
    type: Types.VALIDATECODE_SUCCESS,
    payload,
  }),

  validateCodeError: () => ({
    type: Types.VALIDATECODE_ERROR,
    payload: {},
  }),

  validationsRequest: (payload) => ({
    type: Types.VALIDATIONS_REQUEST,
    payload,
  }),

  validationsSuccess: (payload) => ({
    type: Types.VALIDATIONS_SUCCESS,
    payload,
  }),
};
