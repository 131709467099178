import api from 'services/api';
import { getToken } from 'services/auth';
import { call, select, put, all, takeEvery } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';

import { Creators as AgendaActions, Types } from 'store/ducks/agenda';

export const getOrganization = state => state.usuarios.auth.organization;

export function* getAgendaData() {
  const organization = yield select(getOrganization);

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const { data } = yield call(api.get, 'agenda', headerParams);

    yield put(AgendaActions.fetchDataSuccess(data));

    } catch (error) {
    toastr.error('Ops', 'Não foi possível acessar sua Agenda.');
  }
}

export default all([
  takeEvery(Types.GET_DATA_REQUEST, getAgendaData),
])