import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles({
  root: {
    padding: "2px 4px",
    borderRadius: "4px",
    display: "inline",
    fontWeight: 600,
  },
  PROCEDENTE: {
    backgroundColor: "green",
    color: "#FFF",
  },
  IMPROCEDENTE: {
    backgroundColor: "red",
    color: "#FFF",
  },

  PARCIAL_PROCEDENTE: {
    backgroundColor: "orange",
    color: "#FFF",
  },

  EXTINTO_SEM_RESOLUCAO_MERITO: {
    backgroundColor: "purple",
    color: "#FFF",
  },

  EXTINTO_COM_RESOLUCAO_MERITO: {
    backgroundColor: "brown",
    color: "#FFF",
  },
  LEGADO: {
    backgroundColor: "blue",
    color: "#FFF",
  },
});

const MOTIVO_ENUM = {
  IMPROCEDENTE: "Improcedente",
  PROCEDENTE: "Procedente",
  PARCIAL_PROCEDENTE: "Parcial procedente",
  EXTINTO_SEM_RESOLUCAO_MERITO: "Extinto sem resolução do mérito",
  EXTINTO_COM_RESOLUCAO_MERITO: "Extinto com resolução do mérito",
  ADMINISTRATIVO_INTERNO: "Administrativo interno",
  LEGADO: 'Legado'
};

function Qualificacao({ children }) {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, classes[children])}>
      {MOTIVO_ENUM[children]}
    </div>
  );
}

export default Qualificacao;
