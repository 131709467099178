import React from 'react';
import TextField from '@material-ui/core/TextField';

import DynamicInputMask from 'components/DynamicInputMask';
import { Date, CPF, CNPJ } from 'components/Masks';

function DynamicTextField({ tipo, mascara, ...others}) {

  return (
    <>
      {tipo === 'data' &&
      <TextField
        {...others}
        InputProps={{
          ...others.InputProps,
          inputComponent: Date
        }}
      />}
      {tipo === 'cpf' &&
      <TextField
        {...others}
        InputProps={{
          ...others.InputProps,
          inputComponent: CPF
        }}
      />}
      {tipo === 'cnpj' &&
      <TextField
        {...others}
        InputProps={{
          ...others.InputProps,
          inputComponent: CNPJ
        }}
      />}
      {tipo !== 'combo' && tipo !== 'cpf' &&
      tipo !== 'cnpj' && tipo !== 'data' && 
      mascara !== '' && tipo !== 'tabela' && 
      <DynamicInputMask
        {...others}
        mask={mascara}
      />}
      {tipo !== 'data' &&
      tipo !== 'cpf' && 
      tipo !== 'cnpj' && 
      mascara === '' &&
      <TextField
        {...others}
        type={tipo === 'numero' ? 'number' : 'text'}
      />}
    </>
  );
}

export default DynamicTextField;