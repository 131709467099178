import React from "react";
import _ from 'lodash';

import GridCadastro from "components/GridCadastro";
import TableTipoDocumento from "./TableTipoDocumento";

export default function TipoDocumentos() {
  return (
    <GridCadastro
      title='Tipos de documento'
      route={"/configuracao/tiposdocumento/novo"}
      titleButtonNovo="Novo Tipo de Documento"
    >
      <TableTipoDocumento />
    </GridCadastro>
  )
}