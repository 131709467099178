import React, { useEffect, useState, useMemo, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import useForm from "react-hook-form";

import { Form } from "@unform/web";
import { Button, Row, Col, Form as FormBootstrap } from "react-bootstrap";

import CheckboxForm from "components/CheckboxForm";
import { BeatLoader } from "react-spinners";

import Separador from "components/Separador";

import Grid from "@material-ui/core/Grid";
import ButtonMui from "@material-ui/core/Button";
import BackButton from "../../../components/BackButton";
import AutocompleteForm from "components/AutocompleteForm";

import { Creators as CheckTipoActions } from "../../../store/ducks/checktipo";
// import { Creators as DepartamentosActions } from "../../../store/ducks/departamentos";
import { Creators as TiposDocumentoActions } from "../../../store/ducks/tiposdocumento";
import { Creators as TiposImagemActions } from "../../../store/ducks/tipoimagem";

import "./styles.css";
import { AddCircleIcon } from "components/Icons";
import FormRegra from "./FormRegra";
import InputForm from "components/InputForm";

export default function FormItem({ tituloSelect, IndexItem, initialData }) {
  const formRef = useRef();

  const [hash, setHash] = useState(initialData?.hash);
  const [showStatus, setShowStatus] = useState(
    initialData?.tipoCampo === "Combo" || false
  );
  const [statusList, setStatusList] = useState(initialData?.optionsCombo || []);
  const [textStatus, setTextStatus] = useState("");
  const [tipoItem, setTipoItem] = useState(initialData?.tipoItem || null);
  const [regras, setRegras] = useState(initialData?.regras || []);

  // const {
  //   access: { departamentos },
  // } = useSelector((state) => state.permissoes);
  const empresaSetada = useSelector((state) => state.checkTipos.empresaSetada);
  // const { listByEmpresa } = useSelector((state) => state.departamentos);
  const tiposdocumento = useSelector(
    (state) => state.tipoDocumentos.listByEmpresas
  );
  const { loading } = useSelector((state) => state.tipoDocumentos);
  const tiposimagem = useSelector((state) => state.tipoImagem.list);
  const loadingTipoImagem = useSelector((state) => state.tipoImagem.loading);

  const [tipoImagemSelecionado, setTipoImagemSelecionado] = useState(
    initialData?.tipoImagem || null
  );
  const [tipoImagemSwitch, setTipoImagemSwitch] = useState(
    initialData?.tipoImagemSwitch === true ? "on" : "off"
  );

  const [tipoDocumento, setTipoDocumento] = useState(
    initialData?.tipodocumento?._id || null
  );

  const checksSelect = useSelector((state) => state.checkTipos.checksSelect);

  const checkTitulos = checksSelect.titulos;

  const itens = checkTitulos[IndexItem].itens;

  const hideRecorrencia =
    Array.isArray(tipoImagemSelecionado) && tipoImagemSelecionado.length > 1;

  const { register } = useForm();

  const dispatch = useDispatch();

  const generateObjectId = (
    m = Math,
    d = Date,
    h = 16,
    s = (s) => m.floor(s).toString(h)
  ) => s(d.now() / 1000) + " ".repeat(h).replace(/./g, () => s(m.random() * h));

  const indexadores = useMemo(() => {
    if (
      tiposdocumento.some(
        (tipoDocumentoItem) => tipoDocumentoItem._id === tipoDocumento
      )
    ) {
      return tiposdocumento.find(
        (tipoDocumentoItem) =>
          tipoDocumentoItem._id?.toString() === tipoDocumento.toString()
      )?.indexadores;
    }
    return [];
  }, [tiposdocumento, tipoDocumento]);

  const indexadoresTipoImagem = useMemo(() => {
    if (!tipoImagemSelecionado || !tipoImagemSelecionado[0]) return [];

    const indexadoresTipoImagem = tiposimagem.find(
      (item) => item._id?.toString() === tipoImagemSelecionado[0]._id.toString()
    );

    console.log(">> ", indexadoresTipoImagem?.indexadores || []);
    return indexadoresTipoImagem?.indexadores || [];
  }, [tiposimagem, tipoImagemSelecionado]);

  const onReset = () => {
    setTipoDocumento(null);
    setTipoImagemSwitch("off");
    setTipoImagemSelecionado("");
    setRegras([]);
  };

  const onSubmit = (values, { reset }) => {
    let itensNew = {
      regras: values.regras,
      nome: values.nome,
      ordem: values.ordem,
      tipoItem: values.tipoItem,
      periodo: values.periodo,
      tipoCampo: values.tipoCampo,
      tipoImagem: tipoImagemSelecionado,
      optionsCombo: values.optionsCombo,
      indexadorInicio: values.indexadorInicio,
      indexadorFim: values.indexadorFim,
      indexadorImagem: values.indexadorImagem,
      tipodocumento: values.tipodocumento,
      tipoImagemSwitch: values.incluirTipoImagem,
      recorrencia: values.recorrencia,
      instrucoes: values.instrucoes,
      hash: hash || generateObjectId(),
    };

    let currentItens = [];

    if (itens) {
      currentItens = [
        ...itens.filter((x) => x.hash !== itensNew.hash),
        itensNew,
      ];
    } else {
      currentItens = [itensNew];
    }

    const tituloAtualizado = {
      descricao: checkTitulos[IndexItem].descricao,
      ordem: checkTitulos[IndexItem].ordem,
      hash: checkTitulos[IndexItem].hash,
      itens: currentItens,
    };

    checkTitulos[IndexItem] = tituloAtualizado;

    let tituloCompleto = {
      _id: checksSelect._id,
      titulos: checkTitulos,
    };

    dispatch(CheckTipoActions.createItemRequest(tituloCompleto));

    setStatusList([]);

    onReset();
    reset();
  };

  const handleShowStatus = (e, newValue) => {
    if (newValue === "Combo") {
      setShowStatus(true);
    } else {
      setShowStatus(false);
    }
  };

  useEffect(() => {
    if (empresaSetada) {
      dispatch(TiposDocumentoActions.listByEmpresaRequest(empresaSetada));
    }
  }, [empresaSetada, dispatch]);

  useEffect(() => {
    if (tipoDocumento) {
      dispatch(TiposImagemActions.listByTipoDocumentoRequest(tipoDocumento));
    }
  }, [tipoDocumento, dispatch]);

  const handleLoadTiposImagem = (e, newValue) => {
    setTipoDocumento(newValue?._id);
    if (newValue?._id) {
      dispatch(TiposImagemActions.listByTipoDocumentoRequest(newValue._id));
    }
  };

  const handleLoadTipoImagemSelecionado = (e, value) => {
    setTipoImagemSelecionado(value);
  };

  const handleCheckTipoImagem = (e, newValue) => {
    if (newValue) {
      setTipoImagemSwitch("on");
    } else {
      setTipoImagemSwitch("off");
      setTipoImagemSelecionado(newValue);
    }
  };

  useEffect(() => {}, [checksSelect]);

  const novoStatus = () => {
    const novo = [...statusList, textStatus];
    setStatusList(novo);
  };

  const novaRegra = () => {
    const novo = [...regras, null];
    setRegras(novo);
  };

  const handleTextStatus = (e) => {
    setTextStatus(e.target.value);

    if (e.keyCode === 13) {
      novoStatus();
    }
  };

  const handleRemove = (index) => {
    setRegras([...regras.filter((regra, index2) => index2 !== index)]);
  };

  // useEffect(() => {
  //   dispatch(
  //     DepartamentosActions.listByEmpresaRequest({
  //       empresa: empresaSetada,
  //       departamentos,
  //     })
  //   );
  // }, [departamentos, dispatch, empresaSetada]);

  return (
    <Form onSubmit={onSubmit} ref={formRef} initialData={initialData}>
      <Row>
        <Col md="5" sm="12">
          <FormBootstrap.Label>Nome:</FormBootstrap.Label>
          <InputForm name="nome" />
        </Col>

        <Col md="3" sm="12">
          <FormBootstrap.Label>Tipo item:</FormBootstrap.Label>
          <AutocompleteForm
            name="tipoItem"
            options={["Qualitativo", "Quantitativo"]}
            transformOnSelect={(option) => option}
            getOptionLabel={(option) =>
              option?.label ? option?.label : option
            }
            getOptionSelected={(option, value) => value === option}
            onChange={(e, v) => setTipoItem(v)}
          />
        </Col>

        <Col md="2" sm="12">
          <FormBootstrap.Label>Ordem:</FormBootstrap.Label>
          <InputForm type="number" name="ordem" />
        </Col>

        <Col md="2" className="mt-4">
          <CheckboxForm
            name="incluirTipoImagem"
            label="Incluir Tipo de Imagem"
            className="mt-1"
            defaultChecked={initialData?.tipoImagemSwitch || false}
            onChange={(e, v) => handleCheckTipoImagem(e, v)}
          />
        </Col>
      </Row>

      <Row>
        <Col md="12" sm="12">
          <FormBootstrap.Label>Instruções:</FormBootstrap.Label>
          <InputForm type="text" name="instrucoes" />
        </Col>
      </Row>
      <Row>
        {tipoItem === "Qualitativo" && (
          <>
            <Col md="3" sm="12">
              <FormBootstrap.Label>Tipo Campo:</FormBootstrap.Label>
              <AutocompleteForm
                name="tipoCampo"
                options={["Combo", "Texto"]}
                transformOnSelect={(option) => option}
                getOptionLabel={(option) => option}
                getOptionSelected={(option, value) => value === option}
                onChange={(e, v) => handleShowStatus(e, v)}
              />
            </Col>
          </>
        )}
        {showStatus && (
          <>
            <Col md="3" xs="12">
              <FormBootstrap.Label>
                Insira as opções de combo:
              </FormBootstrap.Label>
              <AutocompleteForm
                multiple={true}
                freeSolo={true}
                options={[]}
                name="optionsCombo"
                onChange={(e) => handleTextStatus(e)}
              />
            </Col>
          </>
        )}
      </Row>
      {tipoImagemSwitch === "on" && (
        <Row>
          {/* <Col md="3">
            <FormBootstrap.Label>Departamento:</FormBootstrap.Label>
            <AutocompleteForm
              name="departamento"
              options={listByEmpresa.docs || []}
              transformOnSelect={(option) => ({
                _id: option._id,
                nome: option.nome,
              })}
              getOptionLabel={(option) =>
                typeof option === "string" ? option : option.nome
              }
              getOptionSelected={(option, value) => value._id === option._id}
            />
          </Col> */}
          <Col md="3">
            <FormBootstrap.Label>Tipo documento:</FormBootstrap.Label>
            {loading ? (
              <BeatLoader
                sizeUnit={"px"}
                size={10}
                color={"#444444"}
                loading={loading}
              />
            ) : (
              <AutocompleteForm
                name="tipodocumento"
                options={tiposdocumento || []}
                onChange={(e, newValue) => handleLoadTiposImagem(e, newValue)}
                transformOnSelect={(option) => ({
                  _id: option._id,
                  nome: option.nome,
                })}
                getOptionLabel={(option) =>
                  typeof option === "string" ? option : option.nome
                }
                getOptionSelected={(option, value) => value._id === option._id}
              />
            )}
          </Col>
          <Col md="3">
            <FormBootstrap.Label>Tipo imagem:</FormBootstrap.Label>
            {loadingTipoImagem ? (
              <BeatLoader
                sizeUnit={"px"}
                size={10}
                color={"#444444"}
                loading={loadingTipoImagem}
              />
            ) : (
              <AutocompleteForm
                multiple={true}
                name="tipoImagem"
                options={tiposimagem || []}
                onChange={(e, newValue) =>
                  handleLoadTipoImagemSelecionado(e, newValue)
                }
                getOptionLabel={(option) => option.nome}
                getOptionSelected={(option, value) => value._id === option._id}
              />
            )}
          </Col>
        </Row>
      )}

      {tipoImagemSwitch === "on" && !hideRecorrencia && (
        <>
          <Separador titulo="Configuração de recorrência (Indisponível para itens com múltiplos tipos de imagem)" />
          <Row>
            <Col md="3">
              <FormBootstrap.Label>Recorrência:</FormBootstrap.Label>

              <AutocompleteForm
                name="recorrencia"
                options={[
                  "Sem recorrência",
                  "Mensal",
                  "Trimestral",
                  "Semestral",
                  "Anual",
                  "Vencimento",
                ]}
                transformOnSelect={(option) => option}
                getOptionLabel={(option) => option}
                getOptionSelected={(option, value) => option === value}
              />
            </Col>
            <Col md="3">
              <FormBootstrap.Label>Início da recorrência:</FormBootstrap.Label>

              <AutocompleteForm
                name="indexadorInicio"
                options={indexadores.filter(
                  (indexador) => indexador.tipo === "data"
                )}
                transformOnSelect={(option) => ({
                  id_es: option.id_es,
                  nome: option.nome,
                })}
                getOptionLabel={(option) =>
                  typeof option === "string" ? option : option.nome
                }
                getOptionSelected={(option, value) =>
                  value.id_es === option.id_es
                }
              />
            </Col>
            <Col md="3">
              <FormBootstrap.Label>Fim da recorrência:</FormBootstrap.Label>
              <AutocompleteForm
                name="indexadorFim"
                options={indexadores.filter(
                  (indexador) => indexador.tipo === "data"
                )}
                transformOnSelect={(option) => ({
                  id_es: option.id_es,
                  nome: option.nome,
                })}
                getOptionLabel={(option) =>
                  typeof option === "string" ? option : option.nome
                }
                getOptionSelected={(option, value) =>
                  value.id_es === option.id_es
                }
              />
            </Col>
            <Col md="3">
              <FormBootstrap.Label>Indexador referência:</FormBootstrap.Label>
              <AutocompleteForm
                name="indexadorImagem"
                options={indexadoresTipoImagem}
                transformOnSelect={(option) => ({
                  id_es: option.id_es,
                  nome: option.nome,
                })}
                getOptionLabel={(option) =>
                  typeof option === "string" ? option : option.nome
                }
                getOptionSelected={(option, value) =>
                  value.id_es === option.id_es
                }
              />
            </Col>
          </Row>
        </>
      )}

      <Separador titulo="Regras para obrigatoriedade do item" />
      <Row className="text-right">
        <Col>
          <ButtonMui
            variant="contained"
            color="primary"
            id="buttonAddRule"
            style={{ fontSize: "x-small" }}
            onClick={novaRegra}
          >
            Adicionar regra
            <AddCircleIcon className="ml-2" />
          </ButtonMui>
        </Col>
      </Row>
      {regras.map((regra, index) => (
        <FormRegra
          formRef={formRef}
          register={register}
          regra={regra}
          index={index}
          indexadores={indexadores}
          tipoDocumento={tipoDocumento}
          onRemove={() => handleRemove(index)}
          disableRemove={index < regras.length - 1}
        />
      ))}

      <div className="buttons">
        <Grid container spacing={1}>
          <Grid item>
            <ButtonMui variant="contained" color="primary" type="submit">
              Salvar
            </ButtonMui>
          </Grid>
          <Grid item>
            <ButtonMui
              variant="contained"
              onClick={() => onReset()}
              color="secondary"
              type="reset"
            >
              Limpar
            </ButtonMui>
          </Grid>
          <Grid item>
            <BackButton variant="contained" color="secondary">
              Voltar
            </BackButton>
          </Grid>
        </Grid>
      </div>
    </Form>
  );
}
