import React from "react";
import { Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";

import "./styles.css";
import Content from "components/Content";
import { getTenant, setTenant } from "services/auth";
import { retrying } from "pages/Login/context/util";

export default function Ops() {

  if (!retrying()) {
    const tenant = getTenant();
    localStorage.clear();
    setTenant(tenant);
  }

  window.location.reload();

  return (
    <Content>
      <Row>
        <div className="ops-container">
          <div>
            <h1>OPS</h1>
            <h4>
              Algum problema ocorreu. Entre em contato com o administrador.
            </h4>
            <NavLink to="/inicio" className="btn btn-success">
              Voltar para o inicio
            </NavLink>
          </div>
        </div>
      </Row>
    </Content>
  );
}
