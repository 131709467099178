import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import CircularProgress from "@material-ui/core/CircularProgress";

import { Creators as UsuariosActions } from "../../store/ducks/usuarios";

import "./styles.css";

export default function LoginWithToken(props) {
  const dispatch = useDispatch();

  useEffect(() => {
    const token = props.match.params.token;

    dispatch(UsuariosActions.loginWithTokenRequest(token));
  }, []);

  return (
    <div className="loading-application">
      <div className="dados">
        <CircularProgress size={100} style={{ color: "#fff" }} />
        <h4>
          Aguarde, estamos realizando a sua autenticanção na plataforma...
        </h4>
      </div>
    </div>
  );
}
