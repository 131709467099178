import React, { useState } from 'react';
import {
  List, ListItem, ListSubheader,
  ListItemText, Badge
} from '@material-ui/core';
import { useSelector } from 'react-redux';

import Mensagens from './Mensagens';
import Tarefas from './Tarefas';

import './styles.css'
import { InboxIcon, SendIcon } from 'components/Icons';
import Content from 'components/Content';

function MailBox() {

  const [mailBox, setMailBox] = useState(1);

  const { naoLidas: mensagensNaoLidas } = useSelector(state => state.mensagens);
  const { naoLidas: tarefasNaoLidas } = useSelector(state => state.providencias);

  function handleMailBoxClick(e, mailBox) {
    setMailBox(mailBox);
  }

  return (
    <Content>
      <div className="mailbox-container">
        <div className="painel-content">
          <List className="mailbox-pastas">
            <ListSubheader>Mensagens</ListSubheader>
            <ListItem
              button
              selected={mailBox === 1}
              onClick={e => handleMailBoxClick(e, 1)}
              className="mailbox-pasta"
            >
              <Badge
                badgeContent={mensagensNaoLidas}
                color="secondary"
                overlap="rectangular"
              >
                <InboxIcon />
                <ListItemText primary="Caixa de entrada" />
              </Badge>

            </ListItem>
            <ListItem
              button
              selected={mailBox === 2}
              onClick={e => handleMailBoxClick(e, 2)}
              className="mailbox-pasta"
            >
              <SendIcon />
              <ListItemText primary="Enviados" />
            </ListItem>
            <ListSubheader>Tarefas</ListSubheader>
            <ListItem
              button
              selected={mailBox === 3}
              className="mailbox-pasta"
              onClick={e => handleMailBoxClick(e, 3)}
            >
              <Badge
                badgeContent={tarefasNaoLidas}
                color="secondary"
                overlap="rectangular"
              >
                <InboxIcon />
                <ListItemText primary="Caixa de entrada" />
              </Badge>

            </ListItem>
            <ListItem
              button
              selected={mailBox === 4}
              className="mailbox-pasta"
              onClick={e => handleMailBoxClick(e, 4)}
            >
              <SendIcon />
              <ListItemText primary="Enviados" />
            </ListItem>
            <ListSubheader>E-mails externos</ListSubheader>
            <ListItem
              button
              selected={mailBox === 5}
              disabled
              className="mailbox-pasta"
            >
              <Badge
                badgeContent={0}
                color="secondary"
                overlap="rectangular"
              >
                <InboxIcon />
                <ListItemText primary="Caixa de entrada" />
              </Badge>

            </ListItem>
            <ListItem
              button
              selected={mailBox === 6}
              disabled
              className="mailbox-pasta"
            >
              <SendIcon />
              <ListItemText primary="Enviados" />
            </ListItem>
          </List>
        </div>
        {mailBox === 1 && <Mensagens endpoint={mailBox} />}
        {mailBox === 2 && <Mensagens endpoint={mailBox} />}
        {mailBox === 3 && <Tarefas endpoint={1} />}
        {mailBox === 4 && <Tarefas endpoint={2} />}
      </div>
    </Content>
  );
}

export default MailBox;