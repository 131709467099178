import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import IconButton from "@material-ui/core/IconButton";

import { Col } from "react-bootstrap";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

export default function SimpleModal({
  children,
  isOpen,
  title,
  onClose,
  size,
}) {
  const useStyles = makeStyles((theme) => ({
    paper: {
      position: "absolute",
      width: size ? size : 600,
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: "0 0 8px 0",
    },
    modalTitle: {
      width: "100%",
      borderBottom: "1px solid #dbdbdb",
      marginBottom: "15px",
      padding: "3px 10px",
      fontSize: 12,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      background: "#333",
      color: "#fff",
    },
    closeButton: {
      color: "#fff",
    },
  }));

  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    onClose(false);
    setOpen(false);
  };

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          {title && (
            <div className={classes.modalTitle}>
              <span>{title}</span>
              <IconButton
                size="small"
                className={classes.closeButton}
                onClick={handleClose}
              >
                x
              </IconButton>
            </div>
          )}
          <Col>{children}</Col>
        </div>
      </Modal>
    </div>
  );
}
