export const Types = {
  NEW: "providencia/NEW",
  EDIT: "providencia/EDIT",
  CANCEL: "providencia/CANCEL",
  SAVE_REQUEST: "providencia/SAVE_REQUEST",
  CREATE_REQUEST: "providencia/CREATE_REQUEST",
  SAVE_SUCCESS: "providencia/SAVE_SUCCESS",
  SAVE_ERROR: "providencia/SAVE_ERROR",
  DELETE_REQUEST: "providencia/DELETE_REQUEST",
  DELETE_SUCCESS: "providencia/DELETE_SUCCESS",
  OPEN_UPLOAD: "providencia/OPEN_UPLOAD",
  CLOSE_UPLOAD: "providencia/CLOSE_UPLOAD",
  OPEN_VIEW: "providencia/OPEN_VIEW",
  CLOSE_VIEW: "providencia/CLOSE_VIEW",
  OPEN_DELETE: "providencia/OPEN_DELETE",
  CLOSE_DELETE: "providencia/CLOSE_DELETE",
  UPLOAD_REQUEST: "providencia/UPLOAD_REQUEST",
  UPLOAD_SUCCESS: "providencia/UPLOAD_SUCCESS",
  UPLOAD_ERROR: "providencia/UPLOAD_ERROR",
  ADD_PARTICIPANTS_REQUEST: "providencia/ADD_PARTICIPANTS_REQUEST",
  TASK_APPROVAL_REQUEST: "providencia/TASK_APPROVAL_REQUEST",
  TASK_APPROVAL_SUCCESS: "providencia/TASK_APPROVA_SUCCESS",
  DELETE_IMAGE_REQUEST: "providencia/DELETE_IMAGE_REQUEST",
};

const INITIAL_STATE = {
  data: {},
  loading: false,
  loadingTaskApproval: false,
  open: false,
  openUpload: false,
  openView: false,
  openDelete: false,
};

export default function providencia(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.NEW:
      return {
        ...state,
        data: {},
        loading: false,
        open: true,
      };

    case Types.EDIT:
      return {
        ...state,
        data: action.payload.providencia,
        loading: false,
        open: true,
      };

    case Types.SAVE_REQUEST:
      return {
        ...state,
        loading: true,
        data: action.payload.providencia,
      };

    case Types.SAVE_SUCCESS:
      return {
        ...state,
        loading: false,
        open: false,
      };

    case Types.SAVE_ERROR:
      return {
        ...state,
        loading: false,
      };

    case Types.CANCEL:
      return {
        ...state,
        loading: false,
        open: false,
      };

    case Types.DELETE_REQUEST:
      return {
        ...state,
        loading: true,
        data: action.payload,
      };

    case Types.DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        openDelete: false,
      };

    case Types.OPEN_UPLOAD:
      return {
        ...state,
        openUpload: true,
        data: action.payload.mensagem,
      };

    case Types.CLOSE_UPLOAD:
      return {
        ...state,
        openUpload: false,
        data: {},
      };

    case Types.OPEN_VIEW:
      return {
        ...state,
        openView: true,
        data: action.payload.mensagem,
      };

    case Types.CLOSE_VIEW:
      return {
        ...state,
        openView: false,
        data: {},
      };

    case Types.OPEN_DELETE:
      return {
        ...state,
        openDelete: true,
        data: action.payload.mensagem,
      };

    case Types.CLOSE_DELETE:
      return {
        ...state,
        openDelete: false,
        data: {},
      };

    case Types.UPLOAD_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case Types.UPLOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        openUpload: false,
      };

    case Types.TASK_APPROVAL_REQUEST:
      return {
        ...state,
        loadingTaskApproval: true,
      };

    case Types.TASK_APPROVAL_SUCCESS:
      return {
        ...state,
        loadingTaskApproval: false,
        openView: false,
      };

    case Types.UPLOAD_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}

export const Creators = {
  new: () => ({
    type: Types.NEW,
  }),
  edit: (providencia) => ({
    type: Types.EDIT,
    payload: { providencia },
  }),
  cancel: () => ({
    type: Types.CANCEL,
  }),
  saveRequest: (providencia) => ({
    type: Types.SAVE_REQUEST,
    payload: { providencia },
  }),
  createRequest: (providencia, imagens) => ({
    type: Types.CREATE_REQUEST,
    payload: { providencia, imagens },
  }),
  saveSuccess: () => ({
    type: Types.SAVE_SUCCESS,
  }),
  saveError: () => ({
    type: Types.SAVE_ERROR,
  }),
  deleteRequest: (id) => ({
    type: Types.DELETE_REQUEST,
    payload: id,
  }),
  deleteSuccess: () => ({
    type: Types.DELETE_SUCCESS,
  }),
  openUpload: (mensagem) => ({
    type: Types.OPEN_UPLOAD,
    payload: { mensagem },
  }),
  closeUpload: () => ({
    type: Types.CLOSE_UPLOAD,
  }),

  openView: (mensagem) => ({
    type: Types.OPEN_VIEW,
    payload: { mensagem },
  }),
  closeView: () => ({
    type: Types.CLOSE_VIEW,
  }),
  openDelete: (mensagem) => ({
    type: Types.OPEN_DELETE,
    payload: { mensagem },
  }),
  closeDelete: () => ({
    type: Types.CLOSE_DELETE,
    payload: {},
  }),
  uploadRequest: (body) => ({
    type: Types.UPLOAD_REQUEST,
    payload: { body },
  }),
  uploadSuccess: () => ({
    type: Types.UPLOAD_SUCCESS,
  }),
  uploadError: () => ({
    type: Types.UPLOAD_ERROR,
  }),
  taskApprovalRequest: task => ({
    type: Types.TASK_APPROVAL_REQUEST,
    payload: task,
  }),
  taskApprovalSuccess: () => ({
    type: Types.TASK_APPROVAL_SUCCESS,
  }),
  addParticipantsRequest: ({ taskId, newParticipants }) => ({
    type: Types.ADD_PARTICIPANTS_REQUEST,
    payload: { taskId, newParticipants }
  }),
  deleteImageRequest: (taskId, imageId, imageArrayLength) => ({
    type: Types.DELETE_IMAGE_REQUEST,
    payload: { taskId, imageId, imageArrayLength },
  }),

};
