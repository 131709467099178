import React from 'react';
import { CopyrightIcon, NoteAddIcon } from 'components/Icons';

function NotificacaoIcone({ tipo }) {
  return tipo === 'INDEXACAO_DOCUMENTO' ||
    tipo === 'NOVA_DISTRIBUICAO' ||
    tipo === 'NOVO_ANDAMENTO' ? (
    <NoteAddIcon />
  ) : (
    <CopyrightIcon />
  );
}

export default NotificacaoIcone;
