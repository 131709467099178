import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TreeItem } from '@material-ui/lab';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Chip from '@material-ui/core/Chip';
import { AddCircleIcon, DeleteIcon, EditIcon } from 'components/Icons';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary,
    '&:hover > $content': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label':
      {
        backgroundColor: 'transparent',
      },
  },
  content: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    '$expanded > &': {
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
  group: {
    marginLeft: 0,
    '& $content': {
      paddingLeft: theme.spacing(2),
    },
  },
  expanded: {},
  selected: {},
  label: {
    fontWeight: 'inherit',
    color: 'inherit',
  },
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5, 0),
  },
  labelIcon: {
    marginRight: theme.spacing(1),
  },
  labelText: {
    fontWeight: 'inherit',
    flexGrow: 1,
  },
}));

function StyledTreeItem(props) {
  const classes = useStyles();
  const {
    labelText,
    labelIcon: LabelIcon,
    labelInfo,
    color,
    bgColor,
    onClickRemove,
    onClickEdit,
    onAddFieldClick,
    addFields = false,
    indexador,
    hasDocument,
    ...other
  } = props;

  const wrapper = [
    { propriedade: 'obrigatorio', label: 'Obrigatório' },
    { propriedade: 'somenteLeitura', label: 'Somente leitura' },
    { propriedade: 'indexar', label: 'Pesquisável' },
    { propriedade: 'invisivel', label: 'Invisível' },
    { propriedade: 'limpar', label: 'Limpar após interação' },
    {
      propriedade: 'exibirPesquisaDetalhada',
      label: 'Exibir na pesquisa detalhada/esteira',
    },
    {
      propriedade: 'esteira',
      label: 'Pesquisavel na esteira/pesquisa detalhada',
    },
    { propriedade: 'sensivel', label: 'Dado Pessoal' },
  ];

  return (
    <TreeItem
      label={
        <div className={classes.labelRoot}>
          <LabelIcon color="inherit" className={classes.labelIcon} />
          <Typography variant="body2" className={classes.labelText}>
            {labelText}
          </Typography>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexWrap: 'wrap',
              textAlign: 'center',
            }}
            className="d-none d-lg-block"
          >
            {wrapper
              .filter((item) => indexador[item.propriedade])
              .map((item) => (
                <Chip
                  className="mr-1"
                  variant="outlined"
                  size="small"
                  label={item.label}
                />
              ))}
          </div>
          <Typography variant="caption" color="inherit">
            {labelInfo}
          </Typography>
          {!!addFields && (
            <IconButton onClick={onAddFieldClick}>
              <AddCircleIcon />
            </IconButton>
          )}
          <IconButton onClick={onClickEdit}>
            <EditIcon />
          </IconButton>
          <IconButton disabled={hasDocument} onClick={onClickRemove}>
            <DeleteIcon />
          </IconButton>
        </div>
      }
      style={{
        '--tree-view-color': color,
        '--tree-view-bg-color': bgColor,
      }}
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        selected: classes.selected,
        group: classes.group,
        label: classes.label,
      }}
      {...other}
    />
  );
}

export default StyledTreeItem;
