import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '8px'
  },
});

function LoadingProcess({ open }) {

  const classes = useStyles();

  return (
    <Dialog open={open}>
      <DialogContent className={classes.root}>
        <CircularProgress color="primary" />&nbsp;
        <Typography variant="h6">Aguarde... Extraindo informações do(s) documento(s)</Typography>
      </DialogContent>
    </Dialog>
  );
}

export default LoadingProcess;