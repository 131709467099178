import React from 'react';

import {
    Container,
    Title,
    Content,
    Actions,
    Button,
} from './styles/DefaultModal';

import ButtonCloseModal from '../ButtonCloseModal';

function DefaultModal({
    open,
    handleClose,
    title, 
    noActions,
    centerbuttons = false,
    confirmButtonText = 'Confirmar',
    confirmButtonFunction,
    nocancelbutton = false,
    cancelButtonText = 'Cancelar',
    cancelButtonFunction = handleClose,
    contained = false,
    children
}) {

    return (
        <Container open={open} contained={contained}>
                <ButtonCloseModal handleClose={handleClose} />
            <Title>{title}</Title>
            <Content>
                {children}
            </Content>
            {!noActions && 
                <Actions centerbuttons={centerbuttons}>
                    {!nocancelbutton &&
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={cancelButtonFunction}>
                            {cancelButtonText}
                        </Button>}
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={confirmButtonFunction}>
                        {confirmButtonText}
                    </Button>
                </Actions>}
        </Container>
    );
}

export default DefaultModal;