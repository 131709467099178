import React, { useState, useRef } from "react";

import { useDispatch, useSelector } from "react-redux";

import BackButton from "components/BackButton";
import InputForm from "components/InputForm";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

import { Row, Col } from "react-bootstrap";
import { Form } from "@unform/web";
import { ValidationError } from "yup";
import { FormControlLabel, Switch } from "@material-ui/core";

import UFSchema from "validators/UFSchema";
import { Creators as AcaoActions } from "store/ducks/acao";

import "./styles.css";
import Content from "components/Content";

export default function FormAcao() {
  const dispatch = useDispatch();
  const formRef = useRef(null);

  const usuario = useSelector((state) => state.usuarios.auth);
  const { loading } = useSelector((state) => state.acao);

  const [status, setStatus] = useState(false);

  const handleCheckStatus = () => {
    setStatus(!status);
  };

  const onSubmit = async (values) => {
    const acaoBody = {
      ...values,
      userAcao: usuario.id,
      status,
    };

    try {
      await UFSchema.validate(acaoBody, {
        abortEarly: false,
      });

      dispatch(AcaoActions.createRequest(acaoBody));
    } catch (err) {
      const validationErrors = {};
      if (err instanceof ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });

        formRef.current.setErrors(validationErrors);
      }
    }
  };

  return (
    <Content>
          <Row>
            <Col md="12" className="painel">
              <div className="painel-content">
                <div className="painel-title text-left">Nova Ação</div>
                <div className="painel-body">
                  <Form ref={formRef} onSubmit={onSubmit}>
                    <Row className="row">
                      <Col md={6}>
                        <InputForm name="nome" label="Nome da ação" />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormControlLabel
                          control={
                            <Switch
                              name="status"
                              onChange={handleCheckStatus}
                              color="primary"
                            />
                          }
                          label="Ativo"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Grid container spacing={1}>
                        <Grid item>
                          <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            disabled={loading}
                          >
                            Enviar
                            {!!loading && <CircularProgress size={20} />}
                          </Button>
                        </Grid>
                        <Grid item>
                          <BackButton variant="contained" color="secondary">
                            Voltar
                          </BackButton>
                        </Grid>
                      </Grid>
                    </Row>
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
          </Content>
  );
}
