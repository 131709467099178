import React, { useEffect, useRef, useState } from "react";
import { useField } from "@unform/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { toastr } from "react-redux-toastr";

const useStyles = makeStyles({
  root: {
    marginTop: "8px",
  },
  input: {
    display: "none",
  },
});

const DEFAULT_TRANSFORM = (option) => option;

function AutocompleteForm({
  name,
  label,
  onChange,
  disabled = false,
  forceShrink = false,
  freeSolo = false,
  multiple = false,
  transformOnSelect = DEFAULT_TRANSFORM,
  ...rest
}) {
  const inputRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);
  const refValue = useRef(transformOnSelect(defaultValue || ""));
  const [shrink, setShrink] = useState(!!defaultValue || false);

  const classes = useStyles();

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue: () => refValue.current,
      setValue: (ref, value) => {
        refValue.current = transformOnSelect(value);
        setShrink(!!value);
        setTimeout(() => {
          ref.children[0].children[1].children[0].value = transformOnSelect(
            value
          );
        }, 100);
      },
    });
  }, [fieldName, registerField, transformOnSelect]);

  function handleChange(e, value) {
    refValue.current = null;
    if (value) {
      refValue.current = transformOnSelect(value);
    }

    setShrink(!!value);
    if (onChange) onChange(e, value);
  }

  function handleDoubleClick(e) {
    if (!e.target.value) return;
    navigator.clipboard.writeText(e.target.value);
    toastr.info("", "Input copiado em segundo plano");
  }

  return (
    <Autocomplete
      freeSolo={freeSolo}
      multiple={multiple}
      className={classes.root}
      onChange={handleChange}
      defaultValue={defaultValue}
      disabled={disabled}
      ref={inputRef}
      getOptionSelected={(option, value) =>
        typeof option === "string" ? option === value : option._id === value._id
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          error={error && true}
          onDoubleClick={handleDoubleClick}
          helperText={error}
          InputLabelProps={{
            shrink: shrink || forceShrink
          }}
        />
      )}
      size="small"
      {...rest}
    />
  );
}

export default AutocompleteForm;
