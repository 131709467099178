import React, { useEffect, useRef, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { TextField, IconButton } from "@material-ui/core";

import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import CheckboxForm from "components/CheckboxForm";
import ToolTipComponent from "components/Tooltip";
import { AddIcon, DragIcon, PreviewIcon } from "components/Icons";
import { Dropdown } from "components/Dropdown";
import { SensibleFlag } from "components/SensibleFlag";

export const TIPOS_ETAPAS = {
    imagem: "Imagem",
    biometria: "Biometria",
    texto: "Texto",
    validacoes: "Validações",
    textoComCampos: "Texto com campos",
}

export const INITIAL_FORM = {
    nome: "",
    idTipoDocumento: null,
    mensagem: "",
    campos: [],
    steps: [],
    tiposDeImagem: [],
};

export function Struct({ index, className, field0, field1, field2, field3, field4, field5 }) {
    return (
        <div index={index} className={className} >
            <Col md={0}>{field1}</Col>
            <Col md={1}>{field0}</Col>
            <Col md={3}>{field2}</Col>
            <Col md={5}>{field3}</Col>
            <Col md={1}>{field4}</Col>
            <Col md={1}>{field5}</Col>
        </div>
    )
}

export const FormItems = ({ index, item, className, onChange, onEdit }) => {

    const { nome, nomeFormulario, visivel, options, visivelNaEtapa, sensivel } = item;
    const [texto, setTexto] = useState('');

    //#region artifício para melhorar a performance dos componentes
    const ativo = useRef(false);
    const setAtivo = (value) => {
        ativo.current = value;
    }

    const handleBlur = (property) => {
        if (texto.trim() === nomeFormulario.trim()) return;
        onChange(index, property, texto);
    };

    const specialChange = ({ target }) => {
        setTexto(target.value);
    };
    //#endregion

    const handleChange = (property, value) => {
        onChange(index, property, value);
    };

    useEffect(() => { setTexto(nomeFormulario) }, [nomeFormulario]);

    return (
        <div className="wire-block">
            <Struct
                key={index}
                className={className}
                field0={index + 1}
                field1={<DragIcon />}
                field2=
                {
                    <SensibleFlag indexador={{ sensivel }} >
                        <b>{nome}</b>
                    </SensibleFlag>
                }
                field3={
                    <TextField
                        type="text"
                        variant="outlined"
                        name="nomeFormulario"
                        value={texto}
                        autoFocus={index === 0}
                        onChange={specialChange}
                        onFocus={() => setAtivo(true)}
                        onBlur={() => { ativo.current && handleBlur('nomeFormulario') }}
                    />
                }
                field4={
                    <CheckboxForm
                        name='visivel'
                        checked={visivel}
                        onChange={e => handleChange('visivel', e.target.checked)}
                    />
                }
                field5={
                    <Dropdown
                        value={visivelNaEtapa}
                        options={options.map((e) => ({ value: e, label: e }))}
                        onChange={e => handleChange('visivelNaEtapa', e.target.value)}
                    />
                }
            />
            {onEdit &&
                <IconButton className="button-add" onClick={onEdit}>
                    <AddIcon />
                </IconButton>}
        </div>

    );
};

export function Line({ children, md = 6 }) {
    return (
        <Row>
            <Col md={md}>
                {children}
            </Col>
        </Row>
    )
}

export function onAddNewIndexador({ id_es, nome }) {
    return ({ id_es, nome, nomeFormulario: nome, visivel: true })
}

export const onDragEnd = (result, indexadores, setIndexadores) => {
    if (!result.destination) return;
    const reorderedItems = [...indexadores]
    const [reorderedItem] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, reorderedItem);
    setIndexadores(reorderedItems);
};

export function DradEndDropList({ onDragEnd, onChange, indexadores, setIndexadores, options = [] }) {

    function onEdit(setIndexadores) {
        const novo = onAddNewIndexador({ id_es: '', nome: 'Adicional' });
        setIndexadores(indexadores.concat(novo));
    }

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
                {(provided) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                    >
                        {indexadores.map(({ id_es, nome, nomeFormulario, visivel, visivelNaEtapa, sensivel }, index) => {
                            const id = id_es || `${nome}-${index}`;
                            return (
                                <Draggable
                                    key={id}
                                    draggableId={id}
                                    index={index}
                                >
                                    {(provided) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                        >

                                            <FormItems
                                                index={index}
                                                item={{
                                                    nome,
                                                    visivel,
                                                    nomeFormulario,
                                                    options,
                                                    visivelNaEtapa,
                                                    sensivel
                                                }}
                                                onChange={onChange}
                                                className={`drag-n-drop-item${visivel ? '' : '-invisivel'}`}
                                                onEdit={index !== indexadores.length - 1 ? undefined : () => onEdit(setIndexadores)}
                                            />

                                        </div>
                                    )}
                                </Draggable>
                            )
                        })}
                    </div>
                )}
            </Droppable>

        </DragDropContext>
    )
}

export function HeaderFormulario({ title, onClick, enableSharing, onClickShare }) {

    return (
        <div className="wire-title">
            <span>
                {title}
            </span>
            <IconButton className="button-preview" onClick={onClick}>
                <ToolTipComponent title="Preview do Formulário" placement="left">
                    <PreviewIcon />
                </ToolTipComponent>
            </IconButton>
        </div>
    )
}