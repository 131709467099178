import api from "services/api";
import { getToken } from "services/auth";
import { call, select, put, all, takeLatest } from "redux-saga/effects";
import { toastr } from "react-redux-toastr";

import { Creators as DocSignatureActions, Types } from "../ducks/docsignature";

export const getOrganization = (state) => state.usuarios.auth.organization;
export const getUserId = (state) => state.usuarios.auth.id;

export function* sendSignature({ payload }) {
  try {
    const organization = yield select(getOrganization);

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
      params: {},
    };

    const response = yield call(
      api.post,
      `docsignature/send`,
      payload,
      headerParams
    );

    if (response.data.error) throw new Error(response.data.message);

    toastr.success("Documento enviado para assinatura eletrônica");

    yield put(DocSignatureActions.sendSuccess(response));
  } catch (error) {
    yield put(DocSignatureActions.sendError());

    console.log("error ", error);
    toastr.error("Ops", "Inconsistências ao enviar a assinatura eletronica");
  }
}

export function* listDocSignature({ payload }) {
  const organization = yield select(getOrganization);

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const { data } = yield call(
      api.get,
      `docsignature/list/${payload}`,
      headerParams
    );

    yield put(DocSignatureActions.listSuccess(data));
  } catch (error) {
    toastr.error("Ops", "Não foi possível listar as assinaturas eletrônicas");
  }
}

export function* getBlob({ payload }) {
  const organization = yield select(getOrganization);

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const response = yield call(api.post, `s3/blob`, payload, headerParams);

    yield put(DocSignatureActions.blobSuccess(response));
  } catch (error) {
    toastr.error("Ops", "Não foi possível recuperar o blob do arquivo");
  }
}

export function* getB64({ payload }) {
  const organization = yield select(getOrganization);

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const response = yield call(api.post, `s3/base64`, payload, headerParams);

    yield put(DocSignatureActions.b64Success(response));
  } catch (error) {
    toastr.error("Ops", "Não foi possível recuperar o base64");
  }
}

export default all([takeLatest(Types.SEND_REQUEST, sendSignature)]);
