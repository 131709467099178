import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { DialogContent } from '@material-ui/core';
import { Row, Col } from 'react-bootstrap';

import { Creators as TiposDocumentoActions } from 'store/ducks/tiposdocumento';
import { Creators as DepartamentosActions } from 'store/ducks/departamentos';

import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Skeleton from '@material-ui/lab/Skeleton';
import Dialog from '@material-ui/core/Dialog';

import useForm from 'react-hook-form';

import { LabelSwapper } from '../../../../../components/LabelSwapper';
import { Button, GroupButton, DialogTitle } from './style';
import { MailFileOptions } from '../MailFileOptions';

const INITIAL_FORM = {
  indexador: '',
  tipodoc: '',
  departamento: '',
  agrupamento: '',
  dataInicial: '',
  dataFinal: '',
  status: '',
  attachmentType: null,
};

function ModalFilterVersionamento({
  open,
  handleClose,
  handlePrint,
  dateHasError,
  options,
}) {
  const dispatch = useDispatch();

  const { register } = useForm();
  const [form, setForm] = useState(INITIAL_FORM);

  const handleChangeForm = (name, value) => {
    setForm({ ...form, [name]: value });
  };

  const {
    access: { empresas },
  } = useSelector((state) => state.permissoes);

  useEffect(() => {
    dispatch(TiposDocumentoActions.listByEmpresaRequest(empresas));
    dispatch(DepartamentosActions.listRequest({ onlyOperational: true }));
  }, [dispatch]);

  const { list: departamentosList, loading: loadingDepartamentos } =
    useSelector((state) => state.departamentos);

  const { listByEmpresas: tipoDocList, loading: loadingTipoDoc } = useSelector(
    (state) => state.tipoDocumentos
  );

  const atachmentHasValue = options.sendAsMail ? form.attachmentType : true;

  const disableSendButtonWhen =
    dateHasError(form.dataInicial) ||
    dateHasError(form.dataFinal) ||
    !form.departamento ||
    !form.status ||
    !atachmentHasValue;

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        id="alert-dialog-title"
        style={{ minWidth: '600px' }}
      ></DialogTitle>
      <DialogContent>
        <Row>
          <Col xm={12} sm={12} md={6}>
            <TextField
              required
              type="date"
              name="dataInicial"
              label="Período Inicial"
              value={form.dataInicial}
              onChange={(e) => handleChangeForm('dataInicial', e.target.value)}
              variant="outlined"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Col>
          <Col xm={12} sm={12} md={6}>
            <TextField
              required
              type="date"
              name="dataFinal"
              label="Período Final"
              onChange={(e) => handleChangeForm('dataFinal', e.target.value)}
              value={form.dataFinal}
              variant="outlined"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={12}>
            {loadingDepartamentos ? (
              <Skeleton width="100%" height={50} />
            ) : (
              <Autocomplete
                required
                size="small"
                onChange={(event, newValue) => {
                  handleChangeForm('departamento', newValue);
                }}
                options={departamentosList}
                getOptionLabel={(option) => option.nome}
                noOptionsText="Selecione um departamento"
                getOptionSelected={(option, value) => option._id === value._id}
                clearOnBlur={false}
                name="departamento"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputRef={register}
                    name="modelType"
                    label="Departamento"
                    variant="outlined"
                  />
                )}
              />
            )}
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={12}>
            {!loadingTipoDoc ? (
              <Autocomplete
                required
                size="small"
                onChange={(event, newValue) => {
                  handleChangeForm('tipodoc', newValue);
                }}
                options={tipoDocList}
                getOptionLabel={(option) => option.nome}
                noOptionsText="Selecione um tipo documento"
                getOptionSelected={(option, value) => option._id === value._id}
                clearOnBlur={false}
                name="tipodoc"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputRef={register}
                    name="modelType"
                    label="Tipo de documento"
                    variant="outlined"
                  />
                )}
              />
            ) : (
              <Skeleton width="100%" height={50} />
            )}
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={12}>
            <Autocomplete
              required
              size="small"
              options={form.tipodoc?.indexadores || []}
              onChange={(event, newValue) => {
                handleChangeForm('indexador', newValue);
              }}
              getOptionLabel={(option) => option.nome}
              renderInput={(params) => (
                <TextField
                  {...params}
                  inputRef={register}
                  name="tipoDoc"
                  label="Indexador"
                  variant="outlined"
                />
              )}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={12}>
            <Autocomplete
              id="combo-box-status"
              options={[
                { tipo: true, label: 'Aberta' },
                { tipo: false, label: 'Encerrada' },
              ]}
              getOptionLabel={(option) => option.label}
              clearOnBlur={true}
              getOptionSelected={(option, value) => option.tipo === value.tipo}
              name="status"
              onChange={(event, newValue) => {
                handleChangeForm('status', newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={
                    <LabelSwapper
                      text={'Status'}
                      parent={'ModalFilterVersionamento'}
                      name={'statusDocumento'}
                    />
                  }
                  variant="outlined"
                />
              )}
              size="small"
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={12}>
            <Autocomplete
              id="combo-box-agrupamento"
              options={[
                { mensal: false, label: 'Diário' },
                { mensal: true, label: 'Mensal' },
              ]}
              getOptionLabel={(option) => option.label}
              clearOnBlur={true}
              getOptionSelected={(option, value) =>
                option.mensal === value.mensal
              }
              name="agrupamento"
              onChange={(event, newValue) => {
                handleChangeForm('agrupamento', newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} label="Agrupamento" variant="outlined" />
              )}
              size="small"
            />
          </Col>
        </Row>
        {options.sendAsMail && (
          <Row className="mt-3">
            <Col md={12}>
              <MailFileOptions
                canPrint={options.canPrint}
                canSheel={options.canSheel}
                onChange={(option) =>
                  handleChangeForm('attachmentType', option?.value)
                }
              />
            </Col>
          </Row>
        )}
      </DialogContent>
      <GroupButton className="mt-2">
        <Button variant="contained" color="secondary" onClick={handleClose}>
          Cancelar
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={disableSendButtonWhen}
          onClick={() => {
            handlePrint({
              departamento: form.departamento,
              agrupamento: form.agrupamento,
              dataInicial: form.dataInicial,
              dataFinal: form.dataFinal,
              status: form.status,
              dentroDoPrazoSLA: form.dentroDoPrazoSLA,
              tipoDoAnexo: form.attachmentType,
              sendAsMail: options.sendAsMail,
              indexador: form.indexador?.nome,
            });
          }}
        >
          {options.sendAsMail ? 'Enviar por e-mail' : 'Imprimir'}
        </Button>
      </GroupButton>
    </Dialog>
  );
}

export default ModalFilterVersionamento;
