export const Types = {
  LATEST_NEWS_REQUEST: "welcome/LATEST_NEWS_REQUEST",
  LATEST_NEWS_SUCCESS: "welcome/LATEST_NEWS_SUCCESS",
  BI_TOKEN_REQUEST: "temas/BI_TOKEN_REQUEST",
  BI_TOKEN_SUCCESS: "temas/BI_TOKEN_SUCCESS",
};

const INITIAL_STATE = {
  blog: {
    loading: false,
    list: [],
  },
  loadingToken: false,
  token: "",
};

export default function welcome(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.LATEST_NEWS_REQUEST:
      return { ...state, blog: { loading: true, list: [] } };
    case Types.LATEST_NEWS_SUCCESS:
      return { ...state, blog: { loading: false, list: action.payload } };
    case Types.BI_TOKEN_REQUEST:
      return { ...state, loadingToken: true };
    case Types.BI_TOKEN_SUCCESS:
      return { ...state, loadingToken: false, token: action.payload };
    default:
      return state;
  }
}

export const Creators = {
  latestNewsRequest: () => ({
    type: Types.LATEST_NEWS_REQUEST,
    payload: {},
  }),

  latestNewsSuccess: (payload) => ({
    type: Types.LATEST_NEWS_SUCCESS,
    payload,
  }),

  biTokenRequest: () => ({
    type: Types.BI_TOKEN_REQUEST,
    payload: {},
  }),

  biTokenSuccess: (token) => ({
    type: Types.BI_TOKEN_SUCCESS,
    payload: token,
  }),
};
