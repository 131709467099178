import { v4 as uuid } from "uuid";
import { format, parseISO } from "date-fns";
import pt from "date-fns/locale/pt-BR";
import { getPresignedUrl } from "services/presignedUrl";
import { valida_cnpj, valida_cpf } from "services/validadorCpfCNPJ";
class Utils {
  static decimalToTimeString(decimal) {
    let hours = Math.floor(decimal);
    let minutes = Math.round((decimal - hours) * 60);
    let seconds = Math.round((decimal - hours - minutes / 60) * 3600); // new line
    if (seconds === 60) {
      minutes++;
      seconds = 0;
    }
    if (minutes === 60) {
      hours++;
      minutes = 0;
    }
    const hoursString = hours.toString().padStart(2, "0");
    const minutesString = minutes.toString().padStart(2, "0");
    const secondsString = seconds.toString().padStart(2, "0"); // new line
    return hoursString + ":" + minutesString + ":" + secondsString; // modified return statement
  }

  static isHTML(text) {
    return ["<br>", "<p>", "<b>"].some((tag) => text?.includes(tag));
  }

  static cropTooltipText(text, maxCharCount) {
    if (Array.isArray(text)) {
      text = text[0];
    }

    if (!text) {
      return "";
    }

    return `${text.replace(/<[^>]*>/g, "").substring(0, maxCharCount)}${text.length > maxCharCount ? "..." : ""
      }`;
  }

  static generateObjectId = (
    m = Math,
    d = Date,
    h = 16,
    s = (s) => m.floor(s).toString(h)
  ) => s(d.now() / 1000) + " ".repeat(h).replace(/./g, () => s(m.random() * h));

  localeDateStringToDate(date) {
    let arrayDate;
    if (date.includes("/")) arrayDate = date.split("/").reverse();
    else arrayDate = date.split("-");

    const year = parseInt(arrayDate[0]);
    const month = parseInt(arrayDate[1]);
    const day = parseInt(arrayDate[2]);

    const dateWithoutTimeZone = new Date(year, month - 1, day);

    return dateWithoutTimeZone;
  }

  static validateEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  static getNameInitials(name) {
    if (!name) {
      return "";
    }

    const [firstName, middleName] = name.split(" ");

    if (firstName && middleName) {
      return `${firstName[0]}${middleName[0]}`;
    } else {
      return name[0];
    }
  }

  static replaceVariables(selectedModel, variables) {
    let newSelectedModel = { ...selectedModel };

    Object.keys(variables).forEach((variableName) => {
      if (!variables[variableName]) {
        return;
      }
      const re = new RegExp(`{{ ${variableName} }}`, "gi");

      newSelectedModel.header = newSelectedModel.header.map((item) =>
        item.replace(re, variables[variableName])
      );
      newSelectedModel.footer = newSelectedModel.footer.map((item) =>
        item.replace(re, variables[variableName])
      );
      newSelectedModel.body = newSelectedModel.body.map((item) => ({
        ...item,
        data:
          item.data instanceof Array
            ? item.data.map((chunk) =>
              chunk.replace(re, variables[variableName])
            )
            : item.data.replace(re, variables[variableName]),
      }));
    });

    return newSelectedModel;
  }

  static objectifyArray(array) {
    if (array) {
      return array.reduce(
        (obj, item) => ({
          ...obj,
          [item._id]: item,
        }),
        {}
      );
    }
    return {};
  }

  static getSubdomain = () => {
    const host = window.location.host;
    const parts = host.split(".");
    const first = parts.length === 4 ? parts[0] : null;
    if (!first) return null;
  } 

  static isNullOrUndefined(value) {
    return value === null || value === undefined;
  }

  static createMongoId() {
    const id = uuid();
    return Buffer.from(id).toString("hex").substring(0, 24);
  }

  static getURLExtension(url) {
    return url.split(/[#?]/)[0].split(".").pop().trim().toLowerCase();
  }

  formatISODate(date) {
    return format(parseISO(date), "dd/MM/yyyy");
  }

  getFormattedDate(date, formatDate = "dd/MM/yyyy") {
    if (!date) return "";
    const formattedDate = format(parseISO(date), formatDate);
    const formattedHour = format(parseISO(date), "p", { locale: pt });
    return `${formattedDate} - ${formattedHour}`;
  }

  extratcEmailFromString(value) {
    try {
      const regex = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/g;
      const email = regex.exec(value)[0];
      return email;
    } catch (err) {
      return "";
    }
  }

  isValidEmail(value) {
    const regex =
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    const matched = regex.exec(value);
    return matched || false;
  }

  async signedUrl(anexo) {
    const preview = await getPresignedUrl(anexo);
    return preview;
  }

  handlePastedEmails(lista) {
    if (!lista) return [];

    const listaString = lista.toString();

    if (listaString.length === 0) return lista;

    const listaArray = listaString.replace(/;/g, ",").split(",");

    const util = new Utils();
    const listaTratada = [];

    listaArray.forEach((email) => {
      const emailExtraido = util.extratcEmailFromString(email);
      const isValid = util.isValidEmail(emailExtraido);
      if (!isValid) return;
      listaTratada.push(emailExtraido);
    });

    const listaFiltrada = Array.from(
      new Set(
        listaTratada.filter((email) => email.length > 0).map((email) => email)
      )
    );

    return listaFiltrada;
  }
}

export async function handlePasteTexts(cb, value) {
  if (!cb || !navigator.clipboard) return;
  const text = await navigator.clipboard.readText();
  cb(text, value);
}

export function validContent(prev = '', value, tipo, label, cb) {
  if (!value) return;
  const separator = ';';

  const valor = typeof value === 'object' ? value.join(separator) : value;

  const CpfCnpj = [tipo.toUpperCase(), label.toUpperCase()];

  const resultString = valor
    .replace(/\n/g, separator)
    .split(separator)
    .filter(valor => {
      if (CpfCnpj.some(e => e.includes("CPF"))) return valida_cpf(valor) ? valor : '';
      if (CpfCnpj.some(e => e.includes("CNPJ"))) return valida_cnpj(valor) ? valor : '';
      return valor;
    });

  cb(prev ? prev.concat(resultString) : resultString);
}

export function handleBlurForceEnter(textFieldRef) {
  const enterKeyEvent = new KeyboardEvent('keydown', {
    key: 'Enter',
    keyCode: 13,
    bubbles: true,
    cancelable: true,
  });
  textFieldRef.current.dispatchEvent(enterKeyEvent);
};

export function usaKeycloak() {
  return process.env.REACT_APP_KEYCLOAK_URL && process.env.REACT_APP_KEYCLOAK_URL.length > 0
}

export const reorderIndexadores = (indexadores) => {
  const separadoresMap = {};
  indexadores.forEach((indexador) => {
    const separador = indexador.separador || "Outros indexadores";
    
    if (!separadoresMap[separador]) {
      separadoresMap[separador] = [];
    }
    
    separadoresMap[separador].push(indexador);
  });

  Object.keys(separadoresMap).forEach((separador) => {
    separadoresMap[separador].sort((a, b) => a.order - b.order);
  });

  const indexadoresOrdenados = Object.values(separadoresMap).flat();
  return indexadoresOrdenados;
}

export default Utils;
