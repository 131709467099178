import { makeStyles } from "@material-ui/core/styles";
import { lightBlue } from "@material-ui/core/colors";

export default makeStyles((theme) => ({
  buttonProgress: {
    color: lightBlue[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  avatarContainer: {
    display: "flex",
    justifyContent: "center",
  },
  avatar: {
    color: "#fff",
    backgroundColor: "#7f7f7f",
  },
  comment: {
    borderBottom: "solid 1px #cccccc7a",
    display: "grid",
    gridTemplateColumns: "100px 1fr",
    position: "relative",
    padding: "16px 0",
  },
  dateComment: {
    position: "absolute",
    top: "8px",
    right: "38px",
    fontWeight: "bold",
  },
  buttonAnexo: {
    position: "absolute",
    bottom: "8px",
    right: "24px",
  },
}));
