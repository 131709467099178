import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Row, Col } from "react-bootstrap";

import FormItem from "../FormItem";

import { Creators as DepartamentosActions } from "../../../store/ducks/departamentos";

import "./styles.css";
import Content from "components/Content";
export default function EditItemConfig(props) {
  const {
    access: { departamentos },
  } = useSelector((state) => state.permissoes);
  const empresaSetada = useSelector((state) => state.checkTipos.empresaSetada);

  const { tituloSelect, IndexItem, item } = props.location.state;

  const checksSelect = useSelector((state) => state.checkTipos.checksSelect);

  const dispatch = useDispatch();

  useEffect(() => {}, [checksSelect]);

  useEffect(() => {
    dispatch(
      DepartamentosActions.listByEmpresaRequest({
        empresa: empresaSetada,
        departamentos,
      })
    );
  }, [departamentos, dispatch, empresaSetada]);

  return (
    <Content>
      <div className="inter-panels">
        <Row>
          <Col md="12" className="painel">
            <div className="painel-content">
              <div className="painel-title text-left">Editar Item</div>
              <div className="painel-body">
                <FormItem
                  IndexItem={IndexItem}
                  tituloSelect={tituloSelect}
                  initialData={item}
                />
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </Content>
  );
}
