import React, { useEffect, useState, useRef } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Grid,
  Button as ButtonMui,
  Typography,
} from "@material-ui/core";
import StyledTableRow from "components/StyledTableRow";
import { useDispatch, useSelector } from "react-redux";
import Navbar from "components/Navbar";
import Menu from "components/Menu";
import LoadingTable from "components/LoadingTable";
import InputForm from "components/InputForm";
import { Row, Col } from "react-bootstrap";
import { Form } from "@unform/web";
import BackButton from "components/BackButton";
import ButtonSquare from "components/ButtonSquare";
import { ValidationError } from "yup";

import CustomTableSortLabel from "components/CustomTableSortLabel";

import { Creators as EtapasActions } from "store/ducks/etapas";

import EtapaSchema from "validators/EtapaSchema";

import FormModal from "../FormModal";

export default function EtapasConfig(props) {
  const {
    list: etapas,
    loading,
    page = 1,
    limit = 10,
    total,
    sort = "nome",
  } = useSelector((state) => state.etapas);

  const dispatch = useDispatch();
  const usuario = useSelector((state) => state.usuarios.auth);
  const [open, setOpen] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [request, setRequest] = useState({ page, limit, sort });

  const formRef = useRef(null);

  const { estconf } = props.location.state;

  useEffect(() => {
    const fila = {
      fila: estconf._id,
    };

    dispatch(EtapasActions.listRequest(fila));
  }, [dispatch, estconf._id]);

  const onSubmit = async (values) => {
    const etapas = {
      ...values,
      userAcao: usuario.id,
      fila: estconf._id,
    };

    try {
      await EtapaSchema.validate(etapas, {
        abortEarly: false,
      });
      dispatch(EtapasActions.createRequest(etapas));
    } catch (err) {
      const validationErrors = {};
      if (err instanceof ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });

        formRef.current.setErrors(validationErrors);
      }
    }
  };

  function handleSave() {
    setOpen(false);
  }

  function handleClick(data) {
    setModalData(data);
    setOpen(true);
  }

  function handleChangeSort(sort) {
    setRequest({ ...request, sort });
  }

  return (
    <>
      <Menu />
      <div className="content">
        <Navbar />
        <div className="panels">
          <Row>
            <Col md="12" className="painel">
              <div className="painel-content">
                <div className="painel-title text-left">Nova Etapa</div>
                <div className="painel-body">
                  <Form ref={formRef} onSubmit={onSubmit}>
                    <Row>
                      <Col md="12">
                        <Typography color="primary" variant="h6">
                          Fila: ({estconf.descricao})
                        </Typography>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <InputForm label="Nome da Etapa" name="nome" />
                      </Col>
                    </Row>

                    <div className="buttons">
                      <Grid container spacing={1}>
                        <Grid item>
                          <ButtonMui
                            variant="contained"
                            color="primary"
                            type="submit"
                          >
                            Enviar
                          </ButtonMui>
                        </Grid>
                        <Grid item>
                          <ButtonMui
                            variant="contained"
                            color="secondary"
                            type="reset"
                          >
                            Limpar
                          </ButtonMui>
                        </Grid>
                        <Grid item>
                          <BackButton variant="contained" color="secondary">
                            Voltar
                          </BackButton>
                        </Grid>
                      </Grid>
                    </div>
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
        </div>

        <div className="panels">
          <Row>
            <Col md="12" className="painel">
              <div className="painel-content">
                <div className="painel-title text-left">Etapas</div>
                <div className="painel-body">
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Descrição da Etapa</TableCell>
                        <TableCell>
                          <CustomTableSortLabel
                            onClick={handleChangeSort}
                            currentSort={request.sort}
                            sortField="sla"
                          >
                            SLA
                          </CustomTableSortLabel>
                        </TableCell>
                        <TableCell>Última etapa</TableCell>
                        <TableCell align="center">Ações</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {!!loading && <LoadingTable columns={4} />}
                      {!loading &&
                        etapas &&
                        etapas
                          .filter((etapa) => etapa.nome)
                          .map((item) => (
                            <StyledTableRow key={item._id}>
                              <TableCell>{item.nome}</TableCell>
                              <TableCell>
                                {item.sla === 0 && <>D{item.sla}</>}
                                {item.sla > 0 && <>D+{item.sla}</>}
                                {!item.hasOwnProperty("sla") && (
                                  <>Não definido</>
                                )}
                              </TableCell>
                              <TableCell>
                                {item.ultima ? "sim" : "não"}
                              </TableCell>
                              <TableCell align="center">
                                <ButtonSquare
                                  icon="edit"
                                  onClick={() => handleClick(item)}
                                />
                              </TableCell>
                            </StyledTableRow>
                          ))}
                    </TableBody>
                  </Table>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <FormModal
        data={modalData}
        onClose={() => setOpen(false)}
        onSave={handleSave}
        open={open}
        fullWidth
      />
    </>
  );
}
