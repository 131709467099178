import React, { useState, useEffect } from "react";
import moment from "moment";

const CountdownTimer = ({ endDate, getTimeElapsed = false, style= {} }) => {
  const calculateTimeRemaining = () => {
    return getTimeElapsed ? moment.duration(moment().diff(endDate)) : moment.duration(endDate.diff(moment()));
  };

  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

  useEffect(() => {
    const interval = setInterval(() => {
      const remaining = calculateTimeRemaining();
      setTimeRemaining(remaining);
    }, 1000);

    return () => clearInterval(interval);
  }, [endDate]);

  const formatTime = (time) => {
    return time < 10 ? `0${time}` : time;
  };

  const renderTimer = () => {
    const remainingSeconds = timeRemaining.asSeconds();
    if (remainingSeconds <= 0) {
      const negativeDuration = moment.duration(-remainingSeconds, "seconds");
      const days = negativeDuration.days() ?? 0;
      const sum = 24 * days + negativeDuration.hours();
      return (
        <div style={{ color: "red", fontSize: "13px", ...style }}>
          <span>-{sum.toString().padStart(2, '0')}:</span>
          <span>{formatTime(negativeDuration.minutes())}:</span>
          <span>{formatTime(negativeDuration.seconds())}</span>
        </div>
      );
    } else {
      const days = timeRemaining.days() ?? 0;
      const sum = 24 * days + timeRemaining.hours();

      return (
        <div style={{ color: "green", fontSize: "13px", ...style }}>
          <span>{sum.toString().padStart(2, '0')}:</span>
          <span>{formatTime(timeRemaining.minutes())}:</span>
          <span>{formatTime(timeRemaining.seconds())}</span>
        </div>
      );
    }
  };

  return <div>{renderTimer()}</div>;
};

/**
 * @param {Object} props
 * @param {string} props.date
 * @param {boolean} [props.getTimeElapsed]
 * @param {Object} [props.style]
 */
const App = ({ date, getTimeElapsed = false, style = {} }) => {
  const diffMilliseconds = moment(date).diff(moment());
  const diffSeconds = Math.floor(diffMilliseconds / 1000);
  const endDate = moment().add(diffSeconds, "seconds");

  return (
    <div>
      <CountdownTimer endDate={endDate} getTimeElapsed={getTimeElapsed} style={style} />
    </div>
  );
};

export default App;
