import React, { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { add, format, parseISO, isValid } from 'date-fns';
import { ValidationError } from 'yup';
import { Form } from '@unform/web';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { Content, DoubleInputs, Fieldset, Column, LesserColumn, Row } from './styles/ModalNewTask';

import DefaultModal from '../DefaultModal';
import DefaultInput from 'components/DefaultInput';
import FileDropUploader from 'components/FileDropUploader';
import CNJSearcher from 'components/CNJSearcher';

import { Creators as ProvidenciaActions } from 'store/ducks/providencia';
import { Creators as TiposTarefasActions } from 'store/ducks/tipostarefas';
import { Creators as UsuariosActions } from 'store/ducks/usuarios';

import { PRIORITIES } from '../../constants/task-events-priorities';
import REMINDERS from '../../constants/reminders';
import ProvidenciaSchema from 'validators/ProvidenciaSchema';
import { DebouncedAutoComplete } from 'components/DebouncedAutoComplete';

function ModalNewTask({ open, handleClose, selectedDocument = null }) {
  const formRef = useRef(null);
  const dispatch = useDispatch();

  const [taskType, setTaskType] = useState(null);
  const [priority, setPriority] = useState('');
  const [CNJ, setCNJ] = useState(selectedDocument);
  const [reminder, setReminder] = useState(null);
  const [participants, setParticipants] = useState([]);
  const [fullDayEvent, setFullDayEvent] = useState(false);
  const [imagens, setImagens] = useState([]);

  const { list: listTiposTarefas } = useSelector(state => state.tipostarefas);
  const { analysts } = useSelector(state => state.usuarios);
  const {
    access: { empresas },
  } = useSelector(state => state.permissoes);

  useEffect(() => {
    dispatch(
      TiposTarefasActions.listRequest({
        page: 1,
        limit: Number.MAX_SAFE_INTEGER,
        sort: 'nome',
      })
    );
    dispatch(UsuariosActions.searchByNameRequest(''));
  }, [dispatch, empresas]);

  async function handleSubmit(data) {
    try {
      const documentsToSend = selectedDocument ? (Array.isArray(selectedDocument) ? selectedDocument : CNJ._id) : null;

      console.log('documentsToSend', documentsToSend);

      const JSONformData = {
        ...data,
        tipoTarefa: taskType ? taskType._id : null,
        prioridade: priority,
        documento: documentsToSend,
        participantes: participants.map(participant => participant._id),
        diaTodo: fullDayEvent,
        dataInicio: formatToDatetime(formRef, 'dataInicio', 'hour_start'),
        vencimento: formatToDatetime(formRef, 'vencimento', 'hour_end'),
        lembrete: reminder ? reminder.fn(formatToDatetime(formRef, 'dataInicio', 'hour_start')) : null,
        participantesExternos: formatParticipantesExternos(formRef),
      };

      console.log(JSONformData);

      await ProvidenciaSchema.validate(JSONformData, {
        abortEarly: false,
      });

      const formData = createImageFormData(imagens);

      dispatch(ProvidenciaActions.createRequest(JSONformData, formData));
      handleClose();
    } catch (err) {
      const validationErrors = {};
      if (err instanceof ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });

        formRef.current.setErrors(validationErrors);
      }
    }
  }

  function createImageFormData(imagens) {
    const formData = new FormData();

    imagens.forEach(fileToUpload => {
      formData.append('imagem', fileToUpload.file);
      formData.append('nome', fileToUpload.file.name);
    });

    return formData;
  }

  function handleFormSubmit() {
    formRef.current.submitForm();
  }

  function formatParticipantesExternos(formRef) {
    const participantesExternos = formRef.current.getFieldValue('participantesExternos');

    if (participantesExternos) {
      return participantesExternos.split(';').map(email => email.trim());
    }
    return [];
  }

  function handleStartDateChange(event) {
    if (taskType && taskType.prazo) {
      calculateEndDate(formRef, taskType);
    }
  }

  function calculateEndDate(formRef, taskType) {
    const startDate = formRef.current.getFieldValue('dataInicio');

    if (isValid(parseISO(startDate))) {
      const calculatedEndDate = add(parseISO(startDate), {
        days: taskType.prazo,
      });
      formRef.current.setFieldValue('vencimento', format(calculatedEndDate, 'yyyy-MM-dd'));
    }
  }

  function formatToDatetime(formRef, dateField, hourField) {
    const date = formRef.current.getFieldValue(dateField);
    const hour = formRef.current.getFieldValue(hourField);

    if (fullDayEvent) {
      return date;
    }
    return `${date}T${hour}:00.000+00:00`;
  }

  useEffect(() => {
    if (taskType && taskType.prazo) {
      calculateEndDate(formRef, taskType);
    }
  }, [taskType]);

  function setHoursDisabled() {
    return fullDayEvent;
  }

  function handleTaskTypeChange(event, newValue) {
    setTaskType(newValue);
    if (newValue.prazo) {
      setFullDayEvent(true);
    } else {
      setFullDayEvent(false);
    }
  }

  function labelGroupByBuilder(option) {
    switch (option.nome) {
      case 'Audiência':
        return 'Audiência';
      case 'Evento':
        return 'Evento';
      default:
        return 'Tarefa';
    }
  }

  return (
    <>
      <DefaultModal
        title="Adicionar tarefas/eventos"
        handleClose={handleClose}
        confirmButtonFunction={handleFormSubmit}
        open={open}
      >
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Content>
            <Fieldset>
              <Column>
                <DefaultInput name="titulo" label="Título" required />
              </Column>
              <Column>
                <DoubleInputs>
                  <Autocomplete
                    options={listTiposTarefas}
                    getOptionLabel={option => option.nome}
                    onChange={handleTaskTypeChange}
                    getOptionSelected={(option, value) => option._id === value._id}
                    //groupBy={labelGroupByBuilder}
                    renderInput={params => (
                      <DefaultInput
                        {...params}
                        name="tipoTarefa"
                        label="Tipo"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        required
                      />
                    )}
                  />
                  <Autocomplete
                    options={Array.from(Object.values(PRIORITIES))}
                    getOptionLabel={option => option}
                    defaultValue="Alta"
                    onChange={(event, newValue) => setPriority(newValue)}
                    renderInput={params => (
                      <DefaultInput
                        {...params}
                        name="prioridade"
                        label="Prioridade"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        required
                      />
                    )}
                  />
                </DoubleInputs>
              </Column>
            </Fieldset>
            <Fieldset>
              <Row>
                <LesserColumn>
                  <DefaultInput
                    name="dataInicio"
                    label="Início"
                    onChange={handleStartDateChange}
                    variant="standard"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    required
                  />
                  <DefaultInput
                    name="vencimento"
                    disabled={taskType ? !!taskType.prazo : false}
                    label="Término"
                    variant="standard"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    required
                  />
                </LesserColumn>
                <LesserColumn>
                  <DefaultInput
                    required
                    name="hour_start"
                    label="Horário"
                    disabled={setHoursDisabled()}
                    variant="standard"
                    type="time"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <DefaultInput
                    required
                    name="hour_end"
                    disabled={setHoursDisabled()}
                    label="Horário"
                    variant="standard"
                    type="time"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </LesserColumn>
                <LesserColumn>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={fullDayEvent}
                        onChange={() => setFullDayEvent(state => !state)}
                        name="all_day"
                        color="primary"
                      />
                    }
                    label="Dia todo"
                  />
                </LesserColumn>
              </Row>
              <Column>
                <CNJSearcher
                  name="documento"
                  disabled={!!selectedDocument || (taskType && taskType.nome === 'Evento')}
                  CNJ={CNJ}
                  setCNJ={setCNJ}
                />
                <Autocomplete
                  options={REMINDERS}
                  getOptionLabel={option => option.label}
                  onChange={(event, newValue) => setReminder(newValue)}
                  getOptionSelected={(option, value) => option.label === value.label}
                  renderInput={params => <TextField {...params} label="Lembrete" size="small" variant="outlined" />}
                />
                <DefaultInput
                  name="comentario"
                  label="Observações"
                  type="time"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  multiline
                  rows={4}
                />
              </Column>
            </Fieldset>
            <Fieldset>
              <legend>Participantes</legend>
              <Column>
                <DebouncedAutoComplete
                  name="users"
                  options={() => {
                    return analysts?.results ?? [];
                  }}
                  onChange={value => {
                    setParticipants(value);
                  }}
                  loading={analysts.loading}
                  getOptionLabel={option => option.nome}
                  onDebounce={value => {
                    dispatch(UsuariosActions.searchByNameRequest(value));
                  }}
                  multiple
                  renderInput={params => (
                    <DefaultInput
                      {...params}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="participantes"
                      label="Participantes"
                      required
                    />
                  )}
                />
                {/* <Autocomplete
                  multiple
                  options={analysts?.results ?? []}
                  onChange={(event, newValue) => setParticipants(newValue)}
                  getOptionLabel={option => option.nome}
                  getOptionSelected={(option, value) =>
                    option._id === value._id
                  }
                  renderInput={params => (
                    <DefaultInput
                      {...params}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="participantes"
                      label="Participantes"
                      required
                    />
                  )}
                /> */}
              </Column>
              <Column>
                <DefaultInput
                  name="participantesExternos"
                  label="Participantes externos (separados por ponto e vírgula)"
                />
              </Column>
            </Fieldset>
            <Fieldset>
              <legend>Arquivos</legend>
              <FileDropUploader imagens={imagens} setImagens={setImagens} />
            </Fieldset>
          </Content>
        </Form>
      </DefaultModal>
    </>
  );
}

export default ModalNewTask;
