import api from 'services/api';
import { getToken } from 'services/auth';
import { call, select, put, all, takeLatest } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';
import { push } from 'connected-react-router';

import { Creators as NaturezasActions, Types } from 'store/ducks/naturezas';

export const getOrganization = state => state.usuarios.auth.organization;

export function* saveNatureza({ payload }) {
  const organization = yield select(getOrganization);
  const { _id, ...body } = payload;

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    if (_id) {
      yield call(api.put, `naturezas/${_id}`, body, headerParams);
    } else {
      yield call(api.post, 'naturezas', body, headerParams);
    }

    yield put(push('/administracao/naturezas'));
  } catch (error) {
    toastr.error('Ops', 'Não foi possível salvar a natureza.');
  }
}

export function* listNaturezas({ payload }) {
  const organization = yield select(getOrganization);

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
      params: payload,
    };

    const { data } = yield call(api.get, 'naturezas', headerParams);

    yield put(NaturezasActions.listSuccess(data.docs, data.total));
  } catch (error) {
    toastr.error('Ops', 'Não foi possível listar as naturezas.');
  }
}


export function* listNaturezasEnum() {
  const organization = yield select(getOrganization);

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization }
    };

    const { data } = yield call(api.get, 'naturezas-enum', headerParams);
    yield put(NaturezasActions.listSuccessEnum(data))

  } catch (err) {
    yield put(NaturezasActions.listErrorEnum());
    toastr.error('Ops', 'Não foi possível listar as naturezas');
  }
}

export default all([
  takeLatest(Types.LIST_REQUEST, listNaturezas),
  takeLatest(Types.SAVE_REQUEST, saveNatureza),
  takeLatest(Types.LIST_REQUEST_ENUM, listNaturezasEnum),
])