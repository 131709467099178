import React from "react";
import { Button } from "@material-ui/core";
import { ButtonSquareIcon } from "components/Icons";
import "./style.css";

function ButtonSquare({ icon, color = "primary", float = "left", ...props }) {
  return (
    <div style={{ float }}> 
      <Button
        {...props}
        variant="contained"
        color={color}
        className={`button-square ${icon}`}
        size="small"
      >
        <ButtonSquareIcon icon={icon} />
      </Button>
    </div>
  );
}

export default ButtonSquare;
