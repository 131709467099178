import React, { useState, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useDropzone } from "react-dropzone";
import { toastr } from "react-redux-toastr";
import { Row, Col } from "react-bootstrap";
import { Form } from "@unform/web";

import { Creators as ImportacaoActions } from "store/ducks/importacao";
import { Creators as LayoutActions } from "store/ducks/layout";

import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import DefaultInput from "components/DefaultInput";
import SeparadorLayout from "../Separador/index";
import Skeleton from "@material-ui/lab/Skeleton";
import ButtonMui from "@material-ui/core/Button";
import BackButton from "components/BackButton";
import FormRules from "../FormRules/index";
import GridFromTo from "../GridFromTo/index";
import Grid from "@material-ui/core/Grid";
import { OUTROS_CAMPOS } from "utils/default-another-fields";
import Content from "components/Content";

export default function EditFormLayout(params) {
  const dispatch = useDispatch();
  const formRef = useRef(null);
  const [filesToUpload] = useState();
  const { layout } = params.location.state;

  const [form, setForm] = useState(layout);

  const {
    access: { empresas },
  } = useSelector((state) => state.permissoes);

  const { listByEmpresas: tipoDocList, loading: loadingTipoDoc } = useSelector(
    (state) => state.tipoDocumentos
  );

  const tipoDocSelecionado = (tipoDocList ?? []).find(
    (tipoDoc) => tipoDoc._id === form.idTipoDocumento
  );

  const checkFile = (file) => {
    const typeSuport = ["xlsx", "xls", "csv", "txt"];
    const typeFile = file.path.split(".").pop();
    return typeSuport.includes(typeFile);
  };

  const canSave = () => {
    return form.nome !== "" && form.descricao !== "";
  };

  const onDrop = useCallback(
    (files) => {
      const filter = files.filter(checkFile);
      dispatch(ImportacaoActions.createRequestHeader(filter[0]));
    },
    [filesToUpload]
  );

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
  });

  const onSubmitLayout = () => {
    if (!form.acao) return toastr.warning("Ação é obrigatório");

    if (!form.type) return toastr.warning("Tipo é obrigatório");

    if (form.rules?.length === 0)
      return toastr.warning("Necessário configurar ao menos uma regra");

    try {
      dispatch(LayoutActions.editRequest(form));
    } catch (err) {
      return toastr.error("Ops", "Ocorreu um erro ao salvar layout");
    }
  };

  const handleChangeTipoDoc = (value) => {
    setForm({
      ...form,
      idTipoDocumento: value?._id,
    });
  };

  const handleChange = ({ target }) => {
    const { checked, value, name } = target;

    if (target?.type === "checkbox") {
      setForm({
        ...form,
        [name]: checked,
      });
    } else {
      setForm({ ...form, [name]: value });
    }
  };

  const handleCampoDestino = (newValue, index) => {
    let columns = form?.columns;
    columns[index].destino = newValue;
    columns[index].indexador = true;
    setForm({ ...form, columns: columns });
  };

  const handleRule = (rule, key, value) => {
    if (key === "nome") {
      rule[key] = value;
    } else {
      rule.destinations[key] = value;
    }

    setForm((prev) => ({
      ...form,
      rules: [
        ...(prev.rules.filter(function (x) {
          return x.hash != rule.hash;
        }) || []),
        ...[rule],
      ],
    }));
  };

  const handleCampoPadrao = (e, index) => {
    const { value } = e.target;
    let columns = form?.columns;
    columns[index].valorPadrao = value;
    setForm({ ...form, columns: columns });
  };

  const handleCampoValidar = ({ target }, index) => {
    const { checked } = target;
    let columns = form?.columns;
    columns[index].validar = checked;
    setForm({ ...form, columns: columns });
  };

  const handleRemoveCondition = (hash, hashcond) => {
    if (hash && hashcond) {
      let rule = {
        ...form.rules.find(function (rule) {
          return rule.hash === hash;
        }),
      };

      removeRule(hash);

      rule.conditions =
        rule.conditions.filter(function (x) {
          return x.hash != hashcond;
        }) || [];

      setForm((prev) => ({
        ...form,
        rules: [
          ...(prev.rules.filter(function (x) {
            return x.hash != hash;
          }) || []),
          ...[rule],
        ],
      }));
    }
  };

  const handleNewCondition = (hash) => {
    _newCondition(hash);
  };

  const handleNewRule = () => {
    _newRule();
  };

  const removeRule = (hash) => {
    if (hash) {
      setForm((prev) => ({
        ...form,
        rules: [
          ...prev.rules.filter(function (rule) {
            return rule.hash !== hash;
          }),
        ],
      }));
    }
  };

  const _newRule = () => {
    setForm((prev) => ({
      ...form,
      rules: [
        ...(prev.rules || []),
        ...[
          {
            nome: "",
            hash: Math.random(),
            conditions: [],
            destinations: {},
          },
        ],
      ],
    }));
  };

  const _newCondition = (hash) => {
    if (hash) {
      let rules = [...form.rules];
      let rule = {
        ...form.rules.find(function (rule) {
          return rule.hash === hash;
        }),
      };

      rule.conditions.push({
        hash: Math.random(),
        atributo: "",
        condicao: "",
        valor: "",
      });
      rules.push(rule);

      setForm((prev) => ({
        ...form,
        rules: [
          ...(prev.rules.filter(function (x) {
            return x.hash != hash;
          }) || []),
          ...[rule],
        ],
      }));
    }
  };

  const _updateCondition = (rule, condition) => {
    rule.conditions = rule.conditions.filter(function (x) {
      return x.hash != condition.hash;
    });
    rule.conditions.push(condition);

    setForm((prev) => ({
      ...form,
      rules: [
        ...(prev.rules.filter(function (x) {
          return x.hash != rule.hash;
        }) || []),
        ...[rule],
      ],
    }));
  };

  const handleCondition = (rule, condition, key, value) => {
    if (rule && condition && key && value) {
      condition[key] = value;
      _updateCondition(rule, condition);
    }
  };

  return (
    <Content>
      <Row>
        <Col md="12" className="painel">
          <div className="painel-content">
            <div className="painel-title text-left">Editar Layout</div>
            <div className="painel-body">
              <Form
                ref={formRef}
                onSubmit={onSubmitLayout}
                className={"mt-2"}
              >
                <SeparadorLayout titulo="Descritivo" />
                <Row>
                  <Col md={6} sm={12}>
                    <TextField
                      required
                      onChange={handleChange}
                      value={form.nome || ""}
                      autoComplete="off"
                      label="Nome"
                      name="nome"
                      variant="outlined"
                      size="small"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <TextField
                      required
                      multiline
                      onChange={handleChange}
                      value={form.descricao || ""}
                      autoComplete="off"
                      label="Descrição"
                      name="descricao"
                      variant="outlined"
                      size="small"
                      minRows={3}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    {!loadingTipoDoc ? (
                      <Autocomplete
                        onChange={(event, newValue) =>
                          handleChangeTipoDoc(newValue)
                        }
                        value={tipoDocSelecionado || null}
                        name="idTipoDocumento"
                        options={tipoDocList}
                        getOptionLabel={(option) => option.nome || null}
                        getOptionSelected={(option, value) =>
                          option._id === value?._id
                        }
                        renderInput={(params) => (
                          <DefaultInput
                            {...params}
                            name="tipoDocumento"
                            label="Tipo de Documento"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            required
                          />
                        )}
                      />
                    ) : (
                      <Skeleton width="100%" height={50} />
                    )}
                  </Col>
                </Row>
                <SeparadorLayout titulo="Parâmetros" />
                <Row>
                  <Col md={3} sm={12}>
                    <Autocomplete
                      required
                      options={["ENTRADA", "SAÍDA", "AMBOS"]}
                      clearOnBlur={false}
                      value={form.type}
                      name="type"
                      onChange={(event, newValue) => {
                        setForm({ ...form, type: newValue });
                      }}
                      getOptionSelected={(option, value) =>
                        option === value
                      }
                      size="small"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="alertType"
                          label="Tipo"
                          variant="outlined"
                        />
                      )}
                    />
                  </Col>
                  <Col md={3} sm={12}>
                    <Autocomplete
                      options={["INCLUIR", "ALTERAR", "AMBOS"]}
                      clearOnBlur={false}
                      value={form.acao}
                      name="acao"
                      onChange={(event, newValue) => {
                        setForm({ ...form, acao: newValue });
                      }}
                      getOptionSelected={(option, value) =>
                        option === value
                      }
                      size="small"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="acao"
                          label="Ação"
                          variant="outlined"
                        />
                      )}
                    />
                  </Col>
                  <Col md={3}>
                    <Autocomplete
                      options={["Propensão BACEN", ""]}
                      clearOnBlur={false}
                      name="alerta"
                      getOptionSelected={(option, value) =>
                        option === value
                      }
                      value={form.alerta}
                      onChange={(event, newValue) => {
                        setForm({ ...form, alerta: newValue });
                      }}
                      size="small"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="alertType"
                          label="Gerar Alerta"
                          variant="outlined"
                        />
                      )}
                    />
                  </Col>
                </Row>
                <SeparadorLayout titulo="Regras" />
                <Row>
                  <FormRules
                    atributes={
                      tipoDocSelecionado?.indexadores?.concat(
                        OUTROS_CAMPOS
                      ) || OUTROS_CAMPOS
                    }
                    handleRule={handleRule}
                    removeRule={removeRule}
                    handleNewRule={handleNewRule}
                    handleCondition={handleCondition}
                    handleNewCondition={handleNewCondition}
                    handleRemoveCondition={handleRemoveCondition}
                    rules={form.rules}
                  />
                </Row>
                <SeparadorLayout titulo="Arquivo" />
                <Row>
                  {form?.columns && form?.columns?.length > 0 && (
                    <GridFromTo
                      columns={form?.columns}
                      tipoDocSelecionado={tipoDocSelecionado}
                      handleCampoPadrao={handleCampoPadrao}
                      handleCampoDestino={handleCampoDestino}
                      handleCampoValidar={handleCampoValidar}
                    />
                  )}
                </Row>

                <Grid container spacing={1} className="mt-2 mb-2">
                  <Grid item>
                    <ButtonMui
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={!canSave}
                    >
                      Salvar
                    </ButtonMui>
                  </Grid>
                  <Grid item>
                    <BackButton variant="contained" color="secondary">
                      Voltar
                    </BackButton>
                  </Grid>
                </Grid>
              </Form>
            </div>
          </div>
        </Col>
      </Row>
    </Content>
  );
}
