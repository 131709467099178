import React, { useMemo } from 'react';
import { 
  Button, Dialog, DialogContent, DialogTitle, DialogActions,
  TableContainer, Table, TableHead, TableCell, TableRow,
  TableBody, Typography
} from '@material-ui/core'
import { Row, Col } from 'react-bootstrap';

import StyledTableRow from 'components/StyledTableRow';

function DadosAlterados({ ip, detalhes, onClose, ...props }) {

  const tableContent = useMemo(() => {
    const tableContent = [];
    if (detalhes.dadosAlterados) {
      Object.keys(detalhes.dadosAlterados).forEach(key => {
        tableContent.push({ 
          campo: key, 
          valorAnterior: detalhes.dadosAlterados[key].valorAnterior,
          valorNovo: detalhes.dadosAlterados[key].valorNovo
        })
      })
    }
    return tableContent;
  }, [detalhes.dadosAlterados]);

  function formatValue(value, ident=0) {
    if (Array.isArray(value)) {
      if (value.length > 0) {
        const [first] = value;
        if (typeof first === 'object') {
          return value.map(subItem => formatValue(subItem, ident));
        }
      }
      return value.join(', ')
    };
    if (typeof value === 'object') {
      return Object.keys(value).map((key, index) => 
        <React.Fragment key={index}>
          <br />{identString(ident)}{key}:&nbsp;{formatValue(value[key], ident + 1)}
        </React.Fragment>
      )
    }

    return value && value.length > 80 ? `${value.substring(0, 80)}...` : value || '';
  }

  function identString(ident) {
    const idents = []
    for (let index = 0; index < ident; index++) {
      idents.push(index);
    }
    return idents.map(item => <React.Fragment key={item}>&nbsp;&nbsp;</React.Fragment>)
  }
  
  return (
    <Dialog 
      onClose={onClose}
      maxWidth="md"
      fullWidth 
      {...props}
    >
      <DialogTitle>LOGS DE ALTERAÇÃO</DialogTitle>
      <DialogContent>
        <Row>
          <Col>
            <Typography color="textPrimary">IP</Typography>
            <Typography color="textSecondary">{ip}</Typography>
          </Col>
          <Col>
            <Typography color="textPrimary">Origem</Typography>
            <Typography color="textSecondary">{detalhes.rota}</Typography>
          </Col>
        </Row>
        {tableContent && tableContent.length > 0 && 
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Campo</TableCell>
                <TableCell>Valor anterior</TableCell>
                <TableCell>Valor novo</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableContent.map((row, index) => 
                <StyledTableRow key={index}>
                  <TableCell>{row.campo}</TableCell>
                  <TableCell>{formatValue(row.valorAnterior)}</TableCell>
                  <TableCell>{formatValue(row.valorNovo)}</TableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="contained" color="primary">Fechar</Button>
      </DialogActions>
    </Dialog>
  );
}

export default DadosAlterados;