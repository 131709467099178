import React, { useState, useEffect, useCallback, useRef } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import ToolTipComponent from "components/Tooltip";
import Button from "@material-ui/core/Button";
import { useSelector, useDispatch } from "react-redux";

import useStyles from "../FormEnviarEmail/styles";
import Skeleton from "@material-ui/lab/Skeleton";
import Alert from "@material-ui/lab/Alert";
import Utils from "utils/utils";
import clsx from "clsx";

import { EditorState } from "draft-js";

import { Creators as MailActions } from "store/ducks/mail";
import { Creators as TemplateActions } from "store/ducks/template";
import { Creators as GrupoEmailActions } from "store/ducks/grupoemail";
import { Creators as DepartamentosActions } from "store/ducks/departamentos";
import DropZone from "components/Dropzone";
import fileToBase64 from "services/fileToBase64";
import { getUploadAndPresignedUrl } from "services/uploadAndPresignedUrl";
import { TextEditorWithTemplate } from "components/TextEditorWithTemplate";
import { Acordeon } from "components/Acordeon";
import { DisplayConteudo } from "components/EmailsList";

const util = new Utils();

function FormEnviarEmail({ documento, email, children, aoEnviar = () => {} }) {
  const [emailCarregado] = useState(email);

  //#region estados auxiliares
  const [selectedModelType, setSelectedModelType] = useState("");
  const [emailsCarregados, setEmailsCarregados] = useState([]);
  const [conteudoTmp, setConteudoTmp] = useState(null);
  const [emailsTmp, setEmailsTmp] = useState([]);
  const [deptoTmp, setDeptoTmp] = useState();
  const [grupoTmp, setGrupoTmp] = useState();
  const [ccTmp, setCCTmp] = useState([]);

  const ref = useRef();
  //#endregion

  const INITIAL_FORM = {
    assunto: "",
    texto: "",
    documento: documento?._id || "",
    protocolo: documento?.numeroCNJ || "",
    canal:
      documento?.indexadores?.find((e) => e.id_es === "canal")?.valor || "",
    processInstanceId: "",
    emails: [],
    cc: [],
    attachments: [],
    departamento: null,
    grupoemail: null,
    enviarEmailOperador: false,
  };

  const { loading } = useSelector((state) => state.comentarios);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [form, setForm] = useState(INITIAL_FORM);
  const [sending, setSending] = useState(false);
  const [mailWithDepartament, setMailWithDepartament] = useState(false);

  const { template: templateList, loading: loadingTemplate } = useSelector(
    (state) => state.template
  );

  const escalonamento = form.departamento?.escalonamento?.grupoEmails?.find(
    (x) => x.nivel === 1
  );

  const { loading: loadingGrupoEmail, list: grupoemail } = useSelector(
    (state) => state.grupoemail
  );

  const { list: departamentosList, loading: loadingDepartamentos } =
    useSelector((state) => state.departamentos);

  const classes = useStyles();
  const dispatch = useDispatch();

  const handleSelectTemplate = (value) => {
    setForm({
      ...form,
      texto: value,
    });
  };

  function emailsFilter(grupo) {
    return grupo ? grupo?.emails : [];
  }

  function groupFilter() {
    return grupoemail?.filter((x) => x?._id === escalonamento?._id);
  }

  function handleChangeDepartamento(valor) {
    setForm({ ...form, departamento: valor, emails: [], grupoemail: null });
    setGrupoTmp(null);
    setEmailsTmp([]);
  }

  function handleChangeAutocomplete(name, value) {
    switch (name) {
      case "emails":
        setEmailsTmp(value);
        break;

      case "cc":
        setCCTmp(value);
        break;

      default:
        setForm({ ...form, [name]: value });
        break;
    }
  }

  useEffect(() => {
    const filter = (email) => email.length > 0;
    const map = (email) => email;

    const grupoResult = groupFilter();
    const grupoemail = grupoResult ? grupoResult[0] : null;
    const emails = emailsFilter(grupoemail);

    const emailsSelecionados = Array.from(
      new Set(
        form.emails.filter(filter).map(map),
        emails.filter(filter).map(map)
      )
    );

    setForm({ ...form, grupoemail: grupoemail });
    setEmailsTmp(emailsSelecionados);
  }, [form.departamento, form.mailWithDepartament]);

  useEffect(() => {
    setForm(INITIAL_FORM);

    if (emailCarregado) {
      const {
        tipo,
        remetente = "",
        destinatarios = "",
        cc = "",
        departamento = "",
        grupoEmail = "",
        conteudo: texto = "",
      } = emailCarregado;

      const emails = tipo === "ENVIO" ? destinatarios : remetente;

      setDeptoTmp(departamento);
      setGrupoTmp(grupoEmail);
      setEmailsCarregados(emails);
      setCCTmp(cc);
      setConteudoTmp(texto);
    }

    dispatch(TemplateActions.getTemplate({}));
    dispatch(DepartamentosActions.listRequest());
    dispatch(
      GrupoEmailActions.listRequest({
        page: 1,
        limit: 10000,
      })
    );
  }, [loading]);

  function canSubmit() {
    return (
      !sending &&
      form.texto !== "" &&
      form.emails?.length > 0 &&
      (form.departamento ? form.grupoemail : true)
    );
  }

  function clearForm() {
    setForm(INITIAL_FORM);
    ref.current?.clearForm();
  }

  function handleGrupoEmails(grupo) {
    const emails = emailsFilter(grupo);
    setForm({ ...form, grupoemail: grupo });
    setEmailsTmp(emails);
  }

  async function handleSubmit() {
    setSending(true);

    const attachments = form.attachments;

    delete form.attachments;

    const arquivosPromisses = attachments.map((file) => fileToBase64(file));

    const arquivosPrometidos = await Promise.all(arquivosPromisses);

    const arquivosAssinados = arquivosPrometidos.map(async (file) => {
      const filepath = await getUploadAndPresignedUrl(file);
      return { path: filepath };
    });

    const arquivos = await Promise.all(arquivosAssinados);

    const textoAgrupado = "".concat(
      form.texto,
      conteudoTmp
        ? `<br>________________________________<br>${conteudoTmp}`
        : ""
    );

    const formToSend = {
      ...form,
      texto: textoAgrupado,
      attachments: arquivos,
    };

    dispatch(MailActions.createRequest(formToSend));
    if (aoEnviar) aoEnviar();
    clearForm();

    setSending(false);
  }

  const onDrop = useCallback(
    (files) => {
      let attachments = [];
      const { path, type, size } = files[0];
      const allow = size < 20000000;
      if (!allow)
        return;
      const file = form.attachments.find(
        (x) => x.path === path && x.type === type
      );
      if (!file) attachments = [...form.attachments, ...files];
      else attachments = [...form.attachments];
      setForm({ ...form, attachments: attachments });
    },
    [form]
  );

  const onDeleteFile = (file) => {
    setForm({
      ...form,
      attachments: form?.attachments.filter((item) => item !== file),
    });
  };

  //#region Estados auxiliares
  useEffect(() => {
    const depto = departamentosList?.find(
      (departamento) => departamento._id.toString() == deptoTmp
    );
    if (depto) handleChangeDepartamento(depto);
  }, [loadingDepartamentos, deptoTmp]);

  useEffect(() => {
    const grupo = grupoemail?.find(
      (grupo) => grupo._id.toString() === grupoTmp
    );
    if (grupo) handleGrupoEmails(grupo);
  }, [loadingGrupoEmail, grupoTmp]);

  useEffect(() => {
    if (!emailsTmp) return;
    const listaEmails = "".concat(emailsCarregados, ",", emailsTmp);
    const emails = util.handlePastedEmails(listaEmails);
    setForm({ ...form, emails });
  }, [emailsTmp]);

  useEffect(() => {
    const cc = util.handlePastedEmails(ccTmp);
    setForm({ ...form, cc });
  }, [ccTmp]);
  //#endregion

  return (
    <div style={{ marginTop: "1rem" }}>
      <FormControlLabel
        control={
          <Checkbox
            disableRipple
            size="small"
            color="primary"
            name="addMailOperator"
            checked={form.enviarEmailOperador}
            onChange={(event, newValue) => {
              setForm({ ...form, enviarEmailOperador: newValue });
            }}
          />
        }
        label="Incluir meu e-mail como destinatário"
      />

      <FormControlLabel
        control={
          <Checkbox
            disableRipple
            checked={form.mailWithDepartament}
            name="enviarEmail"
            onChange={(event, newValue) => {
              setForm({
                ...form,
                departamento: null,
                emails: [],
                grupoemail: null,
              });
              setGrupoTmp(null);
              setEmailsTmp([]);
              setMailWithDepartament(newValue);
            }}
            size="small"
            color="primary"
          />
        }
        label="E-mail com escalonamento automático"
      />

      {mailWithDepartament && (
        <>
          {loadingDepartamentos ? (
            <Skeleton width="100%" height={50} />
          ) : (
            <Autocomplete
              options={departamentosList || []}
              onChange={(event, newValue) => handleChangeDepartamento(newValue)}
              getOptionLabel={(option) => option.nome}
              getOptionSelected={(option, value) => option?._id === value?._id}
              name="departamento"
              value={form.departamento}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Departamento"
                  size="small"
                  variant="outlined"
                  className="mb-4"
                />
              )}
            />
          )}
        </>
      )}

      {form?.departamento && groupFilter()?.length > 0 && (
        <Alert severity="warning" className="mb-3">
          E-mail de escalonamento automático conforme configuração do
          departamento
        </Alert>
      )}

      <Autocomplete
        options={form?.departamento ? groupFilter() || [] : grupoemail || []}
        onChange={(event, newValue) => handleGrupoEmails(newValue)}
        getOptionLabel={(option) => option.nome}
        getOptionSelected={(option, value) => option?._id === value?._id}
        value={form.grupoemail}
        name="grupoemail"
        renderInput={(params) => (
          <TextField
            {...params}
            label="Grupo de email"
            size="small"
            variant="outlined"
            className="mb-4"
          />
        )}
      />

      <ToolTipComponent
        title={
          "Se o campo do assunto não for preenchido, o sistema o completará automaticamente, de qualquer forma, o protocolo sempre será incluído ao final do assunto."
        }
        placement="top"
      >
        <TextField
          size="small"
          variant="outlined"
          name="assunto"
          id="assunto_personalizado"
          label="Assunto personalizado"
          className={clsx(classes.margin, classes.textField)}
          onChange={(e) =>
            setForm({
              ...form,
              assunto: e.target.value,
            })
          }
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                - {documento?.numeroCNJ}
              </InputAdornment>
            ),
          }}
        />
      </ToolTipComponent>

      <Autocomplete
        freeSolo
        multiple
        selectOnFocus
        clearOnBlur
        id="options-combo"
        name="email"
        className="mt-4"
        value={form.emails}
        style={{ marginBottom: "8px" }}
        onChange={(e, value) => handleChangeAutocomplete("emails", value)}
        options={[]}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Adicione destinatários"
            variant="outlined"
            name="opcoes"
          />
        )}
        getOptionLabel={(option) =>
          typeof option === "string" ? option : option.inputValue
        }
        filterOptions={(options, params) => [
          {
            inputValue: params.inputValue,
            title: `Novo e-mail "${params.inputValue}"`,
          },
        ]}
        renderOption={(option) =>
          typeof option === "string" ? option : option.title
        }
        size="small"
      />

      <div className={classes.inputMargin}>
        <ToolTipComponent title={"Com cópia para"} placement="top">
          <Autocomplete
            freeSolo
            multiple
            selectOnFocus
            clearOnBlur
            id="options-combo"
            name="cc"
            value={form.cc}
            style={{ marginBottom: "8px" }}
            onChange={(e, value) => handleChangeAutocomplete("cc", value)}
            options={[]}
            renderInput={(params) => (
              <TextField
                {...params}
                label="CC"
                variant="outlined"
                name="opcoes"
              />
            )}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.inputValue
            }
            filterOptions={(options, params) => [
              {
                inputValue: params.inputValue,
                title: `Novo e-mail "${params.inputValue}"`,
              },
            ]}
            renderOption={(option) =>
              typeof option === "string" ? option : option.title
            }
            size="small"
          />
        </ToolTipComponent>
      </div>

      <TextEditorWithTemplate
        size={"sm"}
        documento={documento}
        onSelectTemplate={(texto) => handleSelectTemplate(texto)}
        ref={ref}
        onTextChange={(texto) =>
          setForm({
            ...form,
            texto,
          })
        }
      />

      {conteudoTmp && (
        <Acordeon
          titleOpen={"Exibir conteúdo do email relacionado"}
          titleClose={"Ocultar conteúdo do email relacionado"}
        >
          <DisplayConteudo
            width="100%"
            height={210}
            scroll={true}
            frameBorder={2}
            conteudo={conteudoTmp.replace(/href=/g, "")}
            pointerEvents={"unset"}
          />
        </Acordeon>
      )}

      <DropZone
        onDrop={onDrop}
        onDeleteFile={onDeleteFile}
        files={form.attachments}
        message="Arraste e solte os arquivos aqui para anexar ao e-mail"
        warning=""
        style={{
          marginLeft: "2px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignmentItems: "center",
          width: "100%",
        }}
      />
      <div className={classes.actions}>
        <Button
          color="primary"
          variant="contained"
          className={classes.newCommentButton}
          disabled={!canSubmit()}
          onClick={handleSubmit}
          children={sending ? "Enviando..." : "Enviar"}
        />
        {children}
      </div>
    </div>
  );
}

export default FormEnviarEmail;
