import React from "react";
import { Col, Row } from "react-bootstrap";
import { TreeView } from "@material-ui/lab";
import { Acordeon } from "components/Acordeon";
import { ArrowDownIcon, ArrowRightIcon, ICONS_CUSTOM_FIELDS } from "components/Icons";
import StyledTreeItem from "./StyledTreeItem";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const OUTROS_INDEXADORES = "Outros indexadores";


export function Indexadores({
    separadores,
    indexadores,
    treeViewRef,
    hasDocument,
    onClick,
    onClickRemove,
    onClickEdit,
    onAddFieldClick,
    onReorder,
}) {
    
    const onDragEnd = (result) => {
        onReorder(result);
    };

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <TreeView
                ref={treeViewRef}
                defaultCollapseIcon={<ArrowDownIcon />}
                defaultExpandIcon={<ArrowRightIcon />}
            >
                {separadores &&
                    separadores
                        .filter((separador) =>
                            indexadores.some(
                                (indexador) => indexador.separador === separador
                            )
                        )
                        .map((separador) => (
                            <Acordeon
                                key={separador}
                                titleOpen={separador}
                                titleClose={separador}
                            >
                                <Droppable droppableId={separador}>
                                    {(provided) => (
                                        <div
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                        >
                                            {indexadores
                                                .filter(
                                                    (indexador) =>
                                                        indexador.separador === separador
                                                )
                                                .map((indexador, index) => (
                                                    <Draggable
                                                        key={indexador.index}
                                                        draggableId={indexador.index.toString()}
                                                        index={index}
                                                    >
                                                        {(provided) => (
                                                            <Row
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                            >
                                                                <Col md={12}>
                                                                    <StyledTreeItem
                                                                        key={indexador.index}
                                                                        indexador={indexador}
                                                                        nodeId={`${indexador.index}`}
                                                                        labelText={indexador.nome}
                                                                        labelIcon={
                                                                            ICONS_CUSTOM_FIELDS[indexador.tipo]
                                                                        }
                                                                        addFields={
                                                                            indexador.tipo === "tabela"
                                                                        }
                                                                        onClick={() =>
                                                                            onClick(indexador, null)
                                                                        }
                                                                        onClickRemove={() =>
                                                                            onClickRemove(indexador)
                                                                        }
                                                                        onClickEdit={() =>
                                                                            onClickEdit(indexador)
                                                                        }
                                                                        onAddFieldClick={(e) =>
                                                                            onAddFieldClick(e, indexador)
                                                                        }
                                                                        hasDocument={hasDocument}
                                                                    >
                                                                        {indexador.campos &&
                                                                            indexador.campos.map(
                                                                                (field, index2) => (
                                                                                    <StyledTreeItem
                                                                                        key={index2}
                                                                                        nodeId={`${index + 1} - ${index2 + 1}`}
                                                                                        labelText={field.nome}
                                                                                        labelIcon={
                                                                                            ICONS_CUSTOM_FIELDS[field.tipo]
                                                                                        }
                                                                                        onClick={() =>
                                                                                            onClick(
                                                                                                indexador,
                                                                                                index2
                                                                                            )
                                                                                        }
                                                                                        onClickEdit={() =>
                                                                                            onClickEdit(
                                                                                                indexador,
                                                                                                field
                                                                                            )
                                                                                        }
                                                                                        onClickRemove={() =>
                                                                                            onClickRemove(
                                                                                                indexador,
                                                                                                field
                                                                                            )
                                                                                        }
                                                                                        hasDocument={hasDocument}
                                                                                    />
                                                                                )
                                                                            )}
                                                                    </StyledTreeItem>
                                                                </Col>
                                                            </Row>
                                                        )}
                                                    </Draggable>
                                                ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </Acordeon>
                        ))}

                <Acordeon
                    key={OUTROS_INDEXADORES}
                    titleOpen={OUTROS_INDEXADORES}
                    titleClose={OUTROS_INDEXADORES}
                >
                    <Droppable droppableId={OUTROS_INDEXADORES}>
                        {(provided) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                {indexadores &&
                                    indexadores
                                        .filter(
                                            (indexador) =>
                                                !indexador.separador ||
                                                indexador.separador === OUTROS_INDEXADORES
                                        )
                                        .map((indexador, index) => (
                                            <Draggable
                                                key={indexador.index}
                                                draggableId={indexador.index.toString()}
                                                index={index}
                                            >
                                                {(provided) => (
                                                    <Row
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                    >
                                                        <Col md={12}>
                                                            <StyledTreeItem
                                                                key={indexador.index}
                                                                indexador={indexador}
                                                                nodeId={`${indexador.index}`}
                                                                labelText={indexador.nome}
                                                                labelIcon={
                                                                    ICONS_CUSTOM_FIELDS[indexador.tipo]
                                                                }
                                                                onClick={() => onClick(indexador)}
                                                                addFields={
                                                                    indexador.tipo === "tabela"
                                                                }
                                                                onClickRemove={() =>
                                                                    onClickRemove(indexador)
                                                                }
                                                                onClickEdit={() =>
                                                                    onClickEdit(indexador)
                                                                }
                                                                onAddFieldClick={(e) =>
                                                                    onAddFieldClick(e, indexador)
                                                                }
                                                                hasDocument={hasDocument}
                                                            >
                                                                {indexador.campos &&
                                                                    indexador.campos.map(
                                                                        (field, index2) => (
                                                                            <StyledTreeItem
                                                                                key={index2}
                                                                                nodeId={`${index + 1} - ${index2 + 1}`}
                                                                                labelText={field.nome}
                                                                                labelIcon={
                                                                                    ICONS_CUSTOM_FIELDS[field.tipo]
                                                                                }
                                                                                onClick={() =>
                                                                                    onClick(
                                                                                        indexador,
                                                                                        index2
                                                                                    )
                                                                                }
                                                                                onClickEdit={() =>
                                                                                    onClickEdit(
                                                                                        indexador,
                                                                                        field
                                                                                    )
                                                                                }
                                                                                onClickRemove={() =>
                                                                                    onClickRemove(
                                                                                        indexador,
                                                                                        field
                                                                                    )
                                                                                }
                                                                                hasDocument={hasDocument}
                                                                            />
                                                                        )
                                                                    )}
                                                            </StyledTreeItem>
                                                        </Col>
                                                    </Row>
                                                )}
                                            </Draggable>
                                        ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </Acordeon>
            </TreeView>
        </DragDropContext>
        
    );
}

export default Indexadores;
