import api from "../../services/api";
import { getToken } from "../../services/auth";
import { put, call, all, takeLatest } from "redux-saga/effects";
import { toastr } from "react-redux-toastr";
import { select } from "redux-saga/effects";

import {
  Creators as PesosProdutividadeActions,
  Types,
} from "../ducks/pesosprodutividade";

export const getOrganization = (state) => state.usuarios.auth.organization;

export function* listPesosProdutividade() {
  try {
    const organization = yield select(getOrganization);

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const { data } = yield call(api.get, "pesos/produtividade", headerParams);

    yield put(PesosProdutividadeActions.listSuccess(data));
  } catch (error) {
    toastr.error("Ops", "Não foi possível os pesos de produtividade.");
  }
}

export function* createPesosProdutividade({ payload }) {
  const organization = yield select(getOrganization);

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const { data } = yield call(
      api.post,
      `pesos/produtividade`,
      payload,
      headerParams
    );

    yield put(PesosProdutividadeActions.createSuccess(data));

    toastr.success("", "Pesos de produtividade criados com sucesso.");
  } catch (error) {
    toastr.error("Ops", "Não foi possível criar pesos.");
  }
}

export default all([
  takeLatest(Types.NEW_REQUEST, createPesosProdutividade),
  takeLatest(Types.LIST_REQUEST, listPesosProdutividade),
]);
