import { CALENDAR_EXIBITION_TYPES } from 'constants/calendar-exibition-types';

export const Types = {
    CHANGE_DATE: '@agenda/CHANGE_DATE',
    CHANGE_BIG_CALENDAR_EXIBITION_MODE: '@agenda/CHANGE_BIG_CALENDAR_EXIBITION_MODE',
    GET_DATA_REQUEST: '@agenda/GET_DATA_REQUEST',
    GET_DATA_SUCCESS: '@agenda/GET_DATA_SUCCESS',
    OPEN_MODAL_TASK_DETAILS: '@agenda/OPEN_MODAL_TASK_DETAILS',
    CLOSE_MODAL_TASK_DETAILS: '@agenda/CLOSE_MODAL_TASK_DETAILS',
}
  
const INITIAL_STATE = {
    selectedDate: new Date(),
    data: {},
    loading: false,
    selectedEvent: null,
    openModalTaskDetails: false,
    bigCalendarExibitionMode: CALENDAR_EXIBITION_TYPES.MONTH,
}
  
export default function agenda(state = INITIAL_STATE, action) {
    switch (action.type) {
        case Types.CHANGE_DATE:
            return {
                ...state,
                selectedDate: action.payload,
            }

        case Types.GET_DATA_REQUEST:
            return {
                ...state,
                loading: true,
            }

        case Types.GET_DATA_SUCCESS:
            return {
                ...state,
                data: action.payload,
                loading: false,
            }

        case Types.OPEN_MODAL_TASK_DETAILS:
            return {
                ...state,
                selectedEvent: action.payload,
                openModalTaskDetails: true,
            }

        case Types.CLOSE_MODAL_TASK_DETAILS:
            return {
                ...state,
                openModalTaskDetails: false,
            } 
            
        case Types.CHANGE_BIG_CALENDAR_EXIBITION_MODE:
            return {
                ...state,
                bigCalendarExibitionMode: action.payload,
            }

        default:
            return state;
    }
}
  
export const Creators = {
    changeDate: newDate => ({
        type: Types.CHANGE_DATE,
        payload: newDate
    }),
    fetchDataRequest: () => ({
        type: Types.GET_DATA_REQUEST,
    }),
    fetchDataSuccess: data => ({
        type: Types.GET_DATA_SUCCESS,
        payload: data,
    }),
    openModalTaskDetails: event => ({
        type: Types.OPEN_MODAL_TASK_DETAILS,
        payload: event,
    }),
    closeModalTaskDetails: () => ({
        type: Types.CLOSE_MODAL_TASK_DETAILS,
    }),
    changeBigCalendarExibitionMode: exibitionType => ({
        type: Types.CHANGE_BIG_CALENDAR_EXIBITION_MODE,
        payload: exibitionType,
    }),
}