import { call, put, select } from 'redux-saga/effects';
import { toastr } from "react-redux-toastr";
import { push } from "connected-react-router";

import api from "../../services/api";
import { getToken } from "../../services/auth";

import { Creators as RelacionamentosActions } from "../ducks/relacionamentos";

export const getOrganization = (state) => state.usuarios.auth.organization;
export const getUserId = (state) => state.usuarios.auth.id;
export const getPermissoes = (state) => state.permissoes;
export const getUserInfo = (state) => state.usuarios;


export function* listRelacionamentos({ payload }) {

    try {
        const organization = yield select(getOrganization);

        const { relacionamentos, page, limit, sort } = payload;

        const headerParams = {
            headers: { Authorization: getToken(), xkeyorg: organization },
            params: { page, limit, sort }
        };

        const response = yield call(api.post,
            `relacionamentos`,
            relacionamentos,
            headerParams
        );

        const data = {
            total: response.data.total,
            list: response.data.docs
        }

        yield put(RelacionamentosActions.listRelacionamentoSuccess(data));

    } catch (error) {
        toastr.error("Ops", "Não foi possível listar os relacionamentos.");
    }
}

export function* createRelacionamento(action) {

    const organization = yield select(getOrganization);

    const permissoes = yield select(getPermissoes);
    const usuarios = yield select(getUserInfo);

    try {
        const headerParams = {
            headers: { Authorization: getToken(), xkeyorg: organization },
        };

        const { data } = yield call(
            api.post,
            `relacionamento`,
            action.payload,
            headerParams
        );

        const informacoesUpdate = {
            _id: usuarios.auth.id,
            relacionamentos: [...permissoes.access.relacionamentos, data._doc._id]
        };

        const relacionamentoReq = yield put(

            RelacionamentosActions.editPermissaoRelacionanentoRequest(informacoesUpdate)

        );

        permissoes.access.relacionamentos = [ ...permissoes.access.relacionamentos, data._doc._id];

        permissoes.access = { ...permissoes.access, relacionamentos: permissoes.access.relacionamentos };

        yield put(RelacionamentosActions.editPermissaoRelacionanentoSuccess(permissoes.access));

        yield put(push(`/administracao/relacionamentos`));

        yield put(RelacionamentosActions.createRelacionamentoSuccess(data));

        toastr.success("", "Relacionamento criado com sucesso");

    } catch (error) {
        toastr.error("Ops", "Não foi possível criar o Relacionamento");
    }
}

export function* editRelacionamento(action) {

    const userId = yield select(getUserId);

    try {
        const organization = yield select(getOrganization);

        const headerParams = {
            headers: { Authorization: getToken(), xkeyorg: organization },
        };

        const body = { ...action.payload, userAcao: userId };

        const { data } = yield call(
            api.put,
            `relacionamento/${action.payload._id}`,
            body,
            headerParams
        );

        yield put(push(`/administracao/relacionamentos`));

        yield put(RelacionamentosActions.editRelacionamentoSuccess(data));

        toastr.success("Relacionamento editado com sucesso.");
    } catch (error) {
        toastr.error("Ops", "Não foi possível editar o relacionamento.");
    }
}

export function* removeRelacionamento(action) {

    try {

        const organization = yield select(getOrganization);

        const headerParams = {
            headers: { Authorization: getToken(), xkeyorg: organization },
        };

        const returnDelete = yield call(api.delete, `relacionamento/${action.payload}`, headerParams);

        if (returnDelete.status === 202) {
            return toastr.error("Ops", returnDelete.data.message);
        };

        yield put(push(`/administracao/relacionamentos`));

        yield put(RelacionamentosActions.removeRelacionamentoSuccess());

        toastr.success("Relacionamento removido com sucesso.");

    } catch (error) {

        toastr.error("Ops", "Não foi possível excluir o Relacionamento");
    }
}

export function* editRelacionamentoPermissionUser(action) {
    const organization = yield select(getOrganization);

    try {
        const headerParams = {
            headers: { Authorization: getToken(), xkeyorg: organization },
        };

        yield call(api.post, "/relacionamento/updateacesso", action.payload, headerParams);
    } catch (error) {
        toastr.error(
            "Ops",
            "Não foi possível inserir o relacionamento no usuário logado."
        );
    }
}