import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import ModalSendEmail from '../../components/ModalSendEmail';
import ModalMerge from '../../components/ModalMerge';
import ModalUploadImagens from '../../components/ModalUploadImagens/indexPart';
import ThumbnailGallery from './ThumbnailGallery';
import RecaptchaDownload from './RecaptchaDownload';
import ModalWaitCheckTransfer from './ModalWaitCheckTransfer';
import RenderInWindow from '../RenderInWindow';
import ControlPanel from './ControlPanel';

// import checkTipoImagemToTransfer from "services/checkTipoImageToTransfer";
import { getPresignedUrl } from '../../services/presignedUrl';
import { getB64Image } from '../../services/getB64Image';
import Viewer from './Viewer';

import { Creators as DocumentosActions } from '../../store/ducks/documentos';
import { Creators as TiposImagemActions } from '../../store/ducks/tipoimagem';
import Utils from 'utils/utils';

import 'react-lazy-load-image-component/src/effects/blur.css';
import './styles.css';
import Filters from './Filters';

const util = new Utils();
export const TERMO_DE = '_de';
export const TERMO_ATE = '_ate';

export default function SearchViewer({
  colSize,
  haveToRecharge,
}) {
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const tiposimagem = useSelector((state) => state.tipoImagem.list);
  const { loading, advSearch } = useSelector((state) => state.documentos);
  const {
    access: { servicos },
  } = useSelector((state) => state.permissoes);
  const { documento: selectedDoc } = useSelector((state) => state.documentos);

  const [fileObject, setFileObject] = useState();
  const [fileObjectIndex, setFileObjectIndex] = useState(0);
  const [fileObjectArray, setFileObjectArray] = useState([]);
  const [copyFileObjectArray, setCopyFileObjectArray] = useState([]);
  const [fileSelected, setFileSelected] = useState(null);
  const [imageOrderReversed, setImageOrderReversed] = useState(false);

  const [tpimgOptions, settpimgOptions] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [showViewer, setShowViewer] = useState(false);

  const [textDocs, setTextDocs] = useState([]);
  const [showModalMerge, setShowModalMerge] = useState(false);
  const [showModalSendEmail, setShowModalSendEmail] = useState(false);
  const [showModalUploadImagens, setShowModalUploadImagens] = useState(false);
  const [openCheckTransfer, setOpenCheckTransfer] = useState(false);
  const [openDownload, setOpenDownload] = useState(false);
  const [sizeArrayImagens, setSizeArrayImagens] = useState(0);
  const [selectedFilesSendMail, setSelectedFilesSendMail] = useState([]);
  const [openDocInNewWindow, setOpenDocInNewWindow] = useState(false);
  const [docOpenedInNewWindow, setDocOpenedInNewWindow] = useState(false);

  const { searchEsById, searchText } = advSearch;

  const imagens = selectedDoc
    ? selectedDoc.hasOwnProperty('imagens')
      ? selectedDoc.imagens
      : []
    : [];

  const userActions = useMemo(() => {
    const find = servicos.find((item) => item.route === pathname);
    if (find !== undefined) return find;
    return [];
  }, [servicos]);

  const hasActionBaixar = useMemo(
    () =>
      userActions.actions &&
      userActions.actions.some(
        (userAct) => userAct.action === 'baixar documentos'
      ),
    [userActions]
  );

  const hasActionRegistrado = useMemo(
    () =>
      userActions.actions &&
      userActions.actions.some(
        (userAct) => userAct.action === 'email registrado'
      ),
    [userActions]
  );

  const hasActionTransladar = useMemo(
    () =>
      userActions.actions &&
      userActions.actions.some((userAct) => userAct.action === 'transladar'),
    [userActions]
  );

  const hasActionUpload = useMemo(
    () =>
      userActions.actions &&
      userActions.actions.some((userAct) => userAct.action === 'upload'),
    [userActions]
  );

  const hasActionEmail = useMemo(
    () =>
      userActions.actions &&
      userActions.actions.some((userAct) => userAct.action === 'upload'),
    [userActions]
  );

  const containsTpImagem = (obj, list) => {
    return list.find((item) => item._id === obj._id);
  };

  const getDocumentImageTypes = useCallback(async () => {
    if (!fileObjectArray) {
      return;
    }

    fileObjectArray.forEach((image) => {
      for (let i = 0; i < tiposimagem.length; i++) {
        if (tiposimagem[i]._id === image.tipoImagem._id) {
          if (!containsTpImagem(tiposimagem[i], tpimgOptions)) {
            settpimgOptions([...tpimgOptions, tiposimagem[i]]);
          }
        }
      }
    });
  }, [fileObjectArray, tiposimagem, tpimgOptions]);

  const fillImageTypeName = (id) => {
    const tpImg = tiposimagem.find((tpimg) => tpimg._id === id);

    if (tpImg) {
      let nome = tpImg.shortname ? tpImg.shortname : tpImg.nome;

      if (nome.length > 18) {
        nome = nome.substring(0, 12) + '...';
      }

      return nome;
    }

    return '';
  };

  function reverseImageArray() {
    const imageArray = [...fileObjectArray];
    setFileObjectIndex(0);
    setFileObjectArray(imageArray.reverse());
    setCopyFileObjectArray(imageArray.reverse());
    setImageOrderReversed((state) => !state);
  }

  const handleStay = () => {
    setShowModalMerge(false);
    setShowModalSendEmail(false);
    setShowModalUploadImagens(false);
  };

  const setFileObjectCb = useCallback(async () => {
    if (imagens && imagens.length > 0) {
      const signedUrl = await getPresignedUrl(imagens[0].filepath);

      setFileObject(signedUrl);
      setFileObjectArray(imagens);
      setCopyFileObjectArray(imagens);

      dispatch(
        TiposImagemActions.listByTipoDocumentoRequest(
          selectedDoc.idTipoDocumento._id
            ? selectedDoc.idTipoDocumento._id
            : selectedDoc.idTipoDocumento
        )
      );
    } else {
      setFileObjectArray([]);
      setCopyFileObjectArray([]);
    }

    if (selectedDoc && selectedDoc._id && searchText !== '') {
      dispatch(DocumentosActions.searchEsByIdRequest(selectedDoc._id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, selectedDoc]);

  const getFileObject = useCallback(async () => {
    if (fileObjectArray && fileObjectArray[fileObjectIndex]) {
      const signedUrl = await getPresignedUrl(
        fileObjectArray[fileObjectIndex].filepath
      );
      setFileObject(signedUrl);
    }
  }, [fileObjectArray, fileObjectIndex]);

  const nextDocument = async (e) => {
    if (fileObjectArray.length > fileObjectIndex + 1) {
      setFileObjectIndex(fileObjectIndex + 1);
    }
  };

  const previousDocument = async (e) => {
    if (fileObjectIndex > 0) {
      setFileObjectIndex(fileObjectIndex - 1);
    }
  };

  const handleSelectedOption = async (e, value) => {
    if (!value) {
      setFileObjectArray(imagens);
      setCopyFileObjectArray(imagens);
      setFileSelected(null);
      return;
    }

    const action = (imagem) => imagem.tipoImagem._id === value._id

    const image = imagens.find(action);
    const imageIndex = imagens.findIndex(action);
    const file = imagens.filter(action);
    const signedUrl = await getPresignedUrl(image.filepath);
    setFileObjectArray(file);
    setCopyFileObjectArray(file);
    setFileSelected(value);
    setFileObjectIndex(imageIndex);
    setFileObject(signedUrl);
  };

  const handleImageTypeIndexesFilter = async (value) => {

    if (value.length === 0) return;

    const filtered = value.reduce((acc, indexador) => {

      const { id_es, tipo, valor } = indexador;
      const baseCompare = (idx, id, cb) => idx.tipo === tipo && idx.id_es === id && cb(idx);

      if (tipo === 'data') {
        if (id_es.includes(TERMO_DE)) {
          const id = id_es.replace(TERMO_DE, '');
          return acc.filter(e => e.indexadores.some(idx => baseCompare(idx, id, (i) => util.localeDateStringToDate(i.valor) >= util.localeDateStringToDate(valor))));
        }
        else if (id_es.includes(TERMO_ATE)) {
          const id = id_es.replace(TERMO_ATE, '');
          return acc.filter(e => e.indexadores.some(idx => baseCompare(idx, id, (i) => util.localeDateStringToDate(i.valor) <= util.localeDateStringToDate(valor))));
        }
      } else if (tipo === 'caracter') {
        return acc.filter(e => e.indexadores.some(idx => baseCompare(idx, id_es, (i) => i.valor.toUpperCase().includes(valor.trim().toUpperCase()))));
      } else {
        return acc.filter(e => e.indexadores.some(idx => baseCompare(idx, id_es, (i) => i.valor === valor)));
      }
    }, copyFileObjectArray);

    setFileObjectArray(filtered);
  };

  const downloadSignedUrl = (signedUrl) => {
    window.open(signedUrl);
  };

  const handleShowViewer = async (image) => {
    const signedUrl = await getPresignedUrl(image.filepath);
    const extension = Utils.getURLExtension(image.filepath);
    const tipoImagensDownload = ['pdf', 'jpeg', 'jpg', 'png', 'mp4', 'mp3', 'webm', 'ogv'];

    if (tipoImagensDownload.includes(extension)) {
      setFileObject(signedUrl);

      setTimeout(function () {
        setShowViewer(!showViewer);
      }, 500);
    } else {
      downloadSignedUrl(signedUrl);
    }
  };

  const downloadFile = (base64Data, fileType) => {
    try {
      if (typeof base64Data !== "string") {
        throw new Error("Os dados não estão no formato Base64 válido");
      }

      const blobData = base64ToBlob(base64Data);

      const url = window.URL.createObjectURL(blobData);
      const link = document.createElement('a');
      link.href = url;

      let extension = "";
      if (fileType === "pdf") {
        extension = "arquivo.pdf";
        link.setAttribute("download", extension);
      } else if (fileType === "png") {
        extension = "imagem.png";
        link.setAttribute("download", extension);
      } else if (fileType === "jpeg" || fileType === "jpg") {
        extension = "imagem.jpeg";
        link.setAttribute("download", extension);
      } else {
        throw new Error("Tipo de arquivo não suportado");
      }

      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error("Ocorreu um erro ao baixar o arquivo:", error);
    }
  };

  const base64ToBlob = (base64Data) => {
    const byteCharacters = atob(base64Data);
    const byteArrays = [];
  
    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
  
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
  
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
  
    return new Blob(byteArrays);
  };

  const handleDownload = async () => {
    try {
      let arrayB64 = await Promise.all(
        selectedFiles.map(async (file) => {
          const base64 = await getB64Image(file);
          const fileType = file.split(".").pop();

          return { base64, fileType };
        })
      );

      arrayB64.forEach(({ base64, fileType }) => {
        downloadFile(base64.data, fileType);
      });
    } catch (error) {
      console.error("Ocorreu um erro no handleDownload:", error);
    }
  };

  const handleDownloadDocs = async() => {
    dispatch(
      DocumentosActions.downloadDocsRequest({
        filepaths: selectedFiles || [],
        documentId: selectedDoc._id
      })
    );
  };

  const checkUncheckImage = (filepath) => {
    if (selectedFiles.includes(filepath)) {
      setSelectedFiles(selectedFiles.filter((file) => file !== filepath));
    } else {
      setSelectedFiles([...selectedFiles, filepath]);
    }
  };

  const checkUncheckImageSendEmail = (filepathAndTpImagem) => {
    let filepathAndTpImagemArray = [];
    let newfilePathArray = [];

    if (selectedFilesSendMail.length > 0) {
      filepathAndTpImagemArray = selectedFilesSendMail;

      const found = filepathAndTpImagemArray.find(
        (imgs) => imgs.filepath === filepathAndTpImagem.filepath
      );

      if (typeof found !== 'undefined') {
        newfilePathArray = filepathAndTpImagemArray.filter(
          (imgs) => imgs.filepath !== filepathAndTpImagem.filepath
        );

        setSelectedFilesSendMail(newfilePathArray);
      } else {
        filepathAndTpImagemArray.push(filepathAndTpImagem);
        setSelectedFilesSendMail(filepathAndTpImagemArray);
      }
    } else {
      setSelectedFilesSendMail([filepathAndTpImagem]);
    }
  };

  const checkUncheckAllFiles = () => {
    if (selectedFiles.length === imagens.length) {
      setSelectedFiles([]);

      setSelectedFilesSendMail([]);
    } else {
      const selectedFiles = imagens.map(({ filepath }) => filepath);
      setSelectedFiles(selectedFiles);

      setSelectedFilesSendMail(imagens);
    }
  };

  const findTextOnDocumento = async () => {
    const textFound = [];

    const { searchEsById, searchText } = advSearch;

    if (searchEsById && searchEsById.document_text && searchText) {
      const { document_text } = searchEsById;

      for (let text of document_text) {
        const normalizedText = text.toUpperCase().replace(/\s+/g, ' ').trim();

        const foundTextPosition = normalizedText.indexOf(
          searchText.toUpperCase()
        );

        textFound.push({
          found:
            normalizedText.toUpperCase().indexOf(searchText.toUpperCase()) > -1,
          text: `...${normalizedText.substring(
            foundTextPosition - 50,
            foundTextPosition + 50
          )}...`,
        });
      }

      setTextDocs(textFound);
    } else {
      setTextDocs([]);
    }
  };

  function handleOpenDocumentInNewWindow() {
    if (openDocInNewWindow) {
      setOpenDocInNewWindow(false);
    }
    setOpenDocInNewWindow(true);
    setDocOpenedInNewWindow(fileObject);
  }

  function handleCloseDocumentInNewWindow() {
    setOpenDocInNewWindow(false);
  }

  function handleGenerateThumbnails() {
    dispatch(DocumentosActions.generateThumbnailsRequest({documentoId:selectedDoc._id}));
  }

  // EFFECTS ----------------------------------------------------------------------------------------------------------------------------
  useEffect(() => {
    setSizeArrayImagens(selectedFiles.length);
  }, [selectedFiles]);

  useEffect(() => {
    findTextOnDocumento();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchEsById]);

  useEffect(() => {
    setFileObjectCb();
    setSelectedFiles([]);
  }, [selectedDoc, setFileObjectCb]);

  useEffect(() => {
    getDocumentImageTypes();
  }, [getDocumentImageTypes]);

  useEffect(() => {
    getFileObject();
  }, [fileObjectArray, fileObjectIndex, getFileObject]);

  useEffect(() => {
    if (haveToRecharge) {
      dispatch(DocumentosActions.getDocumentoRequest(selectedDoc._id));
    }
  }, [dispatch, haveToRecharge]);

  // ---------------------------------------------------------------------------------------------------------------------------------
  return (
    <div id="searchViewer">
      {/* TODO - Refactor this component. It receives so many props */}
      <ControlPanel
        imageOrderReversed={imageOrderReversed}
        reverseImageArray={reverseImageArray}
        selectedFiles={selectedFiles}
        sizeArrayImagens={sizeArrayImagens}
        tpimgOptions={tpimgOptions}
        tipoImagemSelect={fileSelected}
        showViewer={showViewer}
        setShowViewer={setShowViewer}
        setShowModalMerge={setShowModalMerge}
        handleSelectedOption={handleSelectedOption}
        handleImageTypeIndexesFilter={handleImageTypeIndexesFilter}
        setShowModalSendEmail={setShowModalSendEmail}
        setShowModalUploadImagens={setShowModalUploadImagens}
        handleSelectAll={checkUncheckAllFiles}
        handleDownload={handleDownloadDocs}
        handleGenerateThumbnails={handleGenerateThumbnails}
      />

      <Filters handleFilter={handleImageTypeIndexesFilter} tipoImagemSelect={fileSelected} clearType={() => {
        const copy = fileSelected;
        setFileSelected(null);
        setTimeout(() => {
          setFileSelected(copy);
        }, 0.01);
      }} />

      {imagens.length > 0 && fileObjectArray && !loading && !showViewer && (
        <Col className="search-viewer__thumbnail-gallery--container">
          <Col md={12}>
            <Row className="search-viewer__thumbnail-gallery--wrapper">
              <ThumbnailGallery
                images={fileObjectArray}
                handleShowViewer={handleShowViewer}
                textDocs={textDocs}
                colSize={colSize}
                setFileObjectIndex={setFileObjectIndex}
                checkUncheckImage={checkUncheckImage}
                selectedFiles={selectedFiles}
                checkUncheckImageSendEmail={checkUncheckImageSendEmail}
                selectedDoc={selectedDoc}
              />
            </Row>
          </Col>
        </Col>
      )}

      {imagens && imagens.length === 0 && !loading && (
        <Col className="search-viewer__thumbnail-gallery--container">
          <div className="tabLoading">
            Não há imagens disponíveis para visualização.
          </div>
        </Col>
      )}
      {loading && (
        <Col className="search-viewer__thumbnail-gallery--container">
          <div className="tabLoading">
            <CircularProgress />
          </div>
        </Col>
      )}

      {fileObjectArray && showViewer && (
        <>
          {fileObjectArray.length > 1 && (
            <div className="search-viewer__show-document--navigation">
              <Button
                variant="contained"
                color="primary"
                onClick={(e) => previousDocument(e)}
                disabled={fileObjectIndex === 0}
              >
                Documento anterior
              </Button>
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleOpenDocumentInNewWindow}
                  disabled={fileObjectArray.length === 0}
                >
                  Abrir em uma nova janela
                </Button>
              </div>
              <Button
                variant="contained"
                color="primary"
                onClick={(e) => nextDocument(e)}
                disabled={fileObjectArray.length === fileObjectIndex + 1}
              >
                Próximo documento
              </Button>
            </div>
          )}

          <div className="search-viewer__file-info">
            <span>Data da Inclusão:&nbsp; </span>
            {fileObjectArray[fileObjectIndex] &&
              fileObjectArray[fileObjectIndex].createdAt
              ? util.formatISODate(fileObjectArray[fileObjectIndex].createdAt)
              : '--/--/----'}
          </div>

          <div className="search-viewer__file-info">
            <span>Tipo de Imagem:&nbsp; </span>
            {fileObjectArray[fileObjectIndex] &&
              fileObjectArray[fileObjectIndex].tipoImagem
              ? fileObjectArray[fileObjectIndex].tipoImagem.nome.replace(
                /_/g,
                ' '
              )
              : 'Não registrado'}
          </div>

          <Viewer imagem={fileObject} />
        </>
      )}

      {showModalMerge && (
        <ModalMerge 
          handleStay={handleStay}
          selectedDoc={selectedDoc}
          showModal={showModalMerge}
          selectedFiles={selectedFiles}
          selectedFilesComplete={selectedFilesSendMail}
        />
      )}

      {showModalSendEmail && (
        <ModalSendEmail
          showModal={showModalSendEmail}
          handleStay={handleStay}
          selectedFiles={selectedFiles}
          selectedFilesSendMail={selectedFilesSendMail}
          fillImageTypeName={fillImageTypeName}
          selectedDoc={selectedDoc}
        />
      )}
      {showModalUploadImagens && (
        <ModalUploadImagens
          showModal={showModalUploadImagens}
          setShowModal={setShowModalUploadImagens}
          handleStay={handleStay}
          selectedDoc={selectedDoc}
        />
      )}

      {openCheckTransfer && <ModalWaitCheckTransfer open={openCheckTransfer} />}
      {openDownload && (
        <RecaptchaDownload
          selectedDoc={selectedDoc}
          open={openDownload}
          onClose={() => setOpenDownload(false)}
          showViewer={showViewer}
          selectedFiles={selectedFiles}
        />
      )}
      {openDocInNewWindow && (
        <RenderInWindow
          title="Visualizar documento"
          handleClose={handleCloseDocumentInNewWindow}
        >
          <Viewer imagem={docOpenedInNewWindow} />
        </RenderInWindow>
      )}
    </div>
  );
}
