import React from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

import { Row } from "react-bootstrap";

import { useDispatch, useSelector } from "react-redux";

import { parseISO, format } from "date-fns";

import { Creators as EncerramentoAction } from "store/ducks/encerramento";

import Field from "./field";
import "./styles.css";

function ViewEncerramento({ documento }) {
  const dispatch = useDispatch();

  const { openView, loading, data } = useSelector(
    (state) => state.encerramento
  );

  const MOTIVO_ENUM = {
    IMPROCEDENTE: "Improcedente",
    PROCEDENTE: "Procedente",
    PARCIAL_PROCEDENTE: "Parcial procedente",
    EXTINTO_SEM_RESOLUCAO_MERITO: "Extinto sem resolução do mérito",
    EXTINTO_COM_RESOLUCAO_MERITO: "Extinto com resolução do mérito",
    ADMINISTRATIVO_INTERNO: "Administrativo interno",
  };

  const {
    comentario,
    createdAt,
    dataDoEncerramento,
    dataDoTransito,
    motivoDoEncerramento,
  } = data;

  function handleClose() {
    if (!loading) dispatch(EncerramentoAction.closeView());
  }

  const FORMAPAG_ENUM = {
    A_VISTA: "À vista",
    PARCELADO: "Parcelado",
  };

  return (
    <Dialog open={openView} onClose={handleClose}>
      <DialogTitle>Detalhes do encerramento</DialogTitle>
      <DialogContent>
        <Row>
          <Field
            label="Data da criação"
            value={
              createdAt
                ? format(parseISO(createdAt.split(".")[0]), "dd/MM/yyyy")
                : ""
            }
          />
          <Field
            label="Data do encerramento"
            value={
              dataDoEncerramento
                ? format(
                    parseISO(dataDoEncerramento.split(".")[0]),
                    "dd/MM/yyyy"
                  )
                : ""
            }
          />
          <Field
            label="Data do trânsito"
            value={
              dataDoTransito
                ? format(parseISO(dataDoTransito.split(".")[0]), "dd/MM/yyyy")
                : ""
            }
          />

          <Field
            label="Motivo do encerramento"
            value={MOTIVO_ENUM[motivoDoEncerramento]}
          />
          <Field label="Comentário" value={comentario} />
        </Row>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={handleClose}>
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ViewEncerramento;
