import React from 'react';
import { Tooltip } from '@material-ui/core';
import { WarningIcon } from 'components/Icons';

function BootstrapTooltip(props) {
  return (
    <Tooltip
      color="green"
      arrow
      placement="top-start"
      title="Dado Pessoal"
      {...props}
    />
  );
}

export const SensibleFlag = ({
  indexador,
  sensibleHidden = false,
  children,
}) => {
  const { sensivel } = indexador;
  return !sensibleHidden && sensivel ? (
    <BootstrapTooltip>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ marginRight: 0 }}>{children}</div>
        <WarningIcon
          fontSize="small"
          style={{ marginLeft: '5px', padding: '2px' }}
        />
      </div>
    </BootstrapTooltip>
  ) : (
    <>{children}</>
  );
};

export const WrapSensibleFlag = ({ indexador, children }) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
      }}
    >
      {children}
      <SensibleFlag indexador={indexador} />
    </div>
  );
};
