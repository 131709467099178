import * as Yup from 'yup';
import Locale from './Locale'

Yup.setLocale(Locale);

export default Yup.object().shape({
  inpuText: Yup.string().required(),
  radio: Yup.string().required(),
  select: Yup.string().required(),
  cpf: Yup.string().required()
});