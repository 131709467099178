import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Row, Col } from 'react-bootstrap';

import AutocompleteForm from 'components/AutocompleteForm';
import FormularioCadastro from '../../../components/FormularioCadastro';
import InputForm from 'components/InputForm';

import { Creators as EmpresasActions } from 'store/ducks/empresas';
import { Creators as FormularioActions } from 'store/ducks/formulario';
import { Creators as DepartamentosActions } from 'store/ducks/departamentos';
import { Creators as TiposDocumentoActions } from 'store/ducks/tiposdocumento';
import { Creators as TiposImagensActions } from 'store/ducks/tipoimagem';

import {
  DradEndDropList,
  HeaderFormulario,
  Line,
  onAddNewIndexador,
  onDragEnd,
  Struct,
} from '../Util';

import { ValidationError } from 'yup';
import FormularioSchema from 'validators/FormularioSchema';
import { Scope } from '@unform/core';
import { Autocomplete } from '@material-ui/lab';
import { IconButton, TextField } from '@material-ui/core';
import PreviewFormulario from '../Preview';
import { SyncIcon } from 'components/Icons';
import ToolTipComponent from 'components/Tooltip';
import { TransferList } from 'components/TransferList';

import '../styles.css';

export default function HandleFormulario({ idFormulario = null, initialData }) {
  const dispatch = useDispatch();
  const formRef = useRef(null);

  const [sending, setSending] = useState(false);
  const [preview, setPreview] = useState(false);
  const [indexadores, setIndexadores] = useState(initialData.campos);
  const [steps, setSteps] = useState(initialData.steps);
  const [tmp, setTmp] = useState(initialData.steps);
  const [tiposDeImagem, setTiposDeImagem] = useState(initialData.tiposDeImagem);
  const [error, setError] = useState(false);

  const lista = useMemo(() => indexadores, [indexadores]);
  const etapas = useMemo(() => steps, [steps]);

  //#region states
  const {
    access: { empresas },
  } = useSelector((state) => state.permissoes);

  const { listByEmpresas: tipodocumentosList, loading: loadingIipodocumentos } =
    useSelector((state) => state.tipoDocumentos);

  const { list: departamentosList, loading: loadingDepartamentos } =
    useSelector((state) => state.departamentos);

  const { list: tipoImagens } = useSelector((state) => state.tipoImagem);
  //#endregion

  //#region funções auxiliares
  const onSubmit = async (value) => {
    setError(false);

    try {
      if (!steps.length) throw new Error();

      const payload = {
        ...value,
        campos: indexadores,
        tiposDeImagem,
        steps,
        _id: idFormulario,
      };

      await FormularioSchema.validate(payload, {
        abortEarly: false,
      });

      setSending(true);

      if (idFormulario) dispatch(FormularioActions.editRequest(payload));
      else dispatch(FormularioActions.createRequest(payload));
    } catch (err) {
      setError(true);

      const validationErrors = {};
      if (err instanceof ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });

        formRef.current.setErrors(validationErrors);
      }
    } finally {
      setSending(false);
    }
  };

  const onChangeIndexador = useCallback(
    (index, name, value) => {
      const camposEdited = indexadores.map((item, idx) =>
        idx === index ? { ...item, [name]: value } : item
      );
      setIndexadores(camposEdited);
    },
    [indexadores, setIndexadores]
  );

  function getIndexadores(idTipoDocumento) {
    const tipoDocumento = tipodocumentosList.find(
      (e) => e._id === idTipoDocumento
    );
    return tipoDocumento
      ? tipoDocumento.indexadores.map(onAddNewIndexador)
      : [];
  }

  function onSelectChange(idTipoDocumento) {
    const idxs = getIndexadores(idTipoDocumento);
    setIndexadores(idxs);
    window.scrollTo(0, 0);
  }

  function onRefresh() {
    const idTipoDocumento = formRef.current.getFieldValue('idTipoDocumento');
    const idxs = getIndexadores(idTipoDocumento);
    const filtered = idxs.filter(
      (indexador) => !indexadores.some((e) => e.id_es === indexador.id_es)
    );
    setIndexadores([...indexadores, ...filtered]);
  }

  function onPreview() {
    setPreview(!preview);
  }
  //#endregion

  //#region efeitos
  useEffect(() => {
    dispatch(EmpresasActions.listRequest());
    dispatch(DepartamentosActions.listRequest(empresas));
    dispatch(TiposDocumentoActions.listByEmpresaRequest(empresas));
    dispatch(
      TiposImagensActions.listRequest({
        limit: Number.MAX_SAFE_INTEGER,
        page: 1,
        sort: 'nome',
      })
    );
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  //#endregion

  return (
    <FormularioCadastro
      formRef={formRef}
      initialData={initialData}
      onSubmit={onSubmit}
      canSave={!sending}
      title={
        <HeaderFormulario title={'Editar Formulário'} onClick={onPreview} />
      }
    >
      <>
        {preview ? (
          <PreviewFormulario
            initialData={{
              ...formRef?.current?.getData(),
              campos: indexadores,
              steps,
              tiposDeImagem,
              _id: idFormulario,
            }}
            itsPreview={true}
            open={true}
            onClose={onPreview}
          />
        ) : (
          <div>
            <Line md={12}>
              <InputForm
                autoFocus
                label="Título do formulário"
                name="nome"
                type="text"
                InputLabelProps={{ shrink: true }}
              />
            </Line>

            <Line md={12}>
              <AutocompleteForm
                label="Tipo de documento"
                name="idTipoDocumento"
                options={tipodocumentosList}
                disabled={loadingIipodocumentos}
                onChange={(_, value) => {
                  onSelectChange(value?._id);
                }}
                getOptionLabel={(option) => option.nome}
                transformOnSelect={(option) => (option ? option?._id : null)}
              />
            </Line>

            <Line md={12}>
              <AutocompleteForm
                label="Departamento"
                name="idDepartamento"
                options={departamentosList}
                disabled={loadingDepartamentos}
                getOptionLabel={(option) => option.nome}
                transformOnSelect={(option) => (option ? option._id : null)}
              />
            </Line>

            <Line md={12}>
              <InputForm
                label="Mensagem de finalização"
                name="mensagem"
                type="text"
                multiline
                minRows={8}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Line>

            <Line md={12}>
              <hr />
            </Line>

            <Line>
              <h4>Formulário</h4>
            </Line>

            <Row>
              <Col md={12}>
                <Scope path={'steps'}>
                  <Autocomplete
                    freeSolo
                    multiple
                    name="steps"
                    clearOnBlur
                    options={steps || []}
                    size="medium"
                    selectOnFocus
                    value={tmp}
                    id="options-combo"
                    style={{ marginBottom: '8px', height: '120px' }}
                    onBlur={() => {
                      setSteps(tmp);
                    }}
                    onChange={(e, value) => setTmp(value)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Informe o nome da etapa"
                        variant="outlined"
                        name="opcoes"
                      />
                    )}
                    getOptionLabel={(option) =>
                      typeof option === 'string' ? option : option.inputValue
                    }
                    filterOptions={(options, params) => [
                      {
                        inputValue: params.inputValue,
                        title: `Nova etapa "${params.inputValue}"`,
                      },
                    ]}
                    renderOption={(option) =>
                      typeof option === 'string' ? option : option.title
                    }
                  />
                  <div
                    hidden={!error}
                    style={{ color: '#f44336', fontSize: '10.5px' }}
                  >
                    Campo obrigatório
                  </div>
                </Scope>
              </Col>
            </Row>

            <Line md={12}>
              <TransferList
                title="Tipos de imagem para upload no formulário"
                list={tipoImagens?.map((e) => ({ ...e, name: e.nome }))}
                titleList="Disponíveis:"
                value={tiposDeImagem}
                placeholder="Filtrar por tipo de imagem"
                titleValue="Requeridos no formulário:"
                onChange={(value) => setTiposDeImagem(value)}
              />
            </Line>

            <Line md={12}>
              <hr />
            </Line>

            <Line md={12}>
              <div className="wire-line">
                <h5>Campos</h5>
                <ToolTipComponent
                  title="Atualizar campos abaixo conforme o cadastro do tipo de documento"
                  placement="top"
                >
                  <IconButton
                    className="button-refresh"
                    color="primary"
                    onClick={onRefresh}
                  >
                    <SyncIcon />
                  </IconButton>
                </ToolTipComponent>
              </div>
            </Line>

            <Struct
              index={-1}
              className="title-items"
              field2={'Campo plataforma'}
              field3={'Nome no formulário'}
              field4={'Visível'}
              field5={'Etapa'}
            />

            <DradEndDropList
              options={etapas}
              indexadores={lista}
              onChange={onChangeIndexador}
              setIndexadores={setIndexadores}
              onDragEnd={(result) => onDragEnd(result, lista, setIndexadores)}
            />
          </div>
        )}
      </>
    </FormularioCadastro>
  );
}
