// Actions
export const Types = {
    TOGGLE_MENU: 'menu/TOGGLE_MENU',
};

// Initial State
const initialState = {
    isOpen: false,
};

// Reducer
export default function menuReducer(state = initialState, action) {
    switch (action.type) {
        case Types.TOGGLE_MENU:
            return {
                ...state,
                isOpen: action.payload,
            };
        default:
            return state;
    }
}

// Action Creators
export const Creators = {
    toggleMenu: (active) => {
        return { type: Types.TOGGLE_MENU, payload: active };
    },
};

/*export const Types = {
    SET_ACTIVE: "menu/SET_ACTIVE",
    SET_INACTIVE: "menu/SET_INACTIVE",
};

const INITIAL_STATE = {
    menuActive: false,
};

export default function items(state = INITIAL_STATE, action) {
    switch (action.type) {
        case Types.SET_ACTIVE:
            return { ...state, menuActive: true };

        case Types.SET_INACTIVE:
            return { ...state, menuActive: false };

        default:
            return state;
    }
}

export const Creators = {
    setActive: () => ({
        type: Types.SET_ACTIVE,
        menuActive: true,
    }),

    unsetActive: () => ({
        type: Types.SET_INACTIVE,
        menuActive: false,
    }),

};
*/