import React, { useEffect, useRef } from "react";
import "./styles.css";
import { useDispatch, useSelector } from "react-redux";
import { Creators as CheckTipoActions } from "../../../store/ducks/checktipo";
import { Form } from "@unform/web";
import { Row, Col } from "react-bootstrap";
import { ValidationError } from "yup";

import InputForm from "components/InputForm";
import Skeleton from "@material-ui/lab/Skeleton";
import AutocompleteForm from "components/AutocompleteForm";

import Grid from "@material-ui/core/Grid";
import ButtonMui from "@material-ui/core/Button";
import BackButton from "../../../components/BackButton";

import { Creators as EmpresasActions } from "store/ducks/empresas";
import { Creators as TiposDocumentoActions } from "store/ducks/tiposdocumento";

import CheckTipoSchema from "validators/CheckTipoSchema";
import Content from "components/Content";

export default function CreateCheckTipo() {
  const dispatch = useDispatch();
  const formRef = useRef();

  const {
    access: { empresas },
  } = useSelector((state) => state.permissoes);



  useEffect(() => {
    dispatch(EmpresasActions.listRequest());
  }, [dispatch]);

  useEffect(() => {
    dispatch(TiposDocumentoActions.listByEmpresaRequest(empresas));
  }, [dispatch, empresas]);

  // const empresas = useSelector((state) => state.empresas.list);

  const usuarioId = useSelector((state) => state.usuarios.auth.id);

  const { listByEmpresas: tipoDocList, loading: loadingTipoDoc } = useSelector(
    (state) => state.tipoDocumentos
  );

  const { list: empresasList, loading: loadingEmpresas } = useSelector(
    (state) => state.empresas
  );

  const handleSubmit = async (values) => {
    try {
      const body = { ...values, userAcao: usuarioId };

      await CheckTipoSchema.validate(body, {
        abortEarly: false,
      });

      dispatch(CheckTipoActions.createRequest(body));
    } catch (err) {
      const validationErrors = {};
      if (err instanceof ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  };

  return (
    <Content>
      <Row>
        <Col md="12" className="painel">
          <div className="painel-content">
            <div className="painel-title text-left">
              Cadastro de Tipo de Checklist
            </div>
            <div className="painel-body">
              <Form onSubmit={handleSubmit} ref={formRef}>
                <Row>
                  <Col md={6} sm={12}>
                    {loadingEmpresas ? (
                      <Skeleton width="100%" height={50} />
                    ) : (
                      <AutocompleteForm
                        label="Empresa"
                        name="empresa"
                        options={empresasList}
                        transformOnSelect={(option) =>
                          option ? option._id : null
                        }
                        getOptionLabel={(option) => option.fantasia}
                      />
                    )}
                  </Col>
                  <Col md={6} sm={12}>
                    {!loadingTipoDoc ? (
                      <AutocompleteForm
                        label="Tipo documento"
                        required
                        size="small"
                        options={tipoDocList}
                        getOptionLabel={(option) => option.nome}
                        transformOnSelect={(option) =>
                          option ? option._id : null
                        }
                        noOptionsText="Selecione um tipo documento"
                        getOptionSelected={(option, value) =>
                          option._id === value._id
                        }
                        name="tipodoc"
                      />
                    ) : (
                      <Skeleton width="100%" height={50} />
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <InputForm name="nome" label="Descrição" />
                  </Col>
                </Row>

                <div className="buttons">
                  <Grid container spacing={1}>
                    <Grid item>
                      <ButtonMui
                        variant="contained"
                        color="primary"
                        type="submit"
                      >
                        Criar
                      </ButtonMui>
                    </Grid>
                    <Grid item>
                      <ButtonMui
                        variant="contained"
                        color="secondary"
                        type="reset"
                      >
                        Limpar
                      </ButtonMui>
                    </Grid>
                    <Grid item>
                      <BackButton variant="contained" color="secondary">
                        Voltar
                      </BackButton>
                    </Grid>
                  </Grid>
                </div>
              </Form>
            </div>
          </div>
        </Col>
      </Row>
    </Content>
  );
}
