import React, { useEffect, useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

import { NavLink } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Button from '@material-ui/core/Button';

import { Creators as DocumentoActions } from 'store/ducks/documentos';
import { Creators as AnaliseActions } from 'store/ducks/analise';
import Content from 'components/Content';

export default function WaitingFlow() {
  const dispatch = useDispatch();

  const { doc } = useParams();

  const listaFilas = useSelector((state) => state.filas);
  const analise = useSelector((state) => state.analise);
  const { byEtapas: documentos, loading } = useSelector(
    (state) => state.documentos
  );
  const [intervalId, setIntervalId] = useState(null);
  const [timeoutReached, setTimeoutReached] = useState(false);

  useEffect(() => {
    const id = setInterval(() => {
      dispatch(
        DocumentoActions.listRequestByEtapas({
          etapas: null,
          page: 1,
          limit: 10,
          sort: null,
          values: {
            documento: doc,
          },
          fields:
            'documentoAnalise.numeroCNJ,documentoAnalise._id,usuarioAcao,etapas,ultimaEtapa,dtUltimaEtapa,sla,tarefa',
        })
      );
    }, 10000);

    const timeout = setTimeout(() => {
      clearInterval(id);
      setTimeoutReached(true);
    }, 21000);

    setIntervalId(id);

    return () => {
      clearInterval(id);
      clearTimeout(timeout);
    };
  }, [dispatch]);

  useEffect(() => {
    if (documentos && documentos.length > 0) {
      const documento = documentos[0];
      const {
        documento: documentoId,
        id,
        processInstanceId,
        name,
        processDefinitionId,
      } = documento;

      openAnalise(
        documentoId,
        id,
        processInstanceId,
        'analise',
        name,
        processDefinitionId
      );
    }
  }, [documentos]);

  const getNameFila = (fluxo, listaFilas = []) => {
    try {
      const { list } = listaFilas;
      const fluxoKey = fluxo.split(':').shift();
      const filaName = list.find((e) => e.fila === fluxoKey);
      return filaName.nome;
    } catch {
      return '';
    }
  };

  const openAnalise = (_id, taskId, processInstanceId, type, etapa, fluxo) => {
    const nomeFila = getNameFila(fluxo, listaFilas);

    dispatch(
      AnaliseActions.getRequest(
        _id,
        taskId,
        processInstanceId,
        type,
        etapa,
        nomeFila,
        fluxo
      )
    );

    dispatch(DocumentoActions.getDocumentoRequest(_id));
  };

  const buttonStyle = {
    marginLeft: '1rem',
    marginRight: '1rem',
  };

  return (
    <Content>
      <Row>
        <Col md="12" className="painel">
          <div className="painel-content">
            <Row>
              <Col sm={12} className="text-center p-3">
                <h5 className="mt-2">Etapa concluída com sucesso!</h5>
                <NavLink
                    to={{
                      pathname: '/operacao/esteira',
                      state: {
                        fluxo: analise.fila,
                      },
                    }}
                    className="text-white"
                  >
                  <Button
                    variant="contained"
                    color="primary"
                    style={buttonStyle}
                    className="mt-2 mb-2 mr-1"
                  >
                    Redirecionar para a esteira
                  </Button>
                </NavLink>
                {!timeoutReached && (
                  <Button
                    variant="contained"
                    color="primary"
                    style={buttonStyle}
                    className="mt-2 mb-2 mr-1"
                  >
                    <NavLink to="/operacao/esteira"></NavLink>
                    Prosseguindo para a próxima etapa
                    <CircularProgress
                      className="ml-2"
                      color="inherit"
                      size={12}
                    />
                  </Button>
                )}
              </Col>
            </Row>
            {!!timeoutReached && (
              <Row>
                <Col className="text-center p-3">
                  Neste momento, parece que não existem mais pendências para
                  este documento
                </Col>
              </Row>
            )}
          </div>
        </Col>
      </Row>
    </Content>
  );
}
