import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Creators as OnboardingActions } from 'store/ducks/onboarding';

import { Container, ValidationList, ValidationListContainer } from './styled';

const ValidationStep = ({ settings, nextStep, fields }) => {
  const dispatch = useDispatch();

  const [validation, setValidation] = useState(settings.validation || null);
  const [validations, setValidations] = useState({
    status: false,
    data: [],
  });

  const {
    validations: { loading, data },
  } = useSelector((state) => state.onboarding);

  const getFieldValue = (field) => {
    const f = fields.find((item) => item.id_es === field.id_es);

    return f ? f.valor : null;
  };

  useEffect(() => {
    const cpf = getFieldValue({ id_es: 'cpf' });

    if (cpf) {
      dispatch(
        OnboardingActions.validationsRequest({
          cpf,
        })
      );
    }
  }, []);

  const fillValidations = (text) => {
    setValidations((prev) => ({
      ...prev,
      data: [...prev.data, text],
    }));
  };

  useEffect(() => {
    function validate() {
      if (validation.cpf_status_validation) {
        console.log('data.taxIdStatus', data.taxIdStatus);
        if (data.taxIdStatus && data.taxIdStatus.indexOf('FALECIDO') !== -1) {
          fillValidations(
            'O CPF informado está vinculado a uma pessoa falecida'
          );
        } else if (!data.taxIdStatus) {
          fillValidations('Não foi possível validar o CPF');
        }
      }

      if (validation.email_validation) {
        const fieldValue = getFieldValue(validation.email_validation_field);

        if (data.email && fieldValue && fieldValue !== data.email) {
          fillValidations('O email não é o primário');
        } else if (!data.email || !fieldValue) {
          fillValidations('Não foi possível validar o email');
        }
      }

      if (validation.birthdate_validation) {
        const fieldValue = getFieldValue(validation.birthdate_validation_field);

        if (
          data.birthdate &&
          fieldValue &&
          fieldValue !==
            data.birthdate.split('T')[0].split('-').reverse().join('/')
        ) {
          fillValidations('A data de nascimento informada não confere');
        } else if (!data.email || !fieldValue) {
          fillValidations('Não foi possível validar a data de nascimento');
        }
      }

      setValidations((prev) => ({ ...prev, status: true }));
    }

    if (validation && !validations.status && data.name) {
      validate();
    }
  }, [data]);

  // useEffect(() => {
  //   //após 5 segundos, chama a função de callback
  //   setTimeout(() => {
  //     nextStep();
  //   }, 7000);
  // }, []);

  return (
    <Container>
      {loading ? (
        <>
          <CircularProgress size={60} />
          <h3>Aguarde enquanto validamos os dados...</h3>
        </>
      ) : (
        <>
          {validations.data.length > 0 ? (
            <ValidationListContainer>
              <h4>Ocorreram os seguintes erros de validação:</h4>
              <ValidationList>
                {validations.data.map((v) => (
                  <ul>
                    <li>- {v}</li>
                  </ul>
                ))}
              </ValidationList>
            </ValidationListContainer>
          ) : (
            <>
              <h4>Validações realizadas com sucesso!</h4>
            </>
          )}
        </>
      )}
    </Container>
  );
};

export default ValidationStep;
