import api from "services/api";
import { getToken } from "services/auth";
import { call, select, put, all, takeLatest } from "redux-saga/effects";
import { toastr } from "react-redux-toastr";

import { Creators as GrupoAcessoActions, Types } from "../ducks/grupoacesso";

export const getOrganization = (state) => state.usuarios.auth.organization;
export const getUserId = (state) => state.usuarios.auth.id;

export function* listGrupoAcesso({ payload }) {
  try {
    const organization = yield select(getOrganization);

    const { page, limit, sort } = payload;

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
      params: { page, limit, sort },
    };

    const response = yield call(api.get, `acessgroups`, headerParams);

    const data = {
      total: response.data.total,
      list: response.data.docs,
    };

    yield put(GrupoAcessoActions.listSuccess(data));
  } catch (error) {
    toastr.error("Ops", "Não foi possível listar os grupos de acesso.");
  }
}

export default all([takeLatest(Types.LIST_REQUEST, listGrupoAcesso)]);
