export const Types = {
  LIST_BY_ETAPA_REQUEST: "checktipo/LIST_BY_ETAPA_REQUEST",
  LIST_BY_ETAPA_SUCCESS: "checktipo/LIST_BY_ETAPA_SUCCESS",
  NEW_REQUEST: "checktipo/NEW_REQUEST",
  NEW_SUCCESS: "checktipo/NEW_SUCCESS",
  EDIT_REQUEST: "checktipo/EDIT_REQUEST",
  EDIT_SUCCESS: "checktipo/EDIT_SUCCESS",
  GET_REQUEST: "checktipo/GET_REQUEST",
  GET_SUCCESS: "checktipo/GET_SUCCESS",

  NEW_TITULO_REQUEST: "checktipo/NEW_TITULO_REQUEST",
  NEW_TITULO_SUCCESS: "checktipo/NEW_TITULO_SUCCESS",

  REMOVE_TITULO_REQUEST: "checktipo/REMOVE_TITULO_REQUEST",
  REMOVE_TITULO_SUCCESS: "checktipo/REMOVE_TITULO_SUCCESS",

  REMOVE_ITEM_REQUEST: "checktipo/REMOVE_ITEM_REQUEST",
  REMOVE_ITEM_SUCCESS: "checktipo/REMOVE_ITEM_SUCCESS",

  NEW_ITEM_REQUEST: "checktipo/NEW_ITEM_REQUEST",
  NEW_ITEM_SUCCESS: "checktipo/NEW_ITEM_SUCCESS",

  SEARCH_CHECK_EMPRESA_REQUEST: "checktipo/SEARCH_CHECK_EMPRESA_REQUEST",
  SEARCH_CHECK_EMPRESA_SUCCESS: "checktipo/SEARCH_CHECK_EMPRESA_SUCCESS",

  SET_CHECK_EMPRESA_REQUEST: "checktipo/SET_CHECK_EMPRESA_REQUEST",
};

/* Reducer */

const INITIAL_STATE = {
  loading: false,
  list: [],
  checksSelect: [],
  empresaSetada: [],
  page: 1,
  limit: 10,
  total: 0,
  sort: "nome",
};

export default function items(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.LIST_BY_ETAPA_REQUEST:
      return { ...state, loading: true };
    case Types.LIST_BY_ETAPA_SUCCESS:
      return {
        ...state,
        list: action.payload,
        loading: false,
      };
    case Types.NEW_REQUEST:
      return { ...state, loading: true };
    case Types.NEW_SUCCESS:
      return { ...state, loading: false };
    case Types.EDIT_REQUEST:
      return { ...state, loading: true };
    case Types.EDIT_SUCCESS:
      return { ...state, loading: false };
    case Types.GET_REQUEST:
      return { ...state, loading: true };
    case Types.GET_SUCCESS:
      return {
        ...state,
        loading: false,
        checksSelect: action.payload,
      };

    case Types.NEW_TITULO_REQUEST:
      return { ...state, loading: true };
    case Types.NEW_TITULO_SUCCESS:
      return { ...state, loading: false };

    case Types.REMOVE_TITULO_REQUEST:
      return { ...state, loading: true };
    case Types.REMOVE_TITULO_SUCCESS:
      return { ...state, loading: false };

    case Types.NEW_ITEM_REQUEST:
      return { ...state, loading: true };
    case Types.NEW_ITEM_SUCCESS:
      return { ...state, loading: false };

    case Types.SEARCH_CHECK_EMPRESA_REQUEST:
      return {
        ...state,
        loading: true,
        page: action.payload.page,
        limit: action.payload.limit,
        sort: action.payload.sort,
      };

    case Types.SEARCH_CHECK_EMPRESA_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };

    case Types.SET_CHECK_EMPRESA_REQUEST:
      return {
        ...state,
        empresaSetada: action.payload,
      };

    default:
      return state;
  }
}

export const Creators = {
  listByEtapasRequest: (idTipoDoc) => ({
    type: Types.LIST_BY_ETAPA_REQUEST,
    payload: idTipoDoc,
  }),

  listByEtapasSuccess: (checktipos) => ({
    type: Types.LIST_BY_ETAPA_SUCCESS,
    payload: checktipos,
  }),

  createRequest: (checktipo) => ({
    type: Types.NEW_REQUEST,
    payload: checktipo,
  }),

  createSuccess: () => ({
    type: Types.NEW_SUCCESS,
    payload: {},
  }),

  editRequest: (checksTipo) => ({
    type: Types.EDIT_REQUEST,
    payload: checksTipo,
  }),

  editSuccess: () => ({
    type: Types.EDIT_SUCCESS,
    payload: {},
  }),

  getRequest: (id) => ({
    type: Types.GET_REQUEST,
    payload: id,
  }),

  getSuccess: (checksTipo) => ({
    type: Types.GET_SUCCESS,
    payload: checksTipo,
  }),

  createTituloRequest: (checktitulo) => ({
    type: Types.NEW_TITULO_REQUEST,
    payload: checktitulo,
  }),

  createTituloSuccess: () => ({
    type: Types.NEW_TITULO_SUCCESS,
    payload: {},
  }),

  removeTituloRequest: (checktitulo) => ({
    type: Types.REMOVE_TITULO_REQUEST,
    payload: checktitulo,
  }),

  removeTituloSuccess: () => ({
    type: Types.REMOVE_TITULO_SUCCESS,
    payload: {},
  }),

  removeItemRequest: (checktitulo) => ({
    type: Types.REMOVE_ITEM_REQUEST,
    payload: checktitulo,
  }),

  removeItemSuccess: () => ({
    type: Types.REMOVE_ITEM_SUCCESS,
    payload: {},
  }),

  createItemRequest: (checkitem) => ({
    type: Types.NEW_ITEM_REQUEST,
    payload: checkitem,
  }),

  createItemSuccess: () => ({
    type: Types.NEW_ITEM_SUCCESS,
    payload: {},
  }),

  getCheckEmpresaRequest: (data) => ({
    type: Types.SEARCH_CHECK_EMPRESA_REQUEST,
    payload: data,
  }),

  getCheckEmpresaSuccess: (data) => ({
    type: Types.SEARCH_CHECK_EMPRESA_SUCCESS,
    payload: data,
  }),

  setCheckEmpresaRequest: (checkempresa) => ({
    type: Types.SET_CHECK_EMPRESA_REQUEST,
    payload: checkempresa,
  }),
};
