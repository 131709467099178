import api from "services/api";
import { getToken } from "services/auth";
import { call, select, put, all, takeLatest } from "redux-saga/effects";
import { toastr } from "react-redux-toastr";
import { push } from "connected-react-router";

import { Creators as CargaItensActions, Types } from "../ducks/cargaitens";

export const getOrganization = (state) => state.usuarios.auth.organization;
export const getUserId = (state) => state.usuarios.auth.id;

export function* listByCargaId({ payload }) {
  try {
    const organization = yield select(getOrganization);

    const { page, limit, sucess, id, numeroCNJ, type } = payload;

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
      params: { page, limit, sucess, numeroCNJ, type },
    };

    const response = yield call(api.get, `carga/${id}/itens`, headerParams);

    const data = { cargaitens: response.data || [] };

    yield put(CargaItensActions.listSuccess(data));
  } catch (error) {
    toastr.error("Ops", "Não foi possível listar as cargas.");
  }
}

export function* markAsRemoved({ payload }) {
  try {
    const organization = yield select(getOrganization);

    const { ids } = payload;

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const response = yield call(
      api.patch,
      `cargaitens`,
      {
        ids: ids,
      },
      headerParams
    );

    const data = { cargaitens: response.data || [] };

    yield put(CargaItensActions.markAsRemovedSuccess(data));

    toastr.success("", "Exclusão realizada com sucesso");
    yield put(push(`/cargas`));
  } catch (error) {
    toastr.error("Ops", "Não foi possível marcar o item como removido.");
  }
}

export default all([
  takeLatest(Types.LIST_BY_CARGAID_REQUEST, listByCargaId),
  takeLatest(Types.MARK_AS_REMOVED_REQUEST, markAsRemoved),
]);
