import React, { useRef, useEffect, useState } from "react";
import { useField } from "@unform/core";
import TextField from "@material-ui/core/TextField";
import { toastr } from "react-redux-toastr";

function InputForm({
  name,
  InputLabelProps = {},
  multiline = false,
  minRows = 1,
  onChange,
  type = "text",
  ...rest
}) {
  const inputRef = useRef(null);

  const { fieldName, defaultValue, registerField, error } = useField(name);

  const [shrink, setShrink] = useState(
    InputLabelProps.shrink || !!defaultValue || false
  );

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "value",
      setValue: (ref, value) => {
        setShrink(!!value || InputLabelProps.shrink);
        ref.value = value ? value : null;
      },
    });
  }, [InputLabelProps.shrink, fieldName, registerField]);

  function handleChange(e) {
    const { value } = e.target;
    setShrink(!!value || InputLabelProps.shrink);
    if (onChange) onChange(e);
  }

  function handleDoubleClick(e) {
    if (!e.target.value) return;
    toastr.info("", "Input copiado em segundo plano");
  }

  return (
    <TextField
      multiline={multiline}
      rows={minRows}
      error={error && true}
      inputRef={inputRef}
      helperText={error}
      variant="outlined"
      type={type}
      margin="dense"
      defaultValue={defaultValue}
      onChange={handleChange}
      {...rest}
      InputLabelProps={{
        ...InputLabelProps,
        shrink,
      }}
      onDoubleClick={handleDoubleClick}
    />
  );
}

export default InputForm;
