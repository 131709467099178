import React from 'react';
import NumberFormat from 'react-number-format';

function Currency({ inputRef, onChange, name, defaultValue, ...other }) {
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name,
            value: values.value,
          },
        });
      }}
      thousandSeparator='.'
      decimalSeparator=','
      defaultValue={defaultValue / 100}
      decimalScale={2}
      fixedDecimalScale={true}
    />
  );
}

export default Currency;