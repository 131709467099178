export const Types = {
  NEW_REQUEST: "mail/NEW_REQUEST",
  NEW_SUCCESS: "mail/NEW_SUCCESS",
  LIST_REQUEST: "mail/LIST_REQUEST",
  LIST_SUCCESS: "mail/LIST_SUCCESS",
  READ_MAIL_REQUEST: "mail/READ_MAIL_REQUEST",
  READ_MAIL_SUCCESS: "mail/READ_MAIL_SUCCESS",
  FORCE_SCALLING_REQUEST: "mail/FORCE_SCALLING_REQUEST",
  FORCE_SCALLING_SUCCESS: "mail/FORCE_SCALLING_SUCCESS",
  UNREADED_LIST_REQUEST: "mail/UNREADED_LIST_REQUEST",
  UNREADED_LIST_SUCESS: "mail/UNREADED_LIST_SUCESS",
  EXPORT_MAIL_REQUEST: "mail/EXPORT_MAIL_REQUEST",
  EXPORT_MAIL_SUCCESS: "mail/EXPORT_MAIL_SUCCESS",
  LIST_MAX_SCALLING_MAILS_WITHOUT_ANSWER_FROM_DOCUMENT_REQUEST:
    "mail/LIST_MAX_SCALLING_MAILS_WITHOUT_ANSWER_FROM_DOCUMENT_REQUEST",
  LIST_MAX_SCALLING_MAILS_WITHOUT_ANSWER_FROM_DOCUMENT_SUCCESS:
    "mail/LIST_MAX_SCALLING_MAILS_WITHOUT_ANSWER_FROM_DOCUMENT_SUCCESS",
  SET_LOADING_MAIL_MAX_SCALLING: "mail/TOGGLE_LOADING_MAIL_MAX_SCALLING",
  REMOVE_MAIL_FROM_MAX_SCALING_LIST_REQUEST: "mail/REMOVE_MAIL_FROM_MAX_SCALING_LIST_REQUEST",
  REMOVE_MAIL_FROM_MAX_SCALING_LIST_SUCCESS: "mail/REMOVE_MAIL_FROM_MAX_SCALING_LIST_SUCCESS",
  RESEND_REQUEST: "mail/RESEND_REQUEST",
  RESEND_SUCCESS: "mail/RESEND_SUCCESS",
};

const INITIAL_STATE = {
  loading: false,
  emails: {},
  emailsNaoLidos: [],
  agregacoes: {},
  exportar: {
    loading: false,
    data: {},
  },
  emailsMaxScallingFromDocument: {
    loading: false,
    data: [],
  },
};

export default function items(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.NEW_REQUEST:
      return { ...state, loading: true };

    case Types.NEW_SUCCESS:
      return { ...state, loading: false };

    case Types.LIST_REQUEST:
      return { ...state, loading: true };

    case Types.LIST_SUCCESS:
      return { ...state, ...action.payload, loading: false };

    case Types.UNREADED_LIST_REQUEST:
      return { ...state, loading: true };

    case Types.UNREADED_LIST_SUCESS:
      return { ...state, ...action.payload, loading: false };

    case Types.READ_MAIL_REQUEST:
      return { ...state, loading: true };

    case Types.READ_MAIL_SUCCESS:
      return { ...state, loading: false };

    case Types.FORCE_SCALLING_REQUEST:
      return { ...state, loading: true };

    case Types.FORCE_SCALLING_SUCCESS:
      return { ...state, loading: false };

    case Types.EXPORT_MAIL_REQUEST:
      return { ...state, exportar: { data: {}, loading: true } };

    case Types.EXPORT_MAIL_SUCCESS:
      return { ...state, exportar: { data: action.payload, loading: false } };

    case Types.LIST_MAX_SCALLING_MAILS_WITHOUT_ANSWER_FROM_DOCUMENT_REQUEST:
      return {
        ...state,
        emailsMaxScallingFromDocument: {
          ...state.emailsMaxScallingFromDocument,
          loading: true,
        },
      };

    case Types.LIST_MAX_SCALLING_MAILS_WITHOUT_ANSWER_FROM_DOCUMENT_SUCCESS:
      return {
        ...state,
        emailsMaxScallingFromDocument: {
          data: action.payload,
          loading: false,
        },
      };

    case Types.REMOVE_MAIL_FROM_MAX_SCALING_LIST_REQUEST:
      return {
        ...state,
        emailsMaxScallingFromDocument: {
          ...state.emailsMaxScallingFromDocument,
          loading: true,
        },
      };

    case Types.REMOVE_MAIL_FROM_MAX_SCALING_LIST_SUCCESS:
      return {
        ...state,
        emailsMaxScallingFromDocument: {
          ...state.emailsMaxScallingFromDocument,
          loading: false,
        },
      };

    case Types.SET_LOADING_MAIL_MAX_SCALLING:
      return {
        ...state,
        emailsMaxScallingFromDocument: {
          ...state.emailsMaxScallingFromDocument,
          loading: action.payload,
        },
      };

    case Types.RESEND_REQUEST:
      return { ...state, loading: true };

    case Types.RESEND_SUCCESS:
      return { ...state, loading: false };

    default:
      return state;
  }
}

export const Creators = {
  createRequest: (mail) => ({
    type: Types.NEW_REQUEST,
    payload: mail,
  }),

  createSuccess: () => ({
    type: Types.NEW_SUCCESS,
    payload: {},
  }),

  listRequest: (data) => ({
    type: Types.LIST_REQUEST,
    payload: data,
  }),

  listSuccess: (data) => ({
    type: Types.LIST_SUCCESS,
    payload: data,
  }),

  readMailRequest: (data) => ({
    type: Types.READ_MAIL_REQUEST,
    payload: data,
  }),

  readMailSuccess: (data) => ({
    type: Types.READ_MAIL_SUCCESS,
    payload: data,
  }),

  forceScallingRequest: (data) => ({
    type: Types.FORCE_SCALLING_REQUEST,
    payload: data,
  }),

  forceScallingSuccess: () => ({
    type: Types.READ_MAIL_SUCCESS,
    payload: {},
  }),

  listUnreadedRequest: (data) => ({
    type: Types.UNREADED_LIST_REQUEST,
    payload: data,
  }),

  listUnreadedSuccess: (data) => ({
    type: Types.UNREADED_LIST_SUCESS,
    payload: data,
  }),

  exportEmailRequest: (data) => ({
    type: Types.EXPORT_MAIL_REQUEST,
    payload: data,
  }),

  exportEmailSuccess: (data) => ({
    type: Types.EXPORT_MAIL_SUCCESS,
    payload: data,
  }),

  listMaxScallingMailsWithoutAnswerFromDocumentRequest: (data) => ({
    type: Types.LIST_MAX_SCALLING_MAILS_WITHOUT_ANSWER_FROM_DOCUMENT_REQUEST,
    payload: data,
  }),

  listMaxScallingMailsWithoutAnswerFromDocumentSuccess: (data) => ({
    type: Types.LIST_MAX_SCALLING_MAILS_WITHOUT_ANSWER_FROM_DOCUMENT_SUCCESS,
    payload: data,
  }),

  removeMailFromMaxScallingListRequest: (data) => ({
    type: Types.REMOVE_MAIL_FROM_MAX_SCALING_LIST_REQUEST,
    payload: data,
  }),

  removeMailFromMaxScallingListSuccess: (data) => ({
    type: Types.REMOVE_MAIL_FROM_MAX_SCALING_LIST_SUCCESS,
    payload: data,
  }),

  setLoadingMailMaxScalling: (data) => ({
    type: Types.SET_LOADING_MAIL_MAX_SCALLING,
    payload: data,
  }),

  resendingEmailRequest: (data) => ({
    type: Types.RESEND_REQUEST,
    payload: data,
  }),

  resendingEmailSuccess: () => ({
    type: Types.RESEND_SUCCESS,
    payload: {},
  }),
};
