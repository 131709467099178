import { Types as TypesBloqueio } from './bloqueio';

export const Types = {
  LIST_REQUEST: 'bloqueios/LIST_REQUEST',
  LIST_SUCCESS: 'bloqueios/LIST_SUCCESS',
}

const INITIAL_STATE = {
  list: [],
  loading: false,
  page: 1,
  limit: 10,
  total: 0,
  search: '',
  sort: 'natureza.nome'
}

export default function bloqueios(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.LIST_REQUEST:
      return {
        ...state,
        ...action.payload,
        list: [],
        loading: true
      }

    case Types.LIST_SUCCESS:
      return { 
        ...state,
        total: action.payload.total,
        list: action.payload.list,
        loading: false
      }

    case TypesBloqueio.DELETE_IMAGE_SUCCESS:
      return {
        ...state,
        list: state.list.map(
          bloqueio => bloqueio._id !== action.payload.bloqueio._id ? bloqueio : action.payload.bloqueio
        )
      }
    
    default:
      return state;
  }
}

export const Creators = {
  listRequest: params => ({
    type: Types.LIST_REQUEST,
    payload: params
  }),
  listSuccess: (list, total) => ({
    type: Types.LIST_SUCCESS,
    payload: { list, total }
  })
}