import * as React from 'react';
import { CompactTable } from '@table-library/react-table-library/compact';
import { useTheme } from '@table-library/react-table-library/theme';
import { useTree } from '@table-library/react-table-library/tree';

const THEME = {
  HeaderRow: `
    font-size: 12px;
    border: 1px solid #d9d9f2;
    background-color: #ffffff;
  `,
  Row: `
    font-size: 12px;
    border: 1px solid #d9d9f2;
    font-weight: 400;
    line-height: 1.43;

    &:nth-of-type(odd) {
      background-color: #e8e8f7;

      input {
        background-color: #ffffff;
      }

      .MuiFormControl-root {
        background-color: #ffffff;
        border-radius: 5px;
      }

      .MuiOutlinedInput-notchedOutline {
        border-color: #d3d3d3;
      }
    }

    &:nth-of-type(even) {
      background-color: #ffffff;

      input {
        background-color: #ffffff;
      }
    }
  `,
  BaseCell: `

  text-align: center;
  height: 68px!important;

  &:first-of-type {
    text-align: left;
  }

  &:last-of-type {
    text-align: right;
  }
`,
};

const TableEditable = ({ columns, nodes }) => {
  const DATA = { nodes };

  const theme = useTheme(THEME);

  function onTreeChange(action, state) {
  }

  const tree = useTree(DATA, {
    onChange: onTreeChange,
  });

  return (
    <CompactTable columns={columns} data={DATA} theme={theme} tree={tree} />
  );
};

export default TableEditable;
