import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  TableFooter,
  TablePagination,
} from "@material-ui/core";
import StyledTableRow from "components/StyledTableRow";
import LoadingTable from "components/LoadingTable";
import CustomTableSortLabel from "components/CustomTableSortLabel";
import "./styles.css";

import { Link, useLocation } from "react-router-dom";

import ButtonSquare from "components/ButtonSquare";

import ToolTipComponent from "components/Tooltip";

import { Creators as TipoImagensActions } from "store/ducks/tipoimagem";

export default function TableTipoImagem({ query }) {
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const {
    list: tipoImagens,
    loading,
    page = 1,
    limit = 10,
    total,
    sort = "nome",
  } = useSelector((state) => state.tipoImagem);
  const {
    access: { servicos },
  } = useSelector((state) => state.permissoes);

  const [request, setRequest] = useState({
    page,
    limit: limit > 100 ? 10 : limit,
    sort,
  });
  const [userActions, setUserActions] = useState([]);

  useEffect(() => {
    dispatch(TipoImagensActions.listRequest({ ...request, query }));
  }, [dispatch, request, query]);

  useEffect(() => {
    let find = servicos.find((item) => item.route === pathname);
    if (find !== undefined) {
      setUserActions(find);
    }
  }, [pathname, servicos]);

  function handleChangeRowsPerPage({ target }) {
    const { value: limit } = target;
    setRequest({ ...request, limit, page: 1 });
  }

  function handleChangePage(e, newPage) {
    setRequest({ ...request, page: newPage + 1 });
  }

  function handleChangeSort(sort) {
    setRequest({ ...request, sort });
  }

  function handleDelete(item) {
    dispatch(TipoImagensActions.removeRequest(item._id));
  }

  return (
    <Table size="small">
      {tipoImagens && tipoImagens.length > 0 ? (
        <>
          <TableHead>
            <TableRow>
              <TableCell>
                <CustomTableSortLabel
                  onClick={handleChangeSort}
                  currentSort={request.sort}
                  sortField="nome"
                >
                  Nome
                </CustomTableSortLabel>
              </TableCell>
              <TableCell>
                <CustomTableSortLabel
                  onClick={handleChangeSort}
                  currentSort={request.sort}
                  sortField="tipoDocumento.nome"
                >
                  Tipo de Documento
                </CustomTableSortLabel>
              </TableCell>
              {userActions.actions &&
                userActions.actions.map(
                  (userAct, index) =>
                    userAct.action === "editar" && (
                      <TableCell key={index} align="center">
                        Ações
                      </TableCell>
                    )
                )}
            </TableRow>
          </TableHead>
          <TableBody>
            {!!loading && <LoadingTable columns={4} rows={request.limit} />}
            {!loading &&
              tipoImagens &&
              tipoImagens.map((item) => (
                <StyledTableRow key={item._id}>
                  <TableCell>{item.nome}</TableCell>
                  <TableCell>{item.tipoDocumento.nome}</TableCell>
                  <TableCell align="center">
                    <div className="flex-direction">
                    <ToolTipComponent title="Indexadores" placement="top">
                        <Link
                          to={{
                            pathname:
                              "/configuracao/tipoimagem/indexadores",
                            state: {
                              TipoImagem: item,
                            },
                          }}
                        >
                          <ButtonSquare icon="playlist_add" />
                        </Link>
                      </ToolTipComponent>
                      {userActions.actions &&
                        userActions.actions.map(
                          (userAct, index) =>
                            userAct.action === "editar" && (
                              <ToolTipComponent
                                title="Editar"
                                placement="top"
                                key={index}
                              >
                                <Link
                                  to={{
                                    pathname: "/configuracao/tipoimagens/edit",
                                    state: {
                                      TipoImagem: item,
                                    },
                                  }}
                                  className="linkicon"
                                  id="tipoimagens"
                                >
                                  <ButtonSquare icon="edit" />
                                </Link>
                              </ToolTipComponent>
                            )
                        )}
                      <ToolTipComponent title="Remover" placement="top">
                        <div>
                          <ButtonSquare
                            onClick={() => handleDelete(item)}
                            icon="delete"
                          />
                        </div>
                      </ToolTipComponent>
                    </div>
                  </TableCell>
                </StyledTableRow>
              ))}
          </TableBody>
          <TableFooter>
            {!loading && (
              <TableRow>
                <TablePagination
                  count={total}
                  page={request.page - 1}
                  rowsPerPage={request.limit}
                  rowsPerPageOptions={[10, 25, 50, 100]}
                  labelRowsPerPage="Linhas por página"
                  labelDisplayedRows={({ from, to, count }) =>
                    `${from}-${to} de ${
                      count !== -1 ? count : `mais que ${to}`
                    }`
                  }
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  onPageChange={handleChangePage}
                />
              </TableRow>
            )}
          </TableFooter>
        </>
      ) : (
        <span className="list__none">Não há tipos de imagens registrados.</span>
      )}
    </Table>
  );
}
