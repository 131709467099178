import styled, { css } from "styled-components/macro";

import { default as TableRowMaterial } from "@material-ui/core/TableRow";
import { default as TableMaterial } from "@material-ui/core/Table";
import { default as TableHeadMaterial } from "@material-ui/core/TableHead";
import { default as TableBodyMaterial } from "@material-ui/core/TableBody";
import { default as TableCellMaterial } from "@material-ui/core/TableCell";
import { default as TableContainerMaterial } from "@material-ui/core/TableContainer";
import { default as TablePaginationMaterial } from "@material-ui/core/TablePagination";
import { default as IconButtonMaterial } from "@material-ui/core/IconButton";

import {
  TASK_DATE_STATUS_COLORS,
  TASK_APROVATION_STATUS_COLORS,
} from "constants/task-colors";

export const VencimentoStatus = styled.span`
  background-color: #fff;
  padding: 4px 10px;
  border: 2px solid ${({ status }) => TASK_DATE_STATUS_COLORS[status]};
  color: ${({ status }) => TASK_DATE_STATUS_COLORS[status]};
  text-shadow: 1px 1px 2px 2px #000;
  border-radius: 50px;
  font-weight: bold;
  letter-spacing: 1.5px;
  height: 28px;
  width: 130px;

  svg {
    margin-right: 4px;
    width: 16px;
  }
`;

export const AprovacaoStatus = styled.span`
  background-color: #fff;
  padding: 4px 10px;
  border: 2px solid ${({ status }) => TASK_APROVATION_STATUS_COLORS[status]};
  color: ${({ status }) => TASK_APROVATION_STATUS_COLORS[status]};
  text-shadow: 1px 1px 2px 2px #000;
  border-radius: 50px;
  font-weight: bold;
  letter-spacing: 1.5px;
  height: 28px;
  width: 230px;

  svg {
    margin-right: 4px;
    width: 16px;
  }
`;

export const TableRow = styled(TableRowMaterial)`
  transition: background 0.3s ease;
  border: none;

  tbody &:nth-of-type(odd) {
    background-color: #efefef;
  }

  tbody &:hover {
    background-color: #bde6ff;
  }

  ${({ selected }) =>
    selected &&
    css`
      background-color: #bde6ff !important;
    `};
`;

export const LoaderContainer = styled.div`
  width: 100%;
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #efefef;
`;

export const Table = styled(TableMaterial)``;

export const TableHead = styled(TableHeadMaterial)`
  background-color: #0c4383;

  & .MuiTableCell-head {
    color: #fff;
  }

  & .MuiTableSortLabel-root.MuiTableSortLabel-active {
    color: #7f7f7f !important;
    background-color: #fff;
    padding: 0 8px;
    border-radius: 50px;

    svg {
      width: 14px;
    }
  }

  & .MuiTableSortLabel-root:hover {
    color: #7f7f7f !important;
  }
`;

export const TableBody = styled(TableBodyMaterial)``;

export const TableCell = styled(TableCellMaterial)`
  border: none;
  text-align: center;
  color: #666;
`;

export const UserList = styled(TableCell)`
  display: flex;
  justify-content: center;
  border: none;
`;

export const TableContainer = styled(TableContainerMaterial)``;

export const TablePagination = styled(TablePaginationMaterial)``;

export const ActionButton = styled(IconButtonMaterial)`
  border: 1px solid #7f7f7f19;
  background-color: #fff;
  width: 36px;
  height: 36px;
  box-shadow: 1px 1px 2px 2px #00000005;
`;
