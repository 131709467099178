import api from "../../services/api";
import { getToken } from "../../services/auth";
import { put, call, all, takeLatest } from "redux-saga/effects";
import { toastr } from "react-redux-toastr";
import { select } from "redux-saga/effects";

import { Creators as AgrupamentoActions, Types } from "../ducks/agrupamento";

export const getOrganization = (state) => state.usuarios.auth.organization;

export function* listAgrupamento() {
    try {

        const organization = yield select(getOrganization);

        const headerParams = {
            headers: { Authorization: getToken(), xkeyorg: organization },
        };

        const { data } = yield call(api.get, "agrupamento", headerParams);

        yield put(AgrupamentoActions.listSuccess(data));
    } catch (error) {
        toastr.error("Ops", "Não foi possível listar agrupamentos.");
    }
}

export function* createAgrupamento({ payload }) {
    const organization = yield select(getOrganization);

    try {
        const headerParams = {
            headers: { Authorization: getToken(), xkeyorg: organization },
        };

        const { data } = yield call(api.post, `agrupamento`, payload, headerParams);

        yield put(AgrupamentoActions.createSuccess(data));

        toastr.success("", "Agrupamento criado com sucesso.");
    } catch (error) {
        toastr.error("Ops", "Não foi possível criar agrupamento.");
    }
}

export function* editAgrupamento({ payload }) {

    try {
        const organization = yield select(getOrganization);

        const headerParams = {
            headers: { Authorization: getToken(), xkeyorg: organization },
        };

        const body = { ...payload }

        const { data } = yield call(
            api.put,
            `/agrupamento/${body._id}`,
            body,
            headerParams
        );

        yield put(AgrupamentoActions.editSuccess(data));

        toastr.success("", "Agrupamento editado com sucesso.");
    } catch (error) {
        toastr.error("Ops", "Não foi possível editar agrupamento.");
    }
}

export function* removeAgrupamento({ payload }) {

    try {
        const organization = yield select(getOrganization);

        const headerParams = {
            headers: { Authorization: getToken(), xkeyorg: organization },
        };

        yield call(api.delete, `agrupamento/${payload}`, headerParams);

        yield put(AgrupamentoActions.removeSuccess());

        toastr.success("", "Agrupamento excluído com sucesso.");

    } catch (error) {
        toastr.error("Ops", "Não foi possível excluir agrupamento.");
    }
}

export function* detalhesEtapas() {

    try {
        const organization = yield select(getOrganization);

        const headerParams = {
            headers: { Authorization: getToken(), xkeyorg: organization },
        };

        const { data } = yield call(api.get, `detalhes-etapas`, headerParams);

        const etapas = data?.reduce((acc,fluxo) => {
            const {
                definitionId: id,
                definitionName: name,
                deploymentId,
                key,
                steps
            } = fluxo;

            const resumo = steps?.reduce((res,etapa,idx) => {
                const obj = {
                    id: `${id}&${idx}`,
                    name: `${name} - ${etapa}`,
                    deploymentId,
                    key,
                    etapa,
                    fluxo: name,
                };
                res.push(obj);
                return res;
            },[]);
            return [...acc,...resumo];
        },[]);

        yield put(AgrupamentoActions.listStepsSuccess(etapas));

    } catch (error) {
        toastr.error("Ops", "Não foi possível obter detalhamento dos fluxos");
    }
}

export default all([
    takeLatest(Types.NEW_REQUEST, createAgrupamento),
    takeLatest(Types.EDIT_REQUEST, editAgrupamento),
    takeLatest(Types.LIST_REQUEST, listAgrupamento),
    takeLatest(Types.REMOVE_REQUEST, removeAgrupamento),
    takeLatest(Types.LIST_STEPS_REQUEST, detalhesEtapas),
]);
