export const Types = {
  LIST_REQUEST: "versionsimage/LIST_REQUEST",
  LIST_SUCCESS: "versionsimage/LIST_SUCCESS",
};

const INITIAL_STATE = {
  loading: false,
  data: [],
};

export default function versionsimage(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.LIST_REQUEST:
      return { ...state, loading: true };

    case Types.LIST_SUCCESS:
       return { ...state, data: action.payload, loading: false };

    default:
      return state;
  }
}

export const Creators = {
  listRequest: (payload) => ({
    type: Types.LIST_REQUEST,
    payload: payload,
  }),

  listSuccess: (data) => ({
    type: Types.LIST_SUCCESS,
    payload: data,
  }),
};
