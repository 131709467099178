import React, { useState } from 'react';

import { Container, MenuItem, ListItemIcon, ListItemText } from './styles/TasksActionMenu';

import { useDispatch } from 'react-redux';

import { ArrowDownCircle, CheckCircleIcon, FaTasks } from 'components/Icons';
import { Creators as DocumentosActions } from 'store/ducks/documentos';
import ModalNewTask from 'components/ModalNewTask';

function SearchResultActionMenu({ anchor, handleClose, selecionadas, tenant = '', tiposImagem }) {
  const [openModalNewTask, setOpenModalNewTask] = useState(false);

  const dispatch = useDispatch();

  const downloadChecklists = () => {
    dispatch(
      DocumentosActions.downloadImagesAsZipRequest({
        ids: selecionadas || [],
      })
    );
  };

  const createTask = () => {
    setOpenModalNewTask(true);
  };

  function handleCloseModalNewTask() {
    setOpenModalNewTask(false);
  }

  const downloadSpecificImageTypes = () => {
    dispatch(
      DocumentosActions.downloadImagesAsZipRequest({
        ids: selecionadas || [],
        idsTipoImagem: tiposImagem,
      })
    );
  };

  const confirmacaoDePresenca = () => {
    dispatch(
      DocumentosActions.confirmacaoDePresencaRequest({
        ids: selecionadas || [],
      })
    );
  };

  return (
    <>
      <Container
        onClose={handleClose}
        open={!!anchor}
        elevation={10}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        anchorEl={anchor}
      >
        <MenuItem onClick={() => downloadChecklists()}>
          <ListItemIcon>
            <ArrowDownCircle />
          </ListItemIcon>
          <ListItemText>Baixar arquivos</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => createTask()}>
          <ListItemIcon>
            <FaTasks />
          </ListItemIcon>
          <ListItemText>Criar tarefa</ListItemText>
        </MenuItem>
        {tiposImagem.length > 0 && (
          <MenuItem onClick={() => downloadSpecificImageTypes()}>
            <ListItemText>Baixar imagens selecionadas</ListItemText>
          </MenuItem>
        )}
      </Container>
      {openModalNewTask && (
        <ModalNewTask selectedDocument={selecionadas} open={openModalNewTask} handleClose={handleCloseModalNewTask} />
      )}
    </>
  );
}

export default SearchResultActionMenu;
