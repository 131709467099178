import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '20px'
  },
  text: {
    fontWeight: 'bold',
    marginLeft: '8px'
  }
}));