import api from 'services/api';
import { getToken } from 'services/auth';
import { call, select, put, all, takeEvery } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';

import { Creators as KanbanActions, Types } from 'store/ducks/kanban';

export const getOrganization = state => state.usuarios.auth.organization;

export function* getKanbanData() {
  const organization = yield select(getOrganization);

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const { data } = yield call(api.get, 'kanban', headerParams);

    yield put(KanbanActions.fetchDataSuccess(data));

  } catch (error) {
    toastr.error('Ops', 'Não foi possível acessar a lista de Kanban.');
  }
}

export function* createKanban({ payload }) {
  const organization = yield select(getOrganization);

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const { data } = yield call(api.post, 'kanban', payload, headerParams);

    yield put(KanbanActions.createBoardSuccess(data));

  } catch (error) {
    toastr.error('Ops', 'Não foi possível criar o Kanban.');
  }
}

export function* updateKanban({ payload }) {
  const organization = yield select(getOrganization);

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    yield call(api.put, `kanban/${payload._id}`, payload, headerParams);

    yield put(KanbanActions.updateBoardList(payload));

    } catch (error) {
      toastr.error('Ops', 'Não foi possível atualizar o Kanban.');
  }
}

export default all([
  takeEvery(Types.UPDATE_KANBAN_REQUEST, updateKanban),
  takeEvery(Types.GET_DATA_REQUEST, getKanbanData),
  takeEvery(Types.CREATE_BOARD_REQUEST, createKanban),
])