export const Types = {
  TOTAL_DOCS_BY_COMPANY_REQUEST: 'graphs/TOTAL_DOCS_BY_COMPANY_REQUEST',
  TOTAL_DOCS_BY_COMPANY_SUCCESS: 'graphs/TOTAL_DOCS_BY_COMPANY_SUCCESS'
}

const INITIAL_STATE = {
  docsByCompany: {
    data: [],
    loading: false
  }
}

export default function graphs(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.TOTAL_DOCS_BY_COMPANY_REQUEST:
      return { 
        ...state, 
        docsByCompany: { 
          data: [],
          loading: true
        } 
      }
    
    case Types.TOTAL_DOCS_BY_COMPANY_SUCCESS:
      return { 
        ...state,
        docsByCompany: { 
          data: action.payload,
          loading: false
        }
      }

    default:
      return state;
  }
}

export const Creators = {
  totalByCompanyRequest: () => ({
    type: Types.TOTAL_DOCS_BY_COMPANY_REQUEST,
  }),

  totalByCompanySuccess: data => ({
    type: Types.TOTAL_DOCS_BY_COMPANY_SUCCESS,
    payload: data
  })
}