import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row } from 'react-bootstrap';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableFooter from '@material-ui/core/TableFooter';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';

import DefaultPanel from 'components/DefaultPanel';
import ActionMenu from './ActionMenu';
import CustomTableSortLabel from 'components/CustomTableSortLabel';
import { Creators as DocumentosActions } from 'store/ducks/documentos';
import Checkbox from '@material-ui/core/Checkbox';
import LoadingTable from 'components/LoadingTable';

import ContentRow from './ContentRow';
import { SensibleFlag } from 'components/SensibleFlag';

export default function SearchResults({ setCollapse, collapse, simpleSearch }) {
  const dispatch = useDispatch();

  const { organization } = useSelector((state) => state.usuarios.auth);

  const [tenant, setTenant] = useState('');

  const [selectedId, setSelectedId] = useState('');
  const [selecionadas, setSelecionadas] = useState([]);
  const [actionMenuAnchor, setActionMenuAnchor] = useState(null);

  const {
    result,
    limit = 10,
    page = 1,
    total,
    query,
    loading,
    sort,
    searchText,
    fromAnalise,
  } = useSelector((state) => state.documentos.advSearch);




  const limite_de_campos_exibidos = 5;

  useEffect(() => {
    if (fromAnalise) {
      setSelectedId(query[0]);
    }
  }, [dispatch, fromAnalise]);

  useEffect(() => {
    dispatch(DocumentosActions.getDocumentoRequest(selectedId));

    if (selectedId) {
      setCollapse(true);
    }
  }, [dispatch, selectedId, setCollapse]);

  useEffect(() => {

    setTenant(organization);
  }, [organization]);

  function handleChangeRowsPerPage({ target }) {
    const { value } = target;

    if (simpleSearch) {
      dispatch(
        DocumentosActions.sendToSearchPageRequest(query, {
          page,
          sort,
          limit: value,
          searchText,
        })
      );
    } else {
      dispatch(
        DocumentosActions.advancedSearchResultRequest(query, {
          page,
          sort,
          limit: value,
        })
      );
    }

  }

  function handleChangePage(e, newPage) {

    if (simpleSearch) {
      dispatch(
        DocumentosActions.sendToSearchPageRequest(query, {
          limit,
          sort,
          page: newPage + 1,
          searchText,
        })
      );
    } else {
      dispatch(
        DocumentosActions.advancedSearchResultRequest(query, {
          limit,
          sort,
          page: newPage + 1,
        })
      );
    }
  }

  function handleChangeSort(sort) {
    if (simpleSearch) {
      dispatch(
        DocumentosActions.sendToSearchPageRequest(query, {
          limit,
          page,
          sort,
          searchText,
        })
      );
    } else {
      dispatch(
        DocumentosActions.advancedSearchResultRequest(query, {
          limit,
          page,
          sort,
        })
      );
    }
  }

  function handleOpenActionMenu(e) {
    setActionMenuAnchor(e.currentTarget);
  }

  function handleCloseActionMenu() {
    setActionMenuAnchor(null);
  }

  function openDocumentDetails(id) {
    dispatch(DocumentosActions.getDocumentoRequest(id));
    setSelectedId(id);
  }

  const handleCheckAll = ({ target }) => {
    const { checked } = target;

    if (checked) {
      const allSelect = result?.map((doc) => {
        return doc._id;
      });

      setSelecionadas(allSelect);//aqui
    } else setSelecionadas([]);
  };

  const handleCheck = ({ target }, id) => {
    const { checked } = target;

    if (checked) setSelecionadas([...selecionadas, id]);
    else setSelecionadas([...selecionadas.filter((sel) => sel !== id)]);
  };

  return (
    <Row>
      <DefaultPanel
        titleText="Resultado da pesquisa"
        isCollapsible={true}
        collapse={collapse}
        setCollapse={setCollapse}
        actionMenu={ActionMenu}
        handleOpenActionMenu={handleOpenActionMenu}
      >
        <Row>
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Checkbox
                      name="selecionar"
                      color="primary "
                      onChange={(e) => handleCheckAll(e)}
                    />
                  </TableCell>
                  <TableCell align="left">
                    <CustomTableSortLabel
                      currentSort={sort}
                      sortField="idEmpresa.razaoSocial"
                      onClick={handleChangeSort}
                    >
                      Empresa
                    </CustomTableSortLabel>
                  </TableCell>
                  <TableCell align="left">Número do Cliente</TableCell>
                  {result &&
                    result.length > 0 &&
                    result[0].indexadores
                      .filter((indexador) => indexador.exibirPesquisaDetalhada)
                      .slice(0, limite_de_campos_exibidos)
                      .map((indexador) => (
                        <TableCell
                          align={'left'}
                          style={{
                            width: indexador.nome.toUpperCase().includes('NOME')
                              ? '30%'
                              : '150px',
                          }}
                        >
                          <SensibleFlag indexador={indexador} >
                            {indexador.nome}
                          </SensibleFlag>
                        </TableCell>
                      ))}
                  <TableCell align="left">Etapa/Fila</TableCell>
                  {/* <TableCell align="center">Duração Produtiva</TableCell>
                  <TableCell align="center">Status</TableCell>
                  <TableCell align="center">
                    <CustomTableSortLabel
                      currentSort={sort}
                      sortField="createdAt"
                      onClick={handleChangeSort}
                    >
                      Criado em
                    </CustomTableSortLabel>
                  </TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {loading && <LoadingTable columns={4} rows={10} />}
                {result &&
                  result.length > 0 &&
                  result.map((doc, index) => (
                    <ContentRow
                      onClick={(e) => {
                        openDocumentDetails(doc._id);
                      }}
                      row={doc}
                      key={index}
                      index={index}
                      handleCheck={handleCheck}
                      selecionadas={selecionadas}
                    />
                  ))}
                {result && result.length === 0 && !loading && (
                  <TableRow>
                    <TableCell colSpan={6} align="center">
                      Nenhum documento encontrado.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
              {!loading && result && result.length > 0 && (
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      count={total}
                      page={page > 0 ? page - 1 : 0}
                      rowsPerPage={limit ? limit : 10}
                      rowsPerPageOptions={[10, 25, 50, 100]}
                      labelRowsPerPage="Linhas por página"
                      labelDisplayedRows={({ from, to, count }) =>
                        `${from}-${to} de ${count !== -1 ? count : `mais que ${to}`
                        }`
                      }
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      onPageChange={handleChangePage}
                    />
                  </TableRow>
                </TableFooter>
              )}
            </Table>
          </TableContainer>
        </Row>
      </DefaultPanel>
      {actionMenuAnchor && (
        <ActionMenu
          anchor={actionMenuAnchor}
          handleClose={handleCloseActionMenu}
          selecionadas={selecionadas}
          tiposImagem={query?.idsTipoImagem ?? []}
          tenant={tenant}
        />
      )}
    </Row>
  );
}
