import React, { useRef, useEffect, useState } from 'react';
import { useField } from '@unform/core';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    width: '100%',
    marginTop: '8px',
    '& .MuiInputLabel-formControl': {
      marginTop: '-8px'
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, 3px) scale(0.75)',
      background: '#FFF',
      padding: '0 6px'
    },
    '& input': {
      display: 'none'
    }
  }
})


function SelectForm({ name, label, children, id, boolean, onChange, ...rest }) {
  const inputRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  const [value, setValue] = useState(defaultValue || '');

  const classes = useStyles();

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      // setValue: (ref, value) => {
      //   ref.current.value = value
      // }
    });

  }, [boolean, fieldName, registerField, rest.isMulti]);

  function handleChange(e) {
    setValue(e.target.value)
    if (onChange) onChange(e);
  }

  return (
    <>
      <FormControl
        className={classes.root}
        variant="outlined"
        error={error && true}
      >
        <InputLabel id={id} >{label}</InputLabel>
        <Select
          labelId={id}
          margin="dense"
          value={value}
          onChange={handleChange}
          {...rest}
        >
          {children}
        </Select>
        <FormHelperText>{error}</FormHelperText>
        <input
          ref={inputRef}
          value={value}
          name={name}
          type="text"
          readOnly
        />
      </FormControl>
    </>
  );
}

export default SelectForm;
