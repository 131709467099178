import { call, put } from "redux-saga/effects";

import api from "../../services/api";
import { getToken } from "../../services/auth";

import { Creators as TranslateActions } from "../ducks/translate";

export function* getTranslate(xkeyorg) {
  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg },
    };

    const { data } = yield call(api.get, `/translate-tenant`, headerParams);

    yield put(TranslateActions.getTranslateSuccess(data));
  } catch (error) {}
}
