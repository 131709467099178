import styled, { css } from 'styled-components/macro';
import { default as MaterialDialog } from "@material-ui/core/Dialog";

export const MailboxContainer = styled.div`
    min-height: 200px;
    max-height: 210px;
    overflow: hidden;
    margin: 1px;
    padding: 8px;
`;

export const MailboxSent = styled.div`
    padding: 12px;
    height: 195px;
    max-height: 195px;
    width: 1160px;
    max-width: 1160px;
    overflow: hidden;
    border-radius: 12px;    
    
    display: flex;
    flex-direction: row;

    @media screen and (max-width: 2000px) {
        scale: 0.9;
    }

    margin-top: 16px;
    background-color: #F2F3F4;

    &:hover {
        background-color: #EBEDEF;
        border: 2px solid #EAECEE;
    }
`;

export const MailboxResponse = styled.div`
    padding: 12px;
    height: 195px;
    max-height: 195px;
    width: 1270px;
    max-width: 1270px;
    overflow: hidden;
    border-radius: 12px;    

    display: flex;
    flex-direction: row;

    @media screen and (max-width: 2000px) {
        scale: 0.9;
    }
    
    margin-left: 5%;
    background-color: #D5D8DC;

    &:hover {
        background-color: #EAECEE;
        border: 2px solid #EAECEE;
    }
`;

export const Modal = styled(MaterialDialog)`
    .MuiDialog-paperWidthSm {
        max-width: 40vw;
        width: 40vw;
        padding: 2em;

        @media screen and (max-width:  1000px) {
            max-width: 80vw;
            width: 80vw;
        }

        ${({ contained }) => contained && css`
            width: 600px;
        `};
    }
`;