import styled from 'styled-components/macro';

export const LoadingOnboardingData = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  flex-direction: column;
  padding: 20px;

  h3 {
    margin-top: 20px;
    text-align: center;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: ${(props) =>
    props.$direction ? props.$direction : 'column'};
  border: ${(props) => (props.$border ? '1px solid #eaeaea' : 'none')};
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  padding: ${(props) => (props.$fullwidth ? '0' : '20px')};
  background-color: ${(props) =>
    props.$background ? props.$background : 'none'};
`;

export const ContainerConfig = styled.div`
  display: flex;
  flex-direction: ${(props) =>
    props.$direction ? props.$direction : 'column'};
  border: ${(props) => (props.$border ? '1px solid #eaeaea' : 'none')};
  align-items: flex-start;
  justify-content: center;
  width: 100%;

  min-height: 500px;
  padding: ${(props) => (props.$fullwidth ? '0' : '20px')};
  background-color: ${(props) =>
    props.$background ? props.$background : 'none'};
`;

export const Header = styled.div`
  margin-top: 20px;
  margin-bottom: 15px;

  img {
    max-height: 100px;
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-height: auto;
  height: 100%;
  width: 90%;
  background-color: #fff;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
  }
`;

export const StepListContainer = styled.div`
  width: 30%;
  height: 100%;
  min-height: 500px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 30px;
  border-right: 1px solid #eaeaea;

  @media only screen and (max-width: 768px) {
    padding: 10px 10px 0 10px;
    width: 100%;
    border: none;
    border-bottom: 1px solid #eaeaea;
    flex: 0;
    min-height: 75px;
  }
`;

export const StepList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

export const StepItem = styled.li`
  cursor: pointer;
  width: 100%;
  margin-bottom: 15px;

  span {
    color: #fff;
    border: ${(props) =>
    props.$selected
      ? props.$layout.selected.border
      : props.$layout.normal.border};
    background-color: ${(props) =>
    props.$selected
      ? props.$layout.selected.background
      : props.$layout.normal.background};
    color: ${(props) =>
    props.$selected
      ? props.$layout.selected.numberColor
      : props.$layout.normal.numberColor};
    width: 40px;
    font-size: 16px;
    padding: 5px;
    display: inline-block;
    text-align: center;
    font-weight: bolder;
    margin-right: 15px;
    border-radius: 5px;
  }

  strong {
    color: ${(props) =>
    props.$selected
      ? props.$layout.selected.textColor
      : props.$layout.normal.textColor};
    font-size: 16px;
    font-weight: bolder;
  }
`;

export const StepItemMobile = styled.div`
  cursor: pointer;
  width: 100%;
  margin-bottom: 10px;
  display: none;

  span {
    color: #fff;
    border: ${(props) =>
    props.$selected
      ? props.$layout.selected.border
      : props.$layout.normal.border};
    background-color: ${(props) =>
    props.$selected
      ? props.$layout.selected.background
      : props.$layout.normal.background};
    color: ${(props) =>
    props.$selected
      ? props.$layout.selected.numberColor
      : props.$layout.normal.numberColor};
    width: 40px;
    font-size: 16px;
    padding: 5px;
    display: inline-block;
    text-align: center;
    font-weight: bolder;
    margin-right: 15px;
    border-radius: 5px;
  }

  strong {
    color: ${(props) =>
    props.$selected
      ? props.$layout.selected.textColor
      : props.$layout.normal.textColor};
    font-size: 16px;
    font-weight: bolder;
  }

  @media only screen and (max-width: 768px) {
    display: block;
  }
`;

export const PreviewStepContainer = styled.div`
  width: ${(props) => (props.$fullwidth ? '100%' : '70%')};
  height: 100%;
  max-height: 100%;
  object-fit: cover;
  padding: ${(props) => (props.$fullwidth ? '0' : '30px')};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-x: auto;

  @media only screen and (max-width: 768px) {
    width: 100%;
    padding: 20px;
  }
`;

export const EmptyStep = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border: 1px solid #eaeaea;
  padding: 150px 0px;
  color: #aeaeae;

  svg {
    font-size: 110px;
    margin-bottom: 15px;
    color: #aeaeae;
  }
`;

export const PreviewStepContent = styled.div``;

export const PreviewButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  button {
    background: ${(props) =>
    props.$layout.background ? props.$layout.background : '#672478'};
    color: ${(props) => (props.$layout.$color ? props.$layout.color : '#fff')};
    margin-left: 15px;
    width: 150px;

    @media only screen and (max-width: 768px) {
      margin-left: 0;
    }
  }

  @media only screen and (max-width: 768px) {
    justify-content: space-between;
  }
`;

export const StepsConfig = styled.div`
  border-top: 1px solid #e5e5e5;
  padding-top: 20px;
  margin-top: 20px;

  h4 {
    font-size: 18px;
    font-weight: 500;
  }
`;

export const StepsConfigHeader = styled.div`
  width: 100%;

  h3 {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 15px;
  }
`;

export const StepsConfigBody = styled.div`
  width: 100%;
  padding-bottom: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
`;

export const StepsConfigLayout = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;

  div {
    flex: 0 0 49.1%;
  }
`;

export const StepConfigTypes = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 20px;

  div {
    flex: 0 0 45%;
    padding-right: 15px;
  }

  div:first-child {
  }

  div:last-child {
    flex: 0 0 10%;
    padding-right: 0;
    text-align: right;
  }
`;

export const StepConfigTypesHeader = styled.div``;

export const StepConfigTypesBody = styled.div`
  width: 100%;
  display: flex;

  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  div {
    flex: 0 0 46%;
    padding-right: 15px;
  }

  .MuiAutocomplete-root {
    margin-top: 3px !important;
  }

  div:last-child {
    flex: 0 0 8%;
    padding-right: 0;
    text-align: right;
  }
`;

export const NovaEtapaButton = styled.button`
  background: #6259ca;
  color: #fff;
  border: none;
  width: 100%;
  min-height: 35px !important;

  svg {
    margin-right: 5px;
  }

  &:hover {
    background: #4f47b2;
  }
`;
