import { all, takeEvery, takeLatest } from 'redux-saga/effects';

// --------- WELCOME -----------------------------//
import { Types as WelcomeTypes } from '../ducks/welcome';
import { biToken, latestNewsList } from './welcome';

// --------- ORGANIZAÇÃO -----------------------------//
import { Types as OrganizacoesTypes } from '../ducks/organizacoes';
import { listOrganizacoes } from './organizacoes';

// --------- VERSÕES DE IMAGEM -----------------------------//
import { Types as VersionsImageTypes } from '../ducks/versionsimage';
import { getVersionsImage } from './versionsimage';

// --------- PERMISSOES -----------------------------//
import { Types as PermissoesTypes } from '../ducks/permissoes';
import {
  getPermissoes,
  getPermissoesAnotherUser,
  updatePermissoes,
} from './permissoes';

// --------- STORAGE -----------------------------//
import { Types as TempStorageTypes } from '../ducks/tempstorage';
import {
  callStorage,
  createPartTempStorage
} from './tempstorage';

// --------- USUÁRIO -----------------------------//
import { Types as UsuariosTypes } from '../ducks/usuarios';
import {
  changePassword,
  changePasswordByLogin,
  changePasswordByNewPass,
  editEmpresaPermissionUser,
  editUser,
  forgotPassword,
  goToApp,
  listUsers,
  listUsersByAccessGroup,
  listUsersByDepartamento,
  loginUser,
  loginUserKc,
  loginWithToken,
  logoutUser,
  searchAnalystsByName,
  twoFactorValidate,
  validateToken,
} from './usuarios';

// --------- DEPARTAMENTO -----------------------------//
import { Types as DeptoTypes } from '../ducks/departamentos';
import {
  createDepartamento,
  editDepartamento,
  getDepartamento,
  listDepartamentos,
  listDepartamentosByEmpresa,
  removeDepartamento,
} from './departamentos';

// --------- DOC SIGNATURE -----------------------------//
import { Types as DocSignatureTypes } from '../ducks/docsignature';
import {
  sendSignature,
  listDocSignature,
  getBlob,
  getB64,
} from './docsignature';

// --------- TIPO DEPARTAMENTO -----------------------------//
import { Types as TipoDepartamentoTypes } from '../ducks/tipodepartamento';
import {
  createTipoDepartamento,
  editTipoDepartamento,
  getTipoDepartamento,
  listTipoDepartamentos,
  removeTipoDepartamento,
} from './tipodepartamento';

// --------- CATEGORIA -----------------------------//
import { Types as CategoriasTypes } from '../ducks/categorias';
import {
  createCategoria,
  editCategoria,
  getCategoria,
  listCategorias,
} from './categorias';

// --------- IMPORTAÇÃO -----------------------------//
import { Types as ImportTypes } from '../ducks/importacao';
import { createImportacao, createImportacaoHeader } from './importacao';

// --------- EXPORTAÇÃO -----------------------------//
import { Types as ExportTypes } from '../ducks/exportacao';
import { createExportacao } from './exportacao';

// --------- EXPORTAÇÃO UNIC -----------------------------//
import { Types as ExportUnicTypes } from '../ducks/exportacaounic';
import { createExportacaoUnic } from './exportacaounic';

// --------- REPORT -----------------------------//
import { Types as ReportTypes } from '../ducks/report';
import { createReport, sendReportAsMail } from './report';

// --------- DASHBOARD -----------------------------//
import { Types as DashboardTypes } from '../ducks/dashboard';
import {
  createDashboard,
  documentsOnMaxScallingWithoutAnswerRequest,
  slaFilterList,
} from './dashboard';

// --------- DASHBOARD CUSTOM -----------------------------//
import { Types as DashboardCustomTypes } from '../ducks/dashboardcustom';
import {
  createDashboardCustom,
  createDashboardYearCustom,
  exportCustomTasks,
  getDashPredefinitions,
} from './dashboardcustom';

// --------- DASHBOARD SCALING PENDING -----------------------------//
import { Types as DashboardScalingPending } from '../ducks/dashboardscalingpending';
import {
  createScalingPending,
  exportScalingPending,
} from './dashboardscalingpending';

// --------- DASHBOARD SCALING PENDING -----------------------------//
import { Types as ProductivityTypes } from '../ducks/productivity';
import { createProductivity, exportProductivity } from './productivity';

// --------- DEFINIÇÃO SLA -----------------------------//
import { Types as DefinicaoSLATypes } from '../ducks/definicaosla';
import { createSLA, editSLA, getSLA, listSLA } from './definicaosla';

// --------- ESCALONAMENTO -----------------------------//
import { Types as DepartamentoEscalonamentoTypes } from '../ducks/departamentoescalonamento';
import {
  createDepartamentoEscalonamento,
  editDepartamentoEscalonamento,
  getDepartamentoEscalonamento,
  listDepartamentoEscalonamento,
} from './departamentoescalonamento';

// --------- LAYOUT -----------------------------//
import { Types as LayoutTypes } from '../ducks/layout';
import { createLayout, editLayout, getLayout, listLayout } from './layout';

// --------- CARGA -----------------------------//
import { Types as CargaTypes } from '../ducks/carga';
import { editCarga, listCarga } from './carga';

// --------- RPA -----------------------------//
import { Types as RPATypes } from '../ducks/rpa';
import { editRPA, listRPA } from './rpa';

// --------- CONFIG DASHBOARD EXTERNO -----------------------------//
import { Types as ConfiguracaoDashboardExternoTypes } from '../ducks/configuracaoDashboardExterno';
import { editConfiguracaoDashboardExterno, listConfiguracaoDashboardExterno } from './configuracaoDashboardExterno';

// --------- DASHBOARD EXTERNO -----------------------------//
import { Types as DashboardExternoTypes } from '../ducks/dashboardexterno';
import { getDashboardExterno } from './dashboardExterno';

// --------- CARGA ITENS --------------------------//
import { Types as CargaItensTypes } from '../ducks/cargaitens';
import { listByCargaId, markAsRemoved } from './cargaitens';

// --------- FORMULARIO -----------------------------//
import { Types as FormularioTypes } from '../ducks/formulario';
import {
  createFormulario,
  deleteFormulario,
  editFormulario,
  getFormulario,
  getPublicFormulario,
  listFormularios,
  respostaFormulario,
  sendFormulario,
  validaFormulario,
} from './formularios';

// --------- GRUPO DE EMAIL -----------------------------//
import { Types as GrupoEmailTypes } from '../ducks/grupoemail';
import {
  addEmailDosGruposDeEmail,
  createGrupoEmail,
  deleteEmailDosGruposDeEmail,
  deleteGrupoEmail,
  editGrupoEmail,
  getGrupoEmail,
  listGrupoEmail,
} from './grupoemail';

// --------- MAIL -----------------------------//
import { Types as MailTypes } from '../ducks/mail';
import {
  createMail,
  exportEmail,
  forceScalling,
  listMail,
  listMaxScallingMailsWithoutAnswerFromDocument,
  listUnreadedMail,
  readMail,
  removeMailFromMaxScallingList,
  resendingEmailWithError,
} from './mail';

// --------- EXPORTAÇÃO DE DOCUMENTO -----------------------------//
import { Types as ExportingDocumentTypes } from '../ducks/exportDocuments';
import { createExportation } from './exportDocuments';

// --------- RESPOSTA ÓRGÃOS -----------------------------//
import { Types as OrgaosAnswerTypes } from '../ducks/respostaOrgaos';
import {
  createOrgaosAnswer,
  listOrgaos,
  listTipoManifestacoes,
} from './respostaOrgaos';

// --------- FILA -----------------------------//
import { Types as AgrupamentoTypes } from '../ducks/agrupamento';
import { Types as FilaTypes } from '../ducks/filas';
import { Types as FilaDetalhesTypes } from '../ducks/filasDetalhes';
import { Types as PesosProdutividadeTypes } from '../ducks/pesosprodutividade';
import {
  createAgrupamento,
  detalhesEtapas,
  editAgrupamento,
  listAgrupamento,
  removeAgrupamento,
} from './agrupamento';
import { createFila, editFila, getFila, listFila, listFluxo } from './filas';
import { exportTasks, listFilaDetails } from './filasdetalhes';
import {
  createPesosProdutividade,
  listPesosProdutividade,
} from './pesosprodutividade';

// --------- EMPRESA -----------------------------//
import { Types as EmpresasTypes } from '../ducks/empresas';
import {
  createEmpresa,
  editEmpresa,
  findEmpresaByCNPJ,
  getRegras,
  listEmpresas,
  removeEmpresa,
} from './empresas';

// --------- TIPO DOC -----------------------------//
import { Types as TipoDocTypes } from '../ducks/tiposdocumento';
import {
  createTipoDoc,
  editIndexadoresTipoDoc,
  editTipoDoc,
  getIndexadores,
  listTipoDocs,
  listTipoDocsByEmpresas,
  removeTiposDocs,
  updateDocumentTypeRules,
} from './tiposdocumento';

import { Types as MenuTypes } from '../ducks/menu';
import { toggleMenuSaga } from './menu';

import { Types as MenusTypes } from '../ducks/menus';
import { createMenu, editMenu, listMenus, removeMenu } from './menus';

// --------- TIPO IMAGEM -----------------------------//
import { Types as TipoImgTypes } from '../ducks/tipoimagem';
import {
  createTipoImagem,
  editIndexadoresTipoImagem,
  editTipoImagem,
  getTipoImagem,
  listTipoImagem,
  listTipoImagemByTipoDocumento,
  removeTipoImagem,
  reportTipoImagem,
} from './tipoimagem';

// --------- DOCUMENTOS -----------------------------//
import { Types as NaturezasTypes } from '../ducks/naturezas';
import { listNaturezasEnum } from './naturezas';

import { Types as DocumentoTypes } from '../ducks/documentos';
import {
  confirmacaoPresenca,
  downloadImagensAsZip,
  advancedSearch,
  autocompleteDocumentos,
  createDocumento,
  exportDocumentosToCSV,
  getDocumento,
  goSearchDocumentos,
  listDocumentosByEmpresa,
  listDocumentosByEtapaRelatorio,
  listDocumentosByEtapas,
  reopen,
  searchEsById,
  sendEmailByApp,
  uploadNewVersion,
  mergeImagens,
  updateImagens,
  updateIndexadores,
  updateIndexadoresImage,
  updateTypeImageArchieve,
  updateImagensV2,
  removeImagem,
  generateThumbnails,
  downloadDocs,
} from './documentos';

// --------- ETAPAS -----------------------------//
import { Types as EtapasTypes } from '../ducks/etapas';
import { createEtapas, editEtapa, listEtapas } from './etapas';

// --------- PARAM FORMALIZACAO ELETRÔNICA -----------------------------//
import { Types as ParamAssinaturaEletronicaTypes } from '../ducks/paramassinaturaeletronica';
import {
  editAssinaturaEletronica,
  listAssinaturaEletronica,
} from './paramassinaturaeletronica';

// --------- TEMPLATE SIGNATURE POINTS -----------------------------//
import { Types as TemplateSignaturePointsTypes } from '../ducks/templatesignaturepoints';
import {
  editTemplateSignaturePoints,
  listTemplateSignaturePoints,
} from './templatesignaturepoints';

// --------- CHECKTIPO -----------------------------//
import { Types as CheckTipoTypes } from '../ducks/checktipo';
import {
  createCheckItem,
  createCheckTipo,
  createCheckTitulo,
  editCheckTipo,
  listParamFormalizacaoByEmpresa,
  listParamFormalizacaoByEtapa,
  removeCheckItem,
  removeCheckTitulo,
  showCheckTipo,
} from './checktipo';

// --------- AUDITORIA CONFIG CHECKLIST -----------------------------//
import { Types as ParamFormalizacaoAuditoriaTypes } from '../ducks/paramformalizacaoauditoria';
import { getParamFormalizacaoAuditoria } from './paramformalizacaoauditoria';

// --------- ANALISE -----------------------------//
import { Types as AnaliseTypes } from '../ducks/analise';
import {
  assignTaskToUser,
  batchUnassignFromUser,
  createBulkAnalise,
  createAnalise,
  generatePDF,
  getAnalise,
  sendEmail,
  unassign,
} from './analise';

// --------- DIVIDA ATIVA -----------------------------//
import { Types as DividaAtivaTypes } from '../ducks/dividaativa';
import { listAnalitico, listLancamentos, listValores } from './dividaativa';

// --------- CONFIGURACOES -----------------------------//
import { Types as ConfiguracoesTypes } from '../ducks/configuracoes';
import { editConfiguracoes, getConfiguracoes } from './configuracoes';

// --------- AUDITORIA -----------------------------//
import { Types as AuditoriaTypes } from '../ducks/auditoria';
import {
  createAuditoria,
  listAuditoria,
  listAuditoriaDocumento,
  listAuditoriaDocumentoWF,
  reportAuditoria,
} from './auditoria';

// --------- RECLAMACOES -----------------------------//
import { Types as ReclamacoesTypes } from '../ducks/reclamacoes';
import {
  cipsNaoRespEvolucao,
  cipsNaoRespPrazo,
  cipsRespPrazo,
  listReclamacoesProcon,
  reclamacoesEvolucaoCadastro,
  totalCipsNaoResp,
  totalCipsNaoRespPorSindec,
  totalRecPorSindec,
} from './reclamacoes';

// --------- RECLAMACOES -----------------------------//
import { Types as ProcessosTypes } from '../ducks/processos';
import {
  acoesUF,
  analiticoProcessos,
  capturaAcaoJudicial,
  capturaAcaoJudicialPrazo,
  monitoria,
  naturezaOperacao,
  statusAcoes,
} from './processos';

// -------- CHECKLISTDOCUMENTO --------------------//
import { Types as ChecklistdocumentoTypes } from '../ducks/checklistdocumento';
import {
  getChecklistdocumento,
  updateChecklistDocumento,
  uploadInformation,
  uploadImagemRecorrencia,
  syncChecklistdocumento,
} from './checklistdocumento';

// -------- GRUPOSEMPRESARIAIS -----------//
import { Types as GruposEmpresariaisTypes } from 'store/ducks/gruposempresariais';
import {
  listGruposEmpresariais,
  saveGrupoEmpresarial,
} from './gruposempresariais';

// -------- GRAPHS --------- //
import { Types as GraphsTypes } from 'store/ducks/graphs';
import { getTotalByCompany } from './graphs';

import acao from './acao';
import acessosrpa from './acessosrpa';
import acoesauditoria from './acoesauditoria';
import agenda from './agenda';
import andamentos from './andamentos';
import assessoria from './assessoria';
import asynchronousresults from './asynchronousresults';
import bancos from './bancos';
import bloqueio from './bloqueio';
import bloqueios from './bloqueios';
import comarca from './comarca';
import comentarios from './comentarios';
import denominacoes from './denominacoes';
import despesa from './despesa';
import despesas from './despesas';
import empresa from './empresa';
import encerramento from './encerramento';
import encerramentos from './encerramentos';
import esfera from './esfera';
import imagensmodalidade from './imagensmodalidade';
import instancia from './instancia';
import kanban from './kanban';
import mensagem from './mensagem';
import mensagens from './mensagens';
import modalidades from './modalidades';
import naturezas from './naturezas';
import notificacoes from './notificacoes';
import parte from './parte';
import partes from './partes';
import processo from './processo';
import providencia from './providencia';
import providencias from './providencias';
import provisoes from './provisoes';
import separadores from './separadores';
import template from './template';
import tiposdespesas from './tiposdespesas';
import tipostarefas from './tipostarefas';
import tribunal from './tribunal';
import uf from './uf';
import ocorrencias from './ocorrencias';
import unidades from './unidades';
import assistant from './assistant';
import bulkimport from './bulkimport';
import onboarding from './onboarding';
import classificacao from './classificacao';
import whatsapp from "./whatsapp"

/**
 * NOTE: RELACIONAMENTOS
 */
import { Types as RelacionamentosTypes } from 'store/ducks/relacionamentos';

import {
  createRelacionamento,
  editRelacionamento,
  editRelacionamentoPermissionUser,
  listRelacionamentos,
  removeRelacionamento,
} from './relacionamentos';

import { ACTIONS as PETICIONAMENTO_ACTIONS } from 'store/ducks/peticionamento';
import grupoacesso from './grupoacesso';
import {
  deleteParagraph,
  getParagraphs,
  saveParagraph,
  updateParagraph,
} from './peticionamento';
import { getTemplates } from './template';
import authSaga from './login';

export default function* rootSaga() {
  yield all([
    authSaga(),

    //------ PARAGRAPHS ------/
    takeEvery(PETICIONAMENTO_ACTIONS.GET_PARAGRAPHS_REQUEST, getParagraphs),
    takeEvery(
      PETICIONAMENTO_ACTIONS.DELETE_SINGLE_PARAGRAPH_REQUEST,
      deleteParagraph
    ),
    takeLatest(PETICIONAMENTO_ACTIONS.SAVE_PARAGRAPH_REQUEST, saveParagraph),
    takeLatest(
      PETICIONAMENTO_ACTIONS.UPDATE_SINGLE_PARAGRAPH_REQUEST,
      updateParagraph
    ),
    //------ TEMPLATE ------/
    takeEvery(PETICIONAMENTO_ACTIONS.GET_TEMPLATES_REQUEST, getTemplates),
    template,
    // --------- WELCOME -----------------------------//
    takeLatest(WelcomeTypes.LATEST_NEWS_REQUEST, latestNewsList),
    takeLatest(WelcomeTypes.BI_TOKEN_REQUEST, biToken),
    // --------- PERMISSÕES -----------------------------//
    takeLatest(PermissoesTypes.GET_PERMISSOES_REQUEST, getPermissoes),
    takeLatest(PermissoesTypes.UPDATE_PERMISSOES_REQUEST, updatePermissoes),
    takeLatest(
      PermissoesTypes.GET_PERMISSOES_ANOTHER_USER_REQUEST,
      getPermissoesAnotherUser
    ),

    // --------- TEMP STORAGE -----------------------------//
    takeLatest(TempStorageTypes.NEW_PART_REQUEST, createPartTempStorage),
    takeLatest(TempStorageTypes.NEW_STORAGE_REQUEST, callStorage),

    // --------- USUÁRIO -----------------------------//

    takeLatest(UsuariosTypes.SEARCH_BY_NAME_REQUEST, searchAnalystsByName),
    takeLatest(UsuariosTypes.LOGIN_REQUEST, loginUser),
    takeLatest(UsuariosTypes.LOGIN_KC_REQUEST, loginUserKc),
    takeLatest(UsuariosTypes.LOGOUT_REQUEST, logoutUser),
    takeLatest(UsuariosTypes.LOGIN_WITH_TOKEN_REQUEST, loginWithToken),
    takeLatest(UsuariosTypes.GO_TO_APP_REQUEST, goToApp),
    takeLatest(UsuariosTypes.LIST_REQUEST, listUsers),
    takeLatest(UsuariosTypes.EDIT_REQUEST, editUser),
    takeLatest(UsuariosTypes.LIST_REQUEST_BY_DEPTO, listUsersByDepartamento),
    takeLatest(UsuariosTypes.CHANGE_PASSWORD_REQUEST, changePassword),
    takeLatest(UsuariosTypes.FORGOT_PASSWORD_REQUEST, forgotPassword),
    takeLatest(UsuariosTypes.EDIT_EMPRESA_REQUEST, editEmpresaPermissionUser),
    takeLatest(UsuariosTypes.VALIDATE_TOKEN_REQUEST, validateToken),
    takeLatest(
      UsuariosTypes.CHANGE_PASSWORD_BY_LOGIN_REQUEST,
      changePasswordByLogin
    ),
    takeLatest(
      UsuariosTypes.CHANGE_PASSWORD_BY_NEW_PASS_REQUEST,
      changePasswordByNewPass
    ),
    takeLatest(
      UsuariosTypes.LIST_BY_ACCESS_GROUP_REQUEST,
      listUsersByAccessGroup
    ),
    takeLatest(UsuariosTypes.TWO_FACTOR_REQUEST, twoFactorValidate),

    // --------- EMPRESA -----------------------------//
    takeLatest(EmpresasTypes.NEW_REQUEST, createEmpresa),
    takeLatest(EmpresasTypes.LIST_REQUEST, listEmpresas),
    takeLatest(EmpresasTypes.EDIT_REQUEST, editEmpresa),
    takeLatest(EmpresasTypes.REMOVE_REQUEST, removeEmpresa),
    takeLatest(EmpresasTypes.LIST_BYCNPJ_REQUEST, findEmpresaByCNPJ),
    takeLatest(EmpresasTypes.GET_REQUEST_REGRAS, getRegras),

    // --------- DEPARTAMENTO -----------------------------//
    takeLatest(DeptoTypes.LIST_REQUEST, listDepartamentos),
    takeLatest(DeptoTypes.LIST_BYEMPRESA_REQUEST, listDepartamentosByEmpresa),
    takeLatest(DeptoTypes.NEW_REQUEST, createDepartamento),
    takeLatest(DeptoTypes.EDIT_REQUEST, editDepartamento),
    takeLatest(DeptoTypes.GET_REQUEST, getDepartamento),
    takeLatest(DeptoTypes.REMOVE_REQUEST, removeDepartamento),

    // --------- DOC SIGNATURE -----------------------------//
    takeLatest(DocSignatureTypes.BLOB_REQUEST, getBlob),
    takeLatest(DocSignatureTypes.B64_REQUEST, getB64),
    takeLatest(DocSignatureTypes.SEND_REQUEST, sendSignature),
    takeLatest(DocSignatureTypes.LIST_REQUEST, listDocSignature),

    // --------- DEPARTAMENTO -----------------------------//
    takeLatest(TipoDepartamentoTypes.LIST_REQUEST, listTipoDepartamentos),
    takeLatest(TipoDepartamentoTypes.NEW_REQUEST, createTipoDepartamento),
    takeLatest(TipoDepartamentoTypes.EDIT_REQUEST, editTipoDepartamento),
    takeLatest(TipoDepartamentoTypes.GET_REQUEST, getTipoDepartamento),
    takeLatest(TipoDepartamentoTypes.REMOVE_REQUEST, removeTipoDepartamento),

    // --------- CATEGORIA -----------------------------//
    takeLatest(CategoriasTypes.LIST_REQUEST, listCategorias),
    takeLatest(CategoriasTypes.NEW_REQUEST, createCategoria),
    takeLatest(CategoriasTypes.EDIT_REQUEST, editCategoria),
    takeLatest(CategoriasTypes.GET_REQUEST, getCategoria),

    // --------- IMPORTAÇÃO -----------------------------//
    takeLatest(ImportTypes.NEW_REQUEST, createImportacao),
    takeLatest(ImportTypes.NEW_REQUEST_HEADER, createImportacaoHeader),

    // --------- EXPORTAÇÃO -----------------------------//
    takeLatest(ExportTypes.NEW_REQUEST, createExportacao),

    // --------- EXPORTAÇÃO UNIC -----------------------------//
    takeLatest(ExportUnicTypes.NEW_REQUEST, createExportacaoUnic),

    // --------- REPORT -----------------------------//
    takeLatest(ReportTypes.NEW_REQUEST, createReport),
    takeLatest(ReportTypes.SEND_AS_MAIL_REQUEST, sendReportAsMail),

    // --------- DASHBOARD -----------------------------//
    takeLatest(DashboardTypes.NEW_REQUEST, createDashboard),
    takeLatest(DashboardTypes.SLA_FILTER_REQUEST, slaFilterList),
    takeLatest(
      DashboardTypes.DOCUMENTS_MAX_SCALLING_SLA_REQUEST,
      documentsOnMaxScallingWithoutAnswerRequest
    ),

    // --------- DASHBOARD CUSTOM -----------------------------//
    takeLatest(DashboardCustomTypes.NEW_REQUEST, createDashboardCustom),
    takeLatest(
      DashboardCustomTypes.NEW_REQUEST_YEAR,
      createDashboardYearCustom
    ),
    takeLatest(
      DashboardCustomTypes.PREDEFINITIONS_REQUEST,
      getDashPredefinitions
    ),
    takeLatest(DashboardCustomTypes.EXPORT_REQUEST, exportCustomTasks),

    // --------- DASHBOARD SCALING PENDING -----------------------------//
    takeLatest(DashboardScalingPending.NEW_REQUEST, createScalingPending),
    takeLatest(DashboardScalingPending.EXPORT_REQUEST, exportScalingPending),

    // --------- PRODUCTIVITY  -----------------------------//
    takeLatest(ProductivityTypes.NEW_REQUEST, createProductivity),
    takeLatest(ProductivityTypes.EXPORT_REQUEST, exportProductivity),

    // --------- LAYOUT -----------------------------//
    takeLatest(LayoutTypes.NEW_REQUEST, createLayout),
    takeLatest(LayoutTypes.EDIT_REQUEST, editLayout),
    takeLatest(LayoutTypes.GET_REQUEST, getLayout),
    takeLatest(LayoutTypes.LIST_REQUEST, listLayout),

    // --------- CARGA -----------------------------//
    takeLatest(CargaTypes.EDIT_REQUEST, editCarga),
    takeLatest(CargaTypes.LIST_REQUEST, listCarga),

    // --------- RPA -----------------------------//
    takeLatest(RPATypes.LIST_REQUEST, listRPA),
    takeLatest(RPATypes.EDIT_REQUEST, editRPA),

    // --------- CONFIG DASHBOARD EXTERNO -----------------------------//
    takeLatest(ConfiguracaoDashboardExternoTypes.LIST_REQUEST, listConfiguracaoDashboardExterno),
    takeLatest(ConfiguracaoDashboardExternoTypes.NEW_REQUEST, editConfiguracaoDashboardExterno),

    // --------- DASHBOARD EXTERNO -----------------------------//
    takeLatest(DashboardExternoTypes.NEW_REQUEST, getDashboardExterno),

    // --------- CARGA ITENS---------------------------//
    takeLatest(CargaItensTypes.LIST_BY_CARGAID_REQUEST, listByCargaId),
    takeLatest(CargaItensTypes.MARK_AS_REMOVED_REQUEST, markAsRemoved),

    // --------- DEFINIÇÃO SLA -----------------------//
    takeLatest(DefinicaoSLATypes.NEW_REQUEST, createSLA),
    takeLatest(DefinicaoSLATypes.EDIT_REQUEST, editSLA),
    takeLatest(DefinicaoSLATypes.GET_REQUEST, getSLA),
    takeLatest(DefinicaoSLATypes.LIST_REQUEST, listSLA),

    // --------- ESCALONAMENTO -----------------------//
    takeLatest(
      DepartamentoEscalonamentoTypes.NEW_REQUEST,
      createDepartamentoEscalonamento
    ),
    takeLatest(
      DepartamentoEscalonamentoTypes.EDIT_REQUEST,
      editDepartamentoEscalonamento
    ),
    takeLatest(
      DepartamentoEscalonamentoTypes.GET_REQUEST,
      getDepartamentoEscalonamento
    ),
    takeLatest(
      DepartamentoEscalonamentoTypes.LIST_REQUEST,
      listDepartamentoEscalonamento
    ),

    // --------- FORMULÁRIOS -----------------------------//
    takeLatest(FormularioTypes.NEW_REQUEST, createFormulario),
    takeLatest(FormularioTypes.EDIT_REQUEST, editFormulario),
    takeLatest(FormularioTypes.GET_REQUEST, getFormulario),
    takeLatest(FormularioTypes.LIST_REQUEST, listFormularios),
    takeLatest(FormularioTypes.DELETE_REQUEST, deleteFormulario),
    takeLatest(FormularioTypes.SEND_REQUEST, sendFormulario),
    takeLatest(FormularioTypes.VALIDATE_REQUEST, validaFormulario),
    takeLatest(FormularioTypes.RESPONSE_REQUEST, respostaFormulario),
    takeLatest(FormularioTypes.GET_NOAUTH_REQUEST, getPublicFormulario),

    // --------- GRUPO DE EMAIL -----------------------------//
    takeLatest(GrupoEmailTypes.NEW_REQUEST, createGrupoEmail),
    takeLatest(GrupoEmailTypes.EDIT_REQUEST, editGrupoEmail),
    takeLatest(GrupoEmailTypes.GET_REQUEST, getGrupoEmail),
    takeLatest(GrupoEmailTypes.LIST_REQUEST, listGrupoEmail),
    takeLatest(GrupoEmailTypes.DELETE_REQUEST, deleteGrupoEmail),
    takeLatest(
      GrupoEmailTypes.DELETE_MAIL_REQUEST,
      deleteEmailDosGruposDeEmail
    ),
    takeLatest(GrupoEmailTypes.ADD_MAIL_REQUEST, addEmailDosGruposDeEmail),

    // --------- MAIL -----------------------------//
    takeLatest(MailTypes.NEW_REQUEST, createMail),
    takeLatest(MailTypes.LIST_REQUEST, listMail),
    takeLatest(MailTypes.UNREADED_LIST_REQUEST, listUnreadedMail),
    takeLatest(MailTypes.READ_MAIL_REQUEST, readMail),
    takeLatest(MailTypes.FORCE_SCALLING_REQUEST, forceScalling),
    takeLatest(MailTypes.EXPORT_MAIL_REQUEST, exportEmail),
    takeLatest(
      MailTypes.LIST_MAX_SCALLING_MAILS_WITHOUT_ANSWER_FROM_DOCUMENT_REQUEST,
      listMaxScallingMailsWithoutAnswerFromDocument
    ),
    takeLatest(
      MailTypes.REMOVE_MAIL_FROM_MAX_SCALING_LIST_REQUEST,
      removeMailFromMaxScallingList
    ),
    takeLatest(MailTypes.RESEND_REQUEST, resendingEmailWithError),

    // --------- TIPO IMAGENS -----------------------------//
    takeLatest(TipoImgTypes.LIST_REQUEST, listTipoImagem),
    takeLatest(TipoImgTypes.REPORT_REQUEST, reportTipoImagem),
    takeLatest(TipoImgTypes.REMOVE_REQUEST, removeTipoImagem),
    takeLatest(TipoImgTypes.NEW_REQUEST, createTipoImagem),
    takeLatest(TipoImgTypes.EDIT_REQUEST, editTipoImagem),
    takeLatest(TipoImgTypes.GET_REQUEST, getTipoImagem),
    takeLatest(
      TipoImgTypes.LIST_BY_TIPODOC_REQUEST,
      listTipoImagemByTipoDocumento
    ),
    takeLatest(
      TipoImgTypes.EDIT_INDEXADORES_REQUEST,
      editIndexadoresTipoImagem
    ),

    // --------- TIPO DOC -----------------------------//
    takeLatest(TipoDocTypes.LIST_REQUEST, listTipoDocs),
    takeLatest(TipoDocTypes.LIST_BY_EMPRESAS_REQUEST, listTipoDocsByEmpresas),
    takeLatest(TipoDocTypes.NEW_REQUEST, createTipoDoc),
    takeLatest(TipoDocTypes.REMOVE_REQUEST, removeTiposDocs),
    takeLatest(TipoDocTypes.EDIT_REQUEST, editTipoDoc),
    takeLatest(TipoDocTypes.EDIT_INDEXADORES_REQUEST, editIndexadoresTipoDoc),
    takeLatest(TipoDocTypes.GET_INDEXADORES_REQUEST, getIndexadores),
    takeLatest(TipoDocTypes.UPDATE_RULES_REQUEST, updateDocumentTypeRules),
    // --------- FILA -----------------------------//
    takeLatest(FilaTypes.LIST_FLUXO_REQUEST, listFluxo),
    takeLatest(FilaTypes.LIST_REQUEST, listFila),
    takeLatest(FilaTypes.NEW_REQUEST, createFila),
    takeLatest(FilaTypes.EDIT_REQUEST, editFila),
    takeLatest(FilaTypes.GET_REQUEST, getFila),
    takeLatest(FilaDetalhesTypes.LIST_REQUEST_DETAILS, listFilaDetails),
    takeLatest(FilaDetalhesTypes.EXPORT_REQUEST_DETAILS, exportTasks),
    takeLatest(FilaDetalhesTypes.EXPORT_TASKS_REQUEST_DETAILS, exportTasks),

    takeLatest(AgrupamentoTypes.NEW_REQUEST, createAgrupamento),
    takeLatest(AgrupamentoTypes.EDIT_REQUEST, editAgrupamento),
    takeLatest(AgrupamentoTypes.LIST_REQUEST, listAgrupamento),
    takeLatest(AgrupamentoTypes.REMOVE_REQUEST, removeAgrupamento),
    takeLatest(AgrupamentoTypes.LIST_STEPS_REQUEST, detalhesEtapas),

    takeLatest(PesosProdutividadeTypes.NEW_REQUEST, createPesosProdutividade),
    takeLatest(PesosProdutividadeTypes.LIST_REQUEST, listPesosProdutividade),

    // --------- ETAPAS -----------------------------//
    takeLatest(EtapasTypes.LIST_REQUEST, listEtapas),
    takeLatest(EtapasTypes.NEW_REQUEST, createEtapas),
    takeLatest(EtapasTypes.EDIT_REQUEST, editEtapa),

    // --------- DOCUMENTOS -----------------------------//
    takeLatest(DocumentoTypes.LIST_REQUEST_BY_EMPRESA, listDocumentosByEmpresa),
    takeLatest(DocumentoTypes.LIST_REQUEST_BY_ETAPAS, listDocumentosByEtapas),
    takeLatest(
      DocumentoTypes.LIST_REQUEST_BY_ETAPAS_RELATORIO,
      listDocumentosByEtapaRelatorio
    ),
    takeLatest(DocumentoTypes.EXPORT_TO_CSV_REQUEST, exportDocumentosToCSV),
    takeLatest(DocumentoTypes.UPDATE_INDEXADOR_REQUEST, updateIndexadores),
    takeLatest(DocumentoTypes.UPDATE_TYPE_IMAGE_ARCHIEVE_REQUEST, updateTypeImageArchieve),
    takeLatest(
      DocumentoTypes.UPDATE_INDEXADOR_IMAGE_REQUEST,
      updateIndexadoresImage
    ),
    takeLatest(
      DocumentoTypes.DOWNLOAD_IMAGES_AS_ZIP_REQUEST,
      downloadImagensAsZip
    ),
    takeLatest(DocumentoTypes.CONFIRMACAO_DE_PRESENCA_REQUEST, confirmacaoPresenca),
    takeLatest(DocumentoTypes.ADVANCED_SEARCH_RESULT_REQUEST, advancedSearch),
    takeLatest(DocumentoTypes.NEW_REQUEST, createDocumento),
    takeLatest(DocumentoTypes.GET_DOCUMENTO_REQUEST, getDocumento),
    takeLatest(DocumentoTypes.AUTOCOMPLETE_REQUEST, autocompleteDocumentos),
    takeLatest(DocumentoTypes.GO_SEARCH_REQUEST, goSearchDocumentos),
    takeLatest(DocumentoTypes.SEARCH_ES_BY_ID_REQUEST, searchEsById),
    takeLatest(DocumentoTypes.UPDATE_IMAGENS_REQUEST, updateImagens),
    takeLatest(DocumentoTypes.REMOVE_IMAGEM_REQUEST, removeImagem),
    takeLatest(DocumentoTypes.UPDATE_IMAGENS_REQUEST_V2, updateImagensV2),
    takeLatest(DocumentoTypes.SEND_EMAIL_BY_APP_REQUEST, sendEmailByApp),
    takeLatest(DocumentoTypes.NEW_UPLOAD_VERSION_REQUEST, uploadNewVersion),
    takeLatest(DocumentoTypes.NEW_MERGE_REQUEST, mergeImagens),
    takeLatest(DocumentoTypes.REOPEN_REQUEST, reopen),
    takeLatest(DocumentoTypes.GENERATE_THUMBNAILS_REQUEST, generateThumbnails),
    takeLatest(DocumentoTypes.DOWNLOAD_DOCS_REQUEST, downloadDocs),

    takeLatest(NaturezasTypes.LIST_REQUEST_ENUM, listNaturezasEnum),

    // --------- CHECK TIPOS -----------------------------//
    takeLatest(
      CheckTipoTypes.LIST_BY_ETAPA_REQUEST,
      listParamFormalizacaoByEtapa
    ),
    takeLatest(CheckTipoTypes.NEW_REQUEST, createCheckTipo),
    takeLatest(CheckTipoTypes.EDIT_REQUEST, editCheckTipo),
    takeLatest(CheckTipoTypes.GET_REQUEST, showCheckTipo),
    takeLatest(CheckTipoTypes.NEW_TITULO_REQUEST, createCheckTitulo),
    takeLatest(CheckTipoTypes.REMOVE_TITULO_REQUEST, removeCheckTitulo),
    takeLatest(CheckTipoTypes.REMOVE_ITEM_REQUEST, removeCheckItem),
    takeLatest(CheckTipoTypes.NEW_ITEM_REQUEST, createCheckItem),
    takeLatest(
      CheckTipoTypes.SEARCH_CHECK_EMPRESA_REQUEST,
      listParamFormalizacaoByEmpresa
    ),

    // --------- CONFIG CHECKLIST AUDITORIA -----------------------------//
    takeLatest(
      ParamFormalizacaoAuditoriaTypes.GET_REQUEST,
      getParamFormalizacaoAuditoria
    ),

    // --------- ANALISE -----------------------------//
    takeLatest(AnaliseTypes.NEW_REQUEST, createAnalise),
    takeLatest(AnaliseTypes.NEW_BULK_REQUEST, createBulkAnalise),
    takeLatest(AnaliseTypes.GENERATE_PDF_REQUEST, generatePDF),
    takeLatest(AnaliseTypes.SEND_EMAIL_REQUEST, sendEmail),
    takeLatest(AnaliseTypes.GET_REQUEST, getAnalise),
    takeLatest(AnaliseTypes.UNASSIGN_REQUEST, unassign),
    takeLatest(AnaliseTypes.BATCH_UNASSIGN_USER_REQUEST, batchUnassignFromUser),
    takeLatest(AnaliseTypes.ASSIGN_USER_REQUEST, assignTaskToUser),

    // --------- PARAM FORMALIZACAO ELETRÔNICA -----------------------------//
    takeLatest(
      ParamAssinaturaEletronicaTypes.LIST_REQUEST,
      listAssinaturaEletronica
    ),
    takeLatest(
      ParamAssinaturaEletronicaTypes.EDIT_REQUEST,
      editAssinaturaEletronica
    ),

    // --------- TEMPLATE SIGNATURE POINTS -----------------------------//
    takeLatest(
      TemplateSignaturePointsTypes.LIST_REQUEST,
      listTemplateSignaturePoints
    ),
    takeLatest(
      TemplateSignaturePointsTypes.EDIT_REQUEST,
      editTemplateSignaturePoints
    ),

    // --------- EXPORTAÇÃO DOCUMENTO -----------------------------//
    takeLatest(ExportingDocumentTypes.NEW_REQUEST, createExportation),

    // --------- RESPOSTA ÓRGÃOS -----------------------------//
    takeLatest(OrgaosAnswerTypes.NEW_REQUEST, createOrgaosAnswer),
    takeLatest(OrgaosAnswerTypes.LIST_ORGAOS_REQUEST, listOrgaos),
    takeLatest(OrgaosAnswerTypes.LIST_OPCOES_REQUEST, listTipoManifestacoes),

    // --------- DIVIDA ATIVA -----------------------------//
    takeLatest(DividaAtivaTypes.LIST_LANCAMENTOS_REQUEST, listLancamentos),
    takeLatest(DividaAtivaTypes.LIST_VALORES_REQUEST, listValores),
    takeLatest(DividaAtivaTypes.LIST_ANALITICO_REQUEST, listAnalitico),

    // --------- ORGANIZAÇÃO -----------------------------//
    takeLatest(OrganizacoesTypes.LIST_REQUEST, listOrganizacoes),

    // --------- VERSÕES DE IMAGEM -----------------------------//
    takeLatest(VersionsImageTypes.LIST_REQUEST, getVersionsImage),

    // --------- ORGANIZAÇÃO -----------------------------//
    takeLatest(MenuTypes.TOGGLE_MENU, toggleMenuSaga),

    takeLatest(MenusTypes.LIST_REQUEST, listMenus),
    takeLatest(MenusTypes.EDIT_REQUEST, editMenu),
    takeLatest(MenusTypes.NEW_REQUEST, createMenu),
    takeLatest(MenusTypes.REMOVE_REQUEST, removeMenu),

    // --------- AUDITORIA -----------------------------//
    takeLatest(AuditoriaTypes.NEW_REQUEST, createAuditoria),
    takeLatest(AuditoriaTypes.LIST_REQUEST, listAuditoria),
    takeLatest(AuditoriaTypes.REPORT_REQUEST, reportAuditoria),
    takeLatest(AuditoriaTypes.LIST_DOCUMENTO_REQUEST, listAuditoriaDocumento),
    takeLatest(
      AuditoriaTypes.LIST_DOCUMENTO_WF_REQUEST,
      listAuditoriaDocumentoWF
    ),
    takeLatest(
      AuditoriaTypes.LIST_IDENTIFICADOR_REQUEST,
      listAuditoriaDocumento
    ),

    // --------- CONFIGURACOES -----------------------------//
    takeLatest(ConfiguracoesTypes.GET_REQUEST, getConfiguracoes),
    takeLatest(ConfiguracoesTypes.EDIT_REQUEST, editConfiguracoes),

    // --------- RECLAMACOES -----------------------------//
    takeLatest(
      ReclamacoesTypes.LIST_RECLAMACOES_PROCON_REQUEST,
      listReclamacoesProcon
    ),
    takeLatest(
      ReclamacoesTypes.TOTAL_RECLAMACOES_POR_SINDEC_REQUEST,
      totalRecPorSindec
    ),
    takeLatest(
      ReclamacoesTypes.TOTAL_CIPSNAORESP_POR_SINDEC_REQUEST,
      totalCipsNaoRespPorSindec
    ),
    takeLatest(ReclamacoesTypes.TOTAL_CIPSNAORESP_REQUEST, totalCipsNaoResp),
    takeLatest(
      ReclamacoesTypes.TOTAL_CIPSNAORESP_PRAZO_REQUEST,
      cipsNaoRespPrazo
    ),
    takeLatest(
      ReclamacoesTypes.RECLAMACOES_EVOLUCAO_REQUEST,
      reclamacoesEvolucaoCadastro
    ),
    takeLatest(
      ReclamacoesTypes.CIPSNAORESP_EVOLUCAO_REQUEST,
      cipsNaoRespEvolucao
    ),
    takeLatest(ReclamacoesTypes.TOTAL_CIPSRESP_PRAZO_REQUEST, cipsRespPrazo),

    // -------- PROCESSOS ---------- //

    takeLatest(ProcessosTypes.STATUS_ACOES_REQUEST, statusAcoes),
    takeLatest(ProcessosTypes.ACOES_UF_REQUEST, acoesUF),
    takeLatest(ProcessosTypes.MONITORIA_REQUEST, monitoria),
    takeLatest(ProcessosTypes.NATUREZA_OPERACAO_REQUEST, naturezaOperacao),
    takeLatest(
      ProcessosTypes.CAPTURA_ACAO_JUDICIAL_REQUEST,
      capturaAcaoJudicial
    ),
    takeLatest(
      ProcessosTypes.CAPTURA_ACAO_JUDICIAL_PRAZO_REQUEST,
      capturaAcaoJudicialPrazo
    ),
    takeLatest(ProcessosTypes.ANALITICO_REQUEST, analiticoProcessos),

    // -------- CHECKLISTDOCUMENTO ---------- //
    takeLatest(
      ChecklistdocumentoTypes.GET_CHECKLISTDOCUMENTO_REQUEST,
      getChecklistdocumento
    ),
    takeLatest(
      ChecklistdocumentoTypes.UPLOAD_INFORMATION_REQUEST,
      uploadInformation
    ),
    takeLatest(
      ChecklistdocumentoTypes.UPDATE_CHECKLISTDOCUMENTO_REQUEST,
      updateChecklistDocumento
    ),
    takeLatest(
      ChecklistdocumentoTypes.UPLOAD_IMAGE_RECORRENCIA_REQUEST,
      uploadImagemRecorrencia
    ),
    takeLatest(
      ChecklistdocumentoTypes.SYNC_CHECKLISTDOCUMENTO_REQUEST,
      syncChecklistdocumento
    ),

    // -------- GRUPOS EMPRESARIAS --------- //
    takeLatest(GruposEmpresariaisTypes.LIST_REQUEST, listGruposEmpresariais),
    takeLatest(GruposEmpresariaisTypes.SAVE_REQUEST, saveGrupoEmpresarial),

    // -------- GRAPHS ---------- //
    takeLatest(GraphsTypes.TOTAL_DOCS_BY_COMPANY_REQUEST, getTotalByCompany),

    // -------- MODALIDADES -------- //
    modalidades,

    // -------- IMAGENS MODALIDADE --------//
    imagensmodalidade,

    // -------- SEPARADORES -------- //
    separadores,

    // -------- COMENTARIOS -------- //
    comentarios,

    // -------- EMPRESA -------- //
    empresa,

    // -------- TRIBUNAL -------- //
    tribunal,

    // -------- TRIBUNAL -------- //
    uf,

    // -------- COMARCA -------- //
    comarca,

    // -------- ESFERA -------- //
    esfera,

    // -------- INSTANCIA -------- //
    instancia,

    // -------- ACAO-------- //
    acao,

    // -------- GRUPO DE ACESSO-------- //
    grupoacesso,

    // -------- ASSESSORIA-------- //
    assessoria,

    // -------- ASYNCHRONOUS RESULTS -------- //
    asynchronousresults,

    // -------- UNIDADES -------- //
    unidades,

    // -------- NATUREZAS ------- //
    naturezas,

    // -------- TIPOS TAREFAS ------- //
    tipostarefas,

    // -------- TIPOS DESPESAS -------- //
    tiposdespesas,

    // -------- PARTES -------- //
    partes,

    // -------- PARTE -------- //
    parte,

    // -------- BLOQUEIOS -------- //
    bloqueios,

    // -------- BLOQUEIO -------- //
    bloqueio,

    // -------- ENCERRAMENTOS ------- //
    encerramentos,

    // -------- ENCERRAMENTO ------- //
    encerramento,

    // -------- DESPESAS -------- //
    despesas,

    // -------- DESPESA --------- //
    despesa,

    // -------- PROVIDENCIAS -------- //
    providencias,

    // -------- PROVIDENCIA -------- //
    providencia,

    // -------- MENSAGENS -------- //
    mensagens,

    // -------- MENSAGEM -------- //
    mensagem,

    // -------- AÇÕES AUDITORIA -------- //
    acoesauditoria,

    // -------- PROCESSO -------- //
    processo,

    // -------- DENOMINAÇÕES -------- //
    denominacoes,

    // -------- PROVISÃO ------- //
    provisoes,

    // -------- BANCOS ------ //
    bancos,

    // -------- NOTIFICAÇÕES -------- //
    notificacoes,

    //------ ANDAMENTOS ------/
    andamentos,

    //------ AGENDA ------/
    agenda,

    //------ KANBAN ------/
    kanban,

    //------ ACESSOS RPA ------/
    acessosrpa,

    //------ ASSISTANT ------/
    assistant,

    //------ BULKIMPORT ------/
    bulkimport,

    //------ ONBOARDING ------/
    onboarding,

    //------ CLASSIFICACAO ------/
    classificacao,

    //------ WHATSAPP ------/
    whatsapp,

    //------- OCORRENCIAS -------/
    ocorrencias,

    // -------- RELACIONAMENTOS ---------- //

    takeLatest(
      RelacionamentosTypes.NEW_RELACIONAMENTO_REQUEST,
      createRelacionamento
    ),
    takeLatest(
      RelacionamentosTypes.LIST_RELACIONAMENTO_REQUEST,
      listRelacionamentos
    ),
    takeLatest(
      RelacionamentosTypes.EDIT_RELACIONAMENTO_REQUEST,
      editRelacionamento
    ),

    takeLatest(
      RelacionamentosTypes.DELETE_RELACIONAMENTO_REQUEST,
      removeRelacionamento
    ),

    takeLatest(
      RelacionamentosTypes.EDIT_RELACIONAMENTO_PERMISSAO_REQUEST,
      editRelacionamentoPermissionUser
    ),
  ]);
}
