import React, { useState, useRef } from "react";

import { useDispatch } from "react-redux";

import { Creators as DefinicaoSLAActions } from "store/ducks/definicaosla";

import { Row, Col } from "react-bootstrap";
import { Form } from "@unform/web";

import FormRules from "../../Layout/FormRules/index";
import ButtonMui from "@material-ui/core/Button";
import BackButton from "components/BackButton";
import Grid from "@material-ui/core/Grid";
import { OUTROS_CAMPOS } from "utils/default-another-fields";
import Content from "components/Content";

export default function DefinicaoSLA(props) {
  const { tipodoc } = props.location.state;
  const { definicoes } = tipodoc;

  const INITIAL_FORM = {
    idTipoDocumento: tipodoc._id,
    _id: definicoes.length > 0 ? definicoes[0]._id : null,
    rules: definicoes.length > 0 ? definicoes[0].rules : [],
  };

  const dispatch = useDispatch();
  const [form, setForm] = useState(INITIAL_FORM);
  const formRef = useRef(null);

  const canSave = () => {
    return form.rules.length > 0;
  };

  const onSubmitLayout = () => {
    const payload = form;
    if (form._id) {
      dispatch(DefinicaoSLAActions.editRequest(payload));
    } else {
      delete payload._id;
      dispatch(DefinicaoSLAActions.createRequest(payload));
    }
  };

  const removeRule = (hash) => {
    if (hash) {
      setForm((prev) => ({
        ...form,
        rules: [
          ...prev.rules.filter(function (rule) {
            return rule.hash !== hash;
          }),
        ],
      }));
    }
  };

  const handleRule = (rule, key, value) => {
    if (key === "nome") {
      rule[key] = value;
    } else {
      rule.destinations[key] = value;
    }

    setForm((prev) => ({
      ...form,
      rules: [
        ...(prev.rules.filter(function (x) {
          return x.hash != rule.hash;
        }) || []),
        ...[rule],
      ],
    }));
  };

  const _newRule = () => {
    setForm((prev) => ({
      ...form,
      rules: [
        ...(prev.rules || []),
        ...[
          {
            nome: "",
            hash: Math.random(),
            conditions: [],
            destinations: {},
          },
        ],
      ],
    }));
  };

  const handleNewRule = () => {
    _newRule();
  };

  const handleCondition = (rule, condition, key, value) => {
    if (rule && condition && key && value) {
      condition[key] = value;
      _updateCondition(rule, condition);
    }
  };

  const _updateCondition = (rule, condition) => {
    rule.conditions = rule.conditions.filter(function (x) {
      return x.hash != condition.hash;
    });
    rule.conditions.push(condition);

    setForm((prev) => ({
      ...form,
      rules: [
        ...(prev.rules.filter(function (x) {
          return x.hash != rule.hash;
        }) || []),
        ...[rule],
      ],
    }));
  };

  const handleNewCondition = (hash) => {
    _newCondition(hash);
  };

  const _newCondition = (hash) => {
    if (hash) {
      let rules = [...form.rules];
      let rule = {
        ...form.rules.find(function (rule) {
          return rule.hash === hash;
        }),
      };

      rule.conditions.push({
        hash: Math.random(),
        atributo: "",
        condicao: "",
        valor: "",
      });
      rules.push(rule);

      setForm((prev) => ({
        ...form,
        rules: [
          ...(prev.rules.filter(function (x) {
            return x.hash != hash;
          }) || []),
          ...[rule],
        ],
      }));
    }
  };

  const handleRemoveCondition = (hash, hashcond) => {
    if (hash && hashcond) {
      let rule = {
        ...form.rules.find(function (rule) {
          return rule.hash === hash;
        }),
      };

      removeRule(hash);

      rule.conditions =
        rule.conditions.filter(function (x) {
          return x.hash != hashcond;
        }) || [];

      setForm((prev) => ({
        ...form,
        rules: [
          ...(prev.rules.filter(function (x) {
            return x.hash != hash;
          }) || []),
          ...[rule],
        ],
      }));
    }
  };

  return (
    <Content>
      <Row>
        <Col md="12" className="painel">
          <div className="painel-content">
            <div className="painel-title text-left">Definição SLA</div>
            <div className="painel-body">
              <Form
                ref={formRef}
                onSubmit={onSubmitLayout}
                className={"mt-2"}
              >
                <Row>
                  <FormRules
                    atributes={
                      tipodoc.indexadores?.concat(OUTROS_CAMPOS) ||
                      OUTROS_CAMPOS
                    }
                    handleRule={handleRule}
                    removeRule={removeRule}
                    handleNewRule={handleNewRule}
                    handleCondition={handleCondition}
                    handleNewCondition={handleNewCondition}
                    handleRemoveCondition={handleRemoveCondition}
                    rules={form.rules}
                    isSLA={true}
                  />
                  <Grid
                    style={{ marginLeft: "1rem" }}
                    container
                    spacing={1}
                    className="mt-2 mb-2"
                  >
                    <Grid item>
                      <ButtonMui
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={!canSave}
                      >
                        Salvar
                      </ButtonMui>
                    </Grid>
                    <Grid item>
                      <BackButton variant="contained" color="secondary">
                        Voltar
                      </BackButton>
                    </Grid>
                  </Grid>
                </Row>
              </Form>
            </div>
          </div>
        </Col>
      </Row>
    </Content>
  );
}
