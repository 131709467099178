import React, { useMemo, useState, useEffect } from 'react';
import {
  Table, TableHead, TableBody, TableRow, TableCell,
  TableContainer, TableFooter, TablePagination,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import CustomTableSortLabel from 'components/CustomTableSortLabel';
import LoadingTable from 'components/LoadingTable';
import StyledTableRow from 'components/StyledTableRow';
import ButtonSquare from "components/ButtonSquare";
import ToolTipComponent from "components/Tooltip";

import { Creators as TiposTarefasActions } from 'store/ducks/tipostarefas';

function TableTarefas({ userActions }) {
  const dispatch = useDispatch();

  const { loading, list: tarefas, total, page, limit, sort, search } = useSelector(state => state.tipostarefas);

  const [params, setParams] = useState({ page, limit, sort, search });

  const hasActions = useMemo(
    () =>
      true || (
      userActions.actions &&
      userActions.actions.some((userAct) => userAct.action === 'editar' || userAct.action === 'criar')),
    [userActions]
  );

  useEffect(() => {
    dispatch(TiposTarefasActions.listRequest(params))
  }, [dispatch, params]);

  function handleChangeRowsPerPage({ target }) {
    const { value } = target;
    setParams({ ...params, limit: value });
  }

  function handleChangePage(e, newPage) {
    setParams({ ...params, page: newPage + 1 });
  }

  function handleChangeSort(sort) {
    setParams({ ...params, sort });
  }

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>
              <CustomTableSortLabel
                onClick={handleChangeSort}
                currentSort={params.sort}
                sortField="nome"
              >
                Nome
              </CustomTableSortLabel>
            </TableCell>
            <TableCell>
              <CustomTableSortLabel
                onClick={handleChangeSort}
                currentSort={params.sort}
                sortField="prazo"
              >
                Prazo (em dias)
              </CustomTableSortLabel>
            </TableCell>
            {!!hasActions && 
            <TableCell align="center">
              Ações
            </TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {!!loading && <LoadingTable rows={limit} columns={hasActions ? 3 : 2} />}
          {!loading && 
          tarefas.map(tarefa => 
            <StyledTableRow key={tarefa._id}>
              <TableCell>{tarefa.nome}</TableCell>
              <TableCell>{tarefa.prazo ? tarefa.prazo : '-'}</TableCell>
              {!!hasActions &&
              <TableCell align="center">
                <div className="flex-direction">
                  <ToolTipComponent title="Editar" placement="top">
                    <Link
                      to={{
                        pathname: "tarefas/edit",
                        state: { tarefa },
                      }}
                    >
                      <ButtonSquare icon="edit" />
                    </Link>
                  </ToolTipComponent>
                </div>
              </TableCell>}
            </StyledTableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              count={total}
              page={page - 1}
              rowsPerPage={limit}
              rowsPerPageOptions={[10, 25, 50, 100]}
              labelRowsPerPage="Linhas por página"
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} de ${count !== -1 ? count : `mais que ${to}`}`
              }
              onRowsPerPageChange={handleChangeRowsPerPage}
              onPageChange={handleChangePage}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  )
}

export default TableTarefas;