import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

import TableResults from "./TableResults";

import { Creators as AsynchronousResultsActions } from "store/ducks/asynchronousresults";
import Content from "components/Content";

export default function AsynchronousResultsExecution() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(AsynchronousResultsActions.listRequest({}));
  }, [dispatch]);

  function handleChangePage(e, newPage) {
    dispatch(
      AsynchronousResultsActions.listRequest({
        page: newPage + 1,
        limit,
        sort,
      })
    );
  }

  function handleChangeRowsPerPage({ target }) {
    const { value } = target;
    dispatch(
      AsynchronousResultsActions.listRequest({
        page,
        limit: value,
        sort,
      })
    );
  }

  const {
    loading,
    docs,
    totalDocs: total,
    page,
    limit,
    sort,
  } = useSelector((state) => state.asynchronousresults);

  return (
    <Content>
      <Row>
        <Col md="12" className="painel">
          <div className="painel-content">
            <div className="painel-title text-left">
              <Col>
                <strong>Meus arquivos</strong>
              </Col>
            </div>
            <div className="painel-body">
              <TableResults
                page={page}
                docs={docs}
                total={total}
                limit={limit}
                loading={loading}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </div>
          </div>
        </Col>
      </Row>
    </Content>
  );
}
