import React, { useState, useEffect } from 'react';
import {
  Table, TableHead, TableBody, TableRow, TableCell, 
  TableContainer, TableFooter, TablePagination
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import StyledTableRow from 'components/StyledTableRow';
import { useLocation, Link } from 'react-router-dom';
import ButtonSquare from 'components/ButtonSquare';
import LoadingTable from 'components/LoadingTable';
import CustomTableSortLabel from 'components/CustomTableSortLabel';

import { Creators as GruposEmpresariaisActions } from 'store/ducks/gruposempresariais';

function TableGruposEmpresariais() {

  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const { loading, list: gruposEmpresariais, page = 1, limit, total, sort='nome' } = useSelector(state => state.gruposempresariais);
  const { access: { servicos }} = useSelector(state => state.permissoes);

  const [userActions, setUserActions] = useState([]);
  const [request, setRequest] = useState({ sort, page, limit: limit > 100 ? 10 : limit })

  useEffect(() => {
    let find = servicos.find(item => item.route === pathname);
    if(find !== undefined) { setUserActions(find) }
  }, [pathname, servicos]);

  useEffect(() => {
    dispatch(GruposEmpresariaisActions.listRequest(request));
  }, [dispatch, request]);

  function handleChangeRowsPerPage({ target }) {
    const { value } = target;
    setRequest({ ...request, limit: value });
  }

  function handleChangePage(e, newPage) {
    setRequest({ ...request, page: newPage + 1 });
  }

  function handleChangeSort(sort) {
    setRequest({ ...request, sort })
  }

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>
              <CustomTableSortLabel
                onClick={handleChangeSort}
                currentSort={request.sort}
                sortField="nome"
              >
                Nome
              </CustomTableSortLabel>
            </TableCell>
            {userActions.actions && 
            userActions.actions.some(userAct => userAct.action === 'editar') && 
              <TableCell align="center">Ações</TableCell> 
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {!!loading && <LoadingTable rows={request.limit} columns={2} />}
          {!loading && gruposEmpresariais.map(grupoEmpresarial => 
            <StyledTableRow key={grupoEmpresarial._id}>
              <TableCell>{grupoEmpresarial.nome}</TableCell>
              {userActions.actions && 
              userActions.actions.some(userAct => userAct.action === 'editar') && 
                <TableCell align="center">
                  <Link
                    to={{
                      pathname: 'gruposempresariais/edit',
                      state: { grupoEmpresarial }
                    }}
                    className="linkicon"
                    id="empresas"
                  >
                    <ButtonSquare 
                      icon="edit"
                    />
                  </Link>
                </TableCell> 
              }
            </StyledTableRow>
          )}
        </TableBody>
        {!loading && 
        <TableFooter>
          <TableRow>
            <TablePagination 
              count={total}
              page={request.page - 1}
              rowsPerPage={request.limit}
              rowsPerPageOptions={[10, 25, 50, 100]}
              labelRowsPerPage="Linhas por página"
              labelDisplayedRows={({ from, to, count }) => 
                `${from}-${to} de ${count !== -1 ? count : `mais que ${to}`}`
              }
              onRowsPerPageChange={handleChangeRowsPerPage}
              onPageChange={handleChangePage}
            />
          </TableRow>
        </TableFooter>}
      </Table>
    </TableContainer>
  );
}

export default TableGruposEmpresariais;