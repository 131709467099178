import api from "services/api";
import { getToken } from "services/auth";
import { call, select, put, all, takeLatest } from "redux-saga/effects";
import { toastr } from "react-redux-toastr";
import { push } from "connected-react-router";

import { Creators as FormulariosActions, Types } from "../ducks/formulario";

export const getOrganization = (state) => state.usuarios.auth.organization;
export const getUserId = (state) => state.usuarios.auth.id;

export function* listFormularios({ payload }) {
    try {
        const { page = 1, limit = 10, search } = payload;

        const organization = yield select(getOrganization);

        const headerParams = {
            headers: { Authorization: getToken(), xkeyorg: organization },
            params: { page, limit, search },
        };

        const response = yield call(api.get, `formulario`, headerParams);
        const data = {
            total: response.data.total,
            list: response.data.docs,
            limit: response.data.limit,
            page: response.data.page,
            pages: response.data.pages,
            origin: response.data.origin,
        };

        yield put(FormulariosActions.listSuccess(data));

    } catch (error) {
        toastr.error("Ops", "Não foi possível listar os formulários.");
    }
}

export function* getFormulario({ payload }) {
    try {
        const headerParams = {
            headers: { Authorization: getToken() },
        };

        const { data } = yield call(
            api.get,
            `formulario/${payload.id}`,
            headerParams
        );

        yield put(FormulariosActions.getSuccess(data));
    } catch (error) {
        toastr.error("Ops", "Não foi possível buscar os dados do formulário.");
    }
}

export function* editFormulario(action) {
    const userId = yield select(getUserId);

    try {
        const organization = yield select(getOrganization);

        const headerParams = {
            headers: { Authorization: getToken(), xkeyorg: organization },
        };

        const body = { ...action.payload, id_user_acao: userId };

        const { data } = yield call(
            api.put,
            `formulario/${action.payload._id}`,
            body,
            headerParams
        );

        yield put(push(`/formulario`));

        yield put(FormulariosActions.editSuccess(data));

        toastr.success("Formulário editado com sucesso.");
    } catch (error) {
        toastr.error("Ops", "Não foi possível editar formulário.");
    }
}

export function* deleteFormulario({ payload }) {
    const { _id: id } = payload;
    try {
        const organization = yield select(getOrganization);

        const headerParams = {
            headers: { Authorization: getToken(), xkeyorg: organization },
        };

        const response = yield call(
            api.delete,
            `formulario/${id}`,
            headerParams
        );

        if (response?.data?.error)
            throw new Error(
                response?.data?.message || "Não foi possível deletar formulário."
            );

        yield put(FormulariosActions.deleteSuccess());
        yield put(FormulariosActions.listRequest({}));

        toastr.success("Formulário removido com sucesso.");

        yield put(push(`/formulario`));
    } catch (error) {
        toastr.error(error.message);
    }
}

export function* createFormulario(action) {
    const organization = yield select(getOrganization);

    try {
        const headerParams = {
            headers: { Authorization: getToken(), xkeyorg: organization },
        };

        const { data } = yield call(
            api.post,
            `formulario`,
            action.payload,
            headerParams
        );

        yield put(push(`/formulario`));

        yield put(FormulariosActions.createSuccess(data));

        toastr.success("", "Formulário criado com sucesso.");
    } catch (error) {
        toastr.error("Ops", "Não foi possível criar o formulário.");
    }
}

export function* sendFormulario({ payload }) {
    const organization = yield select(getOrganization);

    try {
        const headerParams = {
            headers: { Authorization: getToken(), xkeyorg: organization },
        };

        const { data } = yield call(
            api.post,
            `formulario/send-link`,
            payload,
            headerParams
        );

        yield put(FormulariosActions.sendSuccess(data));

        toastr.success("", data.message);
    } catch (error) {
        toastr.error("Ops", "Não foi possível enviar o formulário.");
    }
}

export function* validaFormulario({ payload }) {
    try {

        const ETAPA_USUARIO = 0;

        const headerParams = {
            headers: payload,
        };

        let success = false;
        let message = '';

        if (payload.etapa === ETAPA_USUARIO) {
            const { data } = yield call(
                api.get,
                `formulario-publico/validacao`,
                headerParams
            );

            yield put(FormulariosActions.validateSuccess(data));

            success = data.success;
            message = data.message;

        } else {
            const { data } = yield call(
                api.get,
                `formulario-publico/validacao-codigo`,
                headerParams
            );

            yield put(FormulariosActions.validateSuccess(data));

            success = data.success;
            message = data.message;
        }

        if (success) toastr.success("", message);
        else toastr.error("", message);

    } catch (error) {
        toastr.error("Ops", "Não foi possível validar o formulário.");
    }
}

export function* respostaFormulario({ payload }) {
    try {

        const { xkeyorg } = payload;

        const headerParams = {
            headers: { xkeyorg },
        };

        delete payload.xkeyorg;

        const { data } = yield call(
            api.post,
            `formulario-publico/resposta`,
            payload,
            headerParams
        );

        yield put(FormulariosActions.sendSuccess(data));

        if (data.status) toastr.success("", data.message);
        else toastr.error("Ops", data.message);

    } catch (error) {
        toastr.error("Ops", "Não foi possível salvar a resposta do formulário.");
    }
}

export function* getPublicFormulario({ payload }) {
    try {
        const { id, xkeyorg } = payload;

        const headerParams = {
            headers: { xkeyorg },
        };

        const { data } = yield call(
            api.get,
            `formulario-publico/formulario/${id}`,
            headerParams
        );

        yield put(FormulariosActions.getNoAuthSuccess(data));
    } catch (error) {
        console.log(error);
        toastr.error("Ops", "Não foi possível buscar os dados do formulário.");
    }
}

export default all([
    takeLatest(Types.LIST_REQUEST, listFormularios),
    takeLatest(Types.GET_REQUEST, getFormulario),
    takeLatest(Types.EDIT_REQUEST, editFormulario),
    takeLatest(Types.NEW_REQUEST, createFormulario),
    takeLatest(Types.SEND_REQUEST, sendFormulario),
    takeLatest(Types.VALIDATE_REQUEST, validaFormulario),
    takeLatest(Types.RESPONSE_REQUEST, respostaFormulario),
    takeLatest(Types.GET_NOAUTH_REQUEST, getPublicFormulario),
]);
