import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import _ from 'lodash';

import GridCadastro from "components/GridCadastro";
import { useLocation } from "react-router-dom";
import TableTemplate from "./TableTemplate";

export default function Template() {

  const { pathname } = useLocation();

  const {
    access: { servicos },
  } = useSelector((state) => state.permissoes);

  const [userActions, setUserActions] = useState([]);

  useEffect(() => {
    let find = servicos.find((item) => item.route === pathname);
    if (find !== undefined) {
      setUserActions(find);
    }
  }, [pathname, servicos]);

  return (
    <GridCadastro
      title='Templates'
      route={"/template/edit"}
      titleButtonNovo="Novo Template"
    >
      <TableTemplate userActions={userActions} />
    </GridCadastro>
  )
}