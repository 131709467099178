import React, { useEffect } from "react";

import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";

import { useSelector, useDispatch } from "react-redux";
import { format, parseISO } from "date-fns";

import CustomTableSortLabel from "components/CustomTableSortLabel";
import LoadingTable from "components/LoadingTable";
import StyledTableRow from "components/StyledTableRow";
import ToolTipComponent from "components/Tooltip";
import ButtonSquare from "components/ButtonSquare";

import SelectFilesToUpload from "components/SelectFilesToUpload";
import DialogPreviewImages from "components/DialogPreviewImages";

import MotivoEncerramento from "./MotivoEncerramento";

import { Creators as EncerramentoActions } from "store/ducks/encerramento";
import { Creators as EncerramentosActions } from "store/ducks/encerramentos";

function TableEncerramentos({ documento }) {
  const dispatch = useDispatch();

  const {
    loading,
    list: encerramentos,
    total,
    page,
    limit,
    sort,
    search,
  } = useSelector((state) => state.encerramentos);

  const { openUpload, loading: loadingEncerramento, openViewImages, data: encerramento } = useSelector(
    (state) => state.encerramento
  );

  useEffect(() => {
    if (documento._id) {
      dispatch(EncerramentosActions.listRequest({
        documento: documento._id,
        page: 1,
        limit: 10,
        sort: '-dataDoEncerramento',
        search: '',
      }));
    }
  }, [dispatch, documento._id]);

  function handleChangeRowsPerPage({ target }) {
    const { value } = target;
    dispatch(EncerramentosActions.listRequest({
      documento: documento._id,
      page,
      limit: value,
      sort,
      search,
    }));
  }

  function handleChangePage(e, newPage) {
    dispatch(EncerramentosActions.listRequest({
      documento: documento._id,
      page: newPage + 1,
      limit,
      sort,
      search,
    }));
  }

  function handleChangeSort(sort) {
    dispatch(EncerramentosActions.listRequest({
      documento: documento._id,
      page,
      limit,
      sort,
      search,
    }));
  }

  function handleEdit(encerramento) {
    dispatch(EncerramentoActions.edit(encerramento));
  }

  function handleShowUpload(despesa) {
    dispatch(EncerramentoActions.openUpload(despesa));
  }

  function handleClose() {
    dispatch(EncerramentoActions.closeUpload());
  }

  function handleCloseImages() {
    dispatch(EncerramentoActions.closeViewImages())
  }

  function handleUpload(filesToUpload) {
    const formData = new FormData();

    filesToUpload.forEach((fileToUpload) => {
      formData.append("imagem", fileToUpload.file);
      formData.append("nome", fileToUpload.nome);
    });

    dispatch(EncerramentoActions.uploadRequest(formData));
  }

  function handleShowImagens(encerramento) {
    dispatch(EncerramentoActions.openViewImages(encerramento))
  }

  function handleShowView(despesa) {
    dispatch(EncerramentoActions.openView(despesa));
  }

  /* function handleShowDelete(despesa) {
    dispatch(EncerramentoActions.openDelete(despesa));
  } */

  function handleRemoveImage(idImage) {
    dispatch(EncerramentoActions.deleteImageRequest(idImage));
  }

  return (
    <>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                <CustomTableSortLabel
                  onClick={handleChangeSort}
                  currentSort={sort}
                  sortField="dataDoEncerramento"
                >
                  Data do encerramento
                </CustomTableSortLabel>
              </TableCell>
              
              <TableCell>
                <CustomTableSortLabel
                  onClick={handleChangeSort}
                  currentSort={sort}
                  sortField="motivoDoEncerramento"
                >
                  Motivo
                </CustomTableSortLabel>
              </TableCell>
              <TableCell align="center">Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!!loading && <LoadingTable rows={limit} columns={4} />}
            {!loading &&
              encerramentos.map((encerramento) => (
                <StyledTableRow key={encerramento._id}>
                  <TableCell>
                    {format(
                      parseISO(encerramento.dataDoEncerramento),
                      "dd/MM/yyyy"
                    )}
                  </TableCell>
                  <TableCell>
                    {encerramento.dataDoTransito
                      ? format(
                          parseISO(encerramento.dataDoTransito),
                          "dd/MM/yyyy"
                        )
                      : ""}
                  </TableCell>
                  <TableCell>
                    <MotivoEncerramento>
                      {encerramento.motivoDoEncerramento}
                    </MotivoEncerramento>
                  </TableCell>
                  <TableCell align="center">
                    <div className="flex-direction">
                      <ToolTipComponent title="Editar" placement="top">
                        <div style={{ marginRight: "8px" }}>
                          <ButtonSquare
                            icon="edit"
                            onClick={() => handleEdit(encerramento)}
                            disabled={documento.status}
                          />
                        </div>
                      </ToolTipComponent>
                      <ToolTipComponent title="Visualizar" placement="top">
                        <div style={{ marginRight: "8px" }}>
                          <ButtonSquare
                            icon="visibility"
                            onClick={() => handleShowView(encerramento)}
                          />
                        </div>
                      </ToolTipComponent>
                      <ToolTipComponent title="Upload" placement="top">
                        <div style={{ marginRight: "8px" }}>
                          <ButtonSquare
                            icon="backup"
                            onClick={() => handleShowUpload(encerramento)}
                            disabled={documento.status}
                          />
                        </div>
                      </ToolTipComponent>
                      <ToolTipComponent
                        title={
                          !encerramento.imagens ||
                          encerramento.imagens.length === 0
                            ? "Não há documentos"
                            : "Visualizar documentos"
                        }
                        placement="top"
                      >
                        <div>
                          <ButtonSquare
                            icon="image"
                            onClick={() =>
                              handleShowImagens(encerramento)
                            }
                            disabled={
                              !encerramento.imagens ||
                              encerramento.imagens.length === 0
                            }
                          />
                        </div>
                      </ToolTipComponent>
                    </div>
                  </TableCell>
                </StyledTableRow>
              ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                count={total}
                page={page - 1}
                rowsPerPage={limit}
                rowsPerPageOptions={[10, 25, 50, 100]}
                labelRowsPerPage="Linhas por página"
                labelDisplayedRows={({ from, to, count }) =>
                  `${from}-${to} de ${count !== -1 ? count : `mais que ${to}`}`
                }
                onRowsPerPageChange={handleChangeRowsPerPage}
                onPageChange={handleChangePage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <SelectFilesToUpload
        open={openUpload}
        onClose={handleClose}
        onUpload={handleUpload}
        loading={loadingEncerramento}
        defaultName="Documento"
        names={["Documento"]}
      />
      {encerramento.imagens &&
      <DialogPreviewImages
        open={openViewImages}
        onClose={handleCloseImages}
        imagens={encerramento.imagens}
        loadingRemove={loadingEncerramento}
        onRemove={handleRemoveImage}
      />}
    </>
  );
}

export default TableEncerramentos;
