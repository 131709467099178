import React from 'react';
import _ from 'lodash';

import GridCadastro from 'components/GridCadastro';
import TableOnboarding from './TableOnboarding';

export default function OnboardingAdministration(props) {
  return (
    <GridCadastro
      title="Formulários"
      route={'/configuracao/onboarding/novo'}
      titleButtonNovo="Novo processo de onboarding"
    >
      <TableOnboarding />
    </GridCadastro>
  );
}
