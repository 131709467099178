import api from './api';
import { getToken } from 'services/auth';
import { toastr } from 'react-redux-toastr';

class ProvidenciaImagensService {

    constructor(api) {
        this.api = api;
    }

    async fetchAll(taskId, organization) {

        try {
            const headerParams = {
              headers: { Authorization: getToken(), xkeyorg: organization },
            };
        
            const { data } = await this.api.get(`providencias/${taskId}/imagens`, headerParams);
        
            return data;
        } catch (error) {
            toastr.error('Ops', 'Não foi possível listar as imagens.');
        }
    }
}

export default new ProvidenciaImagensService(api);