import api from 'services/api';
import { getToken } from 'services/auth';
import { call, select, put, all, takeLatest, takeEvery } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';

import { Creators as NotificacoesActions, Types } from 'store/ducks/notificacoes';

export const getOrganization = state => state.usuarios.auth.organization;
export const getIdUsuario = state => state.usuarios.auth.id;

export function* listNotificacoes({ payload }) {
  const organization = yield select(getOrganization);
  const usuario = yield select(getIdUsuario);
  const { page } = payload;

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
      params: { usuario, page }
    }

    const response = yield call(
      api.get,
      'notificacoes',
      headerParams
    );

    const data = {
      list: response.data.docs,
      total: response.data.total
    };

    yield put(NotificacoesActions.listSuccess(data));
  } catch(error) {
    toastr.error('Ops', 'Não foi possível listar as notificações.');
  }
}

export function* markAsRead({ payload }) {
  const organization = yield select(getOrganization);

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization }
    }

    yield call(
      api.put,
      `notificacoes/${payload._id}`,
      {},
      headerParams
    )
  } catch(error) {
    toastr.error('Ops', 'Não foi possível marcar como lida a notificação');
  }
}

export default all([
  takeLatest(Types.LIST_REQUEST, listNotificacoes),
  takeEvery(Types.MARK_READ_REQUEST, markAsRead)
])