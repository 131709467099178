import api from 'services/api';
import { getToken } from 'services/auth';
import { call, select, put, all, takeLatest } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';

import { Creators as ProvidenciaActions, Types } from 'store/ducks/providencia';
import { Creators as ProvidenciasActions } from 'store/ducks/providencias';
import { Creators as AgendaActions } from 'store/ducks/agenda';
import { Creators as KanbanActions } from 'store/ducks/kanban';

import ProvidenciasService from 'services/ProvidenciasService';

export const getOrganization = state => state.usuarios.auth.organization;
export const getParamProvidencia = state => ({
  page: state.providencias.page,
  search: state.providencias.search,
  documento: state.providencias.documento,
  sort: state.providencias.sort,
  limit: state.providencias.limit,
});

export const getOriginRoute = state => state.router.location.pathname;
export const getProvidencia = state => state.providencia.data;
export const getKanbanColumnToReceiveNewTask = state => state.kanban.columnToReceiveNewTask;
export const getSelectedTask = state => state.providencias.selectedTask;

export function* saveProvidencia({ payload }) {
  const organization = yield select(getOrganization);
  const { _id, ...body } = payload.providencia;

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    let res;

    if (_id) {
      res = yield call(api.put, `providencias/${_id}`, body, headerParams);
    } else {
      res = yield call(api.post, 'providencias', body, headerParams);
    }

    toastr.success('Sucesso!', 'Dados salvos com sucesso!');
    yield put(ProvidenciaActions.saveSuccess());
    const params = yield select(getParamProvidencia);
    yield put(ProvidenciasActions.listRequest(params));
  } catch (error) {
    yield put(ProvidenciaActions.saveError());
    toastr.error('Ops', 'Não foi possível salvar a providência.');
  }
}

export function* createProvidencia({ payload }) {
  const organization = yield select(getOrganization);
  const originRoute = yield select(getOriginRoute);
  const { providencia, imagens } = payload;

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const { data } = yield call(api.post, 'providencias', providencia, headerParams);

    if (imagens.length > 0) {
      yield call(api.post, `providencias/${data._id}/imagens`, imagens, headerParams);
    }

    toastr.success('Sucesso!', 'Dados salvos com sucesso!');
    yield put(ProvidenciaActions.saveSuccess());

    if (originRoute === '/agenda') {
      yield put(AgendaActions.fetchDataRequest());
    } else if (originRoute === '/kanban') {
      const columnToReceiveNewTask = yield select(getKanbanColumnToReceiveNewTask);
      yield put(KanbanActions.addNewTask(data));
      yield put(KanbanActions.updateActiveBoardColumn(columnToReceiveNewTask, data._id));
    } else {
      if (!Array.isArray(providencia.documento)) {
        const params = yield select(getParamProvidencia);
        yield put(ProvidenciasActions.listRequest(params));
      }
    }
  } catch (error) {
    console.error(error);
    yield put(ProvidenciaActions.saveError());
    toastr.error('Ops', 'Não foi possível salvar a providência.');
  }
}

export function* remove({ payload }) {
  const organization = yield select(getOrganization);
  const originRoute = yield select(getOriginRoute);
  const id = payload;

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    yield call(api.delete, `providencias/${id}`, headerParams);

    toastr.success('Sucesso!', 'Remoção realizada com sucesso!');
    yield put(ProvidenciaActions.deleteSuccess());

    if (originRoute === '/agenda') {
      yield put(AgendaActions.fetchDataRequest());
    } else if (originRoute === '/kanban') {
      yield put(KanbanActions.fetchDataRequest());
    } else {
      const params = yield select(getParamProvidencia);
      yield put(ProvidenciasActions.listRequest(params));
    }
  } catch (err) {
    yield put(ProvidenciaActions.uploadError());
    toastr.error('Ops', 'Não foi possível realizar a remoção');
  }
}

export function* upload({ payload }) {
  const organization = yield select(getOrganization);
  const providencia = yield select(getProvidencia);
  const originRoute = yield select(getOriginRoute);
  const { body } = payload;
  const updatedTask = {
    ...providencia,
    haveImages: true,
  };

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    yield call(api.post, `providencias/${providencia._id}/imagens`, body, headerParams);

    toastr.success('Sucesso!', 'Upload realizado com sucesso!');
    yield put(ProvidenciaActions.uploadSuccess());
    yield put(ProvidenciasActions.selectTask(updatedTask));
    if (originRoute === '/agenda') {
      yield put(AgendaActions.fetchDataRequest());
    } else if (originRoute === '/kanban') {
      yield put(KanbanActions.fetchDataRequest());
    } else {
      const params = yield select(getParamProvidencia);
      yield put(ProvidenciasActions.listRequest(params));
    }
  } catch (err) {
    yield put(ProvidenciaActions.uploadError());
    toastr.error('Ops', 'Não foi possível realizar o upload');
  }
}

export function* removeImage({ payload }) {
  const organization = yield select(getOrganization);
  const selectedTask = yield select(getSelectedTask);
  const originRoute = yield select(getOriginRoute);
  const { taskId, imageId, imageArrayLength } = payload;

  try {
    yield call(ProvidenciasService.removeImage, taskId, imageId, organization);

    toastr.success('Sucesso!', 'Imagem excluída com sucesso!');

    const updatedTask = {
      ...selectedTask,
      haveImages: imageArrayLength <= 1,
    };

    yield put(ProvidenciasActions.selectTask(updatedTask));
    yield put(ProvidenciasActions.handleModalImages(false));
    if (originRoute === '/agenda') {
      yield put(AgendaActions.fetchDataRequest());
    } else if (originRoute === '/kanban') {
      yield put(KanbanActions.fetchDataRequest());
    } else {
      const params = yield select(getParamProvidencia);
      yield put(ProvidenciasActions.listRequest(params));
    }
  } catch (err) {
    toastr.error('Ops', 'Não foi possível excluir a imagem');
  }
}

export function* taskApproval({ payload }) {
  const organization = yield select(getOrganization);
  const approvationState = !payload.aprovado;
  const updatedTask = {
    ...payload,
    aprovado: !payload.aprovado,
  };

  try {
    const { data } = yield call(ProvidenciasService.taskApproval, payload._id, organization, approvationState);

    if (data.error) {
      toastr.error('Não foi posssivel encerrar a tarefa!', data.messsage);
      return;
    }

    toastr.success('Tarefa concluída!', `${approvationState ? 'Aprovação' : 'Reprovação'} realizada com sucesso!`);
    yield put(ProvidenciasActions.taskApprovalSuccess(updatedTask));
  } catch (err) {
    console.error(err);
    toastr.error('Ops', 'Não foi possível realizar a mudança de status da tarefa');
  } finally {
    yield put(ProvidenciaActions.taskApprovalSuccess());
  }
}

export function* addParticipants({ payload }) {
  const organization = yield select(getOrganization);
  const { taskId, newParticipants } = payload;

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const { data } = yield call(
      api.put,
      `providencias/${taskId}/adicionar_participantes`,
      newParticipants,
      headerParams
    );

    toastr.success('Sucesso', `Participantes adicionados com sucesso!`);

    yield put(KanbanActions.addParticipantsSuccess(data));
  } catch (err) {
    toastr.error('Ops', 'Não foi possível adicionar participantes');
  }
}

export default all([
  takeLatest(Types.ADD_PARTICIPANTS_REQUEST, addParticipants),
  takeLatest(Types.CREATE_REQUEST, createProvidencia),
  takeLatest(Types.SAVE_REQUEST, saveProvidencia),
  takeLatest(Types.UPLOAD_REQUEST, upload),
  takeLatest(Types.DELETE_REQUEST, remove),
  takeLatest(Types.DELETE_IMAGE_REQUEST, removeImage),
  takeLatest(Types.TASK_APPROVAL_REQUEST, taskApproval),
]);
