import React, { useState, useMemo } from 'react';

import { Container, FormContainer, FormField } from './styled';
import InputMask from 'react-input-mask';

const ConciligConditionalStep = ({ settings, onFieldsChange }) => {

  const [form, setForm] = useState({
    optantePlanoSaude: false,
    optanteValeTransporte: false,
    cidade: "",
    valetransporte: "",
    valorValeTransporte: "",
  });

  const valeTransporteOptions = {
    Lins: ["Intermunicipal"],
    Bauru: ["Transurb", "Prata"],
    "São Paulo": ["Outros"],
    Pederneiras: ["Transporte municipal"]
  };

  const selectStyle = {
   width: "100%"
  }

  const handleFieldChange = (e) => {
    const { name, value } = e.target;

    if (name === "optantePlanoSaude") {
      onFieldsChange({ target: { name: "Optante de plano de saúde?", value }})
    } else if (name === "optanteValeTransporte") {
      onFieldsChange({ target: { name: "Necessita de vale transporte?", value }})
    } else if (name === "valetransporte") {
      onFieldsChange({ target: { name: "Vale transporte selecionado onboarding", value }})
    } else if (name === "valorValeTransporte") {
      onFieldsChange({ target: { name: "Valor vale transporte ", value }})
    }

    setForm({ ...form, [e.target.name]:  e.target.value });
  };

  const mask = "R$ ***,**";

  const maskDynamic = useMemo(() => {
    let maskDynamic = '';
    [...mask].forEach(char => {
      if (char === '1') {
        maskDynamic += '9';
      } else if (char === 'a') {
        maskDynamic += '*'
      } else {
        maskDynamic += char
      }
    });
    return maskDynamic
  }, [mask]);

  return (
    <Container>
      <h4>{settings.text}</h4>
      <FormContainer>
        <form>
          <FormField>
            <label htmlFor="optantePlanoSaude">Optante por plano de saúde:</label>
            <select style={selectStyle} name="optantePlanoSaude" id="optantePlanoSaude" onChange={handleFieldChange}>
              <option value="">Selecione</option>
              <option value="Sim">Sim</option>
              <option value="Não">Não</option>
            </select>
          </FormField>

          <FormField>
            <label htmlFor="optanteValeTransporte">Optante por vale transporte:</label>
            <select style={selectStyle} name="optanteValeTransporte" id="optanteValeTransporte" onChange={handleFieldChange}>
              <option value="">Selecione</option>
              <option value="Sim">Sim</option>
              <option value="Não">Não</option>
            </select>
          </FormField>
          
          {form.optanteValeTransporte === "Sim" && <>
            <FormField>
              <label htmlFor="cidade">Cidade:</label>
              <select style={selectStyle} name="cidade" id="cidade" onChange={handleFieldChange}>
                <option value="">Selecione</option>
                <option value="Lins">Lins</option>
                <option value="Bauru">Bauru</option>
                <option value="Lins">São Paulo</option>
                <option value="Pederneiras">Pederneiras</option>
              </select>
            </FormField>

            {form?.cidade && (
              <FormField>
                <label htmlFor="valetransporte">Vale Transporte:</label>
                <select style={selectStyle} name="valetransporte" id="valetransporte" onChange={handleFieldChange}>
                  <option value="">Selecione</option>
                  {valeTransporteOptions[form.cidade].map((option) => (
                    <option key={option} value={option}>{option}</option>
                  ))}
                </select>
              </FormField>
              
            )}

            {form?.cidade === "Lins" || form?.cidade === "São Paulo" ? (
              <FormField>
                <label htmlFor="valorValeTransporte">Valor do Vale Transporte:</label>
                <InputMask
                  id="valorValeTransporte"
                  name="valorValeTransporte" 
                  mask={maskDynamic}
                  onChange={handleFieldChange}
                >
                  {(inputProps) => <input {...inputProps} type="text" id="valorValeTransporte" />}
                </InputMask>
              </FormField>
            ) : null}
          </>}
        </form>
      </FormContainer>
    </Container>
  );
};

export default ConciligConditionalStep;
