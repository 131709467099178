import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useForm from "react-hook-form";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import StyledTableRow from "components/StyledTableRow";
import TextField from "@material-ui/core/TextField";
import ButtonSquare from "components/ButtonSquare";
import ToolTipComponent from "components/Tooltip";
import ButtonMui from "@material-ui/core/Button";
import Skeleton from "@material-ui/lab/Skeleton";
import { Form, Row, Col } from "react-bootstrap";

import { Link } from "react-router-dom";

import Grid from "@material-ui/core/Grid";
import BackButton from "../../../components/BackButton";

import { Creators as CheckTipoActions } from "../../../store/ducks/checktipo";
import { Creators as DepartamentosActions } from "../../../store/ducks/departamentos";
import { Creators as TiposDocumentoActions } from "../../../store/ducks/tiposdocumento";

import "./styles.css";
import Content from "components/Content";

export default function TituloConfig(props) {
  const dispatch = useDispatch();
  const usuario = useSelector((state) => state.usuarios.auth);

  const [departamento, setDepartamento] = useState([]);

  const { checkTipo } = props.location.state;

  const empresaSetada = useSelector((state) => state.checkTipos.empresaSetada);

  const { loading: loadingDep, listByEmpresa } = useSelector(
    (state) => state.departamentos
  );

  const {
    access: { empresas },
  } = useSelector((state) => state.permissoes);

  useEffect(() => {
    dispatch(CheckTipoActions.getRequest(checkTipo._id));
    dispatch(TiposDocumentoActions.listByEmpresaRequest(empresas));
  }, [dispatch, checkTipo._id, empresas]);

  useEffect(() => {
    dispatch(
      DepartamentosActions.listByEmpresaRequest({
        empresa: empresaSetada,
      })
    );
  }, [dispatch, empresaSetada]);

  const checksSelect = useSelector((state) => state.checkTipos.checksSelect);

  const { register, handleSubmit } = useForm();

  const onRemove = ({ _id }, { hash }) => {
    dispatch(CheckTipoActions.removeTituloRequest({ _id, hash }));
  };

  const onSubmit = (values) => {
    let titulo = {
      descricao: values.nome,
      ordem: values.ordem,
      _id: checksSelect._id,
      userAcao: usuario.id,
      departamento: departamento?.map((x) => {
        return { nome: x.nome, _id: x._id };
      }),
    };

    dispatch(CheckTipoActions.createTituloRequest(titulo));
  };

  return (
    <Content>
      <div className="inter-panels">
        <Row>
          <Col md="12" className="painel">
            <div className="painel-content">
              <div className="painel-title text-left">Novo Título</div>
              <div className="painel-body">
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <Row>
                    <Col md="12">
                      <Form.Label>Checklist: ({checkTipo.nome})</Form.Label>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <Form.Label>Nome do Título:</Form.Label>
                      <TextField
                        size="small"
                        variant="outlined"
                        autoComplete="off"
                        type="text"
                        className="descricao"
                        name="nome"
                        inputRef={register}
                        placeholder=""
                      />
                    </Col>
                    <Col md="6">
                      <Form.Label>Ordem:</Form.Label>
                      <TextField
                        size="small"
                        variant="outlined"
                        autoComplete="off"
                        type="number"
                        className="descricao"
                        name="ordem"
                        inputRef={register}
                        placeholder=""
                      />
                    </Col>
                    <Col md="6">
                      <Form.Label>Departamento:</Form.Label>
                      {!loadingDep ? (
                        <Autocomplete
                          multiple={true}
                          noOptionsText="Departamento"
                          size="small"
                          className="mt-2"
                          getOptionLabel={(option) => option.nome}
                          options={listByEmpresa?.docs || []}
                          onChange={(e, v) => {
                            setDepartamento(v);
                          }}
                          name="departamentoId"
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="departamento"
                              label="Departamento"
                              variant="outlined"
                            />
                          )}
                        />
                      ) : (
                        <Skeleton width="100%" height={50} />
                      )}
                    </Col>
                  </Row>

                  <div className="buttons">
                    <Grid container spacing={1}>
                      <Grid item>
                        <ButtonMui
                          variant="contained"
                          color="primary"
                          type="submit"
                        >
                          Criar
                        </ButtonMui>
                      </Grid>
                      <Grid item>
                        <ButtonMui
                          variant="contained"
                          color="secondary"
                          type="reset"
                        >
                          Limpar
                        </ButtonMui>
                      </Grid>
                      <Grid item>
                        <BackButton variant="contained" color="secondary">
                          Voltar
                        </BackButton>
                      </Grid>
                    </Grid>
                  </div>
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </div>

      <div className="inter-panels">
        <Row>
          <Col md="12" className="painel">
            <div className="painel-content">
              <div className="painel-title text-left">Títulos Cadastrados</div>
              <div className="painel-body">
                <TableContainer>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Descrição do Título</TableCell>
                        <TableCell align="center">Ordem</TableCell>
                        <TableCell align="center">Departamento(s)</TableCell>
                        <TableCell align="center">Ações</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {checksSelect.titulos &&
                        checksSelect.titulos.map((titulos, index) => (
                          <StyledTableRow key={index}>
                            <TableCell>{titulos.descricao}</TableCell>
                            <TableCell align="center">
                              {titulos.ordem}
                            </TableCell>
                            <TableCell align="center">
                              {titulos.departamento?.map((x) => {
                                return x.nome + "  ";
                              })}
                            </TableCell>
                            <TableCell align="center">
                              <div className="flex-direction">
                                <ToolTipComponent title="Itens" placement="top">
                                  <Link
                                    to={{
                                      pathname: "/operacao/checktipo/item",
                                      state: {
                                        tituloSelect: titulos,
                                        IndexItem: index,
                                      },
                                    }}
                                  >
                                    {/* <FontAwesomeIcon icon={faEllipsisH}></FontAwesomeIcon> */}
                                    <ButtonSquare icon="format_indent_increase" />
                                  </Link>
                                </ToolTipComponent>

                                <ToolTipComponent
                                  title="Deletar"
                                  placement="top"
                                >
                                  <ButtonSquare
                                    onClick={() =>
                                      onRemove(checksSelect, titulos)
                                    }
                                    icon="delete"
                                  />
                                </ToolTipComponent>
                              </div>
                            </TableCell>
                          </StyledTableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </Content>
  );
}
