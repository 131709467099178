import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextField, CircularProgress } from '@material-ui/core';

import Alert from '@material-ui/lab/Alert';
import Skeleton from '@material-ui/lab/Skeleton';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { Col, Row } from 'react-bootstrap';
import _ from '@lodash';
import { toastr } from 'react-redux-toastr';

import { Date } from 'components/Masks';
import { parseISO, format } from 'date-fns';
import { valida_cnpj, valida_cpf } from 'services/validadorCpfCNPJ';
import { LabelSwapper } from '../../../../components/LabelSwapper';

import { Creators as PartesActions } from 'store/ducks/partes';
import { Creators as ComarcasActions } from 'store/ducks/comarca';
import { Creators as EmpresasActions } from 'store/ducks/empresas';
import { Creators as ProcessoActions } from 'store/ducks/processo';
import { Creators as DocumentosActions } from 'store/ducks/documentos';
import { Creators as TipoImagensActions } from 'store/ducks/tipoimagem';
import { Creators as DepartamentosActions } from 'store/ducks/departamentos';
import { Creators as TiposDocumentoActions } from 'store/ducks/tiposdocumento';

import { Acordeon } from 'components/Acordeon';
import { IndexadorComponent } from 'components/IndexadorComponent';
import { AlertVulnerability } from 'components/AlertVulnerability';
import { AlertPartWithMoreManifestation } from 'components/AlertPartWithMoreManifestation';
import { ExibeFilaEtapa } from 'components/ExibeFilaEtapa';
import { SearchIcon } from 'components/Icons';

import './styles.css';

export default function Indexadores({ form, setForm, updateIndexadores = null }) {
  const [open, setOpen] = useState({});
  const [indexadores, setIndexadores] = useState(null);
  const [currentEtapa, setCurrentEtapa] = useState(null);
  const [currentMetadata, setCurrentMetadata] = useState(null);

  const DEFAULT_SEPARADOR = 'Preenchimento obrigatório nesta etapa';
  const SECONDARY_SEPARADOR = 'Preenchimento Analista';
  const OUTROS_INDEXADORES = 'Outros indexadores';

  const dispatch = useDispatch();
  const dispatchDebounce = useRef(_.debounce(dispatch, 500)).current;

  //#region sagas
  const {
    access: { empresas, relacionamentos },
  } = useSelector(state => state.permissoes);
  const { list: departamentosList, loading: loadingDepartamentos } = useSelector(state => state.departamentos);
  const { listByEmpresas: tipoDocList, loading: loadingTipoDoc } = useSelector(state => state.tipoDocumentos);
  const { list: empresasList, loading: loadingEmpresas } = useSelector(state => state.empresas);
  const { metadados, loadingMetadados, loading, byEtapas } = useSelector(state => state.documentos);
  const { data: processo, loading: loadingProcesso, error: errorProcesso } = useSelector(state => state.processo);
  //#endregion

  //#region memos
  const listEmpresas = useMemo(() => {
    setForm(f => ({ ...f, empresa: null }));
    return !form.tipoDocumento ? [] : empresasList.filter(emp => form.tipoDocumento.empresas.includes(emp._id));
  }, [form.tipoDocumento, empresasList]);

  const listDepartamentos = useMemo(() => {
    setForm(f => ({ ...f, departamento: null }));
    return !form.empresa ? [] : departamentosList.filter(dep => dep.empresa === form.empresa._id);
  }, [form.empresa, departamentosList]);

  const separadores = useMemo(
    () =>
      indexadores
        ? indexadores.reduce(
          (acc, indexador) =>
            indexador.separador && !acc.includes(indexador.separador) ? [indexador.separador, ...acc] : acc,
          [OUTROS_INDEXADORES]
        )
        : null,
    [indexadores]
  );

  const indexadoresObrigatorios = useMemo(
    () =>
      currentEtapa
        ? indexadores.filter(
          indexador => indexador.obrigatorioEtapa && indexador.obrigatorioEtapa.includes(currentEtapa)
        )
        : null,
    [indexadores]
  );

  useEffect(() => {
    byEtapas.length > 0 &&
      setCurrentEtapa(byEtapas.find(process => process.numeroCNJ === form.numeroCNJ)?.taskDefinitionKey);
  }, [byEtapas]);

  useEffect(() => {
    if (!loadingProcesso && !errorProcesso && !_.isEqual(processo, {})) {
      setForm(f => ({
        ...f,
        ...processo,
        valorDaCausa: processo.valorDaCausa ? processo.valorDaCausa / 100 : f.valorDaCausa,
        tipoDocumento: processo.idTipoDocumento,
        empresa: processo.idEmpresa,
        departamento: processo.idDepartamento,
      }));
      dispatch(ProcessoActions.clear());
    }
  }, [dispatch, processo, loadingProcesso, errorProcesso]);

  useEffect(() => {
    dispatch(EmpresasActions.listRequest());
    dispatch(TiposDocumentoActions.listByEmpresaRequest(empresas));
    dispatch(DepartamentosActions.listRequest());
    dispatch(
      TipoImagensActions.listRequest({
        limit: Number.MAX_SAFE_INTEGER,
        page: 1,
        sort: 'nome',
      })
    );
  }, [dispatch, empresas, relacionamentos]);

  useEffect(() => {
    if (listEmpresas.length === 1) {
      const [empresa] = listEmpresas;
      setForm(f => ({ ...f, empresa }));
    }
    if (listDepartamentos.length === 1) {
      const [departamento] = listDepartamentos;
      setForm(f => ({ ...f, departamento }));
    }
    if (tipoDocList.length === 1) {
      const [tipoDocumento] = tipoDocList;
      setForm(f => ({ ...f, tipoDocumento }));
    }
  }, [listEmpresas, listDepartamentos, tipoDocList]);

  useEffect(() => {
    if (form._id) dispatch(PartesActions.totalManifestationNumberRequest(form._id));
  }, []);

  useEffect(() => {
    if (form.indexadores) setIndexadores(form.indexadores);
  }, [form.indexadores]);
  //#endregion

  //#region funções auxiliares
  function handleChangeCNJ({ target }) {
    const { value, name } = target;

    setForm({ ...form, [name]: value });
    dispatchDebounce(ProcessoActions.getRequest(value));
  }

  function handleChange({ target }) {
    const { value, name } = target;
    setForm({ ...form, [name]: value });
  }

  function handleChangeIndexadorWithLodash(e) {
    dispatchDebounce(
      DocumentosActions.metadadosRequest(
        form.empresa._id,
        form.modalidade._id,
        form.tipoDocumento._id,
        e.target.name,
        e.target.value
      )
    );
    handleChangeIndexador(e);
  }

  function handleChangeIndexador({ target }) {
    const { name, value } = target;
    const newIndexadores = indexadores.map(indexador => ({
      ...indexador,
      valor: name === indexador.nome ? value : indexador.valor,
    }));

    setIndexadores(newIndexadores);
    if (updateIndexadores) {
      updateIndexadores(newIndexadores);
    }
  }

  function handleChangeAutocomplete(e, value) {
    const newIndexadores = indexadores.map(indexador => ({
      ...indexador,
      valor: value ? value[indexador.nome] : '',
    }));

    setIndexadores(newIndexadores);
    setCurrentMetadata(value);
  }

  function hasIndexadorError(indexador) {
    if (indexador.obrigatorio && !indexador.valor) return true;

    if (indexador.tipo === 'cpf' && indexador.valor && indexador.valor !== '') {
      return !valida_cpf(indexador.valor);
    }

    if (indexador.tipo === 'cnpj' && indexador.valor && indexador.valor !== '') {
      return !valida_cnpj(indexador.valor);
    }

    return false;
  }

  function hasIndexadorTextError(indexador) {
    if (indexador.obrigatorio && !indexador.valor) return 'Campo obrigatório';

    if (indexador.tipo === 'cpf' && indexador.valor && indexador.valor !== '') {
      if (!valida_cpf(indexador.valor)) return 'CPF inválido!';
    }

    if (indexador.tipo === 'cnpj' && indexador.valor && indexador.valor !== '') {
      if (!valida_cnpj(indexador.valor)) return 'CNPJ inválido!';
    }

    return;
  }

  function handleDoubleClick(e) {
    if (!e.target.value) return;
    navigator.clipboard.writeText(e.target.value);
    toastr.info('', 'Input copiado em segundo plano');
  }
  //#endregion

  return (
    <div className="panel">
      {/* <AlertPartWithMoreManifestation _id={form._id} />
      <AlertVulnerability indexadores={indexadores} /> */}

      <div className="index-title">
        <span>Indexadores</span>
      </div>
      <div className="index-inputs">
        <ExibeFilaEtapa fila={form.fila} etapa={form.etapa} />

        <Row>
          <Col md={12}>
            {form.alerta && form.alerta !== '' && (
              <Alert className="mb-2" severity="warning">
                {form.alerta}
              </Alert>
            )}
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <TextField
              name="numeroCNJ"
              label={<LabelSwapper text={'Nº do cliente'} parent={'Indexadores'} name={'numeroCNJ'} />}
              onDoubleClick={handleDoubleClick}
              onChange={handleChangeCNJ}
              value={form.numeroCNJ}
              disabled={true}
              size="small"
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <React.Fragment>
                    {!!loadingProcesso ? <CircularProgress color="primary" size={20} /> : <SearchIcon />}
                  </React.Fragment>
                ),
              }}
            />
          </Col>

          <Col md={6}>
            <TextField
              label={'Data de vencimento'}
              name={'dataVencimento'}
              value={form.dataVencimento ? format(parseISO(form.dataVencimento), 'dd-MM-yyyy') : ''}
              disabled={true}
              variant="outlined"
              size="small"
              InputProps={{
                inputComponent: Date,
              }}
            />
          </Col>
        </Row>

        {/* <Row>
          <Col md={12}>
            <TextField
              name="outrasInformacoes"
              label="Outras Informações"
              onChange={handleChange}
              disabled={false}
              value={form.outrasInformacoes}
              size="small"
              variant="outlined"
              multiline
              minRows={4}
              onDoubleClick={handleDoubleClick}
            />
          </Col>
        </Row>*/}
        {form.resumo && (<Row>
          <Col md={12}>
            <TextField
              name="resumo"
              label="Resumo"
              onChange={handleChange}
              disabled={false}
              value={form.resumo}
              size="small"
              variant="outlined"
              multiline
              minRows={4}
              onDoubleClick={handleDoubleClick}
            />
          </Col>
        </Row>)}

        {/*
        <Row>
          <Col md={12}>
            <TextField
              name="analiseInterna"
              label="Análise"
              onChange={handleChange}
              disabled={false}
              value={form.analiseInterna}
              size="small"
              variant="outlined"
              multiline
              minRows={8}
              onDoubleClick={handleDoubleClick}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <TextField
              name="conclusao"
              label="Conclusão"
              onChange={handleChange}
              disabled={false}
              value={form.conclusao}
              size="small"
              variant="outlined"
              multiline
              minRows={8}
              onDoubleClick={handleDoubleClick}
            />
          </Col>
        </Row> */}

        {indexadoresObrigatorios && (
          <Acordeon
            index={DEFAULT_SEPARADOR}
            titleOpen={DEFAULT_SEPARADOR}
            titleClose={DEFAULT_SEPARADOR}
            hidden={indexadoresObrigatorios.length === 0}
          >
            {indexadoresObrigatorios.map(indexador => (
              <Row key={indexador.nome} className="indexadores-form">
                <Col md={12}>
                  <IndexadorComponent
                    indexador={indexador}
                    handleChangeIndexador={
                      indexador.indentificadorUnico ? handleChangeIndexadorWithLodash : handleChangeIndexador
                    }
                    handleChangeIndexadorUnico={handleChangeAutocomplete}
                    handleDoubleClick={handleDoubleClick}
                    open={open[indexador.nome] || false}
                    onOpen={() => setOpen({ ...open, [indexador.nome]: true })}
                    onClose={() => setOpen({ ...open, [indexador.nome]: false })}
                    options={metadados}
                    value={currentMetadata}
                    loading={loadingMetadados}
                    onDoubleClick={handleDoubleClick}
                    error={hasIndexadorError(indexador)}
                    helperText={hasIndexadorTextError(indexador)}
                  />
                </Col>
              </Row>
            ))}
          </Acordeon>
        )}

        {separadores &&
          separadores
            .filter(separador =>
              indexadores.some(
                indexador =>
                  indexador.separador === separador || (!indexador.separador && separador === OUTROS_INDEXADORES)
              )
            )
            .sort((a, b) => {
              if (a === SECONDARY_SEPARADOR || b === SECONDARY_SEPARADOR) return true;
              return a.localeCompare(b);
            })
            .map(separador => (
              <Acordeon key={separador} titleOpen={separador} titleClose={separador}>
                {indexadores
                  .filter(
                    indexador =>
                      (indexador.separador === separador &&
                        (indexador.invisivel === false || indexador.invisivel === undefined)) ||
                      (!indexador.separador && separador === OUTROS_INDEXADORES)
                  )
                  .map(indexador => (
                    <Row key={indexador.nome} className="indexadores-form">
                      <Col md={12}>
                        <IndexadorComponent
                          indexador={indexador}
                          handleChangeIndexador={
                            indexador.indentificadorUnico ? handleChangeIndexadorWithLodash : handleChangeIndexador
                          }
                          handleChangeIndexadorUnico={handleChangeAutocomplete}
                          handleDoubleClick={handleDoubleClick}
                          open={open[indexador.nome] || false}
                          onOpen={() => setOpen({ ...open, [indexador.nome]: true })}
                          onClose={() => setOpen({ ...open, [indexador.nome]: false })}
                          options={metadados}
                          value={currentMetadata}
                          loading={loadingMetadados}
                          onDoubleClick={handleDoubleClick}
                          error={hasIndexadorError(indexador)}
                          helperText={hasIndexadorTextError(indexador)}
                        />
                      </Col>
                    </Row>
                  ))}
              </Acordeon>
            ))}
      </div>
    </div>
  );
}
