import api from "services/api";
import { getToken } from "services/auth";
import { call, select, put, all, takeLatest, takeEvery } from "redux-saga/effects";
import { toastr } from "react-redux-toastr";

import { Creators as MensagensActions, Types } from "store/ducks/mensagens";

export const getOrganization = (state) => state.usuarios.auth.organization;

export function* listMensagens({ payload }) {
  const organization = yield select(getOrganization);

  const { endpoint, ...params } = payload;

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
      params,
    };

    const url = ["mensagens", "mensagens/recebidas", "mensagens/enviadas"][
      endpoint
    ];

    const { data } = yield call(api.get, url, headerParams);
    yield put(MensagensActions.listSuccess({ messages: data.mensagens.docs, authorList: data.authorList }, data.mensagens.total));
  } catch (error) {
    toastr.error("Ops", "Não foi possível listar as mensagens.");
  }
}

export function* mensagensNaoLidas() {
  const organization = yield select(getOrganization);

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const url = 'mensagens/nao-lidas'

    const { data } = yield call(api.get, url, headerParams);
    yield put(MensagensActions.unreadSuccess(data.total));
  } catch (error) {
    toastr.error("Ops", "Não foi possível listar as mensagens.");
  }
}

export function* markAsRead({ payload }) {
  const organization = yield select(getOrganization);

  const { _id } = payload;

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const url = `mensagens/${_id}/marcar-como-lida`

    yield call(api.put, url, null, headerParams);
    yield put(MensagensActions.markAsReadSuccess(_id));
  } catch (error) {
    toastr.error("Ops", "Não foi possível marcar como lida a mensagem.");
  }
}

export default all([
  takeLatest(Types.LIST_REQUEST, listMensagens),
  takeLatest(Types.UNREAD_REQUEST, mensagensNaoLidas),
  takeEvery(Types.MARK_AS_READ_REQUEST, markAsRead)
]);
