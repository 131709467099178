import * as Yup from "yup";
import Locale from "./Locale";
import moment from "moment";

Yup.setLocale(Locale);

function sameMonth(value, parent, valueToCompare) {

  if (!value) return true;

  const checkFinal = moment(value, 'YYYY-MM-DD');
  const checkStart = moment(parent[valueToCompare], 'YYYY-MM-DD');

  const startMonth = checkStart.format('M');
  const startYear = checkStart.format('YYYY');

  const FinalMonth = checkFinal.format('M');
  const FinalYear = checkFinal.format('YYYY');

  return startMonth === FinalMonth && startYear && FinalYear;
}

function requiredFieldWhen(field) {
  return Yup.string()
    .nullable()
    .test({
      name: "valor-obrigatorio",
      message: "Campo obrigatório ao ter um período final",
      test: function (value) {
        return !this.parent[field] || !!value;
      },
    });
}

function createdEndFieldValidation({ dataInicial }) {
  return requiredFieldWhen(dataInicial).test({
    name: "dataFinal",
    message: "Período Final deve ser no mesmo mês do Período Inicial",
    test: function (value) {
      return sameMonth(value, this.parent, dataInicial);
    },
  });
}

const FilterSameMonthSchema = Yup.object().shape({
  dataInicial: Yup.string().required().typeError('Data inicial é obrigatória'),
  dataFinal: createdEndFieldValidation({ dataInicial: "dataInicial" }),
  departamento: Yup.object().required().typeError("Departamento é obrigatório"),
});

export { FilterSameMonthSchema };
