import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";


import {
    Table, TableRow, TableCell, TableHead, TableBody,
    TableContainer, TableFooter, TablePagination
} from '@material-ui/core';

import LoadingTable from 'components/LoadingTable';

import { Creators as RelacionamentosActions } from "store/ducks/relacionamentos";

import CustomTableSortLabel from 'components/CustomTableSortLabel';
import StyledTableRow from 'components/StyledTableRow';

import ButtonSquare from 'components/ButtonSquare';

export default function ListRelacionamento() {

    const dispatch = useDispatch();

    const { list: relacionamentos, loading, total, page = 1, limit = 10, sort = 'nome' } = useSelector(state => state.relacionamentos);
    const { access: { relacionamentos: relacionamentosAccess } } = useSelector(state => state.permissoes);

    const [request, setRequest] = useState({ page, limit, sort });

    useEffect(() => {
        dispatch(RelacionamentosActions.listRelacionamentoRequest({ relacionamentos: relacionamentosAccess, ...request }))
    }, [dispatch, relacionamentosAccess, request]);

    function handleSortChange(sort) {
        setRequest({ ...request, sort });
    };

    function handleChangeRowsPerPage({ target }) {
        const { value: limit } = target;
        setRequest({ ...request, limit, page: 1 });
    };

    function handleChangePage(event, newPage) {
        setRequest({ ...request, page: newPage + 1 })
    }

    return (
        <TableContainer className="list-relacionamento-container">
            {(relacionamentos && relacionamentos.length > 0) || loading ?
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <CustomTableSortLabel
                                    currentSort={request.sort}
                                    sortField="relacionamento"
                                    onClick={handleSortChange}
                                >
                                    Relacionamento
                                 </CustomTableSortLabel>
                            </TableCell>
                            <TableCell>
                                <CustomTableSortLabel
                                    currentSort={request.sort}
                                    sortField="descricao"
                                    onClick={handleSortChange}
                                >
                                    Descrição
                                 </CustomTableSortLabel>
                            </TableCell>

                            <TableCell>
                                <CustomTableSortLabel
                                    currentSort={request.sort}
                                    sortField="usuario.nome"
                                    onClick={handleSortChange}
                                >
                                    Usuário Cadastro
                                 </CustomTableSortLabel>
                            </TableCell>
                            <TableCell align="center">
                                <CustomTableSortLabel
                                    currentSort={request.sort}
                                    sortField="status"
                                    onClick={handleSortChange}
                                >
                                    Status
                                 </CustomTableSortLabel>
                            </TableCell>
                            <TableCell align="center">Ações</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!!loading && <LoadingTable columns={5} />}
                        {!loading && relacionamentos
                            .map(relac => (
                                <StyledTableRow key={relac._id}>
                                    <TableCell>{relac.relacionamento}</TableCell>
                                    <TableCell>{relac.descricao}</TableCell>

                                    <TableCell>{relac.usuario.nome}</TableCell>
                                    <TableCell align="center">
                                        {relac.status === true ? (
                                            <span className="status-active">Ativo</span>
                                        ) : (
                                                <span className="status-inactive">Inativo</span>
                                            )}
                                    </TableCell>
                                    <TableCell align="center" key={relac._id}>
                                        <Link
                                            to={{ pathname: "/administracao/relacionamentos/edit", state: { relac: relac } }}
                                            className="linkicon"
                                            id="relacionamentos">
                                            <ButtonSquare
                                                icon="edit"
                                            />
                                        </Link>
                                    </TableCell>
                                </StyledTableRow>
                            ))}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                count={total}
                                page={request.page - 1}
                                rowsPerPage={request.limit}
                                rowsPerPageOptions={[10, 25, 50, 100]}
                                labelRowsPerPage="Linhas por página"
                                labelDisplayedRows={({ from, to, count }) =>
                                    `${from}-${to} de ${count !== -1 ? count : `mais que ${to}`}`
                                }
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                onPageChange={handleChangePage}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
                :
                <div className="list-departamento-content">
                    <span className="list__none">Não há relacionamentos registrados.</span>
                </div>
            }
        </TableContainer>
    )
}