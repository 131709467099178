import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row, Form } from 'react-bootstrap';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import Skeleton from '@material-ui/lab/Skeleton';
import IndexerFormFieldFactory from 'components/FormFieldIndexer/IndexerFormFieldFactory';

import DefaultPanel from 'components/DefaultPanel';

import useForm from 'react-hook-form';

import { Creators as TipoDocumentosActions } from 'store/ducks/tiposdocumento';
import { Creators as DocumentoActions } from 'store/ducks/documentos';
import { Creators as UsuariosActions } from 'store/ducks/usuarios';
import { LabelSwapper } from '../../../../components/LabelSwapper';

import './styles.css';
import { Typography } from '@material-ui/core';
import Utils from 'utils/utils';
import { DebouncedAutoComplete } from 'components/DebouncedAutoComplete';

export default function SearchForm({ collapse, setCollapse, numeroCNJ, selectedFilaNome }) {
  const dispatch = useDispatch();

  const { register, handleSubmit, setValue, watch, getValues, reset } = useForm({
    defaultValues: {
      numeroCNJ,
    },
  });
  const myForm = useRef(null);

  const [tipoDocumento, setTipoDocumento] = useState();

  const submitButtonRef = useRef();

  const { byEtapas: documentos, loading, sla, limit = 10, sort, etapas } = useSelector(state => state.documentos);
  const { analysts } = useSelector(state => state.usuarios);
  const {
    access: { empresas },
  } = useSelector(state => state.permissoes);

  useEffect(() => {
    if (Utils.isNullOrUndefined(numeroCNJ)) return;

    setValue('numeroCNJ', numeroCNJ);

    handleInputChange({
      target: {
        value: numeroCNJ,
        name: 'numeroCNJ',
      },
    });

    dispatch(
      DocumentoActions.listRequestByEtapas({
        etapas: etapas,
        fila: selectedFilaNome,
        page: 1,
        limit,
        sort,
        sla,
        values: { numeroCNJ },
        fields:
          'documentoAnalise.numeroCNJ,documentoAnalise._id,usuarioAcao,etapas,ultimaEtapa,dtUltimaEtapa,sla,tarefa',
      })
    );
  }, [numeroCNJ]);

  const { loading: loadingAnalise } = useSelector(state => state.analise);
  const { listByEmpresas: tipoDocList, loading: loadingTipoDoc } = useSelector(state => state.tipoDocumentos);

  const handleInputChange = ({ target }) => {
    const { value, name } = target;

    if (name !== 'tipoDocumento') {
      setValue(name, value);
    } else {
      setTipoDocumento(value);

      if (!value?.indexadores) return;

      const indexadores = value.indexadores.filter(indexador => indexador.esteira);

      for (let indexador of indexadores) {
        register({ name: indexador.id_es });
      }
    }
  };

  useEffect(() => {
    dispatch(TipoDocumentosActions.listByEmpresaRequest(empresas));
    dispatch(UsuariosActions.searchByNameRequest(''));
    register({ name: 'numeroCNJ' });
    register({ name: 'assignee' });
  }, []);

  useEffect(() => {
    return () => {
      dispatch(DocumentoActions.resetLoading());
    };
  }, []);

  const onSubmit = async values => {
    const payload = {
      ...getValues(),
      values,
    };

    let keys = Object.keys(payload);

    for (let key of keys) {
      if (values[key] === undefined || values[key] === '' || !values[key] || values[key].length === 0) {
        delete payload[key];
      }
    }

    dispatch(
      DocumentoActions.listRequestByEtapas({
        fila: selectedFilaNome,
        empresas,
        endEvent: false,
        etapas: etapas,
        page: 1,
        limit,
        sort: '-vencimento',
        sla,
        values: payload,
        fields:
          'documentoAnalise.numeroCNJ,documentoAnalise._id,usuarioAcao,etapas,ultimaEtapa,dtUltimaEtapa,sla,tarefa',
      })
    );

    setCollapse(true);
  };

  const clearForm = () => {
    setTipoDocumento(null);
    myForm.current.reset();

    const autocompletes = document.getElementsByClassName('MuiAutocomplete-clearIndicator');

    autocompletes[2].click();

    const values = getValues();

    for (let key in values) {
      if (key === 'numeroCNJ') continue;

      setValue(key, '');
    }
  };

  return (
    <Row>
      <DefaultPanel titleText="Filtros da etapa" isCollapsible={true} collapse={collapse} setCollapse={setCollapse}>
        <Form ref={myForm} onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col md={6}>
              <TextField
                name="numeroCNJ"
                label={<LabelSwapper text={'Nº do cliente'} parent={'SearchForm'} name={'numeroCNJ'} />}
                onChange={handleInputChange}
                value={watch('numeroCNJ') || ''}
                size="small"
                variant="outlined"
                ref={register}
              />
            </Col>
            <Col md={6}>
              {loadingTipoDoc ? (
                <Skeleton width={'100%'} height={40} />
              ) : (
                <Autocomplete
                  id="combo-box-tipodocumento"
                  options={tipoDocList}
                  getOptionLabel={option => option.nome}
                  clearOnBlur={true}
                  getOptionSelected={(option, value) => option._id === value._id}
                  onChange={(e, value) => {
                    handleInputChange({
                      target: { name: 'tipoDocumento', value },
                    });
                  }}
                  renderInput={params => <TextField {...params} label="Tipos de documento" variant="outlined" />}
                  size="small"
                />
              )}
            </Col>
          </Row>
          <Row className="docTypeFields">
            <Col md={12}>
              <Typography variant="h6">Indexadores </Typography>
            </Col>
            {tipoDocumento &&
              tipoDocumento.indexadores
                .filter(indexador => indexador.esteira)
                .map(indexador => (
                  <Col key={indexador.id_es} md={6} className="esteira-indexador">
                    <IndexerFormFieldFactory
                      searching={true}
                      fieldToAdd={null}
                      genericParams={{
                        indexador,
                        checkReadOnly: false,
                        hasIndexadorError: () => {},
                        hasIndexadorTextError: () => {},
                        handleChangeIndexador: v => {
                          handleInputChange({ target: { name: indexador.id_es, value: v?.target?.value } });
                        },
                      }}
                      identificadorUnicoParams={{}}
                    />
                  </Col>
                ))}
          </Row>

          <Row>
            <Col className="search-form-buttons">
              <Button type="submit" variant="contained" color="primary" disabled={loading} ref={submitButtonRef}>
                Pesquisar
              </Button>
              <Button onClick={e => clearForm()} variant="contained" color="secondary" type="reset">
                Limpar
              </Button>
            </Col>
          </Row>
        </Form>
      </DefaultPanel>
    </Row>
  );
}
