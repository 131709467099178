import React, { useState, useRef } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import Icon from '@material-ui/core/Icon';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';

import _ from '@lodash';

import MessageChat from './MessageChat';
import FormMensagem from './FormMensagem';
import ViewMensagem from './ViewMensagem';
import DeleteMensagem from './DeleteMensagem';

import { Creators as MensagemActions } from 'store/ducks/mensagem';
import { Creators as MensagensActions } from 'store/ducks/mensagens';

import { MESSAGE_FILTER_OPTIONS } from '../../constants/message-filter-options';

import './styles.css';

function Mensagens({ documento }) {
  const dispatch = useDispatch();
  const dispatchDebounce = useRef(_.debounce(dispatch, 500)).current;

  const {
    search = '',
    page,
    sort,
    endpoint,
    list: messages,
    authorList: authorFilter,
  } = useSelector((state) => state.mensagens);

  const [searchField, setSearchField] = useState(search);
  const [filterByType, setFilterByType] = useState(null);
  const [selectedAuthorFilter, setSelectedAuthorFilter] = useState('');

  function handleChange({ target }) {
    const { value } = target;
    setSearchField(value);
    dispatch(MensagensActions.listInitialize());
    dispatchDebounce(
      MensagensActions.listRequest({
        documento: documento._id,
        page,
        limit: Number.MAX_SAFE_INTEGER,
        sort,
        search: value,
        endpoint,
      })
    );
  }

  function handleChangeFilter({ target }) {
    const { value } = target;
    setFilterByType(value);
  }

  function handleChangeAuthorFilter({ target }) {
    const { value } = target;
    setSelectedAuthorFilter(value);

    if (value) {
      setFilterByType(MESSAGE_FILTER_OPTIONS.FILTER_SPECIFIC_USER);
    } else {
      setFilterByType(MESSAGE_FILTER_OPTIONS.NO_FILTER);
    }
  }

  function handleClick() {
    dispatch(MensagemActions.new());
  }

  return (
    <>
      <Row>
        <Col md="12">
          <div className="controlpanel-header">
            <div className="controlpanel-item comments-header-spacing">
              <TextField
                label="Pesquisar"
                variant="outlined"
                size="small"
                onChange={handleChange}
                value={searchField}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Icon className="text-20" color="action">
                        search
                      </Icon>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="controlpanel-item comments-header-spacing">
              <TextField
                size="small"
                label="Comentário por remetente"
                id="select"
                variant="outlined"
                value={selectedAuthorFilter}
                onChange={handleChangeAuthorFilter}
                select
              >
                <MenuItem value={''}>Nenhum</MenuItem>
                {authorFilter &&
                  authorFilter.map((author) => (
                    <MenuItem key={author._id} value={author.autor}>
                      {author.autor}
                    </MenuItem>
                  ))}
              </TextField>
            </div>
            <div className="controlpanel-item">
              <TextField
                size="small"
                label="Tipo de envio"
                id="select"
                variant="outlined"
                value={filterByType}
                onChange={handleChangeFilter}
                select
              >
                <MenuItem value={MESSAGE_FILTER_OPTIONS.NO_FILTER}>
                  Todos
                </MenuItem>
                <MenuItem
                  value={MESSAGE_FILTER_OPTIONS.ONLY_MESSAGES_FROM_OTHERS}
                >
                  Recebidos
                </MenuItem>
                <MenuItem
                  value={MESSAGE_FILTER_OPTIONS.ONLY_MESSAGES_FROM_USER}
                >
                  Enviados por mim
                </MenuItem>
              </TextField>
            </div>
            <div className="controlpanel-item">
              <Button
                variant="contained"
                color="primary"
                onClick={handleClick}
                disabled={!documento.status}
              >
                Novo Comentário
              </Button>
            </div>
          </div>
          <div className="border-top"></div>
          <div className="painel-content">
            <div className="painel-body">
              <MessageChat
                selectedAuthorFilter={selectedAuthorFilter}
                filter={filterByType}
                documento={documento}
              />
            </div>
          </div>
        </Col>
      </Row>

      <FormMensagem documento={documento} />
      <ViewMensagem />
      <DeleteMensagem />
    </>
  );
}

export default Mensagens;
