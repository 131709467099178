export const Types = {
  LIST_REQUEST: 'bancos/LIST_REQUEST',
  LIST_SUCCESS: 'bancos/LIST_SUCCESS',
  LIST_ERROR: 'bancos/LIST_ERROR'
}

const INITIAL_STATE = {
  list: [],
  loading: false
}

export default function bancos(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.LIST_REQUEST:
      return { ...state, loading: true }

    case Types.LIST_SUCCESS:
      return { ...state, loading: false, list: action.payload.list }

    case Types.LIST_ERROR:
      return { ...state, loading: false }

    default:
      return state;
  }
}

export const Creators = {
  listRequest: () => ({
    type: Types.LIST_REQUEST
  }),
  listSuccess: list => ({
    type: Types.LIST_SUCCESS,
    payload: { list }
  }),
  listError: () => ({
    type: Types.LIST_ERROR
  })
}