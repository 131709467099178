import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import {
  Typography, Table, TableHead, TableRow, TableCell,
  TableBody, TablePagination, TableFooter
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import BackButton from 'components/BackButton';
import StyledTableRow from 'components/StyledTableRow';
import LoadingTable from 'components/LoadingTable';
import ButtonSquare from 'components/ButtonSquare';
import ToolTipComponent from 'components/Tooltip';

import { Creators as ImagensModalidadeActions } from 'store/ducks/imagensmodalidade';

import FormModal from './FormModal';

import useStyles from './styles';
import Content from 'components/Content';

function TableEmpresasModalidade({ location }) {
  const dispatch = useDispatch();

  const classes = useStyles();

  const { list, loading, page, limit, sort, total } = useSelector(state => state.imagensmodalidade);

  const { modalidade } = location.state;

  const [open, setOpen] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [request, setRequest] = useState({
    modalidade: modalidade._id, page, limit, sort
  });

  useEffect(() => {
    dispatch(ImagensModalidadeActions.listRequest(request))
  }, [dispatch, request])

  function handleChangeRowsPerPage({ target }) {
    const { value } = target;
    setRequest({ ...request, limit: value });
  }

  function handleChangePage(e, newPage) {
    setRequest({ ...request, page: newPage + 1 });
  }

  function handleClick(data) {
    setModalData(data);
    setOpen(true);
  }

  function handleSave() {
    setOpen(false);
  }

  return (
    <>
      <Content>
        <Row>
          <Col md="12" className="painel">
            <div className="painel-content">
              <div className="painel-title text-left">
                <div className={classes.header}>
                  <div className={classes.title}>
                    <Typography className={classes.bold}>
                      Modalidade:&nbsp;
                    </Typography>
                    <Typography>{modalidade.nome}</Typography>
                  </div>
                  <div className={classes.title}>
                    <Typography className={classes.bold}>
                      Tipo de Documento:&nbsp;
                    </Typography>
                    <Typography>{modalidade.tipoDocumento.nome}</Typography>
                  </div>
                </div>
                <div>
                  <BackButton
                    color="primary"
                    variant="contained"
                  >Voltar</BackButton>
                </div>
              </div>
              <div className="painel-body">
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Empresa</TableCell>
                      <TableCell>Imagens</TableCell>
                      <TableCell align="center">Ações</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!!loading && <LoadingTable rows={list.length > 0 ? list.length : 10} columns={3} />}
                    {!loading && list.map(item =>
                      <StyledTableRow key={item._id}>
                        <TableCell>{item.empresa.fantasia}</TableCell>
                        <TableCell>{item.imagens.map(imagem => imagem.nome).join(', ')}</TableCell>
                        <TableCell align="center">
                          <div className="flex-direction">
                            <ToolTipComponent
                              title="Indexadores"
                              placement="top"
                            >
                              <Link
                                to={{
                                  pathname: 'imagens/indexadores',
                                  state: { modalidadeEmpresa: item }
                                }}
                              >
                                <ButtonSquare icon="playlist_add" />
                              </Link>
                            </ToolTipComponent>
                            <ButtonSquare onClick={() => handleClick(item)} icon="edit" />
                          </div>
                        </TableCell>
                      </StyledTableRow>
                    )}
                  </TableBody>
                  {!loading &&
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          count={total}
                          page={request.page - 1}
                          rowsPerPage={request.limit}
                          rowsPerPageOptions={[10, 25, 50, 100]}
                          labelRowsPerPage="Linhas por página"
                          labelDisplayedRows={({ from, to, count }) =>
                            `${from}-${to} de ${count !== -1 ? count : `mais que ${to}`}`
                          }
                          onRowsPerPageChange={handleChangeRowsPerPage}
                          onPageChange={handleChangePage}
                        />
                      </TableRow>
                    </TableFooter>}
                </Table>
              </div>
            </div>
          </Col>
        </Row>
      </Content>
      <FormModal
        data={modalData}
        onClose={() => setOpen(false)}
        onSave={handleSave}
        open={open}
        fullWidth
      />
    </>
  );
}

export default TableEmpresasModalidade;