import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import axios from "axios";
import { getRetrying, getTenant, login as saveToken, setRetrying } from "services/auth";

const baseUrl = process.env.REACT_APP_ROOT_URL ?? 'http://localhost:3000';//criar uma env pra isso
const client_id = 'documentor';
const redirect_uri = `${baseUrl}/callback`;
const post_logout_redirect_uri = `${baseUrl}/`;
const baseUrlKeycload = () => {
    const tenant = getTenant()
    if (tenant) {
        return `${process.env.REACT_APP_KEYCLOAK_URL}/${tenant}`;
    }
    return `${process.env.REACT_APP_KEYCLOAK_URL}/master`;
}

function getHash() {
    return Math.random().toString(36);
}

export function makeLoginUrl() {

    const nonce = getHash();
    const state = getHash();

    //lembrar armazenar com cookie seguro (https)
    Cookies.set("nonce", nonce);
    Cookies.set("state", state);

    const loginUrlParams = new URLSearchParams({
        client_id: client_id,
        redirect_uri: redirect_uri,
        response_type: "code id_token token",
        nonce: nonce,
        state: state,
    });

    const url = `${baseUrlKeycload()}/protocol/openid-connect/auth?${loginUrlParams.toString()}`;
    return url;
}

export async function exchangeCodeForToken(code) {

    try {

        const tokenUrlParams = new URLSearchParams({
            client_id,
            grant_type: "authorization_code",
            code,
            redirect_uri,
            nonce: Cookies.get("nonce"),
        });

        const url = `${baseUrlKeycload()}/protocol/openid-connect/token`;

        const headerParams = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        };

        const ret = await axios.post(
            url,
            tokenUrlParams.toString(),
            headerParams
        )
            .then((res) => {
                console.log('\nret', res.data)//teste
                return res.data;
            })
            .then((data) => {
                return login(data.access_token, null, data.refresh_token);
            })

        console.log('ret', ret)//teste

        return ret;

    } catch (e) {
        console.error('\nError exchangeCodeForToken', e);
        return null;
    }
}

export function login(
    accessToken,
    idToken,
    refreshToken,
    state
) {
    const stateCookie = Cookies.get("state");
    if (state && stateCookie !== state) throw new Error("Invalid state");

    let decodedAccessToken = null;
    let decodedIdToken = null;
    let decodedRefreshToken = null;

    try {
        decodedAccessToken = JwtDecode(accessToken);
        if (idToken) decodedIdToken = JwtDecode(idToken);
        if (refreshToken) decodedRefreshToken = JwtDecode(refreshToken);
    } catch (e) {
        console.error(e);
        throw new Error("Invalid token");
    }

    const nonce = Cookies.get("nonce");

    if (decodedAccessToken.nonce !== nonce) throw new Error("Invalid nonce");
    if (decodedIdToken && decodedIdToken.nonce !== nonce) throw new Error("Invalid nonce");
    if (decodedRefreshToken && decodedRefreshToken.nonce !== nonce) throw new Error("Invalid nonce");

    Cookies.set("access_token", accessToken);
    if (idToken) Cookies.set("id_token", idToken);
    if (decodedRefreshToken) Cookies.set("refresh_token", refreshToken);

    setRetrying(0);
    saveToken(accessToken);

    return decodedAccessToken;
}

export function getAuth() {
    const token = Cookies.get("access_token");

    if (!token) return null;

    try {
        return JwtDecode(token);
    } catch (e) {
        console.error(e);
        return null;
    }
}

export function makeLogoutUrl() {
    if (!Cookies.get("id_token")) return false;

    const logoutParams = new URLSearchParams({
        id_token_hint: Cookies.get("id_token"),
        post_logout_redirect_uri,
    });

    Cookies.remove("access_token");
    Cookies.remove("id_token");
    Cookies.remove("refresh_token");
    Cookies.remove("nonce");
    Cookies.remove("state");

    return `${baseUrlKeycload()}/protocol/openid-connect/logout?${logoutParams.toString()}`;
}

export function JwtDecode(token) {
    return jwtDecode(token);
}

export function retrying() {
    const valor = parseInt(getRetrying());
    const keepAlive = valor < 5;
    if (keepAlive) setRetrying(valor + 1);
    return keepAlive;
}