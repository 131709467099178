export const Types = {
  NEW_REQUEST: "dashboard/NEW_REQUEST",
  NEW_SUCCESS: "dashboard/NEW_SUCCESS",
  SLA_FILTER_REQUEST: "dashboard/SLA_FILTER_REQUEST",
  SLA_FILTER_SUCCESS: "dashboard/SLA_FILTER_SUCCESS",
  TOGGLE_LOADING_SLA: "dashboard/TOGGLE_LOADING_SLA",

  DOCUMENTS_MAX_SCALLING_SLA_REQUEST:
    "dashboard/DOCUMENTS_MAX_SCALLING_SLA_REQUEST",
  DOCUMENTS_MAX_SCALLING_SLA_SUCCESS:
    "dashboard/DOCUMENTS_MAX_SCALLING_SLA_SUCCESS",
  TOGGLE_LOADING_DOCUMENTS_MAX_SCALLING_SLA:
    "dashboard/TOGGLE_LOADING_DOCUMENTS_MAX_SCALLING_SLA",
};

/* Reducer */

const INITIAL_STATE = {
  loading: false,
  data: null,
  slaData: {
    loading: false,
  },
  maxScallingDocumentsWithoutAnswer: {
    loading: false,
  },
};

export default function items(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.NEW_REQUEST:
      return { ...state, loading: true };

    case Types.NEW_SUCCESS:
      return { ...state, loading: false, data: action?.payload?.data };

    case Types.SLA_FILTER_REQUEST:
      return { ...state, slaData: { ...state.slaData, loading: true } };

    case Types.SLA_FILTER_SUCCESS:
      return { ...state, slaData: { ...action.payload, loading: false } };
    case Types.TOGGLE_LOADING_SLA:
      return {
        ...state,
        slaData: {
          ...state.slaData,
          loading: action.payload ?? !state.slaData.loading,
        },
      };
    case Types.DOCUMENTS_MAX_SCALLING_SLA_REQUEST:
      return {
        ...state,
        maxScallingDocumentsWithoutAnswer: {
          ...state.maxScallingDocumentsWithoutAnswer,
          loading: true,
        },
      };

    case Types.DOCUMENTS_MAX_SCALLING_SLA_SUCCESS:
      return {
        ...state,
        maxScallingDocumentsWithoutAnswer: {
          ...action.payload,
          loading: false,
        },
      };
    case Types.TOGGLE_LOADING_DOCUMENTS_MAX_SCALLING_SLA:
      return {
        ...state,
        maxScallingDocumentsWithoutAnswer: {
          ...state.maxScallingDocumentsWithoutAnswer,
          loading:
            action.payload ?? !state.maxScallingDocumentsWithoutAnswer.loading,
        },
      };

    default:
      return state;
  }
}

export const Creators = {
  createRequest: (dashboard) => ({
    type: Types.NEW_REQUEST,
    payload: dashboard,
  }),

  createSuccess: (dashboard) => ({
    type: Types.NEW_SUCCESS,
    payload: dashboard,
  }),

  slaFilterRequest: (dashboard) => ({
    type: Types.SLA_FILTER_REQUEST,
    payload: dashboard,
  }),

  slaFilterSuccess: (dashboard) => ({
    type: Types.SLA_FILTER_SUCCESS,
    payload: dashboard,
  }),

  toggleLoading: (payload) => ({
    payload,
    type: Types.TOGGLE_LOADING_SLA,
  }),

  documentsMaxScalllingWithoutAnswerRequest: (dashboard) => ({
    type: Types.DOCUMENTS_MAX_SCALLING_SLA_REQUEST,
    payload: dashboard,
  }),

  documentsMaxScalllingWithoutAnswerSuccess: (dashboard) => ({
    type: Types.DOCUMENTS_MAX_SCALLING_SLA_SUCCESS,
    payload: dashboard,
  }),

  toggleLdocumentsMaxScalllingWithoutAnswerToggleLooading: (payload) => ({
    payload,
    type: Types.TOGGLE_LOADING_DOCUMENTS_MAX_SCALLING_SLA,
  }),
};
