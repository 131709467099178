import api from "services/api";
import { getToken } from "services/auth";
import { call, select, put, all, takeLatest } from "redux-saga/effects";
import { toastr } from "react-redux-toastr";
import { push } from "connected-react-router";

import { Creators as UFActions, Types } from "../ducks/uf";

export const getOrganization = (state) => state.usuarios.auth.organization;
export const getUserId = (state) => state.usuarios.auth.id;

export function* listUF({ payload }) {
  try {
    const organization = yield select(getOrganization);

    const { page, limit, sort } = payload;

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
      params: { page, limit, sort },
    };

    const response = yield call(api.post, `ufs`, {}, headerParams);

    const data = {
      total: response.data.total,
      list: response.data.docs,
    };

    yield put(UFActions.listSuccess(data));
  } catch (error) {
    toastr.error("Ops", "Não foi possível listar os ufs.");
  }
}

export function* getUF(action) {
  try {
    const headerParams = {
      headers: { Authorization: getToken() },
    };

    const { data } = yield call(api.get, `uf/${action.payload}`, headerParams);

    yield put(UFActions.getSuccess(data));
  } catch (error) {
    toastr.error("Ops", "Não foi possível buscar os dados do UF.");
  }
}

export function* editUF(action) {
  const userId = yield select(getUserId);

  try {
    const organization = yield select(getOrganization);

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const body = { ...action.payload, id_user_acao: userId };

    const { data } = yield call(
      api.put,
      `uf/${action.payload._id}`,
      body,
      headerParams
    );

    yield put(push(`/configuracao/uf`));

    yield put(UFActions.editSuccess(data));

    toastr.success("UF editada com sucesso.");
  } catch (error) {
    toastr.error("Ops", "Não foi possível editar o UF.");
  }
}

export function* createUF(action) {
  const organization = yield select(getOrganization);

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const { data } = yield call(api.post, `uf`, action.payload, headerParams);

    yield put(push(`/configuracao/uf`));

    yield put(UFActions.createSuccess(data));

    toastr.success("", "UF criada com sucesso.");
  } catch (error) {
    toastr.error("Ops", "Não foi possível criar o UF.");
  }
}

export function* deleteUF(action) {
  try {
    const organization = yield select(getOrganization);
    const userId = yield select(getUserId);

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization, userId },
    };

    yield call(api.delete, `uf/${action.payload}`, headerParams);

    toastr.success("", "UF removida com sucesso.");

    yield put(
      UFActions.listRequest({
        page: 1,
        limit: 10,
        sort: "nome",
      })
    );
  } catch (error) {
    //   toastr.error("Ops", "Não foi possível remover os ufs.");
  }
}

export default all([
  takeLatest(Types.LIST_REQUEST, listUF),
  takeLatest(Types.GET_REQUEST, getUF),
  takeLatest(Types.EDIT_REQUEST, editUF),
  takeLatest(Types.NEW_REQUEST, createUF),
  takeLatest(Types.DELETE_REQUEST, deleteUF),
]);
