import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import Navbar from "components/Navbar";
import Menu from "components/Menu";

export default function Content({ children }) {
    const isOpen = useSelector((state) => state.menu.isOpen);
    return (
        <Fragment>
            <Menu />
            {/* <div className={`content ${isOpen ? 'smaller' : ''}`}> */}
            <div className={`content`}>
                <Navbar />
                <div className={`panels ${isOpen ? 'smaller' : ''}`}>
                    {children}
                </div>
            </div>
        </Fragment>
    );

}
