export const Types = {
  LIST_REQUEST: "pesosprodutividade/LIST_REQUEST",
  LIST_SUCCESS: "pesosprodutividade/LIST_SUCCESS",
  NEW_REQUEST: "pesosprodutividade/NEW_REQUEST",
  NEW_SUCCESS: "pesosprodutividade/NEW_SUCCESS",
};

const INITIAL_STATE = {
  pesosprodutividade: [],
  loading: false,
};

export default function pesosprodutividade(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.LIST_REQUEST:
      return { ...state, loading: true, pesosprodutividade: [] };
    case Types.LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        pesosprodutividade: Array.isArray(action.payload)
          ? action.payload[0]
          : null,
      };

    case Types.NEW_REQUEST:
      return { ...state, loading: true };

    case Types.NEW_SUCCESS:
      return { ...state, loading: false };

    default:
      return state;
  }
}

export const Creators = {
  listRequest: () => ({
    type: Types.LIST_REQUEST,
    payload: [],
  }),
  listSuccess: (data) => ({
    type: Types.LIST_SUCCESS,
    payload: data,
  }),

  createRequest: (data) => ({
    type: Types.NEW_REQUEST,
    payload: data,
  }),

  createSuccess: () => ({
    type: Types.NEW_SUCCESS,
    payload: {},
  }),
};
