import React from "react";
import CheckboxComponent from "../../../CustomCheckboxIcon";
import { ICONS_CUSTOM_FIELDS } from "components/Icons";
const StepOne = ({ indexador, changeIndexador, tabela, isImageTypeIndexor = false }) => {

  const isChildren = parseInt(tabela) >= 0;

  const containerStyle = {
    display: "flex",
    justifyContent: "center",
    gap: "1em",
    flexWrap: "wrap",
  };

  const getCheckboxComponent = (componentType, title, Icon) => {
    return (
      <CheckboxComponent
        active={indexador.tipo === componentType}
        title={title}
        Icon={Icon}
        select={() => {
          const e = {
            target: {
              name: "tipo",
              value: componentType.toString(),
            },
          };
          changeIndexador(e);
        }}
      />
    );
  };

  return (
    <div style={containerStyle} className="flex-container flex-start mt-1">
      {getCheckboxComponent("caracter", "Caracter", ICONS_CUSTOM_FIELDS["caracter"])}
      {getCheckboxComponent("data", "Data", ICONS_CUSTOM_FIELDS["data"])}
      {getCheckboxComponent("numero", "Número", ICONS_CUSTOM_FIELDS["numero"])}
      {getCheckboxComponent("combo", "Combo", ICONS_CUSTOM_FIELDS["combo"])}
      {getCheckboxComponent("cpf", "CPF", ICONS_CUSTOM_FIELDS["cpf"])}
      {getCheckboxComponent("cnpj", "CNPJ", ICONS_CUSTOM_FIELDS["cnpj"])}
      {isImageTypeIndexor || (!isChildren && getCheckboxComponent("tabela", "Tabela", ICONS_CUSTOM_FIELDS["tabela"]))}
    </div>
  );
};

export default StepOne;
