import api from 'services/api';
import { getToken } from 'services/auth';
import { call, select, put, all, takeLatest } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';
import { push } from 'connected-react-router';

import {
  Creators as ClassificacaoActions,
  Types,
} from '../ducks/classificacao';

export const getOrganization = (state) => state.usuarios.auth.organization;
export const getUserId = (state) => state.usuarios.auth.id;

export function* getNextJob({ payload }) {
  try {
    const organization = yield select(getOrganization);

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const response = yield call(api.get, `classification`, headerParams);

    yield put(ClassificacaoActions.getNextJobSuccess(response.data));
  } catch (error) {
    toastr.error('Ops', 'Não foi possível retornar o documento.');
  }
}

export function* updateClassification({ payload }) {
  try {
    const organization = yield select(getOrganization);
    const usuario = yield select(getUserId);

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization, usuario },
    };

    const response = yield call(
      api.post,
      `classification`,
      payload,
      headerParams
    );

    yield put(ClassificacaoActions.updateClassificationSuccess(response.data));
  } catch (error) {
    toastr.error('Ops', 'Não foi possível classificar o documento.');
  }
}

export default all([
  takeLatest(Types.NEXTJOB_REQUEST, getNextJob),
  takeLatest(Types.UPDATECLASSIFICATION_REQUEST, updateClassification),
]);
