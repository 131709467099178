import { Types as EncerramentoTypes } from "store/ducks/encerramento";

export const Types = {
  CLEAR_ALL: "documentos/CLEAR_ALL",
  LIST_REQUEST_BY_EMPRESA: "documentos/LIST_REQUEST_BY_EMPRESA",
  LIST_SUCCESS_BY_EMPRESA: "documentos/LIST_SUCCESS_BY_EMPRESA",
  LIST_REQUEST_BY_ETAPAS: "documentos/LIST_REQUEST_BY_ETAPAS",
  LIST_SUCCESS_BY_ETAPAS: "documentos/LIST_SUCCESS_BY_ETAPAS",
  CLEAR_BY_ETAPAS: "documentos/CLEAR_BY_ETAPAS",
  LIST_REQUEST_BY_ETAPAS_RELATORIO:
    "documentos/LIST_REQUEST_BY_ETAPAS_RELATORIO",
  LIST_SUCCESS_BY_ETAPAS_RELATORIO:
    "documentos/LIST_SUCCESS_BY_ETAPAS_RELATORIO",
  NEW_REQUEST: "documentos/NEW_REQUEST",
  NEW_SUCCESS: "documentos/NEW_SUCCESS",
  RESET_LOADING: "documentos/RESET_LOADING",
  SHOW_REQUEST: "documentos/SHOW_REQUEST",
  SHOW_SUCCESS: "documentos/SHOW_SUCCESS",
  EXPORT_TO_CSV_REQUEST: "documentos/EXPORT_TO_CSV_REQUEST",
  EXPORT_TO_CSV_SUCCESS: "documentos/EXPORT_TO_CSV_SUCCESS",
  AUTOCOMPLETE_REQUEST: "documentos/AUTOCOMPLETE_REQUEST",
  AUTOCOMPLETE_SUCCESS: "documentos/AUTOCOMPLETE_SUCCESS",
  SEARCH_RESULT_REQUEST: "documentos/SEARCH_RESULT_REQUEST",
  SEARCH_RESULT_SUCCESS: "documentos/SEARCH_RESULT_SUCCESS",
  ADVANCED_SEARCH_RESULT_REQUEST: "documentos/ADVANCED_SEARCH_RESULT_REQUEST",
  ADVANCED_SEARCH_RESULT_SUCCESS: "documentos/ADVANCED_SEARCH_RESULT_SUCCESS",
  ADVANCED_SEARCH_RESULT_CLEAR: "documentos/ADVANCED_SEARCH_RESULT_CLEAR",
  DOWNLOAD_IMAGES_AS_ZIP_REQUEST: "documentos/DOWNLOAD_IMAGES_AS_ZIP_REQUEST",
  DOWNLOAD_IMAGES_AS_ZIP_SUCCESS: "documentos/DOWNLOAD_IMAGES_AS_ZIP_SUCCESS",
  CONFIRMACAO_DE_PRESENCA_REQUEST: "documentos/CONFIRMACAO_DE_PRESENCA_REQUEST",
  CONFIRMACAO_DE_PRESENCA_SUCCESS: "documentos/CONFIRMACAO_DE_PRESENCA_SUCCESS",
  GO_SEARCH_REQUEST: "documentos/GO_SEARCH_REQUEST",
  GO_SEARCH_SUCCESS: "documentos/GO_SEARCH_SUCCESS",
  GET_DOCUMENTO_REQUEST: "documentos/GET_DOCUMENTO_REQUEST",
  GET_DOCUMENTO_SUCCESS: "documentos/GET_DOCUMENTO_SUCCESS",

  GET_REQUEST_REGRAS: "documentos/GET_REQUEST_REGRAS",
  GET_REQUEST_REGRAS_SUCCESS: "documentos/GET_REGRAS_SUCCESS",

  UPDATE_INDEXADOR_REQUEST: "documentos/UPDATE_INDEXADOR_REQUEST",
  UPDATE_INDEXADOR_SUCCESS: "documentos/UPDATE_INDEXADOR_SUCCESS",
  UPDATE_INDEXADOR_IMAGE_REQUEST: "documentos/UPDATE_INDEXADOR_IMAGE_REQUEST",
  UPDATE_INDEXADOR_IMAGE_SUCCESS: "documentos/UPDATE_INDEXADOR_IMAGE_SUCCESS",
  UPDATE_TYPE_IMAGE_ARCHIEVE_REQUEST: "documentos/UPDATE_TYPE_IMAGE_ARCHIEVE_REQUEST",
  UPDATE_TYPE_IMAGE_ARCHIEVE_SUCCESS: "documentos/UPDATE_TYPE_IMAGE_ARCHIEVE_SUCCESS",
  SEARCH_ES_BY_ID_REQUEST: "documentos/SEARCH_ES_BY_ID_REQUEST",
  SEARCH_ES_BY_ID_SUCCESS: "documentos/SEARCH_ES_BY_ID_SUCCESS",

  UPDATE_IMAGENS_REQUEST: "documentos/UPDATE_IMAGENS_REQUEST",
  UPDATE_IMAGENS_SUCCESS: "documentos/UPDATE_IMAGENS_SUCCESS",

  UPDATE_IMAGENS_REQUEST_V2: "documentos/UPDATE_IMAGENS_REQUEST_V2",
  UPDATE_IMAGENS_SUCCESS_V2: "documentos/UPDATE_IMAGENS_SUCCESS_V2",
  REMOVE_IMAGEM_REQUEST: "documentos/REMOVE_IMAGEM_REQUEST",
  REMOVE_IMAGEM_SUCCESS: "documentos/REMOVE_IMAGEM_SUCCESS",

  SEND_EMAIL_BY_APP_REQUEST: "documentos/SEND_EMAIL_BY_APP_REQUEST",
  SEND_EMAIL_BY_APP_SUCCESS: "documentos/SEND_EMAIL_BY_APP_SUCCESS",
  NEW_MERGE_REQUEST: "documentos/NEW_MERGE_REQUEST",
  NEW_UPLOAD_VERSION_SUCCESS: "documentos/NEW_UPLOAD_VERSION_SUCCESS",
  NEW_UPLOAD_VERSION_REQUEST: "documentos/NEW_UPLOAD_VERSION_REQUEST",
  NEW_MERGE_SUCCESS: "documentos/NEW_MERGE_SUCCESS",

  OPEN_EDIT: "documentos/OPEN_EDIT",
  CLOSE_EDIT: "documentos/CLOSE_EDIT",

  REOPEN_REQUEST: "documentos/REOPEN_REQUEST",
  REOPEN_SUCCESS: "documentos/REOPEN_SUCCESS",
  REOPEN_ERROR: "documentos/REOPEN_ERROR",

  GENERATE_THUMBNAILS_REQUEST: "documentos/GENERATE_THUMBNAILS_REQUEST",
  GENERATE_THUMBNAILS_SUCCESS: "documentos/GENERATE_THUMBNAILS_SUCCESS",

  DOWNLOAD_DOCS_REQUEST: "documents/DOWNLOAD_DOCS_REQUEST",
  DOWNLOAD_DOCS_SUCCESS: "documentos/DOWNLOAD_DOCS_SUCCESS",
};

/* Reducer */

const INITIAL_STATE = {
  advSearch: {
    loading: false,
    result: [],
    page: 1,
    pages: 0,
    total: 0,
    query: {},
    sort: "idEmpresa.razaoSocial",
    searchText: "",
    searchEsById: {},
    fromAnalise: false,
  },
  downImagesAsZip: {
    loading: false,
    message: "",
  },
  confirmarPresenca: {
    loading: false,
    message: "",
  },
  loading: false,
  list: [],
  byEmpresa: [],
  byEtapas: [],
  etapaFinal: false,
  byEtapaRelatorio: [],
  autocomplete: {
    resultSearch: [],
    loading: false,
  },
  searchResult: [],
  regras: [],
  documento: {},
  advQuery: {},
  sla: null,
  values: null,
  page: 1,
  limit: 10,
  total: 0,
  sort: null,
  openIndexadoresEdit: false,
  loadingSave: false,
  loadingReopen: false,
};

export default function documentos(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.NEW_REQUEST:
      return { ...state, loading: true };

    case Types.NEW_SUCCESS:
      return { ...state, loading: false };

    case Types.LIST_REQUEST_BY_EMPRESA:
      return { ...state, ...action.payload, loading: true, byEmpresa: [] };

    case Types.LIST_SUCCESS_BY_EMPRESA:
      return {
        ...state,
        loading: false,
        byEmpresa: action.payload.docs,
        limit: action.payload.limit,
        total: action.payload.total,
      };

    case Types.LIST_REQUEST_BY_ETAPAS:
      return {
        ...state,
        loading: true,
        sla: action.payload.sla,
        etapas: action.payload.etapas,
        values: action.payload.values ? action.payload.values : state.values,
        etapaFinal: action.payload.endEvent,
      };

    case Types.LIST_SUCCESS_BY_ETAPAS:
      return {
        ...state,
        loading: false,
        byEtapas: action.payload.list,
        limit: 10,
        total: action.payload.total,
      };

    case Types.CLEAR_ALL:
      return INITIAL_STATE;

    case Types.CLEAR_BY_ETAPAS:
      return { ...state, byEtapas: [], total: 0 };

    case Types.LIST_REQUEST_BY_ETAPAS_RELATORIO:
      return { ...state, loading: true };

    case Types.LIST_SUCCESS_BY_ETAPAS_RELATORIO:
      return { ...state, loading: false, byEtapaRelatorio: action.payload };

    case Types.EXPORT_TO_CSV_REQUEST:
      return { ...state, loading: true };

    case Types.EXPORT_TO_CSV_SUCCESS:
      return { ...state, loading: false };

    case Types.AUTOCOMPLETE_REQUEST:
      return { ...state, autocomplete: { resultSearch: [], loading: true } };

    case Types.AUTOCOMPLETE_SUCCESS:
      return {
        ...state,
        autocomplete: { resultSearch: action.payload.result, loading: false },
      };

    case Types.SEARCH_RESULT_REQUEST:
      return { ...state, loading: true };

    case Types.SEARCH_RESULT_SUCCESS:
      return {
        ...state,
        loading: false,
        searchResult: action.payload.result,
      };

    case Types.DOWNLOAD_IMAGES_AS_ZIP_REQUEST:
      return { ...state };

    case Types.DOWNLOAD_IMAGES_AS_ZIP_SUCCESS:
      return {
        ...state,
        downImagesAsZip: action.payload.result,
      };

    case Types.CONFIRMACAO_DE_PRESENCA_REQUEST:
      return { ...state };

    case Types.CONFIRMACAO_DE_PRESENCA_SUCCESS:
      return {
        ...state,
        confirmarPresenca: action.payload.result,
      };

    case Types.ADVANCED_SEARCH_RESULT_REQUEST:
      return {
        ...state,
        documento: {},
        advSearch: {
          ...state.advSearch,
          loading: true,
          query: action.payload.q,
          result: [],
          page: action.payload.params.page,
          limit: action.payload.params.limit,
          sort: action.payload.params.sort,
          searchText: "",
          searchEsById: {},
        },
      };

    case Types.ADVANCED_SEARCH_RESULT_SUCCESS:
      return {
        ...state,
        advSearch: {
          ...state.advSearch,
          loading: false,
          result: action.payload.docs,
          page: action.payload.page,
          total: action.payload.total,
          limit: action.payload.limit,
        },
      };

    case Types.GO_SEARCH_REQUEST:
      return {
        ...state,
        documento: {},
        advSearch: {
          ...state.advSearch,
          loading: true,
          query: action.payload.ids,
          result: [],
          limit: 10,
          page: 1,
          sort: action.payload.params.sort,
          searchText: action.payload.params.searchText,
          fromAnalise: action.payload.fromAnalise,
        },
      };

    case Types.ADVANCED_SEARCH_RESULT_CLEAR:
      return {
        ...state,
        documento: {},
        advSearch: {
          ...state.advSearch,
          loading: false,
          result: [],
          page: 0,
          pages: 0,
          total: 0,
          query: {},
        },
      };

    case Types.GET_REQUEST_REGRAS:
      return { ...state, loading: true };

    case Types.GET_REQUEST_REGRAS_SUCCESS:
      return { ...state, loading: false, regras: action.payload };

    case Types.GET_DOCUMENTO_REQUEST:
      return {
        ...state,
        loading: true,
        documento: {},
        advSearch: { ...state.advSearch },
      };

    case Types.GET_DOCUMENTO_SUCCESS:
      return {
        ...state,
        loading: false,
        documento: action.payload,
        advSearch: { ...state.advSearch, fromAnalise: false },
      };

    case Types.UPDATE_INDEXADOR_REQUEST:
      return { ...state, loading: true, loadingSave: true };

    case Types.UPDATE_INDEXADOR_SUCCESS:
      return {
        ...state,
        loading: false,
        byEtapas: [],
        openIndexadoresEdit: false,
        loadingSave: false,
      };

    case Types.UPDATE_INDEXADOR_IMAGE_REQUEST:
      return { ...state, loading: true };

    case Types.UPDATE_INDEXADOR_IMAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        byEtapas: [],
      };

    case Types.UPDATE_TYPE_IMAGE_ARCHIEVE_REQUEST:
      return { ...state, loading: true };

    case Types.UPDATE_TYPE_IMAGE_ARCHIEVE_SUCCESS:
      return {
        ...state,
        loading: false,
        byEtapas: [],
      };

    case Types.SEARCH_ES_BY_ID_REQUEST:
      return {
        ...state,
        advSearch: {
          ...state.advSearch,
          searchEsById: {},
        },
      };

    case Types.SEARCH_ES_BY_ID_SUCCESS:
      return {
        ...state,
        advSearch: {
          ...state.advSearch,
          searchEsById: action.payload,
        },
      };

    case Types.UPDATE_IMAGENS_REQUEST:
      return { ...state, loading: true };

    case Types.UPDATE_IMAGENS_SUCCESS:
      return { ...state, loading: false };

    case Types.UPDATE_IMAGENS_REQUEST_V2:
      return { ...state, loading: true };

    case Types.UPDATE_IMAGENS_SUCCESS_V2:
      return { ...state, loading: false };

    case Types.REMOVE_IMAGEM_REQUEST:
      return { ...state, loading: true };

    case Types.REMOVE_IMAGEM_SUCCESS:
      return { ...state, loading: false };

    case Types.SEND_EMAIL_BY_APP_REQUEST:
      return { ...state, loading: true };

    case Types.SEND_EMAIL_BY_APP_SUCCESS:
      return { ...state, loading: false };

    case Types.NEW_MERGE_REQUEST:
      return { ...state, loading: true };

    case Types.NEW_MERGE_SUCCESS:
      return { ...state, loading: false };

    case Types.NEW_UPLOAD_VERSION_REQUEST:
      return { ...state, loading: true };

    case Types.NEW_UPLOAD_VERSION_SUCCESS:
      return { ...state, loading: false };

    case Types.OPEN_EDIT:
      return {
        ...state,
        openIndexadoresEdit: true,
      };

    case Types.CLOSE_EDIT:
      return {
        ...state,
        openIndexadoresEdit: false,
      };

    case EncerramentoTypes.SAVE_SUCCESS:
      return {
        ...state,
        documento: {
          ...state.documento,
          status: false,
        },
      };

    case Types.REOPEN_REQUEST:
      return {
        ...state,
        loadingReopen: true,
      };

    case Types.REOPEN_SUCCESS:
      return {
        ...state,
        loadingReopen: false,
        documento: {
          ...state.documento,
          status: true,
        },
      };

    case Types.REOPEN_ERROR:
      return {
        ...state,
        loadingReopen: false,
      };

    case Types.RESET_LOADING:
      return {
        ...state,
        loading: false,
      };

    case Types.GENERATE_THUMBNAILS_REQUEST:
      return {
        ...state,
        loading: false,
      };

    case Types.GENERATE_THUMBNAILS_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case Types.DOWNLOAD_DOCS_REQUEST:
      return {
        ...state,
        loading: false,
      };

    case Types.DOWNLOAD_DOCS_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}

/*Actions */

export const Creators = {
  resetLoading: () => ({
    type: Types.RESET_LOADING,
    payload: {},
  }),

  createRequest: (documento) => ({
    type: Types.NEW_REQUEST,
    payload: documento,
  }),

  createSuccess: () => ({
    type: Types.NEW_SUCCESS,
  }),

  listRequestByEmpresa: (payload) => ({
    type: Types.LIST_REQUEST_BY_EMPRESA,
    payload,
  }),

  listSuccessByEmpresa: (payload) => ({
    type: Types.LIST_SUCCESS_BY_EMPRESA,
    payload,
  }),

  listRequestByEtapas: (etapas) => ({
    type: Types.LIST_REQUEST_BY_ETAPAS,
    payload: etapas,
  }),

  listSuccessByEtapas: (documentos) => ({
    type: Types.LIST_SUCCESS_BY_ETAPAS,
    payload: documentos,
  }),

  clearAll: () => ({
    type: Types.CLEAR_ALL
  }),

  clearByEtapas: () => ({
    type: Types.CLEAR_BY_ETAPAS,
    payload: {},
  }),

  listRequestByEtapaRelatorio: (etapas) => ({
    type: Types.LIST_REQUEST_BY_ETAPAS_RELATORIO,
    payload: etapas,
  }),

  listSuccessByEtapaRelatorio: (documentos) => ({
    type: Types.LIST_SUCCESS_BY_ETAPAS_RELATORIO,
    payload: documentos,
  }),

  exportToCSVRequest: (documentos) => ({
    type: Types.EXPORT_TO_CSV_REQUEST,
    payload: documentos,
  }),

  exportToCSVSuccess: () => ({
    type: Types.EXPORT_TO_CSV_SUCCESS,
  }),

  autoCompleteRequest: (q, docid) => ({
    type: Types.AUTOCOMPLETE_REQUEST,
    payload: { q, docid },
  }),

  autoCompleteSuccess: (payload) => ({
    type: Types.AUTOCOMPLETE_SUCCESS,
    payload,
  }),

  searchResultRequest: (q, docid) => ({
    type: Types.SEARCH_RESULT_REQUEST,
    payload: { q, docid },
  }),

  searchResultSuccess: (payload) => ({
    type: Types.SEARCH_RESULT_SUCCESS,
    payload,
  }),

  advancedSearchResultRequest: (q, params) => ({
    type: Types.ADVANCED_SEARCH_RESULT_REQUEST,
    payload: { q, params },
  }),

  advancedSearchResultSuccess: (payload) => ({
    type: Types.ADVANCED_SEARCH_RESULT_SUCCESS,
    payload,
  }),

  downloadImagesAsZipRequest: (payload) => ({
    type: Types.DOWNLOAD_IMAGES_AS_ZIP_REQUEST,
    payload
  }),

  downloadImagesAsZipSuccess: (payload) => ({
    type: Types.DOWNLOAD_IMAGES_AS_ZIP_SUCCESS,
    payload,
  }),

  confirmacaoDePresencaRequest: (payload) => ({
    type: Types.CONFIRMACAO_DE_PRESENCA_REQUEST,
    payload
  }),

  confirmacaoDePresencaSuccess: (payload) => ({
    type: Types.CONFIRMACAO_DE_PRESENCA_SUCCESS,
    payload,
  }),


  advancedSearchResultSClear: () => ({
    type: Types.ADVANCED_SEARCH_RESULT_CLEAR,
    payload: {},
  }),

  sendToSearchPageRequest: (ids, params, justOne, fromAnalise = false) => ({
    type: Types.GO_SEARCH_REQUEST,
    payload: { ids, params, justOne, fromAnalise },
  }),

  getDocumentoRequest: (id) => ({
    type: Types.GET_DOCUMENTO_REQUEST,
    payload: id,
  }),

  getDocumentoSuccess: (regras) => ({
    type: Types.GET_DOCUMENTO_SUCCESS,
    payload: regras,
  }),

  getRequestRegras: (documento) => ({
    type: Types.GET_REQUEST_REGRAS,
    payload: documento,
  }),

  getRegrasSuccess: (regras) => ({
    type: Types.GET_REQUEST_REGRAS_SUCCESS,
    payload: regras,
  }),

  updateDocIndexRequest: (indexadores) => ({
    type: Types.UPDATE_INDEXADOR_REQUEST,
    payload: indexadores,
  }),

  updateDocIndexSuccess: () => ({
    type: Types.UPDATE_INDEXADOR_SUCCESS,
  }),

  updateDocIndexImgRequest: (payload) => ({
    type: Types.UPDATE_INDEXADOR_IMAGE_REQUEST,
    payload: payload,
  }),

  updateDocIndexImgSuccess: () => ({
    type: Types.UPDATE_INDEXADOR_IMAGE_SUCCESS,
  }),


  updateTypeImageArchieveRequest: (payload) => ({
    type: Types.UPDATE_TYPE_IMAGE_ARCHIEVE_REQUEST,
    payload: payload,
  }),

  updateTypeImageArchieveSuccess: () => ({
    type: Types.UPDATE_TYPE_IMAGE_ARCHIEVE_SUCCESS,
  }),

  searchEsByIdRequest: (id) => ({
    type: Types.SEARCH_ES_BY_ID_REQUEST,
    payload: id,
  }),

  searchEsByIdSuccess: (result) => ({
    type: Types.SEARCH_ES_BY_ID_SUCCESS,
    payload: result,
  }),

  updateImagensRequestV2: (imagens) => ({
    type: Types.UPDATE_IMAGENS_REQUEST_V2,
    payload: imagens,
  }),

  updateImagensSuccessV2: () => ({
    type: Types.UPDATE_IMAGENS_SUCCESS_V2,
  }),

  removeImagemRequest: (payload) => ({
    type: Types.REMOVE_IMAGEM_REQUEST,
    payload
  }),

  removeImagemSuccess: () => ({
    type: Types.REMOVE_IMAGEM_SUCCESS
  }),

  updateImagensRequest: (imagens) => ({
    type: Types.UPDATE_IMAGENS_REQUEST,
    payload: imagens,
  }),

  updateImagensSuccess: () => ({
    type: Types.UPDATE_IMAGENS_SUCCESS,
  }),
  sendEmailByAppRequest: (email) => ({
    type: Types.SEND_EMAIL_BY_APP_REQUEST,
    payload: email,
  }),
  sendEmailByAppSuccess: () => ({
    type: Types.SEND_EMAIL_BY_APP_SUCCESS,
  }),
  createMergeRequest: (payload) => ({
    type: Types.NEW_MERGE_REQUEST,
    payload: payload,
  }),
  createMergeSuccess: () => ({
    type: Types.NEW_MERGE_SUCCESS,
  }),
  createUploadVersionRequest: (payload) => ({
    type: Types.NEW_UPLOAD_VERSION_REQUEST,
    payload: payload,
  }),
  createUploadVersionSuccess: () => ({
    type: Types.NEW_UPLOAD_VERSION_SUCCESS,
  }),
  openEdit: () => ({
    type: Types.OPEN_EDIT,
    payload: {},
  }),
  closeEdit: () => ({
    type: Types.CLOSE_EDIT,
    payload: {},
  }),
  reopenRequest: (documento) => ({
    type: Types.REOPEN_REQUEST,
    payload: { documento },
  }),
  reopenSuccess: () => ({
    type: Types.REOPEN_SUCCESS,
  }),
  reopenError: () => ({
    type: Types.REOPEN_ERROR,
  }),
  generateThumbnailsRequest: (params) => ({
    type: Types.GENERATE_THUMBNAILS_REQUEST,
    payload: params,
  }),
  generateThumbnailsSuccess: (params) => ({
    type: Types.GENERATE_THUMBNAILS_SUCCESS,
    payload: params,
  }),
  downloadDocsRequest: (payload) => ({
    type: Types.DOWNLOAD_DOCS_REQUEST,
    payload,
  }),
  downloadDocsSuccess: (payload) => ({
    type: Types.DOWNLOAD_DOCS_SUCCESS,
    payload,
  }),
};
