import styled from "styled-components/macro";

import { default as IconButtonMaterial } from "@material-ui/core/IconButton";

export const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: #cccccc33;
  max-width: 230px;
  border-radius: 100px;
  padding: 8px;
  margin-top: 8px;
`;

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 200px;
  width: 36px;
  height: 36px;
  background-color: #fff;
`;

export const IconButton = styled(IconButtonMaterial)`
  width: 36px;
  height: 36px;
  background-color: #fff;
  color: #444444;
  transition: background-color 0.2s ease, color 0.2s ease;

  &:hover {
    background-color: #444444;
    color: #fff;
  }

  &:not(:last-of-type) {
    margin-right: 8px;
  }
`;
