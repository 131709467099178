import React, { useMemo, useEffect } from "react";

import { Link } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TableFooter,
  TablePagination,
  IconButton,
  Tooltip,
} from "@material-ui/core";

import StyledTableRow from "components/StyledTableRow";
import ButtonSquare from "components/ButtonSquare";
import LoadingTable from "components/LoadingTable";
import ToolTipComponent from "components/Tooltip";
import Utils from "utils/utils";
import { ErrorIcon } from "components/Icons";

function TableCarga({
  loading,
  carga,
  total,
  page,
  limit,
  handleChangePage,
  handleChangeRowsPerPage,
}) {
  const util = new Utils();

  async function loadAttachment(anexo) {
    if (!anexo) return;
    const link = await util.signedUrl(anexo);
    window.open(link, "_blank");
  }

  return (
    <>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Nome arquivo</TableCell>
              <TableCell>Data/Hora</TableCell>
              <TableCell>Linhas</TableCell>
              <TableCell>Sucesso</TableCell>
              <TableCell>Falha</TableCell>
              <TableCell>Alerta</TableCell>
              <TableCell>Operador</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!!loading && <LoadingTable rows={limit} columns={7} />}
            {!loading &&
              carga?.docs?.map((carga) => (
                <StyledTableRow key={carga._id}>
                  <TableCell>{carga.name}</TableCell>
                  <TableCell>{carga.createdAt}</TableCell>
                  <TableCell>{carga.count}</TableCell>
                  <TableCell>{carga.sucess}</TableCell>
                  <TableCell>{carga.fail}</TableCell>
                  <TableCell>
                    <Tooltip
                      title="Carga contém erros"
                      placement="top-start"
                    >
                      <IconButton
                        size="small"
                        color="secondary"
                        component="span"
                      >
                        {carga.fail > 0 ? <ErrorIcon /> : <></>}
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                  <TableCell>{carga.usuario || " - "}</TableCell>
                  <TableCell align="center">
                    <div className="flex-direction">
                      <ToolTipComponent title="Visualizar" placement="top">
                        <Link
                          to={{
                            pathname: "/cargas/gerenciar",
                            state: { carga: carga },
                          }}
                          className="linkicon"
                          id="cargas"
                        >
                          <ButtonSquare icon="visibility" />
                        </Link>
                      </ToolTipComponent>
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    <div className="flex-direction">
                      <ButtonSquare
                        onClick={() => loadAttachment(carga.url)}
                      rel="noopener noreferrer"
                      icon="backup"
                    />
                  </div>
                </TableCell>
              </StyledTableRow>
            ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              count={total || 0}
              page={page - 1}
              rowsPerPage={limit || 10}
              rowsPerPageOptions={[10, 25, 50, 100]}
              labelRowsPerPage="Linhas por página"
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} de ${count !== -1 ? count : `mais que ${to}`}`
              }
              onRowsPerPageChange={handleChangeRowsPerPage}
              onPageChange={handleChangePage}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  </>
);
}

export default TableCarga;
