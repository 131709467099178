export const Types = {
  NEW: "despesa/NEW",
  EDIT: "despesa/EDIT",
  CANCEL: "despesa/CANCEL",
  SAVE_REQUEST: "despesa/SAVE_REQUEST",
  SAVE_SUCCESS: "despesa/SAVE_SUCCESS",
  SAVE_ERROR: "despesa/SAVE_ERROR",
  DELETE_REQUEST: "despesa/DELETE_REQUEST",
  DELETE_SUCCESS: "despesa/DELETE_SUCCESS",
  OPEN_UPLOAD: "despesa/OPEN_UPLOAD",
  CLOSE_UPLOAD: "despesa/CLOSE_UPLOAD",
  OPEN_VIEW: "despesa/OPEN_VIEW",
  CLOSE_VIEW: "despesa/CLOSE_VIEW",
  OPEN_VIEW_IMAGENS: "despesa/OPEN_VIEW_IMAGENS",
  CLOSE_VIEW_IMAGENS: "despesa/CLOSE_VIEW_IMAGENS",
  OPEN_DELETE: "despesa/OPEN_DELETE",
  CLOSE_DELETE: "despesa/CLOSE_DELETE",
  UPLOAD_REQUEST: "despesa/UPLOAD_REQUEST",
  UPLOAD_SUCCESS: "despesa/UPLOAD_SUCCESS",
  UPLOAD_ERROR: "despesa/UPLOAD_ERROR",
  DELETE_IMAGE_REQUEST: "despesa/DELETE_IMAGE_REQUEST",
  DELETE_IMAGE_SUCCESS: "despesa/DELETE_IMAGE_SUCCESS",
  DELETE_IMAGE_ERROR: "despesa/DELETE_IMAGE_ERROR"
};

const INITIAL_STATE = {
  data: {},
  loading: false,
  open: false,
  openUpload: false,
  openView: false,
  openViewImages: false,
  openDelete: false,
};

export default function despesa(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.NEW:
      return {
        ...state,
        data: {},
        loading: false,
        open: true,
      };

    case Types.EDIT:
      return {
        ...state,
        data: action.payload.despesa,
        loading: false,
        open: true,
      };

    case Types.SAVE_REQUEST:
      return {
        ...state,
        loading: true,
        data: action.payload.despesa,
      };

    case Types.SAVE_SUCCESS:
      return {
        ...state,
        loading: false,
        open: false,
      };

    case Types.SAVE_ERROR:
      return {
        ...state,
        loading: false,
      };

    case Types.DELETE_REQUEST:
      return {
        ...state,
        loading: true,
        data: action.payload,
      };

    case Types.DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        openDelete: false,
      };

    case Types.CANCEL:
      return {
        ...state,
        loading: false,
        open: false,
      };

    case Types.OPEN_UPLOAD:
      return {
        ...state,
        openUpload: true,
        data: action.payload.despesa,
      };

    case Types.CLOSE_UPLOAD:
      return {
        ...state,
        openUpload: false,
        data: {},
      };

    case Types.OPEN_VIEW:
      return {
        ...state,
        openView: true,
        data: action.payload.despesa,
      };

    case Types.CLOSE_VIEW:
      return {
        ...state,
        openView: false,
        data: {},
      };

    case Types.OPEN_DELETE:
      return {
        ...state,
        openDelete: true,
        data: action.payload.despesa,
      };

    case Types.CLOSE_DELETE:
      return {
        ...state,
        openDelete: false,
        data: {},
      };

    case Types.UPLOAD_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case Types.UPLOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        openUpload: false,
      };

    case Types.UPLOAD_ERROR:
      return {
        ...state,
        loading: false,
      };

    case Types.OPEN_VIEW_IMAGENS:
      return { ...state, openViewImages: true, data: action.payload.despesa }

    case Types.CLOSE_VIEW_IMAGENS:
      return { ...state, openViewImages: false, data: {} }

    case Types.DELETE_IMAGE_REQUEST:
      return { ...state, loading: true }

    case Types.DELETE_IMAGE_SUCCESS:
      return { ...state, loading: false, data: action.payload.despesa }

    case Types.DELETE_IMAGE_ERROR:
      return { ...state, loading: false }

    default:
      return state;
  }
}

export const Creators = {
  new: () => ({
    type: Types.NEW,
  }),
  edit: (despesa) => ({
    type: Types.EDIT,
    payload: { despesa },
  }),
  cancel: () => ({
    type: Types.CANCEL,
  }),
  saveRequest: (despesa) => ({
    type: Types.SAVE_REQUEST,
    payload: { despesa },
  }),
  saveSuccess: () => ({
    type: Types.SAVE_SUCCESS,
  }),
  saveError: () => ({
    type: Types.SAVE_ERROR,
  }),
  deleteRequest: (id) => ({
    type: Types.DELETE_REQUEST,
    payload: id,
  }),
  deleteSuccess: () => ({
    type: Types.DELETE_SUCCESS,
  }),
  openUpload: (despesa) => ({
    type: Types.OPEN_UPLOAD,
    payload: { despesa },
  }),
  closeUpload: () => ({
    type: Types.CLOSE_UPLOAD,
  }),

  openView: (despesa) => ({
    type: Types.OPEN_VIEW,
    payload: { despesa },
  }),
  closeView: () => ({
    type: Types.CLOSE_VIEW,
  }),
  openDelete: (despesa) => ({
    type: Types.OPEN_DELETE,
    payload: { despesa },
  }),
  closeDelete: () => ({
    type: Types.CLOSE_DELETE,
    payload: {},
  }),
  uploadRequest: (body) => ({
    type: Types.UPLOAD_REQUEST,
    payload: { body },
  }),
  uploadSuccess: () => ({
    type: Types.UPLOAD_SUCCESS,
  }),
  uploadError: () => ({
    type: Types.UPLOAD_ERROR,
  }),
  openViewImages: despesa => ({
    type: Types.OPEN_VIEW_IMAGENS,
    payload: { despesa }
  }),
  closeViewImages: () => ({
    type: Types.CLOSE_VIEW_IMAGENS
  }),
  deleteImageRequest: idImage => ({
    type: Types.DELETE_IMAGE_REQUEST,
    payload: { idImage }
  }),
  deleteImageSuccess: despesa => ({
    type: Types.DELETE_IMAGE_SUCCESS,
    payload: { despesa }
  }),
  deleteImageError: () => ({
    type: Types.DELETE_IMAGE_ERROR
  })
};
