/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import useForm from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { toastr } from "react-redux-toastr";

import bcrypt from "bcryptjs";

import { Link } from "react-router-dom";

import { Creators as UsuariosActions } from "../../store/ducks/usuarios";

import "./styles.css";

export default function ChangePassword(props) {
  const dispatch = useDispatch();
  const { register, handleSubmit, errors } = useForm();

  const [verified, setVerified] = useState(false);
  const [gResponse, setGResponse] = useState("");

  const [senha1, setSenha1] = useState();
  const [senha2, setSenha2] = useState();

  const [validaSenhas, setValidaSenhas] = useState(true);

  const token = props.match.params.token;
  const user = props.match.params.user;
  const org = props.match.params.org;
  const loading = useSelector((state) => state.usuarios.loading);
  const errorMessage = useSelector((state) => state.usuarios.error);

  const { isValidated } = useSelector((state) => state.usuarios.validatedToken);

  useEffect(() => {
    dispatch(UsuariosActions.validateTokenRequest(token));
  }, []);

  const onSubmit = async (values) => {
    const hashingPass = async (password) => {
      const salt = await bcrypt.genSalt(10);
      const passHash = await bcrypt.hash(password, salt);
      const editedPass = passHash;

      return editedPass;
    };

    let passHashed = await hashingPass(senha1);

    if (senha1 !== senha2) {
      toastr.error(
        "Falha ao trocar a senha",
        "As senhas digitadas precisam ser iguais."
      );
    } else {
      dispatch(
        UsuariosActions.changePasswordByLoginRequest({
          usuario: user,
          changePassword: false,
          senha: passHashed,
          org: org,
        })
      );
    }
  };

  const handleChangeSenha1 = (e) => {
    if (e.target.value) {
      setSenha1(e.target.value);
    }
  };

  const handleChangeSenha2 = (e) => {
    if (e.target.value) {
      setSenha2(e.target.value);
    }
  };

  useEffect(() => {
    if (senha1 === senha2) {
      setValidaSenhas(true);
    } else {
      setValidaSenhas(false);
    }
  }, [senha1, senha2]);

  return (
    <div className="login-form">
      <div className="left">
        <div className="content"></div>
      </div>
      <div className="center">
        <div className="content">
          <div className="loginfields">
            <div>
              <h2>Troque</h2>
              <h4>Sua Senha</h4>
            </div>

            {isValidated && isValidated === true ? (
              <div>
                <form onSubmit={handleSubmit(onSubmit)}>
                  {errorMessage && (
                    <div className="loginError">{errorMessage}</div>
                  )}

                  <div>Usuário : {user}</div>

                  <input
                    type="password"
                    className={
                      validaSenhas === true ? "password" : "color-validation"
                    }
                    name="senha1"
                    ref={register}
                    spellCheck="false"
                    placeholder="Digite sua nova senha"
                    autoComplete="off"
                    onChange={handleChangeSenha1}
                  />

                  <input
                    type="password"
                    className={
                      validaSenhas === true ? "password" : "color-validation"
                    }
                    name="senha"
                    ref={register}
                    spellCheck="false"
                    placeholder="Confirme sua nova senha"
                    onChange={handleChangeSenha2}
                  />
                  <button>Trocar</button>
                  <Link to="/">
                    <button>Voltar para Página de Login</button>
                  </Link>
                </form>
              </div>
            ) : (
              <div>
                <div className="forgot-password-spacing">
                  O link expirou. Faça uma nova solicitação de troca de senha
                  para gerar um novo link.
                </div>

                <Link to="/">
                  <button disabled={loading}>
                    {loading ? "Carregando..." : "Voltar para Página de Login"}
                  </button>
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="right">
        <div className="content"></div>
      </div>
    </div>
  );
}
