export const Types = {
    ADD_FILES_TO_MERGE: "mergepdf/ADD_FILES_TO_MERGE",
    CLEAR_FILES_TO_MERGE: "mergepdf/CLEAR_FILES_TO_MERGE"
}

const INITIAL_STATE = {
    filesToMerge: null,
    output: null
}

export default function organizacoes(state = INITIAL_STATE, action) {
    switch(action.type) {

        case Types.ADD_FILES_TO_MERGE:
            return { ...state, ...action.payload }
        
        case Types.CLEAR_FILES_TO_MERGE:
            return { ...INITIAL_STATE }
        
        default:
            return state;
    }
}

export const Creators = {
    
    add: payload => ({
        type: Types.ADD_FILES_TO_MERGE,
        payload
    }),

    clear: () => ({
        type: Types.CLEAR_FILES_TO_MERGE
    })
}