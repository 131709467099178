import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form } from "@unform/web";
import { Col, Row } from "react-bootstrap";
import { isArray } from "lodash";
import { Creators as TiposTarefasActions } from "store/ducks/tipostarefas";
import Etapas from "../../../../pages/Esteira/components/Etapas";

import { Creators as EtapasActions } from "../../../../store/ducks/etapas";

import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

const StepFour = ({
  handleChangeMostrarFluxo,
  handleChangeObrigatorioEtapa,
  indexador,
}) => {
  const dispatch = useDispatch();

  const containerStyle = {
    display: "flex",
    justifyContent: "center",
    gap: "1em",
    flexWrap: "wrap",
  };

  const {
    access: { empresas },
  } = useSelector((state) => state.permissoes);

  function newSelection(e, etapa) {
    if (etapa)
      handleChangeObrigatorioEtapa(
        e,
        isArray(etapa)
          ? [...etapa, ...indexador.obrigatorioEtapa]
          : [...[etapa], ...indexador.obrigatorioEtapa]
      );
    setOpenEtapas(false);
  }

  useEffect(() => {
    dispatch(
      TiposTarefasActions.listRequest({
        page: 1,
        limit: Number.MAX_SAFE_INTEGER,
        sort: "nome",
      })
    );
  }, [dispatch]);

  const { list: listTiposTarefas } = useSelector((state) => state.tipostarefas);
  const [selectedEtapasNome, setSelectedEtapasNome] = useState([]);
  const [openEtapas, setOpenEtapas] = useState(false);

  return (
    <div style={containerStyle} className="flex-container flex-start mt-1">
      <Col md="12">
        <Form>
          <Row>
            <Col md="6">
              <Autocomplete
                multiple
                selectOnFocus
                clearOnBlur
                id="options-combo"
                name="mostrarFluxo"
                onChange={(e, newValue) => {
                  handleChangeMostrarFluxo(e, newValue);

                  const last = newValue.pop();
                  if (last && last?.fila) {
                    setOpenEtapas(true);
                    dispatch(EtapasActions.listRequest(last.fila, empresas));
                  }
                }}
                value={
                  indexador.mostrarFluxo && isArray(indexador.mostrarFluxo)
                    ? indexador.mostrarFluxo
                    : []
                }
                options={listTiposTarefas}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Indexador aparecerá no(s) fluxo(s)"
                    variant="outlined"
                    name="mostrarFluxo"
                    error={
                      !indexador.mostrarFluxo ||
                      indexador.mostrarFluxo.length === 0
                    }
                    helperText={
                      (!indexador.mostrarFluxo ||
                        indexador.mostrarFluxo.length === 0) &&
                      "Desejável a configuração de um ou mais fluxos"
                    }
                  />
                )}
                getOptionLabel={(option) =>
                  typeof option === "string" ? option : option.nome
                }
                renderOption={(option) =>
                  typeof option === "string" ? option : option.nome
                }
                size="small"
              />
            </Col>
            <Col md="6">
              <Autocomplete
                freeSolo
                multiple
                selectOnFocus
                clearOnBlur
                id="options-combo"
                name="obrigatorioEtapa"
                onChange={handleChangeObrigatorioEtapa}
                value={
                  indexador.obrigatorioEtapa &&
                    isArray(indexador.obrigatorioEtapa)
                    ? indexador.obrigatorioEtapa
                    : []
                }
                options={[]}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Indexador obrigatório na(s) etapa(s)"
                    variant="outlined"
                    name="obrigatorioEtapa"
                    error={
                      !indexador.obrigatorioEtapa ||
                      indexador.obrigatorioEtapa.length === 0
                    }
                    helperText={
                      (!indexador.obrigatorioEtapa ||
                        indexador.obrigatorioEtapa.length === 0) &&
                      "Desejável a configuração de uma ou mais etapas"
                    }
                  />
                )}
                getOptionLabel={(option) =>
                  typeof option === "string" ? option : option.inputValue
                }
                filterOptions={(options, params) => [
                  {
                    inputValue: params.inputValue,
                    title: `Nova etapa obrigatória "${params.inputValue}"`,
                  },
                ]}
                renderOption={(option) =>
                  typeof option === "string" ? option : option.title
                }
                size="small"
              />
            </Col>
          </Row>
        </Form>
      </Col>
      <Etapas
        setSelectedEtapasNome={setSelectedEtapasNome}
        selectedEtapasNome={selectedEtapasNome}
        openEtapas={openEtapas}
        setOpenEtapas={setOpenEtapas}
        functionToResult={newSelection}
        multiple={true}
        isToResult={true}
      />
    </div>
  );
};

export default StepFour;
