export const Types = {
  NEW: "mensagem/NEW",
  EDIT: "mensagem/EDIT",
  CANCEL: "mensagem/CANCEL",
  SAVE_REQUEST: "mensagem/SAVE_REQUEST",
  SAVE_SUCCESS: "mensagem/SAVE_SUCCESS",
  DELETE_REQUEST: "mensagem/DELETE_REQUEST",
  DELETE_SUCCESS: "mensagem/DELETE_SUCCESS",
  SAVE_ERROR: "mensagem/SAVE_ERROR",
  OPEN_UPLOAD: "mensagem/OPEN_UPLOAD",
  CLOSE_UPLOAD: "mensagem/CLOSE_UPLOAD",
  OPEN_VIEW: "mensagem/OPEN_VIEW",
  CLOSE_VIEW: "mensagem/CLOSE_VIEW",
  OPEN_DELETE: "mensagem/OPEN_DELETE",
  CLOSE_DELETE: "mensagem/CLOSE_DELETE",
  UPLOAD_REQUEST: "mensagem/UPLOAD_REQUEST",
  UPLOAD_SUCCESS: "mensagem/UPLOAD_SUCCESS",
  UPLOAD_ERROR: "mensagem/UPLOAD_ERROR",
};

const INITIAL_STATE = {
  data: {},
  loading: false,
  open: false,
  openUpload: false,
  openView: false,
  openDelete: false,
};

export default function mensagem(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.NEW:
      return {
        ...state,
        data: {},
        loading: false,
        open: true,
      };

    case Types.EDIT:
      return {
        ...state,
        data: action.payload.mensagem,
        loading: false,
        open: true,
      };

    case Types.SAVE_REQUEST:
      return {
        ...state,
        loading: true,
        data: action.payload.mensagem,
      };

    case Types.SAVE_SUCCESS:
      return {
        ...state,
        loading: false,
        open: false,
      };

    case Types.SAVE_ERROR:
      return {
        ...state,
        loading: false,
      };

    case Types.DELETE_REQUEST:
      return {
        ...state,
        loading: true,
        data: action.payload,
      };

    case Types.DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        openDelete: false,
      };

    case Types.CANCEL:
      return {
        ...state,
        loading: false,
        open: false,
      };

    case Types.OPEN_UPLOAD:
      return {
        ...state,
        openUpload: true,
        data: action.payload.mensagem,
      };

    case Types.CLOSE_UPLOAD:
      return {
        ...state,
        openUpload: false,
        data: {},
      };

    case Types.OPEN_VIEW:
      return {
        ...state,
        openView: true,
        data: action.payload.mensagem,
      };

    case Types.CLOSE_VIEW:
      return {
        ...state,
        openView: false,
        data: {},
      };

    case Types.OPEN_DELETE:
      return {
        ...state,
        openDelete: true,
        data: action.payload.mensagem,
      };

    case Types.CLOSE_DELETE:
      return {
        ...state,
        openDelete: false,
        data: {},
      };

    case Types.UPLOAD_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case Types.UPLOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        openUpload: false,
      };

    case Types.UPLOAD_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}

export const Creators = {
  new: () => ({
    type: Types.NEW,
  }),
  edit: (mensagem) => ({
    type: Types.EDIT,
    payload: { mensagem },
  }),
  cancel: () => ({
    type: Types.CANCEL,
  }),
  saveRequest: (mensagem) => ({
    type: Types.SAVE_REQUEST,
    payload: { mensagem },
  }),
  saveSuccess: () => ({
    type: Types.SAVE_SUCCESS,
  }),
  deleteRequest: (id) => ({
    type: Types.DELETE_REQUEST,
    payload: id,
  }),
  deleteSuccess: () => ({
    type: Types.DELETE_SUCCESS,
  }),
  saveError: () => ({
    type: Types.SAVE_ERROR,
  }),
  openUpload: (mensagem) => ({
    type: Types.OPEN_UPLOAD,
    payload: { mensagem },
  }),
  closeUpload: () => ({
    type: Types.CLOSE_UPLOAD,
  }),
  openView: (mensagem) => ({
    type: Types.OPEN_VIEW,
    payload: { mensagem },
  }),
  closeView: () => ({
    type: Types.CLOSE_VIEW,
  }),
  openDelete: (mensagem) => ({
    type: Types.OPEN_DELETE,
    payload: { mensagem },
  }),
  closeDelete: () => ({
    type: Types.CLOSE_DELETE,
    payload: {},
  }),

  uploadRequest: (body) => ({
    type: Types.UPLOAD_REQUEST,
    payload: { body },
  }),
  uploadSuccess: () => ({
    type: Types.UPLOAD_SUCCESS,
  }),
  uploadError: () => ({
    type: Types.UPLOAD_ERROR,
  }),
};
