import api from "services/api";
import { getToken } from "services/auth";
import { call, select, put, all, takeLatest } from "redux-saga/effects";
import { toastr } from "react-redux-toastr";

import {
  Creators as TemplateSignaturePointsActions,
  Types,
} from "../ducks/templatesignaturepoints";

export const getOrganization = (state) => state.usuarios.auth.organization;
export const getUserId = (state) => state.usuarios.auth.id;

export function* listTemplateSignaturePoints({ payload }) {
  try {
    const organization = yield select(getOrganization);

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    if (!payload)
      return yield put(TemplateSignaturePointsActions.listSuccess([]));

    const response = yield call(
      api.get,
      `template/signature-points/${payload}`,
      headerParams
    );

    yield put(TemplateSignaturePointsActions.listSuccess(response.data || []));
  } catch (error) {
    toastr.error("Ops", "Não foi possível listar os pontos de assinaturas.");
  }
}

export function* editTemplateSignaturePoints({ payload }) {
  try {
    const organization = yield select(getOrganization);

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const response = yield call(
      api.post,
      `template/signature-points`,
      payload,
      headerParams
    );

    yield put(TemplateSignaturePointsActions.editSuccess(response));

    toastr.success(
      "Pontos de assinaturas salvos com sucesso"
    );

    yield put(TemplateSignaturePointsActions.listRequest());
  } catch (error) {
    toastr.error(
      "Ops",
      "Não foi possível criar ou editar os pontos de assinaturas."
    );
  }
}

export default all([
  takeLatest(Types.LIST_REQUEST, listTemplateSignaturePoints),
  takeLatest(Types.EDIT_REQUEST, editTemplateSignaturePoints),
]);
