import api from '../../services/api';
import { getToken } from '../../services/auth';
import { put, call, select } from 'redux-saga/effects';
import { toastr } from "react-redux-toastr";

import { Creators as DividaAtivaActions} from '../ducks/dividaativa';

export const getOrganization = state => state.usuarios.auth.organization;

export function* listLancamentos(action) {
  
    const organization = yield select(getOrganization);
  
    try {
      const headerParams = {
        headers: { Authorization: getToken(), xkeyorg: organization }
      };
  
      const { data } = yield call(api.post, `dividaativa/lancamentos`, action.payload, headerParams);
  
      yield put(DividaAtivaActions.listLancamentosSuccess(data));
      
    } catch (error) {
      toastr.error("Ops", "Não foi possível listar os lançamentos.");
    }
  }

  export function* listValores(action) {
  
    const organization = yield select(getOrganization);
  
    try {
      const headerParams = {
        headers: { Authorization: getToken(), xkeyorg: organization }
      };
  
      const { data } = yield call(api.post, `dividaativa/valores`, action.payload, headerParams);
  
      yield put(DividaAtivaActions.listValoresSuccess(data));
      
    } catch (error) {
      toastr.error("Ops", "Não foi possível listar os lançamentos.");
    }
  }

  export function* listAnalitico(action) {
  
    const organization = yield select(getOrganization);
  
    try {
      const headerParams = {
        headers: { Authorization: getToken(), xkeyorg: organization }
      };
  
      const { data } = yield call(api.post, `dividaativa/total`, action.payload, headerParams);
  
      yield put(DividaAtivaActions.listAnaliticoSuccess(data));
      
    } catch (error) {
      toastr.error("Ops", "Não foi possível listar os lançamentos.");
    }
  }