import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@material-ui/core";
import { DialogContent } from "@material-ui/core";
import { Modal, DialogTitle, ContainerMessage } from "./styles";
import { Row, Col } from "react-bootstrap";
import { BeatLoader } from "react-spinners";
import { FirstPageIcon, LastPageIcon } from "components/Icons";
import { getPresignedUrl } from "services/presignedUrl";
import { InfoIcon } from "components/Icons";

import Viewer from "components/SearchViewer/Viewer";
import Button from "@material-ui/core/Button";

import DialogActions from "@material-ui/core/DialogActions";
import ButtonMui from "@material-ui/core/Button";

import { Creators as VersionsImageActions } from "store/ducks/versionsimage";

function ImageVersions({ open, image, handleOpenVersionsModal, selectedDoc }) {
  const dispatch = useDispatch();

  const [fileObjectArray, setFileObjectArray] = useState([]);

  const [previewURL, setPreviewURL] = useState("");
  const [numberVersion, setNumberVersion] = useState(0);
  const [index, setIndex] = useState(0);

  const { loading, data } = useSelector((state) => state.versionsimage);

  const setFileObjectCb = useCallback(async () => {
    if (data && data.length > 0) {
      const signedUrl = await getPresignedUrl(data[index].location);

      setPreviewURL(signedUrl);
      setNumberVersion(data[index]?.numberVersion || 0);
      setFileObjectArray(data);
      setIndex(0);
    } else {
      setFileObjectArray([]);
    }
  }, [data, index]);

  useEffect(() => {
    setFileObjectCb();
  }, [selectedDoc, setFileObjectCb]);

  useEffect(() => {
    if (selectedDoc._id) {
      dispatch(
        VersionsImageActions.listRequest({
          documento: selectedDoc._id,
          imagem: image._id,
        })
      );
    }
  }, [selectedDoc, image, dispatch]);

  function nextImage() {
    setIndex(index + 1);
  }

  function previousImage() {
    setIndex(index - 1);
  }

  const nothingToShow = data && data.length === 0;

  return (
    <Modal open={open} className="lg-modal">
      <div className="modal-header">
        <DialogTitle className="modal-title">Versões da imagem</DialogTitle>
      </div>
      <DialogContent>
        {!!nothingToShow && !loading && (
          <ContainerMessage>
            <div>
              <InfoIcon />
            </div>
            <h4>Imagem não possui versões anteriores</h4>
          </ContainerMessage>
        )}
        {!nothingToShow && (
          <Col md="12">
            {!!loading ? (
              <BeatLoader
                sizeUnit={"px"}
                size={10}
                color={"#444444"}
                loading={loading}
              />
            ) : (
              <Col className="search-viewer__thumbnail-gallery--container">
                <Col md={12}>
                  <Row className="search-viewer__thumbnail-gallery--wrapper">
                    <b>{`Versão ${numberVersion}`}</b>
                    <Viewer imagem={previewURL}></Viewer>
                  </Row>
                </Col>
              </Col>
            )}
          </Col>
        )}
        <DialogActions>
          <div className="buttons p-2">
            <Grid container spacing={1}>
              <Grid item>
                <Button
                  color="primary"
                  variant="contained"
                  disabled={index === 0 || nothingToShow}
                  onClick={previousImage}
                >
                  <FirstPageIcon />
                </Button>
              </Grid>

              <Grid item>
                <Button
                  color="primary"
                  variant="contained"
                  disabled={
                    index === fileObjectArray.length - 1 || nothingToShow
                  }
                  onClick={nextImage}
                >
                  <LastPageIcon />
                </Button>
              </Grid>

              {!loading && (
                <Grid item>
                  <ButtonMui
                    variant="contained"
                    disabled={nothingToShow}
                    color="secondary"
                    type="button"
                    onClick={() => window.open(previewURL, "_blank")}
                  >
                    Abrir em nova aba
                  </ButtonMui>
                </Grid>
              )}
              <Grid item>
                <ButtonMui
                  variant="contained"
                  color="secondary"
                  type="button"
                  onClick={handleOpenVersionsModal}
                >
                  Voltar
                </ButtonMui>
              </Grid>
            </Grid>
          </div>
        </DialogActions>
      </DialogContent>
    </Modal>
  );
}

export default ImageVersions;
