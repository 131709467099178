export const Types = {
  ANALITICO_REQUEST: "reclamacoes/ANALITICO_REQUEST",
  ANALITICO_SUCCESS: "reclamacoes/ANALITICO_SUCCESS",
  ANALITICO_CLOSE: "reclamacoes/ANALITICO_CLOSE",
  STATUS_ACOES_REQUEST: "processos/STATUS_ACOES_REQUEST",
  STATUS_ACOES_SUCCESS: "processos/STATUS_ACOES_SUCCESS",
  ACOES_UF_REQUEST: "processos/ACOES_UF_REQUEST",
  ACOES_UF_SUCCESS: "processos/ACOES_UF_SUCCESS",
  MONITORIA_REQUEST: "processos/MONITORIA_REQUEST",
  MONITORIA_SUCCESS: "processos/MONITORIA_SUCCESS",
  NATUREZA_OPERACAO_REQUEST: "processos/NATUREZA_OPERACAO_REQUEST",
  NATUREZA_OPERACAO_SUCCESS: "processos/NATUREZA_OPERACAO_SUCCESS",
  CAPTURA_ACAO_JUDICIAL_REQUEST: "processos/CAPTURA_ACAO_JUDICIAL_REQUEST",
  CAPTURA_ACAO_JUDICIAL_SUCCESS: "processos/CAPTURA_ACAO_JUDICIAL_SUCCESS",
  CAPTURA_ACAO_JUDICIAL_PRAZO_REQUEST:
    "processos/CAPTURA_ACAO_JUDICIAL_PRAZO_REQUEST",
  CAPTURA_ACAO_JUDICIAL_PRAZO_SUCCESS:
    "processos/CAPTURA_ACAO_JUDICIAL_PRAZO_SUCCESS",
};

const INITIAL_STATE = {
  analitico: {
    result: [],
    show: false,
    title: "",
  },
  statusAcoes: {
    graphic: [],
    loading: false,
  },
  monitoria: {
    graphic: [],
    loading: false,
  },
  acoesUF: {
    graphic: [],
    loading: false,
  },
  naturezaOperacao: {
    graphic: [],
    loading: false,
  },
  capturaAcaoJudicial: {
    graphic: [],
    loading: false,
  },
  capturaAcaoJudicialPrazo: {
    graphic: [],
    loading: false,
  },
};

export default function processos(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.ANALITICO_REQUEST:
      return {
        ...state,
        analitico: {
          ...state.analitico,
          loading: true,
          result: [],
          page: action.payload.params.page,
          limit: action.payload.params.limit,
          sort: action.payload.params.sort,
          show: true,
          title: action.payload.title,
          type: action.payload.type,
          param: action.payload.param,
        },
      };
    case Types.ANALITICO_SUCCESS:
      return {
        ...state,
        analitico: {
          ...state.analitico,
          loading: false,
          result: action.payload.docs,
          page: action.payload.page,
          total: action.payload.total,
          limit: action.payload.limit,
          show: true,
        },
      };

    case Types.ANALITICO_CLOSE:
      return {
        ...state,
        analitico: {
          list: [],
          show: false,
          title: "",
        },
      };

    case Types.STATUS_ACOES_REQUEST:
      return {
        ...state,
        statusAcoes: {
          graphic: [],
          loading: true,
        },
      };

    case Types.STATUS_ACOES_SUCCESS:
      return {
        ...state,
        statusAcoes: {
          loading: false,
          graphic: action.payload.graphic,
        },
      };

    case Types.ACOES_UF_REQUEST:
      return {
        ...state,
        acoesUF: {
          graphic: [],
          loading: true,
        },
      };

    case Types.ACOES_UF_SUCCESS:
      return {
        ...state,
        acoesUF: {
          loading: false,
          graphic: action.payload.graphic,
        },
      };

    case Types.MONITORIA_REQUEST:
      return {
        ...state,
        monitoria: {
          graphic: [],
          loading: true,
        },
      };

    case Types.MONITORIA_SUCCESS:
      return {
        ...state,
        monitoria: {
          loading: false,
          graphic: action.payload.graphic,
        },
      };

    case Types.NATUREZA_OPERACAO_REQUEST:
      return {
        ...state,
        naturezaOperacao: {
          graphic: [],
          loading: true,
        },
      };

    case Types.NATUREZA_OPERACAO_SUCCESS:
      return {
        ...state,
        naturezaOperacao: {
          loading: false,
          graphic: action.payload.graphic,
        },
      };

    case Types.CAPTURA_ACAO_JUDICIAL_REQUEST:
      return {
        ...state,
        capturaAcaoJudicial: {
          graphic: [],
          loading: true,
        },
      };

    case Types.CAPTURA_ACAO_JUDICIAL_SUCCESS:
      return {
        ...state,
        capturaAcaoJudicial: {
          loading: false,
          graphic: action.payload.graphic,
        },
      };

    case Types.CAPTURA_ACAO_JUDICIAL_PRAZO_REQUEST:
      return {
        ...state,
        capturaAcaoJudicialPrazo: {
          graphic: [],
          loading: true,
        },
      };

    case Types.CAPTURA_ACAO_JUDICIAL_PRAZO_SUCCESS:
      return {
        ...state,
        capturaAcaoJudicialPrazo: {
          loading: false,
          graphic: action.payload.graphic,
        },
      };

    default:
      return state;
  }
}

export const Creators = {
  analiticoRequest: (type, param, title, params) => ({
    type: Types.ANALITICO_REQUEST,
    payload: { type, param, title, params },
  }),
  analiticoSuccess: (data) => ({
    type: Types.ANALITICO_SUCCESS,
    payload: data,
  }),
  analiticoClose: () => ({
    type: Types.ANALITICO_CLOSE,
    payload: {},
  }),
  statusAcoesRequest: (data) => ({
    type: Types.STATUS_ACOES_REQUEST,
    payload: { filter: { data } },
  }),
  statusAcoesSuccess: (data) => ({
    type: Types.STATUS_ACOES_SUCCESS,
    payload: data,
  }),
  acoesUFRequest: (data) => ({
    type: Types.ACOES_UF_REQUEST,
    payload: { filter: { data } },
  }),
  acoesUFSuccess: (data) => ({
    type: Types.ACOES_UF_SUCCESS,
    payload: data,
  }),
  monitoriaRequest: (data) => ({
    type: Types.MONITORIA_REQUEST,
    payload: { filter: { data } },
  }),
  monitoriaSuccess: (data) => ({
    type: Types.MONITORIA_SUCCESS,
    payload: data,
  }),
  naturezaOperacaoRequest: (data) => ({
    type: Types.NATUREZA_OPERACAO_REQUEST,
    payload: { filter: { data } },
  }),
  naturezaOperacaoSuccess: (data) => ({
    type: Types.NATUREZA_OPERACAO_SUCCESS,
    payload: data,
  }),
  capturaAcaoJudicialRequest: (data) => ({
    type: Types.CAPTURA_ACAO_JUDICIAL_REQUEST,
    payload: { filter: { data } },
  }),
  capturaAcaoJudicialSuccess: (data) => ({
    type: Types.CAPTURA_ACAO_JUDICIAL_SUCCESS,
    payload: data,
  }),
  capturaAcaoJudicialPrazoRequest: (data) => ({
    type: Types.CAPTURA_ACAO_JUDICIAL_PRAZO_REQUEST,
    payload: { filter: { data } },
  }),
  capturaAcaoJudicialPrazoSuccess: (data) => ({
    type: Types.CAPTURA_ACAO_JUDICIAL_PRAZO_SUCCESS,
    payload: data,
  }),
};
