import api from 'services/api';
import { getToken } from 'services/auth';
import { call, select, put, all, takeLatest } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';

import { Creators as BancosActions, Types } from 'store/ducks/bancos';

export const getOrganization = (state) => state.usuarios.auth.organization;

export function* listBancos() {
  const organization = yield select(getOrganization);

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization }
    };

    const { data } = yield call(api.get, 'bancos', headerParams);

    yield put(BancosActions.listSuccess(data));
  } catch(err) {
    yield put(BancosActions.listError());
    toastr.error('Ops', 'Não foi possível listar os bancos')
  }
}

export default all([
  takeLatest(Types.LIST_REQUEST, listBancos)
])