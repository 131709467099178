export default function (file) {
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = async () => {
      const fileBase64 = `data:${file.type};base64,${btoa(reader.result)}`;
  
      resolve(fileBase64);
    }
  })
}