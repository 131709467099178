import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Button, TextField, TableContainer, Table, TableBody,
  TableHead, TableCell, TableRow, TableFooter,
  TablePagination, Grid, CircularProgress
} from "@material-ui/core"
import Skeleton from "@material-ui/lab/Skeleton";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Col, Row } from "react-bootstrap";
import Moment from "react-moment";
import { startOfDay, parseISO, formatISO, endOfDay } from 'date-fns';
import { CSVLink } from "react-csv";

import { useLocation } from "react-router-dom";

import StyledTableRow from "components/StyledTableRow";
import LoadingTable from "components/LoadingTable";
import ButtonSquare from 'components/ButtonSquare';

import DadosAlterados from './DadosAlterados';
import Operacao from './Operacao';
import Identificacao from './Identificacao';

import { Creators as AuditoriaActions } from "../../store/ducks/auditoria";
import { Creators as AcoesAuditoriaActions } from "store/ducks/acoesauditoria";
import { Creators as EmpresasActions } from 'store/ducks/empresas';
import { Creators as UsuariosActions } from 'store/ducks/usuarios';

import "./styles.css";
import Content from "components/Content";

const INITIAL_FORM = {
  empresa: [],
  dataInicio: '',
  dataTermino: '',
  'detalhes.acao': [],
  usuario: [],
  operacao: []
}

export default function Auditoria() {
  const btnDownloadReport = useRef();

  const { list: listAcoes, loading: loadingAcoes } = useSelector(state => state.acoesauditoria);
  const { list: listUsers, loading: loadingUsers } = useSelector(state => state.usuarios);
  const { list: listEmpresas, loading: loadingEmpresas } = useSelector(state => state.empresas);
  const { total, page = 1, limit, loading: loadingAudit, list: listAuditoria, report, loadingReport } = useSelector(
    state => state.auditoria
  );
  const { access: { empresas, servicos } } = useSelector(state => state.permissoes);

  const [form, setForm] = useState(INITIAL_FORM);
  const [params, setParams] = useState({});
  const [open, setOpen] = useState(false);
  const [detalhes, setDetalhes] = useState({});
  const [IP, setIP] = useState('');

  const { pathname } = useLocation();
  const [userActions, setUserActions] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(AcoesAuditoriaActions.listRequest());
    dispatch(UsuariosActions.listRequest({ empresas, page: 1, limit: Number.MAX_SAFE_INTEGER }));
    dispatch(EmpresasActions.listRequest());
  }, [dispatch, empresas]);

  useEffect(() => {
    if (report.length > 0) {
      btnDownloadReport.current.link.click();
      dispatch(AuditoriaActions.reportCleaner());
    }
  }, [dispatch, report]);

  useEffect(() => {
    let find = servicos.find(item => item.route === pathname);

    if (find !== undefined) { setUserActions(find) };

  }, [pathname, servicos]);

  useEffect(() => {
    const usuario = form.usuario.map(usuario => usuario._id);
    const empresa = form.empresa.map(empresa => empresa._id);
    const operacao = form.operacao.map(op => op.value);
    const acao = form['detalhes.acao'].map(acao => acao.acao);
    let data = null;

    if (form.dataInicio && form.dataTermino) {
      const dataInicio = formatISO(startOfDay(parseISO(form.dataInicio)));
      const dataTermino = formatISO(endOfDay(parseISO(form.dataTermino)));
      data = `btw(${dataInicio},${dataTermino})`
    }
    setParams({
      usuario: usuario.length > 0 ? usuario.join(',') : null,
      empresa: empresa.length > 0 ? empresa.join(',') : null,
      operacao: operacao.length > 0 ? operacao.join(',') : null,
      'detalhes.acao': acao.length > 0 ? acao.join(',') : null,
      data
    })
  }, [form]);

  function handleChange({ target }) {
    const { value, name } = target;
    setForm({ ...form, [name]: value });
  }

  function handleFilter() {
    dispatch(AuditoriaActions.listRequest({ page: 1, limit, ...params }))
  }

  function handleChangeRowsPerPage({ target }) {
    const { value } = target;
    dispatch(AuditoriaActions.listRequest({ page, limit: value, ...params }))
  }

  function handleChangePage(event, newPage) {
    dispatch(AuditoriaActions.listRequest({ limit, page: newPage + 1, ...params }))
  }

  function handleShowDetails(detalhes, ip) {
    setIP(ip);
    setDetalhes(detalhes);
    setOpen(true);
  }

  function handleExport() {
    dispatch(AuditoriaActions.reportRequest({ page: 1, ...params }))
  }

  return (
    <>
      <Content>
        <Row>
          <Col md="12" className="painel">
            <div className="painel-content">
              <div className="painel-title text-left">Auditoria</div>
              <div className="painel-body">
                <div className="auditoria-container">
                  <Row>
                    <Col md={4}>
                      {!loadingAcoes &&
                        <Autocomplete
                          clearOnBlur
                          multiple
                          id="combo-box-acoes"
                          options={listAcoes}
                          onChange={(event, newValue) => setForm({ ...form, 'detalhes.acao': newValue })}
                          getOptionLabel={option => option.titulo}
                          getOptionSelected={(option, value) =>
                            option.acao === value.acao
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Ações"
                              variant="outlined"
                            />
                          )}
                          size="small"
                          value={form['detalhes.acao']}
                        />}
                      {!!loadingAcoes && <Skeleton width="100%" height={50} />}
                    </Col>
                    <Col md={4}>
                      {!loadingUsers &&
                        <Autocomplete
                          clearOnBlur
                          multiple
                          id="combo-box-usuarios"
                          options={listUsers}
                          onChange={(event, newValue) => setForm({ ...form, usuario: newValue })}
                          getOptionLabel={option => option.nome}
                          getOptionSelected={(option, value) =>
                            option._id === value._id
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Usuarios"
                              variant="outlined"
                            />
                          )}
                          size="small"
                          value={form.usuario}
                        />}
                      {!!loadingUsers && <Skeleton width="100%" height={50} />}
                    </Col>
                    <Col md={4}>
                      {!loadingEmpresas &&
                        <Autocomplete
                          clearOnBlur
                          multiple
                          id="combo-box-empresas"
                          options={listEmpresas}
                          onChange={(event, newValue) => setForm({ ...form, empresa: newValue })}
                          getOptionLabel={option => option.fantasia}
                          getOptionSelected={(option, value) =>
                            option._id === value._id
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Empresas"
                              variant="outlined"
                            />
                          )}
                          size="small"
                          value={form.empresa}
                        />}
                      {!!loadingEmpresas && <Skeleton width="100%" height={50} />}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Autocomplete
                        clearOnBlur
                        multiple
                        id="combo-box-operacao"
                        options={[
                          { value: 'INCLUSAO', name: 'Inclusão' },
                          { value: 'ALTERACAO', name: 'Alteração' },
                          { value: 'EXCLUSAO', name: 'Exclusão' },
                        ]}
                        onChange={(event, newValue) => setForm({ ...form, operacao: newValue })}
                        getOptionLabel={option => option.name}
                        getOptionSelected={(option, value) =>
                          option.value === value.value
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Operação"
                            variant="outlined"
                          />
                        )}
                        size="small"
                        value={form.operacao}
                      />
                    </Col>
                    <Col>
                      <TextField
                        label="Data de Início"
                        name="dataInicio"
                        variant="outlined"
                        type="date"
                        size="small"
                        value={form.dataInicio}
                        InputLabelProps={{ shrink: true }}
                        onChange={handleChange}
                      />
                    </Col>
                    <Col>
                      <TextField
                        label="Data de Término"
                        name="dataTermino"
                        variant="outlined"
                        type="date"
                        size="small"
                        value={form.dataTermino}
                        InputLabelProps={{ shrink: true }}
                        onChange={handleChange}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Grid container spacing={1}>
                        <Grid item>
                          <Button
                            color="primary"
                            variant="contained"
                            onClick={handleFilter}
                          >
                            Filtrar
                          </Button>
                        </Grid>
                        <Grid item>
                          {!!loadingReport && <CircularProgress color="secondary" />}
                          {!loadingReport &&
                            <>
                              <CSVLink
                                headers={[
                                  { label: "Ação", key: "detalhes.acao" },
                                  { label: "Data", key: "data" },
                                  { label: "Operação", key: "operacao" },
                                  { label: "Usuário", key: "usuario.nome" },
                                  { label: "Email Usuário", key: "usuario.email" },
                                  { label: "Empresa", key: "empresa.fantasia" },
                                  { label: "Identificação", key: "detalhes.identificacaoDetalhada.[0].valor" },
                                ]}
                                data={report}
                                className="hidden"
                                filename={"auditoria.csv"}
                                target="_blank"
                                ref={btnDownloadReport}
                              />

                              {userActions.actions &&
                                userActions.actions.map((userAct, index) =>
                                  userAct.action === "exportar" &&

                                  <Button
                                    color="secondary"
                                    variant="contained"
                                    onClick={handleExport}
                                  >
                                    Exportar
                                  </Button>

                                )}


                            </>}
                        </Grid>
                      </Grid>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md="12" className="painel">
            <div className="painel-content">
              <div className="painel-title text-left">Auditoria</div>
              <div className="painel-body">
                <Row>
                  <Col>
                    <TableContainer>
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell>Ação</TableCell>
                            <TableCell>Identificação</TableCell>
                            <TableCell>Operação</TableCell>
                            <TableCell>Usuário</TableCell>
                            <TableCell>Empresa</TableCell>
                            <TableCell align="center">Data e Horário</TableCell>
                            <TableCell align="center">Detalhes</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {!!loadingAudit && <LoadingTable columns={7} rows={limit} />}
                          {!loadingAudit &&
                            listAuditoria.map(auditoria =>
                              <StyledTableRow key={auditoria._id}>
                                <TableCell>{auditoria.detalhes.acao}</TableCell>
                                <TableCell>
                                  {auditoria.detalhes && auditoria.detalhes.identificacaoDetalhada &&
                                    <Identificacao identificacao={auditoria.detalhes.identificacaoDetalhada} />
                                  }
                                </TableCell>
                                <TableCell>
                                  <Operacao operacao={auditoria.operacao} />
                                </TableCell>
                                <TableCell>
                                  {auditoria.usuario.nome}<br />
                                  {auditoria.usuario.email}
                                </TableCell>
                                <TableCell>{auditoria.empresa ? auditoria.empresa.fantasia : ''}</TableCell>
                                <TableCell align="center">
                                  <Moment
                                    format="DD/MM/YYYY HH:mm"
                                    date={auditoria.data}
                                  />
                                </TableCell>
                                <TableCell align="center">
                                  <ButtonSquare
                                    icon="visibility"
                                    onClick={() => handleShowDetails(auditoria.detalhes, auditoria.ip)}
                                  />
                                </TableCell>
                              </StyledTableRow>
                            )}
                        </TableBody>
                        <TableFooter>
                          <TableRow>
                            <TablePagination
                              count={total}
                              page={page - 1}
                              rowsPerPage={limit}
                              rowsPerPageOptions={[10, 25, 50, 100]}
                              labelRowsPerPage="Linhas por página"
                              labelDisplayedRows={({ from, to, count }) =>
                                `${from}-${to} de ${count !== -1 ? count : `mais que ${to}`}`
                              }
                              onRowsPerPageChange={handleChangeRowsPerPage}
                              onPageChange={handleChangePage}
                            />
                          </TableRow>
                        </TableFooter>
                      </Table>
                    </TableContainer>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </Content>
      <DadosAlterados
        open={open}
        onClose={() => setOpen(false)}
        detalhes={detalhes}
        ip={IP}
      />
    </>
  );
}
