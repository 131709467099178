import * as Yup from "yup";
import Locale from "./Locale";

Yup.setLocale(Locale);

export default Yup.object().shape({
  nome: Yup.string().required(),
  cnpj: Yup.string().required(),
  email: Yup.string().required(),
  status: Yup.string().required(),
});
