import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

function ConfirmDialog({ open, onClose, onConfirm }) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Nenhuma imagem selecionada para indexação</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Você não selecionou nenhuma imagem para indexação, 
          você poderá indexar as imagens para este documento posteriormente na tela de pesquisa. 
          Deseja continuar?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onClose} color="secondary">
          Voltar
        </Button>
        <Button variant="contained" onClick={onConfirm} color="primary" autoFocus>
          Continuar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmDialog;