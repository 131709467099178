import styled from 'styled-components/macro';

export const Container = styled.div`
  display: flex;
  flex-direction: ${(props) =>
    props.$direction ? props.$direction : 'column'};
  border: ${(props) => (props.$border ? '1px solid #eaeaea' : 'none')};
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: ${(props) => (props.$fullwidth ? '0' : '20px')};
  background-color: ${(props) =>
    props.$background ? props.$background : 'none'};
`;

export const ContainerConfig = styled.div`
  display: flex;
  flex-direction: ${(props) =>
    props.$direction ? props.$direction : 'column'};
  border: ${(props) => (props.$border ? '1px solid #eaeaea' : 'none')};
  align-items: flex-start;
  justify-content: center;
  width: 100%;

  min-height: 500px;
  padding: ${(props) => (props.$fullwidth ? '0' : '20px')};
  background-color: ${(props) =>
    props.$background ? props.$background : 'none'};
`;

export const Header = styled.div`
  margin-bottom: 15px;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 300px;
  width: 400px;
  background-color: #fff;
  padding: 20px;

  h4 {
    font-size: 14px;
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;

    h4 {
      font-size: 12px;
    }
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding: 20px 0;
`;

export const Field = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 20px;

  input {
    width: 100%;
    padding: 10px;
    border: 1px solid #eaeaea;
    border-radius: 5px;
  }
`;

export const PreviewButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;

  button {
    background: ${(props) =>
      props.$layout.background ? props.$layout.background : '#672478'};
    color: ${(props) => (props.$layout.$color ? props.$layout.color : '#fff')};
    width: 150px;

    @media only screen and (max-width: 768px) {
      margin-left: 0;
    }
  }

  @media only screen and (max-width: 768px) {
    justify-content: space-between;
  }
`;

export const TwoFactorForm = styled.form`
  width: 100%;

  h4 {
    font-size: 14px;
    margin-bottom: 10px;
  }
`;

export const TwoFactorContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const InputTwoFactor = styled.input`
  width: 4em;
  padding: 5px 7px;
  text-align: center;
  margin-bottom: 20px;
`;
