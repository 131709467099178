import React from 'react';
import { useDispatch } from 'react-redux';
import {
    DialogContent,
} from '@material-ui/core';
import { toastr } from "react-redux-toastr";

import {
    Modal,
    Button,
    GroupButton,
    DialogContentText,
    DialogTitle,
    ButtonClose,
} from './styles/ModalDeleteConfirmation';

import { Creators as TemplateActions } from "store/ducks/template";
import { CloseIcon } from 'components/Icons';

function ModalDeleteConfirmation({ open, handleClose, item }) {
    const dispatch = useDispatch();

    async function handleConfirm() {
        dispatch(TemplateActions.deleteParagraph(item.id));
        handleClose();
        return await toastr.success("Parágrafo excluido com sucesso!");
    }

    return (
        <Modal open={open}>
            <DialogTitle>Confirmar exclusão</DialogTitle>
            <DialogContent>
                <ButtonClose onClick={handleClose}>
                        <CloseIcon color="primary" fontSize="large"/>
                    </ButtonClose>
                <DialogContentText>
                    Deseja realmente excluir <span>{item ? item.title : ''}</span>?
                </DialogContentText>
                <GroupButton>
                    <Button
                        onClick={handleClose}
                        variant="contained"
                        color="secondary">
                        Cancelar
                    </Button>
                    <Button
                        onClick={handleConfirm}
                        variant="contained"
                        color="primary">
                        Confirmar
                    </Button>
                </GroupButton>
            </DialogContent>
        </Modal>
    );
}

export default ModalDeleteConfirmation;