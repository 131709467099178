import React, { useState, useEffect } from 'react';
import {
  List,
  ListItem,
  Avatar,
  Typography,
  TablePagination,
  Checkbox,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import Skeleton from '@material-ui/lab/Skeleton';
import _ from '@lodash';
import { parseISO, format } from 'date-fns';
import pt from 'date-fns/locale/pt';

import { LabelSwapper } from '../../../components/LabelSwapper';

import Mensagem from './Mensagem';

import './styles.css';

import { Creators as MensagensActions } from 'store/ducks/mensagens';
import { DraftsIcon, MailUnreadedIcon } from 'components/Icons';

function Mensagens({ endpoint }) {
  const dispatch = useDispatch();

  const { list, loading, total, page, limit } = useSelector(
    (state) => state.mensagens
  );

  const [mensagem, setMensagem] = useState(null);
  const [selecionadas, setSelecionadas] = useState([]);

  useEffect(() => {
    dispatch(MensagensActions.listInitialize());
    dispatch(
      MensagensActions.listRequest({
        page: 1,
        limit: 10,
        sort: '-createdAt',
        endpoint,
      })
    );
  }, [endpoint, dispatch]);

  useEffect(() => {
    if (mensagem && !mensagem.lida && endpoint === 1)
      dispatch(MensagensActions.markAsReadRequest(mensagem._id));
  }, [dispatch, mensagem, endpoint]);

  function getAuthorInitials(name) {
    const [firstName, middleName] = name.split(' ');

    if (firstName && middleName) {
      return `${firstName[0]}${middleName[0]}`;
    } else {
      return name[0];
    }
  }

  function handleChangeRowsPerPage({ target }) {
    dispatch(MensagensActions.listInitialize());
    const { value } = target;
    dispatch(
      MensagensActions.listRequest({
        page: 1,
        limit: value,
        sort: '-createdAt',
        endpoint,
      })
    );
  }

  function handleChangePage(e, newPage) {
    dispatch(MensagensActions.listInitialize());
    dispatch(
      MensagensActions.listRequest({
        page: newPage + 1,
        limit,
        sort: '-createdAt',
        endpoint,
      })
    );
  }

  function handleCheck({ target }) {
    if (target.checked) setSelecionadas([...selecionadas, target.id]);

    if (!target.checked)
      setSelecionadas(selecionadas.filter((_id) => _id !== target.id));
  }

  function handleCheckUncheckAll({ target }) {
    if (target.checked) setSelecionadas(list.map(({ _id }) => _id));

    if (!target.checked) setSelecionadas([]);
  }

  function markAsRead() {
    selecionadas.forEach((_id) => {
      if (list.some((msg) => msg._id === _id && !msg.lida))
        dispatch(MensagensActions.markAsReadRequest(_id));
    });
    setSelecionadas([]);
  }

  return (
    <div className="painel-content mensagens-container">
      {!mensagem && !!loading && (
        <>
          <div className="mensagens-actions">
            <Checkbox tabIndex={-1} disableRipple color="primary" disabled />
            <Tooltip title="Marcar como lida">
              <IconButton disabled>
                <DraftsIcon />
              </IconButton>
            </Tooltip>
          </div>
          <List className="mensagens-list">
            {[0, 1, 2, 3, 4, 5, 6].map((el) => (
              <ListItem dense button className="mensagens-item" key={el}>
                <Skeleton width={15} />
                <div>
                  <div className="mensagens-item-header">
                    <div className="mensagens-item-avatar">
                      <Skeleton variant="circle" width={40} height={40} />
                      <Skeleton
                        variant="text"
                        width={160}
                        style={{ marginLeft: '8px' }}
                      />
                    </div>
                    <Skeleton variant="text" width={80} />
                  </div>
                  <div className="mensagens-item-body">
                    <Skeleton variant="text" />
                    <Skeleton variant="rect" width="100%" height={50} />
                  </div>
                  <div className="mensagens-item-footer">
                    <Skeleton variant="text" width={240} />
                  </div>
                </div>
              </ListItem>
            ))}
          </List>
        </>
      )}
      {!mensagem && !loading && (
        <>
          <div className="mensagens-actions">
            <Checkbox
              tabIndex={-1}
              disableRipple
              color="primary"
              onChange={handleCheckUncheckAll}
            />
            <Tooltip title="Marcar como lida">
              <IconButton
                onClick={markAsRead}
                disabled={selecionadas.length === 0 || endpoint === 2}
              >
                <DraftsIcon />
              </IconButton>
            </Tooltip>
          </div>
          {list.length === 0 && (
            <div className="mensagens-aviso">
              <Typography variant="h3">Não há mensagens =(</Typography>
            </div>
          )}
          {list.length > 0 && (
            <List className="mensagens-list">
              {list.map((msg) => (
                <ListItem
                  dense
                  button
                  className={
                    msg.lida
                      ? 'mensagens-item'
                      : 'mensagens-item mensagens-nao-lida'
                  }
                  onClick={() => setMensagem(msg)}
                  key={msg._id}
                >
                  <Checkbox
                    id={msg._id}
                    tabIndex={-1}
                    disableRipple
                    onClick={(e) => e.stopPropagation()}
                    color="primary"
                    checked={selecionadas.some((_id) => _id === msg._id)}
                    onChange={handleCheck}
                  />
                  <div>
                    <div className="mensagens-item-header">
                      <div className="mensagens-item-avatar">
                        <Avatar>
                          {endpoint === 1
                            ? getAuthorInitials(msg.autor.nome)
                            : getAuthorInitials(msg.destinatario.nome)}
                        </Avatar>
                        <Typography variant="subtitle1">
                          {endpoint === 1
                            ? msg.autor.nome
                            : msg.destinatario.nome}
                        </Typography>
                      </div>
                      <div className="mensagens-item-header-details">
                        {!msg.lida ? <MailUnreadedIcon /> : <DraftsIcon />}
                        <Typography variant="subtitle1">
                          {format(parseISO(msg.createdAt), 'dd MMM', {
                            locale: pt,
                          })}
                        </Typography>
                      </div>
                    </div>
                    <div className="mensagens-item-body">
                      <Typography
                        className={!msg.lida ? 'mensagens-titulo-nao-lido' : ''}
                      >
                        {msg.assunto}
                      </Typography>
                      <Typography color="textSecondary">
                        {_.truncate(msg.conteudo, { length: 280 })}
                      </Typography>
                    </div>
                    <div className="mensagens-item-footer">
                      <Typography
                        className="mensagens-item-numero-processo"
                        color="textSecondary"
                      >
                        <LabelSwapper
                          text={'Nr. do cliente: '}
                          parent={'Mensagens'}
                          name={'numeroCNJ'}
                        />
                        {msg.documento.numeroCNJ}
                      </Typography>
                    </div>
                  </div>
                </ListItem>
              ))}
            </List>
          )}
          <TablePagination
            style={{
              float: 'right',
            }}
            count={total}
            page={page - 1}
            rowsPerPage={limit}
            rowsPerPageOptions={[10, 25, 50, 100]}
            labelRowsPerPage="Linhas por página"
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} de ${count !== -1 ? count : `mais que ${to}`}`
            }
            onRowsPerPageChange={handleChangeRowsPerPage}
            onPageChange={handleChangePage}
          />
        </>
      )}
      {!!mensagem && (
        <Mensagem
          onBack={() => setMensagem(null)}
          mensagem={mensagem}
          avatar={
            endpoint === 1 ? mensagem.autor.nome : mensagem.destinatario.nome
          }
        />
      )}
    </div>
  );
}

export default Mensagens;
