import React from 'react';
import { CloseIcon } from 'components/Icons';
import { Container } from './styles/ButtonCloseModal';

function ButtonCloseModal({ handleClose }) {
    return (
        <Container onClick={handleClose}>
            <CloseIcon color="primary" fontSize="large"/>
        </Container>

    )
}

export default ButtonCloseModal;