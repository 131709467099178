import React from "react";

import {
  Container,
  MenuItem,
  ListItemText,
} from "./styles/TasksActionMenu";

import List from "@material-ui/core/List";

import { useDispatch } from "react-redux";

import { Creators as ChecklistDocumentosActions } from "store/ducks/checklistdocumento";
import {Creators as DocumentosActions} from "store/ducks/documentos"

function SearchResultActionMenu({ anchor, handleClose, documentId,  tenant = '' }) {
  const dispatch = useDispatch();
  const syncChecklists = () => {
    dispatch(
      ChecklistDocumentosActions.syncChecklistdocumentoRequest({
        id: documentId,
      })
    );
    handleClose();
  };

  const downloadImagesAsZip = () => {
    dispatch(
      DocumentosActions.downloadImagesAsZipRequest({
        ids: [documentId],
      })
    );
    handleClose();
  };

  return (
    <>
      <Container
        onClose={handleClose}
        open={!!anchor}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
      }}
      transformOrigin={{
          vertical: "top",
          horizontal: "center",
      }}
        anchorEl={anchor}
      >
        <List >
          <MenuItem >
            <ListItemText  onClick={() => syncChecklists()}>Sincronizar Checklists</ListItemText>
          </MenuItem>
          <MenuItem >
            <ListItemText  onClick={() => downloadImagesAsZip()}>Baixar Arquivos</ListItemText>
          </MenuItem>
        </List>
      </Container >
    </>
  );
}

export default SearchResultActionMenu;
