import { call, put, takeEvery } from "redux-saga/effects";
import { Types, loginSuccess, loginFailure } from "../ducks/login";
import * as utils from "../../pages/Login/context/util";

function* handleLogin(action) {
    const { accessToken, idToken, code, state } = action.payload;
    try {

        const authData = yield call(utils.login, accessToken, idToken, null, state);
        yield put(loginSuccess(authData));

        // const exchangedAuthData = yield call(utils.exchangeCodeForToken, code);
        // yield put(loginSuccess(exchangedAuthData));

        //o sistema está repassando o access token para o backend e trocando pelo token do sistema
    } catch (error) {
        console.log('Login error:', error);
        yield put(loginFailure(error));
    }
}

export default function* authSaga() {
    yield takeEvery(Types.LOGIN_REQUEST, handleLogin);
}
