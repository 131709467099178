import api from "services/api";
import { getToken } from "services/auth";
import { call, select, put, all, takeLatest } from "redux-saga/effects";
import { toastr } from "react-redux-toastr";
import { push } from "connected-react-router";

import { Creators as TemplateActions, Types } from "store/ducks/template";
import { Creators as PeticionamentoActions } from '../ducks/peticionamento';

export const getOrganization = (state) => state.usuarios.auth.organization;

export function* save(action) {
    const organization = yield select(getOrganization);

    try {
        const headerParams = {
            headers: { Authorization: getToken(), xkeyorg: organization },
        };
        const { header, footer, paragraphTemplate} = action.payload.state
        const { modelName, description, modelType, idTipoDocumento, tipoImagem } = action.payload.data

        const dataToSend = {
            modelName,
            description,
            modelType,
            paragraphTemplate: paragraphTemplate,
            header,
            footer,
            idTipoDocumento,
            tipoImagem
        }

        const { data } = yield call(api.post, `template/`, dataToSend, headerParams);
        
        yield put(TemplateActions.requestSuccess(data));
        yield put(push('/template'))
        
        toastr.success("Template salvo com sucesso");
    } catch (err) {
        console.error(err);
        toastr.error("Ops", "Não foi possível salvar o template");
    }
}

export function* deleteTemplate(action) {
  
    try {
      const organization = yield select(getOrganization);
  
      const headerParams = {
        headers: { Authorization: getToken(), xkeyorg: organization },
      };
  
      const response = yield call(
        api.delete,
        `template/${action.payload}`,
        headerParams
      );
  
      if (response?.data?.error)
        throw new Error(
          response?.data?.message || "Não foi possível deletar o template."
        );
  
      yield put(TemplateActions.deleteSuccess());
      yield put(TemplateActions.getTemplate({}));
      yield put(TemplateActions.selectTemplateToEdit(null));
  
      toastr.success("Template removido com sucesso.");
    } catch (error) {
      toastr.error(error.message);
  
      yield put(TemplateActions.deleteFailure());
    }
  }

export function* getTemplates(action) {
    const organization = yield select(getOrganization);

    yield put(PeticionamentoActions.selectTemplateLoading(true));

    try {
        const headerParams = {
            headers: { Authorization: getToken(), xkeyorg: organization },
        };

        const { data } = yield call(api.get, `template/`, headerParams);


        yield put(PeticionamentoActions.getTemplatesSuccess(data));
        yield put(TemplateActions.getTemplateSuccess(data));

    } catch (err) {
        console.error(err);
        toastr.error("Ops", "Não foi possível acessar a lista de templates");
    }
}


export function* updateTemplate(action) {
    const organization = yield select(getOrganization);


    try {
        const headerParams = {
            headers: { Authorization: getToken(), xkeyorg: organization },
        };

        const { header, footer, paragraphTemplate } = action.payload.state
        const { modelName, description, modelType, idTipoDocumento, tipoImagem } = action.payload.data
        const {id} = action.payload
        const dataToSend = {
            modelName,
            description,
            modelType,
            tipoImagem,
            idTipoDocumento,
            paragraphTemplate: paragraphTemplate,
            header: header.currentContent,
            footer: footer.currentContent
        }
        const { data } = yield call(api.put, `template/${id}`, dataToSend, headerParams);
        yield put(TemplateActions.requestSuccess(data));
        toastr.success("Template editado com sucesso");
    } catch (err) {
        console.error(err);
        toastr.error("Ops", "Não foi possível acessar a lista de templates");
    }
}

export default all([
    takeLatest(Types.GET_TEMPLATE, getTemplates),
    takeLatest(Types.SAVE_TEMPLATE, save),
    takeLatest(Types.EDIT_TEMPLATE_REQUEST, updateTemplate),
    takeLatest(Types.DELETE_REQUEST, deleteTemplate),
  ]);
