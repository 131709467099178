import styled from 'styled-components/macro';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 80vh;
`;

export const ControlPanel = styled.div`
    width: 100%;
`;

export const ButtonRow = styled.div`
    display: flex;
    margin: 8px 48px;
    justify-content: space-between;
    align-items: center;
`;

export const Actions = styled.div`

`;

export const ImageName = styled.div`
    height: 36px;
    display: flex;
    align-items: center;
    font-size: 12px;    
    color: #555;

    span {
        color: #777;
        font-size: 14px;
    }
`;

export const ViewerContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e5e5e5;
    flex-grow: 1;
    height: 100%;
    min-height: 100%;
    margin: 4px 48px;
    position: relative;
    border: 2px solid #86ccff;
    border-radius: 10px;
    box-shadow: 2px 2px 2px 2px #00000011;
`;

