import React, { useMemo, useEffect } from "react";

import { Link } from "react-router-dom";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TableFooter,
  TablePagination,
} from "@material-ui/core";
import { Creators as LayoutActions } from "store/ducks/layout";
import { useSelector, useDispatch } from "react-redux";

import StyledTableRow from "components/StyledTableRow";
import ButtonSquare from "components/ButtonSquare";
import LoadingTable from "components/LoadingTable";
import ToolTipComponent from "components/Tooltip";

function TableLayout({ userActions }) {
  const dispatch = useDispatch();

  const { loading, layout } = useSelector((state) => state.layout);
  const { total, page, limit, sort, search } = layout;

  const hasActions = useMemo(
    () =>
      true ||
      (userActions.actions &&
        userActions.actions.some(
          (userAct) => userAct.action === "editar" || userAct.action === "criar"
        )),
    [userActions]
  );

  function handleChangeRowsPerPage({ target }) {
    const { value } = target;
    dispatch(
      LayoutActions.listRequest({
        page,
        limit: value,
        sort,
        search,
      })
    );
  }

  function handleChangePage(e, newPage) {
    dispatch(
      LayoutActions.listRequest({
        page: newPage + 1,
        limit,
        sort,
        search,
      })
    );
  }

  useEffect(() => {
    dispatch(LayoutActions.listRequest({}));
  }, [dispatch]);

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Nome</TableCell>
            <TableCell>Tipo</TableCell>
            {userActions.actions &&
              userActions.actions.map(
                (userAct, index) =>
                  userAct.action === "editar" && (
                    <TableCell key={index} align="center">
                      Ações
                    </TableCell>
                  )
              )}
          </TableRow>
        </TableHead>
        <TableBody>
          {!!loading && <LoadingTable columns={hasActions ? 4 : 2} />}
          {!loading &&
            layout?.docs?.map((layout) => (
              <StyledTableRow key={layout._id}>
                <TableCell>{layout.nome}</TableCell>
                <TableCell>{layout.type}</TableCell>
                {userActions.actions &&
                  userActions.actions.map(
                    (userAct, index) =>
                      userAct.action === "editar" && (
                        <TableCell align="center" key={index}>
                          <div className="flex-direction">
                            <ToolTipComponent title="Editar" placement="top">
                              <Link
                                to={{
                                  pathname: "/layout/edit",
                                  state: { layout: layout },
                                }}
                                className="linkicon"
                                id="departamentos"
                              >
                                <ButtonSquare icon="edit" />
                              </Link>
                            </ToolTipComponent>
                            <ToolTipComponent title="Remover" placement="top">
                              <div>
                                <ButtonSquare
                                  disabled
                                  icon="delete"
                                  style={{ marginLeft: "1rem" }}
                                />
                              </div>
                            </ToolTipComponent>
                          </div>
                        </TableCell>
                      )
                  )}
              </StyledTableRow>
            ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              count={total || 0}
              page={page - 1}
              rowsPerPage={limit || 10}
              rowsPerPageOptions={[10, 25, 50, 100]}
              labelRowsPerPage="Linhas por página"
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} de ${count !== -1 ? count : `mais que ${to}`}`
              }
              onRowsPerPageChange={handleChangeRowsPerPage}
              onPageChange={handleChangePage}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}

export default TableLayout;
