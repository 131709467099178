export const Types = {
  NEW_REQUEST: "exportacao/NEW_REQUEST",
  NEW_SUCCESS: "exportacao/NEW_SUCCESS",
};

/* Reducer */

const INITIAL_STATE = {
  loading: false,
  data: null
};

export default function items(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.NEW_REQUEST:
      return { ...state, loading: true };

    case Types.NEW_SUCCESS:
      return { ...state, loading: false, data: action?.payload };

    default:
      return state;
  }
}

export const Creators = {
  createRequest: (exportacao) => ({
    type: Types.NEW_REQUEST,
    payload: exportacao,
  }),

  createSuccess: (exportacao) => ({
    type: Types.NEW_SUCCESS,
    payload: exportacao,
  }),
};
