import { call, put } from "redux-saga/effects";

import { toastr } from "react-redux-toastr";
import api from "../../services/api";
import { getToken } from "../../services/auth";

import { Creators as MenusActions } from "../ducks/menus";
import { select } from "redux-saga/effects";
import { push } from "connected-react-router";

export const getOrganization = (state) => state.usuarios.auth.organization;
export const getUserId = (state) => state.usuarios.auth.id;

export function* listMenus({ payload }) {
  try {
    const organization = yield select(getOrganization);
    const { page, limit, sort } = payload;

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
      params: { page, limit, sort },
    };

    const response = yield call(api.get, `menu`, headerParams);

    const data = {
      total: response.data.total,
      list: response.data.docs,
    };
    yield put(MenusActions.listSuccess(data));
  } catch (error) {
    toastr.error("Ops", "Não foi possível listar os menus.");
  }
}

export function* createMenu(action) {
  try {
    const organization = yield select(getOrganization);

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const { data } = yield call(
      api.post,
      `/menu`,
      action.payload,
      headerParams
    );

    yield put(push(`/configuracao/menu`));

    yield put(MenusActions.createSuccess(data));

    toastr.success("", "Menu criado com sucesso.");
  } catch (error) {
    toastr.error("Ops", "Não foi possível criar o menu.");
  }
}

export function* removeMenu(action) {
  try {
    const organization = yield select(getOrganization);

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const { data } = yield call(
      api.delete,
      `/menu/${action.payload}`,
      headerParams
    );

    toastr.success("Menu removido com sucesso!");

    yield put(MenusActions.listRequest({ page: 1, limit: 10 }));
  } catch (error) {
    toastr.error("Ops,", " Não foi possível remover o menu!");

    yield put(MenusActions.listRequest({ page: 1, limit: 10 }));
  }
}

export function* editMenu(action) {
  const userId = yield select(getUserId);

  try {
    const organization = yield select(getOrganization);

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };
    const body = { ...action.payload, _id_user_acao: userId };

    const { data } = yield call(
      api.put,
      `/menu/${action.payload._id}`,
      body,
      headerParams
    );

    yield put(MenusActions.editSuccess(data));

    yield put(push(`/configuracao/menu`));

    toastr.success("Menu editado com sucesso!");
  } catch (error) {
    toastr.error("Ops,", " Não foi possível editar o menu!");
  }
}
