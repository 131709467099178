import React from "react";

import ButtonMui from "@material-ui/core/Button";
import { Grid } from "@material-ui/core";
import { Form } from "@unform/web";

import { Row, Col } from "react-bootstrap";

import BackButton from "../../components/BackButton";
import Content from "components/Content";

export default function FormularioCadastro({
  title,
  children,
  formRef,
  onSubmit,
  canSave,
  initialData = {},
}) {
  return (
    <Content>
      <Row>
        <Col md="12" className="painel">
          <div className="painel-content">
            <div className="painel-title text-left">{title}</div>
            <div className="painel-body">
              <Form
                ref={formRef}
                onSubmit={onSubmit}
                initialData={initialData}
                className={"mt-2"}
              >
                {children}
                <Grid container spacing={1} className="mt-2 mb-2">
                  <Grid item>
                    <ButtonMui
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={!canSave}
                    >
                      Salvar
                    </ButtonMui>
                  </Grid>
                  <Grid item>
                    <BackButton variant="contained" color="secondary">
                      Voltar
                    </BackButton>
                  </Grid>
                </Grid>
              </Form>
            </div>
          </div>
        </Col>
      </Row>
    </Content>
  );
}
