import * as Yup from 'yup';
import Locale from './Locale'

Yup.setLocale(Locale);

export default Yup.object().shape({
  grupoEmpresarial: Yup.string().required(),
  razaoSocial: Yup.string().required(),
  tipoPessoa: Yup.string().required(),
  fantasia: Yup.string().required(),
  status: Yup.string().required(),
  tipoPagamento: Yup.string().required(),
  emailFatura: Yup.string().email('Informe um e-mail válido').required()
});