import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    display: 'grid',
    gridTemplateColumns: '50% 50%',
    minWidth: '100%'
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
  }
});

function OptionAutocomplete({ option }) {
  const classes = useStyles();
  const keys = Object.keys(option);
  return (
    <div className={classes.root}>
      {keys.map(key => 
        <div className={classes.item} key={key}>
          <Typography variant="caption" color="textPrimary">{key}</Typography>
          <Typography variant="subtitle2" color="textSecondary">{option[key]}</Typography>
        </div>
      )}
    </div>
    
  );
}

export default OptionAutocomplete;