export const Types = {
    GET_DATA_REQUEST: '@kanban/GET_DATA_REQUEST',
    GET_DATA_SUCCESS: '@kanban/GET_DATA_SUCCESS',
    SET_FILTERED_TASKS: '@kanban/SET_FILTERED_TASKS',
    SET_ACTIVE_BOARD: '@kanban/SET_FILTERED_TASKS',
    CREATE_BOARD_REQUEST: '@kanban/CREATE_BOARD_REQUEST',
    CREATE_BOARD_SUCCESS: '@kanban/CREATE_BOARD_SUCCESS',
    SELECT_BOARD: '@kanban/SELECT_BOARD',
    UPDATE_KANBAN_REQUEST: '@kanban/UPDATE_KANBAN_REQUEST',
    UPDATE_BOARD_LIST: '@kanban/UPDATE_BOARD_LIST',
    UPDATE_ACTIVE_BOARD_COLUMN: '@kanban/UPDATE_ACTIVE_BOARD_COLUMN',
    STORE_COLUMN_TO_RECEIVE_NEW_TASK: '@kanban/STORE_COLUMN_TO_RECEIVE_NEW_TASK',
    ADD_NEW_TASK: '@kanban/ADD_NEW_TASK',
    ADD_PARTICIPANTS_SUCCESS: "@kanban/ADD_PARTICIPANTS_SUCCESS",
    CLEAR_STATE_ON_UNMOUNT: "@kanban/CLEAR_STATE_ON_UNMOUNT",
}
  
const INITIAL_STATE = {
    tasks: [],
    loading: false,
    filteredTasks: [],
    activeBoard: null,
    boardList: [],
    columnToReceiveNewTask: null,
}
  
export default function kanban(state = INITIAL_STATE, action) {
    switch (action.type) {
        case Types.GET_DATA_REQUEST:
            return {
                ...state,
                loading: true,
            }

        case Types.GET_DATA_SUCCESS:
            return {
                ...state,
                tasks: action.payload.tasks,
                boardList: action.payload.kanbanBoards,
                activeBoard: action.payload.kanbanBoards ? action.payload.kanbanBoards[0] : null,
                loading: false,
            }

        case Types.SET_FILTERED_TASKS:
            return {
                ...state,
                filteredTasks: action.payload,
            }

        case Types.CREATE_BOARD_REQUEST:
            return {
                ...state,
                loading: true,
            }

        case Types.CREATE_BOARD_SUCCESS:
            return {
                ...state,
                loading: false,
                boardList: [ ...state.boardList, action.payload ],
                activeBoard: state.activeBoard ? state.activeBoard : action.payload,
            } 

        case Types.SELECT_BOARD:
            return {
                ...state,
                activeBoard: state.boardList.find(board => board._id === action.payload._id),
            }    

        case Types.UPDATE_ACTIVE_BOARD_COLUMN:

            const updatedActiveBoard = {
                ...state.activeBoard,
                columns: state.activeBoard.columns.map(column => 
                    column._id !== action.payload.columnId 
                    ? column
                    : ({ ...column, taskIds: [ action.payload.taskId, ...column.taskIds ] })
                ),
            }

            return {
                ...state,
                activeBoard: updatedActiveBoard,
                boardList: state.boardList.map(board => 
                    board._id !== updatedActiveBoard._id
                    ? board
                    : updatedActiveBoard
                ),
            }

        case Types.STORE_COLUMN_TO_RECEIVE_NEW_TASK:
            return {
                ...state,
                columnToReceiveNewTask: action.payload,
            }

        case Types.UPDATE_BOARD_LIST:
            return {
                ...state,
                boardList: state.boardList.map(board =>
                    board._id !== action.payload._id
                    ? board
                    : action.payload
                ),
            }

        case Types.ADD_NEW_TASK:
            return {
                ...state,
                tasks: [ action.payload, ...state.tasks ],
            }

        case Types.ADD_PARTICIPANTS_SUCCESS:
            return {
                ...state,
                tasks: state.tasks.map(task =>
                    task._id !== action.payload._id
                    ? task
                    : action.payload
                ),
            }

        case Types.CLEAR_STATE_ON_UNMOUNT:
            return INITIAL_STATE;

        default:
            return state;
    }
}
  
export const Creators = {
    fetchDataRequest: () => ({
        type: Types.GET_DATA_REQUEST,
    }),
    fetchDataSuccess: data => ({
        type: Types.GET_DATA_SUCCESS,
        payload: data,
    }),
    setFilters: filteredTasks => ({
        type: Types.SET_FILTERED_TASKS,
        payload: filteredTasks,
    }),
    createBoardRequest: boardData => ({
        type: Types.CREATE_BOARD_REQUEST,
        payload: boardData,
    }),
    createBoardSuccess: newBoard => ({
        type: Types.CREATE_BOARD_SUCCESS,
        payload: newBoard,
    }),
    selectBoard: selectedBoard => ({
        type: Types.SELECT_BOARD,
        payload: selectedBoard,
    }),
    updateKanbanRequest: updatedKanban => ({
        type: Types.UPDATE_KANBAN_REQUEST,
        payload: updatedKanban,
    }),
    updateBoardList: updatedKanban => ({
        type: Types.UPDATE_BOARD_LIST,
        payload: updatedKanban,
    }),
    updateActiveBoardColumn: (columnId, taskId) => ({
        type: Types.UPDATE_ACTIVE_BOARD_COLUMN,
        payload: { columnId, taskId }
    }),
    storeColumnToReceiveNewTask: columnId => ({
        type: Types.STORE_COLUMN_TO_RECEIVE_NEW_TASK,
        payload: columnId,
    }),
    addNewTask: newTask => ({
        type: Types.ADD_NEW_TASK,
        payload: newTask,
    }),
    addParticipantsSuccess: updatedTask => ({
        type: Types.ADD_PARTICIPANTS_SUCCESS,
        payload: updatedTask,
    }),
    clearStateOnUnmount: () => ({
        type: Types.CLEAR_STATE_ON_UNMOUNT,
    }),
}
