import React, { useState, useEffect, useMemo } from "react";

import { useDispatch, useSelector } from "react-redux";
import { CheckCircleIcon, CancelCircleIcon, InfoIcon } from "components/Icons";
import { Creators as CheckListDocumentoActions } from "store/ducks/checklistdocumento";
import { toastr } from "react-redux-toastr";
import { BeatLoader } from "react-spinners";
import { Col, Row } from "react-bootstrap";
import { Grid } from "@material-ui/core";

import ButtonSquare from "components/ButtonSquare";
import InputAdornment from "@material-ui/core/InputAdornment";
import ToolTipComponent from "components/Tooltip";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Icon from "@material-ui/core/Icon";
import Radio from "@material-ui/core/Radio";
import AppBar from "@material-ui/core/AppBar";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import MenuItem from "@material-ui/core/MenuItem";
import TableContainer from "@material-ui/core/TableContainer";
import TableEditable from "../../components/TableEditable";
import TextField from "@material-ui/core/TextField";
import ModalUploadFile from "./ModalCheckItemView/index";
import DialogPreviewImages from "components/DialogPreviewImages";
import ActionMenu from './ActionMenu';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { MoreIcon } from "components/Icons";
import { makeStyles } from "@material-ui/core/styles";




import "./styles.css";

const styleTabs = {
  boxShadow: "none",
  border: "1px solid rgb(222, 226, 230)",
  borderRadius: "8px",
};

const isDisabled = (item) => {
  return item.tipoItem === "Quantitativo" || item.agrupamento;
};

const isAgrupamento = (item) => {
  return item.agrupamento || item.nodes?.length > 0;
};

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
    fontSize: 12,
  },
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
}


export default function ChecklistForm({ selectedDoc }) {
  const dispatch = useDispatch();

  const [documentId, setDocumentId] = useState(selectedDoc._id);
  const [openPreviewImage, setOpenPreviewImage] = useState(false);
  const [imagensPreview, setImagensPreview] = useState([]);
  const [filterStatusChecklist, setFilterStatusChecklist] = useState("");
  const [tituloActive, setTituloActive] = useState(null);
  const [itensModify, setItensModify] = useState([]);
  const [filterTipo, setFilterTipo] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [search, setSearch] = useState("");
  const [actionMenuAnchor, setActionMenuAnchor] = useState(false);

  const { loading, data } = useSelector((state) => state.checklistdocumento);

  const [itemUpload, setItemUpload] = useState(null);

  const titulos = useMemo(() => {
    if (data) {
      return data.map((item) => ({
        descricaoTitulo: item.descricaoTitulo,
        hashTitulo: item.hashTitulo,
        ordemTitulo: item.ordemTitulo,
      }));
    }
    return [];
  }, [data]);

  useEffect(() => {
    if (titulos.length > 0 && !tituloActive) {
      setTituloActive(titulos[0]);
    }
  }, [titulos, tituloActive]);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    setDocumentId(selectedDoc._id);
  }, [selectedDoc]);

  const handleShowAnexo = (tipoImagem) => {
    tipoImagem = Array.isArray(tipoImagem) ? tipoImagem : [tipoImagem];

    let images = [];
    for (const tipo of tipoImagem) {
      const image = selectedDoc.imagens.find(
        (imagem) => imagem.tipoImagem._id === tipo._id
      );

      images.push(image);
    }

    setImagensPreview(images);
    setOpenPreviewImage(images.length > 0);
  };

  const handleShowAnexoRecorrencia = (filePath) => {
    const image = selectedDoc.imagens.find(
      (imagem) => imagem.filepath === filePath
    );

    if (!image) return;

    setImagensPreview([image]);
    setOpenPreviewImage(true);
  };

  const onUpload = (item) => {
    setOpen(true);
    setItemUpload(item);
  };


  function handleCloseActionMenu() {
    setActionMenuAnchor(null);
  }


  const onChangeTab = (active) => {
    if (itensModify.length === 0) return setTituloActive(active);
    toastr.warning(
      "É necessário salvar as alterações antes de prosseguir com a mudança de título"
    );
  };

  const COLUMNS_GRID_EDITABLE = [
    {
      label: "Item checklist",
      renderCell: (item) => (
        <div className="itemChecklistName">
          <span>
            {item.nodes && item.nodes.length > 0 && <>📂</>}
            {item.parent && item.nodes?.length === 0 && <>🗒️</>}
            <>{item.nome}</>
            {item.obrigatorio && <span style={{ color: "red" }}>*</span>}
          </span>
          {item.instrucoes && (
            <ToolTipComponent title={item.instrucoes} placement="top">
              <div>
                <InfoIcon />
              </div>
            </ToolTipComponent>
          )}
        </div>
      ),
    },
    {
      label: "Status",
      renderCell: (item) =>
        !isAgrupamento(item) && (
          <>
            {item.pendenteChecklist ? (
              <ToolTipComponent
                title="Imagem ainda não inserida"
                placement="top"
              >
                <span>
                  <CancelCircleIcon color="red" />
                </span>
              </ToolTipComponent>
            ) : (
              <ToolTipComponent title="Imagem encontrada" placement="top">
                <span>
                  <CheckCircleIcon color="green" />
                </span>
              </ToolTipComponent>
            )}
          </>
        ),
    },
    {
      label: "Motivos",
      renderCell: (item) => {
        return (
          <div className="formalizacao-motivos">
            {item.motivo &&
              item.motivo.map((motivo, index) => (
                <span key={index} className="badge bg-secondary">
                  {motivo}
                </span>
              ))}
          </div>
        );
      },
    },
    {
      label: "Observações",
      renderCell: (item) => item.observacao,
    },
    {
      label: "Ações",
      renderCell: (item) => (
        <div className="formalizacao-actions">
          {!item.recorrencia &&
            !item.parent &&
            !item.pendenteChecklist &&
            item.tipoImagem &&
            item.hasUpload && (
              <ButtonSquare
                icon="visibility"
                onClick={() => handleShowAnexo(item.tipoImagem)}
              />
            )}

          {item.parent && item.idImagem && item.filePath && (
            <ButtonSquare
              icon="visibility"
              onClick={() => handleShowAnexoRecorrencia(item.filePath)}
            />
          )}

          {!item.recorrencia && !item.agrupamento && (
            <ButtonSquare icon="upload_cloud" onClick={() => onUpload(item)} />
          )}
        </div>
      ),
      align: "right",
    },
  ];

  const handleChangeTipo = ({ target }) => {
    const { value } = target;
    setFilterTipo(value);
  };

  function handleOpenActionMenu(e) {
    setActionMenuAnchor(e.currentTarget);
  }

  const handleChangeStatus = ({ target }) => {
    const { value } = target;
    setFilterStatusChecklist(value);
  };

  const handleChangeSearch = ({ target }) => {
    const { value } = target;
    setSearch(value);
  };

  useEffect(() => {
    if (!open)
      makeRequest(
        dispatch,
        documentId,
        filterStatusChecklist,
        filterTipo,
        search
      );
  }, [dispatch, open, documentId, filterStatusChecklist, filterTipo, search]);

  useEffect(() => {
    setItensModify([]);
  }, [dispatch, loading]);

  const makeRequest = (
    dispatch,
    documentId,
    filterStatusChecklist,
    filterTipo,
    search
  ) => {
    dispatch(
      CheckListDocumentoActions.getChecklistdocumentoRequest({
        documentId,
        filterStatusChecklist,
        filterTipo,
        search,
      })
    );
  };

  return (
    <>
      <Row>
        <Col md={12}>
          <Grid container alignContent="center" justify="center">
            {!!loading && (
              <BeatLoader
                size={10}
                sizeUnit={"px"}
                color={"#444444"}
                loading={loading}
              />
            )}
          </Grid>
        </Col>
      </Row>
      {!loading && (
        <>
          <Row>
            <Col md="12">
              <div
                className="checklist-header"
                style={{ borderBottom: "1px solid #dee2e6" }}
              >
                <div className="checklist-header-search">
                  <TextField
                    label="Pesquisar"
                    variant="outlined"
                    size="small"
                    onBlur={handleChangeSearch}
                    defaultValue={search}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Icon className="text-20" color="action">
                            search
                          </Icon>
                        </InputAdornment>
                      ),
                    }}
                  />

                  <TextField
                    size="small"
                    label="Tipo de item"
                    id="select"
                    variant="outlined"
                    value={filterTipo}
                    onChange={handleChangeTipo}
                    select
                  >
                    <MenuItem value={""}>Nenhum</MenuItem>
                    <MenuItem value={"Quantitativo"}>Quantitativo</MenuItem>
                    <MenuItem value={"Qualitativo"}>Qualitativo</MenuItem>
                  </TextField>

                  <FormControl component="fieldset">
                    <RadioGroup
                      row
                      aria-label="filter"
                      name="filter"
                      value={filterStatusChecklist}
                      onChange={handleChangeStatus}
                    >
                      <FormControlLabel
                        value={""}
                        control={<Radio color="primary" />}
                        label="Todos"
                      />
                      <FormControlLabel
                        value={"pendente"}
                        control={<Radio color="primary" />}
                        label="Pendente"
                      />
                      <FormControlLabel
                        value={"concluido"}
                        control={<Radio color="primary" />}
                        label="Concluídos"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>

                <div className="checklist-header-radios">

                </div>
                <div className="checklist-header-actions">
                  {!!ActionMenu && (
                    <BootstrapTooltip
                      title={"Opções"}
                      placement="top"
                      onClick={(e) => handleOpenActionMenu(e)}
                    >
                      <IconButton
                        size="small"
                        color="primary"
                        aria-label={"Opções"}
                        component="span"
                      >
                        <MoreIcon />
                      </IconButton>
                    </BootstrapTooltip>
                  )}
                  {actionMenuAnchor && (
                    <ActionMenu
                      anchor={actionMenuAnchor}
                      handleClose={handleCloseActionMenu}
                      documentId={documentId}
                    />
                  )}
                </div>
              </div>

              {/* <Col md="12">
                <div
                  className="checklist-header"
                  style={{ borderBottom: "1px solid #dee2e6" }}
                >
                  <div className="controlpanel-item comments-header-spacing">
                    <div className="controlpanel-item">
                      <TextField
                        label="Pesquisar"
                        variant="outlined"
                        size="small"
                        onBlur={handleChangeSearch}
                        defaultValue={search}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Icon className="text-20" color="action">
                                search
                              </Icon>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                  </div>

                  <div className="controlpanel-item comments-header-spacing">
                    <div className="controlpanel-item justify-content-center">
                      <FormControl component="fieldset">
                        <RadioGroup
                          row
                          aria-label="filter"
                          name="filter"
                          value={filterStatusChecklist}
                          onChange={handleChangeStatus}
                        >
                          <FormControlLabel
                            value={""}
                            control={<Radio color="primary" />}
                            label="Todos"
                          />
                          <FormControlLabel
                            value={"pendente"}
                            control={<Radio color="primary" />}
                            label="Pendente"
                          />
                          <FormControlLabel
                            value={"concluido"}
                            control={<Radio color="primary" />}
                            label="Concluídos"
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </div>

                  <div className="controlpanel-item comments-header-spacing">
                    <div className="controlpanel-item">
                      <TextField
                        size="small"
                        label="Tipo de item"
                        id="select"
                        variant="outlined"
                        value={filterTipo}
                        onChange={handleChangeTipo}
                        select
                      >
                        <MenuItem value={""}>Nenhum</MenuItem>
                        <MenuItem value={"Quantitativo"}>Quantitativo</MenuItem>
                        <MenuItem value={"Qualitativo"}>Qualitativo</MenuItem>
                      </TextField>
                    </div>
                  </div>

                  <div className="controlpanel-item comments-header-spacing" style={{ borderBottom: 'none' }}>
                    <div className="controlpanel-item">
                      <div>
                        {!!ActionMenu && (
                          <BootstrapTooltip
                            title={"Opções"}
                            placement="top"
                            onClick={(e) => handleOpenActionMenu(e)}
                          >
                            <IconButton
                              size="small"
                              color="primary"
                              aria-label={"Opções"}
                              component="span"
                            >
                              <MoreIcon />
                            </IconButton>
                          </BootstrapTooltip>
                        )}
                        {actionMenuAnchor && (
                          <ActionMenu
                            anchor={actionMenuAnchor}
                            handleClose={handleCloseActionMenu}
                            documentId={documentId}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Col> */}
            </Col>
          </Row>
        </>
      )}

      {!loading && tituloActive && data?.length > 0 && (
        <Row className="m-4">
          <Col className="ml-4 mr-4 shadow-tab">
            <AppBar position="absolute" className="mt-2" style={styleTabs}>
              <Tabs
                aria-label="simple tabs example"
                value={tituloActive.hashTitulo}
                variant="scrollable"
                scrollButtons="on"
              >
                {
                  //ordenar os titulos em ordem alfabetica
                  titulos
                    .sort((a, b) => a.descricaoTitulo - b.descricaoTitulo)
                    .map((titulo) => (
                      <Tab
                        value={titulo.hashTitulo}
                        label={titulo.descricaoTitulo}
                        onClick={() => onChangeTab(titulo)}
                      />
                    ))}
              </Tabs>
            </AppBar>
            <Row className="mt-3">
              {!loading &&
                data
                  ?.filter(
                    (x) => x.descricaoTitulo === tituloActive.descricaoTitulo
                  )
                  ?.map((titulo, index) => {
                    return { ...titulo, itens: titulo.itens.sort((a, b) => a.nome.localeCompare(b.nome)) }
                  })
                  ?.map((titulo, index) => (
                    <Col key={index} md={12}>
                      <TableContainer className="mb-3">
                        <TableEditable
                          columns={COLUMNS_GRID_EDITABLE}
                          nodes={titulo.itens}
                        />
                      </TableContainer>
                    </Col>
                  ))}
            </Row>
          </Col>
        </Row>
      )}

      <DialogPreviewImages
        canRemove={false}
        loadingRemove={false}
        open={openPreviewImage}
        imagens={imagensPreview}
        onClose={() => setOpenPreviewImage(false)}
      />

      <ModalUploadFile
        documentId={documentId}
        open={open}
        item={itemUpload}
        setOpen={setOpen}
      />
    </>
  );
}
