import styled from "styled-components/macro";

import {
  Accordion as MaterialAccordion,
  AccordionDetails as MaterialAccordionDetails,
} from "@material-ui/core";

export const Container = styled(MaterialAccordion)`
  background-color: #7f7f7f;
  color: #fff;
  box-shadow: 0px 2px 4px #00000027;
  margin-top: 20px;

  .MuiCollapse-container {
    box-shadow: 0px 2px 4px #00000033;
  }
`;

export const AccordionDetails = styled(MaterialAccordionDetails)`
  background-color: #fff;
  border-radius: 0 0 2px 2px;
  margin: 4px;
`;
