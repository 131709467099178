import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';

import { Creators as AssistantActions } from '../../../../store/ducks/assistant';

import './styles.css';

function Assistente({ selectedDoc }) {
  const [textMessage, setTextMessage] = useState('');
  const [messages, setMessages] = useState([]);

  const dispatch = useDispatch();
  const { loading, list } = useSelector((state) => state.assistant);

  useEffect(() => {
    dispatch(
      AssistantActions.listRequest({
        threadId: selectedDoc.threadId,
        documentId: selectedDoc._id,
      })
    );
  }, []);

  useEffect(() => {
    setMessages(list.reverse());
  }, [list]);

  useEffect(() => {
    var element = document.getElementById('messages');
    element.scrollTop = element.scrollHeight;
  }, [messages]);

  function submitMessage(e) {
    e.preventDefault();

    setMessages([
      ...messages,
      {
        role: 'user',
        content: [{ text: { value: textMessage } }],
      },
      {
        role: 'assistant',
        content: [{ text: { value: <BeatLoader color="#ffffff" /> } }],
      },
    ]);

    dispatch(
      AssistantActions.createRequest({
        content: textMessage,
        threadId: selectedDoc.threadId,
        documentId: selectedDoc._id,
      })
    );

    setTextMessage('');
  }

  return (
    <div className=" bg-white">
      <div class="assistant-container">
        <div class="messages" id="messages">
          {loading && <div>Carregando...</div>}
          {!loading && messages.length === 0 && (
            <div class="message m-assistant">
              <span className="message-title">Assistente</span>
              <div>
                Estou aqui para ajuda-lo! Digite a pergunta que responderei com
                prazer.
              </div>
            </div>
          )}
          {!loading &&
            messages.length > 0 &&
            messages.map((message) => (
              <div class={`message m-${message.role}`}>
                <span className="message-title">
                  {message.role === 'user' ? 'Usuário' : 'Assistente'}
                </span>
                <div>{message.content[0].text.value}</div>
              </div>
            ))}
        </div>
        <div className="form-message">
          <form onSubmit={submitMessage}>
            <input
              type="text"
              placeholder="Digite sua mensagem"
              value={textMessage}
              onChange={(e) => setTextMessage(e.target.value)}
            />
            <button disabled={loading} className={loading && 'disabled'}>
              {loading ? 'Aguarde' : 'Enviar'}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Assistente;
