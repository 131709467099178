import React, { useEffect, useState } from 'react';

import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

export default function RichTextEditor({ onEditorStateChange, value }) {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  //se o value tiver conteudo, converte para o formato do editor
  useEffect(() => {
    if (value) {
      const contentBlock = htmlToDraft(value);
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);
      setEditorState(editorState);
    }
  }, []);

  const styles = {
    height: '300px',
    width: '100%',
    backgroundColor: '#e5ebff',
    marginBottom: '1rem',
    borderRadius: 5,
    paddingLeft: 10,
  };

  function onChange(editorState) {
    setEditorState(editorState);

    const formattedText = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );

    onEditorStateChange(formattedText);
  }

  return (
    <Editor
      editorStyle={styles}
      sx={{ borderRadius: 1, minHeight: '450px' }}
      wrapperClassName="wrapper"
      editorClassName="editor-class"
      toolbarClassName="toolbar-class"
      toolbar={{
        options: [
          'inline',
          'blockType',
          'fontSize',
          'fontFamily',
          'image',
          'list',
          'textAlign',
          'history',
        ],
        inline: {
          options: ['bold', 'italic'],
        },
        fontSize: {
          options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
          className: undefined,
          component: undefined,
          dropdownClassName: undefined,
        },
        fontFamily: {
          options: [
            'Arial',
            'Georgia',
            'Impact',
            'Tahoma',
            'Times New Roman',
            'Verdana',
          ],
          className: undefined,
          component: undefined,
          dropdownClassName: undefined,
        },
        list: {
          inDropdown: false,
          className: undefined,
          component: undefined,
          dropdownClassName: undefined,
          options: ['unordered', 'ordered', 'indent', 'outdent'],
        },
        textAlign: { inDropdown: true },
        image: {
          uploadEnabled: false,
          previewImage: true,
          inputAccept: 'image/jpeg,image/jpg,image/png,image/svg',
          alt: { present: false, mandatory: false },
          defaultSize: {
            height: 'auto',
            width: 'auto',
          },
        },
      }}
      onEditorStateChange={onChange}
      editorState={editorState}
      hashtag={{}}
    />
  );
}
