import React, { useRef } from "react";
import { useDispatch } from "react-redux";
import { Creators as TipoDepartamentoActions } from "store/ducks/tipodepartamento";

import _ from 'lodash';

import GridCadastro from "components/GridCadastro";
import List from "./ListTipoDepartamento/index";

export default function TipoDepartamento() {

    const dispatch = useDispatch();
    const dispatchDebounce = useRef(_.debounce(dispatch, 500)).current;

    return (
        <GridCadastro
            title='Tipos de Departamento'
            route={"/administracao/tipodepartamento/novo"}
            searchRequest={(value) => {
                dispatchDebounce(
                    TipoDepartamentoActions.listRequest({ page: 1, limit: 10, search: value })
                );
            }}
            titleButtonNovo="Novo Tipo"
        >
            <List/>
        </GridCadastro>
    )
}