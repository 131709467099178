import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { FormControlLabel, Switch } from "@material-ui/core";
import { Row, Col } from "react-bootstrap";
import InputForm from "components/InputForm";
import { Form } from "@unform/web";
import { useSelector, useDispatch } from "react-redux";

import { Creators as EtapasAction } from "store/ducks/etapas";

function FormModal({ data, onSave, onClose, ...props }) {
  const dispatch = useDispatch();

  const [etapa, setEtapa] = useState("");
  const [ultima, setUltima] = useState(false);

  useEffect(() => {
    if (data) setEtapa(data);
  }, [data]);

  function handleSubmit(values) {
    dispatch(
      EtapasAction.editRequest({
        id: data._id,
        fila: data.fila,
        ultima,
        ...values,
      })
    );
    onSave();
  }

  const handleChangeUltima = ({ target }) => {
    const { checked } = target;

    setUltima(checked);
  };

  return (
    <Dialog {...props}>
      <DialogTitle>
        <strong>Etapa:&nbsp;</strong>
        <span>{etapa.nome}</span>
      </DialogTitle>
      <DialogContent>
        <Form initialData={data} onSubmit={handleSubmit}>
          <Row>
            <Col>
              <InputForm name="nome" label="Nome da etapa" />
            </Col>
          </Row>
          <Row>
            <Col>
              <InputForm
                name="sla"
                label="SLA da etapa"
                type="number"
                InputProps={{ inputProps: { min: 0 } }}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6} className="field">
              <FormControlLabel
                control={
                  <Switch
                    name="ultima"
                    onChange={handleChangeUltima}
                    color="primary"
                    defaultChecked={data ? data.ultima : false}
                  />
                }
                label="Última etapa?"
              />
            </Col>
          </Row>

          <DialogActions>
            <Button color="primary" variant="contained" type="submit">
              Salvar
            </Button>
            <Button color="secondary" variant="contained" onClick={onClose}>
              Cancelar
            </Button>
          </DialogActions>
        </Form>
      </DialogContent>
    </Dialog>
  );
}

export default FormModal;
