import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

export const ImageSelection = styled.div`
  flex: 0 0 44%;
  box-sizing: border-box;
  align-items: center;
  margin-right: 1%;
  margin-bottom: 5px;
  padding: 0 5px 5px 5px;
  background-color: #eaeaea;

  :last-child {
    margin-right: 0;
  }
`;

export const ImageSelectionHeader = styled.div`
  display: flex;
  justify-content: flex-end;
`;
