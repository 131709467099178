import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';

import { Row } from 'react-bootstrap';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';

import AttachIconDown from 'assets/img/141.png';
import AttachIconUP from 'assets/img/140.png';

import DialogPreviewImage from 'components/DialogPreviewImage';

import { Creators as TipoImagensActions } from 'store/ducks/tipoimagem';
import { Creators as ComentariosActions } from 'store/ducks/comentarios';

import useStyles from './styles';
import ConfirmDialog from './ConfirmDialog';
import ComentarioPadrao from './ComentarioPadrao';
import { ImageSearchIcon } from 'components/Icons';

export default function Comentarios({
  analise,
  handleApprove,
  onSubmitFormalizacao = null,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();

  // const { loading } = useSelector((state) => state.analise);
  const { list: tipoImagens } = useSelector((state) => state.tipoImagem);
  const { list: comentarios } = useSelector((state) => state.comentarios);

  const [showLastComment, setShowLastComment] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  const [open, setOpen] = useState(false);
  const [isCommentBlank, setIsCommentBlank] = useState(true);
  const [confirmIndex, setConfirmIndex] = useState(false);
  const [loading, setLoading] = useState(false);

  const commentsOrder = useMemo(() => comentarios.reverse(), [comentarios]);

  useEffect(() => {
    dispatch(ComentariosActions.setListComments(analise.comentarios || []));
  }, [analise, dispatch, comentarios]);

  useEffect(() => {
    dispatch(
      TipoImagensActions.listRequest({
        limit: Number.MAX_SAFE_INTEGER,
        page: 1,
        sort: 'nome',
      })
    );
  }, [dispatch]);

  function handleShowAnexo(imageUrl) {
    setImageUrl(imageUrl);
    setOpen(true);
  }

  function avatarContent(autor) {
    const [firstName, lastName] = autor.split(' ');
    const [firstLetter] = firstName;
    const [secondLetter] = lastName ? lastName : [''];

    return `${firstLetter}${secondLetter}`;
  }

  function titleTipoImagem(tipoImagem) {
    const tipo = tipoImagens.find((item) => item._id === tipoImagem);
    if (tipo) return tipo.nome;
    return 'Tipo de imagem não encontrado';
  }

  function checkCommentAndApprove() {
    if (!isCommentBlank) {
      setConfirmIndex(true);
      return;
    }

    if (onSubmitFormalizacao) onSubmitFormalizacao();

    handleApprove('Sim');
  }

  function handleConfirmDialogClose() {
    setConfirmIndex(false);
  }

  function handleConfirmDialogConfirm() {
    handleApprove('Sim');
    setConfirmIndex(false);
  }

  return (
    <div>
      <Tooltip
        title={showLastComment ? 'Esconder comentários' : 'Mostrar comentários'}
      >
        <img
          src={showLastComment ? AttachIconUP : AttachIconDown}
          alt="AttachIcon"
          className="checklist-attach-icon"
          onClick={() => setShowLastComment(!showLastComment)}
        />
      </Tooltip>

      <div className="checklist-comment">
        {showLastComment && (
          <div className="last-comments">
            {commentsOrder.length > 0 ? (
              commentsOrder.map((item) => (
                <div className={classes.comment} key={item.data}>
                  <div className={classes.avatarContainer}>
                    <Tooltip title={item.autor}>
                      <Avatar className={classes.avatar}>
                        {avatarContent(item.autor)}
                      </Avatar>
                    </Tooltip>
                  </div>
                  <div>
                    <Typography variant="subtitle1">{item.autor}</Typography>
                    <Typography
                      style={{ maxWidth: '90%' }}
                      variant="subtitle2"
                      color="textSecondary"
                    >
                      {item.texto}
                    </Typography>
                    <Typography className={classes.dateComment} variant="body1">
                      <Moment format="DD/MM/YYYY - HH:mm" date={item.data} />
                    </Typography>
                    {item.filePath && (
                      <Tooltip
                        className={classes.buttonAnexo}
                        title={titleTipoImagem(item.tipoImagem)}
                      >
                        <IconButton
                          onClick={() => handleShowAnexo(item.filePath)}
                          variant="contained"
                        >
                          <ImageSearchIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </div>
                </div>
              ))
            ) : (
              <Row className="comment-line">
                <span className="comment-full">
                  Não há histórico de comentários para o documento.
                </span>
              </Row>
            )}
          </div>
        )}

        <div className="checklist-commnet-container">
          <div className="checklist-comment-area">
            <ComentarioPadrao documento={analise} />

            <Row className="checklist-buttons">
              <Button
                variant="contained"
                color="primary"
                style={{ color: 'white!important' }}
                disabled={loading}
                onClick={() => {
                  setLoading(true);
                  checkCommentAndApprove();
                }}
              >
                Avançar
                {loading && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </Button>

              {/* <Button
                variant="contained"
                color="secondary"
                disabled={loading}
                onClick={(e) => handleApprove("nao")}
              >
                Reprovar
                {loading && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </Button> */}

              <Link to="/operacao/esteira">
                <Button variant="contained" color="default">
                  Voltar
                </Button>
              </Link>
            </Row>
          </div>
        </div>
      </div>
      <DialogPreviewImage
        open={open}
        onClose={() => setOpen(false)}
        imageUrl={imageUrl}
      />
      {!!confirmIndex && (
        <ConfirmDialog
          open={!!confirmIndex}
          onClose={handleConfirmDialogClose}
          onConfirm={handleConfirmDialogConfirm}
        />
      )}
    </div>
  );
}
