import React, { useRef, memo, useState, useEffect } from "react";
import pdfjs from "pdfjs-dist";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

import Viewer from "components/SearchViewer/Viewer";

import "./styles.css";

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

function Thumbnail({ file }) {
  const canvas = useRef();

  const [open, setOpen] = useState(false);
  const [thumbnail, setThumbnail] = useState(null);

  useEffect(() => {
    const fileReader = new FileReader();
    fileReader.onload = async () => {

      if (file.type === "application/pdf") {
        let typedarray = new Uint8Array(fileReader.result);

        const pdf = await pdfjs.getDocument(typedarray).promise;
        const page1 = await pdf.getPage(1);
        const viewport = page1.getViewport({ scale: 0.2 });

        if (!canvas.current) {
          return;
        }

        canvas.current.height = viewport.height;
        canvas.current.width = viewport.width;

        page1.render({
          canvasContext: canvas.current.getContext("2d"),
          viewport: viewport,
        });
      } else {
        setThumbnail(fileReader.result);
      }
    };

    if (file.type === "application/pdf") {
      fileReader.readAsArrayBuffer(file);
    } else {
      fileReader.readAsDataURL(file);
    }
  }, [file]);

  function handleClick() {
    setOpen(true);
  }

  return (
    <div className="thumbnail-container">
      {file.type === "application/pdf" && <canvas ref={canvas}></canvas>}
      {file.type !== "application/pdf" && (
        <img src={thumbnail} alt="thumbnail" />
      )}
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
        <DialogContent>
          <Viewer imagem={file} />
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default memo(Thumbnail);
