import { call, put, select } from "redux-saga/effects";

import { toastr } from "react-redux-toastr";

import api from "../../services/api";
import { getToken } from "../../services/auth";

import { Creators as OrganizacoesActions } from "../ducks/organizacoes";

export const getOrganization = state => state.usuarios.auth.organization;

export function* listOrganizacoes(action) {

    const organization = yield select(getOrganization);
    
    try {
        const headerParams = {
            headers: { Authorization: getToken(), xkeyorg: organization }
        }

        const { data } = yield call(api.get, `organizacoes`, headerParams);

        yield put(OrganizacoesActions.listSuccess(data));

    } catch(error) {
        toastr.error("Ops", "Não foi possível listar organizações.");
    }
}