export const Types = {
  GET_REQUEST: 'empresa/GET_REQUEST',
  GET_SUCCESS: 'empresa/GET_SUCCESS'
}

const INITIAL_STATE = {
  data: null,
  loading: false
}

export default function empresa(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_REQUEST:
      return {
        ...state,
        loading: true
      }

    case Types.GET_SUCCESS:

      return {
        ...state,
        loading: false,
        data: action.payload
      }

    default:
      return state;
  }
}

export const Creators = {
  getEmpresaRequest: empresa => ({
    type: Types.GET_REQUEST,
    payload: empresa
  }),

  getEmpresaSuccess: empresa => ({
    type: Types.GET_SUCCESS,
    payload: empresa
  })
}