import React, { useEffect, useRef, useState } from 'react';
import './styles.css';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as TipoDocumentoActions } from 'store/ducks/tiposdocumento';
import { Row, Col } from 'react-bootstrap';
import { Form } from '@unform/web';
import { ValidationError } from 'yup';

import { Creators as EmpresasActions } from 'store/ducks/empresas';

import Grid from '@material-ui/core/Grid';
import ButtonMui from '@material-ui/core/Button';
import BackButton from 'components/BackButton';
import InputForm from 'components/InputForm';
import AutocompleteForm from 'components/AutocompleteForm';
import { FormControlLabel, Switch } from '@material-ui/core';

import TipoDocumentoSchema from 'validators/TipoDocumentoSchema';
import Content from 'components/Content';

export default function CreateTipoDocumento() {
  const dispatch = useDispatch();
  const formRef = useRef(null);

  const { list } = useSelector((state) => state.empresas);
  const usuarioId = useSelector((state) => state.usuarios.auth.id);

  useEffect(() => {
    dispatch(EmpresasActions.listRequest());
  }, [dispatch]);

  const [status, setStatus] = useState(false);
  const [useAssistant, setUseAssistant] = useState(false);

  const handleCheckStatus = () => {
    setStatus(!status);
  };

  const handleCheckUseAssistant = () => {
    setUseAssistant(!useAssistant);
  };

  const onSubmit = async (values) => {
    const tiposdocs = {
      ...values,
      userAcao: usuarioId,
      status,
      useAssistant,
    };

    try {
      await TipoDocumentoSchema.validate(tiposdocs, {
        abortEarly: false,
      });

      dispatch(TipoDocumentoActions.createRequest(tiposdocs));
    } catch (err) {
      const validationErrors = {};
      if (err instanceof ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });

        formRef.current.setErrors(validationErrors);
      }
    }
  };

  return (
    <Content>
      <Row>
        <Col md="12" className="painel">
          <div className="painel-content">
            <div className="painel-title text-left">Novo Tipo de Documento</div>
            <div className="painel-body">
              <Form ref={formRef} onSubmit={onSubmit}>
                <Row>
                  <Col md="12">
                    <AutocompleteForm
                      multiple
                      options={list}
                      label="Empresas*"
                      name="empresas"
                      getOptionLabel={(option) => option.fantasia}
                      transformOnSelect={(options) =>
                        options ? options.map((option) => option._id) : []
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <InputForm label="Nome*" name="nome" />
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <InputForm label="Descrição" name="descricao" />
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <InputForm label="apikey ocr" name="apikey" />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormControlLabel
                      control={
                        <Switch
                          name="useAssistant"
                          onChange={handleCheckUseAssistant}
                          color="primary"
                        />
                      }
                      label="Usar assistente"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormControlLabel
                      control={
                        <Switch
                          name="status"
                          onChange={handleCheckStatus}
                          color="primary"
                        />
                      }
                      label="Ativo"
                    />
                  </Col>
                </Row>

                <div className="buttons">
                  <Grid container spacing={1}>
                    <Grid item>
                      <ButtonMui
                        variant="contained"
                        color="primary"
                        type="submit"
                      >
                        Enviar
                      </ButtonMui>
                    </Grid>
                    <Grid item>
                      <ButtonMui
                        variant="contained"
                        color="secondary"
                        type="reset"
                      >
                        Limpar
                      </ButtonMui>
                    </Grid>
                    <Grid item>
                      <BackButton variant="contained" color="secondary">
                        Voltar
                      </BackButton>
                    </Grid>
                  </Grid>
                </div>
              </Form>
            </div>
          </div>
        </Col>
      </Row>
    </Content>
  );
}
