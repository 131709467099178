import { useState, useRef, useEffect } from "react";
import ReactDOM from "react-dom";

const RenderInWindow = ({ children, title, handleClose }) => {
  const [container, setContainer] = useState(null);
  const newWindow = useRef(null);

  useEffect(() => {
    // Create container element on client-side
    setContainer(document.createElement("div"));
  }, []);

  function copyStyles(src, dest) {
    Array.from(src.styleSheets).forEach((styleSheet) => {
      dest.head.appendChild(styleSheet.ownerNode.cloneNode(true));
    });
    Array.from(src.fonts).forEach((font) => dest.fonts.add(font));
  }

  useEffect(() => {
    // When container is ready
    if (container) {
      // Create window
      newWindow.current = window.open(
        "",
        "",
        "width=900,height=600,left=200,top=200,status=no"
      );
      // Append container
      newWindow.current.document.body.appendChild(container);
      newWindow.current.document.title = title;
      newWindow.current.addEventListener("beforeunload", () => handleClose());

      copyStyles(document, newWindow.current.document);

      // Save reference to window for cleanup
      const curWindow = newWindow.current;

      // Return cleanup function
      return () => {
        curWindow.close();
      };
    }
  }, [container]);

  return container && ReactDOM.createPortal(children, container);
};

export default RenderInWindow;
