import React, { useRef } from "react";
import { useDispatch } from "react-redux";
import { Creators as DepartamentosActions } from "store/ducks/departamentos";

import _ from 'lodash';
import "./styles.css";

import GridCadastro from "components/GridCadastro";
import List from "../Departamentos/ListDepartamento";

export default function Departamento() {

  const dispatch = useDispatch();
  const dispatchDebounce = useRef(_.debounce(dispatch, 500)).current;

  return (
    <GridCadastro
      title='Departamentos'
      route={"/administracao/departamentos/novo"}
      searchRequest={(value) => {
        dispatchDebounce(
          DepartamentosActions.listRequest({ page: 1, limit: 10, search: value })
        );
      }}
      titleButtonNovo="Novo Departamento"
    >
      <List />
    </GridCadastro>
  )
}