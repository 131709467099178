import { call, put, select } from "redux-saga/effects";

import { toastr } from "react-redux-toastr";

import api from "../../services/api";

import { getToken } from "../../services/auth";
import { Creators as ExportDocumentosActions } from "../ducks/exportDocuments";
import { Creators as DocumentoActions } from "../ducks/documentos";

export const getOrganization = (state) => state.usuarios.auth.organization;
export const getUser = (state) => state.usuarios;

export function* createExportation({ payload }) {
    const organization = yield select(getOrganization);

    try {
        const headerParams = {
            headers: { Authorization: getToken(), xkeyorg: organization },
        };

        const documentoId = payload.documentoId;

        delete payload.documentoId;

        let body = {
            ...payload,
        };

        const { data } = yield call(api.post, `documento/${documentoId}/generate-attachment/text-to-pdf`, body, headerParams);

        yield put(DocumentoActions.getDocumentoRequest(documentoId));
        yield put(ExportDocumentosActions.createSuccess(data));

        toastr.success("", "Exportação de documento efetuada com sucesso.");
    } catch (error) {
        toastr.error("Ops", "Não foi possível exportar o documento.");
    }
}
