export const Types = {
  LIST_REQUEST: 'notificacoes/LIST_REQUEST',
  LIST_SUCCESS: 'notificacoes/LIST_SUCCESS',
  MARK_READ_REQUEST: 'notificacoes/MARK_READ_REQUEST'
}

const INITIAL_STATE = {
  list: [],
  loading: false,
  page: 1,
  total: 0
}

export default function notificacoes(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.LIST_REQUEST:
      return { 
        ...state,
        loading: true,
        page: action.payload.page,
        list: action.payload.page === 1 ? [] : state.list
      }

    case Types.LIST_SUCCESS:
      return { 
        ...state, 
        ...action.payload,
        list: [
          ...state.list,
          ...action.payload.list
        ],
        loading: false 
      }

    case Types.MARK_READ_REQUEST:
      return { 
        ...state, 
        list: state.list.map(notification => (notification._id === action.payload._id ? { ...notification, lida: true } : notification))
      }

    default:
      return state
  }
}

export const Creators = {
  listRequest: payload => ({
    type: Types.LIST_REQUEST,
    payload
  }),
  listSuccess: payload => ({
    type: Types.LIST_SUCCESS,
    payload
  }),
  markReadRequest: _id => ({
    type: Types.MARK_READ_REQUEST,
    payload: { _id }
  })
}