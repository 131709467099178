export const Types = {
  GET_TRANSLATE_REQUEST: "translate/GET_TRANSLATE_REQUEST",
  GET_TRANSLATE_SUCCESS: "translate/GET_TRANSLATE_SUCCESS",
};

const INITIAL_STATE = {
  loading: false,
  data: {},
};

export default function translate(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_TRANSLATE_REQUEST:
      return { ...state, loading: true };

    case Types.GET_TRANSLATE_SUCCESS:
      return { ...state, loading: false, data: action.payload };

    default:
      return state;
  }
}

export const Creators = {
  getTranslateRequest: () => ({
    type: Types.GET_TRANSLATE_REQUEST,
  }),

  getTranslateSuccess: (data) => ({
    type: Types.GET_TRANSLATE_SUCCESS,
    payload: data,
  }),
};
