import api from "services/api";
import { call, select, put, all, takeLatest } from "redux-saga/effects";
import { toastr } from "react-redux-toastr";
import { getToken } from "services/auth";

import { Creators as AcoesAuditoriaActions, Types } from 'store/ducks/acoesauditoria';

export const getOrganization = state => state.usuarios.auth.organization;

export function* listAcoesAuditoria() {
  const organization = yield select(getOrganization);

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    }

    const { data } = yield call(
      api.get,
      'auditoria/acoes',
      headerParams
    )

    yield put(AcoesAuditoriaActions.listSuccess(data));
  } catch(error) {
    toastr.error('Ops', 'Não foi possível listar as ações')
  }
}

export default all([
  takeLatest(Types.LIST_REQUEST, listAcoesAuditoria)
])