import styled, { css } from 'styled-components/macro';
import { Button as MaterialButton } from '@material-ui/core';

export const Container = styled.div`
    background-color: #fff;
    margin-left: -32px;
    padding: 0 0 0 32px;
    border-radius: 4px;
    transition: background-color 0.3s ease-in-out;
    ${props =>
        props.isFocused &&
        css`
            background-color: #a7c2f2;
        `}
`;

export const Wrapper = styled.div`
    position: relative;
    border: 2px dotted transparent;
    background-color: #fff;
    padding: 4px;
    border-radius: 4px;
    transition: border 0.1s ease-in-out;

    ${props =>
        props.isFocused &&
        css`
            border: 3px dashed #a7c2f2;
            margin-bottom: 45px;
        `}

    .toolbar-class {
        position: absolute;
        background-color: #fff;
        padding: 4px;
        border: 1px solid #ccc;
        top: -50px;
        z-index: 11;
        visibility: hidden !important;
        ${props =>
            props.isFocused &&
            css`
                visibility: visible !important;
            `}
    }
    &::before {
        position: absolute;
        top: 0;
        left: -32px;
        width: 32px;
    }
    .editor-class {
        margin: 0 5px;
        overflow: hidden;
        .public-DraftStyleDefault-block {
            margin: 0px;
        }
    }
`;

export const Label = styled.div`
    position: absolute;
    display: block;
    width: 32px;
    height: 100%;
    top: 0;
    left: -32px;
    color: #fff;
    overflow: hidden;

    & span {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        writing-mode: vertical-lr;
        text-orientation: sideways-right;
        text-align: center;
        transform: rotate(180deg);
    }
`;

export const Menu = styled.div`
    position: absolute;
    bottom: -48px;
    right: 0;
    z-index: 10;
    pointer-events: true;
    background-color: transparent;
    padding-top: 16px;

    ${({ isFocused }) =>
        !isFocused &&
        css`
            opacity: 0;
            pointer-events: none;
        `};
`;

export const Button = styled(MaterialButton)`
    &:not(:last-of-type) {
        margin-right: 8px;
    }

    svg {
        margin-right: 8px;
    }
`;
