import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { debounce } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
/* Prévia de exemplo usando o react-hook-form
function Component() {

  const { analysts } = useSelector((state) => state.usuarios);

  const { register, handleSubmit, setValue, watch, errors, reset } = useForm({
    validationSchema: PesquisaAvancadaSchema,
    defaultValues: {
      teste: "638f472c4627dee514420838",
      
    },
  });

  return (

    <form>
      <DebouncedAutoComplete
        name="teste"
        initialValue={analysts?.results.find(
          (r) => r._id === watch("teste")
        )}
        onChange={(value) => setValue("teste", value?._id)}
        options={() => {
          return analysts?.results ?? [];
        }}
        loading={analysts.loading}
        getOptionLabel={(option) => option.nome}
        onFirstMount={() =>
          dispatch(Creators.searchByNameRequest(""))
        }
        onDebounce={(value) => {
          dispatch(Creators.searchByNameRequest(value));
        }}
      />
    </form>
  )
}
*/
export const DebouncedAutoComplete = ({
  options = () => [],
  onDebounce,
  name,
  getOptionLabel,
  debounceDelayInMs = 400,
  ref,
  onChange,
  loading = false,
  initialValue = null,
  onFirstMount = () => {},
  getOptionSelected = (v, o) => v._id === o._id,
  renderInput = (params) => <TextField {...params} />,
  ...props
}) => {
  const debouncedCallback = useCallback(
    debounce(onDebounce, debounceDelayInMs),
    []
  );

  const [value, setValue] = useState(props.multiple ? [] : initialValue)
  useEffect(() => {
    if (!onFirstMount) return

    onFirstMount()
  }, [])

  return (
    <Autocomplete
      {...(props || {})}
      name={name}
      loading={loading}
      loadingText={"Carregando..."}
      ref={ref}
      noOptionsText="Nenhum registro encontrado"
      options={options()}
      getOptionLabel={getOptionLabel}
      onChange={(e, v) => {
        setValue(v);
        onChange(v);
      }}
      onInputChange={(e, value) => {
        debouncedCallback(value);
      }}
      getOptionSelected={getOptionSelected}
      value={value}
      renderInput={renderInput}
      defaultValue={initialValue}
    />
  );
};
