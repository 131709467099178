import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Creators as LoginActions } from "../../store/ducks/login";
import { Creators as UsuariosActions } from "../../store/ducks/usuarios";
import history from "../../../src/routes/history";
import { getInformations, logoutUser } from "store/sagas/usuarios";

export default function Callback() {

    const { hash } = useLocation();
    const dispatch = useDispatch();

    const { login, auth } = useSelector((state) => state.login);

    const handleLogin = (accessToken, idToken, code, state) => {
        try {
            getInformations(accessToken);
            dispatch(LoginActions.loginRequest({ accessToken, idToken, code, state }));
            dispatch(UsuariosActions.loginKcRequest({ kctoken: accessToken }));
        } catch (error) {
            logoutUser();
        }
    };

    useEffect(() => {

        if (auth) {
            history.push('/');
            return;
        }

        const searchParams = new URLSearchParams(hash.replace("#", ""));
        const accessToken = searchParams.get("access_token");
        const idToken = searchParams.get("id_token");
        const state = searchParams.get("state");
        const code = searchParams.get("code");

        if (!accessToken || !idToken || !state) {
            history.push('/');
            return;
        }

        handleLogin(accessToken, idToken, code, state);

    }, [hash, login, auth, history]);

    return (
        <div>
            CALLBACK - Loading...
        </div>
    );
}
