import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  TableContainer,
  TableFooter,
  TablePagination,
} from "@material-ui/core";

import { Link, useLocation } from "react-router-dom";
import LoadingTable from "components/LoadingTable";
import StyledTableRow from "components/StyledTableRow";
import ButtonSquare from "components/ButtonSquare";
import ToolTipComponent from "components/Tooltip";
import { Creators as RPAActions } from "store/ducks/rpa";

import "./styles.css";

export default function ListRPA() {
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const [userActions, setUserActions] = useState([]);

  const {
    docs,
    loading,
    page = 1,
    limit = 10,
    sort = "nome",
    totalDocs,
  } = useSelector((state) => state.rpa);

  const {
    access: { servicos },
  } = useSelector((state) => state.permissoes);

  const [request, setRequest] = useState({ page, limit, sort });

  useEffect(() => {
    let find = servicos.find((item) => item.route === pathname);
    if (find !== undefined) {
      setUserActions(find);
    }
  }, [pathname, servicos]);

  useEffect(() => {
    dispatch(
      RPAActions.listRequest(request)
    );
  }, [dispatch, request]);

  function handleChangeRowsPerPage({ target }) {
    const { value: limit } = target;
    setRequest({ ...request, limit, page: 1 });
  }

  function handleChangePage(event, newPage) {
    setRequest({ ...request, page: newPage + 1 });
  }

  function handleSortChange(sort) {
    setRequest({ ...request, sort });
  }

  return (
    <>
      <TableContainer className="list-departamento-container">
        {(docs && docs.length > 0) || !!loading ? (
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Tipo</TableCell>
                <TableCell>CNPJ</TableCell>
                <TableCell>URL</TableCell>
                <TableCell>Ativo</TableCell>
                <TableCell>Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!!loading && <LoadingTable columns={5} />}
              {!loading &&
                docs.map((doc) => (
                  <StyledTableRow key={doc._id}>
                    <TableCell>{doc.tipo}</TableCell>
                    <TableCell>{doc.empresa?.razaoSocial}</TableCell>
                    <TableCell>{doc.url}</TableCell>
                    <TableCell>
                      {doc.status === true ? (
                        <span className="status-active">Ativo</span>
                      ) : (
                        <span className="status-inactive">Inativo</span>
                      )}
                    </TableCell>
                    {userActions.actions &&
                      userActions.actions.map(
                        (userAct, index) =>
                          userAct.action === "editar" && (
                            <TableCell key={index}>
                              <div className="flex-direction">
                                <ToolTipComponent
                                  title="Editar"
                                  placement="top"
                                >
                                  <Link
                                    to={{
                                      pathname: "/rpa/gerenciar",
                                      state: { rpa: doc },
                                    }}
                                    className="linkicon"
                                    id="rpa"
                                  >
                                    <ButtonSquare icon="edit" />
                                  </Link>
                                </ToolTipComponent>
                              </div>
                            </TableCell>
                          )
                      )}
                  </StyledTableRow>
                ))}
            </TableBody>
            {!loading && (
              <TableFooter>
                <TableRow>
                  <TablePagination
                    count={totalDocs}
                    page={request.page - 1}
                    rowsPerPage={request.limit}
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    labelRowsPerPage="Linhas por página"
                    labelDisplayedRows={({ from, to, count }) =>
                      `${from}-${to} de ${
                        count !== -1 ? count : `mais que ${to}`
                      }`
                    }
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    onPageChange={handleChangePage}
                  />
                </TableRow>
              </TableFooter>
            )}
          </Table>
        ) : (
          <div className="list-departamento-content">
            <span className="list__none">Não há RPAs registrados.</span>
          </div>
        )}
      </TableContainer>
    </>
  );
}
