import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  TablePagination,
  TableFooter,
  TableSortLabel,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import CustomTableSortLabel from "components/CustomTableSortLabel";
import StyledTableRow from "components/StyledTableRow";
import LoadingTable from "components/LoadingTable";
import ButtonSquare from "components/ButtonSquare";

import ToolTipComponent from "components/Tooltip";

import { Creators as CheckTipoActions } from "store/ducks/checktipo";

function TableCheckTipo({ empresa }) {
  const {
    list: checkTipos,
    loading = false,
    page = 1,
    limit = 10,
    total,
    sort = "nome",
  } = useSelector((state) => state.checkTipos);
  const dispatch = useDispatch();
  const [request, setRequest] = useState({ page, limit, sort });

  useEffect(() => {
    dispatch(CheckTipoActions.getCheckEmpresaRequest({ ...request, empresa }));
  }, [empresa, request, dispatch]);

  function handleChangeRowsPerPage({ target }) {
    const { value } = target;
    setRequest({ ...request, limit: value });
  }

  function handleChangePage(event, newPage) {
    setRequest({ ...request, page: newPage + 1 });
  }

  function handleSortChange(sort) {
    setRequest({ ...request, sort });
  }

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>
              <CustomTableSortLabel
                onClick={handleSortChange}
                currentSort={request.sort}
                sortField="nome"
              >
                Descrição do Checklist
              </CustomTableSortLabel>
            </TableCell>
            <TableCell align="center">Ações</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!!loading && <LoadingTable rows={request.limit} columns={3} />}
          {!loading &&
            checkTipos &&
            checkTipos
              .filter((checkTp) => checkTp.nome)
              .map((item) => (
                <StyledTableRow key={item._id}>
                  <TableCell>{item.nome}</TableCell>
                  <TableCell align="center">
                    <div className="flex-direction">
                      <ToolTipComponent title="Títulos" placement="top">
                        <Link
                          to={{
                            pathname: "/operacao/checktipo/titulo",
                            state: {
                              checkTipo: item,
                            },
                          }}
                        >
                          <ButtonSquare icon="list" />
                        </Link>
                      </ToolTipComponent>

                      <ToolTipComponent
                        title="Editar Tipo de Checklist"
                        placement="top"
                      >
                        <Link
                          to={{
                            pathname: "/operacao/checktipo/editchecktipo",
                            state: {
                              checkTipos: item,
                            },
                          }}
                          className="linkicon"
                          id="tiposdocs"
                        >
                          <ButtonSquare icon="edit" />
                        </Link>
                      </ToolTipComponent>

                      <ToolTipComponent
                        title="Visualizar auditoria"
                        placement="top"
                      >
                        <Link
                          to={{
                            pathname: "/operacao/checktipo/auditoria",
                            state: {
                              checkTipos: item,
                            },
                          }}
                          className="linkicon"
                          id="tiposdocs"
                        >
                          <ButtonSquare icon="visibility" />
                        </Link>
                      </ToolTipComponent>
                    </div>
                  </TableCell>
                </StyledTableRow>
              ))}
        </TableBody>
        {!loading && (
          <TableFooter>
            <TableRow>
              <TablePagination
                count={total}
                page={request.page - 1}
                rowsPerPage={request.limit}
                rowsPerPageOptions={[10, 25, 50, 100]}
                labelRowsPerPage="Linhas por página"
                labelDisplayedRows={({ from, to, count }) =>
                  `${from}-${to} de ${count !== -1 ? count : `mais que ${to}`}`
                }
                onRowsPerPageChange={handleChangeRowsPerPage}
                onPageChange={handleChangePage}
              />
            </TableRow>
          </TableFooter>
        )}
      </Table>
    </TableContainer>
  );
}

export default TableCheckTipo;
