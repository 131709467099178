import api from "services/api";
import { getToken } from "services/auth";
import { call, select, put } from "redux-saga/effects";
import { toastr } from "react-redux-toastr";

import { Creators as DashboardCustomActions } from "store/ducks/dashboardcustom";

export const getOrganization = (state) => state.usuarios.auth.organization;

export function* createDashboardCustom(action) {
  const organization = yield select(getOrganization);

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const body = { ...action.payload };

    const { data } = yield call(
      api.post,
      `dashboard/custom`,
      body,
      headerParams
    );

    yield put(DashboardCustomActions.createSuccess(data));
  } catch (error) {
    toastr.error("Ops", "Não foi possível listar as Dashboard.");
  }
}

export function* createDashboardYearCustom(action) {
  const organization = yield select(getOrganization);

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const body = { ...action.payload };

    const { data } = yield call(
      api.post,
      `dashboard/custom/year`,
      body,
      headerParams
    );

    yield put(DashboardCustomActions.createYearSuccess(data));
  } catch (error) {
    toastr.error("Ops", "Não foi possível listar as Dashboard.");
  }
}

export function* getDashPredefinitions(action) {
  const organization = yield select(getOrganization);

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const body = { ...action.payload };

    const { data } = yield call(
      api.post,
      `dashboard/custom/predefinitions`,
      body,
      headerParams
    );

    yield put(DashboardCustomActions.predefinitionsSuccess(data));
  } catch (error) {
    toastr.error("Ops", "Não foi possível listar as Dashboard.");
  }
}

export function* exportCustomTasks({ payload }) {
  try {
    const organization = yield select(getOrganization);
    const { option, type, name, x, y, typeDocument, selectedLine } = payload;

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
      params: { option, type, name, x, y, typeDocument, selectedLine },
    };

    toastr.success("", `Exportação agendada com sucesso.`);

    const { data } = yield call(api.get, `dashboard/custom/export`, headerParams);

    if (data.error) {
      toastr.error("", data.message);
      yield put(DashboardCustomActions.exportSuccess());
      return
    }

    yield put(DashboardCustomActions.exportSuccess(data));

  } catch (error) {
    console.error(error);
    toastr.error("Ops", "Não foi possivel exportar os dados.");
  }
}