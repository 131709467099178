import React, { useEffect, useState, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { Draggable } from 'react-beautiful-dnd';

import EditorParagraph from '../EditorParagraph';
import { EditorHeaderFooter } from '../EditorHeaderFooter';

import { Page, Container } from './styles/pageEditor';

function TextBox({}) {
    const { paragraphTemplate } = useSelector(state => state.template);
    const state = useSelector(state => state.template);

    return (
        <Row className='space-row'>
            <Container>
                <Col className=''>
                <Page>
                    <div className='header'>
                    {!!state.header && <EditorHeaderFooter header data={state.header} />}
                    </div>

                     <ul className='content'>
                        {!!Object.keys(paragraphTemplate).length &&
                            paragraphTemplate.map((item, index) => {
                            return (

                            <Draggable
                            key={item.id_editor}
                            draggableId={item.id_editor}
                            index={index}>
                            {provided => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}>
                                    
                                  <EditorParagraph item={item} index={index} />

                                  {provided.placeholder}
                                </div>
                            )}
                            </Draggable>
                            );
                        })}
                     </ul>
                    <div className='footer'>
                    {!!Object.keys(state.footer).length && <EditorHeaderFooter data={state.footer} />}
                    </div>
                  </Page>
                </Col>
            </Container>
        </Row>
    );
}

export default memo(TextBox);
