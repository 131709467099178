import styled from "styled-components/macro";

export const Container = styled.div`
  height: 200px;
  width: 100%;
  border-radius: 10px;
  padding: 8px;
  border: 1px solid #444444;
  box-shadow: 2px 2px 4px 4px #00000017;
  display: flex;
  justify-content: center;
`;

export const DropZone = styled.div`
  width: 30%;
  border-radius: 10px;
  border: 4px dashed #444444;
  background-color: #114ccc22;
  padding: 16px;
  display: flex;
  margin-left: 16px;
  justify-content: center;
  align-items: center;

  span {
    display: block;
    font-size: 16px;
    color: #444444;
    text-align: center;
  }
`;

export const FileList = styled.ul`
  min-height: 100%;
  width: 70%;
  overflow: scroll;
  overflow-x: hidden;
`;
