import { call, put, select } from "redux-saga/effects";

import { toastr } from "react-redux-toastr";
import { push } from "connected-react-router";

import api from "../../services/api";
import { getToken } from "../../services/auth";

import { Creators as ImportacaoActions } from "../ducks/importacao";

var FormData = require("form-data");

export const getOrganization = (state) => state.usuarios.auth.organization;
export const getUserId = (state) => state.usuarios.auth.id;

export function* createImportacao(action) {
  const organization = yield select(getOrganization);

  let formData = new FormData();

  const {
    layout,
    separador,
    files,
    groupDistribution,
  } = action?.payload;

  formData.append("separador", separador || "|");
  formData.append("layout", layout);
  formData.append("groupDistribution", groupDistribution || null);

  files?.map((file) => {
    formData.append("file", file);
  });

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const { data } = yield call(api.post, `importacao`, formData, headerParams);
    const { message, errorMessage, error } = data;

    if (error) throw new Error(message);

    yield put(push(`/importacao`));
    yield put(ImportacaoActions.createSuccess(data));

    if (!errorMessage) toastr.success("", message);
    else toastr.warning("", message.concat(' ', errorMessage));

  } catch (error) {
    toastr.error('Ops', error.message || 'Não foi possível agendar a importação.');
  }
}

export function* createImportacaoHeader(action) {
  const organization = yield select(getOrganization);

  let formData = new FormData();
  formData.append("file", action.payload);

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const { data } = yield call(
      api.post,
      `importacao/header`,
      formData,
      headerParams
    );

    yield put(ImportacaoActions.createSuccessHeader(data));
  } catch (error) {
    toastr.error("Ops", "Não foi obter o cabeçalho do arquivo");
  }
}
