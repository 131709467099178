import React from "react";
import { useSelector } from "react-redux";

export default function MobileSearch({ searchText, setSearchText }) {
  let { resultSearch } = useSelector((state) => state.documentos.autocomplete);

  let isDocIdSelected = useSelector((state) => state.documentos.docid);

  return (
    <div className="search_mobile no-wrap">
      <input
        type="text"
        placeholder="Digite para pesquisar"
        onChange={(e) => setSearchText(e.target.value)}
        value={searchText}
      />

      {resultSearch &&
        !isDocIdSelected &&
        resultSearch.length > 0 &&
        searchText !== "" && (
          <div className="topSearchResult">
            <ul>
              {resultSearch.map((doc) => (
                <li key={doc._id}>
                  <div className="linkicon">
                    <strong>{doc._id}</strong>
                    <span>
                      <strong>Empresa: </strong>
                      {doc._source.empresa}
                    </span>
                    <span>
                      <strong>Departamento: </strong>
                      {doc._source.departamento}
                    </span>
                    <span>
                      <strong>Tipo de documento: </strong>
                      {doc._source.tipodocumento}
                    </span>
                    <span>
                      <strong>Palavra encontrada: </strong>
                      {doc._source.tipodocumento}
                    </span>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )}
    </div>
  );
}
