import React, { useState, useEffect, useRef } from "react";
import Button from "@material-ui/core/Button";
import { useSelector, useDispatch } from "react-redux";
import { TextEditorWithTemplate } from "components/TextEditorWithTemplate";
import { Autocomplete } from "@material-ui/lab";
import DefaultInput from "components/DefaultInput";
import Utils from "utils/utils";

import useStyles from "../FormEnviarEmail/styles";

import { Creators as ExportDocumentsActions } from "store/ducks/exportDocuments";
import { Creators as TiposImagemActions } from "store/ducks/tipoimagem";
import { Creators as TemplateActions } from "store/ducks/template";

function FormEnviarDocumento({ documento, children }) {
  const dispatch = useDispatch();
  const ref = useRef();

  const { tipoDocumento } = documento;

  const tiposimagem = useSelector((state) => state.tipoImagem.list);

  useEffect(() => {
    if (tipoDocumento) {
      dispatch(TiposImagemActions.listByTipoDocumentoRequest(tipoDocumento));
    }
  }, [dispatch, tipoDocumento]);

  const INITIAL_FORM = {
    texto: "",
    documento: documento?._id || "",
    protocolo: documento?.numeroCNJ || "",
    signsPoints: [],
    tipoImagem: "",
    addImagesRelated: false,
    templateId: null,
    emails: [],
  };

  const { loading } = useSelector((state) => state.comentarios);
  const [form, setForm] = useState(INITIAL_FORM);
  const [sending, setSending] = useState(false);

  const classes = useStyles();

  const handleSelectTemplate = (value, signsPoints, templateId) => {
    setForm({
      ...form,
      texto: value,
      templateId: templateId,
      signsPoints: signsPoints,
    });
  };

  const handleCheckBox = (value) => {
    setForm({
      ...form,
      addImagesRelated: value,
    });
  };

  useEffect(() => {
    setForm(INITIAL_FORM);
    dispatch(TemplateActions.getTemplate({}));
  }, [loading, dispatch]);

  function canSubmit() {
    return !sending && form.texto !== "";
  }

  function clearForm() {
    setForm(INITIAL_FORM);
    ref.current?.clearForm();
  }

  function onChangeAutocomplete(name, value, e) {
    const util = new Utils();
    const email = value?.map((e) =>
      typeof e === "object"
        ? e.inputValue.toLocaleLowerCase()
        : e.toLocaleLowerCase()
    );
    const emails = util.handlePastedEmails(email);
    setForm({ ...form, [name]: emails });
  }

  async function handleSubmit() {
    setSending(true);

    const formToSend = {
      texto: form.texto,
      tipoImagem: form.tipoImagem,
      documentoId: form.documento,
      templateId: form.templateId,
      destinatarios: form.emails,
      protocolo: form.protocolo,
      signsPoints: form.signsPoints,
      addImagesRelated: form.addImagesRelated,
    };

    dispatch(ExportDocumentsActions.createRequest(formToSend));

    clearForm();

    setSending(false);
  }

  return (
    <div style={{ marginTop: "1rem" }}>
      <Autocomplete
        className="mb-2"
        options={tiposimagem}
        getOptionLabel={(option) => option.nome}
        onChange={(e, value) =>
          setForm({ ...form, tipoImagem: value?._id || null })
        }
        getOptionSelected={(option, value) => option._id === value._id}
        renderInput={(params) => (
          <DefaultInput
            {...params}
            name="tipoImagem"
            label="Tipo de imagem"
            InputLabelProps={{
              shrink: true,
            }}
            required
          />
        )}
      />

      <TextEditorWithTemplate
        size={"lg"}
        documento={documento}
        onSelectTemplate={(texto, signsPoints, templateId) => handleSelectTemplate(texto, signsPoints, templateId)}
        onHandleCheckBox={(value) => handleCheckBox(value)}
        ref={ref}
        allOptions={true}
        onTextChange={(texto) =>
          setForm({
            ...form,
            texto,
          })
        }
      />

      {/* <Autocomplete
                size="small"
                freeSolo
                multiple
                selectOnFocus
                clearOnBlur
                id="emails"
                name="email"
                value={form.emails}
                style={{ marginBottom: "8px" }}
                onChange={(e, value) =>
                    onChangeAutocomplete("emails", value, e)
                }
                options={[]}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Adicione destinatários"
                        variant="outlined"
                        name="opcoes"
                    />
                )}
                getOptionLabel={(option) =>
                    typeof option === "string"
                        ? option
                        : option.inputValue
                }
                filterOptions={(_, params) => [
                    {
                        inputValue: params.inputValue,
                        title: `Novo e-mail "${params.inputValue}"`,
                    },
                ]}
                renderOption={(option) =>
                    typeof option === "string" ? option : option.title
                }
            /> */}

      <div className={classes.actions}>
        <Button
          color="primary"
          variant="contained"
          className={classes.newCommentButton}
          disabled={!canSubmit()}
          onClick={handleSubmit}
          children={sending ? "Enviando..." : "Enviar"}
        />
        {children}
      </div>
    </div>
  );
}

export default FormEnviarDocumento;
