import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    EditorState,
    ContentState,
    Modifier
} from "draft-js";
import htmlToDraft from "html-to-draftjs";
import { Creators as TemplateActions } from "../../../store/ducks/template";

import { Container, Button, Menu, Wrapper } from "./styles/EditorHeaderFooter";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { DeleteIcon, SaveOutlinedIcon } from "components/Icons";

import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "ckeditor5-build-full";

export const EditorHeaderFooter = ({ data, header }) => {
    const [stateCK5, setStateCK5] = useState(data.currentContent);
    const [CK5Ref, setCK5Ref] = useState(null);

    const dispatch = useDispatch();

    const { variable, currentEditor } = useSelector((state) => state.template);
    const contentDataState = ContentState.createFromBlockArray(
        htmlToDraft(data.currentContent)
    );

    const editorDataState = EditorState.createWithContent(contentDataState);

    const [editorState, setEditorState] = useState(
        editorDataState ? editorDataState : EditorState.createEmpty()
    );

    const [isFocused, setIsFocused] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false)

    useEffect(() => {
        if (!!Object.keys(variable).length) {
            if (data.id_editor === currentEditor) {
                if (CK5Ref) {
                    CK5Ref.model.change(writer => {
                        const insertPosition = 0 || CK5Ref.model.document.selection.getFirstPosition();
                        writer.insertText(`${variable}`, insertPosition);
                    });
                } else {
                    setStateCK5(stateCK5 + `${variable}`);
                }
            }
        }
    }, [variable]);



    const handleSave = async () => {
        const { id_editor } = data;

        setIsFocused(false);
        dispatch(TemplateActions.currenteEditor(id_editor));
        header
            ? dispatch(
                TemplateActions.addHeader({ currentContent: stateCK5, id_editor })
            )
            : dispatch(
                TemplateActions.addFooter({ currentContent: stateCK5, id_editor })
            );
    };

    const handleIsFocus = (editor) => {
        setCK5Ref(editor);
        handleSave();
        setIsFocused(true);
    };

    const handleDelete = () => {
        header
            ? dispatch(TemplateActions.deleteHeader())
            : dispatch(TemplateActions.deleteFooter())
    }

    const confirmButtonDelete = async () => {
        setIsFocused(true)
        setConfirmDelete(true)
        await setTimeout(() => setConfirmDelete(false), 2000)
    }

    const handleEditorChangeCK5 = (event, editor) => {
        setStateCK5(editor.getData());
    };



    return (
        <Container isFocused={isFocused}>
            <Wrapper
                onBlur={handleSave}
                isFocused={isFocused}
                onFocus={() => handleIsFocus}
            >
                <CKEditor
                    data={stateCK5}
                    editor={ClassicEditor}
                    onChange={handleEditorChangeCK5}
                    onFocus={(event, editor) => handleIsFocus(editor)}
                />
                <Menu
                    isFocused={isFocused}
                >
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={handleSave}
                    >
                        <SaveOutlinedIcon style={{ color: 'white' }} />
                        Salvar
                    </Button>
                    {
                        confirmDelete ?
                            <Button
                                variant="contained"
                                color="secondary"
                                size="small"
                                onClick={handleDelete}
                            >
                                <DeleteIcon style={{ color: 'white' }} />
                                Clique Novamente
                            </Button>
                            :
                            <Button
                                variant="contained"
                                color="secondary"
                                size="small"
                                onClick={confirmButtonDelete}
                            >
                                <DeleteIcon style={{ color: 'white' }} />
                                Excluir
                            </Button>
                    }
                </Menu>
            </Wrapper>
        </Container>
    );
};
