import api from "../../services/api";
import { getToken } from "../../services/auth";
import { put, call } from "redux-saga/effects";
import { toastr } from "react-redux-toastr";
import { select } from "redux-saga/effects";

import { push } from "connected-react-router";

import { Creators as FilaActions } from "../ducks/filas";

export const getOrganization = (state) => state.usuarios.auth.organization;

export const getUserId = (state) => state.usuarios.auth.id;

export function* listFila({ payload }) {
  try {
    const organization = yield select(getOrganization);

    const { page, limit, sort } = payload;

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
      params: { page, limit, sort },
    };

    const { data } = yield call(api.post, "filas", null, headerParams);

    const resp = {
      list: data,
      total: data.length,
    };

    yield put(FilaActions.listSuccess(resp));
  } catch (error) {
    toastr.error("Ops", "Não foi possível listar as filas");
  }
}

export function* listFluxo({ payload }) {
  try {
    const organization = yield select(getOrganization);

    const { page, limit, sort } = payload;

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
      params: { page, limit, sort },
    };

    const { data } = yield call(api.post, "fluxos", null, headerParams);

    const resp = {
      list: data,
      total: data.length,
    };

    yield put(FilaActions.listSuccess(resp));
  } catch (error) {
    toastr.error("Ops", "Não foi possível listar as filas");
  }
}

export function* createFila(action) {
  const organization = yield select(getOrganization);

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const { data } = yield call(api.post, `fila`, action.payload, headerParams);

    yield put(push(`/operacao/esteiraconfig`));

    yield put(FilaActions.createSuccess(data));

    toastr.success("", "Fila criada com sucesso.");
  } catch (error) {
    toastr.error("Ops", "Não foi possível criar a fila.");
  }
}

export function* editFila(action) {
  const userId = yield select(getUserId);

  try {
    const organization = yield select(getOrganization);

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const body = { ...action.payload, userAcao: userId };

    const { data } = yield call(
      api.put,
      `/fila/${action.payload.fila}`,
      body,
      headerParams
    );

    yield put(push(`/operacao/esteiraconfig`));

    yield put(FilaActions.editSuccess(data));

    toastr.success("", "Fila editada com sucesso.");
  } catch (error) {
    toastr.error("Ops", "Não foi possível editar a fila.");
  }
}

export function* getFila(action) {
  try {
    const organization = yield select(getOrganization);

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const { data } = yield call(
      api.get,
      `/fila/${action.payload._id}`,
      headerParams
    );

    yield put(FilaActions.getSuccess(data));
  } catch (error) {
    toastr.error("Ops", "Não foi possível buscar os dados da fila.");
  }
}
