export const Types = {
  GET_CHECKLISTDOCUMENTO_REQUEST:
    "checklistdocumento/GET_CHECKLISTDOCUMENTO_REQUEST",
  GET_CHECKLISTDOCUMENTO_SUCCESS:
    "checklistdocumento/GET_CHECKLISTDOCUMENTO_SUCCESS",
  UPDATE_CHECKLISTDOCUMENTO_REQUEST:
    "checklistdocumento/UPDATE_CHECKLISTDOCUMENTO_REQUEST",
  UPDATE_CHECKLISTDOCUMENTO_SUCCESS:
    "checklistdocumento/UPDATE_CHECKLISTDOCUMENTO_SUCCESS",
  UPLOAD_INFORMATION_REQUEST: "checklistdocumento/UPLOAD_INFORMATION_REQUEST",
  UPLOAD_INFORMATION_SUCCESS: "checklistdocumento/UPLOAD_INFORMATION_SUCCESS",
  UPLOAD_IMAGE_RECORRENCIA_REQUEST:
    "checklistdocumento/UPLOAD_IMAGE_RECORRENCIA_REQUEST",
  UPLOAD_IMAGE_RECORRENCIA_SUCCESS:
    "checklistdocumento/UPLOAD_IMAGE_RECORRENCIA_SUCCESS",
  SAVE_ITEM: "checklistdocumento/SAVE_ITEM",
  SYNC_CHECKLISTDOCUMENTO_REQUEST: "checklistdocumento/SYNC_CHECKLISTDOCUMENTO_REQUEST",
  SYNC_CHECKLISTDOCUMENTO_SUCCESS: "checklistdocumento/SYNC_CHECKLISTDOCUMENTO_SUCCESS",
};

const INITIAL_STATE = {
  loading: false,
  loadingUpload: false,
  data: null,
};

export default function checklistdocumento(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.UPDATE_CHECKLISTDOCUMENTO_REQUEST:
      return { ...state, loading: true };
    case Types.SYNC_CHECKLISTDOCUMENTO_REQUEST:
      return { ...state, loading: true };
    case Types.SYNC_CHECKLISTDOCUMENTO_SUCCESS:
      return { ...state, loading: false };
    case Types.UPDATE_CHECKLISTDOCUMENTO_SUCCESS:
      return { ...state, loading: false, data: action.payload };
    case Types.GET_CHECKLISTDOCUMENTO_REQUEST:
      return { ...state, loading: true };
    case Types.GET_CHECKLISTDOCUMENTO_SUCCESS:
      return { ...state, loading: false, data: action.payload };
    case Types.UPLOAD_INFORMATION_REQUEST:
      return { ...state, loadingUpload: true };
    case Types.UPLOAD_INFORMATION_SUCCESS:
      return { ...state, loadingUpload: false };
    case Types.UPLOAD_IMAGE_RECORRENCIA_REQUEST:
      return { ...state, loadingUpload: true };
    case Types.UPLOAD_IMAGE_RECORRENCIA_SUCCESS:
      return { ...state, loadingUpload: false };
    case Types.SAVE_ITEM:
      return {
        ...state,
        data: {
          ...state.data,
          checkListDocumento: state.data.checkListDocumento.map(
            (checklist) => ({
              ...checklist,
              itens: checklist.itens.map((item) =>
                checklist.kitChecklist === action.payload.kitChecklist &&
                item.descricao === action.payload.item.descricao
                  ? action.payload.item
                  : item
              ),
            })
          ),
        },
      };
    default:
      return state;
  }
}

export const Creators = {
  updateChecklistdocumentoRequest: (params) => ({
    type: Types.UPDATE_CHECKLISTDOCUMENTO_REQUEST,
    payload: params,
  }),

  syncChecklistdocumentoRequest: (params) => ({
    type: Types.SYNC_CHECKLISTDOCUMENTO_REQUEST,
    payload: params,
  }),

  syncChecklistdocumentoSuccess: (params) => ({
    type: Types.SYNC_CHECKLISTDOCUMENTO_SUCCESS,
    payload: params,
  }),

  updateChecklistdocumentoSuccess: (data) => ({
    type: Types.UPDATE_CHECKLISTDOCUMENTO_SUCCESS,
    payload: data,
  }),

  getChecklistdocumentoRequest: (params) => ({
    type: Types.GET_CHECKLISTDOCUMENTO_REQUEST,
    payload: params,
  }),

  getChecklistdocumentoSuccess: (data) => ({
    type: Types.GET_CHECKLISTDOCUMENTO_SUCCESS,
    payload: data,
  }),

  uploadInformationRequest: (data) => ({
    type: Types.UPLOAD_INFORMATION_REQUEST,
    payload: data,
  }),

  uploadInformationSuccess: (data) => ({
    type: Types.UPLOAD_INFORMATION_SUCCESS,
    payload: data,
  }),

  uploadImageRecorrenciaRequest: (data) => ({
    type: Types.UPLOAD_IMAGE_RECORRENCIA_REQUEST,
    payload: data,
  }),

  uploadImageRecorrenciaSuccess: (data) => ({
    type: Types.UPLOAD_IMAGE_RECORRENCIA_SUCCESS,
    payload: data,
  }),

  save: (data) => ({
    type: Types.SAVE_ITEM,
    payload: data,
  }),
};
