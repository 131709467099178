import React, { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DialogContent } from "@material-ui/core";
import { Row, Col, Form as FormBootstrap } from "react-bootstrap";
import { Modal, DialogTitle } from "./styles/ModalUploadFile";
import { Grid } from "@material-ui/core";
import { toastr } from "react-redux-toastr";
import DropZone from "components/Dropzone";
import ButtonMui from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { Creators as TempStorageActions } from "store/ducks/tempstorage";
import { Creators as CheckListDocumentoActions } from "store/ducks/checklistdocumento";

function ModalUploadFile({ open, handleClose, item, setOpen, documentId }) {
  const dispatch = useDispatch();

  const isRecorrencia = !!item?.parent;

  const {
    loading: load,
    loadingStorage,
    storageId,
    countRequest,
    countSuccess,
  } = useSelector((state) => state.tempstorage);

  const { loading: loadingDocumento } = useSelector(
    (state) => state.documentos
  );

  useEffect(() => {
    if (!loadingDocumento) {
      setOpen(false);
    }
  }, [loadingDocumento]);

  const partialSuccess =
    !!storageId && countSuccess === countRequest && countSuccess > 0;

  const defaultTipoImagem =
    item?.tipoImagem?.length === 1 ? item.tipoImagem[0] : null;

  const [files, setFiles] = useState(null);
  const [storagesId, setStoragesId] = useState([]);
  const [imagem, setImagem] = useState(defaultTipoImagem);

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  

  const process = async () => {
    if (files.length === 0) return;

    const imagemFile = files[0];

    const { size, storageId, name } = imagemFile;

    const chunkSize = 1024 * 1024 * 10;
    const totalParts = Math.ceil(size / chunkSize);

    let start = 0;
    dispatch(TempStorageActions.clearRequest());
    for (let part = 1; part <= totalParts; part++) {
      let end = Math.min(start + chunkSize, imagemFile.size);
      if (part === totalParts) {
        end = imagemFile.size;
      }
      const chunk = imagemFile.slice(start, end);
      const type = imagemFile.name.split(".").at(-1);

      const formData = new FormData();
      formData.append("part", part);
      formData.append("filename", name);
      formData.append("size", chunk.size);
      formData.append("idDoc", documentId);
      formData.append("storageId", storageId);
      formData.append("tipoImagem", imagem?._id ?? defaultTipoImagem?._id);
      formData.append("totalParts", totalParts);
      formData.append(`file_${storageId}`, chunk);
      formData.append("type", type.toLowerCase());
      formData.append("contentType", type.toLowerCase());
      start = end;
      dispatch(TempStorageActions.newPartRequest(formData));
    }
    setStoragesId(oldStorageId => [...oldStorageId, imagemFile]);
  }

  useEffect(() => {
    const processFiles = async () => {
      if (partialSuccess) {
        console.log("processFiles ", files);
        const file = files[0];
        const storageId = file.storageId;

        dispatch(TempStorageActions.newStorageRequest({
          idDoc: documentId,
          itemchecklist: item,
          storageId: storageId,
          contentType: file.type,
          tipoImagem: imagem?._id ?? defaultTipoImagem?._id,
          type: file.path?.split(".").pop(),
        }));
        dispatch(TempStorageActions.clearRequest());
        setFiles([]);
        setStoragesId([]);
        setOpen(false);
      }
    };
    processFiles();
  }, [dispatch, storagesId, partialSuccess]);

  const onSubmit = async () => {
    try {
      if (!Array.isArray(files) || files.length !== 1) {
        return toastr.warning(
          "Ops",
          "É necessário selecionar um único arquivo."
        );
      }

      if (!imagem && !defaultTipoImagem)
        return toastr.warning("Ops", "É necessário selecionar um tipo imagem.");

      const file = files[0];
      const imageUpload = {};
      imageUpload.imagem = await toBase64(file);
      imageUpload.tipoImagem = imagem?._id || defaultTipoImagem?._id;
      imageUpload.tipoFile = file.type;
      imageUpload.fileName = file.name;

      const upload = {
        imagem: [imageUpload],
        idDoc: documentId,
        parent: item.parent,
        name: item.nome,
        itemchecklist: item,
      };

      if (isRecorrencia) {
        dispatch(
          CheckListDocumentoActions.uploadImageRecorrenciaRequest({ upload })
        );
        setFiles(null);
      } else {
        process();
      }
    } catch (err) {
      console.log(err.stack);
      return toastr.error("Ops", "Ocorreu um erro.");
    }
  };

  const onDeleteFile = () => {
    setFiles(null);
  };

  const generateObjectId = (
    m = Math,
    d = Date,
    h = 16,
    s = (s) => m.floor(s).toString(h)
  ) => s(d.now() / 1000) + " ".repeat(h).replace(/./g, () => s(m.random() * h));

  const onDrop = useCallback(
    (fileArray) => {
      if (fileArray.length > 1) {
        setFiles(null);
      } else {
        const file = fileArray[0];
        const allow = file.size < 20000000
        if (allow)
          setFiles(fileArray.map((file) => {
            file["storageId"] = generateObjectId();
            return file;
          }));
      }
    },
    [files]
  );

  return (
    <Modal open={open} className="small-modal">
      <div className="modal-header">
        <DialogTitle className="modal-title">{item?.nome}</DialogTitle>
      </div>
      <DialogContent>
        <Row className="mt-1">
          <Col md={12} className="m-1">
            <DropZone
              onDrop={onDrop}
              onDeleteFile={onDeleteFile}
              files={files}
              message=""
              warning=""
              style={{
                marginLeft: "2px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignmentItems: "center",
                width: "100%",
              }}
            />
          </Col>
        </Row>
        <Row className="ml-2 mr-2 mt-2">
          <Col md={12}>
            <Autocomplete
              options={item?.tipoImagem || []}
              getOptionLabel={(option) => option.nome}
              defaultValue={defaultTipoImagem}
              onChange={(e, value) => setImagem(value)}
              getOptionSelected={(option, value) => option._id === value._id}
              renderInput={(params) => (
                <TextField
                  required
                  {...params}
                  label="Tipo imagem"
                  size="small"
                  variant="outlined"
                />
              )}
            />
          </Col>
        </Row>
      </DialogContent>

      <DialogActions>
        <div className="buttons p-3">
          <Grid container spacing={1}>
            <Grid item>
              <ButtonMui
                onClick={() => onSubmit()}
                variant="contained"
                color="primary"
                type="submit"
                disabled={load || loadingDocumento || loadingStorage}
              >
                {loadingDocumento ? "Enviando..." : "Salvar"}
              </ButtonMui>
            </Grid>
            <Grid item>
              <ButtonMui
                onClick={() => setOpen(false)}
                variant="contained"
                color="secondary"
                type="reset"
              >
                Cancelar
              </ButtonMui>
            </Grid>
          </Grid>
        </div>
      </DialogActions>
    </Modal>
  );
}

export default ModalUploadFile;
