import React, { useState } from "react";

import "./styles.css";

export default function Zoom(props) {
  const [hovered, setHovered] = useState(false);
  const [style, setStyle] = useState({
    backgroundImage: `url(${props.imagem})`,
    backgroundPosition: "0% 0%",
  });

  const handleMouseMove = (e) => {
    const { left, top, width, height } = e.target.getBoundingClientRect();
    const x = ((e.pageX - left) / width) * 100;
    const y = ((e.pageY - top) / height) * 100 - 100;
    setStyle({
      backgroundImage: `url(${props.imagem})`,
      backgroundPosition: `${x}% ${y}%`,
    });
  };

  return (
    <>
      {props.imagem && (
        <div id="zoom">
          <img src={props.imagem} alt="imagem" />
        </div>
      )}
    </>
  );
}
