import React, { useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Creators as ExportacaoUnicActions } from "store/ducks/exportacaounic";

import { toastr } from "react-redux-toastr";
import { Row, Col } from "react-bootstrap";
import { Form } from "@unform/web";

import CircularProgress from "@material-ui/core/CircularProgress";

import BackButton from "components/BackButton";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Content from "components/Content";

export default function ExportacaoUNIC() {
  const dispatch = useDispatch();
  const formRef = useRef(null);

  const { data: exportacaoarquivo, loading } = useSelector(
    (state) => state.exportacaounic || {}
  );

  const onSubmit = async () => {
    try {
      dispatch(ExportacaoUnicActions.createRequest());
    } catch (err) {
      return toastr.error("Ops", "Ocorreu um erro.");
    }
  };

  return (
    <Content>
      <Row>
        <Col md="12" className="painel">
          <div className="painel-content">
            <div className="painel-title text-left">Exportação - UNIC</div>
            <div className="painel-body">
              <Form ref={formRef} onSubmit={onSubmit} className={"mt-2"}>
                <Col md="12" sm="12">
                  <Row>
                    {loading ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <CircularProgress color="primary" size={20} />
                      </div>
                    ) : (
                      <Grid container spacing={1}>
                        <Grid item>
                          <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            disabled={loading}
                          >
                            Exportar
                          </Button>
                        </Grid>
                        <Grid item>
                          <BackButton variant="contained" color="secondary">
                            Voltar
                          </BackButton>
                        </Grid>
                      </Grid>
                    )}
                  </Row>
                </Col>
              </Form>
            </div>
          </div>
        </Col>
      </Row>
    </Content>
  );
}
