import React, { useState, useEffect, useMemo } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { BeatLoader } from 'react-spinners';
import { Grid } from '@material-ui/core';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Icon from '@material-ui/core/Icon';
import AppBar from '@material-ui/core/AppBar';
import ButtonMui from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import ToolTipComponent from 'components/Tooltip';
import ButtonSquare from 'components/ButtonSquare';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TableEditable from '../../components/TableEditable';
import InputAdornment from '@material-ui/core/InputAdornment';
import TableContainer from '@material-ui/core/TableContainer';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { InfoIcon } from 'components/Icons';
import { Col, Row } from 'react-bootstrap';
import ModalUploadFile from './ModalCheckItemView/index';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import DialogPreviewImages from 'components/DialogPreviewImages';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

import { Creators as CheckListDocumentoActions } from 'store/ducks/checklistdocumento';
import { toastr } from 'react-redux-toastr';

import './styles.css';
import { getPresignedUrl } from 'services/presignedUrl';

const isDisabled = (item) => {
  return item.tipoItem === 'Quantitativo' || item.agrupamento;
};

const isAgrupamento = (item) => {
  return item.agrupamento;
};

const styleTabs = {
  boxShadow: 'none',
  border: '1px solid rgb(222, 226, 230)',
  borderRadius: '8px',
};

export default function Formalizacao({
  onSubmit,
  selectedDoc,
  itensModify,
  setItensModify,
}) {
  const dispatch = useDispatch();

  const [documentId, setDocumentId] = useState(selectedDoc._id);
  const [openPreviewImage, setOpenPreviewImage] = useState(false);
  const [filterTipo, setFilterTipo] = useState('');
  const [filterStatus, setFilterStatus] = useState('');
  const [tituloActive, setTituloActive] = useState(null);
  const [popupWindow, setPopupWindow] = useState(null);
  const [search, setSearch] = useState('');

  const [imagensPreview, setImagensPreview] = useState([]);

  const { loading, data } = useSelector((state) => state.checklistdocumento);
  const [itemUpload, setItemUpload] = useState(null);

  const useStyles = makeStyles({
    paper: {
      width: 500,
    },
  });

  function CustomPaper(props) {
    const classes = useStyles();
    return <Paper style={{ width: 600 }} {...props} />;
  }

  const titulos = useMemo(() => {
    if (data) {
      return data.map((item) => ({
        descricaoTitulo: item.descricaoTitulo,
        hashTitulo: item.hashTitulo,
        ordemTitulo: item.ordemTitulo,
      }));
    }
    return [];
  }, [data]);

  useEffect(() => {
    if (titulos.length > 0 && !tituloActive) {
      setTituloActive(titulos[0]);
    }
  }, [titulos, tituloActive]);

  const [open, setOpen] = useState(false);

  const isEditing = Array.isArray(itensModify) && itensModify.length > 0;

  useEffect(() => {
    setDocumentId(selectedDoc._id);
  }, [selectedDoc]);

  useEffect(() => {
    if (data?.length > 0) setTituloActive(data[0]);
  }, [data]);

  const onChangeTab = (active) => {
    if (itensModify.length === 0) return setTituloActive(active);
    toastr.warning(
      'É necessário salvar as alterações antes de prosseguir com a mudança de título'
    );
  };

  const onUpload = (item) => {
    setOpen(true);
    setItemUpload(item);
  };

  const onChange = (item, name, newValue) => {
    if (!item.parent) {
      const itemInEdition =
        itensModify.find((x) => x.hashItem === item.hashItem) || item;

      itemInEdition[name] = newValue;
      let copyItensModify = itensModify;
      copyItensModify = copyItensModify.filter(
        (x) => x.hashItem !== itemInEdition.hashItem
      );

      const isAllNull = itemInEdition.nodes?.every((element) => !element);
      if (isAllNull) itemInEdition.nodes = itemInEdition.recorrencias;

      copyItensModify.push(itemInEdition);

      setItensModify(copyItensModify);
    } else {
      item[name] = newValue;

      let itemInEdition = null;
      itemInEdition = itensModify.find((x) => x.id === item.id) || item;
      itemInEdition[name] = newValue;

      let copyItensModify = itensModify;
      copyItensModify = copyItensModify.filter(
        (x) => x.id !== itemInEdition.id
      );

      copyItensModify.push(itemInEdition);

      setItensModify(copyItensModify);
    }
  };

  const campoObservacao = (item) => {
    if (!isDisabled(item)) {
      return (
        <TextField
          name="observacao"
          variant="outlined"
          onChange={(event) => {
            onChange(item, 'observacao', event.target.value);
          }}
          defaultValue={item.observacao}
          InputLabelProps={{
            shrink: false,
          }}
        />
      );
    }
    return <></>;
  };

  const tipoCampo = (item) => {
    const value = item.motivo || '';

    if (isDisabled(item)) return <></>;

    if (item.tipoCampo === 'Combo') {
      return (
        <Autocomplete
          size="medium"
          multiple={true}
          options={item.optionsCombo || []}
          getOptionLabel={(option) => option}
          defaultValue={Array.isArray(value) ? value : [value]}
          onChange={(event, newValue) => {
            onChange(item, 'motivo', newValue);
          }}
          getOptionSelected={(option, value) => option === value}
          renderInput={(params) => (
            <TextField
              disabled={isDisabled(item)}
              {...params}
              label=""
              name="motivo"
              variant="outlined"
            />
          )}
          PaperComponent={CustomPaper}
        />
      );
    } else {
      return (
        <TextField
          disabled={isDisabled(item)}
          variant="outlined"
          name="motivo"
          defaultValue={item.motivo || ''}
          onChange={(event) => {
            onChange(item, 'motivo', event.target.value);
          }}
          InputLabelProps={{
            shrink: false,
          }}
        />
      );
    }
  };

  function handleShowAnexo(tipoImagem) {
    tipoImagem = Array.isArray(tipoImagem) ? tipoImagem : [tipoImagem];

    let images = [];
    for (const tipo of tipoImagem) {
      const image = selectedDoc.imagens.find(
        (imagem) => imagem.tipoImagem._id === tipo._id
      );

      images.push(image);
    }

    setImagensPreview(images);
    setOpenPreviewImage(images.length > 0);
  }

  function handleShowAnexoRecorrencia(filePath) {
    const image = selectedDoc.imagens.find(
      (imagem) => imagem.filepath === filePath
    );

    if (!image) return;

    setImagensPreview([image]);
    setOpenPreviewImage(true);
  }

  function renderPopupContent(image, win) {
    console.log(' ====> renderPopupContent');

    const checkReadyState = setInterval(async () => {
      if (win.document.readyState === 'complete') {
        console.log('====> complete');
        clearInterval(checkReadyState);

        const containerId = 'react-popup-container';
        let container = win.document.getElementById(containerId);
        if (!container) {
          console.log('====> !container');
          container = win.document.createElement('div');
          container.id = containerId;
          win.document.body.appendChild(container);
        }

        console.log('====> renderizar');

        const preview = await getPresignedUrl(image);
        // Renderiza o componente React dentro do container
        ReactDOM.render(
          // <PopupImage image={imageUrl} close={'null'} />,
          <iframe
            src={preview}
            title="Visualizador de documentos"
            width="100%"
            height="100%"
          />,
          container
        );
      }
    }, 100);
  }

  const handleShowAnexoOnPopup = (item) => {
    if (!item || !Array.isArray(item.tipoImagem) || !item.tipoImagem[0]) return;

    const image = selectedDoc.imagens.find(
      (imagem) => imagem.tipoImagem._id === item.tipoImagem[0]._id
    );

    console.log('image', image);

    if (image?.filepath) {
      if (!popupWindow || popupWindow.closed) {
        const newWindow = window.open('', '_blank', 'width=600,height=800');
        newWindow.document.title = 'Visualização de documento';

        // Utilize o callback para garantir que o estado seja atualizado antes de renderizar o conteúdo
        setPopupWindow(newWindow);

        const interval = setInterval(() => {
          if (newWindow.document.readyState === 'complete') {
            clearInterval(interval);
            renderPopupContent(image.filepath, newWindow);
          }
        }, 100);

        // setPopupWindow(newWindow, () => {
        //   renderPopupContent(image.filepath, newWindow);
        // });
      } else {
        // Se a janela já estiver aberta, simplesmente renderize o conteúdo
        renderPopupContent(image.filepath, popupWindow);
      }
    }
  };

  const COLUMNS_GRID_EDITABLE = [
    {
      label: 'Item checklist',
      renderCell: (item) => (
        <div
          className="itemChecklistName"
          onClick={() => handleShowAnexoOnPopup(item)}
        >
          <span>
            {item.nodes && item.nodes.length > 0 && <>📂</>}
            {item.parent && item.nodes?.length === 0 && <>🗒️</>}
            <>{item.nome}</>
            {item.obrigatorio && <span style={{ color: 'red' }}>*</span>}
          </span>
          {item.instrucoes && (
            <ToolTipComponent title={item.instrucoes} placement="top">
              <div>
                <InfoIcon />
              </div>
            </ToolTipComponent>
          )}
        </div>
      ),
    },
    {
      label: 'Status',
      renderCell: (item) =>
        !isAgrupamento(item) && (
          <FormControlLabel
            control={
              <Checkbox
                name="pendenteChecklist"
                defaultChecked={!item.pendenteChecklist}
                onChange={(event, newValue) =>
                  onChange(item, 'pendenteChecklist', !newValue)
                }
                color="primary"
              />
            }
            label=""
          />
        ),
      resize: {
        minWidth: 50,
        resizerWidth: 50,
      },
    },

    {
      label: 'Motivo',
      renderCell: (item) => tipoCampo(item),
      resize: {
        minWidth: 600,
      },
    },
    {
      label: 'Observação',
      renderCell: (item) => campoObservacao(item),
    },
    {
      label: 'Arquivos',
      renderCell: (item) =>
        !isAgrupamento(item) && (
          <div className="formalizacao-actions">
            {!item.recorrencia &&
              !item.parent &&
              !item.pendenteChecklist &&
              item.tipoImagem &&
              item.hasUpload && (
                <ButtonSquare
                  icon="visibility"
                  onClick={() => handleShowAnexo(item.tipoImagem)}
                />
              )}

            {item.parent && item.idImagem && item.filePath && (
              <ButtonSquare
                icon="visibility"
                onClick={() => handleShowAnexoRecorrencia(item.filePath)}
              />
            )}

            {!item.recorrencia && (
              <ButtonSquare
                icon="upload_cloud"
                onClick={() => onUpload(item)}
              />
            )}
          </div>
        ),
      align: 'right',
    },
  ];

  const makeRequest = (
    dispatch,
    documentId,
    filterStatus,
    filterTipo,
    search
  ) => {
    dispatch(
      CheckListDocumentoActions.getChecklistdocumentoRequest({
        documentId,
        filterStatus,
        filterTipo,
        search,
      })
    );
  };

  const handleChangeTipo = ({ target }) => {
    const { value } = target;
    setFilterTipo(value);
  };

  const handleChangeStatus = ({ target }) => {
    const { value } = target;
    setFilterStatus(value);
  };

  const handleChangeSearch = ({ target }) => {
    const { value } = target;
    setSearch(value);
  };

  useEffect(() => {
    if (!open)
      makeRequest(dispatch, documentId, filterStatus, filterTipo, search);
  }, [dispatch, open, documentId, filterStatus, filterTipo, search]);

  useEffect(() => {
    setItensModify([]);
  }, [dispatch, setItensModify, loading]);

  return (
    <>
      <div className="formalizacao-forms">
        <Row>
          <Col md={12}>
            <Grid container alignContent="center" justify="center">
              {!!loading && (
                <BeatLoader
                  sizeUnit={'px'}
                  size={10}
                  color={'#444444'}
                  loading={loading}
                />
              )}
            </Grid>
          </Col>
        </Row>
        {!loading && (
          <>
            <Row>
              <Col md="12">
                <div
                  className="controlpanel-header"
                  style={{ borderBottom: '1px solid #dee2e6' }}
                >
                  <div className="controlpanel-item comments-header-spacing">
                    <div className="controlpanel-item">
                      <TextField
                        disabled={isEditing}
                        label="Pesquisar"
                        variant="outlined"
                        size="small"
                        onBlur={handleChangeSearch}
                        defaultValue={search}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Icon className="text-20" color="action">
                                search
                              </Icon>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                  </div>

                  <div className="controlpanel-item comments-header-spacing">
                    <div className="controlpanel-item justify-content-center">
                      <FormControl component="fieldset" disabled={isEditing}>
                        <RadioGroup
                          row
                          aria-label="filter"
                          name="filter"
                          value={filterStatus}
                          onChange={handleChangeStatus}
                        >
                          <FormControlLabel
                            value={''}
                            control={<Radio color="primary" />}
                            label="Todos"
                          />
                          <FormControlLabel
                            value={'pendente'}
                            control={<Radio color="primary" />}
                            label="Pendente"
                          />
                          <FormControlLabel
                            value={'concluido'}
                            control={<Radio color="primary" />}
                            label="Concluídos"
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </div>

                  <div className="controlpanel-item comments-header-spacing">
                    <div className="controlpanel-item">
                      <TextField
                        disabled={isEditing}
                        size="small"
                        label="Tipo de item"
                        id="select"
                        variant="outlined"
                        value={filterTipo}
                        onChange={handleChangeTipo}
                        select
                      >
                        <MenuItem value={''}>Nenhum</MenuItem>
                        <MenuItem value={'Quantitativo'}>Quantitativo</MenuItem>
                        <MenuItem value={'Qualitativo'}>Qualitativo</MenuItem>
                      </TextField>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </>
        )}
        {!loading && tituloActive && data?.length > 0 && (
          <Row>
            <Col className="ml-4 mr-4 shadow-tab">
              <AppBar position="absolute" className="mt-2" style={styleTabs}>
                <Tabs
                  aria-label="Títulos"
                  value={tituloActive.hashTitulo}
                  variant="scrollable"
                  scrollButtons="on"
                >
                  {titulos.map((titulo) => (
                    <Tab
                      value={titulo.hashTitulo}
                      label={titulo.descricaoTitulo}
                      onClick={() => onChangeTab(titulo)}
                    />
                  ))}
                </Tabs>
              </AppBar>
              <Row className="mt-3">
                {!loading &&
                  data
                    ?.filter((x) => x.hashTitulo === tituloActive.hashTitulo)
                    ?.map((titulo, index) => (
                      <Col key={index} md={12}>
                        <TableContainer className="mb-3">
                          <TableEditable
                            columns={COLUMNS_GRID_EDITABLE}
                            nodes={titulo.itens}
                          />
                        </TableContainer>
                      </Col>
                    ))}
              </Row>
            </Col>
          </Row>
        )}
        {/* <Row>
        <Col className="mb-3 ml-3"> */}

        {/* </Col>
      </Row> */}

        <DialogPreviewImages
          canRemove={false}
          loadingRemove={false}
          open={openPreviewImage}
          imagens={imagensPreview}
          onClose={() => setOpenPreviewImage(false)}
        />

        <ModalUploadFile
          documentId={documentId}
          open={open}
          item={itemUpload}
          setOpen={setOpen}
        />
      </div>
    </>
  );
}
