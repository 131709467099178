import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as TipoDocumentoActions } from 'store/ducks/tiposdocumento';
import { MoonLoader } from 'react-spinners';
import { Row, Col } from 'react-bootstrap';
import { Form } from '@unform/web';
import { ValidationError } from 'yup';

import Grid from '@material-ui/core/Grid';
import ButtonMui from '@material-ui/core/Button';
import BackButton from 'components/BackButton';
import InputForm from 'components/InputForm';
import AutocompleteForm from 'components/AutocompleteForm';
import { FormControlLabel, Switch } from '@material-ui/core';

import TipoDocumentoSchema from 'validators/TipoDocumentoSchema';

import { Creators as EmpresasActions } from 'store/ducks/empresas';
import Content from 'components/Content';

export default function EditTiposDocumento(params) {
  const dispatch = useDispatch();
  const formRef = useRef();

  const { list } = useSelector((state) => state.empresas);
  const { loading } = useSelector((state) => state.tipoDocumentos);

  const { tiposDocs } = params.location.state;
  const { _id, ...initialData } = tiposDocs;

  const [status, setStatus] = useState(tiposDocs ? tiposDocs.status : false);
  const [useAssistant, setUseAssistant] = useState(
    tiposDocs ? tiposDocs.useAssistant : false
  );

  const handleCheckStatus = () => {
    setStatus(!status);
  };

  const handleCheckUseAssistant = () => {
    setUseAssistant(!useAssistant);
  };

  const onSubmitEdit = async (values) => {
    const body = {
      ...values,
      _id,
      status,
      useAssistant,
    };

    try {
      await TipoDocumentoSchema.validate(body, {
        abortEarly: false,
      });
      dispatch(TipoDocumentoActions.editRequest(body));
    } catch (err) {
      const validationErrors = {};
      if (err instanceof ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });

        formRef.current.setErrors(validationErrors);
      }
    }
  };

  useEffect(() => {
    dispatch(EmpresasActions.listRequest());
  }, [dispatch]);

  return (
    <Content>
      <Row>
        <Col md="12" className="painel">
          <div className="painel-content">
            <div className="painel-title text-left">
              Editar tipo de documento:
            </div>
            <div className="painel-body">
              {loading ? (
                <MoonLoader
                  sizeUnit={'px'}
                  size={50}
                  color={'#123abc'}
                  loading={loading}
                />
              ) : (
                tiposDocs && (
                  <Form
                    ref={formRef}
                    initialData={initialData}
                    onSubmit={onSubmitEdit}
                  >
                    <Row>
                      <Col md="12">
                        <AutocompleteForm
                          multiple
                          label="Empresas*"
                          name="empresas"
                          options={list}
                          transformOnSelect={(options) =>
                            options ? options.map((option) => option._id) : []
                          }
                          getOptionLabel={(option) => option.fantasia}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <InputForm label="Nome*" name="nome" />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <InputForm label="Descrição" name="descricao" />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <InputForm label="apikey ocr" name="apikey" />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormControlLabel
                          control={
                            <Switch
                              name="useAssistant"
                              onChange={handleCheckUseAssistant}
                              color="primary"
                              defaultChecked={
                                tiposDocs ? tiposDocs.useAssistant : false
                              }
                            />
                          }
                          label="Usar assistente"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormControlLabel
                          control={
                            <Switch
                              name="status"
                              onChange={handleCheckStatus}
                              color="primary"
                              defaultChecked={
                                tiposDocs ? tiposDocs.status : false
                              }
                            />
                          }
                          label="Ativo"
                        />
                      </Col>
                    </Row>

                    <Grid container spacing={1}>
                      <Grid item>
                        <ButtonMui
                          variant="contained"
                          color="primary"
                          type="submit"
                        >
                          Enviar
                        </ButtonMui>
                      </Grid>
                      <Grid item>
                        <ButtonMui
                          variant="contained"
                          color="secondary"
                          type="reset"
                        >
                          Limpar
                        </ButtonMui>
                      </Grid>
                      <Grid item>
                        <BackButton variant="contained" color="secondary">
                          Voltar
                        </BackButton>
                      </Grid>
                    </Grid>
                  </Form>
                )
              )}
            </div>
          </div>
        </Col>
      </Row>
    </Content>
  );
}
