import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Creators } from "store/ducks/template";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import { Col, Row } from "react-bootstrap";
import { Skeleton } from "@material-ui/lab";
import { MenuItem, TextField } from "@material-ui/core";

import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "ckeditor5-build-full";

import { Page, Container, Small } from "./styles/pageEditor";

export const TextEditorWithTemplate = forwardRef(
  ({ documento, onSelectTemplate, trigger, onTextChange, onHandleCheckBox, size, allOptions, hideTemplates }, ref) => {
    useImperativeHandle(ref, () => ({
      clearForm,
    }));

    console.log("TextEditorWithTemplate ", allOptions)

    const editorConfiguration = {
      htmlSupport: {
        allow: [
          {
            name: /.*/,
            attributes: true,
            classes: true,
            styles: true,
          },
        ],
      },
    };

    const { template: templateList, loading: loadingTemplate } = useSelector(
      (state) => state.template
    );
    const dispatch = useDispatch();

    const [stateCK5, setStateCK5] = useState(``);
    const [CK5Ref, setCK5Ref] = useState(null);
    const [selectedModelType, setSelectedModelType] = useState("");
    const styles =
      size !== "lg"
        ? {
          height: "300px",
          backgroundColor: "#e5ebff",
          marginBottom: "1rem",
          borderRadius: 5,
          paddingLeft: 10,
        }
        : {};

    const Wrapper = size === "lg" ? Page : Small;

    const templateModels = Array.from(
      new Set(templateList.map(({ modelType }) => modelType))
    );

    const replaceTemplateHtml = (value) => {
      const regex = /\{{ (.*?)\ }}/;
      while (regex.exec(value)) {
        const matched = regex.exec(value);
        value = value.replace(matched[0], getIndexadorById(matched[1]));
      }
      return value;
    };

    const getIndexadorById = (id_es) => {
      return (
        documento?.indexadores?.find((index) => index.id_es === id_es)?.valor ||
        ''//aqui - retornando branco para que, ao gerar documentos, não sejam gerados com o nome dos indexadores(o que é um problema no momento da assinatura por exemplo) - `@${id_es.replace("{{", "").replace("}}", "")}`
      );
    };

    const handleSelectTemplate = (value) => {
      const selected = templateList?.find((tpt) => tpt._id === value);
      if (selected) {
        let paragraphs = "";
        selected.paragraphTemplate?.map((x) => {
          paragraphs += x.paragraph + "<br>";
        });

        const html = replaceTemplateHtml(
          selected?.header + paragraphs + selected?.footer
        );

        setStateCK5(html);
        onSelectTemplate(html, [], selected._id)
      }
    };

    function clearForm() {
      setStateCK5("");
    }

    const handleEditorChangeCK5 = (event, editor) => {
      const data = editor.getData();
      setStateCK5(data);
      onTextChange(data);
    };

    const handleIsFocus = (editor) => {
      setCK5Ref(editor);
    };

    useEffect(() => {
      if (!trigger) return;
      clearForm();
    }, [trigger]);

    useEffect(() => {
      dispatch(Creators.getTemplate());
    }, []);
    return (
      <>
        {!hideTemplates && (
          <>
            {loadingTemplate ? (
              <Skeleton width="100%" height={56} animation="wave" />
            ) : (
              <TextField
                style={{
                  marginTop: "5px",
                  marginBottom: "20px",
                }}
                label="Selecione o modelo do template"
                variant="outlined"
                onChange={(e) => {
                  setSelectedModelType(e.target.value ?? "");
                }}
                defaultValue=""
                size="small"
                select
              >
                <MenuItem value={""}>Nenhum</MenuItem>
                {templateModels.map((modelType) => (
                  <MenuItem key={modelType} value={modelType}>
                    {modelType}
                  </MenuItem>
                ))}
              </TextField>
            )}

            {loadingTemplate ? (
              <Skeleton width="100%" height={56} animation="wave" />
            ) : (
              <TextField
                style={{
                  marginTop: "5px",
                  marginBottom: "20px",
                }}
                label="Selecione o template"
                variant="outlined"
                onChange={(e) => handleSelectTemplate(e.target.value)}
                defaultValue=""
                size="small"
                select
              >
                <MenuItem value={false}>Nenhum</MenuItem>
                {Array.from(
                  new Set(
                    templateList
                      ?.filter((item) => {
                        if (!selectedModelType) return true;

                        return item.modelType === selectedModelType;
                      })
                      ?.map((item) => (
                        <MenuItem key={item._id} value={item._id}>
                          {item.name}
                        </MenuItem>
                      ))
                  )
                )}
              </TextField>
            )}

          </>
        )}

        {!!allOptions &&
          <>
            <FormControlLabel
              label="Impressão na orientação retrato"
              control={
                <Checkbox
                  color="primary"
                  name="isPortrait"
                  defaultChecked={true}
                  onChange={(event, newValue) =>
                    onHandleCheckBox("isPortrait", newValue)
                  }
                />
              }
            />

            <FormControlLabel
              label="Adicionar imagens relacionadas ao template"
              control={
                <Checkbox
                  color="primary"
                  name="addImagesRelated"
                  onChange={(event, newValue) =>
                    onHandleCheckBox("addImagesRelated", newValue)
                  }
                />
              }
            />
          </>
        }

        <Row className="space-row mt-3">
          <Container style={{ width: "100%" }}>
            <Col className="mb-2">
              <Wrapper>
                <CKEditor
                  editorStyle={styles}
                  config={editorConfiguration}
                  sx={{ borderRadius: 1, minHeight: "450px" }}
                  data={stateCK5}
                  editor={ClassicEditor}
                  onChange={handleEditorChangeCK5}
                  onFocus={(event, editor) => handleIsFocus(editor)}
                />
              </Wrapper>
            </Col>
          </Container>
        </Row>
      </>
    );
  }
);
