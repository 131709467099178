import React, { useRef } from "react";
import { useDispatch } from "react-redux";
import { Creators as OcorrenciasActions } from "store/ducks/ocorrencias";

import _ from "lodash";

import GridCadastro from "components/GridCadastro";
import List from "./ListOcorrencias";

export const loadingTipo = true;
export const TYPES_LIST = [
  { value: 'Indisponibilidade', name: 'Indisponibilidade' },
  { value: 'Oscilação', name: 'Oscilação' },
];

export const loadingSistema = true;
export const SISTEM_LIST = [
  { value: 'PCR', name: 'PCR' },
  { value: 'WF NS Pagamento', name: 'WF NS Pagamento' },
  { value: 'BPO NS Formalização', name: 'BPO NS Formalização' },
  { value: 'BPO VWFS', name: 'BPO VWFS' },
  { value: 'Outro', name: 'Outro' },
];

export const loadingClassificao = true;
export const CLASSIFICATION_LIST = [
  { value: 'Alemanha', name: 'Alemanha' },
  { value: 'Externo', name: 'Externo' },
  { value: 'Interno', name: 'Interno' },
];

export const loadingEsteira = true;
export const PROCESS_LINE_LIST = [
  { value: 'Tratativa ', name: 'Tratativa ' },
  { value: 'Pagamento', name: 'Pagamento' },
  { value: 'SAP', name: 'SAP' },
  { value: 'Gravame', name: 'Gravame' },
  { value: 'Outro', name: 'Outro' },
]

export const STATUS_LIST = [
  { value: true, name: 'Aberto' },
  { value: false, name: 'Fechado' },
];

export function normalizeSchema(data) {
  return {
    ...data,
    tipo: data.tipo.value,
    sistema: data.sistema.value,
    classificacao: data.classificacao.value,
    esteira: data.esteira.value,
    status: data.status.value,
  }
}

export function handleDateTime(data, hora) {
  return hora ? `${data}T${hora}` : data
}

export function handleTime(time) {
  const [hora, minuto] = time.split(':');
  return hora ? `${hora}:${minuto}` : '';
}

export function localeDateStringToDate(date) {
  let arrayDate;
  if (!date) return date;
  if (date.includes("/")) arrayDate = date.split("/").reverse();
  else arrayDate = date.split("-");

  const year = parseInt(arrayDate[0]);
  const month = parseInt(arrayDate[1]);
  const day = parseInt(arrayDate[2]);

  const dateWithoutTimeZone = new Date(year, month - 1, day);
  const [data] = dateWithoutTimeZone.toISOString().split('T');
  return data;
}

export default function Ocorrencias() {
  const dispatch = useDispatch();
  const dispatchDebounce = useRef(_.debounce(dispatch, 500)).current;

  return (
    <GridCadastro
      title="Ocorrências"
      route={"/configuracao/ocorrencia/nova"}
      searchRequest={(value) => {
        dispatchDebounce(
          dispatch(
            OcorrenciasActions.listOcorrenciasRequest({
              page: 1,
              limit: 100,
              search: value,
            })
          )
        );
      }}
      titleButtonNovo="Nova Ocorrência"
    >
      <List />
    </GridCadastro>
  );
}
