export const Types = {
  LIST_REQUEST_DETAILS: "fila/LIST_REQUEST_DETAILS",
  LIST_SUCCESS_DETAILS: "fila/LIST_SUCCESS_DETAILS",
  EXPORT_REQUEST_DETAILS: "fila/EXPORT_REQUEST_DETAILS",
  EXPORT_SUCCESS_DETAILS: "fila/EXPORT_SUCCESS_DETAILS",
  EXPORT_TASKS_REQUEST_DETAILS: "fila/EXPORT_TASKS_REQUEST_DETAILS",
  EXPORT_TASKS_SUCCESS_DETAILS: "fila/EXPORT_TASKS_SUCCESS_DETAILS",
};

const INITIAL_STATE = {
  list: [],
  loading: false,
  loadingExport: false,
  loadingExportTasks: false,
  export: null,
  exportTasks: null,
  page: 1,
  limit: 10,
  total: 0,
  updatedAt: null,
};

export default function filasDetalhes(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.LIST_REQUEST_DETAILS:
      return { ...state, loading: true };

    case Types.LIST_SUCCESS_DETAILS:
      return { ...state, loading: false, ...action.payload };

    case Types.EXPORT_REQUEST_DETAILS:
      return { ...state, loadingExport: true, export: null };

    case Types.EXPORT_SUCCESS_DETAILS:
      return { ...state, loadingExport: false, export: action.payload };

    case Types.EXPORT_TASKS_REQUEST_DETAILS:
      return { ...state, loadingExportTasks: true, exportTasks: null };

    case Types.EXPORT_TASKS_SUCCESS_DETAILS:
      return { ...state, loadingExportTasks: false, exportTasks: action.payload };

    default:
      return state;
  }
}

export const Creators = {
  listDetailsRequest: (data) => ({
    type: Types.LIST_REQUEST_DETAILS,
    payload: data,
  }),
  listDetailsSuccess: (data) => ({
    type: Types.LIST_SUCCESS_DETAILS,
    payload: data,
  }),
  exportDetailsRequest: (data) => ({
    type: Types.EXPORT_REQUEST_DETAILS,
    payload: data,
  }),
  exportDetailsSuccess: (data) => ({
    type: Types.EXPORT_SUCCESS_DETAILS,
    payload: data,
  }),
  exportDetailsTasksRequest: (data) => ({
    type: Types.EXPORT_TASKS_REQUEST_DETAILS,
    payload: data,
  }),
  exportDetailsTasksSuccess: (data) => ({
    type: Types.EXPORT_TASKS_SUCCESS_DETAILS,
    payload: data,
  }),
};
