import React, { useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import BackButton from "components/BackButton";
import { Row, Col } from "react-bootstrap";
import { Form } from "@unform/web";
import InputForm from "components/InputForm";
import AutocompleteForm from "components/AutocompleteForm";
import { ValidationError } from "yup";

import { Creators as FilaActions } from "store/ducks/filas";

import FilaSchema from "validators/FilaSchema";

import "./styles.css";
import Content from "components/Content";

export default function EditFila(params) {
  const dispatch = useDispatch();

  const { filaSelecionada } = params.location.state;
  const { _id, empresa: empresaID, ...initialData } = filaSelecionada;

  const { list } = useSelector((state) => state.empresas);

  const formRef = useRef(null);

  const empresa = useMemo(() => list.find((emp) => emp._id === empresaID), [
    empresaID,
    list,
  ]);

  const onSubmitEdit = async (values) => {
    const body = { ...values, fila: _id };

    try {
      await FilaSchema.validate(body, {
        abortEarly: false,
      });
      dispatch(FilaActions.editRequest(body));
    } catch (err) {
      const validationErrors = {};
      if (err instanceof ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });

        formRef.current.setErrors(validationErrors);
      }
    }
  };

  return (
    <Content>
      <Row>
        <Col md="12" className="painel">
          <div className="painel-content">
            <div className="painel-title text-left">Editar Fila</div>
            <div className="painel-body">
              <Form
                ref={formRef}
                onSubmit={onSubmitEdit}
                initialData={{ empresa, ...initialData }}
              >
                <Row>
                  <Col>
                    <AutocompleteForm
                      name="empresa"
                      label="Empresa"
                      options={list}
                      transformOnSelect={(option) => option._id}
                      getOptionLabel={(option) => option.fantasia}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <InputForm name="descricao" label="Nome da Fila" />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <InputForm
                      name="sla"
                      label="SLA da Fila"
                      type="number"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Grid container spacing={1}>
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                        >
                          Enviar
                        </Button>
                      </Grid>
                      <Grid item>
                        <BackButton variant="contained" color="secondary">
                          Voltar
                        </BackButton>
                      </Grid>
                    </Grid>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        </Col>
      </Row>
    </Content>
  );
}
