import React, { useState, useEffect, useRef } from "react";

import Viewer from "bpmn-js/lib/NavigatedViewer";

import "./styles.css";

export default function BPMNDiagram({
  xml,
  setTaskDefinition,
  setEndEvent,
  fila,
  count,
  multiple,
  ...props
}) {
  const [loaded, setLoaded] = useState(false);

  const viewer = new Viewer({
    canvas: {
      deferUpdate: false,
    },
  });

  async function importXML(xml) {
    await viewer.importXML(xml);

    const canvas = viewer.get("canvas");

    const elementRegistry = viewer.get("elementRegistry")._elements;

    const elementNames = Object.keys(elementRegistry);

    var eventBus = viewer.get("eventBus");

    if (!multiple)
      for await (let element of elementNames) {
        if (elementRegistry[element].element.type === "bpmn:UserTask") {
          canvas.addMarker(element, "selectable");
        }
      }

    if (!setTaskDefinition) {
      return;
    }

    var events = [
      // "element.hover",
      // "element.out",
      "element.click",
      // "element.dblclick",
      // "element.mousedown",
      // "element.mouseup",
    ];

    if (multiple) {
      let multipleOptions = [];
      for await (let event of events) {
        eventBus.on(event, async function (e) {
          const { originalEvent, element } = e;
          const { ctrlKey } = originalEvent;
          const isAllow =
            element.type === "bpmn:UserTask" ||
            element.type === "bpmn:EndEvent";
          const etapa = e.element.id;
          if (!ctrlKey) multipleOptions = [];
          if (isAllow) {
            const isSelected = viewer.get("selection").isSelected(element);
            if (isSelected) {
              canvas.addMarker(element, "selectable");
              multipleOptions.push(etapa);
            } else {
              canvas.removeMarker(element, "selectable");
              multipleOptions = multipleOptions.filter(function (element) {
                return element !== etapa;
              });
            }
          }
          setTaskDefinition(multipleOptions);
          setEndEvent(false);
        });
      }
    } else {
      for await (let event of events) {
        eventBus.on(event, function (e) {
          // e.element = the model element
          // e.gfx = the graphical element

          const { element } = e;

          if (element.type === "bpmn:UserTask") {
            setTaskDefinition(e.element.id);
            setEndEvent(false);
          }

          if (element.type === "bpmn:EndEvent") {
            setTaskDefinition(fila);
            setEndEvent(true);
          }
        });
      }
    }

    canvas.resized();
    canvas.zoom("fit-viewport", "auto");

    if (count) {
      var overlays = viewer.get("overlays");

      const keys = Object.keys(count);

      for await (let key of keys) {
        try {
          overlays.add(key, {
            position: {
              bottom: 10,
              right: 10,
            },
            html: `<div class="countTask" title="Total de instâncias">${count[key]}</div>`,
          });
        } catch (error) {
          console.log("Não foi possível adicionar a contagem de instâncias", error.message);
        }

      }
    }

    setLoaded(true);

    // viewer.importXML(xml, async (err) => {
    //   const canvas = viewer.get("canvas");

    //   var eventBus = viewer.get("eventBus");

    //   // you may hook into any of the following events
    //   var events = [
    //     // "element.hover",
    //     // "element.out",
    //     "element.click",
    //     // "element.dblclick",
    //     // "element.mousedown",
    //     // "element.mouseup",
    //   ];

    //   let selectedTaskDef = "";

    //   for await (let event of events) {
    //     eventBus.on(event, function (e) {
    //       // e.element = the model element
    //       // e.gfx = the graphical element

    //       const { element } = e;

    //       if (element.type === "bpmn:UserTask") {
    //         selectedTaskDef = e.element.id;
    //       }
    //     });
    //   }

    //   console.log(selectedTaskDef);

    //   canvas.resized();
    //   canvas.zoom("fit-viewport", "auto");

    //   var overlays = viewer.get("overlays");

    //   // attach an overlay to a node
    //   // overlays.add("go_outside", {
    //   //   position: {
    //   //     bottom: 0,
    //   //     right: 0,
    //   //   },
    //   //   html: "<div>Mixed up the labels?</div>",
    //   // });

    //   setLoaded(true);
    // });
  }

  useEffect(() => {
    viewer.attachTo("#bpmviewer");
    importXML(xml);
  }, [xml]);

  return (
    <div className="BPMNDiagram" id="bpmviewer">
      {loaded &&
        props.children &&
        React.cloneElement(props.children, { viewer })}
    </div>
  );
}
