import React, { useRef, useState } from 'react';

import DynamicInputMask from "components/DynamicInputMask";
import { WrapSensibleFlag } from 'components/SensibleFlag';
import { Autocomplete } from '@material-ui/lab';
import { handleBlurForceEnter, handlePasteTexts, validContent } from 'utils/utils';
import { TextField } from '@material-ui/core';

function FieldDynamicMask({
    indexador,
    checkReadOnly,
    handleChangeIndexador,
    hasIndexadorError,
    hasIndexadorTextError,
    searching = false
}) {
    const [value, setValue] = useState(indexador.valor || "")
    const textFieldRef = useRef(null);

    function handleValueChange(newValue, prev) {
        validContent(prev, newValue, indexador.tipo, indexador.nome, (value) => {
            handleChangeIndexador({
                currentTarget: { value },
                target: { value }
            });
            setValue(value);
        })
    }

    function onChange(event) {
        handleValueChange(event?.currentTarget?.value);
    }

    async function onPaste() {
        handlePasteTexts(handleValueChange, value);
    }

    return (
        <WrapSensibleFlag indexador={indexador}>
            {searching
                ? <Autocomplete
                    innerRef={textFieldRef}
                    multiple
                    freeSolo
                    clearOnBlur
                    selectOnFocus
                    disabled={checkReadOnly ? indexador.somenteLeitura : false}
                    id={`generic-${indexador.nome}`}
                    name={indexador.nome}
                    value={value}
                    options={[]}
                    onPaste={onPaste}
                    onBlur={() => handleBlurForceEnter(textFieldRef)}
                    onChange={(_, value) =>
                        onChange({
                            currentTarget: { value },
                            target: { value }
                        })
                    }
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={indexador.nome}
                            variant="outlined"
                            nme="opcoes"
                        />
                    )}
                    getOptionLabel={(option) =>
                        typeof option === "string" ? option : option.nome
                    }
                    renderOption={(option) =>
                        typeof option === "string" ? option : option.nome
                    }
                    size="small"
                />
                : <DynamicInputMask
                    disabled={checkReadOnly ? indexador.somenteLeitura : false}
                    mask={indexador.mascara}
                    onChange={(event, second) => {
                        handleChangeIndexador(event, second)
                        setValue(event?.currentTarget?.value?.replace(/\D/g, ""))
                    }}
                    value={value}
                    name={indexador.nome}
                    label={indexador.nome}
                    error={hasIndexadorError(indexador)}
                    helperText={hasIndexadorTextError(indexador)} />

            }
        </WrapSensibleFlag>
    );
}

export default FieldDynamicMask;