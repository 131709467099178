import React from "react";
import { Row, Col } from "react-bootstrap";
import Button from "@material-ui/core/Button";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";

function ModalView({ open, setOpenDocView, b64 }) {
  return (
    <Row>
      <Col>
        <Dialog open={open} maxWidth="lg" fullWidth>
          <DialogTitle className="header-modal">Visualizar PDF</DialogTitle>
          <DialogContent>
            <Row>
              <Col style={{minHeight: "800px"}}>
                <iframe
                  src={`data:application/pdf;base64,${b64}`}
                  width="100%"
                  height="100%"
                  title="Visualizador de imagens"
                />
              </Col>
            </Row>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpenDocView(false)}
            >
              Voltar
            </Button>
          </DialogActions>
        </Dialog>
      </Col>
    </Row>
  );
}

export default ModalView;
