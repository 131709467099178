import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Creators as RespostaActions } from "store/ducks/respostaOrgaos";

import AutocompleteForm from "components/AutocompleteForm";
import InputForm from "components/InputForm";
import { Skeleton } from "@material-ui/lab";
import CheckboxForm from "components/CheckboxForm";
import { useStyles } from "pages/Agenda";
import { Button } from "@material-ui/core";
import { Form } from "@unform/web";
import FormEnviarDocumento from "pages/Analise/components/Comentarios/FormEnviarDocumento";
import { TextEditorWithTemplate } from "components/TextEditorWithTemplate";

const TERMO_SINDEC = 'SINDEC';
const TERMO_CIP_ELETRONICA = "CIP Eletrônica";

function RespostaOrgaos({ documento, children }) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const style = {
        marginTop: "5px",
        marginBottom: "20px",
    };

    const formRef = useRef();
    const [document, setDocument] = useState(documento);
    const [enviaResposta, setEnviaResposta] = useState(false);
    const [resposta, setResposta] = useState('');

    const INITIAL_FORM = {
        orgao: "",
        tipo: "",
        gerarPdf: false,
        resposta: "",
        descricaoDosArquivos: "",
    };

    const [sending, setSending] = useState(false);
    const [enable, setEnable] = useState(false);
    const [loadingTipo] = useState(false);
    const [tipoManifestacoesList, setTipoManifestacoesList] = useState([]);

    const { orgaos: orgaosList, tipoManifestacoesList: lista } = useSelector((state) => state.respostaOrgaos);

    //#region efeitos
    useEffect(() => {
        clearForm();
        setDocument(documento);
    }, [documento]);

    useEffect(() => { dispatch(RespostaActions.listOrgaosRequest()) }, [document]);
    useEffect(() => { setTipoManifestacoesList(lista) }, [lista]);
    //#endregion

    //#region funções auxiliares
    function onChangeOrgao(e, orgao) {
        if (orgao === TERMO_SINDEC) dispatch(RespostaActions.listOpcoesRequest({ orgao }));
    }

    function onChangeTipoManifestacao(e, tipo) {
        setEnviaResposta(tipo === TERMO_CIP_ELETRONICA)
    }

    function canSubmit() {
        const { orgao, tipo, gerarPdf, descricaoDosArquivos } = formRef.current.getData();

        const pdfs = (!gerarPdf || descricaoDosArquivos.length > 0);

        const canSub = !sending
            && orgao.length > 0
            && tipo.length > 0
            && resposta.length > 0
            && pdfs;
        return canSub;
    }

    function clearForm() {
        setResposta('');
        setEnable(false);
    }

    async function handleSubmit() {

        if (!canSubmit()) return;

        const form = formRef.current.getData()

        setSending(true);

        const body = {
            ...form
            , resposta
            , documentId: document._id.toString()
            , descricaoDosArquivos: form.gerarPdf ? form.descricaoDosArquivos : ''
        };

        dispatch(RespostaActions.createRequest(body));

        clearForm();

        setSending(false);
    }

    const handleSelectTemplate = (value) => {
        setResposta(value);
    };

    //#endregion

    return (
        <div style={{ marginBottom: "1rem" }}>

            <Form
                ref={formRef}
                onSubmit={handleSubmit}
                initialData={{
                    ...INITIAL_FORM,
                }}
            >
                {/* <AutocompleteForm
                    required
                    name="orgao"
                    label="Órgão"
                    options={orgaosList || []}
                    transformOnSelect={(option) => option}
                    getOptionLabel={(option) => option}
                    disabled={sending}
                    onChange={onChangeOrgao}
                    style={style}
                />

                {loadingTipo ? (
                    <Skeleton width="100%" height={50} />
                ) : (
                    <AutocompleteForm
                        style={style}
                        name="tipo"
                        label="Tipo de manifestação"
                        options={tipoManifestacoesList}
                        transformOnSelect={(option) => option}
                        getOptionLabel={(option) => option}
                        disabled={sending}
                        onChange={onChangeTipoManifestacao}
                    />
                )} */}

                {
                    enviaResposta
                        ?
                        <>

                            <TextEditorWithTemplate
                                size={"lg"}
                                documento={documento}
                                onSelectTemplate={(texto) => handleSelectTemplate(texto)}
                                name='resposta'
                                style={style}
                                ref={formRef}
                                onTextChange={(texto) =>
                                    setResposta(texto)
                                }
                            />

                            <CheckboxForm
                                label="Gerar PDF"
                                name="gerarPdf"
                                style={style}
                                disabled={sending}
                                onChange={(e) => setEnable(e.target.checked)}
                            />

                            <InputForm
                                required
                                style={style}
                                label="Descrição dos arquivos"
                                name="descricaoDosArquivos"
                                disabled={!enable || sending} multiline minRows={4}
                            />

                            <div className={classes.actions}>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    type="submit"
                                    disabled={sending}
                                    className={classes.newCommentButton}
                                    children={sending ? "Gravando..." : "Gravar"}
                                />
                                {children}
                            </div>

                        </>

                        : <FormEnviarDocumento documento={document}>{children}</FormEnviarDocumento>
                }

            </Form>

        </div>
    );
}

export default RespostaOrgaos;
