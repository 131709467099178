
import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import {
    Button
    , Checkbox
    , Grid
    , List
    , ListItem
    , ListItemIcon
    , ListItemText
    , Paper,
    Typography
} from "@material-ui/core";
import { SearchInput } from "components/SearchInput";

//#region funções ciliares
function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}
//#endregion

export const TransferList = ({
    title = ''
    , list = []
    , titleList = ''
    , value = []
    , titleValue = ''
    , placeholder = ''
    , onChange = () => { }
}) => {

    const [checked, setChecked] = useState([]);
    const [right, setRight] = useState(value);
    const [left, setLeft] = useState([]);

    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);

    //#region funções ciliares
    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const handleAllRight = () => {
        setRight(right.concat(left));
        setLeft([]);
    };

    const handleCheckedRight = () => {
        setRight(right.concat(leftChecked));
        setLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
    };

    const handleCheckedLeft = () => {
        setLeft(left.concat(rightChecked));
        setRight(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
    };

    const handleAllLeft = () => {
        setLeft(left.concat(right));
        setRight([]);
    };
    //#endregion

    //#region componentes auxiliares
    const TButton = ({ label, disabled, onClick, children }) => (
        <Button
            sx={{ my: 0.5 }}
            color="secondary"
            variant="outlined"
            size="small"
            onClick={onClick}
            disabled={disabled}
            aria-label={label}
        >
            {children}
        </Button>
    )

    function CustomList(items, title, id, placeholder) {

        const [filtro, setFiltro] = useState([]);
        const margin = 16;

        const onChange = (texto) => {
            if (!texto.length) {
                setFiltro(items);
                return;
            }
            setFiltro(filtro
                .filter(grupo =>
                    grupo.name.toUpperCase().includes(texto.toUpperCase()))
            );
        };

        useEffect(() => { setFiltro(items) }, [items]);

        return (
            <>
                <div style={{ margin: 16 }}>{title}</div>

                <SearchInput
                    id={id}
                    margin={margin}
                    placeholder={placeholder}
                    onChange={onChange}
                />

                <Paper style={{
                    margin,
                    height: 230,
                    overflow: 'auto',
                    border: '1px solid #D5D8DC',
                }}>

                    <List
                        dense
                        component="div"
                        role="list"
                    >
                        {filtro.map((value) => {
                            const { name } = value;
                            const labelId = `transfer-list-item-${value}-label`;
                            return (
                                <ListItem
                                    key={value}
                                    role="listitem"
                                    button
                                    onClick={handleToggle(value)}
                                >
                                    <ListItemIcon>
                                        <Checkbox
                                            checked={checked.indexOf(value) !== -1}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{
                                                'aria-labelledby': labelId,
                                            }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText id={labelId} primary={`${name}`} />
                                </ListItem>
                            );
                        })}
                    </List>
                </Paper>
            </>
        )
    };
    //#endregion

    useEffect(() => { onChange(right) }, [onChange, right]);
    useEffect(() => {
        const listafiltrada = list.filter(objList =>
            !right.some(objRight => objRight._id === objList._id)
        );
        setLeft(listafiltrada);
    }, [list, right]);

    return (
        <div>

            <Typography variant="h5" style={{ marginTop: 8, marginBottom: 8 }} >{title}</Typography>

            <Grid
                container
                spacing={1}
                justifyContent="center"
                alignItems="center"
            >

                <Col lg={5}>
                    {CustomList(left, titleList, 'left', placeholder)}
                </Col>

                <Col>
                    <Grid container direction="column" alignItems="center">
                        <TButton
                            onClick={handleAllRight}
                            disabled={left.length === 0}
                            label="mover todos para direita"
                        >
                            ≫
                        </TButton>
                        <TButton
                            onClick={handleCheckedRight}
                            disabled={leftChecked.length === 0}
                            label="mover selecionados para direita"
                        >
                            &gt;
                        </TButton>
                        <TButton
                            onClick={handleCheckedLeft}
                            disabled={rightChecked.length === 0}
                            label="mover selecionados para esquerda"
                        >
                            &lt;
                        </TButton>
                        <TButton
                            onClick={handleAllLeft}
                            disabled={right.length === 0}
                            label="mover todos para esquerda"
                        >
                            ≪
                        </TButton>
                    </Grid>
                </Col>

                <Col lg={5}>
                    {CustomList(right, titleValue, 'right', placeholder)}
                </Col>

            </Grid>

        </div>
    )
}
