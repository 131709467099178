import * as Icons from 'react-feather';

export const MENU_ICONS = {
  admin: Icons.Globe,
  checklist: Icons.CheckSquare,
  config: Icons.Settings,
  configdoc: Icons.File,
  configesteira: Icons.Settings,
  configimg: Icons.Image,
  departamento: Icons.Box,
  distributed: Icons.Share2,
  empresa: Icons.Briefcase,
  home: Icons.Home,
  monitoramento: Icons.Monitor,
  operacoes: Icons.Clipboard,
  indeximg: Icons.Image,
  steps: Icons.CheckSquare,
  usuarios: Icons.Users,
  calendarrio: Icons.Calendar,
};

export const MENU_ITEM_DATA = [
  {
    title: "Página Inicial",
    icon: "operacoes",
    shortName: "Início",
  },
  {
    title: "Administrativo",
    icon: "admin",
    shortName: "",
  },
  {
    title: "Operações",
    icon: "operacoes",
    shortName: "",
  },
  {
    title: "Configurações",
    icon: "config",
    shortName: "",
  },
  {
    title: "Calendário",
    icon: "calendario",
    shortName: "Calen.",
  },
];

export const ITEM_HEIGHT = 48;
