import api from 'services/api';
import { getToken } from 'services/auth';
import { call, select, put, all, takeLatest } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';

import { Creators as BloqueiosActions, Types } from 'store/ducks/bloqueios';

export const getOrganization = state => state.usuarios.auth.organization;

export function* listBloqueios({ payload }) {
  const organization = yield select(getOrganization);

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
      params: payload,
    };

    const { data } = yield call(api.get, 'bloqueios', headerParams);

    yield put(BloqueiosActions.listSuccess(data.docs, data.total));
  } catch (error) {
    toastr.error('Ops', 'Não foi possível listar ao bloqueio(s)/garantia(s).');
  }
}

export default all([
  takeLatest(Types.LIST_REQUEST, listBloqueios)
])