import api from 'services/api';
import { getToken } from 'services/auth';
import { call, select, put, all, takeLatest } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';

import { Creators as PartesActions, Types } from 'store/ducks/partes';

export const getOrganization = state => state.usuarios.auth.organization;

export function* listPartes({ payload }) {
  const organization = yield select(getOrganization);

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
      params: payload,
    };

    const { data } = yield call(api.get, 'partes', headerParams);

    yield put(PartesActions.listSuccess(data.docs, data.total));
  } catch (error) {
    toastr.error('Ops', 'Não foi possível listar as partes.');
  }
}

export function* countManifestationTotal({ payload }) {
  const organization = yield select(getOrganization);

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const { data } = yield call(api.get, `partes/manifestation-total/${payload}`, headerParams);

    yield put(PartesActions.totalManifestationNumberSuccess(data));
  } catch (error) {
    toastr.error('Ops', 'Não foi possível listar as partes.');
  }
}

export default all([
  takeLatest(Types.LIST_REQUEST, listPartes),
  takeLatest(Types.COUNT_TOTAL_MANIFESTATION_REQUEST, countManifestationTotal)
])