import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row, Form } from 'react-bootstrap';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import useForm from 'react-hook-form';
import { parse, isValid } from 'date-fns';

import { LabelSwapper } from '../../../components/LabelSwapper';
import { valida_cnpj, valida_cpf } from 'services/validadorCpfCNPJ';

import DefaultPanel from '../../../components/DefaultPanel';
import DynamicTextField from './DynamicTextField';

import { Creators as EmpresasActions } from 'store/ducks/empresas';
import { Creators as DepartamentosActions } from 'store/ducks/departamentos';
import { Creators as TipoDocumentosActions } from 'store/ducks/tiposdocumento';
import { Creators as DocumentosActions } from 'store/ducks/documentos';
import { Creators as ComarcasActions } from 'store/ducks/comarca';
import { Creators as UsuariosActions } from 'store/ducks/usuarios';
import { Creators as AssessoriaActions } from 'store/ducks/assessoria';
import { Creators as TipoImagemActions} from 'store/ducks/tipoimagem'

import './styles.css';
import { PesquisaAvancadaSchema } from 'validators/PesquisaAvancadaSchema';
import IndexerFormFieldFactory from 'components/FormFieldIndexer/IndexerFormFieldFactory';
import Utils from 'utils/utils';

export default function SearchForm({ setCollapse, collapse, cpfCnpj, date }) {
  const { register, handleSubmit, setValue, watch, errors, reset } = useForm({
    validationSchema: PesquisaAvancadaSchema,
    defaultValues: {
      cpfCNPJ: cpfCnpj,
    },
  });

  const myForm = useRef(null);
  const submitButtonRef = useRef(null);

  const dispatch = useDispatch();

  const [empresasSelecionadas, setEmpresasSelecionadas] = useState([]);
  const [UF, setUF] = useState('');
  const [open, setOpen] = useState(false);
  const [tipoPessoa, setTipoPessoa] = useState(null);
  const [tipoDocumentoSelecionado, setTipoDocumentoSelecionado] = useState(null);
  const [idsTipoImagem, setIdsTipoImagem] = useState([]); 

  const handleIndexadoresData = indexadorData => {
    const result = [];
    for (const indexador of indexadorData) {
      const newIndexadorStart = { ...indexador };
      const newIndexadorEnd = { ...indexador };
      newIndexadorStart.id_es += '___start';
      newIndexadorEnd.id_es += '___end';
      newIndexadorStart.nome += ' (Período Inicial)';
      newIndexadorEnd.nome += ' (Período Final)';
      result.push(newIndexadorStart);
      result.push(newIndexadorEnd);
    }

    return result;
  };

  const [indexadoresTipoDocumento, setIndexadoresTipoDocumento] = useState([]);

  const indexadores = tipoDocumentoSelecionado?.indexadores || [];
  const indexadoresData = handleIndexadoresData(indexadores?.filter(indexador => indexador.tipo === 'data'));
  const indexadoresSemData = indexadores?.filter(indexador => indexador.tipo !== 'data');

  const { list: empresasList } = useSelector(state => state.empresas);
  const { listByEmpresas: tipoDocList } = useSelector(state => state.tipoDocumentos);
  let tiposimagem = useSelector((state) => state.tipoImagem.list);


  let {
    limit = 10,
    loading,
    page = 1,
    sort = 'idEmpresa.razaoSocial',
  } = useSelector(state => state.documentos.advSearch);
  const {
    access: { empresas },
  } = useSelector(state => state.permissoes);

  useEffect(() => {
    if (UF.length === 0) {
      return;
    }
    dispatch(
      ComarcasActions.listRequest({
        uf: UF,
        page: 1,
        limit: Number.MAX_SAFE_INTEGER,
      })
    );
  }, [UF]);

  useEffect(() => {
    if (Utils.isNullOrUndefined(cpfCnpj)) {
      return;
    }

    setValue('cpfCNPJ', cpfCnpj);
    submitButtonRef.current.click();
  }, [cpfCnpj, date]);

  useEffect(() => {
    dispatch(EmpresasActions.listRequest());
    // dispatch(DepartamentosActions.listRequest());
    dispatch(TipoDocumentosActions.listByEmpresaRequest(empresas));
    dispatch(
      UsuariosActions.listRequest({
        empresas,
        page: 1,
        limit: Number.MAX_SAFE_INTEGER,
      })
    );
    dispatch(
      AssessoriaActions.listRequest({
        page: 1,
        limit: Number.MAX_SAFE_INTEGER,
      })
    );
  }, [dispatch, empresas]);

  useEffect(() => {
    register({ name: 'idEmpresa' });
    register({ name: 'idTipoDocumento' });
    register({ name: 'idTipoImagem' });
    register({ name: 'idDepartamento' });
    register({ name: 'causaRaiz' });
    register({ name: 'tipoPessoa' });
    register({ name: 'nome' });
    register({ name: 'cpfCNPJ' });
    register({ name: 'createdAt' });
    register({ name: 'dataCaptura' });
    register({ name: 'status' });
    register({ name: 'numeroCNJ' });
    register({ name: 'UF' });
    register({ name: 'bloqueio' });
    register({ name: 'advogadoInterno' });
    register({ name: 'advogadoExterno' });
    register({ name: 'analista' });
    register({ name: 'dentroDoPrazoSLA' });
    register({ name: 'createdAtStart' });
    register({ name: 'createdAtEnd' });
    register({ name: 'updatedAtStart' });
    register({ name: 'updatedAtEnd' });
    register({ name: 'externalDateAtStart' });
    register({ name: 'externalDateAtEnd' });
  }, [register]);

  const handleUF = ({ target }) => {
    const { value, name } = target;
    setValue(name, value);
    setUF(value);
  };

  const handleSelectChange = async (field, key, value) => {
    if (!value && field === 'tipoPessoa') {
      setTipoPessoa(null);
      setValue('tipoPessoa', null);
    }

    if(field === 'idTipoImagem'){
      setIdsTipoImagem(value)
      return
    }

    if (value && field === 'tipoPessoa') {
      setValue('tipoPessoa', value.tipo);
      setTipoPessoa(value.tipo);

      setValue('nome', []);

      setValue('cpfCNPJ', []);
    } else if (value && ['status', 'bloqueio', 'dentroDoPrazoSLA'].includes(field)) {
      setValue(field, value.tipo);
    } else if (value && field === 'bloqueio') {
      setValue('bloqueio', value.tipo);
    } else {
      if (value && field === 'idTipoDocumento') {
        setValue('idTipoImagem', []);
        setIdsTipoImagem([]);
        setTipoDocumentoSelecionado(value);
        setIndexadoresTipoDocumento([]);
        await listImages(value._id);
        setValue(field, value[key]);
        return;
      } else if (!value && field === 'idTipoDocumento') {
        setTipoDocumentoSelecionado(null);
        setIndexadoresTipoDocumento([]);
        setIdsTipoImagem([]);
        setValue('idTipoImagem', []);
      }

      const formattedArrayValue = value
        ? value.map(item => {
            return item[key];
          })
        : [];

      if (field === 'idEmpresa') {
        setEmpresasSelecionadas(value);
      }

      setValue(field, formattedArrayValue ? formattedArrayValue : null);
    }
  };

  const onSubmit = async values => {
    
    const cnj = document.getElementsByName('numeroCNJ')[0].value;
    const data = {
      ...values,
      numeroCNJ: cnj,
      idsTipoImagem: idsTipoImagem.map(imagem => imagem._id),
      indexadoresTipoDocumento,
      cpfCNPJ: watch('cpfCNPJ'),
    };

    
    await PesquisaAvancadaSchema.validate(values, { abortEarly: false });

    let keys = Object.keys(data);
    for (let key of keys) {
      if (data[key] === undefined || data[key] === '' || data[key].length === 0) {
        delete data[key];
      }
    }

    dispatch(
      DocumentosActions.advancedSearchResultRequest(data, {
        limit,
        page,
        sort,
      })
    );
  };

  const listImages = async (id) => {
    dispatch(TipoImagemActions.listByTipoDocumentoRequest(id));
  };

  const handleInputChange = (tipo, field, value) => {
    if (tipo === 'cpf') {
      const cpfsValidos = value.filter(cpf => valida_cpf(cpf));
      setValue(field, cpfsValidos);
    } else if (tipo === 'cnpj') {
      const cnpjsValidos = value.filter(cnpj => valida_cnpj(cnpj));
      setValue(field, cnpjsValidos);
    } else if (tipo === 'data') {
      const datasValidas = value.filter(data => isValid(parse(data, 'dd/MM/yyyy', new Date())));
      setValue(field, datasValidas);
    } else {
      setValue(field, value);
    }
  };

  function handleChange({ target }) {
    const { value, name } = target;
    setValue(name, value);
  }

  const clearForm = () => {
    reset();
    setValue('numeroCNJ', '');
    setValue('createdAtStart', undefined);
    setValue('createdAtEnd', undefined);

    dispatch(DocumentosActions.advancedSearchResultSClear());
    const autocompletes = document.getElementsByClassName('MuiAutocomplete-clearIndicator');

    for (var i = 0; i < autocompletes.length; i++) autocompletes[i].click();
  };

  const handlePaste = (tipo, mascara, e) => {
    e.preventDefault();

    const valueOfMask = e.clipboardData.getData('Text').split(';');

    const value = valueOfMask.map(item => {
      if (tipo !== 'data' && (!mascara || mascara === '')) return item;

      const mask = tipo === 'data' ? '11/11/1111' : mascara;

      let index2 = 0;
      let serializeItem = [];
      [...mask].forEach((char, index) => {
        if (char === '1' || char === 'a') {
          serializeItem[index] = item[index2];
          index2 += 1;
        } else {
          serializeItem[index] = char;
          if (item[index] === char) index2 += 1;
        }
      });
      return serializeItem.join('');
    });

    if (tipo === 'cpf') {
      const cpfsValidos = value.filter(cpf => valida_cpf(cpf));
      setValue(e.target.name, [...cpfsValidos, ...(watch(e.target.name) || [])]);
    } else if (tipo === 'cnpj') {
      const cnpjsValidos = value.filter(cnpj => valida_cnpj(cnpj));
      setValue(e.target.name, [...cnpjsValidos, ...(watch(e.target.name) || [])]);
    } else if (tipo === 'data') {
      const datasValidas = value.filter(data => isValid(parse(data, 'dd/MM/yyyy', new Date())));
      setValue(e.target.name, [...datasValidas, ...(watch(e.target.name) || [])]);
    } else {
      const data = [...value, ...(watch(e.target.name) || [])];
      setValue(e.target.name, data);
    }
  };

  const groupClick = group => {
    const empresas = empresasList.filter(emp => emp.grupoEmpresarial.nome === group);
    if (empresas.some(emp => !empresasSelecionadas.includes(emp))) {
      setEmpresasSelecionadas([...empresasSelecionadas.filter(emp => !empresas.includes(emp)), ...empresas]);
    } else {
      setEmpresasSelecionadas(empresasSelecionadas.filter(emp => !empresas.includes(emp)));
    }
    const empresasIds = empresas.map(emp => emp._id);
    setValue('idEmpresa', empresasIds ? empresasIds : null);
    setOpen(false);
  };

  const renderGroup = params => [
    <div onClick={() => groupClick(params.group)} key={params.key} className="grupo-title-autocomplete">
      {params.group}
    </div>,
    params.children,
  ];

  const handleChangeIndexador = (indexador, v) => {
    setIndexadoresTipoDocumento(prev => {
      if (!v?.target?.value) {
        return prev.filter(({ nome }) => indexador.nome !== nome);
      }

      const index = prev.findIndex(indexadorAntigo => indexadorAntigo.nome === indexador.nome && indexador.nome);

      if (index !== -1) {
        prev[index] = {
          ...prev[index],
          valor: v?.target?.value,
        };

        return prev;
      }

      return [
        ...prev,
        {
          valor: v?.target?.value,
          tipo: indexador.tipo,
          nome: indexador.nome,
        },
      ];
    });
  };

  return (
    <Row>
      <DefaultPanel titleText="Filtrar pesquisa" isCollapsible={true} collapse={collapse} setCollapse={setCollapse}>
        <Row>
          <Col md={12}>
            <Form ref={myForm} onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <Col md={12}>
                  <Autocomplete
                    open={open}
                    onOpen={() => setOpen(true)}
                    onClose={() => setOpen(false)}
                    multiple
                    id="combo-box-empresas"
                    options={
                      empresasList &&
                      empresasList.sort((a, b) =>
                        b.grupoEmpresarial && a.grupoEmpresarial && b.grupoEmpresarial.nome && a.grupoEmpresarial.nome
                          ? -b.grupoEmpresarial.nome.localeCompare(a.grupoEmpresarial.nome)
                          : b.grupoEmpresarial && !a.grupoEmpresarial
                          ? -1
                          : 1
                      )
                    }
                    groupBy={option => (option.grupoEmpresarial ? option.grupoEmpresarial.nome : '')}
                    renderGroup={renderGroup}
                    getOptionLabel={option => option.razaoSocial}
                    getOptionSelected={(option, value) => option._id === value._id}
                    onChange={(e, value) => handleSelectChange('idEmpresa', '_id', value)}
                    renderInput={params => (
                      <TextField {...params} label="Grupo Empresarial/Empresa" variant="outlined" />
                    )}
                    size="small"
                    value={empresasSelecionadas}
                  />
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  <TextField
                    name="numeroCNJ"
                    label={<LabelSwapper text={'Nº do cliente'} parent={'SearchForm'} name={'numeroCNJ'} />}
                    onChange={handleChange}
                    autoComplete="off"
                    size="small"
                    variant="outlined"
                  />
                </Col>
              </Row>

              <Row></Row>
              <Row>
                <Col md={6}>
                  <Autocomplete
                    id="combo-box-tipodocumento"
                    options={tipoDocList}
                    getOptionLabel={option => option.nome}
                    getOptionSelected={(option, value) => option._id === value._id}
                    onChange={(e, value) => {
                      handleSelectChange('idTipoDocumento', '_id', value);
                    }}
                    renderInput={params => <TextField {...params} label="Tipo de documento" variant="outlined" />}
                    size="small"
                  />
                </Col>
                <Col md={6}>
                  <Autocomplete
                    multiple={true}
                    id="combo-box-tipoimagem"
                    options={tiposimagem}
                    getOptionLabel={option => option.nome}
                    getOptionSelected={(option, value) => option._id === value._id}
                    value={idsTipoImagem}
                    onChange={(e, value) => {
                      handleSelectChange('idTipoImagem', '_id', value);
                    }}
                    disabled={!tipoDocumentoSelecionado}
                    renderInput={params => <TextField {...params} label="Tipo de imagem" variant="outlined" />}
                    size="small"
                  />
                </Col>
              </Row>

              {tipoDocumentoSelecionado && (
                <Row>
                  <Col md={12} className="docTypeFields">
                    <Typography variant="h6" gutterBottom>
                      Filtros por indexadores do tipo de documento:
                      {tipoDocumentoSelecionado.nome}
                    </Typography>
                    <Divider />
                  </Col>
                  {indexadoresData &&
                    indexadoresData
                      .filter(indexador => indexador.esteira) //indexar
                      .map(indexador => (
                        <Col key={indexador.id_es} md={6} className="esteira-indexador">
                          <IndexerFormFieldFactory
                            searching={false}
                            fieldToAdd={null}
                            genericParams={{
                              indexador,
                              checkReadOnly: false,
                              hasIndexadorError: () => {},
                              hasIndexadorTextError: () => {},
                              handleChangeIndexador: v => handleChangeIndexador(indexador, v),
                            }}
                            identificadorUnicoParams={{}}
                          />
                        </Col>
                      ))}
                  {indexadoresSemData &&
                    indexadoresSemData
                      .filter(indexador => indexador.esteira) //indexar
                      .map(indexador => (
                        <Col key={indexador.id_es} md={6} className="esteira-indexador">
                          <IndexerFormFieldFactory
                            searching={true}
                            fieldToAdd={null}
                            genericParams={{
                              indexador,
                              checkReadOnly: false,
                              hasIndexadorError: () => {},
                              hasIndexadorTextError: () => {},
                              handleChangeIndexador: v => handleChangeIndexador(indexador, v),
                            }}
                            identificadorUnicoParams={{}}
                          />
                        </Col>
                      ))}
                </Row>
              )}
              {tipoPessoa && (
                <Row>
                  <Col md={12} className="docTypeFields">
                    <Typography variant="h6" gutterBottom>
                      Dados da pessoa {tipoPessoa === 'FISICA' ? 'física' : 'jurídica'}
                    </Typography>
                    <Divider />
                    <Row>
                      {tipoPessoa === 'FISICA' ? (
                        <Col md={6} className="field">
                          <Autocomplete
                            freeSolo={true}
                            multiple
                            autoSelect
                            selectOnFocus
                            onChange={(e, newValue) => handleInputChange('cpf', 'cpfCNPJ', newValue)}
                            options={[]}
                            renderInput={params => (
                              <>
                                <DynamicTextField
                                  {...params}
                                  tipo="cpf"
                                  mascara="111.111.111-11"
                                  label="CPF"
                                  name={'cpfCNPJ'}
                                  onPaste={e => handlePaste('cpf', '111.111.111-11', e)}
                                  variant="outlined"
                                />
                              </>
                            )}
                            getOptionLabel={option => (typeof option === 'string' ? option : option.inputValue)}
                            filterOptions={(options, params) => (options.length === 0 ? [] : options)}
                            renderOption={option => (typeof option === 'string' ? option : option.title)}
                            size="small"
                            value={watch('cpfCNPJ') || []}
                          />
                        </Col>
                      ) : (
                        <Col md={6} className="field">
                          <Autocomplete
                            freeSolo={true}
                            multiple
                            autoSelect
                            selectOnFocus
                            onChange={(e, newValue) => handleInputChange('cnpj', 'cpfCNPJ', newValue)}
                            options={[]}
                            renderInput={params => (
                              <>
                                <DynamicTextField
                                  {...params}
                                  tipo="cnpj"
                                  mascara="11.111.111/1111-11"
                                  label="CNPJ"
                                  name={'cpfCNPJ'}
                                  onPaste={e => handlePaste('cnpj', '11.111.111/1111-11', e)}
                                  variant="outlined"
                                />
                              </>
                            )}
                            getOptionLabel={option => (typeof option === 'string' ? option : option.inputValue)}
                            filterOptions={(options, params) => (options.length === 0 ? [] : options)}
                            renderOption={option => (typeof option === 'string' ? option : option.title)}
                            size="small"
                            value={watch('cpfCNPJ') || []}
                          />
                        </Col>
                      )}
                      <Col md={6} className="field">
                        <Autocomplete
                          freeSolo={true}
                          multiple
                          autoSelect
                          selectOnFocus
                          onChange={(e, newValue) => handleInputChange('caracter', 'nome', newValue)}
                          options={[]}
                          renderInput={params => (
                            <>
                              <DynamicTextField
                                {...params}
                                tipo="caracter"
                                mascara=""
                                label={tipoPessoa === 'FISICA' ? 'Nome' : 'Razão social'}
                                name={'nome'}
                                onPaste={e => handlePaste('caracter', null, e)}
                                variant="outlined"
                              />
                            </>
                          )}
                          getOptionLabel={option => (typeof option === 'string' ? option : option.inputValue)}
                          filterOptions={(options, params) => (options.length === 0 ? [] : options)}
                          renderOption={option => (typeof option === 'string' ? option : option.title)}
                          size="small"
                          value={watch('nome') || []}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              )}
              <Row>
                <Col className="search-form-buttons">
                  <Button type="submit" variant="contained" color="primary" disabled={loading} ref={submitButtonRef}>
                    Pesquisar
                  </Button>
                  <Button onClick={e => clearForm()} variant="contained" color="secondary" type="reset">
                    Limpar
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </DefaultPanel>
    </Row>
  );
}
