import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Row } from "react-bootstrap";

import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CircularProgress from "@material-ui/core/CircularProgress";

import { Creators as TemplateActions } from "store/ducks/template";

function ModalDeleteConfirmationTemplate({ handleClose }) {
  const dispatch = useDispatch();

  const { loading, openDelete, templateToDelete } = useSelector(
    (state) => state.template
  );

  function handleClose() {
    if (!loading) dispatch(TemplateActions.closeDelete());
  }

  async function handleConfirm() {
    if (templateToDelete?.template) {
      dispatch(TemplateActions.deleteRequest(templateToDelete?.template?._id));
    }
  }

  return (
    <Dialog open={openDelete} onClose={handleClose}>
      <DialogTitle>Remover template de e-mail</DialogTitle>
      <DialogContent>
        <DialogActions>
          <Row>
            <p>
              Tem certeza que deseja remover o template:{" "}
              {templateToDelete?.template?.name}?
            </p>

            <DialogActions>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                onClick={handleConfirm}
              >
                {!!loading && (
                  <>
                    <CircularProgress color="primary" size={20} />
                    &nbsp;
                  </>
                )}
                Confirmar
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleClose}
              >
                Cancelar
              </Button>
            </DialogActions>
          </Row>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}

export default ModalDeleteConfirmationTemplate;
