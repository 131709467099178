import React, { useRef, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Form } from "@unform/web";
import Grid from "@material-ui/core/Grid";
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from "@material-ui/core/Button";

import Skeleton from "@material-ui/lab/Skeleton";
import { useDispatch, useSelector } from "react-redux";

import {
  FormHeader,
  FormRow,
  QueueDiagram,
} from './styles/FormTarefa';

import InputForm from "components/InputForm";
import BackButton from "components/BackButton";
import BPMViewer from "components/BPMViewer";

import AutocompleteForm from "components/AutocompleteForm";
import TipoTarefaSchema from "validators/TipoTarefaSchema";
import CheckboxForm from "components/CheckboxForm";

import { Creators as TiposTarefasActions } from "store/ducks/tipostarefas";

import { Creators as FilaActions } from "store/ducks/filas";
import { Creators as EtapasActions } from "store/ducks/etapas";

import { ValidationError } from "yup";
import Content from "components/Content";

function FormTarefa({ location }) {
  const dispatch = useDispatch();
  const formRef = useRef();

  const { access: { filas, empresas } } = useSelector(state => state.permissoes);
  const { xml, loading: etapasLoading } = useSelector(state => state.etapas);
  const { list: filasList, loading: filasLoading } = useSelector(state => state.filas);

  const { tarefa } = location.state || {};

  const [selectedFila, setSelectedFila] = useState(null);

  useEffect(() => {
    dispatch(FilaActions.listFluxoRequest({ filas, empresa: empresas }));
  }, [dispatch, empresas, filas]);

  async function handleSubmit(data) {
    try {
      await TipoTarefaSchema.validate(data, {
        abortEarly: false,
      });

      const body = {
        ...data,
        _id: tarefa ? tarefa._id : null,
      };

      dispatch(TiposTarefasActions.saveRequest(body));
    } catch (err) {
      const validationErrors = {};
      if (err instanceof ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });

        formRef.current.setErrors(validationErrors);
      }
    }
  }

  function handleChangeFila(e, fila) {
    if (fila) {
      setSelectedFila(fila);
      dispatch(EtapasActions.listRequest({ fila: fila.key }));
    }
  }

  function handleInputChange(event, newInputValue, reason) {
    if (reason === 'clear' || reason === 'reset') {
      setSelectedFila(null);
    }
  }

  return (
    <Content>
      <Row>
        <Col md="12" className="painel">
          <div className="painel-content">
            <div className="painel-title text-left">Tarefa</div>
            <div className="painel-body">
              <Form
                ref={formRef}
                initialData={tarefa}
                onSubmit={handleSubmit}>
                <FormHeader>
                  <FormRow>
                    <InputForm name="nome" label="Nome da natureza" />

                    <InputForm
                      name="prazo"
                      label="Prazo em dias"
                      type="number"
                      inputProps={{
                        inputMode: 'numeric',
                        pattern: '[0-9]*',
                        min: 0,
                      }} />
                  </FormRow>

                  {filasLoading ? (
                    <Skeleton width="100%" height={50} />
                  ) : (
                    <AutocompleteForm
                      options={filasList}
                      label="Fila"
                      name="fila"
                      value={selectedFila}
                      transformOnSelect={option => option ? option.key : null}
                      getOptionLabel={option => option.name}
                      onChange={handleChangeFila}
                      onInputChange={handleInputChange}
                    />)}

                  <CheckboxForm
                    name="restricaoEncerramento"
                    label="Restrição de Encerramento"
                  />
                </FormHeader>
                <QueueDiagram>
                  {etapasLoading ? (
                    <CircularProgress size={88} />
                  ) : (
                    xml && selectedFila && <BPMViewer xml={xml} />
                  )}

                </QueueDiagram>

                <Grid container spacing={1}>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                    >
                      Enviar
                    </Button>
                  </Grid>
                  <Grid item>
                    <BackButton variant="contained" color="secondary">
                      Voltar
                    </BackButton>
                  </Grid>
                </Grid>
              </Form>
            </div>
          </div>
        </Col>
      </Row>
    </Content>
  );
}

export default FormTarefa;
