import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from '@unform/web';
import { ValidationError } from 'yup';
import { parseISO, format } from 'date-fns';

import { Col, Row } from 'react-bootstrap';

import Grid from '@material-ui/core/Grid';
import ButtonMui from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem"
import BackButton from "components/BackButton";
import AutocompleteForm from "components/AutocompleteForm";
import InputForm from "components/InputForm";
import SelectForm from "components/SelectForm";
import CheckboxForm from "components/CheckboxForm";
import { CNPJ, CPF } from "components/Masks";

import { toastr } from "react-redux-toastr";

import EmpresaSchema from "validators/EmpresaSchema";

import { Creators as EmpresasActions } from 'store/ducks/empresas';
import { Creators as GruposEmpresariaisActions } from 'store/ducks/gruposempresariais';

import Currency from "components/Masks/Currency";

import { InputAdornment } from '@material-ui/core';

import './styles.css';
import Content from 'components/Content';

export default function EditEmpresas(params) {

    const dispatch = useDispatch();
    const formRef = useRef(null);

    const usuarioID = useSelector(state => state.usuarios.auth.id);
    const { list: gruposEmpresariais } = useSelector(state => state.gruposempresariais);

    const [valueErrorEmail, setValueErrorEmail] = useState(false);

    function validaEmail(email) {

        let reg = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;

        const retorno = reg.test(email);

        if (retorno === true) {
            return false
        } else {
            return true;
        }

    };

    const handleDestinatario = (e) => {

        if (e.target.value) {

            setValueErrorEmail(validaEmail(e.target.value));

        }

    };


    const { Empresa: empresa } = params.location.state;
    const {
        _id, dataAssinatura: dateTimeDataAssinatura,
        dataReajuste: dateTimeDataReajuste,
        dataEncerramento: dateTimeDataEncerramento,
        ...initialData
    } = empresa;

    const dataAssinatura = useMemo(() =>
        dateTimeDataAssinatura ? format(parseISO(dateTimeDataAssinatura), 'yyyy-MM-dd') : ''
        , [dateTimeDataAssinatura]);

    const dataReajuste = useMemo(() =>
        dateTimeDataReajuste ? format(parseISO(dateTimeDataReajuste), 'yyyy-MM-dd') : ''
        , [dateTimeDataReajuste]);

    const dataEncerramento = useMemo(() =>
        dateTimeDataEncerramento ? format(parseISO(dateTimeDataEncerramento), 'yyyy-MM-dd') : ''
        , [dateTimeDataEncerramento]);

    useEffect(() => {
        dispatch(GruposEmpresariaisActions.listRequest({
            page: 1,
            limit: Number.MAX_SAFE_INTEGER
        }))
    }, [dispatch])

    const onSubmitEdit = async values => {

        if (valueErrorEmail === true) {
            toastr.error('Ops', 'E-mail de cadastro inválido.')
            return;
        };

        const body = { ...values, usuarioAlteracao: usuarioID, _id }
        try {
            await EmpresaSchema.validate(body, {
                abortEarly: false
            })

            dispatch(EmpresasActions.editRequest(body));
        } catch (err) {
            toastr.error(err.message);
            const validationErrors = {};
            if (err instanceof ValidationError) {
                err.inner.forEach(error => {
                    validationErrors[error.path] = error.message;
                })

                formRef.current?.setErrors(validationErrors);
            }
        }

    }

    return (
        <Content>
            <Row>
                <Col md="12" className="painel">
                    <div className="painel-content">
                        <div className="painel-title text-left">Editar Empresa</div>
                        <div className="painel-body">

                            <Form initialData={{ ...initialData, dataAssinatura, dataReajuste, dataEncerramento }} onSubmit={onSubmitEdit}>
                                <Row className="row">
                                    <Col>
                                        <AutocompleteForm
                                            label="Grupo Empresarial*"
                                            name="grupoEmpresarial"
                                            options={gruposEmpresariais}
                                            transformOnSelect={option => option._id}
                                            getOptionLabel={option => option.nome}
                                        />
                                    </Col>
                                    <Col>
                                        <InputForm
                                            label="Razao Social*"
                                            name="razaoSocial"
                                            disabled
                                        />
                                    </Col>
                                    <Col>
                                        <InputForm
                                            label="Tipo Pessoa*"
                                            name="tipoPessoa"
                                            disabled
                                        />
                                    </Col>
                                    <Col>
                                        <InputForm
                                            label="Fantasia*"
                                            name="fantasia"
                                            disabled={!!empresa.fantasia}
                                        />
                                    </Col>
                                </Row>
                                {empresa.tipoPessoa === 'Física' &&
                                    <Row>
                                        <Col>
                                            <InputForm
                                                label="CPF"
                                                name="cpf"
                                                InputProps={{
                                                    inputComponent: CPF
                                                }}
                                                disabled={!!empresa.cpf}
                                            />
                                        </Col>
                                        <Col>
                                            <InputForm
                                                label="RG"
                                                name="rg"
                                            />
                                        </Col>
                                    </Row>
                                }
                                {empresa.tipoPessoa === 'Jurídica' &&
                                    <Row>
                                        <Col>
                                            <InputForm
                                                label="Inscrição Estadual"
                                                name="inscricaoEstadual"
                                            />
                                        </Col>
                                        <Col>
                                            <InputForm
                                                label="Inscrição Municipal"
                                                name="inscricaoMunicipal"
                                            />
                                        </Col>
                                        <Col>
                                            <InputForm
                                                label="CNPJ"
                                                name="cnpj"
                                                InputProps={{
                                                    inputComponent: CNPJ
                                                }}
                                                disabled={!!empresa.cnpj}
                                            />
                                        </Col>
                                    </Row>
                                }
                                <Row>
                                    <Col>
                                        <InputForm
                                            label="Data de Assinatura"
                                            name="dataAssinatura"
                                            type="date"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Col>
                                    <Col>
                                        <InputForm
                                            label="Data de Reajuste"
                                            name="dataReajuste"
                                            type="date"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Col>
                                    <Col>
                                        <InputForm
                                            label="Data de Encerramento"
                                            name="dataEncerramento"
                                            type="date"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Col>
                                    <Col>
                                        <InputForm
                                            label="Data de Inicio do Fechamento"
                                            type="number"
                                            name="dataInicioFechamento"
                                            InputProps={
                                                { inputProps: { min: 1, max: 30 } }
                                            }
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <InputForm
                                            label="Data de Fim do Fechamento"
                                            type="number"
                                            name="dataTerminoFechamento"
                                            InputProps={
                                                { inputProps: { min: 1, max: 30 } }
                                            }
                                        />
                                    </Col>
                                    <Col>
                                        <InputForm
                                            label="Data de Emissão da Nota"
                                            type="number"
                                            name="dataEmissaoNota"
                                            InputProps={
                                                { inputProps: { min: 1, max: 30 } }
                                            }
                                        />
                                    </Col>
                                    <Col>
                                        <InputForm
                                            label="Data de Vencimento da Nota"
                                            type="number"
                                            name="dataVencimentoNota"
                                            InputProps={
                                                { inputProps: { min: 1, max: 30 } }
                                            }
                                        />
                                    </Col>
                                    <Col>
                                        <InputForm
                                            type="email"
                                            name="emailFatura"
                                            label="Email Fatura*"
                                            onChange={(e) => handleDestinatario(e)}
                                            error={valueErrorEmail}
                                            helperText={valueErrorEmail === true ? "Email Destinatário inválido." : ""}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <InputForm
                                            name="faturamentoMinimo"
                                            label="Faturamento Mínimo"
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                                                inputComponent: Currency
                                            }}

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Col>
                                    <Col>
                                        <InputForm
                                            type="number"
                                            name="limiteSenhasRepetidas"
                                            label="Limite de Senhas Repetidas"
                                        />
                                    </Col>
                                    <Col>
                                        <InputForm
                                            type="number"
                                            name="limiteSenhasIncorretas"
                                            label="Limite de Senhas Incorretas"
                                        />
                                    </Col>
                                    <Col>
                                        <InputForm
                                            type="number"
                                            name="vencimentoSenha"
                                            label="Vencimento Senha"
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <InputForm
                                            name="timeOut"
                                            label="Timeout (Segundos)"
                                            type="number"
                                            InputProps={
                                                { inputProps: { min: 1 } }
                                            }
                                        />
                                    </Col>
                                    <Col>
                                        <SelectForm
                                            name="status"
                                            label="Status*"
                                        >
                                            <MenuItem value={true}>Ativo</MenuItem>
                                            <MenuItem value={false}>Inativo</MenuItem>
                                        </SelectForm>
                                    </Col>
                                    <Col>
                                        <SelectForm
                                            name="tipoPagamento"
                                            label="Tipo de Pagamento*"
                                        >
                                            <MenuItem value="Depósito Bancário">Depósito Bancário</MenuItem>
                                            <MenuItem value="Boleto">Boleto</MenuItem>
                                        </SelectForm>
                                    </Col>
                                    <Col>
                                        <Row>
                                            <Col>
                                                <CheckboxForm
                                                    label="Bloqueio Inatividade"
                                                    name="bloqueioInatividade"
                                                />
                                            </Col>
                                            <Col>
                                                <CheckboxForm
                                                    label="Acesso Simultaneo"
                                                    name="acessoSimultaneo"
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md={2}>
                                        <InputForm className="mt-0" name="codigo" label="Código" />
                                    </Col>
                                </Row>

                                <Grid container spacing={1}>
                                    <Grid item  >
                                        <ButtonMui
                                            variant="contained"
                                            color="primary"
                                            type="submit">Enviar</ButtonMui>
                                    </Grid>

                                    <Grid item >
                                        <BackButton
                                            variant="contained"
                                            color="secondary">Voltar</BackButton>
                                    </Grid>
                                </Grid>

                            </Form>
                        </div>
                    </div>
                </Col>
            </Row>
        </Content>
    )
}
