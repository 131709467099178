export const FIELD_TYPES = {
  ID_UNIQUE: "id-unique",
  DYNAMIC_MASK: "dynamic-mask",
  COMBO: "combo",
  DATA: "data",
  CNPJ: "cnpj",
  CPF: "cpf",
  GENERIC: "generic",
  TABELA: "tabela",
};
