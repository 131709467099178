import styled, { css, keyframes } from "styled-components/macro";

import { default as MenuItemMaterial } from "@material-ui/core/MenuItem";
import { default as ListItemTextMaterial } from "@material-ui/core/ListItemText";


import { default as PopoverMaterial } from "@material-ui/core/Popover";

const confirmationTimer = keyframes`
    to {
        width: 0%;
    }
`;

export const Container = styled(PopoverMaterial)`
`;

export const MenuItem = styled(MenuItemMaterial)`
  position: relative;
  ${({ confirmation }) =>
    confirmation &&
    css`
      background-color: #e06969 !important;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        background-color: #00000033;
        width: 100%;
        height: 100%;
        animation: ${confirmationTimer} 2s forwards;
      }
    `};

  ${({ loading }) =>
    loading &&
    css`
      pointer-events: none;
    `};

`;


export const ListItemText = styled(ListItemTextMaterial)`
  border-bottom: none
`;