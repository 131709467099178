import React, { useEffect, useRef, useState } from 'react';
import {
  Container,
  Header,
  FormContainer,
  Form,
  Field,
  PreviewButtons,
} from './style';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';

import { Creators as OnboardingActions } from 'store/ducks/onboarding';
import TwoFactorInput from './twofactor';

export default function Verificacao({ form }) {
  const dispatch = useDispatch();
  const { id } = useParams();

  const {
    twofactor: { formCode, codigo_verificado },
  } = useSelector((state) => state.onboarding);

  const [formData, setFormData] = useState({
    // Inicialize com valores vazios para cada campo
    nome: '',
    email: '',
    formId: id,
    // Adicione mais campos conforme necessário
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    // Atualize o estado com o novo valor
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!formData.nome || !formData.email) {
      toastr.error('Erro', 'Preencha todos os campos');
      return;
    }

    dispatch(OnboardingActions.generateCodeRequest(formData));
  };

  const handleTwoFactorSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <>
      {form && (
        <Container $background={form.layout.background}>
          {form.layout.logo && (
            <Header>
              <img src={form.layout.logo} alt="logo" />
            </Header>
          )}
          <FormContainer>
            {formCode && !codigo_verificado ? (
              <>
                <TwoFactorInput form={form} />
              </>
            ) : (
              <>
                {' '}
                <h4>Preencha o formulário:</h4>
                <Form onSubmit={handleSubmit}>
                  <Field>
                    <input
                      type="text"
                      placeholder="Nome"
                      name="nome"
                      onChange={handleChange}
                    />
                  </Field>
                  <Field>
                    <input
                      type="text"
                      placeholder="Email"
                      name="email"
                      onChange={handleChange}
                    />
                  </Field>
                  <PreviewButtons $layout={form.layout.buttons}>
                    <button type="submit">Enviar</button>
                  </PreviewButtons>
                </Form>
              </>
            )}
          </FormContainer>
        </Container>
      )}
    </>
  );
}
