import api from "services/api";
import { getToken } from "services/auth";
import { call, select, put, all, takeLatest } from "redux-saga/effects";
import { toastr } from "react-redux-toastr";

import {
  Creators as AsynchronousResultsActions,
  Types,
} from "../ducks/asynchronousresults";

export const getOrganization = (state) => state.usuarios.auth.organization;
export const getUserId = (state) => state.usuarios.auth.id;

export function* listAsynchronousResults({ payload }) {
  try {
    const organization = yield select(getOrganization);

    const { page, limit, sort } = payload;

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
      params: { page, limit, sort },
    };

    const { data } = yield call(api.get, `asynchronous/results`, headerParams);

    yield put(AsynchronousResultsActions.listSuccess(data));
  } catch (error) {
    toastr.error("Ops", "Não foi possível listar os meus arquivos.");
  }
}

export default all([takeLatest(Types.LIST_REQUEST, listAsynchronousResults)]);
