import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
} from "@material-ui/core";

import StyledTableRow from "components/StyledTableRow";

import { Row, Col } from "react-bootstrap";

import { Link } from "react-router-dom";

import ButtonSquare from "components/ButtonSquare";
import FormItem from "../FormItem";

import { Creators as CheckTipoActions } from "../../../store/ducks/checktipo";
import { Creators as DepartamentosActions } from "../../../store/ducks/departamentos";

import "./styles.css";
import Content from "components/Content";
export default function ItemConfig(props) {
  const {
    access: { departamentos },
  } = useSelector((state) => state.permissoes);
  const empresaSetada = useSelector((state) => state.checkTipos.empresaSetada);

  const { tituloSelect, IndexItem } = props.location.state;

  const checksSelect = useSelector((state) => state.checkTipos.checksSelect);

  const checkTitulos = checksSelect.titulos;

  const itens = checkTitulos[IndexItem].itens;

  const dispatch = useDispatch();

  const onRemove = ({ _id }, { hash: hashTitulo }, { hash: hashItem }) => {
    dispatch(CheckTipoActions.removeItemRequest({ _id, hashTitulo, hashItem }));
  };

  useEffect(() => {}, [checksSelect]);

  useEffect(() => {
    dispatch(
      DepartamentosActions.listByEmpresaRequest({
        empresa: empresaSetada,
        departamentos,
      })
    );
  }, [departamentos, dispatch, empresaSetada]);

  return (
    <Content>
      <div className="inter-panels">
        <Row>
          <Col md="12" className="painel">
            <div className="painel-content">
              <div className="painel-title text-left">Novo Item</div>
              <div className="painel-body">
                <FormItem IndexItem={IndexItem} tituloSelect={tituloSelect} />
              </div>
            </div>
          </Col>
        </Row>
      </div>

      <div className="inter-panels">
        <Row>
          <Col md="12" className="painel">
            <div className="painel-content">
              <div className="painel-title text-left">Itens Cadastrados</div>
              <div className="painel-body">
                <TableContainer>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Descrição do Item</TableCell>
                        <TableCell align="center">Ordem</TableCell>
                        <TableCell align="center">Tipo</TableCell>
                        <TableCell align="center">Opções de Combo</TableCell>
                        <TableCell align="center">Ações</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {itens &&
                        itens.map((itens) => (
                          <StyledTableRow key={itens.descricao}>
                            <TableCell>{itens.nome}</TableCell>
                            <TableCell align="center">{itens.ordem}</TableCell>
                            <TableCell align="center">
                              {itens.tipoItem}
                            </TableCell>
                            <TableCell align="center">
                              {Array.isArray(itens.optionsCombo) &&
                                itens?.optionsCombo
                                  ?.map((combo) => combo)
                                  .join(" - ")}
                            </TableCell>
                            <TableCell align="center">
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <Link
                                  to={{
                                    pathname: "/operacao/checktipo/item/edit",
                                    state: {
                                      tituloSelect,
                                      IndexItem,
                                      item: itens,
                                    },
                                  }}
                                >
                                  <ButtonSquare
                                    style={{ margin: "0 5px" }}
                                    icon="edit"
                                  />
                                </Link>

                                <ButtonSquare
                                  onClick={() =>
                                    onRemove(checksSelect, tituloSelect, itens)
                                  }
                                  style={{ margin: "0 5px" }}
                                  icon="delete"
                                />
                              </div>
                            </TableCell>
                          </StyledTableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </Content>
  );
}
