import React, { useMemo, useState, useRef } from 'react';
import Button from '@material-ui/core/Button';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Icon from '@material-ui/core/Icon';
import _ from '@lodash';

import ListAcessos from './ListAcessos';

import { Creators as AcessosRPAActions } from 'store/ducks/acessosrpa';
import Content from 'components/Content';

function AcessosRPA() {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const dispatchDebounce = useRef(_.debounce(dispatch, 500)).current;

  const { access: { servicos } } = useSelector(state => state.permissoes);
  const { search='', page, limit, sort } = useSelector(state => state.acessosrpa);

  const userActions = useMemo(() => 
    servicos.find(item => item.route === pathname)
  , [pathname, servicos]);

  const canCreate = useMemo(() => 
    userActions && userActions.actions && 
    userActions.actions.some(userAct => userAct.action === 'criar')
  , [userActions]);

  const [searchField, setSearchField] = useState(search);

  function handleChange({ target }) {
    const { value } = target;
    setSearchField(value);
    dispatchDebounce(AcessosRPAActions.listRequest({ page, limit, sort, search: value }))
  }

  return (
    <Content>
          <Row>
            <Col md="12" className="painel">
              <div className="painel-content">
                <div className="painel-title text-left">
                  <Col>
                    <TextField
                      label="Pesquisar"
                      variant="outlined"
                      size="small"
                      InputProps={{
                        endAdornment: <InputAdornment position="end"><Icon className="text-20" color="action">search</Icon></InputAdornment>
                      }}
                      onChange={handleChange}
                      value={searchField}
                    />
                  </Col>
                  {canCreate &&
                  <Button
                    component={Link}
                    to={"/configuracao/acessos-rpa/novo"}
                    variant="contained"
                    color="primary"
                  >
                    Novo Acesso
                  </Button>}
                </div>
                <div className="painel-body">
                  <ListAcessos />
                </div>
              </div>
            </Col>
          </Row>
          </Content>
  );
}

export default AcessosRPA;