import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { useDispatch } from "react-redux";

import { Row, Col } from "react-bootstrap";

import List from "../EsteiraConfig/ListEsteiraConfig";

import { useSelector } from "react-redux";

import { Creators as EmpresasActions } from "store/ducks/empresas";
import Content from "components/Content";

export default function EsteiraConfig() {
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const [userActions, setUserActions] = useState([]);

  const {
    access: { servicos },
  } = useSelector((state) => state.permissoes);

  useEffect(() => {
    let find = servicos.find((item) => item.route === pathname);
    if (find !== undefined) {
      setUserActions(find);
    }
  }, [pathname, servicos]);

  useEffect(() => {
    dispatch(EmpresasActions.listRequest());
  }, [dispatch]);

  return (
    <Content>
      <Row>
        <Col md="12" className="painel">
          <div className="painel-content">
            <div className="painel-title text-left">
              <div></div>
              {userActions.actions &&
                userActions.actions.map(
                  (userAct, index) =>
                    userAct.action === "criar" && (
                      <Button
                        key={index}
                        component={Link}
                        to={"/operacao/esteiraconfig/create"}
                        color="primary"
                        variant="contained"
                      >
                        Nova Fila
                      </Button>
                    )
                )}
            </div>
            <div className="painel-body">
              <List />
            </div>
          </div>
        </Col>
      </Row>
    </Content>
  );
}
