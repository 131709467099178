import React, { useState } from "react";
import { Row, Col, Form, Table } from "react-bootstrap";

import CsvDownloader from "react-csv-downloader";

import useForm from "react-hook-form";

import "./style.css";
import Content from "components/Content";

export default function Relatorios() {
  const { register, handleSubmit } = useForm();

  const [selectEmpresa, setSelectEmpresa] = useState("");
  const [selectFila, setSelectFila] = useState("");
  const [selectEtapa, setSelectEtapa] = useState("");

  const getFilas = (values) => {
    const { empresa } = values;

    setSelectEmpresa(empresa);
  };

  const getEtapas = (values) => {
    const { fila } = values;

    setSelectFila(fila);
  };

  const getDocumentosEtapa = (values) => {
    const { etapa } = values;

    setSelectEtapa(etapa);
  };

  const empresas = [
    {
      _id: "5dd32bb53b7fdb2fe01b997a",
      limiteSenhasRepetidas: 3,
      limiteSenhasIncorretas: "5",
      tipoPagamento: "Depósito Bancário",
      qtMinimaCaracteresSenha: 8,
      timeOut: 60,
      restricaoIps: [],
      status: "Ativo",
      razaoSocial: "Empresa 1 LTDA",
      fantasia: "Empresa 1",
      tipoPessoa: "Jurídica",
      emailFatura: "admin@empresa1.com",
      acessoSimultaneo: false,
      bloqueioInatividade: false,
      dtCadastro: "18/11/2019",
      dtAlteracao: "18/11/2019",
    },
    {
      _id: "5dd32bb53b7fdb2fe01b997b",
      limiteSenhasRepetidas: 3,
      limiteSenhasIncorretas: "5",
      tipoPagamento: "Depósito Bancário",
      qtMinimaCaracteresSenha: 8,
      timeOut: 60,
      restricaoIps: [],
      status: "Ativo",
      razaoSocial: "Empresa 2 LTDA",
      fantasia: "Empresa 2",
      tipoPessoa: "Jurídica",
      emailFatura: "admin@empresa2.com",
      acessoSimultaneo: false,
      bloqueioInatividade: false,
      dtCadastro: "18/11/2019",
      dtAlteracao: "18/11/2019",
    },
  ];

  const fila1 = {
    _id: "5dd32d297d5da925e8a66d2f",
    etapas: ["5dd32d657d5da925e8a66d30", "5dd32e387d5da925e8a66d31"],
    empresa: "5dd32bb53b7fdb2fe01b997a",
    descricao: "Fila 1",
    userAcao: "Administrador",
    dtCadastro: "18/11/2019",
    dtAlteracao: "18/11/2019",
  };

  const fila2 = {
    _id: "5dd32d297d5da925e8a66d2z",
    empresa: "5dd32bb53b7fdb2fe01b997b",
    descricao: "Fila 2",
    userAcao: "Administrador",
    dtCadastro: "18/11/2019",
    dtAlteracao: "18/11/2019",
  };

  const filas = [fila1, fila2];

  const etapa1 = {
    _id: "5dd32e387d5da925e8a66d31a",
    nome: "Etapa A",
    userAcao: "Administrador",
    fila: "5dd32d297d5da925e8a66d2f",
    dtCadastro: "18/11/2019",
    dtAlteracao: "18/11/2019",
  };

  const etapa2 = {
    _id: "5dd32e387d5da925e8a66d31b",
    nome: "Etapa B",
    userAcao: "Administrador",
    fila: "5dd32d297d5da925e8a66d2z",
    dtCadastro: "18/11/2019",
    dtAlteracao: "18/11/2019",
  };

  const etapa3 = {
    _id: "5dd32e387d5da925e8a66d31c",
    nome: "Etapa C",
    userAcao: "Administrador",
    fila: "5dd32d297d5da925e8a66d2f",
    dtCadastro: "18/11/2019",
    dtAlteracao: "18/11/2019",
  };

  const etapa4 = {
    _id: "5dd32e387d5da925e8a66d31d",
    nome: "Etapa D",
    userAcao: "5dd32c527d5da925e8a66d2e",
    fila: "5dd32d297d5da925e8a66d2z",
    dtCadastro: "18/11/2019",
    dtAlteracao: "18/11/2019",
  };

  const documento1 = {
    indexadores: [
      {
        nome: "Nota Fiscal",
        valor: "4568648",
      },
    ],
    usuarioAlteracao: "Usuario A",
    ultimaAlteracao: "17/11/2019",
    imagens: [
      {
        filepath: "https://i.imgur.com/g6Mde6O.jpg",
      },
    ],
  };

  const documento2 = {
    indexadores: [
      {
        nome: "Nota Fiscal",
        valor: "3168648",
      },
    ],
    ultimaAlteracao: "18/11/2019",
    usuarioAlteracao: "Usuario A",
    imagens: [
      {
        filepath: "https://i.imgur.com/g6Mde6O.jpg",
      },
    ],
  };

  const documento3 = {
    indexadores: [
      {
        nome: "Nota Fiscal",
        valor: "0167518",
      },
    ],
    ultimaAlteracao: "19/11/2019",
    usuarioAlteracao: "Usuario B",
    imagens: [
      {
        filepath: "https://i.imgur.com/g6Mde6O.jpg",
      },
    ],
  };

  const documento4 = {
    indexadores: [
      {
        nome: "Nota Fiscal",
        valor: "7897518",
      },
    ],
    ultimaAlteracao: "20/11/2019",
    usuarioAlteracao: "Usuario B",
    imagens: [
      {
        filepath: "https://i.imgur.com/g6Mde6O.jpg",
      },
    ],
  };

  const documento5 = {
    indexadores: [
      {
        nome: "Nota Fiscal",
        valor: "1237518",
      },
    ],
    ultimaAlteracao: "20/11/2019",
    usuarioAlteracao: "Usuario C",
    imagens: [
      {
        filepath: "https://i.imgur.com/g6Mde6O.jpg",
      },
    ],
  };

  const documento6 = {
    indexadores: [
      {
        nome: "Nota Fiscal",
        valor: "9817518",
      },
    ],
    ultimaAlteracao: "20/11/2019",
    usuarioAlteracao: "Usuario C",
    imagens: [
      {
        filepath: "https://i.imgur.com/g6Mde6O.jpg",
      },
    ],
  };

  const documento7 = {
    indexadores: [
      {
        nome: "Nota Fiscal",
        valor: "5247518",
      },
    ],
    ultimaAlteracao: "20/11/2019",
    usuarioAlteracao: "Usuario D",
    imagens: [
      {
        filepath: "https://i.imgur.com/g6Mde6O.jpg",
      },
    ],
  };

  const documento8 = {
    indexadores: [
      {
        nome: "Nota Fiscal",
        valor: "6117518",
      },
    ],
    ultimaAlteracao: "20/11/2019",
    usuarioAlteracao: "Usuario D",
    imagens: [
      {
        filepath: "https://i.imgur.com/g6Mde6O.jpg",
      },
    ],
  };

  const columns = [
    { id: "first", displayName: "Nota Fiscal" },
    { id: "second", displayName: "Última Alteração" },
    { id: "third", displayName: "Usuário Alteração" },
  ];

  const dataA = [
    {
      first: documento1.indexadores[0].valor,
      second: documento1.ultimaAlteracao,
      third: documento1.usuarioAlteracao,
    },
    {
      first: documento2.indexadores[0].valor,
      second: documento2.ultimaAlteracao,
      third: documento2.usuarioAlteracao,
    },
  ];

  const dataB = [
    {
      first: documento3.indexadores[0].valor,
      second: documento3.ultimaAlteracao,
      third: documento3.usuarioAlteracao,
    },
    {
      first: documento4.indexadores[0].valor,
      second: documento4.ultimaAlteracao,
      third: documento4.usuarioAlteracao,
    },
  ];

  const dataC = [
    {
      first: documento5.indexadores[0].valor,
      second: documento5.ultimaAlteracao,
      third: documento5.usuarioAlteracao,
    },
    {
      first: documento6.indexadores[0].valor,
      second: documento6.ultimaAlteracao,
      third: documento6.usuarioAlteracao,
    },
  ];

  const dataD = [
    {
      first: documento7.indexadores[0].valor,
      second: documento7.ultimaAlteracao,
      third: documento7.usuarioAlteracao,
    },
    {
      first: documento8.indexadores[0].valor,
      second: documento8.ultimaAlteracao,
      third: documento8.usuarioAlteracao,
    },
  ];

  return (
    <Content>
      <Row>
        <Col md="12" className="painel">
          <div class="painel-content">
            <div class="painel-title text-left">Relatórios</div>
            <div class="painel-body">
              <Form>
                <Row>
                  <Col md={4}>
                    <Form.Group controlId="exampleForm.ControlSelect1">
                      <Form.Label>Empresa</Form.Label>
                      <Form.Control
                        as="select"
                        ref={register}
                        name="empresa"
                        onChange={handleSubmit(getFilas)}
                      >
                        <option value={selectEmpresa}>
                          Selecione a empresa
                        </option>
                        {empresas.map((empresa) => (
                          <option
                            key={empresa._id}
                            ref={register}
                            value={empresa._id}
                          >
                            {empresa.fantasia}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  {selectEmpresa && filas.length > 0 && (
                    <Col md={4}>
                      <Form.Group controlId="exampleForm.ControlSelect1">
                        <Form.Label>Filas</Form.Label>
                        <Form.Control
                          as="select"
                          ref={register}
                          name="fila"
                          onChange={handleSubmit(getEtapas)}
                        >
                          <option value={selectFila}>
                            Selecione a fila
                          </option>
                          {filas.map((fila) => (
                            <option
                              key={fila._id}
                              ref={register}
                              value={fila._id}
                            >
                              {fila.descricao}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  )}

                  {selectFila && (
                    <Col md={4}>
                      <Form.Group controlId="exampleForm.ControlSelect1">
                        <Form.Label>Etapas</Form.Label>
                        <Form.Control
                          as="select"
                          ref={register}
                          name="etapa"
                          onChange={handleSubmit(getDocumentosEtapa)}
                        >
                          {" "}
                          <option value={selectEtapa}>
                            Selecione a etapa
                          </option>
                          {selectFila === "5dd32d297d5da925e8a66d2f" && (
                            <>
                              <option value={etapa1._id}>
                                {etapa1.nome}
                              </option>
                              <option value={etapa2._id}>
                                {etapa2.nome}
                              </option>
                            </>
                          )}
                          {selectFila === "5dd32d297d5da925e8a66d2z" && (
                            <>
                              <option value={etapa3._id}>
                                {etapa3.nome}
                              </option>
                              <option value={etapa4._id}>
                                {etapa4.nome}
                              </option>
                            </>
                          )}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  )}
                </Row>
              </Form>
            </div>
          </div>
        </Col>
      </Row>
      {selectEtapa === "5dd32e387d5da925e8a66d31a" && (
        <Row>
          <Col md="12" className="painel">
            <div className="painel-content">
              <div className="painel-title text-left">
                Documentos
                <CsvDownloader
                  filename="Relatorio"
                  separator=";"
                  columns={columns}
                  datas={dataA}
                  text="Exportar para Excel"
                />
              </div>
              <div className="painel-body">
                <Table className="text-center">
                  <thead>
                    <tr>
                      <th>{documento1.indexadores[0].nome}</th>
                      <th>Última Alteração</th>
                      <th>Usuário Alteração</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{documento1.indexadores[0].valor}</td>
                      <td>{documento1.ultimaAlteracao}</td>
                      <td>{documento1.usuarioAlteracao}</td>
                    </tr>
                    <tr>
                      <td>{documento2.indexadores[0].valor}</td>
                      <td>{documento2.ultimaAlteracao}</td>
                      <td>{documento2.usuarioAlteracao}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
          </Col>
        </Row>
      )}

      {selectEtapa === "5dd32e387d5da925e8a66d31b" && (
        <Row>
          <Col md="12" className="painel">
            <div className="painel-content">
              <div className="painel-title text-left">
                Documentos
                <CsvDownloader
                  filename="Relatorio"
                  separator=";"
                  columns={columns}
                  datas={dataB}
                  id="export"
                  text="Exportar para Excel"
                />
              </div>
              <div className="painel-body">
                <Table className="text-center">
                  <thead>
                    <tr>
                      <th>{documento1.indexadores[0].nome}</th>
                      <th>Última Alteração</th>
                      <th>Usuário Alteração</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{documento3.indexadores[0].valor}</td>
                      <td>{documento3.ultimaAlteracao}</td>
                      <td>{documento3.usuarioAlteracao}</td>
                    </tr>
                    <tr>
                      <td>{documento4.indexadores[0].valor}</td>
                      <td>{documento4.ultimaAlteracao}</td>
                      <td>{documento4.usuarioAlteracao}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
          </Col>
        </Row>
      )}

      {selectEtapa === "5dd32e387d5da925e8a66d31c" && (
        <Row>
          <Col md="12" className="painel">
            <div className="painel-content">
              <div className="painel-title text-left">
                Documentos
                <CsvDownloader
                  filename="Relatorio"
                  separator=";"
                  columns={columns}
                  datas={dataC}
                  id="export"
                  text="Exportar para Excel"
                />
              </div>
              <div className="painel-body">
                <Table className="text-center">
                  <thead>
                    <tr>
                      <th>{documento1.indexadores[0].nome}</th>
                      <th>Última Alteração</th>
                      <th>Usuário Alteração</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{documento5.indexadores[0].valor}</td>
                      <td>{documento5.ultimaAlteracao}</td>
                      <td>{documento5.usuarioAlteracao}</td>
                    </tr>
                    <tr>
                      <td>{documento6.indexadores[0].valor}</td>
                      <td>{documento6.ultimaAlteracao}</td>
                      <td>{documento6.usuarioAlteracao}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
          </Col>
        </Row>
      )}

      {selectEtapa === "5dd32e387d5da925e8a66d31d" && (
        <Row>
          <Col md="12" className="painel">
            <div className="painel-content">
              <div className="painel-title text-left">
                Documentos
                <CsvDownloader
                  filename="Relatorio"
                  separator=";"
                  columns={columns}
                  datas={dataD}
                  id="export"
                  text="Exportar para Excel"
                />
              </div>
              <div className="painel-body">
                <Table className="text-center">
                  <thead>
                    <tr>
                      <th>{documento1.indexadores[0].nome}</th>
                      <th>Última Alteração</th>
                      <th>Usuário Alteração</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{documento7.indexadores[0].valor}</td>
                      <td>{documento7.ultimaAlteracao}</td>
                      <td>{documento7.usuarioAlteracao}</td>
                    </tr>
                    <tr>
                      <td>{documento8.indexadores[0].valor}</td>
                      <td>{documento8.ultimaAlteracao}</td>
                      <td>{documento8.usuarioAlteracao}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
          </Col>
        </Row>
      )}
    </Content>
  );
}
