export const Types = {
  NEW_REQUEST: "whatsapp/NEW_REQUEST",
  NEW_SUCCESS: "whatsapp/NEW_SUCCESS",
};

const INITIAL_STATE = {
  loading: false,
};

export default function whatsapp(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.NEW_REQUEST:
      return { ...state, loading: true };

    case Types.NEW_SUCCESS:
      return { ...state, loading: false };

    default:
      return state;
  }
}

export const Creators = {
  createRequest: (payload) => ({
    type: Types.NEW_REQUEST,
    payload,
  }),

  createSuccess: () => ({
    type: Types.NEW_SUCCESS,
    payload: {},
  })
};
