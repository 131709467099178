import { sub, parseISO } from 'date-fns';

export default [
    {
        label: '1 hora antes',
        fn: (date) => sub(parseISO(date), { hours: 1 }).toISOString(),
    },
    {
        label: '1 dia antes',
        fn: (date) => sub(parseISO(date), { days: 1 }).toISOString(),
    },
    {
        label: '2 dias antes',
        fn: (date) => sub(parseISO(date), { days: 2 }).toISOString(),
    },
]