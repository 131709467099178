import React, { useEffect, useState } from 'react';
import CircularProgress from "@material-ui/core/CircularProgress";
import Slide from "@material-ui/core/Slide";
import LinearProgress from "@material-ui/core/LinearProgress";
import { withStyles } from '@material-ui/core/styles';
import pdfjs from 'pdfjs';

import { getPresignedUrl } from "../../../services/presignedUrl";

import './styles.css';


const BorderLinearProgress = withStyles({
  root: {
    backgroundColor: '#000000', 
    opacity: 0.8
  },
  bar: {
    borderRadius: 20,
    backgroundColor: '#FFF',
  },
})(LinearProgress);

function downloadBuffer({ buffer, fileName }) {
  // Create an invisible A element
  const a = document.createElement('a');
  a.style.display = 'none';
  document.body.appendChild(a);

  // Set the HREF to a Blob representation of the buffer to be downloaded
  a.href = window.URL.createObjectURL(
    new Blob([buffer]),
  );
  // Use download attribute to set set desired file name
  a.setAttribute('download', fileName);

  // Trigger the download by simulating click
  a.click();

  // Cleanup
  window.URL.revokeObjectURL(a.href);
  document.body.removeChild(a);
}

async function readableToBuffer(readable) {
  const chunks = [];
  for await (const chunk of readable) {
    chunks.push(chunk);
  }
  return Buffer.concat(chunks);
}

function MergeProcess({ data }) {
  
  const { filesToMerge, output = 'documentos.pdf' } = data;

  const [inProcess, setInProcess] = useState(true);
  const [completed, setCompleted] = useState(0);

  useEffect(() => {

    const step = (100 / ((filesToMerge.length * 3) + 1));
    async function process() {
      const pdfUrls = await Promise.all(filesToMerge.map(async file => {
        setCompleted(completed => completed += step);
        return await getPresignedUrl(file)
      }));

      const inputFiles = await Promise.all(
        pdfUrls
          .map(async (url) => {
            const res = await fetch(url);
            setCompleted(completed => completed += step);
            return res.arrayBuffer();
          }),
      );

      const doc = new pdfjs.Document();
      inputFiles.forEach((inputFileBuffer) => {
        const ext = new pdfjs.ExternalDocument(inputFileBuffer);
        doc.setTemplate(ext);
        doc.addPagesOf(ext);
        setCompleted(completed => completed += step);
      });
      doc.end();

      const buffer = await readableToBuffer(doc);
      setCompleted(completed => completed += step);
      downloadBuffer({
        buffer,
        fileName: output,
      });

      setInProcess(false);

    }

    process();

  }, [filesToMerge, output]);

  return (
    <Slide direction="left" in={inProcess} mountOnEnter unmountOnExit>
      <div className="merge-process-container">
        <div className="merge-process-content">
          <CircularProgress size={20} style={{ animationDuration: '400ms', color: '#FFF' }} />
          <span>Exportando arquivos...</span>
        </div>
        {inProcess && 
        <div className="merge-process-progress">
          <BorderLinearProgress 
            variant="determinate"
            value={completed}
          />
        </div>}
      </div>
    </Slide>
  );
}

export default (MergeProcess);