import styled from "styled-components/macro";
import {
  Button,
  Dialog,
  DialogTitle as MaterialDialogTitle,
} from "@material-ui/core";

export const Modal = styled(Dialog)`
  .MuiDialog-paperWidthSm {
    width: 800px;
    height: 450px;
  }
`;

export const DialogTitle = styled(MaterialDialogTitle)`
  width: 100%;
  text-align: center;
`;

export const ColorButton = styled(Button)`
  color: white;
  height: 42px;
`;
