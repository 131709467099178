import api from "../../services/api";
import { getToken } from "../../services/auth";
import { put, call, select } from "redux-saga/effects";
import { toastr } from "react-redux-toastr";
import { push } from "connected-react-router";

import { Creators as EmpresasActions } from "../ducks/empresas";
import { getPermissoes as executePerms } from "./permissoes";

export const getOrganization = state => state.usuarios.auth.organization;

export const getUserId = state => state.usuarios.auth.id;
export const getUserInfo = state => state.usuarios;

export const getPermissoes = state => state.permissoes;

export function* listEmpresas({ payload }) {
  const organization = yield select(getOrganization);

  const { page, limit, sort, grupoEmpresarial, search } = payload;

  const endpoint = !grupoEmpresarial
    ? "empresas"
    : `gruposempresariais/${grupoEmpresarial.join("|")}/empresas`;

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
      params: { page, limit, sort, search },
    };

    const response = yield call(api.post, endpoint, null, headerParams);

    const data = {
      list: response.data.docs,
      total: response.data.total,
    };

    yield put(EmpresasActions.listSuccess(data));
  } catch (error) {
    toastr.error("Ops", "Não foi possível listar as empresas.");
  }
}

export function* createEmpresa(action) {
  const organization = yield select(getOrganization);

  const usuarios = yield select(getUserInfo);

  const permissoes = yield select(getPermissoes);

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const { data } = yield call(
      api.post,
      `empresa`,
      action.payload,
      headerParams
    );

    yield executePerms();

    yield put(push("/administracao/empresas"));

    toastr.success("", "Empresa criada com sucesso.");
  } catch (error) {
    toastr.error("Ops", "Não foi possível criar a empresa. Mesmo.");
  }
}

export function* editEmpresa(action) {
  const organization = yield select(getOrganization);

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const { data } = yield call(
      api.put,
      `/empresa/${action.payload._id}`,
      action.payload,
      headerParams
    );

    yield put(push("/administracao/empresas"));

    yield put(EmpresasActions.editSuccess(data));

    toastr.success("", "Empresa editada com sucesso.");
  } catch (error) {
    toastr.error("Ops", "Não foi possível editar a empresa.");
  }
}

export function* removeEmpresa(action) {
  try {
    const headerParams = {
      headers: { Authorization: getToken() },
    };

    yield call(api.delete, `empresa/${action.payload}`, headerParams);

    yield put(EmpresasActions.removeSuccess());

    toastr.success("", "Empresa removida com sucesso.");
  } catch (error) {
    toastr.error("Ops", "Não foi possível remover a empresa.");
  }
}

export function* findEmpresaByCNPJ(action) {
  try {
    const headerParams = {
      headers: { Authorization: getToken() },
    };

    const { data } = yield call(
      api.get,
      `cnpj/${action.payload}`,
      headerParams
    );
    yield put(EmpresasActions.listByCNPJSuccess(data));
  } catch (error) {
    toastr.error("Ops", "Não foi possível buscar a empresa.");
  }
}

export function* getRegras(empresa) {
  const organization = yield select(getOrganization);
  const permissoes = yield select(getPermissoes);

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const body = { empresas: [...permissoes.access.empresas] };

    const { data } = yield call(api.post, `regras`, body, headerParams);

    yield put(EmpresasActions.getRegrasSuccess(data));
  } catch (error) {
    //
  }
}

export function* executeRegras(regra) {
  const organization = yield select(getOrganization);

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const { data } = yield call(api.post, `script`, regra, headerParams);

    return data;
  } catch (error) {}
}

export function* findPermissaoByUserId(action) {
  try {
    const headerParams = {
      headers: { Authorization: getToken() },
    };

    const { data } = yield call(
      api.post,
      `/permissoes/usuarioid/`,
      action.payload,
      headerParams
    );

    return data;
  } catch (error) {
    toastr.error("Ops", "Não foi possível buscar a empresa.");
  }
}
