const OUTROS_CAMPOS = [
    {
      nome: "Protocolo",
      id_es: "numeroCNJ",
    },
    {
      nome: "Data geração",
      id_es: "dataGeracao",
    },
    {
      nome: "Hora geração",
      id_es: "horaGeracao",
    },
    {
      nome: "Data captura",
      id_es: "dataCaptura",
    },
    {
      nome: "Nome da parte",
      id_es: "parteNome",
    },
    {
      nome: "CPF da parte",
      id_es: "parteCpfCnpj",
    },
    {
      nome: "Resumo",
      id_es: "resumo",
    },
  ];


  const OUTROS_CAMPOS_TIPO_LAYOUT = [
    {
      nome: "Encerrado",
      id_es: "status",
    }
  ];

module.exports = { OUTROS_CAMPOS, OUTROS_CAMPOS_TIPO_LAYOUT };
