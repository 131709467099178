import * as Yup from "yup";
import Locale from "./Locale";

Yup.setLocale(Locale);

export default Yup.object().shape({
    nome: Yup.string()
        .required("Campo Obrigatório")
        .typeError("Campo obrigatório")
        .required()
});
