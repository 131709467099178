import React, { useEffect, useRef, useState } from 'react';
import {
  Container,
  Header,
  FormContainer,
  Form,
  Field,
  PreviewButtons,
} from './style';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';

import { Creators as OnboardingActions } from 'store/ducks/onboarding';
import TwoFactorInput from './twofactor';

export default function Verificacao({ form }) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [conectorOnboarding, setConectorOnboarding] = useState(null);


  useEffect(() => {
    async function loadForm() {
      if (form) {
        const steps = form.steps;

        for await (const step of steps) {
          if (step.type === 'fields') {
            const fields = step.fields;

            for await (const field of fields) {
              if (field.conectorOnboarding) {
                setConectorOnboarding(field);
              }
            }
          }
        }
      }
    }

    loadForm();
  }, [form])

  useEffect(() => {
    setFormData({
      ...formData,
      conectorName: conectorOnboarding?.nome,
    });
  }, [conectorOnboarding]);

  const {
    twofactor: { formCode, codigo_verificado },
  } = useSelector((state) => state.onboarding);

  const [formData, setFormData] = useState({
    // Inicialize com valores vazios para cada campo
    code: '',
    // nome: '',
    conector: '',
    conectorName: '',
    email: '',
    formId: id,
    // Adicione mais campos conforme necessário
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    // Atualize o estado com o novo valor
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!formData.email || !formData.conector || !formData.code) {
      toastr.error('Erro', 'Preencha todos os campos');
      return;
    }

    console.log("validateCodePrivateRequest", formData)

    dispatch(OnboardingActions.validateCodePrivateRequest(formData));
  };

  const handleTwoFactorSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <>
      {form && (
        <Container $background={form.layout.background}>
          {form.layout.logo && (
            <Header>
              <img src={form.layout.logo} alt="logo" />
            </Header>
          )}
          <FormContainer>
            {formCode && !codigo_verificado ? (
              <>
                <TwoFactorInput form={form} />
              </>
            ) : (
              <>
                <h4>Preencha o formulário:</h4>
                <Form onSubmit={handleSubmit}>
                  {/* <Field>
                    <input
                      type="text"
                      placeholder="Nome Completo"
                      name="nome"
                      onChange={handleChange}
                    />
                  </Field> */}
                  <Field>
                    <input
                      type="text"
                      placeholder="Email"
                      name="email"
                      onChange={handleChange}
                      autoComplete='off'
                    />
                  </Field>
                  {conectorOnboarding && (
                    <Field>
                      <input
                        type="number"
                        placeholder={conectorOnboarding.nome}
                        name={"conector"}
                        onChange={handleChange}
                        autoComplete='off'
                      />
                    </Field>
                  )}
                  <Field>
                    <input
                      type="text"
                      placeholder="Código"
                      name="code"
                      onChange={handleChange}
                      autoComplete='off'
                    />
                  </Field>
                  <PreviewButtons $layout={form.layout.buttons}>
                    <button type="submit">Enviar</button>
                  </PreviewButtons>
                </Form>
              </>
            )}
          </FormContainer>
        </Container>
      )}
    </>
  );
}
