import React, { memo, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Menu from '@material-ui/core/Menu';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import Badge from '@material-ui/core/Badge';
import Avatar from '@material-ui/core/Avatar';
import { parseISO, formatDistance } from 'date-fns';
import pt from 'date-fns/locale/pt';

import NotificacaoIcone from './NotificacaoIcone';

import { Creators as NotificacoesActions } from 'store/ducks/notificacoes';
import { Creators as DocumentosActions } from 'store/ducks/documentos';

import useStyles from './styles';
import { NotificationsIcon } from 'components/Icons';

function Notificacoes(props) {
  const dispatch = useDispatch();
  const { list, loading, page, total } = useSelector(
    (state) => state.notificacoes
  );
  const [anchorEl, setAnchorEl] = useState(null);

  const classes = useStyles();

  const notificacoes = useMemo(
    () =>
      list.map((notificacao) => ({
        ...notificacao,
        age: formatDistance(parseISO(notificacao.createdAt), new Date(), {
          addSuffix: true,
          locale: pt,
        }),
      })),
    [list]
  );

  function handleClick(notificacao) {
    if (
      notificacao.tipo === 'INDEXACAO_DOCUMENTO' ||
      notificacao.tipo === 'NOVA_DISTRIBUICAO' ||
      notificacao.tipo === 'NOVO_ANDAMENTO' ||
      notificacao.tipo === 'COMENTARIO_MENCAO'
    ) {
      handleClickDocumento(notificacao);
    }
    handleMarkAsRead(notificacao._id);
  }

  function handleClickDocumento(notificacao) {
    dispatch(
      DocumentosActions.sendToSearchPageRequest(
        [notificacao.referencia],
        {
          limit: 10,
          page: 1,
          sort: '-createdAt',
          searchText: '',
        },
        true
      )
    );
  }

  function handleMarkAsRead(_id) {
    dispatch(NotificacoesActions.markReadRequest(_id));
  }

  function handleMenuClick(event) {
    dispatch(NotificacoesActions.listRequest({ page: 1 }));
    setAnchorEl(event.currentTarget);
  }

  function handleMenuClose() {
    setAnchorEl(null);
  }

  function showMore() {
    dispatch(NotificacoesActions.listRequest({ page: page + 1 }));
  }

  return (
    <>
      <Avatar className={classes.whiteBg} onClick={handleMenuClick}>
        <Badge
          color="primary"
          variant="dot"
          overlap="rectangle"
          invisible={!notificacoes.some((notificacao) => !notificacao.lida)}
        >
          <NotificationsIcon size={18} />
        </Badge>
      </Avatar>
      <Menu
        id="notificacoes-menu"
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        getContentAnchorEl={null}
        PaperProps={{
          style: {
            width: '36ch',
            marginTop: '5px',
          },
        }}
      >
        {!loading && (!notificacoes || notificacoes.length === 0) && (
          <ListItem>
            <ListItemText
              primary={
                <div className="notifications">Não há novas notificações</div>
              }
            />
          </ListItem>
        )}
        {notificacoes &&
          notificacoes.length > 0 &&
          notificacoes.map((notificacao) => (
            <ListItem
              key={notificacao._id}
              className={classes.item}
              onClick={(e) => handleClick(notificacao)}
            >
              <ListItemIcon>
                <NotificacaoIcone tipo={notificacao.tipo} />
              </ListItemIcon>
              <ListItemText
                primary={notificacao.conteudo}
                secondary={
                  <React.Fragment>
                    <Typography component="span" variant="caption">
                      {notificacao.age}
                    </Typography>
                    {!notificacao.lida && (
                      <Typography
                        component="span"
                        variant="caption"
                        className={classes.read}
                        color="primary"
                        onClick={(e) => handleMarkAsRead(notificacao._id)}
                      >
                        Marcar como lida
                      </Typography>
                    )}
                  </React.Fragment>
                }
              />
            </ListItem>
          ))}
        {!loading && notificacoes.length < total && (
          <ListItem className={classes.item} onClick={showMore}>
            <ListItemText primary="Mostrar mais..." />
          </ListItem>
        )}
        {!!loading &&
          [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((item) => (
            <ListItem key={item}>
              <ListItemIcon>
                <Skeleton width={30} />
              </ListItemIcon>
              <ListItemText primary={<Skeleton />} secondary={<Skeleton />} />
            </ListItem>
          ))}
      </Menu>
    </>
  );
}

export default memo(Notificacoes);
