import React, { useRef } from "react";
import { useDispatch } from "react-redux";
import { Creators as RPAActions } from "store/ducks/rpa";

import _ from "lodash";

import GridCadastro from "components/GridCadastro";
import List from "./ListRPA";

export default function Departamento() {
  const dispatch = useDispatch();
  const dispatchDebounce = useRef(_.debounce(dispatch, 500)).current;

  return (
    <GridCadastro
      title="RPA"
      route={"/rpa/gerenciar"}
      searchRequest={(value) => {
        dispatchDebounce(
          dispatch(
            RPAActions.listRequest({
              page: 1,
              limit: 100,
              search: value,
            })
          )
        );
      }}
      titleButtonNovo="Novo RPA"
    >
      <List />
    </GridCadastro>
  );
}
