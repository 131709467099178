import React, { useMemo, useEffect, useState } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TableFooter,
  TablePagination,
} from "@material-ui/core";
import { Creators as GrupoEmailActions } from "store/ducks/grupoemail";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, Link } from "react-router-dom";

import StyledTableRow from "components/StyledTableRow";
import LoadingTable from "components/LoadingTable";
import ButtonSquare from "components/ButtonSquare";
import ToolTipComponent from "components/Tooltip";
import ViewModalDelete from "../ModalDelete/index";

function TableGrupoEmail() {
  const { pathname } = useLocation();

  const dispatch = useDispatch();
  const {
    loading,
    list: grupoemail,
    total,
    page,
    limit,
    sort,
    search,
  } = useSelector((state) => state.grupoemail);

  const {
    access: { servicos },
  } = useSelector((state) => state.permissoes);

  useEffect(() => {
    let find = servicos.find((item) => item.route === pathname);
    if (find !== undefined) {
      setUserActions(find);
    }
  }, [pathname, servicos]);

  const [userActions, setUserActions] = useState([]);

  const hasActions = useMemo(
    () =>
      true ||
      (userActions.actions &&
        userActions.actions.some(
          (userAct) => userAct.action === "editar" || userAct.action === "criar"
        )),
    [userActions]
  );

  function handleDelete(grupoemail) {
    dispatch(GrupoEmailActions.openDelete(grupoemail));
  }

  function handleChangeRowsPerPage({ target }) {
    const { value } = target;
    dispatch(
      GrupoEmailActions.listRequest({
        page,
        limit: value,
        sort,
        search,
      })
    );
  }

  function handleChangePage(e, newPage) {
    dispatch(
      GrupoEmailActions.listRequest({
        page: newPage + 1,
        limit,
        sort,
        search,
      })
    );
  }

  useEffect(() => {
    dispatch(GrupoEmailActions.listRequest({}));
  }, [dispatch]);

  return (
    <TableContainer>
      <ViewModalDelete />
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Grupo</TableCell>
            <TableCell>Emails</TableCell>
            {userActions.actions &&
              userActions.actions.map(
                (userAct, index) =>
                  userAct.action === "editar" && (
                    <TableCell key={index} align="center">
                      Ações
                    </TableCell>
                  )
              )}
          </TableRow>
        </TableHead>
        <TableBody>
          {!!loading && <LoadingTable columns={hasActions ? 4 : 2} />}
          {!loading &&
            grupoemail?.map((grupoemail) => {
              const { _id, nome, emails } = grupoemail;
              const keys = Object.keys(emails);
              return (
                <StyledTableRow key={_id}>
                  <TableCell>{nome}</TableCell>
                  <TableCell>
                    {keys.map((e, index) => (
                      <div key={index}>{emails[e]}</div>
                    ))}
                  </TableCell>
                  {userActions.actions &&
                    userActions.actions.map(
                      (userAct, index) =>
                        userAct.action === "editar" && (
                          <TableCell align="center" key={index}>
                            <div className="flex-direction">
                              <ToolTipComponent title="Editar" placement="top">
                                <Link
                                  to={{
                                    pathname: "/grupoemail/edit",
                                    state: { grupoemail: grupoemail },
                                  }}
                                  className="linkicon"
                                  id="grupoemail"
                                >
                                  <ButtonSquare icon="edit" />
                                </Link>
                              </ToolTipComponent>
                              <ToolTipComponent title="Remover" placement="top">
                                <div>
                                  <ButtonSquare
                                    onClick={() => handleDelete(grupoemail)}
                                    icon="delete"
                                    style={{ marginLeft: "1rem" }}
                                  />
                                </div>
                              </ToolTipComponent>
                            </div>
                          </TableCell>
                        )
                    )}
                </StyledTableRow>
              );
            })}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              count={total || 0}
              page={page - 1}
              rowsPerPage={limit || 10}
              rowsPerPageOptions={[10, 25, 50, 100]}
              labelRowsPerPage="Linhas por página"
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} de ${count !== -1 ? count : `mais que ${to}`}`
              }
              onRowsPerPageChange={handleChangeRowsPerPage}
              onPageChange={handleChangePage}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}

export default TableGrupoEmail;
