import React, { useState, useEffect, useRef, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";

import CircularProgress from "@material-ui/core/CircularProgress";
import AutocompleteForm from "components/AutocompleteForm";
import Skeleton from "@material-ui/lab/Skeleton";
import SeparadorLayout from "../Layout/Separador/index";
import CheckboxForm from "components/CheckboxForm";

import { Creators as TiposDocumentoActions } from "store/ducks/tiposdocumento";
import { Creators as DepartamentosActions } from "store/ducks/departamentos";
import { Creators as EmpresasActions } from "store/ducks/empresas";
import { Creators as ExportacaoActions } from "store/ducks/exportacao";

import { toastr } from "react-redux-toastr";
import { Row, Col } from "react-bootstrap";
import { Form } from "@unform/web";

import DefaultInput from "components/DefaultInput";
import BackButton from "components/BackButton";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Content from "components/Content";

const INITIAL_FORM = {
  tipoDocumento: [],
  departamento: "",
  empresa: [],
  layout: "",
  formato: { arquivo: "csv", separador: ";" }, // hoje está fixo pois só existe esse formato de exportação no momento
  jaExportado: false,
  numero: "",
  situacao: "",
  dataInicioAbertura: null,
  dataFimAbertura: null,
  dataInicioImportacao: null,
  dataFimImportacao: null,
  processoEmAndamento: false,
  enviarEmail: false,
};

export default function Exportacao() {
  const dispatch = useDispatch();
  const formRef = useRef(null);
  const [loading] = useState(false);
  const [form, setForm] = useState(INITIAL_FORM);

  const {
    access: { empresas },
  } = useSelector((state) => state.permissoes);

  const { list: departamentosList, loading: loadingDepartamentos } =
    useSelector((state) => state.departamentos);

  const { list: empresasList, loading: loadingEmpresas } = useSelector(
    (state) => state.empresas
  );

  const { data: exportacaoarquivo, loading: loadingExportacao } = useSelector(
    (state) => state.exportacao || {}
  );

  const listEmpresas = useMemo(() => {
    setForm((f) => ({ ...f, empresa: null }));
    if (!form.tipoDocumento) {
      return [];
    }

    return empresasList.filter((emp) =>
      form.tipoDocumento?.empresas?.includes(emp._id)
    );
  }, [form.tipoDocumento, empresasList]);

  const listDepartamentos = useMemo(() => {
    setForm((f) => ({ ...f, departamento: null }));
    if (!form.empresa) {
      return [];
    }

    return departamentosList.filter((dep) => dep.empresa === form.empresa._id);
  }, [form.empresa, departamentosList]);

  useEffect(() => { }, [form.layout]);

  useEffect(() => {
    if (!exportacaoarquivo?.data) return;

    const element = document.createElement("a");
    const file = new Blob([exportacaoarquivo?.data], {
      type: "text/plain;charset=utf-8",
    });
    element.href = URL.createObjectURL(file);
    element.download = "extracao.csv";
    document.body.appendChild(element);
    element.click();
  }, [exportacaoarquivo]);

  useEffect(() => {
    dispatch(EmpresasActions.listRequest());
    dispatch(TiposDocumentoActions.listByEmpresaRequest(empresas));
    dispatch(DepartamentosActions.listRequest({ onlyOperational: true }));
  }, [dispatch, empresas]);

  useEffect(() => {
    if (listEmpresas.length === 1) {
      const [empresa] = listEmpresas;
      setForm((f) => ({ ...f, empresa }));
    }
    if (listDepartamentos.length === 1) {
      const [departamento] = listDepartamentos;
      setForm((f) => ({ ...f, departamento }));
    }
  }, [listEmpresas, listDepartamentos]);

  const onSubmit = async (values) => {
    try {
      if (values?.empresa == null) {
        return toastr.error("Empresa é obrigátorio");
      }

      if (values?.departamento == null) {
        return toastr.error("Departamento é obrigátorio");
      }

      values.formato = INITIAL_FORM.formato;

      setForm({ ...form, processoEmAndamento: true });

      dispatch(ExportacaoActions.createRequest(values));
    } catch (err) {
      return toastr.error("Ops", "Ocorreu um erro.");
    }
  };

  return (
    <Content>
      <Row>
        <Col md="12" className="painel">
          <div className="painel-content">
            <div className="painel-title text-left">
              Exportação de documentos
            </div>
            <div className="painel-body">
              <Form ref={formRef} onSubmit={onSubmit} className={"mt-2"}>
                <SeparadorLayout titulo="Filtros obrigatórios" />
                <Row>
                  <Col md={3} sm={12}>
                    {loadingEmpresas ? (
                      <Skeleton width="100%" height={50} />
                    ) : (
                      <AutocompleteForm
                        label="Empresa"
                        name="empresa"
                        options={empresasList}
                        transformOnSelect={(option) =>
                          option ? option._id : null
                        }
                        getOptionLabel={(option) => option.fantasia}
                      />
                    )}
                  </Col>
                  <Col md={3} sm={12}>
                    {loadingDepartamentos ? (
                      <Skeleton width="100%" height={50} />
                    ) : (
                      <AutocompleteForm
                        label="Departamento"
                        name="departamento"
                        options={departamentosList}
                        transformOnSelect={(option) =>
                          option ? option._id : null
                        }
                        getOptionLabel={(option) => option.nome}
                      />
                    )}
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md={3} sm={12}>
                    <DefaultInput
                      style={{ margin: 0 }}
                      name="dataInicioImportacao"
                      label="Início importação"
                      //onChange={handleStartDateChange}
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      required
                    />
                  </Col>
                  <Col md={3} sm={12}>
                    <DefaultInput
                      style={{ margin: 0 }}
                      name="dataFimImportacao"
                      label="Término importação"
                      //onChange={handleStartDateChange}
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      required
                    />
                  </Col>
                </Row>
                <SeparadorLayout titulo="Opcionais" />
                <Row>
                  <Col md={12} sm={12}>
                    <CheckboxForm
                      label="Execução assíncrona (Recomendável para grandes períodos)"
                      name="enviarEmail"
                    />
                  </Col>
                </Row>

                <Col md="12" sm="12">
                  <Row>
                    <Grid container spacing={1}>
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          disabled={loading}
                        >
                          Exportar
                          {!!loadingExportacao &&
                            form.processoEmAndamento && (
                              <CircularProgress
                                style={{ marginLeft: "5px" }}
                                color="secondary"
                                size={20}
                              />
                            )}
                        </Button>
                      </Grid>
                      <Grid item>
                        <BackButton variant="contained" color="secondary">
                          Voltar
                        </BackButton>
                      </Grid>
                    </Grid>
                  </Row>
                </Col>
              </Form>
            </div>
          </div>
        </Col>
      </Row>
    </Content>
  );
}
