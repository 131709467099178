export const Types = {
    LIST_LANCAMENTOS_REQUEST: 'dividaativa/LIST_LANCAMENTOS_REQUEST',
    LIST_LANCAMENTOS_SUCCESS: 'dividaativa/LIST_LANCAMENTOS_SUCCESS',

    LIST_VALORES_REQUEST: 'dividaativa/LIST_VALORES_REQUEST',
    LIST_VALORES_SUCCESS: 'dividaativa/LIST_VALORES_SUCCESS',

    LIST_ANALITICO_REQUEST: 'dividaativa/LIST_ANALITICO_REQUEST',
    LIST_ANALITICO_SUCCESS: 'dividaativa/LIST_ANALITICO_SUCCESS'
}


const INITIAL_STATE = {
    listLancamentos: [],
    listValores: [],
    listAnalitico: [],
    loading: false
}

export default function dividaativa(state = INITIAL_STATE, action) {
    switch (action.type) {
        case Types.LIST_LANCAMENTOS_REQUEST:
            return { ...state, loading: true }
        case Types.LIST_LANCAMENTOS_SUCCESS:
            return { ...state, loading: false, listLancamentos: action.payload }

        case Types.LIST_VALORES_REQUEST:
            return { ...state, loading: true }
        case Types.LIST_VALORES_SUCCESS:
            return { ...state, loading: false, listValores: action.payload }

        case Types.LIST_ANALITICO_REQUEST:
                return { ...state, loading: true }
        case Types.LIST_ANALITICO_SUCCESS:
                return { ...state, loading: false, listAnalitico: action.payload }    

        default:
            return state;
    }
}


export const Creators = {

    listLancamentosRequest: dividalancamentos => ({
        type: Types.LIST_LANCAMENTOS_REQUEST,
        payload: dividalancamentos
    }),

    listLancamentosSuccess: dividalancamentos => ({
        type: Types.LIST_LANCAMENTOS_SUCCESS,
        payload: dividalancamentos
    }),

    listValoresRequest: dividavalores => ({
        type: Types.LIST_VALORES_REQUEST,
        payload: dividavalores
    }),

    listValoresSuccess: dividavalores => ({
        type: Types.LIST_VALORES_SUCCESS,
        payload: dividavalores
    }), 

    listAnaliticoRequest: dividaanalitico => ({
        type: Types.LIST_ANALITICO_REQUEST,
        payload: dividaanalitico
    }),

    listAnaliticoSuccess: dividaanalitico => ({
        type: Types.LIST_ANALITICO_SUCCESS,
        payload: dividaanalitico
    })
}    