import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import ModalFormulario from "../ModalFormulario";

import '../styles.css';

const ETAPA_PREENCHIMENTO = 0;

export default function PreviewFormulario({ initialData, itsPreview, onClose, open }) {

    const { campos, nome, mensagem, idTipoDocumento, steps, tiposDeImagem } = initialData;
    const [tipoDocumento, setTipoDocumento] = useState(null);
    const [activeStep, setActiveStep] = useState(ETAPA_PREENCHIMENTO);

    const { listByEmpresas: tipodocumentosList, loading: loadingIipodocumentos } = useSelector(
        (state) => state.tipoDocumentos
    );

    //#region funções auxiliares
    function rule(indexador) {
        return itsPreview ? false : indexador.obrigatorio && !indexador.valor
    }

    function hasIndexadorError(indexador) {
        return rule(indexador) ? true : false;
    }

    function hasIndexadorTextError(indexador) {
        if (rule(indexador)) return "Campo obrigatório";
        return "";
    }
    //#endregion

    //#region efeitos
    useEffect(() => {
        const tipoDocumento = tipodocumentosList.find(e => e._id === idTipoDocumento);
        setTipoDocumento(tipoDocumento);
        setActiveStep(ETAPA_PREENCHIMENTO);
    }, [tipodocumentosList, loadingIipodocumentos, open]);
    //#endregion

    return (
        <ModalFormulario
            steps={steps}
            tiposDeImagem={tiposDeImagem}
            open={open}
            title={nome}
            campos={campos}
            onClose={onClose}
            mensagem={mensagem}
            activeStep={activeStep}
            indexadores={tipoDocumento?.indexadores}
            handleSave={null}
            hasIndexadorError={hasIndexadorError}
            hasIndexadorTextError={hasIndexadorTextError}
        />
    );
}
