export const Types = {
    LOGIN_REQUEST: "LOGIN_REQUEST",
    LOGIN_SUCCESS: "LOGIN_SUCCESS",
    LOGIN_FAILURE: "LOGIN_FAILURE",
    LOGOUT: "LOGOUT",
}

export const loginRequest = (accessToken, idToken, code, state) => ({
    type: Types.LOGIN_REQUEST,
    payload: { accessToken, idToken, code, state },
});

export const loginSuccess = (auth) => ({
    type: Types.LOGIN_SUCCESS,
    payload: auth,
});

export const loginFailure = (error) => ({
    type: Types.LOGIN_FAILURE,
    payload: error,
});

export const logout = () => ({
    type: Types.LOGOUT,
});

const initialState = {
    auth: null,
};

export default function authReducer(state = initialState, action) {
    switch (action.type) {
        case Types.LOGIN_SUCCESS:
            return {
                ...state,
                auth: action.payload,
            };
        case Types.LOGOUT:
            return {
                ...state,
                auth: null,
            };
        case Types.LOGIN_FAILURE:
            return {
                ...state,
                auth: null,
                error: action.payload,
            };
        default:
            return state;
    }
};

export const Creators = {
    loginRequest: (payload) => ({
        type: Types.LOGIN_REQUEST,
        payload: payload,
    }),

    loginSuccess: (payload) => ({
        type: Types.LOGIN_SUCCESS,
        payload: payload,
    }),

    loginFailure: (payload) => ({
        type: Types.LOGIN_FAILURE,
        payload: payload,
    }),

    logout: (payload) => ({
        type: Types.LOGOUT,
        payload: payload,
    }),

};

