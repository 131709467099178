import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Timeline from '@material-ui/lab/Timeline';

import ComponenteHistorico from '../Historico/ComponenteHistorico';
import DadosAlterados from './DadosAlterados';
import DadosAuditados from './DadosAuditados';
import { Box, MenuItem, TextField, makeStyles } from '@material-ui/core';
import { Button, Col } from 'react-bootstrap';
import { AlignJustify, Info } from 'components/Icons';

const useStyles = makeStyles(() => ({
  col: {
    marginTop: '0.5rem',
    gap: '0 1rem',
    display: 'flex',
    alignItems: 'center',
  },
  fullWidth: {
    width: '100%',
  },
}));

export default function CustomizeTimeline({ fullzise = false }) {
  const auditoriaDoc = useSelector((state) => state.auditoria.documentData);
  const styles = useStyles();
  const [searchActionTitle, setSearcActionTitle] = useState('');
  const auditDocumentFilterTitles = [
    ...new Set(auditoriaDoc.map((item) => item.detalhes.acao).filter(Boolean)),
  ];

  const handleFilterByTItle = (searchActionTitle) => {
    if (searchActionTitle) {
      return auditoriaDoc.filter(
        (item) => item.detalhes.acao === searchActionTitle
      );
    }
    return auditoriaDoc.filter(
      (item) => item.detalhes.acao !== 'Indexador alterado'
    );
  };

  const [open, setOpen] = useState(false);
  const [logs, setLogs] = useState([]);
  const [titulo, setTitulo] = useState('');
  const [isSimplified, setIsSimplified] = useState(true);

  function handleShowlogs(dadosAlterados, titulo) {
    setLogs(dadosAlterados);
    setTitulo(titulo);
    setOpen(true);
  }

  return (
    <div className={styles.fullWidth}>
      <div className="controlpanel-header">
        <div className="controlpanel-item comments-header-spacing">
          <TextField
            size="small"
            label="Filtrar por titulo"
            id="select"
            variant="outlined"
            value={searchActionTitle}
            onChange={(event) => {
              setSearcActionTitle(event.target.value);
            }}
            select
          >
            <MenuItem value={''}>Nenhum</MenuItem>
            {auditDocumentFilterTitles.map((auditDocumentFilterTitle) => (
              <MenuItem
                key={auditDocumentFilterTitle}
                value={auditDocumentFilterTitle}
              >
                {auditDocumentFilterTitle}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <div className="controlpanel-item comments-header-spacing">
          {auditoriaDoc.length && (
            <Box display={'flex'} gridGap={12} my={1}>
              <button
                className="btn btn-fit btn-secondary"
                onClick={() => setIsSimplified(true)}
              >
                Simplificada
                <Info className="ml-2" />
              </button>
              <button
                className="btn  btn-fit btn-secondary"
                onClick={() => setIsSimplified(false)}
              >
                Detalhada
                <AlignJustify className="ml-2" />
              </button>
            </Box>
          )}
        </div>
      </div>

      <div className="border-top"></div>

      <div
        className={styles.fullWidth}
        style={{
          maxHeight: fullzise ? '100%' : '400px',
          overflow: 'scroll',
        }}
      >
        <Timeline align={isSimplified ? 'alternate' : 'left'}>
          {auditoriaDoc &&
            handleFilterByTItle(searchActionTitle).map((adoc, index) => (
              <ComponenteHistorico
                key={index}
                document={adoc}
                onShowlogs={handleShowlogs}
                isSimplified={isSimplified}
              />
            ))}
        </Timeline>
        {logs.length > 0 && Object.keys(logs[0]).includes('visivel') ? (
          <DadosAuditados
            open={open}
            titulo={titulo}
            onClose={() => setOpen(false)}
            dadosAlterados={logs}
          />
        ) : (
          <DadosAlterados
            open={open}
            onClose={() => setOpen(false)}
            dadosAlterados={logs}
          />
        )}
      </div>
    </div>
  );
}
