import React, { useState, useMemo } from 'react';
import {
  Typography, TextField, Checkbox, FormControlLabel,
  Button, CircularProgress
} from '@material-ui/core';
import TreeView from '@material-ui/lab/TreeView';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import BackButton from 'components/BackButton';

import { Creators as ImagensModalidadeActions } from 'store/ducks/imagensmodalidade';
import { Creators as AuditoriaActions } from 'store/ducks/auditoria';

import StyledTreeItem from './StyledTreeItem';
import useStyles from './styles';
import { ArrowDownIcon, ArrowRightIcon, ICONS_CUSTOM_FIELDS } from 'components/Icons';
import Content from 'components/Content';

const filter = createFilterOptions();

function IndexadoresModalidade({ location }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { modalidadeEmpresa } = location.state;

  const { loading } = useSelector(state => state.imagensmodalidade);

  const [indexadores, setIndexadores] = useState(modalidadeEmpresa.indexadores);
  const [indexador, setIndexador] = useState();
  const [tabela, setTabela] = useState(null);

  const separadores = useMemo(() => {
    const separadores = indexadores.map(idx => idx.separador);
    return separadores.filter((separador, i) => separadores.indexOf(separador) === i && separador);
  }, [indexadores]);

  function handleChange({ target }) {
    let campo;
    const index = indexadores.indexOf(tabela ? tabela : indexador);
    if (tabela) {
      campo = indexadores[index].campos.indexOf(indexador);
    }

    const { name, value, checked, type } = target;
    const newValue = {
      ...indexador,
      [name]: type === 'checkbox' ? checked : value
    }
    setIndexador(newValue)

    if (tabela) {
      indexadores[index].campos[campo] = newValue;
    } else {
      indexadores[index] = newValue;
    }
    setIndexadores([...indexadores]);
  }

  function handleChangeAutocomplete(name, value) {
    const index = indexadores.indexOf(indexador);

    const newValue = indexador;
    delete newValue[name];
    if (value) {
      if (typeof value === 'string') {
        newValue[name] = value;
      } else if (Array.isArray(value)) {
        newValue[name] = value.map(opcao =>
          typeof opcao === 'string' ? opcao : opcao.inputValue
        )
      } else {
        newValue[name] = value.inputValue;
      }
    }

    indexadores[index] = newValue;
    setIndexadores([...indexadores]);
  }

  function handleClickActiveInactive(indexador, tabela) {
    let campo;
    const index = indexadores.indexOf(tabela ? tabela : indexador);
    if (tabela) {
      campo = indexadores[index].campos.indexOf(indexador);
    }

    const newValue = {
      ...indexador,
      status: indexador.status === 'INATIVO' ? 'ATIVO' : 'INATIVO',
      campos: indexador.tipo === 'tabela' ?
        indexador.campos.map(item => ({
          ...item,
          status: indexador.status === 'INATIVO' ? 'ATIVO' : 'INATIVO'
        }))
        : null
    }
    setIndexador(newValue);

    if (tabela) {
      indexadores[index].campos[campo] = newValue;
    } else {
      indexadores[index] = newValue;
    }
    setIndexadores([...indexadores]);

    dispatch(AuditoriaActions.createRequest({
      detalhes: {
        acao: indexador.status === 'INATIVO' ?
          `Ativou o indexador ${indexador.nome}`
          :
          `Desativou o indexador ${indexador.nome}`,
        route: location.pathname,
        modalidade: modalidadeEmpresa._id,
        categoria: 'modalidadeEmpresa'
      }
    }));

    dispatch(
      ImagensModalidadeActions.saveRequest({
        modalidade: modalidadeEmpresa.modalidade._id,
        empresa: modalidadeEmpresa.empresa._id,
        indexadores
      })
    );
  }

  function handleSave() {
    if (!_.isEqual(indexadores, modalidadeEmpresa.indexadores)) {
      const Indexadores = {}

      modalidadeEmpresa.indexadores.forEach((item, index) => {
        if (!_.isEqual(item, indexadores[index])) {
          Indexadores[item.nome] = {};
          const fields = Object.keys(item);
          fields.forEach(field => {
            Indexadores[item.nome] = {
              ...Indexadores[item.nome],
              [field]: {
                valorAnterior: item[field],
                valorNovo: indexadores[index][field]
              }
            };
          });
        }
      });

      if (!_.isEqual(Indexadores, {})) {
        dispatch(AuditoriaActions.createRequest({
          detalhes: {
            acao: 'Alteração dos Indexadores',
            route: location.pathname,
            modalidade: modalidadeEmpresa._id,
            categoria: 'modalidade',
            dadosAlterados: {
              Indexadores
            },
          }
        }));
      }
    }

    dispatch(
      ImagensModalidadeActions.saveRequest({
        modalidade: modalidadeEmpresa.modalidade._id,
        empresa: modalidadeEmpresa.empresa._id,
        indexadores
      })
    );
  }

  return (
    <Content>
      <Row>
        <Col md="6" className="painel">
          <div className="painel-content">
            <div className="painel-title text-left">
              <div className={classes.header}>
                <div className={classes.title}>
                  <Typography className={classes.bold}>
                    Modalidade:&nbsp;
                  </Typography>
                  <Typography>{modalidadeEmpresa.modalidade.nome}</Typography>
                </div>
                <div className={classes.title}>
                  <Typography className={classes.bold}>
                    Empresa:&nbsp;
                  </Typography>
                  <Typography>{modalidadeEmpresa.empresa.fantasia}</Typography>
                </div>
              </div>
              <BackButton
                color="primary"
                variant="contained"
              >Voltar</BackButton>
            </div>
            <div className="painel-body">
              <Row>
                <Col md="12">
                  <TreeView
                    defaultCollapseIcon={<ArrowDownIcon />}
                    defaultExpandIcon={<ArrowRightIcon />}
                  >
                    {indexadores.map(indexador =>
                      <StyledTreeItem
                        key={indexador.nome}
                        nodeId={indexador.nome}
                        labelText={indexador.nome}
                        labelIcon={ICONS_CUSTOM_FIELDS[indexador.tipo]}
                        status={indexador.status}
                        onClick={() => {
                          setIndexador(indexador);
                          setTabela(null);
                        }}
                        onClickActiveInactive={() => handleClickActiveInactive(indexador)}
                      >
                        {indexador.campos && indexador.campos.map(field =>
                          <StyledTreeItem
                            key={`${indexador.nome}-${field.nome}`}
                            nodeId={`${indexador.nome}-${field.nome}`}
                            labelText={field.nome}
                            status={field.status}
                            labelIcon={ICONS_CUSTOM_FIELDS[field.tipo]}
                            onClick={() => {
                              setIndexador(field);
                              setTabela(indexador);
                            }}
                            onClickActiveInactive={() => handleClickActiveInactive(field, indexador)}
                          />
                        )}
                      </StyledTreeItem>
                    )}
                  </TreeView>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
        <Col md="6" className="painel">
          <div className="painel-content">
            <div className="painel-title text-left">
              <Typography variant="h4">Indexador</Typography>
            </div>
            <div className="painel-body">
              {indexador &&
                <Row>
                  <Col md="12" className={classes.form}>
                    <Row>
                      <Col md="6">
                        <TextField
                          label="Nome"
                          name="nome"
                          variant="outlined"
                          size="small"
                          value={indexador.nome}
                        />
                      </Col>
                      <Col md="6">
                        <TextField
                          label="Tipo"
                          name="tipo"
                          variant="outlined"
                          size="small"
                          value={indexador.tipo}
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <TextField
                          label="Máscara"
                          name="mascara"
                          variant="outlined"
                          size="small"
                          value={indexador.mascara}
                        />
                      </Col>
                      <Col md="6">
                        <TextField
                          label="Limite de caracteres"
                          name="limiteCaracteres"
                          value={indexador.limiteCaracteres || ''}
                          onChange={handleChange}
                          variant="outlined"
                          size="small"
                          type="number"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <TextField
                          label="Nome OCR"
                          name="icapt"
                          variant="outlined"
                          size="small"
                          value={indexador.icapt}
                        />
                      </Col>
                      <Col md="6">
                        <Autocomplete
                          freeSolo
                          selectOnFocus
                          clearOnBlur
                          id="free-solo-with-text-demo"
                          name="separador"
                          value={indexador.separador || ''}
                          onChange={(e, value) => handleChangeAutocomplete('separador', value)}
                          options={separadores}
                          disabled={!!tabela}
                          renderInput={params =>
                            <TextField
                              {...params}
                              label="Separador"
                              variant="outlined"
                              name="separador"
                              error={(!indexador.separador || indexador.separador === '') && indexador.tipo === 'tabela'}
                              helperText={(!indexador.separador || indexador.separador === '') && indexador.tipo === 'tabela' && 'Separador é obrigatório para o tipo de campo tabela!'}
                            />
                          }
                          getOptionLabel={(option) => {
                            if (typeof option === 'string') {
                              return option;
                            }
                            if (option && option.inputValue) {
                              return option.inputValue;
                            }
                          }}
                          filterOptions={(options, params) => {
                            const filtered = filter(options, params);

                            if (params.inputValue !== '') {
                              filtered.push({
                                inputValue: params.inputValue,
                                title: `Novo separador "${params.inputValue}"`
                              });
                            }

                            return filtered;
                          }}
                          renderOption={option => {
                            if (typeof option === 'string') {
                              return option;
                            }
                            return option.title;
                          }}
                          size="small"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={indexador.tipo === 'combo' ? 6 : 12}>
                        <TextField
                          label="Ordem da Tela de Esteira"
                          name="ordemEsteira"
                          variant="outlined"
                          size="small"
                          value={indexador.ordemEsteira}
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </Col>
                      {indexador.tipo === 'combo' &&
                        <Col md={6}>
                          <Autocomplete
                            freeSolo
                            multiple
                            selectOnFocus
                            clearOnBlur
                            id="options-combo"
                            name="opcoes"
                            value={indexador.opcoes || []}
                            onChange={(e, value) => handleChangeAutocomplete('opcoes', value)}
                            options={[]}
                            renderInput={params =>
                              <TextField
                                {...params}
                                label="Opções"
                                variant="outlined"
                                name="opcoes"
                                error={!indexador.opcoes || indexador.opcoes.length === 0}
                                helperText={(!indexador.opcoes || indexador.opcoes.length === 0) && 'Opções são obrigatórias ao informar indexador do tipo combo!'}
                              />
                            }
                            getOptionLabel={(option) => typeof option === 'string' ? option : option.inputValue}
                            filterOptions={(options, params) => [{
                              inputValue: params.inputValue,
                              title: `Nova opção "${params.inputValue}"`
                            }]}
                            renderOption={option => typeof option === 'string' ? option : option.title}
                            size="small"
                          />
                        </Col>
                      }
                    </Row>
                    <Row>
                      <Col md="12">
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={indexador.limpar}
                              name="limpar"
                              color="primary"
                              onChange={handleChange}
                            />
                          }
                          label="Limpar após interação"
                        />
                      </Col>
                      <Col md="12">
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={indexador.obrigatorio}
                              name="obrigatorio"
                              color="primary"
                              onChange={handleChange}
                            />
                          }
                          label="Obrigatório"
                        />
                      </Col>
                      <Col md="12">
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={indexador.indexar}
                              name="indexar"
                              color="primary"
                              onChange={handleChange}
                            />
                          }
                          label="Pesquisável"
                        />
                      </Col>
                      <Button
                        color="primary"
                        variant="contained"
                        className={classes.button}
                        onClick={handleSave}
                        disabled={loading}
                      >
                        Salvar
                        {!!loading && <CircularProgress size={20} />}
                      </Button>
                    </Row>
                  </Col>
                </Row>
              }
            </div>
          </div>
        </Col>
      </Row>
    </Content>
  );
}

export default IndexadoresModalidade;