import api from "services/api";
import { getToken } from "services/auth";
import { call, select, put } from "redux-saga/effects";
import { toastr } from "react-redux-toastr";

import { Creators as DashboardExternoActions } from "../ducks/dashboardexterno";

export const getOrganization = (state) => state.usuarios.auth.organization;
export const getUserId = (state) => state.usuarios.auth.id;

export function* getDashboardExterno({ payload }) {
  try {
    const { dashboard } = payload;

    const queryParams = !!dashboard ? `?id=${dashboard}` : "";
    const route = `external-dashboard${queryParams}`;

    const organization = yield select(getOrganization);

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const response = yield call(api.get, route, headerParams);

    yield put(DashboardExternoActions.newSuccess(response.data));
  } catch (error) {
    toastr.error("Ops", "Não foi possível buscar o dashboard externo.");
  }
}
