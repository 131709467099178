import React from 'react';
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';
import List from '../Relacionamento/ListRelacionamento';

import { Row, Col } from "react-bootstrap";
import Button from '@material-ui/core/Button';

import "./styles.css";
import Content from 'components/Content';

export default function Relacionamento() {

    const { access: { relacionamentos: relacionamentosAccess, servicos } } = useSelector(state => state.permissoes);
    const { access: { empresas: empresasAccess } } = useSelector(state => state.permissoes);

    return (
        <Content>
            <Row>
                <Col md="12" className="painel">
                    <div className="painel-content">
                        <div className="painel-title text-left">
                            <div></div>
                            <Button
                                component={Link}
                                variant="contained"
                                color="primary"
                                to={"/administracao/relacionamento/novo"}
                            >
                                Novo Relacionamento
                            </Button>
                        </div>
                        <div className="painel-body">
                            <List />
                        </div>
                    </div>
                </Col>
            </Row>
        </Content>
    );
}