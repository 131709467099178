import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toastr } from "react-redux-toastr";
import { Form } from "@unform/web";

import { Button, DialogActions, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

import InputForm from "components/InputForm";
import Modal from "components/Modal";

import Utils from "utils/utils";

import { Creators as FormularioActions } from "store/ducks/formulario";
import AutocompleteForm from "components/AutocompleteForm";

export default function ModalShare({ open, onCloseModal, idFormulario }) {

    const dispatch = useDispatch();

    const [openModal, setOpenModal] = useState(open);
    const [grupoemail, setGrupoEmail] = useState([]);
    const [emails, Emails] = useState([]);
    const [host, setHost] = useState('');
    const [tenant, setTenant] = useState('');

    const formRef = useRef(null);
    const initialData = {
        url: `${host || ''}/formulario-publico/${idFormulario || 'foo'}-${tenant}`,
        grupoemail: [],
        emails: []
    };

    const [sending, setSending] = useState(false);

    const { origin } = useSelector((state) => state.formularios);
    const { organization } = useSelector((state) => state.usuarios.auth);
    const { list: grupoemailLoaded } = useSelector((state) => state.grupoemail);
    //#region funções auxiliares

    function setForm(name, value) {
        formRef.current.setFieldValue(name, value);
    }

    const onChangeAutocomplete = (name, value, e) => {
        const util = new Utils();
        const email = value?.map((e) => typeof e === "object" ? e.inputValue.toLocaleLowerCase() : e.toLocaleLowerCase());
        const emails = util.handlePastedEmails(email);
        Emails(emails);
    };

    function handleChangeAutocomplete(name, value) {
        setForm(name, value);
    }

    function onSubmit(value) {
        try {
            setSending(true);

            const grupoemail = value.grupoemail.emails;
            const toSend = new Set();

            grupoemail?.map(e => toSend.add(e));
            emails?.map(e => toSend.add(e));

            const payload = {
                url: initialData.url,// url: value.url,//TODO: desconmentar esse trecho quando definir o processo de autenticação do "copia e cola"
                emails: Array.from(toSend)
            }

            if (!payload.emails.length) {
                toastr.warning("Informe pelo menos um e-mail para compartilhar o link");
                return;
            }

            dispatch(FormularioActions.sendRequest(payload));

            onCloseModal();

        }
        catch (e) {
            toastr.error("Ops", e.message)
        }
        finally {
            setSending(false);
        }
    }
    //#endregion

    //#region funções auxiliares
    useEffect(() => {
        setGrupoEmail(grupoemailLoaded);
    }, [grupoemailLoaded]);

    useEffect(() => {
        setHost(origin);
    }, [origin]);

    useEffect(() => {
        setOpenModal(open);
    }, [open])

    useEffect(() => {
        setTenant(organization);
    }, [organization])
    //#endregion

    return (
        <Modal
            title={'Compartilhamento de formulário'}
            isOpen={openModal}
            onClose={onCloseModal}>

            <Form
                ref={formRef}
                onSubmit={onSubmit}
                initialData={initialData}
                className={"mt-2"}
            >

                {/* <InputForm //TODO: quando definirmos a autenticação do "copi e cola", basta descomentar esse 
                    disabled
                    name={"url"}
                    label={"Dê um duplo clique para copiar o link"}
                    style={{ margin: '16px 0' }}
                /> */}

                <span>Se desejar compartilhar com um grupo de e-mails cadastrado no sistema, basta informar no campo abaixo</span>

                <AutocompleteForm
                    options={grupoemail}
                    onChange={(e, value) =>
                        handleChangeAutocomplete("emails", value)
                    }
                    getOptionLabel={(option) => option.nome}
                    getOptionSelected={(option, value) => option?._id === value?._id}
                    name="grupoemail"
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Grupo de email"
                            size="small"
                            variant="outlined"
                            className="mb-4" />
                    )} />

                <span>Se desejar compartilhar com uma lista de e-mails específica, basta informar no campo abaixo</span>

                <Autocomplete
                    freeSolo
                    multiple
                    selectOnFocus
                    clearOnBlur
                    id="options-combo"
                    name="emails"
                    style={{ marginBottom: "8px" }}
                    value={emails}
                    onChange={(e, value) => onChangeAutocomplete("emails", value)}
                    options={[]}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Adicione destinatários"
                            variant="outlined"
                            name="opcoes" />
                    )}
                    getOptionLabel={(option) => typeof option === "string" ? option : option.inputValue}
                    filterOptions={(options, params) => [
                        {
                            inputValue: params.inputValue,
                            title: `Novo e-mail "${params.inputValue}"`,
                        },
                    ]}
                    renderOption={(option) => typeof option === "string" ? option : option.title}
                    size="small" />

                <DialogActions>

                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={sending}
                    >
                        Enviar
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        disabled={sending}
                        onClick={onCloseModal}
                    >
                        Cancelar
                    </Button>

                </DialogActions>

            </Form>

        </Modal >
    )
}