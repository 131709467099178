import React, { useState, useMemo, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import DeleteIcon from "@material-ui/icons/Delete";
import Tooltip from "@material-ui/core/Tooltip";
import Fab from "@material-ui/core/Fab";
import Box from "@material-ui/core/Box";

import DynamicFieldValue from "./DynamicFieldValue";
import useForm from "react-hook-form";
import AutocompleteForm from "components/AutocompleteForm";

import { Form as FormBootstrap } from "react-bootstrap";

function FormRegra({
  register,
  regra,
  index,
  indexadores,
  tipoDocumento,
  onRemove,
  disableRemove,
}) {
  const { control, reset, setValue, getValues } = useForm();

  const [referencia, setReferencia] = useState(regra ? regra.referencia : "");
  const [condicao, setCondicao] = useState(regra ? regra.condicao : "");
  const [indexador, setIndexador] = useState(regra ? regra.indexador : null);

  const tipo = useMemo(() => {
    if (indexador) {
      if (indexadores.some((idx) => idx.index === indexador)) {
        const idx = indexadores.find((idx) => idx.index === indexador);
        return idx.tipo;
      }
    }
    return "caracter";
  }, [indexador, indexadores]);

  // const disableComparador = useMemo(() => {
  //   if (referencia !== "INDEXADOR") return true;
  //   if (tipo === "numero" || tipo === "data") return false;
  //   return true;
  // }, [tipo, referencia]);

  useEffect(() => {
    setReferencia(regra ? regra.referencia : "");
    setCondicao(regra ? regra.condicao : "");
    setIndexador(regra ? regra.indexador : null);
  }, [regra]);

  function handleChangeReferencia(e, newValue) {
    setReferencia(newValue);
    setValue(`regras[${index}].condicao`, null);
    setCondicao("");
  }

  function handleChangeCondicao(e, newValue) {
    setValue(`regras[${index}].valor`, []);
    setCondicao(newValue);
  }

  function handleChangeIndexador(e, newValue) {
    setIndexador(newValue);
  }

  function handleRemove() {
    reset();
    onRemove();
  }

  const styleBox = {
    padding: "20px",
    margin: "5px",
    boxShadow: "0px 0px 0px 1px #e0e0e0",
    borderRadius: "5px",
    marginTop: "1rem",
  };

  return (
    <>
      <Box style={styleBox}>
        <Row>
          <Col md={11}>
            <FormBootstrap.Label>Referencia:</FormBootstrap.Label>
            <AutocompleteForm
              name={`regras[${index}].referencia`}
              options={["EMPRESA", "INDEXADOR"]}
              transformOnSelect={(option) => option}
              getOptionLabel={(option) => option}
              onChange={(e, newValue) =>
                handleChangeReferencia(e, newValue)
              }
              getOptionSelected={(option, value) =>
                value === option
              }
            />
          </Col>
          <Col md={1} className="text-center">
            <Tooltip className="mt-4" title="Remover regra">
              <Fab
                onClick={handleRemove}
                style={{ marginTop: "8px" }}
                color="primary"
                size="small"
                disabled={disableRemove}
              >
                <DeleteIcon />
              </Fab>
            </Tooltip>
          </Col>
        </Row>
        {referencia === "INDEXADOR" && (
          <Row>
            <Col md={12}>
              <FormBootstrap.Label>Indexador:</FormBootstrap.Label>
              <AutocompleteForm
                name={`regras[${index}].indexador`}
                options={indexadores}
                transformOnSelect={(option) => ({
                  id_es: option.id_es,
                  nome: option.nome,
                })}
                getOptionLabel={(option) => option.nome}
                getOptionSelected={(option, value) => value._id === option._id}
                onChange={(e, newValue) =>
                  handleChangeIndexador(e, newValue?.id_es)
                }
              />
            </Col>
          </Row>
        )}
        <Row>
          <Col>
            <FormBootstrap.Label>Condição:</FormBootstrap.Label>
            <AutocompleteForm
              name={`regras[${index}].condicao`}
              options={["DENTRO_DE", "FORA_DE", "MAIOR", "MAIOR_IGUAL", "MENOR", "MENOR_IGUAL"]}
              transformOnSelect={(option) => option}
              getOptionLabel={(option) => option}
              onChange={(e, newValue) => handleChangeCondicao(e, newValue)}
              getOptionSelected={(option, value) =>
                value === option
              }
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <DynamicFieldValue
              register={register}
              condicao={condicao}
              indexador={indexador}
              indexadores={indexadores}
              referencia={referencia}
              tipoDocumento={tipoDocumento}
              index={index}
            />
          </Col>
        </Row>
      </Box>
    </>
  );
}

export default FormRegra;
