import api from 'services/api';
import { getToken } from 'services/auth'
import { put, call, select, all, takeLatest } from 'redux-saga/effects';
import { toastr } from "react-redux-toastr";

import { Creators as SeparadoresActions, Types } from 'store/ducks/separadores';

export const getOrganization = state => state.usuarios.auth.organization;

export function* listSeparadores({ payload }) {
  const organization = yield select(getOrganization);

  const { tipoDocumento } = payload;
  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization }
    }

    const { data } = yield call(
      api.get,
      `separadores/${tipoDocumento}`,
      headerParams
    );

    yield put(SeparadoresActions.listSuccess(data));

  } catch (error) {
    toastr.error('Ops', 'Não foi possível listar os separadores.');
  }
}

export default all([
  takeLatest(Types.LIST_REQUEST, listSeparadores)
])