import React, { useState, useEffect } from 'react';
import { ValidationContainer, ValidationItem } from '../StepTypesStyle';

import { Autocomplete } from '@material-ui/lab';
import DefaultInput from 'components/DefaultInput';
import { FormControlLabel, Switch } from '@material-ui/core';

export default function ValidationStep({ settings, tipoDocumento, onChange }) {
  const [validation, setValidation] = useState(settings.validation || {});

  const handleChange = (e) => {
    setValidation({ ...validation, [e.target.name]: e.target.checked });
    //onChange(settings, { ...settings, validation });
    console.log(e.target.name, e.target.checked);
  };

  useEffect(() => {
    console.log('settings', settings);
  }, [settings]);

  const handleInputChange = (name, value) => {
    setValidation({ ...validation, [name]: value });
    //onChange(settings, { ...settings, validation });
    console.log(name, value);
  };

  useEffect(() => {
    if (validation) {
      onChange(settings, { validation });
      console.log(validation.cpf_status_validation_field);
    }
  }, [validation]);

  return (
    <ValidationContainer>
      <ValidationItem>
        <FormControlLabel
          control={
            <Switch
              name="cpf_status_validation"
              color="primary"
              defaultChecked={validation.cpf_status_validation}
            />
          }
          onChange={handleChange}
          label="Validar Status do CPF"
        />
      </ValidationItem>

      {validation.cpf_status_validation && (
        <Autocomplete
          className="mb-2"
          options={tipoDocumento.indexadores}
          // multiple={true}
          getOptionLabel={(option) => option.nome}
          onChange={(e, value) =>
            handleInputChange('cpf_status_validation_field', value)
          }
          getOptionSelected={(option, value) => option.id_es === value.id_es}
          // defaultValue={tipoDocumento.indexadores.filter((item) => {
          //   return validation.cpf_status_validation_field?.id_es === item.id_es;
          // })}
          renderInput={(params) => (
            <DefaultInput
              {...params}
              name="cpf_status_validation_field"
              label="Selecione o campo para validar o CPF"
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
        />
      )}

      <ValidationItem>
        <FormControlLabel
          control={
            <Switch
              name="email_validation"
              color="primary"
              defaultChecked={validation.email_validation}
            />
          }
          onChange={handleChange}
          label="Validar email primário"
        />
      </ValidationItem>
      {validation.email_validation && (
        <Autocomplete
          className="mb-2"
          options={tipoDocumento.indexadores}
          // multiple={true}
          getOptionLabel={(option) => option.nome}
          onChange={(e, value) =>
            handleInputChange('email_validation_field', value)
          }
          getOptionSelected={(option, value) => option.id_es === value.id_es}
          // defaultValue={tipoDocumento.indexadores.filter((item) => {
          //   return fields.some((i) => i.id_es === item.id_es);
          // })}
          renderInput={(params) => (
            <DefaultInput
              {...params}
              name="email_validation_field"
              label="Selecione o campo para validar o Email"
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
        />
      )}

      <ValidationItem>
        <FormControlLabel
          control={
            <Switch
              name="birthdate_validation"
              color="primary"
              defaultChecked={validation.birthdate_validation}
            />
          }
          onChange={handleChange}
          label="Validar data de nascimento"
        />
      </ValidationItem>

      {validation.birthdate_validation && (
        <Autocomplete
          className="mb-2"
          options={tipoDocumento.indexadores}
          // multiple={true}
          getOptionLabel={(option) => option.nome}
          onChange={(e, value) =>
            handleInputChange('birthdate_validation_field', value)
          }
          getOptionSelected={(option, value) => option.id_es === value.id_es}
          // defaultValue={tipoDocumento.indexadores.filter((item) => {
          //   return fields.some((i) => i.id_es === item.id_es);
          // })}
          renderInput={(params) => (
            <DefaultInput
              {...params}
              name="birthdate_validation_field"
              label="Selecione o campo para validar a data de nascimento"
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
        />
      )}
    </ValidationContainer>
  );
}
