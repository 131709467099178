import api from "services/api";
import { getToken } from "services/auth";
import { call, select, put, all, takeLatest } from "redux-saga/effects";
import { toastr } from "react-redux-toastr";
import { push } from "connected-react-router";

import { Creators as ModalidadesActions, Types } from "store/ducks/modalidades";

export const getOrganization = (state) => state.usuarios.auth.organization;

export function* saveModalidade({ payload }) {
  const organization = yield select(getOrganization);
  const { _id, ...body } = payload;

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    if (_id) {
      yield call(api.put, `modalidades/${_id}`, body, headerParams);
    } else {
      yield call(api.post, "modalidades", body, headerParams);
    }

    yield put(push("/configuracao/modalidades"));
  } catch (error) {
    toastr.error("Ops", "Não foi possível editar a modalidade.");
  }
}

export function* listModalidades({ payload }) {
  const organization = yield select(getOrganization);
  const { page, limit, sort } = payload;

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
      params: { page, limit, sort },
    };

    const response = yield call(api.get, "modalidades", headerParams);

    const data = {
      list: response.data.docs,
      total: response.data.total,
    };

    yield put(ModalidadesActions.listSuccess(data));
  } catch (error) {
    toastr.error("Ops", "Não foi possível listar as modalidades.");
  }
}

export function* deleteModalidade({ payload }) {
  const organization = yield select(getOrganization);

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const {
      data: { status },
    } = yield call(api.delete, `modalidades/${payload}`, headerParams);

    if (status) {
      toastr.success("Modalidade removida", "Remoção realizada com sucesso.");
    } else {
      toastr.error(
        "Remoção cancelada",
        "A modalidade está atrelada a um ou mais documentos."
      );
    }

    yield put(ModalidadesActions.listRequest({ page: 1, limit: 10 }));
  } catch (error) {
    toastr.error("Ops", "Não foi possível remover a modalidade.");
  }
}

export default all([
  takeLatest(Types.LIST_REQUEST, listModalidades),
  takeLatest(Types.SAVE_REQUEST, saveModalidade),
  takeLatest(Types.DELETE_REQUEST, deleteModalidade),
]);
