import { call, put } from "redux-saga/effects";

import api from "../../services/api";

import { getToken } from "../../services/auth";

import { Creators as RedirectLoginActions } from "../ducks/redirectlogin";

export function* getRedirectLogin(xkeyorg) {
  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg },
    };

    const { data } = yield call(api.get, `/user/token/role`, headerParams);

    yield put(RedirectLoginActions.getRedirectLoginSuccess(data));
  } catch (error) {}
}
