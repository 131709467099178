import styled from 'styled-components/macro';

export const Content = styled.div`

    .MuiFormControl-marginDense {
        margin-top: 0;
    }

    .MuiFormControl-marginDense:not(:last-of-type) {
        margin-bottom: 8px;
    }

    .MuiFormControl-marginDense:not(:first-of-type) {
        margin-top: 8px;
    }

    .MuiAutocomplete-root:not(:first-of-type) {
        margin-top: 8px;
    }

    legend {
        font-size: 16px;
    }

    button:not(:last-of-type) {
        margin-right: 8px;
    }
`;

export const Fieldset = styled.fieldset`
    display: flex;
    flex-direction: row !important; 
    flex-wrap: nowrap !important;
    box-sizing: border-box;
    width: 100%;
    padding: 10px 0;
`;

export const Column = styled.div`
    width: 50%;
    margin-right: 16px;
    display: flex;
    flex-direction: column;

    .MuiFormControl-marginDense:not(:first-of-type) {
        margin-top: 8px !important;
        margin-right: 16px;
    }

`;

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    width: 50%;
    margin-right: 16px;
    justify-content: center;
    align-items: center;

    .MuiFormControl-marginDense:not(:first-of-type) {
        margin-top: 8px !important;
        margin-right: 16px;
    }
`;

export const LesserColumn = styled(Column)`
    display: flex;
    flex-direction: column;
    width: 30%;

    .MuiFormControl-marginDense {
        margin-top: 0;
    }
`;

export const DoubleInputs = styled.div`

    display: flex;
    flex-direction: row;

    .MuiAutocomplete-root:not(:first-of-type) {
        margin-top: 0;
        margin-left: 16px;
    }

`;

export const HeaderWithButton = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
`;

export const ListDependenciasFields = styled.div`
    width: 100%;
`;

export const ListDependenciasFieldsItem = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 8px;
    margin-bottom: 40px

    //Para a primeira div inserir margin-right
    > div:first-of-type {
        margin-right: 8px;
    }
`