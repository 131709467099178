import styled from 'styled-components/macro';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h4 {
    margin-bottom: 15px;
  }
`;

export const ImagePreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  button {
    margin-top: 20px;
    border-radius: 5px;
    width: 100%;
    height: 50px;
    background-color: #999;
    color: #fff;
    cursor: pointer;
    drop-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    z-index: 10;
  }
`;

export const CameraContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;

  button {
    position: absolute;
    bottom: 5px;
    border-radius: 100%;
    width: 50px;
    height: 50px;
    background-color: #999;
    color: #fff;
    cursor: pointer;
    drop-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    z-index: 10;
  }

  @media only screen and (max-width: 768px) {
    height: 500px;
  }
`;

export const MaskCamera = styled.div`
  position: absolute;
  width: ${(props) => (props.$maskType === 'face' ? '50%' : '80%')};
  height: 80%;
  top: 20px;
  border: solid 3px rgba(255, 255, 255, 0.5); /* Ajuste a cor e a espessura da borda conforme necessário */
  box-sizing: border-box;
  border-radius: ${(props) => (props.$maskType === 'face' ? '50%' : '5px')};
  box-shadow: 0 0 0 2000px rgba(0, 0, 0, 0.7);

  @media only screen and (max-width: 768px) {
    width: ${(props) => (props.$maskType === 'face' ? '80%' : '80%')};
    height: ${(props) => (props.$maskType === 'face' ? '80%' : '80%')};
  }
`;

export const CapturePhotoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
`;

export const PictureTypeSelectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const SelectionType = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding: 20px;
  width: 50%;

  button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 200px;
    background-color: #0b736c;
    color: #fff;

    svg {
      width: 100px;
      height: 70px;
    }
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;

    button {
      margin-bottom: 20px;
    }
  }
`;
