import api from "./api";
import { getToken } from "./auth";

export const getTemplateAsB64 = async (contentString) => {
  const headerParams = {
    headers: { Authorization: getToken() },
  };

  const response = await api.post(`template/previewAsB64`, { contentString }, headerParams);

  return response;
};
