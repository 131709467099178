import { call, put, select } from "redux-saga/effects";

import { toastr } from "react-redux-toastr";
import { push } from "connected-react-router";

import api from "../../services/api";
import { getToken } from "../../services/auth";

import { Creators as ExportacaoActions } from "../ducks/exportacao";

export const getOrganization = (state) => state.usuarios.auth.organization;
export const getUserId = (state) => state.usuarios.auth.id;

export function* createExportacao(action) {
  const organization = yield select(getOrganization);

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const body = { ...action.payload };

    const { data } = yield call(
      api.post,
      `exportacao`,
      body,
      headerParams
    );

    yield put(ExportacaoActions.createSuccess(data));
    toastr.success("", "Processado com sucesso");
  } catch (error) {
    toastr.error("Ops", "Não foi possível executar a extração.");
  }
}