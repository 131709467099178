import React from "react";

import { Col } from "react-bootstrap";

import Autocomplete from "@material-ui/lab/Autocomplete";
import DefaultInput from "components/DefaultInput";
import TableContainer from "@material-ui/core/TableContainer";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import Checkbox from "@material-ui/core/Checkbox";
import TableRow from "@material-ui/core/TableRow";
import InputForm from "components/InputForm";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";

import { OUTROS_CAMPOS } from "utils/default-another-fields";

function GridFromTo({
  columns,
  tipoDocSelecionado,
  handleCampoPadrao,
  handleCampoDestino,
  handleCampoValidar,
  showCampoArquivo,
}) {
  return (
    <Col md={12} className="mt-1">
      {columns && columns.length > 0 && (
        <>
          <TableContainer component={Paper} style={{ maxHeight: "70vh" }}>
            <Table stickyHeader aria-label="table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Ordem</TableCell>
                  {showCampoArquivo && <TableCell>Campo arquivo</TableCell>}
                  <TableCell>Campo plataforma</TableCell>
                  <TableCell>Valor padrão</TableCell>
                  <TableCell>Validar</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {columns?.map((item, index) => (
                  <TableRow style={{ maxHeight: "20px" }} key={index}>
                    <TableCell>{index}</TableCell>
                    {showCampoArquivo && <TableCell>{item}</TableCell>}
                    <TableCell>
                      <Autocomplete
                        multiple
                        options={
                          tipoDocSelecionado?.indexadores?.concat(
                            OUTROS_CAMPOS
                          ) || OUTROS_CAMPOS
                        }
                        getOptionLabel={(option) => option?.nome}
                        getOptionSelected={(option, value) =>
                          option?.id_es === value?.id_es
                        }
                        value={item.destino}
                        onChange={(event, newValue) =>
                          handleCampoDestino(newValue, index)
                        }
                        renderInput={(params) => (
                          <DefaultInput
                            {...params}
                            name="Campo destino"
                            label="Campo destino"
                            size="small"
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        )}
                      />
                    </TableCell>
                    <TableCell>
                      <InputForm
                        label="Padrão"
                        name="padrao"
                        value={item.valorPadrao}
                        onChange={(e) => handleCampoPadrao(e, index)}
                        type="text"
                        multiline
                      />
                    </TableCell>
                    <TableCell>
                      <Checkbox
                        defaultChecked={item.validar}
                        onChange={(e) => handleCampoValidar(e, index)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </Col>
  );
}

export default GridFromTo;
