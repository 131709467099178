import React, { useRef } from "react";
import { useDispatch } from "react-redux";
import { Creators as EmpresasActions } from "store/ducks/empresas";

import _ from 'lodash';
import "./styles.css";

import GridCadastro from "components/GridCadastro";
import TableEmpresas from "./TableEmpresas";

export default function Empresas() {

  const dispatch = useDispatch();
  const dispatchDebounce = useRef(_.debounce(dispatch, 500)).current;

  return (
    <GridCadastro
      title='Empresas'
      route={"/administracao/empresas/novo"}
      searchRequest={(value) => {
        dispatchDebounce(
          EmpresasActions.listRequest({ page: 1, limit: 10, search: value })
        );
      }}
      titleButtonNovo="Nova Empresa"
    >
      <TableEmpresas />
    </GridCadastro>
  )
}