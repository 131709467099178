export const Types = {
  LIST_REQUEST: "configuracaoDashboardExterno/LIST_REQUEST",
  LIST_SUCCESS: "configuracaoDashboardExterno/LIST_SUCCESS",
  NEW_REQUEST: "configuracaoDashboardExterno/NEW_REQUEST",
  NEW_SUCCESS: "configuracaoDashboardExterno/NEW_SUCCESS",
};

const INITIAL_STATE = {
  loading: false,
  docs: [],
  sort: "_id",
  page: 1,
  limit: 10,
  total: 0,
};

export default function configuracaoDashboardExterno(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.LIST_REQUEST:
      return { ...state, loading: true };

    case Types.LIST_SUCCESS:
      return { ...state, ...action.payload, loading: false };

    case Types.NEW_REQUEST:
      return { ...state, loading: true };

    case Types.NEW_SUCCESS:
      return { ...state, loading: false };

    default:
      return state;
  }
}

export const Creators = {
  listRequest: (data) => ({
    type: Types.LIST_REQUEST,
    payload: data,
  }),

  listSuccess: (data) => ({
    type: Types.LIST_SUCCESS,
    payload: data,
  }),
  
  newRequest: (data) => ({
    type: Types.NEW_REQUEST,
    payload: data,
  }),

  newSuccess: () => ({
    type: Types.NEW_SUCCESS,
    payload: {},
  }),
};
