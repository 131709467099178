import React, { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { Row, Col } from "react-bootstrap";
import "./styles.css";

import { Creators as DocumentosActions } from "store/ducks/documentos";
import { Creators as TiposImagemActions } from "store/ducks/tipoimagem";
import { toastr } from "react-redux-toastr";

export default function ModalMerge({
  selectedDoc,
  showModal,
  handleStay,
  selectedFiles,
}) {
  const validateTypeImg = (selectedFiles) => {
    if (!selectedFiles || selectedFiles.length === 0) return null;

    const { imagens: imagensFull } = selectedDoc;

    let tipoImagem = imagensFull.find(
      (img) => img.filepath === selectedFiles[0]
    );

    for (const file of selectedFiles) {
      const full = imagensFull.find((img) => img.filepath === file);
      if (tipoImagem.tipoImagem._id !== full.tipoImagem._id){
        toastr.warning(
          'Para unificação, os arquivos devem ser do mesmo tipo de imagem'
        );
        return null;
      };
    }
    return tipoImagem.tipoImagem;
  };

  const tipoImagemEvery = validateTypeImg(selectedFiles);

  const dispatch = useDispatch();
  const sendClicked = useRef(false);

  const tiposimagem = useSelector((state) => state.tipoImagem.list);

  const { loading } = useSelector((state) => state.documentos);

  const [tipoImagem, setTipoImagem] = useState(null);

  const handleSubmit = async () => {
    if (tipoImagem === null)
      toastr.warning("Selecione o tipo de imagem da unificação");

    const request = {
      selectedFiles,
      tipoImagem: tipoImagem,
      documentId: selectedDoc && selectedDoc._id,
    };

    dispatch(DocumentosActions.createMergeRequest(request));
  };

  useEffect(() => {
    if (selectedDoc.idTipoDocumento && selectedDoc.idTipoDocumento._id) {
      dispatch(
        TiposImagemActions.listByTipoDocumentoRequest(
          selectedDoc.idTipoDocumento._id
        )
      );
    }
  }, [dispatch, selectedDoc]);


  useEffect(() => {
    const findType = tiposimagem.find((ti) => ti.nome === tipoImagemEvery?._id);
    setTipoImagem(findType?._id);
  }, [dispatch, tipoImagemEvery, tiposimagem]);

  useEffect(() => {
    if (!loading && sendClicked.current === true) {
      handleStay();
      sendClicked.current = false;
    }
  }, [loading, handleStay]);

  if (!tipoImagemEvery) handleStay();

  return (
    <Modal show={showModal} onHide={handleStay} centered size="xl">
      <Modal.Header>
        <Modal.Title>Unificar documentos</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            {!!tipoImagemEvery && (
              <TextField
                disabled={true}
                size={"small"}
                variant="outlined"
                name="tipoImagem"
                label="Tipo de imagem"
                value={tipoImagemEvery.nome}
              />
            )}

            {!tipoImagemEvery && (
              <Autocomplete
                className="mb-2"
                options={tiposimagem}
                getOptionLabel={(option) => option.nome}
                onChange={(e, value) => setTipoImagem(value?._id || null)}
                getOptionSelected={(option, value) => option._id === value._id}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size={"small"}
                    variant="outlined"
                    name="tipoImagem"
                    label="Tipo de imagem"
                  />
                )}
              />
            )}
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <div className="white-loading">
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={loading}
          >
            Unificar
            {!!loading && <CircularProgress size={20} />}
          </Button>
        </div>

        <div>
          <Button variant="contained" color="secondary" onClick={handleStay}>
            Voltar
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
