import React, { useRef, useEffect, useState } from 'react';
import { useField } from '@unform/core';
import TextField from '@material-ui/core/TextField';
import { toastr } from 'react-redux-toastr';

function InputForm({ name, InputLabelProps={}, onChange, variant = "outlined", ...rest }) {
  const inputRef = useRef(null);

  const { fieldName, defaultValue, registerField, error } = useField(name);

  const[shrink, setShrink] = useState(InputLabelProps.shrink ? InputLabelProps.shrink : !!defaultValue || false);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      setValue: (ref, value) => {
        setShrink(!!value || InputLabelProps.shrink);
        ref.value = value ? value : null;
      }
    });
  }, [InputLabelProps.shrink, fieldName, registerField]);

  function handleChange(e) {
    const { value } = e.target;
    setShrink(!!value || InputLabelProps.shrink);
    if (onChange) onChange(e);
  }

  function handleDoubleClick(e) {
    if (!e.target.value) return;
    navigator.clipboard.writeText(e.target.value);
    toastr.info("", "Input copiado em segundo plano");
  }

  return (
    <TextField
      error={error && true}
      inputRef={inputRef}
      helperText={error}
      variant={variant}
      margin="dense"
      defaultValue={defaultValue}
      onDoubleClick={handleDoubleClick}
      onChange={handleChange}
      {...rest}
      InputLabelProps={{
        ...InputLabelProps,
        shrink
      }}
    />
  );
}

export default InputForm;