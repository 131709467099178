export const Types = {
    NEW_PERMISSOES_REQUEST: "permissoes/NEW_PERMISSOES_REQUEST",
    NEW_PERMISSOES_SUCCESS: "permissoes/NEW_PERMISSOES_SUCCESS",
    GET_PERMISSOES_REQUEST: "permissoes/GET_PERMISSOES_REQUEST",
    GET_PERMISSOES_SUCCESS: "permissoes/GET_PERMISSOES_SUCCESS",
    GET_PERMISSOES_ANOTHER_USER_REQUEST: "permissoes/GET_PERMISSOES_ANOTHER_USER_REQUEST",
    GET_PERMISSOES_ANOTHER_USER_SUCCESS: "permissoes/GET_PERMISSOES_ANOTHER_USER_SUCCESS",
    UPDATE_PERMISSOES_REQUEST: "permissoes/UPDATE_PERMISSOES_REQUEST",
    UPDATE_PERMISSOES_SUCCESS: "permissoes/UPDATE_PERMISSOES_SUCCESS",
    REMOVE_PERMISSOES_REQUEST: "permissoes/REMOVE_PERMISSOES_REQUEST",
    REMOVE_PERMISSOES_SUCCESS: "permissoes/REMOVE_PERMISSOES_SUCCESS"
}

const INITIAL_STATE = {
    loading: false, 
    access: [],
    otherUser: []
}

export default function permissoes(state = INITIAL_STATE, action) {

    switch(action.type) {
        case Types.NEW_PERMISSOES_REQUEST:
            return { ...state, loading: true };
        
        case Types.NEW_PERMISSOES_SUCCESS:
            return { ...state, loading: false, access: action.payload };

        case Types.GET_PERMISSOES_REQUEST:
            return { ...state, loading: true }

        case Types.GET_PERMISSOES_SUCCESS:
            return { ...state, loading: false, access: action.payload }

        case Types.GET_PERMISSOES_ANOTHER_USER_REQUEST:
            return { ...state, loading: true };

        case Types.GET_PERMISSOES_ANOTHER_USER_SUCCESS:
            return { ...state, loading: false, otherUser: action.payload }

        case Types.UPDATE_PERMISSOES_REQUEST: 
            return { ...state, loading: true }

        case Types.UPDATE_PERMISSOES_SUCCESS:
            return { ...state, loading: false, access: action.payload }

        case Types.REMOVE_PERMISSOES_REQUEST:
            return { ...state, loading: true }

        case Types.REMOVE_PERMISSOES_SUCCESS:
            return { ...state, loading: false }
        
        default:
            return state;
    }   

}

export const Creators = {

    newPermissoesRequest: permissoes => ({
        type: Types.NEW_PERMISSOES_REQUEST,
        payload: permissoes
    }),

    newPermissoesSuccess: permissoes => ({
        type: Types.NEW_PERMISSOES_SUCCESS,
        payload: permissoes
    }),

    getPermissoesRequest: () => ({
        type: Types.GET_PERMISSOES_REQUEST,
    }),

    getPermissoesSuccess: permissoes => ({
        type: Types.GET_PERMISSOES_SUCCESS,
        payload: permissoes
    }),

    getPermissoesAnotherUserRequest: id => ({
        type: Types.GET_PERMISSOES_ANOTHER_USER_REQUEST,
        payload: id
    }),
    
    getPermissoesAnotherUserSuccess: permissoes => ({
        type: Types.GET_PERMISSOES_ANOTHER_USER_SUCCESS,
        payload: permissoes
    }),

    updatePermissoesRequest: permissoes => ({
        type: Types.UPDATE_PERMISSOES_REQUEST,
        payload: permissoes
    }),

    updatePermissoesSuccess: () => ({
        type: Types.UPDATE_PERMISSOES_SUCCESS
    }),

    removePermissoesRequest: permissoes => ({
        type: Types.REMOVE_PERMISSOES_REQUEST,
    }),

    removePermissoesSuccess: () => ({
        type: Types.REMOVE_PERMISSOES_SUCCESS
    })
}