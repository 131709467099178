import { call, put, select } from "redux-saga/effects";

import { toastr } from "react-redux-toastr";

import api from "../../services/api";
import { getToken } from "../../services/auth";

import { Creators as ReportActions } from "../ducks/report";

export const getOrganization = (state) => state.usuarios.auth.organization;
export const getUserId = (state) => state.usuarios.auth.id;

export function* createReport(action) {
  const organization = yield select(getOrganization);

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const body = action.payload;

    const { data } = yield call(
      api.post,
      `report/dash-report`,
      body,
      headerParams
    );

    yield put(ReportActions.createSuccess(data));

    const isQueued = data?.status === "queue";

    isQueued ? toastr.success("", "Relatório assincrono agendado com sucesso.") : toastr.success("", "Relatório executado com sucesso.");

  } catch (error) {
    toastr.error("Ops", "Não foi agendar executar o relatório.");
  }
}

export function* sendReportAsMail(action) {
  const organization = yield select(getOrganization);

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const body = action.payload;

    yield call(api.post, `report/dash-report/mail`, body, headerParams);

    yield put(ReportActions.sendAsMailSuccess({}));

    toastr.success("", "Envio do relatório por email agendado.");
  } catch (error) {
    toastr.error("Ops", "Não foi agendar executar o relatório.");
  }
}
