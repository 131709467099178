export const Types = {
  LIST_REQUEST: 'denominacoes/LIST_REQUEST',
  LIST_SUCCESS: 'denominacoes/LIST_SUCCESS',
  LIST_ERROR: 'denominacoes/LIST_ERROR'
}

const INITIAL_STATE = {
  enum: {},
  loading: false,
}

export default function denominacoes(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.LIST_REQUEST:
      return { ...state, loading: true };

    case Types.LIST_SUCCESS:
      return { 
        ...state, 
        loading: false, 
        enum: action.payload.enum
      };

    case Types.LIST_ERROR:
      return {
        ...state,
        loading: false
      }    

    default:
      return state;
  }
}

export const Creators = {
  listRequest: () => ({
    type: Types.LIST_REQUEST,
  }),
  listSuccess: data => ({
    type: Types.LIST_SUCCESS,
    payload: { enum: data }
  }),
  listError: () => ({
    type: Types.LIST_ERROR
  })
}