import React from "react";

import {
  Container,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "./styles/TasksActionMenu";
import { ArrowDownCircle, PreviewIcon } from "components/Icons";

function DocSignatureActionsMenu({
  anchor,
  handleClose,
  setOpenSigners,
  setOpenDocView,
  enableViewImagem,
}) {
  return (
    <>
      <Container
        onClose={handleClose}
        open={!!anchor}
        elevation={10}
        transformOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        anchorEl={anchor}
      >
        <MenuItem onClick={() => setOpenSigners(true)}>
          <ListItemIcon>
            <PreviewIcon />
          </ListItemIcon>
          <ListItemText>Visualizar destinatários</ListItemText>
        </MenuItem>

        <MenuItem
          onClick={() => setOpenDocView(true)}
          disabled={!enableViewImagem}
        >
          <ListItemIcon>
            <ArrowDownCircle />
          </ListItemIcon>
          <ListItemText>Visualizar documento</ListItemText>
        </MenuItem>
      </Container>
    </>
  );
}

export default DocSignatureActionsMenu;
