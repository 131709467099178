export const Types = {
  NEW: 'parte/NEW',
  EDIT: 'parte/EDIT',
  CANCEL: 'parte/CANCEL',
  SAVE_REQUEST: 'parte/SAVE_REQUEST',
  SAVE_SUCCESS: 'parte/SAVE_SUCCESS',
  SAVE_ERROR: 'parte/SAVE_ERROR',
}

const INITIAL_STATE = {
  data: {},
  loading: false,
  open: false
}

export default function parte(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.NEW:
      return {
        ...state,
        data: {},
        loading: false,
        open: true
      }

    case Types.EDIT:
      return { 
        ...state,
        data: action.payload.parte,
        loading: false,
        open: true
      }

    case Types.SAVE_REQUEST:
      return { 
        ...state,
        loading: true,
        data: action.payload.parte,
      }
    
    case Types.SAVE_SUCCESS:
      return { 
        ...state,
        loading: false,
        open: false
      }

    case Types.SAVE_ERROR:
      return {
        ...state,
        loading: false
      }

    case Types.CANCEL:
      return {
        ...state,
        loading: false,
        open: false
      }

    default:
      return state;
  }
}

export const Creators = {
  new: () => ({
    type: Types.NEW,
  }),
  edit: parte => ({
    type: Types.EDIT,
    payload: { parte }
  }),
  cancel: () => ({
    type: Types.CANCEL
  }),
  saveRequest: parte => ({
    type: Types.SAVE_REQUEST,
    payload: { parte }
  }),
  saveSuccess: () => ({
    type: Types.SAVE_SUCCESS,
  }),
  saveError: () => ({
    type: Types.SAVE_ERROR,
  })
}