import { call, put, select } from "redux-saga/effects";

import api from "../../services/api";
import { getToken } from "../../services/auth";

import { Creators as ReclamacoesActions } from "../ducks/reclamacoes";

export const getOrganization = (state) => state.usuarios.auth.organization;
export const getUserId = (state) => state.usuarios.auth.id;

export function* listReclamacoesProcon(action) {
  try {
    const organization = yield select(getOrganization);

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    let dataRel = "";

    if (action.payload.filter.data) {
      dataRel = `?dataRel=${action.payload.filter.data}`;
    }

    const { data } = yield call(
      api.get,
      `/procon/comparar${dataRel}`,
      headerParams
    );

    yield put(ReclamacoesActions.listReclamacoesProconSuccess(data));
  } catch (error) {}
}

export function* totalRecPorSindec(action) {
  try {
    const organization = yield select(getOrganization);

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    let dataRel = "";

    if (action.payload.filter.data) {
      dataRel = `?dataRel=${action.payload.filter.data}`;
    }

    const { data } = yield call(
      api.get,
      `/procon/totalrecporsindec${dataRel}`,
      headerParams
    );

    yield put(ReclamacoesActions.totalRecPorSindecSuccess(data));
  } catch (error) {}
}

export function* totalCipsNaoRespPorSindec(action) {
  try {
    const organization = yield select(getOrganization);

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    let dataRel = "";

    if (action.payload.filter.data) {
      dataRel = `?dataRel=${action.payload.filter.data}`;
    }

    const { data } = yield call(
      api.get,
      `/procon/totalcipsnaorespporsindec${dataRel}`,
      headerParams
    );

    yield put(ReclamacoesActions.totalCipsNaoRespPorSindecSuccess(data));
  } catch (error) {}
}

export function* totalCipsNaoResp(action) {
  try {
    const organization = yield select(getOrganization);

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    let dataRel = "";

    if (action.payload.filter.data) {
      dataRel = `?dataRel=${action.payload.filter.data}`;
    }

    const { data } = yield call(
      api.get,
      `/procon/compararcipsnaoresp${dataRel}`,
      headerParams
    );

    yield put(ReclamacoesActions.totalCipsNaoRespSuccess(data));
  } catch (error) {}
}

export function* cipsNaoRespPrazo(action) {
  try {
    const organization = yield select(getOrganization);

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    let dataRel = "";

    if (action.payload.filter.data) {
      dataRel = `?dataRel=${action.payload.filter.data}`;
    }

    const { data } = yield call(
      api.get,
      `/procon/cipsnaorespondidasprazo${dataRel}`,
      headerParams
    );

    yield put(ReclamacoesActions.cipsNaoRespPrazoSuccess(data));
  } catch (error) {}
}

export function* reclamacoesEvolucaoCadastro(action) {
  try {
    const organization = yield select(getOrganization);

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const { data } = yield call(
      api.get,
      `/procon/evolucaocadastro`,
      headerParams
    );

    yield put(ReclamacoesActions.reclamacoesEvolucaoSuccess(data));
  } catch (error) {}
}

export function* cipsNaoRespEvolucao(action) {
  try {
    const organization = yield select(getOrganization);

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const { data } = yield call(
      api.get,
      `/procon/evolucaocipsnaoresp`,
      headerParams
    );

    yield put(ReclamacoesActions.cipsNaoRespEvolucaoSuccess(data));
  } catch (error) {}
}

export function* cipsRespPrazo(action) {
  try {
    const organization = yield select(getOrganization);

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    let dataRel = "";

    if (action.payload.filter.data) {
      dataRel = `?dataRel=${action.payload.filter.data}`;
    }

    const { data } = yield call(
      api.get,
      `/procon/cipsrespondidasprazo${dataRel}`,
      headerParams
    );

    yield put(ReclamacoesActions.cipsRespPrazoSuccess(data));
  } catch (error) {}
}
