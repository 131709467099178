import { SvgIcon } from '@material-ui/core';
import React from 'react';
import * as Icons from 'react-feather';

import {
  Mail,
  DraftsOutlined,
  Email,
  CopyrightSharp,
  Markunread,
  LinearScale,
  GetAppSharp,
  LibraryAddCheckSharp,
  AppsSharp,
  PriorityHigh,
  PictureAsPdfSharp,
  AccountTree,
  GroupWork,
  HourglassFullTwoTone,
  Today,
  History,
  ImageSearch,
  Dashboard,
  MailOutline,
  Gavel,
  DragIndicator,
  Drafts,
  AssignmentInd,
  FirstPage,
  LastPage,
  AssignmentLate,
  BurstMode,
  ListAlt,
  KeyboardArrowRightRounded,
  KeyboardArrowLeftRounded,
  FileCopy,
} from '@material-ui/icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faChevronUp,
  faChevronRight,
  faTasks,
  faSearch,
  faSave,
  faAngleDown,
  faAngleUp,
  faDownload,
  faEdit,
  faEnvelope,
  faTimes,
  faUser,
  faSquare,
  faPrint,
  faPlus,
  faCog,
  faTrash,
  faEye,
  faAngleLeft,
  faAngleRight,
  faLayerGroup,
  faParagraph,
  faSignature,
  faPlusCircle,
  faHistory,
  faEyeSlash,
} from '@fortawesome/free-solid-svg-icons';

//#region FontAwesome
export function ButtonAwesomeIcon({ icon, ...props }) {
  return <FontAwesomeIcon icon={icon} {...props} />;
}
export const FaParagraph = ({ ...props }) => (
  <ButtonAwesomeIcon icon={faParagraph} {...props} />
);
export const FaHistory = ({ ...props }) => (
  <ButtonAwesomeIcon icon={faHistory} {...props} />
);
export const FaSignature = ({ ...props }) => (
  <ButtonAwesomeIcon icon={faSignature} {...props} />
);
export const FaChevronRight = ({ ...props }) => (
  <ButtonAwesomeIcon icon={faChevronRight} {...props} />
);
export const FaChevronDown = ({ ...props }) => (
  <ButtonAwesomeIcon icon={faChevronDown} {...props} />
);
export const FaChevronUp = ({ ...props }) => (
  <ButtonAwesomeIcon icon={faChevronUp} {...props} />
);
export const FaTasks = ({ ...props }) => (
  <ButtonAwesomeIcon icon={faTasks} {...props} />
);
export const FaSearch = ({ ...props }) => (
  <ButtonAwesomeIcon icon={faSearch} {...props} />
);
export const FaSave = ({ ...props }) => (
  <ButtonAwesomeIcon icon={faSave} {...props} />
);
export const FaAngleDown = ({ ...props }) => (
  <ButtonAwesomeIcon icon={faAngleDown} {...props} />
);
export const FaAngleUp = ({ ...props }) => (
  <ButtonAwesomeIcon icon={faAngleUp} {...props} />
);
export const FaAngleLeft = ({ ...props }) => (
  <ButtonAwesomeIcon icon={faAngleLeft} {...props} />
);
export const FaAngleRight = ({ ...props }) => (
  <ButtonAwesomeIcon icon={faAngleRight} {...props} />
);
export const FaLayerGroup = ({ ...props }) => (
  <ButtonAwesomeIcon icon={faLayerGroup} {...props} />
);
export const FaDownload = ({ ...props }) => (
  <ButtonAwesomeIcon icon={faDownload} {...props} />
);
export const FaEdit = ({ ...props }) => (
  <ButtonAwesomeIcon icon={faEdit} {...props} />
);
export const FaEnvelope = ({ ...props }) => (
  <ButtonAwesomeIcon icon={faEnvelope} {...props} />
);
export const FaTimes = ({ ...props }) => (
  <ButtonAwesomeIcon icon={faTimes} {...props} />
);
export const FaUser = ({ ...props }) => (
  <ButtonAwesomeIcon icon={faUser} {...props} />
);
export const FaSquare = ({ ...props }) => (
  <ButtonAwesomeIcon icon={faSquare} {...props} />
);
export const FaPrint = ({ ...props }) => (
  <ButtonAwesomeIcon icon={faPrint} {...props} />
);
export const FaPlus = ({ ...props }) => (
  <ButtonAwesomeIcon icon={faPlus} {...props} />
);
export const FaPlusCircle = ({ ...props }) => (
  <ButtonAwesomeIcon icon={faPlusCircle} {...props} />
);
export const FaCog = ({ ...props }) => (
  <ButtonAwesomeIcon icon={faCog} {...props} />
);
export const FaTrash = ({ ...props }) => (
  <ButtonAwesomeIcon icon={faTrash} {...props} />
);
export const FaEye = ({ ...props }) => (
  <ButtonAwesomeIcon icon={faEye} {...props} />
);
export const FaEyeSlash = ({ ...props }) => (
  <ButtonAwesomeIcon icon={faEyeSlash} {...props} />
);
//#endregion

//#region Material UI
const materialUIToFeatherIcons = {
  edit: 'Edit2',
  delete: 'Trash',
  visibility: 'Eye',
  backup: 'DownloadCloud',
  image: 'Image',
  list: 'List',
  format_indent_increase: 'AlignRight',
  settings: 'Settings',
  person_off: 'UserX',
  person_add: 'UserPlus',
  assignment: 'Clipboard',
  menu_open: 'Menu',
  share: 'Share2',
  playlist_add: 'Layers',
  move_down: 'Shuffle',
  upload_cloud: 'UploadCloud',
};

export function ButtonSquareIcon({ icon }) {
  const Icone = Icons[materialUIToFeatherIcons[icon]];
  return (
    <SvgIcon color="primary" fontSize="small">
      {Icone && <Icone size={24} color="white" />}
    </SvgIcon>
  );
}

export const MailUnreadedIcon = ({ ...props }) => <Mail {...props} />;
export const MailReadedIcon = ({ ...props }) => <DraftsOutlined {...props} />;
export const MailOutlineIcon = ({ ...props }) => <MailOutline {...props} />;
export const EmailIcon = ({ ...props }) => <Email {...props} />;
export const CopyrightIcon = ({ ...props }) => <CopyrightSharp {...props} />;
export const MarkunreadIcon = ({ ...props }) => <Markunread {...props} />;
export const LinearScaleIcon = ({ ...props }) => <LinearScale {...props} />;
export const GetAppIcon = ({ ...props }) => <GetAppSharp {...props} />;
export const AssignmentIndIcon = ({ ...props }) => <AssignmentInd {...props} />;
export const AssignmentLateIcon = ({ ...props }) => (
  <AssignmentLate {...props} />
);
export const LibraryAddCheckIcon = ({ ...props }) => (
  <LibraryAddCheckSharp {...props} />
);
export const AppsIcon = ({ ...props }) => <AppsSharp {...props} />;
export const PriorityHighIcon = ({ ...props }) => <PriorityHigh {...props} />;
export const PictureAsPdfIcon = ({ ...props }) => (
  <PictureAsPdfSharp {...props} />
);
export const GroupIcon = ({ ...props }) => <GroupWork {...props} />;
export const FluxoIcon = ({ ...props }) => <AccountTree {...props} />;
export const HourglassFullTwoToneIcon = ({ ...props }) => (
  <HourglassFullTwoTone {...props} />
);
export const TodayIcon = ({ ...props }) => <Today {...props} />;
export const HistoryIcon = ({ ...props }) => <History {...props} />;
export const ImageSearchIcon = ({ ...props }) => <ImageSearch {...props} />;
export const DashboardIcon = ({ ...props }) => <Dashboard {...props} />;
export const DragIcon = ({ ...props }) => <DragIndicator {...props} />;
export const GavelIcon = ({ ...props }) => <Gavel {...props} />;
export const DraftsIcon = ({ ...props }) => <Drafts {...props} />;
export const FirstPageIcon = ({ ...props }) => <FirstPage {...props} />;
export const LastPageIcon = ({ ...props }) => <LastPage {...props} />;
export const BurstModeIcon = ({ ...props }) => <BurstMode {...props} />;
export const ListAltIcon = ({ ...props }) => <ListAlt {...props} />;
export const KeyboardArrowRightRoundedIcon = ({ ...props }) => (
  <KeyboardArrowRightRounded {...props} />
);
export const KeyboardArrowLeftRoundedIcon = ({ ...props }) => (
  <KeyboardArrowLeftRounded {...props} />
);
export const FileCopyIcon = ({ ...props }) => <FileCopy {...props} />;
//#endregion

//#region feather-icons
export function FeatherIcon({ ...props }) {
  const color = props.htmlColor || props.color;
  const Icon = props.icon;

  delete props.color;
  delete props.htmlColor;
  delete props.icon;

  return (
    <SvgIcon {...props}>
      <Icon color={color} />
    </SvgIcon>
  );
}

export const MergeIcon = ({ ...props }) => (
  <FeatherIcon icon={Icons.GitMerge} {...props} />
);
export const MessageIcon = ({ ...props }) => (
  <FeatherIcon icon={Icons.MessageSquare} {...props} />
);
export const MaximizeIcon = ({ ...props }) => (
  <FeatherIcon icon={Icons.Maximize} {...props} />
);
export const MinimizeIcon = ({ ...props }) => (
  <FeatherIcon icon={Icons.Minimize} {...props} />
);
export const NotificationsIcon = ({ ...props }) => (
  <FeatherIcon icon={Icons.Bell} {...props} />
);
export const SearchIcon = ({ ...props }) => (
  <FeatherIcon icon={Icons.Search} {...props} />
);
export const CloseIcon = ({ ...props }) => (
  <FeatherIcon icon={Icons.X} {...props} />
);
export const ClockIcon = ({ ...props }) => (
  <FeatherIcon icon={Icons.Clock} {...props} />
);
export const EditIcon = ({ ...props }) => (
  <FeatherIcon icon={Icons.Edit2} {...props} />
);
export const DeleteIcon = ({ ...props }) => (
  <FeatherIcon icon={Icons.Trash} {...props} />
);
export const MoreIcon = ({ ...props }) => (
  <FeatherIcon icon={Icons.MoreVertical} {...props} />
);
export const AttachFileIcon = ({ ...props }) => (
  <FeatherIcon icon={Icons.Paperclip} {...props} />
);
export const PreviewIcon = ({ ...props }) => (
  <FeatherIcon icon={Icons.Eye} {...props} />
);
export const ArrowUpIcon = ({ ...props }) => (
  <FeatherIcon icon={Icons.ChevronUp} {...props} />
);
export const ArrowDownIcon = ({ ...props }) => (
  <FeatherIcon icon={Icons.ChevronDown} {...props} />
);
export const BackupIcon = ({ ...props }) => (
  <FeatherIcon icon={Icons.UploadCloud} {...props} />
);
export const ImageIcon = ({ ...props }) => (
  <FeatherIcon icon={Icons.Image} {...props} />
);
export const CheckIcon = ({ ...props }) => (
  <FeatherIcon icon={Icons.Check} {...props} />
);
export const CheckCircleIcon = ({ ...props }) => (
  <FeatherIcon icon={Icons.CheckCircle} {...props} />
);
export const CancelCircleIcon = ({ ...props }) => (
  <FeatherIcon icon={Icons.XCircle} {...props} />
);
export const SendIcon = ({ ...props }) => (
  <FeatherIcon icon={Icons.Send} {...props} />
);
export const MailUndeliveredIcon = ({ ...props }) => (
  <FeatherIcon icon={Icons.AlertTriangle} {...props} />
);
export const UploadIcon = ({ ...props }) => (
  <FeatherIcon icon={Icons.Upload} {...props} />
);
export const DialpadIcon = ({ ...props }) => (
  <FeatherIcon icon={Icons.Hash} {...props} />
);
export const BusinessIcon = ({ ...props }) => (
  <FeatherIcon icon={Icons.Briefcase} {...props} />
);
export const WorkIcon = ({ ...props }) => (
  <FeatherIcon icon={Icons.Briefcase} {...props} />
);
export const ListIcon = ({ ...props }) => (
  <FeatherIcon icon={Icons.List} {...props} />
);
export const TextFieldsIcon = ({ ...props }) => (
  <FeatherIcon icon={Icons.Type} {...props} />
);
export const DateRangeIcon = ({ ...props }) => (
  <FeatherIcon icon={Icons.Calendar} {...props} />
);
export const FormatListNumberedIcon = ({ ...props }) => (
  <FeatherIcon icon={Icons.Table} {...props} />
);
export const PermIdentityIcon = ({ ...props }) => (
  <FeatherIcon icon={Icons.User} {...props} />
);
export const DeleteForeverIcon = ({ ...props }) => (
  <FeatherIcon icon={Icons.Trash2} {...props} />
);
export const ChevronLeftIcon = ({ ...props }) => (
  <FeatherIcon icon={Icons.ChevronLeft} {...props} />
);
export const ChevronRightIcon = ({ ...props }) => (
  <FeatherIcon icon={Icons.ChevronRight} {...props} />
);
export const NoteAddIcon = ({ ...props }) => (
  <FeatherIcon icon={Icons.FilePlus} {...props} />
);
export const AddIcon = ({ ...props }) => (
  <FeatherIcon icon={Icons.Plus} {...props} />
);
export const SaveOutlinedIcon = ({ ...props }) => (
  <FeatherIcon icon={Icons.Save} {...props} />
);
export const FeatherOutlinedIcon = ({ ...props }) => (
  <FeatherIcon icon={Icons.Feather} {...props} />
);
export const AssignmentIcon = ({ ...props }) => (
  <FeatherIcon icon={Icons.Clipboard} {...props} />
);
export const CloudUploadIcon = ({ ...props }) => (
  <FeatherIcon icon={Icons.UploadCloud} {...props} />
);
export const ChatIcon = ({ ...props }) => (
  <FeatherIcon icon={Icons.MessageCircle} {...props} />
);
export const NewReleasesIcon = ({ ...props }) => (
  <FeatherIcon icon={Icons.AlertOctagon} {...props} />
);
export const GridOnIcon = ({ ...props }) => (
  <FeatherIcon icon={Icons.Grid} {...props} />
);
export const DescriptionIcon = ({ ...props }) => (
  <FeatherIcon icon={Icons.FileText} {...props} />
);
export const ExcelIcon = ({ ...props }) => (
  <FeatherIcon icon={Icons.FileText} {...props} />
);
export const PeopleIcon = ({ ...props }) => (
  <FeatherIcon icon={Icons.Users} {...props} />
);
export const TuneIcon = ({ ...props }) => (
  <FeatherIcon icon={Icons.Sliders} {...props} />
);
export const WarningIcon = ({ ...props }) => (
  <FeatherIcon icon={Icons.AlertTriangle} {...props} />
);
export const ErrorIcon = ({ ...props }) => (
  <FeatherIcon icon={Icons.AlertCircle} {...props} />
);
export const AssessmentIcon = ({ ...props }) => (
  <FeatherIcon icon={Icons.BarChart2} {...props} />
);
export const LockOpenIcon = ({ ...props }) => (
  <FeatherIcon icon={Icons.Unlock} {...props} />
);
export const TimerIcon = ({ ...props }) => (
  <FeatherIcon icon={Icons.Watch} {...props} />
);
export const PrintIcon = ({ ...props }) => (
  <FeatherIcon icon={Icons.Printer} {...props} />
);
export const SyncIcon = ({ ...props }) => (
  <FeatherIcon icon={Icons.RefreshCw} {...props} />
);
export const PersonAddIcon = ({ ...props }) => (
  <FeatherIcon icon={Icons.UserPlus} {...props} />
);
export const HelpOutlineIcon = ({ ...props }) => (
  <FeatherIcon icon={Icons.HelpCircle} {...props} />
);
export const AddCircleIcon = ({ ...props }) => (
  <FeatherIcon icon={Icons.PlusCircle} {...props} />
);
export const InboxIcon = ({ ...props }) => (
  <FeatherIcon icon={Icons.Inbox} {...props} />
);
export const CreateIcon = ({ ...props }) => (
  <FeatherIcon icon={Icons.Edit2} {...props} />
);
export const InfoIcon = ({ ...props }) => (
  <FeatherIcon icon={Icons.Info} {...props} />
);
export const RemoveCircleIcon = ({ ...props }) => (
  <FeatherIcon icon={Icons.XCircle} {...props} />
);
export const ArrowRightIcon = ({ ...props }) => (
  <FeatherIcon icon={Icons.ChevronRight} {...props} />
);
export const KeyboardArrowLeftIcon = ({ ...props }) => (
  <FeatherIcon icon={Icons.ChevronLeft} {...props} />
);
export const KeyboardArrowRightIcon = ({ ...props }) => (
  <FeatherIcon icon={Icons.ChevronRight} {...props} />
);
export const DoneAllIcon = ({ ...props }) => (
  <FeatherIcon icon={Icons.Check} {...props} />
);
export const DesktopWindowsIcon = ({ ...props }) => (
  <FeatherIcon icon={Icons.Monitor} {...props} />
);
export const AttachMoneyIcon = ({ ...props }) => (
  <FeatherIcon icon={Icons.DollarSign} {...props} />
);
export const ArrowDownCircle = ({ ...props }) => (
  <FeatherIcon icon={Icons.ArrowDownCircle} {...props} />
);
export const Info = ({ ...props }) => (
  <FeatherIcon icon={Icons.Info} {...props} />
);
export const AlignJustify = ({ ...props }) => (
  <FeatherIcon icon={Icons.AlignJustify} {...props} />
);
export const ArrowUpCircle = ({ ...props }) => (
  <FeatherIcon icon={Icons.ArrowUpCircle} {...props} />
);
//#endregion

export const ICONS_CUSTOM_FIELDS = {
  caracter: TextFieldsIcon,
  numero: DialpadIcon,
  data: DateRangeIcon,
  cpf: PermIdentityIcon,
  cnpj: BusinessIcon,
  tabela: FormatListNumberedIcon,
  combo: ListIcon,
  '': TextFieldsIcon,
};
