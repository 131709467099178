import React, {useMemo, useRef, useState} from 'react';

import {DialogContent, MenuItem} from '@material-ui/core';
import { Row, Col } from 'react-bootstrap';
import { ValidationError } from 'yup';

import AutocompleteForm from 'components/AutocompleteForm';

import Dialog from '@material-ui/core/Dialog';

import InputForm from 'components/InputForm';

import { Form } from '@unform/web';
import { MailFileOptions } from '../MailFileOptions';
import { Button, GroupButton, DialogTitle } from './style';
import { FilterJustSameMonthSchema } from "../../../../../validators/FilterJustSameMonthSchema";
import SelectForm from "../../../../../components/SelectForm";

const INITIAL_FORM = {
    departamento: '',
    dataInicial: '',
    dataFinal: '',
    attachmentType: null,
    visao: 'analitica'
};

function ModalFilterDataAndOptions({
                                   open,
                                   handleClose,
                                   handlePrint,
                                   dateHasError,
                                   showSLAFilter,
                                   options,
                               }) {
    const formRef = useRef(null);
    const [selectOption, setSelectOption] = useState("analitica")

    const handleSubmit = async (values) => {
        try {
            await FilterJustSameMonthSchema.validate(values, {
                abortEarly: false,
            });

            if (
                options.sendAsMail &&
                formRef.current &&
                !formRef.current.getFieldValue('attachmentType')
            ) {
                return formRef.current.setErrors({
                    attachmentType: 'É necessário preencher tipo de anexo',
                });
            }

            handlePrint({
                dataInicial: values.dataInicial,
                dataFinal: values.dataFinal,
                status: values.status,
                dentroDoPrazoSLA: values.dentroDoPrazoSLA,
                tipoDoAnexo: values.attachmentType,
                sendAsMail: options.sendAsMail,
                visao: selectOption
            });
        } catch (err) {
            const validationErrors = {};
            if (err instanceof ValidationError) {
                err.inner.forEach((error) => {
                    validationErrors[error.path] = error.message;
                });

                formRef.current.setErrors(validationErrors);
            }
        }
    };
    return (
        <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle
                id="alert-dialog-title"
                style={{ minWidth: '600px' }}
            ></DialogTitle>
            <DialogContent>
                <Form
                    ref={formRef}
                    onSubmit={handleSubmit}
                    initialData={{
                        INITIAL_FORM,
                    }}
                >
                    <Row>
                        <Col xm={12} sm={12} md={6}>
                            <InputForm
                                autoFocus
                                label="Período Inicial"
                                name="dataInicial"
                                type="date"
                                size="small"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Col>
                        <Col xm={12} sm={12} md={6}>
                            <InputForm
                                label="Período Final"
                                name="dataFinal"
                                type="date"
                                size="small"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Col>

                        <Col xm={12} sm={12} md={12}>
                            <SelectForm
                                label={"Visão"}
                                name={"visao"}
                                value={selectOption}
                                onChange={
                                    (e) => {
                                        setSelectOption(e.target.value)
                                    }
                            }>
                                <MenuItem value={"analitica"}>Analítica</MenuItem>
                                <MenuItem value={"sintetica"}>Sintética</MenuItem>
                            </SelectForm>
                        </Col>

                        {showSLAFilter && (
                            <Col md={12}>
                                <AutocompleteForm
                                    size="small"
                                    label="Status do SLA"
                                    name="dentroDoPrazoSLA"
                                    options={[
                                        { tipo: true, label: 'Dentro do SLA' },
                                        { tipo: false, label: 'Fora do SLA' },
                                    ]}
                                    transformOnSelect={(option) => (option ? option : null)}
                                    getOptionLabel={(option) => option.label}
                                />
                            </Col>
                        )}

                        {options.sendAsMail && (
                            <Col md={12}>
                                <MailFileOptions
                                    canPrint={options.canPrint}
                                    canSheel={options.canSheel}
                                    isForm={true}
                                />
                            </Col>
                        )}
                    </Row>

                    <GroupButton className="mt-3">
                        <Button variant="contained" color="secondary" onClick={handleClose}>
                            Cancelar
                        </Button>
                        <Button variant="contained" color="primary" type="submit">
                            {options.sendAsMail ? 'Enviar por e-mail' : 'Imprimir'}
                        </Button>
                    </GroupButton>
                </Form>
            </DialogContent>
        </Dialog>
    );
}

export default ModalFilterDataAndOptions;
