export const Types = {
  NEW_REQUEST: "report/NEW_REQUEST",
  NEW_SUCCESS: "report/NEW_SUCCESS",
  SEND_AS_MAIL_REQUEST: "report/SEND_AS_MAIL_REQUEST",
  SEND_AS_MAIL_SUCCESS: "report/SEND_AS_MAIL_SUCCESS"
};

/* Reducer */

const INITIAL_STATE = {
  loading: false,
  data: null,
};

export default function items(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.NEW_REQUEST:
      return { ...state, loading: true };

    case Types.NEW_SUCCESS:
      return { ...state, loading: false, data: action?.payload?.data };

      case Types.SEND_AS_MAIL_REQUEST:
        return { ...state, loading: true };
  
      case Types.SEND_AS_MAIL_SUCCESS:
        return { ...state, loading: false };

    default:
      return state;
      
  }
}

export const Creators = {
  createRequest: (report) => ({
    type: Types.NEW_REQUEST,
    payload: report,
  }),

  createSuccess: (report) => ({
    type: Types.NEW_SUCCESS,
    payload: report,
  }),

  sendAsMailRequest: (report) => ({
    type: Types.SEND_AS_MAIL_REQUEST,
    payload: report,
  }),

  sendAsMailSuccess: (report) => ({
    type: Types.SEND_AS_MAIL_SUCCESS,
    payload: report,
  })
};
