import React, { useState, useEffect } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import IdleTimer from 'react-idle-timer';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { BrowserRouter } from 'react-router-dom';

import ReduxToastr, { toastr } from 'react-redux-toastr';

import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/global.css';

import Routes from './routes';
import history from './routes/history';

import AppLoader from './components/AppLoader';
import ModalTimeout from './components/ModalTimeout';
import MergePDF from './components/MergePDF';
import ErrorBoundary from './components/ErrorBoundary';

import { store, persistor } from './store';

import { logout } from './services/auth';

import axios from './services/api';

import * as utilsLogin from "./pages/Login/context/util";

export const color = '#e8e8f7';

const mainTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#6259ca',
    },
    secondary: {
      main: '#f1388b',
    },
  },
  props: {},
  overrides: {
    MuiButton: {
      root: {
        color: '#8f9cc0',
        textAlign: 'center',
        verticalAlign: 'middle',
        userSelect: 'none',
        border: '1px solid transparent',
        padding: '0.375rem 0.75rem',
        fontSize: '0.875rem',
        lineHeight: '1.5',
        borderRadius: '3px',
        fontWeight: '400',

        '&.button-square': {
          padding: '16px 16px',
          width: '16px',
          height: '8px',
          fontSize: '8px',
          fontWeight: 'normal',

          '&:hover': {
            color: '#e6e6e6',
          },
        },

        '&.button-square.edit': {
          backgroundColor: '#01b8ff',
          '&:hover': {
            backgroundColor: '#0092cc',
          },
          '&:disabled': {
            backgroundColor: '#0080b3',
          },
        },

        '&.button-square.delete': {
          backgroundColor: '#fd6074',
          '&:hover': {
            backgroundColor: '#e30220',
          },
          '&:disabled': {
            backgroundColor: '#b30000',
          },
        },
      },
    },
    MuiTable: {
      root: {
        border: `1px solid ${color}`,
      },
    },
    MuiTableRow: {
      root: {
        '&:nth-of-type(even)': {
          backgroundColor: 'white',
        },
      },
    },
    MuiTableCell: {
      root: {
        border: `1px solid #d9d9f2`,
      },
    },
    MuiOutlinedInput: {
      notchedOutline: {
        borderColor: `${color}`,
      },
    },
    MuiAutocomplete: {
      endAdornment: {
        top: 'calc(50% - 18px)',
      },
    },
    MuiTab: {
      root: {
        borderRadius: '0px',
      },
    },
    MuiInputBase: {
      input: {
        fontSize: '0.875rem',
        fontWeight: '400',
        lineheight: '1.5',
        color: '#020b2b',
        backgroundColor: '#ffffff',
        borderRadius: '5px',
      },
    },
    MuiAlert: {
      standardInfo: {
        border: '1px solid #1ab6cf',
        color: '#01b8ff',
        backgroundColor: 'none',
      },
    },
  },
});

export default function App() {
  const [showModal, setShowModal] = useState(false);
  const [logoutUser, setLogoutUser] = useState(false);

  let idleTimer = null;

  const onIdle = () => {
    if (window.location.pathname !== '/') {
      setShowModal(true);
    }
  };

  const handleStay = () => {
    setShowModal(false);
    idleTimer.reset();
  };

  useEffect(() => {
    if (showModal) {
      let timer = setTimeout(() => setLogoutUser(true), 30000); //Timeout do modal "Olá você ainda está aí?"
      return () => {
        clearTimeout(timer);
      };
    }
  }, [showModal]);

  useEffect(() => {
    if (logoutUser) {
      setShowModal(false);
      logout();
      history.push('/');
      setLogoutUser(false);
    }
  }, [logoutUser]);

  useEffect(() => {
    axios.interceptors.response.use(
      response => {
        return response;
      },
      error => {
        if (error.response) {
          if (error.response.status === 401) {
            logout();
            history.push('/');
          } else if (error.response.status === 403) {
            toastr.warning('Sem permissão de acesso ao recurso');
            history.push('/agenda');
          } else if (error.response.status !== 404) {
            history.push('/ops');
          }
        } else {
          history.push('/ops');
        }

        return Promise.reject(error);
      }
    );
  }, [logoutUser]);

  return (
    <Provider store={store}>
      <PersistGate loading={<AppLoader />} persistor={persistor}>
        <ThemeProvider theme={mainTheme}>
          <BrowserRouter>
            <IdleTimer
              ref={ref => {
                idleTimer = ref;
              }}
              element={document}
              onIdle={onIdle}
              debounce={250}
              timeout={1800000} //Timeout por inatividade -> 900000 15 minutos
            >
              <ErrorBoundary>
                <Routes />
              </ErrorBoundary>
              <ReduxToastr
                timeOut={4000}
                newestOnTop={false}
                preventDuplicates
                position="bottom-left"
                transitionIn="fadeIn"
                transitionOut="fadeOut"
                progressBar
                closeOnToastrClick
              />
              <MergePDF />
            </IdleTimer>
          </BrowserRouter>
          <ModalTimeout showModal={showModal} handleStay={handleStay} />
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
}
