import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import Button from "@material-ui/core/Button"
import { CSVLink } from "react-csv";
import LoadingIcon from "../../assets/img/loading.svg";
import { useLocation } from "react-router-dom";

import "./styles.css";

import { Creators as TipoImagensActions } from "../../store/ducks/tipoimagem";

import TableTipoImagem from "./TableTipoImagem";
import GridCadastro from "components/GridCadastro";

export default function TipoImagens() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const { access: { servicos } } = useSelector(state => state.permissoes);

  const [userActions, setUserActions] = useState([]);
  const [query, setQuery] = useState({});

  const btnDownloadReport = useRef();

  const { report, loadingReport } = useSelector(
    state => state.tipoImagem
  );

  const handleReport = e => {
    dispatch(TipoImagensActions.reportRequest(query));
  };

  useEffect(() => {
    if (report.length > 0) {
      btnDownloadReport.current.link.click();

      dispatch(TipoImagensActions.reportCleaner());
    }
  }, [dispatch, report]);

  const handleSearchChange = value => {
    if (value.length > 2) {
      setQuery({
        nome: {
          $regex: value,
          $options: "i"
        }
      });
    } else {
      setQuery({})
    }
  };
  useEffect(() => {
    let find = servicos.find(item => item.route === pathname);
    if (find !== undefined) { setUserActions(find) }
  }, [pathname, servicos]);

  return (
    <>
      <GridCadastro
        title="Tipo de Imagem"
        route={"/configuracao/tipoimagens/create"}
        searchRequest={(value) => {
          handleSearchChange(value);
        }}
        titleButtonNovo="Novo Tipo de Imagem"
        component={
          <>
            <CSVLink
              headers={[
                { label: "Nome", key: "nome" },
                {
                  label: "Tipo de documento",
                  key: "tipoDocumento.nome"
                },
                {
                  label: "Data de cadastro",
                  key: "dtCadastro"
                }
              ]}
              data={report}
              className="hidden"
              filename={"tipoimagem.csv"}
              target="_blank"
              ref={btnDownloadReport}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleReport}
            >
              Relatório
            </Button>
          </>
        }
      >
        <TableTipoImagem query={query} />
      </GridCadastro>
      <div className="painel-body">
        <div className="tableContainer">
          {loadingReport && (
            <div className="tableLoading">
              <div className="loadingContent">
                Gerando relatório <img src={LoadingIcon} width={32} alt="Relatório" />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
