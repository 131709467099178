import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row } from 'react-bootstrap';

import Autocomplete from '@material-ui/lab/Autocomplete';
import Skeleton from '@material-ui/lab/Skeleton';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import { Creators as TiposImagemActions } from 'store/ducks/tipoimagem';

import { Container, Field, Buttons } from './style';

export default function IndexadoresClassificaoManual({
  document,
  handleUpdateClassification,
  selectedImagesType,
  setSelectedImagesType,
}) {
  const dispatch = useDispatch();

  const { list: listTpImages, loading: loadingTpImages } = useSelector(
    (state) => state.tipoImagem
  );
  const { loading: loadingDocument } = useSelector(
    (state) => state.classificacao
  );

  useEffect(() => {
    if (document.idTipoDocumento) {
      dispatch(
        TiposImagemActions.listByTipoDocumentoRequest(document.idTipoDocumento)
      );
    }
  }, [dispatch, document.idTipoDocumento]);

  return (
    <Container>
      <Field>
        <TextField
          name={"numeroCNJ"}
          label={"Número do Cliente"}
          size="small"
          variant="outlined"
          defaultValue={document.numeroCNJ}
          disabled={true}
          tabIndex={-1}
        />
      </Field>
      {document.indexadores
        .filter((indexador) => indexador.exibirPesquisaDetalhada)
        .map((indexador) => (
          <Field>
            <TextField
              name={indexador.nome}
              label={indexador.nome}
              size="small"
              variant="outlined"
              defaultValue={indexador.valor}
              disabled={true}
              tabIndex={-1}
            />
          </Field>
        ))}
      <Field>
        {loadingTpImages ? (
          <Skeleton variant="rect" width="100%" height={35} />
        ) : (
          <Autocomplete
            id="combo-box-tipoimagem"
            options={listTpImages}
            getOptionLabel={(option) => option.nome}
            getOptionSelected={(option, value) => option._id === value._id}
            onChange={(e, value) => {
              setSelectedImagesType(value);
            }}
            value={selectedImagesType}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Tipo de imagem"
                variant="outlined"
              />
            )}
            size="small"
            tabIndex={0}
          />

          //   <select>
          //     {listTpImages.map((item) => (
          //       <option value={item._id}>{item.nome}</option>
          //     ))}
          //   </select>
        )}
      </Field>
      <Buttons>
        <Button
          type="button"
          variant="contained"
          color="primary"
          disabled={loadingDocument}
          onClick={handleUpdateClassification}
        >
          Salvar
        </Button>
        <Button
          onClick={(e) => console.log('Voltar')}
          variant="contained"
          color="secondary"
          type="button"
        >
          Voltar
        </Button>
      </Buttons>
    </Container>
  );
}
