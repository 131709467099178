import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import useForm from "react-hook-form";
import Collapse from "@material-ui/core/Collapse";

import bcrypt from "bcryptjs";
import moment from "moment";

import { Creators as UsuariosActions } from "store/ducks/usuarios";

import { Row, Col, Modal, Button, Form } from "react-bootstrap";
import Charts from "./Charts";

import "./style.css";
import { FaEye } from "components/Icons";
import Content from "components/Content";

export default function Welcome() {
  const dispatch = useDispatch();
  const { register, handleSubmit, errors } = useForm();

  //Redux
  const {
    auth: { changePassword, id, vencimentoSenha, empresa },
  } = useSelector((state) => state.usuarios);
  const { list } = useSelector((state) => state.empresas);
  const { passwordChanged } = useSelector((state) => state.usuarios);
  const { strengthPassword } = useSelector(
    (state) => state.configuracoes.configuracoes
  );

  //Local
  const [showModal, setShowModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [msgExpiredPass, setMsgExpiredPass] = useState(false);
  const [passwordSaveButton, setPasswordSaveButton] = useState(false);
  const [showAnalitic, setShowAnalitic] = useState(false);

  const onSubmit = async (values) => {
    const hashingPass = async (password) => {
      const salt = await bcrypt.genSalt(10);
      const passHash = await bcrypt.hash(password, salt);
      const editedPass = passHash;

      return editedPass;
    };

    let passHashed = await hashingPass(values.senha);
    dispatch(
      UsuariosActions.changePasswordRequest({
        id,
        changePassword: false,
        senha: passHashed,
      })
    );

    if (msgExpiredPass && list) {
      let empresaUsuario = list.filter((item) => item._id === empresa)[0];
      let date = new Date(vencimentoSenha);
      date.setDate(date.getDate() + empresaUsuario.vencimentoSenha);
      let formatISO = date.toISOString();

      dispatch(UsuariosActions.editRequest({ vencimentoSenha: formatISO }));
      setShowModal(false);
    }
  };

  useEffect(() => {
    if (changePassword && !passwordChanged) {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  }, [changePassword, passwordChanged]);

  useEffect(() => {
    //dispatch(ConfiguracaoActions.getRequest());
    // dispatch(GraphsActions.totalByCompanyRequest());
  }, [dispatch]);

  useEffect(() => {
    if (vencimentoSenha && list) {
      let empresaUsuario = list.filter((item) => item._id === empresa)[0];
      let date1 = moment(vencimentoSenha).format("l");
      let date2 = moment(empresaUsuario.vencimentoSenha).format("l");

      if (date1 >= date2) {
        setShowModal(true);
        setMsgExpiredPass(true);
      }
    }
  }, [empresa, list, vencimentoSenha]);

  const checkPassword = (e) => {
    const passwordRegex = new RegExp(strengthPassword);

    if (passwordRegex.test(e.target.value)) {
      setPasswordSaveButton(true);
    } else {
      setPasswordSaveButton(false);
    }
  };

  return (
    <Fragment>
      <Content>
        <Row>
          <Col md="12" className="painel">
            <div className="painel-content">
              <Collapse in={!showAnalitic}>
                <div className="painel-body painel-body-grahps">
                  <Charts />
                </div>
              </Collapse>
            </div>
          </Col>
        </Row>
      </Content>
      {showModal && (
        <Modal show={showModal} centered>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Modal.Header>
              <Modal.Title>
                {msgExpiredPass ? "Senha Expirada" : "Mudança de Senha "}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Label>Nova Senha</Form.Label>
              <Form.Control
                ref={register({
                  validate: (value) => value !== "" || "Campo obrigatório!",
                })}
                name="senha"
                type={showPassword ? "text" : "password"}
                onChange={(e) => checkPassword(e)}
              />
              <span className="color">
                {errors.senha && errors.senha.message}
              </span>
              <FaEye
                className="view__pass"
                size="1x"
                onClick={() => setShowPassword(!showPassword)}
              />
            </Modal.Body>
            <Modal.Footer>
              <Modal.Footer>
                <Button type="submit" variant="success">
                  Salvar
                </Button>
              </Modal.Footer>
            </Modal.Footer>
          </Form>
        </Modal>
      )}
    </Fragment>
  );
}
