import { Box, FormControl, MenuItem, Select } from "@material-ui/core";
import React from "react";

export function Dropdown({
    value,
    style,
    options = [],
    onChange = () => { },
}) {
    return (
        <Box sx={{ minWidth: 120 }}
        style={style}
        >
            <FormControl>
                <Select
                    labelId="simple-select-label"
                    id="simple-select"
                    value={value}
                    label="Age"
                    onChange={onChange}
                    style={{ margin: "1px", fontSize: "0.8em", fontWeight: "bold" }}
                >
                    {options.map(({ value, label }) => (
                        <MenuItem value={value}>{label}</MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    )

}