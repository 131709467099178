import api from "services/api";
import { getToken } from "services/auth";
import { call, select, put } from "redux-saga/effects";
import { toastr } from "react-redux-toastr";

import { Creators as DashboardScalingPendingActions } from "store/ducks/dashboardscalingpending";

export const getOrganization = (state) => state.usuarios.auth.organization;

export function* createScalingPending(action) {
  const organization = yield select(getOrganization);

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const body = { ...action.payload };

    const { data } = yield call(
      api.post,
      `dashboard/custom/pending-scalings`,
      body,
      headerParams
    );

    yield put(DashboardScalingPendingActions.createSuccess(data));
  } catch (error) {
    toastr.error("Ops", "Não foi possível listar o escalonamento pendente.");
  }
}

export function* exportScalingPending({ payload }) {
  try {
    const organization = yield select(getOrganization);
    const { option, groupField, groupDescription } = payload;

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
      params: { option, groupField, groupDescription },
    };

    toastr.success("", `Exportação agendada com sucesso.`);

    const { data } = yield call(api.get, `dashboard/custom/pending-scalings/export`, headerParams);

    if (data.error) {
      toastr.error("", data.message);
      yield put(DashboardScalingPendingActions.exportSuccess());
      return
    }

    yield put(DashboardScalingPendingActions.exportSuccess(data));

  } catch (error) {
    console.error(error);
    toastr.error("Ops", "Não foi possivel exportar os dados.");
  }
}