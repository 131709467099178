import api from 'services/api';
import { getToken } from 'services/auth';
import { put, call, select } from "redux-saga/effects";
import { toastr } from "react-redux-toastr";
import { push } from "connected-react-router";

import { Creators as GruposEmpresariais } from 'store/ducks/gruposempresariais';

export const getOrganization = state => state.usuarios.auth.organization;

export function* saveGrupoEmpresarial({ payload }) {
  const organization = yield select(getOrganization);
  const { _id, ...body } = payload;

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization }
    }

    if (_id) {
      yield call(
        api.put,
        `gruposempresariais/${_id}`,
        body,
        headerParams
      );
    } else {
      yield call(
        api.post,
        `gruposempresariais`,
        body,
        headerParams
      );
    }

    yield put(push('/administracao/gruposempresariais'));
  } catch (error) {
    toastr.error('Ops', 'Não foi possível editar o grupo empresarial.');
  }
}

export function* listGruposEmpresariais({ payload }) {
  const organization = yield select(getOrganization);
  const { page, limit, sort } = payload;

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
      params: { page, limit, sort }
    }

    const response = yield call(
      api.get,
      'gruposempresariais',
      headerParams
    );

    const data = {
      list: response.data.docs,
      total: response.data.total
    };

    yield put(GruposEmpresariais.listSuccess(data));

  } catch (error) {
    toastr.error('Ops', 'Não foi possível listar as empresas.');
  }
}