const colors = {
    red: '#E06969',
    yellow: '#e5d459',
    green: '#87df5a'
}

export const TASK_DATE_STATUS_COLORS = {
    'Atrasadas': colors.red,
    'Hoje': colors.yellow,
    'Futuras': colors.green,
}

export const CHART_COLORS = [
    TASK_DATE_STATUS_COLORS.Atrasadas,
    TASK_DATE_STATUS_COLORS.Futuras,
    TASK_DATE_STATUS_COLORS.Hoje,
]

export const TASK_PRIORITY_STATUS_COLORS = {
    'Alta': colors.red,
    'Média': colors.yellow,
    'Baixa': colors.green,
}

export const TASK_APROVATION_STATUS_COLORS = {
    'REPROVADO': colors.red,
    'AGUARDANDO APROVACAO': colors.yellow,
    'APROVADO': colors.green,
    'EM ANDAMENTO': colors.red,
    'ENCERRADA': colors.green,
}
