import { call, put, select } from "redux-saga/effects";

import { toastr } from "react-redux-toastr";
import { push } from "connected-react-router";
import history from "../../routes/history";

import api from "../../services/api";
import { getToken } from "../../services/auth";

import { Creators as ParamFormalizacaoAuditoriaActions } from "../ducks/paramformalizacaoauditoria";

export const getOrganization = (state) => state.usuarios.auth.organization;
export const getUserId = (state) => state.usuarios.auth.id;

export function* getParamFormalizacaoAuditoria(action) {
  try {
    const organization = yield select(getOrganization);

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const { data }  = yield call(
      api.get,
      `paramformalizacao/auditoria/${action.payload}`,
      headerParams
    );

    yield put(ParamFormalizacaoAuditoriaActions.getSuccess(data));
  } catch (error) {
    toastr.error("Ops", "Não foi possível listar a auditoria do config checklist");
  }
}
