import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { Form } from '@unform/web';
import { ValidationError } from 'yup';

import { Creators as OnboardingActions } from 'store/ducks/onboarding';
import { Creators as TipoDocumentosActions } from 'store/ducks/tiposdocumento';
import { Creators as DepartamentosActions } from 'store/ducks/departamentos';
import { Creators as TarefasActions } from 'store/ducks/tipostarefas';
import { Creators as TiposImagemActions } from 'store/ducks/tipoimagem';

import Grid from '@material-ui/core/Grid';
import ButtonMui from '@material-ui/core/Button';
import BackButton from 'components/BackButton';
import InputForm from 'components/InputForm';
import AutocompleteForm from 'components/AutocompleteForm';
import { FormControlLabel, Switch } from '@material-ui/core';

import { InfoIcon, FaPlusCircle } from 'components/Icons';

import OnboardingSchema from 'validators/OnboardingSchema';
import Content from 'components/Content';

import { toastr } from 'react-redux-toastr';

import {
  ContainerConfig,
  Header,
  FormContainer,
  StepList,
  StepItem,
  StepItemMobile,
  StepListContainer,
  PreviewStepContainer,
  PreviewStepContent,
  PreviewButtons,
  EmptyStep,
  StepsConfig,
  StepsConfigHeader,
  StepsConfigBody,
  StepsConfigLayout,
  StepConfigTypes,
  StepConfigTypesHeader,
  StepConfigTypesBody,
  NovaEtapaButton,
} from '../../OnboardingStyle';

import { tiposEtapa } from './tiposEtapa';

import CameraStep from './StepTypes/camera';
import TextStep from './StepTypes/text';
import ValidationStep from './StepTypes/validation';
import FieldsStep from './StepTypes/fields';
import ImageStep from './StepTypes/image';

export default function FormAdmOnboarding(params) {
  let { onboarding } = params.location.state ?? {};
  const dispatch = useDispatch();
  const formRef = useRef(null);

  const [currentStep, setCurrentStep] = useState(0);
  const [loading, setLoading] = useState(false);

  const { list: listTpDocs, loading: loadingListTpDocs } = useSelector(
    (state) => state.tipoDocumentos
  );
  const { list: listDeps, loading: loadingListDeps } = useSelector(
    (state) => state.departamentos
  );
  const { list: listTiposTarefa, loading: loadingListTiposTarefas } =
    useSelector((state) => state.tipostarefas);

  const {
    access: { empresas },
  } = useSelector((state) => state.permissoes);

  const usuarioId = useSelector((state) => state.usuarios.auth.id);

  useEffect(() => {
    dispatch(TipoDocumentosActions.listRequest({ empresas }));
    dispatch(DepartamentosActions.listRequest());
    dispatch(TarefasActions.listRequest({ page: 1, limit: 10, search: '' }));
  }, [dispatch]);

  useEffect(() => {
    if (onboarding) {
      formRef.current.setData(onboarding);
      if (onboarding?.tipoDocumento) {
        handleImageTypes(onboarding?.tipoDocumento);
      }

    }
  }, [onboarding]);

  const [status, setStatus] = useState(false);
  const [config, setConfig] = useState(
    (onboarding && onboarding.config) || {
      layout: {
        background: '#ededed',
        logo: 'https://gruponewspace.com.br/wp-content/uploads/2022/01/logo-new-space.png',
        step: {
          normal: {
            border: '1px solid #0B736C',
            background: '#0B736C',
            numberColor: '#fff',
            textColor: '#3c3c3c',
          },
          selected: {
            border: '1px solid #0B736C',
            background: '#fff',
            numberColor: '#0B736C',
            textColor: '#3c3c3c',
          },
        },
        buttons: {
          background: '#0B736C',
          color: '#fff',
        },
      },
      steps: [],
    }
  );



  const handleImageTypes = (tipoDocumento) => {
    dispatch(
      TiposImagemActions.listByTipoDocumentoRequest(tipoDocumento)
    );
  }

  const handleCheckStatus = () => {
    setStatus(!status);
  };

  const handleCurrentStep = (stepIndex) => {
    setLoading(true);
    setTimeout(() => {
      setCurrentStep(stepIndex);
      setLoading(false);
    }, 100);
  };

  const newStep = () => {
    if (
      ['fields', 'image', 'camera', 'validation'].includes(
        formRef.current.getFieldValue('tipoEtapa').id
      ) &&
      !formRef.current.getFieldValue('tipoDocumento')
    ) {
      toastr.error(
        'Tipo de documento obrigatório',
        'Você precisa selecionar um tipo de documento antes de criar uma etapa deste tipo.'
      );
      return;
    }

    if (!formRef.current.getFieldValue('nomeEtapa')) {
      toastr.error(
        'Nome da etapa obrigatório',
        'Você precisa nomear a etapa antes de cria-la.'
      );

      return;
    }

    if (!formRef.current.getFieldValue('tipoEtapa')) {
      toastr.error(
        'Tipo da etapa obrigatório',
        'Você precisa selecionar o tipo da etapa antes de cria-la.'
      );

      return;
    }

    const step = {
      name: formRef.current.getFieldValue('nomeEtapa'),
      type: formRef.current.getFieldValue('tipoEtapa').id,
    };

    formRef.current.setFieldValue('nomeEtapa', '');

    setConfig({ ...config, steps: [...config.steps, step] });
  };

  const onFormChange = (step, setting) => {
    let updatedSteps = [...config.steps];

    const stepIndex = updatedSteps.findIndex((x) => x.name === step.name);

    updatedSteps[stepIndex] = { ...step, ...setting };

    setConfig({ ...config, steps: updatedSteps });
  };

  const onSubmit = async (values) => {
    let onboardingForm = {
      ...formRef.current.getData(),
      userAcao: usuarioId,
      status,
      config,
    };

    try {
      await OnboardingSchema.validate(onboardingForm, {
        abortEarly: false,
      });

      if (onboarding) {
        onboardingForm = {
          ...onboardingForm,
          _id: onboarding._id,
          tipoDocumento:
            onboardingForm.tipoDocumento._id || onboarding.tipoDocumento,
          tipoTarefa: onboardingForm.tipoTarefa._id || onboarding.tipoTarefa,
          departamentos:
            onboardingForm.departamentos.length > 0
              ? onboardingForm.departamentos
              : onboarding.departamentos,
        };
        dispatch(OnboardingActions.editRequest(onboardingForm));
      } else {
        dispatch(OnboardingActions.createRequest(onboardingForm));
      }
    } catch (err) {
      const validationErrors = {};
      if (err instanceof ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });

        formRef.current.setErrors(validationErrors);
      }
    }
  };

  return (
    <Content>
      <Row>
        <Col md="12" className="painel">
          <div className="painel-content">
            <div className="painel-title text-left">Novo processo de onboarding</div>
            <div className="painel-body">
              <Form ref={formRef} onSubmit={onSubmit}>
                <Row>
                  <Col md="12">
                    <InputForm label="Nome do formulário *" name="nome" />
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <InputForm label="Descrição" name="descricao" />
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    {!loadingListDeps && (
                      <AutocompleteForm
                        multiple
                        options={listDeps}
                        label="Departamentos *"
                        name="departamentos"
                        getOptionLabel={(option) => option.nome}
                        defaultValue={listDeps.filter((item) => {
                          return onboarding?.departamentos?.some(
                            (i) => i === item._id
                          );
                        })}
                        transformOnSelect={(options) =>
                          options ? options.map((option) => option._id) : []
                        }
                        forceShrink={true}
                      />
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    {!loadingListTpDocs && (
                      <AutocompleteForm
                        multiple={false}
                        options={listTpDocs}
                        label="Tipo de documento *"
                        name="tipoDocumento"
                        getOptionLabel={(option) => option.nome}
                        defaultValue={
                          onboarding?.tipoDocumento &&
                          listTpDocs.find((item) => {
                            return onboarding?.tipoDocumento === item._id;
                          })
                        }
                        forceShrink={true}
                      />
                    )}
                  </Col>

                  <Col md="6">
                    {!loadingListTiposTarefas && (
                      <AutocompleteForm
                        options={listTiposTarefa}
                        label="Fluxo de destino"
                        name="tipoTarefa"
                        getOptionLabel={(option) => option.nome}
                        defaultValue={listTiposTarefa.find((item) => {
                          return onboarding?.tipoTarefa === item._id;
                        })}
                        forceShrink={true}
                      />
                    )}
                  </Col>
                </Row>

                <StepsConfig>
                  <StepsConfigHeader>
                    <h3>Formulário</h3>
                  </StepsConfigHeader>
                  <StepsConfigBody>
                    <StepsConfigLayout>
                      <h4>Layout</h4>
                      <div></div>
                      <div>
                        <InputForm label="Logo" name="logo" />
                      </div>
                      <div>
                        <InputForm label="Background" name="background" />
                      </div>
                    </StepsConfigLayout>
                    <StepConfigTypes>
                      <StepConfigTypesHeader>
                        <h4>Steps</h4>
                      </StepConfigTypesHeader>
                      <StepConfigTypesBody>
                        <div>
                          <InputForm
                            label="Preencha o nome da etapa"
                            name="nomeEtapa"
                          />
                        </div>
                        <div>
                          <AutocompleteForm
                            options={tiposEtapa}
                            label="Selecione o tipo de etapa"
                            name="tipoEtapa"
                            getOptionLabel={(option) => option.nome}
                          />
                        </div>
                        <div>
                          <NovaEtapaButton
                            type="button"
                            onClick={() => newStep()}
                          >
                            <FaPlusCircle />
                            Nova etapa
                          </NovaEtapaButton>
                        </div>
                      </StepConfigTypesBody>
                    </StepConfigTypes>
                  </StepsConfigBody>
                </StepsConfig>

                <div className="stepsForm">
                  <ContainerConfig
                    $fullwidth={true}
                    $direction="row"
                    $border={true}
                  >
                    {config.steps.length > 0 && (
                      <StepListContainer>
                        <StepList>
                          {config.steps.map((step, index) => (
                            <StepItem
                              key={index}
                              onClick={() => handleCurrentStep(index)}
                              active={currentStep === index}
                              $layout={config.layout.step}
                            >
                              <span>{index + 1}</span>
                              <strong>{step.name}</strong>
                            </StepItem>
                          ))}
                        </StepList>
                      </StepListContainer>
                    )}
                    <PreviewStepContainer
                      $fullwidth={config.steps.length === 0}
                    >
                      <PreviewStepContent>
                        {loading ? (
                          <div></div>
                        ) : (
                          <>
                            {config.steps.length > 0 ? (
                              <>
                                {config.steps[currentStep].type === 'text' && (
                                  <TextStep
                                    settings={config.steps[currentStep]}
                                    onChange={onFormChange}
                                  />
                                )}
                                {config.steps[currentStep].type === 'image' && (
                                  <ImageStep
                                    tipoDocumento={formRef.current?.getFieldValue(
                                      'tipoDocumento'
                                    )}
                                    onChange={onFormChange}
                                    settings={config.steps[currentStep]}
                                  />
                                )}
                                {config.steps[currentStep].type ===
                                  'camera' && (
                                    <CameraStep
                                      tipoDocumento={formRef.current?.getFieldValue(
                                        'tipoDocumento'
                                      )}
                                      onChange={onFormChange}
                                      settings={config.steps[currentStep]}
                                    />
                                  )}
                                {config.steps[currentStep].type ===
                                  'fields' && (
                                    <FieldsStep
                                      tipoDocumento={formRef.current?.getFieldValue(
                                        'tipoDocumento'
                                      )}
                                      onChange={onFormChange}
                                      settings={config.steps[currentStep]}
                                    />
                                  )}
                                {config.steps[currentStep].type ===
                                  'validation' && (
                                    <ValidationStep
                                      settings={config.steps[currentStep]}
                                      onChange={onFormChange}
                                      tipoDocumento={formRef.current?.getFieldValue(
                                        'tipoDocumento'
                                      )}
                                    //   nextStep={handleNextStep}
                                    />
                                  )}
                              </>
                            ) : (
                              <EmptyStep>
                                <span>
                                  <InfoIcon />
                                </span>
                                <h4>Nenhuma etapa adicionada</h4>
                              </EmptyStep>
                            )}{' '}
                          </>
                        )}
                      </PreviewStepContent>
                    </PreviewStepContainer>
                  </ContainerConfig>
                </div>

                <Row>
                  <Col>
                    <FormControlLabel
                      control={
                        <Switch
                          name="status"
                          onChange={handleCheckStatus}
                          color="primary"
                        />
                      }
                      label="Ativo"
                    />
                  </Col>
                </Row>

                <div className="buttons">
                  <Grid container spacing={1}>
                    <Grid item>
                      <ButtonMui
                        variant="contained"
                        color="primary"
                        type="submit"
                      >
                        Enviar
                      </ButtonMui>
                    </Grid>
                    <Grid item>
                      <ButtonMui
                        variant="contained"
                        color="secondary"
                        type="reset"
                      >
                        Limpar
                      </ButtonMui>
                    </Grid>
                    <Grid item>
                      <BackButton variant="contained" color="secondary">
                        Voltar
                      </BackButton>
                    </Grid>
                  </Grid>
                </div>
              </Form>
            </div>
          </div>
        </Col>
      </Row>
    </Content>
  );
}
