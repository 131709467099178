export const Types = {
    LIST_REQUEST: 'organizacao/LIST_REQUEST',
    LIST_SUCCESS: 'organizacao/LIST_SUCCESS'
}

const INITIAL_STATE = {
    list: [],
    loading: false
}

export default function organizacoes(state = INITIAL_STATE, action) {
    switch(action.type) {

        case Types.LIST_REQUEST:
            return { ...state, loading: true }
        
        case Types.LIST_SUCCESS:
            return { ...state, loading: false, list: action.payload }
        
        default:
            return state;
    }
}

export const Creators = {
    
    listRequest: () => ({
        type: Types.LIST_REQUEST
    }),

    listSuccess: organizacoes => ({
        type: Types.LIST_SUCCESS,
        payload: organizacoes
    })
}