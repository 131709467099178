import React, { useEffect, useRef, useState, useMemo } from "react";

import { Row, Col } from "react-bootstrap";
import { Document, Page, pdfjs } from "react-pdf";
import { useDispatch, useSelector } from "react-redux";

import ButtonSquare from "components/ButtonSquare";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";

import CircularProgress from "@material-ui/core/CircularProgress";

import { Creators as TemplateSignaturePointsActions } from "store/ducks/templatesignaturepoints";
import { getTemplateAsB64 } from "../../services/getTemplateAsB64";

import "./style.css";

function ModalPreviewTemplate({ handleClose, template, state, open }) {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/2.1.266/pdf.worker.js`;

  const dispatch = useDispatch();

  const [b64, setB64] = useState(null);

  const { data, loading } = useSelector(
    (state) => state.templatesignaturepoints
  );

  const { templateSelected } = useSelector((state) => state.template);

  const onSubmit = () => {
    dispatch(
      TemplateSignaturePointsActions.editRequest({
        idTemplate: templateSelected?._id,
        points,
      })
    );

    handleClose();
  };

  useEffect(() => {
    if (open) {
      let paragraphs = state.paragraph.map((x) => {
        return x.paragraph;
      });

      const header = !!state.header?.currentContent
        ? state.header?.currentContent
        : "";
      const footer = !!state.footer?.currentContent
        ? state.footer?.currentContent
        : "";

      let contentString = header + paragraphs + footer;

      getTemplateAsB64(contentString)
        .then((response) => {
          setB64(response.data);
          if (templateSelected?._id)
            dispatch(
              TemplateSignaturePointsActions.listRequest(templateSelected?._id)
            );
        })
        .catch((error) => {
          setB64(null);
          console.error("Error fetching template:", error);
        });
    }
  }, [open, state, templateSelected, dispatch]);

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [points, setPoints] = useState([]);

  useEffect(() => {
    setPoints(data?.points || []);
  }, [data]);

  const canvasRef = useRef(null);

  const ObjectId = (
    m = Math,
    d = Date,
    h = 16,
    s = (s) => m.floor(s).toString(h)
  ) => s(d.now() / 1000) + " ".repeat(h).replace(/./g, () => s(m.random() * h));

  const removePoint = (_id) => {
    const updatedPoints = points.filter((x) => x._id !== _id);
    setPoints(updatedPoints);
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const onPageChange = (newPage) => {
    setPageNumber(newPage);
  };

  function onNewSignature(e) {
    const rect = canvasRef.current.getBoundingClientRect();
    const canvasX = rect.left + window.scrollX;
    const canvasY = rect.top + window.scrollY;

    setPoints([
      ...points,
      {
        page: pageNumber,
        x: e.pageX - canvasX,
        y: e.pageY - canvasY,
        email: "",
        assinatura: "",
        _id: ObjectId(),
      },
    ]);
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xl" fullWidth>
      <DialogTitle className="header-modal">
        Configuração de assinaturas
      </DialogTitle>
      <DialogContent>
        <Row>
          <Col md={12}>
            {!b64 && <CircularProgress size={20} />}

            {!!b64 && (
              <Row>
                <Col lg="12">
                  <div className="pagination">
                    <p>
                      Página {pageNumber} de {numPages}
                    </p>
                    <div className="paginationButtons">
                      <button onClick={() => onPageChange(pageNumber - 1)}>
                        Anterior
                      </button>
                      <button onClick={() => onPageChange(pageNumber + 1)}>
                        Próxima
                      </button>
                    </div>
                  </div>
                </Col>

                <Col lg="8">
                  {!!b64 && (
                    <div className="canvas mb-0">
                      <div className="viewer" ref={canvasRef}>
                        <Document
                          file={`data:application/pdf;base64,${b64}`}
                          onLoadSuccess={onDocumentLoadSuccess}
                          onClick={onNewSignature}
                          noData={<h3>Loading...</h3>}
                          renderMode="canvas"
                          className="pdfviewer"
                        >
                          <Page
                            pageNumber={pageNumber}
                            onRenderSuccess={() => onPageChange(pageNumber)}
                            renderTextLayer={false}
                            scale={1}
                            height={800}
                          >
                            <div
                              style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%",
                                pointerEvents: "none",
                              }}
                            >
                              {points?.map((point, index) => (
                                <div key={index}>
                                  {pageNumber === point.page && (
                                    <div
                                      className="signature"
                                      style={{
                                        top: point.y,
                                        left: point.x,
                                      }}
                                    >
                                      Assinatura {index + 1}
                                    </div>
                                  )}
                                </div>
                              ))}
                            </div>
                          </Page>
                        </Document>
                      </div>
                    </div>
                  )}
                </Col>
                <Col lg="4" sm="12">
                  <div className="fields">
                    <div className="w-100">
                      {points?.map((point, index) => (
                        <div className="mt-3 signature-info">
                          <Row>
                            <Col md={6} sm={12}>
                              <span>Assinatura {index + 1}</span>
                            </Col>
                            <Col md={6} sm={12}>
                              <ButtonSquare
                                float="right"
                                icon="delete"
                                variant="contained"
                                color="secondary"
                                onClick={() => removePoint(point._id)}
                              />
                            </Col>
                          </Row>
                        </div>
                      ))}
                    </div>
                    <DialogActions className="mt-2">
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        onClick={() => onSubmit()}
                      >
                        Salvar
                      </Button>

                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => handleClose()}
                      >
                        Cancelar
                      </Button>
                    </DialogActions>
                  </div>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </DialogContent>
    </Dialog>
  );
}

export default ModalPreviewTemplate;
