import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  checkboxWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "7rem",
    minHeight: "7rem",
    borderRadius: "0.5rem",
    border: `2px solid ${theme.palette.grey[400]}`,
    backgroundColor: "#fff",
    boxShadow: "0 5px 10px rgba(0, 0, 0, 0.1)",
    transition: "0.15s ease",
    cursor: "pointer",
    position: "relative",
    "&:hover": {
      borderColor: "#444444",
    },
  },
  checkboxTile: {
    position: "absolute",
    display: "block",
    width: "1.25rem",
    height: "1.25rem",
    border: `2px solid ${theme.palette.grey[400]}`,
    backgroundColor: "#fff",
    borderRadius: "50%",
    top: "0.25rem",
    left: "0.25rem",
    transition: "0.25s ease",
  },
  checkboxLabel: {
    color: "#707070",
    transition: "0.375s ease",
    textAlign: "center",
    position: "absolute",
    marginTop: "58px",
  },
  checkboxIcon: {
    transition: "0.375s ease",
    color: "#494949",
    "& svg": {
      width: "3rem",
      height: "3rem",
    },
  },
}));

const CheckboxComponent = ({ title, Icon, select, active }) => {
  const classes = useStyles();

  return (
    <div
      className={classes.checkboxWrapper}
      style={active ? { borderColor: "#000" } : {}}
      onClick={select}
    >
      <Icon fontSize="large" />
      <span className={classes.checkboxLabel}>{title} </span>
    </div>
  );
};

export default CheckboxComponent;
