import React, { useRef } from "react";
import { Form } from '@unform/web';
import { useDispatch, useSelector } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { ValidationError } from "yup";

import Grid from '@material-ui/core/Grid';
import ButtonMui from "@material-ui/core/Button";
import BackButton from "components/BackButton";
import AutocompleteForm from "components/AutocompleteForm";
import InputForm from "components/InputForm";

import FilaSchema from "validators/FilaSchema";

import { Creators as FilaActions } from "store/ducks/filas";

import "./styles.css";
import Content from "components/Content";

export default function FormEsteiraConfig() {

    const dispatch = useDispatch();
    const usuario = useSelector(state => state.usuarios.auth);
    const empresas = useSelector(state => state.empresas.list);
    const formRef = useRef(null);

    const onSubmit = async values => {

        const filaesteira = {
            ...values,
            userAcao: usuario.id
        }

        try {
            await FilaSchema.validate(filaesteira, {
                abortEarly: false
            });
            dispatch(FilaActions.createRequest(filaesteira));
        } catch (err) {
            const validationErrors = {};
            if (err instanceof ValidationError) {
                err.inner.forEach(error => {
                    validationErrors[error.path] = error.message;
                })

                formRef.current.setErrors(validationErrors);
            }
        }

    }

    return (
        <Content>
            <Row>
                <Col md="12" className="painel">
                    <div className="painel-content">
                        <div className="painel-title text-left">Nova Fila</div>
                        <div className="painel-body">
                            <Form ref={formRef} onSubmit={onSubmit}>
                                <Row>
                                    <Col md="12">
                                        <AutocompleteForm
                                            label="Empresa*"
                                            name="empresa"
                                            options={empresas}
                                            transformOnSelect={option => option._id}
                                            getOptionLabel={option => option.fantasia}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="12">
                                        <InputForm
                                            name="descricao"
                                            label="Nome"
                                        />
                                    </Col>
                                </Row>

                                <div className="buttons">
                                    <Grid container spacing={1}>
                                        <Grid item  >
                                            <ButtonMui
                                                variant="contained"
                                                color="primary"
                                                type="submit">Enviar</ButtonMui>
                                        </Grid>
                                        <Grid item >
                                            <ButtonMui
                                                variant="contained"
                                                color="secondary"
                                                type="reset">Limpar</ButtonMui>
                                        </Grid>
                                        <Grid item >
                                            <BackButton
                                                variant="contained"
                                                color="secondary">Voltar</BackButton>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Form>
                        </div>
                    </div>
                </Col>
            </Row>
        </Content>
    );
}
