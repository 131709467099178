import { call, put, select } from "redux-saga/effects";

import { toastr } from "react-redux-toastr";

import api from "../../services/api";

import { getToken } from "../../services/auth";
import { Creators as Actions } from "../ducks/respostaOrgaos";

export const getOrganization = (state) => state.usuarios.auth.organization;
export const getUser = (state) => state.usuarios;

export function* createOrgaosAnswer({ payload }) {
    const organization = yield select(getOrganization);

    try {
        const headerParams = {
            headers: { Authorization: getToken(), xkeyorg: organization },
        };

        const documentoId = payload.documentoId;

        delete payload.documentoId;

        let body = {
            ...payload,
        };

        const { data } = yield call(api.post, `documento/${documentoId}/anwser`, body, headerParams);

        yield put(Actions.createSuccess(data));

        toastr.success("", "Resposta à òrgão efetuada com sucesso.");
    } catch (error) {
        toastr.error("Ops", "Não foi possível enviar a resposta.");
    }
}

export function* listOrgaos({ payload }) {
    const organization = yield select(getOrganization);

    try {
        const headerParams = {
            headers: { Authorization: getToken(), xkeyorg: organization },
        };

        const { data } = yield call(api.get, `orgaos/list`, headerParams);

        yield put(Actions.listOrgaosSuccess(data.orgaos));

    } catch (error) {
        toastr.error("Ops", "Não foi possível listar os órgãos cadastrados.");
    }
}

export function* listTipoManifestacoes({ payload }) {
    const organization = yield select(getOrganization);
    const { orgao } = payload;

    try {
        const headerParams = {
            headers: { Authorization: getToken(), xkeyorg: organization },
        };

        const { data } = yield call(api.get, `tiposdemanifestacao/${orgao}`, headerParams);

        yield put(Actions.listOpcoesSuccess(data.tipoManifestacoesList));

    } catch (error) {
        toastr.error("Ops", `Não foi possível listar os tipos de manifestações do órgão ${orgao}.`);
    }
}
