import api from "services/api";
import { getToken } from "services/auth";
import { call, select, put, all, takeLatest } from "redux-saga/effects";
import { toastr } from "react-redux-toastr";
import { push } from "connected-react-router";

import { Creators as ComarcaActions, Types } from "../ducks/comarca";

export const getOrganization = state => state.usuarios.auth.organization;
export const getUserId = state => state.usuarios.auth.id;

export function* listComarca({ payload }) {
  try {
    const organization = yield select(getOrganization);

    const { page, limit, sort, uf } = payload;

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
      params: { page, limit, sort, uf },
    };

    const response = yield call(api.post, `comarcas`, {}, headerParams);

    const data = {
      total: response.data.total,
      list: response.data.docs,
    };

    yield put(ComarcaActions.listSuccess(data));
  } catch (error) {
    toastr.error("Ops", "Não foi possível listar os comarcas.");
  }
}

export function* getComarca(action) {
  try {
    const headerParams = {
      headers: { Authorization: getToken() },
    };

    const { data } = yield call(
      api.get,
      `comarca/${action.payload}`,
      headerParams
    );

    yield put(ComarcaActions.getSuccess(data));
  } catch (error) {
    toastr.error("Ops", "Não foi possível buscar os dados do Comarca.");
  }
}

export function* editComarca(action) {
  const userId = yield select(getUserId);

  try {
    const organization = yield select(getOrganization);

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const body = { ...action.payload, id_user_acao: userId };

    const { data } = yield call(
      api.put,
      `comarca/${action.payload._id}`,
      body,
      headerParams
    );

    yield put(push(`/configuracao/comarca`));

    yield put(ComarcaActions.editSuccess(data));

    toastr.success("Comarca editada com sucesso.");
  } catch (error) {
    toastr.error("Ops", "Não foi possível editar o Comarca.");
  }
}

export function* createComarca(action) {
  const organization = yield select(getOrganization);

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const { data } = yield call(
      api.post,
      `comarca`,
      action.payload,
      headerParams
    );

    yield put(push(`/configuracao/comarca`));

    yield put(ComarcaActions.createSuccess(data));

    toastr.success("", "Comarca criada com sucesso.");
  } catch (error) {
    toastr.error("Ops", "Não foi possível criar a Comarca.");
  }
}

export function* deleteComarca(action) {
  try {
    const organization = yield select(getOrganization);
    const userId = yield select(getUserId);

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization, userId },
    };

    yield call(api.delete, `comarca/${action.payload}`, headerParams);

    toastr.success("", "Comarca removida com sucesso.");

    yield put(
      ComarcaActions.listRequest({
        page: 1,
        limit: 10,
        sort: "nome",
      })
    );
  } catch (error) {
    //   toastr.error("Ops", "Não foi possível remover os comarcas.");
  }
}

export default all([
  takeLatest(Types.LIST_REQUEST, listComarca),
  takeLatest(Types.GET_REQUEST, getComarca),
  takeLatest(Types.EDIT_REQUEST, editComarca),
  takeLatest(Types.NEW_REQUEST, createComarca),
  takeLatest(Types.DELETE_REQUEST, deleteComarca),
]);
