import api from 'services/api';
import { getToken } from 'services/auth';
import { call, select, put, all, takeLatest } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';

import { Creators as ParteActions, Types } from 'store/ducks/parte';
import { Creators as PartesActions } from 'store/ducks/partes';

export const getOrganization = state => state.usuarios.auth.organization;
export const getParamsPartes = state => ({ 
  page: state.partes.page,
  search: state.partes.search,
  documento: state.partes.documento,
  sort: state.partes.sort,
  limit: state.partes.limit
})

export function* saveParte({ payload }) {
  const organization = yield select(getOrganization);
  const { _id, ...body } = payload.parte;

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    if (_id) {
      yield call(api.put, `partes/${_id}`, body, headerParams);
    } else {
      yield call(api.post, 'partes', body, headerParams);
    }

    toastr.success('Sucesso!', 'Dados salvos com sucesso!')
    yield put(ParteActions.saveSuccess());
    const params = yield select(getParamsPartes);
    yield put(PartesActions.listRequest(params));
  } catch (error) {
    yield put(ParteActions.saveError());
    toastr.error('Ops', 'Não foi possível salvar a parte do processo.');
  }
}

export default all([
  takeLatest(Types.SAVE_REQUEST, saveParte),
]);