import styled, { keyframes, css } from "styled-components/macro";
import { default as MaterialTabs } from "@material-ui/core/Tabs";
import { default as MaterialTab } from "@material-ui/core/Tab";
import { default as MaterialButton } from "@material-ui/core/Button";
import { Form } from "@unform/web";

const inputExpand = keyframes`
    from {
        height: 0;
        opacity: 0;
    }

    to {
        height: 35px;
        opacity: 1;
    }
`;

export const Container = styled.div`
  margin-top: 16px;
  background-color: #bdbdbd;
  border-radius: 4px;
`;

export const Tabs = styled(MaterialTabs)`
  background-color: #fff;
  padding-top: 16px;

  [class^="PrivateTabIndicator-root"] {
    background-color: transparent;
    border: 2px solid #444444;
    border-radius: 4px 4px 0 0;
    box-shadow: 2px 2px 4px 4px #00000011;
    height: 100%;
  }

  .MuiTab-textColorPrimary {
    background-color: #fff;
    transition: background 1.3s ease;
    border-radius: 4px 4px 0 0;
    box-shadow: 2px 2px 4px 4px #00000011, 1px 1px 2px 2px #00000011 inset;
    border-radius: 4px 4px 0 0;
  }

  .MuiTab-textColorPrimary.Mui-selected {
    background-color: #444444;
    color: #fff;
  }
`;

export const Tab = styled(MaterialTab)``;

export const Description = styled.div`
  position: relative;
  margin-top: 1px;
  box-shadow: 1px 1px 2px 2px #00000011;
  position: relative;
  width: 100%;
  height: 300px;
  background-color: #fff;
  border-radius: 4px;
  border: 2px solid #bdbdbd;
  padding: 16px;
  overflow-y: auto;
  border-radius: 0 0 4px 4px;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    box-shadow: 1px 1px 2px 2px #00000011 inset;
    width: 100%;
    height: 100%;
  }
`;

export const DescriptionText = styled.p`
  color: #777;
`;

export const NoDescription = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  text-align: center;
  color: #a9a9a9;

  img {
    margin: 16px 0 4px;
    height: 36px;
    width: auto;
    opacity: 0.7;
  }
`;

export const Remark = styled.div`
  display: flex;
  align-items: center;
  color: #555;

  ${({ ownmessage }) =>
    !ownmessage &&
    css`
      flex-direction: row-reverse;
    `};

  &:not(:last-of-type) {
    margin-bottom: 16px;
  }
`;

export const MiniUserAvatar = styled.div`
  position: relative;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  cursor: default;

  border-radius: 100px;
  background-color: #cccc;
`;

export const RemarkData = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 4px 16px;
  background-color: #86ccff44;
  border-radius: 6px;
  width: 100%;
  font-size: 14px;
  text-align: justify;
  margin-left: 16px;

  ${({ ownmessage }) =>
    !ownmessage &&
    css`
      flex-direction: row-reverse;
      margin-left: 0;
      margin-right: 16px;
    `};

  span {
    display: inline-block;
    font-size: 10px;
    font-weight: bold;
    min-width: 100px;
    margin-left: 16px;
  }
`;

export const InputContainer = styled(Form)`
  display: flex;
  align-items: center;
  padding: 4px 8px;
  box-shadow: 1px 1px 2px 2px #00000011;
  background-color: #e4e4e4;
  overflow: hidden;
  animation: ${inputExpand} 0.3s ease;

  .MuiFormControl-root {
    background-color: #fff;
    border-radius: 6px;
    border: none;
    border-radius: 4px 0 0 4px;
    margin: 0;
    height: 35px;

    input {
      border-radius: 4px 0 0 4px;
      outline: none;
    }
  }

  .MuiOutlinedInput-root {
    border-radius: 4px 0 0 4px;
    height: 35px;
  }
`;

export const LoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #bdbdbd44;
  overflow: hidden;
`;

export const ButtonSubmit = styled(MaterialButton)`
  background-color: #444444;
  height: 35px;
  border-radius: 0 4px 4px 0;

  &:hover {
    background-color: #083f6f;
  }
`;
