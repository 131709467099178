export const Types = {
  LIST_REQUEST: "grupoemail/LIST_REQUEST",
  LIST_SUCCESS: "grupoemail/LIST_SUCCESS",
  NEW_REQUEST: "grupoemail/NEW_REQUEST",
  NEW_SUCCESS: "grupoemail/NEW_SUCCESS",
  GET_REQUEST: "grupoemail/GET_REQUEST",
  GET_SUCCESS: "grupoemail/GET_SUCCESS",
  EDIT_REQUEST: "grupoemail/EDIT_REQUEST",
  EDIT_SUCCESS: "grupoemail/EDIT_SUCCESS",
  DELETE_REQUEST: "grupoemail/DELETE_REQUEST",
  DELETE_SUCCESS: "grupoemail/DELETE_SUCCESS",
  DELETE_FAILURE: "grupoemail/DELETE_FAILURE",
  OPEN_DELETE: "grupoemail/OPEN_DELETE",
  CLOSE_DELETE: "grupoemail/CLOSE_DELETE",
  DELETE_MAIL_REQUEST: "grupoemail/DELETE_MAIL_REQUEST",
  DELETE_MAIL_SUCCESS: "grupoemail/DELETE_MAIL_SUCCESS",
  ADD_MAIL_REQUEST: "grupoemail/ADD_MAIL_REQUEST",
  ADD_MAIL_SUCCESS: "grupoemail/ADD_MAIL_SUCCESS",
};

/* Reducer */

const INITIAL_STATE = {
  loading: false,
  openDelete: false,
  openDeleteMail: false,
  mailsToRemove: [],
  grupoToDelete: {},
  grupoemail: [],
  sort: "nome",
  page: 1,
  limit: 10,
  total: 0,
};

export default function grupoemail(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.LIST_REQUEST:
      return { ...state, loading: true };

    case Types.LIST_SUCCESS:
      return { ...state, ...action.payload, loading: false };

    case Types.NEW_REQUEST:
      return { ...state, loading: true };

    case Types.NEW_SUCCESS:
      return { ...state, loading: false };

    case Types.GET_REQUEST:
      return { ...state, loading: true };

    case Types.GET_SUCCESS:
      return { ...state, loading: false };

    case Types.EDIT_REQUEST:
      return { ...state, loading: true };

    case Types.EDIT_SUCCESS:
      return { ...state, loading: false };

    case Types.DELETE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case Types.DELETE_FAILURE:
      return {
        ...state,
        loading: false,
        openDelete: false,
        grupoToDelete: null,
      };

    case Types.DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        openDelete: false,
        grupoToDelete: null,
      };

    case Types.OPEN_DELETE:
      return {
        ...state,
        openDelete: true,
        grupoToDelete: action.payload,
      };

    case Types.CLOSE_DELETE:
      return {
        ...state,
        openDelete: false,
        grupoToDelete: null,
      };

    case Types.DELETE_MAIL_REQUEST:
      return {
        ...state,
        openDeleteMail: true,
        mailsToRemove: action.payload,
      };

    case Types.DELETE_MAIL_SUCCESS:
      return {
        ...state,
        openDeleteMail: false,
        mailsToRemove: [],
      };

    case Types.ADD_MAIL_REQUEST:
      return {
        ...state,
        openAddMail: true,
        mailsToAdd: action.payload,
      };

    case Types.ADD_MAIL_SUCCESS:
      return {
        ...state,
        openAddMail: false,
        mailsToAdd: [],
      };

    default:
      return state;
  }
}

export const Creators = {
  deleteRequest: (data) => ({
    type: Types.DELETE_REQUEST,
    payload: data,
  }),

  deleteSuccess: () => ({
    type: Types.DELETE_SUCCESS,
    payload: {},
  }),

  deleteFailure: () => ({
    type: Types.DELETE_FAILURE,
    payload: {},
  }),

  listRequest: (data) => ({
    type: Types.LIST_REQUEST,
    payload: data,
  }),

  listSuccess: (data) => ({
    type: Types.LIST_SUCCESS,
    payload: data,
  }),

  getRequest: (id) => ({
    type: Types.GET_REQUEST,
    payload: id,
  }),

  getSuccess: (grupoemail) => ({
    type: Types.GET_SUCCESS,
    payload: grupoemail,
  }),

  editRequest: (grupoemail) => ({
    type: Types.EDIT_REQUEST,
    payload: grupoemail,
  }),

  editSuccess: () => ({
    type: Types.EDIT_SUCCESS,
    payload: {},
  }),

  createRequest: (grupoemail) => ({
    type: Types.NEW_REQUEST,
    payload: grupoemail,
  }),

  createSuccess: () => ({
    type: Types.NEW_SUCCESS,
    payload: {},
  }),

  openDelete: (grupoemail) => ({
    type: Types.OPEN_DELETE,

    payload: { grupoemail },
  }),

  closeDelete: () => ({
    type: Types.CLOSE_DELETE,
    payload: {},
  }),

  deleteMailRequest: (emails) => ({
    type: Types.DELETE_MAIL_REQUEST,
    payload: emails,
  }),

  deleteMailSucess: () => ({
    type: Types.DELETE_MAIL_SUCCESS,
    payload: [],
  }),

  addMailRequest: (emails) => ({
    type: Types.ADD_MAIL_REQUEST,
    payload: emails,
  }),

  addMailSucess: () => ({
    type: Types.ADD_MAIL_SUCCESS,
    payload: [],
  }),
};
