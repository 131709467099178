import React, { useState, useRef } from "react";
import { Col, Row } from "react-bootstrap";

import FormIndexacao from "./FormIndexacao";
import ClassificacaoImagens from "./ClassificacaoImagens";

import "./styles.css";
import Content from "components/Content";

export default function Documento() {
  const [tipoDocumento, setTipoDocumento] = useState(null);
  const [imagens, setImagens] = useState([]);
  const refClassificacaoImagens = useRef({});

  function hanfleChangeClassificacao(imagens) {
    setImagens(imagens);
  }

  function onSave() {
    refClassificacaoImagens.current.clearOnSave();
  }

  return (
    <Content>
      <div className="panels">
        <Row>
          <Col md="6" className="painel">
            <div className="painel-content">
              <div className="painel-title text-left">
                Adicionar {tipoDocumento ? tipoDocumento.nome : "Dossiê"}
              </div>
              <div className="painel-body">
                <FormIndexacao
                  onChangeTipoDocumento={setTipoDocumento}
                  imagens={imagens}
                  onSave={onSave}
                  checkReadOnly={false}
                />
              </div>
            </div>
          </Col>
          <Col md="6" className="painel">
            <div className="painel-content">
              <div className="painel-title text-left">
                Documentos Selecionados
              </div>
              <div className="painel-body">
                <ClassificacaoImagens
                  tipoDocumento={tipoDocumento}
                  onChange={hanfleChangeClassificacao}
                  ref={refClassificacaoImagens}
                />
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </Content>
  );
}
