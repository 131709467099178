import api from "../../services/api";
import { getToken } from "../../services/auth";
import { put, call, all, takeLatest } from "redux-saga/effects";
import { toastr } from "react-redux-toastr";
import { select } from "redux-saga/effects";

import { Creators as TempStorageActions, Types } from "../ducks/tempstorage";

import { Creators as DocumentoActions } from "../ducks/documentos";

export const getOrganization = (state) => state.usuarios.auth.organization;

export function* createPartTempStorage({ payload }) {
  try {
    const organization = yield select(getOrganization);

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const { data } = yield call(api.post, "temp/storage/upload", payload, headerParams);
    const { storageId } = data;

    yield put(TempStorageActions.newPartSuccess({ storageId }));

    return storageId;
  } catch (error) {
    toastr.error("Ops", "Não foi criar nova parte de upload");
  }
}

export function* callStorage({ payload }) {
  try {
    const { storageId, idDoc, tipoImagem, contentType, type, itemchecklist } = payload;
    const organization = yield select(getOrganization);

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const body = {
      type,
      idDoc,
      tipoImagem,
      contentType,
      itemchecklist
    };

    const data = yield call(api.post, `temp/storage/tempToS3/${storageId}`, body, headerParams);

    yield put(TempStorageActions.newStorageSuccess());

    if (idDoc)
      yield put(DocumentoActions.getDocumentoRequest(idDoc));
  } catch (error) {
    toastr.error("Ops", "Não foi possível o redirecionamento de upload");
  }
}

export default all([
  takeLatest(Types.NEW_PART_REQUEST, createPartTempStorage),
  takeLatest(Types.NEW_STORAGE_REQUEST, callStorage),
]);
