import styled from 'styled-components/macro';

import { TASK_APROVATION_STATUS_COLORS } from 'constants/task-colors';

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 16px 32px 32px;
`;

export const Header = styled.div`
    width: 100%;
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const BasicInfo = styled.div`

`;

export const Title = styled.h1`
    font-size: 24px;
    color: #114ccc;
    font-weight: 600;
`;

export const TaskType = styled.h2`
    font-size: 20px;
    color: #c9c9c9;
`;

export const TaskDoc = styled.div`
    font-size: 16px;
    color: #114ccc;
    cursor: pointer;
    padding: 8px 16px;
    margin-left: -16px;
    border-radius: 50px;
    transition: background-color .3s ease;

    span {
        font-size: 14px;
        color: #c9c9c9;
    }

    &:hover {
        background-color: #f1f1f1;
    }
`;

export const StatusInfo = styled.div`
    display: flex;
    flex-direction: column;
`;

export const DateStatus = styled.div`
    width: 450px;
    display: flex;
    flex-direction: column;
`;

export const DateStart = styled.div`
    font-size: 14px;
    color: #888;
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
        display: inline-block;
    }

    span:first-of-type {
        font-size: 10px;
        color: #a9a9a9;
    }
`;

export const DateEnd = styled(DateStart)`

`;

export const InfoBar = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;
`;

export const CompletionStatus = styled.div`
    display: flex;
    align-items: center;
    color: #888;

    svg {
        margin-right: 4px;
    }
`;

export const PriorityStatus = styled(CompletionStatus)`
    margin-top: 8px;

    img {
        height: 24px;
        width: 24px;
        margin-right: 2px;
    }
`;

export const UsersList = styled.ul`
    position: relative;
    list-style: none;
    display: flex;
    flex-direction: row;
    margin: 8px 0;
`;

export const AprovacaoStatus = styled.span`
    background-color: #FFF;
    text-align: center;
    padding: 4px 10px;
    border: 2px solid ${({ status }) => TASK_APROVATION_STATUS_COLORS[status]};
    color: ${({ status }) => TASK_APROVATION_STATUS_COLORS[status]};
    text-shadow: 1px 1px 2px 2px #000;
    border-radius: 50px;
    font-weight: bold;
    letter-spacing: 1.5px;
    height: 28px;
    width: 290px;

    svg {
        margin-right: 4px;
        width: 16px;
    }
`;
