import React from 'react';

import { CPF, CNPJ } from "components/Masks";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import OptionAutocomplete from "./OptionAutocomplete";
import { SearchIcon } from 'components/Icons';
import { WrapSensibleFlag } from 'components/SensibleFlag';

const filter = createFilterOptions();

function FieldIdentificadorUnico({
    indexador,
    metadados,
    currentMetadata,
    loadingMetadados,
    open,
    setOpen,
    hasIndexadorError,
    hasIndexadorTextError,
    handleChangeAutocomplete,
    handleChangeIndexadorWithLodash
}) {

    function handleFilterOptions(options, params) {
        const filtered = filter(options, params);

        if (params.inputValue !== "") {
            filtered.push({ [indexador.nome]: params.inputValue });
        }

        return filtered;
    }

    function showLoadingOrSearchIcon(loadingMetadados, params) {
        return (
            <>
                {!!loadingMetadados
                    ? <CircularProgress color="primary" size={20} />
                    : <SearchIcon />}
                {params.InputProps.endAdornment}
            </>
        );
    }

    function showInputComponent(indexador, params) {
        return indexador.tipo === "cpf"
            ? CPF
            : indexador.tipo === "cnpj"
                ? CNPJ
                : params.InputProps.inputComponent;
    }

    return <Autocomplete
        id={`combo-box-${indexador.nome}`}
        open={open[indexador.nome] || false}
        onOpen={() => setOpen({ ...open, [indexador.nome]: true })}
        onClose={() => setOpen({ ...open, [indexador.nome]: false })}
        clearOnBlur={false}
        getOptionLabel={option => option[indexador.nome]}
        getOptionSelected={(option, value) => option === value}
        renderOption={option => <OptionAutocomplete option={option} />}
        onChange={handleChangeAutocomplete}
        options={metadados}
        loading={loadingMetadados}
        renderInput={params =>
            <WrapSensibleFlag indexador={indexador}>
                <TextField
                    {...params}
                    label={indexador.nome}
                    onChange={handleChangeIndexadorWithLodash}
                    name={indexador.nome}
                    value={indexador.valor || ""}
                    variant="outlined"
                    InputProps={{
                        ...params.InputProps,
                        inputComponent: showInputComponent(indexador, params),
                        endAdornment: showLoadingOrSearchIcon(loadingMetadados, params)
                    }}
                    error={hasIndexadorError(indexador)}
                    helperText={hasIndexadorTextError(indexador)} />
            </WrapSensibleFlag>
        }
        filterOptions={handleFilterOptions}
        value={currentMetadata}
        size="small"
    />;
}

export default FieldIdentificadorUnico;