export const Types = {
  LIST_REQUEST: "auditoria/LIST_REQUEST",
  LIST_SUCCESS: "auditoria/LIST_SUCCESS",
  REPORT_REQUEST: "auditoria/REPORT_REQUEST",
  REPORT_SUCCESS: "auditoria/REPORT_SUCCESS",
  REPORT_CLEANER: "auditoria/REPORT_CLEANER",
  NEW_REQUEST: "auditoria/NEW_REQUEST",
  NEW_SUCCESS: "auditoria/NEW_SUCCESS",

  LIST_DOCUMENTO_REQUEST: "auditoria/LIST_DOCUMENTO_REQUEST",
  LIST_DOCUMENTO_SUCCESS: "auditoria/LIST_DOCUMENTO_SUCCESS",

  LIST_DOCUMENTO_WF_REQUEST: "auditoria/LIST_DOCUMENTO_WF_REQUEST",
  LIST_DOCUMENTO_WF_SUCCESS: "auditoria/LIST_DOCUMENTO_WF_SUCCESS",

  LIST_IDENTIFICADOR_REQUEST: "auditoria/LIST_IDENTIFICADOR_REQUEST",
  LIST_IDENTIFICADOR_SUCCESS: "auditoria/LIST_IDENTIFICADOR_SUCCESS",
};

const INITIAL_STATE = {
  list: [],
  loading: false,
  loadingReport: false,
  loadingCreate: false,
  loadingHistoricoWF: false,
  report: [],
  historicoWF: [],
  documentData: [],
  identifierData: [],
  page: 1,
  limit: 10,
  total: 0,
};

export default function auditoria(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.LIST_REQUEST:
      return {
        ...state,
        loading: true,
        list: [],
        page: action.payload.page,
        limit: action.payload.limit,
      };
    case Types.LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload.list,
        total: action.payload.total,
      };
    case Types.REPORT_REQUEST:
      return { ...state, loadingReport: true };
    case Types.REPORT_SUCCESS:
      return {
        ...state,
        loadingReport: false,
        report: action.payload,
      };
    case Types.REPORT_CLEANER:
      return {
        ...state,
        report: [],
      };
    case Types.NEW_REQUEST:
      return { ...state, loadingCreate: true };
    case Types.NEW_SUCCESS:
      return { ...state, loadingCreate: false };

    case Types.LIST_DOCUMENTO_REQUEST:
      return { ...state, loading: true, report: [] };
    case Types.LIST_DOCUMENTO_SUCCESS:
      return {
        ...state,
        loading: false,
        documentData: action.payload,
      };

    case Types.LIST_IDENTIFICADOR_REQUEST:
      return { ...state, loading: true, report: [] };

    case Types.LIST_IDENTIFICADOR_SUCCESS:
      return {
        ...state,
        loading: false,
        identifierData: action.payload,
      };

    case Types.LIST_DOCUMENTO_WF_REQUEST:
      return { ...state, loadingHistoricoWF: true, historicoWF: [] };
    case Types.LIST_DOCUMENTO_WF_SUCCESS:
      return {
        ...state,
        loadingHistoricoWF: false,
        historicoWF: action.payload,
      };

    default:
      return state;
  }
}

export const Creators = {
  listRequest: (params) => ({
    type: Types.LIST_REQUEST,
    payload: params,
  }),

  listSuccess: (list, total) => ({
    type: Types.LIST_SUCCESS,
    payload: { list, total },
  }),

  reportRequest: (params) => ({
    type: Types.REPORT_REQUEST,
    payload: params,
  }),

  reportSuccess: (auditoria) => ({
    type: Types.REPORT_SUCCESS,
    payload: auditoria,
  }),

  reportCleaner: () => ({
    type: Types.REPORT_CLEANER,
    payload: {},
  }),

  createRequest: (auditoria) => ({
    type: Types.NEW_REQUEST,
    payload: auditoria,
  }),

  createSuccess: () => ({
    type: Types.NEW_SUCCESS,
    payload: {},
  }),

  listDocumentoRequest: (data) => ({
    type: Types.LIST_DOCUMENTO_REQUEST,
    payload: data,
  }),

  listDocumentoSuccess: (auditoria) => ({
    type: Types.LIST_DOCUMENTO_SUCCESS,
    payload: auditoria,
  }),

  listIdentificadorRequest: (data) => ({
    type: Types.LIST_IDENTIFICADOR_REQUEST,
    payload: data,
  }),

  listIdentificadorSuccess: (auditoria) => ({
    type: Types.LIST_IDENTIFICADOR_SUCCESS,
    payload: auditoria,
  }),

  listDocumentoWFRequest: (data) => ({
    type: Types.LIST_DOCUMENTO_WF_REQUEST,
    payload: data,
  }),

  listDocumentoWFSuccess: (auditoria) => ({
    type: Types.LIST_DOCUMENTO_WF_SUCCESS,
    payload: auditoria,
  }),
};
