import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CircularProgress from '@material-ui/core/CircularProgress';

import {
    Container,
    MenuItem,
    ListItemIcon,
    ListItemText,
} from './styles/TasksActionMenu';

import ModalTaskImages from "components/ModalTaskImages";
import ModalUploadFiles from 'components/SelectFilesToUpload';
import ModalTaskDetails from 'components/ModalTaskDetails';

import { Creators as ProvidenciasActions } from 'store/ducks/providencias';
import { Creators as ProvidenciaActions } from 'store/ducks/providencia';
import { BackupIcon, CheckIcon, CloseIcon, ImageIcon, PreviewIcon } from 'components/Icons';

function TasksActionsMenu({
    anchor,
    handleClose,
}) {
    const dispatch = useDispatch();

    const { id: loggedUserId } = useSelector(state => state.usuarios.auth);
    const {
        loadingTaskApproval,
        openUpload: openModalUpload,
        loading: loadingFromProvidencia,
    } = useSelector(state => state.providencia);
    const {
        selectedTask,
        openModalTaskDetails,
        openModalImages,
    } = useSelector(state => state.providencias);

    const [confirmDelete, setConfirmDelete] = useState(false);

    // On Unmount
    useEffect(() => {
        return () => dispatch(ProvidenciasActions.selectTask(null));
    }, []);

    function handleConfirmDelete() {
        setConfirmDelete(true);
        setTimeout(() => {
            setConfirmDelete(false);
        }, 2000);
    }

    function handleApproveTask() {
        dispatch(ProvidenciaActions.taskApprovalRequest(selectedTask));
    }

    function handleEditTask() {
        dispatch(ProvidenciaActions.edit(selectedTask));
    }

    function handleOpenTaskDetails() {
        dispatch(ProvidenciasActions.openModalTaskDetails(selectedTask));
    }

    function handleOpenUploadDocuments() {
        dispatch(ProvidenciaActions.openUpload(selectedTask));
    }

    function handleOpenModalImages() {
        dispatch(ProvidenciasActions.handleModalImages(true));
    }

    function handleCloseModalImages() {
        dispatch(ProvidenciasActions.handleModalImages(false));
    }

    function handleDeleteTask() {
        dispatch(ProvidenciaActions.deleteRequest(selectedTask._id));
        handleClose();
    }

    // turned this into a function because this code will get complex later
    function userIsResponsibleForTaskApproval(participantes, loggedUserId) {
        if (participantes && participantes.length > 0) {
            const isUserAuthorized = participantes.some(participante => participante._id === loggedUserId);
            return isUserAuthorized;
        }
        return false;
    }

    function handleCloseModalUpload() {
        dispatch(ProvidenciaActions.closeUpload());
    }

    function handleUpload(filesToUpload) {
        const formData = new FormData();

        filesToUpload.forEach((fileToUpload) => {
            formData.append("imagem", fileToUpload.file);
            formData.append("nome", fileToUpload.nome);
        });

        dispatch(ProvidenciaActions.uploadRequest(formData));
    }

    function handleCloseModalTaskDetails() {
        dispatch(ProvidenciasActions.closeModalTaskDetails());
    }

    return (
        <>
            <Container
                onClose={handleClose}
                open={anchor}
                elevation={10}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
                anchorEl={anchor}>

                {!selectedTask?.aprovado && (
                    <MenuItem
                        onClick={handleApproveTask}
                        loading={loadingTaskApproval}
                        disabled={!userIsResponsibleForTaskApproval(selectedTask.participantes, loggedUserId)}>
                        <ListItemIcon>
                            {loadingTaskApproval ? (
                                <CircularProgress size={16} color="white" />
                            ) : (
                                selectedTask.aprovado ? <CloseIcon /> : <CheckIcon />
                            )}
                        </ListItemIcon>

                        <ListItemText loading={loadingTaskApproval}>
                            {loadingTaskApproval ? (
                                'Atualizando...'
                            ) : (
                                'Encerrar tarefa'
                            )}
                        </ListItemText>


                    </MenuItem>
                )}

                <MenuItem onClick={handleOpenTaskDetails}>
                    <ListItemIcon>
                        <PreviewIcon />
                    </ListItemIcon>
                    <ListItemText>
                        Detalhes da tarefa
                    </ListItemText>
                </MenuItem>

                <MenuItem onClick={handleOpenUploadDocuments}>
                    <ListItemIcon>
                        <BackupIcon />
                    </ListItemIcon>
                    <ListItemText>
                        Upload de documentos
                    </ListItemText>
                </MenuItem>

                <MenuItem
                    onClick={handleOpenModalImages}
                    disabled={!selectedTask.haveImages}>
                    <ListItemIcon>
                        <ImageIcon />
                    </ListItemIcon>
                    <ListItemText>
                        Visualizar documentos
                    </ListItemText>
                </MenuItem>
                {/* TO-DO - QUANDO TIVER AUDITORIA NA REMOÇÃO DA TASK, HABILITAR ESSE BOTÃO NOVAMENTE */}
                {/* {!confirmDelete ? (
                    <MenuItem onClick={handleConfirmDelete}>
                        <ListItemIcon>
                            <Delete />
                        </ListItemIcon>
                        <ListItemText>
                            Excluir tarefa
                        </ListItemText>
                    </MenuItem>
                ) : (
                    <MenuItem
                        confirmation={true}
                        onClick={handleDeleteTask} >
                        <ListItemIcon confirmation={true}>
                            <DeleteForeverIcon />
                        </ListItemIcon>
                        <ListItemText confirmation={true}>
                            Confirma exclusão?
                        </ListItemText>
                    </MenuItem>
                )} */}

            </Container>
            {openModalImages &&
                <ModalTaskImages
                    open={openModalImages}
                    handleClose={handleCloseModalImages} />}
            {openModalUpload &&
                <ModalUploadFiles
                    open={openModalUpload}
                    onClose={handleCloseModalUpload}
                    onUpload={handleUpload}
                    loading={loadingFromProvidencia}
                    defaultName="Documento"
                    names={["Documento"]} />}
            {openModalTaskDetails &&
                <ModalTaskDetails
                    task={selectedTask}
                    open={openModalTaskDetails}
                    handleClose={handleCloseModalTaskDetails} />}
        </>
    );
}

export default TasksActionsMenu;

