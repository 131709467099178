import React, { useRef, useEffect } from 'react';
import { useField } from '@unform/core';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import FormHelperText from '@material-ui/core/FormHelperText';

function SwitchForm({ name, label, ...rest }) {
  const inputRef = useRef(null);

  const { fieldName, defaultValue, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'checked'
    });
  }, [fieldName, registerField])

  return (
    <FormControl component="fieldset">
      <FormControlLabel 
        label={label}
        control={
          <Switch
            inputRef={inputRef}
            name={name}
            defaultChecked={defaultValue}
            {...rest}
          />
        }
      />
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
}

export default SwitchForm;