import React, { useState } from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { SensibleFlag } from 'components/SensibleFlag';

function FieldCombo({
  indexador,
  handleChangeIndexador,
  checkReadOnly,
  hasIndexadorError,
  hasIndexadorTextError,
  fieldToAdd = null,
}) {
  const [value, setValue] = useState(indexador.valor || '');

  return (
    <FormControl variant="outlined" size="small" fullWidth>
      <SensibleFlag indexador={indexador}>
        <InputLabel id={`select-label-${indexador.nome}`}>{indexador.nome}</InputLabel>
      </SensibleFlag>
      <Select
        disabled={checkReadOnly ? indexador.somenteLeitura : false}
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        value={value}
        label={indexador.nome}
        name={indexador.nome}
        error={hasIndexadorError(indexador)}
        helperText={hasIndexadorTextError(indexador)}
        onChange={value => {
          setValue(value.target.value);
          handleChangeIndexador(value);
        }}
      >
        <MenuItem value="">Limpar seleção</MenuItem>
        {fieldToAdd && <MenuItem value={fieldToAdd.valor}>{fieldToAdd.texto}</MenuItem>}
        {indexador.opcoes.map(opcao => (
          <MenuItem key={opcao} value={opcao}>
            {opcao}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default FieldCombo;
