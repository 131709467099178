import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';

import ReactHtmlParser from 'react-html-parser';

import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import Debounce from '../../../components/Debounce';

import { Creators as DocumentosActions } from '../../../store/ducks/documentos';
import { SearchIcon } from 'components/Icons';

export default function SimplesSearch({ searchText, setSearchText }) {
  const dispatch = useDispatch();

  const [returnedIds, setReturnedIds] = useState([]);

  const debouncedSearchText = Debounce(searchText, 500);

  let { resultSearch, loading: searchLoading } = useSelector(
    (state) => state.documentos.autocomplete
  );

  let isDocIdSelected = useSelector((state) => state.documentos.docid);

  useEffect(() => {
    dispatch(DocumentosActions.autoCompleteRequest(debouncedSearchText, null));
  }, [dispatch, debouncedSearchText]);

  const closeAutoCompleteResult = () => {
    setTimeout(() => {
      setSearchText('');
    }, 300);
  };

  const sendOneToSearchPage = (id) => {
    dispatch(
      DocumentosActions.sendToSearchPageRequest(
        [id],
        {
          limit: 10,
          page: 1,
          sort: '-createdAt',
          searchText,
        },
        true
      )
    );

    closeAutoCompleteResult();
  };

  const sendAllToSearchPage = (e) => {
    e.preventDefault();
    dispatch(
      DocumentosActions.sendToSearchPageRequest(
        returnedIds,
        {
          limit: 10,
          page: 1,
          sort: '-_id',
          searchText,
        },
        false
      )
    );

    closeAutoCompleteResult();
  };

  useEffect(() => {
    if (resultSearch) {
      setReturnedIds(resultSearch.map((item) => item._id));
    }
  }, [resultSearch]);

  const makeWordBold = (string, target) => {
    var regex = new RegExp(target, 'g'),
      targetBold = "<b class='blueWord'>" + target + '</b>';

    return string.replace(regex, targetBold);
  };

  const searchInWord = (source, value) => {
    for (var key in source) {
      if (source.hasOwnProperty(key)) {
        if (
          typeof source[key] === 'string' &&
          source[key].toUpperCase().indexOf(value.toUpperCase()) > -1
        ) {
          return (
            `<strong>${
              key === 'numeroCNJ' ? 'Número do cliente' : key
            }: </strong>` +
            makeWordBold(source[key].toUpperCase(), value.toUpperCase())
          );
        } else if (typeof source[key] === 'object') {
          if (source[key]) {
            const findText = source[key].find((text) => {
              return text
                ? text.toUpperCase().includes(value.toUpperCase())
                : {};
            });

            const normalizedText = findText
              ? findText.toUpperCase().replace(/\s+/g, ' ').trim()
              : '';

            if (normalizedText.length > 0) {
              const foundedTextPosition = normalizedText.indexOf(
                value.toUpperCase()
              );

              return (
                `<strong>Texto do documento: </strong>` +
                makeWordBold(
                  normalizedText.substring(
                    foundedTextPosition - 35,
                    foundedTextPosition + 35
                  ),
                  value.toUpperCase()
                )
              );
            }
          }
        }
      }
    }
  };

  return (
    <>
      <div className="search-bar">
        <form onSubmit={sendAllToSearchPage}>
          <input
            type="text"
            onChange={(e) => setSearchText(e.target.value)}
            onBlur={(e) => closeAutoCompleteResult()}
            value={searchText}
            placeholder="Digite para pesquisar"
            className={searchLoading ? 'searchLoad' : ''}
          />
        </form>
        <OverlayTrigger
          placement="right"
          overlay={<Tooltip>Ir para pesquisa avançada</Tooltip>}
        >
          <Link
            to={{
              pathname: '/documentos/pesquisa/avancada',
            }}
            className="advancedSearchIcon"
          >
            {searchLoading ? (
              <CircularProgress size={20} color="inherit" />
            ) : (
              <SearchIcon size={13} color="#fff" />
            )}
          </Link>
        </OverlayTrigger>
      </div>
      {resultSearch &&
        !isDocIdSelected &&
        resultSearch.length > 0 &&
        searchText !== '' && (
          <div className="topSearchResult">
            <ul>
              {resultSearch.map((doc) => (
                <li key={doc._id}>
                  <div
                    className="linkicon"
                    onClick={(e) => sendOneToSearchPage(doc._id)}
                  >
                    <span>
                      <strong>Empresa: </strong>
                      {doc._source.empresa}
                    </span>
                    {/* <span>
                      <strong>Relacionamento: </strong>
                      {doc._source.tipodocumento === 'Contratos'
                        ? 'Colaborador'
                        : 'Cliente'}
                    </span> */}
                    <span>
                      <strong>Tipo de documento: </strong>
                      {doc._source.tipodocumento}
                    </span>
                    {/* <span>
                      <strong>Modalidade: </strong>
                      {doc._source.tipodocumento === 'Contratos'
                        ? 'Trabalhista'
                        : 'Cível'}
                    </span> */}
                    <span>
                      {ReactHtmlParser(searchInWord(doc._source, searchText))}
                    </span>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )}
    </>
  );
}
