import React, { useEffect, useRef } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Form } from '@unform/web';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import MenuItem from '@material-ui/core/MenuItem';
import { useSelector, useDispatch } from 'react-redux';
import { ValidationError } from 'yup';

import InputForm from 'components/InputForm';
import SwitchForm from 'components/SwitchForm';
import AutocompleteForm from 'components/AutocompleteForm';
import RadioGroupFrom from 'components/RadioGroupForm';
import CheckboxForm from 'components/CheckboxForm';
import SelectForm from 'components/SelectForm';
import CPFMask from './CPFMask';

import UnformSchema from 'validators/UnformSchema';

import { Creators as EmpresasActions } from 'store/ducks/empresas';
import Content from 'components/Content';

const OPTIONS = [
  'Opção 1',
  'Opção 2'
]

function UnFormExample() {

  const { list: empresasList } = useSelector((state) => state.empresas);

  const dispatch = useDispatch();

  const formRef = useRef(null);

  useEffect(() => {
    dispatch(EmpresasActions.listRequest());
  }, [dispatch]);

  async function handleSubmit(data) {
    try {
      await UnformSchema.validate(data, {
        abortEarly: false
      })
    } catch (err) {
      const validationErrors = {};
      if (err instanceof ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        })

        formRef.current.setErrors(validationErrors);
      }
    }
  }

  return (
    <Content>
      <Row>
        <Col md="12" className="painel">
          <div className="painel-content">
            <div className="painel-body">
              <Form ref={formRef} initialData={{ switch: true, inpuDate: '', autocomplete2: 'Opção 3' }} onSubmit={handleSubmit}>
                <AutocompleteForm
                  options={empresasList}
                  name="autocomplete"
                  label="Autocomplete Empresas"
                  getOptionLabel={(option) => option.fantasia}
                  transformOnSelect={option => option._id}
                />
                <AutocompleteForm
                  options={OPTIONS}
                  name="autocomplete2"
                  label="Autocomplete Options"
                  getOptionLabel={(option) => option}
                />
                <InputForm name="inpuText" label="Input Text" />
                <SwitchForm color="primary" name="switch" label="Switch Form" />
                <RadioGroupFrom
                  label="Radio Group"
                  name="radio"
                >
                  <FormControlLabel value="male" control={<Radio />} label="Male" />
                  <FormControlLabel value="female" control={<Radio />} label="Female" />
                </RadioGroupFrom>
                <CheckboxForm
                  name="checkbox"
                  label="Checkbox Maroto"
                  color="primary"
                />
                <SelectForm
                  name="select"
                  label="Select Maroto"
                >
                  <MenuItem value="maroto1">Maroto 1</MenuItem>
                  <MenuItem value="maroto2">Maroto 2</MenuItem>
                </SelectForm>
                <InputForm
                  name="inpuDate"
                  label="Input Date"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <InputForm
                  name="cpf"
                  label="CPF"
                  InputProps={{
                    inputComponent: CPFMask
                  }}
                />
                <Button variant="contained" color="primary" type="submit">Enviar</Button>
              </Form>
            </div>
          </div>
        </Col>
      </Row>
    </Content>
  );
}

export default UnFormExample;