import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Container, LoadingContainer } from "./styles/MessageChat";

import CircularProgress from "@material-ui/core/CircularProgress";

import SelectFilesToUpload from "components/SelectFilesToUpload";
import DialogPreviewImages from "components/DialogPreviewImages";
import SingleMessage from "../SingleMessage";

import { Creators as MensagemActions } from "store/ducks/mensagem";
import { Creators as MensagensActions } from "store/ducks/mensagens";

import { MESSAGE_FILTER_OPTIONS } from "../../../constants/message-filter-options";

// Component to replace TableMensagens Component
function MessageChat({ documento, filter, selectedAuthorFilter }) {
    const dispatch = useDispatch();
    const loaderRef = useRef(null);
    const userId = useSelector(state => state.usuarios.auth.id);

    const {
        loading,
        list: mensagens,
        total,
        limit,
        page,
        sort,
        search,
        endpoint,
    } = useSelector((state) => state.mensagens);
    const { openUpload, loading: loadingUpload } = useSelector(
        (state) => state.mensagem
    );

    const [imagens, setImagens] = useState([]);
    const [open, setOpen] = useState(false);
    const [scrollRatio, setScrollRatio] = useState(null);

    const intersectionObserver = new IntersectionObserver((entries) => {
        setScrollRatio(entries[0].intersectionRatio);
    });

    const listRequestPayload = {
        documento: documento._id,
        page: 1,
        limit: 10,
        sort: '-createdAt',
        search: '',
        endpoint: 0,
    }

    function refreshList() {
      if (filter === MESSAGE_FILTER_OPTIONS.FILTER_SPECIFIC_USER && mensagens.length < total) {
        return dispatch(MensagensActions.listRequest({
          ...listRequestPayload,
          limit: Number.MAX_SAFE_INTEGER,
          page: 1,
        })); 

      } 
      
      if (scrollRatio > 0 && mensagens.length < total) {
        const newPage = Math.ceil(mensagens.length / 10) + 1;
        return dispatch(MensagensActions.listRequest({
            ...listRequestPayload,
            page: newPage,
        })); 
      }
    }

    useEffect(() => {
        dispatch(MensagensActions.listInitialize());
        if (loaderRef && loaderRef.current) {
            intersectionObserver.observe(loaderRef.current);
        }
        
        return () => {
            intersectionObserver.disconnect();
            dispatch(MensagensActions.listInitialize());
        }
    }, []);

    useEffect(() => {
        if (documento._id) {
            dispatch(MensagensActions.listRequest(listRequestPayload));
        }

    }, [dispatch, documento._id]);

    useEffect(() => {
      refreshList();

    }, [scrollRatio, filter]);

    function handleChangeSort(sort) {
        dispatch(MensagensActions.listRequest({
            ...listRequestPayload,
            page,
            limit,
            sort,
            search,
            endpoint,
        }));
    }

  useEffect(() => {
    if (documento._id) {
      dispatch(MensagensActions.listRequest(listRequestPayload));
    }
  }, [dispatch, documento._id]);

  function handleChangeSort(sort) {
    dispatch(
      MensagensActions.listRequest({
        ...listRequestPayload,
        page,
        limit: Number.MAX_SAFE_INTEGER,
        sort,
        search,
        endpoint,
      })
    );
  }

  function handleShowUpload(mensagem) {
    dispatch(MensagemActions.openUpload(mensagem));
  }

  function handleClose() {
    dispatch(MensagemActions.closeUpload());
  }

  function handleShowView(mensagem) {
    dispatch(MensagemActions.openView(mensagem));
  }

  function handleUpload(filesToUpload) {
    const formData = new FormData();

    filesToUpload.forEach((fileToUpload) => {
      formData.append("imagem", fileToUpload.file);
      formData.append("nome", fileToUpload.nome);
      formData.append("size", fileToUpload.size);
    });

    dispatch(MensagemActions.uploadRequest(formData));
  }

  function handleShowImagens(imagens) {
    setImagens(imagens);
    setOpen(true);
  }

  function activeFilter(message) {
    switch (filter) {
      case MESSAGE_FILTER_OPTIONS.FILTER_SPECIFIC_USER:
        return selectedAuthorFilter ? message.autor.nome === selectedAuthorFilter : true;

      case MESSAGE_FILTER_OPTIONS.NO_FILTER:
        return true;

      case MESSAGE_FILTER_OPTIONS.ONLY_MESSAGES_FROM_USER:
        return message.autor._id === userId;

      case MESSAGE_FILTER_OPTIONS.ONLY_MESSAGES_FROM_OTHERS:
        return message.autor._id !== userId;

      default:
        return true;
    }
  }

  return (
    <>
      <Container>
        {mensagens.filter(activeFilter).map((message) => (
          <SingleMessage
            key={message._id}
            documentStatus={documento.status}
            message={message}
            handleUpload={handleUpload}
            handleShowImagens={handleShowImagens}
            handleShowView={handleShowView}
            handleShowUpload={handleShowUpload}
          />
        ))}

        <LoadingContainer ref={loaderRef} loading={loading || mensagens.length < total}>
          <CircularProgress size={60} />
        </LoadingContainer>
      </Container>
      <SelectFilesToUpload
        open={openUpload}
        onClose={handleClose}
        onUpload={handleUpload}
        loading={loadingUpload}
        defaultName="Documento"
        names={["Documento"]}
      />

      <DialogPreviewImages
        open={open}
        onClose={() => setOpen(false)}
        imagens={imagens}
      />
    </>
  );
}

export default MessageChat;
