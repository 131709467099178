export const Types = {
  LIST_REQUEST: "tipoimagens/LIST_REQUEST",
  LIST_SUCCESS: "tipoimagens/LIST_SUCCESS",
  REPORT_REQUEST: "tipoimagens/REPORT_REQUEST",
  REPORT_SUCCESS: "tipoimagens/REPORT_SUCCESS",
  REPORT_CLEANER: "tipoimagens/REPORT_CLEANER",
  LIST_BY_TIPODOC_REQUEST: "tipoimagens/LIST_BY_TIPODOC_REQUEST",
  LIST_BY_TIPODOC_SUCCESS: "tipoimagens/LIST_BY_TIPODOC_SUCCESS",
  REMOVE_REQUEST: "tipoimagens/REMOVE_REQUEST",
  REMOVE_SUCCESS: "tipoimagens/REMOVE_SUCCESS",
  NEW_REQUEST: "tipoimagens/NEW_REQUEST",
  NEW_SUCCESS: "tipoimagens/NEW_SUCCESS",
  EDIT_REQUEST: "tipoimagens/EDIT_REQUEST",
  EDIT_SUCCESS: "tipoimagens/EDIT_SUCCESS",
  GET_REQUEST: "tipoimagens/GET_REQUEST",
  GET_SUCCESS: "tipoimagens/GET_SUCCESS",
  EDIT_INDEXADORES_REQUEST: "tipoimagens/EDIT_INDEXADORES_REQUEST",
  EDIT_INDEXADORES_SUCCESS: "tipoimagens/EDIT_INDEXADORES_SUCCESS",
};

const INITIAL_STATE = {
  list: [],
  report: [],
  loading: false,
  loadingReport: false,
  pages: 0,
  page: 1,
  limit: 10,
  total: 0,
  sort: "nome",
};

export default function tipoimagem(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.LIST_REQUEST:
      return {
        ...state,
        loading: true,
        report: [],
        page: action.payload.page,
        limit: action.payload.limit,
        sort: action.payload.sort,
      };
    case Types.LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload.docs,
        pages: action.payload.pages,
        total: action.payload.total,
      };
    case Types.REPORT_REQUEST:
      return { ...state, loadingReport: true };
    case Types.REPORT_SUCCESS:
      return {
        ...state,
        loadingReport: false,
        report: action.payload,
      };
    case Types.REPORT_CLEANER:
      return {
        ...state,
        report: [],
      };
    case Types.REMOVE_REQUEST:
      return { ...state, loading: true };

    case Types.REMOVE_SUCCESS:
      return { ...state, loading: false };
    case Types.NEW_REQUEST:
      return { ...state, loading: true };
    case Types.NEW_SUCCESS:
      return { ...state, loading: false };
    case Types.EDIT_REQUEST:
      return { ...state };
    case Types.EDIT_SUCCESS:
      return { ...state, loading: false };
    case Types.GET_REQUEST:
      return { ...state, loading: true };
    case Types.GET_SUCCESS:
      return { ...state, loading: false, tipoimg: action.payload };
    case Types.EDIT_INDEXADORES_REQUEST:
      return { ...state, loading: true };
    case Types.EDIT_INDEXADORES_SUCCESS:
      return { ...state, loading: false };

    case Types.LIST_BY_TIPODOC_REQUEST:
      return { ...state, loading: true };

    case Types.LIST_BY_TIPODOC_SUCCESS:
      return { ...state, list: action.payload, loading: false };

    default:
      return state;
  }
}

export const Creators = {
  listRequest: ({ empresa, page, limit, query, sort }) => ({
    type: Types.LIST_REQUEST,
    payload: { empresa, page, limit, query, sort },
  }),

  listSuccess: (tipoimagem) => ({
    type: Types.LIST_SUCCESS,
    payload: tipoimagem,
  }),

  reportRequest: (query) => ({
    type: Types.REPORT_REQUEST,
    payload: { query },
  }),

  reportSuccess: (tiposimagem) => ({
    type: Types.REPORT_SUCCESS,
    payload: tiposimagem,
  }),

  reportCleaner: () => ({
    type: Types.REPORT_CLEANER,
    payload: {},
  }),

  listByTipoDocumentoRequest: (idTipoDocumento) => ({
    type: Types.LIST_BY_TIPODOC_REQUEST,
    payload: idTipoDocumento,
  }),

  listByTipoDocumentoSuccess: (tiposimagem) => ({
    type: Types.LIST_BY_TIPODOC_SUCCESS,
    payload: tiposimagem,
  }),

  removeRequest: (id) => ({
    type: Types.REMOVE_REQUEST,
    payload: id,
  }),

  removeSuccess: () => ({
    type: Types.REMOVE_SUCCESS,
    payload: [],
  }),
  createRequest: (tipoimagem) => ({
    type: Types.NEW_REQUEST,
    payload: tipoimagem,
  }),

  createSuccess: () => ({
    type: Types.NEW_SUCCESS,
    payload: {},
  }),
  editRequest: (tipoimagem) => ({
    type: Types.EDIT_REQUEST,
    payload: tipoimagem,
  }),

  editSuccess: () => ({
    type: Types.EDIT_SUCCESS,
    payload: {},
  }),
  getRequest: (id) => ({
    type: Types.GET_REQUEST,
    payload: id,
  }),

  getSuccess: (tipoimagem) => ({
    type: Types.GET_SUCCESS,
    payload: tipoimagem,
  }),

  editIndexadoresRequest: (idTipoDoc, indexadores) => ({
    type: Types.EDIT_INDEXADORES_REQUEST,
    payload: {
      idTipoDoc,
      indexadores,
    },
  }),

  editIndexadoresSuccess: () => ({
    type: Types.EDIT_INDEXADORES_SUCCESS,
    payload: {},
  }),
};
