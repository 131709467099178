import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Row, Col } from 'react-bootstrap';
import * as pdfjsLib from 'pdfjs-dist';
import { toastr } from 'react-redux-toastr';
import jsPDF from 'jspdf';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import { getPresignedUrl } from 'services/presignedUrl';

import { InfoIcon, FaPlusCircle } from 'components/Icons';
import Content from 'components/Content';
import Viewer from 'components/SearchViewer/Viewer';
import IndexadoresClassificaoManual from './IndexadoresClassificaoManual';
import GaleriaIndexacaoManual from './GaleriaIndexacaoManual';

import { Creators as ClassificacaoActions } from 'store/ducks/classificacao';
import { Buttons, ContainerMessage } from './style';

export default function ClassificacaoManual() {
  const dispatch = useDispatch();

  const [imagesToProcess, setImagesToProcess] = useState([]);
  const [currentFile, setCurrentFile] = useState();
  const [pdfPages, setPdfPages] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedImagesType, setSelectedImagesType] = useState('');
  const [loadingSplitImages, setLoadingSplitImages] = useState(false);

  const { document: documento, loading: loadingDocument } = useSelector(
    (state) => state.classificacao
  );

  const mergeImagesIntoPDF = async () => {
    return new Promise((resolve, reject) => {
      const pdf = new jsPDF({
        orientation: 'p',
        unit: 'mm',
        format: 'a4',
      });

      selectedImages.forEach((image, index) => {
        if (index > 0) pdf.addPage();
        const imgData = image.replace('image/png', 'image/jpeg');
        pdf.addImage(imgData, 'JPEG', 0, 0, 210, 297, undefined, 'SLOW');
      });

      try {
        const pdfBase64 = pdf.output('datauristring');
        console.log('pdfBase64', pdfBase64);
        resolve(pdfBase64);
      } catch (error) {
        reject(error);
      }
    });
  };

  const splitPdf = async () => {
    setLoadingSplitImages(true);
    const presignedPdf = await getPresignedUrl(currentFile.original);

    const response = await fetch(presignedPdf);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.arrayBuffer();

    const loadingTask = pdfjsLib.getDocument({ data: data });

    const pdf = await loadingTask.promise;
    const numPages = pdf.numPages;

    for (let i = 1; i <= numPages; i++) {
      const page = await pdf.getPage(i);
      const viewport = page.getViewport({ scale: 1 });
      const canvas = document.createElement('canvas');
      canvas.height = viewport.height;
      canvas.width = viewport.width;
      const context = canvas.getContext('2d');

      await page.render({ canvasContext: context, viewport: viewport }).promise;

      const imageData = canvas.toDataURL('image/png');

      setPdfPages((prev) => [...prev, imageData]);
    }

    setLoadingSplitImages(false);
  };

  const handleUpdateClassification = async () => {
    if (!selectedImagesType) {
      toastr.error(
        'Tipo de imagem obrigatório',
        'Selecione um tipo de imagem antes de salvar o documento'
      );

      return;
    }

    if (pdfPages.length > 0 && selectedImages.length === 0) {
      toastr.error(
        'Nenhuma página selecionada',
        'Selecione pelo menos uma página antes de salvar o documento'
      );

      return;
    }

    let file = {
      filepath: currentFile.original,
      tipoImagem: selectedImagesType._id,
    };

    if (selectedImages.length > 0) {
      const generetadPdf = await mergeImagesIntoPDF();

      file.pdf = generetadPdf;
    }

    dispatch(
      ClassificacaoActions.updateClassificationRequest({
        documento: documento._id,
        file,
      })
    );

    const updatedPdfPages = pdfPages.filter(
      (page) => !selectedImages.includes(page)
    );

    setPdfPages(updatedPdfPages);
    setSelectedImagesType([]);
    setSelectedImages([]);

    if (updatedPdfPages.length !== 0) {
      return;
    }

    const newImagesToProcess = imagesToProcess.filter(
      (image) => image.filepath !== currentFile.original
    );

    setImagesToProcess(newImagesToProcess);

    if (newImagesToProcess.length === 0) {
      setCurrentFile(null);
      dispatch(ClassificacaoActions.getNextJobRequest({}));
    } else {
      const firstDocument = newImagesToProcess[0].filepath;

      const presignedPdf = await getPresignedUrl(firstDocument);
      setCurrentFile({ presignedPdf, original: firstDocument });
    }
  };

  useEffect(() => {
    async function getFirstPresignedUrl() {
      if (!documento) return;

      if (documento.type) {
        toastr.success('', documento.message);
        return;
      }

      setImagesToProcess(documento.imagens);

      const firstDocument = documento.imagens[0].filepath;

      const presignedPdf = await getPresignedUrl(firstDocument);
      setCurrentFile({ presignedPdf, original: firstDocument });
    }

    getFirstPresignedUrl();
  }, [documento]);

  useEffect(() => {
    dispatch(ClassificacaoActions.getNextJobRequest({}));
  }, []);

  return (
    <Content>
      <Row>
        <Col md={documento && documento.type ? 12 : 9} className="painel">
          <div className="painel-content">
            {loadingDocument ? (
              <ContainerMessage>
                <div>
                  <InfoIcon />
                </div>
                <h4>Carregando...</h4>
              </ContainerMessage>
            ) : (
              <>
                {documento && documento.type && (
                  <ContainerMessage>
                    <div>
                      <InfoIcon />
                    </div>
                    <h4>Não há novos documentos para classificar</h4>
                  </ContainerMessage>
                )}
                {currentFile && (
                  <Buttons>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={splitPdf}
                      tabIndex={-1}
                      startIcon={
                        loadingSplitImages ? (
                          <CircularProgress size={20} color="#fff" />
                        ) : null
                      }
                    >
                      Dividir Páginas
                    </Button>
                  </Buttons>
                )}

                {pdfPages.length > 0 ? (
                  <GaleriaIndexacaoManual
                    images={pdfPages}
                    selectedImages={selectedImages}
                    setSelectedImages={setSelectedImages}
                  />
                ) : (
                  <>
                    {currentFile && (
                      <Viewer imagem={currentFile.presignedPdf} />
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </Col>
        {documento && !documento.type && (
          <Col md="3" className="painel">
            <div className="painel-content">
              {loadingDocument ? (
                <div>Carregando...</div>
              ) : (
                <>
                  {documento && (
                    <IndexadoresClassificaoManual
                      document={documento}
                      handleUpdateClassification={handleUpdateClassification}
                      selectedImagesType={selectedImagesType}
                      setSelectedImagesType={setSelectedImagesType}
                    />
                  )}
                </>
              )}
            </div>
          </Col>
        )}
      </Row>
    </Content>
  );
}
