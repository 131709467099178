import React from 'react';

import useStyles from './styles';

const OPERACOES = {
  INCLUSAO: 'Inclusão',
  ALTERACAO: 'Alteração',
  EXCLUSAO: 'Exclusão'
}

function Operacao({ operacao }) {
  const classes = useStyles();

  return (
    <div className={classes[operacao]}>
      {OPERACOES[operacao]}
    </div>
  );
}

export default Operacao;