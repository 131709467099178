import { DescriptionIcon, GridOnIcon, ImageIcon, PictureAsPdfIcon, TextFieldsIcon, UploadIcon } from "components/Icons";

export const THUMBNAIL_STYLES = {
    'pdf': {
      icon: PictureAsPdfIcon,
      color: "#b72222",
    },
    'txt': {
      icon: TextFieldsIcon,
      color: "#4f4f4f",
    },
    'png': {
      icon: ImageIcon,
      color: "#c0bcec",
    },
    'jpeg': {
      icon: ImageIcon,
      color: "#c0bcec",
    },
    'jpg': {
        icon: ImageIcon,
        color: "#c0bcec",
    },
    'docx': {
      icon: DescriptionIcon,
      color: "#477ccc",
    },
    'xlsx': {
      icon: GridOnIcon,
      color: "#34c132",
    },
    'generic': {
      icon: UploadIcon,
      color: "#8700ff",
    },
}