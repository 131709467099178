import * as Yup from "yup";
import Locale from "./Locale";

Yup.setLocale(Locale);

export default Yup.object().shape({
  empresa: Yup.string().required(),
  tipodoc: Yup.string().required(),
  nome: Yup.string().required(),
});
