import React, { memo, useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Creators as TemplateActions } from "store/ducks/template";
import { Grid, Card, CardContent, Typography } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";

import { Creators as TiposDocumentoActions } from "store/ducks/tiposdocumento";

import {
  FaParagraph,
  FaSignature,
  ArrowUpIcon,
  ArrowDownIcon,
} from "components/Icons";

import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import { ListItem, ListItemText } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import "../styles.css";

const TAB_ELEMENTOS = 1;
const TAB_INDEXADORES = 2;

function Options({
  state,
  handleShowModalHeader,
  handleShowModalFooter,
  handleShowModalSignaturePoints,
}) {
  const dispatch = useDispatch();

  const [value, setValue] = useState(1);

  const {
    access: { empresas },
  } = useSelector((state) => state.permissoes);

  useEffect(() => {
    dispatch(TiposDocumentoActions.listByEmpresaRequest(empresas));
  }, [dispatch, empresas]);

  const { listByEmpresas: tipoDocList, loading: loadingTipoDoc } = useSelector(
    (state) => state.tipoDocumentos
  );

  const indexadorToChipData = (indexadores) => {
    const mapeamento = indexadores?.map((item) => {
      let key = `{{ ${item.id_es.toLowerCase().replace(/_/g, "_")} }}`;
      return { key, label: item.nome };
    });

    return mapeamento || [];
  };

  const hasFooter = !!Object.keys(state?.footer || {}).length;
  const hasHeader = !!Object.keys(state?.header || {}).length;

  const [chipData, setChipData] = useState([]);

  useEffect(() => {
    const indexadores = indexadorToChipData(
      tipoDocList?.find(
        (x) => x._id === state?.templateSelected?.idTipoDocumento
      )?.indexadores
    );

    setChipData(indexadores);
  }, [state?.templateSelected?.idTipoDocumento, tipoDocList]);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const styles = {
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
  };

  const stylesAvatar = {
    color: "#6259ca",
    backgroundColor: "#eaedf7",
  };

  const Container = ({ children }) => {
    return <div style={styles}>{children}</div>;
  };

  const handleNewParagraph = () => {
    dispatch(TemplateActions.showModal({ modal: "PARAGRAPH", show: true }));
  };

  const InnerContent = ({ children }) => {
    return (
      <div
        className="dados-section"
        style={{
          backgroundColor: "#fff",
          border: "none",
          padding: "0px",
          margin: "0px",
          boxShadow:
            "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
        }}
      >
        {children}
      </div>
    );
  };

  const handleVariable = ({ key }) => {
    const { currentEditor } = state;
    dispatch(TemplateActions.addVariable({ key, currentEditor }));
  };

  return (
    <Container>
      <InnerContent>
        <AppBar
          position="static"
          style={{
            border: "none",
            display: "flex",
            overflow: "hidden",
            alignItems: "center",
            alignContent: "center",
            boxShadow:
              "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
          }}
        >
          <Tabs
            aria-label="simple tabs example"
            onChange={handleTabChange}
            value={value}
          >
            <Tab value={TAB_ELEMENTOS} label="Elementos" />
            <Tab value={TAB_INDEXADORES} label="Indexadores" />
          </Tabs>
        </AppBar>

        {value === TAB_INDEXADORES && chipData && (
          <Card className="dados-section m-0">
            <CardContent>
              <Typography className="label" color="textSecondary" gutterBottom>
                Indexadores
              </Typography>
              {chipData.map((item) => (
                <Chip
                  onClick={() => handleVariable(item)}
                  label={item.label}
                  className="chip m-1"
                />
              ))}
            </CardContent>
          </Card>
        )}

        {value === TAB_ELEMENTOS && (
          <div className="mt-2">
            <ListItem md={2} onClick={() => handleNewParagraph()}>
              <ListItemAvatar>
                <Avatar style={stylesAvatar} variant="rounded">
                  <FaParagraph />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={"Parágrafo"}
                secondary={
                  "Permite combinação de digitação livre com o preenchimento de campos customizáveis."
                }
              />
            </ListItem>

            <ListItem
              md={2}
              disabled={hasHeader}
              onClick={() => handleShowModalHeader()}
            >
              <ListItemAvatar>
                <Avatar style={stylesAvatar} variant="rounded">
                  <ArrowUpIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={"Cabeçalho"}
                secondary={
                  "Seção localizada no topo comumente utilizada para títulos, datas e identificação da empresa"
                }
              />
            </ListItem>

            <ListItem
              md={2}
              disabled={hasFooter}
              onClick={() => handleShowModalFooter()}
            >
              <ListItemAvatar>
                <Avatar style={stylesAvatar} variant="rounded">
                  <ArrowDownIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={"Rodapé"}
                secondary={
                  "Seção localizada no parte inferior do documento comumente utilizado para informações adicionais"
                }
              />
            </ListItem>

            <ListItem
              md={2}
              onClick={() => handleShowModalSignaturePoints()}
            >
              <ListItemAvatar>
                <Avatar style={stylesAvatar} variant="rounded">
                  <FaSignature />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={"Gerenciamento de assinaturas"}
                secondary={"Permite o posicionamento da assinatura"}
              />
            </ListItem>
          </div>
        )}
      </InnerContent>
    </Container>
  );
}

export default memo(Options);
