import React from "react";
import { Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import { Creators as MensagemAction } from "store/ducks/mensagem";

function ViewMensagem({ documento }) {
  const dispatch = useDispatch();

  const { openDelete, loading, data } = useSelector((state) => state.mensagem);

  const { assunto, _id } = data;

  function handleClose() {
    if (!loading) dispatch(MensagemAction.closeDelete());
  }

  function handleDelete() {
    dispatch(MensagemAction.deleteRequest(_id));
  }

  return (
    <Dialog open={openDelete} onClose={handleClose}>
      <DialogTitle>Remover mensagem</DialogTitle>
      <DialogContent>
        <DialogActions>
          <Row>
            <p>Você tem certeza que deseja remover a mensagem "{assunto}"?</p>

            <DialogActions>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={!!loading}
                onClick={handleDelete}
              >
                {!!loading && (
                  <>
                    <CircularProgress color="primary" size={20} />
                    &nbsp;
                  </>
                )}
                Confirmar
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleClose}
              >
                Cancelar
              </Button>
            </DialogActions>
          </Row>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}

export default ViewMensagem;
