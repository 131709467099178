import api from "services/api";
import { getToken } from "services/auth";
import { call, select, put, all, takeLatest } from "redux-saga/effects";
import { toastr } from "react-redux-toastr";
import { parseISO } from "date-fns";

import {
  Creators as EncerramentoActions,
  Types,
} from "store/ducks/encerramento";
import { Creators as EncerramentosActions } from "store/ducks/encerramentos";

export const getOrganization = (state) => state.usuarios.auth.organization;
export const getParamsEncerramentos = (state) => ({
  page: state.encerramentos.page,
  search: state.encerramentos.search,
  documento: state.encerramentos.documento,
  sort: state.encerramentos.sort,
  limit: state.encerramentos.limit,
});
export const getEncerramento = (state) => state.encerramento.data;

export function* saveEncerramento({ payload }) {
  const organization = yield select(getOrganization);
  const { _id, ...body } = payload.encerramento;

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const data = {
      ...body,
      dataDoTransito: parseISO(body.dataDoTransito),
      dataDoEncerramento: parseISO(body.dataDoEncerramento),
    };

    let response;
    if (_id) {
      response = yield call(
        api.put,
        `encerramentos/${_id}`,
        data,
        headerParams
      );
    } else {
      response = yield call(api.post, "encerramentos", data, headerParams);
    }

    if (response?.data?.error)
      throw new Error(
        response?.data?.message ||
          "Não foi possível salvar o encerramento do processo"
      );

    toastr.success("Sucesso!", "Dados salvos com sucesso!");
    yield put(EncerramentoActions.saveSuccess());
    const params = yield select(getParamsEncerramentos);
    yield put(EncerramentosActions.listRequest(params));
  } catch (error) {
    yield put(EncerramentoActions.saveError());
    toastr.error("Ops", error.message);
  }
}

export function* remove({ payload }) {
  const organization = yield select(getOrganization);
  const id = payload;

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    yield call(api.delete, `encerramentos/${id}`, headerParams);

    toastr.success("Sucesso!", "Remoção realizada com sucesso!");
    yield put(EncerramentoActions.deleteSuccess());
    const params = yield select(getParamsEncerramentos);
    yield put(EncerramentosActions.listRequest(params));
  } catch (err) {
    yield put(EncerramentoActions.uploadError());
    toastr.error("Ops", "Não foi possível realizar a remoção");
  }
}

export function* upload({ payload }) {
  const organization = yield select(getOrganization);
  const encerramento = yield select(getEncerramento);
  const { body } = payload;

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    yield call(
      api.post,
      `encerramentos/${encerramento._id}/imagens`,
      body,
      headerParams
    );

    toastr.success("Sucesso!", "Upload realizado com sucesso!");
    yield put(EncerramentoActions.uploadSuccess());
    const params = yield select(getParamsEncerramentos);
    yield put(EncerramentosActions.listRequest(params));
  } catch (err) {
    yield put(EncerramentoActions.uploadError());
    toastr.error("Ops", "Não foi possível realizar o upload");
  }
}

export function* deleteEncerramentoImage({ payload }) {
  const organization = yield select(getOrganization);
  const encerramento = yield select(getEncerramento);

  const { idImage } = payload;

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    yield call(
      api.delete,
      `encerramentos/${encerramento._id}/imagens/${idImage}`,
      headerParams
    );

    const encerramentoSerialized = {
      ...encerramento,
      imagens: encerramento.imagens.filter((imagem) => imagem._id !== idImage),
    };

    yield put(EncerramentoActions.deleteImageSuccess(encerramentoSerialized));
  } catch (err) {
    yield put(EncerramentoActions.deleteImageError());
    toastr.error(
      "Ops",
      "Não foi possível remover a imagem vinculado ao encerramento"
    );
  }
}

export default all([
  takeLatest(Types.SAVE_REQUEST, saveEncerramento),
  takeLatest(Types.UPLOAD_REQUEST, upload),
  takeLatest(Types.DELETE_REQUEST, remove),
  takeLatest(Types.DELETE_IMAGE_REQUEST, deleteEncerramentoImage),
]);
