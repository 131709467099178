import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { Form } from '@unform/web';
import Grid from '@material-ui/core/Grid';
import ButtonMui from "@material-ui/core/Button";
import BackButton from "components/BackButton";
import InputForm from 'components/InputForm';
import { FormControlLabel, Switch } from '@material-ui/core';

import RelacionamentoSchema from 'validators/RelacionamentoSchema';

import { ValidationError } from 'yup';

import { Creators as RelacionamentosActions } from "../../../store/ducks/relacionamentos";
import Content from "components/Content";

export default function EditRelacionamentos(params) {


  const dispatch = useDispatch();
  const formRef = useRef(null);

  const { relac } = params.location.state;

  const { relacionamento, descricao, _id, status } = relac;

  const [statusSwitch, setStatusSwitch] = useState(relac.status);

  const onSubmitEdit = async (values) => {
    const body = { ...values, _id, status: statusSwitch };

    try {

      await RelacionamentoSchema.validate(body, {
        abortEarly: false
      });

      dispatch(RelacionamentosActions.editRelacionamentoRequest(body));

    } catch (err) {
      const validationErrors = {};
      if (err instanceof ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        })

        formRef.current.setErrors(validationErrors);
      }
    }
  };

  const handleExclude = () => {

    try {

      dispatch(RelacionamentosActions.removeRelacionamentoRequest(relac._id));

    } catch (err) {
      const validationErrors = {};
      if (err instanceof ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        })

        formRef.current.setErrors(validationErrors);
      }
    }

  }

  const handleCheckStatus = () => {

    if (statusSwitch !== true) {
      setStatusSwitch(true);
    } else {
      setStatusSwitch(false);
    }
  };

  return (
    <Content>
      <Row>
        <Col md="12" className="painel">
          <div className="painel-content">
            <div className="painel-title text-left">
              Editar Relacionamento
            </div>
            <div className="painel-body">
              <Form ref={formRef} initialData={{ relacionamento, descricao, status: String(statusSwitch) }} onSubmit={onSubmitEdit}>
                <Row>
                  <Col>
                    <InputForm
                      label="Nome"
                      name="relacionamento"
                    />
                  </Col>
                  <Col>
                    <InputForm
                      label="Descrição"
                      name="descricao"
                    />
                  </Col>
                  {/* <Col>
                        <SelectForm
                          id="select-status"
                          label="Status"
                          name="status"
                        >
                          <MenuItem value="true">Ativo</MenuItem>
                          <MenuItem value="false">Inativo</MenuItem>
                        </SelectForm>
                      </Col> */}
                </Row>
                <FormControlLabel
                  control={
                    <Switch
                      name="status"
                      onChange={handleCheckStatus}
                      color="primary"
                      checked={statusSwitch}
                    />
                  }
                  label="Ativo"
                />
                <Grid container spacing={1}>
                  <Grid item  >
                    <ButtonMui
                      variant="contained"
                      color="primary"
                      type="submit">Enviar</ButtonMui>
                  </Grid>
                  <Grid item >
                    <BackButton
                      variant="contained"
                      color="primary">Voltar</BackButton>
                  </Grid>

                  <Grid item >
                    <ButtonMui
                      variant="contained"
                      color="secondary"
                      onClick={handleExclude}
                    >Excluir</ButtonMui>
                  </Grid>
                </Grid>
              </Form>
            </div>
          </div>
        </Col>
      </Row>
    </Content>
  );
}
