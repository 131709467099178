import styled, { keyframes, css } from 'styled-components/macro';

const circleAnimation = keyframes`
    50% {
        opacity: 0;
    }
`;

const barAnimation = keyframes`
    50% {
        transform: scaleY(.2);
    }
`;

export const Container = styled.div`
    display: flex;
    width: ${({ size }) => `${size}px`};
    height: ${({ size }) => `${size * 1.2}px`};
    justify-content: center;
    align-items: center;
    ${({ fullscreen }) => fullscreen && css`
        min-height: 90vh;
        width: 100%;
    `};

    
    #circles_fill_1,
    #circles_fill_2,
    #circles_fill_3,
    #circles_fill_4,
    #circles_fill_5 {
        animation: ${circleAnimation} 3s infinite;
        
    }

    #circles_fill_1 {
        animation-delay: -.5s;
    }

    #circles_fill_2 {
        animation-delay: .2s;
    }

    #circles_fill_3 {
        animation-delay: -.7s;
    }

    #circles_fill_4 {
        animation-delay: .1s;
    }

    #circles_fill_5 {
        animation-delay: 1.2s;
    }

    #Bar_1,
    #Bar_2,
    #Bar_3 {
        animation: ${barAnimation} 1.8s infinite;
        transform-origin: bottom;
        transform-box: fill-box;
    }

    #Bar_2 {
        animation-delay: -.3s; 
    }

    #Bar_3 {
        animation-delay: .9s;      
    }
`;