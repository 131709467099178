import api from "services/api";
import { getToken } from "services/auth";
import { call, select, put, all, takeLatest } from "redux-saga/effects";
import { toastr } from "react-redux-toastr";
import { push } from "connected-react-router";

import {
  Creators as DepartamentoEscalonamentoActions,
  Types,
} from "../ducks/departamentoescalonamento";


export const getOrganization = (state) => state.usuarios.auth.organization;
export const getUserId = (state) => state.usuarios.auth.id;

export function* listDepartamentoEscalonamento({ payload }) {
  try {
    const organization = yield select(getOrganization);

    const { page, limit } = payload;

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
      params: { page, limit },
    };

    const response = yield call(api.get, `escalonamento`, headerParams);

    const data = { departamentoescalonamento: response.data || [] };

    yield put(DepartamentoEscalonamentoActions.listSuccess(data));
  } catch (error) {
    toastr.error("Ops", "Não foi possível listar os escalonamentos");
  }
}

export function* getDepartamentoEscalonamento(action) {
  try {
    const headerParams = {
      headers: { Authorization: getToken() },
    };

    const { data } = yield call(
      api.get,
      `escalonamento/${action.payload}`,
      headerParams
    );

    yield put(DepartamentoEscalonamentoActions.getSuccess(data));
  } catch (error) {
    toastr.error("Ops", "Não foi possível buscar o escalonamento.");
  }
}

export function* editDepartamentoEscalonamento(action) {
  const userId = yield select(getUserId);

  try {
    const organization = yield select(getOrganization);

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const body = { ...action.payload };

    const { data } = yield call(
      api.put,
      `escalonamento/${action.payload._id}`,
      body,
      headerParams
    );

    yield put(DepartamentoEscalonamentoActions.editSuccess(data));

    toastr.success("Escalonamento editado com sucesso.");
  } catch (error) {
    toastr.error("Ops", "Não foi possível editar o escalonamento.");
  }
}

export function* createDepartamentoEscalonamento(action) {
  const organization = yield select(getOrganization);

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const { data } = yield call(
      api.post,
      `escalonamento`,
      action.payload,
      headerParams
    );

    yield put(DepartamentoEscalonamentoActions.createSuccess(data));

    yield put(push('/administracao/departamentos'));

    toastr.success("", "Escalonamento atualizado com sucesso.");
  } catch (error) {
    toastr.error("Ops", "Não foi possível criar o escalonamento.");
  }
}

export default all([
  takeLatest(Types.LIST_REQUEST, listDepartamentoEscalonamento),
  takeLatest(Types.GET_REQUEST, getDepartamentoEscalonamento),
  takeLatest(Types.EDIT_REQUEST, editDepartamentoEscalonamento),
  takeLatest(Types.NEW_REQUEST, createDepartamentoEscalonamento),
]);
