import api from 'services/api';
import { getToken } from 'services/auth';
import { call, select, put, all, takeLatest } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';
import { parseISO } from 'date-fns';

import { Creators as BloqueioActions, Types } from 'store/ducks/bloqueio';
import { Creators as BloqueiosActions } from 'store/ducks/bloqueios';

export const getOrganization = state => state.usuarios.auth.organization;
export const getParamsBloqueios = state => ({ 
  page: state.bloqueios.page,
  search: state.bloqueios.search,
  documento: state.bloqueios.documento,
  sort: state.bloqueios.sort,
  limit: state.bloqueios.limit
});
export const getBloqueio = state => state.bloqueio.data;

export function* saveBloqueio({ payload }) {
  const organization = yield select(getOrganization);
  const { _id, ...body } = payload.bloqueio;

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const data = {
      ...body,
      data: parseISO(body.data)
    }

    if (_id) {
      yield call(api.put, `bloqueios/${_id}`, data, headerParams);
    } else {
      yield call(api.post, 'bloqueios', data, headerParams);
    }

    toastr.success('Sucesso!', 'Dados salvos com sucesso!')
    yield put(BloqueioActions.saveSuccess());
    const params = yield select(getParamsBloqueios);
    yield put(BloqueiosActions.listRequest(params));
  } catch (error) {
    yield put(BloqueioActions.saveError());
    toastr.error('Ops', 'Não foi possível salvar o bloqueio/garantia do processo.');
  }
}

export function* upload({ payload }) {
  const organization = yield select(getOrganization);
  const bloqueio = yield select(getBloqueio);
  const { body } = payload;

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    yield call(api.post, `bloqueios/${bloqueio._id}/imagens`, body, headerParams);

    toastr.success('Sucesso!', 'Upload realizado com sucesso!');
    yield put(BloqueioActions.uploadSuccess());
    const params = yield select(getParamsBloqueios);
    yield put(BloqueiosActions.listRequest(params));
  } catch(err) {
    yield put(BloqueioActions.uploadError());
    toastr.error('Ops', 'Não foi possível realizar o upload');
  }
}

export function* deleteBloqueio() {
  const organization = yield select(getOrganization);
  const bloqueio = yield select(getBloqueio);

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    yield call(api.delete, `bloqueios/${bloqueio._id}`, headerParams);

    yield put(BloqueioActions.deleteSuccess());
    const params = yield select(getParamsBloqueios);
    yield put(BloqueiosActions.listRequest(params));
  } catch(err) {
    yield put(BloqueioActions.deleteError());
    toastr.error('Ops', 'Não foi possível remover o bloqueio/garantia');
  }
}

export function* deleteBloqueioImage({ payload }) {
  const organization = yield select(getOrganization);
  const bloqueio = yield select(getBloqueio);

  const { idImage } = payload;

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    yield call(api.delete, `bloqueios/${bloqueio._id}/imagens/${idImage}`, headerParams);
    
    const bloqueioSerialized = {
      ...bloqueio,
      imagens: bloqueio.imagens.filter(imagem => imagem._id !== idImage)
    }

    yield put(BloqueioActions.deleteImageSuccess(bloqueioSerialized));
  } catch(err) {
    yield put(BloqueioActions.deleteImageError());
    toastr.error('Ops', 'Não foi possível remover a imagem vinculado ao bloqueio/garantia');
  }
}

export default all([
  takeLatest(Types.SAVE_REQUEST, saveBloqueio),
  takeLatest(Types.UPLOAD_REQUEST, upload),
  takeLatest(Types.DELETE_REQUEST, deleteBloqueio),
  takeLatest(Types.DELETE_IMAGE_REQUEST, deleteBloqueioImage)
]);