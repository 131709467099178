import styled, { css, keyframes } from 'styled-components/macro';

const messagePop = keyframes`
    from {
        transform: translateX(100px)
        opacity: 0;
    }

    from {
        transform: translateX(0)
        opacity: 1;
    }
`;

export const Container = styled.li`
    display: flex;
    width: 100%;
    animation: ${messagePop} .3s forwards ease-in;
    flex-direction: ${({ ownUserMessage }) => ownUserMessage ? 'row' : 'row-reverse'};

    &:not(:first-of-type) {
        margin-top: 16px;
    }
`;

export const Wrapper = styled.div`
    display: flex;  
`;

export const Body = styled.div`
    position: relative;
    background-color: rgb(235, 237, 239);
    border: 2px solid rgb(234, 236, 238);
    color: rgb(79, 79, 79);
    padding: 16px;
    margin-right: 24px;
    border-radius: 4px;
    box-shadow: 0 1px 2px #00000044;
    padding: 1rem;

    ${({ ownUserMessage }) => !ownUserMessage && css`
        background-color: rgb(235, 237, 239);
        border: 2px solid rgb(234, 236, 238);
        margin-left: auto;

        &::after {
            content: "";
            position: absolute;
            right: -10px;
            top: 30px;
            width: 0; 
            height: 0; 
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;
            border-left: 10px solid rgb(235, 237, 239);
        }    
    `};
`;

export const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
    margin-top: 5px;
    font-size: 1rem;
`;

export const AuthorName = styled.strong`
    font-size: 12px;
    color: rgb(79, 79, 79);
`;

export const Time = styled.span`
    font-size: 12px;
`;

export const Subject = styled.div`
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 4px;
    font-weight: 400;
`;

export const Message = styled.p`
    width: 75ch;
    font-size: 12px;
    line-height: 3ch;
    text-align: justify;
    text-justify: newspaper;
`;

export const AuthorAvatar = styled.div`
    margin-top: 16px;
    width: 45px;
    height: 45px;
    background-color: #e7ced1;
    border-radius: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 20px;
`;

export const ActionBar = styled.div`
    pointer-events: none;
    opacity: 0;
    transition: opacity .3s ease;

    ${({ visible }) => visible && css`
        opacity: 1;
        pointer-events: all;
    `};
`;
