import React, { useMemo } from 'react';
import InputMask from 'react-input-mask';
import TextField from '@material-ui/core/TextField';

function DynamicInputMask({ value = '', onChange, name, label, mask, error, helperText }) {

  const maskDynamic = useMemo(() => {
    let maskDynamic = '';
    [...mask].forEach(char => {
      if (char === '1') {
        maskDynamic += '9';
      } else if (char === 'a') {
        maskDynamic += '*'
      } else {
        maskDynamic += char
      }
    });
    return maskDynamic
  }, [mask])

  return (
    <InputMask
      multiple={true}
      mask={maskDynamic}
      onChange={onChange}
      value={value}
      name={name}
    >
      {inputProps => <TextField
        {...inputProps}
        label={label}
        variant="outlined"
        size="small"
        error={error}
        helperText={helperText}
      />}
    </InputMask>
  );
}

export default DynamicInputMask;