import React, { useState } from 'react';
import { 
  Icon, IconButton, Typography,
  Avatar, Divider, Button,
  CircularProgress
} from '@material-ui/core';
import mime from 'mime-types';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from "react-redux-toastr";

import DialogPreviewImage from 'components/DialogPreviewImage';
import Aprovado from './Aprovado';
import Reprovado from './Reprovado';

import { Creators as DocumentosActions } from 'store/ducks/documentos';
import Utils from 'utils/utils';
import { defaultQueryParams } from 'constants/default-query-params';

import ProvidenciasService from 'services/ProvidenciasService';

import './styles.css'

function Tarefa({ onBack, tarefa, avatar, disableButtons, onApproveDisapprove }) {
  const dispatch = useDispatch();

  const organization = useSelector(state => state.usuarios.auth.organization);

  const [imageUrl, setImageUrl] = useState('');
  const [open, setOpen] = useState(false);
  const [loadingToApprove, setLoadingToApprove] = useState(false);
  const [loadingToDisapprove, setLoadingToDisapprove] = useState(false);

  function getEXT(filepath) {
    const contentType = mime.lookup(filepath);
    const extension = mime.extension(contentType);
    return extension.toLowerCase();
  }

  function handleShowAnexo(imageUrl) {
    setImageUrl(imageUrl);
    setOpen(true);
  }

  function handleClickProcesso() {
    dispatch(DocumentosActions.sendToSearchPageRequest(
      [tarefa.documento._id], 
      defaultQueryParams, 
      false,
      true
    ));
  }

  async function handleTaskApproval(status) {

    try {
      setLoadingToApprove(true);
      await ProvidenciasService.taskApproval(tarefa._id, organization, status);
      toastr.success(
        "Tarefa concluída!",
        `${status ? "Aprovação" : "Reprovação"} realizada com sucesso!`
      );
      onApproveDisapprove();
    } catch (e) {
      console.error(e);
      toastr.error("Ops", "Não foi possível realizar a mudança de status da tarefa");
    } finally {
      setLoadingToApprove(false);
    } 
  }

  return (
    <div className="tarefa-container">
      <div className="tarefa-header">
        <div className="tarefa-title">
          <IconButton onClick={onBack}>
            <Icon>arrow_back</Icon>
          </IconButton>
          <Typography variant="subtitle1">
            {tarefa.tipoTarefa.nome}
          </Typography>
        </div>
        {(tarefa.aprovado !== true && tarefa.aprovado !== false) && 
        <div className="tarefa-aprovar-reprovar">
          <Button
            color="primary"
            variant="contained"
            style={{
              marginRight: '8px'
            }}
            disabled={disableButtons || loadingToApprove || loadingToDisapprove}
            onClick={() => handleTaskApproval(true)}
            startIcon={!!loadingToApprove && <CircularProgress size={20} />}
          >
            Encerrar
          </Button>
        </div>}
        {tarefa.aprovado === true && <Aprovado />}
        {tarefa.aprovado === false && <Reprovado />}
      </div>
      <Divider />
      <div className="tarefa-body">
        <div className="tarefa-body-header">
          <div className="tarefa-avatar">
            <Avatar>{Utils.getNameInitials(avatar)}</Avatar>
            <Typography variant="subtitle1">{avatar}</Typography>
          </div>
          <Typography 
            className="mensagens-numero-processo" 
            variant="subtitle1"
            onClick={handleClickProcesso}
          >
            {tarefa.documento ? tarefa.documento.numeroCNJ : ''}
          </Typography>
        </div>
        <Typography variant="body2" dangerouslySetInnerHTML={{
          __html: tarefa.descricao
        }}/>
        <Divider />
        <Typography variant="subtitle1">
          {`Anexos (${tarefa.imagens ? tarefa.imagens.length : 0})`}
        </Typography>
        <div className="tarefa-anexos">
          {tarefa.imagens && tarefa.imagens.map((imagem, index) => 
            <div 
              key={index}
              className="tarefa-anexo" 
              onClick={e => handleShowAnexo(imagem.filepath)}
            >
              <div className={`tarefa-anexo-${getEXT(imagem.filepath)}`}>
                {getEXT(imagem.filepath).toUpperCase()}
              </div>
              <Typography variant="subtitle1">{imagem.nome}</Typography>
            </div>
          )}
        </div>
      </div>
      <DialogPreviewImage
        open={open}
        onClose={() => setOpen(false)}
        imageUrl={imageUrl}
      />
    </div>
  );
}

export default Tarefa;