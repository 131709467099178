import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format, parseISO } from 'date-fns';

import {
  CheckIcon as EmDiaIcon,
  NewReleasesIcon as AtrasadaIcon,
  PriorityHighIcon as HojeIcon,
} from 'components/Icons';

import {
  VencimentoStatus,
  AprovacaoStatus,
  LoaderContainer,
  TableRow,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  UserList,
  ActionButton,
} from './styles/TaskList';

import CustomTableSortLabel from 'components/CustomTableSortLabel';
import UserAvatar from 'components/UserAvatar';
import TaskActionsMenu from '../TaskActionsMenu';
import Loader from '../Loader';

import { Creators as ProvidenciasActions } from 'store/ducks/providencias';
import { MoreIcon } from 'components/Icons';

import './styles.css';

const TABLE_HEADERS = [
  {
    label: 'Data',
    sortField: 'createdAt',
  },
  {
    label: 'Tipo',
    sortField: 'tipoTarefa.nome',
  },
  {
    label: 'Titulo',
    sortField: 'titulo',
  },
  {
    label: 'Participantes',
    sortField: 'participantes',
  },
  {
    label: 'Prazo',
    sortField: 'vencimento',
  },
  {
    label: 'Situaçao',
    sortField: 'status',
  },
];

function TaskList({
  tasks,
  loading,
  total,
  page,
  limit,
  handleChangeRowsPerPage,
  handleChangePage,
  handleChangeSort,
  sort,
}) {
  const dispatch = useDispatch();

  const { selectedTask } = useSelector((state) => state.providencias);

  const [actionMenuAnchor, setActionMenuAnchor] = useState(null);

  useEffect(() => {
    // TODO - see if it will conflict with modals
    if (!actionMenuAnchor) {
      dispatch(ProvidenciasActions.selectTask(null));
    }
  }, [actionMenuAnchor]);

  function getVencimento(task) {
    if (!task.vencimento) {
      return null;
    }

    const [vencimentoDate] = task.vencimento.split('.');
    const formattedVencimentoDate = format(
      parseISO(vencimentoDate),
      'dd/MM/yyyy'
    );
    // TODO se tarefa for aprovada antes do vencimento, a cor é a mesma de tarefas futuras (verde)
    const icon =
      task.dateStatus === 'Atrasadas' ? (
        <AtrasadaIcon />
      ) : task.dateStatus === 'Hoje' ? (
        <HojeIcon />
      ) : (
        <EmDiaIcon />
      );

    return (
      <VencimentoStatus approved={task.dataAprovacao} status={task.dateStatus}>
        {icon}
        {formattedVencimentoDate}
      </VencimentoStatus>
    );
  }

  function getAprovacaoStatus(task) {
    const status = task.hasOwnProperty('aprovado')
      ? task.aprovado
        ? 'ENCERRADA'
        : 'EM ANDAMENTO'
      : 'EM ANDAMENTO';

    return <AprovacaoStatus status={status}>{status}</AprovacaoStatus>;
  }

  function handleOpenActionMenu(e, task) {
    dispatch(ProvidenciasActions.selectTask(task));
    setActionMenuAnchor(e.currentTarget);
  }

  function handleCloseActionMenu() {
    dispatch(ProvidenciasActions.selectTask(null));
    setActionMenuAnchor(null);
  }

  return (
    <>
      {loading ? (
        <LoaderContainer>
          <Loader fullscreen={false} size={300} />
        </LoaderContainer>
      ) : (
        <>
          {tasks.map((task) => (
            <>
              <div key={task._id} className="task-list-item">
                <div className="task-list-item__header">
                  <div className="task-list-item__header__item">
                    <div className="task-list-item__header_title">
                      {task.titulo}
                    </div>
                    <div className="task-list-item__header_subtitle">
                      <div className="task-list-item__header_subtitle_item">
                        <span className="task-list-item__header__label">
                          Tipo de tarefa:
                        </span>
                        {task.tipoTarefa ? task.tipoTarefa.nome : ''}
                      </div>
                      <div className="task-list-item__header_subtitle_item">
                        <span className="task-list-item__header__label">
                          Data da criação:
                        </span>
                        {format(parseISO(task.createdAt), 'dd/MM/yyyy')}
                      </div>
                      <div className="task-list-item__header_subtitle_item">
                        <span className="task-list-item__header__label">
                          Vencimento:
                        </span>
                        {format(parseISO(task.vencimento), 'dd/MM/yyyy')}
                      </div>
                    </div>
                  </div>
                  <div className="task-list-item__header__item">
                    <ActionButton
                      aria-label="Abrir menu de ações"
                      onClick={(event) => handleOpenActionMenu(event, task)}
                    >
                      <MoreIcon />
                    </ActionButton>
                  </div>
                </div>
                <div className="task-list-item__content">
                  <div className="task-list-item__content__participants_status">
                    <div className="task-list-item__content__participants_status_item task-list-item__content__participants ">
                      <label>Participantes:</label>
                      <UserList>
                        {task.participantes.map((participante) => (
                          <UserAvatar
                            key={participante._id}
                            user={participante}
                            size={30}
                          />
                        ))}
                      </UserList>
                    </div>
                    <div className="task-list-item__content__participants_status_item task-list-item__content__status ">
                      {getAprovacaoStatus(task)}
                    </div>
                  </div>
                </div>
              </div>
              {/* 
              <TableRow
                key={task._id}
                selected={selectedTask && selectedTask._id === task._id}
              >
                <TableCell>
                  {format(parseISO(task.createdAt), 'dd/MM/yyyy')}
                </TableCell>
                <TableCell>
                  {task.tipoTarefa ? task.tipoTarefa.nome : ''}
                </TableCell>
                <TableCell>{task.titulo}</TableCell>
                <TableCell>
                  <UserList>
                    {task.participantes.map((participante) => (
                      <UserAvatar
                        key={participante._id}
                        user={participante}
                        size={30}
                      />
                    ))}
                  </UserList>
                </TableCell>
                <TableCell>{getVencimento(task)}</TableCell>
                <TableCell>{getAprovacaoStatus(task)}</TableCell>

                <TableCell align="center">
                  
                </TableCell>
              </TableRow> */}
            </>
          ))}
        </>
      )}
      {actionMenuAnchor && (
        <TaskActionsMenu
          anchor={actionMenuAnchor}
          handleClose={handleCloseActionMenu}
        />
      )}
    </>
  );
}

export default TaskList;
