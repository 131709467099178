import React, { useState, useEffect, useMemo } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { BeatLoader } from 'react-spinners';
import { Grid } from '@material-ui/core';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Icon from '@material-ui/core/Icon';
import AppBar from '@material-ui/core/AppBar';
import ButtonMui from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import ToolTipComponent from 'components/Tooltip';
import ButtonSquare from 'components/ButtonSquare';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TableEditable from '../../components/TableEditable';
import InputAdornment from '@material-ui/core/InputAdornment';
import TableContainer from '@material-ui/core/TableContainer';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { InfoIcon } from 'components/Icons';
import { Col, Row } from 'react-bootstrap';
import ModalUploadFile from './ModalCheckItemView/index';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import DialogPreviewImages from 'components/DialogPreviewImages';
import MenuItem from '@material-ui/core/MenuItem';

import { Creators as CheckListDocumentoActions } from 'store/ducks/checklistdocumento';
import { toastr } from 'react-redux-toastr';

import './styles.css';
import { getPresignedUrl } from 'services/presignedUrl';

export default function FormalizacaoButtons({ onSubmit }) {
  const { loading, data } = useSelector((state) => state.checklistdocumento);

  return (
    <>
      {!loading && (
        <>
          {data && data.length > 0 ? (
            <div className="buttons formalizacao-buttons">
              <Grid container spacing={1}>
                <Grid item>
                  <ButtonMui
                    onClick={() => onSubmit()}
                    variant="contained"
                    color="primary"
                    type="submit"
                  >
                    Salvar
                  </ButtonMui>
                </Grid>
              </Grid>
            </div>
          ) : (
            <Grid container alignContent="center" justify="center">
              Não há dados de formalização a serem mostrados.
            </Grid>
          )}
        </>
      )}
    </>
  );
}
