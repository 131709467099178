import api from "./api";
import { getToken } from "./auth";
import { getPresignedUrl } from "./presignedUrl";
import { store } from 'store';

export const getUploadAndPresignedUrl = async file => {
  
  const { organization } = store.getState().usuarios.auth;

  const headerParams = {
    headers: { Authorization: getToken(), xkeyorg: organization }
  };

  const responseUpload = await api.post(
    's3/upload',
    { file: file },
    headerParams
  )

  const { filePath } = responseUpload?.data;
  const signedUrl = await getPresignedUrl(filePath);

  return signedUrl
};
