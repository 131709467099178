export const Types = {
  LIST_REQUEST: "tiposDocumento/LIST_REQUEST",
  LIST_SUCCESS: "tiposDocumento/LIST_SUCCESS",
  LIST_BY_EMPRESAS_REQUEST: "tiposDocumento/LIST_BY_EMPRESAS_REQUEST",
  LIST_BY_EMPRESAS_SUCCESS: "tiposDocumento/LIST_BY_EMPRESAS_SUCCESS",
  REMOVE_REQUEST: "tiposDocumento/REMOVE_REQUEST",
  REMOVE_SUCCESS: "tiposDocumento/REMOVE_SUCCESS",
  NEW_REQUEST: "tiposDocumento/NEW_REQUEST",
  NEW_SUCCESS: "tiposDocumento/NEW_SUCCESS",
  EDIT_REQUEST: "tiposDocumento/EDIT_REQUEST",
  EDIT_INDEXADORES_REQUEST: "tiposDocumento/EDIT_INDEXADORES_REQUEST",
  EDIT_INDEXADORES_SUCCESS: "tiposDocumento/EDIT_INDEXADORES_SUCCESS",
  GET_INDEXADORES_REQUEST: "tiposDocumento/GET_INDEXADORES_REQUEST",
  GET_INDEXADORES_SUCCESS: "tiposDocumento/GET_INDEXADORES_SUCCESS",

  UPDATE_RULES_REQUEST: "tiposDocumento/UPDATE_RULES_REQUEST",
  UPDATE_RULES_SUCCESS: "tiposDocumento/UPDATE_RULES_SUCCESS"
};

const INITIAL_STATE = {
  loading: false,
  list: [],
  listByEmpresas: [],
  indexadores: [],
  page: 1,
  limit: 10,
  total: 0,
  sort: 'nome'
};

export default function items(state = INITIAL_STATE, action) {

  switch (action.type) {

    case Types.LIST_REQUEST:
      return { 
        ...state, 
        loading: true,
        page: action.payload.page,
        limit: action.payload.limit,
        sort: action.payload.sort
      };

    case Types.LIST_SUCCESS:
      return { 
        ...state, 
        ...action.payload, 
        loading: false 
      };

    case Types.LIST_BY_EMPRESAS_REQUEST:
      return { ...state, loading: true }

    case Types.LIST_BY_EMPRESAS_SUCCESS:
      return { ...state, loading: false, listByEmpresas: action.payload }

    case Types.REMOVE_REQUEST:
      return { ...state, loading: true };

    case Types.REMOVE_SUCCESS:
      return { ...state, loading: false };

    case Types.EDIT_INDEXADORES_REQUEST:
      return { ...state, loading: true };

    case Types.EDIT_INDEXADORES_SUCCESS:
      return { ...state, loading: false };

    case Types.GET_INDEXADORES_REQUEST: 
      return { ...state, loading: true };

    case Types.GET_INDEXADORES_SUCCESS:
      return { ...state, loading: false, indexadores: action.payload };

    default:
      return state;
  }
}

export const Creators = {
  listRequest: data => ({
    type: Types.LIST_REQUEST,
    payload: data
  }),

  listSuccess: data => ({
    type: Types.LIST_SUCCESS,
    payload: data
  }),

  listByEmpresaRequest: idEmpresa => ({
    type: Types.LIST_BY_EMPRESAS_REQUEST,
    payload: idEmpresa
  }),

  listByEmpresaSuccess: tiposDocumento => ({
    type: Types.LIST_BY_EMPRESAS_SUCCESS,
    payload: tiposDocumento
  }),

  removeRequest: id => ({
    type: Types.REMOVE_REQUEST,
    payload: id
  }),

  removeSuccess: () => ({
    type: Types.REMOVE_SUCCESS,
    payload: []
  }),

  createRequest: tiposDocumento => ({
    type: Types.NEW_REQUEST,
    payload: tiposDocumento
  }),

  createSuccess: () => ({
    type: Types.NEW_SUCCESS,
    payload: {}
  }),

  editRequest: tiposDocumento => ({
    type: Types.EDIT_REQUEST,
    payload: tiposDocumento
  }),

  editSuccess: () => ({
    type: Types.NEW_SUCCESS,
    payload: {}
  }),

  editIndexadoresRequest: (idTipoDoc, indexadores) => ({
    type: Types.EDIT_INDEXADORES_REQUEST,
    payload: {
      idTipoDoc,
      indexadores
    }
  }),

  editIndexadoresSuccess: () => ({
    type: Types.EDIT_INDEXADORES_SUCCESS,
    payload: {}
  }),

  getIndexadoresRequest: idTipoDoc => ({
    type: Types.GET_INDEXADORES_REQUEST,
    payload: idTipoDoc
  }),

  getIndexadoresSuccess: indexadores => ({
    type: Types.GET_INDEXADORES_SUCCESS,
    payload: indexadores
  }),
  

  updateRulesRequest: (payload) => ({
    type: Types.UPDATE_RULES_REQUEST,
    payload,
  }),

  updateRulesSucess: () => ({
    type: Types.UPDATE_RULES_SUCCESS,
  }),
};
