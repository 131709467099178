export const Types = {
  GET_REQUEST: 'processo/GET_REQUEST',
  GET_SUCCESS: 'processo/GET_SUCCESS',
  GET_ERROR: 'processo/GET_ERROR',
  CLEAR: 'processo/CLEAR'
}

const INITIAL_STATE = {
  data: {},
  loading: false,
  error: false
}

export default function processo(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
        data: {} 
      }

    case Types.GET_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload.processo
      }

    case Types.GET_ERROR:
      return {
        ...state,
        loading: false,
        error: true
      }

    case Types.CLEAR:
      return INITIAL_STATE

    default:
      return state
  }
}

export const Creators = {
  getRequest: numeroCNJ => ({
    type: Types.GET_REQUEST,
    payload: { numeroCNJ }
  }),
  getSuccess: processo => ({
    type: Types.GET_SUCCESS,
    payload: { processo }
  }),
  getError: () => ({
    type: Types.GET_ERROR
  }),
  clear: () => ({
    type: Types.CLEAR
  })
}