export const Types = {
    NEW_REQUEST: "exportDocuments/NEW_REQUEST",
    NEW_SUCCESS: "exportDocuments/NEW_SUCCESS",
};

const INITIAL_STATE = {
    loading: false,
};

export default function items(state = INITIAL_STATE, action) {
    switch (action.type) {
        case Types.NEW_REQUEST:
            return { ...state, loading: true };

        case Types.NEW_SUCCESS:
            return { ...state, loading: false };

        default:
            return state;
    }
}

export const Creators = {
    createRequest: (mail) => ({
        type: Types.NEW_REQUEST,
        payload: mail,
    }),

    createSuccess: () => ({
        type: Types.NEW_SUCCESS,
        payload: {},
    }),
};
