import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import { getDocument } from 'pdfjs-dist';
import { getPresignedUrlBase64 } from 'services/presignedUrl';

export default function Thumb({ base64PDF }) {
    const [thumbnail, setThumbnail] = useState(null);

    const { organization } = useSelector(state => state.usuarios.auth);

    useEffect(() => {
        const renderThumbnail = async () => {
            if (base64PDF.startsWith('data:image/png') || base64PDF.startsWith('data:image/jpeg')) {
                setThumbnail(base64PDF);
                return;
            } else if (base64PDF.indexOf(organization) > -1) {
                const base64 = await getPresignedUrlBase64(base64PDF);
                setThumbnail(base64);
                return;
            }

            const thumbnailBase64 = await renderPDFThumbnail(base64PDF);
            setThumbnail(thumbnailBase64);
        };
        renderThumbnail();
    }, [base64PDF]);


    function base64ToUint8Array(base64) {
        const cleanedBase64 = base64.includes(',') ? base64.split(',')[1] : base64;

        const raw = atob(cleanedBase64);
        const uint8Array = new Uint8Array(new ArrayBuffer(raw.length));
        for (let i = 0; i < raw.length; i++) {
            uint8Array[i] = raw.charCodeAt(i);
        }
        return uint8Array;
    }

    // Função para renderizar o PDF e gerar uma miniatura
    async function renderPDFThumbnail(base64PDF) {
        const pdfData = base64ToUint8Array(base64PDF);

        // Carregar o PDF usando PDF.js
        const pdf = await getDocument({ data: pdfData }).promise;
        const page = await pdf.getPage(1); // Obter a primeira página do PDF

        const scale = 0.5; // Definir a escala para o thumbnail
        const viewport = page.getViewport({ scale });

        // Criar um canvas para renderizar o thumbnail
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        canvas.height = viewport.height;
        canvas.width = viewport.width;

        // Renderizar a página no canvas
        const renderContext = {
            canvasContext: context,
            viewport: viewport,
        };

        await page.render(renderContext).promise;

        // Converter o canvas para uma imagem base64
        const thumbnailBase64 = canvas.toDataURL();
        return thumbnailBase64;
    }


    return (
        <>
            {thumbnail ? <img src={thumbnail} alt="PDF Thumbnail" /> : <p>Carregando thumbnail...</p>}
        </>
    );
}