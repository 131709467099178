import styled from 'styled-components/macro';
import {
    Dialog,
    DialogTitle as MaterialDialogTitle,
    DialogContent,
    DialogContentText as MaterialDialogContentText,
    DialogActions,
    Button as MaterialButton,
} from '@material-ui/core';

export const Modal = styled(Dialog)`
 min-width: 500px;
`;

export const Button = styled(MaterialButton)`
    width: 30%;

    &:not(:last-of-type) {
        margin-right: 8px;
    }
`;

export const ButtonClose = styled.div`
    position: absolute;
    display: flex;
    top: 16px;
    right: 16px;
    background-color: transparent;
    outline: none;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color .3s ease;

    &:hover {
        background-color: #00000022;
    }
`;

export const DialogTitle = styled(MaterialDialogTitle)`
    width: 100%;
    text-align: center;
`;

export const DialogContentText = styled(MaterialDialogContentText)`
    padding: 32px;
`;

export const GroupButton = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 32px; 
`;