import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Row, Col } from "react-bootstrap";

import { CircularProgress } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import ButtonMui from "@material-ui/core/Button";

import { Form } from "@unform/web";
import InputForm from "components/InputForm";
import AutocompleteForm from "components/AutocompleteForm";

import BackButton from "../../../components/BackButton";

import "./styles.css";

import { Creators as CheckTipoActions } from "../../../store/ducks/checktipo";
import Content from "components/Content";

export default function EditCheckTipo(params) {
  const dispatch = useDispatch();
  const formRef = useRef();

  const empresaSetada = useSelector((state) => state.checkTipos.empresaSetada);
  const { checkTipos } = params.location.state;

  checkTipos["empresa"] = empresaSetada;
  let { _id, ...initialData } = checkTipos;

  const empresas = useSelector((state) => state.empresas.list);
  const { loading } = useSelector((state) => state.checkTipos);
  initialData.empresa = empresas.find(
    (empresa) => empresa._id === initialData.empresa
  );

  const handleSubmit = async (values) => {
    const body = { ...values, _id };

    dispatch(CheckTipoActions.editRequest(body));
  };

  return (
    <Content>
      <Row>
        <Col md="12" className="painel">
          <div className="painel-content">
            <div className="painel-title text-left">
              Editar Tipo de CheckList:
            </div>
            <div className="painel-body">
              <Form
                onSubmit={handleSubmit}
                ref={formRef}
                initialData={initialData}
              >
                <Row>
                  <Col md={4}>
                    <AutocompleteForm
                      disabled
                      label="Empresa*"
                      name="empresa"
                      options={empresas}
                      transformOnSelect={(option) => option._id}
                      getOptionLabel={(option) => option.fantasia}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <InputForm name="nome" label="Descrição" />
                  </Col>
                </Row>

                <div className="buttons">
                  <Grid container spacing={1}>
                    <Grid item>
                      <ButtonMui
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={loading}
                      >
                        Enviar
                        {!!loading && <CircularProgress size={20} />}
                      </ButtonMui>
                    </Grid>
                    <Grid item>
                      <BackButton variant="contained" color="secondary">
                        Voltar
                      </BackButton>
                    </Grid>
                  </Grid>
                </div>
              </Form>
            </div>
          </div>
        </Col>
      </Row>
    </Content>
  );
}
