import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { format, parseISO } from 'date-fns';
import {
  CreateIcon,
  DateRangeIcon,
  GavelIcon,
  InfoIcon,
  NoteAddIcon,
  PermIdentityIcon,
} from 'components/Icons';

function ArrayItem(dadosAlterados) {
  return dadosAlterados
    ?.filter((dadoAlterado) => dadoAlterado.visivel !== false)
    ?.map((dadoAlterado) => (
      <>
        {dadoAlterado && typeof dadoAlterado?.valor === 'object'
          ? Object.keys(dadoAlterado?.valor).map((key) =>
              Information({ key, description: dadoAlterado.valor[key] })
            )
          : Information({
              key: dadoAlterado?.chave,
              description: dadoAlterado?.valor,
            })}
      </>
    ));
}

function ObjectItem(dadosAlterados) {
  return Object.keys(dadosAlterados?.Descricao || {}).map((key) =>
    Information({ key, description: dadosAlterados.Descricao[key] })
  );
}

function Information({ key, description }) {
  const text =
    typeof description !== 'string' ? JSON.stringify(description) : description;
  return (
    <>
      {key && (
        <Typography key={key}>
          <strong>{key}: </strong>
          {isHTML(text, key) ? (
            <iframe
              src={`data:text/html;charset=utf-8,${text}`}
              width="100%"
              frameborder="0"
              maxHeight="500"
              display="block"
              overflow="hidden"
            ></iframe>
          ) : isHTMLAnswer(text) ? (
            <iframe
              src={`data:text/plain;charset=utf-8,${text}`}
              width="100%"
              frameborder="0"
              maxHeight="500"
              display="block"
              overflow="hidden"
            ></iframe>
          ) : (
            `${text || '-'}`
          )}
        </Typography>
      )}
    </>
  );
}

function isHTML(text) {
  return ['<br>', '<p>', '<b>'].some((tag) => text?.includes(tag));
}

function isHTMLAnswer(text) {
  return text?.includes('\n');
}

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
  content: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  contentdata: {
    padding: '6px 16px',
  },
  information: {
    textAlign: 'left',
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  mainInfos: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: '10px !important',
    marginBottom: '10px',
  },
  dateInfo: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export default function ComponenteHistorico({
  document,
  onShowlogs,
  isSimplified,
}) {
  const classes = useStyles();
  const usuario = document?.nome || document?.usuario?.nome;
  const dadosAlterados =
    document.detalhes.dadosAlterados ||
    document.detalhes.identificacaoDetalhada;
  const grupoemail = document.detalhes.identificacaoDetalhada.find(
    (e) => e.chave === 'grupoemail'
  );

  function handleClick(dadosAlterados) {
    if (onShowlogs) onShowlogs(dadosAlterados, document.detalhes.acao);
  }

  function getTimelineDotColor(document) {
    let color = 'secondary';

    switch (document.operacao) {
      case 'ALTERACAO':
        color = '#f1388b';
        break;
      case 'INCLUSAO':
        color = '#6259ca';
        break;
      case 'ANDAMENTO':
        color = '#01b8ff';
        break;
      case 'ENCERRAMENTO':
        color = '#f16d75';
        break;
      default:
        color = '#6259ca';
        break;
    }

    return color;
  }

  return (
    <TimelineItem
      className="componente-historico-container"
      style={{
        wordBreak: 'break-all',
        borderRadius: '0px',
      }}
    >
      <TimelineOppositeContent
        style={
          !isSimplified
            ? { maxWidth: '1px', paddingLeft: '0px', paddingRight: '0px' }
            : {}
        }
      >
        <Typography variant="body2" color="textSecondary"></Typography>
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineDot
          style={{ backgroundColor: getTimelineDotColor(document) }}
        ></TimelineDot>
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent>
        <Paper elevation={3} className={classes.paper}>
          <div className={classes.content}>
            <div
              style={{
                width: '100%',
                border: '1px solid',
                borderColor: getTimelineDotColor(document),
              }}
            ></div>
            <div className={classes.contentdata}>
              <div className={classes.information}>
                <Typography variant="h6" component="h1">
                  <strong>{document.detalhes.acao}</strong>
                  {dadosAlterados && isSimplified ? (
                    <IconButton onClick={() => handleClick(dadosAlterados)}>
                      <InfoIcon htmlColor="#333" />
                    </IconButton>
                  ) : (
                    <></>
                  )}
                </Typography>
                <div className={classes.mainInfos}>
                  {document.operacao !== 'ANDAMENTO' && (
                    <Typography>
                      <PermIdentityIcon
                        className="mr-1"
                        style={{ fontSize: '14px', color: '#8f8fb1' }}
                      />
                      {usuario}
                    </Typography>
                  )}
                  <Typography className={classes.dateInfo}>
                    <DateRangeIcon
                      className="mr-1"
                      style={{ fontSize: '14px', color: '#8f8fb1' }}
                    />
                    <span>
                      {format(parseISO(document.data), 'dd/MM/yyyy - HH:mm')}
                    </span>
                  </Typography>
                </div>
                {grupoemail && (
                  <Typography>
                    <b>grupo de e-mail:</b> {grupoemail.valor}
                  </Typography>
                )}
                {document.operacao === 'ANDAMENTO' && (
                  <Typography>
                    {document.detalhes.identificacaoDetalhada[0].valor}
                  </Typography>
                )}
                {dadosAlterados &&
                !isSimplified &&
                Array.isArray(dadosAlterados)
                  ? ArrayItem(dadosAlterados)
                  : ObjectItem(dadosAlterados)}
              </div>
            </div>
          </div>
        </Paper>
      </TimelineContent>
    </TimelineItem>
  );
}
