export const Types = {
  GET_REQUEST: "paramformalizacaoauditoria/GET_REQUEST",
  GET_SUCCESS: "paramformalizacaoauditoria/GET_SUCCESS",
};

/* Reducer */

const INITIAL_STATE = {
  loading: false,
  data: [],
  page: 1,
  limit: 10,
  total: 0,
  sort: "_id",
};

export default function items(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_REQUEST:
      return { ...state, loading: true };
    case Types.GET_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    default:
      return state;
  }
}

export const Creators = {
  getRequest: (id) => ({
    type: Types.GET_REQUEST,
    payload: id,
  }),

  getSuccess: (data) => ({
    type: Types.GET_SUCCESS,
    payload: data,
  }),
};
