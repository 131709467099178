import api from "../../services/api";
import { getToken } from "../../services/auth";
import { put, call } from "redux-saga/effects";
import { toastr } from "react-redux-toastr";
import { Creators as TipoImagemActions } from "../ducks/tipoimagem";
import { push } from "connected-react-router";
import { select } from "redux-saga/effects";

export const getOrganization = (state) => state.usuarios.auth.organization;
export const getUserId = (state) => state.usuarios.auth.id;

export function* listTipoImagem({ payload }) {
  try {
    const organization = yield select(getOrganization);

    const { page, limit, sort } = payload;

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
      params: { page, limit, sort },
    };

    const body = {};

    if (payload.query) {
      body.query = payload.query;
    }

    const { data } = yield call(api.post, `tipoimagens`, body, headerParams);

    yield put(TipoImagemActions.listSuccess(data));
  } catch (error) {
    toastr.error("Ops", "Não foi possível listar os tipos de imagens.");
  }
}

export function* reportTipoImagem(action) {
  try {
    const organization = yield select(getOrganization);

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const body = {};

    if (action.payload.query) {
      body.query = action.payload.query;
    }

    const { data } = yield call(
      api.post,
      `tipoimagens/report`,
      body,
      headerParams
    );

    yield put(TipoImagemActions.reportSuccess(data));
  } catch (error) {
    toastr.error(
      "Ops",
      "Não foi possível gerar o relatorio dos tipos de imagens."
    );
  }
}

export function* listTipoImagemByTipoDocumento(action) {
  try {
    const organization = yield select(getOrganization);

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const { data } = yield call(
      api.get,
      `tipoimagens/tipodoc/${action.payload}`,
      headerParams
    );

    yield put(TipoImagemActions.listByTipoDocumentoSuccess(data));
  } catch (error) {
    toastr.error("Ops", "Não foi possível listar os tipos de imagens.");
  }
}

export function* removeTipoImagem(action) {
  try {
    const organization = yield select(getOrganization);

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const {
      data: { status },
    } = yield call(api.delete, `tipoimagem/${action.payload}`, headerParams);

    if (status) {
      toastr.success("Modalidade removida", "Remoção realizada com sucesso.");
    } else {
      toastr.error(
        "Remoção cancelada",
        "O tipo de documento está atrelado a um ou mais documentos."
      );
    }

    yield put(
      TipoImagemActions.listRequest({ page: 1, limit: 10 })
    );
  } catch (error) {
    toastr.error("Ops", "Não foi possível remover o tipo de imagem.");
  }
}
export function* createTipoImagem(action) {
  try {
    const userId = yield select(getUserId);

    const organization = yield select(getOrganization);

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const body = { ...action.payload, userAcao: userId };

    const { data } = yield call(api.post, `tipoimagem`, body, headerParams);

    yield put(push(`/configuracao/tipoimagens`));

    yield put(TipoImagemActions.createSuccess(data));

    toastr.success("", "Tipo de imagem criado com sucesso.");
  } catch (error) {
    toastr.error("Ops", "Não foi possível criar o tipo de imagem.");
  }
}

export function* editTipoImagem(action) {
  const userId = yield select(getUserId);

  try {
    const organization = yield select(getOrganization);

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const body = { ...action.payload, _id_user_acao: userId };

    const { data } = yield call(
      api.put,
      `/tipoimagem/${action.payload._id}`,
      body,
      headerParams
    );

    yield put(push(`/configuracao/tipoimagens`));

    yield put(TipoImagemActions.editSuccess(data));

    toastr.success("", "Tipo de imagem editada com sucesso.");
  } catch (error) {
    toastr.error("Ops", "Não foi possível editar o tipo de imagem.");
  }
}

export function* getTipoImagem(action) {
  try {
    const organization = yield select(getOrganization);

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const { data } = yield call(
      api.get,
      `/tipoimagem/${action.payload._id}`,
      headerParams
    );

    yield put(TipoImagemActions.getSuccess(data));
  } catch (error) {
    toastr.error("Ops", "Não foi possível buscar os dados do tipo de imagem.");
  }
}

export function* editIndexadoresTipoImagem(action) {
  try {
    const organization = yield select(getOrganization);

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };
    const body = { ...action.payload };

    const { data } = yield call(
      api.put,
      `/tipoimagem/${action.payload.idTipoDoc}/indexadores`,
      body,
      headerParams
    );

    yield put(TipoImagemActions.editIndexadoresSuccess(data));
  } catch (error) {
    yield put(TipoImagemActions.editIndexadoresSuccess());
  }
}
