import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
  item: {
    cursor: 'pointer',
    width: '100%',
    maxWidth: '36ch',
    backgroundColor: theme.palette.background.paper,
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.1)'
    }
  },
  read: {
    float: 'right',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  whiteBg: {
    color: "#5f6368",
    backgroundColor: "#fff",
  },
}));