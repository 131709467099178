import styled from 'styled-components/macro';

import { default as MaterialButton } from "@material-ui/core/Button";
import { default as MaterialIcon } from "@material-ui/core/Icon";

export const Button = styled(MaterialButton)`
    background-color: transparent;
    color: #cccccc;
    box-shadow: none;
    border-radius: 500px;
    width: 45px;
    height: 45px;

    &:hover {
        background-color: transparent;
        color: #333;
        box-shadow: none;
    }

    &:disabled {
        background-color: transparent;
        color: #EEE;
        box-shadow: none;
    }

    &:disabled &::after, &:disabled &::after {
        content: "";
        width: 100%;
        height: 2px;
        background-color: #333;
    }

`;

export const Icon = styled(MaterialIcon)`

`;
