import React, { useState, useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import Popover from "@material-ui/core/Popover";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";

import Analytics from "./Analytics";
import Tooltip from "../../../components/Tooltip";

import "./style.css";

import { Col, Row } from "react-bootstrap";
import { MoreIcon } from "components/Icons";

export default function Charts() {
  const [menuEl, setMenuEl] = useState(null);
  const [loading, setLoading] = useState(true);
  const [view, setView] = useState("");
  const open = Boolean(menuEl);

  const { pathname } = useLocation();

  const { servicos } = useSelector(({ permissoes }) => permissoes.access);

  const userActions = useMemo(() => {
    const find = servicos.find((item) => item.route === pathname);
    if (find !== undefined) return find;
    return [];
  }, [servicos]);

  const hasDashboardProcessosGerais = useMemo(
    () =>
      userActions.actions &&
      userActions.actions.some(
        (userAct) => userAct.action === "dashboard processos gerais"
      ),
    [userActions]
  );

  const hasDashboardProcon = useMemo(
    () =>
      userActions.actions &&
      userActions.actions.some(
        (userAct) => userAct.action === "dashboard procon"
      ),
    [userActions]
  );

  const handleMenuClick = (event) => {
    setMenuEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuEl(null);
  };

  const reloadTableau = async (view) => {
    setLoading(true);
    await new Promise((resolve) => setTimeout(resolve, 500));
    setView(view);
    await new Promise((resolve) => setTimeout(resolve, 500));
    setLoading(false);
  };

  useEffect(() => {
    if (hasDashboardProcessosGerais) {
      reloadTableau("");
    } else {
      reloadTableau("_procon");
    }
  }, []);

  return (
    <div>
      <Row>
        <Col md={11}></Col>
        <Col md={1}>
          <Tooltip title="Selecionar Dashboard" placement="left" arrow>
            <div className="menuDashboard">
              <IconButton
                aria-describedby="menulist"
                aria-label="delete"
                size="medium"
                onClick={handleMenuClick}
              >
                <MoreIcon size="small" />
              </IconButton>
              <Popover
                id="menulist"
                open={open}
                anchorEl={menuEl}
                onClose={handleMenuClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <List component="nav" aria-label="menu de ações dos documentos">
                  {hasDashboardProcessosGerais && (
                    <>
                      <ListItem button onClick={(e) => reloadTableau("")}>
                        <ListItemText primary={"Processos gerais"} />
                      </ListItem>
                      <Divider />
                    </>
                  )}
                  {hasDashboardProcon && (
                    <ListItem button onClick={(e) => reloadTableau(`_procon`)}>
                      <ListItemText primary={"Administrativo"} />
                    </ListItem>
                  )}
                </List>
              </Popover>
            </div>
          </Tooltip>
        </Col>
      </Row>
      <Row>{!loading && <Analytics view={view} />}</Row>
    </div>
  );
}
