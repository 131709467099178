import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

import TableCarga from "./TableCarga";

import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Skeleton from "@material-ui/lab/Skeleton";
import Button from "@material-ui/core/Button";

import { Creators as TiposDocumentoActions } from "store/ducks/tiposdocumento";
import { Creators as CargaActions } from "store/ducks/carga";
import Content from "components/Content";

export default function Cargas() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const {
    access: { empresas },
  } = useSelector((state) => state.permissoes);

  const INITIAL_FORM = {
    dataInicial: "",
    dataFinal: "",
    tipoDoc: "",
  };

  const { loading, carga } = useSelector((state) => state.carga);
  const { totalDocs: total, page, limit, sort, search } = carga;

  const [form, setForm] = useState(INITIAL_FORM);

  const { listByEmpresas: tipoDocList, loading: loadingTipoDoc } = useSelector(
    (state) => state.tipoDocumentos
  );

  const canSave = canRequest();

  const {
    access: { servicos },
  } = useSelector((state) => state.permissoes);

  const [userActions, setUserActions] = useState([]);

  useEffect(() => {
    let find = servicos.find((item) => item.route === pathname);
    if (find !== undefined) {
      setUserActions(find);
    }
  }, [pathname, servicos]);

  useEffect(() => {
    dispatch(TiposDocumentoActions.listByEmpresaRequest(empresas));
  }, [empresas]);

  function handleChange(field, value) {
    setForm({
      ...form,
      [field]: value,
    });
  }

  function isValidDate(period) {
    const d = new Date(period);
    return d instanceof Date && !isNaN(d);
  }

  function canRequest() {
    return (
      form.tipoDoc?._id &&
      isValidDate(form.dataInicial) &&
      isValidDate(form.dataFinal)
    );
  }

  function handleChangeRowsPerPage({ target }) {
    if (!canRequest) return true;

    const { value } = target;
    dispatch(
      CargaActions.listRequest({
        page,
        limit: value,
        sort,
        search,
        tipoDoc: form.tipoDoc?._id,
        periodoInicial: form.dataInicial,
        periodoFinal: form.dataFinal,
      })
    );
  }

  function handleChangePage(e, newPage) {
    if (!canRequest) return true;

    dispatch(
      CargaActions.listRequest({
        page: newPage + 1,
        limit: limit,
        sort: sort,
        search: search,
        tipoDoc: form.tipoDoc?._id,
        periodoInicial: form.dataInicial,
        periodoFinal: form.dataFinal,
      })
    );
  }

  function handleSearch() {
    dispatch(
      CargaActions.listRequest({
        page,
        limit,
        sort,
        search,
        tipoDoc: form.tipoDoc?._id,
        periodoInicial: form.dataInicial,
        periodoFinal: form.dataFinal,
      })
    );
  }

  return (
    <Content>
      <Row>
        <Col md="12" className="painel">
          <div className="painel-content">
            <div className="painel-title text-left">
              <Col>
                <strong>Filtros</strong>
              </Col>
            </div>
            <div className="painel-body" style={{ padding: "1.6rem" }}>
              <Row>
                <Col sm={12} md={6}>
                  <TextField
                    required
                    type="date"
                    name="dataInicial"
                    label="Período Inicial"
                    value={form.dataInicial}
                    onChange={(e) =>
                      handleChange("dataInicial", e.target.value)
                    }
                    variant="outlined"
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Col>
                <Col sm={12} md={6}>
                  <TextField
                    required
                    type="date"
                    name="dataFinal"
                    label="Período Final"
                    onChange={(e) =>
                      handleChange("dataFinal", e.target.value)
                    }
                    value={form.dataFinal}
                    variant="outlined"
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col sm={12} md={6}>
                  {loadingTipoDoc ? (
                    <Skeleton width="100%" height={50} />
                  ) : (
                    <Autocomplete
                      options={tipoDocList || []}
                      onChange={(event, newValue) =>
                        handleChange("tipoDoc", newValue)
                      }
                      getOptionLabel={(option) => option.nome || ""}
                      getOptionSelected={(option, value) =>
                        option?._id === value?._id
                      }
                      name="tipoDoc"
                      value={form.tipoDoc}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Tipo de Documento"
                          size="small"
                          variant="outlined"
                          className="mb-4"
                        />
                      )}
                    />
                  )}
                </Col>
                <Col sm={12} md={6}>
                  <Button
                    onClick={handleSearch}
                    size="small"
                    color="primary"
                    variant="contained"
                    disabled={!canSave}
                  >
                    Pesquisar
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md="12" className="painel">
          <div className="painel-content">
            <div className="painel-title text-left">
              <Col>
                <strong>Gerenciamento de cargas</strong>
              </Col>
            </div>
            <div className="painel-body">
              <TableCarga
                page={page || 1}
                carga={carga}
                total={total || 0}
                limit={limit || 10}
                loading={loading}
                userActions={userActions}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </div>
          </div>
        </Col>
      </Row>
    </Content>
  );
}
