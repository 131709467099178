import React, { useState, useEffect } from 'react';

import { Grid, Dialog, DialogActions, DialogContent, Button } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

import Viewer from "components/SearchViewer/Viewer";
import { getPresignedUrl } from "services/presignedUrl";

import './styles.css'
import { Modal, DialogTitle, ColorButton } from './style-dialog'
import { DeleteIcon, FirstPageIcon, LastPageIcon } from 'components/Icons';

function DialogPreviewImages({ imagens, onClose, onRemove, canRemove = true, loadingRemove, ...props }) {

  const [loading, setLoading] = useState(true);
  const [previewURL, setPreviewURL] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [nome, setNome] = useState('');
  const [index, setIndex] = useState(0);
  const [idImagem, setIdImagem] = useState(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!loadingRemove) {
      setIndex(0);
      if (imagens.length > 0) {
        const [imagem] = imagens;
        if (imagem?.filepath) {
          setImageUrl(imagem.filepath);
          setNome(imagem.nome ?? imagem.tipoImagem?.shortname);
          setIdImagem(imagem._id);
        }
      } else {
        if (props.open && onClose) onClose()
      }
    }
  }, [imagens, onClose, props.open, loadingRemove]);

  useEffect(() => {
    async function signedUrl(imageUrl) {
      setLoading(true);
      const preview = await getPresignedUrl(imageUrl);
      setPreviewURL(preview);
      setLoading(false);
    }
    if (imageUrl && props.open) signedUrl(imageUrl)
  }, [imageUrl, props.open]);

  function nextImage() {
    const imagem = imagens[index + 1];
    setImageUrl(imagem.filepath);
    setNome(imagem.nome ?? imagem.tipoImagem?.shortname);
    setIndex(index + 1);
    setIdImagem(imagem._id);
  }

  function previousImage() {
    const imagem = imagens[index - 1];
    setImageUrl(imagem.filepath);
    setNome(imagem.nome ?? imagem.tipoImagem?.shortname);
    setIndex(index - 1);
    setIdImagem(imagem._id);
  }

  function handleConfimRemove() {
    setOpen(false);
    onRemove(idImagem);
  }

  return (
    <>
      <Modal onClose={onClose} fullWidth {...props}>
        <div id="modal-header">
          <DialogTitle className="modal-title">Visualização de Imagem {nome && nome.length > 0 ? `- ${nome}` : ''}</DialogTitle>
        </div>

        <DialogContent>
          {!loading && <Viewer imagem={previewURL} />}
          {!!loading && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "500px",
              }}
            >
              {!!loading && <CircularProgress />}
            </div>
          )}
        </DialogContent>

        <DialogActions>

          <div className="buttons p-2">
            <Grid container spacing={1}>
              <Grid item>
                <Button
                  color="primary"
                  variant="contained"
                  disabled={index === 0 || loadingRemove}
                  onClick={previousImage}
                >
                  <FirstPageIcon />
                </Button>
              </Grid>

              <Grid item>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={() => setOpen(true)}
                  disabled={!canRemove || !onRemove || loadingRemove}
                >
                  {loadingRemove ? <CircularProgress size={20} /> : <DeleteIcon />}
                </Button>
              </Grid>

              <Grid item>
                <Button
                  color="primary"
                  variant="contained"
                  disabled={index === imagens.length - 1 || loadingRemove}
                  onClick={nextImage}
                >
                  <LastPageIcon />
                </Button>
              </Grid>

              {!loading && (
                <Grid item>
                  <ColorButton
                    variant="contained"
                    color="primary"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => window.open(previewURL, "_blank")}
                  >
                    Abrir em uma nova aba
                  </ColorButton>
                </Grid>
              )}
              <Grid item>
                <ColorButton
                  variant="contained"
                  color="secondary"
                  type="reset"
                  onClick={onClose}>
                  Fechar
                </ColorButton>
              </Grid>
            </Grid>

          </div>

        </DialogActions>

      </Modal>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Deseje realmente remover o documento?</DialogTitle>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={handleConfimRemove}
          >
            Sim
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setOpen(false)}
          >
            Não
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default DialogPreviewImages;