import React from 'react';
import { Col } from 'react-bootstrap';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { ListItem, ListItemText } from '@material-ui/core';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';
import { useSelector } from 'react-redux';
import { AssessmentIcon, GridOnIcon, MailOutlineIcon, PeopleIcon, PrintIcon, TimerIcon } from 'components/Icons';

const reports = [
  {
    title: 'Login',
    subtitle: 'Detalhamento de login dos usuários da plataforma',
    tipo: 'login',
    canPrint: true,
    canSheel: true,
    filter: 'auditoria',
    icon: <PeopleIcon />,
    hideInTenant: ['vwbcpag', 'vwbcpag-dev'],
  },
  {
    title: 'Usuários ativos',
    subtitle: 'Detalhamento de usuários ativos',
    tipo: 'usuarios_ativos',
    canPrint: true,
    canSheel: true,
    filter: 'grupoacesso',
    icon: <PeopleIcon />,
    hideInTenant: ['vwbcpag', 'vwbcpag-dev'],
  },
  {
    title: 'Volumetria por indexador',
    subtitle: 'Análise quantitativa de indexadores em um período de tempo customizável',
    tipo: 'manifestacao_volumetria',
    canPrint: true,
    canSheel: true,
    filter: 'documento',
    icon: <AssessmentIcon />,
    hideInTenant: ['vwbcpag', 'vwbcpag-dev'],
  },
  {
    title: 'Etapas concluídas por usuário',
    subtitle: 'Informe um range de data para obter informações de etapas concluídas por usuário',
    tipo: 'etapas_completadas_por_usuario',
    canPrint: true,
    canSheel: false,
    filter: 'auditoria',
    icon: <PeopleIcon />,
    hideInTenant: ['vwbcpag', 'vwbcpag-dev'],
  },
  {
    title: 'Duração média de fluxos finalizados',
    subtitle: 'Informe um range de data para obter informações sobre a média de duração produtiva de cada fluxo',
    tipo: 'tarefas_for_fluxo',
    canPrint: true,
    canSheel: false,
    filter: 'auditoria',
    icon: <TimerIcon />,
    hideInTenant: ['vwbcpag', 'vwbcpag-dev'],
  },
  {
    title: 'Duração média de etapa finalizadas',
    subtitle: 'Informe um range de data para obter informações sobre a média de duração produtiva de cada etapa',
    tipo: 'tarefas_for_etapa',
    canPrint: true,
    canSheel: false,
    filter: 'auditoria',
    icon: <TimerIcon />,
    hideInTenant: ['vwbcpag', 'vwbcpag-dev'],
  },
  {
    title: 'Encerramentos de prontuarios detalhado',
    subtitle: 'Detalhamento de encerramentos detalhado',
    tipo: 'manifestacao_encerramento',
    canPrint: true,
    canSheel: false,
    showSLAFilter: true,
    icon: <AssessmentIcon />,
    filter: 'auditoria',
    hideInTenant: ['vwbcpag', 'vwbcpag-dev'],
  },
  {
    title: 'Encerramentos de prontuarios simplificado',
    subtitle: 'Detalhamento de encerramentos simplificado',
    tipo: 'manifestacao_encerramento_simples',
    showSLAFilter: true,
    canPrint: true,
    canSheel: true,
    icon: <AssessmentIcon />,
    filter: 'auditoria',
    hideInTenant: ['vwbcpag', 'vwbcpag-dev'],
  },
  {
    title: 'Aguardando resposta simplificado',
    subtitle: 'Manifestações aguardando resposta de solicitação de subsídios',
    tipo: 'manifestacao_aguardando_resposta',
    showSLAFilter: false,
    canPrint: true,
    canSheel: true,
    icon: <AssessmentIcon />,
    filter: 'auditoria',
    hideInTenant: ['vwbcpag', 'vwbcpag-dev'],
  },
  {
    title: 'Aguardando resposta detalhado',
    subtitle: 'Manifestações aguardando resposta de solicitação de subsídios',
    tipo: 'manifestacao_aguardando_resposta_detalhado',
    showSLAFilter: false,
    canPrint: true,
    canSheel: true,
    icon: <AssessmentIcon />,
    filter: 'auditoria',
    hideInTenant: ['vwbcpag', 'vwbcpag-dev'],
  },
  {
    title: 'Documentos em aberto',
    subtitle: 'Ações pendentes de documentos em aberto',
    tipo: 'docs_em_aberto',
    showSLAFilter: false,
    canPrint: true,
    canSheel: true,
    icon: <AssessmentIcon />,
    filter: 'apenasdepartamento',
    hideInTenant: ['vwbcpag', 'vwbcpag-dev'],
  },
  {
    title: 'Propostas em andamento',
    subtitle: 'Visão da esteira em produção',
    tipo: 'formalizacao_sla_volks',
    canPrint: false,
    canSheel: true,
    filter: '',
    icon: <PeopleIcon />,
    hideInTenant: [],
    showJustInTenant: ['vwbcpag', 'vwbcpag-dev'],
  },
  {
    title: 'Propostas finalizadas',
    subtitle: 'Análise quantitativa de protocolos finalizados, agrupados por data',
    tipo: 'formalizacao_sla_finalizadas_volks',
    canPrint: false,
    canSheel: true,
    filter: 'apenasdata',
    icon: <PeopleIcon />,
    hideInTenant: [],
    showJustInTenant: ['vwbcpag', 'vwbcpag-dev'],
  },
  {
    title: 'Produtividade por usuário',
    subtitle: 'Relatório de produtivo por usuário',
    tipo: 'formalizacao_produtividade_volks',
    canPrint: false,
    canSheel: true,
    filter: 'apenasdata',
    icon: <PeopleIcon />,
    hideInTenant: ['vwbcpag', 'vwbcpag-dev'],
  },
  {
    title: 'Produtividade por usuário - quantitativo',
    subtitle: 'Relatório de produtividade por usuário - Quantitativo',
    tipo: 'formalizacao_produtividade_quantitativo_volks',
    canPrint: false,
    canSheel: true,
    filter: 'apenasdata',
    icon: <PeopleIcon />,
    hideInTenant: ['vwbcpag', 'vwbcpag-dev'],
  },
  {
    title: 'Propostas - Base carga pagas',
    subtitle: 'Informações completas relacionadas a propostas pagas',
    tipo: 'formalizacao_dashboard_volks',
    canPrint: false,
    canSheel: true,
    filter: 'apenasdata',
    icon: <PeopleIcon />,
    hideInTenant: [],
    showJustInTenant: ['vwbcpag', 'vwbcpag-dev'],
  },
  {
    title: 'Relatório de Storage',
    subtitle: 'Imagens, vídeos ou qualquer outra midia armazenada em documentos',
    tipo: 'storage',
    showSLAFilter: false,
    canPrint: false,
    canSheel: true,
    icon: <AssessmentIcon />,
    filter: 'departamento_indexadores',
    hideInTenant: ['vwbcpag', 'vwbcpag-dev'],
  },
  {
    title: 'Histórico de pendências',
    subtitle: 'Informações de propostas finalizadas e que contém pendências no checklist',
    tipo: 'historico_pendencias',
    showSLAFilter: false,
    canPrint: false,
    canSheel: true,
    icon: <AssessmentIcon />,
    filter: 'apenasdata',
    hideInTenant: [],
    showJustInTenant: ['vwbcpag', 'vwbcpag-dev'],
  },
  {
    title: 'Relatório de Indexação',
    subtitle: 'Documentos indexados no período',
    tipo: 'indexacao',
    showSLAFilter: false,
    canPrint: false,
    canSheel: true,
    icon: <AssessmentIcon />,
    filter: 'departamento_indexadores',
    hideInTenant: ['vwbcpag', 'vwbcpag-dev'],
  },
  {
    title: 'Relatório de Tipificação',
    subtitle: 'Arquivos tipificados no período',
    tipo: 'indexacao',
    showSLAFilter: false,
    canPrint: false,
    canSheel: true,
    icon: <AssessmentIcon />,
    filter: 'departamento_indexadores',
    hideInTenant: ['vwbcpag', 'vwbcpag-dev'],
  },
  {
    title: 'Volumetria',
    subtitle: 'Análise quantitativa por período de tempo',
    tipo: 'formalizacao_volumetria_volks',
    canPrint: false,
    canSheel: true,
    filter: 'apenasdata',
    icon: <AssessmentIcon />,
    hideInTenant: [],
    showJustInTenant: ['vwbcpag', 'vwbcpag-dev'],
  },
  {
   title: 'Produtividade usuário - analítica/sintética',
   subtitle: 'Visão analítica/sintética da produtividade por usuário',
   tipo: 'produtividade_usuario_volks',
   canPrint: false,
   canSheel: true,
   filter: 'dataAndoptions',
   icon: <AssessmentIcon />,
   hideInTenant: [],
   showJustInTenant: ['vwbcpag', 'vwbcpag-dev'],
  },
];

function Report({ searchText, printReport, canSubmit }) {
  const { organization } = useSelector(state => state.usuarios.auth);
  return (
    <>
      {reports
        .filter(rep => rep?.title.includes(searchText || ''))
        .filter(rep => !rep?.hideInTenant.includes(organization))
        .filter(rep => rep?.showJustInTenant ? rep.showJustInTenant.includes(organization) : true)
        .sort((a, b) => (a?.title > b?.title ? 1 : -1))
        .map(rep => (
          <Col lg={12} md={12} sm={12} key={rep.title} style={{ color: '#444444' }}>
            <div className="dados-section">
              <ListItem md={2}>
                <ListItemAvatar>
                  <Avatar style={{ color: 'black' }} variant="rounded">
                    {rep.icon}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={rep.title} secondary={rep.subtitle} />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="excel"
                    onClick={e =>
                      printReport({
                        tipo: rep.tipo,
                        isExcel: true,
                        filter: rep.filter,
                        showSLAFilter: rep.showSLAFilter || false,
                      })
                    }
                    disabled={!rep.canSheel || !canSubmit}
                  >
                    <GridOnIcon />
                  </IconButton>
                  <IconButton
                    edge="end"
                    aria-label="print"
                    onClick={e =>
                      printReport({
                        tipo: rep.tipo,
                        isExcel: false,
                        filter: rep.filter,
                        showSLAFilter: rep.showSLAFilter || false,
                      })
                    }
                    disabled={!rep.canPrint || !canSubmit}
                  >
                    <PrintIcon />
                  </IconButton>
                  <IconButton
                    edge="end"
                    aria-label="print"
                    onClick={e =>
                      printReport({
                        tipo: rep.tipo,
                        canPrint: rep.canPrint,
                        canSheel: rep.canSheel,
                        sendAsMail: true,
                        filter: rep.filter,
                        showSLAFilter: rep.showSLAFilter || false,
                      })
                    }
                    disabled={!rep.canPrint && !canSubmit}
                  >
                    <MailOutlineIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            </div>
          </Col>
        ))}
    </>
  );
}

export default Report;
