import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format, parseISO } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';

import {
  Content,
  Header,
  BasicInfo,
  InfoBar,
  Title,
  TaskType,
  TaskDoc,
  StatusInfo,
  DateStatus,
  DateStart,
  DateEnd,
  CompletionStatus,
  PriorityStatus,
  UsersList,
  AprovacaoStatus,
} from './styles/ModalTaskDetails';

import DefaultModal from 'components/DefaultModal';
import TabModalTaskDetails from 'components/TabModalTaskDetails';
import UserAvatar from 'components/UserAvatar';

import { default as Actions } from 'components/ModalTaskDetailsActions';

import { TASK_DATE_STATUS_COLORS } from 'constants/task-colors';
import priorityIcons from 'constants/priority-icons';

import { Creators as DocumentosActions } from 'store/ducks/documentos';
import { defaultQueryParams } from 'constants/default-query-params';
import { ErrorIcon } from 'components/Icons';

function ModalTaskDetails({ open, handleClose }) {
  const dispatch = useDispatch();
  const { selectedTask: task } = useSelector((state) => state.providencias);

  const {
    titulo,
    dateStatus,
    participantes,
    dataInicio,
    vencimento,
    tipoTarefa,
    prioridade,
    comentario,
    documento,
    diaTodo,
  } = task;

  function handleClickProcesso() {
    dispatch(
      DocumentosActions.sendToSearchPageRequest(
        [task.documento._id],
        defaultQueryParams,
        true
      )
    );
    handleClose();
  }

  function getFormattedDate(date, fullDay) {
    const [onlyDate] = date.split('T');

    const formattedDate = format(parseISO(onlyDate), "iii, d 'de' MMMM, yyyy", {
      locale: pt,
    });

    if (fullDay) {
      return `${formattedDate}, o dia inteiro`;
    }

    const formattedHour = format(parseISO(date), 'p', { locale: pt });

    return `${formattedDate} - ${formattedHour}`;
  }

  function getAprovacaoStatus(task) {
    const status = task.hasOwnProperty('aprovado')
      ? task.aprovado
        ? 'ENCERRADA'
        : 'EM ANDAMENTO'
      : 'EM ANDAMENTO';

    return <AprovacaoStatus status={status}>{status}</AprovacaoStatus>;
  }

  return (
    <>
      <DefaultModal noActions open={open} handleClose={handleClose}>
        <Content>
          <Header>
            <BasicInfo>
              <Title>{titulo}</Title>
              <TaskType>{tipoTarefa.nome}</TaskType>
              {documento && (
                <TaskDoc onClick={handleClickProcesso}>
                  <span>Número do cliente </span>
                  {documento.numeroCNJ}
                </TaskDoc>
              )}
            </BasicInfo>
            <DateStatus>
              <DateStart>
                <span>Início</span>{' '}
                <span>{getFormattedDate(dataInicio, diaTodo)}</span>
              </DateStart>
              <DateEnd>
                <span>Vencimento</span>{' '}
                <span>{getFormattedDate(vencimento, diaTodo)}</span>
              </DateEnd>
            </DateStatus>
          </Header>
          <InfoBar>
            <StatusInfo>
              <CompletionStatus>
                <ErrorIcon
                  style={{ color: TASK_DATE_STATUS_COLORS[dateStatus] }}
                />
                {dateStatus}
              </CompletionStatus>
              <PriorityStatus>
                <img
                  src={priorityIcons[prioridade]}
                  alt={`Prioridade ${prioridade}`}
                />
                Prioridade {prioridade}
              </PriorityStatus>
            </StatusInfo>
            <UsersList>
              {participantes.map((participante) => (
                <UserAvatar key={participante._id} user={participante} />
              ))}
            </UsersList>
          </InfoBar>
          <InfoBar>
            <Actions handleClose={handleClose} task={task} />
            {getAprovacaoStatus(task)}
          </InfoBar>
          <TabModalTaskDetails taskId={task._id} comentario={comentario} />
        </Content>
      </DefaultModal>
    </>
  );
}

export default ModalTaskDetails;
