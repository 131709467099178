import api from "services/api";
import { getToken } from "services/auth";
import { call, select, put, all, takeLatest } from "redux-saga/effects";
import { toastr } from "react-redux-toastr";
import { push } from "connected-react-router";

import { Creators as LayoutActions, Types } from "../ducks/layout";

export const getOrganization = (state) => state.usuarios.auth.organization;
export const getUserId = (state) => state.usuarios.auth.id;

export function* listLayout({ payload }) {
  try {
    const organization = yield select(getOrganization);

    const { page, limit } = payload;

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
      params: { page, limit },
    };

    const response = yield call(api.get, `layout`, headerParams);

    const data = { layout: response.data || [] };

    yield put(LayoutActions.listSuccess(data));
  } catch (error) {
    toastr.error("Ops", "Não foi possível listar os layouts.");
  }
}

export function* getLayout(action) {
  try {
    const headerParams = {
      headers: { Authorization: getToken() },
    };

    const { data } = yield call(
      api.get,
      `layout/${action.payload}`,
      headerParams
    );

    yield put(LayoutActions.getSuccess(data));
  } catch (error) {
    toastr.error("Ops", "Não foi possível buscar os dados do Layout.");
  }
}

export function* editLayout(action) {
  const userId = yield select(getUserId);

  try {
    const organization = yield select(getOrganization);

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const body = { ...action.payload, id_user_acao: userId };

    const { data } = yield call(
      api.put,
      `layout/${action.payload._id}`,
      body,
      headerParams
    );

    yield put(LayoutActions.editSuccess(data));

    toastr.success("Layout editado com sucesso.");
  } catch (error) {
    toastr.error("Ops", "Não foi possível editar o Layout.");
  }
}

export function* createLayout(action) {
  const organization = yield select(getOrganization);

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const { data } = yield call(
      api.post,
      `layout`,
      action.payload,
      headerParams
    );

    yield put(LayoutActions.createSuccess(data));

    yield put(push(`/layouts`));

    toastr.success("", "Layout criado com sucesso.");
  } catch (error) {
    toastr.error("Ops", "Não foi possível criar o Layout.");
  }
}

export default all([
  takeLatest(Types.LIST_REQUEST, listLayout),
  takeLatest(Types.GET_REQUEST, getLayout),
  takeLatest(Types.EDIT_REQUEST, editLayout),
  takeLatest(Types.NEW_REQUEST, createLayout),
]);
