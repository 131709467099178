import styled, { css } from 'styled-components';
import { Button as MaterialButton } from '@material-ui/core';


export const Container = styled.div`
    background-color: #fff;
    border-radius: 4px;
    margin-top: 20px;
  
      
`;
export const Wrapper = styled.div`
    position: relative;
    background-color: #fff;
    padding: 4px;
    border-radius: 4px;

    ${props =>
        props.isFocused &&
        css`
            margin-bottom: 45px;
        `}

    .toolbar-class {
        position: absolute;
        background-color: #fff;
        padding: 4px;
        border: 1px solid #ccc;
        top: -45px;
        z-index: 11;
        visibility: hidden !important;
        ${props =>
            props.isFocused &&
            css`
                visibility: visible !important;
            `}
    }
    &::before {
        position: absolute;
        top: 0;
        left: -32px;
        width: 32px;
    }
    .editor-class {
        margin: 0 5px;
        overflow: hidden;
        .public-DraftStyleDefault-block {
            margin: 0px;
        }
    }
`;

export const Menu = styled.div`
    position: absolute;
    bottom: -48px;
    right: 0;
    z-index: 10;
    pointer-events: true;
    background-color: transparent;

    ${({ isFocused }) =>
        !isFocused &&
        css`
            opacity: 0;
            pointer-events: none;
        `};
`;

export const Button = styled(MaterialButton)`
    &:not(:last-of-type) {
        margin-right: 8px;
    }

    svg {
        margin-right: 8px;
    }
`;
