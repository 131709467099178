import api from "../../services/api";
import { getToken } from "../../services/auth";
import { put, call } from "redux-saga/effects";
import { toastr } from "react-redux-toastr";
import { select } from "redux-saga/effects";

import { Creators as FilaActions } from "../ducks/filasDetalhes";

export const getOrganization = (state) => state.usuarios.auth.organization;

export function* listFilaDetails({ payload }) {
  try {
    const organization = yield select(getOrganization);

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const { data: { data, updatedAt } } = yield call(api.get, "fluxos-detalhes", headerParams);

    const resp = {
      list: data,
      total: data.length,
      updatedAt: updatedAt,
    };

    yield put(FilaActions.listDetailsSuccess(resp));
  } catch (error) {
    console.error(error);
    toastr.error("Ops", "Não foi possível listar as filas");
  }
}

export function* exportTasks({ payload }) {
  try {

    const organization = yield select(getOrganization);
    const { option, etapa } = payload;
    const exportTasks = option.includes('TASKS');

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
      params: { option, etapa },
    };

    toastr.success("", `Exportação de ${exportTasks ? "protocolos" : "acompanhamento"} agendada com sucesso.`);

    const { data } = yield call(api.get, `fluxos-detalhes/export`, headerParams);

    if (data.error) {
      toastr.error("", data.message);
      if (exportTasks) yield put(FilaActions.exportDetailsTasksSuccess());
      else yield put(FilaActions.exportDetailsSuccess());
      return
    }

    if (exportTasks) yield put(FilaActions.exportDetailsTasksSuccess(data));
    else yield put(FilaActions.exportDetailsSuccess(data));

  } catch (error) {
    console.error(error);
    toastr.error("Ops", "Não foi possivel fazer a exportação de acompanhamentos.");
  }
}