export const Types = {
  GET_REDIRECT_LOGIN_REQUEST: "redirectlogin/GET_REDIRECT_LOGIN_REQUEST",
  GET_REDIRECT_LOGIN_SUCCESS: "redirectlogin/GET_REDIRECT_LOGIN_SUCCESS",
};

const INITIAL_STATE = {
  loading: false,
  data: {},
};

export default function translate(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_REDIRECT_LOGIN_REQUEST:
      return { ...state, loading: true };

    case Types.GET_REDIRECT_LOGIN_SUCCESS:
      return { ...state, loading: false, data: action.payload };

    default:
      return state;
  }
}

export const Creators = {
  getRedirectLoginRequest: () => ({
    type: Types.GET_REDIRECT_LOGIN_REQUEST,
  }),

  getRedirectLoginSuccess: (data) => ({
    type: Types.GET_REDIRECT_LOGIN_SUCCESS,
    payload: data,
  }),
};
