import React, { useState, useEffect } from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";

import Viewer from "components/SearchViewer/Viewer";
import { getPresignedUrl } from "services/presignedUrl";

function DialogPreviewImage({ imageUrl, onClose, ...props }) {

  const [loading, setLoading] = useState(true);
  const [previewURL, setPreviewURL] = useState('');

  useEffect(() => {
    async function signedUrl(imageUrl) {
      setLoading(true);
      const preview = await getPresignedUrl(imageUrl);
      setPreviewURL(preview);
      setLoading(false);
    }
    if (imageUrl) signedUrl(imageUrl)
  }, [imageUrl])

  return (
    <Dialog onClose={onClose} fullWidth {...props}>
      <DialogTitle>
        <strong>Visualização de Imagem</strong>
      </DialogTitle>
      <DialogContent>
        {!loading && <Viewer imagem={previewURL} />}
        {!!loading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "500px",
            }}
          >
            {!!loading && <CircularProgress />}
          </div>
        )}
      </DialogContent>
      <DialogActions>
        {!loading && (
          <a target="_blank" rel="noopener noreferrer" href={previewURL}>
            Abrir em uma nova aba
          </a>
        )}
        <Button onClick={onClose}>Fechar</Button>
      </DialogActions>
    </Dialog>
  );
}

export default DialogPreviewImage;