export const Types = {
  NEW_REQUEST: "productivity/NEW_REQUEST",
  NEW_SUCCESS: "productivity/NEW_SUCCESS",
  CLEAR: "productivity/CLEAN",
  EXPORT_REQUEST: "productivity/EXPORT_REQUEST",
  EXPORT_SUCCESS: "productivity/EXPORT_SUCCESS",
};

/* Reducer */
const INITIAL_STATE = {
  exportacao: null,
  data: null,
  loading: false,
  loadingExport: false,
  totalPages: 0,
  totalDocs: 0,
  limit: 10,
  page: 0,
};

export default function items(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.CLEAR:
      return { ...state, loading: false, data: null };

    case Types.NEW_REQUEST:
      return { ...state, loading: true };

    case Types.NEW_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action?.payload?.data,
        totalPages: action?.payload?.totalPages,
        totalDocs: action?.payload?.totalDocs,
        limit: action?.payload?.limit,
        page: action?.payload?.page,
      };

    case Types.EXPORT_REQUEST:
      return { ...state, loadingExport: true };

    case Types.EXPORT_SUCCESS:
      return { ...state, loadingExport: false, exportacao: action?.payload };

    default:
      return state;
  }
}

export const Creators = {
  createRequest: (dashboardscalingpending) => ({
    type: Types.NEW_REQUEST,
    payload: dashboardscalingpending,
  }),

  createSuccess: (dashboardscalingpending) => ({
    type: Types.NEW_SUCCESS,
    payload: dashboardscalingpending,
  }),

  clearRequest: () => ({
    type: Types.CLEAR,
    payload: null,
  }),

  exportRequest: (data) => ({
    type: Types.EXPORT_REQUEST,
    payload: data,
  }),

  exportSuccess: (data) => ({
    type: Types.EXPORT_SUCCESS,
    payload: data,
  }),
};
