export const Types = {
  LIST_REQUEST: 'naturezas/LIST_REQUEST',
  LIST_SUCCESS: 'naturezas/LIST_SUCCESS',
  SAVE_REQUEST: 'naturezas/SAVE_REQUEST',
  LIST_REQUEST_ENUM: 'naturezas/LIST_REQUEST_ENUM',
  LIST_SUCCESS_ENUM: 'naturezas/LIST_SUCCESS_ENUM',
  LIST_ERROR_ENUM: 'naturezas/LIST_ERROR_ENUM'
}

const INITIAL_STATE = {
  list: [],
  loading: false,
  page: 1,
  limit: 10,
  total: 0,
  search: '',
  sort: 'nome',
  enum: {}
}

export default function naturezas(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.LIST_REQUEST:
      return {
        ...state,
        ...action.payload,
        list: [],
        loading: true
      }

    case Types.LIST_SUCCESS:
      return {
        ...state,
        total: action.payload.total,
        list: action.payload.list,
        loading: false
      }

    case Types.LIST_REQUEST_ENUM:
      return { ...state, loading: true };

    case Types.LIST_SUCCESS_ENUM:
      return {
        ...state,
        loading: false,
        enum: action.payload.enum
      };

    case Types.LIST_ERROR_ENUM:
      return {
        ...state,
        loading: false
      }

    default:
      return state;
  }
}

export const Creators = {
  listRequest: params => ({
    type: Types.LIST_REQUEST,
    payload: params
  }),
  listSuccess: (list, total) => ({
    type: Types.LIST_SUCCESS,
    payload: { list, total }
  }),
  saveRequest: payload => ({
    type: Types.SAVE_REQUEST,
    payload
  }),

  listRequestEnum: () => ({
    type: Types.LIST_REQUEST_ENUM,
  }),
  listSuccessEnum: data => ({
    type: Types.LIST_SUCCESS_ENUM,
    payload: { enum: data }
  }),
  listErrorEnum: () => ({
    type: Types.LIST_ERROR_ENUM
  })

}