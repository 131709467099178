import React, { useRef, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import Button from "@material-ui/core/Button";
import IndexadoresBulk from "components/IndexadoresBulk";
import CircularProgress from "@material-ui/core/CircularProgress";

import { Creators as AnaliseActions } from "store/ducks/analise";

import "./styles.css";

export default function ModalBulkTaskMove({
  documentos = [],
  selecionadas,
  handleStay,
  showModal,
}) {
  const dispatch = useDispatch();
  const sendClicked = useRef(false);
  const { loading } = useSelector((state) => state.documentos);

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [indexadores, setIndexadores] = useState(null);

  const onSubmit = () => {
    if (!showConfirmation) {
      setShowConfirmation(true);
      return;
    }

    console.log("Enviando requisição...")

    let documentosSelecionados = documentos.filter((doc) =>
      selecionadas.some((selecionada) => selecionada === doc.id)
    );

    documentosSelecionados = documentosSelecionados.map((doc) => {
      return { id: doc.id, documento: doc.documento };
    });

    const payload = { taskAndDocs: documentosSelecionados, indexadores };

    dispatch(AnaliseActions.createBulkRequest(payload));
    handleStay();
  };

  useEffect(() => {
    console.log("indexadores", indexadores)
  }, [indexadores])

  useEffect(() => {
    if (!loading && sendClicked.current === true) {
      handleStay();
      sendClicked.current = false;
    }
  }, [loading, handleStay]);

  return (
    <Modal show={showModal} onHide={handleStay} centered size="xl">
      <Modal.Header>
        <Modal.Title>Mover tarefas em massa</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!showConfirmation ? (
          <IndexadoresBulk
            indexadores={indexadores}
            setIndexadores={setIndexadores}
            selecionadas={selecionadas}
            documentos={documentos}
          />) : (
          <>
            {indexadores.length === 0 ? (
              <>
                <p>Deseja mover as análises selecionadas?</p>
              </>
            ) : (
              <>
                <p>Você selecionou as seguintes respostas:</p>
                <table width={"100%"} border="1" className="esteira-avancar-table">
                  <thead>
                    <tr>
                      <th>Indexador</th>
                      <th>Resposta</th>
                    </tr>
                  </thead>
                  <tbody>
                    {indexadores.map((indexador) => (
                      <tr key={indexador.id}>
                        <td>{indexador.nome}</td>
                        <td><strong>{indexador.valor}</strong></td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <br />
                <p>Deseja mover as análises com essas informações selecionadas?</p>
              </>)}

          </>
        )}

      </Modal.Body>
      <Modal.Footer>
        <div className="white-loading">
          <Button
            variant="contained"
            color="primary"
            onClick={onSubmit}
            disabled={loading}
          >
            {!showConfirmation ? "Prosseguir" : "Confirmar"}
            {!!loading && <CircularProgress size={20} />}
          </Button>
        </div>

        <div>
          <Button variant="contained" color="secondary" onClick={() => { setShowConfirmation(false); handleStay() }}>
            Fechar
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
