import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import BackButton from "components/BackButton";
import InputForm from "components/InputForm";

import { Grid, Button, CircularProgress } from "@material-ui/core";

import { Row, Col } from "react-bootstrap";
import { Form } from "@unform/web";
import { ValidationError } from "yup";

import TipoDepartamentoSchema from "validators/TipoDepartamentoSchema";
import { Creators as TipoDepartamentosActions } from "store/ducks/tipodepartamento";

import "./styles.css";
import Content from "components/Content";

const INITIAL_STATE = {
    nome: '',
};

function Line({ children }) {
    return (
        <Row className="row">
            <Col md={6}>
                {children}
            </Col>
        </Row>
    )
}

export default function EditTipoDepartamento(params) {

    const dispatch = useDispatch();
    const formRef = useRef(INITIAL_STATE);

    const { tipoDepartamento } = params.location.state;

    const { loading } = useSelector((state) => state.tipodepartamento);

    const onSubmit = async (values) => {

        const body = {
            _id: tipoDepartamento._id,
            ...values,
        };

        try {
            await TipoDepartamentoSchema.validate(body, {
                abortEarly: false,
            });

            dispatch(TipoDepartamentosActions.editRequest(body));

        } catch (err) {
            const validationErrors = {};
            if (err instanceof ValidationError) {
                err.inner.forEach((error) => {
                    validationErrors[error.path] = error.message;
                });
                formRef.current.setErrors(validationErrors);
            }
        }
    };

    return (
        <Content>
            <Row>
                <Col md="12" className="painel">
                    <div className="painel-content">
                        <div className="painel-title text-left">Editar Tipo de departamento</div>
                        <div className="painel-body">
                            <Form
                                ref={formRef}
                                onSubmit={onSubmit}
                                initialData={{ ...tipoDepartamento }}
                            >

                                <Line>
                                    <InputForm name="nome" label="Nome" />
                                </Line>

                                <Row>
                                    <Grid container spacing={1}>
                                        <Grid item>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                type="submit"
                                                disabled={loading}
                                            >
                                                Enviar
                                                {!!loading && <CircularProgress size={20} />}
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <BackButton variant="contained" color="secondary">
                                                Voltar
                                            </BackButton>
                                        </Grid>
                                    </Grid>
                                </Row>
                            </Form>
                        </div>
                    </div>
                </Col>
            </Row>
        </Content>
    );
}
