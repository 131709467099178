import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Creators as TemplateActions } from "../../../store/ducks/template";
import Utils from "../../../utils/utils";

import "../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "ckeditor5-build-full";

import {
  Container,
  Wrapper,
  Label,
  Menu,
  Button,
} from "./styles/EditorParagraph";
import { DeleteIcon, SaveOutlinedIcon } from "components/Icons";

function EditorParagraph({ item = {}, index = false }) {
  const dispatch = useDispatch();

  const [CK5Ref, setCK5Ref] = useState(null);
  const [stateCK5, setStateCK5] = useState(item.paragraph);

  const [isFocused, setIsFocused] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);

  const { variable, currentEditor } = useSelector((state) => state.template);

  useEffect(() => {
    if (!!Object.keys(variable).length) {
      if (item.id_editor === currentEditor) {
        if (CK5Ref) {
          CK5Ref.model.change((writer) => {
            const insertPosition =
              0 || CK5Ref.model.document.selection.getFirstPosition();
            writer.insertText(`${variable}`, insertPosition);
          });
        } else {
          setStateCK5(stateCK5 + `${variable}`);
        }
      }
    }
  }, [variable]);

  const handleEditorChangeCK5 = (event, editor) => {
    setStateCK5(editor.getData());
  };

  const handleIsFocus = (editor) => {
    setCK5Ref(editor);
    handleSave();
    const { id_editor, id } = item;

    dispatch(TemplateActions.currenteEditor(id_editor));
    dispatch(
      TemplateActions.editParagraphTemplate({
        currentContent: stateCK5,
        id_editor,
        id,
      })
    );
    setIsFocused(true);
  };

  const handleDelete = () => {
    dispatch(TemplateActions.deleteParagraphTemplate(item.id_editor));
  };

  const confirmButtonDelete = async () => {
    setConfirmDelete(true);
    setIsFocused(true);

    await setTimeout(() => setConfirmDelete(false), 2000);
  };

  const handleSave = () => {
    setIsFocused(false);
    const { id_editor, id } = item;
    dispatch(
      TemplateActions.editParagraphTemplate({
        currentContent: stateCK5,
        id_editor,
        id,
      })
    );
  };

  return (
    <>
      <Container isFocused={isFocused}>
        <Wrapper
          onBlur={handleSave}
          isFocused={isFocused}
          onFocus={handleIsFocus}
        >
          <Label>
            <span>{Utils.cropTooltipText(item.title && item.title)}</span>
          </Label>

          <CKEditor
            data={stateCK5}
            editor={ClassicEditor}
            onChange={handleEditorChangeCK5}
            onFocus={(event, editor) => handleIsFocus(editor)}
          />

          <Menu isFocused={isFocused}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={handleSave}
            >
              <SaveOutlinedIcon style={{ color: "white" }} />
              Salvar
            </Button>
            {confirmDelete ? (
              <Button
                variant="contained"
                color="secondary"
                size="small"
                onClick={handleDelete}
              >
                <DeleteIcon style={{ color: "white" }} />
                Clique Novamente
              </Button>
            ) : (
              <Button
                variant="contained"
                color="secondary"
                size="small"
                onClick={confirmButtonDelete}
              >
                <DeleteIcon style={{ color: "white" }} />
                Excluir
              </Button>
            )}
          </Menu>
        </Wrapper>
      </Container>
    </>
  );
}

export default EditorParagraph;
