import api from 'services/api';
import { getToken } from 'services/auth';
import { call, select, put, all, takeLatest } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';

import { Creators as ProcessoActions, Types } from 'store/ducks/processo';

export const getOrganization = state => state.usuarios.auth.organization;

export function* getProcesso({ payload }) {
  const organization = yield select(getOrganization);
  const { numeroCNJ } = payload;

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const { data } = yield call(api.get, `processos/${numeroCNJ}`, headerParams);

    yield put(ProcessoActions.getSuccess(data))
  } catch(err) {
    yield put(ProcessoActions.getError());
    toastr.error('Ops', 'Não foi possível recuperar o processo')
  }
}

export default all([
  takeLatest(Types.GET_REQUEST, getProcesso)
])