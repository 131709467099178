import React, { useState, useEffect, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TableFooter,
  TablePagination,
} from "@material-ui/core";
import { Link, useLocation } from "react-router-dom";
import StyledTableRow from "components/StyledTableRow";
import LoadingTable from "components/LoadingTable";
import CustomTableSortLabel from "components/CustomTableSortLabel";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import ButtonSquare from "components/ButtonSquare";

import { Creators as EmpresasActions } from 'store/ducks/empresas';

function TableEmpresas() {
  const dispatch = useDispatch();

  const { pathname } = useLocation();

  const {
    list: empresas,
    loading,
    page = 1,
    total = 0,
    limit = 10,
    sort = "fantasia",
  } = useSelector(state => state.empresas);
  
  const {
    access: { servicos },
  } = useSelector(state => state.permissoes);

  const [userActions, setUserActions] = useState([]);
  const [request, setRequest] = useState({ page, limit, sort });

  useEffect(() => { 
    dispatch(EmpresasActions.listRequest(request));
  }, [dispatch, request])

  useEffect(() => {
    let find = servicos.find(item => item.route === pathname);
    if (find !== undefined) {
      setUserActions(find);
    }
  }, [pathname, servicos]);

  function handleChangeRowsPerPage({ target }) {
    const { value: limit } = target;
    setRequest({ ...request, limit, page: 1 });
  }

  function handleChangePage(e, newPage) {
    setRequest({ ...request, page: newPage + 1 });
  }

  function handleChangeSort(sort) {
    setRequest({ ...request, sort });
  }

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>
              <CustomTableSortLabel
                onClick={handleChangeSort}
                currentSort={request.sort}
                sortField="fantasia"
              >
                Fantasia
              </CustomTableSortLabel>
            </TableCell>
            <TableCell>
              <CustomTableSortLabel
                onClick={handleChangeSort}
                currentSort={request.sort}
                sortField="grupoEmpresarial.nome"
              >
                Grupo Empresarial
              </CustomTableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel>CNPJ</TableSortLabel>
            </TableCell>
            <TableCell>
              <CustomTableSortLabel
                onClick={handleChangeSort}
                currentSort={request.sort}
                sortField="razaoSocial"
              >
                Razão Social
              </CustomTableSortLabel>
            </TableCell>
            <TableCell>
              <CustomTableSortLabel
                onClick={handleChangeSort}
                currentSort={request.sort}
                sortField="tipoPessoa"
              >
                Tipo Pessoa
              </CustomTableSortLabel>
            </TableCell>
            <TableCell>
              <CustomTableSortLabel
                onClick={handleChangeSort}
                currentSort={request.sort}
                sortField="tipoPagamento"
              >
                Tipo Pagamento
              </CustomTableSortLabel>
            </TableCell>
            <TableCell>
              <CustomTableSortLabel
                onClick={handleChangeSort}
                currentSort={request.sort}
                sortField="usuarioAlteracao.nome"
              >
                Usuário Alteração
              </CustomTableSortLabel>
            </TableCell>
            <TableCell align="center">
              <CustomTableSortLabel
                onClick={handleChangeSort}
                currentSort={request.sort}
                sortField="status"
              >
                Status
              </CustomTableSortLabel>
            </TableCell>
            {userActions.actions &&
              userActions.actions.map(
                (userAct, index) =>
                  userAct.action === "editar" && (
                    <TableCell align="center" key={index}>
                      Ações
                    </TableCell>
                  )
              )}
          </TableRow>
        </TableHead>
        <TableBody>
          {!!loading && <LoadingTable rows={request.limit} columns={8} />}
          {!loading &&
            empresas &&
            empresas.length > 0 &&
            empresas.map(item => (
              <StyledTableRow key={item._id}>
                <TableCell>{item.fantasia}</TableCell>
                <TableCell>
                  {item.grupoEmpresarial ? item.grupoEmpresarial.nome : ""}
                </TableCell>
                <TableCell>{item.cnpj || ""}</TableCell>
                <TableCell>{item.razaoSocial}</TableCell>
                <TableCell>{item.tipoPessoa}</TableCell>
                <TableCell>{item.tipoPagamento}</TableCell>
                <TableCell>
                  {item.usuarioAlteracao &&
                    (item.usuarioAlteracao.nome === ""
                      ? "Usuário não registrado"
                      : item.usuarioAlteracao.nome)}
                </TableCell>
                <TableCell align="center">
                  {item.status === "true" ? (
                    <span className="status-active">Ativo</span>
                  ) : (
                    <span className="status-inactive">Inativo</span>
                  )}
                </TableCell>
                {userActions.actions &&
                  userActions.actions.map(
                    (userAct, index) =>
                      userAct.action === "editar" && (
                        <TableCell align="center" key={index}>
                          <Link
                            to={{
                              pathname: "/administracao/empresas/edit",
                              state: { Empresa: item },
                            }}
                            className="linkicon"
                            id="empresas"
                          >
                            <ButtonSquare icon="edit" />
                          </Link>
                        </TableCell>
                      )
                  )}
              </StyledTableRow>
            ))}
        </TableBody>
        {!loading && (
          <TableFooter>
            <TableRow>
              <TablePagination
                count={total}
                page={request.page - 1}
                rowsPerPage={request.limit}
                rowsPerPageOptions={[10, 25, 50, 100]}
                labelRowsPerPage="Linhas por página"
                labelDisplayedRows={({ from, to, count }) =>
                  `${from}-${to} de ${count !== -1 ? count : `mais que ${to}`}`
                }
                onRowsPerPageChange={handleChangeRowsPerPage}
                onPageChange={handleChangePage}
              />
            </TableRow>
          </TableFooter>
        )}
      </Table>
    </TableContainer>
  );
}

export default memo(TableEmpresas);
