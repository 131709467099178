import { call, put } from "redux-saga/effects";

import { toastr } from "react-redux-toastr";
import api from "../../services/api";
import { getToken } from "../../services/auth";

import { Creators as TipoDocActions } from "../ducks/tiposdocumento";
import { select } from "redux-saga/effects";
import { push } from "connected-react-router";

export const getOrganization = (state) => state.usuarios.auth.organization;
export const getUserId = (state) => state.usuarios.auth.id;
export const getEmpresas = (state) => state.permissoes.access.empresas;

export function* listTipoDocs({ payload }) {
  try {
    const organization = yield select(getOrganization);

    const { empresas, page, limit, sort } = payload;

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
      params: { page, limit, sort },
    };

    const response = yield call(
      api.post,
      `tipodocumentos`,
      empresas,
      headerParams
    );
    const data = {
      list: response.data.docs,
      total: response.data.total,
    };
    yield put(TipoDocActions.listSuccess(data));
  } catch (error) {
    toastr.error("Ops", "Não foi possível listar os Tipos de Documento.");
  }
}

export function* listTipoDocsByEmpresas(action) {
  try {
    const organization = yield select(getOrganization);

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const { data } = yield call(
      api.get,
      `tipodocumentos/empresa/${action.payload}`,
      headerParams
    );

    yield put(TipoDocActions.listByEmpresaSuccess(data));
  } catch (error) {
    toastr.error(
      "Ops",
      "Não foi possível listar os Tipos de Documento do Departamento."
    );
  }
}

export function* removeTiposDocs(action) {
  try {
    const organization = yield select(getOrganization);
    const empresas = yield select(getEmpresas);
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const {
      data: { status },
    } = yield call(api.delete, `tipodocumento/${action.payload}`, headerParams);

    if (status) {
      toastr.success("Modalidade removida", "Remoção realizada com sucesso.");
    } else {
      toastr.error(
        "Remoção cancelada",
        "O tipo de documento está atrelado a um ou mais documentos."
      );
    }

    yield put(TipoDocActions.listRequest({ empresas, page: 1, limit: 10 }));
  } catch (error) {
    toastr.error("Ops", "Não foi possível remover o tipo de documento.");
  }
}

export function* createTipoDoc(action) {
  try {
    const organization = yield select(getOrganization);

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const { data } = yield call(
      api.post,
      `/tipodocumento`,
      action.payload,
      headerParams
    );

    yield put(push(`/configuracao/tiposdocumento`));

    yield put(TipoDocActions.createSuccess(data));

    toastr.success("", "Tipo de documento criado com sucesso.");
  } catch (error) {
    toastr.error("Ops", "Não foi possível criar o tipo de documento.");
  }
}

export function* editIndexadoresTipoDoc(action) {
  try {
    const organization = yield select(getOrganization);

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };
    const body = { ...action.payload };

    const { data } = yield call(
      api.put,
      `/tipodocumento/${action.payload.idTipoDoc}/indexadores`,
      body,
      headerParams
    );

    yield put(TipoDocActions.editIndexadoresSuccess(data));

    //yield put(push(`/configuracao/tiposdocumento`));

    // toastr.success("Indexadores atualizados com sucesso!");
  } catch (error) {
    yield put(TipoDocActions.editIndexadoresSuccess());
  }
}

export function* editTipoDoc(action) {
  const userId = yield select(getUserId);

  try {
    const organization = yield select(getOrganization);

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };
    const body = { ...action.payload, _id_user_acao: userId };

    const { data } = yield call(
      api.put,
      `/tipodocumento/${action.payload._id}`,
      body,
      headerParams
    );

    yield put(TipoDocActions.editSuccess(data));

    yield put(push(`/configuracao/tiposdocumento`));

    toastr.success("Tipo de Documento editado com sucesso!");
  } catch (error) {
    toastr.error("Ops,", " Não foi possível editar o Tipo de Documento mesm!");
  }
}


export function* updateDocumentTypeRules(action) {
  try {
    const organization = yield select(getOrganization);

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };
    const body = { ...action.payload };

     yield call(
      api.put,
      `/tipodocumento/${action.payload._id}/rules`,
      body,
      headerParams
    );

    yield put(push(`/configuracao/tiposdocumento`));

    toastr.success("Tipo de Documento editado com sucesso!");
  } catch (error) {
    toastr.error("Ops,", " Não foi possível editar o Tipo de Documento mesm!");
  } finally {
    yield put(TipoDocActions.editIndexadoresSuccess())
  }
}

export function* getIndexadores(action) {
  try {
    const organization = yield select(getOrganization);

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const { data } = yield call(
      api.get,
      `/tipodocumentos/${action.payload}/indexadores`,
      headerParams
    );

    yield put(TipoDocActions.getIndexadoresSuccess(data));
  } catch (error) {}
}
