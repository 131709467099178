export const Types = {
  GET_REQUEST: "configuracoes/GET_REQUEST",
  GET_SUCCESS: "configuracoes/GET_SUCCESS",
  EDIT_REQUEST: "configuracoes/EDIT_REQUEST",
  EDIT_SUCCESS: "configuracoes/EDIT_SUCCESS"
};

const INITIAL_STATE = {
  loading: false,
  configuracoes: []
};

export default function empresas(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_REQUEST:
      return { ...state, loading: true };

    case Types.GET_SUCCESS:
      return { ...state, loading: false, configuracoes: action.payload };

    case Types.EDIT_REQUEST:
      return { ...state, loading: true };

    case Types.EDIT_SUCCESS:
      return { ...state, loading: false };

    default:
      return state;
  }
}

export const Creators = {
  getRequest: () => ({
    type: Types.GET_REQUEST,
  }),

  getSuccess: configuracoes => ({
    type: Types.GET_SUCCESS,
    payload: configuracoes
  }),

  editRequest: configuracoes => ({
    type: Types.EDIT_REQUEST,
    payload: configuracoes
  }),

  editSuccess: () => ({
    type: Types.EDIT_SUCCESS
  })
};
