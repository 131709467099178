import React, { useRef, useEffect, useState } from 'react';
import { useField } from '@unform/core';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    '& input': {
      display: 'none'
    }
  }
})

function RadioGroupForm({ name, label, children}) {
  const inputRef = useRef(null);

  const { fieldName, defaultValue, registerField, error } = useField(name);
  const [value, setValue] = useState(defaultValue || '');

  const classes = useStyles();

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value'
    })
  }, [fieldName, registerField]);

  function handleChange({ target }) {
    const { value } = target;
    setValue(value);
  }
  
  return (
    <FormControl className={classes.root} component="fieldset" error={error && true}>
      <FormLabel component="legend">{label}</FormLabel>
      <RadioGroup name={name} defaultValue={defaultValue} onChange={handleChange}>
        {children}
      </RadioGroup>
      {error && <FormHelperText>{error}</FormHelperText>}
      <input 
        ref={inputRef}
        value={value}
        name={name}
        type="radio"
        readOnly
      />
    </FormControl>
  );
}

export default RadioGroupForm;