import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  TablePagination,
} from "@material-ui/core";
import CustomTableSortLabel from "components/CustomTableSortLabel";
import StyledTableRow from "components/StyledTableRow";
import LoadingTable from "components/LoadingTable";
import ButtonSquare from "components/ButtonSquare";

import { Link, useLocation } from "react-router-dom";

import ToolTipComponent from "components/Tooltip";

import { Creators as FilaActions } from "store/ducks/filas";

export default function ListEsteiraConfig() {
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const {
    access: { servicos },
  } = useSelector((state) => state.permissoes);
  const {
    list: esteiraconfigs,
    loading,
    page = 1,
    limit = 10,
    total,
    sort = "descricao",
  } = useSelector((state) => state.filas);

  const [request, setRequest] = useState({ page, limit, sort });
  const [userActions, setUserActions] = useState([]);

  useEffect(() => {
    dispatch(FilaActions.listRequest({ ...request }));
  }, [dispatch, request]);

  useEffect(() => {
    let find = servicos.find((item) => item.route === pathname);
    if (find !== undefined) {
      setUserActions(find);
    }
  }, [pathname, servicos]);

  function handleChangeRowsPerPage({ target }) {
    const { value: limit } = target;
    setRequest({ ...request, page: 1, limit });
  }

  function handleChangePage(e, newPage) {
    setRequest({ ...request, page: newPage + 1 });
  }

  function handleChangeSort(sort) {
    setRequest({ ...request, sort });
  }

  return esteiraconfigs.length > 0 ? (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>
            <CustomTableSortLabel
              onClick={handleChangeSort}
              currentSort={request.sort}
              sortField="descricao"
            >
              Descrição da Esteira
            </CustomTableSortLabel>
          </TableCell>
          <TableCell>
            <CustomTableSortLabel
              onClick={handleChangeSort}
              currentSort={request.sort}
              sortField="sla"
            >
              SLA
            </CustomTableSortLabel>
          </TableCell>

          {userActions.actions &&
            userActions.actions.map(
              (userAct, index) =>
                userAct.action === "editar" && (
                  <TableCell key={index} align="center">
                    Ações
                  </TableCell>
                )
            )}
        </TableRow>
      </TableHead>
      <TableBody>
        {!!loading && <LoadingTable columns={3} rows={request.limit} />}
        {!loading &&
          esteiraconfigs &&
          esteiraconfigs.map((item) => (
            <StyledTableRow key={item._id}>
              <TableCell>{item.descricao}</TableCell>
              <TableCell>
                {item.sla === 0 && <>D{item.sla}</>}
                {item.sla > 0 && <>D+{item.sla}</>}
                {!item.hasOwnProperty("sla") && <>Não definido</>}
              </TableCell>
              <TableCell align="center">
                <div className="flex-direction">
                  <ToolTipComponent title="Etapas" placement="top">
                    <Link
                      to={{
                        pathname: "/operacao/esteiraconfig/etapas",
                        state: {
                          estconf: item,
                        },
                      }}
                    >
                      {/* <FontAwesomeIcon icon={faClipboardList}></FontAwesomeIcon> */}
                      <ButtonSquare icon="menu_open" />
                    </Link>
                  </ToolTipComponent>

                  <ToolTipComponent title="Editar Esteira" placement="top">
                    <Link
                      to={{
                        pathname: "/operacao/esteiraconfig/editfila",
                        state: {
                          filaSelecionada: item,
                        },
                      }}
                      className="linkicon"
                      id="tiposdocs"
                    >
                      <ButtonSquare icon="edit" />
                    </Link>
                  </ToolTipComponent>
                </div>
              </TableCell>
            </StyledTableRow>
          ))}
      </TableBody>
      {!loading && (
        <TableFooter>
          <TableRow>
            <TablePagination
              count={total}
              page={request.page - 1}
              rowsPerPage={request.limit}
              rowsPerPageOptions={[10, 25, 50, 100]}
              labelRowsPerPage="Linhas por página"
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} de ${count !== -1 ? count : `mais que ${to}`}`
              }
              onRowsPerPageChange={handleChangeRowsPerPage}
              onPageChange={handleChangePage}
            />
          </TableRow>
        </TableFooter>
      )}
    </Table>
  ) : (
    <span className="list__none">
      Não há configurações de esteira registrados.
    </span>
  );
}
