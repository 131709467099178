import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  TableContainer,
  TableFooter,
  TablePagination,
  Icon
} from '@material-ui/core';
import { useLocation, Link } from 'react-router-dom';

import CustomTableSortLabel from 'components/CustomTableSortLabel';
import LoadingTable from 'components/LoadingTable';
import StyledTableRow from 'components/StyledTableRow';
import ToolTipComponent from 'components/Tooltip';
import ButtonSquare from 'components/ButtonSquare';
import ConfirmButton from 'components/ConfirmButton';

import { Creators as AcessosRPAActions } from 'store/ducks/acessosrpa';

function ListAcessos() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const {
    list: acessosRPA,
    loading,
    total,
    page,
    limit,
    sort,
    search,
  } = useSelector(state => state.acessosrpa);
  const { access: { servicos } } = useSelector(state => state.permissoes);

  const userActions = useMemo(() => 
    servicos.find(item => item.route === pathname)
  , [pathname, servicos]);

  const canEdit = useMemo(() => 
    userActions && userActions.actions && 
    userActions.actions.some(userAct => userAct.action === 'editar')
  , [userActions])

  const canRemove = useMemo(() => 
    userActions && userActions.actions && 
    userActions.actions.some(userAct => userAct.action === 'excluir')
  , [userActions])

  useEffect(() => {
    dispatch(AcessosRPAActions.listRequest({
      page: 1,
      limit: 10,
      sort: 'sistema',
      search: ''
    }))
  }, [dispatch]);

  function handleChangeSort(sort) {
    dispatch(AcessosRPAActions.listRequest({
      page,
      limit,
      sort,
      search
    }));
  }

  function handleChangeRowsPerPage({ target }) {
    const { value } = target;
    dispatch(AcessosRPAActions.listRequest({
      page,
      limit: value,
      sort,
      search
    }));
  }

  function handleChangePage(e, newPage) {
    dispatch(AcessosRPAActions.listRequest({
      page: newPage + 1,
      limit,
      sort,
      search
    }));
  }

  function handleRemove(acesso) {
    dispatch(AcessosRPAActions.deleteRequest(acesso._id))
  }

  return (
    <TableContainer className="list-acessos-container">
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>
              <CustomTableSortLabel
                onClick={handleChangeSort}
                currentSort={sort}
                sortField="sistema"
              >
                Sistema
              </CustomTableSortLabel>
            </TableCell>
            <TableCell>
              <CustomTableSortLabel
                onClick={handleChangeSort}
                currentSort={sort}
                sortField="contato"
              >
                Contato
              </CustomTableSortLabel>
            </TableCell>
            <TableCell>
              Empresas
            </TableCell>
            <TableCell>
              <CustomTableSortLabel
                onClick={handleChangeSort}
                currentSort={sort}
                sortField="login"
              >
                Login
              </CustomTableSortLabel>
            </TableCell>
            <TableCell>
              <CustomTableSortLabel
                onClick={handleChangeSort}
                currentSort={sort}
                sortField="uf"
              >
                UF
              </CustomTableSortLabel>
            </TableCell>
            <TableCell>
              <CustomTableSortLabel
                onClick={handleChangeSort}
                currentSort={sort}
                sortField="comarca.nome"
              >
                Comarca
              </CustomTableSortLabel>
            </TableCell>
            <TableCell>
              <CustomTableSortLabel
                onClick={handleChangeSort}
                currentSort={sort}
                sortField="link"
              >
                Link
              </CustomTableSortLabel>
            </TableCell>
            <TableCell align="center">Ações</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!!loading && <LoadingTable rows={limit} columns={8} />}
          {!loading && acessosRPA.map(acesso => 
            <StyledTableRow key={acesso._id}>
              <TableCell>{acesso.sistema}</TableCell>
              <TableCell>{acesso.contato}</TableCell>
              <TableCell>{acesso.empresas.map(emp => emp.razaoSocial).join(', ')}</TableCell>
              <TableCell>{acesso.login}</TableCell>
              <TableCell>{acesso.uf}</TableCell>
              <TableCell>{acesso.comarca?.nome}</TableCell>
              <TableCell>{acesso.link}</TableCell>
              <TableCell align="center">
                <div className="flex-direction">
                  {!!canEdit &&
                  <ToolTipComponent title="Editar" placement="top">
                    <div>
                      <Link
                        to={{
                          pathname: "/configuracao/acessos-rpa/editar",
                          state: {
                            acessoRPA: acesso
                          }
                        }}
                        className="linkicon"
                      >
                        <ButtonSquare
                          icon="edit"
                          style={{ marginRight: '8px' }}
                        />
                      </Link>
                    </div>
                  </ToolTipComponent>}
                  {!!canRemove && 
                  <ToolTipComponent title="Remover" placement="top">
                    <div>
                      <ConfirmButton
                        style={{ minWidth: '32px', backgroundColor: '#0c4383' }}
                        variant="contained"
                        color="primary"
                        title="Deseja realmente remover o acesso?"
                        size="small"
                        onConfirm={() => handleRemove(acesso)}
                      >
                        <Icon>delete</Icon>
                      </ConfirmButton>
                    </div>
                  </ToolTipComponent>}
                </div>
              </TableCell>
            </StyledTableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              count={total}
              page={page - 1}
              rowsPerPage={limit}
              rowsPerPageOptions={[10, 25, 50, 100]}
              labelRowsPerPage="Linhas por página"
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} de ${count !== -1 ? count : `mais que ${to}`}`
              }
              onRowsPerPageChange={handleChangeRowsPerPage}
              onPageChange={handleChangePage}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}

export default ListAcessos;