import React, { useState } from 'react';
import { Date } from "components/Masks";
import TextField from "@material-ui/core/TextField";
import { WrapSensibleFlag } from 'components/SensibleFlag';

function FieldData({ indexador, handleChangeIndexador, checkReadOnly, hasIndexadorError, hasIndexadorTextError }) {
    const [value, setValue] = useState(indexador.valor || "")

    return (
        <WrapSensibleFlag indexador={indexador}>
            <TextField
                disabled={checkReadOnly ? indexador.somenteLeitura : false}
                onChange={event => {
                    setValue(event?.currentTarget?.value)
                    handleChangeIndexador(event)
                }}
                label={indexador.nome}
                name={indexador.nome}
                value={value}
                variant="outlined"
                size="small"
                InputProps={{ inputComponent: Date }}
                error={hasIndexadorError(indexador)}
                helperText={hasIndexadorTextError(indexador)} />
        </WrapSensibleFlag>
    );
}

export default FieldData;