import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { Document, Page, pdfjs } from "react-pdf";
import ButtonSquare from "components/ButtonSquare";
import TextField from "@material-ui/core/TextField";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";

import CircularProgress from "@material-ui/core/CircularProgress";

import "./style.css";

import { Creators as DocSignatureActions } from "store/ducks/docsignature";
import { toastr } from "react-redux-toastr";

function ModalDocSign({
  open,
  image,
  handleClose,
  selectedDoc,
  onSubmitCustom = null,
  title = "Enviar documento para Assinatura Eletrônica",
}) {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/2.1.266/pdf.worker.js`;

  const dispatch = useDispatch();
  
  useEffect(() => {
    setPoints(image?.signsPoints || []);
  }, [image]);

  const limitKB = 1024 * 1024 * 20;
  const isSizeValid = image?.size <= limitKB;
  const isNotPDF = image?.filepath && !image?.filepath?.includes(".pdf");

  const { b64, loading } = useSelector((state) => state.docsignature);

  const isValidEmail = (email = "") => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const isValidSignature = (signature = "") => {
    return signature.length >= 3;
  };

  const onSubmit = () => {
    if (onSubmitCustom) return onSubmitCustom(points);

    const payload = {
      documentId: selectedDoc?._id,
      imageId: image,
      points,
    };

    points?.forEach((point) => {
      const isValidEmailFormat = isValidEmail(point.email);
      const isValidSignatureLength = isValidSignature(point.assinatura);

      if (!isValidEmailFormat)
        return toastr.error(`O e-mail "${point.email}" não é um e-mail valido`);

      if (!isValidSignatureLength)
        return toastr.error(
          `O nome "${point.assinatura}" deve ter ao menos 3 letras`
        );
    });

    dispatch(DocSignatureActions.sendRequest(payload));
  };

  useEffect(() => {
    dispatch(DocSignatureActions.b64Request({ image: image?.filepath }));
  }, [image, dispatch]);

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [points, setPoints] = useState([]);

  const canvasRef = useRef(null);

  const handleInputChange = (_id, event) => {
    const { name, value } = event.target;
    const nameRight = name.split("_")[0];
    const updatedPoints = [...points];
    const idx = points.findIndex((item) => item._id === _id);
    updatedPoints[idx][nameRight] = value;
    setPoints(updatedPoints);
  };

  const ObjectId = (
    m = Math,
    d = Date,
    h = 16,
    s = (s) => m.floor(s).toString(h)
  ) => s(d.now() / 1000) + " ".repeat(h).replace(/./g, () => s(m.random() * h));

  const removePoint = (_id) => {
    const updatedPoints = points.filter((x) => x._id !== _id);
    setPoints(updatedPoints);
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const onPageChange = (newPage) => {
    setPageNumber(newPage);
  };

  function onNewSignature(e) {
    const rect = canvasRef.current.getBoundingClientRect();
    const canvasX = rect.left + window.scrollX;
    const canvasY = rect.top + window.scrollY;

    setPoints([
      ...points,
      {
        page: pageNumber,
        x: e.pageX - canvasX,
        y: e.pageY - canvasY,
        email: "",
        assinatura: "",
        _id: ObjectId(),
      },
    ]);
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xl" fullWidth>
      <DialogTitle className="header-modal">{title}</DialogTitle>
      <DialogContent>
        <Row>
          <Col md={12}>
            {!!loading && <CircularProgress size={20} />}
            {!!isNotPDF && (
              <h6>
                A funcionalidade de assinatura eletrônica está atualmente
                disponível apenas para documentos em formato PDF no sistema.
              </h6>
            )}
            {!isSizeValid && (
              <h6>Arquivo muito grande para ser enviado pela assinatura eletrônica.</h6>
            )}
            {!loading && !isNotPDF && isSizeValid && (
              <Row>
                <Col lg="12">
                  <div className="pagination">
                    <p>
                      Página {pageNumber} de {numPages}
                    </p>
                    <div className="paginationButtons">
                      <button onClick={() => onPageChange(pageNumber - 1)}>
                        Anterior
                      </button>
                      <button onClick={() => onPageChange(pageNumber + 1)}>
                        Próxima
                      </button>
                    </div>
                  </div>
                </Col>

                <Col lg="8">
                  {b64 && b64.data && !loading && (
                    <div className="canvas mb-0">
                      <div className="viewer" ref={canvasRef}>
                        <Document
                          file={`data:application/pdf;base64,${b64?.data}`}
                          onLoadSuccess={onDocumentLoadSuccess}
                          onClick={onNewSignature}
                          noData={<h3>Loading...</h3>}
                          renderMode="canvas"
                          className="pdfviewer"
                        >
                          <Page
                            pageNumber={pageNumber}
                            onRenderSuccess={() => onPageChange(pageNumber)}
                            renderTextLayer={false}
                            scale={1}
                            height={800}
                          >
                            <div
                              style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%",
                                pointerEvents: "none",
                              }}
                            >
                              {points.map((point, index) => (
                                <div key={index}>
                                  {pageNumber === point.page && (
                                    <div
                                      className="signature"
                                      style={{
                                        top: point.y,
                                        left: point.x,
                                      }}
                                    >
                                      Assinatura {index + 1}
                                    </div>
                                  )}
                                </div>
                              ))}
                            </div>
                          </Page>
                        </Document>
                      </div>
                    </div>
                  )}
                </Col>
                <Col lg="4" sm="12">
                  <div className="fields">
                    <div className="w-100">
                      {points.map((point, index) => (
                        <div className="mt-3 signature-info">
                          <Row>
                            <Col md={6} sm={12}>
                              <span>Assinatura {index + 1}</span>
                            </Col>
                            <Col md={6} sm={12}>
                              <ButtonSquare
                                float="right"
                                icon="delete"
                                variant="contained"
                                color="secondary"
                                onClick={() => removePoint(point._id)}
                              />
                            </Col>
                          </Row>

                          <TextField
                            onChange={(e) => handleInputChange(point._id, e)}
                            label={"Nome"}
                            className="mt-2"
                            name={`assinatura_${index}`}
                            value={point.assinatura || ""}
                            variant="outlined"
                            size="small"
                          />
                          <TextField
                            className="mt-2"
                            onChange={(e) => handleInputChange(point._id, e)}
                            value={point.email || ""}
                            label={"E-mail"}
                            name={`email_${index}`}
                            variant="outlined"
                            size="small"
                          />
                        </div>
                      ))}
                    </div>
                    <DialogActions className="mt-2">
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        onClick={() => onSubmit()}
                      >
                        Enviar
                      </Button>

                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => handleClose()}
                      >
                        Cancelar
                      </Button>
                    </DialogActions>
                  </div>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </DialogContent>
    </Dialog>
  );
}

export default ModalDocSign;
