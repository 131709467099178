import React, { useState } from 'react';
import Switch from '@material-ui/core/Switch';
import { Container, ImageSelection, ImageSelectionHeader } from './style';

export default function GaleriaIndexacaoManual({
  images,
  selectedImages,
  setSelectedImages,
}) {
  const handleSelectedImages = (e, image) => {
    if (e.target.checked) {
      setSelectedImages([...selectedImages, image]);
    } else {
      setSelectedImages(selectedImages.filter((img) => img !== image));
    }
  };

  return (
    <Container>
      {images.map((image, index) => (
        <ImageSelection key={`thumb_${index}`}>
          <ImageSelectionHeader>
            <Switch
              checked={selectedImages.includes(image)}
              onChange={(e) => handleSelectedImages(e, image)}
              name={`doc-check-${index}`}
              inputProps={{
                'aria-label': 'primary checkbox',
              }}
              size="small"
              color="primary"
            />
          </ImageSelectionHeader>
          <img src={image} width={'100%'} />
        </ImageSelection>
      ))}
    </Container>
  );
}
