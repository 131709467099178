import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_FRONT_URL,
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status !== 400) {
      throw error;
    } else {
      return { data: { error: true, message: error.response.data.message } };
    }
  }
);

export default api;
