import React, { useState, useEffect } from "react";
import Zoom from "../../Zoom/index";
import { Player } from "video-react";

import "./styles.css";

export default function Viewer({ imagem }) {
  const [imagePreview, setImagePreview] = useState("");
  const [showPdfViewer, setShowPdfViewer] = useState(false);
  const [showVideoViewer, setShowVideoViewer] = useState(false);

  function handleImageChange(imagem) {
    if (!imagem) return;

    if (typeof imagem === "string") {
      if (imagem.includes(".pdf")) {
        setShowPdfViewer(true);
        setShowVideoViewer(false);
        setImagePreview(imagem);
        return;
      } else if (
        imagem.includes(".mp4") ||
        imagem.includes(".mp3") ||
        imagem.includes(".webm") ||
        imagem.includes(".ogv")
      ) {
        setShowVideoViewer(true);
        setShowPdfViewer(false);
        setImagePreview(imagem);
        return;
      } else {
        setShowPdfViewer(false);
        setShowVideoViewer(false);
        setImagePreview(imagem);
        return;
      }
    }

    if (typeof imagem === "object" && imagem.filepath) {
      if (imagem.filepath.includes(".pdf")) {
        setShowPdfViewer(true);
        setShowVideoViewer(false);
        setImagePreview("http://www.africau.edu/images/default/sample.pdf");
        return;
      } else if (
        imagem.includes(".mp4") ||
        imagem.includes(".mp3") ||
        imagem.includes(".webm") ||
        imagem.includes(".ogv")
      ) {
        setShowPdfViewer(false);
        setShowVideoViewer(true);
      } else {
        setShowPdfViewer(false);
        setShowVideoViewer(false);
        setImagePreview(imagem.filepath);
        return;
      }
    }

    let reader = new FileReader();

    reader.onloadend = async () => {
      setShowPdfViewer(reader.result.includes("application/pdf"));
      setImagePreview(reader.result);
    };

    reader.readAsDataURL(imagem);
  }

  useEffect(() => {
    handleImageChange(imagem);
  }, [imagem]);

  return (
    <>
      {showPdfViewer && (
        <div className="image--viewer__viewer">
          <div className="pdfViewerButtons">
            <div className="pdfviewer">
              <iframe
                src={`${imagePreview}`}
                title="Visualizador de documentos"
                width="100%"
                height="800px"
              />
              <div className="embed-cover"></div>
            </div>
          </div>
        </div>
      )}

      {showVideoViewer && <Player playsInline src={imagePreview} />}

      {!showPdfViewer && !showVideoViewer && (
        <div className="image-viewer">
          <Zoom imagem={imagePreview} />
        </div>
      )}
    </>
  );
}
