import React, { useRef, useState, useEffect, useReducer } from 'react';
import Webcam from 'react-webcam';
import * as Icons from 'react-feather';
import { FeatherIcon } from 'components/Icons';

import {
  Container,
  CameraContainer,
  ImagePreviewContainer,
  MaskCamera,
  CapturePhotoContainer,
  PictureTypeSelectionContainer,
  SelectionType,
} from './styled';

const CameraStep = ({ handleCaptureImage, settings }) => {
  const webcamRef = useRef(null);
  const inputFileRef = useRef(null);

  const [imagem, setImagem] = useState(null);
  const [pictureType, setPictureType] = useState(null);

  const handleInputFileClick = () => {
    inputFileRef.current.click();
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      //alert(`Arquivo selecionado: ${selectedFile.name}`);
      // Realize aqui a ação desejada com o arquivo selecionado

      setPictureType('file');
      console.log(selectedFile);

      setImagem(URL.createObjectURL(selectedFile));
    }
  };

  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();

    setImagem(imageSrc);

    handleCaptureImage(imageSrc);
  }, [webcamRef]);

  return (
    <Container>
      <h4>{settings.text}</h4>
      {!pictureType && (
        <PictureTypeSelectionContainer>
          <strong>Selecione o tipo de foto:</strong>
          <SelectionType>
            <button onClick={(e) => setPictureType('camera')}>
              <FeatherIcon fontSize="medium" icon={Icons.Camera} />
              Tirar Foto
            </button>

            <button onClick={handleInputFileClick}>
              <FeatherIcon fontSize="medium" icon={Icons.File} />
              Selecionar arquivo
            </button>
          </SelectionType>
          <input
            type="file"
            ref={inputFileRef}
            style={{ display: 'none' }}
            onChange={handleFileChange}
          />
        </PictureTypeSelectionContainer>
      )}
      {imagem ? (
        <ImagePreviewContainer>
          <img src={imagem} alt="imagem capturada" height="500px" />
          <button
            onClick={(e) => {
              setImagem(null);
              setPictureType(null);
            }}
          >
            Selecionar outra imagem
          </button>
        </ImagePreviewContainer>
      ) : (
        <>
          {pictureType === 'camera' && (
            <>
              <strong>Posicione o documento na área indicada</strong>
              <CameraContainer>
                <Webcam
                  audio={false}
                  height={'100%'}
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                  width={'100%'}
                  videoConstraints={{ facingMode: 'user' }}
                />
                <MaskCamera $maskType={settings.maskType} />
                <button onClick={capture}>
                  <FeatherIcon fontSize="medium" icon={Icons.Camera} />
                </button>
              </CameraContainer>
            </>
          )}
          {/* {pictureType === 'file' && (
            <CapturePhotoContainer>aqui</CapturePhotoContainer>
          )} */}
        </>
      )}
    </Container>
  );
};

export default CameraStep;
