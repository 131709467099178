import React from "react";

import HandleFormulario from "../Util/handleFormulario";

export default function FormularioEdicao({ location }) {

    const { formulario } = location.state;
    const { _id, ...initialData } = formulario;

    return (
        <HandleFormulario
            idFormulario={_id}
            initialData={initialData}
        />
    )
}
