import React from 'react';
import Typography from '@material-ui/core/Typography';

function Reprovado() {
  return (
    <div style={{
      padding: '0px 8px',
      background: '#d00f0f',
      color: '#fff',
      borderRadius: '4px',
      marginRight: '8px'
    }}>
      <Typography 
        style={{
          fontSize: '10px',
          fontWeight: 'bold'
        }}
      >
        REPROVADO
      </Typography>
    </div>
  );
}

export default Reprovado;