import api from './api';
import { getToken } from 'services/auth';


function headerBuilder(organization) {
    return {
        headers: { Authorization: getToken(), xkeyorg: organization },
    };
}
class ProvidenciaImagensService {

    async taskApproval(taskId, organization, approvationState) {

        try {
            const headerParams = headerBuilder(organization);
        
            await api.post(
                `providencias/${taskId}/taskapproval`,
                { approvationState },
                headerParams
            );
        
            // return data;
        } catch (err) {
            console.log(err);
            throw new Error('Não foi possível alterar o status de aprovação.')
        }
    }

    async taskApproval(taskId, organization, approvationState) {

        try {
            const headerParams = headerBuilder(organization);
        
            return api.post(
                `providencias/${taskId}/taskapproval`,
                { approvationState },
                headerParams
            );
        
        } catch (err) {
            console.log(err);
            throw new Error('Não foi possível alterar o status de aprovação.');
        }
    }

    async removeImage(taskId, imageId, organization) {

        try {
            const headerParams = headerBuilder(organization);

            await api.delete(
                `providencias/${taskId}/imagens/${imageId}`,
                headerParams
            );

        } catch (err) {
            console.log(err);
            throw new Error('Não foi possível remover a imagem.');
        }
    }


}

export default new ProvidenciaImagensService();