import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  actions: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '24px'
  },
  newCommentButton: {
    marginRight: '8px',
    minWidth: '200px',
    maxHeight: '40px'
  },
  fileName: {
    marginLeft: '4px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: '100%',
    textOverflow: 'ellipsis'
  },
  anexo: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  inputMargin: {
    margin: "21px 0"
  },
}));