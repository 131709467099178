import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form } from "@unform/web";
import { Col, Row } from "react-bootstrap";
import { Creators as TiposTarefasActions } from "store/ducks/tipostarefas";
import { Checkbox, List, ListItem, ListItemIcon, ListItemText, Paper } from "@material-ui/core";

import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Fab from '@material-ui/core/Fab';
import AddIcon from "@material-ui/icons/Add";
import { HeaderWithButton, ListDependenciasFields, ListDependenciasFieldsItem } from "../styles/ModalNewTask";

const StepThree = ({
  handleDependenciaIndexador,
  handleAddCondicaoDependenciaIndexador,
  handleChangeOptionDependenciaIndexador,
  handleChangeValuesForOptionDependenciaIndexador,
  indexador,
  indexadores
}) => {
  const containerStyle = {
    display: "flex",
    justifyContent: "center",
    gap: "1em",
    flexWrap: "wrap",
  };

  function newSelection(e, idx) {
    handleDependenciaIndexador(e, idx);
  }

  return (
    <div style={containerStyle} className="flex-container flex-start mt-1">
      <Col md="12">
        <Form>
          <Row>
            <Col md="12">
              <Autocomplete
                selectOnFocus
                clearOnBlur
                id="options-combo"
                name="dependenciaIndexador"
                onChange={(e, newValue) => {
                  newSelection(e, newValue)
                }}
                defaultValue={indexador.dependenciaIndexador ? indexadores.find((item) => item.index === indexador.dependenciaIndexador.index) : null}
                options={indexadores.filter((item) => item.nome !== indexador.nome && item.tipo === "combo")}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Indexador irá aparecer se o indexador selecionado for preenchido"
                    variant="outlined"
                    name="dependenciaIndexador"
                  // error={
                  //   !indexador.mostrarFluxo ||
                  //   indexador.mostrarFluxo.length === 0
                  // }
                  // helperText={
                  //   (!indexador.mostrarFluxo ||
                  //     indexador.mostrarFluxo.length === 0) &&
                  //   "Desejável a configuração de um ou mais fluxos"
                  // }
                  />
                )}
                getOptionLabel={(option) =>
                  typeof option === "string" ? option : option.nome
                }
                renderOption={(option) =>
                  typeof option === "string" ? option : option.nome
                }
                size="small"
              />
            </Col>

            {indexador.dependenciaIndexador && (
              <Col md="12">
                <Paper elevation={3} style={{ padding: 10, marginTop: 20 }}>
                  <HeaderWithButton>
                    <strong>Crie os relacionamentos entre os campos</strong>
                    <Fab color="primary" aria-label="add" size="small" onClick={handleAddCondicaoDependenciaIndexador}>
                      <AddIcon />
                    </Fab>
                  </HeaderWithButton>
                  <ListDependenciasFields>
                    {indexador.dependenciaIndexador.condicoes.map((condicao, idx) => (
                      <ListDependenciasFieldsItem key={`dependenciaIndexadorOpcao${idx}`}>
                        <Paper elevation={2} style={{ width: '100%', padding: '10px', marginBottom: 15, display: "flex" }}>
                          <Col md={6}>
                            <Autocomplete
                              selectOnFocus
                              clearOnBlur
                              id={`dependenciaIndexadorOpcao${idx}`}
                              name={`dependenciaIndexadorOpcao${idx}`}
                              onChange={(e, newValue) => {
                                handleChangeOptionDependenciaIndexador(e, newValue, idx)
                              }}
                              defaultValue={indexador.dependenciaIndexador?.condicoes[idx]?.valor}

                              options={indexadores.find((item) => item.index === indexador.dependenciaIndexador.index).opcoes}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Selecione a opção do indexador"
                                  variant="outlined"
                                  name={`dependenciaIndexadorOpcaoInput${idx}`}
                                />
                              )}
                              getOptionLabel={(option) => option}
                              renderOption={(option) => option}
                              size="small"
                            />
                          </Col>

                          <Col md={6}>
                            <Autocomplete
                              multiple
                              selectOnFocus
                              clearOnBlur
                              id={`dependenciaIndexadorValor${idx}`}
                              name={`dependenciaIndexadorValor${idx}`}
                              onChange={(e, newValue) => {
                                handleChangeValuesForOptionDependenciaIndexador(e, newValue, idx)
                              }}
                              // defaultValue={indexador.dependenciaIndexador ? indexadores.find((item) => item.index === indexador.dependenciaIndexador.index) : null}
                              defaultValue={indexador.dependenciaIndexador?.condicoes[idx]?.opcoes}

                              options={indexador.opcoes}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Selecione os valores para a opção"
                                  variant="outlined"
                                  name={`dependenciaIndexadorValorInput${idx}`}
                                />
                              )}
                              getOptionLabel={(option) => option}
                              renderOption={(option) => option}
                              size="small"
                            />
                          </Col>

                          <Fab color="primary" aria-label="add" size="small" onClick={handleAddCondicaoDependenciaIndexador}>
                            <AddIcon />
                          </Fab>
                        </Paper>





                      </ListDependenciasFieldsItem>
                    ))}
                  </ListDependenciasFields>
                </Paper>
              </Col>
            )}

          </Row>
        </Form>
      </Col>
    </div>
  );
};

export default StepThree;
