import React, { useState } from 'react';
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogActions from "@material-ui/core/DialogActions"

function ConfirmButton({ 
  children, title, content, DialogProps,
  confirmTitle='Sim', rejectTitle='Não',
  closeOnOutsideClick=false,
  loading, onConfirm, onReject, ...props 
}) {
  const [open, setOpen] = useState(false);

  function handleConfirm() {
    setOpen(false);
    if (onConfirm) onConfirm();
  }

  function handleReject() {
    setOpen(false);
    if (onReject) onReject();
  }

  function handleClick() {
    setOpen(true);
  }

  return (
    <>
      <Button
        {...props}
        onClick={handleClick}
      >
        {children}
      </Button>
      <Dialog open={open} onClose={() => {
        if (closeOnOutsideClick) setOpen(false);
      }}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>{content}</DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            onClick={handleConfirm}
          >
            {confirmTitle}
          </Button>
          <Button
            color="secondary"
            variant="contained"
            onClick={handleReject}
          >
            {rejectTitle}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ConfirmButton;