import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Creators as TarefasActions } from "store/ducks/tipostarefas";

import _ from 'lodash';

import GridCadastro from "components/GridCadastro";
import TableTarefas from "./TableTarefas";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

export default function Tarefas() {

  const dispatch = useDispatch();
  const dispatchDebounce = useRef(_.debounce(dispatch, 500)).current;

  const { pathname } = useLocation();

  const {
    access: { servicos },
  } = useSelector((state) => state.permissoes);

  const [userActions, setUserActions] = useState([]);

  useEffect(() => {
    let find = servicos.find((item) => item.route === pathname);
    if (find !== undefined) {
      setUserActions(find);
    }
  }, [pathname, servicos]);
  return (
    <GridCadastro
      title='Tarefas'
      route={"/administracao/tarefas/create"}
      searchRequest={(value) => {
        dispatchDebounce(
          TarefasActions.listRequest({ page: 1, limit: 10, search: value })
        );
      }}
      titleButtonNovo="Nova Tarefa"
    >
      <TableTarefas userActions={userActions} />
    </GridCadastro>
  )
}