export default function documentTemplateIndexerBuilder(documento) {

    const {
        tribunal, 
        assunto,
        comarca,
        resumo,
        valorDaCausa,
        provisao,
        pedidos,
        pasta,
        numeroCNJ,
        pais,
        UF,
        denominacao,
        idEmpresa,
    } = documento;

    return {
        tribunal: tribunal.nome,
        comarca: comarca.nome,
        assunto,
        resumo,
        provisao,
        valorDaCausa,
        pedidos,
        pasta,
        numeroCNJ,
        pais,
        UF,
        denominacaoacao: denominacao,
        nome: idEmpresa.razaoSocial,
    }
}