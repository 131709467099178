import React, { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import InputForm from 'components/InputForm';

import { Creators as RelacionamentosActions } from 'store/ducks/relacionamentos';

import { Row, Col } from 'react-bootstrap';
import Grid from '@material-ui/core/Grid';
import { Form } from '@unform/web';
import { FormControlLabel, Switch } from '@material-ui/core';
import Button from "@material-ui/core/Button";
import BackButton from 'components/BackButton';

import RelacionamentoSchema from 'validators/RelacionamentoSchema';

import { ValidationError } from 'yup';
import Content from 'components/Content';

export default function FormRelacionamento() {

    const [status, setStatus] = useState(false);

    const dispatch = useDispatch();
    const formRef = useRef(null);
    const usuario = useSelector(state => state.usuarios.auth);

    const onSubmit = async values => {

        try {
            const relacionamento = { ...values, userCadastro: usuario.id, status };

            await RelacionamentoSchema.validate(relacionamento, {
                abortEarly: false
            });


            dispatch(RelacionamentosActions.createRelacionamentoRequest(relacionamento))
        }

        catch (err) {
            const validationErrors = {};
            if (err instanceof ValidationError) {
                err.inner.forEach(error => {
                    validationErrors[error.path] = error.message;
                })

                formRef.current.setErrors(validationErrors);
            }
        }
    };

    const handleCheckStatus = () => {

        if (status !== true) {
            setStatus(true);
        } else {
            setStatus(false);
        }
    };

    return (
        <Content>
            <Row >
                <Col md="12" className="painel">
                    <div className="painel-content">
                        <div className="painel-title text-left">Novo Relacionamento</div>
                        <div className="painel-body">
                            <Form ref={formRef} onSubmit={onSubmit}>
                                <Row className="row">
                                    <Col>
                                        <InputForm
                                            name="relacionamento"
                                            label="Relacionamento"
                                        />
                                    </Col>
                                    <Col>
                                        <InputForm
                                            name="descricao"
                                            label="Descrição"
                                        />
                                    </Col>
                                </Row>

                                <FormControlLabel
                                    control={
                                        <Switch
                                            name="status"
                                            onChange={handleCheckStatus}
                                            color="primary"
                                        />
                                    }
                                    label="Ativo"
                                />
                                <Row>
                                    <Grid container spacing={1}>
                                        <Grid item >
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                type="submit"
                                            >Enviar</Button>
                                        </Grid>
                                        <Grid item >
                                            <BackButton
                                                variant="contained"
                                                color="secondary"
                                            >
                                                Voltar
                                            </BackButton>
                                        </Grid>
                                    </Grid>
                                </Row>
                            </Form>
                        </div>
                    </div>
                </Col>
            </Row>
        </Content>
    )
}