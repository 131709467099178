import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import FormularioCadastro from "components/FormularioCadastro";

import Skeleton from "@material-ui/lab/Skeleton";

import InputForm from "components/InputForm";
import AutocompleteForm from "components/AutocompleteForm";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

import { Creators as RPAActions } from "store/ducks/rpa";
import { Creators as EmpresasActions } from "store/ducks/empresas";
import { Creators as RespostaActions } from "store/ducks/respostaOrgaos";
import { Creators as DepartamentosActions } from "store/ducks/departamentos";

export default function FormRPA(params) {
  const dispatch = useDispatch();

  const { list: empresasList, loading: loadingEmpresas } = useSelector(
    (state) => state.empresas
  );

  const { list: departamentosList, loading: loadingDepartamentos } =
    useSelector((state) => state.departamentos);

  const { rpa = null } = params.location.state || {};
  const { orgaos: orgaosList } = useSelector((state) => state.respostaOrgaos);

  const newRegister = rpa === null;

  const [status, setStatus] = useState(rpa?.status || false);

  const formRef = useRef();

  function handleCheckStatus() {
    setStatus(!status);
  }

  function handleSubmit(newValues) {
    if (!newRegister) newValues["_id"] = rpa._id;

    dispatch(RPAActions.editRequest(newValues));
  }

  useEffect(() => {
    dispatch(EmpresasActions.listRequest());
    dispatch(RespostaActions.listOrgaosRequest());
    dispatch(DepartamentosActions.listRequest({ onlyOperational: true }));
  }, [dispatch]);

  return (
    <>
      <FormularioCadastro
        title={"Gerenciamento de RPA"}
        formref={formRef}
        onSubmit={handleSubmit}
        canSave={true}
        initialData={{
          ...rpa,
        }}
      >
        <AutocompleteForm
          required
          label="Tipo"
          name="tipo"
          options={orgaosList || []}
          transformOnSelect={(option) => option}
          getOptionLabel={(option) => option}
        />

        {loadingEmpresas ? (
          <Skeleton width="100%" height={50} />
        ) : (
          <AutocompleteForm
            label="Empresa"
            name="empresa"
            options={empresasList}
            transformOnSelect={(option) => option}
            getOptionLabel={(option) => option.razaoSocial}
          />
        )}

        {loadingDepartamentos ? (
          <Skeleton width="100%" height={50} />
        ) : (
          <AutocompleteForm
            label="Departamento"
            name="departamento"
            options={departamentosList}
            transformOnSelect={(option) => option}
            getOptionLabel={(option) => option.nome}
          />
        )}

        <InputForm required label="URL" name="url" type="text" />

        <InputForm required label="Login" name="login" type="text" />

        <InputForm required label="Senha" name="senha" type="password" />

        <FormControlLabel
          control={
            <Switch
              name="status"
              onChange={handleCheckStatus}
              color="primary"
              defaultChecked={status || false}
            />
          }
          label="Ativo"
        />
      </FormularioCadastro>
    </>
  );
}
