export const AUTH_KEYS = {
  TOKEN: "@newspace-token",
  STATE: "@newspace-state",
  ID: "@formalizacao-id",
  TENANT: "@formalizacao-tenant",
  RETRY: "@formalizacao-rt",
};

export const isAuthenticated = () =>
  localStorage.getItem(AUTH_KEYS.TOKEN) !== null;

export const getToken = () => {
  const token = JSON.stringify(localStorage.getItem(AUTH_KEYS.TOKEN)).replace(/"/g, '');
  return token;
}

export const login = (token) => {
  localStorage.setItem(AUTH_KEYS.TOKEN, `${token}`);
};

export const logout = () => {
  localStorage.removeItem(AUTH_KEYS.TOKEN);
};

export const getOrg = () => localStorage.getItem(AUTH_KEYS.ORGANIZATION);

export const getClientId = () => {
  const clientId = localStorage.getItem(AUTH_KEYS.ID);
  return clientId;
}

export const setTenant = (tenant) => {
  localStorage.setItem(AUTH_KEYS.TENANT, tenant);
}

export const getTenant = () => {
  const tenant = localStorage.getItem(AUTH_KEYS.TENANT);
  return tenant;
}

export const setRetrying = (value) => {
  localStorage.setItem(AUTH_KEYS.RETRY, value);
}

export const getRetrying = () => {
  const valor = localStorage.getItem(AUTH_KEYS.RETRY);
  return valor;
}