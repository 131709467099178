import React from 'react';
import { useDropzone } from 'react-dropzone';
import { Col, Row } from 'react-bootstrap';

import {
    List
    , ListItem
    , ListItemIcon
    , Typography
} from "@material-ui/core/";

import ToolTipComponent from "components/Tooltip";
import { DeleteIcon } from 'components/Icons';

function DropZone({ onDrop
    , files = []
    , onDeleteFile = () => { }
    , message = "Arraste e solte os arquivos aqui"
    , warning = "Todos os formatos de arquivo são aceitos"
    , style
}) {

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
    });

    return (
        <Row style={style}>
            <Col>
                <div {...getRootProps({ className: "dropzone" })}>
                    <input {...getInputProps()} />
                    {message}
                    {warning}
                </div>
            </Col>
            <Col>
                <List>
                    {files &&
                        files.map((image, index) => (
                            <Row key={index}>
                                <ListItem key={index}>
                                    <Col md={11}>
                                        <Typography noWrap>{image.path}</Typography>
                                    </Col>

                                    <Col md={1}>
                                        <ToolTipComponent
                                            title="Excluir Arquivo"
                                            placement="right"
                                        >
                                            <ListItemIcon
                                                onClick={() => onDeleteFile(image)}
                                            >
                                                <DeleteIcon />
                                            </ListItemIcon>
                                        </ToolTipComponent>
                                    </Col>
                                </ListItem>
                            </Row>
                        ))}
                </List>
            </Col>
        </Row>
    )
}

export default DropZone;