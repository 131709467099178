import api from "services/api";
import { getToken } from "services/auth";
import { call, select, put, all, takeLatest } from "redux-saga/effects";
import { toastr } from "react-redux-toastr";
import { push } from "connected-react-router";

import { Creators as OcorrenciasActions, Types } from "../ducks/ocorrencias";

export const getOrganization = (state) => state.usuarios.auth.organization;
export const getUserId = (state) => state.usuarios.auth.id;

export function* listOcorrencias({ payload }) {
  try {
    const organization = yield select(getOrganization);

    const { page, limit, sort } = payload;

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
      params: { page, limit, sort },
    };

    const response = yield call(api.post, `ocorrencias/list`, {}, headerParams);

    const data = {
      total: response.data.total,
      list: response.data.docs,
    };

    yield put(OcorrenciasActions.listOcorrenciasSuccess(data));
  } catch (error) {
    toastr.error("Ops", "Não foi possível listar as ocorrências.");
  }
}

export function* getOcorrencia(action) {
  try {
    const headerParams = {
      headers: { Authorization: getToken() },
    };

    const { data } = yield call(api.get, `ocorrencias/${action.payload}`, headerParams);

    yield put(OcorrenciasActions.getOcorrenciasSuccess(data));
  } catch (error) {
    toastr.error("Ops", "Não foi possível buscar os dados da ocorrência.");
  }
}

export function* editOcorrencia(action) {
  const userAcao = yield select(getUserId);

  try {
    const organization = yield select(getOrganization);

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const { _id } = action.payload;
    delete action.payload._id;

    const body = { ...action.payload, userAcao };

    const { data } = yield call(
      api.put,
      `ocorrencias/${_id}`,
      body,
      headerParams
    );

    yield put(push(`/configuracao/ocorrencias`));

    yield put(OcorrenciasActions.editOcorrenciasSuccess(data));

    toastr.success("Ocorrência editada com sucesso.");
  } catch (error) {
    toastr.error("Ops", "Não foi possível editar a ocorrência.");
  }
}

export function* createOcorrencia(action) {
  const organization = yield select(getOrganization);

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const { data } = yield call(api.post, `ocorrencias`, action.payload, headerParams);

    yield put(push(`/configuracao/ocorrencias`));

    yield put(OcorrenciasActions.createOcorrenciasSuccess(data));

    toastr.success("", "Ocorrência criada com sucesso.");
  } catch (error) {
    toastr.error("Ops", "Não foi possível criar a ocorrência.");
  }
}

export function* deleteOcorrencia(action) {
  try {
    const organization = yield select(getOrganization);
    const userId = yield select(getUserId);

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization, userId },
    };

    yield call(api.delete, `ocorrencias/${action.payload}`, headerParams);

    toastr.success("", "Ocorrência removida com sucesso.");

    yield put(
      OcorrenciasActions.listOcorrenciasRequest({
        page: 1,
        limit: 10,
        sort: "nome",
      })
    );
  } catch (error) {
    console.error(error);
  }
}

export default all([
  takeLatest(Types.LIST_REQUEST, listOcorrencias),
  takeLatest(Types.GET_REQUEST, getOcorrencia),
  takeLatest(Types.EDIT_REQUEST, editOcorrencia),
  takeLatest(Types.NEW_REQUEST, createOcorrencia),
  takeLatest(Types.DELETE_REQUEST, deleteOcorrencia),
]);
