import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Col, Row } from 'react-bootstrap';

import FormularioCadastro from "components/FormularioCadastro";

import Skeleton from "@material-ui/lab/Skeleton";

import InputForm from "components/InputForm";
import AutocompleteForm from "components/AutocompleteForm";

import { Creators as OcorrenciasActions } from "store/ducks/ocorrencias";
import OcorrenciasSchema from "validators/OcorrenciasSchema";
import { ValidationError } from "yup";

import {
  TYPES_LIST,
  SISTEM_LIST,
  CLASSIFICATION_LIST,
  PROCESS_LINE_LIST,
  STATUS_LIST,
  loadingTipo,
  loadingSistema,
  loadingClassificao,
  loadingEsteira,
  normalizeSchema,
} from '../index';
import moment from "moment";

export default function FormOcorrencia(params) {
  const dispatch = useDispatch();
  const { ocorrencia = null } = params.location.state || {};
  const formRef = useRef();

  const tipo = TYPES_LIST.find(item => item.value === ocorrencia?.tipo);
  const sistema = SISTEM_LIST.find(item => item.value === ocorrencia?.sistema);
  const classificacao = CLASSIFICATION_LIST.find(item => item.value === ocorrencia?.classificacao);
  const esteira = PROCESS_LINE_LIST.find(item => item.value === ocorrencia?.esteira);
  const status = STATUS_LIST.find(item => item.value === ocorrencia?.status);

  const { dataInicio, dataFinal } = ocorrencia
  const [dateStart, timeStart] = dataInicio.split(' ')
  const [dateEnd, timeEnd] = dataFinal.split(' ')

  const INITIAL_DATA = {
    ...ocorrencia,
    tipo,
    sistema,
    classificacao,
    esteira,
    status,
    dataInicio: moment(dateStart, "DD:MM:YYYY").format("YYYY-MM-DD"),
    dataFinal: moment(dateEnd, "DD:MM:YYYY").format("YYYY-MM-DD"),
    horaInicio: timeStart,
    horaFinal: timeEnd
  };

  const [timeStarts, setTimeStarts] = useState(INITIAL_DATA.horaInicio);
  const [timeEnds, setTimeEnds] = useState(INITIAL_DATA.horaFinal);

  async function handleSubmit(newValues) {
    const { dataInicio, dataFinal } = newValues;

    delete newValues.dataInicio;
    delete newValues.dataFinal;

    const newDataInicio = new Date(`${dataInicio}T${timeStarts || '00:00'}`)
    let newDataFinal = ""
    if (dataFinal) newDataFinal = new Date(`${dataFinal}T${timeEnds || '00:00'}`)

    const normalizedData = normalizeSchema(newValues)
    const body = { ...normalizedData, dataInicio: newDataInicio, dataFinal: newDataFinal, tipo: newValues.tipo.value, _id: INITIAL_DATA._id };

    try {
      await OcorrenciasSchema.validate(body, {
        abortEarly: false,
      });

      dispatch(OcorrenciasActions.editOcorrenciasRequest(body));

    } catch (err) {
      const validationErrors = {};
      if (err instanceof ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });

        formRef.current.setErrors(validationErrors);
      }
    }
  }

  return (

    <>
      <FormularioCadastro
        title={"Edição de ocorrência"}
        formRef={formRef}
        onSubmit={handleSubmit}
        canSave={true}
        initialData={INITIAL_DATA}
      >

        {!loadingTipo ? (
          <Skeleton width="100%" height={50} />
        ) : (
          <AutocompleteForm
            label="Tipo"
            name="tipo"
            options={TYPES_LIST}
            defaultValue={INITIAL_DATA.tipo}
            transformOnSelect={(option) => option}
            getOptionLabel={(option) => option.name}
          />
        )}

        {!loadingSistema ? (
          <Skeleton width="100%" height={50} />
        ) : (
          <AutocompleteForm
            label="Sistema"
            name="sistema"
            options={SISTEM_LIST}
            defaultValue={INITIAL_DATA.sistema}
            transformOnSelect={(option) => option}
            getOptionLabel={(option) => option.name}
          />
        )}

        {!loadingClassificao ? (
          <Skeleton width="100%" height={50} />
        ) : (
          <AutocompleteForm
            label="Classificação"
            name="classificacao"
            options={CLASSIFICATION_LIST}
            defaultValue={INITIAL_DATA.classificacao}
            transformOnSelect={(option) => option}
            getOptionLabel={(option) => option.name}
          />
        )}

        {!loadingEsteira ? (
          <Skeleton width="100%" height={50} />
        ) : (
          <AutocompleteForm
            label="Esteira de processamento"
            name="esteira"
            options={PROCESS_LINE_LIST}
            defaultValue={INITIAL_DATA.esteira}
            transformOnSelect={(option) => option}
            getOptionLabel={(option) => option.name}
          />
        )}

        <Row>
          <Col>
            <InputForm
              label="Data Inicial"
              name="dataInicio"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              defaultValue={INITIAL_DATA.dataInicio}
            />
          </Col>
          <Col>
            <InputForm
              label="Hora"
              name="timeStarts"
              type="time"
              value={timeStarts}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={e => setTimeStarts(e.target.value)}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <InputForm
              label="Data Final"
              name="dataFinal"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              defaultValue={INITIAL_DATA.dataFinal}
            />
          </Col>
          <Col>
            <InputForm
              label="Hora"
              name="timeEnds"
              type="time"
              value={timeEnds}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={e => setTimeEnds(e.target.value)}
            />
          </Col>
        </Row>

        <AutocompleteForm
          label="Status Ocorrência"
          name="status"
          options={STATUS_LIST}
          defaultValue={INITIAL_DATA.status}
          transformOnSelect={(option) => option}
          getOptionLabel={(option) => option.name}
        />

        <InputForm required label="Observação" name="observacao" type="text" />

        <InputForm
          label="Número do chamado"
          name="chamado"
          type="text"
          InputLabelProps={{
            shrink: true,
          }}
        />

      </FormularioCadastro>
    </>
  );
}
