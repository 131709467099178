export const Types = {
  LIST_BY_CARGAID_REQUEST: "cargaitem/LIST_BY_CARGAID_REQUEST",
  LIST_BY_CARGAID_SUCCESS: "cargaitem/LIST_BY_CARGAID_SUCCESS",
  MARK_AS_REMOVED_REQUEST: "cargaitem/MARK_AS_REMOVED_REQUEST",
  MARK_AS_REMOVED_SUCCESS: "cargaitem/MARK_AS_REMOVED_SUCCESS",
};

const INITIAL_STATE = {
  loading: false,
  cargaitens: [],
  sort: "_id",
  page: 1,
  limit: 10,
  total: 0,
};

export default function cargaitens(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.LIST_BY_CARGAID_REQUEST:
      return { ...state, loading: true };

    case Types.LIST_BY_CARGAID_SUCCESS:
      return { ...state, ...action.payload, loading: false };

    case Types.MARK_AS_REMOVED_REQUEST:
      return { ...state, loading: true };

    case Types.MARK_AS_REMOVED_SUCCESS:
      return { ...state, ...action.payload, loading: false };

    default:
      return state;
  }
}

export const Creators = {
  listRequest: (data) => ({
    type: Types.LIST_BY_CARGAID_REQUEST,
    payload: data,
  }),

  listSuccess: (data) => ({
    type: Types.LIST_BY_CARGAID_SUCCESS,
    payload: data,
  }),

  markAsRemovedRequest: (data) => ({
    type: Types.MARK_AS_REMOVED_REQUEST,
    payload: data,
  }),

  markAsRemovedSuccess: (data) => ({
    type: Types.MARK_AS_REMOVED_SUCCESS,
    payload: data,
  }),
};
