import * as Yup from "yup";
import Locale from "./Locale";

Yup.setLocale(Locale);

function finalDateVerify({ status }) {
  return Yup.string().test({
    name: "dataFinal",
    message: "Data final deve ser informada",
    test: function (value) {
      return !(this.parent[status] === false && !value);
    },
  });
}

export default Yup.object().shape({
  dataInicio: Yup.string().required(),
  tipo: Yup.string().required(),
  sistema: Yup.string().required(),
  classificacao: Yup.string().required(),
  esteira: Yup.string().required(),
  dataFinal: finalDateVerify({ status: "status" }),
});
