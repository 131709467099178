import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import StyledTableRow from "components/StyledTableRow";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TableFooter,
  TablePagination,
} from "@material-ui/core";
import { Link, useLocation } from "react-router-dom";

import ToolTipComponent from "components/Tooltip";

import ButtonSquare from "components/ButtonSquare";
import CustomTableSortLabel from "components/CustomTableSortLabel";
import LoadingTable from "components/LoadingTable";
import { Creators as MenusActions } from "store/ducks/menus";

import "./styles.css";

export default function ListTipoDocumento() {
  const dispatch = useDispatch();

  const { pathname } = useLocation();
  const {
    list,
    loading,
    limit = 10,
    page = 1,
    total,
    sort = "title",
  } = useSelector((state) => state.menus);
  const {
    access: { servicos },
  } = useSelector((state) => state.permissoes);

  const [userActions, setUserActions] = useState([]);
  const [request, setRequest] = useState({ limit, page, sort });

  useEffect(() => {
    let find = servicos.find((item) => item.route === pathname);
    if (find !== undefined) {
      setUserActions(find);
    }
  }, [pathname, servicos]);

  useEffect(() => {
    dispatch(MenusActions.listRequest(request));
  }, [dispatch, request]);

  function handleChangeSort(sort) {
    setRequest({ ...request, sort });
  }

  function handleChangeRowsPerPage({ target }) {
    const { value: limit } = target;
    setRequest({ ...request, limit, page: 1 });
  }

  function handleChangePage(e, newPage) {
    setRequest({ ...request, page: newPage + 1 });
  }

  function handleDelete(item) {
    dispatch(MenusActions.removeRequest(item._id));
  }

  return (
    <TableContainer className="table-usuarios-container">
      {(list && list.length > 0) || !!loading ? (
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                <CustomTableSortLabel
                  currentSort={request.sort}
                  sortField="title"
                  onClick={handleChangeSort}
                >
                  Nome
                </CustomTableSortLabel>
              </TableCell>
              <TableCell>
                <CustomTableSortLabel
                  currentSort={request.sort}
                  sortField="identifier"
                  onClick={handleChangeSort}
                >
                  Identificador
                </CustomTableSortLabel>
              </TableCell>
              <TableCell>
                <CustomTableSortLabel
                  currentSort={request.sort}
                  sortField="route"
                  onClick={handleChangeSort}
                >
                  Rota
                </CustomTableSortLabel>
              </TableCell>
              <TableCell align="center">Ações</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {!!loading && <LoadingTable columns={4} rows={request.limit} />}
            {!loading &&
              list &&
              list.map((item) => (
                <StyledTableRow key={item._id}>
                  <TableCell>{item.title}</TableCell>
                  <TableCell>{item.identifier}</TableCell>
                  <TableCell>{item.route}</TableCell>

                  {userActions.actions &&
                    userActions.actions.map(
                      (userAct, index) =>
                        userAct.action === "editar" && (
                          <TableCell key={index}>
                            <div className="flex-direction">
                              <ToolTipComponent title="Editar" placement="top">
                                <Link
                                  to={{
                                    pathname: "/configuracao/menu/edit",
                                    state: {
                                      menu: item,
                                    },
                                  }}
                                  className="linkicon"
                                  id="menu"
                                >
                                  <ButtonSquare icon="edit" />
                                </Link>
                              </ToolTipComponent>

                              <ToolTipComponent title="Remover" placement="top">
                                <div>
                                  <ButtonSquare
                                    onClick={() => handleDelete(item)}
                                    icon="delete"
                                  />
                                </div>
                              </ToolTipComponent>
                            </div>
                          </TableCell>
                        )
                    )}
                </StyledTableRow>
              ))}
          </TableBody>
          {!loading && (
            <TableFooter>
              <TableRow>
                <TablePagination
                  count={total}
                  page={request.page - 1}
                  rowsPerPage={request.limit}
                  rowsPerPageOptions={[10, 25, 50, 100]}
                  labelRowsPerPage="Linhas por página"
                  labelDisplayedRows={({ from, to, count }) =>
                    `${from}-${to} de ${
                      count !== -1 ? count : `mais que ${to}`
                    }`
                  }
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  onPageChange={handleChangePage}
                />
              </TableRow>
            </TableFooter>
          )}
        </Table>
      ) : (
        <div className="table-usuarios-content">
          <span className="list__none">Não há Menus registrados.</span>
        </div>
      )}
    </TableContainer>
  );
}
