import styled from 'styled-components/macro';

export const FormHeader = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    padding: 0 20%;
`;

export const FormRow = styled.fieldset`
    display: flex;
    width: 100%;

    .MuiFormControl-root:not(:last-of-type) {
        margin-right: 16px;
    }

    .MuiFormControl-root:last-of-type {
        width: 20%;
    }

    @media screen and (max-width: 1400px) {
        .MuiFormControl-root:last-of-type {
            width: 30%;
        }
    }
`;

export const QueueDiagram = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 600px;

    canvas {
        padding: 0;
        margin: auto;
        display: block;
        width: 800px;
    }
`;