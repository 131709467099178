import React, { useState, useCallback, useEffect } from "react";

import { Row, Col } from "react-bootstrap";
import { IndexadorComponent } from "components/IndexadorComponent";
import { valida_cnpj, valida_cpf } from "services/validadorCpfCNPJ";

function Indexadores({
  indexadores,
  handleChangeIndexador,
  handleDoubleClick,
}) {
  const isDisabled = !indexadores || indexadores?.length === 0;

  function checkIfValueIsInvalid(fieldType, indexer, validator) {
    return (
      checkIfFieldHaveValidValue(fieldType, indexer) &&
      !validator(indexer.valor)
    );
  }

  function checkIfFieldHaveValidValue(fieldType, indexer) {
    if (!indexer) {
      return false;
    }
    if (indexer.tipo === fieldType && indexer.valor && indexer.valor !== "") {
      return true;
    }
    return false;
  }

  function checkIfItsAInvalidCPF(indexer) {
    return checkIfValueIsInvalid("cpf", indexer, valida_cpf);
  }

  function checkIfItsAInvalidCNPJ(indexer) {
    return checkIfValueIsInvalid("cnpj", indexer, valida_cnpj);
  }

  function hasIndexadorError(indexador) {
    if (indexador.obrigatorio && !indexador.valor) return true;

    if (checkIfItsAInvalidCPF(indexador) || checkIfItsAInvalidCNPJ(indexador)) {
      return true;
    }
    return false;
  }

  function rule(indexador) {
    return indexador.obrigatorio && !indexador.valor;
  }

  function hasIndexadorTextError(indexador) {
    if (rule(indexador)) return "Campo obrigatório";
    return "";
  }

  return (
    <>
      {isDisabled && (
        <div className="tabLoading">Sem informações</div>
      )}
      {!!indexadores && indexadores?.filter((x) => x.tipo !== "tabela") && (
        <>
          {indexadores.map((indexador) => (
            <Row key={indexador.nome}>
              <Col md={12} className="mt-3">
                <IndexadorComponent
                  indexador={indexador}
                  handleChangeIndexador={handleChangeIndexador}
                  handleDoubleClick={handleDoubleClick}
                  // options={metadados}
                  // value={currentMetadata}
                  error={hasIndexadorError(indexador)}
                  helperText={hasIndexadorTextError(indexador)}
                />
              </Col>
            </Row>
          ))}
        </>
      )}
    </>
  );
}

export default Indexadores;
