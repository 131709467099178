export const Types = {
  LIST_REQUEST: "tribunal/LIST_REQUEST",
  LIST_SUCCESS: "tribunal/LIST_SUCCESS",
  NEW_REQUEST: "tribunal/NEW_REQUEST",
  NEW_SUCCESS: "tribunal/NEW_SUCCESS",
  DELETE_REQUEST: "tribunal/DELETE_REQUEST",
  DELETE_SUCCESS: "tribunal/DELETE_SUCCESS",
  GET_REQUEST: "tribunal/GET_REQUEST",
  GET_SUCCESS: "tribunal/GET_SUCCESS",
  EDIT_REQUEST: "tribunal/EDIT_REQUEST",
  EDIT_SUCCESS: "tribunal/EDIT_SUCCESS",
};

/* Reducer */

const INITIAL_STATE = {
  loading: false,
  list: [],
  tribunal: [],
  sort: "nome",
  page: 1,
  limit: 10,
  total: 0,
};

export default function tribunal(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.LIST_REQUEST:
      return { ...state, loading: true };

    case Types.LIST_SUCCESS:
      return { ...state, ...action.payload, loading: false };

    case Types.NEW_REQUEST:
      return { ...state, loading: true };

    case Types.NEW_SUCCESS:
      return { ...state, loading: false };

    case Types.DELETE_REQUEST:
      return { ...state, loading: true };

    case Types.DELETE_SUCCESS:
      return { ...state, loading: false };

    case Types.GET_REQUEST:
      return { ...state, loading: true };

    case Types.GET_SUCCESS:
      return { ...state, loading: false };

    case Types.EDIT_REQUEST:
      return { ...state, loading: true };

    case Types.EDIT_SUCCESS:
      return { ...state, loading: false };

    default:
      return state;
  }
}

export const Creators = {
  listRequest: (data) => ({
    type: Types.LIST_REQUEST,
    payload: data,
  }),

  listSuccess: (data) => ({
    type: Types.LIST_SUCCESS,
    payload: data,
  }),

  deleteRequest: (id) => ({
    type: Types.DELETE_REQUEST,
    payload: id,
  }),

  deleteSuccess: () => ({
    type: Types.DELETE_SUCCESS,
    payload: {},
  }),

  getRequest: (id) => ({
    type: Types.GET_REQUEST,
    payload: id,
  }),

  getSuccess: (tribunal) => ({
    type: Types.GET_SUCCESS,
    payload: tribunal,
  }),

  editRequest: (tribunal) => ({
    type: Types.EDIT_REQUEST,
    payload: tribunal,
  }),

  editSuccess: () => ({
    type: Types.EDIT_SUCCESS,
    payload: {},
  }),

  createRequest: (tribunal) => ({
    type: Types.NEW_REQUEST,
    payload: tribunal,
  }),

  createSuccess: () => ({
    type: Types.NEW_SUCCESS,
    payload: {},
  }),
};
