import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Button, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { ParagraphModal, ModalHeaderFooter } from "../Modal";
import AccordionMenu from "../AccordionMenu";
import Options from "../Options";
import history from "../../../routes/history";
import ModalPreviewTemplate from "../../../pages/ModalPreviewTemplate";

import { Creators as TemplateActions } from "../../../store/ducks/template";

const useStyles = makeStyles((theme) => ({
  content: {
    display: "flex",
    flexDirection: "column",
    "& .MuiFormControl-marginNormal": {
      marginBottom: "0px",
      marginTop: "0px",
    },
  },
  inputItem: {
    marginTop: "20px",
  },

  variables: {
    marginTop: "20px",
    "& .MuiCardContent-root": {
      padding: "5px 8px",
      "& .label": {
        marginTop: "5px",
      },
      "& .MuiChip-root": {
        marginTop: "8px",
        backgroundColor: "#e5ebff",
        borderRadius: "5px",
        width: "100%",
        "& .MuiChip-label": {
          marginRight: "auto",
          fontSize: "13px",
        },
      },
    },

    "& .MuiCardActions-root": {
      padding: "8px, 16px",
      "& .MuiButton-root": {
        padding: "0px",
        margin: "0px",
        marginLeft: "auto",
        minWidth: "0px",
        "& .MuiSvgIcon-root": {
          width: "1.3em",
          height: "1.3em",
        },
      },
    },
  },
  footer: {
    "& .buttonHeaderAndFooter": {
      marginTop: "-20px",

      "& .MuiButton-contained": {
        marginTop: "5px",
      },
    },
    "& .buttons": {
      display: "flex",
      justifyContent: "flex-end",
      margin: "20px 0px",
    },
  },
}));

function ModelForm() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const state = useSelector((state) => state.template);

  useEffect(() => {
    if (!!Object.keys(state.templateSelected).length) {
      return;
    }
    dispatch(TemplateActions.deleteFooter());
    dispatch(TemplateActions.deleteHeader());
  }, [state.templateSelected, dispatch]);

  const handleBackButton = () => {
    history.goBack();
  };

  const handleShowModalHeader = () => {
    dispatch(TemplateActions.showModal({ modal: "HEADER", show: true }));
  };

  const handleShowModalFooter = () => {
    dispatch(TemplateActions.showModal({ modal: "FOOTER", show: true }));
  };

  const handleShowModalSignaturePoints = async () => {
    dispatch(TemplateActions.showModal({ modal: "SIGNATURE", show: true }));
  };

  const handleCloseModalSignaturePoints = () => {
    dispatch(TemplateActions.showModal({ modal: "SIGNATURE", show: false }));
  };

  async function onSubmit() {
    const data = {
      modelType: state?.templateSelected?.modelType,
      modelName:
        state?.templateSelected?.modelName || state?.templateSelected?.name,
      description: state?.templateSelected?.description,
      idTipoDocumento: state?.templateSelected?.idTipoDocumento,
    };

    console.log("state ", state);

    if (state.templateSelected._id) {
      dispatch(
        TemplateActions.editTemplateRequest({
          state,
          data,
          id: state.templateSelected._id,
        })
      );
      return;
    }
    dispatch(TemplateActions.saveTemplate({ state, data }));
    dispatch(TemplateActions.getTemplate({}));
  }

  return (
    <>
      <div className={classes.content}>
        <Options
          state={state}
          handleShowModalHeader={handleShowModalHeader}
          handleShowModalFooter={handleShowModalFooter}
          handleShowModalSignaturePoints={handleShowModalSignaturePoints}
        />
        <AccordionMenu name="paragraph" />

        {state.showModalSignaturePoints && (
          <ModalPreviewTemplate
            open={true}
            state={state}
            template={null}
            handleClose={handleCloseModalSignaturePoints}
          />
        )}

        {state.showModalHeader && (
          <ModalHeaderFooter headerTitle={"Novo cabeçalho"} />
        )}

        {state.showModalFooter && (
          <ModalHeaderFooter footerTitle={"Novo rodapé"} />
        )}

        {state.showModalParagraph && <ParagraphModal />}

        <div className={classes.footer}>
          <div className="buttons">
            {state.loading ? (
              <CircularProgress style={{ margin: "auto" }} />
            ) : (
              <Button
                onClick={() => onSubmit()}
                color="primary"
                variant="contained"
              >
                Salvar
              </Button>
            )}
            <Button
              onClick={() => handleBackButton()}
              style={{ marginLeft: "10px" }}
              color="secondary"
              variant="contained"
            >
              Cancelar
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default ModelForm;
