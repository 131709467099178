import React from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Divider,
} from "@material-ui/core";

import "./styles.css";

function DadosAlterados({ dadosAlterados, onClose, ...props }) {
  return (
    <Dialog onClose={onClose} maxWidth="md" fullWidth {...props}>
      <DialogTitle>LOGS DE ALTERAÇÃO</DialogTitle>
      <DialogContent>
        <div className="dados-alterados-content">
          <span className="header">Campo</span>
          <span className="header">Valor Anterior</span>
          <span className="header">Valor Novo</span>
          <Divider />
          <Divider />
          <Divider />
          {dadosAlterados &&
            Object.keys(dadosAlterados).map((item, index) => (
              <React.Fragment key={index}>
                {dadosAlterados[item].valorNovo ? (
                  <>
                    <span>{item}</span>
                    <span>{dadosAlterados[item].valorAnterior}</span>
                    <span>{dadosAlterados[item].valorNovo}</span>
                  </>
                ) : (
                  <>
                    <strong className="captalize">{`Título: ${item}`}</strong>
                    <div></div>
                    <div></div>
                    {Object.keys(dadosAlterados[item]).map((subItem) => (
                      <>
                        <div>
                          <span className="captalize italico pl-16">{`Item de Checklist: `}</span>
                          <strong>{subItem}</strong>
                        </div>
                        {dadosAlterados[item][subItem] &&
                        typeof dadosAlterados[item][subItem].valorNovo ===
                          "object" ? (
                          <>
                            <div></div>
                            <div></div>
                            {Object.keys(
                              dadosAlterados[item][subItem].valorNovo
                            ).map((subSubItem) => (
                              <>
                                <span className="captalize pl-32">
                                  {subSubItem}
                                </span>
                                <span>
                                  {
                                    dadosAlterados[item][subItem].valorAnterior[
                                      subSubItem
                                    ]
                                  }
                                </span>
                                <span>
                                  {
                                    dadosAlterados[item][subItem].valorNovo[
                                      subSubItem
                                    ]
                                  }
                                </span>
                              </>
                            ))}
                          </>
                        ) : (
                          <>
                            <span>
                              {dadosAlterados[item][subItem] &&
                                dadosAlterados[item][subItem].valorAnterior}
                            </span>
                            <span>
                              {dadosAlterados[item][subItem] &&
                                dadosAlterados[item][subItem].valorNovo}
                            </span>
                          </>
                        )}
                      </>
                    ))}
                  </>
                )}
                <Divider />
                <Divider />
                <Divider />
              </React.Fragment>
            ))}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="contained" color="primary">
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DadosAlterados;
