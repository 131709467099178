import React from 'react';
import Typography from '@material-ui/core/Typography';

function Aprovado() {
  return (
    <div style={{
      padding: '0px 8px',
      background: '#007d00',
      color: '#fff',
      borderRadius: '4px',
      marginRight: '8px'
    }}>
      <Typography 
        style={{
          fontSize: '10px',
          fontWeight: 'bold'
        }}
      >
        APROVADO
      </Typography>
    </div>
  );
}

export default Aprovado;