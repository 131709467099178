import React, { useRef } from "react";
import { useDispatch } from "react-redux";
import { Creators as ConfigActions } from "store/ducks/configuracaoDashboardExterno";

import _ from "lodash";

import GridCadastro from "components/GridCadastro";
import List from "./List";

export default function ConfigExternalDashboard() {
  const dispatch = useDispatch();
  const dispatchDebounce = useRef(_.debounce(dispatch, 500)).current;

  return (
    <GridCadastro
      title="Configuração dashboard externo"
      route={"/configuracao/external-dashboard/gerenciar"}
      searchRequest={(value) => {
        dispatchDebounce(
          dispatch(
            ConfigActions.listRequest({
              page: 1,
              limit: 100,
              search: value,
            })
          )
        );
      }}
      titleButtonNovo="Nova configuração"
    >
      <List />
    </GridCadastro>
  );
}
