import { call, put, select } from "redux-saga/effects";

import { toastr } from "react-redux-toastr";
import { push } from "connected-react-router";
import history from '../../routes/history';

import api from "../../services/api";
import { getToken } from "../../services/auth";

import { Creators as CheckTipoActions } from "../ducks/checktipo";

export const getOrganization = (state) => state.usuarios.auth.organization;
export const getUserId = (state) => state.usuarios.auth.id;

export function* listParamFormalizacaoByEtapa(action) {
  try {
    const organization = yield select(getOrganization);

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const { data } = yield call(
      api.get,
      `paramformalizacao/${action.payload}`,
      headerParams
    );

    yield put(CheckTipoActions.listByEtapasSuccess(data));
  } catch (error) {
    toastr.error("Ops", "Não foi possível listar os Tipos de Checklist.");
  }
}

export function* listParamFormalizacaoByEmpresa({ payload }) {
  try {
    const organization = yield select(getOrganization);

    const { empresa, page, limit, sort } = payload;

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
      params: { page, limit, sort },
    };

    const response = yield call(
      api.get,
      `paramformalizacao/check/empresa/${empresa}`,
      headerParams
    );

    const { docs: list, total } = response.data;

    yield put(CheckTipoActions.getCheckEmpresaSuccess({ list, total }));
  } catch (error) {
    toastr.error("Ops", "Não foi possível listar os Checklists por Empresas.");
  }
}

export function* showCheckTipo(action) {
  try {
    const organization = yield select(getOrganization);
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const { data } = yield call(
      api.get,
      `paramformalizacao/check/${action.payload}`,
      headerParams
    );

    yield put(CheckTipoActions.getSuccess(data));
  } catch (error) {
    toastr.error(
      "Ops",
      "Não foi possível buscar os dados do Tipo de Checklist."
    );
  }
}

export function* editCheckTipo(action) {
  const userId = yield select(getUserId);

  try {
    const organization = yield select(getOrganization);

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const body = { ...action.payload, userAcao: userId };

    const { data } = yield call(
      api.put,
      `paramformalizacao/${action.payload._id}`,
      body,
      headerParams
    );

    yield put(push(`/operacao/checktipo`));

    yield put(CheckTipoActions.editSuccess(data));

    toastr.success("Tipo de Checklist editado com sucesso.");
  } catch (error) {
    toastr.error("Ops", "Não foi possível editar o Tipo de Checklist.");
  }
}

export function* createCheckTipo(action) {
  const organization = yield select(getOrganization);

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const { data } = yield call(
      api.post,
      `paramformalizacao`,
      action.payload,
      headerParams
    );

    yield put(push(`/operacao/checktipo`));

    yield put(CheckTipoActions.createSuccess(data));

    toastr.success("", "Checklist criado com sucesso.");
  } catch (error) {
    toastr.error("Ops", "Não foi possível criar o Tipo de Checklist.");
  }
}

export function* removeCheckTipo(action) {
  try {
    const headerParams = {
      headers: { Authorization: getToken() },
    };

    yield call(api.delete, `checktipo/${action.payload}`, headerParams);

    yield put(CheckTipoActions.removeSuccess());

    toastr.success("", "Departamento removido com sucesso.");
  } catch (error) {
    toastr.error("Ops", "Não foi possível remover os tipos de checklists.");
  }
}

export function* createCheckTitulo(action) {
  const organization = yield select(getOrganization);

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const { data } = yield call(
      api.post,
      `paramformalizacao/titulo/novo`,
      action.payload,
      headerParams
    );

    yield put(CheckTipoActions.createTituloSuccess(data));

    const body = { payload: action.payload._id };

    yield showCheckTipo(body);

    toastr.success("", "Titulo criado com sucesso.");
  } catch (error) {
    toastr.error("Ops", "Não foi possível criar o Item.");
  }
}

export function* removeCheckTitulo(action) {
  const organization = yield select(getOrganization);

  const { _id, hash } = action.payload;

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const { data } = yield call(
      api.delete,
      `paramformalizacao/${_id}/titulo/${hash}`,
      headerParams
    );

    yield put(CheckTipoActions.removeTituloSuccess(data));

    const body = { payload: action.payload._id };

    yield showCheckTipo(body);

    toastr.success("", "Título removido com sucesso.");
  } catch (error) {
    toastr.error("Ops", "Não foi possível remover o Item.");
  }
}

export function* removeCheckItem(action) {
  const organization = yield select(getOrganization);

  const { _id, hashTitulo, hashItem } = action.payload;

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const { data } = yield call(
      api.delete,
      `paramformalizacao/${_id}/titulo/${hashTitulo}/item/${hashItem}`,
      headerParams
    );

    yield put(CheckTipoActions.removeItemSuccess(data));

    const body = { payload: action.payload._id };

    yield showCheckTipo(body);

    toastr.success("", "Item removido com sucesso.");
  } catch (error) {
    toastr.error("Ops", "Não foi possível remover o Item.");
  }
}

export function* createCheckItem(action) {
  const organization = yield select(getOrganization);

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const { data } = yield call(
      api.post,
      `paramformalizacao/item/novo`,
      action.payload,
      headerParams
    );

    yield put(CheckTipoActions.createSuccess(data));

    const body = { payload: action.payload._id };

    yield showCheckTipo(body);
    
    const isEditing = window.location.href.includes("checktipo/item/edit");

    if (isEditing)
      history.goBack();

    toastr.success("", "Checklist criado com sucesso.");
  } catch (error) {
    toastr.error("Ops", "Não foi possível criar o Tipo de Checklist.");
  }
}
