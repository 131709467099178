import styled from 'styled-components/macro';

export const Container = styled.li`
    position: relative;
    width: ${({ size }) => `${size}px`};
    height: ${({ size }) => `${size}px`};
    border-radius: 500px;
    background-color: #CCC;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: ${({ size }) => `${size * 0.4}px`};
    border: ${({ size }) => `${size * 0.08}px`} solid #FFF;
    transition: z-index .2s ease-in-out,
        transform .2s ease-in-out;
    cursor: default;

    &:not(:last-of-type) {
        margin-right: -8px;
    }

    &:hover {
        z-index: 99;
        transform: scale(1.1);
    }
`;