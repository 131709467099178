export const CALENDAR_EXIBITION_TYPES = {
    MONTH: {
        label: 'Mês',
        value: 'month',
    },
    WEEK: {
        label: 'Semana',
        value: 'week',
    },
    DAY: {
        label: 'Dia',
        value: 'day',
    },
    AGENDA: {
        label: 'Agenda',
        value: 'agenda',
    },
}

export const CALENDAR_OPTIONS = {
    TIME_SLOT_HEIGHT: 60,
    DAILY_START_HOUR: new Date(0, 0, 0, 7, 0, 0),
    DAILY_END_HOUR: new Date(0, 0, 0, 22, 0, 0),
}