export const Types = {
  LIST_REQUEST: "partes/LIST_REQUEST",
  LIST_SUCCESS: "partes/LIST_SUCCESS",
  COUNT_TOTAL_MANIFESTATION_REQUEST: "partes/COUNT_TOTAL_MANIFESTATION_REQUEST",
  COUNT_TOTAL_MANIFESTATION_SUCCESS: "partes/COUNT_TOTAL_MANIFESTATION_SUCESS",
};

const INITIAL_STATE = {
  list: [],
  loading: false,
  page: 1,
  limit: 10,
  total: 0,
  search: "",
  sort: "nome",
  manifestationPartGoupBy: null,
};

export default function partes(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.LIST_REQUEST:
      return {
        ...state,
        ...action.payload,
        list: [],
        loading: true,
      };

    case Types.LIST_SUCCESS:
      return {
        ...state,
        total: action.payload.total,
        list: action.payload.list,
        loading: false,
      };

    case Types.COUNT_TOTAL_MANIFESTATION_REQUEST:
      return {
        ...state,
        loading: true,
        totalManifestationsNumber: 0,
      };

    case Types.COUNT_TOTAL_MANIFESTATION_SUCCESS:
      return {
        ...state,
        loading: false,
        manifestationPartGoupBy: action.payload,
      };

    default:
      return state;
  }
}

export const Creators = {
  listRequest: (params) => ({
    type: Types.LIST_REQUEST,
    payload: params,
  }),
  listSuccess: (list, total) => ({
    type: Types.LIST_SUCCESS,
    payload: { list, total },
  }),
  totalManifestationNumberRequest: (payload) => ({
    type: Types.COUNT_TOTAL_MANIFESTATION_REQUEST,
    payload,
  }),
  totalManifestationNumberSuccess: (payload) => ({
    type: Types.COUNT_TOTAL_MANIFESTATION_SUCCESS,
    payload,
  }),
};
