import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useDispatch, useSelector } from "react-redux";

import { Creators as CheckTipoActions } from "../../store/ducks/checktipo";
import { Creators as EtapaActions } from "../../store/ducks/etapas";
import { Creators as EmpresasActions } from "../../store/ducks/empresas";

import { Link, useLocation } from "react-router-dom";

import TableCheckTipo from "./TableCheckTipo";

import "./styles.css";

import { Col, Form, Row } from "react-bootstrap";
import Content from "components/Content";

export default function Formalizacao() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const {
    access: { servicos },
  } = useSelector((state) => state.permissoes);

  const { list } = useSelector((state) => state.empresas);

  const [empresaSelecionada, setEmpresaSelecionada] = useState("");
  const [userActions, setUserActions] = useState([]);

  const handleLoadFila = (event, empresa) => {
    if (empresa && empresa._id) {
      setEmpresaSelecionada(empresa._id);
    } else {
      setEmpresaSelecionada("");
    }
  };

  useEffect(() => {
    dispatch(EmpresasActions.listRequest());
  }, [dispatch]);

  useEffect(() => {
    dispatch(CheckTipoActions.setCheckEmpresaRequest(empresaSelecionada));
  }, [dispatch, empresaSelecionada]);

  useEffect(() => {
    let find = servicos.find((item) => item.route === pathname);
    if (find !== undefined) {
      setUserActions(find);
    }
  }, [pathname, servicos]);

  return (
    <Content>
      <Row>
        <Col md="12" className="painel">
          <div className="painel-content">
            <div className="painel-title text-left">
              Tipos de Checklist
              {userActions.actions &&
                userActions.actions.map(
                  (userAct, index) =>
                    userAct.action === "criar" && (
                      <Button
                        key={index}
                        to={"/operacao/checktipo/createchecktipo"}
                        color="primary"
                        component={Link}
                      >
                        Novo Tipo de Checklist
                      </Button>
                    )
                )}
            </div>

            <div className="painel-body">
              <Form>
                <Row>
                  <Col md={4}>
                    <Autocomplete
                      id="combo-box-empresas"
                      options={list}
                      getOptionLabel={(option) => option.fantasia}
                      getOptionSelected={(option, value) =>
                        option._id === value._id
                      }
                      onChange={handleLoadFila}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Empresa"
                          variant="outlined"
                        />
                      )}
                      size="small"
                    />
                  </Col>
                </Row>

                {empresaSelecionada !== "" && (
                  <div className="panels">
                    <Row>
                      <Col md="12" className="painel">
                        <div className="painel-content">
                          <div className="painel-title text-left">
                            Checklist Cadastrados
                          </div>
                          <TableCheckTipo empresa={empresaSelecionada} />
                          <div className="painel-body"></div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                )}
              </Form>
            </div>
          </div>
        </Col>
      </Row>
    </Content>
  );
}
