import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';

import CustomizeTimeline from '../../../Historico';

import { Creators as AuditoriaActions } from '../../../../store/ducks/auditoria';

import './styles.css';

export default function Historico({ selectedDoc }) {

  const dispatch = useDispatch();
  const { loading } = useSelector(state => state.auditoria);
  const { loading: loadingDocumento } = useSelector(state => state.documentos);

  useEffect(() => {
    if (selectedDoc._id) {
      const idDocumento = selectedDoc._id;
      dispatch(AuditoriaActions.listDocumentoRequest({ idDocumento }));
    }
  }, [dispatch, selectedDoc._id])

  return (
    <div className="historico-container">
      {(loading || loadingDocumento) ? <CircularProgress /> : <CustomizeTimeline />}
    </div>
  )
}
