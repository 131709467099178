import api from 'services/api';
import { getToken } from 'services/auth';
import { call, select, put, all, takeLatest } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';

import { Creators as AssistantActions, Types } from '../ducks/assistant';

export const getOrganization = (state) => state.usuarios.auth.organization;
export const getUserId = (state) => state.usuarios.auth.id;

export function* listAssistantMessages({ payload }) {
  try {
    const organization = yield select(getOrganization);

    const { threadId, documentId } = payload;

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const response = yield call(
      api.get,
      `assistant/${threadId}/${documentId}`,
      headerParams
    );

    const messages = response.data.messages;

    yield put(AssistantActions.listSuccess(messages));
  } catch (error) {
    console.log('error', error);
    toastr.error('Ops', 'Não foi possível listar as mensagens do assistente.');
  }
}

export function* createAssistantMessage(action) {
  const userId = yield select(getUserId);

  try {
    const organization = yield select(getOrganization);

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const { threadId, documentId } = action.payload;

    const body = { ...action.payload, id_user_acao: userId };

    const { data } = yield call(api.post, `assistant`, body, headerParams);

    const response = yield call(
      api.get,
      `assistant/${threadId}/${documentId}`,
      headerParams
    );

    const messages = response.data.messages;

    yield put(AssistantActions.createSuccess(data));

    yield put(AssistantActions.listSuccess(messages));
  } catch (error) {
    toastr.error('Ops', 'Não foi possível criar a mensagem para o assistente.');
  }
}

export default all([
  takeLatest(Types.LIST_REQUEST, listAssistantMessages),
  takeLatest(Types.NEW_REQUEST, createAssistantMessage),
]);
