import api from "./api";
import { getToken } from "./auth";
import Utils from "utils/utils";

export const getPresignedUrl = async image => {
  const headerParams = {
    headers: { Authorization: getToken() }
  };

  const {
    data: { signedUrl }
  } = await api.post(`s3/sign`, { image }, headerParams);

  return signedUrl;
};

export const getPresignedUrlBase64 = async image => {
  const imageUrl = await new Utils().signedUrl(image);
  return imageUrl;
};
