import React, { useState, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { MenuItem } from "@material-ui/core";
import { useDropzone } from "react-dropzone";
import { toastr } from "react-redux-toastr";
import { Row, Col } from "react-bootstrap";
import { Form } from "@unform/web";

import { Creators as ImportacaoActions } from "store/ducks/importacao";
import { Creators as DocumentTypeActions } from "store/ducks/tiposdocumento";

import TextField from "@material-ui/core/TextField";
import ButtonMui from "@material-ui/core/Button";
import BackButton from "components/BackButton";
import Grid from "@material-ui/core/Grid";
import {
  OUTROS_CAMPOS,
  OUTROS_CAMPOS_TIPO_LAYOUT,
} from "utils/default-another-fields";
import FormRules from "pages/Layout/FormRules";
import SeparadorLayout from "pages/Layout/Separador";
import Content from "components/Content";

export const ConfiguracaoRedirecenionamento = (params) => {
  const dispatch = useDispatch();
  const formRef = useRef(null);
  const [filesToUpload] = useState();
  const { tipodoc } = params.location.state;

  const [form, setForm] = useState(tipodoc ?? {});

  const {
    access: { empresas },
  } = useSelector((state) => state.permissoes);

  const { listByEmpresas: tipoDocList, loading: loadingTipoDoc } = useSelector(
    (state) => state.tipoDocumentos
  );

  //   const tipoDocSelecionado = (tipoDocList ?? []).find(
  //     (tipoDoc) => tipoDoc._id === form.idTipoDocumento
  //   );  const tipoDocSelecionado = (tipoDocList ?? []).find(
  //     (tipoDoc) => tipoDoc._id === form.idTipoDocumento
  //   );

  const checkFile = (file) => {
    const typeSuport = ["xlsx", "xls", "csv", "txt"];
    const typeFile = file.path.split(".").pop();
    return typeSuport.includes(typeFile);
  };

  const canSave = () => {
    return form.nome !== "" && form.descricao !== "";
  };

  const onDrop = useCallback(
    (files) => {
      const filter = files.filter(checkFile);
      dispatch(ImportacaoActions.createRequestHeader(filter[0]));
    },
    [filesToUpload]
  );

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
  });

  const onSubmitLayout = () => {
    if (form.rules?.length === 0)
      return toastr.warning("Necessário configurar ao menos uma regra");

    try {
      dispatch(DocumentTypeActions.updateRulesRequest(form));
    } catch (err) {
      return toastr.error("Ops", "Ocorreu um erro ao salvar layout");
    }
  };

  const handleChangeTipoDoc = (value) => {
    setForm({
      ...form,
      idTipoDocumento: value?._id,
    });
  };

  const handleChange = ({ target }) => {
    const { checked, value, name } = target;

    if (target?.type === "checkbox") {
      setForm({
        ...form,
        [name]: checked,
      });
    } else {
      setForm({ ...form, [name]: value });
    }
  };

  const handleCampoDestino = (newValue, index) => {
    let columns = form?.columns;
    columns[index].destino = newValue;
    columns[index].indexador = true;
    setForm({ ...form, columns: columns });
  };

  const handleRule = (rule, key, value) => {
    if (key === "nome") {
      rule[key] = value;
    } else {
      rule.destinations[key] = value;
    }

    setForm((prev) => ({
      ...form,
      rules: [
        ...(prev.rules.filter(function (x) {
          return x.hash != rule.hash;
        }) || []),
        ...[rule],
      ],
    }));
  };

  const handleCampoPadrao = (e, index) => {
    const { value } = e.target;
    let columns = form?.columns;
    columns[index].valorPadrao = value;
    setForm({ ...form, columns: columns });
  };

  const handleRemoveCondition = (hash, hashcond) => {
    if (hash && hashcond) {
      let rule = {
        ...form.rules.find(function (rule) {
          return rule.hash === hash;
        }),
      };

      removeRule(hash);

      rule.conditions =
        rule.conditions.filter(function (x) {
          return x.hash != hashcond;
        }) || [];

      setForm((prev) => ({
        ...form,
        rules: [
          ...(prev.rules.filter(function (x) {
            return x.hash != hash;
          }) || []),
          ...[rule],
        ],
      }));
    }
  };

  const handleNewCondition = (hash) => {
    _newCondition(hash);
  };

  const handleNewRule = () => {
    _newRule();
  };

  const removeRule = (hash) => {
    if (hash) {
      setForm((prev) => ({
        ...form,
        rules: [
          ...prev.rules.filter(function (rule) {
            return rule.hash !== hash;
          }),
        ],
      }));
    }
  };

  const _newRule = () => {
    setForm((prev) => ({
      ...form,
      rules: [
        ...(prev.rules || []),
        ...[
          {
            nome: "",
            hash: Math.random(),
            conditions: [],
            destinations: {},
          },
        ],
      ],
    }));
  };

  const _newCondition = (hash) => {
    if (hash) {
      let rules = [...form.rules];
      let rule = {
        ...form.rules.find(function (rule) {
          return rule.hash === hash;
        }),
      };

      rule.conditions.push({
        hash: Math.random(),
        atributo: "",
        condicao: "",
        valor: "",
      });
      rules.push(rule);

      setForm((prev) => ({
        ...form,
        rules: [
          ...(prev.rules.filter(function (x) {
            return x.hash != hash;
          }) || []),
          ...[rule],
        ],
      }));
    }
  };

  const _updateCondition = (rule, condition) => {
    rule.conditions = rule.conditions.filter(function (x) {
      return x.hash != condition.hash;
    });
    rule.conditions.push(condition);

    setForm((prev) => ({
      ...form,
      rules: [
        ...(prev.rules.filter(function (x) {
          return x.hash != rule.hash;
        }) || []),
        ...[rule],
      ],
    }));
  };

  const handleCondition = (rule, condition, key, value) => {
    if (rule && condition && key && value) {
      condition[key] = value;
      _updateCondition(rule, condition);
    }
  };

  return (
    <Content>
      <Row>
        <Col md="12" className="painel">
          <div className="painel-content">
            <div className="painel-title text-left">
              Editar regras de redirecionamentos
            </div>
            <div className="painel-body">
              <Form
                ref={formRef}
                onSubmit={onSubmitLayout}
                className={"mt-2"}
              >
                <SeparadorLayout titulo="Regras" />
                <Row>
                  <FormRules
                    atributes={
                      form?.tipoDocumento?.indexadores?.concat([
                        ...OUTROS_CAMPOS_TIPO_LAYOUT,
                        ...OUTROS_CAMPOS,
                      ]) || [...OUTROS_CAMPOS_TIPO_LAYOUT, ...OUTROS_CAMPOS]
                    }
                    handleRule={handleRule}
                    removeRule={removeRule}
                    handleNewRule={handleNewRule}
                    handleCondition={handleCondition}
                    handleNewCondition={handleNewCondition}
                    handleRemoveCondition={handleRemoveCondition}
                    rules={form.rules}
                    fieldsOptions={{
                      status: ["Igual", ""],
                    }}
                    idEsRenderField={{
                      status: ({ condition, onChange, conditionHashValues }) => (
                        <>
                          <TextField
                            select
                            variant="outlined"
                            placeholder="Status"
                            size="small"
                            value={`${condition.valor}`}
                            disabled={!conditionHashValues[condition.hash]}
                            onChange={event => {
                              onChange(event)
                            }}
                          >
                            <MenuItem value={"true"}>sim</MenuItem>
                            <MenuItem value={"false"}>não</MenuItem>
                            <MenuItem value={""}></MenuItem>
                          </TextField>
                        </>
                      ),
                    }}
                  />
                </Row>

                <Grid container spacing={1} className="mt-2 mb-2">
                  <Grid item>
                    <ButtonMui
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={!canSave}
                    >
                      Salvar
                    </ButtonMui>
                  </Grid>
                  <Grid item>
                    <BackButton variant="contained" color="secondary">
                      Voltar
                    </BackButton>
                  </Grid>
                </Grid>
              </Form>
            </div>
          </div>
        </Col>
      </Row>
    </Content>
  );
};
