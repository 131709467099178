import React, { useRef } from "react";
import { useDispatch } from "react-redux";
import { Creators as FormulariosActions } from "store/ducks/formulario";

import GridCadastro from "components/GridCadastro";
import TableFormularios from "./TableFormularios";

import _ from 'lodash';

export default function Formularios() {

    const dispatch = useDispatch();
    const dispatchDebounce = useRef(_.debounce(dispatch, 500)).current;

    return (
        <GridCadastro
            title='Formulários'
            route={"/formulario/novo"}
            searchRequest={(value) => {
                dispatchDebounce(
                    FormulariosActions.listRequest({ page: 1, limit: 10, search: value })
                );
            }}
            titleButtonNovo="Novo Formulário"
        >
            <TableFormularios />
        </GridCadastro>
    )
}